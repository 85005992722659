<template>
    <div id="elencoProgetti" class="elencoProgetti">
        <b-overlay :show="this.disableTabs" rounded="sm">
        <div class="table-title">Scheda {{isFinal? "Final" : "Interim"}} Assessment {{title}} </div>
            <div v-if="!this.disableTabs">
                <DynamicTable :tdata="this.bannerTable" />
                    <b-tabs card nav-class="tabs" content-class="content">
                        <ColorLegend/>
                        <b-tab no-body :title="conf_export_pdf['anagrafica'].title_page_pdf" title-item-class="tab1" title-link-class="titleLink"
                            v-on:click="setTabSelection(conf_export_pdf['anagrafica'].name)">
                            <anagraficaProgetto v-if="loadComplete"
                            :idTablePdf="conf_export_pdf['anagrafica'].id_table_pdf"
                            :salvaSchedaToStore="this.salvaSchedaToStore"
                            :incomingData="this.inputData"
                            :tabName="tabSelection"
                            :final="isFinal"/>
                        </b-tab>
                        <b-tab no-body :title="conf_export_pdf['realizzazione_collapse1'].title_page_pdf" title-item-class="tab1" title-link-class="titleLink"
                            v-on:click="setTabSelection(conf_export_pdf['realizzazione_collapse1'].name)">
                            <collapse :name="conf_export_pdf['realizzazione_collapse1'].nameCollapse">
                                <attivita2
                                    :idTablePdf="conf_export_pdf['realizzazione_collapse1'].id_table_pdf"
                                    :incomingData="this.inputData"
                                    :idScheda="this.inputData.schedaAssessment.idProgetto"
                                    :dinamicIds="idsTocheck.attivita2.dinamicIds"
                                    :rootId="idsTocheck.attivita2.rootId"
                                    :tabelloneType="this.tabelloneType"
                                    :tabName="tabSelection"/>                    
                            </collapse>
                            <collapse :name="conf_export_pdf['realizzazione_collapse2'].nameCollapse">
                                <modifiche v-if="loadComplete"
                                    :idTablePdf="conf_export_pdf['realizzazione_collapse2'].id_table_pdf"
                                    :salvaSchedaToStore="this.salvaSchedaToStore"
                                    :incomingData="this.inputData"
                                    :viewOnlyMode="this.viewOnlyMode"
                                    :tabName="tabSelection"/>
                            </collapse>
                            <collapse :name="conf_export_pdf['realizzazione_collapse3'].nameCollapse">
                                <attivitaMon v-if="loadComplete"
                                :idTablePdf="conf_export_pdf['realizzazione_collapse3'].id_table_pdf"
                                :salvaSchedaToStore="this.salvaSchedaToStore"
                                :incomingData="this.inputData"
                                :viewOnlyMode="this.viewOnlyMode"
                                :tabName="tabSelection"/>
                            </collapse>
                            <collapse :name="conf_export_pdf['realizzazione_collapse4'].nameCollapse">
                                <indicatoriImpl v-if="loadComplete"
                                :idTablePdf="conf_export_pdf['realizzazione_collapse4'].id_table_pdf"
                                :salvaSchedaToStore="this.salvaSchedaToStore"
                                :incomingData="this.inputData"
                                :viewOnlyMode="this.viewOnlyMode"
                                :tabName="tabSelection"/>
                            </collapse>
                            <collapse :name="conf_export_pdf['realizzazione_collapse5'].nameCollapse">
                                <valutazioneRis v-if="loadComplete"
                                :idTablePdf="conf_export_pdf['realizzazione_collapse5'].id_table_pdf"
                                :salvaSchedaToStore="this.salvaSchedaToStore"
                                :incomingData="this.inputData"
                                :viewOnlyMode="this.viewOnlyMode"
                                :tabName="tabSelection"/>
                            </collapse>
                            <collapse :name="conf_export_pdf['realizzazione_collapse6'].nameCollapse">
                                <attivitaInfo v-if="loadComplete"
                                :idTablePdf="conf_export_pdf['realizzazione_collapse6'].id_table_pdf"
                                :salvaSchedaToStore="this.salvaSchedaToStore"
                                :incomingData="this.inputData"
                                :viewOnlyMode="this.viewOnlyMode"
                                :tabName="tabSelection"/>
                            </collapse>
                        </b-tab>
                        <b-tab no-body :title="conf_export_pdf['localizzazione_fornitura'].title_page_pdf"  title-item-class="tab1" title-link-class="titleLink"
                            v-on:click="setTabSelection(conf_export_pdf['localizzazione_fornitura'].name)">
                            <localizzazione2
                                :idTablePdf="conf_export_pdf['localizzazione_fornitura'].id_table_pdf"
                                :incomingData="this.inputData"
                                :idScheda="this.inputData.schedaAssessment.idProgetto"
                                :rootId="idsTocheck.localizzazione2.rootId"
                                :tabelloneType="this.tabelloneType"
                                :dinamicIds="idsTocheck.localizzazione2.dinamicIds"
                                :tabName="tabSelection"/>
                        </b-tab>
                        <b-tab v-if="isFinal" no-body :title="conf_export_pdf['pagamenti'].title_page_pdf" title-item-class="tab1" title-link-class="titleLink"
                            v-on:click="setTabSelection(conf_export_pdf['pagamenti'].name)">
                            <pagamenti2Assessment  
                                    :idTablePdf="conf_export_pdf['pagamenti'].id_table_pdf"
                                    :incomingData="this.inputData"
                                    :dinamicIds="idsTocheck.pagamenti2.dinamicIds"
                                    :rootId="idsTocheck.pagamenti2.rootId"
                                    :tabName="tabSelection"
                                    :optionalObj="optionalObj"/>
                        </b-tab>

                        <b-tab :title="conf_export_pdf['invio_mandatory'].title_page_pdf" title-item-class="tab1" title-link-class="titleLink"
                            v-on:click="setTabSelection(conf_export_pdf['invio_mandatory'].name)">
                            <invioAss v-if="loadComplete"
                                :salvaSchedaToStore="this.salvaSchedaToStore" 
                                :incomingData="this.inputData"
                                :idsToCheck="idsTocheck"
                                :tabName="tabSelection"
                                 @buildPdf="generateReport"/>
                        </b-tab>
                    </b-tabs>
            </div>
        </b-overlay>
    </div>
</template>


<script>
import DynamicTable from "@/components/dynamicTable.vue"
import anagraficaProgetto from "./anagraficaProgetto.vue"
import ColorLegend from "@/components/legend.vue"

import { mapGetters } from 'vuex'
import utils from '@/helpers/utils.js'
import tool from '@/helpers/tools.js'
import collapse from "@/components/collapse.vue"
import localizzazione2 from "@/views/monitoring/localizzazione2.vue"
import pagamenti2Assessment from "@/views/monitoring/pagamenti2Assessment.vue"
import attivita2 from "@/views/monitoring/attivita2.vue"
import modifiche from "@/views/assessment/modificheProgetto.vue"
import attivitaMon from "@/views/assessment/attivitaMon.vue"
import indicatoriImpl from "@/views/assessment/indicatoriImpl.vue"
import valutazioneRis from "@/views/assessment/valutazioneRis.vue"
import attivitaInfo from "@/views/assessment/attivitaInfo.vue"
import invioAss from "@/views/assessment/invio.vue"
import notify from "@/helpers/notifications.js"
import helper_pdf from "@/helpers/exportPdf.js"
import endpoints from '@/components/endpoints.vue'

    export default {
        name: 'schedaInterim',
        components: {
            anagraficaProgetto,
            ColorLegend,
            DynamicTable,
            collapse,
            modifiche,
            attivitaMon,
            indicatoriImpl,
            valutazioneRis,
            attivitaInfo,
            localizzazione2,
            attivita2,
            pagamenti2Assessment,
            invioAss
        },
        computed: {
            isFinal: function() {
                return this.$route.name === 'schedaFinal'
            },
            ...mapGetters({
                getScheda: 'assessment/getScheda',
                getTabellone: 'configuration/getTabellone',
                getTabelloneFull: 'configuration/getTabelloneFull',
                getDescBeneficiario: 'announcements/getDescBeneficiario',
            }),
        },

        data() {
            return {
                optionalObj: [],
                conf_export_pdf: {
                    "anagrafica": {
                        id_table_pdf: 'anagrafica_ass',
                        name: "anagrafica",
                        title_page_pdf: 'Anagrafica Progetto',
                        nameCollapse: '',
                    },
                    "realizzazione_collapse1": {
                        id_table_pdf: 'realizzazione_col1',
                        name: "statoavanzamento",
                        title_page_pdf: 'Risultati Realizzazione Progetto',
                        nameCollapse: 'Realizzazione Progetto',
                    },
                    "realizzazione_collapse2": {
                        id_table_pdf: 'realizzazione_col2',
                        name: "",
                        title_page_pdf: 'Risultati Realizzazione Progetto',
                        nameCollapse: 'Modifiche al Progetto',
                    },
                    "realizzazione_collapse3": {
                        id_table_pdf: 'realizzazione_col3',
                        name: "",
                        title_page_pdf: 'Risultati Realizzazione Progetto',
                        nameCollapse: 'Attività di Monitoraggio',
                    },
                    "realizzazione_collapse4": {
                        id_table_pdf: 'realizzazione_col4',
                        name: "",
                        title_page_pdf: 'Risultati Realizzazione Progetto',
                        nameCollapse: 'Implementazione degli indicatori',
                    },
                    "realizzazione_collapse5": {
                        id_table_pdf: 'realizzazione_col5',
                        name: "",
                        title_page_pdf: 'Risultati Realizzazione Progetto',
                        nameCollapse: 'Valutazione dei risultati effettivi',
                    },
                    "realizzazione_collapse6": {
                        id_table_pdf: 'realizzazione_col6',
                        name: "",
                        title_page_pdf: 'Risultati Realizzazione Progetto',
                        nameCollapse: 'Attività di informazione e pubblicità',
                    },
                    "localizzazione_fornitura": {
                        id_table_pdf: 'localizzazzione_ass',
                        name: "localizzazione2",
                        title_page_pdf: 'Localizzazione della fornitura',
                        nameCollapse: '',
                    },
                    "pagamenti": {
                        id_table_pdf: 'pagamenti_assessment',
                        name:"pagamenti2",
                        title_page_pdf: 'Pagamenti',
                        nameCollapse: '',
                        details: true
                    },
                    "invio_mandatory": {
                        id_table_pdf: 'mandatory_ass',
                        name: "invioAss",
                        title_page_pdf: 'Invio',
                        nameCollapse: 'Campi obbligatori non valorizzati',
                    },
                    "invio_note": {
                        id_table_pdf: 'note_ass',
                        name: "",
                        title_page_pdf: 'Invio',
                        nameCollapse: 'Note',
                    },
                    "invio_note_riservate": {
                        id_table_pdf: 'invio_note_riservate',
                        name: '',
                        title_page_pdf: 'Invio',
                        nameCollapse: 'Elenco Note Interne',
                    }
                },
                loadComplete: false,
                idPeriodo: null,
                codiceProgetto: null,
                bannerTable: {
                    header: [
                        "Codice Progetto",
                        "Titolo Progetto",
                        "Obiettivo Specifico",
                        "Obiettivo Nazionale",
                        "Anno Finanziario",
                        "Data Chiusura Progetto",
                    ],
                    rows: [
                        {
                            content: {}
                        }
                    ]
                },
                tabellone: {},
                cronoprog: {},
                title: "",
                inputData: {},
                tabSelection: "",
                disableTabs: true,
                idsTocheck: {
                    attivita2: {
                        rootId: 'attivita',
                        dinamicIds: [
                            "['attivita'][*]['content']['idAttivita']",
                            "['attivita'][*]['content']['titoloAttivita']",
                            "['attivita'][*]['content']['risultatiOttenuti']"
                        ],
                    },
                     pagamenti2: {
                        rootId: 'pagamenti',
                        dinamicIds: [
                            "['contratti'][*]['content']['riferimentoContratto']",
                            "['pagamenti'][*]['content']['riferimento']",
                            "['pagamenti'][*]['content']['importoPagamento']",
                            "['pagamenti'][*]['content']['numeroFattura']",
                            "['pagamenti'][*]['content']['dataFattura']",
                            "['pagamenti'][*]['content']['oggettoFattura']",
                            "['pagamenti'][*]['content']['imponibileFattura']",
                            "['pagamenti'][*]['content']['ivaFattura']",
                            "['pagamenti'][*]['content']['importoFatturaSuISF']",
                            "['pagamenti'][*]['content']['rifDichiarazione']",
                            "['pagamenti'][*]['content']['totaleFattura']",
                            "['pagamenti'][*]['content']['note']"
                        ]
                    },
                    localizzazione2: {
                        rootId: 'inventario',
                        dinamicIds: [
                            "['attivita'][*]['content']['idAttivita']",
                            "['attivita'][*]['content']['titoloAttivita']",
                            "['contratti'][*]['content']['riferimentoContratto']",
                            "['inventario'][*]['content']['numeroSerie']",
                            "['inventario'][*]['content']['beneAcquisito']",
                            "['inventario'][*]['content']['localita']['luogo']",
                            "['inventario'][*]['content']['modifiche']"
                        ]                        
                    }
                },
                tabelloneType: 'cronoprog_assessment'
            };
        },

        methods: {
            generateReport(optPdf) {

                const tabelloneFull = this.getTabelloneFull('cronoprog_assessment');
                const tabelloneCronoprog = tabelloneFull.tabellone;
                 // PAGAMENTI ( LA SOURCE E' SCHEDA CRONOPROG )
                const source_pagamenti = {
                    scheda: this.cronoprog.content,
                    ids_eyes: this.idsTocheck.pagamenti2,
                    mappa: tabelloneCronoprog,
                }
                this.conf_export_pdf['pagamenti'].source = source_pagamenti

                helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
            },

            setTabSelection(name){
                this.tabSelection = name
            },

            caricaScheda: async function() {
                
                if(this.isFinal){
                    const url = endpoints.controlliAmmFase2;
                    await this.$post(url)
                    .then((result) =>{
                       this.optionalObj = result;
                    })
                }

                if(!this.codiceProgetto || !this.idPeriodo) {
                    console.error('scheda con idKey nullo', this.codiceProgetto, this.idPeriodo);
                    return;
                }
                // carico i task camunda
                await this.$store.dispatch('assessment/retrieveTasks');

                const idKey = this.idPeriodo.toString().concat('_').concat(this.codiceProgetto);

                await this.$store.dispatch('assessment/retrieveSchedaAssessment',{
                    key: idKey,
                    isFinal: this.isFinal
                }).catch(
                    error => {
                        notify.error(
                                notify.strings.error, 
                                notify.strings.cantFetchServerData + notify.strings.requestStatus(error.response.status)
                        );
                        throw error;
                    }
                );
                let sc = this.getScheda({codiceProgetto: this.codiceProgetto, idPeriodo: this.idPeriodo});
                // Gestione scheda NoteRiservate
                const userInfo = this.$getUserInfo();
                const group = userInfo.groups[0];
                if( group.includes("MINT")) {
                    await this.$store.dispatch("assessment/retrieveSchedaNoteRiservate", sc.schedaAssessment)
                    .then(() => {
                        sc = this.getScheda({codiceProgetto: this.codiceProgetto, idPeriodo: this.idPeriodo});
                    })
                    .catch(error => {
                        console.log('caricaSchedaNoteRiservate error:', error.message);
                    })
                }

                if(!sc.schedaAssessment.dataEntryConfiguration) {
                    console.log('assessment: aggiungo il tabellone');
                    let conf =  tool.genericFunctions.cloneObject(this.getTabellone('assessment'));
                    let clonedScheda = tool.genericFunctions.cloneObject(sc);
                    for(let key of Object.keys(conf)) {
                        conf[key].data = {};
                    }
                    clonedScheda.schedaAssessment.dataEntryConfiguration = conf;

                    this.salvaSchedaToStore(clonedScheda.schedaAssessment);
                    sc = clonedScheda;
                }

                const idProgetto = sc.schedaAssessment.idProgetto;
                const cronoprogKey = sc.schedaAssessment.cronoprogKey;
                if(!idProgetto) {
                    console.error('Missing idProgetto in schedaAssessment', sc.schedaAssessment, ' Cant load Cronoprog!');
                    return;
                }

                const payload = {
                    idProgetto: idProgetto,
                    cronoprogKey: cronoprogKey,
                    branch: 'assessment'
                };

                await this.getCronoprog(payload).then(
                    result => {
                        console.log("cronoprog: ", result.key);
                        this.cronoprog = result
                    }
                )
                return this.extractData(sc);  
            },

            extractData(schedaCompleta) {
                let result = {};
                if(schedaCompleta){
                    let scheda = schedaCompleta.schedaAssessment;
                    if(!tool.objectHasValue(scheda)) {
                        console.log("extractData(scheda) undefined o null", scheda)
                        return;
                    }
                    result = schedaCompleta;
                    // result.taskList = taskList;

                    let dataChiusura;
                    if(tool.isDate(scheda.dataConclusione)) {
                        dataChiusura = scheda.dataConclusione.getFullYear()+"-"
                            +(scheda.dataConclusione.getMonth()+1)+"-"+scheda.dataConclusione.getDate();
                    } else {
                        dataChiusura = scheda.dataConclusione.substring(0,10);
                    }
                    if (scheda.dataEntryConfiguration 
                        && scheda.dataEntryConfiguration["['dataConclusione']"]
                        && scheda.dataConclusione) {

                        dataChiusura = utils.formatOutput(scheda.dataEntryConfiguration["['dataConclusione']"],
                        scheda.dataConclusione);
                    }
                    // console.log(scheda);
                    // TODO gestire multiobiettivo
                    let obNaz  = "non disponibile";
                    let obSpec = "non disponibile";
                    const obNazArray = scheda.obiettivoNazionale;
                    if (obNazArray && obNazArray.length > 0) {
                        const obiettivoNaz = obNazArray[0];
                        if (obiettivoNaz.codice){
                            obNaz = obiettivoNaz.codice;
                        } else if (obiettivoNaz.titolo){
                            obNaz = obiettivoNaz.titolo;
                        } else if (obiettivoNaz.descrizione){
                            obNaz = obiettivoNaz.descrizione;
                        }

                        const obiettivoSpec = obiettivoNaz.obiettivoSpecifico;
                        if (obiettivoSpec){
                            
                            if (obiettivoSpec.codice){
                                obSpec = obiettivoSpec.codice;
                            } else if (obiettivoSpec.titolo){
                                obSpec = obiettivoSpec.titolo;
                            } else if (obiettivoSpec.descrizione){
                                obSpec = obiettivoSpec.descrizione;
                            }
                        }
                    }

                    this.bannerTable.rows[0].content = {
                        "Codice Progetto": tool.objectHasValue(scheda.codiceProgetto) ? scheda.codiceProgetto : "",
                        "Titolo Progetto": tool.objectHasValue(scheda.titoloProgetto) ? scheda.titoloProgetto : "",
                        "Obiettivo Specifico": obSpec,
                        "Obiettivo Nazionale": obNaz,
                        "Anno Finanziario": tool.objectHasValue(scheda.annoFinanziario) ? scheda.annoFinanziario : "",
                        "Data Chiusura Progetto": dataChiusura
                    }

                    
                

                } else {
                    console.error("Invalid scheda!?");
                }

                return result;
            },

            salvaSchedaToStore(scheda) {
                this.$store.dispatch('assessment/localSaveSchedaAssessment',{
                    codiceProgetto: scheda.codiceProgetto, 
                    idPeriodo: scheda.idPeriodo,
                    scheda: scheda
                });
                this.inputData.schedaAssessment = scheda;    //refresh del dato su tutti i tab alimentati da questo dato
            },

            viewOnlyMode(scheda, actions) {
                if (!scheda || !actions)
                    return false;

                for(let j= 0; j < actions.length; j++) {
                    if(!utils.caniuse(actions[j], scheda.taskList, scheda.schedaAssessment)) {
                        actions.splice(j,1);
                        j--;
                    }
                }
            },

            getCronoprog(payload) {
                return this.$store.dispatch('cronoprog/retrieveScheda', payload);
            },
        },

        mounted() {
            this.disableTabs = true;
            this.$store.commit('assessment/RESET');
            this.idPeriodo = this.$route.params.idPeriodo;
            this.codiceProgetto = this.$route.params.codiceProgetto
            this.title = this.getDescBeneficiario();
            
            Promise.all([
                this.$store.dispatch("configuration/setTabellone", 'assessment'),
                this.$store.dispatch("configuration/setTabellone", this.tabelloneType)
            ]).then(
                config => {
                    this.tabellone = config;
                    this.caricaScheda().then( 
                        result => {
                            if (result){
                                this.inputData = result // set inputData only when it's ready
                                this.disableTabs = false;
                                this.setTabSelection(this.conf_export_pdf['anagrafica'].name);
                                this.loadComplete = true;
                            } else {
                                console.error(">>> schedaAssessment: result of caricaScheda is invalid!")
                            }
                        })
            })
        },
    }
</script>
