<template>
    <div class="app">
    
        <header>

            <div class="app-title"> 
                Ministero dell'Interno - Sistema Informativo ISF 2014-2020
            </div>

            <div class="app-banner">
                <img src="./assets/logo.png" class="app-logo" alt="Logo Ministero dell'Interno"/>
                <div class="right-logo">
                    <img src="./assets/isf_logo.svg" class="app-logo isf-logo" alt="Logo Progetto ISF"/>
                    <img src="./assets/europe_flag.png" class="app-logo" id="app-flag" alt="Bandiera Unione Europea"/>
                </div>
            </div>

            <div class="flag-container">
                <div class="flag-green"></div>
                <div class="flag-red"></div>
            </div>

            <div>
                <navbar :profile="profile" :logoutFromApp="this.logout"/>
            </div>
        </header>    

        <notifications class="notistyle"/>
        <router-view @refreshProfile="doRefreshProfile"/>
        <footer class="app-footer">
            <br/><br/><br/>
            <div class="footer-divider"/>
            <div class="footer-text">
                Tutti i diritti riservati © 2022 - Ministero dell’Interno
                <div class="footer-text float-right">Supporto tecnico: isf-sgi@interno.it</div>
            </div>
            
            <br/>
            <div class="footer-text app-version float-right">
                v{{ this._appVersion }}
                <b-link target="_blank" href="/feconfig/releasenotes.pdf" style="color: white; font-size: 1.4rem">Note di rilascio</b-link>
            </div>
        </footer>    
    </div>
</template>

<script>
    import navbar from "./views/navbar.vue"
    import tools from '@/helpers/tools.js'
    import { mapMutations } from 'vuex';


    export default {
        name: 'App',
        components: {
            navbar
        },
        data() {
            return {
                profile: {}
            }
        },
        mounted() {
            // fix redirect in fase di login alla pagina in cui si era prima di fare logout.
            // questo e' causato dall'introduzione della login tramite keycloak.
            // da rimuovere quando l'app vue sara' in grado di fare fecth dei dati di cruscotto
            // in modo indipendente dalla navigazione dell'utente nella pagina cruscotto.
            // window.addEventListener('keyup', this.refreshData);
            window.addEventListener('keydown', this.refreshData);
            if (!tools.objectHasValue(sessionStorage.getItem('hasBeenLoaded')) || sessionStorage.getItem('hasBeenLoaded') !== "true"){
                this.$router.push({ name: "secure" }); // forzo redirect al cruscotto all'avvio dell'instanza vue
            }
        },
        methods: {
            doRefreshProfile(profile) {
                this.profile = profile;
            },
            resetAll(){
                this.resetMon();
                this.resetAss();
                this.resetStorico();
                this.resetCal();
                this.resetAnnoucements();
                this.resetProgetti();
                this.resetIstruttoria();
                this.resetControlli();
                this.resetIrregolarita();
                this.resetCircuitoFinanziario();
                this.resetDisposizioni();
                this.resetEB();
                this.resetConvenzione();
                this.resetConfiguration();
                this.resetCronoprog();
            },
            refreshData(e) {
                // impedisco il refresh di default: il refresh è consentito solo se 
                // non ci troviamo nella dashboard
                if(e.key === 'F5' || e.key === 'f5' || e.keyCode === 116 ) {
                    if(this.$route.path !== '/cruscotto') {
                        e.preventDefault();
                        this.resetAll();
                        // sessionStorage.removeItem('hasBeenLoaded');
                        this.$router.push({ name: "secure" }); // forzo redirect al cruscotto
                    } else {
                        e.preventDefault();
                    }
                }
            },

             ...mapMutations({
                resetMon: 'monitoraggio/RESET',
                resetAss: 'assessment/RESET',
                resetStorico: 'storicoAzioni/RESET',
                resetCal: 'scadenziario/RESET',
                resetAnnoucements: 'announcements/RESET',
                resetProgetti:"progetto/RESET",
                resetIstruttoria: "istruttoria/RESET",
                resetControlli: "controlli/RESET",
                resetIrregolarita: "irregolarita/RESET",
                resetCircuitoFinanziario: "circuitoFinanziario/RESET",
                resetDisposizioni: "disposizioni/RESET",
                resetEB: "executingBody/RESET",
                resetConvenzione: "convenzione/RESET",
                resetConfiguration: "configuration/RESET",
                resetCronoprog: "cronoprog/RESET"
            }),

            logout() {
                sessionStorage.clear();
                this.resetAll();

                // logout by classic redirect to logout url
                window.location.replace(this._keycloakClient.createLogoutUrl());
            },
        }
    }
</script>
