<template>
    <div>
        <WrapCollapses :config="keyEntityTab"/>
    </div>
</template>
<script>
import WrapCollapses from "@/components/WrapCollapses.vue";
export default {
    name: "complementarietaSostenibilita",
    components: {
        WrapCollapses
    },
    props: {
      name: String,
      keyEntityTab: {
        type: Array,
        required: true
      }
    },
    mounted() {
      this.title = this.name;
    },
    data() {
      return {
        title: '',
        // configWrapCollapses: [
        //   {
        //     title: 'Esperienze pregresse relative alla gestione dei Fondi UE (max 5 elementi)',
        //     nameComponent: 'VerticalComponent',
        //     name: "EsperienzePregresse",
        //     dataComponent: {
        //         inputs: {}, 
        //         dinamicIds:[
        //               "['esperienzePregresse'][*]['inizioSvolgimento']",
        //               "['esperienzePregresse'][*]['fineSvolgimento']",
        //               "['esperienzePregresse'][*]['oggetto']",
        //               "['esperienzePregresse'][*]['importo']",
        //               "['esperienzePregresse'][*]['fondoProgramma']",
        //               "['esperienzePregresse'][*]['descrizioneEsperienza']"
        //           ],
        //       }
        //   },
        //   {
        //     title: 'Complementarietà con progettualità finanziate da altri Strumenti Comunitari e Nazionali',
        //     nameComponent: 'GenericComponent',
        //     tabName: "Complementarietà e sostenibilità",
        //     name: "Complementarietà",
        //     dataComponent: { 
        //         inputs: {},
        //         dinamicIds:[
        //           "['complementarietaAltriStrumenti']",
        //         ],
        //       }
        //   },
        //   {
        //     title: 'Sostenibilità futura del progetto',
        //     nameComponent: 'GenericComponent',
        //     tabName: "Complementarietà e sostenibilità",
        //     name: "Sostenibilità",
        //     dataComponent: { 
        //         inputs: {},
        //         dinamicIds:[
        //           "['sostenibilitaFutura']",
        //         ],
        //       }
        //   },
        // ],
      };
  },
}
</script>