<template>
  <div>
    <h1 class="text-center">Schede Approvate per il Progetto: {{title}}</h1>
    <b-form inline class="m-1" style="display: flex;">
      <label for="filterProgettiInput">
          <h4 class="mx-2">Filtra i risultati:</h4>
      </label>
      <b-form-input
        v-model="filter"
        type="search"
        id="filterProgettiInput"
        placeholder="Digita un filtro" />
      <b-button
        id="filterProgettiButton"
        class="mx-1"
        :disabled="!filter"
        @click="filter = ''">
        Cancella
      </b-button>
    </b-form>
    <b-table
      :busy="isBusy"
      primary-key="id"
      emptyText="Nessun dato disponibile"
      sort-desc
      :filter="filter"
      emptyFilteredText="Nessun dato disponibile"
      :items="tabledata.rows"
      :fields="tabledata.header"
      show-empty
      hover
      responsive>
    <template v-slot:table-busy>
      <div class="text-center">
        <strong>Lista in Caricamento...</strong>
      </div>
    </template>
    <template v-slot:cell(Azioni)="data">
        <b-button
          :id="namingIdIcon(x, data.index + 1)"
          :disabled="rowClicked"
          variant="outline-primary"
          size="sm"
          :key="x"
          :title="'Visualizza Progetto'"
          v-for="x in data.value"
          @click="doAction(x, data)">
          <fa-icon
            v-if="x === 'view'"
            :icon="['far', 'eye']"
            class="selector-icons" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
import endpoints from "@/components/endpoints.vue";
import notify from "@/helpers/notifications.js";
import utils from "@/helpers/utils.js";
export default {
  name: "listaProgettiAttuazione",
  data() {
      return {
          title: '',
          idProgetto: null,
          filter: null,
          isBusy: false,
          rowClicked: false,
          tabledata: {
          header: [
          {
              key: "modalitaDiAccesso",
              label: "Modalità",
          },
          {
              key: "titoloProgetto",
              label: "Titolo",
          },
          {
              key: "dataInserimento",
              sortable: true,
              filterByFormatted: true,
              formatter: this.dateFormatter,
              label: "Data Inserimento",
          },
          {
            // 26-08-21: modificato budgetMassimo->costoProgetto
              key: "costoProgetto",
              label: "Costo Progetto",
              formatter: this.currencyFormatter,
          },
          {
              key: "strumentoFinanziario",
          },
          {
              key: "stato",
          },
          {
              key: "codiceProgetto",
          },          
          {
              key: "Azioni",
              thClass: "thst",
          },
          ],
          rows: [],
      }
      }
  },
  mounted() {
    this.idProgetto = this.$route.params.idProgetto;
    this.extractData();
  },
  methods: {
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    currencyFormatter(value) {
      if (value == undefined || value == null) return null;
      return utils.formatCurrency(value);
    },
    extractData: async function() {
      this.isBusy = true;
      const url = endpoints.getAllProjects_in_Attuazione(this.idProgetto);
      this.$get(url).then((result) => {
        let listProjects = result;
        let cod;
        listProjects.forEach(
          (element) => {
            const info = element.infoProgetto;
            cod = info.codiceProgetto
            let newRow = {
              modalitaDiAccesso: info.modalitaDiAccesso,
              titoloProgetto: info.titoloProgetto,
              dataInserimento: info.dataInserimento,
              // 26-08-21: modificato budgetMassimo->costoProgetto
              costoProgetto: info.costoProgetto,
              strumentoFinanziario: info.strumentoFinanziario,
              stato: info.stato,
              codiceProgetto: info.codiceProgetto != null ?  info.codiceProgetto : "N/D",
              //setto l'unica azione possibile
              Azioni: ['view'],
              idSchedaProgetto: info.idProgetto,
              // idVersione: info.idVersione,
              // carico: element.taskInfo.taskDefinitionKey,
              keyProgetto: info.keyProgetto
            };
            this.tabledata.rows.push(newRow);
            
          },
          (error) => {
            notify.error(
              notify.strings.error,
              notify.strings.internalErrorPleaseReportAction
            );
            throw error;
          }
        );
        this.title = cod ? cod : this.idProgetto;
        this.isBusy = false;
      },
      (error) => {
        this.isBusy = false;
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        console.error("errore caricamento lista",error);
      });
    },
    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      const key = data.item.keyProgetto;
      const keySplit = key.split('_v');
      const idProgetto = keySplit[0]
      const versione = keySplit[1];
      switch (action) {
        case "view":
          this.$router.push({
            name: "schedaProgettoAttuazione",
            params: { idProgetto: idProgetto, versione: versione},
          });
          break;
        default:
          console.error("Unknown action!", action);
          break;
      }
    }
  }
}
</script>