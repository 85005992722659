<template scope="props">
  <div id="elencoProgetti" class="elencoProgetti">
    <div class="table-title">
      Scheda Monitoraggio - Elenco Progetti
      <span v-if="descBeneficiario !== ''"> {{ descBeneficiario }}</span>
    </div>
    <b-form inline class="m-1" style="display: flex">
      <label for="filterProgettiInput"><h4 class="mx-2">Filtra i risultati:</h4>
      </label>
      <b-form-input
        v-model="filter"
        type="search"
        id="filterMonitoraggioInput"
        placeholder="Digita un filtro"/>
      <b-button
        id="filterMonitraggioButton"
        class="mx-1"
        :disabled="!filter"
        @click="filter = ''"
        >Cancella
      </b-button>
    </b-form>

    <b-table
      :busy="isBusy"
      id="elencoProgettiTable"
      primary-key="Codice"
      hover
      emptyText="Nessun dato disponibile"
      :items="tabledata.rows"
      :fields="tabledata.header"
      :sort-by="sortBy"
      sort-asc
      :filter="filter"
      emptyFilteredText="Nessun dato disponibile"
      :filter-included-fields="getFilterFields()"
      show-empty
      responsive>
      <template v-slot:cell(Azioni)="data">
        <b-button
          variant="outline-primary"
          :key="x"
          v-for="x in data.value"
          size="sm"
          v-on:click="doAction(x, data.item.Codice)">
          <fa-icon v-if="x === 'detail'" :icon="['fas', 'folder']" class="selector-icons"/>
          <fa-icon v-if="x === 'edit'" :icon="['far', 'edit']" class="selector-icons"/>
          <fa-icon v-if="x === 'view'" :icon="['far', 'eye']" class="selector-icons"/>
          <fa-icon v-if="x === 'claim'" :icon="['far', 'hand-paper']" class="selector-icons"/>
          <fa-icon v-if="x === 'unclaim'" :icon="['fas', 'times-circle']" class="selector-icons"/>
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="avvioMonitoraggio"
      centered
      size="lg"
      ok-variant="success"
      ok-title="Avvia monitoraggio"
      cancel-title="Annulla"
      @ok="startMonitoraggio()"
    >
      <template v-slot:modal-header
        ><h4><strong>Avvio monitoraggio</strong></h4></template
      >
      <p class="mb-4">
        Si sta per avviare un nuovo monitoraggio.
        <br />
        Non è possibile interrompere un monitoraggio una volta avviato.
      </p>

      <p class="mb-4">Si desidera procedere?</p>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js";
import endpoints from "@/components/endpoints.vue";
import schedeUtils from "@/helpers/schedeUtils.js";

export default {
  name: "selettore",
  computed: {
    ...mapGetters({
      getTaskList: "monitoraggio/getTaskList",
      getTaskId: "monitoraggio/getTaskId",
      getScheda: "monitoraggio/getScheda",
      getDescBeneficiario: 'announcements/getDescBeneficiario'
    }),
  },
  components: {},
  data() {
    return {
      monitOptions: [],
      selectedCodiceProgetto: null,
      descBeneficiario: "",
      progetti: {},
      isBusy: false,
      sortBy: "Codice",
      filter: null,
      tabledata: {
        header: [
			{
                key: 'Codice',
                sortDirection: 'asc',
                sortable: true,
            },
            { 
                key: 'Titolo',
                sortable: true,
            },
            { 
                key: 'Periodo',
                sortable: true,
            },
            {
              key:"dataFineProgetto",
              label:"Data Fine Progetto",
              filterByFormatted: true,
              formatter: this.dateFormatter,
              sortable: true,
            },
            {
                key: 'Stato',
                sortable: true,
            },
            { 
                key: 'Azioni',
            }
        ],
        rows: [
          {
            Codice: "Codice",
            Titolo: "Titolo",
            Periodo: "Periodo",
            beneficiario: "Beneficiario",
            Stato: "Stato",
          },
        ],
      },
    };
  },

  methods: {
    caricaProgetti: async function () {
      this.isBusy = true;
      await this.$store
        .dispatch("selettore/retrieveElencoProgetti")
        .then((result) => {
          this.progetti = result;
        })
        .catch((error) => {
          console.error(
            "selettore: Errore con codice=" + error.response.status + " body=",
            error.response.data
          );
          notify.error(
            notify.strings.error,
            notify.strings.selettoreMonitoraggioKO
          );
        });
      await this.$store.dispatch("monitoraggio/retrieveTasks");
      this.extractData();
      this.isBusy = false;
    },
    beneficiarioFormatter(value) {
      if (!value) 
        return "Sconosciuto";
      return utils.formatBeneficiari(value);
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },
    doCronoCheckout() {
      const codiceProgetto = this.selectedCodiceProgetto;
      const progetto = this.progetti[codiceProgetto];
      const idPeriodo = parseInt(progetto.idPeriodo);
      // checkout del branch di cronoprog a partire dal trunk
      const dateConstraint =
        schedeUtils.getMonitoraggioDateConstraint(idPeriodo);
      const branch = "monitoraggio";
      const checkoutBody = {
        idProgetto: progetto.idProgetto,
        version: null,
        branch: branch,
        dateConstraint: dateConstraint,
      };
      const url = endpoints.cronoCheckout;
      return this.$post(url, checkoutBody);
    },

    async startMonitoraggio() {
      this.isBusy = true;
      const codiceProgetto = this.selectedCodiceProgetto;
      const progetto = this.progetti[codiceProgetto];
      const idPeriodo = parseInt(progetto.idPeriodo);

      const body = {
        idScheda: {
          value: idPeriodo.toString().concat('_', codiceProgetto, "_v1000")
        },
        strumentoFinanziario: {
          value: progetto.strumentoFinanziario
        }
      }

      const url = endpoints.startProcess("Monitoraggio2");

      /*

      // se il monitoraggio non esisteva già bisogna stabilire da dove partire:
      // 1) da un progetto rimodulato nel trimestre di riferimento, se esiste
      // 2) dal monitoraggio precedente, se non ci sono rimodulazioni recenti
      // 3) dalla scheda progetto in attuazione o rimodulata, se non esistono monitoraggi precedenti
      try {
        const body = await this.loadLastProgetto();
        const cronoprog = await this.doCronoCheckout();
        console.log("cronoprogResult", cronoprog);
        const url = endpoints.saveFirst(
          "schedaMonitoraggio",
          codiceProgetto,
          idPeriodo
        );
        */
        this.$post(url, body)
          .then((result) => {
            console.log("prima scheda salvata, result:", result);
            this.$router.push({
              name: "schedaMonitoraggio",
              params: { codiceProgetto: codiceProgetto, idPeriodo: idPeriodo },
            });
            this.isBusy = false;
          })
          .catch((error) => {
              console.error("errore salvataggio monitoraggio", new Date(), error);
              notify.error(
                notify.strings.error,
                notify.strings.avvioMonitoraggioError
              );
              this.isBusy = false;
          });
      /*} catch (error) {
        console.error(
          "startMonitoraggio: errore caricamento progetto",
          codiceProgetto,
          error
        );
        notify.error(
          notify.strings.error,
          notify.strings.avvioMonitoraggioError
        );
        this.isBusy = false;
      }
      */
    },

    async loadLastProgetto() {
      const codiceProgetto = this.selectedCodiceProgetto;
      const idPeriodo = parseInt(this.progetti[codiceProgetto].idPeriodo);
      // per il monitoraggio di un dato trimestre, controllo le eventuali rimodulazioni
      const result = await this.$get(
        endpoints.getAttuazioneByTrimestre(codiceProgetto, idPeriodo)
      );
      const schedaProgetto = result.schedaProgetto;
      const isFromRimodulazione = result.fromRimodulazione;
      const progetto = schedaProgetto.progetto;
      console.log(
        "trovato progetto",
        progetto.codiceProgetto,
        progetto.idProgetto,
        progetto.idVersione
      );
      const idSchema = "monitoraggio";
      const lastTabellone = await this.$getAll(
        endpoints.retrieveTabellone(idSchema)
      );
      const lastVersion = lastTabellone.headers["document-version"];

      let body = {
        schemaVersione: lastVersion,
        fromRimodulazione: isFromRimodulazione,
        dataEntryConfiguration: lastTabellone.data,
        schedaProgetto: schedaProgetto,
      };
      if (isFromRimodulazione) {
        // se ho trovato una rimodulazione di progetto completata nel trimestre di riferimento allora
        // il monitoraggio parte da questa versione del progetto
        console.log("nuovo monitoraggio da progetto in rimodulazione", result);
      } else {
        // se invece non c'è rimodulazione prendo per intero il monitoraggio precedente
        console.log(
          "nuovo monitoraggio dal precedente, oppure nuovo del tutto"
        );
      }

      return body;
    },
    doAction(actionToDo, codiceProgetto) {
      this.selectedCodiceProgetto = codiceProgetto;
      const progetto = this.progetti[codiceProgetto];
      const assente = progetto.assente;
      const idPeriodo = parseInt(progetto.idPeriodo);

      let id;
      switch (actionToDo) {
        case "detail":
          this.$router.push({
            name: "archivioMonitoraggio",
            params: { codiceProgetto: codiceProgetto },
          });
          break;
        case "edit":
          if (assente) {
            // se il monitoraggio non è ancora stato avviato, apro la modale
            console.log("monitoraggio assente per:", codiceProgetto, idPeriodo);
            this.$bvModal.show("avvioMonitoraggio");
          } else {
            // altrimenti si fa un redirect immediato alla scheda monitoraggio esistente
            console.log("redirect al monitoraggio", codiceProgetto, idPeriodo);
            this.$router.push({
              name: "schedaMonitoraggio",
              params: { codiceProgetto: codiceProgetto, idPeriodo: idPeriodo },
            });
          }
          break;
        case "view": // same result for edit and view
          this.$router.push({
            name: "schedaMonitoraggio",
            params: { codiceProgetto: codiceProgetto, idPeriodo: idPeriodo },
          });
          break;
        case "claim":
          id = this.getTaskId(codiceProgetto, idPeriodo);
          this.claimTask(id, codiceProgetto);
          break;
        case "unclaim":
          id = this.getTaskId(codiceProgetto, idPeriodo);
          this.unclaimTask(id, codiceProgetto);
          break;
        default:
          console.error("unknown action: " + actionToDo);
          break;
      }
    },

    async refreshTask(id, codiceProgetto) {
      let res = await this.$store
        .dispatch("monitoraggio/retrieveSingleTask", id)
        .catch((error) => {
          notify.error(
            notify.strings.error,
            notify.strings.operationError("Caricamento scheda")
          );
          this.isBusy = false;
          throw error;
        });
      let index = this.fromCodiceProgettoToIndex(codiceProgetto);
      if (res && index != null)
        this.tabledata.rows[index].Azioni = this.setAction(
          this.progetti[codiceProgetto]
        );
      // refresh icons
      else {
        console.error(
          "error in refreshTask for " + id + " at position " + index
        );
      }
      this.isBusy = false;
    },

    async claimTask(id, codiceProgetto) {
      this.isBusy = true;
      await this.$store
        .dispatch("monitoraggio/claimTask", id)
        .catch((error) => {
          notify.error(
            notify.strings.error,
            notify.strings.operationError("Acquisizione incarico")
          );
          this.isBusy = false;
          throw error;
        });
      this.refreshTask(id, codiceProgetto);
    },

    async unclaimTask(id, codiceProgetto) {
      this.isBusy = true;
      await this.$store
        .dispatch("monitoraggio/unclaimTask", id)
        .catch((error) => {
          notify.error(
            notify.strings.error,
            notify.strings.operationError("Rilascio incarico")
          );
          this.isBusy = false;
          throw error;
        });
      this.refreshTask(id, codiceProgetto);
    },

    fromCodiceProgettoToIndex(codiceProgetto) {
      for (let i = 0; i < this.tabledata.rows.length; i++) {
        if (this.tabledata.rows[i].Codice === codiceProgetto) return i;
      }
      console.error("codiceProgetto = '" + codiceProgetto + "' non trovato!?");
      return null;
    },

    setAction(progetto) {
      let azioni = [];
      // let task = null;
      // const scheda = this.getScheda({ codiceProgetto: progetto.codiceProgetto, idPeriodo: progetto.idPeriodo});
      // if(scheda)
      const task = this.getTaskList(
        progetto.codiceProgetto,
        progetto.idPeriodo
      );

      if (utils.caniuse("edit", task, progetto, progetto.history)) {
        if (progetto.concluso && progetto.assente) {
          azioni = [];
        } else if (progetto.concluso) {
          azioni.push("view");
        } else {
          azioni.push("edit");
        }
      } else if (utils.caniuse("view", task, progetto, progetto.history)) {
        azioni.push("view");
      }

      

      if (task) {
        if (utils.caniuse("claim", task, progetto, progetto.history)) {
          azioni = ["claim", 'view'];
        } else if (utils.caniuse("unclaim", task, progetto, progetto.history)) {
          azioni = ["unclaim", 'edit'];
        }
      }

      if (utils.caniuse("detail", task, progetto, progetto.history)) {
        azioni.push("detail");
      }

      return azioni;
    },

    extractData() {
      let userIdGroup = this.$getUserGroupId();
      if(!userIdGroup.includes("Beneficiari")){
        this.tabledata.header.splice(3, 0,{key:"beneficiario", sortable: true, formatter:"beneficiarioFormatter", sortByFormatted: true});
      }
      let rows = [];
      const elenco = Object.values(this.progetti);      

      if (elenco && elenco.length > 0) {
        for (const progetto of elenco) {
          let azione = this.setAction(progetto);
          let row = {
            Codice: progetto.codiceProgetto,
            Titolo: progetto.titolo,
            Periodo: progetto.periodo,
            Stato: progetto.concluso ? "Concluso" : progetto.stato,
            Azioni: azione,
            assente: progetto.assente,
            history: progetto.history,
            beneficiario: progetto.idBeneficiario,
            dataFineProgetto: progetto.dataConclusione
          };
          
          rows.push(row);
        }
      }
      this.tabledata.rows = rows;
    },

    getFilterFields() {
      let filterFields = [];
      for (let item of this.tabledata.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
  },

  mounted() {
    this.caricaProgetti(); //chiama il metodo di caricamento nello store
    this.descBeneficiario = this.getDescBeneficiario();
  },
};
</script>

