<template>
  <div id="cronoProgramma">
    <div class="card">
      <div class="card-body text-right"><h1 class="font-weight-bold">Totale Spese CronoProgramma: {{totaleSpeseCronoProgrammaEuro}}</h1> </div>
    </div>
    <br/>
    <b-button
      class="align-right"
      @click="doAction('add')"
      variant="outline-primary"
      v-if="visibilityAdd() === true">
      <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
      Aggiungi
    </b-button>
    <DynamicTable2Crono :idTablePdf="idTablePdf" :tdata="tabledata" :tabName="name"
                        :doAction="this.doAction" :actions="this.actions"
                        :disableColors="isTableColorDisabled"
                        :sortBy="sortBy"/>
    <b-modal  id="scegliFase"
              ok-variant='success'
              ok-title='Aggiungi'
              cancel-variant='danger'
              cancel-title='Chiudi'
              @ok="addRiga"
              :ok-disabled="!faseInput[0] || !faseInput[1]">
        <template v-slot:modal-title>
            <h3>Aggiungi fase</h3>
        </template>
        <b-form-group id="sceltaFase"
                      label="Tipo Fase:"
                      label-for="selectFase"
                      label-cols="3"
                      label-size="lg">
          <b-select id="selectFase"
                    size="lg"
                    :options="faseEnumValues[0]"
                    v-model="faseInput[0]" />
        </b-form-group>
        <b-form-group id="sceltaAttivita"
                      label="Id Attività:"
                      label-for="selectAttivita"
                      label-cols="3"
                      label-size="lg">
          <b-select id="selectAttivita"
                    size="lg"
                    :options="faseEnumValues[1]"
                    v-model="faseInput[1]" />
        </b-form-group>
    </b-modal>
    <modal :hidden="!showModal" :buttons="this.buttons" @close="showModal = false" >
      <div class="row-title" slot="header">{{modalHeader}}</div>
      <div slot="body">
        <DynamicTable2 :tdata="this.modalData" :disableColors="isTableColorDisabled"/>
        <br/>
        <div class="row-title">Dettaglio Spese</div>
        <DynamicTable2 :tdata="this.modalData2" :disableColors="isTableColorDisabled"/>
      </div>
    </modal>
    <b-modal
      :id="'modalForm1-' + this.name"
      size="lg"
      scrollable centered
      dialog-class="modal1-content"
      content-class="modal1-content"
      hide-footer>
      <template v-slot:modal-title>
        <h3>Modifica</h3>
      </template>
        <modalForm
                :fdata="this.editFormData"
                :sch="this.getScheda()"
                :buttons="this.buttons"
                :parentName="name"
                :cfg="mappa"
                @salvaRecord="aggiornaRiga"
                @changeRows="changeRows">
          <div class="row-title" slot="header">{{modalHeader}}</div>
        </modalForm>
        
    </b-modal>
    <div class="card">
      <div class="card-body text-right"><h1 class="font-weight-bold">Totale Spese CronoProgramma: {{totaleSpeseCronoProgrammaEuro}}</h1> </div>
    </div>
    <br/>
  </div>
</template>


<script>
import DynamicTable2 from "@/components/dynamicTable2.vue";
import DynamicTable2Crono from "@/components/DynamicTableCronoProgetti.vue";
import modal from "@/components/modal.vue";
import modalForm from "@/components/modalForm2level.vue";
import tool from '@/helpers/tools.js'
import utils from '@/helpers/utils.js'
import mapUtils from '@/helpers/mapUtils.js'
import { JSONPath as jpath } from 'jsonpath-plus';
import validation from '@/helpers/validations.js'
import notify from "@/helpers/notifications.js"
import { mapGetters } from "vuex";
import { addHours } from 'date-fns';


export default {
  components: {
    DynamicTable2,
    DynamicTable2Crono,
    modal,
    modalForm
  },

  props: {
    tabName: {
      type: String,
      value: ''
    },
    idTablePdf: { type: String, default: () => '' },
    wakeUp: Boolean
  },

  watch: {
    wakeUp: function() {
      this.extractData()
    },
    tabName: function() {
      if (this.itsMyTurn()) {
        this.extractData()
        this.visibilityAdd()
      } 
    },
  },
  computed: {
    ...mapGetters({
      getTabelloneProgetto: "configuration/getTabellone",
      getSchedaProgetto: "progetto/getSchedaProgetto",
      getSchedaComplete: "progetto/getScheda",
    }),
    
    
  },

  data() {
    return {
      isTableColorDisabled: false,
      sortBy: '',
      name: 'Cronoprogramma',
      totaleSpeseCronoProgrammaEuro:"",
      faseInput: [],
      faseEnumValues: [[], []],
      editFormData: {},
      showModal: false,
      modalData: {},
      modalData2: {},
      modalHeader: "",
      actions: ['edit', 'view', 'delete'],  //array di tutte le azioni possibili, ristrette durante l'extractData
      buttons: [
        {
          name: 'Salva',
          action: 'salvaRecord',
          show: true,
          param: "edit"
        },
        {
          name: 'Conferma',
          action: 'eliminaRecord',
          show: true,
          param: "delete"
        },
        {
          name: 'Aggiungi',
          action: 'aggiungiRecord',
          show: true,
          param: "add"
        }
      ],
      tabledata: {
        header: [],
        rows: [],
        colors: false    //attributo per gestire le icone colorate
      },
      colIds1: [
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']",
        "['progetto']['cronoProgramma'][*]['idAttivita']",
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['descrizione']",
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataInizio']",
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataFine']",
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['totaleSpese']",
      ],
      colIds2: [
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['spesa']",
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['da']",
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['a']"
      ],
      tagColIds: [
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']",
        "['progetto']['cronoProgramma'][*]['idAttivita']",
      ],
      position: [],
      faseId: "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']",
      attivitaId: "['progetto']['cronoProgramma'][*]['idAttivita']",
      mappa: {}
    };
  },

  methods: {
    visibilityAdd() {

      const schedaComplete = this.getSchedaComplete({ idSchedaProgetto: this.$route.params.idProgetto});

      const scheda =  schedaComplete.schedaProgetto;
      const activities = jpath({resultType: 'all'}, "$" + this.attivitaId, scheda);

      // se non ci sono attività non mostro il bottone
      if(!activities || activities.length === 0)
        return false;
      // se ci sono attività e non c'è modo di sapere se sono eliminate
      // allora mostro il bottone (se mi è concessa l'azione add)
      const actions = this.$projectGetActions(schedaComplete);

      const config = scheda.dataEntryConfiguration;
      if(!config){
        //(console.log("config non esiste");
        return actions.indexOf('add') !== -1;
      }
      
      // altrimenti controllo:
      // se ci sono attività non eliminate
      for(let item of activities) {
        const path = item.path.substr(1);
        
        // alla prima attività non eliminata mostro il bottone
        if(config[path] && !config[path].data.deleted){
          return actions.indexOf('add') !== -1;
        }
      }

      return actions.indexOf('add') !== -1;
    },
    getScheda() {
      return this.getSchedaProgetto({idSchedaProgetto: this.$route.params.idProgetto});
    },
    itsMyTurn: function(){
      return (this.tabName === "cronoprogramma")
    },
    addRiga() {
      let item = {content: {}, content2: {}, conf: {}};
      const scheda = this.getScheda();
      const faseIndex = this.faseInput[0];
      const activities = jpath("$['progetto']['cronoProgramma'][*]", scheda);
      let attivitaIndex = -1;

      if(activities && activities.length > 0) {
        const attivitaFilter = activities.filter(item1 => {
          return item1.idAttivita === this.faseInput[1];
        })
        if(attivitaFilter.length === 1){
          //attivitaIndex = attivitaFilter[0].progressivo;
          //N.B: le attività non hanno più un progressivo, quindi mi baso sull'indice dell'array
          attivitaIndex = activities.indexOf(attivitaFilter[0]) + 1
        }
        if(!attivitaIndex){
          console.error("Error in progressivo attività: ", attivitaIndex)
          notify.error(notify.strings.error, notify.strings.operationError('Inserimento nuova fase'));
          return;
        } 
      }

      if(attivitaIndex < 1) {
        console.error('error in idAttivita select', this.faseInput, attivitaIndex);
        notify.error(notify.strings.error, notify.strings.operationError('Inserimento nuova fase'));
        return;
      }

      const temp = this.faseId.replace(/\*/, attivitaIndex - 1);
      let newFaseIndex = 0;
      const values = jpath('$' + temp, scheda);
      if(values && values.length > 0) {
        newFaseIndex = values.length;
      }
      for(const starId of this.colIds1) {
        let id = starId.replace(/\*/, attivitaIndex - 1);
        
        if(id.match(/\*/))
          id = id.replace(/\*/, newFaseIndex);
        if(starId === this.faseId) {
          item.content[id] = this.faseEnumValues[0][faseIndex].text;
        } else if(starId === this.attivitaId) {
          item.content[id] = this.faseInput[1];
        } else {
          item.content[id] = null;
        }

        item.conf[id] = mapUtils.cloneConf(id, this.mappa); // clona la configurazione
        // evita di aggiungere 'added' all'idAttivita, altrimenti è come se fosse stata 
        // aggiunta una nuova attività
        if(id.includes('dettaglioFase')) {
          item.conf[id].data.added = true;
          item.conf[id].data.edited = true;
        }
      }
      this.doAction('edit', item);
    },
    changeRows(dataFromModal) {
        let indexes;
        let defaultPath;
        let row;

        const actionFromChange = dataFromModal;
        const index = parseInt(actionFromChange.index);
        const regex = /\b\d+\b(?!.*\b\d+\b)/;
        switch(actionFromChange.action) {
          case 'delete':
            row = actionFromChange.data.content2;
            
            for(let key of Object.keys(row)) {
                const lastElementIndex = parseInt(regex.exec(key)[0]);
                if(lastElementIndex !== index) {
                  continue;
                }
                actionFromChange.data.conf[key].data.deleted = true;
                actionFromChange.data.conf[key].data.edited = true;
                actionFromChange.data.conf[key].data.oldValue = actionFromChange.data.content2[key].value;
                // costruisce le informazioni aggiuntive per il tab invio
                if(!actionFromChange.data.conf[key].data.editTag) {
                  actionFromChange.data.conf[key].data.editTag = mapUtils.createEditTag1(this.mappa, key, this.tabName); // null=titolo del collapse
                }
            }
            break;
          case 'add':
            defaultPath = Object.keys(actionFromChange.data.content)[0]; // WARNING assicurarsi di prendere il path più interno possibile
            indexes = [];
            for(let m of defaultPath.match(/(\[(\d+)\])/g)) { // costruisce un array di indici da sostituire
              indexes.push(m.replace('[','').replace(']',''));
            }
            
            for(let path of this.colIds2) { // per ogni campo da aggiungere nella nuova riga, sostituisce gli indici trovati
              let iof = path.indexOf('*');
              let jj = 0;
              while(iof !== -1 && jj < indexes.length) {
                let index1 = indexes[jj];
                path = path.replace("*", index1);
                jj++;
                iof = path.indexOf('*');
              }
              let newId = path.replace("*", actionFromChange.index); // sostituisce l'ultimo indice con quello della nuova riga
              // console.log(newId, actionFromChange);
              let newConf = mapUtils.cloneConf(newId, this.mappa); // clona la configurazione
              newConf.data.added = true;
              newConf.data.edited = true;
              newConf.data.editTag ="";
              // costruisce le informazioni aggiuntive per il tab invio
              for(let path1 of this.tagColIds) {
                let iof1 = path1.indexOf('*');
                let jj1 = 0;
                while(iof1 !== -1 && jj1 < indexes.length) {
                  let index2 = indexes[jj1];
                  path1 = path1.replace("*", index2);
                  jj1++;
                  iof1 = path1.indexOf('*');
                }

                newConf.data.editTag = mapUtils.createEditTag1(this.mappa, newId, this.tabName)
              }
              actionFromChange.data.conf[newId] = newConf;
              let newValue = mapUtils.cloneValue(newId, newConf); // clona il valore
              actionFromChange.data.content2[newId] = newValue;
            }
            break;
          default:
            console.log('azione non gestita', actionFromChange.action);
            break;
        }
        // BUG #13735: workaround per evitare che le date indietreggino di un giorno:
        // se le date sono state modificate, allora bootstrap vue le fa diventare di tipo Date
        // in questo caso aggiungo 12 ore per essere sicuro che il giorno non diminuisca
        [...Object.entries(actionFromChange.data.content),
          ... Object.entries(actionFromChange.data.content2)].forEach(item => {
          if(item[1].value instanceof Date) {
            item[1].value = addHours(item[1].value, 12);
          }
        });

        this.editFormData = actionFromChange.data;
    },
    // checkChangeDuration(actionFromModal) {
    //   let currentDuration
    //   let idDurataProgetto = "['progetto']['durataProgettoMesi']"
    //   let mapElement = this.mappa[idDurataProgetto];
    //   let scheda = this.getScheda()
    //   currentDuration = calculation.calculateValue(mapElement, scheda, idDurataProgetto, actionFromModal); 
    //   if(currentDuration) {
    //     this.salvaSchedaToStore(scheda);
    //   }
    //   this.$emit('changeDuration')
    // },
    aggiornaRiga(actionFromModal) {
      this.$bvModal.hide('modalForm1-' + this.name);
      const incomingData = this.getScheda();
      
      let clonedScheda = tool.genericFunctions.cloneObject(incomingData);

      for(let [key, field] of Object.entries(actionFromModal.content)) {
        let updatedConfiguration = tool.genericFunctions.cloneObject(actionFromModal.conf[key]);

        // controllo sul main tab
        let mainTab = updatedConfiguration.config.tab;
        let currentTab = this.$parent.title;
        if(currentTab 
          && currentTab!='Cronoprogramma'         // escludo questo tab perchè attinge a dati inseriti altrove
          && mainTab && currentTab !== mainTab) { // WARNING i nomi dei tab devono essere consistenti col tabellone
          console.log('skip edit (not in main tab)) for field: ', updatedConfiguration.config.label);
          continue;
        }
        // let updatedValue = utils.unformatOutput(updatedConfiguration, field.value);
        let updatedValue = field.value;


        if(updatedValue == undefined) {
          // nessuna azione se il nuovo valore è undefined
          continue;
        }

        let parent = jpath({resultType: 'parent'}, '$'+key, clonedScheda)[0];
        let fieldToUpdate;
        if(parent == undefined) {
          const retVal = mapUtils.createElementFromScratch(key, clonedScheda, this.mappa);
          parent = retVal.parent;
          fieldToUpdate = retVal.fieldName;
          clonedScheda = retVal.scheda;
        }
        else {
          fieldToUpdate = jpath('$'+key+'~', clonedScheda)[0];
        }

        if(!clonedScheda.dataEntryConfiguration) {
          clonedScheda.dataEntryConfiguration = {};
        }

        if(!updatedConfiguration.data.editTag)
          updatedConfiguration.data.editTag = mapUtils.createEditTag1(this.mappa, key, this.tabName); // null=nome del collapse


        if(parent[fieldToUpdate] !== updatedValue && 
          !((parent[fieldToUpdate] == null || parent[fieldToUpdate] == undefined) && updatedValue === '')
        ){ // se il campo è stato modificato (tranne se settiamo a stringa vuota un campo null o undefined)
          // allora controllo la configurazione
          if(!updatedConfiguration.data.edited) {
            updatedConfiguration.data.edited = true; // se è la prima modifica setto il flag edited e salvo il valore originale
            updatedConfiguration.data.oldValue = parent[fieldToUpdate];
          } else {
            if(updatedValue == updatedConfiguration.data.oldValue || // se è stato riportato il valore iniziale (anche se nullo)
              ((updatedConfiguration.data.oldValue == null || updatedConfiguration.data.oldValue == undefined) && updatedValue === '')
            ) {// WARNING: solo == e non === altrimenti problemi di typeof
              // se il valore era già stato modificato
              // e stiamo riportando il valore all'originale
              // ripristino i valori all'originale e cancello la nota
              updatedConfiguration.data.oldValue = null;
              updatedConfiguration.data.edited = false;          
              updatedConfiguration.data.note = '';
            }
          }

          parent[fieldToUpdate] = updatedValue; // setto il nuovo valore

          // check substancial
          if(updatedConfiguration.config.canBeSubstancial) {
            updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedScheda);
          }

          // if(!removeConf) {// se l'elemento di configurazione è presente
            delete updatedConfiguration.config; // rimuovo la parte static di config
            clonedScheda.dataEntryConfiguration[key] = updatedConfiguration; // configurazione aggiornata
          // }
        } else if(updatedConfiguration.data.added) {
        
          
            // se il campo è stato aggiunto, aggiungo alla configurazione solo
            // la parte variabile(data)
            clonedScheda.dataEntryConfiguration[key] = {};
            clonedScheda.dataEntryConfiguration[key].data = updatedConfiguration.data;
        } else { // aggiorno comunque la nota a prescindere dalla modifica
          if(!clonedScheda.dataEntryConfiguration[key]) {
            clonedScheda.dataEntryConfiguration[key] = {data: {}};
          }
          clonedScheda.dataEntryConfiguration[key].data.note = updatedConfiguration.data.note;
        }
      }

      // gestione spese
      // for(let i in actionFromModal.content2.rows) {
        // let row = actionFromModal.content2.rows[i];
      
      for(let [key, field] of Object.entries(actionFromModal.content2)) {
          let removeConf = false;
          let updatedConfiguration = tool.genericFunctions.cloneObject(actionFromModal.conf[key]);
          if(updatedConfiguration.config.readonly)
            continue;
  
          // let updatedValue = utils.unformatOutput(updatedConfiguration, field.value);
          let updatedValue = field.value;
          // commentato perchè questa ottimizzazione impedisce 
          // di trattare correttamente le spese eliminate
          // che inizialmente erano undefined
          // if(updatedValue == undefined) {
          //   // nessuna azione se il nuovo valore è undefined
          //   continue;
          // }
          let fieldToUpdate2;
          let parent = jpath({resultType: 'parent'}, '$'+key, clonedScheda)[0];
          if(parent == undefined) {
            const retVal = mapUtils.createElementFromScratch(key, clonedScheda, this.mappa);
            parent = retVal.parent;
            fieldToUpdate2 = retVal.fieldName;
            clonedScheda = retVal.scheda;
          } else {
            fieldToUpdate2 = jpath('$'+key+'~', clonedScheda)[0];
          }

          if(updatedConfiguration.data.deleted) {
            delete updatedConfiguration.config; // rimuovo la parte static di config
            clonedScheda.dataEntryConfiguration[key] = updatedConfiguration; // per le righe cancellate aggiorno solo la configurazione
            continue;
          }
          
          

          if(parent[fieldToUpdate2] !== updatedValue && 
              !((parent[fieldToUpdate2] == null || parent[fieldToUpdate2] == undefined) && updatedValue === '')
            ){ // se il campo è stato modificato (tranne se settiamo a stringa vuota un campo null o undefined)
            // allora controllo la configurazione
            // check substancial
            if(updatedConfiguration.config.canBeSubstancial) {
              clonedScheda.dataEntryConfiguration[key] = updatedConfiguration; // configurazione prima del controllo substancial
              updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedScheda);
            }

            if(!updatedConfiguration.data.edited) {
              updatedConfiguration.data.edited = true; // se è la prima modifica setto il flag edited e salvo il valore originale
              updatedConfiguration.data.oldValue = parent[fieldToUpdate2];
              updatedConfiguration.data.editTag = mapUtils.createEditTag1(this.mappa, key, this.tabName);
            } else {
              if(updatedValue == updatedConfiguration.data.oldValue || // se è stato riportato il valore iniziale (anche se nullo)
                ((updatedConfiguration.data.oldValue == null || updatedConfiguration.data.oldValue == undefined) && updatedValue === '')
              ) {// WARNING: solo == e non === altrimenti problemi di typeof
                
                // se il valore era già stato modificato
                // e stiamo riportando il valore all'originale
                // ripristino i valori all'originale e cancello la nota
                updatedConfiguration.data.oldValue = null;
                updatedConfiguration.data.edited = false;          
                updatedConfiguration.data.note = '';
                //  se l'elemento era parte di un array, rimuovo la sua configurazione 
                if(updatedConfiguration.config.path.match(/(\[(\d+)\])/)) {
                    // non aggiorno la configurazione
                    removeConf = true;
                    // rimuovo la configurazione dalla scheda di monitoraggio
                    delete clonedScheda.dataEntryConfiguration[key];
                }
              }
            }
            parent[fieldToUpdate2] = updatedValue; // setto il nuovo valore
            if(!removeConf) {// se l'elemento è presente
              delete updatedConfiguration.config; // rimuovo la parte static di config
              clonedScheda.dataEntryConfiguration[key] = updatedConfiguration; // configurazione aggiornata
            }
          }
          else {
            // aggiorno comunque la nota a prescindere dalla modifica
            // if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
            if(!clonedScheda.dataEntryConfiguration[key]) {
              clonedScheda.dataEntryConfiguration[key] = clonedScheda.dataEntryConfiguration[key] = {data: {}};
            }
            clonedScheda.dataEntryConfiguration[key].data.note = updatedConfiguration.data.note;
          }
        }
      // }
      this.salvaSchedaToStore(clonedScheda);
      // Controllo se modificando una qualsiasi fase (TAB: Cronoprogramma)
      // ed aggiornando la scheda, sto di fatto modificando la durata del progetto
      // if(Object.keys(actionFromModal).length !== 0 &&
      //   actionFromModal.conf && Object.keys(actionFromModal.conf).length !== 0 &&
      //   Object.keys(actionFromModal.conf)[0].indexOf('dettaglioFase') > -1) {
      //   this.checkChangeDuration(actionFromModal)
      // }
      this.extractData();
    },
    convertiRiga(inputItem) {
      const data = {
        header : ['Campo', 'Valore'],
        rows: []
      };
      if(!inputItem){
        console.error("convertiRiga: NULL param passed!?");
        return data;
      }
      for(let keyv in inputItem.content) {
          const mapElement = inputItem.conf[keyv];
          let row = {
              content: {
                Campo : mapElement.config.label,
                Valore : inputItem.content[keyv]
              },
              conf: {
                [keyv]: mapElement
              }
          };
          data.rows.push(row);
      }
      return data;
    },
    doAction(actionToDo, item) {
      // console.log(actionToDo, index, item);      
      let delMsg = "Si sta per eliminare definitivamente un elemento. "
      delMsg = delMsg.concat("L'elemento eliminato non comparirà tra le modifiche e l'operazione non è reversibile. Procedere?");
        switch (actionToDo) {
          case 'add':
            this.$bvModal.show("scegliFase");
            break;
          case 'delete':  
            this.$bvModal.msgBoxConfirm(delMsg, {
            title: "Conferma eliminazione",
            okVariant: 'success',
            cancelVariant: 'danger',
            okTitle: 'Elimina',
            cancelTitle: 'Chiudi',
            titleClass: 'card-title',
            dialogClass: 'card-body'
            }) 
            .then(result => {
              if(result) {
                this.deleteRow(item)
              }
            })
            
            break;
          case 'view':
            this.modalData = this.convertiRiga(item);
            this.modalData2 = this.extractSpese2(item);

            this.showModal = true;
            this.modalHeader = "Visualizza Cronoprogramma";
            this.buttons[0].show=false;
            this.buttons[1].show=false;
            this.buttons[2].show=false;
            break;
          case 'edit':
            {
              let clonedCont2 = tool.genericFunctions.cloneObject(item.content2);
              let clonedConf = tool.genericFunctions.cloneObject(item.conf);
              this.editFormData = {content:{}, content2:clonedCont2, conf: clonedConf};
              for(let [key, field] of Object.entries(item.content)) {
                let value = tool.genericFunctions.cloneObject(field);

                let obj = {'value': value, 'id': key};
                this.editFormData.content[key] = obj;
              }
              this.modalHeader = "Modifica Cronoprogramma";
              this.buttons[0].show=true;
              this.buttons[1].show=false;
              this.buttons[2].show=false;
              this.$bvModal.show('modalForm1-' + this.name);
            }
            break; 
        }
    },
    deleteRow(item){
      let scheda1 = tool.genericFunctions.cloneObject(this.getScheda());
      if(!scheda1.dataEntryConfiguration) {
        scheda1.dataEntryConfiguration = {};
      }
      for(const id in item.conf) {
        let idWithStars = mapUtils.getGenericArrayKey(id);
        const cfg = this.mappa[idWithStars];
        // if(idWithStars === this.attivitaId)
        // skip dei campi readonly o il cui main tab non è cronoprogramma
        if(cfg && cfg.config && (cfg.config.readonly || cfg.config.tab !== this.name))                 
          continue;      
        if(!scheda1.dataEntryConfiguration[id]) {
          scheda1.dataEntryConfiguration[id] = {
            data: {
              note: item.conf[id].data.note
            }
          };
        }
        let oldValue;
        if(item.content[id])
          oldValue = item.content[id];
        else if(item.content2[id])
          oldValue = item.content2[id].value;

        scheda1.dataEntryConfiguration[id].data.deleted = true;
        scheda1.dataEntryConfiguration[id].data.edited = true;
        scheda1.dataEntryConfiguration[id].data.oldValue = oldValue;

        if(!scheda1.dataEntryConfiguration[id].data.editTag) {
          scheda1.dataEntryConfiguration[id].data.editTag = mapUtils.createEditTag1(this.mappa, id, this.tabName);
          // null=titolo del collapse
        }
      } 
              
      this.salvaSchedaToStore(scheda1); 
      this.extractData();
    },

    setupHeader() {
      // setup header
      this.tabledata.header = [];
      for(const colId of this.colIds1) {
          let mapElement = this.mappa[colId];
          if(!mapElement) {
              console.error('no config, get default one for ', colId);
              mapElement = this.$getDefaultConfig();
              mapElement.config.path = colId;
          }
          // // compute field visibility
          // if(mapElement.config.hiddenRule) {
          //   const hidden = visibility.computeHidden(colId, scheda, mapElement);
          //   if(hidden)
          //     continue;
          // }
          const headerColumn = {
              key: colId,
              label: mapElement.config.label,
              tdAttr: this.tooltip,
              sortable: true,
              formatter: this.formatter,
              tdClass: this.getTdClass
          }
          this.tabledata.header.push(headerColumn);
      }
      this.tabledata.header.push({ key: 'azione', label: 'Azione'});
      this.sortBy = this.tabledata.header[1].key;
    },
    formatter(value, key) {
      const starId = mapUtils.getGenericArrayKey(key);
      const mapElement = this.mappa[starId];
      if(!mapElement) {
        
        return value;
      }
      return utils.formatOutput(mapElement, value);
    },

    extractData() {
      const schedaComplete = this.getSchedaComplete({
        idSchedaProgetto: this.$route.params.idProgetto,
      });
      const inputData =  tool.genericFunctions.cloneObject(schedaComplete.schedaProgetto);
      if(!inputData){
        console.error("cronoProgramma: Invalid schedaProgetto!?", schedaComplete);
        return;
      }

      this.isTableColorDisabled = this.$disableEditAddColors(inputData);

      this.setupHeader();

      let totaleSpeseCronoProgramma = 0;
      this.faseEnumValues[1] = [];
      const results = jpath({resultType: 'all'}, '$' + this.attivitaId, inputData);
      for(const item of results) {
        const id = item.path.replace(/\$/, '');
        // si aggiungono tutte le attività purchè non siano eliminate
        if(inputData.dataEntryConfiguration && inputData.dataEntryConfiguration[id]) {
          const data = inputData.dataEntryConfiguration[id].data;
          if(data.deleted)
            continue;
        }
        this.faseEnumValues[1].push({text: item.value, value: item.value});
      }

      let rows = [];
      //ciclo per "AttivitaProgetto.java"

      for(let j=0; inputData.progetto.cronoProgramma && j< inputData.progetto.cronoProgramma.length; j++){
        let idAtt = inputData.progetto.cronoProgramma[j].idAttivita;
        const titolo = inputData.progetto.cronoProgramma[j].titoloAttivita;

        if(!inputData.progetto.cronoProgramma[j].dettaglioFase)
          continue;

        //ciclo per "AttivitaDettaglioFase.java"
        for(let i=0; i< inputData.progetto.cronoProgramma[j].dettaglioFase.length; i++){

          let item = inputData.progetto.cronoProgramma[j].dettaglioFase[i];
          
          let displayedColIds = [];
          //definisco gli id delle colonne da visualizzare
          displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['fase']");

          displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['idAttivita']");
          displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['titoloAttivita']");
          displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['descrizione']");

          if(inputData.dataEntryConfiguration && inputData.dataEntryConfiguration[displayedColIds[3]]) {
            const data = inputData.dataEntryConfiguration[displayedColIds[3]].data;
            if(data.deleted) {
              continue;
            }
          }


          displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['dataInizio']");
          displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['dataFine']");
          displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['totaleSpese']");
          
          let cont2 = {};
          let spesaTotale = 0;

          if (item.spese && item.spese.length>0){ //se sono presenti spesi inserisco anche le chiavi valorizzate
            for(let s=0; s<item.spese.length; s++){
              let idSpesa = "['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['spese']["+s+"]['spesa']";
              let idDa = "['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['spese']["+s+"]['da']";
              let idA = "['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['spese']["+s+"]['a']";
              displayedColIds.push(idSpesa);
              displayedColIds.push(idDa);
              displayedColIds.push(idA);
            

              cont2[idSpesa] = {'value': item.spese[s].spesa, 'id': idSpesa};
              cont2[idDa] = {'value': item.spese[s].da, 'id': idDa};
              cont2[idA] = {'value': item.spese[s].a, 'id': idA};
              
              let valueSpesa = parseFloat(item.spese[s].spesa);
              
              if(!isNaN(valueSpesa)) { // se il valore numerico è valido
                const config = inputData.dataEntryConfiguration;
                if(!config || !config[idSpesa] || !config[idSpesa].data.deleted) {
                  // se non c'è configurazione per questa spesa o la spesa non è stata eliminata, allora aggiungo al totale
                  spesaTotale += valueSpesa;
                }
              }
            }
          }
          // BUG #10686: aggiornamento totale spese su scheda
          // const formatted_value = utils.formatOutput(mapSpese, spesaTotale);
          inputData.progetto.cronoProgramma[j].dettaglioFase[i].totaleSpese = spesaTotale;

          let myConf = {};
          
          for(const realId of displayedColIds) {
            const starId = mapUtils.getGenericArrayKey(realId);
            let mapElement = this.mappa[starId];
            if(!mapElement) {
              console.error('cronoprogramma: error on map', this.mappa, realId, starId);
              continue;
            }
            mapElement = tool.genericFunctions.cloneObject(mapElement);
            mapElement.config.path = realId;
            let dataEntry;
            if(inputData.dataEntryConfiguration) 
              dataEntry = inputData.dataEntryConfiguration[realId];
            if(!dataEntry) {
              const temp = utils.createField();
              dataEntry = {data: temp.data};
            }
            
            myConf[realId] = {
              config: mapElement.config,
              data: dataEntry.data
            }
          }
          // prendo la colonna "fase" per stabilire se è una fase standard o aggiunta
          let faseEntry = {};
          if(inputData.dataEntryConfiguration) 
            faseEntry = inputData.dataEntryConfiguration[displayedColIds[0]];
          if(!faseEntry)
            faseEntry = {};
          let possibleActions = this.actions;
          
          /* controllo rimosso, permetto sempre a chi ha diritto alla delete di cancellare una fase a prescindere da chi/quando l'abbia inserita
          if(!faseEntry.data || !faseEntry.data.added) {
            possibleActions = this.actions.filter(
              item => {
                return item !== 'delete';
              }
            )
          }
          */

          // invio alla computeActions la conf di "idAttivita" per
          // controllare le attività di progetto eliminate
          if(inputData.dataEntryConfiguration) 
            faseEntry = inputData.dataEntryConfiguration[displayedColIds[1]];
          if(!faseEntry)
            faseEntry = {};
          const actions = mapUtils.computeActions(faseEntry.data, schedaComplete, possibleActions);
          const row = {
            content: {},
            position : [j,i], //servirà dopo per capire quale riga si sta aggiornando 
            content2: cont2,
            conf : myConf,
            azione: actions,
            edited: false,
            deleted: false,
            added: false
          };

          //scrivo i campi con l'ID : valore
          row.content[displayedColIds[0]] = item.fase;
          row.content[displayedColIds[1]] = idAtt;
          row.content[displayedColIds[2]] = titolo;
          row.content[displayedColIds[3]] = item.descrizione;
          row.content[displayedColIds[4]] = item.dataInizio;
          row.content[displayedColIds[5]] = item.dataFine;
          row.content[displayedColIds[6]] = spesaTotale.toFixed(2);
          
          totaleSpeseCronoProgramma += tool.roundFloat2Decs(spesaTotale);

          if(inputData.dataEntryConfiguration) {
            // caso di riga di fase eliminata o aggiunta
            for(let ind = 0; ind < 7; ind++) {
              const key = displayedColIds[ind];
              const mapEl = inputData.dataEntryConfiguration[key];
              
              if(mapEl && (mapEl.data.deleted && !mapEl.data.added)) {
                row.deleted = true;
                break;
              } else if(mapEl && (mapEl.data.added && !mapEl.data.deleted)) {
                row.added = true;
                break;
              }
            }
            // caso di edit di riga, oppure qualunque modifica alle spese
            for(const realId of displayedColIds) {

              const mapEl = inputData.dataEntryConfiguration[realId];
              if(mapEl && mapEl.data.edited) {
                row.edited = true;
              }
            }
          }

          rows.push(row);

          //supponendo che le azioni su una riga siano valide per gli altri componenti, setto anche la variabile globale propagata attraverso le props a dynamicTable2Crono
          this.actions = actions;
        }
      }
      this.totaleSpeseCronoProgrammaEuro = utils.formatCurrency(totaleSpeseCronoProgramma);
      this.tabledata.rows = rows;
      // BUG #10686:aggiornamento del totale delle spese nella scheda sullo store
      this.salvaSchedaToStore(inputData);
    },

    getSpeseArray(returnData) {

      let retValue = [];

      if(!returnData.content2 || !returnData.conf)
        return retValue;

      const regex = /\b\d+\b(?!.*\b\d+\b)/;
      for(const [key, obj] of Object.entries(returnData.content2)) {
        const index = regex.exec(key)[0];

        const conf = returnData.conf[key];
        let deleted = (conf && conf.data.deleted) ? true : false;

        if(!retValue[index])
          retValue.splice(index, 1, {index: index, content: {}, conf: {}, deleted: deleted});
        const label = conf.config.label;
        retValue[index].content[label] = obj.value;
        retValue[index].conf[key] = conf;
      }
      return retValue;
    },

    extractSpese2(riga){
      if (!riga || !riga.content2){ // || !riga.content2.rows
        console.error("extractSpese2: INVALID riga passed=",riga);
        return;
      }
      const rows = this.getSpeseArray(riga);
      return {
        header : ["Spesa", "Dal", "Al"],
        rows : rows,
        // conf: spesaConf
      };
    },
    salvaSchedaToStore(scheda) {
      this.$store.dispatch('progetto/updateSchedaProgettoObject',
        {
          idSchedaProgetto: this.$route.params.idProgetto,
          schedaProgetto: scheda
        }
      );
    },
    tooltip(value, key, item) {
        if(item.note[key])
            return {title: 'nota: ' + item.note[key]};
        return {};
    },
    getTdClass(value, key, item) {
      if(this.isTableColorDisabled)
        return '';
      if(item.added || item.deleted)
        return '';
      for(const ele in item.conf) {
        const key1 = mapUtils.getGenericArrayKey(ele);
        if(key1 === key && item.conf[ele].data.edited) {
          return 'modifiedCell';
        }
      }
    },
  },
  mounted() {
    this.mappa = this.getTabelloneProgetto('progetto');
    if(this.mappa[this.faseId]) {
      const config = this.mappa[this.faseId].config;
      for(const index in config.enumValues) {
        this.faseEnumValues[0].push({text: config.enumValues[index], value: index});
      }
      
    }
    this.extractData()
  }
};
</script>
