  function feedProjectData(anagrafica, schedaProgetto) {
    anagrafica.idBeneficiario = schedaProgetto.progetto.beneficiario.beneficiario.idBeneficiario;
    anagrafica.codiceProgetto = schedaProgetto.progetto.codiceProgetto;
    anagrafica.titoloProgetto = schedaProgetto.progetto.titoloProgetto;
    anagrafica.costoProgetto  = schedaProgetto.progetto.costoProgetto;
    anagrafica.dataInserimento = schedaProgetto.dataInserimento;
    anagrafica.strumentoFinanziario = schedaProgetto.progetto.strumentoFinanziario;
    if (schedaProgetto.progetto.obiettivoProgetto.obiettivoNazionale && schedaProgetto.progetto.obiettivoProgetto.obiettivoNazionale.length > 0){
      anagrafica.obiettivoNazionale = schedaProgetto.progetto.obiettivoProgetto.obiettivoNazionale;
      let allObiettiviSpecifici = schedaProgetto.progetto.obiettivoProgetto.obiettivoNazionale.map(obiettivoNaz => obiettivoNaz.obiettivoSpecifico.codice);
      if (allObiettiviSpecifici && allObiettiviSpecifici.length > 0)
        anagrafica.obiettivoSpecifico = [...new Set(allObiettiviSpecifici)].toString();
    }
    anagrafica.responsabileOperativoProgetto = schedaProgetto.progetto.responsabileOperativoProgetto.nome;
    return anagrafica;
  }


  /**
   * 
   * 
   * @param {Object} schedaProgetto contiene i dati del progetto di Riferimento
   * @param {Object} procedura contiene i dati della procedura selezionata
   * Crea il dato omogeneo per il TAB Anagrafica attingendo alle informazioni passate
   */
  function feedAnagrafica1A(schedaProgetto, procedura){

    // if (!checkList || !progetto || !procedura){
    if (!schedaProgetto || !procedura){
      console.error("feedAnagrafica1A: Missing params! Return empty object", schedaProgetto, procedura);
      return {};
    }

    let anagrafica = feedProjectData({}, schedaProgetto);
    //Rimuovo la dataInserimento presa da schedaProgetto, che andrebbe a sovrascrivere la dataInserimento del controllo
    delete anagrafica.dataInserimento;
    anagrafica.oggettoProcedura = procedura.oggetto; 
    anagrafica.tipologiaProcedura = procedura.tipologiaProcedura;
    anagrafica.soggettoAggiudicatario = procedura.soggettoAggiudicatario;
        
    return anagrafica
  }

/**
 * 
 * @param {Object} schedaProgetto dati del progetto di riferimento
 * @param {Object} contratto contratto scelto durante la creazione di 1B
 * Arricchisce la anagrafica del controllo 1B nascente con le informazioni
 * ereditate da 1A più quelle dello specifico contratto scelto
 */
  function feedAnagrafica1B(schedaProgetto, contratto){
    let anagrafica = feedProjectData({}, schedaProgetto);
    if (contratto.controllo1A) {
      anagrafica.oggettoProcedura = contratto.controllo1A.oggettoProcedura;
      anagrafica.tipologiaProcedura = contratto.controllo1A.tipologiaProcedura;
    }
    anagrafica.soggettoAggiudicatario = "";
    const procedura = contratto.procedura.content;
    if (procedura && procedura.soggettoAggiudicatario){
      anagrafica.soggettoAggiudicatario = procedura.soggettoAggiudicatario;
    }

    
    return anagrafica
  }

  /**
   * 
   * @param {Object} progetto dati del progetto di riferimento
   */
  function feedAnagraficaFinanz(progetto){
    let anagrafica = {};
    
    if (!progetto){
      console.error("feedAnagraficaFinanz: Missing params! Return empty object");
    } else {
      
      let obNaz = "";
      let obSpec = "";

      if (progetto.progetto.obiettivoProgetto.obiettivoNazionale && progetto.progetto.obiettivoProgetto.obiettivoNazionale.length > 0){
        obNaz = progetto.progetto.obiettivoProgetto.obiettivoNazionale;
        let allObiettiviSpecifici = progetto.progetto.obiettivoProgetto.obiettivoNazionale.map(obiettivoNaz => obiettivoNaz.obiettivoSpecifico.codice);
        if (allObiettiviSpecifici && allObiettiviSpecifici.length > 0)
          obSpec = [...new Set(allObiettiviSpecifici)].toString();
      }

      anagrafica = {
        idBeneficiario: progetto.progetto.beneficiario.beneficiario.idBeneficiario,
        codiceProgetto: progetto.progetto.codiceProgetto,
        titoloProgetto: progetto.progetto.titoloProgetto,
        strumentoFinanziario: progetto.progetto.strumentoFinanziario,
        costoProgetto: progetto.progetto.costoProgetto,
        obiettivoNazionale: obNaz,
        obiettivoSpecifico: obSpec
      }


    }

    return anagrafica;
  }

  /**
   * 
   * @param {Object} schedaControllo1B 
   * Arricchisce e completa l'anagrafica 2 partendo da 1B
   */
  function feedAnagrafica2(anagrafica, contratto){
    if (contratto && contratto.content){
      anagrafica.oggettoContratto = contratto.content.oggettoContratto;
      anagrafica.soggettoAttuatore = contratto.content.soggettoAttuatore;
      anagrafica.numeroContratto = contratto.content.riferimentoContratto;
      anagrafica.dataContratto = contratto.content.dataContratto;
      anagrafica.importoContratto = contratto.content.importoContrattoTotale;
    } else {
      console.error("Dati del contratto non trovati!? La fase 2 dovrebbe svolgersi su contratti controllati nella fase 1B");
    }

    //pulizia dei campi ereditati dal controllo 1B relativo
    delete anagrafica.autore;
    delete anagrafica.dataInserimento;
    delete anagrafica.dataVerifica;
    delete anagrafica.verificatore;
    
    if (!anagrafica.dataApprovazioneContratto){
      console.error("dataApprovazioneContratto non trovata nell'anagrafica del precedente controllo 1B! Verificare l'esito");
    }

    return anagrafica
  }

  /**
   * 
   * @param {Object} schedaProgetto contiene i dati del progetto di Riferimento
   * Crea il dato omogeneo per il TAB Anagrafica attingendo alle informazioni passate
   */
  function feedInfoProgetto(schedaProgetto){
    let infoProj = {
      "idProgetto": null,
      "codiceProgetto": "",
      "titolo": "",
      "stato": "",
      "costoProgetto": 0,
      "dataConclusione": null,
      "strumentoFinanziario": ""
    };
  
    if (schedaProgetto){
      //console.log("IN progetto=", schedaProgetto);
      
      infoProj = {
        idProgetto: schedaProgetto.progetto.idProgetto,
        codiceProgetto: schedaProgetto.progetto.codiceProgetto,
        titolo: schedaProgetto.progetto.titoloProgetto,
        stato: schedaProgetto.stato,
        costoProgetto: schedaProgetto.progetto.costoProgetto,
        dataConclusione: schedaProgetto.progetto.dataConclusione,
        strumentoFinanziario: schedaProgetto.progetto.strumentoFinanziario
      }

      //console.log("OUT infoProgetto=", infoProj);
    } else {
      console.error("feedInfoProgetto: NULL progetto passed! Return empty info ", schedaProgetto);
    }

    return infoProj;
  }


  /**
   * 
   * @param {Object} progetto contiene i dati del progetto di Riferimento
   * Crea il dato omogeneo per il TAB Anagrafica attingendo alle informazioni passate
   */
  function feedAnagraficaTranche(progetto){
    
    let anagrafica = {};
    
    if (!progetto){
      console.error("feedAnagraficaTranche: Missing params! Return empty object");
    } else {
      
      anagrafica = {
        idBeneficiario: progetto.progetto.beneficiario.beneficiario.idBeneficiario,
        codiceProgetto: progetto.progetto.codiceProgetto,
        titoloProgetto: progetto.progetto.titoloProgetto,
        strumentoFinanziario: progetto.progetto.strumentoFinanziario,
        costoProgetto: progetto.progetto.costoProgetto,
        dataFirmaDecreto: progetto.dataFirmaDecreto,
        dataFirmaConvenzione: progetto.dataFirmaConvenzione
      }


    }

    return anagrafica;
  }

/**
 * 
 * 
 * @param {Object} schedaProgetto contiene i dati del progetto di Riferimento
 */
    function feedAnagraficaIrregolarita(schedaProgetto){

    if (!schedaProgetto){
      console.error("feedAnagraficaIrregolarita: Missing params! Return empty object", schedaProgetto);
      return {};
    }

    let anagrafica = feedProjectData({}, schedaProgetto);
        
    return anagrafica
  }

export default {
  feedAnagrafica1A,
  feedInfoProgetto,
  feedAnagraficaTranche,
  feedAnagrafica1B,
  feedAnagrafica2,
  feedAnagraficaFinanz,
  feedAnagraficaIrregolarita
}