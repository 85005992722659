<template>
  <div>
    <h1 class="text-center">Lista Progetti in Compilazione o Modifica</h1>
    <b-form inline class="m-1" style="display: flex;">
      <label for="filterProgettiInput"
        ><h4 class="mx-2">Filtra i risultati:</h4>
      </label>
      <b-form-input
        v-model="filter"
        type="search"
        id="filterProgettiInput"
        placeholder="Digita un filtro"/>
      <b-button
        id="filterProgettiButton"
        class="mx-1"
        :disabled="!filter"
        @click="filter = ''">
          Cancella
      </b-button>
      <b-button 
        :disabled="this.isBusy"
        id="progettiRimodulazioneButton"
        @click="filterProgettiRim()"
        >{{filterRimButtonText}}
      </b-button>
    
      <b-button
        style="margin-left: auto;"
        class="align-right mr-4"
        @click="openModalCreateProject"
        variant="outline-primary"
        :disabled="isBusy || showModal || clicked"
        v-if="setButtonNewScheda">
        <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
        Nuova Scheda Progetto
      </b-button>
    </b-form>
     <div class="card">
        <div class="card-body text-right">
          <h1 class="font-weight-bold">Totale Importi: {{ totaleSpeseEuro }}</h1>
        </div>
      </div>
    <b-table
      :busy="isBusy"
      primary-key="id"
      emptyText="Nessun dato disponibile"
      :sort-by="sortBy"
      :filter="filter"
      emptyFilteredText="Nessun dato disponibile"
      :filter-included-fields="getFilterFields()"
      :items="currentRows"
      :fields="tabledata.header"
      show-empty
      hover
      responsive>
      <template v-slot:table-busy>
        <div class="text-center">
          <strong>Lista in Caricamento...</strong>
        </div>
      </template>
      <template v-slot:cell(Azioni)="data">
        <b-button
          :id="namingIdIcon(x, data.index + 1)"
          :disabled="clicked"
          variant="outline-primary"
          size="sm"
          :key="x"
          :title="mappingTooltip(x)"
          v-for="x in data.value"
          @click="doAction(x, data)">
          <fa-icon
            v-if="x === 'edit_in_list_progetti' || x === 'edit_in_list_modifica'"
            :icon="['far', 'edit']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'view'"
            :icon="['far', 'eye']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'view_in_list_modifica'"
            :icon="['far', 'eye']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'claim'"
            :icon="['far', 'hand-paper']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'unclaim'"
            :icon="['fas', 'times-circle']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'edit_start_modifica'"
            :icon="['fas', 'user-edit']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'view_project_in_attuazione'"
            :icon="['fas', 'folder']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'start_irr'"
            :icon="['fas', 'exclamation-triangle']"
            class="selector-icons"
          />
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="modalForm1-obiettivi"
      hide-footer
      size="xl"
      hide-header-close
      no-close-on-backdrop
      scrollable
      centered
      dialog-class="modal1-content"
      content-class="modal1-content">
      <template v-slot:modal-title>
        <h3>Crea Nuova Scheda Progetto</h3>
      </template>
      <selezioneObiettivi v-if="showModal" @closeModalCreateProject="closeModal" />
    </b-modal>
    <b-modal
        id="modalNuovaIrregolarita"
        :title="'Nuova Segnalazione per il progetto ' + selectedCodiceProgetto"
        hide-footer
        size="xl"
        no-close-on-backdrop
        scrollable
        centered>
      <modalNuovaIrregolarita
        :isBusy="isBusy"
        :codiceProgetto="selectedCodiceProgetto"
        :processDefKey="'ProgettoSegnalazione'"
        @closeModalCreateIrregolarita="closeModalCreateIrregolarita"/>
    </b-modal>

    <b-modal :id="'modalForm1-ConfermaAvvioRimodulazione'"
             size="lg"
             centered
             hide-header-close
             no-close-on-backdrop
             @ok="avviaRimodulazione"
             ok-title="Avvio Rimodulazione Progetto"
             ok-variant="success"
             cancel-title="Annulla">
      <template v-slot:modal-title>
        <p style="font-size: 2.1rem">ATTENZIONE: Si sta per avviare una rimodulazione della scheda progetto {{selectedCodiceProgetto}}.</p>
      </template>
      <p  style="font-size: 1.8rem">Si vuole procedere?</p>
    </b-modal>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import selezioneObiettivi from "@/components/selezioneObiettivi.vue";
import modalNuovaIrregolarita from "../irregolarita/modalNuovaIrregolarita.vue"
import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js";
import endpoints from "@/components/endpoints.vue";

export default {
  name: "listaProgetti",
  components: {
    selezioneObiettivi,
    modalNuovaIrregolarita
  },
  data() {
    return {
      totaleSpeseEuro: "€ 0,00",
      showModal: false,
      clicked: false,
      filterRimButtonText: 'Mostra progetti in rimodulazione',
      isFilterRimodulazione: false,
      actions: [
        "edit_in_list_modifica",
        "edit_in_list_progetti",
        "view",
        "view_in_list_modifica",
        "claim",
        "unclaim",
        "edit_start_modifica",
        "start_irr",
        "view_project_in_attuazione"
      ],
      sortBy: "codiceProgetto",
      filter: null,
      isBusy: false,
      currentRows: [],
      selectedCodiceProgetto: "",
      tabledata: {
        header: [
          {
            key: "codiceProgetto",
            label: "Codice Progetto",
            sortable: true,
          },
          {
            key: "modalitaDiAccesso",
            label: "Modalità",
            sortable: true,
          },
          {
            key: "titoloProgetto",
            label: "Titolo",
            sortable: true,
          },
          {
            key: "dataInserimento",
            sortable: true,
            filterByFormatted: true,
            formatter: this.dateTimeFormatter,
            label: "Data Inserimento",
          },
          {
            key:"dataFineProgetto",
            label:"Data Fine Progetto",
            filterByFormatted: true,
            formatter: this.dateFormatter,
            sortable: true
          },
          {
            // 26-08-21: modificato budgetMassimo->costoProgetto
            key: "costoProgetto",
            label: "Costo Progetto",
            formatter: this.currencyFormatter,
            sortable: true,
          },
          {
            key: "strumentoFinanziario",
            sortable: true,
          },
          {
            key: "stato",
            sortable: true,
          },
          // {
          //   key: "idSchedaProgetto",
          // },
          // {
          //   key: "idVersione",
          // },
          // {
          //   key: "carico",
          // },
          {
            key: "Azioni",
            thClass: "thst",
          },
        ],
        rows: [],
      },
      idProgetto: null
    };
  },
  computed: {
    ...mapGetters({
      getBeneficiari: "announcements/getAllBeneficiariMap",
    }),
    setButtonNewScheda() {
      return this.$setButtonNewScheda();
    },
    ...mapGetters({
      getConfig: "progetto/getMiscConfig",
    })
  },
  mounted() {
    this.extractData();
  },
  methods: {
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },
    dateTimeFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    currencyFormatter(value) {
      if (value == undefined || value == null) return null;
      return utils.formatCurrency(value);
    },
    openModalCreateProject() {
      this.showModal = true;
      this.$bvModal.show('modalForm1-obiettivi')
    },
    closeModal() {
      this.showModal = false;
      this.$bvModal.hide('modalForm1-obiettivi')
    },
    filterProgettiRim() {
      this.isFilterRimodulazione = !this.isFilterRimodulazione;
      if(this.isFilterRimodulazione) {
        this.currentRows = this.tabledata.rows.filter(item => item.stato === 'In Rimodulazione'
        || item.stato === 'Rinviato per Modifiche Rimodulazione'
        || item.stato === 'In Verifica Rimodulazione'
        || item.stato === 'Finalizzazione Rimodulazione');
        this.filterRimButtonText = 'Mostra tutti i progetti';
      } else {
        this.currentRows = this.tabledata.rows;
        this.filterRimButtonText = 'Mostra progetti in rimodulazione';
      }
    },
    beneficiarioFormatter(value) {
      if (!value) {
        return "Sconosciuto";
      }
      return utils.formatBeneficiari(value);
    },
    async doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      this.clicked = true;
      // console.info("DATA: ", data.item)
      const idProgetto = data.item.idSchedaProgetto;
      //memorizzo l'idProgetto globalmente, usato dopo la modale di conferma
      this.idProgetto = data.item.idSchedaProgetto;
      this.selectedCodiceProgetto = data.item.codiceProgetto;

      switch (action) {
        case "edit_in_list_progetti":
          this.$router.push({
            name: "schedaProgetto",
            params: { idProgetto: idProgetto },
          });
          break;
        case "edit_in_list_modifica":
          this.$router.push({
            name: "schedaProgettoModifica",
            params: { idProgetto: idProgetto },
          });
          break;
        case "view":
          this.$router.push({
            name: 'schedaProgetto',
            params: { idProgetto: idProgetto },
          });
          break;
        case "view_in_list_modifica":
          this.$router.push({
            name: 'schedaProgettoModifica',
            params: { idProgetto: idProgetto },
          });
          break;
        case "claim":
          this.$store
            .dispatch("progetto/claimSchedaProgetto", {
              idProgetto: idProgetto,
            })
            .then(
              (resp) => {
                const indexProgettoRow = this.getProgettoIndex(data.item);
                const azioni = this.$projectGetActions(resp);
                const updateActions = azioni.filter((item) => {
                  // console.log(this.actions)
                  return this.actions.indexOf(item) != -1;
                });
                let obj = resp.infoProgetto;
                obj.Azioni = updateActions;
                this.tabledata.rows[indexProgettoRow].Azioni = obj.Azioni;
                this.clicked = false;
              },
              (error) => {
                notify.error(
                  notify.strings.error,
                  notify.strings.errorClaimScheda
                );
                console.error("claim error", error);
                this.clicked = false;
              }
            );
          break;
        case "unclaim":
          this.$store
            .dispatch("progetto/unclaimSchedaProgetto", {
              idProgetto: idProgetto,
            })
            .then(
              (resp) => {
                const indexProgettoRow = this.getProgettoIndex(data.item);
                const azioni = this.$projectGetActions(resp);
                const updateActions = azioni.filter((item) => {
                  // console.log(this.actions)
                  return this.actions.indexOf(item) != -1;
                });
                let obj = resp.infoProgetto;
                obj.Azioni = updateActions;
                this.tabledata.rows[indexProgettoRow].Azioni = obj.Azioni;
                this.clicked = false;
              },
              (error) => {
                notify.error(
                  notify.strings.error,
                  notify.strings.errorUnclaimScheda
                );
                console.error("unclaim error", error);
                this.clicked = false;
              }
            );
          break;
        case "edit_start_modifica":
          if (data.item && data.item.stato === 'In Attuazione'){
            const config = this.getConfig;
            if(!config || !config.processoRimodulazione) {
               await this.$store.dispatch("progetto/setupMiscConfig").catch(
                error => {
                    console.error('error miscConfig', error.response);
              });
            }
            this.$bvModal.show('modalForm1-ConfermaAvvioRimodulazione');
          } else {
            console.error("Impossibile avviare il processo di modifica per progetti in stato=", data.item.stato);
          }
          this.clicked = false;
          break;
        case "view_project_in_attuazione":
          this.$router.push({ name: "listaProgettiAttuazione", params: {idProgetto: idProgetto}})
          break;
        case "start_irr":
          this.openModalCreateIrregolarita();
          this.clicked = false;
          break;
        default:
          console.error("Unknown action!", action);
          this.clicked = false;
          break;
        
      }

    },

    async getSchemaLastVersion(key){
      const url = endpoints.retrieveLastVersionTabellone(key)
      return this.$get(url)
    },

    async doCronoCheckout() {
        // checkout del branch di cronoprog a partire dal trunk
        const branch = 'progetto';
        const checkoutBody = {
            idProgetto: this.idProgetto,
            version: null,
            branch: branch,
        };
        const url = endpoints.cronoCheckout;
        return this.$post(url, checkoutBody);
    },

    async avviaRimodulazione() {
      this.clicked = true;
      const idProgetto = this.idProgetto;
      console.log("start remodulation for project: ", idProgetto);
      try {
        const cronoprog = await this.doCronoCheckout();
        console.log("cronoprogResult", cronoprog);
      } catch(error) {
        console.error('avviaRimodulazione errore checkout cronoprog', error);
        notify.error(notify.strings.error, notify.strings.errorStartProgettoModifica);
        this.clicked = false;
        return;
      }
      let idSchemaLastVersion = null;
      try{
        idSchemaLastVersion = await this.getSchemaLastVersion('progetto')
      }catch(error){
        console.error('avviaRimodulazione errore recupero ultima versione configurazione', error.message);
        notify.error(notify.strings.error, notify.strings.errorStartProgettoModifica);
        this.clicked = false;
        return;
      }
      const config = this.getConfig;
      const urlAvvioProcesso = endpoints.startModificaProgettoProcess(config.processoRimodulazione);

      const myBody = { 
        'idScheda': this.idProgetto,
        'idSchema': idSchemaLastVersion.lastVersion
      };
      return this.$post(urlAvvioProcesso, myBody)
          .then(
              (resp) => {
                  console.log("Processo di Modifica per il Progetto "+idProgetto+" avviato correttamente, response=", resp);
                  let processId = resp.id;
                  if (processId){
                    this.$router.push({
                      name: "schedaProgettoModifica",
                      params: { idProgetto: idProgetto },
                    });
                  } else {
                    console.error("Qualcosa è andato storto nell'avvio del processo di modifica progetto!? Response:", resp);
                    notify.error(notify.strings.error, notify.strings.errorStartProgettoModifica);
                  }
                  this.clicked = false;
              }
          )
          .catch(
              (error) => {
                  console.error('Start ProgettoModifica process error:', error.message);
                  notify.error(notify.strings.error, notify.strings.errorStartProgettoModifica);
                  this.clicked = false;
                  throw error;
              }
          )
    },
    getProgettoIndex(schedaRow) {
      return  this.tabledata.rows.map( schedaProgetto => {
        return schedaProgetto.idSchedaProgetto;
      }).indexOf(schedaRow.idSchedaProgetto);
    },
    extractData: async function() {
      let userIdGroup = this.$getUserGroupId();
      if(!userIdGroup.includes("Beneficiari")){
        this.tabledata.header.splice(3, 0,{key:"beneficiario", sortable: true, formatter:"beneficiarioFormatter", sortByFormatted: true});
      }
      this.isBusy = true;
      const url = endpoints.listSchedeProgettoForModifica;
      let totaleSpese=0;
      this.$get(url).then((result) => {
        const listaProgetti = result;
        listaProgetti.forEach(
          (element) => {
            //console.log("stato=",element.infoProgetto.stato);
            //visualizzo i progetti con stati relativi alla prima compilazione o modifica del progetto (in attuazione)
            if (element.infoProgetto &&
              element.infoProgetto.stato &&
              (element.infoProgetto.stato === 'In bozza'
                || element.infoProgetto.stato.match('liminato')
                || element.infoProgetto.stato=='Inviato'
                || element.infoProgetto.stato=='In Attuazione'
                || element.infoProgetto.stato=='Rinviato per modifiche'
                || element.infoProgetto.stato.includes('modulazione')
                )){

                  //setto le azioni
                  const info = element.infoProgetto;
                  
                  let azioni = this.$projectGetActions(element);
                  const localActions = azioni.filter((item) => {
                    return this.actions.indexOf(item) != -1;
                  });
                  const newRow = {
                    codiceProgetto: info.codiceProgetto,
                    modalitaDiAccesso: info.modalitaDiAccesso,
                    titoloProgetto: info.titoloProgetto,
                    dataInserimento: info.dataInserimento,
                    dataFineProgetto: info.dataConclusione,
                    // 26-08-21: modificato budgetMassimo->costoProgetto
                    costoProgetto: info.costoProgetto,
                    strumentoFinanziario: info.strumentoFinanziario,
                    stato: info.stato,
                    Azioni: localActions,
                    idSchedaProgetto: info.idProgetto,
                    idIstruttoria: info.idIstruttoria,
                    beneficiario: info.idBeneficiario
                    // idVersione: info.idVersione,
                    // carico: element.taskInfo.taskDefinitionKey
                  };
                  if (azioni.length > 0) {
                    const obj = parseFloat(info.costoProgetto);
                    if(!isNaN(obj)){
                      totaleSpese = totaleSpese + obj;
                    }
                    this.tabledata.rows.push(newRow);
                  } 
                  // else {
                  //   console.log("NO azioni, skip element=",element);
                  // }
            }
          },
          (error) => {
            notify.error(
              notify.strings.error,
              notify.strings.internalErrorPleaseReportAction
            );
            throw error;
          }
        );
        this.currentRows = this.tabledata.rows;
        this.totaleSpeseEuro = utils.formatCurrency(totaleSpese);
        this.isBusy = false;
      },
      (error) => {
        this.isBusy = false;
         notify.error(
                  notify.strings.error,
                  notify.strings.internalErrorPleaseReportAction
                );
        console.error("errore caricamento lista",error);
      });
    },
    mappingTooltip(keyMapping) {
      let objMapping = {
        edit_in_list_progetti: "Modifica",
        edit_in_list_modifica: "Vai a rimodulazione progetto",
        view_in_list_modifica: "Visualizza progetto in modifica",
        view: "Visualizza",
        claim: "Presa in carico",
        unclaim: "Rilascio presa in carico",
        edit_start_modifica: "Avvia processo di modifica del progetto",
        view_project_in_attuazione: "Vai ai Progetti In Attuazione",
        start_irr: "Avvia Segnalazione"
      };
      let temp = objMapping[keyMapping];
      if (!temp) return "";
      return temp;
    },
    getFilterFields() {
      let filterFields = [];
      for (let item of this.tabledata.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
    openModalCreateIrregolarita(){
      this.$bvModal.show('modalNuovaIrregolarita')
    },
    closeModalCreateIrregolarita(){
        this.$bvModal.hide('modalNuovaIrregolarita')
    },
  },
};
</script>
