<template>
  <div id="archivioAss" class="elencoProgetti">
    <div class="table-title">{{type}}&nbsp;Assessment - Archivio progetto {{ codiceProgetto }} </div>
    <b-table hover show-empty id="archivioAssessmentTable"
              :busy="isBusy"
              primary-key="Periodo"
              :items="tabledata.rows"
              :fields="tabledata.header"
              :sort-by="sortBy"
              sort-desc
              empty-text="Nessun dato trovato">
      <template v-slot:cell(Azioni)="data">
        <b-button variant="outline-primary" :key="x" v-for="x in data.item.actions" size="sm" v-on:click="doAction(x, data.item)">
          <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
          <fa-icon v-if="x==='view'" :icon="['far', 'eye']" class="selector-icons"/>
        </b-button>
      </template>
    </b-table>
  </div>
</template>


<script>
import utils from '@/helpers/utils.js'
import notify from "@/helpers/notifications.js"
import { mapGetters} from 'vuex'

export default {
  name: "ArchivioAssComponent",
  computed: {
    ...mapGetters({
        getTaskList: 'assessment/getTaskList',
    }),
    isFinal: function() {
      return this.$route.name === 'archivioAssessFinal'
    }
  },

  data() {
    return {
      codiceProgetto : null,
      isBusy: false,
      sortBy: 'Periodo',
      type: '',
      archivio: {},
      tabledata: {
          "header": [
              {
                  key: 'Codice',
              },
              {
                  key: 'Titolo',
              },
              {
                  key: 'Periodo',
                  sortDirection: 'desc',
                  sortable: true
              },
              {
                  key:'dataApprovazione',
                  thClass: 'thst',
                  formatter: this.dateFormatter,
              },
              {
                  key: 'Stato',
              },
              {
                  key: 'Azioni',
              },
          ],
          "rows": []
      }
    };
  },

  mounted() {
    this.type = this.isFinal ? 'Final' : 'Interim';
    this.codiceProgetto = this.$route.params.codiceProgetto;
    //console.log("archivio: codiceProgetto = "+ this.codiceProgetto );
    this.getArchivioSchede();
  },

  methods: {
    async getArchivioSchede() {
        this.isBusy = true;
        //accesso allo store per leggere i progetti
        await this.$store.dispatch('selettoreAss/retrieveArchivioByCodiceProgetto', 
          {
            codiceProgetto: this.codiceProgetto,
            final: this.isFinal
          }).then(
            result => {
              this.archivio = result;
            }
        ).catch(
          error => {
            console.error('errore get archivio', error);
            notify.error(notify.strings.error, notify.strings.selettoreAssessmentKO);
            this.isBusy = false;
            return;
          }
        );
        this.extractData();
        this.isBusy = false;
    },

    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },
    
    setAction(scheda) {
      let azioni = [];
      let task = this.getTaskList(scheda.codiceProgetto, scheda.idPeriodo);
      if(utils.caniuse('edit', task, scheda) || utils.caniuse('unclaim', task, scheda)) {
          azioni.push('edit');
      }
      else if(utils.caniuse('view', task, scheda)) {
          azioni.push('view');
      }

      return azioni;
    },
    
    doAction(actionToDo, item) {
      // console.log(this.archivio, item.idPeriodo);
      let info = this.archivio[item.idPeriodo];
      
      let payload = {
          codiceProgetto: info.codiceProgetto,
          idPeriodo: info.idPeriodo
      };
      const name = 'scheda' + this.type;
      switch(actionToDo) {
        case 'edit':
        case 'view': // same action for edit and view
          this.$router.push({ name: name, params: payload});
          break;
        default:
          console.log("Nothing to do for " + actionToDo);
          break;
      }
    },

    extractData() {
      
      if(!this.archivio){
        console.log("archivioAss: Invalid inputData!?", this.archivio);
        return;
      }



      let rows = [];
      const inputData = Object.values(this.archivio);
      //ciclare per l'array di oggetti di tipo "SelettoreSchedaMonitoraggio.java" passato
      for(const item of inputData){
          let row = {
              Codice: item.codiceProgetto,
              Titolo: item.titolo,
              Periodo: item.periodo,
              Stato: item.stato,
              idPeriodo: item.idPeriodo,
              actions: this.setAction(item),
              key: item.key,
              dataApprovazione: item.dataApprovazione
          };

          rows.push(row);
      }
      this.tabledata.rows = rows;
    }
  }
};
</script>
