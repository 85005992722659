<template>
  <div id="listaExecutingBody">
    <h1 class="text-center m-2">Executing Body</h1>
    <b-form inline class="m-1" style="display: flex;">
        <label for="filterExecutingBodyInput">
        <h4 class="mx-2">Filtra i risultati:</h4>
        </label>
        <b-form-input
          v-model="filter"
          type="search"
          id="filterExecutingBodyInput"
          placeholder="Digita un filtro"
        />
        <b-button
          id="filterExecutingBodyButton"
          class="mx-1"
          :disabled="!filter"
          @click="filter = ''"
          >Cancella</b-button
        >
        <b-button
          style="margin-left: auto;"
          class="align-right mr-4"
          @click="showModal()"
          variant="outline-primary"
          v-if="visibilityBtn"
        >
          <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
          Nuovo Intervento
        </b-button>
    </b-form>
    <b-table
      :busy="isBusy"
      primary-key="id"
      emptyText="Nessun dato disponibile"
      :sort-by="sortBy"
      sort-desc
      :filter="filter"
      emptyFilteredText="Nessun dato disponibile"
      :filter-included-fields="getFilterFields()"
      :fields="tabledata.header"
      :items="tabledata.rows"
      show-empty
      hover
      responsive
    >
    <template v-slot:table-busy>
      <div class="text-center">
        <strong>Lista in Caricamento...</strong>
      </div>
    </template>
    <template v-slot:cell(Azioni)="data">
      <b-button
        :id="namingIdIcon(x, data.index + 1)"
        :disabled="clicked"
        variant="outline-primary"
        size="sm"
        :key="x"
        :title="mappingTooltip(x)"
        v-for="x in data.value"
        @click="doAction(x, data)"
      >
      <fa-icon
        v-if="x === 'edit_in_list_executing'"
        :icon="['far', 'edit']"
        class="selector-icons"
      />
      <fa-icon
        v-if="x === 'view'"
        :icon="['far', 'eye']"
        class="selector-icons"
      />
      <fa-icon
        v-if="x === 'claim'"
        :icon="['far', 'hand-paper']"
        class="selector-icons"
      />
      <fa-icon
        v-if="x === 'unclaim'"
        :icon="['fas', 'times-circle']"
        class="selector-icons"
      />
      </b-button>
    </template>
    </b-table>
    <b-modal
      :id="'modalForm1-' + this.name"
      hide-footer
      size="xl"
      hide-header-close
      no-close-on-backdrop
      scrollable
      centered
      dialog-class="modal1-content"
      content-class="modal1-content"
    >
      <template v-slot:modal-title>
        <h3>Crea Nuova Scheda Executing Body</h3>
      </template>
      
    </b-modal>
    <modalSelectFinancialTool
        v-if="showModalEB"
        @executingCloseModalCreate="closeModal"
      ></modalSelectFinancialTool>
  </div>
</template>

<script>
import notify from "@/helpers/notifications.js";
import endpoints from "@/components/endpoints.vue";
import utils from "@/helpers/utils.js";
import modalSelectFinancialTool from "@/components/selectFinancialTool.vue";
import { mapGetters } from "vuex";

export default {
  name: "listaExecutingBody",
  components: {
    modalSelectFinancialTool,
  },
  data() {
    return {
      clicked: false,
      name: "listaExecutingBody",
      actions: ["edit_in_list_executing", "view", "claim", "unclaim"],
      sortBy: "dataInvio",
      showModalEB: false,
      filter: null,
      isBusy: false,
      tabledata: {
        header: [
          {
            key: "titoloProgetto",
            label: "Titolo Intervento",
            sortable: true,
          },
          {
            key: "dataInserimento",
            sortable: true,
            filterByFormatted: true,
            formatter: this.dateFormatter,
            label: "Data",
          },
          {
            key: "costoProgetto",
            label: "Importo Intervento",
            formatter: this.currencyFormatter,
            sortable: true,
          },
          {
            key: "strumentoFinanziario",
            sortable: true,
          },
          {
            key: "stato",
            sortable: true,
          },
          {
            key: "codiceProgetto",
            label: "Codice Intervento",
            sortable: true,
          },
          {
            key: "Azioni",
            thClass: "thst",
          },
        ],
        rows: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      getListaProgetti_EB: "executingBody/getListaExecuting",
    }),
    visibilityBtn() {
      return this.$handleVisibilityNewExecuting();
    }
  },
  mounted() {
    this.populateList();
  },
  methods: {
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    currencyFormatter(value) {
      if (value == undefined || value == null) return null;
      return utils.formatCurrency(value);
    },
    showModal() {
      this.showModalEB = !this.showModalEB;
    },
    closeModal() {
      this.showModalEB = false;
    },
    mappingTooltip(keyMapping) {
      let objMapping = {
        edit_in_list_executing: "Modifica",
        view: "Visualizza",
        claim: "Presa in carico",
        unclaim: "Rilascio presa in carico",
      };
      let temp = objMapping[keyMapping];
      if (!temp) return "";
      return temp;
    },
    getFilterFields() {
      let filterFields = [];
      for (let item of this.tabledata.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      let idExecuting = data.item.idSchedaExecuting;
      switch (action) {
        case "edit_in_list_executing":
        case "view":
          this.$router.push({
            name: "schedaExecuting",
            params: { idExecutingBody: idExecuting },
          });
          break;
        case "claim":
        case "unclaim":
          this.clicked = true;
          this.$store
            .dispatch("executingBody/claim_un_claim_SchedaEB", {
              idProgetto: idExecuting,
              type: action
            })
            .then(
              (resp) => {
                // Recupero l'indice della riga
                const indexProgettoRow = this.getListaProgetti_EB
                  .map((schedaProgetto) => {
                    return schedaProgetto.infoProgetto.idProgetto;
                  }).indexOf(resp.infoProgetto.idProgetto);
                  const azioni = this.$executingBodyGetActions(resp);
                  const updateActions = azioni.filter(item => { return this.actions.indexOf(item) != -1  });
                  let obj = resp.infoProgetto;
                  obj.Azioni = updateActions;
                  this.tabledata.rows[indexProgettoRow].Azioni = obj.Azioni;
                  this.clicked = false;
                },
              (error) => {
                notify.error(
                  notify.strings.error,
                  notify.strings.internalErrorPleaseReportAction
                );
                console.error("claim error", error);
                this.clicked = false;
              }
            );
          break;
        default:
          console.error("Unknown action!", action);
          break;
      }
    },
    fillData: function() {
      return this.tabledata;
    },
    populateList: async function() {
      this.isBusy = true;
      this.$store.dispatch("executingBody/clearList")
      const urlGet = endpoints.listSchedeExecutingBody;
      this.$get(urlGet).then((result) => {
        let listSchedeExecuting = result;
        listSchedeExecuting.forEach((element) => {
          //setto le azioni
          const info = element.infoProgetto;
          const azioni = this.$executingBodyGetActions(element);
          const localActions = azioni.filter((item) => {
            return this.actions.indexOf(item) != -1;
          });
          let newRow = {
            // modalitaDiAccesso: info.modalitaDiAccesso,
            titoloProgetto: info.titoloProgetto,
            dataInserimento: info.dataInserimento,
            costoProgetto: info.costoProgetto,
            strumentoFinanziario: info.strumentoFinanziario,
            stato: info.stato,
            codiceProgetto: info.codiceProgetto != null ?  info.codiceProgetto : "N/D",
            Azioni: localActions,
            idSchedaExecuting: info.idProgetto,
          };
          if (localActions.length > 0) {
            this.tabledata.rows.push(newRow);
            this.$store.dispatch("executingBody/pushSchedaInListExecuting", {
              schedaExecuting: element,
            });
          }
          
          this.fillData();
        },
        (error) => {
          notify.error(
            notify.strings.error,
            notify.strings.internalErrorPleaseReportAction
          );
          throw error;
        });
        this.isBusy = false;
      },
      (error) => {
        this.isBusy = false;
         notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        console.log("errore caricamento lista",error);
      });
    }
  }
};
</script>