<template>
  <b-container>
    <span hidden>{{finale}}</span>
    <b-row v-for="field of Object.values(returnData.content)" :key="field.id">
      <b-col>
        <b-row :id="'relation-' + field.id"
                v-if="checkRelation(field)"
                style="margin: 0px; padding: 0px">
          <b-col cols="2">
            <strong style="font-size: 1.3rem; float: right;">
              Verificare anche i seguenti campi:
            </strong>
          </b-col>
          <b-col style="margin: 0px; padding: 0px">
            <strong style="font-size: 1.3rem">
              <span v-for="x of getRelation(field)" :key="x.label">{{x.tab}} -> {{x.label}}<br></span>
            </strong>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :id="'group-' + field.id"
                      :label="returnData.conf[field.id].config.label"
                      label-size="lg" label-cols="3" label-align="center"
                      :hidden="hidden[field.id]">
            <b-form-datepicker  v-if="checkDate(field)"
                              boundary="window"
                              locale="it"
                              hide-header dropright
                              label-help="Usa i cursori per navigare la data"
                              :min="minDate(field)"
                              :max="maxDate(field)"
                              @input="validate(field.id, field.value)"
                              :hidden="hidden[field.id]"
                              v-model="returnData.content[field.id].value" 
                              :disabled="checkDisabled(field)"
                              :state="computeState(field)"
                              :id="field.id"
                              :date-format-options="getYearFormat(field)"
                              value-as-date/>
            <b-textarea v-else-if="checkTextArea(field)"
                      rows="4"
                      :maxlength="maxlength(field)"
                      :hidden="hidden[field.id]"
                      v-model="returnData.content[field.id].value"
                      :disabled="checkDisabled(field)"
                      :state="computeState(field)"
                      :id="field.id" />
            <selezioneBeneficiari v-else-if="isBeneficiari(field)" :hidden="hidden[field.id]"
                                :scheda=sch
                                :value="field.value"
                                :id="field.id"/>
            <selezionePagamenti v-else-if="isPagamenti(field)" 
                                :hidden="hidden[field.id]"
                                :disabledPagamenti="checkPaymentDisabled(field)"
                                :scheda="sch"
                                :values="!checkPaymentDisabled(field) ? field.value : []"
                                :id="field.id"
                                :pagamentiPossibili="optionalObj.pagamenti"
                                @change="aggiorna(field)"
                                />                      
            <b-time v-else-if="checkTime(field)"
                  :hour12=false hide-header
                  :minutes-step="5"
                  :hidden="hidden[field.id]"
                  v-model="returnData.content[field.id].value"
                  :disabled="checkDisabled(field)"
                  :state="computeState(field)"
                  :id="field.id"/>
            <b-select v-else-if="isEnum(field)"
                    size="lg"
                    @change="aggiorna(field)"
                    :hidden="hidden[field.id]"
                    v-model="returnData.content[field.id].value"
                    :disabled="checkDisabled(field)"
                    :state="computeState(field)"
                    :id="field.id">
              <b-form-select-option v-for="option in getEnum(field)" :hidden="hidden[field.id]"
                    :selected="option.value === field.value"  
                    :key="option.value"
                    :value="option.value">
                    {{option.label}}
              </b-form-select-option>
            </b-select>
            <b-select v-else-if="isBoolean(field)"
                    size="lg"
                    :hidden="hidden[field.id]"
                    v-model="returnData.content[field.id].value"
                    :disabled="checkDisabled(field)"
                    :state="computeState(field)"
                    :id="field.id">
                  <b-form-select-option v-for="option in getBoolean(field)"
                          :selected="option.value === field.value"
                          :key="option.value"
                          :value="option.value">
                          {{option.label}}
                  </b-form-select-option>
            </b-select>
            <b-checkbox v-else-if="isFlag(field)"
                      :disabled="checkDisabled(field)"
                      @input="aggiorna(field)"
                      size="lg"
                      :hidden="hidden[field.id]"
                      v-model="returnData.content[field.id].value"
                      :value="returnData.idScheda"
                      unchecked-value=""
                      :state="computeFlagState(field)"
                      :id="field.id">
                      <b-form-invalid-feedback :state="computeFlagState(field)" :id="field.id+'-cb-feedback'">
                        <h5 v-for="errs of errorList(field.id)" :key="errs">{{errs}}</h5>
                      </b-form-invalid-feedback>
              </b-checkbox>
              <b-input-group v-else size="lg" :prepend="prependSymbol(field)">
                <b-input  @input="aggiorna(field)"
                      :maxlength="maxlength(field)"
                      :hidden="hidden[field.id]"
                      v-model="returnData.content[field.id].value"
                      :disabled="checkDisabled(field)"
                      :state="computeState(field)"
                      :id="field.id"/>
                <b-form-invalid-feedback :id="field.id+'-g-feedback'">
                  <h5 v-for="errs of errorList(field.id)" :key="errs">{{errs}}</h5>
                </b-form-invalid-feedback>
              </b-input-group>
              <h5 v-if="showMaxLength(field)">
                Caratteri consentiti: {{returnData.conf[field.id].config.maxLength}}
              </h5>
              <b-form-invalid-feedback :id="field.id+'-feedback'">
                <h5 v-for="errs of errorList(field.id)" :key="errs">{{errs}}</h5>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-textarea :hidden="hidden[field.id] || returnData.conf[field.id].config.suppressNote 
                    || checkDisabled(field) "
                    :state="isValid[field.id+'_nota'] === false ? false : null"
                    placeholder="Note"
                    :id="field.id+'_nota'"
                    maxlength="1000"
                    @input="value => {aggiornaNota(value, field)}"
                    v-model="returnData.conf[field.id].data.note"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="modal-footer">
        <b-button variant="success" v-for="bt in buttons" :hidden="!bt.show" :key="bt.name" 
        class="modal-default-button" @click="controlloFinale(bt.action, returnData)">
          {{bt.name}}
        </b-button>
        <b-button variant="danger" class="modal-default-button"
                  @click="$bvModal.hide('modalForm1-' + parentName)">
          Chiudi
        </b-button>
    </div>
  </b-container>
</template>
  
<script>

import tool from '@/helpers/tools.js'
import validation from '@/helpers/validations.js'
import visibility from '@/helpers/visibility.js'
import calculation from '@/helpers/calculations.js';
import selezioneBeneficiari from "@/components/selezioneBeneficiari.vue";
import selezionePagamenti from "@/components/selezionePagamenti.vue";
import notify from "@/helpers/notifications.js"
import utils from '@/helpers/utils.js'

export default {
  name: "modalForm",
  components: {
    selezioneBeneficiari,
    selezionePagamenti
  },
  props: {
    parentName: {
      type: String,
      required: true
    },
    fdata: {
      type: Object,
      required: true
    },
    buttons:
    {
      type: Array,
    },
    sch: {
      type: Object,
      required: true  
    },
    optionalObj: {
      type: Object,
    },
    tabName: {
      type: String
    },
    cfg: {
      type: Object
    }
  },
  data() {
    return {
      isValid: {},
      errorStrings: {},
      initialData: null,
      hidden: {},
      calculate: {},
      returnData: {content: {}, conf: {}},
      finale: false
    };
  },

  computed: {
    checkRelation() {
      return field => {
          if(!this.returnData.conf[field.id])
            return false;
          const config = this.returnData.conf[field.id].config;
          return config.relatedTo && config.relatedTo.length > 0;
      }
    },
    getRelation() {
      return field => {
          const retVal = [];
          if(!this.returnData.conf[field.id])
            return retVal;
          const config = this.returnData.conf[field.id].config;
          if(!config.relatedTo || config.relatedTo.length === 0)
            return retVal;
          const rels = config.relatedTo;
          
          for(const rel of rels) {
            if(!this.cfg[rel] || !this.cfg[rel].config) {
              console.error('relazione non trovata', rel);
              continue;
            }
            const conf = this.cfg[rel].config;
            retVal.push({
              tab: conf.tab,
              label: conf.label
            })
          }
          return retVal;
      }
    },
    minDate() {
      return field => {
        if(this.returnData.conf[field.id]) {
          const config = this.returnData.conf[field.id].config;
          if(config.minDate) {
            return calculation.computeDate(config.minDate);
          }
        }
        return null;
      }
    },
    maxDate() {
      return field => {
        if(this.returnData.conf[field.id]) {
          const config = this.returnData.conf[field.id].config;
          if(config.maxDate) {
            return calculation.computeDate(config.maxDate);
          }
        }
        return null;
      }
    },
    showMaxLength() {
      return field => {
        if(this.returnData.conf[field.id]) {
          const config = this.returnData.conf[field.id].config;
          return (config.maxLength && !config.readonly && !this.hidden[field.id] && !config.enumValues);
        }
        return false;  
      }
     },

    prependSymbol() {
      return field => {
        if(this.returnData.conf[field.id]) {
          const config = this.returnData.conf[field.id].config;
          if (config.type === 'currency')
            return '€';
          if (config.type === 'percent')
            return '%';
        }
      }
    },
    // WARNING: questa particolare funzione è customizzata per il flag dei pagamenti
    // nella dichiarazione di spesa: infatti deve mantenere lo stato anche quando
    // il flag è disabilitato. Invece la funzione di default dà uno state=null quando
    // il campo è disabilitato
    computeFlagState() {
      return field => {
        if(!field.value && this.isValid[field.id] == null)
          return null;
        return this.validate(field.id, field.value);
      }
    },

    computeState() {
      return field => {
        if(this.checkDisabled(field))
          return null;
        // WARNING: questo controllo dovrebbe dare uno state null all'apertura della modalform
        // verificare che sia efficace e non mascheri un bug
        if(!field.value && this.isValid[field.id] == null)
          return null;
        return this.validate(field.id, field.value);
      }
    },

    errorList() {
      return id => {
        let list = [];
        if(this.errorStrings[id])
          list.push(this.errorStrings[id]);
        if(this.errorStrings[id+'_nota'])
          list.push(this.errorStrings[id+'_nota']);
        return list;
      }
    },

    maxlength: function() {
      return field => {
        let max = 50;
        if(this.returnData.conf[field.id] &&
           this.returnData.conf[field.id].config.maxLength) {
            max = this.returnData.conf[field.id].config.maxLength;
        }
        return max;
      }
    },
    checkPaymentDisabled: function() {
      return field => {
        if(this.returnData && field)
          return this.returnData.content["['content']['disposizione']['riferimentoContratto']"].value === 'Non definito/a'
        return false
      }
    },
    checkDisabled: function() {
      return field => {
        if(this.returnData.conf[field.id]) {
          const conf = this.returnData.conf[field.id].config
          let disabled = conf.readonly;
          if(conf.disabledRule) {
            disabled = disabled || visibility.computeDisabled(field.id, this.returnData, this.fdata.conf[field.id], this.optionalObj);
          }
          let tab = this.returnData.conf[field.id].config.tab;
          let title = this.getTab();
          if(tab && title) {
            disabled = disabled || (tab !== title);
          }
          return disabled;
        }
        return true;
      }
    },
    getBoolean: function () {
        return () => {
          let boolean = [];
          boolean.push({'label': "Scegliere un'opzione"},
                       {'label': "SI", 'value': 'true'},
                       {'label': "NO", 'value': 'false'});
          return boolean
        }
    },
    getEnum: function() {
      return field => {
        if(this.returnData.conf[field.id].config.type === 'enum' && this.returnData.conf[field.id].config.enumValues) { // se il  campo è di tipo enum
          let enumValues = [];
          if(!field.value) // valore di default
            enumValues.push({'label': 'Non selezionato', 'value': field.value});
          for(let obj of this.returnData.conf[field.id].config.enumValues) {
            // se l'oggetto ha label e value li inserisco
            if(obj.label) {
              enumValues.push({'label': obj.label, 'value': obj.value});
            }
            else {
              // altrimenti la label è uguale al value
              enumValues.push({'label': obj, 'value': obj});
            }
          }
          return enumValues;
        }// se il campo non è enum ma ho bisogno comunque della tendina 
        else if(this.returnData.content[field.id] && this.returnData.content[field.id].enumValues) { 
          return this.returnData.content[field.id].enumValues;
        }
        return null;
      }
    },
    
    isBoolean: function () {
         return field => {
        return this.returnData.conf[field.id].config.type === 'boolean';
      }
    },
    isFlag: function () {
      return field => {
        return this.returnData.conf[field.id].config.type === 'flag';
      }
    },
    isEnum: function() {
      return field => {
        // se il  campo è di tipo enum oppure
        // se il campo non è enum ma ho bisogno comunque della tendina 
        return (this.returnData.conf[field.id].config.type === 'enum' || 
           this.returnData.content[field.id] && this.returnData.content[field.id].enumValues);
      }
    },

    isBeneficiari: function() {
      return field => {
        return this.returnData.conf[field.id].config.type === 'enumstring';
      }
    },

    isPagamenti: function() {
      return field => {
        return this.returnData.conf[field.id].config.type === 'enumPayments';
      }
    },
    checkDate: function() {
      return field => {
        const type = this.returnData.conf[field.id].config.type
        return type === 'date' || type === 'year';
      }
    },
    getYearFormat: function() {
      return field => {
        if(this.returnData.conf[field.id].config.type === 'year') {
          // WARNING: da specifica di bootstrap vue bisogna passare undefined
          // nei campi che non devono essere visualizzati
          return { year: 'numeric', month: undefined, day: undefined};
        }

        return null;
      }
    },
    checkTime: function() {
      return field => {
        return this.returnData.conf[field.id].config.type === 'time';
      }
    },

    checkTextArea: function() {
      return field => {
        return this.returnData.conf[field.id].config.type === 'text';
      }
    }
  },
  created() {
    
    if(!this.$props.fdata || !this.$props.fdata.conf || !this.$props.fdata.content) {
      console.error('modalForm1 bad params', this.$props.fdata);
      notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
      );
      this.$bvModal.hide('modalForm1-' + this.parentName);
    }

    this.initialData = tool.genericFunctions.cloneObject(this.$props.fdata);
    this.returnData = tool.genericFunctions.cloneObject(this.$props.fdata);

    for(const key in this.$props.fdata.conf) {
      this.isValid[key] = null;
      this.isValid[key+'_nota'] = true;
      this.errorStrings[key] = '';
      this.errorStrings[key+'_nota'] = '';

      const config = this.fdata.conf[key].config;
      if(config.hiddenRule) {
        if(this.hidden[key] == undefined) {
          
          const result = visibility.computeHidden(key, this.fdata, this.fdata.conf[key]);
          this.$set(this.hidden, key, result);
        }
      }
      if(config.calculationRule) {
        if(this.calculate[key] == undefined) {
          this.$set(this.calculate, key, true);
        }
      }
    }
  },
  
  methods: {
    getTab() {
      if(this.tabName)
        return this.tabName
      // WARNING: percorso cablato per scheda progetto, fase 3-4
      // oppure per monitoraggio con cronoprog
      let tab = this.$parent.$parent.$parent.$parent.title;
      if(!tab)
        tab = this.$parent.$parent.$parent.$parent.$parent.$parent.title;
      if(!tab) {
        tab = this.$parent.$parent.$parent.title
      }
      //console.info("TAB NAME : ", tab)
      return tab;
    },
    unformatValue(key, dataBundle) {
      if(this.checkDisabled({id: key}))
        return;
      if(dataBundle[key] != null && dataBundle[key] != undefined) {
        dataBundle[key].value = utils.unformatOutput(this.returnData.conf[key], dataBundle[key].value);
      }
    },
    controlloFinale(action, data) {
        this.finale = !this.finale;
        let retVal = true;
        for(const key in this.returnData.content) {
          retVal = retVal && this.validate(key, this.returnData.content[key].value);
          retVal = retVal && this.validateNota(key + '_nota', this.returnData.conf[key].data.note, this.returnData.content[key]);
          this.unformatValue(key, this.returnData.content);
        }
        if(retVal)
          this.$emit(action, data);
        else
          return false;
    },

    validateNota(noteId, value, field) {
      let id = field.id;

      // niente validazione se il campo è eliminato
      if(this.returnData.conf[id].data.deleted) {
          return true;
      }

      let isDatumChanged = false;
      if(this.initialData) {
        if(this.initialData.content[id] && this.initialData.content[id].value != field.value) {
          isDatumChanged = true;
        }
      }

      if((value == null || value === '') && this.returnData.conf[id].config.mandatoryNote) {
        if(isDatumChanged || (!isDatumChanged && this.returnData.conf[id].data.edited)) {
          if(this.isValid[noteId] !== undefined)
            this.isValid[noteId] = false;
          if(this.errorStrings[noteId] !== undefined)
            this.errorStrings[noteId] = validation.strings.mandatoryNote;
        }
      }
      else {
          if(this.isValid[noteId] !== undefined)
            this.isValid[noteId] = true;
          if(this.errorStrings[noteId] !== undefined)
            this.errorStrings[noteId] = '';
      }
      return this.isValid[noteId];
    },


    aggiornaNota(value, field) { // validazione per nota non vuota
      // TODO controllare che funzioni ancora
        const id = field.id;
        const notaId = id + '_nota';
        this.validateNota(notaId, value, field);
    },

    validate(id, value) {
      let config = this.returnData.conf[id];
      const tab = this.getTab();
      if(this.hidden[id]) {
        this.errorStrings[id] = '';
        this.isValid[id] = true;
        return this.isValid[id];
      }
      //passo tutta la scheda per avere visibilità di tutti i dati disponibili
      let errors = validation.defaultValidation(config, value, tab, this.sch, this.returnData, this.optionalObj);
      if(errors.length > 0) {
        this.errorStrings[id] = errors[0];
        this.isValid[id] = false;
      }
      else {
        this.errorStrings[id] = '';
        this.isValid[id] = true;
      }

      return this.isValid[id];
    },
    aggiorna (field) {
      // se in base a questo valore alcuni campi possono essere nascosti
      // allora controllo la loro visibilità
      if(this.returnData.conf[field.id]) {
        const config = this.returnData.conf[field.id].config;

        if(config.canHide) {
          for(const id in this.hidden) {
            this.hidden[id] = visibility.computeHidden(id, this.returnData, this.returnData.conf[id]);
          }
        }
      }
      for(const key in this.calculate) {
        const result = calculation.calculateValue(this.returnData.conf[key], this.sch, key, this.returnData, this.optionalObj);
        this.returnData.content[key].value = result;
      }
    }
  }
}
</script>
<style>
ul {
  list-style-type:none;
}
</style>