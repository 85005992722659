<template scope="props">
    <div id="notifiche" class="elencoProgetti">
        <div class="table-title">Elenco Notifiche <span v-if="descBeneficiario !== ''"> {{descBeneficiario}}</span></div>
        <div class="table-subtitle">Ordinamento predefinito per data decrescente</div>

            <b-form inline           
            label-align-sm="left"
            class="filtro-notifiche">
            <label class="mr-sm-2" for="filterInput">Filtra risultati</label>
            <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Digita un filtro"/>
            <b-button :disabled="!filter" @click="filter = ''">Pulisci filtro</b-button>
            
            </b-form>

            <b-table :sort-desc="true" :filter="filter" 
                     :busy="isBusy" id="elencoNotifiche"
                     primary-key="dataModifica" hover
                     :items="tabledata.rows" :fields="tabledata.header" :sort-by="sortBy" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/helpers/utils.js'
import notify from "@/helpers/notifications.js"

    export default {
        name: 'elencoNotifiche',
        computed: {
             ...mapGetters({
                getDescBeneficiario: 'announcements/getDescBeneficiario',
                getBeneficiari: 'announcements/getAllBeneficiari'
            }),
        },
        components: {
        },
        data() {
            return {
                descBeneficiario: "",
                isBusy: false,
                sortBy: 'dataModifica',
                filter: null,
                tabledata: {
                    "header": [
                        {
                            key: 'testo',
                            thClass: 'thst',
                            sortDirection: 'asc',
                            sortable: true,
                            formatter: this.testoFormatter
                        },
                        {
                            key: 'dataModifica',
                            label: 'Data di Modifica',
                            thClass: 'thst',
                            sortable: true,
                            filterByFormatted: true,
                            formatter: this.dateFormatter
                        },
                        {
                            key: 'autoreModifiche',
                            thClass: 'thst'
                        },
                        {
                            key: 'tipoScheda',
                            thClass: 'thst'
                        },
                        {
                            key: 'periodo',
                            thClass: 'thst',
                        },
                        {
                            key: 'stato',
                            thClass: 'thst',
                            sortable: true,
                        },
                    ],
                    "rows": []
                }
            };
        },

        methods: {
            dateFormatter(value) {
                return utils.formatDateTime(value);
            },

            testoFormatter(value){
                let testoBeautify = value;
                
                if (testoBeautify.indexOf("Beneficiario:") !== -1 ){
                    let pos = testoBeautify.indexOf("Beneficiario:") + 14;
                    let end = testoBeautify.length;
                    let idBen = testoBeautify.substring(pos, end);
                    
                    const mappaBen = this.getBeneficiari;
                    let ben = mappaBen[idBen];
                    if (ben){
                        let descBen = ben.denominazione;
                        testoBeautify = testoBeautify.substring(0, pos) + descBen;
                    }
                }
                
                return testoBeautify;
            },
            
            caricaNotifiche: async function () {
                this.isBusy = true;
                this.notifiche = await this.$store.dispatch('storicoAzioni/retrieveStoricoAzioni', { maxFetch: 200, storeResults: true }).catch(
                    error => {
                        if (error.response != null && error.response.status != null){
                            console.log("errore dispatch storicoAzioni/retrieveStoricoAzioni, request status: " + error.response.status)
                            notify.error(
                                notify.strings.error, 
                                notify.strings.serverError + notify.strings.requestStatus(error.response.status)
                            )
                        } else {
                            console.log("errore dispatch storicoAzioni/retrieveStoricoAzioni", error);
                            notify.error(notify.strings.error, notify.strings.serverError)
                        }
                    }
                )
                this.extractData();
                this.isBusy = false;
            },
            
            extractData()
            {
                let rows = [];
                for (const notifica of this.notifiche)
                {
                    let testo = notifica.codiceProgetto;
                    if(notifica.titolo)
                        testo = notifica.titolo;
                    const row = {
                        "testo": testo,
                        "dataModifica": notifica.dataModifica,
                        "autoreModifiche": notifica.modificatoDa,
                        "tipoScheda": notifica.tipoScheda,
                        "periodo": notifica.periodo,
                        "stato": notifica.stato,
                    }
                    rows.push(row);
                }
                this.tabledata.rows = rows;
            },
        },

        mounted(){
            this.descBeneficiario = this.getDescBeneficiario();
            this.caricaNotifiche();
        }
    }

</script>
