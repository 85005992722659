<template>
    <div>
      <WrapCollapses
        :recalculateBudget="wakeUp"
        :config="keyEntityTab"
        @changeCostsInBudget="toggleCostsInBudget()"
      />
    </div>
</template>
<script>
import WrapCollapses from "@/components/WrapCollapses.vue";
export default {
    name: "budget",
    components: {
        WrapCollapses
    },
    props: {
      tabName: {
        type: String,
        value: ''
      },
      name: String,
      wakeUp: Boolean, // Prop per risveglio tab
      keyEntityTab: {
        type: Array,
        required: true
      },
      // changeCostsInBudget: Boolean
    },
    methods: {
      itsMyTurn() {
        // se è stato cliccato il tab corrispondente oppure
        // se il componente è un collapse del tab corrispondente
        return (this.tabName === 'budget');
      },
      toggleCostsInBudget() {
        this.$emit('crudCostsInBudget')
      }
    },
    /*
    computed: {
      // il budget deve essere ricalcolato in divrersi casi, quindi uso itsmyturn
      getIfItsMyTurn() {
        if (this.itsMyTurn()) {
          return this.keyEntityTab;
        }
        return [];
      }
    },
    */
    mounted() {
      this.title = this.name;
    },
    data() {
      return {
        title: '',
        configWrapCollapses: [
      ],
    };
  },
}
</script>