import { JSONPath as jpath } from 'jsonpath-plus';
import mapUtils from '@/helpers/mapUtils.js'
// import utils from '@/helpers/utils.js'
import tool from '@/helpers/tools.js'
import store from '@/store/store.js'
import { differenceInCalendarMonths, isDate, set } from 'date-fns'


const strings = {
  error: "errore",
  mandatoryNote: "Nota obbligatoria",

  mandatoryField: "Campo obbligatorio",
  mandatoryWithRifContratto: "Campo obbligatorio in presenza di un Numero Contratto valido",
  notANumber: "Formato numero errato",
  notPercentage: "Formato percentuale errato",
  mustBePositive: "Il valore deve essere maggiore di zero",
  mustBeInteger: "Il valore deve essere intero",
  mustSelectFiles: "Selezionare almeno un file",
  operationError: function(operationName) {
    if (operationName) { return operationName + ": errato." }
    return "Errato."
  },
  
  internalErrorPleaseReportAction: "Impossibile completare l'azione."
    + "<br/>Per favore, contatta il supporto indicando le azioni effettuate.",
  dataFineBeforeInizio: "La data fine non può essere anteriore alla data inizio",
  dataInizioAfterFine: "La data inizio non può essere posteriore alla data fine",
  dataInizioSpesaBeforeInizioFase: "La data inizio di una spesa non può essere precedente alla data di inizio della fase",
  dataInizioSpesaAfterFineFase: "La data inizio di una spesa non può essere posteriore alla data di fine della fase",
  dataFineSpesaAfterFineFase: "La data fine di una spesa non può essere posteriore alla data di fine della fase",
  dataFineSpesaBeforeInizioFase: "La data fine di una spesa non può essere precedente alla data di inizio della fase",
  risorseComplSupBudgetProp: "Le risorse complessive devono essere superiori al budget di singola proposta",
  budgetPropInfRisorseCompl: "Il budget di singola proposta deve essere inferiore alle risorse complessive",
  valoreNonCorretto: "Valore inserito non corretto, deve essere univoco",
  valoreDuplicato: "Valore inserito già presente, deve essere univoco",
  dataPrimaInizioProgetto: "La data deve essere posteriore a quella di inizio progetto",
  dataDopoFineProgetto: "La data deve essere anteriore a quella di fine progetto",
  importoMaggioreTotale: "Il sopraindicato importo non può essere maggiore dell'importo Totale",
  IVAminoreImponibile: "L'importo dell'IVA deve essere una cifra inferiore all'importo imponibile",
  formatTelephoneIncorrect: "Inserire un Numero di Telefono valido",
  formatPECIncorrect: "Inserire un formato PEC valido",
  formatMailIncorrect: "Inserire un formato Email valido",
  formatIBANIncorrect: "Inserire un formato IBAN valido",
  costoProgettoNegativo:"L'economia di spese non può essere maggiore del budget del progetto",
  imponibileMinoreTotale: "L'imponibile non può essere maggiore dell'importo previsto per il progetto",
  flagValidation: "Impossibile inserire, contratto diverso dalla dichiarazione di spesa corrente"
}

function checkListValidation(mapElement, value) {
  let errors = []
  if(!mapElement || !mapElement.config || !mapElement.data || !mapElement.config.type)  {
      return errors;
  }
  if(mapElement.config.mandatory && (value == undefined || value == null || value === '')) {
    errors.push(strings.mandatoryField);
    return errors;
  }
}

function controlValidation(mapElement, value, type) {
  switch(type) {
    case "esito":
    case "commenti":
      return checkListValidation(mapElement, value)
    case "chooseFiles":
      return docsRiferimentoValidation(mapElement, value)
    case "controDeduzione":
    case "deduzioneOn":
      return []
    default:
      console.error("Type di validazione non trovato", type);
      break;
  }
}

function docsRiferimentoValidation(mapElement, files) {
  let errors = []
  if(!mapElement || !files) {
    return errors
  }
  if(!mapElement.config.docsRiferimento || (mapElement.config.docsRiferimento && mapElement.config.docsRiferimento.length === 0)) {
    return errors
  }
  if(mapElement.config.docsRiferimento && mapElement.config.docsRiferimento.length > 0) {
    if (files.length === 0) {
      errors.push(strings.mustSelectFiles);
    return errors;
    }
  }

}

function defaultValidation(mapElement, value, tab, scheda, fdata, optionalObj) {
  
  let errors = []
  if(!mapElement || !mapElement.config || !mapElement.data || !mapElement.config.type)  {
      return errors;
  }

  let mainTab = mapElement.config.tab;
  if(tab && mainTab && tab !== mainTab) {
    return errors;
  }

  // Riga rimossa per BUG #7941
  //if(mapElement.config.readonly && mapElement.config.mandatory) {
    if(mapElement.config.readonly) {
    return errors;
  }

  if(mapElement.data.deleted) {
    return errors;
  }

  
  if(mapElement.config.mandatory && (value == undefined || value == null || value.toString().trim() === '')) {
      errors.push(strings.mandatoryField);
      return errors;
  }

  if(mapElement.config.validationRule) {
    //validazioni custom ed eventuali errori
    //console.log("validationRule =", mapElement.config.validationRule);
    let returnStr;
    switch(mapElement.config.validationRule) {
      case 'mandatoryIfExistRifContratto':
        if (mandatoryIfExistRifContratto(mapElement, value, fdata, scheda)){
          errors.push(strings.mandatoryWithRifContratto);
        }
        break;
      case 'ivaContrattoV1':
        returnStr = ivaContrattoV1(mapElement, value, fdata, scheda);
        if (returnStr) {
          errors.push(returnStr);
        }
        break;
      case "IVAminoreCostoProgettoV1":
        if (IVAminoreCostoProgettoV1(value, scheda)){
          errors.push(strings.IVAminoreImponibile);
        }
        break;
      case "risorseComplessive":
        if (risorseComplessive(mapElement, value, scheda.schedaBando)){
          errors.push(strings.risorseComplSupBudgetProp);
        }
        break;
      case "budgetProposta":
        if (budgetProposta(mapElement, value, scheda.schedaBando)){
          errors.push(strings.budgetPropInfRisorseCompl);
        }
        break;
      case "dataInizioBando":
        if (dataInizioBando(mapElement, value, scheda)){
          errors.push(strings.dataInizioAfterFine);
        }
        break;
        case "checkCorrectTelephone":
          if (checkCorrectTelephone(mapElement, value, scheda)){
            errors.push(strings.formatTelephoneIncorrect);
          }
        break;
        case "checkCorrectPec":
          if (checkCorrectEmail(mapElement, value, scheda)){
            errors.push(strings.formatPECIncorrect);
          }
        break;
        case "checkCorrectEmail":
          if (checkCorrectEmail(mapElement, value, scheda)){
            errors.push(strings.formatMailIncorrect);
          }
        break;
        case "checkCorrectIban":
          if (checkCorrectIban(mapElement, value, scheda)){
            errors.push(strings.formatIBANIncorrect);
          }
        break;
      case "dataFineBando":
        if (dataFineBando(mapElement, value, scheda)){
          errors.push(strings.dataFineBeforeInizio);
        }
        break;
      case "dataInizioPosterioreFineV1":
        if (dataInizioPosterioreFineV1(mapElement, value, scheda, fdata)){
          errors.push(strings.dataInizioAfterFine);
        }
        break;
      case "dataFinePrecedenteInizioV1":
        if (dataFinePrecedenteInizioV1(mapElement, value, scheda, fdata)){
          errors.push(strings.dataFineBeforeInizio);
        }
        break;
      case "dataInizioSpesaInclusaNellaFaseV1":
        if (dataInizioSpesaPrecedenteInizioFaseV1(mapElement, value, scheda, fdata)){
          errors.push(strings.dataInizioSpesaBeforeInizioFase);
        }
        if (dataInizioSpesaPosterioreFineFaseV1(mapElement, value, scheda, fdata)){
          errors.push(strings.dataInizioSpesaAfterFineFase);
        }
        if (dataInizioPosterioreFineV1(mapElement, value, scheda, fdata)){
          errors.push(strings.dataInizioAfterFine);
        }
        break;
      case "dataFineSpesaInclusaNellaFaseV1":
        if (dataFineSpesaPosterioreFineFaseV1(mapElement, value, scheda, fdata)){
          errors.push(strings.dataFineSpesaAfterFineFase);
        }
        if (dataFineSpesaPrecedenteInizioFaseV1(mapElement, value, scheda, fdata)){
          errors.push(strings.dataFineSpesaBeforeInizioFase);
        }
        if (dataFinePrecedenteInizioV1(mapElement, value, scheda, fdata)){
          errors.push(strings.dataFineBeforeInizio);
        }
        break;
      case "uniqueInArray":
        if (uniqueInArray(mapElement, value, scheda, fdata)){
          errors.push(strings.valoreDuplicato);
        }
        break;
      case "importoISFmaggioreTotale":
        returnStr = importoISFmaggioreTotale(mapElement, value, scheda, fdata);
        if (returnStr){
          errors.push(returnStr);
        }
        break;
      case "importoFatturaISFmaggioreTotale":
        if (importoFatturaISFmaggioreTotale(mapElement, value, scheda, fdata)){
          errors.push(strings.importoMaggioreTotale)
        }
        break;
      case "importoContrattoISFmaggioreTotaleV1":
        if (importoContrattoISFmaggioreTotaleV1(mapElement, value, scheda, fdata)){
          errors.push(strings.importoMaggioreTotale)
        }
        break;
      case "ivaFatturaV1":
        if (ivaFatturaV1(mapElement, value, fdata, scheda)){
          errors.push(strings.IVAminoreImponibile);
          //errors.push(strings.importoMaggioreTotale)
        }
        break;
      // ESPERIENZE PREGRESSE (PROGETTO)
      case "dataInizioEspPregressa":
        if (dataInizioEspPregressa(mapElement, value, scheda, fdata)){
          errors.push(strings.dataInizioAfterFine);
        }
        break;
      case "dataFineEspPregressa":
        if (dataFineEspPregressa(mapElement, value, scheda, fdata)){
          errors.push(strings.dataFineBeforeInizio);
        }
        break;
      case "imponibileDichiarazioneV1":   //funzione usata nei controlli fase 2
        if (imponibileDichiarazioneV1(value, scheda)) {
          errors.push(strings.imponibileMinoreTotale);
        }
        break;
      case "ivaDichiarazioneV1":   //funzione usata nei controlli fase 2
        if (ivaDichiarazioneV1(mapElement, value, scheda, fdata)) {
          errors.push(strings.IVAminoreImponibile);
        }
      break;
      
      case "pagamentiFlagValidationV1": 
        if (pagamentiFlagValidationV1(value, fdata, optionalObj)) {
          errors.push(strings.flagValidation);
        }
      break;
      
      case"costoProgettoNegativo":
        if(costoProgettoNegativo(scheda, value)){
          errors.push(strings.costoProgettoNegativo);
        }
        break;
      default:
        console.error("regola di validazione non trovata", mapElement.config.validationRule);
        break;
    }
  }
  let returnStr;
  switch(mapElement.config.type) {
      case 'number': // TODO eliminare questo tipo
      case 'float':
        if(notANumber(value)) {
          errors.push(strings.notANumber);
        }
        break;
      case 'percent': {
          returnStr = notAPercentage(value);
          if(returnStr) {
            errors.push(returnStr);
          }
        }
        break;
      case 'int':
        if(!tool.isInt(value))
          errors.push(strings.mustBeInteger);
        else if(value <= 0) {
          errors.push(strings.mustBePositive);
        }
        break;
      case 'currency':
        returnStr = notACurrency(value);
        if(returnStr) {
          errors.push(returnStr);
        }
        break;
      case 'date':
        if (dataPrimaInizioProgetto(mapElement, value, scheda)){
          errors.push(strings.dataPrimaInizioProgetto);
        }
        if (dataDopoFineProgetto(mapElement, value, scheda)){
          errors.push(strings.dataDopoFineProgetto);
        }
        break;
      // TODO spostare nella sezione mandatory
      case 'enumstring':
        if(!value || value.length === 0)
          errors.push(strings.mandatoryField);
        break;
      default:
        break;
  }

  return errors;
}


function notANumber(value) {
  return value != null && value != undefined && value.toString().trim().length > 0 && (isNaN(value) || isNaN(parseFloat(value)));
}
// WARNING: ritorna false o la stringa di errore, NON TRUE!
function notAPercentage(value) {
  if(value != null && value != undefined && value.toString().trim().length > 0) {
    const parsedVal = parseFloat(value);
    if(isNaN(value) || isNaN(parsedVal)) {
      return strings.notANumber;
    }

    if(parsedVal < 0 || parsedVal > 100) {
      return strings.notPercentage;
    }
  }

  return false;
}
// WARNING: ritorna false o la stringa di errore, NON TRUE!
function notACurrency(value) {
  if(value != null && value != undefined && value.toString().trim().length > 0) {
    const parsedVal = parseFloat(value);
    if(isNaN(value) || isNaN(parsedVal)) {
      return strings.notANumber;
    }

    if(parsedVal < 0) {
      return strings.mustBePositive;
    }
  }
  return false;
}

function substancialValidation(id, schedaMonitoraggio, mapElement) {
  if(!schedaMonitoraggio 
    || !schedaMonitoraggio.dataEntryConfiguration
    || !id) {
    return false;
  }

  if(!mapElement)
    mapElement = mapUtils.getMapElement(schedaMonitoraggio.dataEntryConfiguration, id);

  if(!mapElement.config || !mapElement.config.canBeSubstancial)
    return false;

  let functionName = mapElement.config.substancialRule;

  if(!functionName)
    return false;
  let cutoff;
  switch(functionName) {
    case 'budgetRimodulatoV1':
      return budgetRimodulatoV1(id, schedaMonitoraggio);
    case 'alwaysTrueV1':
      return alwaysTrueV1();
    case 'percent30V1':
        cutoff = 30/100;
        return percentV1(id, schedaMonitoraggio, cutoff);
    case 'percent15V1':
        cutoff = 15/100;
        return percentV1(id, schedaMonitoraggio, cutoff);
    case 'dataFineProgettoPercent30V1':
        return dataFineProgettoPercent30V1(id, schedaMonitoraggio);
    case 'dataInizioFasePercent30V1':
    case 'dataFineFasePercent30V1':
      cutoff = 30/100;
      return checkFasePercentV1(id,schedaMonitoraggio, cutoff);
    // funzioni obsolete per retrocompatibilità
    case 'budgetRimodulatoV01':
      return budgetRimodulatoV01(id, schedaMonitoraggio);
    case 'dataFineProgettoPercent30V01':
      return dataFineProgettoPercent30V01(id, schedaMonitoraggio);
    case 'checkTransition':
      return controllaTransizioneV1(id,schedaMonitoraggio);
    default:
      console.error("funzione di modifica sostanziale non gestita", functionName);
      return false;
  }
}

function substancialValidationProgetto(id, schedaProgetto, mappa, valueCurrentToCompare) {
  const idWithStars = mapUtils.getGenericArrayKey(id);
  if(!schedaProgetto 
    || !id
    || !mappa
    || !mappa[idWithStars]
    || !mappa[idWithStars].config.canBeSubstancial) {
    return false;
  } 
  let mapElement = mapUtils.getMapElement(mappa, idWithStars);
  if (mapElement) { //per il momento mi basta sapere che il campo con chiave id è presente nella mappa del progetto
    //console.error("substancial element=", id);
    let functionName = mapElement.config.substancialRule;
    if(!functionName){
      console.error("NO functionName!");
      functionName = ''
    }
    let cutoff;
    switch(functionName) {
      case 'durataCambiataPercent30V1':
        cutoff = 30/100;
        return durataCambiataPercent30V1(id, schedaProgetto, mappa, cutoff, valueCurrentToCompare);
      case 'alwaysTrueV2':
        return alwaysTrueV2(id, schedaProgetto, valueCurrentToCompare);
      case 'percent15V1':
          cutoff = 15/100;
          return percentV1(id, schedaProgetto, cutoff);
      case 'checkTransition':
          return controllaTransizioneV1(id, schedaProgetto);
      default:
        console.error("funzione di modifica sostanziale non gestita", functionName);
        return false;
    }
  }
  return false;
}
// WARNING: al contrario delle altre, questa funzione restituisce false oppure la stringa di errore
// (NON RESTITUISCE TRUE!!!)
function mandatoryIfExistRifContratto(mapElement, value, fdata, scheda) {
  //CAMPI DI MONITORAGGIO CHE DIVENTANO OBBLIGATORI QUANDO ESISTE UN NUMERO CONTRATTO
  if(!mapElement || !fdata || !fdata.content) {
    return false;
  }
  const regx = /\['\w+'\]$/
  const id_riferim_contratto = mapElement.config.path.replace(regx, "['riferimentoContratto']");
  let idToCheck = mapUtils.getCronoprogId(fdata.rowId, id_riferim_contratto)
  let valuesMatch = fdata.content[idToCheck] && fdata.content[idToCheck].value ? fdata.content[idToCheck].value : jpath('$'+ idToCheck, scheda)[0]
  // L'importo ANAC deve avere lo stesso trattamento dell'Importo a valere su ISF
  // Questo controllo è fondamentale vista la medesima validationRule settata nel tabellone (CronoProg)
  if(!mapElement.config.path.includes('importoANAC')) {
    // se esiste un numero di contratto valido e il valore del campo
    // è null, undefined, oppure vuoto, allora ritorno la stringa di errore
    if( valuesMatch && valuesMatch.toString().trim() !== "" && 
      (value == null || value == undefined || value.toString().trim().length === 0 )) {
      return strings.mandatoryWithRifContratto;
    }
  }
  return false
}

function pagamentiFlagValidationV1(value, fdata, optionalObj) {
  const rifContrId = "['contratti'][*]['content']['riferimentoContratto']";
  if(fdata.content[rifContrId]) {
    const val = fdata.content[rifContrId].value;
    // se il pagamento ha un contratto definito che è diverso
    // da quello della dichiarazione di spesa corrente, 
    // allora non si può aggiugnere alla suddetta dichiarazione e
    // quindi deve essere disabilitato
    // console.log(val, optionalObj.cronoprogItemId );
    if(value && val && val !== mapUtils.nonDefinito.key && optionalObj.cronoprogItemId !== val) {
      return true;
    }
  }
  return false;
}
// WARNING: questa funzione torna false oppure la stringa di errore, NON TRUE!
function ivaContrattoV1(mapElement, currentValue, fdata, scheda_cronoProg) {
  if(!mapElement || !fdata || !fdata.content) {
    return false;
  }
  if(fdata) {
    const check = mandatoryIfExistRifContratto(mapElement, currentValue, fdata, scheda_cronoProg);
    if(check) {
      return check;
    }
  }
  const ivaParsed = parseFloat(currentValue);
  if(isNaN(ivaParsed))
    return false;
  const regx = /\['\w+'\]$/
  const id_importoContratto = mapElement.config.path.replace(regx, "['importoContratto']")
  const check1 = compare_value_to_field_by_id(id_importoContratto, currentValue, scheda_cronoProg, fdata);
  if(check1)
    return strings.IVAminoreImponibile;
}

function ivaFatturaV1(mapElement, currentValue, fdata, scheda_cronoProg) {
  if(!mapElement || !fdata || !fdata.content) {
    return false;
  }
  const ivaParsed = parseFloat(currentValue)
  if(isNaN(ivaParsed))
    return false;
  const regx = /\['\w+'\]$/;
  const id_imponibileFattura = mapElement.config.path.replace(regx, "['imponibileFattura']")
  return compare_value_to_field_by_id(id_imponibileFattura, currentValue, scheda_cronoProg, fdata);
}

function alwaysTrueV2(id, schedaProgetto, currentValue) {
  const idProgetto = schedaProgetto.progetto.idProgetto
  const schedaOriginale = store.state.progetto.schedeProgetto[idProgetto].schedaOriginale;
  const temp = jpath('$' + id, schedaOriginale);
  return (temp && temp.length === 1 && temp[0] !== currentValue)
}

function durataCambiataPercent30V1(id, schedaProgetto, mappa, cutoff, durataCorrente) {
  if(!id || !schedaProgetto || !mappa || !cutoff || !durataCorrente) {
    return false;
  }
  const idProgetto = schedaProgetto.progetto.idProgetto
  const schedaOriginale = store.state.progetto.schedeProgetto[idProgetto].schedaOriginale;
  const durataProgettoId = "$['progetto']['durataProgettoMesi']"
  const temp = jpath(durataProgettoId, schedaOriginale)
  
  // Durata Originale ( prima di qualsiasi modifica alle fasi )
  let durataOriginale = temp && temp.length === 1 ? parseFloat(temp[0]) : 0
  // Calcolo la diff come valore assoluto delle date
  if(durataOriginale !== 0) {
    let diff = (durataOriginale > durataCorrente) ? (durataOriginale - durataCorrente) : (durataCorrente - durataOriginale);
    let diffPercentage = diff !== 0 ? diff / durataOriginale : 0
    return diffPercentage !== 0 && parseFloat(diffPercentage) >= cutoff ? true : false
  }
  return false
}

function IVAminoreCostoProgettoV1(value, scheda) {
  if(!value || !scheda) {
    return false;
  }

  let costoParsed = parseFloat(value)
  if(isNaN(costoParsed))
    return false;

  const costoProgettoId = "$['progetto']['costoProgetto']";
  let costoProgetto = 0;
  const temp = jpath(costoProgettoId, scheda);
  if (temp && temp.length === 1)
    costoProgetto = temp[0];
  const costoProgParsed = parseFloat(costoProgetto)

  if(isNaN(costoProgParsed))
    return true;

  return costoParsed > costoProgParsed
}

function risorseComplessive(mapElement, value, scheda) {
  if(mapElement && value && scheda) {
    //*** Validazione per risorse Complessive Bando ['bando']['risorse']
    const risorseComplessive1 = parseFloat(value);
    const budgetId = "$['bando']['budgetMassimo']";
    
    const temp = jpath(budgetId, scheda);
    let budgetMax;
    if(temp && temp.length === 1) {
      budgetMax = parseFloat(temp[0]);
    }
      
    if(budgetMax && !isNaN(budgetMax) && !isNaN(risorseComplessive1)) {
      // Message Error: Risorse Complessive > Budget di singola proposta
      return budgetMax > risorseComplessive1;
    }
  }
  return false;

}

function budgetProposta(mapElement, value, scheda) {
  if(mapElement && value && scheda) {
    //*** Validazione per risorse Budget Singola Proposta Bando ['bando']['budgetMassimo']
    const budgetMax = parseFloat(value);
    const risorseId = "$['bando']['risorse']";

    const temp = jpath(risorseId, scheda);
    let risorseComplessive1;
    if(temp && temp.length === 1) {
      risorseComplessive1 = parseFloat(temp[0]);
    }

    if(risorseComplessive1 && !isNaN(risorseComplessive1)
       && !isNaN(budgetMax)) {
      // Message Error: Budget di singola proposta < Risorse Complessive
      return budgetMax > risorseComplessive1;
    }
  }
  return false;
}

function alwaysTrueV1() {
  return true;
}

function dataFineProgettoPercent30V01(id, schedaMonitoraggio) { // versione iniziale
  try {
    let oldValue = schedaMonitoraggio.dataEntryConfiguration[id].data.oldValue;
    if(oldValue == null) 
      return false;
    let newValue = jpath('$'+id, schedaMonitoraggio)[0];
    newValue = new Date(newValue).getTime();
    oldValue = new Date(oldValue).getTime();

    let delta = (newValue - oldValue) / (1000 * 3600 * 24 * 30); // delta in mesi
    let durataProgetto = jpath("$['progetto']['durataProgettoMesi']", schedaMonitoraggio)[0];
    const cutoff = 30/100;
    const percent = delta / durataProgetto;
    if(percent > cutoff)
        return true;
  }
  catch(err) {
    console.log('error dataFineProgettoPercent30V1', id);
  }
  return false;

}

function dataFineProgettoPercent30V1(id, schedaMonitoraggio) {
  try {
    let oldValue = schedaMonitoraggio.dataEntryConfiguration[id].data.oldValue;
    if(oldValue == null) 
      return false;
    let newValue = jpath('$'+id, schedaMonitoraggio)[0];
    newValue = new Date(newValue).getTime();
    oldValue = new Date(oldValue).getTime();

    let delta = (newValue - oldValue) / (1000 * 3600 * 24 * 30); // delta in mesi
    let durataProgettoId = "['progetto']['durataProgettoMesi']";
    let durataProgetto;
    if(schedaMonitoraggio.dataEntryConfiguration[durataProgettoId].data.edited) {
      durataProgetto = parseInt(schedaMonitoraggio.dataEntryConfiguration[durataProgettoId].data.oldValue);
    }
    else {
      durataProgetto = jpath('$' + durataProgettoId, schedaMonitoraggio)[0];
    }

    if(isNaN(durataProgetto) || isNaN(delta)) {
      console.error("dataFineProgettoPercent30V1: errata conversione dati", schedaMonitoraggio);
      return false;
    }
    
    const cutoff = 30/100;
    const percent = delta / durataProgetto;
    if(percent > cutoff)
        return true;
  }
  catch(err) {
    console.log('error dataFineProgettoPercent30V1', id);
  }
  return false;

}

function checkFasePercentV1(id, schedaMonitoraggio, cutoff) {
  const baseVersion = store.state.monitoraggio.baseVersion;
  if(!baseVersion) {
    return false;
  }

  const durataId = "['progetto']['durataProgettoMesi']";
  const fineProgettoId = "['progetto']['dataConclusione']";

  try {
    const baseDurata = jpath('$' + durataId, baseVersion)[0];
    const baseFineProgetto = jpath('$' + fineProgettoId, baseVersion)[0];
    

    const inizio = jpath('$'+id, schedaMonitoraggio)[0];

    if(!inizio || !baseDurata || !baseFineProgetto)
      return false;

    const newInizio = new Date(inizio);

    const fineProgetto = new Date(baseFineProgetto);
    const durata = parseInt(baseDurata);
    if( !isDate(newInizio) || 
        !isDate(fineProgetto) || 
        !tool.isInt(durata)) {
      return false;
    }
    

    const deltaMesi = differenceInCalendarMonths(newInizio, fineProgetto);

    

    const percent = deltaMesi * 1.0 / durata;


    return percent > cutoff;
  }
  catch(err) {
    console.log('error checkFasePercentV1', id);
  }
  return false;
}

function percentV1(id, schedaMonitoraggio, cutoff) {
    let values = jpath('$'+id, schedaMonitoraggio);
    if(values.length === 0){
      console.error('valore non trovato in percentV1', id, schedaMonitoraggio);
      return false;
    }
    let newValue = values[0];

    try {
      let oldValue = schedaMonitoraggio.dataEntryConfiguration[id].data.oldValue;
      newValue = parseFloat(newValue);
      
      oldValue = parseFloat(oldValue);
      if(isNaN(newValue || isNaN(oldValue))) {
        console.error('impossibile validare in percentV1', oldValue, newValue);
        return false;
      }

      const percent = (newValue-oldValue) / oldValue;
      if(percent > cutoff)
        return true;
    }
    catch(err) {
        console.log('error percent30V1', newValue);
    }
    return false;
}


function budgetRimodulatoV1(id, schedaMonitoraggio) {
  
  let budgetIniziale = schedaMonitoraggio.budgetIniziale;
  let budgetToCompare = budgetIniziale;
  let currentIndexTemp = id.match(/\b\d+\b/g)[0];
  let currentIndex;
  if(!isNaN(currentIndexTemp))
    currentIndex = parseInt(currentIndexTemp);


  if(currentIndex == undefined || currentIndex <= 0) {
    // ("budget rimodulato non presente, confronto con budget iniziale");
  }
  else {
    let indexToCompare = currentIndex - 1;
    let newId = id.replace(/\b\d+\b/g, indexToCompare);
    budgetToCompare = jpath('$'+newId, schedaMonitoraggio)[0];
  }

  if(!budgetToCompare) {
    console.error('budget da confrontare non trovato', schedaMonitoraggio);
    return false;
  }

  let budgetRimodulato = jpath('$'+id, schedaMonitoraggio)[0];
  if(!budgetRimodulato) {
    console.error('budget rimodulato non trovato', schedaMonitoraggio);
    return false;
  }

  try {
    budgetToCompare = parseFloat(budgetToCompare);
    budgetRimodulato = parseFloat(budgetRimodulato);
    if(isNaN(budgetToCompare) || isNaN(budgetRimodulato)) {
      console.error('impossibile validare in budgetRimodulatoV1', budgetToCompare, budgetRimodulato);
      return false;
    }
    const cutoff = 15/100;
    const percent = (budgetRimodulato - budgetToCompare) / budgetToCompare;

    if(percent > cutoff)
      return true;
  }
  catch(err) {
      console.log('error budgetRimodulatoV1', budgetToCompare, budgetRimodulato);
  }

  return false;
}


function budgetRimodulatoV01(id, schedaMonitoraggio) { // versione iniziale
    let budgetIniziale = schedaMonitoraggio.budgetIniziale;

    if(!budgetIniziale) {
      console.error('budget iniziale non trovato', schedaMonitoraggio);
      return false;
    }
      

    let budgetRimodulato = jpath('$'+id, schedaMonitoraggio)[0];
    if(!budgetRimodulato) {
      console.error('budget rimodulato non trovato', schedaMonitoraggio);
      return false;
    }

    try {
      budgetIniziale = parseFloat(budgetIniziale);
      budgetRimodulato = parseFloat(budgetRimodulato);
      if(isNaN(budgetIniziale) || isNaN(budgetRimodulato)) {
        console.error('impossibile validare in budgetRimodulatoV1', budgetIniziale, budgetRimodulato);
        return false;
      }
      const cutoff = 15/100;
      const percent = (budgetRimodulato - budgetIniziale) / budgetIniziale;

      if(percent > cutoff)
        return true;
    }
    catch(err) {
        console.log('error budgetRimodulatoV1', budgetIniziale, budgetRimodulato);
    }

    return false;
}

// FUNZIONI FORMATTER (TELEFONO, PEC, MAIL, IBAN )
function checkCorrectTelephone(mapElement, value, scheda) {
  const regexCorrectPhone = '^[0-9]*$'
  let formatNoValid = true;
  if(mapElement && value && scheda) {
    formatNoValid = value.match(regexCorrectPhone) == null ? true : false
  }
  return formatNoValid
}

function checkCorrectEmail(mapElement, value, scheda) {
  const regexCorrectEmail = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'
  let formatNoValid = true
  if(mapElement && value && scheda) {
    formatNoValid = value.match(regexCorrectEmail) == null ? true : false
  }
  return formatNoValid
}
function checkCorrectIban(mapElement, value, scheda) {
  const regexCorrectIban = '^[A-Za-z0-9]{27}$' // viene controllato solo che il num di caratteri sia = 27
  let formatNoValid = true
  if(mapElement && value && scheda) {
    formatNoValid = value.match(regexCorrectIban) == null ? true : false
  }
  return formatNoValid
}

function dataInizioBando(mapElement, value, scheda) {
  if(mapElement && value && scheda) {
    if (mapElement.config.path.includes("['dataInizioProposte']")) {
      //*** Validazione per data inizio Bando ['bando']['dataInizioProposte']
      let dataInizioBando1 = new Date(value);
      let dataFineBando1 = scheda.schedaBando.bando.dataFineProposte != null
        ? new Date(scheda.schedaBando.bando.dataFineProposte)
        : null
      if(dataFineBando1 && dataInizioBando1 > dataFineBando1) {
        // Message Error: Data Inizio <= Data Fine
        return true
      }
    }
  }
  return false;
}

function dataInizioEspPregressa(mapElement, value, scheda, fdata) {
  if(mapElement && value && scheda) {
    let startExperienceValue = value
    let endExperienceValue = []
    let modelCurrentInModal = fdata.content
    
    Object.keys(modelCurrentInModal).forEach(key => {
      if(key.indexOf('fineSvolgimento') !== -1) {
        endExperienceValue.push(modelCurrentInModal[key].value)
      }
    })
    if (endExperienceValue.length === 1) {
      if(endExperienceValue[0] === "") {
        return false
      } else {
        let dataFineEsperienza = new Date(endExperienceValue[0]).getFullYear();
        let dataInizioEsperienza = new Date(startExperienceValue).getFullYear();
        return dataInizioEsperienza > dataFineEsperienza
      } 
    }
  }
  return false
}

function dataFineEspPregressa(mapElement, value, scheda, fdata) {
  if(mapElement && value && scheda) {
    let endExperienceValue = value
    let startExperienceValue = []
    let modelCurrentInModal = fdata.content
    // console.log('modelCurrentInModal', modelCurrentInModal)
    Object.keys(modelCurrentInModal).forEach(key => {
      if(key.indexOf('inizioSvolgimento') !== -1) {
        startExperienceValue.push(modelCurrentInModal[key].value)
      }
    })
    if (startExperienceValue.length === 1) {
      if(startExperienceValue[0] === "") {
        return false
      } else {
        let dataFineEsperienza = new Date(endExperienceValue).getFullYear();
        let dataInizioEsperienza = new Date(startExperienceValue[0]).getFullYear();
        return dataFineEsperienza < dataInizioEsperienza
      } 
    }
  }
  return false
}

function dataFineBando(mapElement, value, scheda) {
  if(mapElement && value && scheda) {
    
    if (mapElement.config.path.includes("['dataFineProposte']")) {
      //*** Validazione per data fine Bando ['bando']['dataFineProposte']
      const dataFine = new Date(value)
      let dataInizio = scheda.schedaBando.bando.dataInizioProposte != null
      ? new Date(scheda.schedaBando.bando.dataInizioProposte)
      : null
      if(dataInizio && dataFine < dataInizio) {
        // Message Error: Data Fine => Data Inizio
        return true
      }
    }
  }
  return false;
}
/**
 * date comparison
 * @param {Date} earlierDate the supposedly earlier date
 * @param {Date} laterDate the supposedly later date
 * @returns true if earlierDate is really earlier than laterDate, false otherwise
 */
const checkDate = function(earlierDate, laterDate) {
  const zero = {hours: 0, minutes: 0, seconds: 0, milliseconds: 0};
  const earlierDate1 = set(earlierDate, zero);
  const laterDate1 = set(laterDate, zero);

  return earlierDate1 < laterDate1;
}

function dataFinePrecedenteInizioV1(mapElement, value, scheda, fdata){
  if(mapElement && scheda){
    //ricavo la chiave=path della dataInizio da quello di dataFine
    let path2 = null;
    let value2 = null;

    if (mapElement.config.path.includes("['dataFine']")){
      //ricavo la chiave=path della dataFine
      path2 = mapElement.config.path.replace("dataFine", "dataInizio");
      value2 = fdata.content[path2].value;
    } else if (mapElement.config.path.includes("['a']")) {
      //ricavo la chiave=path della data inizio
      path2 = mapElement.config.path.replace("['a']", "['da']");
      value2 = fdata.content2[path2].value;
    }
    // se una delle due date è nulla non eseguo il controllo
    if(!value || !value2)
      return false;

    //converto le due stringhe in date
    let dataInizio = new Date(value2);
    let dataFine = new Date(value);
    // se la data fine è precedente a data inizio restituisce errore
    if (dataInizio && dataFine && dataInizio!="Invalid Date" && dataFine!="Invalid Date"
        && checkDate(dataFine, dataInizio)){
          
      return true;
    }
  }
  return false;
}

function dataInizioPosterioreFineV1(mapElement, value, scheda, fdata){
  let path2 = null;
  let value2 = null;

  if(mapElement && value && scheda){
  
    if (mapElement.config.path.includes("['dataInizio']")){
      //ricavo la chiave=path della dataFine
      path2 = mapElement.config.path.replace("['dataInizio']", "['dataFine']");
      value2 = fdata.content[path2].value;
    } else if (mapElement.config.path.includes("['da']")) {
      //ricavo la chiave=path della data fine
      path2 = mapElement.config.path.replace("['da']", "['a']");
      value2 = fdata.content2[path2].value;
    }

    // se una delle due date è nulla non eseguo il controllo
    if(!value2)
      return false;
    
    //converto le due stringhe in date
    let dataFine = new Date(value2);
    let dataInizio = new Date(value);
    // se la data fine è precedente a data inizio restituisce errore
    if (dataInizio && dataFine && dataInizio!="Invalid Date" && dataFine!="Invalid Date"
        && checkDate(dataFine, dataInizio)){
      return true;
    }
  }

  return false;
}

function dataInizioSpesaPrecedenteInizioFaseV1(mapElement, value, scheda, fdata){
  let path2 = null;
  let value2 = null;

  if (mapElement && value && scheda){
  
    if (mapElement.config.path.includes("['da']")) {
      //dalla chiave della data 'da' ricavo la 'dataInizio' della fase, ad esempio:
      //['progetto']['cronoProgramma'][0]['dettaglioFase'][0]['spese'][0]['da']
      //['progetto']['cronoProgramma'][0]['dettaglioFase'][0]['dataInizio']
      path2 = mapElement.config.path;
      let index = path2.lastIndexOf('spese');
      let subStr = path2.substring(0, index-2);
      path2 = subStr+"['dataInizio']";
      value2 = fdata.content[path2].value;
    }

    // se una delle due date (stringa) è nulla non eseguo il controllo
    if (!value2){
      console.error("dataInizioSpesaPrecedenteInizioFaseV1: manca uno dei due valori da confrontare!");
      return false;
    }
    
    //converto le due stringhe in date
    let dataInizioFase = new Date(value2);
    let dataInizioSpesa = new Date(value);
    // se la data inizio spesa è precedente alla data inizio fase restituisce errore
    if (dataInizioFase && dataInizioSpesa 
        && dataInizioFase!="Invalid Date" && dataInizioSpesa!="Invalid Date"
        && checkDate(dataInizioSpesa, dataInizioFase)){
      return true;
    }
  } else {
    console.error("mapelement, value or scheda is null or undefined!");
  }

  return false;
}

function dataInizioSpesaPosterioreFineFaseV1(mapElement, value, scheda, fdata){
  let path2 = null;
  let value2 = null;

  if (mapElement && value && scheda){
  
    if (mapElement.config.path.includes("['da']")) {
      //dalla chiave della data 'da' ricavo la 'dataInizio' della fase, ad esempio:
      //['progetto']['cronoProgramma'][0]['dettaglioFase'][0]['spese'][0]['da']
      //['progetto']['cronoProgramma'][0]['dettaglioFase'][0]['dataInizio']
      path2 = mapElement.config.path;
      let index = path2.lastIndexOf('spese');
      let subStr = path2.substring(0, index-2);
      path2 = subStr+"['dataFine']";
      value2 = fdata.content[path2].value;
    }

    // se una delle due date (stringa) è nulla non eseguo il controllo
    if (!value2){
      console.error("dataInizioSpesaPosterioreFineFaseV1: manca uno dei due valori da confrontare!");
      return false;
    }
    
    //converto le due stringhe in date
    let dataFineFase = new Date(value2);
    let dataInizioSpesa = new Date(value);
    // se la data fine fase è precedente alla data inizio spesa restituisce errore
    if (dataFineFase && dataInizioSpesa 
        && dataFineFase!="Invalid Date" && dataInizioSpesa!="Invalid Date"
        && checkDate(dataFineFase, dataInizioSpesa)){
      return true;
    }
  } else {
    console.error("mapelement, value or scheda is null or undefined!");
  }

  return false;
}

function dataFineSpesaPosterioreFineFaseV1(mapElement, value, scheda, fdata){
  let path2 = null;
  let value2 = null;

  if (mapElement && value && scheda){
  
    if (mapElement.config.path.includes("['a']")) {
      //dalla chiave della data 'a' ricavo la 'dataFine' della fase, ad esempio:
      //['progetto']['cronoProgramma'][0]['dettaglioFase'][0]['spese'][0]['a']
      //['progetto']['cronoProgramma'][0]['dettaglioFase'][0]['dataFine']
      path2 = mapElement.config.path;
      let index = path2.lastIndexOf('spese');
      let subStr = path2.substring(0, index-2);
      path2 = subStr+"['dataFine']";
      value2 = fdata.content[path2].value;
    }

    // se una delle due date (stringa) è nulla non eseguo il controllo
    if (!value2){
      console.error("dataFineSpesaPosterioreFineFaseV1: manca uno dei due valori da confrontare!");
      return false;
    }
    
    //converto le due stringhe in date
    let dataFineFase = new Date(value2);
    let dataFineSpesa = new Date(value);
    // se la data fine fase è precedente alla data fine spesa restituisce errore
    if (dataFineFase && dataFineSpesa 
        && dataFineFase!="Invalid Date" && dataFineSpesa!="Invalid Date"
        && checkDate(dataFineFase, dataFineSpesa)){
      return true;
    }
  } else {
    console.error("mapelement, value or scheda is null or undefined!");
  }

  return false;
}

function dataFineSpesaPrecedenteInizioFaseV1(mapElement, value, scheda, fdata){
  let path2 = null;
  let value2 = null;

  if (mapElement && value && scheda){
  
    if (mapElement.config.path.includes("['a']")) {
      //dalla chiave della data 'a' ricavo la 'dataFine' della fase, ad esempio:
      //['progetto']['cronoProgramma'][0]['dettaglioFase'][0]['spese'][0]['a']
      //['progetto']['cronoProgramma'][0]['dettaglioFase'][0]['dataFine']
      path2 = mapElement.config.path;
      let index = path2.lastIndexOf('spese');
      let subStr = path2.substring(0, index-2);
      path2 = subStr+"['dataInizio']";
      value2 = fdata.content[path2].value;
    }

    // se una delle due date (stringa) è nulla non eseguo il controllo
    if (!value2){
      console.error("dataFineSpesaPrecedenteInizioFaseV1: manca uno dei due valori da confrontare!");
      return false;
    }
    
    //converto le due stringhe in date
    let dataInizioFase = new Date(value2);
    let dataFineSpesa = new Date(value);
    // se la data fine spesa è precedente alla data inizio fase restituisce errore
    if (dataInizioFase && dataFineSpesa 
        && dataInizioFase!="Invalid Date" && dataFineSpesa!="Invalid Date"
        && checkDate(dataFineSpesa, dataInizioFase)){
      return true;
    }
  } else {
    console.error("mapelement, value or scheda is null or undefined!");
  }

  return false;
}

function uniqueInArray(mapElement, value, scheda, fdata) {
  if(!mapElement || !value || value === "" || !scheda){
    console.error("uniqueInArray: NULL param passed", mapElement, value, scheda);
    return false;
  }
  if(value.toString().trim().length === 0)
    return false;
  let key = mapElement.config.path;
  //dalla chiave passata ricavo la chiave generica con *
  let genericKey = mapUtils.getGenericArrayKey(key);
  //console.log("genericKey", genericKey);
  //cerco nella scheda i valori presenti nell'array
  let objs = mapUtils.getSchedaValuesFromMapElementPlus(genericKey, scheda);
  let conf
  if(fdata && fdata.rowId)
    key = mapUtils.getCronoprogId(fdata.rowId, key)
  else {
    conf = scheda.dataEntryConfiguration
  }
    
  //ciclo per tutti i valori
  for (const obj of objs) {
    //console.log("item",objs[i]);
    //salto il valore legato alla chiave originale
    const path = obj.path.substr(1);
    if(path.includes(key))
      continue;
    // escludo i valori eliminati
    if(conf && conf[path] && conf[path].data.deleted) {
      continue;
    }
    //se trovo un duplicato ritorno true
    if(obj.value && obj.value.toString().trim() === value.toString().trim()) {
      return true;
    }
  }
  
  //altrimenti nessun valore duplicato trovato nell'array
  return false;
}
/* TODO commentata perchè va adattata a cronoprog
function uniqueInArrayV2(mapElement, value, scheda){
  if(!mapElement || !value || !scheda){
    console.error("uniqueInArrayV2: NULL param passed", mapElement, value, scheda);
    return false;
  }
  //console.log("elem", mapElement);
  let key = mapElement.config.path;
  //dalla chiave passata ricavo la chiave generica con *
  let genericKey = mapUtils.getGenericArrayKey(key);
  //console.log("genericKey", genericKey);
  //cerco nella scheda i valori presenti nell'array
  let objs = mapUtils.getSchedaValuesFromMapElementPlus(genericKey, scheda);
  const conf = scheda.dataEntryConfiguration;
  //ciclo per tutti i valori
  for (let i=0; i<objs.length; i++){
    //console.log("item",objs[i]);
    //salto il valore legato alla chiave originale
    const path = objs[i].path.substr(1);
    if(path.includes(key))
      continue;
    // escludo i valori eliminati
    if(conf && conf[path] && conf[path].data.deleted) {
      continue;
    }
    //se trovo un duplicato ritorno false
    if(objs[i].value && objs[i].value.toString().trim() === value.toString().trim()) {
      return false
    }
  }
  
  //altrimenti nessun valore duplicato trovato nell'array
  return true;
}
*/
const dateEscluseDaControllo = [
    // "['progetto']['dataInizio']",
    // "['progetto']['dataConclusione']",
    "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataInizio']",
    "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataFine']"
]

function dataPrimaInizioProgetto(mapElement, value, scheda){
  if (!value || !scheda){
    console.log("dataPrimaInizioProgetto NULL param passed!", mapElement.config.path, value);
    return false;
  }
  const chiaveInizio = "['progetto']['dataInizio']";
  
  const idWithStars = mapUtils.getGenericArrayKey(mapElement.config.path);


  // if(mapElement.config.path === chiaveInizio)
  if(dateEscluseDaControllo.indexOf(idWithStars) !== -1 || idWithStars === chiaveInizio)
    return false;
  //leggo il valore dalla scheda
  let dataInizio = mapUtils.getSchedaValueFromMapElement(scheda, chiaveInizio);
  //se esiste
  if (dataInizio){
    //console.log("data inizio progetto=", dataInizio);
    //converto le date
    let dateI = new Date(dataInizio);
    let date = new Date(value);
    // se la data è anteriore a data inizio restituisce errore
    if (date && date != "Invalid Date" && dateI && dateI != "Invalid Date"){
      //console.log("dateI", dateI);
      //confronto le date
      if (checkDate(date, dateI))
        return true;
    }
  }
  return false;
}

function dataDopoFineProgetto(mapElement, value, scheda){
  if (!value || !scheda){
    console.log("dataDopoFineProgetto NULL param passed!", mapElement.config.path, value);
    return false;
  }
  let chiaveFine = "['progetto']['dataConclusione']";

  const idWithStars = mapUtils.getGenericArrayKey(mapElement.config.path);

  if(dateEscluseDaControllo.indexOf(idWithStars) !== -1 || idWithStars === chiaveFine)
    return false;
  //leggo il valore dalla scheda
  let dataFine = mapUtils.getSchedaValueFromMapElement(scheda, chiaveFine);
  //se esiste
  if (dataFine && dataFine!=="Invalid Date"){
    //console.log("data fine progetto=", dataFine);
    //converto le date
    let dateF = new Date(dataFine);
    let date = new Date(value);
    // se la data fine è anteriore alla data, allora restituisce errore
    if (date && date != "Invalid Date" && dateF && dateF != "Invalid Date"){
      //console.log("dateF", dateF);
      //confronto le date
      if (checkDate(dateF, date))
        return true;
    }
  }
  return false;
}

function importoFatturaISFmaggioreTotale(mapElement, value, scheda, fdata) {
  if(!mapElement || !scheda || !fdata){
    console.error("importoFatturaISFmaggioreTotale: NULL param passed!", value);
    return false;
  }
  //ricavo la chiave del totale della fattura dalla chiave primaria
  const regx = /\['\w+'\]$/;
  let id_totale = mapElement.config.path.replace(regx, "['totaleFattura']")
  let idToCheck = mapUtils.getCronoprogId(fdata.rowId, id_totale)
  let totale = fdata.content && fdata.content[idToCheck] ? fdata.content[idToCheck].value : jpath('$'+ idToCheck, scheda)[0]
  if(!totale)
    totale = 0
  return ( (totale === 0 && parseFloat(value) > 0) || 
      (parseFloat(totale) >= 0 && parseFloat(value) > parseFloat(totale)) );
}

function importoContrattoISFmaggioreTotaleV1(mapElement, value, scheda, fdata) {
  if(!mapElement || !scheda || !fdata){
    console.error("importoContrattoISFmaggioreTotaleV1: NULL param passed!", value);
    return false;
  }
  //ricavo la chiave del totale della fattura dalla chiave primaria
  const regx = /\['\w+'\]$/;
  let id_totale = mapElement.config.path.replace(regx, "['importoContrattoTotale']")
  let idToCheck = mapUtils.getCronoprogId(fdata.rowId, id_totale)
  let totale = fdata.content && fdata.content[idToCheck] ? fdata.content[idToCheck].value : jpath('$'+ idToCheck, scheda)[0]
  if(!totale)
    totale = 0
  if( (totale === 0 && parseFloat(value) > 0) || 
      (parseFloat(totale) >= 0 && parseFloat(value) > parseFloat(totale)) ) {
    return true;
  }

  return false;
}
// WARNING: questa funzione torna false oppure la stringa di errore, NON TRUE!
function importoISFmaggioreTotale(mapElement, value, scheda, fdata) {
  if(!mapElement || !scheda || !fdata){
    console.error("importoISFmaggioreTotale: NULL param passed!", value);
    return false;
  }

  if(fdata) {
    const check = mandatoryIfExistRifContratto(mapElement, value, fdata, scheda);
    if(check) {
      return check;
    }
  }
  //ricavo la chiave del totale dalla chiave primaria
  const regx = /\['\w+'\]$/;
  let chiaveTotale = mapElement.config.path.replace(regx, "['importoContrattoTotale']");
  //let chiaveTotale = mapElement.config.path.replace("importoContrattoSuISF","importoContrattoTotale");
  //se presente, leggo l'importo totale (calcolato)

  let idToCheck = mapUtils.getCronoprogId(fdata.rowId, chiaveTotale)
  let totale = fdata.content && fdata.content[idToCheck] ? fdata.content[idToCheck].value : jpath('$'+ idToCheck, scheda)[0]
  if(!totale)
    totale = 0
  //let totale = jpath('$'+ idToCheck, scheda)[0]
  //console.log("totale", totale);
  //confronto i valori numerici e restituisco il risultato
  const check1 = (totale === 0 && parseFloat(value) > 0) || 
                 (parseFloat(totale) >= 0 && parseFloat(value) > parseFloat(totale))
  if(check1) {
    return strings.importoMaggioreTotale;
  }
  return false;
}
function costoProgettoNegativo(scheda,value){
  
  if(!scheda){
    console.error("Scheda Ass: NULL");
    return false;
  }
  const parseValue=parseFloat(value);
  if(isNaN(parseValue)){
    return false;
  }
  let budget;
  const budgetiniziale=parseFloat(scheda.budgetIniziale);
 
  if(isNaN(budgetiniziale)){
   console.error("Error validationRule: Errore lettura budgetIniziale");
    return false;
  }
  if(scheda.budgetIniziale ){
    budget=budgetiniziale;
  }
  if(scheda.budgetRimodulato && scheda.budgetRimodulato.length>0){
    const arrBudgetRimodulato=scheda.budgetRimodulato;
    const budgetrimodulato=parseFloat(arrBudgetRimodulato[arrBudgetRimodulato.length-1].budget);
    if(isNaN(budgetrimodulato)){
     console.error("Error validationRule:  Errore lettura budgetRimodulato");
      return false;
    }
    if(budgetrimodulato >0){
      budget=budgetrimodulato;
    }
  }
  
  const budgetFinale=budget-parseValue;
  return budgetFinale<0;
}
function controllaTransizioneV1(id, schedaMonitoraggio) {
  if (!id || !schedaMonitoraggio || !schedaMonitoraggio.dataEntryConfiguration){
    console.error("controllaTransizioneV1: NULL param passed!?");
    return false;
  }
  //leggo il valore precedente e quello attuale
  let oldValue = schedaMonitoraggio.dataEntryConfiguration[id].data.oldValue;
  let newValue = jpath('$'+id, schedaMonitoraggio)[0];
  //console.log("newValue", newValue);
  //se esistono e sono riuscito a leggerli
  if (oldValue && newValue && oldValue.length>0 && newValue.length>0){
    //condizioni indicate nel foglio excel di EY per le transizioni che sono da marcare come sostanziali
    if (oldValue.includes("60")){
      if(newValue.includes("61") || newValue.includes("62") || newValue.includes("63") 
        || newValue.includes("house") || newValue.includes("P.A.") || newValue.includes("secretate") 
        || newValue.includes("Contratti di servizi"))
        return true;
    } else if (oldValue.includes("61")) {
      if(newValue.includes("62") || newValue.includes("63") || newValue.includes("house") 
        || newValue.includes("P.A.") || newValue.includes("secretate") || newValue.includes("Contratti di servizi"))
        return true;
    } else if (oldValue.includes("62")) {
      if(newValue.includes("diretto") || newValue.includes("63"))
        return true;
    } else if (oldValue.includes("CONSIP")) {
      if(newValue.includes("60") || newValue.includes("61") || newValue.includes("62"))
        return true;
    }
  }  

  return false;
}

function ivaDichiarazioneV1(mapElement, currentValue, scheda, fdata) {
  if(!mapElement || !fdata || !fdata.content) {
    return false;
  }
  
  const ivaParsed = parseFloat(currentValue);
  if(isNaN(ivaParsed))
    return false;

  const regx = /\['\w+'\]$/;
  //dalla chiave dell'elemento editato ricavo la chiave dell'altro elemento a cui fare riferimento
  const importoId = mapElement.config.path.replace(regx, "['imponibile']");
  let imp = jpath("$"+importoId, scheda.content);
  let importo = imp[0];
  let importoParsed = !isNaN(parseFloat(importo)) ? parseFloat(importo) : 0
  if(ivaParsed >= importoParsed)
    return true;
  
  return false;
}

function imponibileDichiarazioneV1(value, scheda) {
  if(!value || !scheda){
    return false;
  }

  //setto l'id del campo di riferimento per il tetto massimo delle cifre
  let idToCheck = "['infoProgetto']['costoProgetto']";
  
  //se presente nella scheda, leggo l'importo totale
  let totale = jpath('$'+ idToCheck, scheda.content)[0]
  
  if(totale){ // se trovo questo dato
      
    //confronto i valori numerici e restituisco il risultato
    if((totale == 0 && parseFloat(value) > 0) 
      || (parseFloat(totale) >= 0 && parseFloat(value) > parseFloat(totale))) {
      return true;
    }

  }

  return false;
}

// ---------------------------------- SEZIONE FUNZIONI GENERICHE ---------------------------------

// Questa è una funzione generica che prende in ingresso un id,
// attraverso questo recupera il campo dalla scheda e lo confronta con valueToCompare
function compare_value_to_field_by_id(id_other_field, valueToCompare, scheda, fdata) {
  let idToCheck = mapUtils.getCronoprogId(fdata.rowId, id_other_field)
  let value_field1 = fdata.content[idToCheck] ? fdata.content[idToCheck].value : jpath('$'+ idToCheck, scheda)[0]
  let value_Parsed = !isNaN(parseFloat(value_field1)) ? parseFloat(value_field1) : 0
  return valueToCompare > value_Parsed;// ? msgError : false
}

export default {
  strings,
  defaultValidation,
  substancialValidation,
  //PROGETTO
  substancialValidationProgetto,
  // istruttoria
  checkListValidation,
  // CONVENZIONE
  checkCorrectIban,
  // AUTOCONTROLLO E CONTROLLO
  controlValidation 
}