import store from '@/store/store.js'

export default{
    install(Vue){

        Vue.prototype.$calculateMandatoryFiles= function(entity, role, scheda, actualFiles){
            let allegati = []
            let index = 0
            let status = scheda.taskInfo.taskDefinitionKey
            switch (entity) {
                case 'bandi':
                    switch (role) {
                        case 'SegreteriaTecnicaAmministrativa-Direttore':
                          if(status==='Bando_valutazioneDirettore_SegreteriaTecnicaAmministrativa-Direttore'){
                              allegati = scheda.infoAllegati['bandi']
                              index = allegati.map(function(e) { return e.type; }).indexOf('Appunto');
                              if ( index > -1)
                                  allegati[index].recommended = true
                            }
                            break;                        
                        case 'AutoritaResponsabile-AutoritaResponsabile':
                          if(status==='Bando_valutazioneAR_AutoritaResponsabile-AutoritaResponsabile'){
                              allegati = scheda.infoAllegati['bandi']
                              index = allegati.map(function(e) { return e.type; }).indexOf('Decreto di approvazione');
                              if ( index > -1)
                                  allegati[index].mandatory = true
                            }
                            break;
                        case 'UfficioGestione-Approvatore':
                            if(status==='Bando_invioBeneficiari_UfficioGestione-Approvatore'){
                                allegati = scheda.infoAllegati['bandi']
                                index = allegati.map(function(e) { return e.type; }).indexOf('Lettera di invito ai beneficiari');
                                if ( index > -1)
                                    allegati[index].recommended = true
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case 'progetti':
                    {
                        allegati = scheda.infoAllegati['progetti']
                        switch (role) {
                          case 'Beneficiario-Operatore':
                            if(status==='Progetto_compilazione_Beneficiario-Operatore' 
                                || status==='Progetto_modifica_Beneficiario-Operatore'){
                              if(scheda.schedaProgetto.progetto.modalitaDiAccesso === "Call for proposal"){
                                  
                                  index = allegati.map(function(e) { return e.type; }).indexOf('Domanda di ammissione a finanziamento');
                                  if ( index > -1)
                                  {
                                    if(status==='Progetto_modifica_Beneficiario-Operatore')
                                      allegati[index].recommended = true
                                    else
                                      allegati[index].mandatory = true
                                      
                                  }
                              }
                              const id = "['costiIndiretti'][*]['importo']"
                              const validValues = Vue.prototype.$getUndeletedValues(id, scheda.schedaProgetto);
                              if(validValues.length > 0) {
  
                                index = allegati.map(function(e) { return e.type; }).indexOf('Autodichiarazione dei costi indiretti');
                                if ( index > -1)
                                {
                                  if(status==='Progetto_modifica_Beneficiario-Operatore')
                                    allegati[index].recommended = true
                                  else
                                    allegati[index].mandatory = true
                                }
                              }
  
                              if(scheda.schedaProgetto.progetto.procedureDerogatorie === "true" || 
                                  scheda.schedaProgetto.progetto.procedureDerogatorie === true ){
                                  index = allegati.map(function(e) { return e.type; }).indexOf('Relazione ricorso derogatorie');
                                  if (index > -1)
                                    if(status==='Progetto_modifica_Beneficiario-Operatore')
                                      allegati[index].recommended = true
                                    else
                                      allegati[index].mandatory = true
                              }
                            }
                            break;
                          case 'UfficioGestione-Operatore':
                            if(status === 'Progetto_analisi_UfficioGestione-Operatore'){
                              if(scheda.schedaProgetto.possibiliModificheSostanziali &&
                                 ( scheda.schedaProgetto.possibiliModificheSostanziali == true || scheda.schedaProgetto.possibiliModificheSostanziali == 'true') ){
                                    index = allegati.map(function(e) { return e.type; }).indexOf('Decreto Approvazione Rimodulazione');
                                    if (index > -1)
                                        allegati[index].mandatory = true
                                  }
                            }
                            break;
                          case 'AutoritaResponsabile-AutoritaResponsabile':
                            if(status === 'Progetto_approvaModifica_AutoritaResponsabile-AutoritaResponsabile'){
                              if(scheda.schedaProgetto.possibiliModificheSostanziali &&
                                  (scheda.schedaProgetto.possibiliModificheSostanziali == true || scheda.schedaProgetto.possibiliModificheSostanziali == 'true')){
                                    index = allegati.map(function(e) { return e.type; }).indexOf('Decreto Approvazione Rimodulazione - Firmato');
                                    if (index > -1)
                                        allegati[index].mandatory = true
                                  }
                            }                            
                            break;
                        }
                        break;
                    }
                case 'organigramma':
                    if(role==='Beneficiario-Operatore' && (status==='Progetto_compilazione_Beneficiario-Operatore' || status==='Progetto_modifica_Beneficiario-Operatore')){
                        allegati = scheda.infoAllegati['organigramma']
                        index = allegati.map(function(e) { return e.type; }).indexOf('Organigramma del beneficiario');
                        if ( index > -1) {
                            if(status==='Progetto_compilazione_Beneficiario-Operatore')
                              allegati[index].mandatory = true
                            else 
                              allegati[index].recommended = true
                        }                    
                    }
                    break;
                case 'executingBody':
                    switch (role) {
                        case 'UfficioAcquisti-UfficioAcquisti':
                            if(status==='Progetto_appunto_UfficioAcquisti-UfficioAcquisti'){
                                allegati = scheda.infoAllegati['executingBody']
                                index = allegati.map(function(e) { return e.type; }).indexOf('Appunto per il direttore');
                                if (index > -1)
                                    allegati[index].mandatory = true
                            }
                            break;
                        case 'AutoritaResponsabile-AutoritaResponsabile':
                          if(status==='Progetto_valutazioneAR_AutoritaResponsabile-AutoritaResponsabile'){
                              allegati = scheda.infoAllegati['executingBody']
                              index = allegati.map(function(e) { return e.type; }).indexOf('Determina a Contrarre');
                              if (index > -1)
                                  allegati[index].mandatory = true
                            }
                            break;
                        case 'SegreteriaTecnicaAmministrativa-Direttore':
                          if(status==='Progetto_valutazioneDirettore_SegreteriaTecnicaAmministrativa-Direttore'){
                              allegati = scheda.infoAllegati['executingBody']
                              index = allegati.map(function(e) { return e.type; }).indexOf('Appunto per Autorita Responsabile');
                              if (index > -1)
                                  allegati[index].mandatory = true
                            }
                            break;
                    }
                    break;
                case 'istruttoria':
                    switch (role) {
                        case 'UfficioGestione-Approvatore':
                            if(status==='Progetto_approvazioneIstruttoria_UfficioGestione-Approvatore'){
                              allegati = scheda.infoAllegati['istruttoria']
                              index = allegati.map(function(e) { return e.type; }).indexOf('Notifica Esito Istruttoria');
                              if (index > -1)
                                  allegati[index].recommended = true
                              }
                        break;
                        }
                    break;
                case 'convenzione':
                    switch (role) {
                      case "UfficioGestione-Operatore":
                        if (
                          status === "Progetto_decreto_UfficioGestione-Operatore"
                        ) {
                          if(scheda.schedaProgetto.erogazione.ammesso === true || scheda.schedaProgetto.erogazione.ammesso === 'true'){
                                allegati = scheda.infoAllegati["convenzione"];
                                index = allegati
                                  .map(function(e) {
                                    return e.type;
                                  })
                                  .indexOf("Decreto di ammissione a finanziamento");
                                if (index > -1) allegati[index].mandatory = true;
                          }
                        }

                        if (
                          status === "Progetto_invioDoc_UfficioGestione-Operatore"
                        ) {
                          allegati = scheda.infoAllegati["convenzione"];
                          index = allegati
                            .map(function(e) {
                              return e.type;
                            })
                            .indexOf("Documento di stipula convenzione");
                          if (index > -1) allegati[index].mandatory = true;

                          index = allegati
                          .map(function(e) {
                            return e.type;
                          })
                          .indexOf("Informativa obblighi di pubblicita");
                        if (index > -1) allegati[index].recommended = true;
                        }

                        break;
                      case "Beneficiario-Approvatore":
                        if (status === "Progetto_convenzione_Beneficiario-Approvatore"){                          
                          allegati = scheda.infoAllegati["convenzione"];
                          index = allegati
                              .map(function(e) {
                              return e.type;
                              })
                              .indexOf(
                                  "Documento di stipula convenzione - Firmato"
                              );
                          if (index > -1) allegati[index].mandatory = true;
                        }
                        break;
                      case "AutoritaResponsabile-AutoritaResponsabile":
                        if (
                          status === "Progetto_firmaAR_AutoritaResponsabile-AutoritaResponsabile"
                        ) {
                          allegati = scheda.infoAllegati["convenzione"];
                          index = allegati
                            .map(function(e) {
                              return e.type;
                            })
                            .indexOf(
                              "Decreto di ammissione a finanziamento - Firmato"
                            );
                          if (index > -1) allegati[index].mandatory = true;
                        }
                       
                        if (
                            status === "Progetto_firmaDefinitiva_AutoritaResponsabile-AutoritaResponsabile"
                        ) {
                            allegati = scheda.infoAllegati["convenzione"];
                            index = allegati
                                .map(function(e) {
                                return e.type;
                                })
                                .indexOf(
                                    "Documento di stipula convenzione - Firmato AR"
                                );
                            if (index > -1) allegati[index].mandatory = true;
                        }

                        break;
                    }
                    break;
                case 'graduatoria':
                    switch (role) {
                      case "UfficioGestione-Operatore":
                        if (
                          status === "Progetto_invioDocGraduatoria_UfficioGestione-Operatore"
                        ) {
                          allegati = scheda.infoAllegati["graduatoria"];
                          index = allegati
                            .map(function(e) {
                              return e.type;
                            })
                            .indexOf("Documento di stipula convenzione");
                          if (index > -1) allegati[index].mandatory = true;

                          index = allegati
                          .map(function(e) {
                            return e.type;
                          })
                          .indexOf("Informativa obblighi di pubblicita");
                        if (index > -1) allegati[index].recommended = true;
                        }
                        break;
                      case "UfficioGestione-Approvatore":
                        if (
                          status === "Progetto_graduatoria_UfficioGestione-Approvatore"
                        ) {
                          allegati = scheda.infoAllegati["graduatoria"];
                          index = allegati
                            .map(function(e) {
                              return e.type;
                            })
                            .indexOf("Decreto Approvazione Graduatoria");
                              if (index > -1) allegati[index].mandatory = true;

                          index = allegati
                          .map(function(e) {
                            return e.type;
                          })
                          .indexOf("Appunto");
                            if (index > -1) allegati[index].recommended = true;

                        index = allegati
                        .map(function(e) {
                          return e.type;
                        })
                        .indexOf("Decreto di ammissione a finanziamento");
                          if (index > -1) allegati[index].mandatory = true;
                        }
                        break;
                      case "AutoritaResponsabile-AutoritaResponsabile":
                        if (
                          status === "Progetto_graduatoria_AutoritaResponsabile-AutoritaResponsabile"
                      ) {
                          allegati = scheda.infoAllegati["graduatoria"];
                          index = allegati
                              .map(function(e) {
                              return e.type;
                              })
                              .indexOf(
                                  "Decreto Approvazione Graduatoria - Firmato"
                              );
                          if (index > -1) allegati[index].mandatory = true;
                      }
                      
                      if (
                        status === "Progetto_firmeAR_AutoritaResponsabile-AutoritaResponsabile"
                      ) {
                        allegati = scheda.infoAllegati["graduatoria"];
                        index = allegati
                          .map(function(e) {
                            return e.type;
                          })
                          .indexOf(
                            "Decreto di ammissione a finanziamento - Firmato"
                          );
                        if (index > -1) allegati[index].mandatory = true;
                      }
                        break;
                    }
                  break;
                default:
                    console.error(entity+" non gestita!?");
                    break;
                
            
            }
            
            //Prendo la lista completa dei file obbligatori
            let allegatiMandatory = scheda.infoAllegati[entity].filter(function(doc){return doc.mandatory === true || doc.recommended === true;});
            actualFiles.forEach( doc => {
              //e restituisco soltanto i file obbligatori non ancora caricati
              allegatiMandatory = allegatiMandatory.filter(mandatory => { return doc.documentType!==mandatory.type} )
            });
            return allegatiMandatory
        }
        //Chiamata in initializeInfoAllegati, imposta le tipologie di allegati disponibili 
        Vue.prototype.$availableDocumentTypes= function(fileList, taskDefinitionKey){
            let availableDocumentTypes = []
            for (const element of fileList) {
              if(!element.task || element.task === taskDefinitionKey)
                availableDocumentTypes.push(element)
            }
            return availableDocumentTypes
        }
        //Funzione di utility: funziona come 'indexOf' con una regex
        Vue.prototype.$regIndexOf=function (rx, array){
            for (let i in array) {
                if (array[i].toString().match(rx)) {
                    return i;
                }
            }
            return -1
        }
        Vue.prototype.$updateMandatoryFilesInStore=function (allegatiMandatory, entity, rid){
            let mappingStore =           
            {
              progetti : 
                { 
                  refStore : "progetto/setMandatoryMissed", 
                  objToStore : { 
                                idSchedaProgetto : rid,
                                entity : this.entity,
                                files : allegatiMandatory
                  } 
                },
                organigramma : 
                { 
                  refStore : "progetto/setMandatoryMissed", 
                  objToStore : { 
                                idSchedaProgetto : rid,
                                entity : this.entity,
                                files : allegatiMandatory
                  } 
                },
              bandi : 
              {
                refStore : "announcements/setMandatoryMissed",
                objToStore : { 
                                idSchedaBando : rid,
                                entity : this.entity,
                                files : allegatiMandatory
                  }
              },
              executingBody : 
                { 
                  refStore : "executingBody/setMandatoryMissed", 
                  objToStore : { 
                                idSchedaProgetto : rid,
                                entity : this.entity,
                                files : allegatiMandatory
                  } 
                },
              istruttoria :
              {
                refStore : "istruttoria/setMandatoryMissed",
                objToStore : {
                              idSchedaIstruttoria : rid,
                              entity : this.entity,
                              files : allegatiMandatory
                }
              },
              convenzione:
                { 
                  refStore : "progetto/setMandatoryMissed", 
                  objToStore : { 
                                idSchedaProgetto : rid,
                                entity : this.entity,
                                files : allegatiMandatory
                  } 
                },
                graduatoria:
                { 
                  refStore : "convenzione/setMandatoryMissed", 
                  objToStore : { 
                                idBando : rid,
                                entity : this.entity,
                                files : allegatiMandatory
                  } 
                }
            }
            
            let requestToStore = mappingStore[entity]
            if(requestToStore == undefined)
              console.error("unmanaged entity:", this.entity)
            else
            {
              store.dispatch(
                requestToStore.refStore.toString(),
                { obj : requestToStore.objToStore }
              )
            }

        }
        Vue.prototype.$updateAvailableLengthInStore=function (availableFilesLength, entity, rid){
            let mappingStore =           
            {
              progetti : 
                { 
                  refStore : "progetto/setAvailableFiles", 
                  objToStore : { 
                                idSchedaProgetto : rid,
                                entity : entity,
                                numeroAllegati : availableFilesLength
                  } 
                },
                organigramma : 
                { 
                  refStore : "progetto/setAvailableFiles", 
                  objToStore : { 
                                idSchedaProgetto : rid,
                                entity : entity,
                                numeroAllegati : availableFilesLength
                  } 
                },
              bandi : 
              {
                refStore : "announcements/setAvailableFiles",
                objToStore : { 
                                idSchedaBando : rid,
                                entity : entity,
                                numeroAllegati : availableFilesLength
                  }
              },
              executingBody :
                { 
                  refStore : "executingBody/setAvailableFiles", 
                  objToStore : { 
                                idSchedaProgetto : rid,
                                entity : entity,
                                numeroAllegati : availableFilesLength
                  } 
                },
              istruttoria : 
              {
                refStore : "istruttoria/setAvailableFiles",
                objToStore : {
                              idSchedaIstruttoria : rid,
                              entity : entity,
                              numeroAllegati : availableFilesLength
                }
              },
              convenzione: 
                { 
                  refStore : "progetto/setAvailableFiles", 
                  objToStore : { 
                                idSchedaProgetto : rid,
                                entity : entity,
                                numeroAllegati : availableFilesLength
                  } 
                },
                graduatoria:
                { 
                  refStore : "convenzione/setAvailableFiles", 
                  objToStore : { 
                                idBando : rid,
                                entity : this.entity,
                                files : availableFilesLength
                  } 
                }
            }

            let requestToStore = mappingStore[entity]
            if(requestToStore == undefined)
              console.error("unmanaged entity:", this.entity)
            else
            {
              store.dispatch(
                requestToStore.refStore.toString(),
                { obj : requestToStore.objToStore }
              )
            }
        }

        //Filtra i file già caricati
        Vue.prototype.$availableFilesFilter= function (resp, userInfo, possibleFiles){
          let availableFiles = []
          const tempGroup = userInfo.groups[0];
          const groupSplit = tempGroup.split('/');

          let mainGroup = '';
          let group = '';
          if(groupSplit.length > 2) {
            mainGroup = groupSplit[1];
            group = groupSplit[2];
          }
          const username = userInfo.preferred_username
          

          // primo filtro: il file deve essere tra i documenti previsti per quella data scheda
          // inoltre, si controlla se il file deve essere gestito solo da un mainGroup (es. MINT o Beneficiari)
          for (const file1 of resp) {
            let takeIt = false;
            for (const possibleFile of possibleFiles) {
              if((file1.documentType === possibleFile.type && ( !possibleFile.group || mainGroup.match(possibleFile.group) ) ) ) {
                takeIt = true;
                break;
              }
            }
            if(takeIt)
              availableFiles.push(file1);
          }

          // secondo filtro: i file sono visibili solo se:
          // 1) appartengono allo stesso gruppo dell'utente (es. ISF1, vvff, capitaneria, ecc), oppure
          // 2) sono stati inseriti dall'utente corrente, oppure
          // 3) sono pubblicati e quindi disponibili per tutti
          availableFiles = availableFiles.filter( doc => { 
            return group === doc.subgroup || doc.owner === username || doc.published === true;
          })
          return availableFiles;
        }
        //Calcola su InvioConvenzioneCFP la lista dei progetti per cui è possibile allegare dei file
        Vue.prototype.$graduatoriaFilterAvailableProjects= function(schedaConvenzioneComplete){
          if (schedaConvenzioneComplete.taskInfo.taskDefinitionKey === 'Progetto_graduatoria_AutoritaResponsabile-AutoritaResponsabile')
            return schedaConvenzioneComplete.schedaConvenzione.progetti
          else
            return schedaConvenzioneComplete.schedaConvenzione.progetti.filter(progetto => { return progetto.ammesso !== "Non ammesso da ISF" ;})
        }
    }
}