<template>
  <div>
    <b-container>
      <b-alert :show="getDeletedFiles.length > 0" variant="warning">
        <div style="text-align: center;">
          <p><strong>ATTENZIONE! Questi sono i files eliminati dalla scheda</strong> (Qualsiasi riferimento a tali files è stato rimosso pure dalla Checklist di propria competenza)</p>
          <ul>
            <li style="list-style: square;" v-for="item in getDeletedFiles" :key="item.fileId">
              {{ item.text }}
            </li>
          </ul>
        </div>     
      </b-alert>
      <b-alert :show="datiSpesa.spesa < datiSpesa.soglia" variant="warning">
        <div style="text-align: center;">
          <p><strong>ATTENZIONE: non risultano rendicontazioni sufficienti a richiedere una nuova tranche di finanziamento</strong> 
            <br> Soglia di rendicontazione per richiedere la tranche {{datiSpesa.tranche}}: <strong>{{soglia}} Euro </strong>
            <br> Importo rendicontato: <strong> {{spesa}} Euro </strong>
          <br>(Allegare i documenti richiesti al punto 2 della checklist o inviare ulteriori dichiarazioni di spesa ed attendere che siano approvate)</p>

          <p><strong>
            La richiesta di tranche verrà inviata in ogni caso, ma l'utente può decidere se andare in deroga rispondendo alla domanda sottostante.
          </strong></p>
        </div>     
      </b-alert>
      <b-row v-if="isConfermaDerogaVisible" >
        <b-col cols="1" />
        <b-col><strong>Si desidera richiedere la tranche con deroga?</strong></b-col>
        <b-col>
          <b-radio-group
            size="lg"
            :disabled="isConfermaDerogaDisabled"
            @change="updateConfermaDeroga"
            v-model="confermaDeroga">
            <b-form-radio value="true">SI</b-form-radio>
            <b-form-radio value="false">NO</b-form-radio>
          </b-radio-group>
        </b-col>
        <b-col cols="1" />
      </b-row>
      
      <Collapse :name="'Campi obbligatori non valorizzati (' + obbligatoriTable.rows.length + ')'">
        <b-table-lite
          :id="'mandatory_tranche'"
          thead-class="head"
          :items="obbligatoriTable.rows"
          :fields="obbligatoriTable.header" />
      </Collapse>
      <Collapse :name="'Note (' + note.length + ')'">
        <br />
          <TableNotes :id="'note_tranche'" v-if="note.length > 0" :notes="note"/>
          <h4 v-else>
            Nessuna nota da mostrare
          </h4>
      </Collapse>
       <Collapse
        v-if="isVisibleReservedNote()"
        :name="'Elenco Note Interne (' + fillNoteRiservate().length + ')'">
        <br />
        <div class="note" v-if="isSend">
          <br>
            <b-form-textarea
              id="textAreaNoteRiservate"
              :disabled="disableButtons"
              v-model="noteRiservate"
              placeholder="Inserire qui le note interne"/>
        </div>
        <div class="pulsanti-invio">
          <b-button :id="'cancelNoteRiservate_controlli'"
            v-if="isSend"
            class="mx-3 btnReservedNote"
            :disabled="disableButtons"
            variant="danger"
            @click="resetReservedNote()"
            >Cancella Nota Interna</b-button>
        </div>
        <TableNotes
          :id="'note_riservate_tranche'"
          v-if="fillNoteRiservate().length > 0" :notes="fillNoteRiservate()">
        </TableNotes>
        <h4 v-else>
          Nessuna nota da mostrare
        </h4>
      </Collapse>
      <b-row>
        <div v-if="fileObbligatori.length > 0">
          <ul>
            <span :class=" isFileObbligatorioMancante ? 'text-danger' : 'text-warning' ">Attenzione: Mancano ancora allegati</span>
            <li v-for="file in fileObbligatori" :key="file.label" style="list-style-type: circle;">
              <span class="text-center">{{file.label}}</span>
            </li>
          </ul>
        </div>
      </b-row>
      <b-row class="mt-2" v-if="isSend">
        <b-col />
        <b-col class="text-right">
          <label for="textarea-note">Nota:</label>
        </b-col>
        <b-col sm="8">
          <b-form-textarea
            v-model="notaTemp"
            id="textarea-note-tranche"
            :disabled="disableButtons"
            size="lg"
            placeholder="Inserire qui le note"/>
        </b-col>
        <b-col sm="2">
          <b-button :id="'cancelNote_tranche'" :disabled="disableButtons" variant="outline-secondary" @click="cancelNote()"
            >Cancella Nota</b-button
          >
        </b-col>
        <b-col />
      </b-row>
      <b-row v-if="isEsitoFinaleVisible">
        <b-col cols="3" />
        <b-col><span class="esitoFinale">Esito finale del controllo:</span></b-col>
        <b-col><strong>
          <b-radio-group
            size="lg"
            :id="'esito_finale_tranche'"
            :disabled="isEsitoFinaleDisabled"
            @change="updateEsitoFinale"
            v-model="esitoFinale">
            <b-form-radio id="esitoSiTranche" style="font-size:1.8rem;" value="true">Positivo</b-form-radio>
              <b-form-radio id="esitoNoTranche" style="font-size:1.8rem;" value="false">Negativo</b-form-radio>
          </b-radio-group>
        </strong></b-col>
        <b-col cols="2" />
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            :id="'export_pdf_tranche'"
            :disabled="disableButtons"
            size="lg"
            variant="secondary"
            class="mx-2"
            @click="preparePdf">
            Export PDF
          </b-button>
          <b-button
            v-if="isSave"
            :id="'save_progetto_tranche'"
            :disabled="disableButtons"
            size="lg"
            variant="primary"
            class="mx-2"
            @click="saveScheda('draft')">
            Salva in Bozza
          </b-button>
          
          <b-button
            v-if="isSend"
            :id="'send_progetto_tranche'"
            size="lg"
            variant="success"
            class="mx-2"
            :disabled="disableBtnSend || disableButtons"
            @click="saveSchedaTranche('complete')">
            Salva e Invia
          </b-button>
          
          <b-button
            v-if="isReject"
            :id="'reject_progetto_tranche'"
            :disabled="disableButtons"
            size="lg"
            variant="danger"
            class="mx-2"
            @click="saveSchedaTranche('reject')">
            Rifiuta
          </b-button>
          
          
        </b-col>
      </b-row>
      <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
     
    </b-container>
  </div>
</template>
<script>

import endpoints from "@/components/endpoints.vue"
import Collapse from "@/components/collapse.vue"
import TableNotes from "../bandi/tableNotes.vue";
import notify from "@/helpers/notifications.js"
import fileHelper from '@/helpers/allegati.js'
import { mapGetters } from "vuex"
import tools from '@/helpers/tools.js'
import { JSONPath as jpath } from 'jsonpath-plus'
import controlliHelper from '@/helpers/controlli.js'
import helper_pdf from "@/helpers/exportPdf.js"

export default {
  name: "InvioTranche",
  components: {
    Collapse,
    TableNotes
  },
  props: {
    tabName: {
      type: String,
      default: 'Sconosciuto'  //NB: deve essere sempre il padre a stabilire un nome univoco di questo componente nel suo contesto
    },
    actions: {
      type: Array,
      default: function () { return ['save', 'send', 'reject'] }
    },
    config: {
      type: Object,
      default: () => {}
    },
    idsToCheck: {
      type: Array,
      default: function () { return [] }
    },
    datiSpesa: {
      type: Object,
      required: true
    },
    idsCustomEdit: {
      type: Array,
      default: function () { return [] }
    }
  },
  data() {
    return {
      scheda: {},
      taskInfo: {},
      schedaNoteRiservate: null,
      noteRiservate: "",
      name: "Invio",
      confermaDeroga: "false",
      esitoFinale: true,
       modal1Map:{
            complete:{
                title:"Conferma Tranche", text:"Conferma di voler Inviare il processo di Tranche? "
            },
            
            reject:{
                title:"Rifiuta Tranche", text:"Conferma di voler Rifiutare il processo Tranche?"
            },
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",
      mappa: {},
      deletedFiles: [],
      fileObbligatori: [],
      note: [],
      notaTemp: "",
      obbligatoriTable: {
        header: [
          {
            key: "Tab"
          },
          {
            key: "Campo",
            label: "Nome Campo"
          }
        ],
        rows: []
      },
      disableButtons: false,
        

    }
  },
  watch: {
    tabName: function() {
      if(this.tabName === this.name) {
        this.deletedFiles = []
        this.refreshMandatoryFields()
      }
    },
    confermaDeroga: function() {
      this.checkFileObbligatori();
    }
  },
  mounted() {
    this.codiceProgetto = this.$route.params.codiceProgetto;
    this.idScheda = this.$route.params.idScheda
    let scheda = this.getCurrentScheda();
    if(!scheda) {
      console.error('tranche->invio: no scheda in store');
      return;
    }
    this.scheda = scheda

    this.taskInfo = scheda.content.taskInfo;

    let esitoFin = scheda.content.esitoFinale
    //Di default, setto l'esito positivo, a meno che non sia esplicitamente richiesto
    this.esitoFinale = esitoFin == "false" || esitoFin === false ? "false" : "true"

    
    let der = scheda.content.deroga
    if(der === true || der === "true"){
      this.confermaDeroga = "true";
    } else {
      this.confermaDeroga = "false";
    }

    if(this.scheda.content.note || this.scheda.content.notaTemporanea) {
      this.note = this.scheda.content.note && this.scheda.content.note.length > 0 ? this.scheda.content.note : [];
      this.notaTemp = this.scheda.content.notaTemporanea.nota
    }
    // NOTE RISERVATE
    this.schedaNoteRiservate = this.getSchedaNoteRiservate();
    if (this.scheda.content?.notaTemporaneaRiservata) {
      this.noteRiservate = this.scheda.content.notaTemporaneaRiservata;
    }
    if(this.config.content){
      const groups = this.$getUserInfo().groups;
      if(!groups || groups.length === 0) {
        console.error('check campi obbligatori: groups error', this.$getUserInfo());
        this.mappa = [];
      }
      const group = groups[0];
      if(group.includes("UfficioEconomicoFinanziario"))
        this.mappa = this.config.content && this.config.content.mappa ? this.config.content.mappa : []
    }
    this.refreshMandatoryFields()
  },
  computed: {
    ...mapGetters({
      getScheda: "circuitoFinanziario/getScheda",
      getAllegatiDaCancellare: "circuitoFinanziario/getAllegatiDaCancellare",
      getNoteRiservate: "circuitoFinanziario/getSchedaNoteRiservate"
    }),
    isSave() {
      return this.actions.indexOf("save") !== -1;
    }, 
    isSend(){
      return this.actions.indexOf('send') !== -1;
    },
    isReject(){
      return this.actions.indexOf('reject') !== -1;
    },
    disableBtnSend() {
      return (this.obbligatoriTable.rows.length > 0
              || (this.datiSpesa && (this.datiSpesa.spesa < this.datiSpesa.soglia) && this.confermaDeroga === "false"))
      // WARNING decommentare solo per debug
      //return false;
    },
    isEsitoFinaleVisible() {
      const role = this.$getUserRole();
      return role.includes('UfficioEconomicoFinanziario');
    },
    isEsitoFinaleDisabled() {
      return  !(this.taskInfo && (
             this.taskInfo.taskDefinitionKey === 'Tranche_validazione_UfficioEconomicoFinanziario-Operatore'
             || this.taskInfo.taskDefinitionKey === 'Tranche_approvaChecklist_UfficioEconomicoFinanziario-Approvatore'
           )
           && this.isSend
         )
    },
    isConfermaDerogaVisible(){
      return (this.confermaDeroga === "true" || (this.datiSpesa && (this.datiSpesa.spesa < this.datiSpesa.soglia)))
    },
    isConfermaDerogaDisabled() {
      return !(this.taskInfo && (
             this.taskInfo.taskDefinitionKey === 'Tranche_compilazione_Beneficiario-Operatore'
           )
           && this.isSend
         )
    },
    // retrieveTaskInfo(){
    //   return this.getCurrentScheda().content.taskInfo
    // },
    getDeletedFiles() {
      // SE ho più occorrenze farò in modo di visualizzarne sempre una
      return this.deletedFiles.reduce((unique, o) => {
        if(!unique.some(obj => obj.fileId === o.fileId))
          unique.push(o)
        return unique
      },[])
    },
    soglia(){
      return tools.roundFloat2Decs(this.datiSpesa.soglia)
    },
    spesa(){
      return tools.roundFloat2Decs(this.datiSpesa.spesa)
    },
    //Colora di rosso l'avviso se ci sono dei file altamente obbligatori
    isFileObbligatorioMancante(){
      return this.fileObbligatori.filter( file => { return file.mandatory }).length > 0
    },
    isLastStep(){
      return (this.taskInfo && this.taskInfo.taskDefinitionKey === "Tranche_approvaChecklist_UfficioEconomicoFinanziario-Approvatore");
    }
  },
  methods: {
    preparePdf() {
      let contentScheda = this.getCurrentScheda().content
      let typeScheda = 'Tranche'
      let optPdf = helper_pdf.handleNamingPdf(contentScheda, typeScheda)
      this.$emit('buildPdf', optPdf)
    },
    isVisibleReservedNote() {
      let userInfo = this.$getUserInfo();
      let group = userInfo.groups[0];
      return group.includes("MINT") && this.schedaNoteRiservate;
    },
    getSchedaNoteRiservate() {
      if (this.scheda.noteRiservateKey) {
        return this.getNoteRiservate({ noteRiservatekey: this.scheda.noteRiservateKey })
      }
    },
    fillNoteRiservate() {
      return this.schedaNoteRiservate.content?.noteRiservate || [];
    },
    //Setta ad "ATTACHED" lo stato dei file caricati ed elimina quelli cancellati
    async attachFileObjects(){
      const allegati = this.getCurrentScheda().content.allegati
      
        const uri = endpoints.filesBasePathV2
        const toDelete = tools.genericFunctions.cloneObject(this.getAllegatiDaCancellare)
        const list = fileHelper.attachFileReqBuilder( allegati, toDelete )

        if(list && list.trim().length > 0){
          console.log("list: ",list)
          const ownerProcessStatus = this.taskInfo ? this.taskInfo.taskDefinitionKey : '';
          this.$put(uri,
          {
            "list" : list,
            "status" : ownerProcessStatus
          }
          ).then(
            (result) => {
              console.log("attached Files:", result)
              this.$store.dispatch("circuitoFinanziario/clearAllegatiDaCancellare")
            },
            (error) => {
                console.error("attachFileObjects: ", error.message);
                notify.error(notify.strings.error, notify.strings.unexpectedLoadStatus(error.message));
                throw error;
            }
          )          
        } 
    },  

    completaTask(action, idScheda, processId, taskId){
      
      if (!action || !idScheda || !processId || !taskId){
        console.error("completaTask: NO params!?");
        throw new Error("completaTask: NO params!?");
      }
    
      const url= endpoints.completePrefinanziamento;

      let bodyForPost = {
        idScheda: idScheda,
        processId: processId,
        taskId: taskId,
        azione: action
      };

      return this.$post(url, bodyForPost)
      .then(
          (respComplete) => {
              console.log("complete ok, return to home ", respComplete);
              notify.success(notify.strings.success, notify.strings.saveCompleteOk);
              this.disableButtons = false;
              this.$router.push({ name: "secure" });
          }
      )
      .catch(error => {
        console.error("ERRORE: ", error.message);
        notify.error(notify.strings.error, notify.strings.errorCompleteTranche);
        this.disableButtons = false;
        throw error;          
      })
    },
    
    saveSchedaTranche(action){
      //Apro la modale se mi trovo all'ultimo step
      this.setModal=action;
      this.$bvModal.show('modalCompletaInvia');
      //API di scrittura del controllo per ID
     
    },
    actionModal(){
         switch (this.setModal) {
                    case "complete":
                        return this.saveScheda(this.setModal);
                    case "reject":
                      return this.saveScheda(this.setModal);
                    
                    default:
                        console.log("Case false");
                    }
    },
    
    saveScheda(action){
      this.disableButtons = true;
         
      const url = endpoints.schedaTrancheById(this.codiceProgetto, this.idScheda)
      let body = this.getCurrentScheda();

      //elimino adesso il taskInfo per non persisterlo
      delete body.content.taskInfo;
      
      const autore = this.$getUserInfo().preferred_username;
      body.content.notaTemporanea = {
          nota: this.notaTemp,
          utente: autore
      }

      //Setto la nota temporanea riservata
      body.content.notaTemporaneaRiservata = this.noteRiservate;
      //setto l'autore dell'ultimo aggiornamento fuori e dentro content
      body.content.autoreUltimoAggiornamento = autore;
      body['autoreUltimoAggiornamento'] = autore;      

      //se sto salvando con il warning sulla spesa attivo modifico il valore della deroga
      if (this.datiSpesa.spesa < this.datiSpesa.soglia && this.confermaDeroga=="true") {
        console.log("Richiesta tranche in deroga confermata dall'utente");
        body.content.deroga = true;
      }

      this.$post(url, body)
      .then((result) => {
        this.scheda = result
        console.log("Result= ", result)
        this.attachFileObjects().then(() => {
          if(action != "draft"){
            //Se siamo all'ultimo task, si decreta l'esito definitivo
            if (action == "complete" && this.taskInfo
              && this.taskInfo.taskDefinitionKey == "Tranche_approvaChecklist_UfficioEconomicoFinanziario-Approvatore"){              
              action = (this.esitoFinale === true || this.esitoFinale === 'true') ?  "completeOK" : "completeKO";
              console.info("conditional action=", action);
            }  
            const taskId = this.taskInfo ? this.taskInfo.taskId : '';
            this.completaTask(action, body.idScheda, body.processId, taskId);
          } else { 
            notify.success(notify.strings.success, notify.strings.saveTrancheOk);
            this.disableButtons = false;
          }
        })
      },
      (error) => {
        console.error("Errore save scheda: ", error.message);
        notify.error(notify.strings.error, notify.strings.errorSaveTranche);
        this.disableButtons = false;
        throw error;        
      })
    },
    getCurrentScheda() {
      let scheda = this.getScheda({ codiceProgetto: this.codiceProgetto, id: this.idScheda })
      return tools.genericFunctions.cloneObject(scheda)
    },
    cancelNote(){
      this.notaTemp = "";
    },
    resetReservedNote() {
      this.noteRiservate = "";
    },
    refreshMandatoryFields(){
      //Se tra le azioni ho dei 'check', allora controllo i campi obbligatori
      // if(this.$regIndexOf('check',this.actions) !== -1)
      this.checkCampiObbligatori()
      this.checkFileObbligatori()
    },
    checkCampiObbligatori() {
      this.obbligatoriTable.rows = []
      let scheda = this.getCurrentScheda()
      let controllo = scheda.content.controllo
      //prendo i campi obbligatori
      for (const campo in this.mappa) {
        if (this.mappa[campo].config && this.mappa[campo].config.mandatory) {
          this.handleVisibilityFilesCanceled(scheda, campo, controllo)
          let checkValid = controlliHelper.checkValidValue( this.mappa[campo].config, controllo )
          //Se non c'è un valore valido lo pusho nella tabella degli obbligatori
          if (!checkValid) {
              this.obbligatoriTable.rows.push({
                Tab: this.mappa[campo].config.tab,
                Campo: 'Punto_' + this.mappa[campo].config.idField,
              })
          }
        }
      }
      this.checkAltriCampiObbligatori(scheda)
    },
    checkAltriCampiObbligatori(scheda) {
      for(const id of this.idsToCheck) {
        let mapElement = this.config[id]
        if (mapElement && mapElement.config && mapElement.config.mandatory) {
            let values = jpath('$'+ mapElement.config.path, scheda.content)
            if(values && values.length === 0 || values[0] == null || values[0] === "") {
              this.obbligatoriTable.rows.push({
                Campo: mapElement.config.label,
                Tab: mapElement.config.tab
              })
          }
        }
      }

      this.checkObbligatoriCustomEdit(scheda)
    },
    checkObbligatoriCustomEdit(scheda) {
      // FUNZIONALITA' DELL'EDIT CUSTOM PER RUOLO
      for(const id of this.idsCustomEdit) {
        let mapElement = this.config[id]
        if(mapElement && mapElement.config && mapElement.config.editCustom) {
          let role = this.$getUserInfo().roles[0]
          if(mapElement.config.editCustom.includes(role))
            this.checkValueAndPushMandatory(mapElement, scheda)
        }
      }
    },
    //Controlla eventuali file obbligatori mancanti
    checkFileObbligatori(){
      const scheda = this.getCurrentScheda()
      this.fileObbligatori = []
      //Se la scheda non ha allegati, pushali tutti
      // if(!scheda.content.allegati){
      //   Object.values(this.config.content.tipologiaAllegati)
      //   .filter(tipo => { return tipo.mandatory || tipo.recommended })
      //   .forEach(tipo => {
      //     if(tipo.task === this.taskInfo.taskDefinitionKey)
      //       this.fileObbligatori.push(tipo)
      //   } )       
      //   return
      // }

      let allegati = [];
      if(scheda.content.allegati) {
        allegati = Object.values(scheda.content.allegati);
      }

      for(const tipo in this.config.content.tipologiaAllegati) {
        const config = this.config.content.tipologiaAllegati[tipo];
        if(config.mandatory || config.recommended) {
          if( (allegati.length === 0 || allegati.filter(allegato => allegato.documentType === config.label).length === 0)
              && (this.taskInfo && config.task === this.taskInfo.taskDefinitionKey)) {
                
                // BUG #13062: relazione motivata diventa recommended solo se si è scelta la deroga
                if(tipo === "relazioneMotivata" && 
                  (this.confermaDeroga !== "true" || this.datiSpesa.spesa >= this.datiSpesa.soglia)) {
                    continue;
                }

                this.fileObbligatori.push(config);
            }
            
        }

      }
    },
    checkValueAndPushMandatory(mapElement, scheda) {
    let values = jpath('$'+ mapElement.config.path, scheda.content)
      if(values && values.length === 0 || values[0] == null || values[0] === "") {
        if(mapElement.config.label && mapElement.config.tab) {
          this.obbligatoriTable.rows.push({
            Campo: mapElement.config.label,
            Tab: mapElement.config.tab
          })
        }
      }
    },
    handleVisibilityFilesCanceled(scheda, campo, controllo) {
      let values = jpath({resultType: 'all'}, '$' + campo, controllo)
      if(scheda.content.allegati && values && values.length === 1) {
        let { deletedFiles } = controlliHelper.updateSituationFiles(values[0], Object.keys(scheda.content.allegati), this.deletedFiles) 
        this.deletedFiles = deletedFiles ? deletedFiles : []
        scheda.content.controllo = controllo
        this.updateSchedaInStore(scheda)
      }
    },
    updateSchedaInStore(scheda) {
      this.$store.dispatch("circuitoFinanziario/updateScheda", {
        codiceProgetto: this.codiceProgetto,
        id: this.idScheda,
        content: scheda,
      });
    }, 
    updateEsitoFinale(checked){ 
      let scheda = this.getCurrentScheda()
      scheda.content.esitoFinale = checked
      this.updateSchedaInStore(scheda)     
    },
    updateConfermaDeroga(checked){ 
      let scheda = this.getCurrentScheda()
      scheda.content.deroga = checked;
      this.updateSchedaInStore(scheda)     
    }
  }
}
</script>