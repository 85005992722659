<template scope="props">
    <div id="dettaglioAttivita" class="elencoProgetti">
        <div class="table-title">Dettaglio Attività Bandi<span v-if="descBeneficiario !== ''"> {{descBeneficiario}}</span></div>

        <b-form inline           
        label-align-sm="left"
        class="filtro-notifiche">
        <label class="mr-sm-2" for="filterInput">Filtra risultati</label>
        <b-form-input
        v-model="filter"
        type="search"
        id="filterInput"
        placeholder="Digita un filtro"/>
        <b-button :disabled="!filter" @click="filter = ''">Pulisci filtro</b-button>
        
        </b-form>

        <b-table :filter="filter" :busy="isBusy" id="elencoAttivita" primary-key="dataModifica" hover show-empty
                 empty-text="Nessun dato trovato" empty-filtered-text="Nessun dato trovato"
                 :items="tabledata.rows" :fields="tabledata.header" :sort-by="sortBy" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/helpers/utils.js'
import dashboardUtils from "@/helpers/dashboardUtils.js";

    export default {
        name: 'elencoAttivitaBandi',
        computed: {
            ...mapGetters({
                getDescBeneficiario: 'announcements/getDescBeneficiario'
            }),
        },
        components: {
        },
        props: {
        },
        data() {
            return {
                descBeneficiario: "",
                gruppo:"",
                myAttivitaBandi: [],
                isBusy: true,
                sortBy: 'Codice',
                filter: null,
                tabledata: {
                    "header": [
                        {
                            key: 'Codice',
                            thClass: 'thst',
                            sortDirection: 'asc',
                            sortable: true,
                        },
                        {
                            key: 'Titolo',
                            thClass: 'thst',
                            sortable: true,
                        },
                        {
                            key: 'Processo',
                            thClass: 'thst'
                        },
                        {
                            key: 'Tipo',
                            thClass: 'thst',
                        },
                        {
                            key: 'Scadenza',
                            thClass: 'thst',
                            sortable: true,
                        },
                    ],
                    "rows": [
                        {
                            "Codice": "Non disponibile",
                            "Titolo": "Non disponibile",
                            "Processo": "Non disponibile",
                            "Tipo": "Non disponibile",
                            "Scadenza": "Non disponibile",
                        }
                    ]
                }
            };
        },
        methods: {
            dateFormatter(value) {
                return utils.formatDateTime(value);
            },

            caricaMieAttivita: function () {
                
                dashboardUtils.leggiBandi().then(
                    (result) =>{
                        let listaBandi = result;
                        listaBandi.forEach(
                            (element) => {
                                
                                //console.log("bando=",element);
                                //console.log("task=", element.taskInfo.taskDefinitionKey);
                                
                                let roles = this.$getUserRoles();
                                //console.log("roles=", roles);

                                //se esistono task per l'utente loggato (monoruolo)
                                if (element.taskInfo.taskDefinitionKey.includes(roles[0]) 
                                    ){
                                    
                                    this.myAttivitaBandi.push(element);
                                
                                } 
                                
                            },
                            (error) => {
                                throw error;
                            }
                        );

                        this.extractData();
                        this.isBusy = false;
                    }
                );
                
            },
            
            extractData()
            {
                let rows = [];
                
                for (let i = 0; i < this.myAttivitaBandi.length; i++) {
                    let att = this.myAttivitaBandi[i];
                    
                    let scadenza = "non disponibile";
                    if (att.infoBando.modalitaDiAccesso !== 'Assegnazione diretta' 
                        && att.infoBando.dataFineProposte && att.infoBando.oraFineProposte){
                        scadenza = att.infoBando.dataFineProposte+" ore "+att.infoBando.oraFineProposte;
                    }

                    let row = {
                        "Codice": att.infoBando.idBando,
                        "Titolo": att.infoBando.titoloBando,
                        "Processo": "Awarding Body",
                        "Tipo": att.infoBando.modalitaDiAccesso,
                        "Scadenza": scadenza,
                    }
                    rows.push(row);
                }
                
                this.tabledata.rows = rows;
            },
        },
        
        mounted(){
            this.descBeneficiario = this.getDescBeneficiario();
            this.gruppo = this.$getUserInfo().groups[0];
            this.caricaMieAttivita();
        }
    }

</script>

<style scoped>
</style>


<style lang="scss">
</style>
