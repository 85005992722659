<template>
  <div>
    <div class="card">
        <div class="card-body text-right">
          <h1 class="font-weight-bold">Totale Importi: {{ totaleSpeseEuro }}</h1>
        </div>
      </div>
    <GenericList v-if="!isBusy"
      :id="'listaControlliAmm'" 
      :title="'Lista Controlli Amministrativi'"
      :busyTable="isBusy"
      :headerTable="headerTable"
      :dataTable="dataForList"
      :actionsInRow="this.$getActionsProgettiControlli"
      :actionsMapping="this.objMapping"
      :blockButton="isBusy"
      :sort-by="'codiceProgetto'"
      @clickActionList="doAction"/>
      
  </div>
</template>
<script>

import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue"
import GenericList from "@/components/genericList.vue"

export default {
  name: "listaControlliAmm",
  components: {
    GenericList
  },
  data() {
    return {
      totaleSpeseEuro: "€ 0,00",
      dataForList: [],
      headerTable: [
          {
            key: "codiceProgetto",
            label: "Codice Progetto",
            sortable: true,
          },
          {
            key: "modalitaDiAccesso",
            label: "Modalità",
            sortable: true,
          },
          {
            key: "titoloProgetto",
            label: "Titolo",
            sortable: true,
          },
          {
            key: "dataFirmaConvenzione",
            sortable: true,
            filterByFormatted: true,
            formatter: "dateTimeFormatter",
            label: "Data Firma Convenzione",
          },
          {
            key:"dataConclusione",
            label:"Data Fine Progetto",
            filterByFormatted: true,
            sortable:true,
            formatter: this.dateFormatter,
          },
          {
            key: "costoProgetto",
            label: "Costo Progetto",
            formatter: "currencyFormatter",
            sortable: true,
          },
          {
            key: "strumentoFinanziario",
            sortable: true,
          },
          {
            key: "stato",
            sortable: true,
          },
          {
            key: "controlliAttivi",
            label: "Controlli in Corso",
            sortable: true,

          },
          {
            key: "Azioni",
            thClass: "thst",
          },
        ],
      objMapping: {
        view: "Visualizza Progetto",
        view_controlli_list: "Visualizza lista Controlli"
      },
      isBusy: true,
    };
  },
  mounted() {
    this.extractData();
  },
  
  methods: {
    
    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      let idProgetto = data.item.idProgetto;
      let codiceProgetto = data.item.codiceProgetto;
      switch (action) {
        case "view":    //visualizzazione della scheda progetto
          this.$router.push({
            name: "schedaProgetto",
            params: { idProgetto: idProgetto },
          });
          break;
        case "view_controlli_list": //visualizzazione dei controlli per il progetto selezionato
          this.$router.push({
            name: "listaControlliAmministrativi",
            params: { 
                      codiceProgetto: codiceProgetto,
                      idProgetto: idProgetto 
                    },
          })
          break;
        
        default:
          console.error("Unknown action!", action);
          break;
      }
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },
    dateTimeFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    
    extractData: async function() {
      let userIdGroup = this.$getUserGroupId();
      if(!userIdGroup.includes("Beneficiari")){
        this.headerTable.splice(3, 0,{key:"idBeneficiario", label:"Beneficiario", sortable:true, formatter: "beneficiarioFormatter"});
      }
      this.isBusy = true;
      const url = endpoints.listActiveProjects;
      let totaleSpese=0;
      this.$get(url)
      .then((result) => {
        const url2 = endpoints.getStatsControlliByType('amministrativi');
        this.$get(url2)
        .then((stats) => {
          this.dataForList = result.map(
            item => {
              const obj = parseFloat(item.infoProgetto.costoProgetto);
              if(!isNaN(obj)){
                totaleSpese = totaleSpese + obj;
              }
              item.infoProgetto.controlliAttivi = stats[item.infoProgetto.codiceProgetto] ? stats[item.infoProgetto.codiceProgetto] : 0
              return item.infoProgetto
              
            });
          this.totaleSpeseEuro = utils.formatCurrency(totaleSpese);
          this.isBusy = false;
        },
        (error) => {
          this.isBusy = false;
          //Provo a caricare la lista senza statistiche
           this.dataForList = result.map(item => { return item.infoProgetto })
            notify.error(
              notify.strings.error,
              "Impossibile caricare statistiche"
            );
          console.error("errore caricamento lista",error);
        })
      },
      (error) => {
        this.isBusy = false;
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        console.error("errore caricamento lista",error);
      });
      
    },
    
  },
};
</script>