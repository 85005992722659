var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"show":!_vm.loadComplete || _vm.generationPdf,"rounded":"sm"}},[_c('div',{staticClass:"table-title"},[_vm._v("Scheda Controllo in Loco "+_vm._s(this.controllo.sottoTipoControllo)+" per il Progetto: "+_vm._s(this.$route.params.codiceProgetto)+" ")]),(_vm.loadComplete)?_c('div',[_c('div',{staticClass:"table-title"},[_vm._v("Titolo scheda: "+_vm._s(_vm.controllo.content.titoloControllo))]),(_vm.loadComplete)?_c('b-table-lite',{attrs:{"thead-class":"head","items":_vm.tabledata.rows,"fields":_vm.tabledata.header}}):_vm._e(),_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":"","nav-class":"tabs","content-class":"content"}},[_c('ColorLegend'),(_vm.loadComplete)?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Generale'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Generale'].name)}}},[_c('GeneralViewerCard',{attrs:{"idTablePdf":_vm.conf_export_pdf['Generale'].id_table_pdf,"name":_vm.conf_export_pdf['Generale'].name,"tabName":_vm.tabSelection,"headerTable":_vm.headerTabGenerale,"pathDataEntryConfiguration":'content',"store":'controlli',"fnGetterTab":'getTabellone',"rowIds":_vm.rowIdsGenerale[this.controllo.sottoTipoControllo],"fnGetter":'getScheda',"idEntity":{
                codiceProgetto: this.$route.params.codiceProgetto,
                id: this.$route.params.idControllo
              },"fnSetter":'updateScheda',"actions":_vm.actionsMap[_vm.mappingTabs[1]]}})],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Documentazione'))?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Documentazione'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Documentazione'].name)}}},[_c('Documentazione',{attrs:{"ownerProcessStatus":this.retrieveTaskDefinitionKey,"actions":_vm.actionsMap[_vm.mappingTabs[2]],"entity":this.entity,"getters":this.getters,"setters":this.setters,"payload":this.payload,"riferimento":this.riferimento,"tabName":"Documentazione"}})],1):_vm._e(),(_vm.loadComplete && _vm.isFinanziario() && _vm.isViewAllowed('ControlloFinanziario'))?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Controllo'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Controllo'].name)}}},[(_vm.loadComplete)?_c('Controllo',{attrs:{"idTablePdf":_vm.conf_export_pdf['Checklist_documentale'].id_table_pdf,"title":_vm.conf_export_pdf['Checklist_documentale'].title_page_pdf,"tabName":_vm.tabSelection.toLowerCase(),"config":_vm.updateTabellone('documentale'),"pointingTab":'documentale',"dataObject":'controllo',"store":'controlli',"fnGetter":'getScheda',"fnSetter":'updateScheda',"idEntity":{
                codiceProgetto: this.$route.params.codiceProgetto,
                id: this.$route.params.idControllo},"name":'Check List Controllo Documentale',"headerTable":_vm.headerChecklist,"actions":_vm.actionsMap[_vm.mappingTabs[3]]}}):_vm._e(),(_vm.loadComplete)?_c('Controllo',{attrs:{"idTablePdf":_vm.conf_export_pdf['Checklist_fisico'].id_table_pdf,"title":_vm.conf_export_pdf['Checklist_fisico'].title_page_pdf,"tabName":_vm.tabSelection.toLowerCase(),"config":_vm.updateTabellone('fisico'),"pointingTab":'fisico',"dataObject":'controllo',"store":'controlli',"fnGetter":'getScheda',"fnSetter":'updateScheda',"idEntity":{
                codiceProgetto: this.$route.params.codiceProgetto,
                id: this.$route.params.idControllo},"name":'Check List Controllo Fisico',"headerTable":_vm.headerChecklist,"actions":_vm.actionsMap[_vm.mappingTabs[3]]}}):_vm._e()],1):_vm._e(),(_vm.loadComplete && !_vm.isFinanziario() && _vm.isViewAllowed('ControlloOperativo'))?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Controllo'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Controllo'].name)}}},[(_vm.loadComplete)?_c('Controllo',{attrs:{"idTablePdf":_vm.conf_export_pdf['Checklist_operativo'].id_table_pdf,"title":_vm.conf_export_pdf['Checklist_operativo'].title_page_pdf,"tabName":_vm.tabSelection.toLowerCase(),"config":_vm.getTabellone,"dataObject":'controllo',"store":'controlli',"fnGetter":'getScheda',"fnSetter":'updateScheda',"idEntity":{
                codiceProgetto: this.$route.params.codiceProgetto,
                id: this.$route.params.idControllo},"headerTable":_vm.headerChecklist,"actions":_vm.actionsMap[_vm.mappingTabs[4]]}}):_vm._e()],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Verbale'))?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Verbale'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Verbale'].name)}}},[_c('GeneralViewerCard',{attrs:{"idTablePdf":_vm.conf_export_pdf['Verbale'].id_table_pdf,"name":_vm.conf_export_pdf['Verbale'].name,"tabName":_vm.tabSelection,"headerTable":_vm.headerTabGenerale,"pathDataEntryConfiguration":'content',"store":'controlli',"fnGetterTab":'getTabellone',"rowIds":_vm.rowIdsVerbale,"fnGetter":'getScheda',"fnSetter":'updateScheda',"idEntity":{
                codiceProgetto: this.$route.params.codiceProgetto,
                id: this.$route.params.idControllo
              },"doRefresh":_vm.refreshDich,"actions":_vm.actionsMap[_vm.mappingTabs[5]]}}),_c('br')],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Invio'))?_c('b-tab',{attrs:{"no-body":"","title":"Invio","title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Invio_mandatory'].name)}}},[(_vm.loadComplete)?_c('Invio',{attrs:{"idsToCheck":_vm.arrayMandatory,"tabName":_vm.tabSelection,"actions":_vm.actionsMap[_vm.mappingTabs[6]],"overloadedMap":_vm.setChecklistForInvio},on:{"buildPdf":_vm.generateReport}}):_vm._e()],1):_vm._e()],1)],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }