import Vue from "vue";
import endpoints from "@/components/endpoints.vue";

const state = {
    schede: {},
}

const getters = {
    getScheda(state){
        return (payload) => {
            if(payload.id){
                if(state.schede[payload.id])
                    return state.schede[payload.id]
                else
                    console.error('disposizioni/getScheda missing scheda in store:', payload.id)
            }
            else
                console.error('disposizioni/getScheda required payload missing:', payload)

            return null
        }
    },

    getAllSchede(state) {
        return state.schede;
    }

}

const mutations = {
    SET_SCHEDA(state, payload){
        if(payload.id && payload.scheda){
            state.schede[payload.id] = payload.scheda
        }
        else
            console.error("SET_SCHEDA Error in Payload:", payload)
    },

    SET_SCHEDE(state, schede) {
            if(schede && Array.isArray(schede)) {
                for(const item of schede) {
                    state.schede[item.idScheda] = item;
                }
            }
            else
                console.error("SET_SCHEDE Error in Payload:", schede)
    },
    CLEAR_SCHEDA(state, payload){
        if(payload.id){
            delete state.schede[payload.id]
        }
        else
            console.error("CLEAR_SCHEDA Error in Payload:", payload)
    },
    RESET(state){
        state.schede = {}
    }
}

const actions = {

    setAllSchede(context, codiceProgetto) {
        context.commit("RESET");
        const url = endpoints.disposizioniProgetto(codiceProgetto);
        return Vue.prototype.$get(url).then(
            schede => {
                context.commit("SET_SCHEDE", schede);
                return schede
            }
        )
    },

    setSchedaDisposizione(context, payload){
        let codiceProgetto = payload.codiceProgetto
        let id = payload.idScheda
        if(codiceProgetto && id){
            const url = endpoints.disposizioneByID(codiceProgetto, id)
            return Vue.prototype.$get(url).then(
                scheda => {
                    // console.log("Disposizione letta = ", scheda)
                    context.commit("SET_SCHEDA", {
                        id: id,
                        scheda: scheda
                    })
                    return scheda
                }
            )
        }
        else{
            console.error("Errore setSchedaDisposizione: Required payload missing ", payload)
            return null
        }
    },
    //Aggiorna la scheda nello store effettuando anche la chiamata API
    updateSchedaDisposizione(context, payload){
        let codiceProgetto = payload.codiceProgetto
        let id = payload.idScheda
        let scheda1 = payload.scheda
        if(codiceProgetto && id && scheda1){
            const url = endpoints.disposizioneByID(codiceProgetto, id)
            return Vue.prototype.$put(url, scheda1).then(
                scheda => {
                    console.log("Disposizione aggiornata = ", scheda)
                    context.commit("SET_SCHEDA", {
                        id: id,
                        scheda: scheda
                    })
                    return scheda
                },
                (error) => {
                    console.error("Errore updateSchedaDisposizione: ", error.message)
                    throw error 
                }
            )
        }
        else{
            console.error("Errore updateSchedaDisposizione: Required payload missing ", payload)
            return null
        }
    },
    //Aggiorna la scheda solo nello store
    updateSchedaDisposizioneInStore(context, payload){
        let id = payload.idScheda
        let scheda = payload.scheda
        if(id && scheda){
            context.commit("SET_SCHEDA", {
                id: id,
                scheda: scheda
            })
            return scheda              
        }
        else{
            console.error("Errore updateSchedaDisposizioneInStore: Required payload missing ", payload)
            return null
        }
    },
    createSchedaDisposizione(context, payload){
        let codiceProgetto = payload.codiceProgetto
        let scheda = payload.scheda
        if(codiceProgetto && scheda){
            const url = endpoints.disposizioniProgetto(codiceProgetto)
            return Vue.prototype.$post(url, scheda).then(
                scheda => {
                    console.log("Disposizione creata = ", scheda)
                    context.commit("SET_SCHEDA", {
                        id: scheda.idScheda,
                        scheda: scheda
                    })
                    return scheda
                },
                (error) => {
                    console.error("Errore createSchedaDisposizione: ", error.message)
                    throw error 
                }
            )
        }
        else{
            console.error("Errore createSchedaDisposizione: Required payload missing ", payload)
            return null
        }
    },
    deleteSchedaDisposizione(context, payload){
        let codiceProgetto = payload.codiceProgetto
        let id = payload.idScheda
        if(codiceProgetto && id){
            const url = endpoints.disposizioneByID(codiceProgetto, id)
            return Vue.prototype.$delete(url).then(
                () => {
                    console.log("Disposizione eliminata = ", id)     
                    context.commit("CLEAR_SCHEDA", {
                        id: id
                    })
                    return;              
                },
                (error) => {
                    console.error("Errore deleteSchedaDisposizione: ", error.message)
                    throw error 
                }
            )
        }
        else{
            console.error("Errore deleteSchedaDisposizione: Required payload missing ", payload)
            return null
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}