<template>
  <div id="listaCRUD">

    <div :id="id">
      <h1 class="text-center m-2">{{title}}</h1>
    </div>

    <b-row no-gutters>
      <div><h4 class="mx-2">Filtra i risultati:</h4></div>
      <b-col cols="4">
        <b-form-input
          v-model="genericFilter"
          type="search"
          id="filterInList"
          placeholder="Digita un filtro" />
      </b-col>
      <b-col cols="2" class="ml-3">
        <b-button
          id="filterInListBtn"
          class="mt-1"
          :disabled="!genericFilter"
          @click="genericFilter = ''">Cancella</b-button>
      </b-col>
      <b-col cols="2" style="margin-left: auto;"
          class="align-right">
        <b-button
          class="mx-1"
          :id="'export_pdf'"
          size="lg"
          variant="secondary"
          @click="preparePdf"
          :disabled="blockButton || isBusy">
          Export PDF
        </b-button>
      </b-col>
      <b-col v-if="visibilityAdd">
        <b-button
          style="margin-left: auto;"
          class="align-right"
          @click="doAction('add')"
          variant="outline-primary"
          v-if="visibilityAdd()"
          :disabled="blockButton || isBusy || checkProgettoIsConcluded"
          :title="'Crea nuova '+this.elemento"
          >
          <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
          Nuova {{this.elemento}}
        </b-button>
      </b-col>
    </b-row>

    

    <b-table
      :id="'disposizioni_pagamenti'"
      thead-class="head"
      :items="getItems"
      :fields="tabledata.header"
      :filter="genericFilter"
      >
      <template v-slot:cell(Azione)="data">
        <b-button
        :id="namingIdButton(x, data.index + 1)"
          variant="outline-primary"
          :key="x" v-for="x in data.value"
          :title="buttonTooltip(x)"
          :disabled="blockButton || isBusy"
          size="sm"
          v-on:click="doAction(x, data.index)">
          <fa-icon v-if="x === 'edit'" :icon="['far', 'edit']" class="selector-icons"/>
          <fa-icon v-if="x === 'delete'" :icon="['far', 'trash-alt']" class="selector-icons"/>
          <fa-icon v-if="x === 'view'" :icon="['far', 'eye']" class="selector-icons"/>
        </b-button>
      </template>
    </b-table>

    <b-modal
      :id="'modalForm1-' + this.name"
      size="lg"
      scrollable centered
      dialog-class="modal1-content"
      content-class="modal1-content"
      hide-footer>
      <template v-slot:modal-title>
        <h3>{{editFormData.title}} {{elemento}}</h3>
      </template>
      <ModalForm
        :fdata="this.editFormData"
        :sch="this.getScheda()"
        :buttons="this.buttons"
        :optionalObj="this.optionalObj"
        :parentName="this.name"
        @editField="createOrEditRiga"
        />
    </b-modal>

    <b-modal 
      :id="'modalData-' + this.name"
      size="lg"
      scrollable centered
      hide-footer
      dialog-class="modal1-content"
      content-class="modal1-content"
      ok-only ok-variant='secondary' ok-title='Chiudi'>
      <template v-slot:modal-title>
        <h3>{{modalData.title}} {{elemento}}</h3>
      </template>
      <DynamicTable2 
        :tdata="this.modalData"
        />
        <b-row>
          <b-col class="text-right">
            <b-button class="mx-2" v-if="this.modalData.title === 'Elimina'" variant="danger" @click="deleteScheda(modalData.idScheda)">Elimina</b-button>
            <b-button class="mx-2" variant="secondary" @click="$bvModal.hide('modalData-'+ name)">Chiudi</b-button>
          </b-col>
        </b-row>
    </b-modal>

  </div>
</template>
<script>

import ModalForm from "./modalForm1.vue";
import mapUtils from "@/helpers/mapUtils.js";
import { JSONPath as jpath } from "jsonpath-plus";
import calculation from "@/helpers/calculations.js";
import tool from "@/helpers/tools.js";
import DynamicTable2 from "@/components/dynamicTable2.vue";
import visibility from '@/helpers/visibility.js';
import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue"
import { mapGetters } from 'vuex'
import helper_pdf from "@/helpers/exportPdf.js"
import utils from '@/helpers/utils.js'

export default {
  data() {
    return {
      schedaProgetto: null,
      name: "listaCRUD",
      genericFilter: null,
      role: "",
      tabledata: {
        header: [],
        rows: [],
      },
      // WORKAROUND: sono ids che vengono passati alla modale a partire dai dinamicIds
      idsModal_in_edit_delete_mode: {
        rows: []
      },
      buttons: [
        {
          name: "Salva",
          action: "editField",
          show: true,
          param: "edit",
        },
      ],
      idSchedaManipulate: null,
      conf_export_pdf: {
        "details_schede_disposizione": {
          id_table_pdf: 'disposizioni_pagamenti',
          name:"",
          title_page_pdf: 'Lista Disposizioni di Pagamento',
          nameCollapse: '',
          details_disposizioni: true
        }
      },
      editFormData: {},
      modalData: {},
      lastStarRegex: /\*(?!.*\*)/,
      isIdRegex: /\[.+\]/,
      editLine: -1,
      isBusy: false,
      optionalObj: {
        contratti: [],
        pagamenti: [],
        action: ""
      },
      cronoprog: {},
      dichiarazioni: [],
      idAlfanumDichiarazioni: [],
      contrattiControllati: {}

    };
  },
  components: {
    ModalForm,
    DynamicTable2
  },
  props: {
    dinamicIds: {
      type: Array,
      required: true,
    },
    tableIds: {
      type: Array
    },
    refreshData: Boolean,
    notifyUpdate: Boolean,

    // ----------------- PROP MANDATORY ----------------------------
    id: { type: String, required: true },
    title: { type: String, required: true  },
    dataTable: { type: Array, required: true  },
    actions: { type: Array, required: true},
    store: { type: String, required: true},
    storeFunctions: {type: Object, required: true},
    // -------------------------------------------------------------
    busyTable: { type: Boolean, default: function() { return false } },
    blockButton: { type: Boolean },
    actionsMapping: { type:Object, default: function() { return {} } },
    createEntity: { type: String, default: function() { return '' } },

    mappa: { type: Object, required: true },
    ruoloAmmesso: { type: String, required: true },
    ruoloAmmessoNew: { type: String, required: true },
    elemento: { type: String, required: true },
    
  },
  watch: {
    refreshData: function() {
        this.extractData();
    }
  },

  mounted() {
    this.role = this.$getUserRole(); 
    //console.log("ruolo=", this.role);
    //ricavo il codiceProgetto dall'URL
    this.codiceProgetto = this.$route.params.codiceProgetto;
    //console.log("codiceProgetto:", this.codiceProgetto);
    //carico la schedaProgetto per ricavare l'idBeneficiario
    this.loadAllData();
    
  },

  computed: {
    ...mapGetters({
        getTabelloneFull: 'configuration/getTabelloneFull'
    }),
    checkProgettoIsConcluded() {
      return this.schedaProgetto.schedaProgetto && this.schedaProgetto.schedaProgetto.stato === 'Concluso'
    },
    getItems() {
      let items = [];
      for (const obj of this.tabledata.rows) {

        let item = {
          note: {},
        };
        for (const key in obj) {
          if (!key.match(this.isIdRegex)) {
            item[key] = obj[key];
          } else {
            item[key] = obj[key].value;
            item.note[key] = obj[key].note;
          }
        }
        items.push(item);
      }
      return items;
    },

  },
  methods: {

    async loadAllData() {

      if (!this.mappa) {
        console.error(this.name, "NOT mappa!", this.mappa);
        notify.error(
          notify.strings.error,
          notify.strings.loadListError
        );
        return;
      }
      this.isBusy = true;
      let url = endpoints.getProgettoByCodice(this.codiceProgetto);
      try {
        const result = await this.$get(url);

        this.schedaProgetto = result;

        if (result.schedaProgetto.progetto.beneficiario.beneficiario.idBeneficiario){
          this.idBeneficiario = result.schedaProgetto.progetto.beneficiario.beneficiario.idBeneficiario;
        } else {
          console.error("Progetto "+this.codiceProgetto+" senza idBeneficiario!?!? La disposizione di pagamento non potrà essere creata correttamente");
          notify.error(
            notify.strings.error,
            notify.strings.errorLoadProgetto
          )
        }
      } catch(error) {
        console.error("Errore nel caricamento della schedaProgetto "+this.codiceProgetto, error);
        notify.error(
          notify.strings.error,
          notify.strings.errorLoadProgetto
        );
      }
      try {
        await this.$store.dispatch(`${this.store}/${this.storeFunctions.setAllSchede}`, this.codiceProgetto);
      } catch(error) {
        console.error("Errore nel caricamento delle schede", error);
        notify.error(
          notify.strings.error,
          notify.strings.loadListError
        );
        this.isBusy = false;
        return;
      }
      //console.log("mappa: ", this.mappa);
      //il nome dello store coincide con il nome del tabellone da leggere
      this.tabellone = this.getTabelloneFull(this.store);
      console.log("tabellone versione: ", this.tabellone.version);
      this.extractData();
      
      console.log("Carico il cronoprog per il progetto ", this.codiceProgetto);
      this.caricaCronoprog();
    },

    async preparePdf() {
      let contentScheda = this.cronoprog
      this.isBusy = true;
      // GESTIONE OCCHI (custom)
      this.conf_export_pdf['details_schede_disposizione'].sources = [];
      // Le sources contengono tanti oggetti quanti sono le rows della tabella di disposizioni
      // Ogni row è praticamente un pagamento
      // this.conf_export_pdf['details_schede_disposizione'].sources = await Promise.all(promises_set_schede);
      this.conf_export_pdf['details_schede_disposizione'].sources = this.tabledata.rows.map( row => {

        const scheda = this.getScheda(row.rowId);
        return {
          idScheda: row.rowId,
          scheda: scheda,
          ids_eyes: this.dinamicIds,
          mappa : this.tabellone
        }
      });


      let typeScheda = 'Disposizioni di Pagamento'
      let optPdf = helper_pdf.handleNamingPdf(contentScheda, typeScheda)
      helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
      this.isBusy = false;
    },
    caricaCronoprog(){
      //chiamo cronoprog per avere tutti i contratti ed i pagamenti per il progetto
      const url = endpoints.cronoprogProgetti(this.codiceProgetto);

      this.$get(url)
      .then((result) => {

        this.cronoprog = result;
        this.caricaDichiarazioniSpesa();
      },
      (error) => {
        this.isBusy = false
          notify.error(
            notify.strings.error,
            notify.strings.internalErrorPleaseReportAction
          )
        console.error("errore caricamento cronoprog", error)
        throw error
      })
      
    },
    
    caricaDichiarazioniSpesa(){
      //carico tutte le dichiarazioni di spesa
      const url = endpoints.getControlliAmministrativi(this.codiceProgetto);

      this.$get(url)
      .then((result) => {
        console.log("Trovati "+result.length+" controlli amministrativi");
        //prendo solo le dichiarazioni di spesa esitate, fase 2
        for (let i=0; i< result.length; i++){
          if (result[i].sottoTipoControllo === "2" && result[i].stato.includes("sitat")){
            console.log("Aggiungo la dichiarazione "+result[i].idSchedaControllo+" per il contratto ", result[i].riferimentoContratto, result[i].cronoprogItemId);
            this.dichiarazioni.push(result[i]);
            this.idAlfanumDichiarazioni.push(result[i].idSchedaControllo);
            this.contrattiControllati[result[i].cronoprogItemId] = result[i];
          }
        }
        console.log("Trovate "+this.dichiarazioni.length+" dichiarazioni di spesa esitate", this.idAlfanumDichiarazioni);
      
        let idAlfanumericiContratti = Object.keys(this.contrattiControllati);
        console.log("Contratti Controllati: ", idAlfanumericiContratti);
        
        //partendo dai contratti controllati recupero il dato intero da cronoprog
        this.optionalObj.contratti = [];
        for (const contr of idAlfanumericiContratti){ 
          let datiContratto = this.cronoprog.content.contratti[contr].content;
          datiContratto.id = this.cronoprog.content.contratti[contr].id;
          console.log("Cerco il contratto "+contr+" in cronoprog, trovato: ",  datiContratto.riferimentoContratto);
          this.optionalObj.contratti.push(datiContratto);
        }
        console.log("Contratti passati alla modale:", this.optionalObj.contratti.length);
        
        //da tutti i pagamenti flaggati estraggo quelli inclusi nelle dichiarazioni esitate 
        this.optionalObj.pagamenti = [];
        for (const pagamentoKey in this.cronoprog.content.pagamenti){

          let pagamento = this.cronoprog.content.pagamenti[pagamentoKey];

          if (pagamento.content.flagged && this.idAlfanumDichiarazioni.includes(pagamento.content.flagged)){
            let datiPagamento = pagamento.content;
            datiPagamento.id = pagamentoKey; 
            this.optionalObj.pagamenti.push(datiPagamento);
          }
          
        }
        console.log("Pagamenti passati alla modale:", this.optionalObj.pagamenti);
        this.isBusy = false;
      },
      (error) => {
        this.isBusy = false
          notify.error(
            notify.strings.error,
            notify.strings.internalErrorPleaseReportAction
          )
        console.error("errore caricamento cronoprog", error)
        this.isBusy = false;
      })
    },

    visibilityAdd () {
      //confronta il ruolo attuale dell'utente con quello passato al componente via props, se combaciano l'utente può aggiungere elementi alla lista
      //se è stato concesso l'edit posso anche creare nuovi elementi
      return (this.role.includes(this.ruoloAmmesso)||this.role.includes(this.ruoloAmmessoNew))&& this.actions.includes('edit');
    },

    // Costruzione id per Buttoni (Azioni nel componente)
    namingIdButton(action, index) {
      return this.$builtIdDinamically(this.name, action, index)
    },

    buttonTooltip(action) {
      if (action == "view"){
        return "Visualizza "+this.elemento;
      } else if (action == "edit"){
        return "Modifica "+this.elemento;
      } else if(action == "delete"){
        return "Elimina "+this.elemento;
      } else {
        console.error("Azione "+action+" senza didascalia gestita!");
      }
    },

    tooltip(value, key, item) {
      if (item.note[key]) return { title: "nota: " + item.note[key] };
      return {};
    },

    async updateSchedaInStore(scheda, idScheda) {
      //console.log("codiceProgetto", this.codiceProgetto);
      //console.log("scheda", scheda);
      //console.log("update scheda in store: ", idScheda);
      //aggiornare questo elemento nello store
      let payload = { codiceProgetto: this.codiceProgetto, idScheda: idScheda, scheda: scheda}
      await this.$store.dispatch(`${this.store}/${this.storeFunctions.updateScheda}`, payload);
      //ricaricare nuovamente tutta la lista
      this.doRefresh();       
    },

    async createSchedaInStore(scheda) {
      console.log("codiceProgetto", this.codiceProgetto);
      console.log("crea scheda", scheda);

      scheda.content.dataEntryConfiguration = {}
      scheda.content.dataEntryConfiguration = scheda.dataEntryConfiguration
      // WORKAROUND: spostata la dataEntryConfiguration dentro content

      //passa elemento allo store che lo crea via API al BE
      let payload = { codiceProgetto: this.codiceProgetto, scheda: scheda}

      await this.$store.dispatch(`${this.store}/${this.storeFunctions.createScheda}`, payload);
      //ricaricare nuovamente tutta la lista
      this.doRefresh();       
    },

    getDati() {
      return this.$store.getters[`${this.store}/${this.storeFunctions.getAllSchede}`];
    },
    formatter(value, key) {
      const mapElement = this.mappa[key];
      if(!mapElement) {
            return value;
      }
      return utils.formatOutput(mapElement, value);
    },

    extractData() {

      let ids = this.tableIds;
      
      if(!ids || ids.length === 0) {
        console.error(this.name, "Invalid ids", this.tableIds);
        return;
      }
      const dati = Object.values(this.getDati());
      this.tabledata.rows = [];
      this.tabledata.header = [];
      // setup header
      for (const colId of ids) {
        let mapElement = this.mappa[colId];
        if (!mapElement) {
          console.error("no config, get default one for ", colId);
          mapElement = this.$getDefaultConfig();
          mapElement.config.path = colId;
        }
        const headerColumn = {
          key: colId,
          label: mapElement.config.label,
          tdAttr: this.tooltip,
          formatter: this.formatter
        };
        this.tabledata.header.push(headerColumn);
      }
      this.tabledata.header.push({ key: "Azione", label: "Azione" });

      // numero di righe della tabella
      const rowMultiplicity = dati.length;


      // add rows to table
      let updateScheda = false;
      for (let i = 0; i < rowMultiplicity; i++) {
        
        let scheda = dati[i];
        if (!scheda) {
          console.error(this.name, "empty scheda", scheda);
          return;
        }

        let rowObj = {
          _cellVariants: {},
          Azione: this.actions,
          rowId: scheda.idScheda
        };
        
        for (const colId of this.tableIds) {
          let id = colId;
          
          const mapElement = this.mappa[colId];
          // il valore viene calcolato automaticamente o ricavato dalla scheda
          let retVal = this.getValue(colId, id, mapElement, scheda);

          scheda = retVal.scheda;
          const result = retVal.result;
          updateScheda = updateScheda || retVal.updateScheda;
          // setup della riga
          const setupVal = mapUtils.setupComponentRow(
            rowObj,
            id,
            result,
            this.mappa,
            scheda,
            null,
            this.actions
          );
          rowObj = setupVal.rowObj;
        }
        this.tabledata.rows.push(rowObj);
      }
      // dinamicIds è un set di id più grande di tableIds
      // PROBLEMATICA: visto l'accoppiamento che ho con tabledata
      // La Modale vedrebbe gli ids di tableIds (quindi non tutti!!! che sarebbero i dinamicIds)
      for (let i = 0; i < rowMultiplicity; i++) {
        
        let scheda = dati[i];
        if (!scheda) {
          console.error(this.name, "empty scheda", scheda);
          return;
        }

        let rowObj = {
          _cellVariants: {},
          Azione: this.actions,
          rowId: scheda.idScheda
        };
        
        for (const colId of this.dinamicIds) {
          let id = colId;
          
          const mapElement = this.mappa[colId];
          // il valore viene calcolato automaticamente o ricavato dalla scheda
          let retVal = this.getValue(colId, id, mapElement, scheda);

          scheda = retVal.scheda;
          const result = retVal.result;
          updateScheda = updateScheda || retVal.updateScheda;
          // setup della riga
          const setupVal = mapUtils.setupComponentRow(
            rowObj,
            id,
            result,
            this.mappa,
            scheda,
            null,
            this.actions
          );
          rowObj = setupVal.rowObj;
        }
        this.idsModal_in_edit_delete_mode.rows.push(rowObj);
      }
    },

    getValue(colId, id, mapElement, scheda) {
      let result;
      let updateScheda = false;
      const results = jpath("$" + id, scheda);
      // se il campo non esiste nella scheda viene creato
      // e si aggiorna la scheda
      if (results.length === 0) {
        // stampo un log solo se il valore non deve essere calcolato
        // altrimenti è probabile che esso non sia presente nella scheda
        if (!mapElement.config.calculationRule) {
          console.error('valore non trovato in scheda: ', id);
        }
        const retVal = mapUtils.createElementFromScratch(
          id,
          scheda,
          this.mappa
        );
        scheda = retVal.scheda;
        result = retVal.object;
        
        updateScheda = true;
      } else if (results.length === 1) {
        result = results[0];
      } else {
        console.error(this.name, "is an array?", colId, id, results);
      }

      // a prescindere dal fatto che sia presente in scheda o meno, 
      // se è prevista una calculation rule allora il campo
      // viene ricalcolato e il result viene sovrascritto
      // qui si inserisce il valore calcolato se previsto dal tabellone
      if (mapElement.config.calculationRule) {
        result = calculation.calculateValue(mapElement, scheda, id, null, this.optionalObj);
      }

      return {
        scheda: scheda,
        result: result,
        updateScheda: updateScheda
      }
    },

    //TBD: questa funzione dipende dall'oggetto che si sta trattando, si potrebbe costruire partendo dalla configurazione disponibile
    //NB: inserire qui tutti gli oggetti necessari a valorizzare le tendine, in questo caso contratti e pagamenti dal cronoprog
    buildEmptyScheda(){
      return {
        disposizione: {
          riferimentoContratto: "",
          oggettoContratto: "",
          soggettoAttuatore: "",
          riferimentoCircuito: "",
          importoCircuito: 0,
          numero: "",
          data: "",
          importo: 0,
          oggetto: "",
          pagamenti: []
        }
      };
    },

    getScheda(idScheda) {
      if (!idScheda){
        return this.buildEmptyScheda();  
      }  
      return this.$store.getters[`${this.store}/${this.storeFunctions.getScheda}`]({id: idScheda})
    },
    //Recupera la scheda dallo store, e se non è disponibile, effettua la dispatch settando la scheda
    async setSchedaInStore(idScheda) {
      const scheda = this.getScheda(idScheda)
      if(scheda){
        return scheda
      }
      else{
        let payload = { codiceProgetto: this.$route.params.codiceProgetto, idScheda: idScheda}
        return this.$store.dispatch(`${this.store}/${this.storeFunctions.setScheda}`, payload)
      }
    },

    async deleteScheda(idScheda){
      if(!idScheda){
        console.error("deleteScheda missing id: ", idScheda)
        return
      }
      
      let payload = { codiceProgetto: this.codiceProgetto, idScheda: idScheda}
      console.info("Payload: ", payload);
      await this.$store.dispatch(`${this.store}/${this.storeFunctions.deleteScheda}`, payload)
      this.$bvModal.hide("modalData-" + this.name);
      //Refresh
      this.doRefresh()     
    },

    createOrEditRiga(actionFromModal) {
      this.$bvModal.hide("modalForm1-" + this.name);
      let id_scheda_By_modal = actionFromModal.idScheda
      if(id_scheda_By_modal) this.editRiga(actionFromModal)
      else this.creaRiga(actionFromModal)
    },

    creaRiga(actionFromModal){
      console.log('creaRiga: actionFromModal=', actionFromModal);
      
      let rowObj = this.tabledata.rows[this.editLine];

      let schedaComplete = {
          codiceProgetto: this.codiceProgetto,
          idBeneficiario: this.idBeneficiario,
          tipoControllo: "CircuitoFinanziario",
          sottoTipoControllo: "Disposizione",
          idSchemaControllo: "disposizione",
          schemaVersione: this.tabellone.version,
          content: {
              tipoControllo: "CircuitoFinanziario",
              sottoTipoControllo: "Disposizione",
              codiceProgetto: this.codiceProgetto,
              disposizione: {
                pagamenti: []     //segna posto contenitore vuoto
              }
          },
          taskInfo: {
            taskDefinitionKey: "Disposizione_disposizione_Beneficiario-Operatore"
          },
          
      }
      
      if (!rowObj) {
        rowObj = {
          _cellVariants: {},
        };
      }
      
      const retVal = mapUtils.updateComponent(
        actionFromModal,
        schedaComplete,
        rowObj,
        this.mappa,
        this.actions,
        this.title,
        this.optionalObj
      );
      
      const clonedScheda = retVal.clonedScheda;
      
      this.createSchedaInStore(clonedScheda);
    },

    editRiga(actionFromModal) {
      console.log('editRiga: actionFromModal=', actionFromModal);
      let idSch = actionFromModal.idScheda

      if (!idSch){
        console.error("Non posso aggiornare una scheda senza ID!?");
        return;
      }

      let rowObj = this.tabledata.rows[this.editLine];

      const schedaComplete = this.getScheda(idSch);
      
      if (!rowObj) {
        rowObj = {
          _cellVariants: {},
        };
      }
      
      const retVal = mapUtils.updateComponent(
        actionFromModal,
        schedaComplete,
        rowObj,
        this.mappa,
        this.actions,
        this.title,
        this.optionalObj
      );
      
      const clonedScheda = retVal.clonedScheda;
      clonedScheda.content.dataEntryConfiguration = {}
      clonedScheda.content.dataEntryConfiguration = clonedScheda.dataEntryConfiguration
      // WORKAROUND: spostata la dataEntryConfiguration dentro content
      // console.log('fine',this.tabledata.rows);
      this.updateSchedaInStore(clonedScheda, idSch);
      
    },

    addRiga() {
      this.editFormData = { content: {}, conf: {}, idScheda: null};

      let mapElement;
      const newIndex = this.tabledata.rows.length;
      this.editLine = newIndex;
      const scheda = {};
      
      for (const colId of this.dinamicIds) {
        mapElement = tool.genericFunctions.cloneObject(
          mapUtils.getMapElement(this.mappa, colId)
        );
        // replace path with correct id not possible
        // mapElement.config.path = colId;
        mapElement.data = {}
        mapElement.data = this.$getDefaultData();
        
        mapElement.data.added = true;

        let value = null;
        // automatic value calculation
        if (mapElement.config.calculationRule) {
          value = calculation.calculateValue(mapElement, scheda, colId, null, this.optionalObj);
        }

        if (mapElement.config.type === 'enum' && mapElement.config.enumRule) {
          mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda, this.optionalObj);
        }

        if (colId == "['content']['disposizione']['pagamenti']"){
          // console.error(colId+" ??? value=", value);
          //TBD: inizializzazione cablata, trovare un modo più elegante per farlo
          value = [];
        }

        let val = { id: colId, value: null };
        val.value = value;
        this.editFormData.conf[colId] = mapElement;
        this.editFormData.content[colId] = val;
        this.editFormData.title = 'Crea';
      }
    },

    async doAction(actionToDo, index) {
      this.isBusy=true

      let row;
      let idSch;
      let scheda;

      if (actionToDo != 'add'){
        row = this.idsModal_in_edit_delete_mode.rows[index];
        idSch = row.rowId
        //Carico la scheda nello store
        try {
          scheda = await this.setSchedaInStore(idSch)
        } catch(error) {
          console.log('errore caricamento disposizione', error);
          this.isBusy = false
          notify.error(
            notify.strings.error,
            notify.strings.loadSchedaError
          )
          return true
        }
      }

      //passo l'azione per far funzionare le calculation in modo diverso
      this.optionalObj.action = actionToDo;
            
      switch (actionToDo) {
        case "add":
          {
            this.addRiga();
            this.$bvModal.show("modalForm1-" + this.name);
            this.isBusy = false;
          }
          break;
        case "delete":
          {
            console.log("delete scheda with ID=", idSch);
            
            this.modalData = {};

            let modalRows = this.buildModalRows(scheda);

            this.modalData = {
              header : ['Campo', 'Valore'],
              rows: modalRows,
              title: 'Elimina',
              idScheda: idSch
            }

            this.$bvModal.show("modalData-" + this.name);
            this.isBusy=false
            
          }
          break;
        case "edit":
          {
            this.editFormData = { content: {}, conf: {}, idScheda: idSch };    
            console.log("edit scheda with ID=", idSch);
            // get fresh data from storage
            //const scheda = this.getScheda(idSch);
            for (const column in row) {
              // skip non consistent info
              if (!column.match(this.isIdRegex))
                continue;
              const id = row[column].id;
              let mapElement = tool.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, id));
              // skip hidden values
              if(mapElement.config.hiddenRule) {
                const hidden = visibility.computeHidden(id, scheda, mapElement);
                if(hidden)
                  continue;
              }
              const dataEntry = scheda.content.dataEntryConfiguration;
              // ricava la parte "data" dalla scheda o ne crea una di default
              if (dataEntry && dataEntry[id]) {
                mapElement.data = tool.genericFunctions.cloneObject(
                  dataEntry[id].data
                );
              } else {
                mapElement.data = this.$getDefaultData();
              }
              // calcolo automatico degli enumValues, la funzione è definita nel tabellone
              if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
                // TODO questo controllo presuppone che tutte le enum calcolate diventino readonly in modifica
                // se non è così bisogna pensare ad un'alternativa (readonlyRule?)
                mapElement.config.readonly = true;
                mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda, this.optionalObj);
              }

              let val = { id: id, value: null};
              // TODO capire se serve && !mapElement.config.readonly
              if (mapElement.config.calculationRule ) {                 
                val.value = calculation.calculateValue(mapElement, scheda, id, null, this.optionalObj);
              }
              else {
                const values = jpath("$" + id, scheda);
                if (values.length === 0) {
                  console.error("valore non trovato in scheda: ", id);
                } else {
                  val.value = values[0];
                }
              }

              this.editFormData.conf[id] = mapElement;
              this.editFormData.content[id] = tool.genericFunctions.cloneObject(val);
              this.editLine = index;
              this.editFormData.title = 'Modifica';
            }
            
            console.log("partenza edit, editFormData: ",this.editFormData);
            this.$bvModal.show("modalForm1-" + this.name);
            this.isBusy=false
          }
          break;
        case 'view':
          {
            console.log("view scheda with ID=", idSch);
            
            this.modalData = {};
            let modalRows = this.buildModalRows(scheda);

            this.modalData = {
              header : ['Campo', 'Valore'],
              rows: modalRows,
              title: 'Visualizza',
              idScheda: idSch
            }

            this.$bvModal.show("modalData-" + this.name);
            this.isBusy=false
          
          }
          break;

        default:
          this.editFormData = {};
          break;
      }
    },

    buildModalRows(scheda){
      let ids = this.dinamicIds;
      let modalRows = [];
      for (const index in ids) {
          // skip non consistent info
          const id = ids[index]
          if (!id && !id.match(this.isIdRegex))
            continue;
          
          const temp = mapUtils.getMapElement(this.mappa, id)
          let mapElement = tool.genericFunctions.cloneObject(temp);
          // compute field visibility
          if(mapElement.config.hiddenRule) {
            const hidden = visibility.computeHidden(id, scheda, mapElement);
            if(hidden)
              continue;
          }
          const dataEntry = scheda.content.dataEntryConfiguration;

          if (dataEntry && dataEntry[id]) {
            mapElement.data = tool.genericFunctions.cloneObject(
              dataEntry[id].data
            );
          } else {
            mapElement.data = this.$getDefaultData();
          }
          let value;
          if(mapElement.config.calculationRule) {
            value = calculation.calculateValue(mapElement, scheda, id, null, this.optionalObj);
          }
          else {
            const values = jpath("$" + id, scheda);
            if (values.length === 0) {
              console.error("valore non trovato in scheda: ", id);
            } else {
              value = values[0];
            }
          }

          let modalRow = { 
            content: {
                Campo : mapElement.config.label,
                Valore : value,
            },
            conf: {} 
          };
          modalRow.conf[id] = mapElement;
          modalRows.push(modalRow);
      }

      return modalRows;
    },

    doRefresh(){
      this.$emit('doRefresh');
    }
  },
};
</script>
