/**
 * calcola la key di una scheda progetto
 * @param SchedaProgetto la scheda progetto di cui calcolare la key
 */
const calculateSchedaProgettoKey = (schedaProgetto) => {
    return schedaProgetto.progetto.idProgetto + '_v' + schedaProgetto.progetto.idVersione
}

/**
 * calcola la key di una scheda monitoraggio
 * @param SchedaMonitoraggio la scheda monitoraggio di cui calcolare la key
 */
const calculateSchedaMonitoraggioKey = (schedaMonitoraggio) => {
    return schedaMonitoraggio.idPeriodo + '_' 
      + schedaMonitoraggio.progetto.codiceProgetto 
      + '_v' + schedaMonitoraggio.idVersione
}

/**
 * restituisce, per un dato idPeriodo, le date di inizio e fine in un oggetto dateConstraints
 * @param idPeriodo periodo in formato AAAAn, es. 20190, 20203, ecc.
 */
function getMonitoraggioDateConstraint(idPeriodo) {
    const year = parseInt(idPeriodo/10);
    const trimestre = idPeriodo%10;

    let dataInizio = new Date();
    let dataFine = new Date();

    switch (trimestre) {
		case 0:
            dataInizio.setUTCFullYear(year, 0, 1);
            dataFine.setUTCFullYear(year, 2, 31);
			break;
		case 1:
            dataInizio.setUTCFullYear(year, 3, 1);
            dataFine.setUTCFullYear(year, 5, 30);
			break;
		case 2:
            dataInizio.setUTCFullYear(year, 6, 1);
            dataFine.setUTCFullYear(year, 8, 30);
			break;
		case 3:
            dataInizio.setUTCFullYear(year, 9, 1);
            dataFine.setUTCFullYear(year, 11, 31);
			break;
		default:
			console.error("getMonitoraggioDateConstraints: Periodo incoerente", idPeriodo);
			return {};
	}

    dataInizio.setUTCHours(0, 0, 0, 0);
    dataFine.setUTCHours(23, 59, 59, 999);

    return {
        dataInizio: dataInizio.toISOString(),
        dataFine: dataFine.toISOString(),
    };
}

/**
 * restituisce, per un dato anno finanziario, le date di inizio e fine in un oggetto dateConstraints
 * @param idPeriodo anno in formato AAAA, es. 2019, 2020, ecc.
 */
function getAssessmentDateConstraint(idPeriodo) {
    let year = parseInt(idPeriodo);

    let dataInizio = new Date();
    let dataFine = new Date();

    // l'anno finanziario parte dal 1 Ottobre dell'anno precedente a quello di riferimento
    // Esempio: idPeriodo = 2021 -> anno dal 1 Ottobre 2020 al 1 Ottobre 2021

    dataInizio.setUTCFullYear(year - 1, 9, 1);
    dataFine.setUTCFullYear(year, 8, 30);
	
    dataInizio.setUTCHours(0, 0, 0, 0);
    dataFine.setUTCHours(23, 59, 59, 999);

    return {
        dataInizio: dataInizio.toISOString(),
        dataFine: dataFine.toISOString(),
    };
}





/**
 * calcola il periodo di una scheda monitoraggio (I trimestre, II trimestre...)
 * @param periodo il periodo in format AAAAn, es. 20193, 20202, ecc.
 */
/* UNUSED
function getPeriodoAsString(periodo) {
    const year = parseInt(periodo/10);
    const trimestre = periodo%10;

    switch (trimestre) {
        case 0:
            return "I Trimestre " + year;
        case 1:
            return "II Trimestre " + year;
        case 2:
            return "III Trimestre " + year;
        case 3:
            return "IV Trimestre " + year;
        default: 
            console.error("trimestre invalido!", periodo, trimestre);
    }
    return "";
}
*/
export default {
  calculateSchedaProgettoKey,
  calculateSchedaMonitoraggioKey,
  getMonitoraggioDateConstraint,
  getAssessmentDateConstraint
//   getPeriodoAsString
}
