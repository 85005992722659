<template>
  <div>
    <b-table :id="idTablePdf" hover :items="rowsTable" :fields="generaleHeader"></b-table>
  </div>

</template>
<script>
import utils from '@/helpers/utils.js'
export default {
    name:"schedaIstruttoria",
    props: {
      infoScheda: {
        type: Array,
        default: () => []
      },
      fieldToShowInTable: {
        type: Array,
        default: () => []
      },
      idTablePdf: { type: String, default: () => '' }
    },
    data() {
      return {
        generaleHeader: [
            { key: "id", label: "Campo" },
            { key: "value", label: "Descrizione" },
        ],
        fieldToShow: ['beneficiario'],
        rowsTable: []
      }
    },
    mounted() {
      const idIstruttoria = this.$route.params.idIstruttoria;
      if(idIstruttoria && this.infoScheda.length > 0) {
        let info = this.infoScheda[0];
        this.fieldToShowInTable.forEach(element => {
          let obj = {}
          if(element.showGeneral) {
            let value = info[element.key];
            if(element.formatter)
              value = this.formatter(element, value);
            obj.id = element.label
            obj.value = value
            this.rowsTable.push(obj)
          }
          
        });
      }
    },
    methods: {
      formatter(element, value) {
        switch(element.formatter) {
          case 'dateFormatter':
            if (!value)
              return null;
            else
              return utils.formatDate(value);
          default:
            return value;
        }
      },
    }
}
</script>