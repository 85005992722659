<template>
  <b-container>
    <b-row>
      <b-col cols="9">
        <h4 class="text-center">Selezionare Strumento Finanziario</h4>
        <b-form-select size="lg"
          v-model="isf"
          :options="beneficiari"
          @input="manageObiettivi"
          required/>
      </b-col>
    </b-row>
    <div v-if="visibilityObiettiviSpecifici">
      <b-row>
        <b-col cols="9">
          <h4 class="text-center">Selezionare Obiettivo Specifico</h4>
          <b-form-select size="lg"
            v-model="selectedObiettiviSpecifici"
            :options="obiettiviSpecificiArray"
            @input="manageObiettiviSpecifici"
            required/>
        </b-col>
      </b-row>
      <span>
        <b-row
          v-for="(item, index) in arrayObiettiviSpecicifyBack"
          :key="item.value"
          class="list-small ">
          <b-col cols="7" class="scelte">
            {{ item.text }}
          </b-col>
          <b-col>
            <b-button
              title="Rimuovi Obiettivo"
              variant="outline-primary"
              size="sm"
              @click="removeObiettivoSpecifico(index, item)">
              <fa-icon :icon="['far', 'trash-alt']" class="small-icons" />
            </b-button>
          </b-col>
        </b-row>
      </span>
    </div>
    <div v-if="visibilityObiettiviNazionali">
      <b-row>
        <b-col cols="9">
          <h4 class="text-center">Selezionare Obiettivo Nazionale</h4>
          <b-form-select size="lg"
            v-model="selectedObiettiviNazionali"
            :options="obiettiviNazionaliArray"
            @input="manageObiettiviNazionali"
            required/>
        </b-col>
      </b-row>
      <span>
        <b-row
          v-for="(item, index) in arrayObiettiviNazionaliBack"
          :key="item.text"
          class="list-small ">
          <b-col cols="7" class="scelte">
            {{ item.text }}
          </b-col>
          <b-col>
            <b-button
              title="Rimuovi Obiettivo"
              variant="outline-primary"
              size="sm"
              @click="removeObiettivoNazionale(index)">
              <fa-icon :icon="['far', 'trash-alt']" class="small-icons" />
            </b-button>
          </b-col>
        </b-row>
      </span>
    </div>
    <b-row v-if="visibilityBudget">
      <b-col>
        <h4>Budget Massimo </h4>
      </b-col>
      <b-col cols="6">
        <b-form-input
          id="budgetMassimo"
          v-model="budgetMassimo"
          type="number"
          :state="budgetControl"/>
          <b-form-invalid-feedback id="input-live-feedback">
            <h4 class="text-center m-2 text-danger">
              Inserire Un Importo Maggiore Di Zero
            </h4>
          </b-form-invalid-feedback>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
    <b-row >
      <b-col class="modal-footer text-left">
        <h4 v-if="!visibilitySend">Caricamento Scheda Progetto...</h4>
      </b-col>
      <b-col class="modal-footer text-right">
        <b-button
          v-if="visibilitySend"
          class="btn-success"
          @click="invioNuovoProgetto"
          :disabled="!blockButton || allObSpecificiCoupled">
          Crea Nuova Scheda Progetto
        </b-button>
        <b-button variant="danger" 
          class="modal-default-button"
          :disabled="!visibilitySend"
          @click="closeModal">
          Chiudi
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import notify from "@/helpers/notifications.js";

export default {
  name: "selezioneObiettivi",
  data() {
    return {
      isf: "",
      visibilityObiettiviSpecifici: false,
      visibilityObiettiviNazionali: false,
      visibilityBudget: false,
      visibilitySend: true,
      selectedObiettiviNazionali: null,
      selectedObiettiviSpecifici: null,
      budgetMassimo: null,
      arrayObiettiviSpecicifyBack: [],
      arrayObiettiviNazionaliBack: [],
      obiettiviSpecificiArray: [],
      obiettiviNazionaliArray: [],
      beneficiari: [
        {
          text: "ISF1",
          value: "ISF1",
        },
        {
          text: "ISF2",
          value: "ISF2",
        },
      ],
      financialTools: {},
      descBeneficiario: ""
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModalCreateProject')
    },
    async invioNuovoProgetto() {

      // modifico valore prima di inviarlo al back
      this.visibilitySend = false
      this.arrayObiettiviNazionaliBack.forEach((element) => {
        element.value = element.value.substring(0, 3);
      });
      // let url = endpoints.newProgettoByListBandi;
      let obiettiviBack;
      let arrayObiettivi= [];
      // Filtro le financialTools in base alle scelte
      // Prima per strumento finanziario
      let financialTools_filtered_ISF = this.financialTools["obiettiviNazionali"][this.isf]
      let obiettiviNazScelti = []
      for (let strumentoFinanziario in financialTools_filtered_ISF) {
        this.arrayObiettiviNazionaliBack.forEach(obiettivoNaz => {
          financialTools_filtered_ISF[strumentoFinanziario].forEach(element => {
            if(element.titolo === obiettivoNaz.text.substr(4) && element.codice === obiettivoNaz.value) {
              obiettiviNazScelti.push(element)
            }
          });
        })
      }
      this.arrayObiettiviSpecicifyBack.forEach((obspecifico) => {
        obiettiviNazScelti.forEach(obnazionale => {
          financialTools_filtered_ISF[obspecifico.value].filter((item) => {
            if(item.titolo === obnazionale.titolo) {
              // Creo l'obiettivo Nazionale da passare al BE
              let obNaz = {
                'codice': item.codice,
                'obiettivoSpecifico': {
                  codice : obspecifico.value,
                  titolo : obspecifico.text
                },
                'titolo': item.titolo
              }
            arrayObiettivi.push(obNaz);
            }
          });
        });
      })
      obiettiviBack = {
        obiettivoNazionale: arrayObiettivi
      };
      let obj = {
        strumentoFinanziario: this.isf,
        budgetMassimo: this.budgetMassimo,
        obiettivoProgetto: obiettiviBack,
        denominazione: this.descBeneficiario
      };

      // creo una nuova scheda progetto
      // la dispatch crea la scheda e avvia il processo
      // la updatestate del processo, al primo step, crea il cronoprog trunk
      // e stacca un branch chiamando anche la checkout
      let idProgetto, schedaProgetto;
      try {
        const newSchedaDoc = await this.$store.dispatch("progetto/createSchedaProgetto", obj);
        schedaProgetto = newSchedaDoc.content;
        idProgetto = newSchedaDoc.idProgetto;
        console.log("creata schedaProgetto con idProgetto ", idProgetto, schedaProgetto);
      } catch(error) {
        console.error('Errore creazione scheda progetto:', error.message);
        notify.error(notify.strings.error, notify.strings.errorCreateNewProgetto);
        this.visibilitySend = true
        return;
      }
      // mi sposto sulla schedaProgetto che caricherà in automatico
      // l'ultimo cronoprog disponibile dal branch
      this.$router.push({
        name: 'schedaProgetto',
        params: { idProgetto: idProgetto },
      });
      this.visibilitySend = true
    },
    manageObiettivi(value) {
      this.obiettiviSpecificiArray = [];
      this.arrayObiettiviSpecicifyBack = [];
      this.obiettiviNazionaliArray = [];
      this.arrayObiettiviNazionaliBack = [];
      this.financialTools = this.getFinancialTools;
      let obiettiviNazionali = this.financialTools["obiettiviNazionali"][value];
      for (const key in obiettiviNazionali) {
        let newOption = {
          text: key + "-" + this.financialTools["obiettiviSpecifici"][key],
          value: key,
        };
        this.obiettiviSpecificiArray.push(newOption);
      }
      this.visibilityObiettiviSpecifici = true;
      this.visibilityObiettiviNazionali = false;
      this.visibilityBudget = false;
    },
    manageObiettiviSpecifici(value) {
      this.selectedObiettiviNazionali = null
      this.obiettiviSpecificiArray.forEach((element) => {
        if (element.value === value) {
          if (this.arrayObiettiviSpecicifyBack.length === 0) {
            this.arrayObiettiviSpecicifyBack.push(element);
          } else {
            let filter = this.arrayObiettiviSpecicifyBack.filter((item) => {
              return item.value === value;
            });
            if (filter.length === 0) {
              this.arrayObiettiviSpecicifyBack.push(element);
            }
          }
        }
      });
      this.obiettiviNazionaliArray = [];
      let isf = this.isf;
      let obiettiviNazionali = this.financialTools["obiettiviNazionali"][isf];
      // Tra tutti i possibili valori di Obiettivo Nazionale
      // filtro quelli in base all'ultima scelta di Obiettivo Specifico
      if(this.selectedObiettiviSpecifici != null) {
        let filteredObiettiviNazionali = obiettiviNazionali[this.selectedObiettiviSpecifici]
        filteredObiettiviNazionali.forEach((element) => {
          let option = {
            text: element.codice + "-" + element.titolo,
            value: element.codice + "-" + element.titolo,
          };
          this.obiettiviNazionaliArray.push(option);
        });
      }
      this.visibilityObiettiviNazionali = true;
    },
    removeObiettivoSpecifico(index, item) {
      // BUG 6390 --> La rimozione di un obiettivo Specifico
      // comporta la rimozione di tutti gli obiettivi Nazionali collegati
      let allObNazionali = []
      if(this.arrayObiettiviNazionaliBack.length > 0) {
        allObNazionali = this.arrayObiettiviNazionaliBack
        for (let i = allObNazionali.length - 1; i >= 0; --i) {
          if (allObNazionali[i].ObiettivoSpecifico === item.value) {
              allObNazionali.splice(i,1);
          }
        }
      }
      this.arrayObiettiviNazionaliBack = allObNazionali
      this.arrayObiettiviSpecicifyBack.splice(index, 1);
      this.selectedObiettiviSpecifici = null
    },
    manageObiettiviNazionali(value) {
      this.obiettiviNazionaliArray.forEach((element) => {
        if (element.value === value) {
          if (this.arrayObiettiviNazionaliBack.length === 0) {
            element.ObiettivoSpecifico = this.selectedObiettiviSpecifici
            this.arrayObiettiviNazionaliBack.push(element);
          } else {
            let filter = this.arrayObiettiviNazionaliBack.filter((item) => {
              return item.value === value;
            });
            if (filter.length === 0) {
              element.ObiettivoSpecifico = this.selectedObiettiviSpecifici
              this.arrayObiettiviNazionaliBack.push(element);
            }
          }
        }
      });
      this.visibilityBudget = true;
    },
    removeObiettivoNazionale(index) {
      this.arrayObiettiviNazionaliBack.splice(index, 1);
      this.selectedObiettiviNazionali = null
    },
  },

  mounted(){
    this.$store.dispatch("announcements/getfinancialTools");
    this.descBeneficiario = this.getDescBeneficiario();
  },
  computed: {
     budgetControl(){
       return this.budgetMassimo > 0
     },
     allObSpecificiCoupled() {
      // BUG 6390 --> per ogni Obiettivo Specifico ci deve essere almeno un Obiettivo Nazionale collegato
      let check = false
      this.arrayObiettiviSpecicifyBack.forEach(obSpecifico => {
        let allObNazionali = this.arrayObiettiviNazionaliBack
        if(!allObNazionali.find(obNaz => obNaz.ObiettivoSpecifico === obSpecifico.value)) {
          check = true
        }
      })
      return check
     },
      blockButton() {
        return  this.budgetMassimo > 0 && this.isf !== "" && 
        this.arrayObiettiviNazionaliBack.length > 0 && this.arrayObiettiviSpecicifyBack.length > 0

      },
    ...mapGetters({
      getFinancialTools: "announcements/retrieveFinancialTools",
      getDescBeneficiario: 'announcements/getDescBeneficiario'
    }),
   
  },
};
</script>
