import Vue from "vue";
import endpoints from "@/components/endpoints.vue";
import { JSONPath as jpath } from 'jsonpath-plus';

const state = {
    listaExecutingBody: [],
    tabelloneExecuting: {},
    schedeExecutingBody: {}
};

const getters = {
    getListaExecuting(state) {
        return state.listaExecutingBody;
    },
    getTabelloneExecuting(state) {
        return state.tabelloneExecuting
    },
    getScheda(state) {
        return (payload) => {
            if (payload &&
                payload.idSchedaProgetto &&
                state.schedeExecutingBody[payload.idSchedaProgetto]) {
                return state.schedeExecutingBody[payload.idSchedaProgetto];
            }
            return null;
        }
    },
    getSchedaProgetto(state) {
        return (payload) => {
            if (payload &&
                payload.idSchedaProgetto &&
                state.schedeExecutingBody[payload.idSchedaProgetto]) {
                return state.schedeExecutingBody[payload.idSchedaProgetto].schedaProgetto;
            } else
                console.error('getSchedaProgetto payload error', payload);

            return null;
        }
    }
}

const actions = {
    clearList(context, state) {
        context.commit("RESET_LIST", state)
    },
    claim_un_claim_SchedaEB(context, payload) {
        let partial_endpoint = payload.type == 'claim' ? endpoints.claimProgetto : endpoints.unclaimProgetto
        const url_claim_unclaim = partial_endpoint + payload.idProgetto;
        return Vue.prototype.$post(url_claim_unclaim).then(
            (respComplete) => {
                context.commit("UPDATE_CLAIM_UNCLAIM_IN_LIST_EXECUTING", respComplete);
                return respComplete;
            },
            (error) => {
                throw error;
            }
        );
    },
    pushSchedaInListExecuting(context, payload) {
        context.commit("UPDATE_LISTA_EXECUTING", payload)
    },
    setTabelloneExecuting(context) {
        const urlGetTabellone = endpoints.retrieveTabellone('executingBody');
        return Vue.prototype.$get(urlGetTabellone).then(
            (result) => {
                context.commit("SET_TABELLONE_EXECUTING", result)
                return result;
            },
            (error) => {
                throw error;
            },
        )
    },
    createSchedaExecutingByList(context, financialTool) {
        // Endpoint creazione scheda ExecutingBody da Lista
        let urlNewExecuting = endpoints.newExecutingByList;
        return Vue.prototype.$post(urlNewExecuting, financialTool).then(
            (response) => {
                // Endpoint Processo per ExecutingBody
                let urlAvvioProcesso = endpoints.startExecutingProcess;
                let idScheda = response.idProgetto
                return Vue.prototype.$post(urlAvvioProcesso, { 'idScheda': idScheda })
                    .then(
                        (resp) => {
                            console.log("Processo per ExecutinBody avviato correttamente, ", resp);
                            return response;
                        }
                    )
                    .catch(
                        (error) => {
                            console.error('Start ExecutinBody process error:', error.message);
                            throw error;
                        }
                    )
            },
            (error) => {
                if (error.response && error.response.status)
                    console.error("create ExecutinBody error:", error.response);
                throw error;
            }
        )
    },
    setSchedaExecuting(context, payload) {
        const infoAllegati = {
            allegatiDisponibili: {
                executingBody: 0,
            },
            allegatiMancanti: {
                executingBody: [],
            },
            /*
            executingBody: [
                {type: 'Appunto per il direttore', mandatory:false, recommended:false, task: 'Progetto_appunto_UfficioAcquisti-UfficioAcquisti', group:'MINT'},
                {type: 'Appunto per Autorita Responsabile', mandatory:false, recommended:false, task: 'Progetto_valutazioneDirettore_SegreteriaTecnicaAmministrativa-Direttore', group:'MINT'},
                {type: 'Determina a Contrarre', mandatory:false, recommended:false, task: 'Progetto_valutazioneAR_AutoritaResponsabile-AutoritaResponsabile', group:'MINT'},
                {type: 'Altro', mandatory:false, recommended:false},
                {type:'Appunto', mandatory:false, recommended:false}
            ],*/
            executingBody: payload.tabelloneAllegati.executingBody
        }
        const url = endpoints.retrieveSchedaExecuting(payload.idSchedaProgetto);
        return Vue.prototype.$get(url).then(
            (result) => {
                let financialTool = result.schedaProgetto.progetto.strumentoFinanziario
                const url_importo_impegnato = endpoints.get_importo_impegnato(financialTool)
                return Vue.prototype.$get(url_importo_impegnato).then(
                    (resp_importo) => {
                        const resp_dotazioni = context.rootState.progetto.miscConfig.dotazioneFinanziaria;
                        
                        let dotazioneIsf1 = 0;
                        let dotazioneIsf2 = 0;

                        if(resp_dotazioni && resp_dotazioni.isf1)
                            dotazioneIsf1 = resp_dotazioni.isf1;

                        if(resp_dotazioni && resp_dotazioni.isf2)
                            dotazioneIsf2 = resp_dotazioni.isf2;

                        const dotazioni = {
                            'ISF1': dotazioneIsf1,
                            'ISF2': dotazioneIsf2
                        }
                        result.importo = resp_importo;
                        result.dotazioniFinanziarie = dotazioni;
                        result.infoAllegati = infoAllegati;
                        let scheda = {
                            scheda: result,
                            idSchedaProgetto: payload.idSchedaProgetto
                        }
                        context.commit("SET_SCHEDA", scheda)
                        return result
                    }
                )},
            (error) => {
                console.log(error)
                throw error;
            },
        )
    },
    // modifico SchedaProgetto
    updateSchedaProgettoObject(context, payload) {
        context.commit("SET_SCHEDA_PROGETTO", payload);
    },
    updateScheda(context, payload){
        context.commit("SET_SCHEDA", payload);
    },
    setAvailableFiles(context, payload) {
        context.commit("UPDATE_ALLEGATI_DISPONIBILI", payload);
    },
    setMandatoryMissed(context, payload) {
        context.commit("UPDATE_ALLEGATI_MANCANTI", payload);
    },
    // Save Scheda Progetto
    saveSchedaExecuting(context, payload) {
        // Due casistiche possibili
        // 1)   Salvataggio in Bozza (endpoint)
        let draftSchedaProgetto = endpoints.save_draft_schedaProgetto;
        // 2)   Salvataggio Completo (endpoint)
        let completeSchedaProgetto = endpoints.complete_schedaProgetto;
        const scheda = payload.schedaProgetto.progetto;
        const body = payload.schedaProgetto;
        //console.log("POST to URL "+draftSchedaProgetto+" with BODY:", body);
        // Se salvo in Draft faccio solo la prima POST
        return Vue.prototype.$post(draftSchedaProgetto, body).then(
            (responseDraft) => {
                console.log("Salvataggio in Draft ok");
                // Se voglio salvare completamente faccio un altra chiamata (POST)
                if (payload.salvataggio === "complete" || payload.salvataggio === "reject") {
                    // Setto l'oggetto per la post del salvataggio completo
                    const progetto = scheda.progetto;
                    const parereId = "['executingBody']['parere']";
                    const results = jpath('$'+ parereId, scheda)
                    let parere = false

                    if (results && results.length > 0)
                        if (results[0] === true || results[0] === 'true')
                            parere = true
                    // else
                    //     parere = false

                    let bodyForPost = {
                        idScheda: progetto.idProgetto,
                        processId: scheda.processId,
                        parereEconomicoOk: parere,
                        titoloProgetto: progetto.titoloProgetto,
                        esitoOk: payload.salvataggio === 'complete' ? true : false,
                    };
                    return Vue.prototype.$post(completeSchedaProgetto, bodyForPost).then(
                        (respComplete) => {
                            return respComplete;
                        },
                        (error) => {
                            console.log("save error Salvataggio Completo:", error.response);
                            throw error;
                        }
                    );
                }
                return responseDraft;
            },
            (error) => {
                console.log("save error Salvataggio in Draft:", error.response);
                throw error;
            }
        );
    }
}

const mutations = {
    UPDATE_CLAIM_UNCLAIM_IN_LIST_EXECUTING(state, schedaUpdated) {
        // Aggiorno la schedaProgetto corrente in allProgetti EB
        const indexScheda = state.listaExecutingBody.map(
            schedaProgetto => {
                return schedaProgetto.infoProgetto.idProgetto;
            }).indexOf(schedaUpdated.infoProgetto.idProgetto);
        state.listaExecutingBody.splice(indexScheda, 1, schedaUpdated)
    },
    UPDATE_LISTA_EXECUTING(state, payload) {
        // Elemento corrente
        let schedaAdded = payload.schedaExecuting
        const indexScheda = state.listaExecutingBody.map(schedaProgetto => {
                return schedaProgetto.infoProgetto.idProgetto;
            }).indexOf(schedaAdded.infoProgetto.idProgetto);
        // Aggiungo l'elemento corrente solo se non è presente in lista
        if(indexScheda === -1) {
            state.listaExecutingBody.push(schedaAdded)
        }
    },
    // only set schedaprogetto (not taskinfo)
    SET_SCHEDA_PROGETTO(state, payload) {
        if (payload && payload.idSchedaProgetto && payload.schedaProgetto) {
            if (!state.schedeExecutingBody[payload.idSchedaProgetto])
                state.schedeExecutingBody[payload.idSchedaProgetto] = {};
            state.schedeExecutingBody[payload.idSchedaProgetto].schedaProgetto = payload.schedaProgetto;
        } else
            console.error('SET_SCHEDA_PROGETTO no key or schedaprogetto', payload);
    },
    SET_SCHEDA(state, payload) {
        if (payload && payload.idSchedaProgetto && payload.scheda) {
            state.schedeExecutingBody[payload.idSchedaProgetto] = payload.scheda;
        } else
            console.error('SET_SCHEDA no key or scheda', payload);
    },
    UPDATE_ALLEGATI_DISPONIBILI(state, payload) {
        if( payload.obj.idSchedaProgetto &&
            state.schedeExecutingBody[payload.obj.idSchedaProgetto] &&
            state.schedeExecutingBody[payload.obj.idSchedaProgetto].infoAllegati.allegatiDisponibili[payload.obj.entity] !== undefined)
                state.schedeExecutingBody[payload.obj.idSchedaProgetto].infoAllegati.allegatiDisponibili[payload.obj.entity] =  payload.obj.numeroAllegati;
            else
                console.error("UPDATE_ALLEGATI_DISPONIBILI Error: Missing paylod or scheda")

    },
    UPDATE_ALLEGATI_MANCANTI(state, payload) {
        if( payload.obj.idSchedaProgetto &&
            state.schedeExecutingBody[payload.obj.idSchedaProgetto] &&
            state.schedeExecutingBody[payload.obj.idSchedaProgetto].infoAllegati.allegatiMancanti[payload.obj.entity])
                state.schedeExecutingBody[payload.obj.idSchedaProgetto].infoAllegati.allegatiMancanti[payload.obj.entity] = payload.obj.files;
            else
                console.error("UPDATE_ALLEGATI_MANCANTI Error: Missing paylod or scheda")

    },
    SET_TABELLONE_EXECUTING(state, payload) {
        state.tabelloneExecuting = payload
    },
    RESET_LIST(state) {
        state.listaExecutingBody = [];
    },
    RESET(state) {
        state.listaExecutingBody = [];
        state.tabelloneExecuting = {};
        state.schedeExecutingBody = {};
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};