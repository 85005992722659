<template>
     <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
               <div class="modal-container">
                   <div class="modal-header">
                       <slot name="header"/>
                           <h3>Scelta Modalità Di Selezione</h3>
                   </div>
                    <b-container>
                       <b-row>
                            <b-col><h4>Modalità Selezione</h4></b-col>
                            <b-col>
                                <b-form-select size="lg"
                                id="selectModalita"
                                v-model="selected" 
                                :options="typesBandoForCreate" 
                                required
                                :disabled="blockButton"/>
                            </b-col>
                        </b-row>
                    </b-container>
                    <div class="modal-footer">
                        <slot name="footer">
                        <b-button 
                          @click="createNewBando()" 
                          variant="success" 
                          class="modal-default-button"
                          :disabled="blockButton"
                          >
                         Salva
                        </b-button>
                        <b-button variant="danger" 
                        class="modal-default-button" 
                        @click="closeModal"
                        :disabled="blockButton"
                        >
                        Chiudi
                        </b-button>
                        </slot>
                    </div>   
               </div>  
            </div>
          </div>           
     </transition>
</template>

<script>
import endpoints from "./endpoints"
import notify from "@/helpers/notifications.js"

export default {
    name:"selectProposal",
    data(){
        return{
            selected : null,
            blockButton: false,
            typesBandoForCreate: [
                    { value: 'CALL_FOR_PROPOSAL', text: 'Call For Proposal' },
                    { value: 'ASSEGNAZIONE_DIRETTA', text: 'Assegnazione Diretta' }
                ],
            show : false,
        }
    },
    mounted() {
        this.selected = this.typesBandoForCreate[0].value
    },
    methods: {
        closeModal() {
            this.$emit('closeModalCreateBando')
        },
        createNewBando() {
            this.blockButton = true;
            let tipoBando = this.selected;
            let urlNewBando = endpoints.newBando(tipoBando);
            let urlNewProcess = endpoints.startBandoProcess('BandoAwardingBody');
            this.$get(urlNewBando).then(bando => {
                console.log("creato bando con id=", bando.idBando);
                let stringIdSchedaBando = bando.idBando;
                return this.$post(urlNewProcess, { idScheda : stringIdSchedaBando } ).then(() => {
                    this.blockButton = false;
                    this.$router.push({ name: "schedaBando", params: { idBando : stringIdSchedaBando} });
                })
                .catch(
                        (error) => {
                        this.blockButton = false;
                        this.closeModal();
                        console.error('selectTypeBando error:', error.message);
                        notify.error(notify.strings.error, notify.strings.errorCreateNewBando)
                    }
                )
            })
        }
    }
}
</script>
