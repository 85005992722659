<template>
  <div>
    <GenericList
      :id="'listaTrancheProgetto'" 
      :title="'Lista Richiesta Tranche per il Progetto '+this.codiceProgetto"
      :busyTable="isBusy"
      :createEntity="this.canCreate()"
      :headerTable="headerTable"
      :dataTable="tableData"
      :actionsInRow="this.$getActionsListaTranche"
      :actionsMapping="this.buttonLabels"
      :blockButton="isBusy || blockButton"
      @clickActionList="doAction"
    >
    </GenericList>

    <b-modal
      id="modalFormNuovaCheckList"
      hide-footer
      size="xl"
      hide-header-close
      no-close-on-backdrop
      scrollable
      centered
      dialog-class="modal1-content"
      content-class="modal1-content">
      <template  v-slot:modal-title>
          <div v-if="popup">
              <h3>Crea Nuova Richiesta Tranche</h3>
          </div>
      </template>
      <selezioneMotivazioneTranche v-if="popup" 
        :richiesteTranche="tableData"
        @closeModalCheckList="closeModal"/>
    </b-modal>


  </div>
</template>
<script>

import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue"
import GenericList from "@/components/genericList.vue"
import selezioneMotivazioneTranche from "@/views/tranche/selezioneMotivazioneTranche.vue"

export default {
  name: "listaTrancheProgetto",
  components: {
    GenericList,
    selezioneMotivazioneTranche
  },
  data() {
    return {
      headerTable: [
        {
          key: "idScheda",
          label: "Id Richiesta"
        },
        {
          key: "numeroTranche",
          label: "Fase"
        },
        {
          key: "titoloControllo",
          label: "Descrizione"
        },
        {
          key: "dataInserimento",
          formatter: "dateFormatter",
          label: "Data Inserimento",
        },
        {
          key: "tranche.percentualeTrasferimento",
          label: "Percentuale Costo Progetto"
        },
        {
          key: "tranche.importo",
          formatter: "currencyFormatter",
          label: "Importo Richiesto"
        },
        {
          key: "deroga",
          formatter: "booleanFormatter",
          label: "Deroga"
        },
        {
          key: "stato",
          label: "Stato"
        },
        {
          key: "Azioni"
        }
      ],
      buttonLabels: {
        start_pref: "Avvia processo di Richiesta Tranche",
        view: "Visualizza Tranche",
        claim: "Prendi in Carico",
        unclaim: "Rilascia presa in Carico"
      },
      blockButton: false,
      isBusy: false,
      tranches: [],
      tableData: [],
      popup: false,
      role: "",
      codiceProgetto: "",
      isTranchePending: false,


    }
  },

  mounted() {
    this.codiceProgetto = this.$route.params.codiceProgetto;
    console.log("carico le richieste di Tranche per il Progetto ", this.codiceProgetto);
    this.role = this.$getUserRole(); 
    console.log("ruolo=", this.role);    
    this.caricaRichiesteTrancheProgetto();
  },
  
  computed: {

  },

  methods: {
    canCreate(){
        if (this.role.includes("Beneficiario-Operatore"))
            return "Nuova Richiesta Tranche";      //label del pulsante
        else
            return "";                              //label vuota significa non posso nemmeno vedere il pulsante (vedi implementazione componente genericList)
    },

    doAction(action, data) {
      if (!data) {
        console.log("scheda tranche vuota", data)
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        )
        return
      }
      let id = null;
       
      switch (action) {
        case "edit":
        case "view":    //visualizzazione della scheda tranche
          if (data && data.item && data.item.idScheda)
            id = data.item.idScheda;
          console.log("idScheda=", id);
     
          this.$router.push({ name: "schedaTranche" , 
          params: { 
                    codiceProgetto: this.$route.params.codiceProgetto,
                    idScheda: id
                  }
          });
          break
        case "claim":
            if(data && data.item && data.item.idScheda){
              let url = endpoints.claimPrefinanziamento + data.item.idScheda
              this.claimUnclaim(url)
            }
            else
              console.error("Scheda Not Found!", action)      
          break
        case "unclaim":
            if(data && data.item && data.item.idScheda){
              let url = endpoints.unclaimPrefinanziamento + data.item.idScheda
              this.claimUnclaim(url)
            }
            else
              console.error("Scheda Not Found!", action)          
          break
        case "createEntity":    //ogni volta che viene cliccato il pulsante + in alto a destra
          this.openModalTranche();
          break;
        
        default:
          console.error("Unknown action!", action)
          break
      }
    },

    openModalTranche() {
      this.popup = true;
      this.$bvModal.show('modalFormNuovaCheckList');
    },

    closeModal(){
      this.popup = false;
      this.$bvModal.hide('modalFormNuovaCheckList');
    },

    caricaRichiesteTrancheProgetto: async function() {
      this.isBusy = true
      const url = endpoints.trancheProgetto(this.codiceProgetto);
      this.$get(url)
      .then((result) => {
        this.tranches = result;
        console.log("Trovati "+result.length+" schede richiesta tranches");
        this.extractData(result);
        this.isBusy = false
      },
      (error) => {
        this.isBusy = false
          notify.error(
            notify.strings.error,
            notify.strings.internalErrorPleaseReportAction
          )
        console.error("errore caricamento lista progetti", error)
        throw error
      })
    }, 

    extractData(result) {
      this.tableData = [];
      for (const item of result) {
        if (item.tranche && item.tranche.index+1 < item.tranche.numeroTrancheProgetto){
          item.numeroTranche = "Tranche "+(item.tranche.index+1); //il numero visualizzato è l'indice dell'array memorizzato incrementato
        } else {
          item.numeroTranche = "Saldo"; //il primo elemento restituito dalla query è sempre il più recente, quindi l'ultima tranche inserita corrisponde al saldo
        }
        this.tableData.push(item);
      }
      
      console.log("Trovate "+this.tranches.length+" schede richiesta tranches per il progetto "+this.codiceProgetto)
    },

    claimUnclaim(url) {
      this.$post(url)
      .then(() => {
            this.caricaRichiesteTrancheProgetto()
      },
      (error) => {
          console.error("ERRORE: ", error.message)
          if (url.includes('unclaim'))
              notify.error(notify.strings.error, notify.strings.errorUnclaimScheda)
          else
              notify.error(notify.strings.error, notify.strings.errorClaimScheda)
          throw error
      })          
    },
   
  },
}
</script>
