<template>
  <div>
      <b-alert :show="getDeletedFiles.length > 0" variant="warning">
        <div style="text-align: center;">
          <p><strong>ATTENZIONE! Questi sono i files che hai cancellato dalla scheda</strong>
                    (Qualsiasi riferimento di questi è stato rimosso pure dalla Checklist di tua competenza)</p>
          <ul>
            <li style="list-style: square;" v-for="item in getDeletedFiles" :key="item.fileId">
              {{ item.text }}
            </li>
          </ul> 
        </div>   
      </b-alert>
      <Collapse :icon_parere="true" :iconInfo="handleColor(getDataRows)" :name="title">
        <b-table-lite :id="idTablePdf" thead-class="head" :items="this.tabledata.rows" :fields="innerHeader">
          <template v-slot:cell(descrizione)="data">
            <span v-html="data.item.descrizione"></span>
          </template>
          <template v-slot:cell(docsRiferimento)="data">
            <ul v-for="(id, index) in data.item.docsRiferimento" :key="index">
              <li style="list-style: circle;" v-if="config && config.content">{{extractLabel(id)}}<br /></li>
            </ul>
          </template>
          <template v-slot:cell(filesRiferimento)="data">
            <ol v-for="(doc, index) in data.item.filesRiferimento" :key="index">
              <li style="list-style: square;">{{doc.text}}</li>
            </ol>
          </template>
          <template v-slot:cell(azione)="data">
              <b-button :id="namingIdButton(x, data.index + 1)" variant="outline-primary" :key="x" v-for="x in data.value" size="sm" @click="doAction(x, data.item.id)">
                  <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
              </b-button>
          </template>
      </b-table-lite>
    </Collapse>
    <b-modal :id="'modalCheck-' + this.name" hide-footer
             size="xl"
             scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content">
      <template v-slot:modal-title>
        <h3>Modifica valore</h3>
      </template>
      <modalCheck
        :fdata="this.editFormData"
        :fdata_viewMode="null"
        :types="config.content.types"
        :attachmentsType="config.content.tipologiaAllegati"
        :buttons="this.buttons"
        :parentName="this.name"
        @editField="editRiga"
        />
    </b-modal>
  </div>
</template>

<script>
import { JSONPath as jpath } from 'jsonpath-plus';
import tools from '@/helpers/tools.js'
import helper from '@/helpers/controlli.js'
import Collapse from "@/components/collapse.vue";
import notify from "@/helpers/notifications.js"
import mapUtils from '@/helpers/mapUtils.js'
import modalCheck from "./modalCheckListControlli.vue"

export default {
    name: "CheckAutoControllo",
    components: {
      modalCheck,
      Collapse
    },
    props: {
      name: {
        type: String,
        default: () => 'AutoControlloCheckList'
      },
      idTablePdf: {
        type: String,
        default: () => ''
      },
      title: {
        type: String,
        default: () => 'CheckList'
      },
      headerTable: {
        type: Array,
        default: () => []
      },
      config: {
        type: Object,
        default: () => {}
      },
      pointingTab: {
        type: String,
        default: () => ''
      },
      actions: {
        type: Array,
        default: () => []
      },
      tabName: {
        type: String
      },
      // MODULO DI STORE DI RIFERIMENTO 
      store: { type: String, required: true },
       // PROPS PER IL RECUPERO/SCRITTURA DINAMICO DELLA SCHEDA
      fnGetter: { type: String, required: true },
      fnSetter: { type: String, required: true },
      idEntity: {type: Object, required: true },
      // PUNTO DELLA SCHEDA DOVE SI FARANNO CRUD OPERATIONs
      dataObject: { type: String, required: true }
    },
    data() {
      return {
        innerHeader: [],
        deletedFiles: [],
        tabledata: {
          rows: [],
        },
        mappa: {},
        editFormData: {conf:{}, content: {}},
        buttons: [
          {
            name: "Modifica",
            action: "editField",
            show: true,
            param: "edit",
          },
        ],
        //name: 'AutoControlloCheckList',
      }
    },
    watch: {
      tabName: function() {
        if(this.tabName === this.dataObject) {
          this.deletedFiles = []
          this.extractData()
        }
      }
    },
    mounted() {
      if (!this.config || !this.config.content ) {
        console.error("NO config valid!", this.config);
        return
      }

      // Visualizzazione in tooltip delle note
      // Hover sull'esito
      this.innerHeader = tools.genericFunctions.cloneObject(this.headerTable);

      if(this.innerHeader && this.innerHeader.length > 1)
        this.innerHeader[1].tdAttr = this.tooltip

      //prop pointingTab
      //La prop è stata aggiunta per permettere di posizionarsi in un punto preciso del tabellone
      // visto che in questo caso questo tabellone include sia il Tab della CheckList del collapse Documentale / Fisico
      this.mappa = this.pointingTab!== '' ? this.config.content[this.pointingTab].mappa : this.config.content.mappa
      this.extractData()
    },
    computed: {
      getDataRows() {
        return this.tabledata.rows
      },
      getDeletedFiles() {
        // SE ho più occorrenze farò in modo di visualizzarne sempre una
        let unique = this.deletedFiles.reduce((unique1, o) => {
          if(!unique1.some(obj => obj.fileId === o.fileId))
            unique1.push(o)
          return unique1
        },[])
        return unique
      },
    },
    methods: {
      extractLabel(id) {
        if (this.config 
          && this.config.content 
          && this.config.content.tipologiaAllegati
          && this.config.content.tipologiaAllegati[id]
          && this.config.content.tipologiaAllegati[id].label){
            return this.config.content.tipologiaAllegati[id].label;
        } else {
          return "no label";
        }
      },
      // Costruzione id per Buttoni (Azioni nel componente)
      namingIdButton(action, index) {
        return this.$builtIdDinamically(this.name, action, index)
      },
      handleColor(data) {
        return helper.handleEsitoIcon(data)
      },
      tooltip(value, key, item) {
        if(item.note)
          return {title: 'nota: ' + item.note};
        return {};
      },
      getSchedaAutoControllo() {
        let scheda = this.$store.getters[`${this.store}/${this.fnGetter}`](this.idEntity)
        return tools.genericFunctions.cloneObject(scheda)
      },
      doAction(action, id) {
        this.editFormData = { content: {}, conf: {} };
        // get fresh data from storage
        const scheda = this.getSchedaAutoControllo()
        let content = this.getContent(scheda)
        let mapElement = tools.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, id));
        
        const dataEntry = scheda.content[this.dataObject].dataEntryConfiguration;

        if (dataEntry && dataEntry[id]) {
          mapElement.data = tools.genericFunctions.cloneObject(
            dataEntry[id].data
          );
        } else {
          mapElement.data = this.$getDefaultData();
        }

        let val = { id: id, value: { esito: null, chooseFiles: [], controDeduzione: null }};
        const values = jpath("$" + id, content[this.dataObject]);

        let valuesControl = jpath("$" + id, content.controllo)
        if (!values || values.length === 0) {
          console.log("valore non trovato in scheda: ", id);
        } else if(values.length === 1) {
          val.value = {
            esito: values[0].esito ? values[0].esito : null,
            chooseFiles: values[0].chooseFiles && values[0].chooseFiles.length > 0 ? values[0].chooseFiles : [],
            controDeduzione: values[0].controDeduzione ? values[0].controDeduzione : null,
            deduzioneOn: valuesControl && valuesControl.length === 1 && valuesControl[0].commenti && valuesControl[0].commenti !== '' && valuesControl[0].esito === 'Negativo'
          }
        }
        // Recupero i files che sono in scheda (dentro content)
        let filesAllegati = helper.populateListFilesForModal(content)
        val.genericFiles = filesAllegati
        
        this.editFormData.conf[id] = mapElement;
        this.editFormData.content[id] = tools.genericFunctions.cloneObject(val);
        this.$bvModal.show("modalCheck-" + this.name);
      },
      getContent(scheda) {
        return scheda.content
      },
      setContent(scheda, content) {
        scheda.content = content
        return scheda
      },
      extractData() {
        if (!this.mappa) {
          console.error("NO mappa!", this.mappa);
          return;
        }
        // Recupera la scheda facendo un clone della stessa
        let scheda = this.getSchedaAutoControllo()
        let content = this.getContent(scheda)

        if(content[this.dataObject] == null) {
          content[this.dataObject] = {}
          scheda = this.setContent(scheda, content)
          this.updateSchedaInStore(scheda)
        }
        let autoControllo = tools.keyFinder(content, [this.dataObject])
        this.tabledata.rows = []
        let updateScheda = false;
        let allIds = Object.keys(this.mappa)
        for (const rowId of allIds) {
          let mapElement = this.mappa[rowId];
          if (!mapElement) {
              console.error("no config for ", rowId);
              notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
              return;
          }
          let result
          let note
          
          const values = jpath({resultType: 'all'}, '$' + rowId, autoControllo);
          
          if (values && values.length === 0) {
            result = mapElement.config.defaultValue;
            const retVal = mapUtils.createElementFromScratch(
              rowId,
              autoControllo,
              this.mappa
            );
    
            autoControllo = retVal.scheda;
            retVal.parent[retVal.fieldName] = result;
            updateScheda = true;
          } else if (values && values.length === 1) {
            result = values[0].value;
          }
          else {
            console.error(this.name, 'è un array?', rowId, values);
          }
          // POPOLAMENTO DATAENTRYCONFIGURATION
          if(!scheda.content[this.dataObject].dataEntryConfiguration)
            scheda.content[this.dataObject].dataEntryConfiguration = {};
          const conf = scheda.content[this.dataObject].dataEntryConfiguration;
          if (note) {
            // Creo la configurazione per le note
            conf[rowId] = {
              data: {
                note: note
              }
            }
            updateScheda = true;
          }

          let row = {
            descrizione: mapElement.config.descrizione, // Commentata la limitCharactersDesc per BUG #10838
            docsRiferimento: mapElement.config.docsRiferimento,
            esito: result && result.esito ? result.esito : null,
            filesRiferimento: result && result.chooseFiles && result.chooseFiles.length > 0 ? result.chooseFiles : [],
            id: rowId,
            note: conf[rowId] && conf[rowId].data && conf[rowId].data.note ? conf[rowId].data.note : null
          }

          // Check se i filesRiferimento sono effettivamente presenti in scheda o sono stati cancellati
          if(content.allegati && values && values.length === 1) {
            let { allFilesRiferimento, deletedFiles } = helper.updateSituationFiles(values[0], Object.keys(content.allegati), this.deletedFiles)
            row.filesRiferimento = allFilesRiferimento 
            this.deletedFiles = deletedFiles
          }
          
          // Qui avviene la gestione delle azioni
          row.azione = this.actions.length > 0 && this.actions.indexOf('edit') > -1 ? this.actions.filter(action => {return action !== 'viewDichiarazione'}) : []
          this.tabledata.rows.push(row)
        }
        if(updateScheda) {
          content[this.dataObject] = autoControllo
          scheda = this.setContent(scheda, content)
          this.updateSchedaInStore(scheda)
        }
      },
      editRiga(dataFromModal) {
        this.$bvModal.hide("modalCheck-" + this.name);
        let scheda = this.getSchedaAutoControllo()
        let content = this.getContent(scheda)

        let result = mapUtils.updateComponent(dataFromModal, scheda.content[this.dataObject], null, this.mappa, null, this.title);
        
        for(const [key, item] of Object.entries(result.clonedScheda.dataEntryConfiguration)) {
          const itemToAdd = tools.genericFunctions.cloneObject(item);
          result.clonedScheda.dataEntryConfiguration[key] = itemToAdd;
        }
        content[this.dataObject] = result.clonedScheda
        scheda = this.setContent(scheda, content)
        this.updateSchedaInStore(scheda)
        this.extractData()
      },
      updateSchedaInStore(scheda) {
        let payloadSetter = Object.assign(this.idEntity, { content: scheda })
        this.$store.dispatch(`${this.store}/${this.fnSetter}`, payloadSetter)
      }
    }
}

</script>