<template>
  <div>
    <b-table-lite :id="idTablePdf" thead-class="head" :items="tabledata.rows" :fields="tabledata.header">
      <template v-slot:cell(Azione)="data">
          <b-button variant="outline-primary" :key="x" v-for="x in data.value" size="sm" v-on:click="doAction(x, data.index)">
              <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
          </b-button>
      </template>
    </b-table-lite>

    <modalForm v-if="showEditForm"
               :fdata="this.editFormData"
               :sch="this.scheda" 
               :hidden="!showEditForm"
               :buttons="this.buttons"
               :cfg="incomingData.schedaAssessment.dataEntryConfiguration"
               @close="showEditForm = false"
               @editField="editRiga">
      <h3 slot="header">Modifica campo</h3>
    </modalForm>
  </div>
</template>

<script>
import modalForm from "@/components/modalForm.vue";
import tool from '@/helpers/tools.js'
import utils from '@/helpers/utils.js'
import notify from "@/helpers/notifications.js"
import { JSONPath as jpath } from 'jsonpath-plus';
import validation from '@/helpers/validations.js';
import mapUtils from '@/helpers/mapUtils.js'

export default {
  name: "anagrafica",
  components: {
    modalForm,
  },
  props: {
    idTablePdf: String,
    incomingData :{
      type: Object,
    },
    salvaSchedaToStore: Function,
    tabName: String,
    final: Boolean,
  },
  watch: {
    tabName: function() {
      if(this.$props.tabName === "anagrafica") {
        this.extractData();
      }
    }
  },
  mounted() {
    this.extractData();
  },
  data() {
    return {
      editFormData: {},
      editLine: 0,
      showEditForm: false,
      buttons: [
        {
          name: 'Modifica',
          action: 'editField',
          show: true,
          param: 'edit'
        },
      ],
      tabledata: {
        header: [
          {
            key: "Campo",
          },
          {
            key: "Descrizione",
            tdAttr: this.tooltip
          },
          /*
          {
            key: "Azione"
          },
          */
        ],
        "rows": [
          {
              id: "['beneficiario']['denominazione']",
              Campo: "",
              Descrizione: "",
          },
          {
              id: "['codiceProgetto']",
              Campo: "",
              Descrizione: "",
          },
          {
              id: "['titoloProgetto']",
              Campo: "",
              Descrizione: "",
          },
          {
              id: "['annoFinanziario']",
              Campo: "",
              Descrizione: "",
          },
          {
              id: "['obiettivoNazionale'][*]['obiettivoSpecifico']['codice']",
              Campo: "",
              Descrizione:"",
          },
          {
              id: "['obiettivoNazionale'][*]['codiceComposito']",
              Campo: "",
              Descrizione: "",
          },
          {
              id: "['budgetIniziale']",
              Campo: "",
              Descrizione:"",
          },
          {
              id: "['budgetRimodulato'][*]['budget']",
              Campo: "",
              Descrizione:"",
          },
          {
              id: "['economieProgetto']",
              Campo: "",
              Descrizione:"",
              Azione:['edit']
          },
          {
              id: "['dataConclusione']",
              Campo: "",
              Descrizione:"",
              Azione:['edit']
          },
          {
              id: "['dataModifica']",
              Campo: "",
              Descrizione:""
          },
        ]
      },
      scheda : {},
    };
  },
  
  methods: {

    tooltip(value, key, item) {
      if(item.note)
        return {title: 'nota: ' + item.note};
      return {};
    },

    extractData() {
      if(!this.incomingData) {
        console.log('anagraficaProgetto: Invalid inputData!?');
        return;
      }

      let scheda = this.incomingData.schedaAssessment;
      let mappa = scheda.dataEntryConfiguration;
      
      // questo controllo elimina dalla tabella la data di conclusione del
      // progetto se non siamo nel final assessment
      if(!this.final) {
        const rows = this.tabledata.rows;
        const length = rows.length;
        
        let index = -1;
        for(let i = 0; i < length; i++) {
          if(rows[i].id === "['dataConclusione']") {
            index = i;
            break;
          }
        }
        if(index !== -1) {
          rows.splice(index, 1);
        }
      }

        this.tabledata.rows.forEach(row => {
          row.Descrizione = '';
          row._cellVariants = {}; 
          delete row.note;
          this.tabledata.header[1].tdAttr = this.tooltip;
          if(!row.id) {
            console.error('id non presente', row);
            return;
          }
          let id = row.id;
          let mapElement = mapUtils.getMapElement(mappa, id);

          if(!mapElement) {
            console.error('elemento non trovato in mappa: ', id);
            return;
          }

          let canEdit = utils.caniuse('edit', this.incomingData.taskList, this.incomingData.schedaAssessment);
          if(canEdit && !mapElement.config.readonly) {
            row.Azione = ['edit']
          }
          else {
            row.Azione = [];
          }


          let results = jpath({resultType: 'all'},'$'+row.id, scheda);
          let valueToPrint;
          if(results.length === 0) {
            if(!row.id.includes('imodulato'))
              console.log('valore non trovato in scheda: ', row.id);
            return;
          } else if(results.length > 1) {
            valueToPrint = results.map(item => item.value);
          } else {
            valueToPrint = results[0].value;
          }

          if(id.includes('imodulato')) {
            const result =  results[results.length - 1];
            valueToPrint = result.value;
            id = result.path.replace('$',''); // WARNING: ultimo valore del budget rimodulato
            mapElement = mapUtils.getMapElement(mappa, id);
          }
          row.Campo = mapElement.config.label;

          let formattedVal = utils.formatOutput(mapElement, valueToPrint);
          row._cellVariants = {};
          if(mapElement.data.edited) {
            let nomeColonna = this.tabledata.header[1].key;
            row._cellVariants[nomeColonna] = mapElement.data.isSubstancial ? 'danger': 'warning'; 
            // row.note = mapElement.data.note;
          }
          // TASK #13750: nota sempre persistita
          row.note = mapElement.data.note;
          row.Descrizione = formattedVal;
          
        });

      // WARNING: azioni visibili solo se viene completato correttamente il caricamento della scheda
      this.tabledata.header = [
          {
            key: "Campo",
          },
          {
            key: "Descrizione",
            tdAttr: this.tooltip
          },
          {
            key: "Azione"
          },
        ];
    },

    editRiga(actionFromModal) {
      this.showEditForm = false;
        let clonedScheda = tool.genericFunctions.cloneObject(this.incomingData.schedaAssessment);
        // update data
        for(let [key, field] of Object.entries(actionFromModal.content)) {
          let updatedValue = field.value;
          if(updatedValue == undefined) {
            // nessuna azione se il nuovo valore è undefined
            continue;
          }
          let updatedConfiguration = actionFromModal.conf[key];
          updatedValue = utils.unformatOutput(updatedConfiguration, updatedValue);
          let jPathResult = jpath({resultType: 'all'}, '$'+key, clonedScheda)[0];
          let parent = jPathResult? jPathResult.parent : undefined;
          let parentArray = parent; // nel caso del budget rimodulato evito un'altra chiamata a jpath;
          let arrayName = jPathResult? jPathResult.parentProperty : undefined;
          if(parent === undefined) { // bisogna creare l'oggetto da modificare
            let temp = key.replace(/(\[(\d+)\])(?!.*\[(\d+)\])/g, "[*]"); // trasforma in * l'ultimo indice di array
            let last = temp.lastIndexOf("[*]");
            let parentKey = temp.substring(0,last); // id dell'oggetto padre

            let elements = jpath({resultType: 'all'}, '$'+parentKey, clonedScheda);
            if(elements.length === 0) {
              console.error('elemento non trovato', key, parentKey);
              notify.error(notify.strings.error, notify.strings.operationError('Modifica di ' + updatedConfiguration.config.label));
              return;
            }
            parentArray = elements[0].parent;
            arrayName = elements[0].parentProperty;
            if(elements[0].value === null) { // l'array di elementi (budget rimodulato) è vuoto.
              let nextEles = parentKey.match(/(\w*)(?='\])/g).filter((val) => {return val !== ''});
              let newValue = []; // creo l'array vuoto
              let ele = mapUtils.recursiveElement(newValue, nextEles); // creo ricorsivamente gli oggetti annidati
              parentArray[arrayName] = ele[arrayName]; // aggiungo l'oggetto contenitore
            }
            
            temp = temp.substring(last);
            let nextElements = temp.match(/(\w*)(?='\])/g).filter((val) => {return val !== ''});
            let fieldName = nextElements[0];
            let newBudgetRimodulato = {};
            newBudgetRimodulato[fieldName] = null;
            parentArray[arrayName].push(newBudgetRimodulato); // nell'oggetto contenitore inserisco l'elemento dell'array
            parent = jpath({resultType: 'parent'}, '$'+key, clonedScheda)[0];
          }
          let fieldToUpdate = jpath('$'+key+'~', clonedScheda)[0];

          if(key.includes('imodulato')) { // controllo il valore del budget rimodulato
            let keys = key.match(/\['\w*'\]/g).filter((val) => {return val !== ''});
            if(keys.length === 0) {
                console.error('chiave di budget rimodulato non trovata');
                notify.error(notify.strings.error, notify.strings.operationError('Operazione su ' + updatedConfiguration.config.label));
                continue;
            }
            let budgetRimodulatoKey = keys[0];
            let budgetRimodulati = jpath('$'+budgetRimodulatoKey, clonedScheda)[0];
            if(budgetRimodulati.length <= 1) {
            // if(parent.length === 1) {
              // console.log('primo elemnto');
              // è il primo elemento, devo confrontare con il budget iniziale
              let budgetIniziale = jpath("$['budgetIniziale']", this.incomingData.schedaAssessment)[0];
              if(updatedValue != budgetIniziale) { // setto il nuovo valore
                parent[fieldToUpdate] = updatedValue;
                updatedConfiguration.data.oldValue = budgetIniziale;
                clonedScheda.dataEntryConfiguration[key] = updatedConfiguration; // configurazione prima del controllo substancial
                updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedScheda);
              }
              else {
                console.log('budget non modificato', budgetRimodulati, updatedConfiguration);
                // se la rimodulazione era stata aggiunta, rimuovo l'elemento dell'array e la configurazione
                if(updatedConfiguration.data.added) {
                  delete clonedScheda.dataEntryConfiguration[key];
                  budgetRimodulati.pop();
                }
                continue;
              }
            }
            else { // prendo l'ultimo budget rimodulato
              let valueToCompare = budgetRimodulati[budgetRimodulati.length -2].budget;
              if(updatedValue != valueToCompare) {
                parent[fieldToUpdate] = updatedValue;
                updatedConfiguration.data.oldValue = valueToCompare;
                clonedScheda.dataEntryConfiguration[key] = updatedConfiguration; // configurazione prima del controllo substancial
                updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedScheda);
              }
              else {
                console.log('budget senza modifiche');
                // ripristino l'array
                delete clonedScheda.dataEntryConfiguration[key];
                budgetRimodulati.pop();
                // continue;
              }
            }
          }
          else {
            if(parent[fieldToUpdate] !== updatedValue){ // se il campo è stato modificato controllo la configurazione
              if(!updatedConfiguration.data.edited) {
                updatedConfiguration.data.edited = true; // se è la prima modifica setto il flag edited e salvo il valore originale
                updatedConfiguration.data.oldValue = parent[fieldToUpdate];
              } else {
                if(updatedValue == updatedConfiguration.data.oldValue) { // se il valore era già stato modificato
                  updatedConfiguration.data.oldValue = null;              // e stiamo riportando il valore all'originale
                  updatedConfiguration.data.edited = false;          // ripristino i valori all'originale e cancello la nota
                  // TASK #13750: le note non vengono mai resettate
                  // updatedConfiguration.data.note = '';                // warning: solo == e non === altrimenti problemi di typeof
                }
              }
              parent[fieldToUpdate] = updatedValue; // setto il nuovo valore
              if(updatedConfiguration.config.canBeSubstancial) {
                clonedScheda.dataEntryConfiguration[key] = updatedConfiguration; // configurazione prima del controllo substancial
                updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedScheda);
              }

              clonedScheda.dataEntryConfiguration[key] = updatedConfiguration; // configurazione aggiornata
            }
            /*
            else {
              // aggiorno comunque la nota se il campo è modificato o aggiunto
              if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
                clonedScheda.dataEntryConfiguration[key].data.note = updatedConfiguration.data.note;
            }*/
            // TASK #13750: le note vengono sempre persistite
            clonedScheda.dataEntryConfiguration[key].data.note = updatedConfiguration.data.note;
          }
        }
        this.salvaSchedaToStore(clonedScheda);
        this.extractData();
    },

    doAction(actionToDo, index)
    {
        this.scheda = this.incomingData.schedaAssessment;

        let value;
        let mapElement;
        let mappa;
        let newId;
        switch(actionToDo) {
            case 'edit': {
                mappa = this.incomingData.schedaAssessment.dataEntryConfiguration;
                this.editFormData = {content:{}, conf: {}};
                let id = this.tabledata.rows[index].id;
                newId = id;
                let values = jpath('$'+id, this.incomingData.schedaAssessment);
                let ele = mapUtils.getMapElement(mappa, id);
                mapElement = tool.genericFunctions.cloneObject(ele);
                
                if(values.length === 0) {
                  console.error('valore non trovato in scheda: ', id);
                  // let value = mapUtils.cloneValue(newId, mapElement);
                  // break;
                }
                else
                  value = {'value': values[0], 'id': id};
                
                // caso del budget rimodulato
                if(id.includes('budgetRimodulato')) {
                  let budgets = jpath({resultType:'all'}, '$'+id, this.incomingData.schedaAssessment);
                  if(budgets.length === 0) {
                    // non ci sono budget rimodulati, verrà creato un nuovo elemento dell'array a partire dal budget iniziale
                    newId = id.replace("*", 0);
                    mapElement.config.path = newId;
                    mapElement.data.added = true;
                    mapElement.data.edited = true;
                    let temp =  jpath("$['budgetIniziale']", this.incomingData.schedaAssessment);
                    if(temp.length === 0) {
                      console.error('budget iniziale non presente');
                      value = {'value': 0, 'id': newId};
                    }
                    else {
                      let newBudget = temp[0];
                      value = {'value': newBudget, 'id': newId};
                    }
                  }
                  else {
                    let len = budgets.length;
                    let budget = budgets[len - 1];
                    newId = budget.path.replace('$', '');
                    let tempConf = mappa[newId];
                    // console.log('ultimo',tempConf, newId);
                    // Se non è modificato aggiungo una riga, altrimenti prendo la riga modificata
                    if(!tempConf) {
                      newId = id.replace("*", len);
                      mapElement.config.path = newId;
                      mapElement.data.edited = true;
                    }
                    else { // prendo la riga modificata
                      // console.log('tempconf',tempConf);
                      mapElement = tool.genericFunctions.cloneObject(tempConf);
                    }
                    let budgetValue = budget.value;
                    if(budgetValue === null) {
                      // prendo come riferimento il budget iniziale oppure zero
                      let temp =  jpath("$['budgetIniziale']", this.incomingData.schedaAssessment);
                      if(temp.length === 0) {
                        console.error('budget iniziale non presente');
                        value = {'value': 0, 'id': newId};
                      }
                      else {
                        let newBudget = temp[0];
                        value = {'value': newBudget, 'id': newId};
                      }
                    }
                    else{
                      value = {'value': budgetValue, 'id': newId};
                    }
                  }
                }
                
                this.editFormData.conf[newId] = mapElement;
                this.editFormData.content[newId] = tool.genericFunctions.cloneObject(value);
              

              this.editLine = index;
              this.showEditForm = true;
            }
              break;
            default:
              this.editFormData = {};
              break;
        }
    },

    updateValue(key, value) {
      let rows = this.tabledata.rows;
      for (let i in rows) {
        if (rows[i].Campo === key) {
          rows[i].Descrizione = value;
          return;
        }
      }
    },
  }
};
</script>
