<template>
  <div>
    <InfoCompiling
      :tabName="infoTabName"
      :title="'Informazioni di compilazione'"
    >
    </InfoCompiling>
    <b-table-lite :id="idTablePdf" thead-class="head" :items="tabledata.rows" :fields="tabledata.header">
    <template v-slot:cell(Azione)="data">
        <b-button variant="outline-primary" :key="x" v-for="x in data.value" size="sm" v-on:click="doAction(x, data.index)">
            <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
        </b-button>
    </template>
    </b-table-lite>

    <modalForm v-if="showEditForm"
               :fdata="this.editFormData"
               :sch="this.scheda" 
               :hidden="!showEditForm"
               :buttons="this.buttons"
               :cfg="incomingData.schedaMonitoraggio.dataEntryConfiguration"
               @close="showEditForm = false"
               @editField="editRiga">
      <h3 slot="header">Modifica campo</h3>
    </modalForm>
  </div>
</template>

<script>
import modalForm from "@/components/modalForm.vue";
import tool from '@/helpers/tools.js'
import utils from '@/helpers/utils.js'
import notify from "@/helpers/notifications.js"
import { JSONPath as jpath } from 'jsonpath-plus';
import validation from '@/helpers/validations.js';
import mapUtils from '@/helpers/mapUtils.js'
import InfoCompiling from "@/components/infoCompiling.vue"

export default {
  name: "generale",
  components: {
    modalForm,
    InfoCompiling
  },
  props: {
    idTablePdf: { type: String, default: () => '' },
    infoTabName: { type: String, default: () => '' },
    incomingData :{
      type: Object,
    },
    salvaSchedaToStore: Function,
    tabName: String
  },
  watch: {
    tabName: function() {
      if(this.$props.tabName === "generale") {
        this.extractData();
      }
    }
  },
  data() {
    return {
      editFormData: {},
      editLine: 0,
      showEditForm: false,
      buttons: [
        {
          name: 'Modifica',
          action: 'editField',
          show: true,
          param: 'edit'
        },
      ],
      tabledata: {
        header: [
          {
            key: "Campo",
          },
          {
            key: "Descrizione",
            tdAttr: this.tooltip
          },
        ],
        "rows": [
          {
              id: "['progetto']['beneficiario']['beneficiario']['denominazione']",
              Campo: "Beneficiario",
              Descrizione: "Non disponibile",
              Azione:[],
          },
          {
              id: "['progetto']['referenteProgetto']['nome']",
              Campo: "Referente di Progetto",
              Descrizione: "Non disponibile",
              Azione:['edit']
          },
          {
              id: "['progetto']['responsabileOperativoProgetto']['nome']",
              Campo: "Responsabile Operativo di Progetto",
              Descrizione: "Non disponibile",
              Azione:['edit']
          },
          {
              id: "['progetto']['codiceProgetto']",
              Campo: "Codice Progetto",
              Descrizione: "Non disponibile",
          },
          {
              id: "['progetto']['titoloProgetto']",
              Campo: "Titolo Progetto",
              Descrizione: "Non disponibile",
          },
          {
              id: "['budgetIniziale']",
              Campo: "Budget Iniziale del Progetto",
              Descrizione: "",
          },
          {
              id: "['budgetRimodulato'][*]['budget']",
              Campo: "Budget Rimodulato del Progetto",
              Descrizione:"",
              Azione:['edit']
          },
          {
              id: "['economieProgetto']",
              Campo: "Economie di Progetto",
              Descrizione:"",
              Azione:['edit']
          },
          {
              id: "['progetto']['strumentoFinanziario']",
              Campo: "Strumento Finanziario",
              Descrizione:"Non disponibile",
          },
          {
              id: "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['obiettivoSpecifico']['codice']",
              Campo: "Obiettivo Specifico",
              Descrizione:"Non disponibile",
          },
          {
              id: "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['codiceComposito']",
              Campo: "Obiettivo Nazionale",
              Descrizione:"Non disponibile",
          },
          {
              id: "['progetto']['durataProgettoMesi']",
              Campo: "Durata Progetto (in mesi)",
              Descrizione: "",
              Azione:['edit']
          },
          {
              id: "['progetto']['dataConclusione']",
              Campo: "Data Fine",
              Descrizione:"",
              Azione:['edit'],
          },
          
        ]
      },
      scheda : {},
    };
  },
  mounted() {
    this.extractData();
  },
  methods: {

    tooltip(value, key, item) {
      if(item.note)
        return {title: 'nota: ' + item.note};
      return {};
    },

    extractData() {
        if(!this.incomingData) {
          console.log('generale: Invalid inputData!?');
          return;
        }

        let scheda = this.incomingData.schedaMonitoraggio;
        let mappa = this.incomingData.schedaMonitoraggio.dataEntryConfiguration;
        if(!mappa) {
          console.log('generale: Invalid tabellone', mappa);
          return;
        }
        let canEdit = utils.caniuse('edit', this.incomingData.taskList, this.incomingData.schedaMonitoraggio, this.incomingData.history);
        this.tabledata.rows.forEach(row => {
          row.Descrizione = '';
          row._cellVariants = {}; 
          delete row.note;
          this.tabledata.header[1].tdAttr = this.tooltip;
          if(!row.id) {
            console.error('id non presente', row);
            return;
          }
          let id = row.id;
          let mapElement = mapUtils.getMapElement(mappa, id);

          if(!mapElement) {
            console.error('elemento non trovato in mappa: ', id);
            return;
          }
          
          if(canEdit && !mapElement.config.readonly) {
            row.Azione = ['edit']
          }
          else {
            row.Azione = [];
          }


          let results = jpath({resultType: 'all'},'$'+row.id, scheda);
          let valueToPrint;
          if(results.length === 0) {
            if(!row.id.includes('imodulato'))
              console.log('valore non trovato in scheda: ', row.id);
            return;
          } else if(results.length > 1) {
            valueToPrint = results.map(item => item.value);
          } else {
            valueToPrint = results[0].value;
          }

          if(id.includes('imodulato')) {
            const result =  results[results.length - 1];
            valueToPrint = result.value;
            id = result.path.replace('$',''); // WARNING: ultimo valore del budget rimodulato
            mapElement = mapUtils.getMapElement(mappa, id);
          }
          
          row.Campo = mapElement.config.label;

          let formattedVal = utils.formatOutput(mapElement, valueToPrint);
          row._cellVariants = {};
          if(mapElement.data.edited) {
            let nomeColonna = this.tabledata.header[1].key;
            row._cellVariants[nomeColonna] = mapElement.data.isSubstancial ? 'danger': 'warning'; 
          }
          // TASK #13750: nota sempre persistita
          row.note = mapElement.data.note;
          row.Descrizione = formattedVal;
        });
      // WARNING: azioni visibili solo se viene completato correttamente il caricamento della scheda
      this.tabledata.header = [
          {
            key: "Campo",
          },
          {
            key: "Descrizione",
            tdAttr: this.tooltip
          },
          {
            key: "Azione"
          },
        ];
    },

    viewOnlyMode() {
      for(let i in this.tabledata.rows) {
        
        let actions = this.tabledata.rows[i].Azione;
        if(!actions)
          continue;
        for(let j = 0; j < actions.length; j++) {
            if(!utils.caniuse(actions[j], this.incomingData.taskList, this.incomingData.schedaMonitoraggio, this.incomingData.history)) {
              actions.splice(j,1);
              j--;
            }
        }
      }
    },

    getCfgKey(budgetRimodulati, key) {
      const len = budgetRimodulati.length;
      if(len > 0) {
        return key.replace(/\b\d+\b/, (len - 1).toString());
      }
      return null;
    },

    editRiga(actionFromModal) {
      this.showEditForm = false;
        let clonedSchedaMonitoraggio = tool.genericFunctions.cloneObject(this.incomingData.schedaMonitoraggio);
        // update data
        for(let [key, field] of Object.entries(actionFromModal.content)) {
          let updatedValue = field.value;
          if(updatedValue == undefined) {
            // nessuna azione se il nuovo valore è undefined
            continue;
          }
          const mappa = this.incomingData.schedaMonitoraggio.dataEntryConfiguration;
          const elem = mapUtils.getMapElement(mappa, key);
          const mapElement = tool.genericFunctions.cloneObject(elem);
          updatedValue = utils.unformatOutput(mapElement, updatedValue);

          let updatedConfiguration = actionFromModal.conf[key];
          let jPathResult = jpath({resultType: 'all'}, '$'+key, clonedSchedaMonitoraggio)[0];
          let parent = jPathResult? jPathResult.parent : undefined;
          let parentArray = parent; // nel caso del budget rimodulato evito un'altra chiamata a jpath;
          let arrayName = jPathResult? jPathResult.parentProperty : undefined;
          if(parent == undefined) { // bisogna creare l'oggetto da modificare
            let temp = key.replace(/(\[(\d+)\])(?!.*\[(\d+)\])/g, "[*]"); // trasforma in * l'ultimo indice di array
            let last = temp.lastIndexOf("[*]");
            let parentKey = temp.substring(0,last); // id dell'oggetto padre

            let elements = jpath({resultType: 'all'}, '$'+parentKey, clonedSchedaMonitoraggio);
            if(elements.length === 0) {
              console.error('elemento non trovato', key, parentKey);
              notify.error(notify.strings.error, notify.strings.operationError('Modifica di ' + updatedConfiguration.config.label));
              return;
            }
            parentArray = elements[0].parent;
            arrayName = elements[0].parentProperty;
            if(elements[0].value == null) { // l'array di elementi (budget rimodulato) è vuoto.
              let nextEles = parentKey.match(/(\w*)(?='\])/g).filter((val) => {return val !== ''});
              let newValue = []; // creo l'array vuoto
              let ele = mapUtils.recursiveElement(newValue, nextEles); // creo ricorsivamente gli oggetti annidati
              parentArray[arrayName] = ele[arrayName]; // aggiungo l'oggetto contenitore
            }
            
            temp = temp.substring(last);
            let nextElements = temp.match(/(\w*)(?='\])/g).filter((val) => {return val !== ''});
            let fieldName = nextElements[0];
            let newBudgetRimodulato = {};
            newBudgetRimodulato[fieldName] = null;
            parentArray[arrayName].push(newBudgetRimodulato); // nell'oggetto contenitore inserisco l'elemento dell'array
            parent = jpath({resultType: 'parent'}, '$'+key, clonedSchedaMonitoraggio)[0];
          }
          let fieldToUpdate = jpath('$'+key+'~', clonedSchedaMonitoraggio)[0];

          let cfgKey;

          if(key.includes('imodulato')) { // controllo il valore del budget rimodulato
            let keys = key.match(/\['\w*'\]/g).filter((val) => {return val !== ''});
            if(keys.length === 0) {
                console.error('chiave di budget rimodulato non trovata');
                notify.error(notify.strings.error, notify.strings.operationError('Operazione su ' + updatedConfiguration.config.label));
                continue;
            }
            let budgetRimodulatoKey = keys[0];
            let budgetRimodulati = jpath('$'+budgetRimodulatoKey, clonedSchedaMonitoraggio)[0];
            if(budgetRimodulati.length <= 1) {
              // è il primo elemento, devo confrontare con il budget iniziale
              let budgetIniziale = jpath("$['budgetIniziale']", this.incomingData.schedaMonitoraggio)[0];
              if(updatedValue != budgetIniziale) { // setto il nuovo valore
                parent[fieldToUpdate] = updatedValue;
                updatedConfiguration.data.oldValue = budgetIniziale;
                clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione prima del controllo substancial
                updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedSchedaMonitoraggio);
              }
              else {
                console.log('budget non modificato', budgetRimodulati, updatedConfiguration);
                // se la rimodulazione era stata aggiunta, rimuovo l'elemento dell'array e la configurazione
                if(updatedConfiguration.data.added) {
                  delete clonedSchedaMonitoraggio.dataEntryConfiguration[key];
                  budgetRimodulati.pop();
                  cfgKey = this.getCfgKey(budgetRimodulati, key);
                }
                // continue;
              }
            }
            else { // prendo l'ultimo budget rimodulato
              let valueToCompare = budgetRimodulati[budgetRimodulati.length -2].budget;
              if(updatedValue != valueToCompare) {
                parent[fieldToUpdate] = updatedValue;
                updatedConfiguration.data.oldValue = valueToCompare;
                clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione prima del controllo substancial
                updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedSchedaMonitoraggio);
              }
              else {
                console.log('budget senza modifiche');
                // ripristino l'array
                delete clonedSchedaMonitoraggio.dataEntryConfiguration[key];
                budgetRimodulati.pop();
                cfgKey = this.getCfgKey(budgetRimodulati, key);
                
                // continue;
              }
            }
          }
          else {
            if(parent[fieldToUpdate] !== updatedValue && 
                !((parent[fieldToUpdate] == null || parent[fieldToUpdate] == undefined) && updatedValue === '')
            ){ // se il campo è stato modificato (tranne se settiamo a stringa vuota un campo null o undefined)
              // allora controllo la configurazione
              if(!updatedConfiguration.data.edited) {
                updatedConfiguration.data.edited = true; // se è la prima modifica setto il flag edited e salvo il valore originale
                updatedConfiguration.data.oldValue = parent[fieldToUpdate];
              } else {

                if(updatedValue == updatedConfiguration.data.oldValue || // se è stato riportato il valore iniziale (anche se nullo)
                  ((updatedConfiguration.data.oldValue == null || updatedConfiguration.data.oldValue == undefined) && updatedValue === '')
                ) {
                  // se il valore era già stato modificato
                  updatedConfiguration.data.oldValue = null;              // e stiamo riportando il valore all'originale
                  updatedConfiguration.data.edited = false;          // ripristino i valori all'originale e cancello la nota
                  // TASK #13750: le note non vengono mai resettate
                  // updatedConfiguration.data.note = '';                // warning: solo == e non === altrimenti problemi di typeof
                }
              }
              parent[fieldToUpdate] = updatedValue; // setto il nuovo valore
              if(updatedConfiguration.config.canBeSubstancial) {
                clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione prima del controllo substancial
                updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedSchedaMonitoraggio);
              }

              clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione aggiornata
            }
            /*
            else {
              // aggiorno comunque la nota se il campo è modificato o aggiunto
              if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
                clonedSchedaMonitoraggio.dataEntryConfiguration[key].data.note = updatedConfiguration.data.note;
            }*/
          }
          if(!cfgKey)
            cfgKey = key;
          // TASK #13750: le note vengono sempre persistite
          if(!clonedSchedaMonitoraggio.dataEntryConfiguration[cfgKey]) {
            clonedSchedaMonitoraggio.dataEntryConfiguration[cfgKey] = mapUtils.cloneConf(cfgKey, clonedSchedaMonitoraggio.dataEntryConfiguration);
          }
          clonedSchedaMonitoraggio.dataEntryConfiguration[cfgKey].data.note = updatedConfiguration.data.note;
        }

        this.salvaSchedaToStore(clonedSchedaMonitoraggio);
        this.extractData();
    },

    doAction(actionToDo, index)
    {
      this.scheda = this.incomingData.schedaMonitoraggio;

        let value;
        let mapElement;
        let mappa;
        let newId;
        switch(actionToDo) {
            case 'edit':
              {
                mappa = this.incomingData.schedaMonitoraggio.dataEntryConfiguration;
                this.editFormData = {content:{}, conf: {}};
                let id = this.tabledata.rows[index].id;
                newId = id;
                let values = jpath('$'+id, this.incomingData.schedaMonitoraggio);
                let ele = mapUtils.getMapElement(mappa, id);
                mapElement = tool.genericFunctions.cloneObject(ele);
                
                if(values.length === 0) {
                  console.log('valore non trovato in scheda: ', id);
                }
                else
                  value = {'value': values[0], 'id': id};
                
                // caso del budget rimodulato
                if(id.includes('budgetRimodulato')) {
                  let budgets = jpath({resultType:'all'}, '$'+id, this.incomingData.schedaMonitoraggio);
                  if(budgets.length === 0) {
                    // non ci sono budget rimodulati, verrà creato un nuovo elemento dell'array a partire dal budget iniziale
                    newId = id.replace("*", 0);
                    mapElement.config.path = newId;
                    mapElement.data.added = true;
                    mapElement.data.edited = true;
                    let temp =  jpath("$['budgetIniziale']", this.incomingData.schedaMonitoraggio);
                    if(temp.length === 0) {
                      console.error('budget iniziale non presente');
                      value = {'value': 0, 'id': newId};
                    }
                    else {
                      let newBudget = temp[0];
                      value = {'value': newBudget, 'id': newId};
                    }
                  }
                  else {
                    let len = budgets.length;
                    let budget = budgets[len - 1];
                    newId = budget.path.replace('$', '');
                    let tempConf = mappa[newId];
                    // console.log('ultimo',tempConf, newId);
                    // Se non è modificato aggiungo una riga, altrimenti prendo la riga modificata
                    if(!tempConf) {
                      newId = id.replace("*", len);
                      mapElement.config.path = newId;
                      mapElement.data.edited = true;
                    }
                    else { // prendo la riga modificata
                      // console.log('tempconf',tempConf);
                      mapElement = tool.genericFunctions.cloneObject(tempConf);
                    }
                    let budgetValue = budget.value;
                    if(budgetValue == null) {
                      // prendo come riferimento il budget iniziale oppure zero
                      let temp =  jpath("$['budgetIniziale']", this.incomingData.schedaMonitoraggio);
                      if(temp.length === 0) {
                        console.error('budget iniziale non presente');
                        value = {'value': 0, 'id': newId};
                      }
                      else {
                        let newBudget = temp[0];
                        value = {'value': newBudget, 'id': newId};
                      }
                    }
                    else{
                      value = {'value': budgetValue, 'id': newId};
                    }
                  }
                }
                
                this.editFormData.conf[newId] = mapElement;
                this.editFormData.content[newId] = tool.genericFunctions.cloneObject(value);
            

                this.editLine = index;
                this.showEditForm = true;
              
              }
              break;
            default:
              this.editFormData = {};
              break;
        }
    },
  },
 
};
</script>
