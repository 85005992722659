import Vue from "vue";
import endpoints from "@/components/endpoints.vue";

const state = {
    schedeCircuitoFinanziario: {},
    schedaNoteRiservate: {},
    tabelloneCircuitoFinanziario: {},
    allegatiDaCancellare: []
}

const getters = {
    getScheda(state) {
        return (payload) => {
            if ( payload &&
                payload.codiceProgetto &&
                payload.id &&
                state.schedeCircuitoFinanziario[payload.codiceProgetto] &&
                state.schedeCircuitoFinanziario[payload.codiceProgetto][payload.id]){
                    return state.schedeCircuitoFinanziario[payload.codiceProgetto][payload.id]
                }
                else
                    console.error('circuitoFinanziario/getScheda required payload missing:', payload)
                ;
                return null
        }
    },
    getTabellone(state) {
        return state.tabelloneCircuitoFinanziario
    },
    getAllegatiDaCancellare(state){
        return state.allegatiDaCancellare;
    },
    getSchedaNoteRiservate(state) {
        return (payload) => {
            if ( payload &&
                payload.noteRiservatekey &&
                state.schedaNoteRiservate &&
                state.schedaNoteRiservate[payload.noteRiservatekey]) {
                    return state.schedaNoteRiservate[payload.noteRiservatekey];
                }
                else
                    console.error('circuitoFinanziario/get SchedaNoteRiservate required payload missing:', payload);
                return null
        }
    }
}

const actions = {
    setPrefinanziamento(context, payload){
        let codProgetto = payload.codiceProgetto
        let idPrefinanziamento = payload.idPrefinanziamento
        const url = endpoints.prefinanziamentoById(codProgetto, idPrefinanziamento)
        return Vue.prototype.$get(url).then(
            scheda => {
                console.log("Prefinanziamento letto = ", scheda)
                context.commit("SET_SCHEDA", {
                    codiceProgetto: codProgetto,
                    id: idPrefinanziamento,
                    scheda: scheda,
                  });
                // GESTIONE SCHEDA NOTE RISERVATE IN STORE
                if(!scheda.noteRiservateKey) return scheda;
                else {
                    const keySchedaNoteRiservate = scheda.noteRiservateKey;
                    const version = scheda.noteRiservateVersion || 1000;
                    const url_reserved = endpoints.noteRiservate(keySchedaNoteRiservate)
                    return Vue.prototype.$get(url_reserved, {version: version}).then((schedaNoteRiservate) => {
                        context.commit('ADD_SCHEDA_NOTE_RISERVATE', {
                            schedaNoteRiservate: schedaNoteRiservate,
                            codiceProgetto: schedaNoteRiservate.codiceProgetto,
                            idScheda: idPrefinanziamento
                        });
                        return scheda;
                    },
                    (error) => {
                        console.error('setSchedaNoteRiservate error', error.response);
                        throw error
                    });
                }
            },
            (error) => {
                console.error("Errore setPrefinanziamento: ", error.message)
                throw error               
            }
        )
    },
    setSchedaTranche(context, payload){
        let codProgetto = payload.codiceProgetto
        let idScheda = payload.idScheda
        const url = endpoints.schedaTrancheById(codProgetto, idScheda)
        return Vue.prototype.$get(url).then(
            scheda => {
                console.log("Scheda Circuito F. Letta = ", scheda)
                context.commit("SET_SCHEDA", {
                    codiceProgetto: codProgetto,
                    id: idScheda,
                    scheda: scheda,
                  });
                  // GESTIONE SCHEDA NOTE RISERVATE IN STORE
                if(!scheda.noteRiservateKey) return scheda;
                else {
                    const keySchedaNoteRiservate = scheda.noteRiservateKey;
                    const version = scheda.noteRiservateVersion || 1000;
                    const url_reserved = endpoints.noteRiservate(keySchedaNoteRiservate)
                    return Vue.prototype.$get(url_reserved, {version: version}).then((schedaNoteRiservate) => {
                        context.commit('ADD_SCHEDA_NOTE_RISERVATE', {
                            schedaNoteRiservate: schedaNoteRiservate
                        });
                        return scheda;
                    },
                    (error) => {
                        console.error('setSchedaNoteRiservate error', error.response);
                        throw error
                    });
                }
            },
            (error) => {
                console.error("Errore setSchedaTranche: ", error.message)
                throw error               
            }
        )
    },
    updateScheda(context, payload) {
        context.commit("SET_SCHEDA", {
            codiceProgetto: payload.codiceProgetto,
            id: payload.id,
            scheda: payload.content,
        })
    },
    setTabellone(context, payload){
        let scheda = payload.scheda
        if (scheda){
        // In base a idSchemaControllo e schemaVersione
        // chiamo la configurazione di CheckList opportuna
            payload.type = scheda.idSchemaControllo
            payload.version = scheda.schemaVersione
            
            let compositeUrl = endpoints.retrieveVersionedTabellone(payload)
            return Vue.prototype.$get(compositeUrl)
            .then(tabellone => {
                context.commit("SET_TABELLONE", {
                    content: tabellone
                })
                return tabellone
            },
            (error) => {
                console.error("SET_TABELLONE Error, url=" + compositeUrl + " " + error.message)
                throw error
            }
            )
        }
        else
            console.error("setTabellone Error in Payload:", payload)
    },
    setAllegatoDaCancellare(context, payload){
        context.commit("SET_DA_CANCELLARE", {
            fileId: payload.fileId
        })
    },
    clearCircuitoFinanziario(context){
        context.commit("RESET")
    },
    clearAllegatiDaCancellare(context){
        context.commit("RESET_DA_CANCELLARE")
    }
}

const mutations = {
    SET_SCHEDA(state, payload){
        if(payload && payload.codiceProgetto && payload.id && payload.scheda){
            if(!state.schedeCircuitoFinanziario[payload.codiceProgetto])
                state.schedeCircuitoFinanziario[payload.codiceProgetto] = {}
            
            state.schedeCircuitoFinanziario[payload.codiceProgetto][payload.id] = payload.scheda
        }
        else
            console.error("SET_SCHEDA Error in Payload:", payload)
    },
    ADD_SCHEDA_NOTE_RISERVATE(state, payload) {
        if(payload && payload.schedaNoteRiservate) {
            if(state.schedaNoteRiservate == null)
                state.schedaNoteRiservate = {};
            state.schedaNoteRiservate[payload.schedaNoteRiservate.noteRiservateKey] = {};
            state.schedaNoteRiservate[payload.schedaNoteRiservate.noteRiservateKey] = payload.schedaNoteRiservate;
        }
        else
            console.error('ADD_SCHEDA_NOTE_RISERVATE no key or scheda', payload);
    },
    SET_TABELLONE(state, payload){
        if(payload && payload.content)
            state.tabelloneCircuitoFinanziario = payload.content
        else
            console.error("SET_TABELLONE Error in payload: ", payload)
    },
    SET_DA_CANCELLARE(state, payload){
        if(payload && payload.fileId)
            state.allegatiDaCancellare.push(payload.fileId)
        else
            console.error("SET_DA_CANCELLARE Error in payload: ", payload)
    },
    RESET(state){
        state.schedeCircuitoFinanziario = {}
        state.tabelloneCircuitoFinanziario = {}
        state.allegatiDaCancellare = []
    },
    RESET_DA_CANCELLARE(state){
        state.allegatiDaCancellare = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
