<template>
  <div>
    <b-table :id="id" striped hover :items="notes" :fields="fields"></b-table>
  </div>
</template>

<script>
import utils from "@/helpers/utils.js";
export default {
  props: {
    notes: { type: Array },
    id: { type: String, default: '' }
  },
  data() {
    return {
      // DA BE utente dataInserimento nota
      fields: [
        { key: "utente", label: "Utente" },
        {
          key: "dataInserimento",
          label: "Data di Inserimento",
          formatter: "formatDate",
        },
        { key: "nota", label: "Nota" },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return utils.formatDate(date);
    },
  },
};
</script>
