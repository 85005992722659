<template>
    <div id="wrapCollapses">
        <Collapse v-for="item in componentAllowed" :key="item.title" :name="item.title">
            <br/>
            <div v-if="item.nameComponent === 'GenericComponent'">
                <GenericComponent v-if="dataComponentExist(item.dataComponent.dinamicIds)"
                    :idTablePdf="item.id_table_pdf"
                    :rowIds="item.dataComponent.dinamicIds"
                    :name="item.name"
                    :tabName="item.tabName"
                    :title="item.title"/>
                <h4 v-else>
                    Niente da mostrare
                </h4>
            </div>
            <div v-if="item.nameComponent === 'CollapseAttivitaProgetto'">
                <CollapseAttivitaProgetto v-if="dataComponentExist(item.dataComponent.dinamicIds)"
                    :idTablePdf="item.id_table_pdf"
                    :inputs="item.dataComponent.inputs"
                    :dinamicIds="item.dataComponent.dinamicIds"
                    :name="item.name"
                    :title="item.title"
                    @updateIdAttivita="updateIdAttivita()"
                    @creationPhases="refreshCronoProgramma()"
                    @aggiornaCronoprogramma="refreshAttivitaChildren()"
                    :updateAttivita="updateAttivita"/>
            </div>
            <div v-if="item.nameComponent === 'BudgetPerObiettivi'">
                <BudgetPerObiettivi v-if="dataComponentExist(item.dataComponent.dinamicIds)"
                    :idTablePdf="item.id_table_pdf"
                    :inputs="item.dataComponent.inputs"
                    :dinamicIds="item.dataComponent.dinamicIds"
                    :name="item.name"
                    :title="item.title"
                    :updateBudgetObiettivi="updateBudgetObiettivi"/>
            </div>
            <div v-if="item.nameComponent === 'Indicatori'">
                <Indicatori v-if="dataComponentExist(item.dataComponent.dinamicIds)"
                    :idTablePdf="item.id_table_pdf"
                    :inputs="item.dataComponent.inputs"
                    :dinamicIds="item.dataComponent.dinamicIds"
                    :name="item.name"
                    :title="item.title"/>
            </div>
            <div v-if="item.nameComponent === 'VerticalComponent'">
                <VerticalComponent v-if="dataComponentExist(item.dataComponent.dinamicIds)"
                    :idTablePdf="item.id_table_pdf"
                    :inputs="item.dataComponent.inputs"
                    :dinamicIds="item.dataComponent.dinamicIds"
                    :name="item.name"
                    :title="item.title" />
                <h4 v-else>
                    Niente da mostrare
                </h4>
            </div>
            <div v-if="item.nameComponent === 'VerticalComponent1'">
                <VerticalComponent1 v-if="dataComponentExist(item.dataComponent.dinamicIds)"
                    :idTablePdf="item.id_table_pdf"
                    v-bind="{idTotalPdf: item.id_total_cost_budget_pdf ? item.id_total_cost_budget_pdf : '' }"
                    :actions="item.dataComponent.actions"
                    :dinamicIds="item.dataComponent.dinamicIds"
                    :tableIds="item.dataComponent.tableIds"
                    :idTotale="item.dataComponent.idTotale"
                    :name="item.name"
                    :title="item.title"
                    :updateVertical1="updateVertical1"
                    :notifyUpdate="item.notifyUpdate"
                    @refreshValues="refreshData()"/>
                <h4 v-else>
                    Niente da mostrare
                </h4>
            </div>
            <div v-if="item.nameComponent === 'iter2'">
                <iter2
                    :idTablePdf="item.id_table_pdf"
                    :incomingData="getScheda({idSchedaProgetto: item.dataComponent.idScheda})"
                    :idScheda="item.dataComponent.idScheda"
                    :dinamicIds="item.dataComponent.dinamicIds"
                    :rootId="item.dataComponent.rootId"
                    :tabelloneType="item.dataComponent.tabelloneType"
                    :updateVertical2="updateIterAmm"
                    :tabName="item.dataComponent.tabName"/>  
            </div>
            <div v-if="item.nameComponent === 'OrganigrammaUpload'">
                <FileUpload v-if="dataComponentExist(item.dataComponent)"
                    :idTablePdf="item.id_table_pdf"
                    :rid="item.dataComponent.inputs.idProgetto"
                    :ownerProcessStatus = item.dataComponent.inputs.ownerProcessStatus
                    :actions= item.dataComponent.inputs.actionsArray
                    :entity= item.dataComponent.inputs.entity
                    :availableFiles= item.dataComponent.inputs.availableFiles
                    :documentTypes = item.dataComponent.inputs.availableDocumentTypes
                    :riferimento = item.dataComponent.inputs.riferimento
                    name= "organigramma"
                    @doRefresh="refreshOrganigramma()">
                </FileUpload>
                <h4 v-else>
                    Niente da mostrare
                </h4>
            </div>
        </Collapse>
    </div>
</template>


<script>
import Collapse from "@/components/collapse.vue";
import { mapGetters } from "vuex";
import GenericComponent from "@/views/progetti/GenericComponent.vue";
import VerticalComponent from './verticalComponent.vue';
import VerticalComponent1 from './verticalComponent1.vue';
import CollapseAttivitaProgetto from '@/views/progetti/CollapseAttivitaProgetto.vue';
import BudgetPerObiettivi from '@/views/progetti/BudgetPerObiettivi.vue';
import Indicatori from '@/views/progetti/indicatori.vue';
import FileUpload from './FileUpload.vue';
import iter2 from "@/views/progetti/iter2.vue"

export default {
    name: "wrapCollapses",
    data() {
        return {
            possibleComponents: [
                "Table",
                "OrganigrammaUpload",
                "GenericComponent",
                "VerticalComponent",
                "VerticalComponent1",
                "CollapseAttivitaProgetto",
                "Indicatori",
                "BudgetPerObiettivi",
                "iter2"
            ],
            updateAttivita: false,
            updateBudgetObiettivi: false,
            updateVertical1: false,
            updateIterAmm: false
        } 
    },
    props: {
        config: {
            type: Array,
            required: true
        },
        recalculateBudget: Boolean,
    },
    watch: {
        // config: function() {
        //     console.log('è cambiato', new Date());
        // },
        'recalculateBudget': function() {
            console.log('TAB BUDGET RISVEGLIATO, RICALCOLARE I BUDGETS')
            this.refreshData()
            this.updateVertical1 = !this.updateVertical1
        }
    },
    components: {
        Collapse,
        GenericComponent,
        VerticalComponent,
        VerticalComponent1,
        FileUpload,
        CollapseAttivitaProgetto,
        Indicatori,
        BudgetPerObiettivi,
        iter2
    },
    computed: {
        ...mapGetters({
            getScheda: "progetto/getScheda",
        }),
        componentAllowed() {
            // console.log(this.config)
            let filteredComponents = [];
            for (let i = 0; i < this.config.length; i++) {
                for (let j = 0; j < this.possibleComponents.length; j++) {
                    if (this.config[i].nameComponent === this.possibleComponents[j]) {
                        filteredComponents.push(this.config[i]);
                    }
                }
            }
            return filteredComponents;
        },
        dataComponentExist() {
            return data => {
                return !Object.keys(data).length == 0
            }
        },
  },
  methods: {
    refreshOrganigramma(){
        //Serve a ricaricare i file di organigramma
        this.$emit('refreshOrganigramma')
    },
    refreshData() {
        // A qualsiasi cambiamento di vertical1 dovrà
        // corrispondere un ricalcolo del costo del progetto
        this.updateBudgetObiettivi = !this.updateBudgetObiettivi
        this.$emit('changeCostsInBudget')
    },

    refreshCronoProgramma() {
        // Questa funzione viene richiamata ad ogni creazione di un'attività
        // Aggiorna di fatto il collapse di CronoProgramma
        this.$emit('creationAttivitaProgetto')
    },

    refreshAttivitaChildren() {
        // questa funzione viene attività all'eliminazione di un'attività di progetto
        // e aggiorna sia il collapse attività di progetto
        // sia i vertical component (quindi anche quelli collegati
        // alle attività di progetto)
        this.updateAttivita = !this.updateAttivita;
        this.updateVertical1 = !this.updateVertical1
        // Ogni cancellazione di un'attività comporta pure un refresh dell'intero tab di Budget
        this.$emit('updateOrDeleteAttivitaProgetto')
        // Anche la cancellazione di un'attività deve aggiorna di fatto il collapse di CronoProgramma
        this.$emit('creationAttivitaProgetto')
    },

    updateIdAttivita() {
        this.updateVertical1 = !this.updateVertical1;
        this.updateIterAmm = !this.updateIterAmm;
        this.$emit('updateOrDeleteAttivitaProgetto');
    }
 
  }
}
</script>