<template scope="props">
    <div id="dettaglioAttivita" class="elencoProgetti">
        <div class="table-title">Dettaglio Attività <span v-if="descBeneficiario !== ''"> {{descBeneficiario}}</span></div>

        <b-form inline           
        label-align-sm="left"
        class="filtro-notifiche">
        <label class="mr-sm-2" for="filterInput">Filtra risultati</label>
        <b-form-input
        v-model="filter"
        type="search"
        id="filterInput"
        placeholder="Digita un filtro"/>
        <b-button :disabled="!filter" @click="filter = ''">Pulisci filtro</b-button>
        
        </b-form>

        <b-table :filter="filter" :busy="isBusy" id="elencoAttivita" primary-key="dataModifica" hover show-empty
                 empty-text="Nessun dato trovato" empty-filtered-text="Nessun dato trovato"
                 :items="tabledata.rows" :fields="tabledata.header" :sort-by="sortBy" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/helpers/utils.js'
import notify from "@/helpers/notifications.js"

    export default {
        name: 'elencoAttivita',
        computed: {
             ...mapGetters({
                getDescBeneficiario: 'announcements/getDescBeneficiario'
            }),
        },
        components: {
        },
        data() {
            return {
                descBeneficiario: "",
                gruppo:"",
                myAttivitaAss: [],
                myAttivitaMon: [],
                isBusy: true,
                sortBy: 'Codice',
                filter: null,
                tabledata: {
                    "header": [
                        {
                            key: 'Codice',
                            thClass: 'thst',
                            sortDirection: 'asc',
                            sortable: true,
                        },
                        {
                            key: 'Titolo',
                            thClass: 'thst',
                            sortable: true,
                        },
                        {
                            key: 'Processo',
                            thClass: 'thst'
                        },
                        {
                            key: 'Periodo',
                            thClass: 'thst',
                        },
                        {
                            key: 'Stato',
                            thClass: 'thst',
                            sortable: true,
                        },
                    ],
                    "rows": []
                }
            };
        },
        methods: {
            dateFormatter(value) {
                return utils.formatDateTime(value);
            },

            caricaMieAttivita: async function () {
                this.isBusy = true;
                
                await this.$store.dispatch('selettoreAss/retrieveElencoProgetti')
                .then(
                    results => {
                        const attivitaMon = Object.values(results);
                        this.myAttivitaMon = utils.selezionaAttivita(this.gruppo, attivitaMon);
                        console.log("myMonitoring: ", this.myAttivitaMon.length);
                    }
                )
                .catch(
                    error => {
                        console.error("dettaglioAttivita: Errore con codice="+error.response.status+" body=", error.response.data)
                        notify.error(notify.strings.error, notify.strings.selettoreMonitoraggioKO)
                    }
                );
                
                const prInterim = this.$store.dispatch('selettoreAss/retrieveElencoProgetti', false);
                const prFinal = this.$store.dispatch('selettoreAss/retrieveElencoProgetti', true);

                await Promise.all([prInterim, prFinal]).then(
                    results => {
                        //assessment di mia competenza
                        let total = utils.selezionaAttivita(this.gruppo, Object.values(results[0]));
                        total = total.concat(utils.selezionaAttivita(this.gruppo, Object.values(results[1])));
                        this.myAttivitaAss = total;
                        console.log("dettaglioAttivita myAssessment: ",this.myAttivitaAss.length, total);
                    }
                ).catch(
                    error => {
                        console.error("dettaglioAttivita: Errore con codice="+error.response.status+" body=", error.response.data)
                        notify.error(notify.strings.error, notify.strings.selettoreAssessmentKO)
                    }
                );

                this.extractData();
                this.isBusy = false;
            },
            
            extractData()
            {
                let rows = [];
                
                for (const att of this.myAttivitaMon) {
                    let row = {
                        "Codice": att.codiceProgetto,
                        "Titolo": att.titolo,
                        "Processo": "Monitoraggio",
                        "Periodo": att.periodo,
                        "Stato": att.stato,
                    }
                    rows.push(row);
                }
                
                for (const att of this.myAttivitaAss) {
                    let row = {
                        "Codice": att.codiceProgetto,
                        "Titolo": att.titolo || "(Titolo non disponibile)",
                        "Processo": "Assessment",
                        "Periodo": att.periodo,
                        "Stato": att.stato,
                    }
                    rows.push(row);
                }
                
                this.tabledata.rows = rows;
            },
        },
        
        mounted(){
            this.descBeneficiario = this.getDescBeneficiario();
            this.gruppo = this.$getUserInfo().groups[0];
            this.caricaMieAttivita();
        }
    }

</script>
