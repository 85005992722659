<template>
    <div id="iter2">
        <VerticalComponent2
            v-if="loadComplete"
            :idTablePdf="idTablePdf"
            :idScheda="idScheda"
            :storeModule="storeModule"
            :tabelloneType="tabelloneType"
            :componentConfig="componentConfig"
            :updateVertical2="updateVertical2"
            @refreshValues="refreshData()"/>
    </div>
</template>

<script>
import VerticalComponent2 from "@/components/verticalComponent2.vue"

export default {
    name:"iter2",
    components: {
        VerticalComponent2
    },
    props: {
        idTablePdf: { type: String, default: () => '' },
        incomingData: Object,
        idScheda: String,
        tabName: String,
        rootId: String,
        tabelloneType: String,
        updateVertical2: Boolean,
        dinamicIds: Array,  default: function() { return [] }
    },
    data(){
        return {
            loadComplete: false,
            tabId: "procedure",
            //tabelloneType: 'cronoprog_monitoraggio',
            storeModule: 'cronoprog',
            componentConfig: {
                title: "Descrizione dell'iter amministrativo",
                name: "attivitaRealizzate",
                notifyUpdate: false,
                defaultActions: ['view', 'edit', 'add'],
                allowedActions: [],
                tableIds: [
                    "['attivita'][*]['content']['idAttivita']",
                    "['procedure'][*]['content']['idProceduraGara']",
                    "['procedure'][*]['content']['tipologiaProcedura']",
                    "['procedure'][*]['content']['oggetto']",
                    "['procedure'][*]['content']['note']",
                ],
                relIds: {
                    "['attivita'][*]['content']['idAttivita']": {
                        type: 'attivita',
                    },
                }  
            }          
        }
    },
    mounted(){
        let actions = this.$projectGetActions(this.incomingData)
        this.componentConfig.allowedActions = actions;
        this.componentConfig.dinamicIds = this.dinamicIds
        this.componentConfig.rootId = this.rootId
        this.componentConfig.tabName = this.tabName
        this.loadComplete = true;
    }
}
</script>
