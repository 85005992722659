<template>
  <div id="listaBandi">
    <h1 class="text-center m-2">AWARDING BODY - {{type}}</h1>
      <b-form inline class="m-1" style="display: flex;">
        <label for="filterBandiInput">
          <h4 class="mx-2">Filtra i risultati:</h4>
        </label>
        <b-form-input
          v-model="filter"
          type="search"
          id="filterBandiInput"
          placeholder="Digita un filtro"
        />
        <b-button
          id="filterBandiButton"
          class="mx-1"
          :disabled="!filter"
          @click="filter = ''"
          >Cancella</b-button
        >
        <b-button
        style="margin-left: auto;"
        class="align-right mr-4"
        @click="showForm()"
        variant="outline-primary"
        v-if= this.buttonNewBando
      >
        <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
        Nuovo Bando
      </b-button>
      </b-form>
    
      <b-table
        :busy="isBusy"
        primary-key="id"
        emptyText="Nessun dato disponibile"
        :sort-by="sortBy"
        sort-desc
        :filter="filter"
        emptyFilteredText="Nessun dato disponibile"
        :filter-included-fields="getFilterFields()"
        :items="tabledata.rows"
        :fields="tabledata.header"
        show-empty
        hover
        responsive>
        <template v-slot:table-busy>
          <div class="text-center">
            <strong>Lista in Caricamento...</strong>
          </div>
        </template>
        <template v-slot:cell(Azioni)="data">
          <b-button
            :id="namingIdIcon(x, data.index + 1)"
            :disabled="clicked"
            variant="outline-primary"
            size="sm"
            :key="x"
            :title="mappingTooltip(x)"
            v-for="x in data.value"
            @click="doAction(x, data.item.idBando)"
          >
            <fa-icon
              v-if="x === 'edit_in_list_bandi'"
              :icon="['far', 'edit']"
              class="selector-icons"
            />
            <fa-icon
              v-if="x === 'view'"
              :icon="['far', 'eye']"
              class="selector-icons"
            />
            <fa-icon
              v-if="x === 'claim'"
              :icon="['far', 'hand-paper']"
              class="selector-icons"
            />
            <fa-icon
              v-if="x === 'unclaim'"
              :icon="['fas', 'times-circle']"
              class="selector-icons"
            />
            <fa-icon
              v-if="x === 'new_project'"
              :icon="['far', 'plus-square']"
              class="selector-icons"
            />
          </b-button>
        </template>
      </b-table>
    <modalTypeBando
      v-if="showModalType"
      @closeModalCreateBando="closeModal"
    ></modalTypeBando>

    <b-modal :id="'modalForm1-' + this.name"
             size="lg"
             centered
             hide-header-close
             no-close-on-backdrop
             dialog-class="modal1-content"
             content-class="modal1-content"
             @ok="handleOk"
             ok-title="Vai al progetto"
             ok-variant="success"
             cancel-title="Annulla">
      <template v-slot:modal-title>
        <h3>ATTENZIONE: Esiste già un progetto associato a questo bando</h3>
      </template>
      <h3> Vuoi spostarti o rimanere su questa pagina?</h3>
    </b-modal>
    <b-modal id="bandoScadutoModal" 
      centered size="lg"
      ok-only ok-title="Chiudi" ok-variant="danger">
      <template v-slot:modal-header><h2><strong>Attenzione!</strong></h2></template>
      <h3 class="mb-4">I termini per la presentazione delle proposte progettuali sono scaduti.</h3>
      <h3 class="mb-4">Pertanto la creazione della scheda progetto non è più possibile.</h3>
    </b-modal>

  </div>
</template>

<script>
import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js";
import endpoints from "@/components/endpoints.vue";
import modalTypeBando from "@/components/selectTypeBando.vue";
import { mapGetters } from "vuex";
export default {
  name: "listaBandi",
  computed: {
    mappingTooltip() {
      return keyMapping => {
        let objMapping = {
          'edit_in_list_bandi': 'Modifica',
          'view': 'Visualizza',
          'claim': 'Presa in carico',
          'unclaim': 'Rilascio presa in carico',
          'new_project': 'Nuovo Progetto'
          }
        let temp = objMapping[keyMapping];
        if(!temp)
          return '';
        return temp;
      }
    },
    ...mapGetters({
      getAllBandi: "announcements/getAllBandi",
      getBeneficiari: 'announcements/getAllBeneficiariMap'

    })
  },
  components: {
    modalTypeBando,
  },
  data() {
    return {
      clicked: false,
      actions: ["edit_in_list_bandi", "view", "claim", "unclaim", "new_project"],
      isBusy: false,
      // bandiCount: null,
      buttonNewBando : false,
      sortBy: "dataInserimento",
      filter: null,
      showModalType: false,
      type: "",
      idProj: "",
      name: "listaBandi",
      tabledata: {
        header: [
          {
            key: "modalitaDiAccesso",
            label: "Modalità",
            sortable: true,
          },
          {
            key: "titoloBando",
            label: "Titolo",
            sortable: true,
          },
          {
            key: "dataInserimento",
            sortable: true,
            filterByFormatted: true,
            formatter: this.dateFormatter,
            label: "Data",
          },
          {
            key: "risorse",
            label: "Risorse complessive",
            formatter: this.currencyFormatter,
            sortable: true,
          },
          {
            key: "strumentoFinanziario",
            sortable: true,
          },
          {
            key: "stato",
            sortable: true,
          },
          {
            key: "Azioni",
            thClass: "thst",
          },
        ],
        rows: [],
      },
    };
  },
  watch: {
    $route() {
      this.loadBandi();
    }
  },
  methods: {
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      console.log("redirect to project: ",this.idProj);
      this.$router.push({
        name: "schedaProgetto",
        params: { idProgetto: this.idProj },
      });
    },
    closeModal() {
      this.showModalType = false;
    },
    getFilterFields() {
      let filterFields = [];
      for (let item of this.tabledata.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
    showForm() {
      this.showModalType = !this.showModalType;
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    currencyFormatter(value) {
      if (value == undefined || value == null)
        return null;
      return utils.formatCurrency(value);
    },
    loadBandi: async function() {
      this.isBusy = true;
      this.type = "Call for proposal";
      if(this.$route.params.type === "ASSEGNAZIONE_DIRETTA")
        this.type = "Assegnazione diretta";
      this.$store.dispatch("announcements/clearBandiState");
      this.tabledata.rows = [];
      // this.bandiCount = 0;

      const url = endpoints.listaBandi(this.$route.params.type);
      this.$get(url)
        .then((result) => {
          let filteredListBandi = []
          for (const row of result) {
            const azioni = this.$getActions(row);
            const localActions = azioni.filter((item) => {
              // console.log(this.actions)
              return this.actions.indexOf(item) != -1;
            });
            // Se non ho azioni non faccio vedere il bando corrispondente
            if (localActions.length > 0 ) {
              let obj = row.infoBando;
              obj.Azioni = localActions;
              
              this.tabledata.rows.push(obj);
              filteredListBandi.push(row)
            }
          }
          // this.bandiCount = this.tabledata.rows.length;
          this.$store.dispatch("announcements/populateBandiInStore", {
            allBandi: filteredListBandi,
          });
          this.isBusy = false;
        })
        
        .catch((error) => {
          this.isBusy = false;
          console.error("error lista bandi:", error.message);
          notify.error(notify.strings.error, notify.strings.listaBandiKO);
        });
    },

    getBeneficiario(idBeneficiario) {
        let mappaBenef = this.getBeneficiari;
        let descBeneficiario = '';
        if(mappaBenef[idBeneficiario]) {
            descBeneficiario = mappaBenef[idBeneficiario].denominazione 
        } else {
          console.error("listaBandi.vue: "+idBeneficiario+" non trovato nella mappa dei Beneficiari disponibili ", mappaBenef);
        }
        return descBeneficiario
    },

    createNewProject(schedaBando) {

      const groupId = this.$getUserGroupId();
      let denomBeneficiario = '';
      if(groupId) {
        const regex = /\w+$/;
        const group = regex.exec(groupId);
        denomBeneficiario = this.getBeneficiario(group);
        console.log(groupId, group, denomBeneficiario, schedaBando);
      }

      this.$store.dispatch("progetto/createSchedaProgetto", {
        idBando: schedaBando.infoBando.idBando,
        denominazione: denomBeneficiario
        }).then(
          (resp) => {
            let idProgetto = resp.idProgetto
            console.log("creata schedaProgetto con idProgetto ", idProgetto);
            //mi sposto sulla schedaProgetto che caricherà in automatico l'ultimo cronoprog disponibile
            this.$router.push({ name: "schedaProgetto", params: { idProgetto: idProgetto }});
            this.clicked = false;
            /*
            if (idProgetto !== "") {
              //dopo aver creato la schedaProgetto creo una schedaCronoprog vuota via API
              const url = endpoints.newCronoprog(idProgetto);
              //console.log("POST without body to URL: ", url);
              this.$post(url)
              .then((cronoprog) => {
                console.log("creata schedaCronoprog:", cronoprog.key);
                //mi sposto sulla schedaProgetto che caricherà in automatico l'ultimo cronoprog disponibile
                this.$router.push({ name: "schedaProgetto", params: { idProgetto: idProgetto }});
                this.clicked = false;
              })
              .catch((error) => {
                console.error('Error creating cronoprog:', error.message);
                notify.error(notify.strings.error, notify.strings.errorCreateNewProgetto);
                this.clicked = false;
              })
              
            } else {
              console.error("Qualcosa è andato storto durante la creazione del progetto!?");
              this.clicked = false;
            }*/
        }).catch(error => {
          if(!error.response) {
            console.error("create schedaProgetto(bandi) generic Error: ", error);
            notify.error(notify.strings.error, notify.strings.errorCreateNewProgetto);
          } else if(error.response.status === 410) {
            console.log("Avviso bando scaduto!", error.response);
            this.$bvModal.show("bandoScadutoModal");
          } else if (error.response.status === 429) {
            this.idProj = error.response.data;
            console.log("Esiste già il progetto:", this.idProj);
            this.$bvModal.show('modalForm1-' + this.name);
          } else if (error.response.status==410) {
            console.error("Errore nella creazione del progetto: ", error.response.data);
            notify.error(notify.strings.error, notify.strings.errorCreateNewProgettoBandoExpired);
          } else {
            console.error("create schedaProgetto(bandi) unmanaged Error: ", error);
            notify.error(notify.strings.error, notify.strings.errorCreateNewProgetto);
          }
          this.clicked = false;
        }
      );
    },
    async doAction(action, idScheda) {
      let schedaBando
      this.clicked = true;
      switch (action) {
        case "new_project":
          schedaBando = this.getAllBandi.find(schedaBando1 => schedaBando1.infoBando.idBando === idScheda )
          if(schedaBando.infoBando.modalitaDiAccesso == 'Assegnazione diretta') {
            // il Beneficiario può creare un nuovo progetto senza restrizione temporale
            this.createNewProject(schedaBando)
          } else if(schedaBando.infoBando.dataFineProposte && // CALL FOR PROPOSAL
            schedaBando.infoBando.oraFineProposte &&
            this.$bandoIsStillValid(new Date(schedaBando.infoBando.dataFineProposte), schedaBando.infoBando.oraFineProposte, schedaBando.infoBando.dataInizioProposte)) {
            // Se il bando non è scaduto a partire da esso il Beneficiario può creare un nuovo progetto
            this.createNewProject(schedaBando)
            break;
          } else { // BANDO SCADUTO
            notify.error(notify.strings.error, notify.strings.errorBandoExpired);
            this.clicked = false;
          }
          break;
        case "claim":
        case "unclaim":
          this.$store.dispatch("announcements/claim_unclaim_SchedaBando", {
            type: action,
            idSchedaBando: idScheda
            }).then(
              (resp) => {
                const indexBandoRow = this.getAllBandi.map(schedaBando1 => { return schedaBando1.infoBando.idBando; }).indexOf(idScheda);
                const azioni = this.$getActions(resp);
                console.log(azioni)
                const updateActions = azioni.filter((item) => {
                  // console.log(this.actions)
                  return this.actions.indexOf(item) != -1;
                });
                console.log(updateActions)
                let obj = resp.infoBando;
                obj.Azioni = updateActions;
                this.tabledata.rows[indexBandoRow].Azioni = obj.Azioni;
                this.clicked = false;
            },
            (error) => {
              console.error("claim/unclaim Error: ", error);
              // Messaggio di errore condizionato alla scelta claim/unclaim
              let errorMessageNotify = action === 'claim' ? notify.strings.errorClaimScheda : notify.strings.errorUnclaimScheda
              notify.error(notify.strings.error, errorMessageNotify);
              this.clicked = false;
            }
          );
          break;
        case "edit_in_list_bandi":
        case "view":
          this.$router.push({
            name: "schedaBando",
            params: { idBando: idScheda },
          });
          this.clicked = false;
          break;
        default:
          console.error("Unknown action!", action);
          break;
      }
    },
  },

  created() {
    // Setto nello Store la configurazione (costante) di SchedaBando da BE
    this.$store.dispatch("announcements/getConfSchedaBando");
    this.$store.dispatch("announcements/getfinancialTools");
    // Rimozione chiamata, spostata in dashboard
    //this.$store.dispatch("announcements/getBeneficiari");
    this.loadBandi();
    if(this.$setButton()) {
      this.buttonNewBando = true
    }
  },
};
</script>
