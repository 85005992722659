<template>
  <div id="verticalComponent2">
    <b-button
      style="margin-left: auto;"
      class="align-right"
      @click="doAction('add')"
      variant="outline-primary"
      v-if="visibilityAdd">
      <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
      Aggiungi
    </b-button>
    <b-table
      :id="idTablePdf"
      :tbodyTrClass="editedRowClass"
      :items="getItems"
      :fields="tabledata.header"
      :sort-by="sortBy">
      <template v-slot:cell(Azioni)="data">
        <b-button
          :id="namingIdButton(x, data.index + 1)"
          variant="outline-primary"
          :key="x" v-for="x in data.value"
          size="sm"
          v-on:click="doAction(x, data)">
          <fa-icon v-if="x === 'edit'" :icon="['far', 'edit']" class="selector-icons"/>
          <fa-icon v-if="x === 'delete'" :icon="['far', 'trash-alt']" class="selector-icons"/>
          <fa-icon v-if="x === 'view'" :icon="['far', 'eye']" class="selector-icons"/>
        </b-button>
      </template>
    </b-table>
    <b-modal
      :id="'modalForm1-' + componentConfig.name"
      size="lg"
      scrollable centered
      dialog-class="modal1-content"
      content-class="modal1-content"
      hide-footer>
      <template v-slot:modal-title>
        <h3>{{modalTitle}}</h3>
      </template>
      <ModalForm
        :fdata="editFormData"
        :sch="getScheda()"
        :optionalObj="optionalObj"
        :buttons="formButtons"
        :parentName="componentConfig.name"
        :tabName="componentConfig.tabName"
        :cfg="mappa"
        @editField="formCallBack"/>
    </b-modal>
    <b-modal :id="'modalData-' + componentConfig.name"
             size="lg"
             scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content"
             ok-only ok-variant='danger' ok-title='Chiudi'>
             <template v-slot:modal-title>
                <h3>Visualizzazione</h3>
             </template>
             <DynamicTable2 :tdata="modalData"/>
    </b-modal>
  </div>
</template>
<script>
import ModalForm from "./modalForm1.vue";
import mapUtils from "@/helpers/mapUtils.js";
import { JSONPath as jpath } from "jsonpath-plus";
import calculation from "@/helpers/calculations.js";
import { mapGetters } from "vuex";
import tool from "@/helpers/tools.js";
import DynamicTable2 from "@/components/dynamicTable2.vue";
import visibility from '@/helpers/visibility.js';
import notify from "@/helpers/notifications.js";
import utils from '@/helpers/utils.js'

export default {
  name: "Vertical2",
  data() {
    return {
      sortBy: '',
      title: '',
      modalTitle: '',
      formButtons: [],
      formCallBack: this.editRiga,
      editLine: -1,
      mappa: {},
      tabledata: {
        header: [],
        rows: [],
      },
      buttons: [
        {
          name: "Modifica",
          action: "editField",
          show: true,
          param: "edit",
        },
      ],
      editFormData: {},
      modalData: {},
      isIdRegex: /\[.+\]/,
      fieldIds: Array,
      headerIds: Array
    };
  },
  components: {
    ModalForm,
    DynamicTable2
  },
  props: {
    idTablePdf: { type: String, default: () => '' },
    componentConfig: {
      type: Object,
      required: true,
      default: () => {
        return {
          defaultActions: ['edit', 'delete'],
        }
      }
    },
    updateVertical2: Boolean,
    storeModule: String,
    idScheda: String,
    tabelloneType: String,
    optionalObj: Object,
    // rimodulated: { type: Boolean, default: () => null}
  },
  watch: {
    updateVertical2: function() {
        this.extractData();
    }
  },
  mounted() {
    this.title = this.componentConfig.title;
    
    this.mappa = this.getTabellone(this.tabelloneType);
    if(this.mappa) {
      this.setupFields();
      this.extractData();
    } 
    else 
      console.error(this.componentConfig.name, "error in mappa", this.mappa);
  },
  computed: {
    ...mapGetters({
      getTabellone: "configuration/getTabellone"
    }),
    getItems() {
      let items = [];
      for (const obj of this.tabledata.rows) {
        let item = {
          note: {},
          rowId: obj.rowId,
        };
        for (const key in obj) {
          if (!key.match(this.isIdRegex)) {
            item[key] = obj[key];
          } else {
            item[key] = obj[key].value;
            item.note[key] = obj[key].note;
          }
        }
        items.push(item);
      }
      return items;
    },
    visibilityAdd() {
      return this.componentConfig.allowedActions.indexOf('add') !== -1;
    },
  },
  methods: {
    editedRowClass(item) {
      if(item.edited && !item.deleted)
        return 'changeInRow';
      else if(item.deleted)
        return 'deletedCell';
      return '';
    },
    // Costruzione id per Buttoni (Azioni nel componente)
    namingIdButton(action, index) {
      return this.$builtIdDinamically(this.componentConfig.name, action, index)
    },

    tooltip(value, key, item) {
      if (item.note[key]) return { title: "nota: " + item.note[key] };
      return {};
    },
    updateSchedaInStore(scheda) {
      this.$store.dispatch(this.storeModule +  "/setScheda", {
        idScheda: this.idScheda,
        scheda: scheda,
      });
      if(this.componentConfig.notifyUpdate) {
        this.$emit('refreshValues');
      }
    },
    formatter(value, key) {
      const mapElement = this.mappa[key];
      if(!mapElement) {
            return value;
      }
      return utils.formatOutput(mapElement, value);
    },


    setupFields() {
      this.fieldIds = [];
      this.headerIds = [];
      for (const colId of this.componentConfig.dinamicIds) {
        const mapElement = this.mappa[colId];
        if (!mapElement) {
          console.error(this.title, 'dinamic field not found in mappa', colId);
          continue;
        }
        this.fieldIds.push(colId);
      }
      if(this.componentConfig.tableIds) {
        for (const colId of this.componentConfig.tableIds) {
          const mapElement = this.mappa[colId];
          if (!mapElement) {
            console.error(this.title, 'table field not found in mappa', colId);
            continue;
          }
          this.headerIds.push(colId);
        }
      }
    },

    setupHeader(ids) {
      this.tabledata.header = [];
      // setup header
      for (const colId of ids) {
        let mapElement = this.mappa[colId];
        if (!mapElement) {
          console.error(this.componentConfig.name, "no config, get default one for ", colId);
          mapElement = this.$getDefaultConfig();
          mapElement.config.path = colId;
        }
        const headerColumn = {
          key: colId,
          label: mapElement.config.label,
          tdAttr: this.tooltip,
        };
        this.tabledata.header.push(headerColumn);
      }
      this.tabledata.header.push({ key: "Azioni", label: "Azioni" });
    },

    extractData() {
      
      if (!this.mappa) {
        console.error(this.componentConfig.name, "Invalid mappa", this.mappa);
        return;
      }

      const schedaTemp = this.getScheda({
        idScheda: this.idScheda,
      });
      let scheda = tool.genericFunctions.cloneObject(schedaTemp);
      
      if (!scheda) {
        console.error(this.componentConfig.name, "Invalid scheda", this.idScheda, scheda);
        return;
      }

      let ids = this.fieldIds;
      if(this.headerIds.length)
         ids = this.headerIds;

      if(!ids || ids.length === 0) {
        console.error(this.componentConfig.name, "Invalid ids", 
          this.headerIds, this.fieldIds);
        return;
      }

      this.tabledata.rows = [];
      this.tabledata.header = [];
      // setup header
      for (const colId of ids) {
        let mapElement = this.mappa[colId];
        if (!mapElement) {
          console.error(this.componentConfig.name, "no config, get default one for ", colId);
          mapElement = this.$getDefaultConfig();
          mapElement.config.path = colId;
        }
        const headerColumn = {
          key: colId,
          label: mapElement.config.label,
          tdAttr: this.tooltip,
          formatter: this.formatter,
          sortable: true
        };
        this.tabledata.header.push(headerColumn);
      }
      this.tabledata.header.push({ key: "Azioni", label: "Azioni" });
      this.sortBy = this.tabledata.header[0].key;

      // referenceId è l'id di riferimento per determinare i valori in tabella:
      // ad esempio per inventario è "['inventario'][*]['content']['beneAcquisito']",
      // corrispondente a refId = 2 (terza colonna della tabella)
      // a partire da questo viene determinata la root di riferimento ("inventario")
      // e si costruisce l'array di id effettivi di inventario, uno per ogni riga
      // const referenceId = this.componentConfig.dinamicIds[this.componentConfig.refId];
      // this.rootId = referenceId.match(/\w+/)[0];
      // idArray è un array di stringhe, ciascuna corrispondente all'id
      // di una riga (ad es. una riga di attività o di inventario)
      let idArray = Object.keys(scheda[this.componentConfig.rootId]);

      // add rows to table
      let updateScheda = false;
      this.tabledata.rows = [];
      // per tutte le righe da inserire nella tabella costruisco il rowobj
      for (const rowId of idArray) {
        let booleanComputedHiddenRow;
        let rowObj = {
          _cellVariants: {},
          Azioni: [],
          rowId: rowId
        };
        this.relRootArray = [];
        for (const colId of this.fieldIds) {
           
          let id;
          // per ogni colonna della tabella, se il colId corrispondente contiene
          // il rootId (ad es. "inventario") allora prendo l'identificativo da idArray,
          // altrimenti devo prenderlo dalla mappa di relatives
          if(colId.includes(this.componentConfig.rootId)) {
            id = colId.replace('*', "'" + rowId + "'");
          }
          else {
            // qui prendo l'identificativo dalla mappa di relatives
            const relMap = scheda[this.componentConfig.rootId][rowId].rel;
            // da colId isolo la radice del relative
            // ad es., per la colonna idAttivita è "attivita"
            const relRootId = colId.match(/\w+/)[0];
            // se nella mappa di relatives c'è questa radice, allora prendo
            // per convenzione il primo indice (zero) dell'array
            const relArray = relMap[relRootId];
            
            if(relArray && relArray.length > 0) {
              const relArrayId = relArray[0];
              // l'id da cui cercare il valore si ottiene sostituendo nel colId
              // l'identificativo pescato dai relatives
              id = colId.replace('*', "'" + relArrayId + "'");
            } else {
              // se non trovo un "papà", passo avanti
              console.log('no relative for colId', colId, relRootId, relArray);
              continue;
            }
          }
          let mapElement = this.mappa[colId];
          if (!mapElement) {
            console.error(this.componentConfig.name, "no config, get default one for ", colId);
            mapElement = this.$getDefaultConfig();
            mapElement.config.path = colId;
          }
          if(mapElement.config.hiddenRowRule){
             booleanComputedHiddenRow=visibility.computeHiddenRow(this.title, id, mapElement, scheda);
            if(booleanComputedHiddenRow){
              break;
            }
            
          }
          // il valore viene calcolato automaticamente o ricavato dalla scheda
          let retVal = this.getValue(colId, id, mapElement, scheda);
          scheda = retVal.scheda;
          const result = retVal.result;
          updateScheda = updateScheda || retVal.updateScheda;
          // setup della riga
          rowObj = mapUtils.setupComponentRow2(
            rowObj,
            id,
            mapElement,
            result,
            scheda,
            this.componentConfig.defaultActions,
            this.componentConfig.allowedActions,
            this.optionalObj
          );
        }
        if(booleanComputedHiddenRow){
          continue;
        }
          this.tabledata.rows.push(rowObj);
            
         
      
      }
      // la scheda viene aggiornata se ci sono state modifiche
      if (updateScheda) {
        this.updateSchedaInStore(scheda);
      }
    },

    getValue(colId, id, mapElement, scheda) {
        let result;
        let updateScheda = false;
        const results = jpath("$" + id, scheda);
        let retVal;
        // se il campo non esiste nella scheda viene creato
        // e si aggiorna la scheda
        if (results.length === 0) {
            // stampo un log solo se il valore non deve essere calcolato
            // altrimenti è probabile che esso non sia presente nella scheda
            //if(!mapElement.config.calculationRule) {
            //  console.log(this.componentConfig.name, 'getValue: valore non trovato in scheda: ', id);
            //}
            // qui è contemplato il caso di un relative non definito (ad esempio un pagamento sfuso)
            if(id.includes(mapUtils.nonDefinito.key)) {
              result = 'Non definito/a';
            } else {
              // altrimenti, l'elemento viene creato da zero
              retVal = mapUtils.createElementFromScratch(
                id,
                scheda,
                this.mappa
              );
              scheda = retVal.scheda;
              result = retVal.object;
              updateScheda = true;
            }
        } else if (results.length === 1) {
            result = results[0];
        } else {
            console.error(this.componentConfig.name, "is an array?", colId, id, results);
        }

        // a prescindere dal fatto che sia presente in scheda o meno, 
        // se è prevista una calculation rule allora il campo
        // viene ricalcolato e il result viene sovrascritto
        // qui si inserisce il valore calcolato se previsto dal tabellone
        if(mapElement.config.calculationRule) {
            result = calculation.calculateValue(mapElement, scheda, id, null, this.optionalObj);
            const results1 = jpath({resultType: 'all'}, "$" + id, scheda);
            if(!results1 || results1.length !== 1) {
              console.error(this.componentConfig.name, '-> id per calculation non trovato', id);
            } else {
              const parent = results1[0].parent;
              const fieldName = results1[0].parentProperty;
              parent[fieldName] = result;
              updateScheda = true;
            }
        }

        return {
          scheda: scheda,
          result: result,
          updateScheda: updateScheda
        }
    },

    findByRowId(rowId) {
      let res;
      let count = 0;
      for(let i = 0; i < this.tabledata.rows.length; i++) {
        const ele = this.tabledata.rows[i];
        if(ele.rowId === rowId) {
          count++;
          res = {
            item: ele,
            index: i
          }
        }
      }
      if(count !== 1) {
        notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
        return null;
      }
      return res;
    },

    updateSchedaInDeletedMode(rowItem) {

      const item = rowItem.item;
      const index = rowItem.index;

      const scheda = this.getScheda({
        idScheda: this.idScheda,
      });
      let clonedScheda = tool.genericFunctions.cloneObject(scheda);

      
      //WARNING: assumo che la prima colonna mostrata contenga le info su oggetto visualizzato e relativo id;
      // se questo caso non è il più generale bisognerà passare un'altra prop che indica quale
      // path assoluto contiene queste informazioni
      // rimuovo l'elemento dalla tabella
      this.tabledata.rows.splice(index, 1);
      const idToDelete = item.rowId;
      // rimuovo l'elemento dalla scheda
      delete clonedScheda[this.componentConfig.rootId][idToDelete];
      // rimuovo i riferimenti nel tabellone
      if(!clonedScheda.dataEntryConfiguration) {
        clonedScheda.dataEntryConfiguration = {};
      }

      const relIds = Object.keys(this.componentConfig.relIds);
      Object.keys(item).forEach(key =>{
        const realKey = key.replace(/\*/, "'"+ idToDelete + "'");

        
        if(key.indexOf(this.componentConfig.rootId) !== -1 && !relIds.includes(key)) {
          if(!clonedScheda.dataEntryConfiguration[realKey]) {
            clonedScheda.dataEntryConfiguration[realKey] = {
              data: {
                editTag: mapUtils.createEditTagCronoProg(scheda, this.mappa, realKey, this.componentConfig.rootId, this.componentConfig.title)
              }
            }
          }
          clonedScheda.dataEntryConfiguration[realKey].data.deleted = true
        }
      });
      this.updateSchedaInStore(clonedScheda);
    },
    getScheda() {
      return this.$store.getters[this.storeModule + "/getScheda"]({
        idScheda: this.idScheda,
      });
    },

    editRiga(actionFromModal) {
      this.$bvModal.hide("modalForm1-" + this.componentConfig.name);
      let rowObj = this.tabledata.rows[this.editLine];
      const schedaTemp = this.getScheda({
        idScheda: this.idScheda,
      });
      let scheda = schedaTemp;
      if (!rowObj) {
        rowObj = {
          _cellVariants: {},
          rowId: actionFromModal.rowId
        };

        scheda = tool.genericFunctions.cloneObject(schedaTemp);
        scheda[this.componentConfig.rootId][actionFromModal.rowId] = {
          id: actionFromModal.rowId
        };
      }
      // se sono cambiati i relatives (ad es. se prima non c'erano)
      // allora li aggiorno nella scheda
      if(actionFromModal.rel) {
        scheda[this.componentConfig.rootId][actionFromModal.rowId].rel = actionFromModal.rel;
      }
      const retVal = mapUtils.updateComponent2(
        actionFromModal,
        scheda,
        rowObj,
        this.mappa,
        this.componentConfig,
        this.optionalObj,
      );

      const clonedScheda = retVal.clonedScheda;

      this.updateSchedaInStore(clonedScheda);
      this.extractData();
    },
    computeDependencies() {
      // se non ci sono dipendenze da relative, si apre subito la modale di edit
      // per la nuova riga
      if(!tool.objectHasValue(this.componentConfig.relIds)) {
        console.log(this.componentConfig.name, 'computeDependency: no deps');
        this.addRiga();
        return;
      }
      // se ci sono dipendenze da relative, ma sono tutte gestibili dentro la modale di edit
      // allora si apre subito la modale di edit per la nuova riga
      const shouldOpenModal = Object.values(this.componentConfig.relIds).filter(item => {
        return !item.allowModalEdit;
      });

      if(!shouldOpenModal.length) {
        console.log(this.componentConfig.name, 'computeDependency: no need to open extra modal');
        this.addRiga();
        return;
      }

      // altrimenti si apre una pre-modale per scegliere i relatives
      this.formButtons = [
        {
          name: "Aggiungi",
          action: "editField",
          show: true,
          param: "add"
        },
      ];
      this.formCallBack = this.addRiga;
      const scheda = this.getScheda();
      this.editFormData = { content: {}, conf: {}, idScheda: this.$route.params.idControllo};
      // per ogni relative aggiungo una enum
      for(const [relId, relIdObj] of Object.entries(this.componentConfig.relIds)) {
        // se per un dato relative è previsto che sia modificata nella modale di edit,
        // allora non la includiamo nella pre-modale
        if(relIdObj.allowModalEdit)
          continue;
        // ogni enum è obbligatoria e senza nota
        let mapElement = tool.genericFunctions.cloneObject(this.mappa[relId]);
        mapElement.config.suppressNote = true;
        mapElement.config.readonly = false;
        mapElement.config.mandatory = true;
        mapElement.config.type = 'enum';
        mapElement.config.validationRule = '';
        //Se ho espressamente indicato il nome del tab lo uso, altrimenti uso il titolo
        mapElement.config.tab = this.componentConfig.tabName ? this.componentConfig.tabName : this.componentConfig.title;
        const results = jpath({resultType: 'all'}, '$' + relId, scheda);
        let enumValues = [];
        // se è ammesso un relativo non obbligatorio (ad es. pagamenti sfusi) allora
        // aggiungo una voce di default al dropdown
        if(relIdObj.canBeEmpty) {
          enumValues.push({
            label: mapUtils.nonDefinito.value,
            value: mapUtils.nonDefinito.key
          });
        }
        for(const res of results) {
          const val = res.value;
          if(val == null || val == undefined || val.toString().trim().length === 0) {
            continue;
          }
          // ogni riga della tendina ha come label il valore di rel trovato
          // nella scheda e come value l'id, ricavato supponendo che si trovi
          // nel secondo elemento del path (es. "['inventario']['blablabla']['content']['beneAcquisito']")
          enumValues.push({
            label: res.value,
            value: res.path.match(/\w+/g)[1]
          });
        }
        mapElement.config.enumValues = enumValues;
        mapElement.data = this.$getDefaultData();
        this.editFormData.conf[relId] = mapElement;
        this.editFormData.content[relId] = {id: relId, value: ''};
      }
      this.modalTitle = 'Inserisci dati';
      this.$bvModal.show("modalForm1-" + this.componentConfig.name);
    },
    // l'aggiunta di una riga ha tre modalità:
    // caso 1) non ci sono relatives: in questo caso si apre una normale modale;
    // caso 2) ci sono realtives prioritari: in questo caso si apre una prima modale
    // per decidere i relatives, poi se ne apre una seconda per inserire i dati,
    // dove i relatives definiti nella modale precedente sono ora readonly;
    // caso 3) ci sono relatives non prioritari: in quest'ultimo caso
    // si apre la normale modale, in cui i relatives possono essere scelti
    // con una tendina
    addRiga(actionFromModal) {
      const newId = tool.genId();
      this.editFormData = { content: {}, conf: {}, rel: {},
                            idScheda: this.$route.params.idControllo, rowId: newId};                 
      this.editLine = this.tabledata.rows.length;

      // console.log('nuovo id', newId);
      // caso 2) se l'add riga è chiamato dalla modale che stabilisce i relatives,
      // allora aggiungo l'oggetto "rel" a editFormData.
      // Tale oggetto contiene i relatives definiti nella prima modale di aggiunta
      if(actionFromModal) {

        for(const [key, relObj] of Object.entries(this.componentConfig.relIds)) {
          if(relObj.allowModalEdit)
            continue;
          // il relative è uguale al value della enum scelta nella prima modale
          const valueFromEnum = [actionFromModal.content[key].value];
          this.editFormData.rel[relObj.type] = valueFromEnum;
        }
        this.$bvModal.hide("modalForm1-" + this.componentConfig.name);
      } 
      // poichè la modale può essere aperta sia per scegliere i relatives
      // (ad es. id attività), sia per aggiungere/modificare effettivamente i dati,
      // allora i pulsanti e la funzione chiamata alla chiusura della modale sono
      // diversi nei due casi. In questo caso i pulsanti sono quelli di default
      // e la funzione chiamata è editRiga.
      this.formButtons = this.buttons;
      this.formCallBack = this.editRiga;

      const scheda = this.getScheda();
      for (const colId of this.fieldIds) {
        let correctId = newId;

        let mapElement = tool.genericFunctions.cloneObject(
          mapUtils.getMapElement(this.mappa, colId)
        );
        // replace path with correct id not possible
        // mapElement.config.path = colId;
        mapElement.data = this.$getDefaultData();
        if(!mapElement.config.readonly || mapElement.config.calculationRule) {
          mapElement.data.added = true;
        }
        let id = colId.replace('*', "'" + correctId + "'");
        let value = null;
        let isRelId;

        if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
            mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda);
        }
        // automatic value calculation
        if (mapElement.config.calculationRule) {
          value = calculation.calculateValue(mapElement, scheda, colId, null, this.optionalObj);
        }
        else if (actionFromModal) {
          // caso 2)
          // se proveniamo dalla modale di scelta dei relatives dobbiamo aggiornare tutti 
          // i campi che dipendono dai relatives
          if(Object.keys(this.componentConfig.relIds).includes(colId)) {
            const editNextModal = this.componentConfig.relIds[colId].allowModalEdit;
            if(!editNextModal){
              correctId = actionFromModal.content[colId].value;
              id = colId.replace('*', "'" + correctId + "'");
            }
          }
          for(const [relId, relObj] of Object.entries(this.componentConfig.relIds)) {
            // questa costante è l'id effettivo del relative
            if(relObj.allowModalEdit)
              continue;
            const valueFromEnum = [actionFromModal.content[relId].value];
            // qui aggiorno le dipendenze dai relatives
            const matchStr = "^\\['" + relObj.type;
            
            // se l'id della colonna inizia con il relative, ad es. con "['attivita", allora 
            // sostituisco nell'asterisco del colId il vero id del relative e cerco il value
            // dell'elemento nella scheda, ad es. il valore di idAttivita o titoloAttivita
            if(colId.match(matchStr)) {
              const searchStr = '$' + colId.replace('*', "'" + valueFromEnum + "'");
              const results = jpath(searchStr, scheda);
              if(results && results.length === 1) {
                value = results[0];
              }
            }
          }
        }
        if(this.componentConfig.relIds && this.componentConfig.relIds[colId] && this.componentConfig.relIds[colId].allowModalEdit) {
          // caso 3)
          // qui si prepara il menu a tendina per scegliere il relative
          
          const defaultVal = {id: id, value: value, isRelId: 'new'};
          const dropdown = this.prepareRelIdDropdown(id, defaultVal, mapElement, scheda);
          id = dropdown.val.id;
          value = dropdown.val.value;
          isRelId = dropdown.val.isRelId;
          mapElement = dropdown.mapElement;
          
        }

        const val = { id: id, value: value, isRelId: isRelId };
        this.editFormData.conf[id] = mapElement;
        this.editFormData.content[id] = val;

      }
      this.modalTitle = 'Aggiungi dati';
      this.$bvModal.show("modalForm1-" + this.componentConfig.name);
    },

    doAction(actionToDo, data) {
      let index = -1
      let item = {}
      let rowId = '';
      let res = null;
      if(data) {
        rowId = data.item.rowId;
        res = this.findByRowId(rowId);
        if(!res) 
          return;
        index = res.index;
        item = res.item;
      }
      let delMsg = "Si sta per eliminare definitivamente un elemento. "
      delMsg = delMsg.concat("L'elemento eliminato non comparirà tra le modifiche e l'operazione non è reversibile. Procedere?");
      switch (actionToDo) {
        
        case "add":
          // questa funzione stabilisce se ci sono dipendenze dai relatives ed eventualmente
          // apre una modale che consente di settarle prima di passare alla modalform
          // di edit degli altri dati
          this.computeDependencies();
          break;

        case "delete":
          this.$bvModal.msgBoxConfirm(delMsg, {
            title: "Conferma eliminazione",
            okVariant: 'success',
            cancelVariant: 'danger',
            okTitle: 'Elimina',
            cancelTitle: 'Chiudi',
            titleClass: 'card-title',
            dialogClass: 'card-body'
          })
          .then(value => {
            if(value) {
                this.updateSchedaInDeletedMode(res);
              }
          })
          break;
        
        case "edit":
          {
            this.formButtons = this.buttons;
            this.formCallBack = this.editRiga;
            this.editLine = index;
            const scheda = this.getScheda();
            const row = item;
            this.editFormData = { content: {}, conf: {}, idScheda: this.$route.params.idControllo, rowId: rowId };
            const retArray = this.prepareViewOrEdit(actionToDo, row, scheda);
            for (const retVal of retArray) {
              const val = retVal.val
              const id = val.id;
              const mapElement = retVal.mapElement;
              this.editFormData.conf[id] = mapElement;
              this.editFormData.content[id] = tool.genericFunctions.cloneObject(val);
            }
            this.modalTitle = 'Modifica dati';
            this.$bvModal.show("modalForm1-" + this.componentConfig.name);
          }
          break;
        case 'view':
          {
            this.editLine = index;
            const scheda = this.getScheda();
            const row = item;
            this.modalData = {};
            let modalRows = [];
            const retArray = this.prepareViewOrEdit(actionToDo, row, scheda);
            for (const retVal of retArray) {
              const val = retVal.val;
              const id = val.id;
              const value = val.value;
              const mapElement = retVal.mapElement;
                let modalRow = { 
                  content: {
                      Campo : mapElement.config.label,
                      Valore : value,
                  },
                  conf: {} 
                };
                modalRow.conf[id] = mapElement;
                modalRows.push(modalRow);
            }

            this.modalData = {
              header : ['Campo', 'Valore'],
              rows: modalRows
            }
            this.$bvModal.show("modalData-" + this.componentConfig.name);
          }
          break;
        default:
          this.editFormData = {};
          break;
      }
    },
    prepareViewOrEdit(action, row, scheda) {
      let retArray = [];
      for (const column of this.fieldIds) {
        // skip non consistent info
        if (!column.match(this.isIdRegex)) {
          continue;
        }

        let id;
        if(row[column]){
          id=row[column].id;
        } else {
          id = column.replace('*', "'" + mapUtils.nonDefinito.key + "'");
        }        
        
        let mapElement = tool.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, column));
        // skip hidden values
        if(mapElement.config.hiddenRule) {
          const hidden = visibility.computeHidden(id, scheda, mapElement);
          if(hidden)
            continue;
        }
        const dataEntry = scheda.dataEntryConfiguration;
        // ricava la parte "data" dalla scheda o ne crea una di default
        if (dataEntry && dataEntry[id] && dataEntry[id].data) {
          mapElement.data = tool.genericFunctions.cloneObject(
            dataEntry[id].data
          );
        } else {
          mapElement.data = this.$getDefaultData();
        }

        let val = { id: id, value: null};
        // calcolo il valore da mostrare, oppure lo ricavo dalla scheda
        // WARNING: in caso di edit, questo valore potrebbe essere sovrascritto
        // se appartenente ad un select di un rel
        if (mapElement.config.calculationRule) {
          val.value = calculation.calculateValue(mapElement, scheda, id, null, this.optionalObj);
        }
        else {
          const values = jpath("$" + id, scheda);
          if (values.length === 0) {
            // se il valore non è presente in scheda, allora potrebbe essere 
            // un relative non obbligatorio (es. pagamento sfuso):
            // in questo caso inserisco la label di default
            if(id.includes(mapUtils.nonDefinito.key)) {
              val.value = mapUtils.nonDefinito.value;
            }
            else
              console.error(this.componentConfig.name, "prepareViewOrEdit: valore non trovato in scheda: ", id);
          } else {
            val.value = values[0];
          }
        }

        if(action === 'edit') {
          // calcolo automatico degli enumValues, la funzione è definita nel tabellone
          if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
            // WARNING: questo controllo presuppone che tutte le enum calcolate diventino
            // readonly in modifica; se non è così bisogna pensare ad un'alternativa (readonlyRule?)
            // 23-03-21: WARNING: commento questa riga per consentire la modifica delle tipologie di procedura
            // mapElement.config.readonly = true;
            mapElement.config.enumValues = calculation.calculateEnum(mapElement, this.getScheda());
          }
          else if(this.componentConfig.relIds) {
            // qui crea il dropdown per i relatives della modale di edit
            const dropdown = this.prepareRelIdDropdown(id, val, mapElement, scheda);
            val = dropdown.val;
            mapElement = dropdown.mapElement;
          }
        }
        else if(action === 'view' && mapElement.config.type === 'flag') {
          // WARNING: codice cablato per i soli flag di pagamenti
          // questo codice serve a mostrare il flag nella modale di view ("occhio")
          // nel caso serva applicare il type flag a più casi, allora bisognerà
          // studiare un modo per generalizzarlo
          const flagResults = jpath('$' + id, scheda);
          // di default il pagamento non è inserito nella dichiarazione di spesa corrente
          val.value = 'NO';
          if(flagResults && flagResults.length > 0) {
            const flagVal = flagResults[0];
            // se il valore del flag coincide con l'id della dichiarazione di spesa corrente, allora
            // il pagamento è riferito a questa dichiarazione e verrà visualizzata la stringa 'SI'
            if(flagVal === this.$route.params.idControllo)
              val.value = 'SI';
          }
        }
        
        let retVal = {
          val: val,
          mapElement: mapElement
        }
        retArray.push(retVal);

      }
      return retArray;
    },
    // questa funzione prepara la tendina per la scelta del relative
    prepareRelIdDropdown(id, val, mapElement, scheda) {
      const relIdPath = mapElement.config.path;
      const relIdObj = this.componentConfig.relIds[relIdPath];
      if(!relIdObj || !relIdObj.allowModalEdit) {
          return {
          val: val,
          mapElement: mapElement
        }
      }
      // nel caso particolare in cui si può modificare un relative, allora
      // passo l'id del rel con gli asterischi e una variabile
      // che indica che si tratta proprio di un rel. Per questi campi
      // è necessario un trattamento particolare anche su editRiga
      // perchè bisogna settare solo il rel e non modificare niente del resto della scheda
      val.id = relIdPath;

      if(id.includes(mapUtils.nonDefinito.key)) {
        val.value = mapUtils.nonDefinito.value
      }
      
    
      mapElement.config.suppressNote = true;
      mapElement.config.readonly = false;
      mapElement.config.mandatory = true;
      mapElement.config.type = 'enum';
      mapElement.config.validationRule = '';
      mapElement.config.tab = this.componentConfig.title;
      const results = jpath({resultType: 'all'}, '$' + relIdPath, scheda);
      let enumValues = [];
      
      if(relIdObj.canBeEmpty) {
        enumValues.push({
          label: mapUtils.nonDefinito.value,
          value: mapUtils.nonDefinito.key
        });
      }
      for(const res of results) {
        const foundValue = res.value;
        // skip dei valori vuoti (ad es., contratti non valorizzati)
        if(foundValue == null || foundValue == undefined || foundValue.toString().trim().length === 0) {
          continue;
        }
        // ogni riga della tendina ha come label il valore di rel trovato
        // nella scheda e come value l'id, ricavato supponendo che si trovi
        // nel secondo elemento del path (es. "['inventario']['blablabla']['content']['beneAcquisito']")
        const option =  {
          label: res.value,
          value: res.path.match(/\w+/g)[1]
        }
        enumValues.push(option);
        // se la label coincide con il valore corrente nella scheda,
        // allora setto il value uguale all'id del relative
        // inoltre lo ridondo dentro isRelId, in modo da sapere qual'era il vecchio
        // rel, di cui farò lo splice nella editRiga
        if(option.label === val.value) {
          val.value = option.value;
          val.isRelId = option.value;
        }
      }
      // se è selezionato il valore non definito, lo setto prima del rendering della modale
      if(id.includes(mapUtils.nonDefinito.key)) {
        val.isRelId = mapUtils.nonDefinito.key;
        val.value = mapUtils.nonDefinito.key;
      }

      mapElement.config.enumValues = enumValues;

      return {
        val: val,
        mapElement: mapElement
      }
    }
  },
};
</script>
