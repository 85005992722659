<template>
    <div>
        <b-container>
            <b-alert :show="getDeletedFiles.length > 0" variant="warning">
                <div style="text-align: center;">
                <p><strong>ATTENZIONE! Questi sono i files eliminati dalla scheda</strong></p>
                <ul>
                    <li style="list-style: square;" v-for="item in getDeletedFiles" :key="item.fileId">
                    {{ item.text }}
                    </li>
                </ul>
                </div>     
            </b-alert>
            <Collapse :name="'Campi obbligatori non valorizzati (' + this.obbligatoriTable.rows.length + ')'">
                <b-table-lite
                :id="'mandatory_irregolarita'"
                thead-class="head"
                :items="this.obbligatoriTable.rows"
                :fields="this.obbligatoriTable.header" />
            </Collapse>
            <Collapse :name="'Note (' + note.length + ')'">
                <br />
                <TableNotes :id="'note_irregolarita'" v-if="note.length > 0" :notes="note"> </TableNotes>
                <h4 v-else>
                    Nessuna nota da mostrare
                </h4>
            </Collapse>
            <Collapse
                v-if="isVisibleReservedNote"
                :name="'Elenco Note Interne (' + fillNoteRiservate.length + ')'">
                <br />
                <div class="note" v-if="isSend">
                <br>
                    <b-form-textarea
                    id="textAreaNoteRiservate"
                    :disabled="disableButton"
                    v-model="noteRiservate"
                    placeholder="Inserire qui le note interne"/>
                </div>
                <div class="pulsanti-invio">
                <b-button :id="'cancelNoteRiservate_controlli'"
                    v-if="isSend"
                    class="mx-3 btnReservedNote"
                    :disabled="disableButton"
                    variant="danger"
                    @click="resetReservedNote()"
                    >Cancella Nota Interna</b-button>
                </div>
                <TableNotes
                :id="'note_riservate_controlli'"
                v-if="fillNoteRiservate.length > 0" :notes="fillNoteRiservate">
                </TableNotes>
                <h4 v-else>
                Nessuna nota da mostrare
                </h4>
            </Collapse>
            <b-row class="mt-2" v-if="isSend">
                <b-col class="text-right">
                    <label for="textarea-note">Nota:</label>
                </b-col>
                <b-col sm="8">
                    <b-form-textarea
                        v-model="notaTemp"
                        id="textarea-note"
                        size="lg"
                        :disabled="disableButton"
                        placeholder="Inserire qui le note"/>
                </b-col>
                <b-col sm="2">
                    <b-button :id="'cancelNote'" variant="outline-secondary" :disabled="disableButton" @click="cancelNote()">
                        Cancella Nota
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="3" />
                <b-col><h4><strong>Segnalazione accettata:</strong></h4></b-col>
                <b-col>
                <strong>
                <b-radio-group
                    id="esitoFinaleRadioGroup"
                    size="lg"
                    :disabled="isEsitoFinaleDisabled"
                    v-model="esitoFinale">
                    <b-form-radio id="segnalazioneSi" class="esitoRadioButton" size="lg" style="font-size:14px;" v-bind:value="true">Si</b-form-radio>
                    <b-form-radio id="segnalazioneNo" class="esitoRadioButton" size="lg" style="font-size:14px;" v-bind:value="false">No</b-form-radio>
                </b-radio-group>
                </strong>
                </b-col>
                <b-col cols="2" />
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button
                        :id="'export_pdf'"
                        size="lg"
                        variant="secondary"
                        class="mx-2"
                        @click="preparePdf">
                        Export PDF
                    </b-button>
                    <b-button
                        v-if="isSaveCommenti"
                        :id="'save'"
                        size="lg"
                        variant="primary"
                        class="mx-2"
                        :disabled="disableButton"
                        @click="saveScheda('save_comments')">
                        Salva Commenti
                    </b-button>
                    <b-button
                        v-if="isSave"
                        :id="'save'"
                        size="lg"
                        variant="primary"
                        class="mx-2"
                        :disabled="disableButton"
                        @click="saveScheda('draft')">
                        Salva in Bozza
                    </b-button>
                    
                    <b-button
                        v-if="isSend"
                        :id="'send'"
                        size="lg"
                        variant="success"
                        class="mx-2"
                        :disabled="disableButton || disableBtnSend"
                        @click="setValueModal('complete')">
                        Salva e Invia
                    </b-button>
                    
                    <b-button
                        v-if="isReject"
                        :id="'reject'"
                        size="lg"
                        variant="danger"
                        class="mx-2"
                        :disabled="disableButton"
                        @click="setValueModal('reject')">
                        Rifiuta
                    </b-button>
                    <b-button
                        v-if="isDelete"
                        :id="'deleteIrregolarita_irr'"
                        size="lg"
                        variant="danger"
                        class="mx-2"
                        :disabled="disableButton"
                        @click="setValueModal('delete')">
                        Elimina Irregolarità
                </b-button>
                </b-col>
            </b-row>
             <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
           
        </b-container>
    </div>
</template>

<script>

import Collapse from "@/components/collapse.vue"
import TableNotes from "../bandi/tableNotes.vue";
import endpoints from "@/components/endpoints.vue"
import notify from "@/helpers/notifications.js"
import { JSONPath as jpath } from 'jsonpath-plus'
import fileHelper from '@/helpers/allegati.js'
import tools from '@/helpers/tools.js'
import { mapGetters } from "vuex"
import helper_pdf from "@/helpers/exportPdf.js"

export default {
    name: "Invio",
    components: {
        Collapse,
        TableNotes,
    },
    props: {
        tabName: { type: String, default: 'Sconosciuto', required: true },
        actions: { type: Array, default: () => [], required: true },
        config: { type: Object, default: () => {}, required: true },
        idsToCheck: { type: Array, default: () => [] },
    },
    data() {
        return {
            schedaNoteRiservate: null,
            noteRiservate: "",
            name: "Invio",
            scheda: {},
            taskInfo: {},
            codiceProgetto: "",
            idScheda: "",
             modal1Map:{
            complete:{
                title:"Conferma Irregolarità", text:"Conferma di voler Inviare questa Irregolarità ? "
            },
            
            reject:{
                title:"Rifiuta Irregolarità", text:"Conferma di voler Rifiutare questa Irregolarità?"
            },
             delete:{
                title:"Cancella Irregolarità", text:"Conferma di voler Cancellare questa Irregolarità?"
            },
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",
            esitoFinale: null,
            deletedFiles: [],
            disableButton: false,
            note: [],
            notaTemp: "",
            // showDeleteModal: false,
            obbligatoriTable: {
                header: [
                {
                    key: "Tab"
                },
                {
                    key: "Campo",
                    label: "Nome Campo"
                }
                ],
                rows: []
            },        
        }
    },
    watch: {
        tabName: function() {
            if(this.tabName === this.name) {
                this.deletedFiles = []
                this.refreshMandatoryFields()
            }
        }
    },
    computed: {
        ...mapGetters({
            getScheda: "irregolarita/getScheda",
            getAllegatiDaCancellare: "irregolarita/getAllegatiDaCancellare",
            getNoteRiservate: "irregolarita/getSchedaNoteRiservate"
        }),
        retrieveTaskInfo(){
            return this.getCurrentScheda().content.taskInfo
        },
        getDeletedFiles() {
        let unique = this.deletedFiles.reduce((unique, o) => {
            if(!unique.some(obj => obj.fileId === o.fileId)) {
                unique.push(o)
            }
            return unique
        },[])
        return unique
        },
        isSend(){
            return this.actions.indexOf('send') !== -1;
        },
        isSave() {
            return this.actions.indexOf("save") !== -1;
        },
        isSaveCommenti() {
            return this.actions.indexOf("save_comments") !== -1;
        },
        isReject() {
            return this.actions.indexOf("reject") !== -1;
        },
        isDelete() {
            return this.actions.indexOf("delete") !== -1;
        }, 
        isEsitoFinaleDisabled() {
            return this.actions.indexOf('editEsito') === -1
        },
        disableBtnSend() {
            return (this.obbligatoriTable.rows.length > 0)
        },
        isVisibleReservedNote() {
            let userInfo = this.$getUserInfo();
            let group = userInfo.groups[0];
            return group.includes("MINT") && this.schedaNoteRiservate;
        },
        fillNoteRiservate() {
            return this.schedaNoteRiservate.content?.noteRiservate || [];
        },
    },
    mounted() {
        this.codiceProgetto = this.$route.params.codiceProgetto;
        this.idScheda = this.$route.params.idControllo
        this.scheda = this.getCurrentScheda();
        if(this.scheda){
            
            this.taskInfo = this.scheda.content.taskInfo;
            if(this.scheda.content.note || this.scheda.content.notaTemporanea) {
                this.note = this.scheda.content.note && this.scheda.content.note.length > 0 ? this.scheda.content.note : [];
                this.notaTemp = this.scheda.content.notaTemporanea.nota
            }

            let esitoFin = this.scheda.content.esitoFinale
            this.esitoFinale = esitoFin ? esitoFin : false
            this.updateEsitoInStore()
            this.refreshMandatoryFields()
            // NOTE RISERVATE
            this.schedaNoteRiservate = this.getNoteRiservate;
            if (this.scheda.content?.notaTemporaneaRiservata) {
                this.noteRiservate = this.scheda.content.notaTemporaneaRiservata;
            }
        }
    },
    methods: {
        resetReservedNote() {
            this.noteRiservate = ""
        },
        updateEsitoInStore() {
            let scheda = this.getCurrentScheda()
            scheda.content.esitoFinale = this.esitoFinale
            this.updateSchedaInStore(scheda)
        },
        preparePdf() {
            this.updateEsitoInStore()
            let contentScheda = this.getCurrentScheda().content
            let typeScheda = 'Irregolarità'
            let optPdf = helper_pdf.handleNamingPdf(contentScheda, typeScheda)
            console.log(optPdf)
            this.$emit('buildPdf', optPdf)
        },
        executeDeleteAction() {
            this.$bvModal.hide('deleteIrregolarita');
            this.disableButton = true;
            const url = endpoints.deleteIrregolaritaByID(this.codiceProgetto, this.idScheda)
            this.$delete(url).then(
            result => {
                console.log(result)
                notify.success(notify.strings.success, notify.strings.saveIrregolaritaOk);
                this.$router.push({ name: "secure" });
                this.disableButton = false
            }).catch(
                (error) => {
                console.error("errore nell'eliminazione dell'irregolarità", error);
                notify.error(
                    notify.strings.error,
                    notify.strings.deleteError('Processo di Irregolarità')
                )
                this.disableButton = false;
            });
        },
        refreshMandatoryFields() {
            this.obbligatoriTable.rows = []
            let scheda = this.getCurrentScheda()
            if(this.idsToCheck.length > 0 && Object.keys(this.config).length > 0) {
                for(const id of this.idsToCheck) {
                let mapElement = this.config[id]
                    if (mapElement && mapElement.config && mapElement.config.mandatory) {
                        let values = jpath('$'+ mapElement.config.path, scheda.content)
                        if(values && values.length === 0 || values[0] == null || values[0] === "") {
                            if(mapElement.config.label && mapElement.config.tab) {
                                this.obbligatoriTable.rows.push({
                                Campo: mapElement.config.label,
                                Tab: mapElement.config.tab
                                })
                            }
                        }
                    }
                }
            }
        },
        getCurrentScheda() {
            let scheda = this.getScheda({ id: this.idScheda })
            if(!scheda){
                console.error("Errore getCurrentScheda: ", scheda)
                return null
            }
            return tools.genericFunctions.cloneObject(scheda)
        },   
        updateSchedaInStore(scheda) {
            this.$store.dispatch("irregolarita/updateScheda", {
                id: this.idScheda,
                content: scheda,
            });
        },            
        cancelNote(){
            this.notaTemp = "";
        }, 
        saveScheda(action){       
            this.disableButton = true
            this.updateEsitoInStore()
            let body = this.getCurrentScheda();
            const url = endpoints.getIrregolaritaByID(this.codiceProgetto, this.idScheda);
            console.info("saveScheda: ", action, url)

            //elimino adesso la taskInfo per non persisterlo
            delete body.content.taskInfo;
            //Setto la nota temporanea
            const autore = this.$getUserInfo().preferred_username;
            body.content.notaTemporanea = {
                nota: this.notaTemp,
                utente: autore
            }
            //setto l'autore dell'ultimo aggiornamento fuori e dentro content
            body.content.autoreUltimoAggiornamento = autore;
            body['autoreUltimoAggiornamento'] = autore;

            //Setto la nota temporanea riservata
            body.content.notaTemporaneaRiservata = this.noteRiservate;

            this.$post(url, body)
            .then((result) => {
                this.scheda = result
                this.attachFileObjects(action).then(() => {
                    if(action != "draft" && action != 'save_comments'){
                        //Se siamo all'ultimo task, si decreta l'esito definitivo
                        if (action == "complete" 
                        && this.taskInfo.taskDefinitionKey == "Segnalazione_valutazione_SegreteriaTecnicaAmministrativa-Direttore"){              
                            this.esitoFinale == true || this.esitoFinale == 'true' ? action = "completeOK" : action = "completeKO";
                            console.info("conditional action=", action);
                        }  
                        
                        this.completaTask(action, body.idSchedaControllo, body.processId, this.taskInfo.taskId);
                    }
                    else{
                        notify.success(notify.strings.success, notify.strings.saveIrregolaritaOk);
                        this.disableButton = false
                    }
                    
                })                
                
            },
            (error) => {
                console.error("Errore save scheda: ", error.message);
                notify.error(notify.strings.error, notify.strings.errorSaveIrregolarita);
                throw error;                        
            }
            )

        },

        setValueModal(action){
      this.setModal=action;
      this.$bvModal.show('modalCompletaInvia');
    },
    actionModal(){
         switch (this.setModal) {
                    case "complete":
                        return this.saveScheda('complete');
                    case "reject":
                         return this.saveScheda('reject');
                     case "delete":
                         return this.executeDeleteAction();  
                    
                    default:
                        console.log("Case false");
                    }
    },

        //Setta ad "ATTACHED" lo stato dei file caricati ed elimina quelli cancellati
        async attachFileObjects(action){
            const uri = endpoints.filesBasePathV2
            const allegati = this.getCurrentScheda().content.allegati
            const toDelete = tools.genericFunctions.cloneObject(this.getAllegatiDaCancellare)
            const list = fileHelper.attachFileReqBuilder( allegati, toDelete )

            if(list && list.trim().length > 0){
                const ownerProcessStatus = action === 'save_comments' ? 
                    'Controllo_controllo-concluso_Nessuno-Nessuno' :
                    this.retrieveTaskInfo.taskDefinitionKey
                
                this.$put(uri,
                {
                "list" : list,
                "status" : ownerProcessStatus
                }
                ).then(
                (result) => {
                    console.log("attached Files:", result)
                    this.$store.dispatch("irregolarita/clearAllegatiDaCancellare")
                },
                (error) => {
                    console.error("ERRORE: ", error.message);
                    notify.error(notify.strings.error, notify.strings.unexpectedLoadStatus(error.message));
                    throw error;
                }
                )
            }        
        }, 
        completaTask(action, idScheda, processId, taskId){
            if (!action || !idScheda || !processId || !taskId){
                console.error("completaTask: NO params!?", action, idScheda, processId, taskId);
                throw new Error("completaTask: NO params!?", action, idScheda, processId, taskId);
            }       
            const url= endpoints.completeIrregolarita;
            console.log("completa task: ", url)
            let bodyForPost = {
                idScheda: idScheda,
                processId: processId,
                taskId: taskId,
                azione: action
            };
            return this.$post(url, bodyForPost)
            .then( () => {
                notify.success(notify.strings.success, notify.strings.saveIrregolaritaOk);
                this.$router.push({ name: "secure" });
                this.disableButton = false
            })
            .catch( error => {
                console.error("ERRORE COMPLETE: ", error.message);
                notify.error(notify.strings.error, notify.strings.errorCompleteIrregolarita);
                throw error; 
            })

        }
    }
}
</script>
