<template>
    <div id="collapseAttivitaProgetto">
        <b-button class="align-right" variant="outline-primary"
                  @click="doAction('add')"
                  v-if="visibilityAdd">
            <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
            Aggiungi
        </b-button>
        <b-table :id="idTablePdf" 
                      :tbodyTrClass="editedRowClass"
                      :items="getItems"
                      :fields="tabledata.header"
                      :sortBy="sortBy">
            <template v-slot:cell(Azione)="data">
                <b-button :id="namingIdButton(x, data.index + 1)" variant="outline-primary" :key="x" v-for="x in data.value" size="sm" v-on:click="doAction(x, data)">
                    <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
                    <fa-icon v-if="x==='delete'" :icon="['far', 'trash-alt']" class="selector-icons"/>
                    <fa-icon v-if="x==='view'" :icon="['far', 'eye']" class="selector-icons"/>
                </b-button>
            </template>
        
        </b-table>
        <b-modal    id="scegliIndicatore"
                    ok-variant='success'
                    ok-title='Aggiungi'
                    cancel-variant='danger'
                    cancel-title='Chiudi'
                    @ok="aggiungiIndicatore">
            <template v-slot:modal-title>
                <h3>Aggiungi indicatore</h3>
            </template>
            <h4>
                Scegli l'indicatore: 
            </h4>
            <ol style="font-size:1.4rem">
                <li>dalla lista degli indicatori
                    per gli obiettivi specifici del progetto</li>
                <li>personalizzato</li>
            </ol>
            <b-form-select size="lg" v-model="indicatoreType">
                <b-form-select-option value="STANDARD">Standard da lista</b-form-select-option>
                <b-form-select-option value="CUSTOM">Personalizzato</b-form-select-option>
            </b-form-select>
        </b-modal>
        <b-modal :id="'modalForm1-' + this.name" hide-footer
             size="lg" scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content">
             <template v-slot:modal-title>
                <h3>Modifica valori</h3>
            </template>
            <ModalForm
                :fdata="this.editFormData"
                :sch="this.getScheda()"
                :buttons="this.buttons"
                :parentName="this.name"
                :cfg="mappa"
                @editField="editRiga"/>
        </b-modal>
        <b-modal :id="'modalData-' + this.name"
             size="lg"
             scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content"
             ok-only ok-variant='danger' ok-title='Chiudi'>
             <template v-slot:modal-title>
                <h3>Visualizzazione</h3>
             </template>
             <DynamicTable2 :tdata="this.modalData"/>
        </b-modal>
    </div>
</template>
<script>
import ModalForm from "@/components/modalForm1.vue";
import mapUtils from '@/helpers/mapUtils.js'
import { JSONPath as jpath } from 'jsonpath-plus';
import calculation from '@/helpers/calculations.js';
import { mapGetters } from "vuex";
import tool from '@/helpers/tools.js'
import DynamicTable2 from "@/components/dynamicTable2.vue";
import visibility from '@/helpers/visibility.js';
import notify from "@/helpers/notifications.js";
import utils from '@/helpers/utils.js'

export default {
    name:"Vertical",
    data() {
        return {
            sortBy: '',
            editLine: -1,
            mappa: {},
            headerIds: [
                "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']",
                "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['descrizione']",
                "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['unitaDiMisura']",
                "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['valorePartenza']",
                "['progetto']['indicatoriObiettivo'][*]['valoreAtteso']"
            ],
            tabledata: {
                header: [],
                "rows": []
            },
            buttons: [
                {
                    name: "Modifica",
                    action: "editField",
                    show: true,
                    param: "edit",
                },
            ],
            editFormData: {},
            modalData: {},
            lastStarRegex: /\*(?!.*\*)/,
            isIdRegex: /\[.+\]/,
            actions: ['view', 'edit', 'delete'],
            indicatoreType: 'STANDARD',
            typeId: "['progetto']['indicatoriObiettivo'][*]['type']",
            descrizioneId: "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['descrizione']",
            unitaId: "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['unitaDiMisura']",
        }
    },
    components: {
        ModalForm,
        DynamicTable2
    },
    props: {
        inputs: {
            type: Object
        },
        dinamicIds: {
            type: Array,
            required: true
        },
        name: String,
        title: String,
        idTablePdf: { type: String, default: () => '' }
        // update: Boolean
    },
    created() {
        this.mappa = this.getTabelloneProgetto('progetto');
        if(this.mappa) {
            this.extractData();
        }
        else
            console.log(this.name, 'error in mappa', this.mappa);
    },
    computed: {
        ...mapGetters({
            getTabelloneProgetto: "configuration/getTabellone",
            getSchedaProgetto: "progetto/getSchedaProgetto",
            getSchedaComplete: "progetto/getScheda",
            getIndicatori: "progetto/getIndicatori"
        }),
        getItems() {
            let items = [];
            for(const obj of this.tabledata.rows){
                let item = {
                    note: {}
                };
                for(const key in obj)
                {
                    if(!key.match(this.isIdRegex)){
                        item[key] = obj[key];
                    }
                    else {
                        item[key] = obj[key].value;
                        item.note[key] = obj[key].note;
                    }
                }
                items.push(item);
            }
            return items;
        },
        visibilityAdd() {
            if(!this.$route.params.idProgetto)
                return false;
            const actions = this.$projectGetActions(
                this.getSchedaComplete({ idSchedaProgetto: this.$route.params.idProgetto})
            );
            return actions.indexOf('add') !== -1;
        },
    },
    methods: {
        editedRowClass(item) {
            if(item.edited && !item.deleted)
                return 'changeInRow';
            else if(item.deleted)
                return 'deletedCell';
            return '';
        },
        // Costruzione id per Buttoni (Azioni nel componente)
        namingIdButton(action, index) {
            return this.$builtIdDinamically(action, index)
        },

        tooltip(value, key, item) {
            if(item.note[key])
                return {title: 'nota: ' + item.note[key]};
            return {};
        },
        updateSchedaInStore(scheda) {
            this.$store.dispatch('progetto/updateSchedaProgettoObject',
                {
                    idSchedaProgetto: this.$route.params.idProgetto,
                    schedaProgetto: scheda
                });
        },
        formatter(value, key) {
            const mapElement = this.mappa[key];
            if(!mapElement)
                return value;
            return utils.formatOutput(mapElement, value);
        },
        extractData() {
            if(!this.dinamicIds || this.dinamicIds.length === 0 || !this.mappa || !this.$route.params.idProgetto) {
                console.error(this.name, 'Invalid dinamicIds/mappa/params',
                this.dinamicIds, this.mappa, this.$route.params.idProgetto);
                return;
            }
            let updateScheda = false;
            const schedaComplete = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto});
            let scheda = schedaComplete.schedaProgetto;
            if(!scheda) {
                console.error(this.name, 'Invalid scheda', scheda);
                return;
            }

            this.tabledata = {
                header: [],
                rows: []
            };

            // setup header
            for(const colId of this.headerIds) {
                let mapElement = this.mappa[colId];
                if(!mapElement) {
                    console.error('no config, get default one for ', colId);
                    mapElement = this.$getDefaultConfig();
                    mapElement.config.path = colId;
                }
                const headerColumn = {
                    key: colId,
                    label: mapElement.config.label,
                    tdAttr: this.tooltip,
                    formatter: this.formatter,
                    sortable: true
                }
                this.tabledata.header.push(headerColumn);
            }
            this.tabledata.header.push({ key: 'Azione', label: 'Azione'});

            this.sortBy = this.tabledata.header[0].key;

            // TBD choose id with largest number of array indices
            // currently first available id is chosen
            // suppongo che l'indice di riga sia l'ultimo disponibile sugli id
            let temp;
            for(let id1 of this.dinamicIds) {
                temp = jpath({resultType: 'all'}, '$' + id1, scheda);
                if(temp && temp.length !== 0) {
                    break;
                }
            }

            if(!temp || temp.length === 0) {
                return;
            }
            
            // for all results, get the highest index 
            // of most deeply nested array
            let maxIndex = -1;
            for(const item of temp) {
                const indexes = item.path.match(mapUtils.lastIndexRegex);
                if(indexes.length === 0) {
                    console.error(this.name, 'unknown index', item.path);
                    continue;
                }
                const tempIndex = parseInt(indexes[0]);
                if(!isNaN(tempIndex) && tempIndex > maxIndex)
                    maxIndex = tempIndex;
            }

            if(maxIndex < 0) {
                console.error(this.name, 'index error', temp);
                return;
            }

            // add rows to table
            for(let i = 0; i <= maxIndex; i++) {
                let rowObj = {
                    '_cellVariants': {},
                    Azione: [],
                    index: i
                };
                const len = Object.entries(rowObj).length;
                for(const colId of this.dinamicIds) {
                    const id = colId.replace(this.lastStarRegex, i);                    
                    const results = jpath('$' + id, scheda);

                    let result;
                    if(results.length === 0) {

                        const retVal = mapUtils.createElementFromScratch(id, scheda, this.mappa);
                        scheda = retVal.scheda;
                        result = retVal.object;
                        updateScheda = true;
                        
                    }
                    else if (results.length === 1) {
                        result = results[0];
                    }
                    else {
                        console.error('is an array?', colId, results);
                    }
                    const setupVal = mapUtils.setupComponentRow(rowObj, id, result, this.mappa,
                                                        scheda, schedaComplete.taskInfo, this.actions);
                    rowObj = setupVal.rowObj;
                }
                if(Object.entries(rowObj).length !== len) {
                    this.tabledata.rows.push(rowObj);
                }
            }
            if(updateScheda) {
                this.updateSchedaInStore(scheda);
            }
        },
        // per ogni riga della tabella determina gli indici di array
        computeArrayIndexes(scheda) {
            let multiplicity;
            for (let id1 of this.dinamicIds) {
                multiplicity = jpath({ resultType: "all" }, "$" + id1, scheda);
                if (multiplicity && multiplicity.length > 0) {
                    break;
                }
            }

            if (!multiplicity || multiplicity.length === 0) {
                return {};
            }

            const rowMultiplicity = multiplicity.length;
            let indexArray = [];
            for(let row of multiplicity) {
                indexArray.push(row.path.match(mapUtils.indexRegex));
            }
            return {
                rowMultiplicity: rowMultiplicity,
                indexArray: indexArray
            }
        },

        deleteIndicatore(item) {
            const index = item.index;
            console.log("Cancellazione fisica alla linea " + index + " per l'indicatore:",
                        item["['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']"]);

            const schedaComplete = this.getSchedaComplete({
                idSchedaProgetto: this.$route.params.idProgetto,
            });
            const clonedScheda = tool.genericFunctions.cloneObject(schedaComplete.schedaProgetto);

            const starIndex = this.typeId.indexOf('*');
            const rootString = this.typeId.substring(0, starIndex);
            const len = this.tabledata.rows.length;

            // cerca l'indicatore nella scheda con jpath
            const realId = rootString.concat(index, ']');
            const res = jpath({resultType: 'all'}, '$' + realId, clonedScheda);
            if(!res || res.length === 0) {
                console.error('deleteIndicatore non trovato, index:', index);
                notify.error(notify.strings.error, notify.strings.operationError('Eliminazione indicatore'));
                return;
            }
            // elimina l'indicatore dalla scheda
            res[0].parent.splice(index, 1);

            if(!clonedScheda.dataEntryConfiguration) {
                clonedScheda.dataEntryConfiguration = {};
            }

            // cerca i riferimenti nel tabellone
            const keysToDelete = [];
            for(const key of Object.keys(clonedScheda.dataEntryConfiguration)) {
                if(key.startsWith(realId))
                    keysToDelete.push(key);
            }
            // elimina i riferimenti dal tabellone
            for(const key of keysToDelete) {
                delete clonedScheda.dataEntryConfiguration[key];
            }

            const lastIndex = len - 1;
            // se l'elemento non è l'ultimo, nel tabellone shifto di una posizione tutte le entry
            // successive all'indicatore eliminato
            if(index !== lastIndex) {
                this.shiftLastOccurrences(clonedScheda, index, lastIndex, rootString);
            }
            
            this.updateSchedaInStore(clonedScheda);
            this.extractData();
        },

        shiftLastOccurrences(clonedScheda, index, lastIndex, rootString) {
            // se l'elemento non è l'ultimo, nel tabellone shifto di una posizione tutte le entry
            // successive all'indicatore eliminato

                
            const firstIndex = index + 1;
            for(let i = firstIndex; i <= lastIndex; i++) {
                const startStr = rootString.concat(i, ']');
                const keysToMove = [];
                for(const key of Object.keys(clonedScheda.dataEntryConfiguration)) {
                    if(key.startsWith(startStr))
                        keysToMove.push(key);
                }
                const newIndex = i - 1;
                for(const oldKey of keysToMove) {
                    const newKey = oldKey.replace(/\b\d+\b/, newIndex);
                    clonedScheda.dataEntryConfiguration[newKey] = clonedScheda.dataEntryConfiguration[oldKey];
                    const data1 = clonedScheda.dataEntryConfiguration[newKey].data;
                    if(data1 && data1.editTag) {
                        data1.editTag = data1.editTag.replace(/\b\d+\b/, (newIndex + 1));
                    }
                    delete clonedScheda.dataEntryConfiguration[oldKey];
                }
            }
        },
        
        getScheda() {
            return this.getSchedaProgetto({idSchedaProgetto: this.$route.params.idProgetto});
        },

        findIndicatoreSelezionatoById(idIndicatore, rows){
            //scorro la lista per trovare l'elemento con lo stesso ID supposto univoco
            for (let i=0; i<rows.length; i++){
                let r = rows[i];
                if (r["['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']"].value == idIndicatore){
                    return {
                        rowObj: r,
                        index: i
                    };
                }
            } 
            console.log("idIndicatore '"+idIndicatore+"' not found in rows", rows);
            return null;
        }, 

        editRiga(actionFromModal) {
            this.$bvModal.hide('modalForm1-' + this.name);
            let idIndicatore = actionFromModal.content["['progetto']['indicatoriObiettivo']["+this.editLine+"]['indicatoreObiettivo']['idIndicatore']"].value;    
            console.log('edit alla linea '+this.editLine+', idIndicatore: ', idIndicatore);
            let rowObj =  this.tabledata.rows[this.editLine];
            let added = false;
            const schedaComplete = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto});
            if(!rowObj) {
                rowObj = {
                    '_cellVariants': {},
                    '_rowVariant': "success",
                    index: this.tabledata.rows.length
                };
                added = true;
            } else {
                console.log("trovato indicatore esistente da editare:", rowObj["['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']"].id);
            }
            
            const retVal = mapUtils.updateComponent(actionFromModal, schedaComplete, rowObj, this.mappa, this.actions, this.title);
            const clonedScheda = retVal.clonedScheda;
            rowObj = retVal.rowObj;

            this.updateSchedaInStore(clonedScheda);
            if (added){
                //un indicatore appena aggiunto ha sempre tutte le azioni disponibili
                rowObj.Azione = this.actions;
                this.tabledata.rows.push(rowObj);
                console.log("inserisco nuovo indicatore in posizione:", this.editLine);
            } else {

                this.tabledata.rows.splice(this.editLine, 1, rowObj);
            }

        },
        addRiga() {
            this.editFormData = { content: {}, conf: {} };
            let mapElement;
            const newIndex = this.tabledata.rows.length;
            this.editLine = newIndex;
            const scheda = this.getScheda();
            // Per ogni id ho n colonne

            // let ids = tool.genericFunctions.cloneObject(this.dinamicIds);
            // rimuove la modalità di calcolo per gli indicatori custom
            // if(this.indicatoreType !== 'custom') {
            //     const index = ids.indexOf("['progetto']['indicatoriObiettivo'][*]['modalitaDiCalcolo']");
            //     ids.splice(index, 1);
            // }

            for (const colId of this.dinamicIds) {
                const id = colId.replace(this.lastStarRegex, newIndex);
                mapElement = tool.genericFunctions.cloneObject(
                    mapUtils.getMapElement(this.mappa, id)
                );
                mapElement.config.path = id;

                ////////////////////////////////////////////////////////////////////////////////
                // configurazione custom per indicatori
                // TODO questa parte si può ottimizzare forse direttamente in calculations
                // visto che è stato introdotto il campo "type"

                if(colId === this.descrizioneId || colId === this.unitaId) {
                    if( this.indicatoreType === 'CUSTOM') {
                        mapElement.config.mandatory = true;
                        delete mapElement.config.calculationRule;
                    } else {
                        mapElement.config.readonly = true;
                    }
                }
                //////////////////////////////////////////////////////////////////////////////// 

                // replace path with correct id not possible
                // mapElement.config.path = colId;
                mapElement.data = this.$getDefaultData();

                mapElement.data.added = true;
                let value = null;
                // automatic value calculation
                if (mapElement.config.calculationRule) {
                    value = calculation.calculateValue(mapElement, scheda, colId);
                }
                
                if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
                    mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda, this.indicatoreType);
                }
                let val = { id: id };

                // WARNING aggiungo manualmente il tipo di indicatore, STANDARD o CUSTOM
                if(colId === this.typeId) {
                    value = this.indicatoreType;
                }
                if (colId.includes("note")){    //inizializzo le note a stringa vuota
                    value = "";
                }

                val.value = value;
                this.editFormData.conf[id] = mapElement;
                this.editFormData.content[id] = val;
            }
        },
        
        aggiungiIndicatore() {
            this.addRiga();
            this.$bvModal.show('modalForm1-' + this.name);
        },

        /*
        findIndicatoreSelezionato(row, rows){
            //scorro la lista per trovare l'elemento con lo stesso ID supposto univoco
            for (let i=0; i<rows.length; i++){
                let r = rows[i];
                if (r["['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']"].value == row.item["['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']"]){
                    return {
                            rowObj: r,
                            index : i
                            };
                }
                
            } 
            console.error("row not found in rows!?", row, rows);
            return null;
        },
        */

        doAction(actionToDo, data) {
            let index;
            let delMsg = "Si sta per eliminare un indicatore. "
            delMsg = delMsg.concat("NB: L'indicatore eliminato non comparirà tra le modifiche. Si desidera procedere?");
            let row = null;
            if(data) {
                index = data.item.index;
                this.indicatoreType = data.item[this.typeId];
                if (actionToDo != 'add'){
                    console.log("azione alla linea: " + data.index + " su idIndicatore:", 
                        data.item["['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']"]);
                    row = this.tabledata.rows[index];
                }
            }

            switch(actionToDo) {
                case 'view': 
                {
                    const scheda = this.getScheda();

                    this.modalData = {};
                    let modalRows = [];

                    const modalContent = {
                        content: row
                    }

                    //ciclo su tutte le colonne possibili, la riga iesima potrebbe non avere qualche campo            
                    //for (const column in row) {
                    for (const column of this.dinamicIds) {
                        // skip non consistent info
                        if (!column.match(this.isIdRegex)){
                            console.log("view skip column:", column);
                            continue;
                        }
                        
                        if (!row[column]){
                            console.log("view skip empty column:", column);
                            continue;
                        }
                        
                        const id = row[column].id;


                        const temp = mapUtils.getMapElement(this.mappa, id)
                        let mapElement = tool.genericFunctions.cloneObject(temp);
                        // compute field visibility
                        if(mapElement.config.hiddenRule) {
                            const hidden = visibility.computeHidden(id, modalContent, mapElement);
                            if(hidden) {
                                continue;
                            }
                        }
                        const dataEntry = scheda.dataEntryConfiguration;

                        if (dataEntry && dataEntry[id]) {
                            mapElement.data = tool.genericFunctions.cloneObject(
                                dataEntry[id].data
                            );
                        } else {
                            mapElement.data = this.$getDefaultData();
                        }
                        let value;
                        
                        const values = jpath("$" + id, scheda);
                        if (values.length === 0) {
                            console.error("valore non trovato in scheda: ", id);
                        } else {
                            value = values[0];
                        }

                        let modalRow = { 
                            content: {
                                Campo : mapElement.config.label,
                                Valore : value,
                            },
                            conf: {} 
                        };
                        modalRow.conf[id] = mapElement;
                        modalRow.conf[id].data.deleted = false;  //voglio vedere sempre anche i campi cancellati
                        //console.log("modalRow", modalRow);
                        modalRows.push(modalRow);
                    }

                    this.modalData = {
                        header : ['Campo', 'Valore'],
                        rows: modalRows
                    }
                    this.$bvModal.show("modalData-" + this.name);
                    break;
                }
                case 'add':
                    if(!this.indicatoreType) {
                        this.indicatoreType = 'STANDARD'
                    }
                    this.$bvModal.show('scegliIndicatore');
                    break;
                case 'delete':
                    this.$bvModal.msgBoxConfirm(delMsg, {
                        title: "Conferma eliminazione indicatore",
                        okVariant: 'success',
                        cancelVariant: 'danger',
                        okTitle: 'Elimina',
                        cancelTitle: 'Chiudi',
                        titleClass: 'card-title',
                        dialogClass: 'card-body'
                    }) .then(value => {
                        if(value) {
                            this.deleteIndicatore(data.item);
                        }
                    })
                    break;
                case 'edit':
                    {
                        this.editFormData = {content:{}, conf: {}};
                        // get fresh data from storage
                        const scheda = this.getScheda();
                        
                        const type = this.tabledata.rows[index][this.typeId].value;
                        
                        //ciclo su tutte le colonne possibili, la riga iesima potrebbe non avere qualche campo        
                        //for (const column in row) {
                        for (let column of this.dinamicIds) {
                            // skip non consistent info
                            if(!column.match(this.isIdRegex)){
                                console.log("edit skip column:", column);
                                continue;
                            }
                            
                            if (!row[column]){
                                console.log("edit skip empty column:", column);
                                continue;
                            }
                            
                            const id = row[column].id;

                            let mapElement = tool.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, id));
                            mapElement.config.path = id;

                            ////////////////////////////////////////////////////////////////////////////////
                            // configurazione custom per indicatori
                            if(column === this.descrizioneId || column === this.unitaId) {
                                if(type === 'CUSTOM') {
                                    mapElement.config.mandatory = true;
                                    delete mapElement.config.calculationRule;
                                } else {
                                    mapElement.config.readonly = true;
                                }
                            }
                            //////////////////////////////////////////////////////////////////////////////// 

                            const dataEntry = scheda.dataEntryConfiguration;

                            if(dataEntry && dataEntry[id]) {
                                mapElement.data = tool.genericFunctions.cloneObject(dataEntry[id].data);
                            }
                            else {
                                mapElement.data = this.$getDefaultData();
                            }
                            if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
                                // WARNING questo controllo presuppone che tutte le enum calcolate diventino readonly in modifica
                                // se non è così bisogna pensare ad un'alternativa (readonlyRule?)
                                mapElement.config.readonly = true;
                                console.info("Tipologia: ", type)
                                mapElement.config.enumValues = calculation.calculateEnum(mapElement, this.getScheda(), type);
                            }
                            let val = {'id': id, value: null};
                            if (mapElement.config.calculationRule && !mapElement.config.readonly) {
                                val.value = calculation.calculateValue(mapElement, this.getScheda(), id);
                            }
                            else {
                                const values = jpath('$'+id, scheda);
                                if(values.length === 0) {
                                    console.error('valore non trovato in scheda: ', id);
                                }
                                else {
                                    val.value = values[0];
                                }
                            }
                            this.editFormData.conf[id] = mapElement;
                            this.editFormData.content[id] = tool.genericFunctions.cloneObject(val);
                            this.editLine = index;
                        }

                        this.$bvModal.show('modalForm1-' + this.name);
                    }
                    break;
                default:
                    this.editFormData = {};
                break;
            }
        }
    }
}
</script>
