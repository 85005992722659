var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"show":!_vm.loadComplete || _vm.generationPdf,"rounded":"sm"}},[_c('div',{staticClass:"table-title"},[_vm._v("Scheda Richiesta Tranche per il Progetto: "+_vm._s(this.$route.params.codiceProgetto)+" ")]),(_vm.loadComplete)?_c('div',[_c('div',{staticClass:"table-title"},[_vm._v("Titolo scheda: "+_vm._s(_vm.schedaTranche.content.titoloControllo))]),(_vm.loadComplete)?_c('b-table-lite',{attrs:{"thead-class":"head","items":this.tabledata.rows,"fields":this.tabledata.header}}):_vm._e(),_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":"","nav-class":"tabs","content-class":"content"}},[_c('ColorLegend'),(_vm.loadComplete && _vm.isViewAllowed('Anagrafica'))?_c('b-tab',{attrs:{"no-body":"","title":"Anagrafica","title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['anagrafica'].name)}}},[_c('GeneralViewerCard',{attrs:{"idTablePdf":_vm.conf_export_pdf['anagrafica'].id_table_pdf,"name":_vm.conf_export_pdf['anagrafica'].name,"tabName":_vm.tabSelection,"pathDataEntryConfiguration":'content',"store":'circuitoFinanziario',"fnGetterTab":'getTabellone',"headerTable":_vm.headerAnagrafica,"rowIds":_vm.rowIds_anagrafica,"fnGetter":'getScheda',"idEntity":{
                        codiceProgetto: this.$route.params.codiceProgetto,
                        id: this.$route.params.idScheda
                    },"actions":_vm.actionsMap[_vm.mappingTabs[1]],"fnSetter":'updateScheda'}})],1):_vm._e(),(_vm.loadComplete)?_c('b-tab',{attrs:{"no-body":"","title":"Richiesta Tranche","title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['richiesta_tranche'].name)}}},[_c('GeneralViewerCard',{attrs:{"idTablePdf":_vm.conf_export_pdf['richiesta_tranche'].id_table_pdf,"name":_vm.conf_export_pdf['richiesta_tranche'].name,"tabName":_vm.tabSelection,"pathDataEntryConfiguration":'content',"store":'circuitoFinanziario',"fnGetterTab":'getTabellone',"headerTable":_vm.headerAnagrafica,"rowIds":_vm.rowIds_tranche,"fnGetter":'getScheda',"idEntity":{
                        codiceProgetto: this.$route.params.codiceProgetto,
                        id: this.$route.params.idScheda
                    },"fnSetter":'updateScheda',"actions":_vm.actionsMap[_vm.mappingTabs[2]]}})],1):_vm._e(),(_vm.loadComplete)?_c('b-tab',{attrs:{"no-body":"","title":"Approvazione Tranche","title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['approvazione_tranche'].name)}}},[_c('GeneralViewerCard',{attrs:{"idTablePdf":_vm.conf_export_pdf['approvazione_tranche'].id_table_pdf,"name":_vm.conf_export_pdf['approvazione_tranche'].name,"tabName":_vm.tabSelection,"pathDataEntryConfiguration":'content',"store":'circuitoFinanziario',"fnGetterTab":'getTabellone',"headerTable":_vm.headerAnagrafica,"rowIds":_vm.rowIds_ApprovazioneTranche,"fnGetter":'getScheda',"idEntity":{
                        codiceProgetto: this.$route.params.codiceProgetto,
                        id: this.$route.params.idScheda
                    },"fnSetter":'updateScheda',"actions":_vm.actionsMap[_vm.mappingTabs[3]]}})],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Documentazione'))?_c('b-tab',{attrs:{"no-body":"","title":"Documentazione","title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['documentazione'].name)}}},[_c('Documentazione',{attrs:{"ownerProcessStatus":this.retrieveTaskDefinitionKey,"actions":_vm.actionsMap[_vm.mappingTabs[4]],"entity":this.store,"getters":this.getters,"setters":this.setters,"payload":this.payload,"riferimento":this.riferimento,"tabName":"Documentazione"}})],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Controllo'))?_c('b-tab',{attrs:{"no-body":"","title":"Checklist","title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['checklist'].name)}}},[_c('Controllo',{attrs:{"idTablePdf":_vm.conf_export_pdf['checklist'].id_table_pdf,"title":_vm.conf_export_pdf['checklist'].nameCollapse,"tabName":_vm.tabSelection.toLowerCase(),"config":_vm.getTabellone,"dataObject":'controllo',"store":'circuitoFinanziario',"fnGetter":'getScheda',"fnSetter":'updateScheda',"idEntity":{
                        codiceProgetto: this.$route.params.codiceProgetto,
                        id: this.$route.params.idScheda
                    },"name":"Check List Controllo","headerTable":_vm.headerTableCheckList,"actions":_vm.actionsMap[_vm.mappingTabs[5]]}})],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Invio'))?_c('b-tab',{attrs:{"no-body":"","title":"Invio","title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Invio_mandatory'].name)}}},[_c('Invio',{attrs:{"tabName":_vm.tabSelection,"idsCustomEdit":_vm.ids_custom_edit,"config":_vm.getTabellone,"idsToCheck":_vm.idsToCheck,"actions":_vm.actionsMap[_vm.mappingTabs[6]],"datiSpesa":_vm.datiSpesa},on:{"buildPdf":_vm.generateReport}})],1):_vm._e()],1)],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }