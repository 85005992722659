<template>
  <div>
    <div class="card">
        <div class="card-body text-right">
          <h1 class="font-weight-bold">Totale Importi: {{ totaleSpeseEuro }}</h1>
        </div>
      </div>
    <GenericList
      :id="'listaProgetti'+dynamicID" 
      :title="'Lista Progetti '+dynamicTitle"
      :busyTable="isBusy"
      :headerTable="headerTable"
      :dataTable="progettiAnticip"
      :actionsInRow="this.$getActionsProgettiAnticipazione"
      :actionsMapping="this.buttonLabels"
      :blockButton="isBusy || blockButton"
      :sort-by="'codiceProgetto'"
      @clickActionList="doAction"
    >
    </GenericList>

  </div>
</template>
<script>

import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue"
import GenericList from "@/components/genericList.vue"

export default {
  name: "listaProgettiAnticipazione",
  components: {
    GenericList
  },
  data() {
    return {
      totaleSpeseEuro: "€ 0,00",
      headerTable: [
          {
            key: "codiceProgetto",
            label: "Codice Progetto",
            sortable: true,
          },
          {
            key: "modalitaDiAccesso",
            label: "Modalità",
            sortable: true,
          },
          {
            key: "titoloProgetto",
            label: "Titolo",
            sortable: true,
          },
          {
            key: "dataFirmaConvenzione",
            sortable: true,
            filterByFormatted: true,
            formatter: "dateFormatter"
          },
          {
            key: "costoProgetto",
            label: "Costo Progetto",
            formatter: "currencyFormatter",
            sortable: true,
          },
          {
            key: "strumentoFinanziario",
            sortable: true,
          },
          {
            key: "richiesteAttive",
            label: "Richieste in Corso",
            sortable: true,
          },
          {
            key: "Azioni",
            thClass: "thst",
          },

        ],
      buttonLabels: {
        start_pref: "Avvia processo di Richiesta Tranche",
        view: "Visualizza Progetto",
        view_controlli_list: "Visualizza Richiesta Tranche",
        claim: "Prendi in Carico",
        unclaim: "Rilascia presa in Carico"
      },
      blockButton: false,
      isBusy: false,
      progettiAttuazione: [],
      progettiAnticip: []


    }
  },

  mounted() {
    this.loadProgettiAnticipazione();
  },
  
  computed: {

    dynamicTitle(){
      //console.log("cambio il titolo")

      //ogni volta che cambio URL cambio il titolo della pagina
      if (this.$route.path.includes("nticipazione")){
        return "In Anticipazione"
      } else {
        console.error("URL non gestito!?")
      }


      return "sconosciuta"
    },

    dynamicID(){
      //console.log("cambio l'ID del componente")

      //ogni volta che cambio URL cambio il titolo della pagina
      if (this.$route.path.includes("nticipazione")){
        return "Anticipazione"
      } else {
        console.error("URL non gestito!?")
      }

      return "sconosciuto"
    },

    
    

  },

  methods: {

    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data)
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        )
        return
      }
      let codiceProgetto = data.item.codiceProgetto
      //console.log("selezionato codiceProgetto=", codiceProgetto)
      let infoProgetto = data.item
      
      //console.log("infoProgetto=", infoProgetto)
      let idProgetto = infoProgetto.idProgetto
      
      switch (action) {
        case "view":    //visualizzazione della scheda progetto
          this.$router.push({
            name: "schedaProgetto",
            params: { idProgetto: idProgetto },
          })
          break
        case "view_controlli_list":
          this.$router.push({
            name: "listaTranche",
            params: { 
              codiceProgetto: codiceProgetto
            },
          })      
          break
        
        default:
          console.error("Unknown action!", action)
          break
      }
    },

    loadProgettiAnticipazione: async function() {
      this.isBusy = true
      const url = endpoints.listSchedeProgettoAnticipazione;
      
      this.$get(url)
      .then((result) => {
        this.progettiAttuazione = result
        console.log("Trovati "+result.length+" progetti in attuazione")
        this.extractData(result)
      },
      (error) => {
        this.isBusy = false
          notify.error(
            notify.strings.error,
            notify.strings.internalErrorPleaseReportAction
          )
        console.error("errore caricamento lista progetti", error)
        throw error
      })
    }, 

    extractData(result) {
      const url = endpoints.trancheStats;
      let totaleSpese=0;
      this.$get(url)
      .then((stats) =>{
        this.progettiAnticip = result.map(item => {
          const obj = parseFloat(item.infoProgetto.costoProgetto);
          if(!isNaN(obj)){
            totaleSpese = totaleSpese + obj;
          }
          item.infoProgetto.richiesteAttive = stats[item.infoProgetto.codiceProgetto] ? stats[item.infoProgetto.codiceProgetto] : 0
          return item.infoProgetto 
        })
        this.totaleSpeseEuro = utils.formatCurrency(totaleSpese);
        this.isBusy = false;
      },
      (error) => {
        this.isBusy = false;
        //Provo a caricare la lista senza statistiche
          this.progettiAnticip = result.map(item => { return item.infoProgetto })
          notify.error(
            notify.strings.error,
            "Impossibile caricare statistiche"
          );
        console.error("errore caricamento lista",error);
      })
    },

   
  },
}
</script>