<template>
    <div>
        <b-container>
            <b-row class="mt-2">
                <b-col class="text-left">
                    <label for="textarea-commenti">Commenti conclusivi sull'irregolarita:</label>
                </b-col>
                <b-col sm="12">
                    <b-form-textarea
                    v-model="commenti"
                    id="textarea-commenti"
                    :disabled="!canEditCommenti"
                    size="lg"
                    placeholder="Inserire qui i commenti"/>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import tools from '@/helpers/tools.js'
import { mapGetters } from "vuex"

export default {
    name: "EsitoFinale",
    props: {
        actions: { type: Array, default: () => [], required: true },
        forceUpdateScheda: { type: Boolean }
    },
    data() {
        return {
            name: "EsitoFinale",
            scheda: {},
            taskInfo: {},
            commenti: ""      
        }
    },
    watch: {
        forceUpdateScheda: function() {
            this.updateCommentiInStore()
        }
    },
    
    mounted() {
        let commenti = this.getCurrentScheda().content.commenti
        this.commenti = commenti ? commenti : ""
        this.updateCommentiInStore()
    },
    computed: {
        ...mapGetters({
            getScheda: "irregolarita/getScheda"
        }),
        canEditCommenti() {
            return this.actions.indexOf("edit_commenti") > -1
        }
    },
    methods: {
        updateCommentiInStore() {
            let scheda = this.getCurrentScheda()
            scheda.content.commenti = this.commenti
            this.updateSchedaInStore(scheda)
        },
        getCurrentScheda() {
            let scheda = this.getScheda({ id: this.$route.params.idControllo })
            if(!scheda){
                console.error("Errore getCurrentScheda: ", scheda)
                return null
            }
            let clonedScheda = tools.genericFunctions.cloneObject(scheda)
            return clonedScheda
        },   
        updateSchedaInStore(scheda) {
            this.$store.dispatch("irregolarita/updateScheda", {
                id: this.$route.params.idControllo,
                content: scheda,
            });
        }
        
    }
}
</script>
