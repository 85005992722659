<template>
  <b-container>
    <b-container>
      <span hidden>{{finale}}</span>
      <b-row v-for="field of Object.values(returnData.content)" :key="field.id">
        <b-col>
          <b-row :id="'relation-' + field.id"
                  v-if="checkRelation(field)"
                  style="margin: 0px; padding: 0px">
            <b-col cols="2">
              <strong style="font-size: 1.3rem; float: right;">
                Verificare anche i seguenti campi:
              </strong>
            </b-col>
            <b-col style="margin: 0px; padding: 0px">
              <strong style="font-size: 1.3rem">
                <span v-for="x of getRelation(field)" :key="x.label">{{x.tab}} -> {{x.label}}<br></span>
              </strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :id="'group-' + field.id"
                        :label="returnData.conf[field.id].config.label"
                        label-size="lg" label-cols="3" label-align="center">
                <b-form-datepicker  v-if="checkDate(field)"
                                boundary="window"
                                locale="it"
                                hide-header dropright
                                label-help="Usa i cursori per navigare la data"
                                :min="minDate(field)"
                                :max="maxDate(field)"
                                v-model="returnData.content[field.id].value" 
                                :disabled="checkDisabled(field)"
                                :state="computeState(field)"
                                :id="field.id"
                                value-as-date/>
                <b-textarea v-else-if="checkTextArea(field)"
                        rows="4"
                        :maxlength="maxlength(field)"
                        v-model="returnData.content[field.id].value"
                        :disabled="checkDisabled(field)" 
                        :state="computeState(field)"
                        :id='field.id'/>
                <b-select v-else-if="isEnum(field)"
                      size="lg"
                      v-model="returnData.content[field.id].value"
                      :disabled="checkDisabled(field)"
                      :state="computeState(field)"
                      :id="field.id">
                <b-form-select-option v-for="option in getEnum(field)"
                                    :selected="option.value === field.value"  
                                    :key="option.value"
                                    :value="option.value">
                                    {{option.label}}
                  </b-form-select-option>
                </b-select>
               <b-input-group v-else size="lg" :prepend="prependSymbol(field)">
                  <b-input 
                        :maxlength="maxlength(field)"
                        v-model="returnData.content[field.id].value"
                        :disabled="checkDisabled(field)"
                        :state="computeState(field)"
                        :id="field.id"/>
                 <b-form-invalid-feedback :id="field.id+'-g-feedback'">
                    <h5 v-for="errs of errorList(field.id)" :key="errs">{{errs}}</h5>
                 </b-form-invalid-feedback>
                </b-input-group>
                <h5 v-if="showMaxLength(field)">
                  Caratteri consentiti: {{returnData.conf[field.id].config.maxLength}}
                </h5>
                <b-form-invalid-feedback :id="field.id+'-feedback'">
                    <h5 v-for="errs of errorList(field.id)" :key="errs">{{errs}}</h5>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group :id="'groupnota-' + field.id">
                <b-textarea :hidden="returnData.conf[field.id].config.suppressNote 
                          || checkDisabled(field) "
                          :state="isValid[field.id+'_nota'] === false ? false : null"
                          placeholder="Note"
                          :id="field.id+'_nota'"
                          maxlength="1000"
                          v-model="returnData.conf[field.id].data.note"/>
                  <b-form-invalid-feedback :id="field.id+'-feedback-nota'">
                    <h5 v-for="errs of errorList(field.id + '_nota')" :key="errs">{{errs}}</h5>
                  </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <br/>
      <b-row>
        <b-col>
          <div class="row-title">Modifica Spese</div>
        </b-col>
        <b-col>
            <b-button variant="outline-primary" 
                      @click="$emit('changeRows', {'action':'add', 'data': returnData, index: computeNewIndex()})">
                      Aggiungi spesa <fa-icon :icon="['far', 'plus-square']" class="selector-icons"/>
            </b-button>
        </b-col>
      </b-row>
      
      <b-container v-for="row of getSpeseArray()" :key="row.index">
        <b-row>
          <b-col v-for="field of Object.values(row.content)" :key="field.id">
            <b-form-group :id="'group-spese-' + field.id"
                          :label="returnData.conf[field.id].config.label"
                          label-size="lg">
              <b-form-datepicker  v-if="checkDate(field)"
                                boundary="window"
                                locale="it"
                                hide-header dropright
                                label-help="Usa i cursori per navigare la data"
                                :min="minDate(field)"
                                :max="maxDate(field)"
                                v-model="returnData.content2[field.id].value" 
                                :disabled="checkDisabled(field)"
                                :state="computeState(field)"
                                :id="field.id"
                                value-as-date/>
              <b-input-group v-else size="lg" :prepend="prependSymbol(field)">
                <b-input  
                        :maxlength="maxlength(field)"
                        v-model="returnData.content2[field.id].value"
                        :disabled="checkDisabled(field)"
                        :state="computeState(field)"
                        :id="field.id"/>
                <b-form-invalid-feedback :id="field.id+'-g-feedback'">
                  <h5 v-for="errs of errorList(field.id)" :key="errs">{{errs}}</h5>
                </b-form-invalid-feedback>
              </b-input-group>
                <b-form-invalid-feedback :id="field.id+'-feedback'">
                  <h5 v-for="errs of errorList(field.id)" :key="errs">{{errs}}</h5>
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-button variant="outline-primary" class="modal-default-button" 
                      @click="$emit('changeRows', {'action':'delete','index': row.index, 'data': returnData})">
              <fa-icon :icon="['far', 'trash-alt']" class="selector-icons"/>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-for="field of Object.values(row.content)" :key="field.id">
            <b-form-group :id="'groupnota-spese-' + field.id">
              <b-textarea :hidden="returnData.conf[field.id].config.suppressNote || checkDisabled(field)"
                        :state="isValid[field.id+'_nota'] === false ? false : null"
                        placeholder="Note"
                        :id="field.id+'_nota-spese'"
                        maxlength="1000"
                        v-model="returnData.conf[field.id].data.note" />
                <b-form-invalid-feedback :id="field.id+'-feedback-nota-spese'">
                  <h5 v-for="errs of errorList(field.id + '_nota')" :key="errs">{{errs}}</h5>
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
          
    <div class="modal-footer">
      <b-button variant="success" v-for="bt in buttons" :hidden="!bt.show" :key="bt.name" 
                class="modal-default-button" @click="controlloFinale(bt.action, returnData)">
                {{bt.name}}
      </b-button>
      <b-button variant="danger" class="modal-default-button"
                @click="$bvModal.hide('modalForm1-' + parentName)">
                Chiudi
      </b-button>
    </div>

  </b-container>
</template>
  
<script>
import notify from "@/helpers/notifications.js"
import tool from '@/helpers/tools.js'
import validation from '@/helpers/validations.js'
import calculation from '@/helpers/calculations.js';
import utils from '@/helpers/utils.js'
import mapUtils from '@/helpers/mapUtils.js'

export default {
  name: "modalForm",
  components: {
  },

  props: {
    parentName: {
      type: String,
      required: true
    },
    fdata: {
      type: Object,
      required: true
    },
    buttons:
    {
      type: Array,
    },
    sch : {
      type: Object,
      required: true
    },
    cfg: Object
  },

  watch: {
    fdata: function() {
      this.setupModalForm();
    }
  },

  data() {
    return {
      isValid: {},
      errorStrings: {},
      initialData: null,
      returnData: { content: {}, conf: {}, content2: {}},
      finale: false
    };
  },

  computed: {
    checkRelation() {
      return field => {
        const id = mapUtils.getGenericArrayKey(field.id);
        if(!id)
          return false;
        const mapElement = mapUtils.getMapElement(this.cfg, id);
        if(!mapElement)
          return false;
        const config = mapElement.config;
        return config && config.relatedTo && config.relatedTo.length > 0;
      }
    },
    getRelation() {
      return field => {
        const retVal = [];
        const id = mapUtils.getGenericArrayKey(field.id);
        if(!id)
          return retVal;
        const mapElement = mapUtils.getMapElement(this.cfg, id);
        if(!mapElement)
          return retVal;
        const config = mapElement.config;
        if(!config || !config.relatedTo || config.relatedTo.length === 0)
          return retVal;
        const rels = config.relatedTo;
        for(const rel of rels) {

          if(!this.cfg[rel] || !this.cfg[rel].config) {
            console.error('relazione non trovata', rel);
            continue;
          }
          const conf = this.cfg[rel].config;
          retVal.push({
            tab: conf.tab,
            label: conf.label
          })
        }
        return retVal;
      }
    },
    prependSymbol() {
      return field => {
        if(this.returnData.conf[field.id]) {
          const config = this.returnData.conf[field.id].config;
          if (config.type === 'currency')
            return '€';
          if (config.type === 'percent')
            return '%';
        }
      }
    },
    showMaxLength() {
      return field => {
        if(this.returnData.conf[field.id]) {
          const config = this.returnData.conf[field.id].config;
          return (config.maxLength && !config.readonly && !config.enumValues);
        }
        return false;  
      }
    },
    computeState() {
      return field => {
        if(this.checkDisabled(field))
          return null;
        // WARNING: questo controllo dovrebbe dare uno state null all'apertura della modalform
        // verificare che sia efficace e non mascheri un bug
        if(!field.value && this.isValid[field.id] == null)
          return null;
        return this.validate(field.id, field.value);
      }
    },
    maxlength: function() {
      return field => {
        let max = 50;
        if(this.returnData.conf[field.id] &&
           this.returnData.conf[field.id].config.maxLength) {
            max = this.returnData.conf[field.id].config.maxLength;
        }
        return max;
      }
    },
    minDate() {
      return field => {
        if(this.returnData.conf && this.returnData.conf[field.id]) {
          const config = this.returnData.conf[field.id].config;
          if(config.minDate) {
            return calculation.computeDate(config.minDate);
          }
        }
        return null;
      }
    },
    maxDate() {
      return field => {
        if(this.returnData.conf && this.returnData.conf[field.id]) {
          const config = this.returnData.conf[field.id].config;
          if(config.maxDate) {
            return calculation.computeDate(config.maxDate);
          }
        }
        return null;
      }
    },
    
    errorList() {
      
      return id => {
        let list = [];
        if(this.errorStrings[id])
          list.push(this.errorStrings[id]);
        if(this.errorStrings[id+'_nota'])
          list.push(this.errorStrings[id+'_nota']);
        return list;
      }
    },

    checkDisabled: function() {
      return field => {
        let disabled = this.returnData.conf[field.id].config.readonly;
        let tab = this.returnData.conf[field.id].config.tab;
        
        if(tab) {
          disabled = disabled || (tab !== this.parentName);
        }

        return disabled;
      }
    },

    getEnum: function() {
      return field => {
        if(this.returnData.conf && this.returnData.conf[field.id].config.type === 'enum') { // se il campo è di tipo enum
          let enumValues = [];
          if(field.value == null || field.value == undefined) // valore di default
            enumValues.push({'label': 'Non selezionato', 'value': field.value});
          for(let value of this.returnData.conf[field.id].config.enumValues) {
            enumValues.push({'label': value, 'value': value});
          }
          return enumValues;
        }
        else { // il campo non è enum ma ho comunque bisogno della tendina
          if(this.returnData.content[field.id] && this.returnData.content[field.id].enumValues) {
            return this.returnData.content[field.id].enumValues;
          }
          return null;
        }
      }
    },

    isEnum: function() {
      return field => {
        return ( 
          (this.returnData.conf && this.returnData.conf[field.id].config.type === 'enum') || // se il campo è enum
          (this.returnData.content[field.id] && this.returnData.content[field.id].enumValues)// se non è enum ma necessita di
        );
      }
    },

    checkDate: function() {
      return field => {
        return this.returnData.conf &&
           this.returnData.conf[field.id].config.type === 'date';
      }
    },

    checkTextArea: function() {
      return field => {
        return (this.returnData.conf &&
           this.returnData.conf[field.id].config.type === 'text');
      }
    }
  },

  created() {
    if(!this.$props.fdata || !this.$props.fdata.conf || !this.$props.fdata.content) {
      console.error('modalForm1 bad params', this.$props.fdata);
      notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
      );
      this.$bvModal.hide('modalForm1-' + this.parentName);
    }

    this.initialData = tool.genericFunctions.cloneObject(this.$props.fdata);
    this.setupModalForm();
  },
  methods: {
    setupModalForm() {
      this.returnData = tool.genericFunctions.cloneObject(this.$props.fdata);

      for(const key in this.$props.fdata.conf) {
        if(this.isValid[key] == undefined) 
          this.$set(this.isValid, key, true);
          
        if(this.isValid[key+'_nota'] == undefined) 
          this.$set(this.isValid, key+'_nota', true);

        // set default error strings for each field
        if(this.errorStrings[key] == undefined)
          this.$set(this.errorStrings, key, '');
        if(this.errorStrings[key+'_nota'] == undefined)
          this.$set(this.errorStrings, key+'_nota', '');
      }
    },

    getSpeseArray() {

      let retValue = [];

      if(!this.returnData.content2 || !this.returnData.conf)
        return retValue;
      const regex = /\b\d+\b(?!.*\b\d+\b)/;
      
      for(const [key, value] of Object.entries(this.returnData.content2)) {
        
        const index = regex.exec(key)[0];

        const conf = this.returnData.conf[key];

        let deleted = (conf && conf.data.deleted) ? true : false;

        if(!retValue[index]) {
          retValue.splice(index, 1, {index: index, content: {}, deleted: deleted});
        }
        retValue[index].content[key] = value;
      }
      
      retValue = retValue.filter(item => !item.deleted);

      return retValue;
      
    },
    computeNewIndex() {
      const regex = /\b\d+\b(?!.*\b\d+\b)/;
      let maxIndex = -1;
      for(const id of Object.keys(this.returnData.content2)) {
        const index = regex.exec(id)[0];
        maxIndex = Math.max(index, maxIndex);
      }
      return maxIndex + 1;
    },

    unformatValue(key, dataBundle) {
      if(this.checkDisabled({id: key}))
        return;
      if(dataBundle[key] != null && dataBundle[key] != undefined) {
        // console.log('prima', key, dataBundle[key].value);
        dataBundle[key].value = utils.unformatOutput(this.returnData.conf[key], dataBundle[key].value);
        // console.log('dopo', key, dataBundle[key].value);
      }
    },


    controlloFinale(action, data) {
        this.finale = !this.finale;
        let retVal = true;
        for(const key in this.returnData.content) {
          retVal = retVal && this.validate(key, this.returnData.content[key].value);
          retVal = retVal && this.validateNota(key, this.returnData.content[key]);
          this.unformatValue(key, this.returnData.content);
        }

        for(const key in this.returnData.content2) {
            retVal = retVal && this.validate(key, this.returnData.content2[key].value);
            retVal = retVal && this.validateNota(key, this.returnData.content2[key]);
            this.unformatValue(key, this.returnData.content2);
        }
        if(retVal)
          this.$emit(action, data);
        else
          return false;
      
    },

    validateNota(key, field) {
      
      const noteId = key + '_nota';
      const fieldValue = field.value;
      // niente validazione se il campo è eliminato
      const conf = this.returnData.conf[key];

      if(conf.data.added || conf.data.deleted) {
          return true;
      }

      let isDatumChanged = false;
      if(this.initialData) {
        if(this.initialData.content[key]) {
          if(this.initialData.content[key].value != fieldValue) {
            isDatumChanged = true;
          }
        } else if(this.initialData.content2[key]) {
          if(this.initialData.content2[key].value !== fieldValue)
            isDatumChanged = true;
        }
      }
      const nota = conf.data.note;
      if((!nota) && conf.config.mandatoryNote) {
        if(isDatumChanged || (!isDatumChanged && conf.data.edited)) {
          if(this.isValid[noteId] != undefined)
            this.isValid[noteId] = false;
          if(this.errorStrings[noteId] != undefined)
            this.errorStrings[noteId] = validation.strings.mandatoryNote;
        }
      }
      else {
          if(this.isValid[noteId] !== undefined)
            this.isValid[noteId] = true;
          if(this.errorStrings[noteId] !== undefined)
            this.errorStrings[noteId] = '';
      }
      return this.isValid[noteId];
    },

    validate(id, value) {
      let config = this.returnData.conf[id];
      let tab;
      if(this.$parent.$parent && this.$parent.$parent.title)
        tab = this.$parent.$parent.title;
      //passo tutta la scheda di monitoraggio ed i dati aggiornati della form per avere visibilità di tutti i dati disponibili
      const errors = validation.defaultValidation(config, value, tab, this.sch, this.returnData); 
      if(errors.length > 0) {
        this.errorStrings[id] = errors[0];
      }
      else {
        this.errorStrings[id] = '';
      }
      return errors.length === 0;
    }
  }
}
</script>
