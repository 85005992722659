<template>
    <div>
        <div class="table-title">Controlli in Loco Progetto: {{codiceProgetto}} </div>
            <b-card-body v-if="schedaProgetto !== null">
                <Collapse :name="'Finanziari'">
                    <GenericList
                        :id="'listaInLocoFinanziari'" 
                        :title="''"
                        :busyTable="isBusy"
                        :createEntity="this.canCreate('Finanziario')"
                        :headerTable="tableControlliHeader"
                        :dataTable="controlliFase('Finanziario')"
                        :actionsInRow="this.$getActionsListaInLoco"
                        :actionsMapping="this.objMapping"
                        :blockButton="isBusy"
                        @clickActionList="doAction"/>
                </Collapse>
                <Collapse :name="'Operativi'">
                    <GenericList
                        :id="'listaInLocoOperativi'" 
                        :title="''"
                        :busyTable="isBusy"
                        :createEntity="this.canCreate('Operativo')"
                        :headerTable="tableControlliHeader"
                        :dataTable="controlliFase('Operativo')"
                        :actionsInRow="this.$getActionsListaInLoco"
                        :actionsMapping="this.objMapping"
                        :blockButton="isBusy"
                        @clickActionList="doAction"/>
                </Collapse>
            </b-card-body>
            <b-modal
                id="modalFormNuovaCheckList"
                hide-footer
                size="xl"
                hide-header-close
                no-close-on-backdrop
                scrollable
                centered
                dialog-class="modal1-content"
                content-class="modal1-content">
                <template  v-slot:modal-title>
                    <div v-if="popupFin">
                        <h3>Crea Nuova CheckList di Controllo In Loco Finanziario</h3>
                    </div>
                    <div v-if="popupOpe">
                        <h3>Crea Nuova CheckList di Controllo In Loco Operativo</h3>
                    </div>
                </template>
                <selezioneCheckListControlloFinanziario v-if="popupFin" 
                    @closeModalCheckList="closeModal"
                    :controllato="controlliFase('Finanziario').length>0"
                    />
                <selezioneCheckListControlloOperativo v-if="popupOpe"
                    @closeModalCheckList="closeModal"
                    />
            </b-modal>
            <b-modal
                id="modalNuovaIrregolarita"
                :title="'Nuova Segnalazione per il progetto ' + codiceProgetto"
                hide-footer
                size="xl"
                no-close-on-backdrop
                scrollable
                centered
            >
            <modalNuovaIrregolarita
                :isBusy="isBusy"
                :codiceProgetto="codiceProgetto"
                :processDefKey="'ProgettoSegnalazioneContr'"
                @closeModalCreateIrregolarita="closeModalCreateIrregolarita"
            />
            </b-modal>
    </div>
</template>

<script>
import Collapse from "@/components/collapse.vue"
import selezioneCheckListControlloFinanziario from "./selezioneCheckListControlloFin.vue"
import selezioneCheckListControlloOperativo from "./selezioneCheckListControlloOpe.vue"
import modalNuovaIrregolarita from "../irregolarita/modalNuovaIrregolarita.vue"
import notify from "@/helpers/notifications.js"
import { mapGetters } from "vuex"
import GenericList from "@/components/genericList.vue"
import endpoints from "@/components/endpoints.vue";


export default {
    name: "listaControlliAmministrativiProgetto",
    components: {
        Collapse,
        selezioneCheckListControlloFinanziario,
        selezioneCheckListControlloOperativo,
        modalNuovaIrregolarita,
        GenericList
    },
    data(){
        return {
            schedaProgetto: null,
            componentId: "listaControlliAmministrativiProgetto",
            popupFin: false,
            popupOpe: false,
            codiceProgetto: "",
            monitoraggio: {},
            controllo: {},
            controlli: [],
            filter: null,
            sortBy: "idAttivita",
            tableControlliHeader: [
                {
                    key: "idSchedaControllo",
                    label: "Id Controllo"
                },
                {
                    key: "sottoTipoControllo", 
                    label: "Tipologia"
                },
                {
                    key: "dataInserimento",
                    formatter: "dateFormatter",
                    label: "Data Inserimento",
                },
                {
                    key: "titoloControllo",
                    label: "Titolo Controllo"
                },
                {
                    key: "dataVerifica",
                    formatter: "dateFormatter",
                    label: "Data del Controllo"
                },
                {
                    key: "verificatore",
                    label: "Verificatore"
                },
                {
                    key: "stato",
                    label: "Stato"
                },
                {
                    key: "Azioni"
                }
            ],
            objMapping: {
                view: "Visualizza Controllo",
                edit: "Modifica Controllo",
                unclaim: "Rilascia presa in carico",
                claim: "Prendi in carico",
            },
            isBusy: true,
            role: "sconosciuto"


        };
    },
    created(){
      this.codiceProgetto = this.$route.params.codiceProgetto
    },
    mounted(){
        this.prepareData()
    },
    computed: {
        ...mapGetters({
        getScheda: "controlli/getScheda"
        }),

        controlliFase() {
          return tipologia => {
            if (this.controlli && this.controlli.length){
                return this.controlli.filter(controllo => {
                    return (controllo.sottoTipoControllo === tipologia
                          && controllo.stato !== "Eliminato dall'utente"
                          && ( 
                               (this.role.includes('UfficioGestione') 
                               || (this.role.includes('UfficioControlli')) 
                               || this.role.includes('AutoritaResponsabile')) 
                               || this.role.includes('Visualizzatore')
                               //Ufficio Gestione e Controlli vede sempre tutte le schede in loco in tutti gli stati
                                                                                   
                            || (
                                controllo.stato !== "Avviato"  //I beneficiari vedono le schede solo dopo lo stato iniziale
                                    && (
                                        this.role.includes('UfficioEconomicoFinanziario')
                                        || this.role.includes('Beneficiario')
                                    )
                                )
                            || ( this.role.includes('TaskForceLie') //la taskForce vede solo i controlli in loco in cui è stata coinvolta
                                    && controllo.sottoTipoControllo == 'Operativo'
                                    && controllo.richiestoParereLIE                                    
                                )
                            || ( this.role.includes('ReferenteStrategico') //il referente strategico vede solo i controlli in loco in cui è stata coinvolta
                                    && controllo.sottoTipoControllo == 'Operativo'
                                    && controllo.richiestoParereICT
                                )
                            )
                    )
                })
            } else return [];
          }
        },
    },
    methods: {
        async prepareData() {
            // Carico la scheda Progetto (per un progetto in stato Concluso il bottone di creazione non sarà visibile)
            await this.getSchedaProgettoByCodice()
            this.role = this.$getUserRole(); 
            this.loadControls();
        },
        async getSchedaProgettoByCodice() {
            let codice_progetto = this.$route.params.codiceProgetto
            this.$get(endpoints.getSchedaProgettoWithIdMax(codice_progetto))
            .then(scheda => {
                this.schedaProgetto = scheda
            })
            .catch((error) => {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },
        canCreate(tipologia){
            if(!this.schedaProgetto){
                return "";
            }
            /* MEV02:REF004
            * Eliminazione del controllo sullo del progetto: anche checklist dei progetti conclusi possono create o modificate
			*/
            if (tipologia === 'Operativo' && this.role.includes("UfficioGestione-Operatore")){
                return "Nuovo Controllo " + tipologia;      //label del pulsante
            } else if (tipologia === 'Finanziario' && this.role.includes("UfficioControlli-Operatore")){
                return "Nuovo Controllo " + tipologia;
            }else {
                return "";                              //label vuota significa non posso nemmeno vedere il pulsante (vedi implementazione componente genericList)
            }
        },

        closeModal(){
            this.popupFin = false;
            this.popupOpe = false;
            this.$bvModal.hide('modalFormNuovaCheckList')
        },

        loadControls(){
            const codiceProgetto = this.codiceProgetto;
            if (!codiceProgetto){
                console.error("loadControls: codiceProgetto NULL!");
                return;
            }
            
            //Setto nello store i controlli relativi a questo progetto
            this.$store.dispatch("controlli/getAllSchedeInLoco",
                { codiceProgetto: codiceProgetto }
            ).then(
                (schedeControlli) => {
                    this.controlli = schedeControlli;
                    console.log("trovate "+this.controlli.length+" schede controllo in loco per il progetto "+codiceProgetto);
                    this.isBusy = false;
                }
            ).catch( error => {
                console.error('Errore store dispatch getAllSchedeInLoco: ', error.message)
                notify.error(notify.strings.error, notify.strings.errorLoadControlli);
            })
        },

        doAction(action, data) {
            if (!data) {
                console.log("scheda controllo vuota", data);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                return;
            }
            
            let idControllo = null;
            if (data && data.item && data.item.idSchedaControllo)
                idControllo = data.item.idSchedaControllo;
            
            const urlc = endpoints.claimControllo+idControllo;
            const urlu = endpoints.unclaimControllo+idControllo;       

            switch (action) {
                case "createEntity":    //ogni volta che viene cliccato il pulsante nuova Check List
                    if (data.id=='listaInLocoFinanziari'){
                        this.popupFin = true;
                        this.popupOpe = false;
                        this.$bvModal.show('modalFormNuovaCheckList');
                    } else if(data.id=='listaInLocoOperativi'){
                        this.popupOpe = true;
                        this.popupFin = false;
                        this.$bvModal.show('modalFormNuovaCheckList');
                    }
                    break;
                case "edit": 
                    this.$router.push({ name: "schedaControlliInLoco" , params: { 
                        codiceProgetto: this.$route.params.codiceProgetto,
                        idControllo: idControllo
                    }})
                    break;   
                    //modifica e visualizza si spostano ugualmente di pagina, i permessi sono gestiti dopo
                case "view":
                    this.$router.push({ name: "schedaControlliInLoco" , params: { 
                            codiceProgetto: this.$route.params.codiceProgetto,
                            idControllo: idControllo
                        }
                    });
                    break;
                case "claim":
                    this.claimUnclaim(urlc);
                    break;
                case "unclaim":
                    this.claimUnclaim(urlu);
                    break;
                case "start_irr":
                    this.openModalCreateIrregolarita();
                    break;  
                default:
                    console.error("Unknown action!", action);
                    break;
            }
        },

        claimUnclaim(url) {
            //console.log("invoco URL=", url);
            // appena si fa click su claim/unclaim i pulsanti si devono disabilitare
            this.isBusy = true;    
            this.$post(url)
            .then((result) => {
                if (url.includes('unclaim'))
                    console.log("UNCLAIM success", result);
                else 
                    console.log("CLAIM success", result);
                
                //refresh della pagina
                this.loadControls();
            },
            (error) => {
                console.error("ERRORE: ", error.message);
                if (url.includes('unclaim'))
                    notify.error(notify.strings.error, notify.strings.errorUnclaimScheda);
                else
                    notify.error(notify.strings.error, notify.strings.errorClaimScheda);
                throw error;
            });
        },
        openModalCreateIrregolarita(){
            this.$bvModal.show('modalNuovaIrregolarita')
        },
        closeModalCreateIrregolarita(){
            this.$bvModal.hide('modalNuovaIrregolarita')
        },
    },
};
</script>
