<template>
    <div :id="id">
      <h1 class="text-center m-2">{{title}}</h1>
      <div>
        <b-row no-gutters>
          <div><h4 class="mx-2">Filtra i risultati:</h4></div>
          <b-col cols="4">
            <b-form-input
              v-model="genericFilter"
              type="search"
              id="filterInList"
              placeholder="Digita un filtro" />
          </b-col>
          <b-col cols="2" class="ml-3">
            <b-button
              id="filterInListBtn"
              class="mt-1"
              :disabled="!genericFilter"
              @click="genericFilter = ''">Cancella</b-button>
          </b-col>
          <b-col v-if="createEntity !== ''">
            <b-button
              style="margin-left: auto;"
              class="align-right mr-4"
              variant="outline-primary"
              :disabled="blockButton"
              @click="doAction('createEntity', {id: id})">
            <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
              {{ createEntity }}
            </b-button>
          </b-col>
        </b-row>
        
        <b-table
          :busy="busyTable"
          primary-key="id"
          emptyText="Nessun dato disponibile"
          sort-asc
          :sort-by="sortBy"
          :filter="genericFilter"
          emptyFilteredText="Nessun dato disponibile"
          :items="rowsVisible"
          :fields="headerTable"
          show-empty
          hover
          responsive>
        <template v-slot:table-busy>
          <div class="text-center">
            <strong>Lista in Caricamento...</strong>
          </div>
        </template>
        <template v-slot:cell(Azioni)="data">
          <b-button
            :id="namingIdIcon(x, data.index + 1)"
            variant="outline-primary"
            size="sm"
            :key="x"
            v-for="x in data.value"
            :title="mappingTooltip(x)"
            :disabled="blockButton"
            @click="doAction(x, data)">
          <fa-icon
            v-if="x === 'view'"
            :icon="['far', 'eye']"
            class="selector-icons"/>
          <fa-icon
            v-if="x === 'edit'"
            :icon="['far', 'edit']"
            class="selector-icons"/>
          <fa-icon
            v-if="x === 'claim'"
            :icon="['far', 'hand-paper']"
            class="selector-icons"/>
          <fa-icon
            v-if="x === 'unclaim'"
            :icon="['fas', 'times-circle']"
            class="selector-icons"/>
          <fa-icon
            v-if="x === 'view_controlli_list'"
            :icon="['fas', 'search-dollar']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'start_pref'"
            :icon="['fas', 'hand-holding-usd']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'start_irr' || x === 'view_irregolarita_list'"
            :icon="['fas', 'exclamation-triangle']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'delete'"
            :icon="['far', 'trash-alt']"
            class="selector-icons"/>
          
          </b-button>
        </template>
      </b-table> 
      </div>
    </div>
</template>
<script>
import utils from "@/helpers/utils.js";
export default {
  name: "genericList",
  props: {
    // ----------------- PROP MANDATORY ----------------------------
    id: { type: String, required: true },
    title: { type: String, required: true  },
    headerTable: { type: Array, required: true  },
    dataTable: { type: Array, required: true  },
    actionsInRow: { type: Function, required: true },
    // -------------------------------------------------------------
    busyTable: { type: Boolean, default: function() { return false } },
    blockButton: { type: Boolean },
    sortBy: {type: String, default: function() { return '' } },
    actionsMapping: { type:Object, default: function() { return {} } },
    createEntity: { type: String, default: function() { return '' } }
  },
  data() {
    return {
      genericFilter: null,
      rowsVisible: []
    }
  },
  watch: {
    'dataTable': function() {
      this.extractData();
    },

  },
  mounted() {
    this.extractData();
  },
  methods: {
    // --------- SEZIONE FORMATTERS ----------
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },
    dateTimeFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    currencyFormatter(value) {
      if (value == undefined || value == null)
        return null;
      return utils.formatCurrency(value);
    },
    booleanFormatter(value) {
      if (!value) 
        return "NO";
      return utils.formatBoolean(value);
    },
    beneficiarioFormatter(value) {
      if (!value) 
        return "Sconosciuto";
      return utils.formatBeneficiari(value);
    },
  

    // --------- SEZIONE FORMATTERS ----------
    // CERCA UN VALORE
    /*
    findProp(obj, key, out) {
    let i
    let proto = Object.prototype
    let ts = proto.toString
    let hasOwn = proto.hasOwnProperty.bind(obj)

    if ('[object Array]' !== ts.call(out)) out = [];

    for (i in obj) {
      if (hasOwn(i)) {
        if (i === key) {
            out.push(obj[i]);
        } else if ('[object Array]' === ts.call(obj[i]) || '[object Object]' === ts.call(obj[i])) {
            this.findProp(obj[i], key, out);
        }
      }
    }
    return out
    },
    */
    extractData() {
      const size = this.rowsVisible.length;
      this.rowsVisible.splice(0, size);

      this.dataTable.forEach(item => {
        let row = item;
        // if (item === null) return []
        // this.headerTable.forEach(obj => {
        //   let key = obj.key
        //   let valueCell = this.findProp(item, key)
        //   row[key] = valueCell.length !== 0 ? valueCell[0] : ''
        // })
        // POPOLAMENTO DELLE AZIONI IN ROW
        row.Azioni = this.actionsInRow(item, this.id);
        
        if(row.Azioni.length > 0) {
          this.rowsVisible.push(row)
        }
      })
    },
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(this.id, action, index)
    },
    mappingTooltip(keyMapping) {
      if(Object.keys(this.actionsMapping).length === 0) return "";
      let temp = this.actionsMapping[keyMapping];
      if (!temp) return "";
      return temp;
    },
    doAction(action, data) {
      this.$emit('clickActionList', action, data)
    }
  },
}
</script>