<template>
  <div>
    <b-table-lite :id="idTablePdf" thead-class="head" :items="tabledata.rows" :fields="tabledata.header">
      <template v-slot:cell(Azione)="data">
          <b-button :id="namingIdButton(x, data.index + 1)" variant="outline-primary" :key="x" v-for="x in data.value" size="sm" v-on:click="doAction(x, data.index)">
              <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
          </b-button>
      </template>
    </b-table-lite>

    <b-modal :id="'modalForm1-' + this.name" hide-footer
             size="lg"
             scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content">
      <template v-slot:modal-title>
        <h3>Modifica valore</h3>
      </template>
      <modalForm1 :fdata="this.editFormData"
                  :sch="this.getScheda()"
                  :buttons="this.buttons"
                  :parentName="this.name"
                  :cfg="mappa"
                  @editField="editRiga"/>
    </b-modal>
  </div>
</template>

<script>
  import modalForm1 from "@/components/modalForm1.vue";
  import tool from '@/helpers/tools.js'
  import utils from '@/helpers/utils.js'
  import notify from "@/helpers/notifications.js"
  import { JSONPath as jpath } from 'jsonpath-plus';
  import validation from '@/helpers/validations.js';
  import mapUtils from '@/helpers/mapUtils.js'
  import { mapGetters } from "vuex";
  import calculation from "@/helpers/calculations.js";

  export default {
    name: "generale",
    components: {
      modalForm1,
    },
    props: {
      tabName: {
        type: String,
        default: ''
      },
      actionAllowed: {
        type: Array,
        default: () => (['edit'])
      },
      store: {
        type: String,
        default: ''
      },
      rowIds: {
        type: Array,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      title: String,
      wakeUp: {
        type: Boolean // Attiva forzatamente il componente
      },
      idTablePdf: { type: String, default: () => '' }
    },
    computed: {
      ...mapGetters({
        getTabelloneProgetto : "configuration/getTabellone",
        getSchedaProgetto : "progetto/getSchedaProgetto",
        getSchedaComplete : "progetto/getScheda",
        //  EXECUTING BODY
        getTabellone_EB : "executingBody/getTabelloneExecuting",
        getScheda_EB: "executingBody/getSchedaProgetto",
        getSchedaComplete_EB : "executingBody/getScheda"
      })
    },
    watch: {
      tabName: function() {
        if (this.itsMyTurn())
          this.extractData()
      },
      wakeUp: function() {
        this.extractData()
      }
    },
    data() {
      return {
        editFormData: {},
        editLine: 0,
        buttons: [
          {
            name: 'Modifica',
            action: 'editField',
            show: true,
            param: 'edit'
          },
        ],
        tabledata: {
          header: [
            {
              key: "Campo",
            },
            {
              key: "Descrizione",
              tdAttr: this.tooltip
            },
            {
              key: "Azione"
            },
          ],
          "rows": []
        },
        mappa: {}
      };
    },

  methods: {
    getScheda() {
      let scheda = this.store !== '' && this.store === 'executing'
        ? this.getScheda_EB({idSchedaProgetto: this.$route.params.idExecutingBody})
        : this.getSchedaProgetto({idSchedaProgetto: this.$route.params.idProgetto});
      return scheda;
    },
    updateSchedaProgettoDinamically(scheda) {
      if(this.store !== '' && this.store === 'executing') {
        this.$store.dispatch('executingBody/updateSchedaProgettoObject',
          {
            idSchedaProgetto: this.$route.params.idExecutingBody,
            schedaProgetto: scheda
          })
          this.$emit('executingUpdate')
      } else {
        this.$store.dispatch('progetto/updateSchedaProgettoObject',
          {
            idSchedaProgetto: this.$route.params.idProgetto,
            schedaProgetto: scheda
          })
        // Serve per il calcolo dei campi obbligatori mancanti per schedaConvenzione
        if(this.store === 'convenzione')
          this.$emit('convenzioneUpdate')
      }
    },
    tooltip(value, key, item) {
      if(item.note)
        return {title: 'nota: ' + item.note};
      return {};
    },

    setupRow(rowId, value) {
      let mapElement = mapUtils.getMapElement(this.mappa, rowId);
      if(!mapElement) {
        console.error('no config, get default one for ', rowId);
        mapElement = this.$getDefaultConfig();
        mapElement.config.path = rowId;
      }
      const formattedVal = utils.formatOutput(mapElement, value);
      const scheda = this.getScheda();


      const dataMap = scheda.dataEntryConfiguration;
      let data = {};
      if(dataMap && dataMap[rowId] && dataMap[rowId].data)
        data = dataMap[rowId].data;
      let row = {
        id: rowId,
        Campo: mapElement.config.label,
        Descrizione: formattedVal,
        _cellVariants: {}
      };
      if (data.edited && !this.$disableEditAddColors(scheda)) {
          const nomeColonna = this.tabledata.header[1].key;
          row._cellVariants[nomeColonna] = data.isSubstancial ? 'danger': 'warning';
      }
      if (data.note) //il settaggio di una nota è indipendente dal fatto che il campo è stato editato o meno
        row.note = data.note;
      return row;
    },
    itsMyTurn() {
      // se è stato cliccato il tab corrispondente oppure
      // se il componente è un collapse del tab corrispondente
      return (this.tabName === this.name || this.tabName === this.$parent.$parent.$parent.$parent.title);
    },
    extractData() {
      if(!this.rowIds || !this.mappa) {
        console.error('GenericComponent: Invalid rowIds/mappa',
                    this.rowIds, this.mappa);
        return;
      }
      let updateScheda = false;
      let scheda = this.getScheda();
      if(!scheda) {
        console.error('GenericComponent: Invalid scheda', scheda);
        return;
      }

      this.tabledata.rows = []
      const actions = this.populateActionsDinamically();
      for(const rowId of this.rowIds) {
        let mapElement = this.mappa[rowId];
        if(!mapElement) {
          console.error('no config, get default one for ', rowId);
          mapElement = this.$getDefaultConfig();
          mapElement.config.path = rowId;
        }
        let result;
        
        // Se ho una calculationRule calcolo il valore
        if(mapElement.config.calculationRule) {
          if(this.store === 'executing')
            result = calculation.calculateValue(mapElement, this.getSchedaComplete_EB({idSchedaProgetto: this.$route.params.idExecutingBody}), rowId);
          else
            result = calculation.calculateValue(mapElement, this.getScheda(), rowId);
          const retVal = mapUtils.createElementFromScratch(rowId, scheda, this.mappa);
          retVal.object = result;
          scheda = retVal.scheda;
        } else { // Altrimenti lo prendo dalla scheda
          const results = jpath('$'+rowId, scheda);
          if(results.length === 0) {
            // console.log('valore non trovato in scheda: ', rowId);
            const retVal = mapUtils.createElementFromScratch(rowId, scheda, this.mappa);
            scheda = retVal.scheda;
            result = retVal.object;
            updateScheda = true;
          }
          else if (results.length === 1) {
            result = results[0];
          }
          else if (results.length > 1) {
            // Gestione tipi array: Concateno tutti gli elementi dell'array in una stringa
            result = this.concatenateArrayInString(results);
          } else {
            console.error(this.name, 'Undefined element is an array?', rowId, results);
          }
        }
        let row = this.setupRow(rowId, result);
        
        // se l'elemento è modificabile oppure il suo maintab è il tab allora
        // si prende in considerazione l'azione di edit
        // aggiunta fix per i GenericComponent nei collapse
        if(!mapElement.config.readonly && 
           (mapElement.config.tab === this.name || this.$parent.$parent.$parent.$parent.title) ) {
          if(actions.indexOf('edit') !== -1 && this.actionAllowed.indexOf('edit') !== -1) {
            row.Azione = ['edit'];
          } else if(actions.indexOf('edit_parere_nota_EB') !== -1 && this.actionAllowed.indexOf('edit_parere_nota_EB') !== -1) {
            row.Azione = ['edit'];
          }
        }
        
        this.tabledata.rows.push(row);
      }
      if(updateScheda) {
          this.updateSchedaProgettoDinamically(scheda);
      }
      
    },
    concatenateArrayInString(array) {
      // crea un array con soli obiettivi specifici distinti
      const newArray = array.filter((x, i, a) => a.indexOf(x) === i);
      return newArray.toString().replace(',', ', ');
    },
    editRiga(actionFromModal) {
      this.$bvModal.hide('modalForm1-' + this.name);
      const scheda = this.getScheda();
      let clonedScheda = tool.genericFunctions.cloneObject(scheda);
      let config = clonedScheda.dataEntryConfiguration;
      if(!config) {
        config = {};
      }
      for(let [key, field] of Object.entries(actionFromModal.content)) {
        const mapElement = mapUtils.getMapElement(this.mappa, key);
        // ritorno al valore non formattato (ad esempio in caso di currency)
        let updatedValue = utils.unformatOutput(mapElement, field.value);
        if(updatedValue == undefined) {
          // nessuna azione se il nuovo valore è undefined
          continue;
        }
        let updatedConfiguration = actionFromModal.conf[key];
        const jPathResult = jpath({resultType: 'all'}, '$'+key, clonedScheda)[0];
        const parent = jPathResult ? jPathResult.parent : undefined;
        if(parent == undefined) {
          console.log('error: should exist', key, jPathResult);
          notify.error(notify.strings.error, notify.strings.operationError('Modifica di ' + updatedConfiguration.config.label));
          continue;
        }
        let fieldToUpdate = jPathResult.parentProperty;

        // update configuration
        if(!config[key]) {
          config[key] = {};
        }
        if(parent[fieldToUpdate] !== updatedValue && 
            !((parent[fieldToUpdate] == null || parent[fieldToUpdate] == undefined) && updatedValue === '')
        ){ // se il campo è stato modificato (tranne se settiamo a stringa vuota un campo null o undefined)
          // allora controllo la configurazione
          //if(!updatedConfiguration.data.edited) { // se è la prima modifica setto il flag edited e salvo il valore originale
            
              updatedConfiguration.data.edited = true; 
              updatedConfiguration.data.oldValue = parent[fieldToUpdate];
            // }
            
          /*TODO: migliorare questo controllo, al momento non funziona bene su progetti appena creati...
          } else {

            if(updatedValue == updatedConfiguration.data.oldValue || // se è stato riportato il valore iniziale (anche se nullo)
              ((updatedConfiguration.data.oldValue == null || updatedConfiguration.data.oldValue == undefined) && updatedValue === '')
            ) {
              // se il valore era già stato modificato
              updatedConfiguration.data.oldValue = null;              // e stiamo riportando il valore all'originale
              updatedConfiguration.data.edited = false;          // ripristino i valori all'originale e cancello la nota
              updatedConfiguration.data.note = '';                // warning: solo == e non === altrimenti problemi di typeof
            }
          }
          */

          parent[fieldToUpdate] = updatedValue; // setto il nuovo valore
          if(updatedConfiguration.config.canBeSubstancial) {
            config[key].data = updatedConfiguration.data; // configurazione prima del controllo substancial
            updatedConfiguration.data.isSubstancial = validation.substancialValidationProgetto(key, clonedScheda, this.mappa, updatedValue);
          }

          config[key].data = updatedConfiguration.data; // configurazione aggiornata
        }
        else {
          if(!config[key].data) {
            config[key].data = {}
          }
          // aggiorno comunque la nota a prescindere dalla modifica
          // if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
          config[key].data.note = updatedConfiguration.data.note;
        }
      }
      clonedScheda.dataEntryConfiguration = config;
      this.updateSchedaProgettoDinamically(clonedScheda)
      // this.$store.dispatch('progetto/updateSchedaProgettoObject',
      //   {
      //     idSchedaProgetto: this.$route.params.idProgetto,
      //     schedaProgetto: clonedScheda
      //   }
      // );
      // update displayed data
      for(const [id, updatedValue] of Object.entries(actionFromModal.content)) {
        let currentRow = this.tabledata.rows[this.editLine]
        const updatedRow = this.setupRow(id, updatedValue.value);
        for(const [key, value] of Object.entries(updatedRow)) {
          currentRow[key] = value;
        }
      }
    },

    // Costruzione id per Buttoni (Azioni nel componente)
    namingIdButton(action, index) {
      return this.$builtIdDinamically(this.tabName, action, index)
    },

    doAction(actionToDo, index)
    {
      switch(actionToDo) {
        case 'edit':
          {
            this.editFormData = {content:{}, conf: {}};
            const id = this.tabledata.rows[index].id;
            // get fresh data from storage
            const scheda = this.getScheda();
            const values = jpath('$'+id, scheda);
            let mapElement = mapUtils.getMapElement(this.mappa, id);
            const dataEntry = scheda.dataEntryConfiguration;

            if(dataEntry && dataEntry[id]) {
              mapElement.data = tool.genericFunctions.cloneObject(dataEntry[id].data);
            }
            else {
              mapElement.data = this.$getDefaultData();
            }
            let value = {'id': id};
            if(values.length === 0) {
              console.error('valore non trovato in scheda: ', id);
            }
            else {
              value.value = values[0];
            }
            this.editFormData.conf[id] = mapElement;
            this.editFormData.content[id] = tool.genericFunctions.cloneObject(value);
            this.editLine = index;
            this.$bvModal.show('modalForm1-' + this.name);
          }
          break;
        default:
          this.editFormData = {};
          break;
      }
    },
    populateActionsDinamically() {
      return this.store !== '' && this.store === 'executing'
        ? this.$executingBodyGetActions(this.getSchedaComplete_EB({idSchedaProgetto: this.$route.params.idExecutingBody}))
        : this.$projectGetActions(this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto}));
    },
    populateMappaDinamically() {
      if(this.store !== '' && this.store === 'executing')
        return this.getTabellone_EB;
      else if(this.store === 'convenzione')
        return this.getTabelloneProgetto('convenzione');
      else
        return this.getTabelloneProgetto('progetto');
    },
  },
  mounted() {
    this.mappa = this.populateMappaDinamically();
    if(this.mappa) {
      this.extractData();
    }
    else
      console.log(this.name, 'error in mappa', this.mappa);
  }
};
</script>

