<template>
    <div>
      <b-overlay :show="!loadComplete || generationPdf" rounded="sm">
      <div class="table-title">Scheda Prefinanziamento per il Progetto: {{this.$route.params.codiceProgetto}} </div>
      <div>

        <b-table-lite
          thead-class="head"
          :items="this.tabledata.rows"
          :fields="this.tabledata.header"
          v-if="loadComplete" />

        <b-card no-body>
            <b-tabs card nav-class="tabs" content-class="content">
                <ColorLegend/>
                <b-tab
                    v-if="loadComplete && isViewAllowed('Anagrafica')"
                    no-body
                    title="Anagrafica"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['anagrafica'].name)">
                    <GeneralViewerCard
                        :idTablePdf="conf_export_pdf['anagrafica'].id_table_pdf"
                        :name="conf_export_pdf['anagrafica'].name" 
                        :tabName="tabSelection"
                        :pathDataEntryConfiguration="'content'"
                        :store="'circuitoFinanziario'"
                        :fnGetterTab="'getTabellone'"
                        :headerTable="headerAnagrafica"
                        :rowIds="rowIds_anagrafica"
                        :fnGetter="'getScheda'"
                        :idEntity="{
                            codiceProgetto: this.$route.params.codiceProgetto,
                            id: this.$route.params.idPrefinanziamento
                        }"
                        :actions="actionsMap[mappingTabs[1]]"
                        :fnSetter="'updateScheda'"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete"
                    no-body
                    title="Nota di Anticipo"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['nota_anticipo'].name)">
                    <GeneralViewerCard
                        :idTablePdf="conf_export_pdf['nota_anticipo'].id_table_pdf"
                        :name="conf_export_pdf['nota_anticipo'].name" 
                        :tabName="tabSelection"
                        :pathDataEntryConfiguration="'content'"
                        :store="'circuitoFinanziario'"
                        :fnGetterTab="'getTabellone'"
                        :headerTable="headerAnagrafica"
                        :rowIds="rowIds_nota_anticipo"
                        :fnGetter="'getScheda'"
                        :idEntity="{
                            codiceProgetto: this.$route.params.codiceProgetto,
                            id: this.$route.params.idPrefinanziamento
                        }"
                        :fnSetter="'updateScheda'"
                        :actions="actionsMap[mappingTabs[2]]"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete && isViewAllowed('Documentazione')"
                    no-body
                    title="Documentazione"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['documentazione'].name)">
                    <Documentazione
                        :ownerProcessStatus= this.retrieveTaskDefinitionKey
                        :actions="actionsMap[mappingTabs[3]]"
                        :entity = this.store
                        :getters = this.getters
                        :setters = this.setters
                        :payload = this.payload
                        :riferimento = this.riferimento
                        tabName="Documentazione"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete && isViewAllowed('Controllo')"
                    no-body
                    title="Checklist"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['checklist_pref'].name)">
                    <Controllo
                        :idTablePdf="conf_export_pdf['checklist_pref'].id_table_pdf"
                        :title="conf_export_pdf['checklist_pref'].nameCollapse"
                        :tabName="tabSelection.toLowerCase()"
                        :config="getTabellone"
                        :dataObject="'controllo'"
                        :store="'circuitoFinanziario'"
                        :fnGetter="'getScheda'"
                        :fnSetter="'updateScheda'"
                        :idEntity="{
                            codiceProgetto: this.$route.params.codiceProgetto,
                            id: this.$route.params.idPrefinanziamento
                        }"
                        name="Check List Controllo"
                        :headerTable="headerTableCheckList"
                        :actions="actionsMap[mappingTabs[4]]"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete && isViewAllowed('Invio')"
                    no-body
                    title="Invio"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['Invio_mandatory'].name)">
                    <Invio
                     :idsCustomEdit="ids_custom_edit"
                     :tabName="tabSelection" 
                     :config="getTabellone"
                     :idsToCheck="rowIds_nota_anticipo"
                     :actions="actionsMap[mappingTabs[5]]"
                     @buildPdf="generateReport"
                    />
                </b-tab>
            </b-tabs>
        </b-card>
      </div>
      </b-overlay>
    </div>
</template>

<script>
import Invio from "./invio.vue"
import Documentazione from "@/components/Documentazione.vue"
import Controllo from '@/views/controlli/CheckListSingleControl.vue'
import GeneralViewerCard from '@/components/GeneralViewerCard.vue'
import notify from "@/helpers/notifications.js"
import { mapGetters } from "vuex"
import utils from "@/helpers/utils.js"
import tools from '@/helpers/tools.js'
import helper_pdf from "@/helpers/exportPdf.js"
import ColorLegend from "@/components/legend.vue"


export default {
  name: "schedaPrefinanziamento",
  components: {
    GeneralViewerCard,
    Controllo,
    Invio,
    Documentazione,
    ColorLegend
  },
  data() {
    return {
        //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
        riferimento : {
            idOggettoRif: "",
            tipoOggettoRif: "Scheda Prefinanziamento", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
            idBando: "",
            tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
            idProgetto: "",
            codiceProgetto: ""
        },
        mappingTabs: {
            1: 'Anagrafica',
            2: 'NotaAnticipo',
            3: 'Documentazione',
            4: 'Controllo',
            5: 'Invio'
        },
        conf_export_pdf: {
            "anagrafica": {
                id_table_pdf: 'anagrafica_pref',
                name: "Anagrafica",
                title_page_pdf: 'Anagrafica',
                nameCollapse: '',
            },
            "nota_anticipo": {
                id_table_pdf: 'nota_anticipo_pref',
                name: "Nota di Anticipo",
                title_page_pdf: 'Nota di Anticipo',
                nameCollapse: '',
            },
            "documentazione": {
                id_table_pdf: 'fileTable',
                name: "Documentazione",
                title_page_pdf: 'Documentazione',
                nameCollapse: '',
            },
            "checklist_pref": {
                id_table_pdf: 'checklist_pref',
                name: "Controllo",
                title_page_pdf: 'CheckList',
                nameCollapse: 'CheckList Controllo',
            },
            "Invio_mandatory": {
                id_table_pdf: 'mandatory_pref',
                name: "Invio",
                title_page_pdf: 'Invio',
                nameCollapse: 'Campi obbligatori non valorizzati',
            },
            "Invio_note": {
                id_table_pdf: 'note_pref',
                name: "Invio",
                title_page_pdf: 'Invio',
                nameCollapse: 'Elenco Note',
            },
            "Invio_note_riservate": {
                id_table_pdf: 'note_riservate_pref',
                name: "Invio",
                title_page_pdf: 'Invio',
                nameCollapse: 'Elenco Note Interne',
            },
            // L'ESITO FINALE DEL CONTROLLO SARA' INSERITO IN FONDO NELL'ULTIMA PAGINA DISPONIBILE
            "Invio_esito_finale": {
                id_field: "['content']['esitoFinale']",
                name: "Invio",
                title_page_pdf: 'Dettagli Invio',
                conf_field: { addPage: true, mapping: 'PosNeg' },
                descRadio: 'Esito finale del controllo: '
            }
        },
        ids_custom_edit: [
            // IDS soggetti a edit Custom
            // considerare nel computo degli obbligatori
            "['anagrafica']['autore']",           // edit uffEconOper
            "['anagrafica']['dataInserimento']",  // edit uffEconOper
        ],
        headerTableCheckList: [
            { key: 'descrizione' },
            { key: 'esito', label: 'Esito' },
            { key: 'docsRiferimento', label: 'Documentazione di riferimento' },
            { key: 'filesRiferimento', label: 'Documenti di riferimento' },
            { key: 'azione' }
        ],
        headerAnagrafica: [
            { key: "campo" },
            {
                key: "descrizione",
                tdAttr: this.tooltip,
                formatter: ""
            },
            { key: "azione" }
        ],
        rowIds_anagrafica: [
          "['anagrafica']['dataInserimento']",
          "['anagrafica']['autore']",
          "['anagrafica']['idBeneficiario']",
          "['anagrafica']['codiceProgetto']",
          "['anagrafica']['titoloProgetto']",
          "['anagrafica']['strumentoFinanziario']",
          "['anagrafica']['costoProgetto']",
          "['anagrafica']['percentualePrefinanziamento']",
          "['anagrafica']['importoPrefinanziamento']",
          "['anagrafica']['dataFirmaDecreto']",
          "['anagrafica']['dataFirmaConvenzione']",
        ],
        rowIds_nota_anticipo: [
          "['notaDiAnticipo']['dataOrdine']",
          "['notaDiAnticipo']['numeroProtocollo']",
          "['notaDiAnticipo']['dataProtocollo']",
          "['notaDiAnticipo']['importo']"
        ],
        schedaPrefinanziamento: {},
        tabSelection: "Anagrafica",
        store: "circuitoFinanziario",
        actionsMap: {},
        loadComplete: false,
        generationPdf: false,
        fakeStatus: "Prefinanziamento_prefinanziamento-concluso_Nessuno-Nessuno" ,
        getters: {
            scheda: "getScheda",
            tabellone: "getTabellone"
        },
        setters: {
            scheda: "updateScheda",
            allegatoDaCancellare: "setAllegatoDaCancellare",
            clearAllegatiDaCancellare: "clearAllegatiDaCancellare"
        },
        payload: {
            scheda: {          
            codiceProgetto: this.$route.params.codiceProgetto, 
            id: this.$route.params.idPrefinanziamento
            },
        },
        tabledata: {
            header: [
                {
                    key: "codiceProgetto"
                },
                {
                    key: "titolo"
                },
                {
                    key: "stato"
                },
                {
                    key: "dataConclusione",
                    formatter: this.dateFormatter
                }
            
            ],
            rows: [
                {
                    "codiceProgetto": "Non disponibile",
                    "titolo": "Non disponibile",
                    "stato": "Non disponibile",
                    "dataConclusione": null
                }
            ],
        },

    }
  },
  mounted() {
    this.loadPrefinanziamentoAndConfigs(this.$route.params.codiceProgetto, this.$route.params.idPrefinanziamento);
    this.riferimento.idOggettoRif = this.$route.params.idPrefinanziamento;
    this.riferimento.codiceProgetto = this.$route.params.codiceProgetto;
  },
  computed: {
    ...mapGetters({
        getTabellone: "circuitoFinanziario/getTabellone",
        getScheda: "circuitoFinanziario/getScheda"
    }),
    retrieveTaskDefinitionKey() {
      if (this.schedaPrefinanziamento 
            && this.schedaPrefinanziamento.content 
            && this.schedaPrefinanziamento.content.taskInfo 
            && this.schedaPrefinanziamento.content.taskInfo.taskDefinitionKey)
        return this.schedaPrefinanziamento.content.taskInfo.taskDefinitionKey
      else 
        return this.fakeStatus
    }
  },
  methods: {
    isEsitoFinaleVisible() {
      const role = this.$getUserRole();
      return role.includes('UfficioEconomicoFinanziario');
    },
    generateReport(optPdf) {
        this.generationPdf = true;
        // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)
        // Inserisco l'esito finale solo quando su Invio il relativo RadioButton sarà visibile
        if(this.isEsitoFinaleVisible()) {
            let source = {
                scheda: this.getCurrentScheda()
            }
            this.conf_export_pdf['Invio_esito_finale'].source = source
        } else delete this.conf_export_pdf['Invio_esito_finale']
        // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)
        helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
        this.generationPdf = false;
    },
    getCurrentScheda() {
      let scheda = this.getScheda({
          codiceProgetto: this.$route.params.codiceProgetto,
          id: this.$route.params.idPrefinanziamento
        })
      return tools.genericFunctions.cloneObject(scheda)
    },
    dateFormatter(value) {
      if (!value) 
        return "Non disponibile";
      return utils.formatDate(value);
    },
    populateTabledata(){
      //agisco direttamente sulla prima riga inizializzata con "Non disponibile"
      this.tabledata.rows[0].codiceProgetto = this.schedaPrefinanziamento.codiceProgetto;
      if (this.schedaPrefinanziamento.content.infoProgetto){
        
        if (this.schedaPrefinanziamento.content.infoProgetto.titolo)
          this.tabledata.rows[0].titolo = this.schedaPrefinanziamento.content.infoProgetto.titolo;
        
        if(this.schedaPrefinanziamento.content.infoProgetto.stato)  
          this.tabledata.rows[0].stato = this.schedaPrefinanziamento.content.infoProgetto.stato;

        if(this.schedaPrefinanziamento.content.infoProgetto.dataConclusione)  
          this.tabledata.rows[0].dataConclusione = this.schedaPrefinanziamento.content.infoProgetto.dataConclusione;
      
      }
    },
    setTabSelection(name) {
      this.tabSelection = name
    },
    loadActionMap(){
      let scheda = this.schedaPrefinanziamento;
      
      // Prendo la lista degli IDs dei tabs previsti per questa scheda dal mappingTabs
      let allIndices = Object.keys(this.mappingTabs)
        for (const index of allIndices) {
            let tabName = this.mappingTabs[index]
            this.actionsMap[tabName] = this.$getActionsPrefinanziamento(scheda, tabName)
        }
    },
    isViewAllowed(tabName) { //se ho diritto ad almeno un'azione su questo TAB devo vederlo
      return this.actionsMap[tabName].length > 0
    },
    loadTabellone(){
        this.$store.dispatch("circuitoFinanziario/setTabellone", {
            scheda: this.schedaPrefinanziamento
        }).then(() => {
            this.populateTabledata();
            //carico tutte le azioni concesse per i vari TAB
            this.loadActionMap();
            this.loadComplete = true;
        }).catch(error => {
            console.error('schedaPrefinanziamento: Errore in loadTabellone: ', error.message)
            notify.error(notify.strings.error, notify.strings.errorGetPrefinanziamento)
        })
    },
    loadPrefinanziamentoAndConfigs(codice, id){
        this.$store.dispatch("circuitoFinanziario/setPrefinanziamento",
        {
            codiceProgetto: codice,
            idPrefinanziamento: id
        }).then((scheda)=> {
            this.schedaPrefinanziamento = scheda;
            console.log(this.schedaPrefinanziamento);
            if (scheda.content.infoProgetto.idProgetto){
                this.riferimento.idProgetto = scheda.content.infoProgetto.idProgetto;
                this.riferimento.tipoBando = scheda.content.infoProgetto.modalitaDiAccesso;
            }
            this.loadTabellone();
        }).catch(error => {
            console.error('Errore store dispatch: ', error.message)
            notify.error(notify.strings.error, notify.strings.errorGetPrefinanziamento)
        })
    }
  }
}
</script>