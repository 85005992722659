<template>
  <div>
    <b-form inline class="m-1" style="display: flex;">
      <label for="filterProgettiInput"
        ><h4 class="mx-2">Filtra i risultati:</h4></label
      >
      <b-form-input
        v-model="filter"
        type="search"
        id="filterProgettiInput"
        placeholder="Digita un filtro"
      />
      <b-button
        id="filterProgettiButton"
        class="mx-1"
        :disabled="!filter"
        @click="filter = ''"
        >Cancella</b-button
      >
      
    </b-form>
    <b-table
      :busy="isBusy"
      primary-key="id"
      emptyText="Nessun dato disponibile"
      :sort-by="sortBy"
      sort-desc
      :filter="filter"
      emptyFilteredText="Nessun dato disponibile"
      :filter-included-fields="getFilterFields()"
      :items="tabledata.rows"
      :fields="tabledata.header"
      show-empty
      hover
      responsive
    >
      <template v-slot:table-busy>
        <div class="text-center">
          <strong>Lista in Caricamento...</strong>
        </div>
      </template>
      <template v-slot:cell(Azioni)="data">
        <b-button
          :id="namingIdIcon(x, data.index + 1)"
          :disabled="clicked"
          variant="outline-primary"
          size="sm"
          :key="x"
          :title="mappingTooltip(x)"
          v-for="x in data.value"
          @click="doAction(x, data)"
        >
          <!-- start_graduatoria_process e claim hanno la stessa icone
          perche' la start_graduatoria_process fa il claim del task in automatico  -->
          <fa-icon
            v-if="x === 'start_graduatoria_process'"
            :icon="['far', 'hand-paper']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'view'"
            :icon="['far', 'eye']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'claim'"
            :icon="['far', 'hand-paper']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'unclaim'"
            :icon="['fas', 'times-circle']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'edit_in_list_graduatoria'"
            :icon="['far', 'edit']"
            class="selector-icons"
          />
        </b-button>
      </template>
    </b-table>
    
  </div>
</template>
<script>

import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js";
import endpoints from "@/components/endpoints.vue";

export default {
  name: "listaProgettiAmmessiCFP",
  components: {
  },
  data() {
    return {
      clicked: false,
      actions: ["view", "claim", "unclaim", "start_graduatoria_process", "edit_in_list_graduatoria"],
      sortBy: "dataInserimento",
      filter: null,
      isBusy: false,
      tabledata: {
        header: [
          {
            key: "idBando",
            label: "ID Bando",
            sortable: true,
          },
          {
            key: "titoloBando",
            label: "Titolo",
            sortable: true,
          },
          {
            key: "dataInserimento",
            sortable: true,
            filterByFormatted: true,
            formatter: this.dateTimeFormatter,
            label: "Data",
          },
          {
            key:"dataFineProgetto",
            label:"Data Fine Progetto",
            filterByFormatted: true,
            formatter: this.dateFormatter,
          },
          {
            key: "risorse",
            label: "Risorse",
            formatter: this.currencyFormatter,
            sortable: true,
          },
          {
            key: "strumentoFinanziario",
            label: "Str. Finanziario",
            sortable: true,
          },
          {
            key: "ICT",
            label: "Parere ICT",
            formatter: this.parereIctFormatter,
            sortable: true,
          },
          {
            key: "stato",
            sortable: true,
          },
          {
            key: "Azioni",
            thClass: "thst",
          },
        ],
        rows: [],
      },
      listaBandi: []
    };
  },
  mounted() {
    this.extractData();
  },
  methods: {
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },
    dateTimeFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    currencyFormatter(value) {
      if (value == undefined || value == null) return null;
      return utils.formatCurrency(value);
    },
    parereIctFormatter(value){
      return value ? 'Richiesto' : 'Non richiesto'
    },
    beneficiarioFormatter(value) {
      if (!value) 
      return "Sconosciuto";
      return utils.formatBeneficiari(value);
    },
    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      let idBando = data.item.idBando
      this.clicked = true;
      switch (action) {
        case "start_graduatoria_process":
          if (data && data.item){
            let urlAvvioProcesso = endpoints.startProgettoGraduatoria;
            let myBody = { 
              'idScheda': idBando, // rappresenta idBando su BpmController di progetti
            };
            return this.$post(urlAvvioProcesso, myBody)
                .then(
                    (resp) => {
                        console.log("Processo di Convenzione per il Bando "+idBando+" avviato correttamente");
                        let processId = resp.id;
                        if (processId!=null && processId!=undefined){
                          // scarico la schedaconvenzione appena creata
                          // dall'update state di SchedaConvenzioneController
                          this.$store.dispatch(
                              "convenzione/setSchedaConvenzione",
                              { idBando: idBando }
                            ).then(result => {
                              // verifico che abbia l'array progetti per il bando popolato
                              if (result &&
                                    result.schedaConvenzione &&
                                    result.schedaConvenzione.progetti &&
                                    result.schedaConvenzione.progetti.length > 0){
                                let matches = this.listaBandi.filter((i)=>i.infoBando.idBando === idBando)
                                if (matches.length == 1){
                                  // salvo la response al proprio posto in lista bandi
                                  matches[0].schedaConvenzione = result.schedaConvenzione
                                  // aggiorno lo stato del task dato che il processo e' adesso avviato
                                  // di fatto rimuovendo il fake taskDefinitionKey
                                  matches[0].taskInfo = result.taskInfo
                                  // faccio claim del task al volo e aggiorno l'icona in tabella
                                  this.updateTableAfterAction(data, 'claim', idBando)                                 
                                } else {
                                  throw new Error("Error: no matching bando found in this.listaBandi for bando " + idBando)
                                }
                              }
                              else
                                throw new Error("schedaConvenzione object is null")  
                            })
                            .catch(err => {
                              notify.error(
                                notify.strings.error,
                                notify.strings.internalErrorPleaseReportAction
                              );
                              this.clicked = false;
                              console.log("Errore caricamento schedaConvenzione.", err.message);
                            })
                        }   
                    })
                .catch(
                    (error) => {
                        console.error('Start ProgettoConvenzione process error:', error.message);
                        notify.error(notify.strings.error, notify.strings.errorStartProcessoGraduatoria);
                        this.clicked = false;
                        throw error;   
                    })
          } else {
            console.error("Impossibile avviare il processo di convenzione per progetti in stato=", data.item.stato);
            this.clicked = false;
          }
          break;
        case "view":
          this.$router.push({
            name: "graduatoria",
            params: { idBando: idBando },
          });
          break;
        case "claim":
          this.updateTableAfterAction(data, 'claim', idBando)
          break;
        case "unclaim":
          this.updateTableAfterAction(data, 'unclaim', idBando)
          break;
        case "edit_in_list_graduatoria":
          this.$router.push({
            name: "graduatoria",
            params: { idBando: idBando },
          });
          this.clicked = false;
          break;
        default:
          console.error("Unknown action!", action);
          this.clicked = false;
          break;
      }
    },
    /**
     * @param {string} data: table data
     * @param {string} taskAction: claim/unclaim
     * @param {string} idBando: id del Bando dell'oggetto in this.listaBandi da aggiornare
     */
    updateTableAfterAction(data, taskAction, idBando){
      this.$store
        .dispatch("announcements/claim_unclaim_SchedaBando", {
          type: taskAction,
          idSchedaBando: idBando
        })
        .then(
          (resp) => {
            // aggiorno l'oggetto taskInfo in listabandi
            let matches = this.listaBandi.filter((i)=>i.infoBando.idBando === idBando)
            if (matches.length == 1)
              matches[0].taskInfo = resp.taskInfo
            // aggiorno le actions in tabella
            const azioni = this.$getActionsPerBandoConvenzione(resp);
            const updateActions = azioni.filter((item) => {
              return this.actions.indexOf(item) != -1;
            });
            let obj = resp.infoBando;
            obj.Azioni = updateActions;
            data.item.Azioni = obj.Azioni;
            this.clicked = false;
          },
          (error) => {
            notify.error(
              notify.strings.error,
              notify.strings.internalErrorPleaseReportAction
            );
            console.error("claim/unclaim error", error);
            this.clicked = false;
          }
        );
    },
    // legge this.listabandi e crea l'oggetto per la btable
    extractData: async function() {
      let userIdGroup = this.$getUserGroupId();
      if(!userIdGroup.includes("Beneficiari")){
        this.tabledata.header.splice(2, 0, {
            key:"beneficiario",
            sortable: true, 
            formatter:"beneficiarioFormatter",
            sortByFormatted: true
          });
      }
      this.isBusy = true;
      const url = endpoints.getBandiConvenzione;

      try {
        let result = await this.$get(url)
        console.log("Bandi scaricati: "+result.length);
        //se non sono operatore devo far vedere solo i bandi che hanno un processo (graduatoria) attivo
        let bandiFiltrati = result.filter(
          bando => (bando.taskInfo
                   && bando.taskInfo.taskDefinitionKey != 'Progetto_convenzioneDaAvviare_Nessuno-Nessuno'     //questo task è tipico di processo non avviato
                   && bando.taskInfo.taskDefinitionKey != 'Progetto_graduatoria_UfficioGestione-Operatore'  //questo task è tipico del primo step del processo
                    )
        );
        let roles = this.$getUserRoles();
        if (roles[0] !== 'UfficioGestione-Operatore'){ //deve esistere almeno un ruolo per ogni utente loggato
          console.log("Bandi Filtrati:", bandiFiltrati.length);
          this.listaBandi = bandiFiltrati;
        } else {
          this.listaBandi = result;
        }
        
        console.log("Bandi eligibili: "+this.listaBandi.length);
        this.listaBandi.forEach(
          (elem) => {
              const azioni = this.$getActionsPerBandoConvenzione(elem);
              const localActions = azioni.filter((item) => {
                return this.actions.indexOf(item) != -1;
              });
              if (localActions.length > 0) {  
                const ib = elem.infoBando
                const sc = elem.schedaConvenzione
                // prendo lo stato dalla scheda convenzione invece che dal bando
                // prima di avviare il processo graduatoria, schedaconvenzione e' undefined
                const stato = (sc && sc.statoConvenzione) ? sc.statoConvenzione : 'Graduatoria in bozza'
                let newRow = {
                  //modalitaDiAccesso: info.modalitaDiAccesso,
                  idBando: ib.idBando,
                  titoloBando: ib.titoloBando,
                  dataInserimento: ib.dataInserimento,
                  dataFineProgetto: ib.dataConclusione,
                  stato: stato,
                  strumentoFinanziario: ib.strumentoFinanziario,
                  risorse: ib.risorse,
                  ICT: ib.richiestoParereICT,
                  Azioni: localActions,
                  beneficiario: ib.idBeneficiario
                };
                this.tabledata.rows.push(newRow);
              }
            })
            this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        console.log("errore caricamento lista",error);
      }
    },
    mappingTooltip(keyMapping) {
      let objMapping = {
        start_graduatoria_process: "Presa in carico", // perche' avvia il processo
        // e lo prende in carico  con lo stesso click
        edit_in_list_graduatoria: "Modifica Dati",
        view: "Visualizza Dati Graduatoria",
        claim: "Presa in carico",
        unclaim: "Rilascio presa in carico",
      };
      let temp = objMapping[keyMapping];
      if (!temp) return "";
      return temp;
    },
    getFilterFields() {
      let filterFields = [];
      for (let item of this.tabledata.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
  },
};
</script>
