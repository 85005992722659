<template>
  <div>
    <b-table-lite
        :id="idTablePdf"
        thead-class="head"
        :items="tabledata.rows"
        :fields="tabledata.header">
      <template v-slot:cell(Azione)="data">
          <b-button :id="namingIdButton(x, data.index + 1)" variant="outline-primary" :key="x" v-for="x in data.value" size="sm" v-on:click="doAction(x, data.index)">
              <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
          </b-button>
      </template>
    </b-table-lite>
    <b-modal :id="'modalForm1-' + componentConfig.name" hide-footer
             size="lg"
             scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content">
      <template v-slot:modal-title>
        <h3>Modifica valore</h3>
      </template>
      <modalForm1 :fdata="editFormData"
                  :sch="getScheda()"
                  :buttons="buttons"
                  :parentName="componentConfig.name"
                  :cfg="mappa"
                  @editField="editRiga"/>
    </b-modal>
  </div>
</template>

<script>
  import modalForm1 from "@/components/modalForm1.vue";
  import tool from '@/helpers/tools.js'
  import utils from '@/helpers/utils.js'
  import notify from "@/helpers/notifications.js"
  import { JSONPath as jpath } from 'jsonpath-plus';
  import validation from '@/helpers/validations.js';
  import mapUtils from '@/helpers/mapUtils.js'
  import { mapGetters } from "vuex";
  import calculation from "@/helpers/calculations.js";

  export default {
    name: "Generic2",
    components: {
      modalForm1,
    },
    props: {
      idTablePdf: { type: String, default: () => '' },
      componentConfig: {
        type: Object,
        required: true,
        default: () => {
          return {
            defaultActions: ['edit', 'delete'],
          }
        }
      },
      updateVertical1: Boolean,
      notifyUpdate: Boolean,
      storeModule: String,
      idScheda: String,
      tabelloneType: String
    },
    computed: {
        ...mapGetters({
            getTabellone: "configuration/getTabellone"
        }),
    },
    data() {
        return {
            title: '',
            editFormData: {},
            editLine: 0,
            buttons: [
                {
                    name: 'Modifica',
                    action: 'editField',
                    show: true,
                    param: 'edit'
                },
            ],
            tabledata: {
                header: [
                    {
                        key: "Campo",
                    },
                    {
                        key: "Descrizione",
                        tdAttr: this.tooltip
                    },
                    {
                        key: "Azione"
                    },
                ],
                "rows": []
            },
            mappa: {}
        };
    },

    methods: {
        getScheda() {
            return this.$store.getters[this.storeModule + "/getScheda"]({
                idScheda: this.idScheda,
            });
        },
        namingIdButton(action, index) {
            return this.$builtIdDinamically(this.componentConfig.name, action, index)
        },
        tooltip(value, key, item) {
            if(item.note)
                return {title: 'nota: ' + item.note};
            return {};
        },
        updateSchedaInStore(scheda) {
            this.$store.dispatch(this.storeModule +  "/setScheda", {
                idScheda: this.idScheda,
                scheda: scheda,
            });
        },

        setupRow(rowId, id, value, scheda) {
          let mapElement = mapUtils.getMapElement(this.mappa, rowId);
          if(!mapElement) {
            console.error(this.componentConfig.name, 'no config, get default one for ', rowId);
            mapElement = this.$getDefaultConfig();
            mapElement.config.path = id;
          }
          const formattedVal = utils.formatOutput(mapElement, value);
          const dataMap = scheda.dataEntryConfiguration;
          let data = {};
          if(dataMap && dataMap[id] && dataMap[id].data)
            data = dataMap[id].data;
          let row = {
            id: rowId,
            Campo: mapElement.config.label,
            Descrizione: formattedVal,
            _cellVariants: {}
          };
          if (data.edited && !this.$disableEditAddColors(scheda)) {
            // if(data.oldValue != null) {
              const nomeColonna = this.tabledata.header[1].key;
              row._cellVariants[nomeColonna] = data.isSubstancial ? 'danger': 'warning';
              // console.log("setto il colore giallo o rosso", row._cellVariants[nomeColonna]);
            // }  
          }
          if (data.note) //il settaggio di una nota è indipendente dal fatto che il campo è stato editato o meno
            row.note = data.note;
          return row;
        },

        extractData() {
            if(!this.componentConfig.dinamicIds || !this.mappa) {
                console.error(this.componentConfig.name, 'Invalid rowIds/mappa',
                this.componentConfig.dinamicIds, this.mappa);
                return;
            }
            let updateScheda = false;
            const schedaFromStore = this.getScheda();
            if(!schedaFromStore) {
                console.error(this.componentConfig.name, 'Invalid scheda', schedaFromStore);
                return;
            }
            let scheda = tool.genericFunctions.cloneObject(schedaFromStore);
            // console.log(this.idScheda, scheda);

            this.tabledata.rows = []
            const allowedActions = this.componentConfig.allowedActions;
            const defaultActions = this.componentConfig.defaultActions;
            for(const rowId of this.componentConfig.dinamicIds) {
                let mapElement = this.mappa[rowId];
                if(!mapElement) {
                    console.error(this.componentConfig.name, 'no config, get default one for ', rowId);
                    mapElement = this.$getDefaultConfig();
                    mapElement.config.path = rowId;
                }
                const alfaId = this.componentConfig.alfaId;
                const id = rowId.replace('*', "'" + alfaId + "'");

                let retVal = this.getValue(id, mapElement, scheda);        

                scheda = retVal.scheda;
                const result = retVal.result;
                updateScheda = updateScheda || retVal.updateScheda;
                
                let row = this.setupRow(rowId, id, result, scheda);
                // console.log(id, result, row);
                // WARNING: nessun controllo presente su maintab!
                if(!mapElement.config.readonly) {
                  row.Azione = defaultActions.filter(
                    item => {
                      return allowedActions.indexOf(item) !== -1;
                    }
                  )
                }
                this.tabledata.rows.push(row);
            }
            if(updateScheda) {
                this.updateSchedaInStore(scheda);
            }
        },

        getValue(colId, mapElement, scheda) {
            let result;
            let updateScheda = false;
            const results = jpath("$" + colId, scheda);
            // se il campo non esiste nella scheda viene creato
            // e si aggiorna la scheda
            let retVal;
            if (results.length === 0) {
                // stampo un log solo se il valore non deve essere calcolato
                // altrimenti è probabile che esso non sia presente nella scheda
                if(!mapElement.config.calculationRule) {
                  console.log('valore non trovato in scheda: ', colId);
                }
                retVal = mapUtils.createElementFromScratch(
                  colId,
                  scheda,
                  this.mappa
                );
                scheda = retVal.scheda;
                result = retVal.object;
                
                updateScheda = true;
            } else if (results.length === 1) {
                result = results[0];
            } else {
                console.error(this.componentConfig.name, "is an array?", colId, results);
                result = this.concatenateArrayInString(results);
            }

            // a prescindere dal fatto che sia presente in scheda o meno, 
            // se è prevista una calculation rule allora il campo
            // viene ricalcolato e il result viene sovrascritto
            // qui si inserisce il valore calcolato se previsto dal tabellone
            if(mapElement.config.calculationRule) {
                result = calculation.calculateValue(mapElement, scheda, colId);
                const results1 = jpath({resultType: 'all'}, "$" + colId, scheda);
                if(!results1 || results1.length !== 1) {
                  console.error(this.componentConfig.name, '-> id per calculation non trovato', colId);
                }
                else {
                  const parent = results1[0].parent;
                  const fieldName = results1[0].parentProperty;
                  parent[fieldName] = result;
                  updateScheda = true;
                }
            }

          if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
              mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda);
          }

            return {
              scheda: scheda,
              result: result,
              updateScheda: updateScheda
            }
        },

        concatenateArrayInString(array) {
          // crea un array con soli obiettivi specifici distinti
          const newArray = array.filter((x, i, a) => a.indexOf(x) === i);
          return newArray.toString().replace(',', ', ');
        },
        editRiga(actionFromModal) {
          this.$bvModal.hide('modalForm1-' + this.componentConfig.name);
          const scheda = this.getScheda();
          let clonedScheda = tool.genericFunctions.cloneObject(scheda);
          let config = clonedScheda.dataEntryConfiguration;
          if(!config) {
            config = {};
          }
          for(let [key, field] of Object.entries(actionFromModal.content)) {
            // console.log('ricevuta', key);
            const mapElement = mapUtils.getMapElement(this.mappa, key);
            // ritorno al valore non formattato (ad esempio in caso di currency)
            let updatedValue = utils.unformatOutput(mapElement, field.value);
            if(updatedValue == undefined) {
              // nessuna azione se il nuovo valore è undefined
              continue;
            }
            let updatedConfiguration = actionFromModal.conf[key];
            // key contiene gli asterischi e serve a recuperare i mapElement
            // nell'id invece l'asterisco è sostituito dall'id alfanumerico dell'elemento
            // WARNING: non gestisce asterischi multipli
            const alfaId = this.componentConfig.alfaId;
            const id = key.replace('*', "'" + alfaId + "'");

            const jPathResult = jpath({resultType: 'all'}, '$'+ id, clonedScheda)[0];
            const parent = jPathResult ? jPathResult.parent : undefined;
            // let parentArray = parent; // nel caso del budget rimodulato evito un'altra chiamata a jpath;
            // let arrayName = jPathResult? jPathResult.parentProperty : undefined;
            if(parent == undefined) {
              console.log('error: should exist', key, id, jPathResult);
              notify.error(notify.strings.error, notify.strings.operationError('Modifica di ' + updatedConfiguration.config.label));
              continue;
            }
            let fieldToUpdate = jPathResult.parentProperty;
            // let fieldToUpdate = jpath('$'+key+'~', clonedScheda)[0];

            // update configuration
            if(!config[id]) {
              config[id] = {};
            }
            if(parent[fieldToUpdate] !== updatedValue && 
                !((parent[fieldToUpdate] == null || parent[fieldToUpdate] == undefined) && updatedValue === '')
            ){ // se il campo è stato modificato (tranne se settiamo a stringa vuota un campo null o undefined)
              // allora controllo la configurazione
              //if(!updatedConfiguration.data.edited) { // se è la prima modifica setto il flag edited e salvo il valore originale
                
                // if ((scheda.stato=='In bozza' || scheda.stato=='Rinviato per modifiche')
                //     && scheda.vistoDa       //se il progetto è ritornato in questo stato ed è stato visto da N persone è in revisione
                //     && scheda.vistoDa.length > 0) {
                //   console.log("EDITED 312");
                  updatedConfiguration.data.edited = true; 
                  updatedConfiguration.data.oldValue = parent[fieldToUpdate];
                // }
                
              /*TODO: migliorare questo controllo, al momento non funziona bene su progetti appena creati...
              } else {

                if(updatedValue == updatedConfiguration.data.oldValue || // se è stato riportato il valore iniziale (anche se nullo)
                  ((updatedConfiguration.data.oldValue == null || updatedConfiguration.data.oldValue == undefined) && updatedValue === '')
                ) {
                  // se il valore era già stato modificato
                  updatedConfiguration.data.oldValue = null;              // e stiamo riportando il valore all'originale
                  updatedConfiguration.data.edited = false;          // ripristino i valori all'originale e cancello la nota
                  updatedConfiguration.data.note = '';                // warning: solo == e non === altrimenti problemi di typeof
                }
              }
              */

              parent[fieldToUpdate] = updatedValue; // setto il nuovo valore
              if(updatedConfiguration.config.canBeSubstancial) {
                config[id].data = updatedConfiguration.data; // configurazione prima del controllo substancial
                updatedConfiguration.data.isSubstancial = validation.substancialValidationProgetto(id, clonedScheda, this.mappa, updatedValue);
              }

              config[id].data = updatedConfiguration.data; // configurazione aggiornata
            }
            else {
              if(!config[id].data) {
                //console.log('create conf.data for ', key);
                config[id].data = {}
              }
              // aggiorno comunque la nota a prescindere dalla modifica
              // if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
              config[id].data.note = updatedConfiguration.data.note;
            }
          }
          clonedScheda.dataEntryConfiguration = config;
          this.updateSchedaInStore(clonedScheda);
          // in questo modo, oltre al refresh del dato appena modificato, vengono aggiornati
          // anche tutti i dati del tab
          this.extractData();
          // console.log(clonedScheda);
          // update displayed data
          // for(const [rowId, updatedValue] of Object.entries(actionFromModal.content)) {
          //   let currentRow = this.tabledata.rows[this.editLine];
          //   const alfaId = this.componentConfig.alfaId;
          //   const id = rowId.replace('*', "'" + alfaId + "'");
          //   const updatedRow = this.setupRow(rowId, id, updatedValue.value, clonedScheda);
          //   for(const [key, value] of Object.entries(updatedRow)) {
          //     currentRow[key] = value;
          //   }
          // }
        },

        doAction(actionToDo, index)
        {
          switch(actionToDo) {
            case 'edit':
              {
                const alfaId = this.componentConfig.alfaId;
                this.editFormData = {content:{}, conf: {}, rowId:alfaId};
                // key contiene asterischi
                // nell'id l'asterisco è sostituito dall'id alfanumerico dell'elemento
                const key = this.tabledata.rows[index].id;
                const id = key.replace('*', "'" + alfaId + "'");
                // get fresh data from storage
                const scheda = this.getScheda();
                const values = jpath('$' + id, scheda);
                let mapElement = mapUtils.getMapElement(this.mappa, id);
                const dataEntry = scheda.dataEntryConfiguration;

                if(dataEntry && dataEntry[id]) {
                  mapElement.data = tool.genericFunctions.cloneObject(dataEntry[id].data);
                }
                else {
                  mapElement.data = this.$getDefaultData();
                }
                let value = {'id': key};
                if(values.length === 0) {
                  console.error('valore non trovato in scheda: ', key, id);
                }
                else {
                  value.value = values[0];
                }
                this.editFormData.conf[key] = mapElement;
                this.editFormData.content[key] = tool.genericFunctions.cloneObject(value);
                this.editLine = index;
                this.$bvModal.show('modalForm1-' + this.componentConfig.name);
              }
              break;
            default:
              this.editFormData = {};
              break;
          }
        },
    },
    mounted() {
        this.title = this.componentConfig.title;
        if (this.tabelloneType)
          this.mappa = this.getTabellone(this.tabelloneType);
        if(this.mappa) {
            this.extractData();
        } 
        else 
          console.log(this.componentConfig.name, "error in mappa", this.mappa);
    }
};
</script>

