<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header"/>
            <b-button variant="danger" class="modal-default-button" @click="$emit('close')">
               <fa-icon :icon="['far', 'window-close']" class="close-icons"/>
            </b-button>
          </div>

          <div class="modal-body">
            <slot name="body" />
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <b-button variant="success" v-for="bt in buttons" :hidden="!bt.show" :key="bt.name" class="modal-default-button" @click="$emit(bt.action,bt.param)">
                {{bt.name}}
              </b-button>
              <b-button variant="danger" class="modal-default-button" @click="$emit('close')">
                Chiudi
              </b-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>
  
<script>
export default {
  name: "modal",
  props: {
    buttons:
    {
      type: Array,
      default: function() {
        return [
          /* sample button
          {
            name: 'Esegui',
            action: 'doSomething',
            show: true,
            param: 'value'
          }, */
        ]
      }
    }
  },
  data() {
    return {      
    };
  },
}
</script>
