import utils from '@/helpers/utils.js'
import { JSONPath as jpath } from 'jsonpath-plus';

  function setToValue(obj, path, value) {
    path = path.split('.');
    let i
    for(i = 0; i < path.length-1; i++) {
        let elem = path[i];
        if( !obj[elem] ) obj[elem] = {}
        obj = obj[elem];
    }

    obj[path[path.length-1]] = value;
    
        

    
    return obj
  }

  // Commentata per BUG #10838
  // function limitCharactersDesc (string) {
  //   let str = string
  //   let limit = 300;

  //   if (typeof str === 'string' && str.length > limit) {
  //     str = str.slice(0, limit) + '...';
  //   }
  //   return str;
  // }

  function updateSituationFiles(valueInScheda, allIdsAllegati, deletedFiles) {
    if(valueInScheda && valueInScheda.value && valueInScheda.value.chooseFiles) {
      let allFilesRiferimento = valueInScheda.value.chooseFiles
      if(allFilesRiferimento.length > 0) { 
        for (let i = allFilesRiferimento.length - 1; i >= 0; --i) {
          let idFile = allFilesRiferimento[i].fileId
          let check = allIdsAllegati.indexOf(idFile) > -1
          if (!check && allFilesRiferimento[i].fileId == idFile) {
            deletedFiles.push(allFilesRiferimento[i])
            allFilesRiferimento.splice(i,1)
          }
        }
        return {allFilesRiferimento: allFilesRiferimento, deletedFiles: deletedFiles }
      }
    }
    return {allFilesRiferimento: [], deletedFiles: [] }
  }

  function populateListFilesForModal(content) {
    let listModal = []
    if(!content || !content.allegati)
      return listModal;
    let files = content.allegati;
    Object.keys(files).forEach(key => {
      let objFile = {}
      let nomeFile = utils.filenameOnly(files[key].objectName.replaceAll('%20', ' '))
      let estremi = files[key].notes
      objFile.fileId = files[key].fileId
      objFile.text = nomeFile + ' - ' + estremi
      listModal.push(objFile)
    })
    return listModal
  }

  function handleEsitoIcon(data) {
    let allEsiti = data.map(element => { return element.esito })
    let genericCheck;// = { color: 'dark', type: 'circle-fill' }
    
    if(allEsiti.indexOf(null) > -1) {
      genericCheck = {  type:'circle', color:'secondary'  }
    } else {
      genericCheck = allEsiti.indexOf('Negativo') > -1 ? { type:'circle-fill', color:'danger' } : { type:'circle-fill', color:'success' }
    }
    return genericCheck
  }

  /**
   * 
   * @param {Object} config 
   * @param {Object} scheda 
   * Controlla se il campo della checkList è compilato e se ha allegati dei documenti
   */
  function checkValidValue(config, scheda) {
    let checkValid = true
    // checkValid  (true) --> campo obbligatorio compilato correttamente
    // !checkValid (false) --> campo obbligatorio NON compilato correttamente
    if(!config || !scheda) {
      console.error('checkValidValue bad params', config, scheda);
      return !checkValid
    }
    let values = jpath('$' + config.path, scheda)
    // Nessun valore in scheda (check --> NEGATIVO)
    if(values.length === 0 || values.length > 1) {
      return !checkValid
    }

    return checkValid;
    /* TASK #10609: rimossa obbligatorietà di flaggare l'allegato
    else {
      // Valore unico in scheda --> (check --> POSITIVO), se:
      // 1) docsRiferimento  === 0, POPOLAMENTO (ESITO) OBBLIGATORIO
      // 2) docsRiferimento    > 0, POPOLAMENTO (ESITO  & CHOOSEFILES) OBBLIGATORI
      if(
          (config.docsRiferimento.length === 0 && values[0].value.esito) ||
          (config.docsRiferimento.length > 0 && values[0].value.esito === 'N/A') || // BUG #9416: per esito N/A check è sempre valido (l'allegato non deve essere obbligatorio)
          (config.docsRiferimento.length > 0 && values[0].value.chooseFiles.length > 0 && values[0].value.esito)
        ) {
        return checkValid
      }
      // (check --> NEGATIVO)
      return !checkValid
    }*/
  }

export default {
    setToValue,
    // updatePuntualInStore,
    //limitCharactersDesc,
    populateListFilesForModal,
    checkValidValue,
    updateSituationFiles,
    handleEsitoIcon
}