import Vue from "vue";
import endpoints from '@/components/endpoints.vue';

export default {
    isValidUserInfo: function() {
        let user = Vue.prototype.$getUserInfo();
        if (user.roles[0] && user.groups[0])    //deve esistere almeno un gruppo di appartenenza ed un ruolo
            return user;
        else 
            return false;
    },

    isMonitoring: function(aree) {
        return aree && aree.indexOf("MONITORING")!=-1;
    },

    isCall: function(aree) {
        return aree && aree.indexOf("CALL")!=-1;
    },

    isEB: function(aree) {
        return aree && aree.indexOf("EXECUTING_BODY")!=-1;
    },
    
    isProject: function(aree) {
        return aree && aree.indexOf("PROJECT")!=-1;
    },

    isControl: function(aree) {
        return aree && aree.indexOf("CONTROL")!=-1;
    },

    isIrregolarita: function(aree) {
        return aree && aree.indexOf("IRREGOLARITA")!=-1;
    },

    isPagamenti: function(aree) {
        return aree && aree.indexOf("CONTROL")!=-1;
    },

    isProgettiIstruttoria: function(aree) {

        if(aree && aree.indexOf("PROJECT") === -1) {
            console.log("PROJECT area disabled");
            return false;
        }
        
        let userInfo = Vue.prototype.$getUserInfo();
        
        if (userInfo
            && userInfo.roles
            && userInfo.roles[0]                                   //dovrebbe esistere sempre almeno un ruolo
            && (userInfo.roles[0].includes('UfficioAcquisti')
                
                // NB: refstrat, reflie e uffcontrol non possono
                // essere esclusi dall'istruttoria perchè esprimono pareri
                // || userInfo.roles[0].includes('ReferenteStrategico')  //ruoli esclusi dal vedere questo link
                // || userInfo.roles[0].includes('TaskForceLie')
                // || userInfo.roles[0].includes('UfficioControlli')
                )
        ){
            return false;
        }

        return true;
    },

    isProgettiAmmessi: function(aree) {    //se non può vedere l'istruttoria non può vedere nemmeno le fasi successive
        return this.isProgettiIstruttoria(aree);
    },

    isDatisintesi: function(aree) {
        return aree && aree.indexOf("MONITORING")!=-1;
    },

    isScadenze: function(aree) {    //le scadenze periodiche sul calendario annuale a data costante vengono dal monitoraggio, quelle a data variabile dai controlli
        return aree && (aree.indexOf("MONITORING")!=-1 || aree.indexOf("CONTROL")!=-1);
    },

    leggiBandi: function() {
        const url = endpoints.listaBandi();
        return Vue.prototype.$get(url);
    },

    leggiProgetti: function() {
        const url = endpoints.retrieveAllSchedeProgetto;
        return Vue.prototype.$get(url);
    },

    leggiEB: function(){
        const url = endpoints.listSchedeExecutingBody;
        return Vue.prototype.$get(url);
    }

  };
  