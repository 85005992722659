import axios from "axios";
import utils from '@/helpers/utils.js';

export default {
    install(Vue) {


        function addCurrentProfileToHeader(header) {
            const currentProfileStr = sessionStorage.getItem('currentProfile');
            if(!currentProfileStr)
                return;
            
            const currentProfile = JSON.parse(currentProfileStr);

            const currentProfileHeader = ''.concat(currentProfile.group, ':', currentProfile.role);
            header.currentProfile = currentProfileHeader;
        }

        function getAuthHeader() {
            const headersContent = {
                token: utils.getSessionToken()
            };
            addCurrentProfileToHeader(headersContent);
            return headersContent;
        }

        Vue.prototype.$get = function(url, params) {
            return axios.get(url, { headers: getAuthHeader(), params: params }).then(
                result => {
                    return result.data;
                },
            );
        }

        Vue.prototype.$getAll = function(url, params) {
            return axios.get(url, { headers: getAuthHeader(), params: params }).then(
                result => {                 
                    return result;
                },
            );
        }

        Vue.prototype.$post = function(url, data, params) {

            let config = { headers: getAuthHeader() };
            if(params) {
                config.params = params;
            }

            return axios.post(url, data, config).then(
                result => {
                    return result.data;
                },
            );
        }

        Vue.prototype.$put = function(url, data) {
            return axios.put(url, data, { headers: getAuthHeader() }).then(
                result => {
                    return result.data;
                },
            );
        }

        Vue.prototype.$delete = function(url, params) {
            let config
            if (params) {
                config = { headers: getAuthHeader() , params: params};
            } else {
                config = { headers: getAuthHeader()};
            } 
            return axios.delete(url, config).then(
                result => {
                    return result.data;
                },
            );
        }
    }
}