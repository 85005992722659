<template>
    <b-container>
        <b-form @submit="creaIrregolarita">
        <b-row style="font-size: 14px; margin-bottom: 30px;">
            <b-col cols="9">
                <b-form-input v-model="titoloControllo" :disabled="isLoading || isStarting" required
                    placeholder="Titolo dell'irregolarità da avviare (Campo Obbligatorio)"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="modal-footer text-right">
                <b-button
                    class="btn-success"
                    type="submit"
                    :disabled="isLoading || isStarting">
                    Crea Nuova Irregolarità
                </b-button>
                <b-button
                    class="modal-default-button"
                    variant="danger"
                    @click="closeModal"
                    :disabled="isStarting">
                    Chiudi
                </b-button>
            </b-col>
        </b-row>
        </b-form>
    </b-container>
</template>

<script>
import endpoints from "@/components/endpoints.vue";
import notify from "@/helpers/notifications.js"
import feedFx from '@/helpers/feedFunctions.js'

export default {
    name: "modalNuovaIrregolarita",
    props: {
        codiceProgetto: { type: String, required: true},
        processDefKey : { type: String, required: true},
        isBusy: { type: Boolean}
    },
    data() {
        return {
            titoloControllo: null,
            isLoading: true,
            isStarting: false,
            schedaProgetto: {},
            idSchemaControllo: "irregolarita",
        }
    },
    mounted() {
        this.loadProjectByCodiceProgetto(this.codiceProgetto);
    },
    computed: {

    },
    methods: {
        closeModal(){
            this.$emit('closeModalCreateIrregolarita')
        },
        loadProjectByCodiceProgetto(codice){
            this.$get(endpoints.getProgettoByCodice(codice))
            .then( result => {
                this.schedaProgetto = result.schedaProgetto               
                this.isLoading = false
            })
            .catch( error => {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            })
        },
        creaIrregolarita(event){
            event.preventDefault()
            this.isLoading = true
            this.isStarting = true;
            this.buildNuovaIrregolarita()
            .then( res => {
                console.log("RES: ", res)
                this.isLoading = false
                this.isStarting = false;
                //Router Push
                this.$router.push({
                    name: 'schedaIrregolarita',
                    params: {
                        codiceProgetto: this.codiceProgetto,
                        idControllo: res.idSchedaControllo
                    }
                })
            })
            .catch((error) => {
                console.error("Errore:", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                this.isLoading = false
                this.isStarting = false;
            });
        },
        async buildNuovaIrregolarita(){
            try{
                let lastChecklist = await this.$get(endpoints.retrieveLastVersionTabellone(this.idSchemaControllo))
                let lastChecklistVersion = lastChecklist.lastVersion;

                let anagrafica = feedFx.feedAnagraficaIrregolarita(this.schedaProgetto);
                let infoProgetto = feedFx.feedInfoProgetto(this.schedaProgetto);

                let userIdGroup = this.$getUserGroupId();
                userIdGroup = userIdGroup.replace('/MINT/','');
                userIdGroup = userIdGroup.replace('/Beneficiari/','');
                const autore = this.$getUserInfo().preferred_username;

                const body = {
                    codiceProgetto: this.codiceProgetto,
                    idSchemaControllo: this.idSchemaControllo,
                    schemaVersione: lastChecklistVersion,
                    idBeneficiario: userIdGroup,
                    tipoControllo: "Irregolarita",
                    sottoTipoControllo: "Segnalazione",
                    autoreUltimoAggiornamento: autore,
                    strumentoFinanziario: infoProgetto.strumentoFinanziario,
                    content:{
                        titoloControllo: this.titoloControllo,
                        anagrafica: anagrafica,
                        autoreUltimoAggiornamento: autore,
                        infoProgetto: infoProgetto,
                        // autocontrollo: {},
                        // controllo: {},
                    }
                }
                const url = endpoints.getIrregolaritaByCodice(this.codice)
                let schedaCreata = await this.$post(url, body);
                if(!schedaCreata.idSchedaControllo && !schedaCreata.codiceProgetto){
                    let msg = "Scheda creata non valida. Controllare la scheda creata.."
                    console.error(msg, schedaCreata);
                    throw new Error(msg);
                }
                console.info(schedaCreata)
                const process = await this.avviaProcesso(schedaCreata.idSchedaControllo, schedaCreata.codiceProgetto);
                console.log("Processo avviato, ID: ", process.id);

                await this.autoclaim(schedaCreata.idSchedaControllo);
                return schedaCreata
            }
            catch (error){
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                throw error;
            }
        },
        async avviaProcesso(idSchedaControllo, codiceProgetto){
            const url = endpoints.startIrregolaritaProcess(this.processDefKey);
            console.log("Starting new process: URL: ", url)
            const body = {
                codiceProgetto: codiceProgetto,
                idScheda: idSchedaControllo
            }
            return this.$post(url, body)
        },
        async autoclaim(idSchedaControllo) {
            const url = endpoints.claimControllo+idSchedaControllo;
            return this.$post(url)
        },
    }
}
</script>

