<template>

    <div class="nav-bar" v-if="loadComplete">
        <div class="isf-navbar">
        <b-navbar toggleable="lg">

            <b-navbar-toggle target="nav-collapse"/>

            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item :to="{ name: 'secure'}" >Home</b-nav-item>
                <b-nav-item-dropdown text="Programma">
                    &nbsp; <strong class="isf-nav-dropdown-title">Gestione Bandi</strong>
                    <b-dropdown-divider/>
                    <b-dropdown-item class="isf-nav-dropdown-item" :to="{name: 'listaBandi', params: {type: 'CALL_FOR_PROPOSAL'}}" :disabled="!isCall">Awarding Body - Call For Proposal</b-dropdown-item>
                    <b-dropdown-item class="isf-nav-dropdown-item" :to="{name: 'listaBandi', params: {type: 'ASSEGNAZIONE_DIRETTA'}}" :disabled="!isCall">Awarding Body - Assegnazione Diretta</b-dropdown-item>
                    <b-dropdown-item class="isf-nav-dropdown-item" :to="{name: 'listaExecutingBody'}" :disabled="!isEB">Executing Body</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item href="#" disabled>Fascicoli</b-nav-item>

                <b-nav-item-dropdown>
                    <template v-slot:button-content>
                    Progetti
                    </template>
                    <b-dropdown-group>
                    
                        &nbsp; <strong class="isf-nav-dropdown-title">Candidatura</strong>
                        <b-dropdown-divider/>
                        <b-dropdown-item class="isf-nav-dropdown-item" :to="{name: 'listaProgettiModifica'}" :disabled="!isProject">Compilazione-Modifica</b-dropdown-item>
                        <b-dropdown-item class="isf-nav-dropdown-item" :to="{name: 'listaProgettiIstruttoria'}" :disabled="!isProgettiIstruttoria">Valutazione-Istruttoria</b-dropdown-item>
                        <b-dropdown-item class="isf-nav-dropdown-item" :to="{name: 'listaProgettiAmmessi'}" :disabled="!isProgettiAmmessi">Finanziamento-Convenzione</b-dropdown-item>
                        <b-dropdown-item class="isf-nav-dropdown-item" :to="{name: 'listaProgetti'}" :disabled="!isProject">Lista Progetti (tutti)</b-dropdown-item>
                        
                        &nbsp; <strong class="isf-nav-dropdown-title">Monitoraggio</strong>
                        <b-dropdown-divider/>
                        <b-dropdown-item class="isf-nav-dropdown-item" :to="{ name: 'selettore'}" :disabled="!isMonitoring">Scheda di Monitoraggio</b-dropdown-item>
                        <b-dropdown-item class="isf-nav-dropdown-item" :to="{ name: 'selettoreInterim'}" :disabled="!isMonitoring">Interim Assessment</b-dropdown-item>
                        <b-dropdown-item class="isf-nav-dropdown-item" :to="{ name: 'selettoreFinal'}" :disabled="!isMonitoring">Final Assessment</b-dropdown-item>

                        &nbsp; <strong class="isf-nav-dropdown-title">Controlli</strong>
                        <b-dropdown-divider/>
                        <b-dropdown-item class="isf-nav-dropdown-item" :to="{ name: 'listaControlliAmm'}" :disabled="!isControl">Controlli Amministrativi</b-dropdown-item>
                        <b-dropdown-item class="isf-nav-dropdown-item" :to="{ name: 'listaControlliLoco'}" :disabled="!isControlInLoco">Controlli in Loco</b-dropdown-item>
                    </b-dropdown-group>
                </b-nav-item-dropdown>
                
                <b-nav-item-dropdown>
                    <template v-slot:button-content>
                        Circuito Finanziario
                    </template>
                    <b-dropdown-group>
                        
                        &nbsp; <strong class="isf-nav-dropdown-title">Prefinanziamento</strong>
                        <b-dropdown-divider/>
                            <b-dropdown-item class="isf-nav-dropdown-item" :to="{name: 'listaProgettiPrefinanziamento'}" :disabled="!isPrefNuovo">Nuovo</b-dropdown-item>
                            <b-dropdown-item class="isf-nav-dropdown-item" :to="{name: 'listaProgettiPrefinanziamentoItinere'}" :disabled="!isPrefCorsoOrCompletato">In Corso</b-dropdown-item>
                            <b-dropdown-item class="isf-nav-dropdown-item" :to="{name: 'listaProgettiPrefinanziamentoCompletato'}" :disabled="!isPrefCorsoOrCompletato">Completato</b-dropdown-item>
                        
                        &nbsp; <strong class="isf-nav-dropdown-title">Tranche e Saldo</strong>
                        <b-dropdown-divider/>
                            <b-dropdown-item class="isf-nav-dropdown-item" :to="{ name: 'listaProgettiAnticipazione'}" :disabled="!isProgettiAnticipazione">Progetti In Anticipazione</b-dropdown-item>

                        &nbsp; <strong class="isf-nav-dropdown-title">Dati Finanziari</strong>
                        <b-dropdown-divider/>
                            <b-dropdown-item class="isf-nav-dropdown-item" :to="{ name: 'listaProgettiDisposizioni'}" :disabled="!isDisposizioni">Disposizioni Pagamento</b-dropdown-item>
                            <b-dropdown-item class="isf-nav-dropdown-item" :to="{ name: 'listaContiAnnuali'}" :disabled="!isContiAnnuali">Conti Annuali</b-dropdown-item>
                    </b-dropdown-group>
                </b-nav-item-dropdown>

                <b-nav-item :to="{ name: 'progettiIrregolari'}" :disabled="!isIrregolarita">Irregolarità</b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">

                <b-nav-item-dropdown right>
                    <template v-slot:button-content>
                        <fa-icon :icon="['fas', 'user-circle']" class="selector-icons"/>
                        <div class="username">
                            {{userInfo.preferred_username}}
                            <br>
                            {{getGroup}} {{getRole}}
                        </div>
                    </template>
                    
                    <b-dropdown-item class="isf-nav-dropdown-item" :to="{ name: 'user'}">Profilo</b-dropdown-item>
                    <b-dropdown-divider/>
                    <b-dropdown-item class="isf-nav-dropdown-item" @click="logout" href="#">Esci</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        </div>
    </div>
        
</template>


<script>
    import dashboardUtils from "@/helpers/dashboardUtils.js";
    import { mapGetters } from "vuex";
    export default {
        name: 'navbar',

        props: {
            logoutFromApp: Function,
            profile: Object
        },
        watch: {
            profile: function() {
                console.log('aggiornamento profilo', this.profile, new Date());
                this.setup();
            }
        },

        computed: {
            ...mapGetters({
                getDescBeneficiario: 'announcements/getDescBeneficiario'
            }),
            getGroup() {
                let group;
                const currentProfile = this.profile;
                if(!currentProfile || ! currentProfile.group) {
                    group = this.userInfo.groups[0];
                } else {
                    group = currentProfile.group;
                }
                const currentSplit = group.split('/');
                if(!currentSplit || currentSplit.length === 0)
                    return '';
                group = currentSplit[currentSplit.length - 1];
                return this.getDescBeneficiario(group);
            },
            getRole() {
                const currentProfile = this.profile;
                let role;
                if(!currentProfile || ! currentProfile.role) {
                    role = this.userInfo.roles[0];
                } else {
                    role = currentProfile.role;
                }
                return role.split('-')[1];
            },

            isMonitoring(){
                return dashboardUtils.isMonitoring(this.aree);
            },

            isCall(){
                return dashboardUtils.isCall(this.aree);
            },

            isEB(){
                return dashboardUtils.isEB(this.aree);
            },
            
            isProject(){
                return dashboardUtils.isProject(this.aree);
            },

            isControl(){
                return dashboardUtils.isControl(this.aree);
            },
            isControlInLoco(){
                 if(this.userInfo && this.userInfo.roles && this.userInfo.roles[0] 
                    && (this.userInfo.roles[0].includes("fficioContabilitaSpeciale"))
                        ){  return 0;
                       }
                       else  return dashboardUtils.isControl(this.aree);
               
            },

            isProgettiIstruttoria(){
                if(this.userInfo && this.userInfo.roles && this.userInfo.roles[0] 
                    && (this.userInfo.roles[0].includes("fficioContabilitaSpeciale"))
                        ){  return 0;
                       }
                else return dashboardUtils.isProgettiIstruttoria(this.aree);
            },

            isIrregolarita(){
                return dashboardUtils.isIrregolarita(this.aree);
            },

            isProgettiAmmessi(){
                if(this.userInfo && this.userInfo.roles && this.userInfo.roles[0] 
                    && (this.userInfo.roles[0].includes("fficioContabilitaSpeciale"))
                        ){  return 0;
                       }
                else return dashboardUtils.isProgettiAmmessi(this.aree);
            },

            isPrefNuovo(){
                //Avvio di un nuovo prefinanziamento visibile solo al ruolo ufficio economico finanziario operatore
                return (this.userInfo && this.userInfo.roles && this.userInfo.roles[0] &&
                 (this.userInfo.roles[0].includes("EconomicoFinanziario-Operatore")
                  || this.userInfo.roles[0].includes("Visualizzatore")
                  || this.userInfo.roles[0].includes("AutoritaResponsabile") 
                  || this.userInfo.roles[0].includes("fficioContabilitaSpeciale") 
                  || this.userInfo.roles[0].includes("SegreteriaTecnicaAmministrativa-Direttore")));
                
            },

            isDisposizioni(){
                //Avvio di un nuovo prefinanziamento visibile solo al gruppo ufficio economico finanziario
                return (this.userInfo && this.userInfo.roles && this.userInfo.roles[0] && 
                (this.userInfo.roles[0].includes("EconomicoFinanziario") 
                || this.userInfo.roles[0].includes("Visualizzatore") 
                || this.userInfo.roles[0].includes("AutoritaResponsabile") 
                || this.userInfo.roles[0].includes("Beneficiario")
                ||  this.userInfo.roles[0].includes("fficioContabilitaSpeciale")
                || this.userInfo.roles[0].includes("SegreteriaTecnicaAmministrativa-Direttore")));

            },

            isContiAnnuali(){
                //Accessibile a tutti tranne che ai beneficiari
                return (this.userInfo 
                        && this.userInfo.roles 
                        && this.userInfo.roles[0]
                        && !this.userInfo.roles[0].includes("Beneficiario"));
            },

            isPrefCorsoOrCompletato(){
                //visibile solo ad ufficio economico
                return (this.userInfo && this.userInfo.roles && this.userInfo.roles[0]
                    && (this.userInfo.roles[0].includes("EconomicoFinanziario")
                        || this.userInfo.roles[0].includes("Beneficiario")
                        || this.userInfo.roles[0].includes("UfficioControlli")
                        || this.userInfo.roles[0].includes("SegreteriaTecnicaAmministrativa-Direttore")
                        || this.userInfo.roles[0].includes("Visualizzatore")
                        || this.userInfo.roles[0].includes("fficioContabilitaSpeciale")
                        || this.userInfo.roles[0].includes("AutoritaResponsabile"))
                );
            },

            isProgettiAnticipazione(){
                //link abilitato solo ai Beneficiari ed all'Ufficio Economico Finanziario
                return (this.userInfo && this.userInfo.roles && this.userInfo.roles[0] 
                    && (this.userInfo.roles[0].includes("Beneficiario")
                        || this.userInfo.roles[0].includes("UfficioEconomicoFinanziario")
                        || this.userInfo.roles[0].includes("UfficioControlli")
                        || this.userInfo.roles[0].includes("SegreteriaTecnicaAmministrativa-Direttore")
                        || this.userInfo.roles[0].includes("Visualizzatore")
                        || this.userInfo.roles[0].includes("fficioContabilitaSpeciale")
                        || this.userInfo.roles[0].includes("AutoritaResponsabile")));
            },

        },
        data() {
            return {
                loadComplete: false,
                userInfo: {},
                aree: []
            };
        },
        mounted(){
            this.setup();
            this.loadComplete = true;
        },

        methods :{
            setup() {
            this.aree = this.$getDashboardAreas();
            this.userInfo = this.$getUserInfo();
            },
            logout(){
                console.log("Logout dell'utente");
                this.logoutFromApp();
            }
        }
    }

</script>
