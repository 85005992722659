<template>
  <div>
    <br/>
    <br/>
    <br/>
      <div id="missingpage">
        <br/>
        <br/>
        <h4>Pagina inesistente o rimossa</h4>
        <br/>
        <br/>
      </div>
    <br/>
    <br/>
    <br/>
  </div>
</template>

<script>

export default {
   name: "PageNotFound"
}

</script>
