<template scope="props">
    <div id="dettaglioAttivita" class="elencoProgetti">
        <div class="table-title">Dettaglio Attività Progetti<span v-if="descBeneficiario !== ''"> {{descBeneficiario}}</span></div>

        <b-form inline           
        label-align-sm="left"
        class="filtro-notifiche">
        <label class="mr-sm-2" for="filterInput">Filtra risultati</label>
        <b-form-input
        v-model="filter"
        type="search"
        id="filterInput"
        placeholder="Digita un filtro"/>
        <b-button :disabled="!filter" @click="filter = ''">Pulisci filtro</b-button>
        
        </b-form>

        <b-table :filter="filter" :busy="isBusy" id="elencoAttivita" primary-key="dataModifica" hover show-empty
                 empty-text="Nessun dato trovato" empty-filtered-text="Nessun dato trovato"
                 :items="tabledata.rows" :fields="tabledata.header" :sort-by="sortBy" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/helpers/utils.js'
import dashboardUtils from "@/helpers/dashboardUtils.js";

    export default {
        name: 'elencoAttivitaProgetti',
        computed: {
            ...mapGetters({
                getDescBeneficiario: 'announcements/getDescBeneficiario'
            }),
        },
        components: {
        },
        props: {
        },
        data() {
            return {
                descBeneficiario: "",
                gruppo:"",
                myAttivitaProgetti: [],
                isBusy: true,
                sortBy: 'Codice',
                filter: null,
                tabledata: {
                    "header": [
                        {
                            key: 'Codice',
                            thClass: 'thst',
                        },
                        {
                            key: 'Titolo',
                            thClass: 'thst',
                        },
                        {
                            key: 'Processo',
                            thClass: 'thst'
                        },
                        {
                            key: 'Tipo',
                            thClass: 'thst',
                        },
                        {
                            key: "dataInserimento",
                            filterByFormatted: true,
                            formatter: this.dateFormatter,
                            label: "Data Inserimento",
                        },
                        {
                            key: 'Stato',
                            thClass: 'thst',
                            sortable: true,
                        },
                    ],
                    "rows": [
                        {
                            "Codice": "Non disponibile",
                            "Titolo": "Non disponibile",
                            "Processo": "Non disponibile",
                            "Tipo": "Non disponibile",
                            "dataInserimento": "Non disponibile",
                            "Stato": "Non disponibile",
                        }
                    ]
                }
            };
        },
        methods: {
            dateFormatter(value) {
                return utils.formatDateTime(value);
            },

            caricaMieAttivita: function () {
                
                dashboardUtils.leggiProgetti().then(
                    (result) =>{
                        let listaProj = result;
                        listaProj.forEach(
                            (element) => {
                                let roles = this.$getUserRoles();
                                //se esistono task per l'utente loggato (monoruolo)
                                if (element.taskInfo.taskDefinitionKey.includes(roles[0]) ){
                                    this.myAttivitaProgetti.push(element);
                                } 
                            },
                            (error) => {
                                throw error;
                            }
                        );

                        this.extractData();
                        this.isBusy = false;
                    }
                );
                
            },
            
            extractData()
            {
                let rows = [];
                
                for (const att of this.myAttivitaProgetti) {
                    let row = {
                        "Codice": att.infoProgetto.codiceProgetto,
                        "Titolo": att.infoProgetto.titoloProgetto,
                        "Processo": "Progetti",
                        "Tipo": att.infoProgetto.modalitaDiAccesso,
                        "dataInserimento" : att.infoProgetto.dataInserimento,
                        "Stato": att.infoProgetto.stato,
                    }
                    rows.push(row);
                }
                
                this.tabledata.rows = rows;
            },
        },
        
        mounted(){
            this.descBeneficiario = this.getDescBeneficiario();
            this.gruppo = this.$getUserInfo().groups[0];
            this.caricaMieAttivita();
        }
    }

</script>

<style scoped>
</style>


<style lang="scss">
</style>
