<template>
  <b-container>
    <span hidden>{{finale}}</span> <!-- campo fittizio per controllo finale -->
    <b-row v-for="field of Object.values(returnData.content)" :key="field.id">
      <b-col>
        <b-form-group :id="'group-' + field.id"
                      :label="returnData.conf[field.id].config.criterio"
                      label-size="lg" label-cols="7" label-align="center"
                      :state="computeState(field)">
          <b-select v-if="getType(field) === 'enumSiNo' || getType(field) === 'enum3'"
                    size="lg"
                    v-model="returnData.content[field.id].value"
                    :state="computeState(field)"
                    :id="field.id">
            <b-form-select-option v-for="option in getEnum(field)"
                    :selected="option.value === field.value"  
                    :key="option.value"
                    :value="option.value">
                    {{option.label}}
            </b-form-select-option>
          </b-select>
          <b-input-group    v-else-if="getType(field) === 'range'"
                            :prepend="getRange(field).min"
                            :append="getRange(field).max"
                            size="lg">
            <b-input    type="range"
                        :min="getRange(field).min"
                        :max="getRange(field).max"
                        :step="getRange(field).step"
                        v-model="returnData.content[field.id].value"
                        :state="computeState(field)"
                        :id="field.id"
                        size="lg"/>
            </b-input-group>
            <h3 class="text-center" v-if="getType(field) === 'range'">{{returnData.content[field.id].value}}</h3>
            <b-form-invalid-feedback :id="field.id+'-g-feedback'" force-show>
                <h5>{{errorStrings[field.id]}}</h5>
            </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-textarea :hidden="returnData.conf[field.id].config.suppressNote"
                    :state="isValid[field.id+'_nota'] === false ? false : null"
                    placeholder="Note"
                    :id="field.id+'_nota'"
                    v-model="returnData.conf[field.id].data.note"
                    maxlength="500"/>
      </b-col>
    </b-row>
    <div class="modal-footer">
        <b-button variant="success" v-for="bt in buttons" :hidden="!bt.show" :key="bt.name" 
        class="modal-default-button" @click="controlloFinale(bt.action, returnData)">
          {{bt.name}}
        </b-button>
        <b-button variant="danger" class="modal-default-button"
                  @click="$bvModal.hide('modalCheck-' + parentName)">
          Chiudi
        </b-button>
    </div>
  </b-container>
</template>
  
<script>

import tool from '@/helpers/tools.js'
import validation from '@/helpers/validations.js'
import notify from "@/helpers/notifications.js"
import { mapGetters } from "vuex";

export default {
  name: "modalForm",
  props: {
    parentName: {
      type: String,
      required: true
    },
    fdata: {
      type: Object,
      required: true
    },
    buttons:
    {
      type: Array,
    },
  },
  data() {
    return {
      isValid: {},
      errorStrings: {},
      initialData: null,
      returnData: {},
      finale: false,
      enumTypes: {}
    };
  },

  computed: {
    ...mapGetters({
      getMappa: "istruttoria/getMappa",
      getSchedaIstruttoria: "istruttoria/getSchedaIstruttoria",
      getIstruttoria: "istruttoria/getIstruttoria",
      getTypes: "istruttoria/getTypes"
    }),
    getRange() {
      return field => {
            let retVal = {
                min:  '0',
                max:  '0',
                step: '0'
            };
            const conf = this.returnData.conf[field.id];
            if(conf) {
                const config = conf.config;
                if(config.min != undefined && config.min != null)
                    retVal.min = config.min.toString();
                if(config.max != undefined && config.max != null)
                    retVal.max = config.max.toString();
                if(config.step)
                    retVal.step = config.step.toString();
            }
            return retVal;
      }
    },
    getMin() {
      return field => {
        const conf = this.returnData.conf[field.id];
        if(conf) {
          const config = conf.config;
          if(config.min)
            return config.min.toString();
        }
        return '0';
      }
     },
    getMax() {
      return field => {
        const conf = this.returnData.conf[field.id];
        if(conf) {
          const config = conf.config;
          if(config.max)
            return config.max.toString();
        }
        return '0';
      }
     },
     
    computeState() {
      return field => {
        if(!field.value && this.isValid[field.id] == null)
          return null;
        return this.validate(field.id, field.value);
      }
    },
    getEnum: function() {
      return field => {
        const conf = this.returnData.conf[field.id]
        if(conf) {
          let enumValues = [];
          if(!field.value) // valore di default
            enumValues.push({'label': 'Non selezionato', 'value': field.value});
          for(let value of this.enumTypes[conf.config.type]) {
            enumValues.push({'label': value, 'value': value});
          }
          return enumValues;
        }
        return null;
      }
    },
    
    getType: function() {
      return field => {
        const conf = this.returnData.conf[field.id];
        if(conf)
            return conf.config.type;
      }
    },
  },
  created() {
    this.enumTypes = this.getTypes;
    if(!this.$props.fdata || !this.$props.fdata.conf || !this.$props.fdata.content) {
      console.error('modalForm1 bad params', this.$props.fdata);
      notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
      );
      this.$bvModal.hide('modalForm1-' + this.parentName);
    }

    this.initialData = tool.genericFunctions.cloneObject(this.$props.fdata);
    this.returnData = tool.genericFunctions.cloneObject(this.$props.fdata);

    for(let key in this.$props.fdata.conf) {
      this.isValid[key] = null;
      this.isValid[key+'_nota'] = true;
      this.errorStrings[key] = '';
      this.errorStrings[key+'_nota'] = '';
    }
  },
  
  methods: {
    controlloFinale(action, data) {
        this.finale = !this.finale;
        let retVal = true;
        for(let key in this.returnData.content) {
          retVal = retVal && this.validate(key, this.returnData.content[key].value);
        }
        if(retVal)
          this.$emit(action, data);
        else
          return false;
    },

    validate(id, value) {
      let config = this.returnData.conf[id];
      let errors = validation.checkListValidation(config, value);
      if(errors && errors.length > 0) {
        this.errorStrings[id] = errors[0];
        this.isValid[id] = false;
      }
      else {
        this.errorStrings[id] = '';
        this.isValid[id] = true;
      }

      return this.isValid[id];
    }
  }
}
</script>