<template>
    <b-container style="font-size: 1.6rem;">
        <b-overlay :show="!waitAllApis" rounded="sm">
            <b-row >
                <b-col cols="9" v-if="this.contrOptions.length>1 && !saldoDone">
                    <b-alert variant="info" show>
                        <strong>Nota:</strong> Un controllo/dichiarazione di spesa contiene fatture in riferimento al solo contratto scelto. 
                        <br>E' possibile creare più controlli/dichiarazioni di spesa in riferimento al medesimo contratto.
                        <br>I contratti non ancora controllati non verranno mostrati in elenco.
                    </b-alert>
                </b-col>
                <b-col cols="9" v-if="!atLeastOneControl1B">
                    <b-alert variant="warning" show>
                        Non &egrave; possibile avviare alcun controllo di fase 2. Deve esistere almeno un precedente controllo 1B esitato.
                    </b-alert>
                </b-col>
                <b-col cols="9" v-if="saldoDone">
                    <b-alert variant="warning" show>
                        Non &egrave; possibile avviare nessun altro controllo di fase 2. E' stato già inviata la Dichiarazione di spesa di Saldo.
                    </b-alert>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="9" v-if="this.contrOptions.length>1 && !saldoDone">
                    <b-form-input id="titolo2" v-model="titoloControllo"
                    placeholder="Titolo del controllo da avviare"
                    :disabled="isWaitingForSchedaControlloPost"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="9" v-if="this.contrOptions.length>1 && !saldoDone">
                    <h4 class="text-center">Selezionare il Contratto a cui farà riferimento la Dichiarazione di Spesa</h4>
                    <b-form-select id="contratto2" v-model="selectedContr" size="lg"
                        :options="contrOptions"
                        @input="assignChecklist()"
                        :disabled="isWaitingForSchedaControlloPost"/>
                </b-col>
            </b-row>
            <b-row v-if="this.contrOptions.length>1 && !saldoDone && isProgettoRimborso">
                <b-col cols="9">
                    <b-alert variant="warning" show>
                        <strong>Nota: </strong>Abilitando la spunta sottostante, l'utente conferma che 
                        la dichiarazione di spesa corrente è relativa al saldo del progetto.
                        <br>
                        Per i progetti con circuito in anticipazione, 
                        abilitare la spunta se sono già state richieste
                        tutte le tranches previste dal progetto.
                    </b-alert>
                    <b-form-checkbox 
                        size="lg"
                        style="font-size: 1.7rem; font-weight: bold"
                        id="saldo-checkbox"
                        v-model="dichiarazioneSaldo"
                        name="saldo-checkbox"
                        value=true
                        unchecked-value=false
                        :disabled="isWaitingForSchedaControlloPost">
                        Dichiarazione di Spesa Saldo Progetto
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="modal-footer text-right">
                    <b-button
                        class="btn-success"
                        @click="creaNuovaCheckList"
                        :disabled="isCreaSchedaControlloDisabled">
                        Crea Nuova CheckList
                    </b-button>
                    <b-button variant="danger" 
                        class="modal-default-button"
                        @click="closeModal"
                        :disabled="isWaitingForSchedaControlloPost">
                        Chiudi
                    </b-button>
                </b-col>
            </b-row>
        </b-overlay>
    </b-container>
</template>

<script>

import endpoints from "@/components/endpoints.vue";
import notify from "@/helpers/notifications.js"
import feedFx from '@/helpers/feedFunctions.js'

export default {
    name: "selezioneCheckListControllo2",
    props: {
        saldoDone: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            sottoTipoControllo: '2',
            selectedChecklist: null,
            selectedContr: null,
            contrOptions: [            //deve contenere le procedure del progetto in questione che non hanno ancora subito controllo di questo tipo
                { value: null, text: 'Seleziona contratto', disabled: true },
            ],
            controlli: [],
            atLeastOneControl1B: false,
            dichiarazioneSaldo: false,
            isProgettoRimborso: false,
            isDelegazione: false,
            isWaitingForSchedaControlloPost: false,
            codiceProgetto: "sconosciuto",
            cronoprog: {},
            titoloControllo: null,
            waitAllApis: false
        }
    },

    mounted() {
        this.codiceProgetto = this.$route.params.codiceProgetto;
        const url = endpoints.cronoprogProgetti(this.codiceProgetto)
        this.$get(url)
            .then((cr) => {
                this.cronoprog = cr
                this.loadControlsByCodiceProgetto(this.codiceProgetto);
            })
            .catch((error) => {
                console.error("ERRORE modale: ", error.message)
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            })

        const url2 = endpoints.getProgettoByCodice(this.codiceProgetto);
        console.log("load schedaProgetto from URL=", url2);
        this.$get(url2)
        .then((schedaProj) => {
            if (schedaProj.schedaProgetto.erogazione){
                if(schedaProj.schedaProgetto.erogazione.circuitoFinanziario == "A Rimborso")
                    this.isProgettoRimborso = true;
                else if(schedaProj.schedaProgetto.erogazione.circuitoFinanziario.includes("elegazione"))
                    this.isDelegazione = true;
            }
        })
        .catch((error) => {
            console.error("ERRORE nel caricamento del progetto nella modale Controllo Fase 2: ", error.message)
            notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
        })
    },

    computed: {
        isCreaSchedaControlloDisabled: function() {
            // disabilitato nel caso l'utente non abbia selezionato
            // nulla nei dropdown oppure se e' in corso la chiamata
            // POST per la creazione della schedacontrollo
            return (this.saldoDone || !this.selectedContr || this.isWaitingForSchedaControlloPost || !this.titoloControllo
            || this.titoloControllo.toString().trim().length === 0)
        },
    },

    methods: {
        loadControlsByCodiceProgetto(codiceProgetto){
            // get controlli per progetto
            let url = endpoints.getControlliAmministrativi(codiceProgetto)
            return this.$get(url)
            .then(
                result => {
                    if (result.length !== 0){
                        console.log("Trovati "+result.length+" controlli per il progetto "+codiceProgetto);
                        this.controlli = result;
                        this.trovaContrattiControllabili();
                        this.waitAllApis = true;
                    } else {
                        console.log("Nessun controllo trovato per il progetto "+codiceProgetto);
                        this.controlli = [];
                        //warning in giallo nella modale
                        this.waitAllApis = true;
                    }
                }
            ).catch(
                error => {
                    console.error('Errore store dispatch: ', error)
                    notify.error(notify.strings.error, notify.strings.errorLoadControlli);
                }
            );       
        },
        trovaContrattiControllabili(){
            //trova controlli 1A esitati
            const c1Bdone = this.controlli.filter((c) => {
                return (c.sottoTipoControllo === '1B'&& c.stato.includes('sitato'))
            })
            console.log("trovati controlli 1B esitati=", c1Bdone.length);

            //popolo elenco contratti controllabili
            if (c1Bdone.length>0){
                this.atLeastOneControl1B = true;
                for (let i=0; i<c1Bdone.length; i++){
                    let c = c1Bdone[i];
                    const contratto = this.cronoprog.content[c.cronoprogRootId][c.cronoprogItemId];
                    console.log("controllo 1B =",c);
                    let text = 'procedura: ' + c.idAttivita+ '.' + c.idProceduraGara;
                    if(contratto) {
                        text += ' controllo riferimento: ' + contratto.content.riferimentoContratto;
                    } else {
                        console.error('trovaContrattiControllabili: contratto non trovato', this.cronoprog.content);
                    }
                    let option = {
                        value: c,
                        // text: c.idAttivita+ '.' + c.idProceduraGara + ' ' + c.titoloControllo  //+ ' ' + c.riferimentoContratto
                        text: text
                    };
                    this.contrOptions.push(option);
                }
            }
        },
        assignChecklist(){
            //checklist fissa a prescindere dal contratto selezionato
            //Se è un progetto in delegazione ho una checklist diversa
            if(this.isDelegazione)
                this.selectedChecklist = "delegazione";
            else
                this.selectedChecklist = "dichiarazione";
        },
        creaNuovaCheckList(){
            
            console.log("selectedContr=", this.selectedContr);
            this.isWaitingForSchedaControlloPost = true;

            this.creaNuovaSchedaControllo()
            .then(res => {
                // passo alla pagina della scheda controllo appena creata
                this.$router.push({
                    name: 'schedaControlliAmministrativi', 
                    params: { 
                        codiceProgetto: this.codiceProgetto,
                        idControllo: res.idSchedaControllo
                    }
                })
                this.isWaitingForSchedaControlloPost = false;
            })
            .catch((error) => {
                this.isWaitingForSchedaControlloPost = false;
                console.error("Errore:", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },
        async creaNuovaSchedaControllo(){
            
            try {
                const selectedContr = this.selectedContr;
                const selectedChecklist = this.selectedChecklist;
                console.log("selectedChecklist=", this.selectedChecklist);
                // 1) recuperare i dati del controllo 1B da ereditare (procedura, sorgente)
                let controllo1B = await this.leggiSchedaControllo1B(selectedContr.codiceProgetto, selectedContr.idSchedaControllo);
                if(!controllo1B) {
                    let msg = "Controllo 1B precedente non trovato! ID="+selectedContr.idSchedaControllo;
                    console.error(msg);
                    throw new Error(msg);
                }

                console.log("letto controllo 1B=", controllo1B);
                // 2) recupero ultima versione del tabellone da usare per questo controllo
                let lastChecklist = await this.$get(
                    endpoints.retrieveLastVersionTabellone('2_' + selectedChecklist)
                    )
                let lastChecklistVersion = lastChecklist.lastVersion;

                let anagrafica = {};
                if (lastChecklist){
                    //al momento è possibile ereditare parte dell'anagrafica 1B a partire dai dati di 1A
                    const contratto = this.cronoprog.content[controllo1B.cronoprogRootId][controllo1B.cronoprogItemId]
                    anagrafica = feedFx.feedAnagrafica2(controllo1B.content.anagrafica, contratto)
                }

                let infoProgetto = {};
                if (controllo1B.content.infoProgetto){
                    infoProgetto = controllo1B.content.infoProgetto;
                    console.log("ereditato infoProgetto=", infoProgetto);
                } else 
                    console.error("infoProgetto non trovato nel precedente controllo 1B!?");

                let userIdGroup = this.$getUserGroupId();
                userIdGroup = userIdGroup.replace('/MINT/','');
                userIdGroup = userIdGroup.replace('/Beneficiari/','');
                //console.log("strippato idBeneficiario=", userIdGroup);

                const autore = this.$getUserInfo().preferred_username;
                
                const body = {
                    codiceProgetto: this.codiceProgetto,
                    idSchemaControllo: '2_' + selectedChecklist,
                    schemaVersione: lastChecklistVersion,
                    idBeneficiario: userIdGroup,
                    tipoControllo: "Amministrativo",
                    sottoTipoControllo: "2",
                    cronoprogItemId: controllo1B.cronoprogItemId,
                    cronoprogRootId: "contratti", // matcha la key all'interno del content di cronoprog
                    autoreUltimoAggiornamento: autore,
                    strumentoFinanziario: infoProgetto.strumentoFinanziario,
                    content: {
                        cronoprogRootId: "pagamenti",   // matcha la key all'interno del content di cronoprog
                        //tipoProcedura: selectedChecklist, //attributo non utilizzato
                        idAttivita: selectedContr.idAttivita,
                        idProceduraGara: selectedContr.idProceduraGara,
                        titoloControllo: this.titoloControllo, // inserito dall'utente
                        riferimentoContratto: selectedContr.riferimentoContratto,
                        autoreUltimoAggiornamento: autore,
                        autocontrollo: {},
                        controllo: {},
                        anagrafica: anagrafica,
                        infoProgetto: infoProgetto,
                        dichiarazione: {
                            importoTotaleISF: 0         //conterrà la somma degli importi a valere sul fondo per i pagamenti inseriti
                        },
                        dichiarazioneSaldo: this.dichiarazioneSaldo
                    }

                }

                let url = endpoints.getControlliAmministrativi(this.codiceProgetto);

                // POST di creazione della scheda controllo
                let result = await this.$post(url, body)
                let schedaControlloCreata = result;
                if (!schedaControlloCreata.idSchedaControllo ||
                    !schedaControlloCreata.codiceProgetto)
                {
                    let msg = "Scheda controllo creata non valida. Controllare la scheda creata"
                    console.error(msg, result);
                    throw new Error(msg);
                }
                console.log(
                    "schedaControllo creata",
                    "idSchedaControllo=", schedaControlloCreata.idSchedaControllo,
                    "codiceProgetto=", schedaControlloCreata.codiceProgetto
                );

                // checkout del branch di cronoprog a partire dal trunk
                url = endpoints.cronoCheckout;
                const branch = 'controlli' + this.sottoTipoControllo;
                const checkoutBody = {
                    idProgetto: this.cronoprog.idProgetto,
                    version: null,
                    branch: branch,
                    idSchedaControllo: schedaControlloCreata.idSchedaControllo
                }
                let cronoResult = await this.$post(url, checkoutBody);
                url = endpoints.cronoprog(this.cronoprog.idProgetto)
                const params =  {
                    branch: branch,
                    idSchedaControllo: schedaControlloCreata.idSchedaControllo
                }
                let cronoprogResult = await this.$post(url, cronoResult, params);
                console.log("cronoprogResult", cronoprogResult);

                // avvio il processo
                this.processo = await this.avviaProcessoControllo(
                    schedaControlloCreata.idSchedaControllo, schedaControlloCreata.codiceProgetto);
                console.log("Processo avviato, ID=", this.processo);

                //effettuo il claim implicito per l'utente che ha creato il processo
                await this.autoclaim(schedaControlloCreata.idSchedaControllo);
                return schedaControlloCreata
                
            } catch (error) {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                throw error;
            }

        },
        leggiSchedaControllo1B(codiceProj, idContr){
            if (!codiceProj || !idContr){
                let msg = "Impossibile leggere scheda controllo senza id!"
                console.error(msg);
                throw new Error(msg);
            }

            let url = endpoints.getControlloAmministrativo(codiceProj, idContr);
            console.log("leggiSchedaControllo1B URL=", url);
            return this.$get(url);
        },
        avviaProcessoControllo(idSchedaControllo, codiceProgetto){
            //i controlli 2 girano sullo lo stesso processo usato per le tranche, ControlliGatewayController.java sul BE effettua la conversione URL->processo da avviare
            const url = endpoints.startControlloAmministrativo2Process;
            const body = {
                codiceProgetto: codiceProgetto,
                idScheda: idSchedaControllo
            }
            // POST per avvio processo controllo
            return this.$post(url, body)
        },
        autoclaim(idSchedaControllo) {
            const url = endpoints.claimControllo+idSchedaControllo;                   
            return this.$post(url)
        },
        closeModal() {
            this.$emit('closeModalCheckList')
        },
    }
}
</script>