<script>

// this component extends vue-chartjs.Pie

import { Doughnut } from 'vue-chartjs'
import css from '@/styles/_variables.scss'
import 'chartjs-plugin-labels';

//const { reactiveProp } = mixins

export default {

  props:['chartData'],
  extends: Doughnut,
  //mixins: [reactiveProp],
  data() {
    return {
      availableBackgroundColors: [
        // reading colors from SCSS and adding them as default
        css.red, css.green, css.orange, css.yellow, css.grey, css.blue
      ],
      pieData: {},
    }
  },

  mounted(){
    this.parseData(this.chartData)
    this.buildChart()
  },

  watch: {
    // watch chartData to change...
    chartData: function(){
      // if I've already rendered a chart, I destoy it before rendering it again
      if (this.$data._chart){
        this.$data._chart.destroy()
      }
      this.parseData(this.chartData)
      this.buildChart()
    }
  },

  methods: {

    buildChart: function() {
      this.renderChart({
        labels: this.pieData.labels,
        datasets: [
          {
            //label: 'Data One',
            backgroundColor: this.pieData.backgroundColors,
            data: this.pieData.values
          }
        ]
      }, {
        responsive: true, 
        maintainAspectRatio: false, 
        plugins: {
          labels: {
            render: 'value',
            //render: 'percentage',
            precision: 0,
            fontColor: css.white,
            fontStyle: 'normal',
            fontFamily: css.sansSerif,
            fontSize: 14,
          },
        },
        onClick: this.handle,
        legend: { 
          display: true,
          position:'bottom',
          align: 'start',
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
      })
    },
    // function handling user click
    handle (point, event) {
      const item = event[0]
      // excluding clicks outside of the chart but still inside the <div>
      // they would cause a TypeError because outside the chart 'item' is undefined
      if(item != undefined && item != null){
        this.$emit('on-user-click', {
          //index: item._index,
          //backgroundColor: item._view.backgroundColor,
          count: this.pieData.values[item._index],
          label: this.pieData.labels[item._index],
          // both arrays have same index so I get the categoryId from the first one
          categoryId: this.chartData[item._index].categoryId || null // returning null if undefined
        })
      }
    },
    parseData(d){
      this.pieData = {
        labels: [],
        values: [],
        backgroundColors: []
      }
      if(d){
        for(let i=0; i<d.length; i++){
          this.pieData.labels.push(d[i].label)
          this.pieData.values.push(d[i].count)
          // setting colors correctly
          if (d[i].categoryId === "REGOLARI" 
            || d[i].categoryId === "AMMESSO"
            || d[i].categoryId === "APERTO"
            ){
            
            this.pieData.backgroundColors.push(css.green)

          } else if (d[i].categoryId === "ASSENTI" 
            || d[i].categoryId === "NON_AMMESSO"
            || d[i].categoryId === "INVALIDO" 
            || d[i].categoryId === "RINVIATO"
            || d[i].categoryId === "SCADUTO"
            || d[i].categoryId === "GRADUATORIA_DA_FIRMARE"
            ){
            
            this.pieData.backgroundColors.push(css.red)

          } else if (d[i].categoryId === "INVIATO" 
            || d[i].categoryId === "CONVENZIONE_IN_FIRMA_AR"
            || d[i].categoryId === "RIMODULAZIONE"
            || d[i].categoryId === "IN_VALUTAZIONE"
            || d[i].categoryId === "DECRETO_DA_FIRMARE"
            ){
            
            this.pieData.backgroundColors.push(css.orange)

          } else if (d[i].categoryId === "BOZZA" 
            || d[i].categoryId === "CONVENZIONE_DA_FIRMARE"
            || d[i].categoryId === "ATTUAZIONE"
            || d[i].categoryId === "DA_PRENDERE_IN_CARICO"
            ){
            
            this.pieData.backgroundColors.push(css.bluehome)

          } else if (d[i].categoryId === "CONVENZIONE_FIRMATA"
            || d[i].categoryId === "CONCLUSO"
            || d[i].categoryId === "VALUTATO"
            || d[i].categoryId === "GRADUATORIA_APPROVATA"
            ){
            
            this.pieData.backgroundColors.push(css.grey)

          } else {
            
            this.pieData.backgroundColors.push(css.darkgrey)
          }
        }
      }
    },
  }

}
</script>
