  /**
   * 
   * @param {Object} tabellone 
   * Restituisce tutte le tipologie di allegati disponibili per questo tabellone
   */
  function getAllDocumentTypes(tabellone){
    let tipologie = new Set();
    const tipologiaAllegati = tabellone.tipologiaAllegati

    if (tabellone.mappa){
      const map = tabellone.mappa
      
      if(!tipologiaAllegati)
        return tipologie
      Object.keys(map).forEach( key => {
        let config = map[key].config
        let docsRiferimento = config.docsRiferimento
        if (docsRiferimento.length > 0){
          docsRiferimento.forEach( doc => {
            if(!tipologie.has(doc))
              tipologie.add(tipologiaAllegati[doc])
          })
        }
      });
    }
      //Allegati extra checklist, aggiunti a quelli disponibili di default
      if(tabellone.defaultDocs){
        tabellone.defaultDocs.forEach ( doc =>{
          if(!tipologie.has(doc))
            tipologie.add(tipologiaAllegati[doc])
        })
      }

    

    return tipologie;
  }

    /**
   * 
   * @param {String} fileId 
   * @param {Object} scheda 
   * Elimina dalla scheda i riferimenti di un allegato da cancellare
   */
  function deleteAttachment(fileId, scheda){
    //Parti della scheda in cui cercare riferimenti
    //let possibiliRiferimenti = []

    // if(scheda.hasOwnProperty('autocontrollo'))
    //   possibiliRiferimenti.push(scheda.autocontrollo)
      
    // if(scheda.hasOwnProperty('controllo'))
    //   possibiliRiferimenti.push(scheda.controllo)
    
    // possibiliRiferimenti.forEach(object => {
    //   if(Object.keys(object).length > 0) {
    //     Object.keys(object).forEach(key => {
    //       Object.keys(object[key]).forEach(field => {
    //         if(tools.keyFinder(object[key][field], 'chooseFiles') !== null) {
    //           if(object[key][field].chooseFiles && object[key][field].chooseFiles.length > 0) {
    //             let allegati = object[key][field].chooseFiles
    //             allegati.forEach(allegato => {
    //               if(allegato.fileId === fileId)
    //                 allegati.splice( allegati.indexOf(allegato), 1 )
    //             });
    //           }
    //         }
    //       })
    //     })
    //   }
    // })

    // possibiliRiferimenti.forEach(
    //   object => {
        
    //     for ( let field in object ){
    //       if(tools.keyFinder(object[field], 'chooseFiles') !== null){
    //         debugger

    //         let allegati = tools.keyFinder(object[field], 'chooseFiles').chooseFiles

    //         allegati.forEach(
    //           allegato => {
    //             if(allegato.fileId === fileId)
    //               allegati.splice( allegati.indexOf(allegato), 1 )
    //           }
    //         );
    //       }
    //     }
    //   }
    // );

    //Infine cancello l'allegato da 'allegati'
    delete scheda.allegati[fileId]
  }

  /**
   * 
   * @param {Object} allegati 
   * @param {Array} toDelete 
   * Crea la stringa da inseire nella chiamata attachFileObjects
   */
  function attachFileReqBuilder( allegati, toDelete ){
    let keys = null

    if(allegati && Object.keys(allegati).length > 0){
      keys = Object.keys(allegati)
      keys.forEach((fileId) => {
        keys[keys.indexOf(fileId)] = fileId.concat(":attach")
      });
    }

    toDelete.forEach(fileId => {
      toDelete[toDelete.indexOf(fileId)] = fileId.concat(":delete")
    })
    
    let list = keys ? keys.join().concat( "," , toDelete.join()) : toDelete.join()
    return list
  }

  export default {
    getAllDocumentTypes,
    deleteAttachment,
    attachFileReqBuilder
  }