<template>
  <div id="attivitaMon">
    <DynamicTable2 :idTablePdf="idTablePdf" :tdata="tabledata" :doAction="this.doAction" :actions="this.actions"/>
    <modal :hidden="!showModal" :buttons="this.buttons" @close="showModal = false">
      <div class="row-title" slot="header">{{modalHeader}}</div>
      <div slot="body">
        <DynamicTable2 :tdata="this.modalData"/>
      </div>
    </modal>
    <modalForm :fdata="this.editFormData"
               v-bind:sch="this.scheda"
               :hidden="!showEditForm"
               :buttons="this.buttons"
               :cfg="incomingData.schedaAssessment.dataEntryConfiguration"
               @close="showEditForm = false"
               @salvaRecord="aggiornaRiga">
      <div class="row-title" slot="header">{{modalHeader}}</div>
    </modalForm>
  </div>
</template>


<script>
import DynamicTable2 from "@/components/dynamicTable2.vue";
import modalForm from "@/components/modalForm.vue";
import modal from "@/components/modal.vue";
import tool from '@/helpers/tools.js'
import notify from "@/helpers/notifications.js"
import mapUtils from '@/helpers/mapUtils.js'

export default {
  name: "attivitaMon",
  components: {
    DynamicTable2,
    modalForm,
    modal
  },

  props: {
    idTablePdf: String,
    incomingData: Object,
    salvaSchedaToStore: Function,
    viewOnlyMode: Function,
    tabName: String
  },
  watch: {
    tabName: function() {
      if(this.$props.tabName === "risultati") {
        this.extractData();
      }
    }
  },
  mounted() {
    this.showEditForm = false;
    this.showModal = false;
    this.extractData();
  },
  data() {
    return {
      showEditForm: false,
      editFormData: {},
      numeroRiga: 0,
      lastAction: "",
      showModal: true,
      modalData: {},
      actions: ['edit'],
      tabledata: {
        header: ["Attività Monitoraggio"],
        rows: [
          {
            content: {
              "Attività Monitoraggio": "Non disponibile"
            }
          }
        ]
      },
      buttons: [
            {
              name: 'Salva',
              action: 'salvaRecord',
              show: true,
              param: "edit"
            }
      ],
      modalHeader: "",
      scheda: {}

    };
  },

  methods: {
     aggiornaRiga(actionFromModal) {
      let clonedScheda = mapUtils.aggiornaScheda(actionFromModal, tool.genericFunctions.cloneObject(this.incomingData.schedaAssessment));
      this.salvaSchedaToStore(clonedScheda);
      this.extractData();
      this.showEditForm = false;
    },

    doAction(actionToDo, index, item) {
        this.lastAction=actionToDo; //memorizzo l'ultima l'azione selezionata, servirà in fase di persistenza
        this.numeroRiga=index;      //memorizzo il numero di riga impattato

        switch(actionToDo) {
          case 'edit':
            this.showEditForm = true;
            this.editFormData = mapUtils.editFormDataFromRiga(item);
            this.modalHeader = "Attività Monitoraggio";
            this.buttons[0].show=true;
            break;
          default:
            notify.error("Errore","Azione non gestita");
            console.error("attivitaMon: azione='"+actionToDo+"' non gestita!?");
            break;
        }
    },

    extractData() {
      if(!this.incomingData){
            console.log("attivitaMon: Invalid input Data in attivitaMon!?");
            return;
      }
      this.scheda = this.incomingData.schedaAssessment;
      let inputData = this.incomingData.schedaAssessment;
      
      this.viewOnlyMode(this.incomingData, this.actions);
      
      let rows = [];

      let row = {};
      const mappa = inputData.dataEntryConfiguration;

        
      let colIds = [];
      //definisco gli id delle colonne da visualizzare
      colIds.push("['attivitaMonitoraggio']");
      
      let myConf = mapUtils.costruisciConfigurazione(colIds, mappa);

      row = {
            content: {},
            conf : myConf
      };

      //scrivo il valore dei vari campi (stesso ordine usato sopra) con l'ID : valore
      row.content[colIds[0]] = inputData.attivitaMonitoraggio;
      rows.push(row);
      this.tabledata.rows = rows;
    }
  }
};
</script>