<template scope="props">
    <div id="elencoProgettiAss" class="elencoProgetti">
        <div class="table-title">
            {{isFinal? "Final" : "Interim"}} Assessment - Elenco Progetti <span v-if="descBeneficiario !== ''"> {{descBeneficiario}}</span>
        </div>

     <b-form inline class="m-1" style="display: flex">
         <label for="filterProgettiInput"><h4 class="mx-2">Filtra i risultati:</h4>
           </label>
          <b-form-input
          v-model="filter"
          type="search"
          id="filterAssesmentInput"
          placeholder="Digita un filtro"/>
         <b-button
          id="filterAssesmentButton"
          class="mx-1"
          :disabled="!filter"
          @click="filter = ''"
          >Cancella
         </b-button>
     </b-form>
        <b-table :busy="isBusy" id="elencoProgettiAssTable" primary-key="Codice" hover :items="tabledata.rows" :fields="tabledata.header" :sort-by="sortBy" sort-asc
          :filter="filter"
          emptyFilteredText="Nessun dato disponibile"
          :filter-included-fields="getFilterFields()"
          emptyText="Nessun dato disponibile"
          show-empty
          responsive>
            <template v-slot:cell(Azioni)="data">
                <b-button variant="outline-primary" :key="x" v-for="x in data.value"
                          size="sm"
                          v-on:click="doAction(x, data.item)"
                          :disabled="clicked">
                    <fa-icon v-if="x==='detail'" :icon="['fas', 'folder']" class="selector-icons"/>
                    <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
                    <fa-icon v-if="x==='view'" :icon="['far', 'eye']" class="selector-icons"/>
                    <fa-icon v-if="x==='claim'" :icon="['far', 'hand-paper']" class="selector-icons"/>
                    <fa-icon v-if="x==='unclaim'" :icon="['fas', 'times-circle']" class="selector-icons"/>
                </b-button>
            </template>
        </b-table>
        <b-modal id="avvioAssessment"
            centered size="lg"
            ok-variant='success' ok-title='Avvia assessment'
            cancel-title='Annulla'
            @ok="startAssessment()">
            <template v-slot:modal-header><h4><strong>Avvio assessment</strong></h4></template>
            <p class="mb-4">Si sta per avviare un nuovo assessment.
                <br>
                Non è possibile interrompere un assessment una volta avviato.
            </p>
                    
            <p class="mb-4">Si desidera procedere?</p>
        </b-modal>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import utils from '@/helpers/utils.js'
    import notify from "@/helpers/notifications.js"
    import endpoints from "@/components/endpoints.vue";

    export default {
        name: 'selettoreInterim',
        computed: {
             ...mapGetters({
                getTaskList: 'assessment/getTaskList',
                getTaskId: 'assessment/getTaskId',
                getDescBeneficiario: 'announcements/getDescBeneficiario'
            }),

            isFinal: function() {
                return (this.$route.name === "selettoreFinal");
            }
        },
        watch: {
            isFinal: function() {
                this.caricaProgetti();
            }
        },
        data() {
            return {
                clicked: false,
                descBeneficiario: "",
                assessments: {},
                isBusy: false,
                sortBy: 'Codice',
                filter: null,
                tabledata: {
                    "header": [
                        {
                            key: 'Codice',
                            sortDirection: 'asc',
                            sortable: true,
                        },
                        {
                            key: 'Titolo',
                            sortable: true,
                        },
                        {
                            key: 'Periodo',
                            sortable: true,
                        },
                        {
                            key:"dataFineProgetto",
                            label:"Data Fine Progetto",
                            filterByFormatted: true,
                            formatter: this.dateFormatter,
                            sortable: true
                        },
                        {
                            key: 'Stato',
                            sortable: true
                        },
                        {
                            key: 'Azioni'
                        },
                    ],
                    "rows": [
                        {
                            "Codice": "Non disponibile",
                            "Titolo": "Non disponibile",
                            "Periodo": "Non disponibile",
                            "Stato": "Non disponibile",
                            beneficiario: "Beneficiario",
                        }
                    ]
                }
            };
        },

        methods: { 
            caricaProgetti: async function () {
                this.isBusy = true;
                await this.$store.dispatch('selettoreAss/retrieveElencoProgetti', this.isFinal)
                .then(result => {
                    this.assessments = result;
                })
                .catch(
                    error => {
                        console.error("selettoreInterim: Errore con codice="+error.response.status+" body=", error.response.data);
                        notify.error(notify.strings.error, notify.strings.selettoreAssessmentKO);
                        this.isBusy = false;
                    }
                    
                );
                await this.$store.dispatch('assessment/retrieveTasks');
                this.extractData();
                this.isBusy = false;
            },
            beneficiarioFormatter(value) {
                if (!value) 
                return "Sconosciuto";
                return utils.formatBeneficiari(value);
            },
            dateFormatter(value) {
                if (!value) return null;
                return utils.formatDate(value);
            },
            async startAssessment() {
                this.isBusy = true;
                const codiceProgetto = this.selectedCodiceProgetto;
                const progetto = this.assessments[codiceProgetto];
                const idPeriodo  = parseInt(progetto.idPeriodo);
                try {
                    // 1) checkout del branch di assessment dal trunk
                    const cronoprog = await this.doCronoCheckout();
                    console.log("cronoprogResult", cronoprog);

                    const idSchema = 'assessment';
                    const idSchemaCronoprog = 'cronoprog_assessment';
                    let lastVersion;
                    let lastVersionCrono;
                    // 2) recupero le ultime versioni dei tabelloni di assessment e cronoprog
                    await Promise.all([
                        this.$get(endpoints.retrieveLastVersionTabellone(idSchema)),
                        this.$get(endpoints.retrieveLastVersionTabellone(idSchemaCronoprog))
                    ]).then( results => {
                        lastVersion = results[0].lastVersion;
                        lastVersionCrono = results[1].lastVersion;
                    })
                    
                    
                    
                    // 3) creo la prima scheda assessment
                    const body = {
                        idSchema: idSchema,
                        schemaVersione: lastVersion,
                        idSchemaCronoprog: idSchemaCronoprog,
                        schemaVersioneCronoprog: lastVersionCrono,
                        cronoprogKey: cronoprog.key,
                        finalAssessment: this.isFinal
                    }
                    const url = endpoints.saveFirst('schedaAssessment', codiceProgetto, idPeriodo);

                    this.$post(url, body).then( result => {
                        console.log('prima scheda salvata, result:', result);
                        this.routerPush(codiceProgetto, idPeriodo);
                        this.isBusy = false;
                    }).catch( error => {
                        console.error('errore salvataggio assessment', new Date(), error);
                        notify.error(notify.strings.error, notify.strings.avvioMonitoraggioError);
                        this.isBusy = false;
                    });
                } catch(error) {
                    console.error("startMonitoraggio: errore caricamento progetto", codiceProgetto, error);
                    notify.error(notify.strings.error, notify.strings.avvioMonitoraggioError);
                    this.isBusy = false;
                }
            },

            routerPush(codiceProgetto, idPeriodo) {
                if(this.isFinal) {
                    this.$router.push({ name: "schedaFinal" , params: { codiceProgetto: codiceProgetto, idPeriodo: idPeriodo }});
                } else {
                    this.$router.push({ name: "schedaInterim" , params: { codiceProgetto: codiceProgetto, idPeriodo: idPeriodo }});
                }
            },

            doCronoCheckout() {
                const codiceProgetto = this.selectedCodiceProgetto;
                const progetto = this.assessments[codiceProgetto];
                // 12/04/2021: interim e final assessment non considerano un periodo di riferimento,
                // ma si basano sui dati più aggiornati
                // const idPeriodo  = parseInt(progetto.idPeriodo);
                // checkout del branch di cronoprog a partire dal trunk
                // const dateConstraint = schedeUtils.getAssessmentDateConstraint(idPeriodo);
                const branch = 'assessment';
                const checkoutBody = {
                    idProgetto: progetto.idProgetto,
                    version: null,
                    branch: branch,
                    // dateConstraint: dateConstraint
                };
                const url = endpoints.cronoCheckout;
                return this.$post(url, checkoutBody);
            },

            doAction(actionToDo, item) {
                this.clicked = true;
                const codiceProgetto = item.Codice;
                let id;

                this.selectedCodiceProgetto = codiceProgetto;
                const progetto = this.assessments[codiceProgetto];
                const assente = progetto.assente;
                const idPeriodo  = parseInt(progetto.idPeriodo);


                switch(actionToDo) {
                    case 'detail':
                        if(this.isFinal) {
                            this.$router.push({ name: "archivioAssessFinal", params: { codiceProgetto: codiceProgetto}});
                        } else {
                            this.$router.push({ name: "archivioAssessInterim", params: { codiceProgetto: codiceProgetto}});
                        }
                        break;
                    case 'edit':
                        if(assente) {
                            // se l'assessment non è ancora stato avviato, apro la modale
                            console.log('assessment assente per:', codiceProgetto, idPeriodo);
                            this.$bvModal.show('avvioAssessment');
                        } else {
                            this.routerPush(codiceProgetto, idPeriodo);
                        }
                        this.clicked = false;
                        break;
                    case 'view': // same result for edit and view
                        this.routerPush(codiceProgetto, idPeriodo);
                        break;
                    case 'claim':
                        id = this.getTaskId(codiceProgetto, idPeriodo);
                        this.claimTask(id, codiceProgetto);
                        this.clicked = false;
                        break;
                    case 'unclaim':
                        id = this.getTaskId(codiceProgetto, idPeriodo);
                        this.unclaimTask(id, codiceProgetto);
                        this.clicked = false;
                        break;
                    default:
                        notify.error("Errore","Azione non gestita");
                        console.error('unknown action: ' + actionToDo);
                        this.clicked = false;
                        break;
                }
            },
            
            fromCodiceProgettoToIndex(codiceProgetto){
                for (let i=0; i<this.tabledata.rows.length; i++){
                    if (this.tabledata.rows[i].Codice === codiceProgetto)
                        return i;
                }
                console.error("codiceProgetto = '"+codiceProgetto+"' non trovato!?");
                return null;
            },

            async refreshTask(id, codiceProgetto) {
                let res = await this.$store.dispatch('assessment/retrieveSingleTask', id);
                //converte il codiceProgetto (btable ordinata) nell'index della tabledata (originale non ordinata)
                let index = this.fromCodiceProgettoToIndex(codiceProgetto);
                if(res && index != null) {
                    const progetto = this.assessments[codiceProgetto];
                    this.tabledata.rows[index].Azioni = this.setAction(progetto); // refresh icons
                } else {
                    notify.error("Errore","Impossibile leggere il task");
                    console.error('error in refreshTask for ' + id + ' at position ' + index);
                }
                this.isBusy = false;
            },

            async claimTask(id, codiceProgetto) {
                this.isBusy = true;
                let res = await this.$store.dispatch('assessment/claimTask', id);
                
                if(res.message) {
                    notify.error("Errore","Impossibile prendere in carico il task");
                    console.error(res.message);
                    this.isBusy = false;
                    return;
                }
                this.refreshTask(id, codiceProgetto);
            },

            async unclaimTask(id, codiceProgetto) {
                this.isBusy = true;
                let res = await this.$store.dispatch('assessment/unclaimTask', id);
                
                if(res.message) {
                    notify.error("Errore","Impossibile lasciare il task");
                    console.error(res.message);
                    this.isBusy = false;
                    return;
                }
                this.refreshTask(id, codiceProgetto);
            },
            
            setAction(progetto) {
                let azioni = [];
                const task = this.getTaskList(progetto.codiceProgetto, progetto.idPeriodo);

                if(utils.caniuse('edit', task, progetto)) {
                    if(progetto.concluso && progetto.assente) {
                        azioni = []
                    } else if(progetto.concluso) {
                        azioni.push('view')
                    } else {
                        azioni.push('edit');
                    }
                } else if(utils.caniuse('view', task, progetto)) {
                    azioni.push('view');
                }

                if (task) {
                    if(utils.caniuse('claim', task, progetto)) {
                        azioni = ["claim", 'view'];
                    }
                    else if(utils.caniuse('unclaim', task, progetto)) {
                        azioni = ["unclaim", 'edit'];
                    }
                }

                if(utils.caniuse('detail', task, progetto)) {
                    azioni.push('detail');
                }

                return azioni;
            },

            extractData(){
                let userIdGroup = this.$getUserGroupId();
                if(!userIdGroup.includes("Beneficiari")){
                    this.tabledata.header.splice(3, 0,{key:"beneficiario", sortable: true, formatter:"beneficiarioFormatter", sortByFormatted: true});
                }
                let rows = [];
                this.tabledata.rows = [];  //pulisco i dati precedentemente visualizzati
                if(this.assessments && Object.values(this.assessments).length > 0) {
                    
                    for (const progetto of Object.values(this.assessments)) {
                        const azione = this.setAction(progetto);

                        const row = {
                            "Codice": progetto.codiceProgetto,
                            "Titolo": progetto.titolo,
                            "Periodo": progetto.periodo,
                            "Stato": progetto.concluso ? 'Concluso': progetto.stato,
                            "Azioni": azione,
                            key: progetto.key,
                            idPeriodo: progetto.idPeriodo,
                            beneficiario: progetto.idBeneficiario,
                            dataFineProgetto: progetto.dataConclusione
                        }
                        rows.push(row);
                    }
                   
                } 
                this.tabledata.rows = rows;
                
            },
            getFilterFields() {
                 let filterFields = [];
                 for (let item of this.tabledata.header) {
                        filterFields.push(item.key);
                        }
                         return filterFields;
    },
        },

        mounted(){
            this.caricaProgetti(); //chiama il metodo di caricamento nello store
            this.descBeneficiario = this.getDescBeneficiario();
        }
    }

</script>
