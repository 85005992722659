<template>
  <div>
    <table class="modal-table" :id="idTablePdf">
      <thead>
          <tr>
              <th scope="col" v-for="(column, index) in tdata.header" :key="index"> {{column}}</th>
              <th scope="col" v-if="tdata.colors===true && isCronoprogramma(tdata)">Stato*</th>
              <th scope="col" :hidden="!tdata|| !actions || actions.length == 0">Azioni</th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="(item, index) in tdata.rows" :key="index">
              <td v-for="(column, indexColumn) in tdata.header" :key="indexColumn" class="dynamictable-cell">{{item.content[column]}}</td>
              <td v-if="tdata.colors===true && isCronoprogramma(tdata)">
                  <fa-icon :icon="['fas', 'circle']"  v-bind:class="classFromStato(item)" title="il colore dipende dalla data inizio e fine in relazione al trimestre corrente"/>
              </td>  
              <td :hidden="isEmptyArray(actions)">
                  <b-button variant="outline-primary" :title="x" v-for="x in actions" v-on:click="doAction(x, index)" :key="x">
                    <fa-icon v-if="x==='detail'" :icon="['fas', 'folder']" class="selector-icons"/>
                    <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
                    <fa-icon v-if="x==='view'" :icon="['far', 'eye']" class="selector-icons"/>
                    <fa-icon v-if="x==='delete'" :icon="['far', 'trash-alt']" class="selector-icons"/>
                  </b-button>
              </td>
          </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>
export default {
  name: "DynamicTable",
  props: {
    idTablePdf: { type: String, default: () => '' },
    tdata: {
      type: Object,
      required: true
    },
    actions: {
      type:Array,
      default: null
    },
    doAction: Function,
  },

  computed: {

    classFromStato(){

      return (stato) => {
                
        //ritornare un colore in base allo stato
        if (stato==="Realizzato"){                  //completato nel passato
          return "crono-green-icon";
        } else if (stato==="Realizzato in parte") { //a cavallo di diversi trimestri oltre quello corrente
          return "crono-orange-icon";
        } else if (stato==="In realizzazione") {    //da completare nel trimestre corrente
          return "crono-yellow-icon";
        } else if (stato==="Da realizzare") {       //da completare nel futuro
          return "crono-grey-icon";
        } else { //eventuale errore
          console.error("stato="+stato+" non gestito con nessun colore!?");
          return "crono-white-icon";
        }
      
      }
    }

  },

  data() {
    return {};
  },

  methods: {
    isEmptyArray(arr){
      if(!arr || arr.length === 0){
        return true
      }
      return false
    },
    
    isCronoprogramma(data) {

      for(let i in data.rows) {
        let row = data.rows[i];
        
        //controllo se i valori corrispondono alle immagini jpeg esistenti
        if(row.content.Stato === "Realizzato" 
          || row.content.Stato ==="Realizzato in parte"
          || row.content.Stato ==="In realizzazione"
          || row.content.Stato ==="Da realizzare"
          )
          return true;
      }
      return false;
    },
/*
    coloraStato(stato){ //funzione basata sui valore dell'ENUM StatoFaseAttivita.java
      //ritornare un colore in base allo stato
      if (stato==="Realizzato"){                  //completato nel passato
        return "VERDE";
      } else if (stato==="Realizzato in parte") { //a cavallo di diversi trimestri oltre quello corrente
        return "ARANCIO";
      } else if (stato==="In realizzazione") {    //da completare nel trimestre corrente
        return "GIALLO";
      } else if (stato==="Da realizzare") {       //da completare nel futuro
        return "GRIGIO";
      } else { //eventuale errore
        console.error("stato="+stato+" non gestito con nessun colore!?");
        return "BIANCO";
      }

    }
*/
  }

}
</script>
