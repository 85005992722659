<template>

    <b-container>
        <b-row style="font-size: 14px; margin-bottom: 30px;">
            <b-col cols="9" v-if="!allDone && isTranche && !noPrefDone">
                <b-alert variant="info" show>
                    <strong>Nota:</strong> Inserire un titolo per la richiesta. 
                    <br>Il numero di tranche disponibili e la relativa ripartizione percentuale del budget deve essere definita in fase di creazione del progetto.
                </b-alert>    
            </b-col>
            <b-col cols="9" v-if="noPrefDone">
                <b-alert variant="warning" show>
                    Non &egrave; possibile richiedere una nuova tranche, l'erogazione del prefinanziamento non è ancora stata conclusa.
                    <br> Verificare con l'Uffico Economico Finanziario competente lo stato della pratica.
                </b-alert>
            </b-col>
            <b-col cols="9" v-if="allDone">
                <b-alert variant="warning" show>
                    Non &egrave; possibile richiedere una nuova tranche, tutte quelle definite nel progetto sono state già richieste.
                </b-alert>
            </b-col>
            <b-col cols="9" v-if="!isTranche">
                <b-alert variant="warning" show>
                    Non &egrave; possibile avviare nuova richiesta di tranche. Nessuna tranche definita per il progetto selezionato.
                </b-alert>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="9" v-if="!allDone && isTranche && !noPrefDone">
                <b-form-input v-model="titoloControllo"
                              :disabled="isWaitingForSchedaPost"
                              placeholder="Descrizione breve della richiesta di tranche"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="9" v-if="!allDone && isTranche && !noPrefDone">
                <h6 class="text-center">Tranche richiesta</h6>
                <b-form-select v-model="selectedTr" 
                :options="trancheOptions"
                :disabled="isWaitingForSchedaPost"
                @input="checkPreviousTranche()"
                ></b-form-select>
            </b-col>
        </b-row>
        
        <b-row>
            <b-col class="modal-footer text-right">
                <b-button
                    class="btn-success"
                    @click="creaScheda"
                    :disabled="isCreaSchedaDisabled"
                    >
                    Crea Nuova Richiesta Tranche
                </b-button>
                <b-button variant="danger"
                    class="modal-default-button"
                    :disabled="isWaitingForSchedaPost"
                    @click="closeModal">
                    Chiudi
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import endpoints from "@/components/endpoints.vue";
import notify from "@/helpers/notifications.js"
import feedFx from '@/helpers/feedFunctions.js'

export default {
    name: "selezioneMotivazioneTranche",
    props: {
      richiesteTranche: {
        type: Array,
        required: true
      }
    },
    data(){
        return {
            noPrefDone: true,   //il processo di prefinanziamento è concluso?
            allDone: false,     //tutte le tranche previste sono state richieste?
            isTranche: false,   //sono state definite tranche nel progetto?
            selectedTr: 0,
            trancheOptions: [            //deve contenere le tranche possibili dichiarate in fase progettuale
                { value: null, text: 'Seleziona Tranche', disabled: true },
            ],
            isWaitingForSchedaPost: false,
            codiceProgetto: null, // il codice progetto preso dal path
            titoloControllo: null,
        }
    },
    computed: {
        isCreaSchedaDisabled: function() {
            // disabilitato nel caso l'utente non abbia inserito il titolo
            // oppure se e' in corso la chiamata POST per la creazione della schedacontrollo
            // oppure non sono state definite tranche o sono state già tutte richieste
            return (!this.titoloControllo 
                || this.isWaitingForSchedaPost 
                || !this.isTranche 
                || this.allDone
                || this.noPrefDone)
        },
    },
    methods: {

        checkPreviousTranche(){
            console.log("Numero tranche selezionata=", this.selectedTr+1);
        },

        creaScheda(){

            let trancheOpt = this.trancheOptions[this.selectedTr];
            
            this.isWaitingForSchedaPost = true;

            this.creaNuovaScheda(
                this.codiceProgetto, trancheOpt)
            .then(res => {
                console.log("visualizzo la schedaTranche=", res);
                
                // passo alla pagina della scheda controllo appena creata
                this.$router.push({
                    name: 'schedaTranche',
                    params: {
                        codiceProgetto: this.codiceProgetto,
                        idScheda: res.idScheda
                    }
                })

                this.isWaitingForSchedaPost = false;
            })
            .catch((error) => {
                console.error("Errore:", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },

        closeModal() {
            this.$emit('closeModalCheckList')
        },

        loadProjectByCodiceProgetto(codice) {
            //carico i dati del progetto per vedere prefinanziamento e tranche definite

            // Rimosso Paletto: si punta sempre alla versione in Attuazione
            this.$get(endpoints.getProgettoByCodice(codice))
            .then((result) => {
                const response = result.schedaProgetto
                this.schedaProgetto = response
                // Rimosso Paletto: se il Progetto è in Attuazione non si possono fare altra azioni
                // if (result && result.content.stato === "In Attuazione"){
                //     this.schedaProgetto = result.content;
                // } else {
                //     console.error("Progetto in rimodulazione! Alcuni dati potrebbero non essere stabili");
                // }

                if (response && response.erogazione){    

                    if (response.erogazione.percentualePrefinanziamento > 0){ //se è stato richiesto prefinanziamento verifico che sia stato concesso (processo prefinanziamento terminato)
                        //chiamo l'API per verificare se esiste una scheda Prefinanziamento e che sia stata esitata correttamente
                        this.cercaSchedaPrefinanziamento(codice);
                    }

                    if (response.erogazione.tranche && response.erogazione.tranche.length > 0) {

                        if (response.erogazione.tranche.length == 0) {
                            console.error("Nessuna tranche definita per il progetto!");
                            this.isTranche = false;
                        } else {
                            console.log("Tranche definite nel progetto=", response.erogazione.tranche.length);
                            this.isTranche = true;
                            this.numeroTrancheProgetto = response.erogazione.tranche.length;
                        }
                        
                        this.trancheOptions = response.erogazione.tranche;
                        
                        for (let i=0; i < this.trancheOptions.length; i++){
                            this.trancheOptions[i].disabled = true; //disabilito tutte le tranche (solo visualizzazione)
                        }

                        //conto quante richieste di tranche esistono già e deduco la tranche da chiedere
                        if (response.erogazione.tranche.length > this.richiesteTranche.length){
                            this.selectedTr = this.richiesteTranche.length;
                            this.trancheOptions[this.selectedTr].disabled = false;    //abilito l'unica tranche richiedibile
                            console.log("Tranche che si può richiedere ", this.trancheOptions[this.selectedTr]);
                        } else {
                            console.error("Tutte le tranche definite sono state già richieste");
                            this.allDone = true;
                        }
                        
                    } else {
                        console.error("Nessuna tranche definita per il progetto "+codice+". Inserirle manualmente nel dato su MongoDB, collecion schedaProgetto: content.erogazione.tranche");
                    }

                } else {
                    console.error("Nel progetto non sono definite le modalità di erogazione!");
                    this.isTranche = false;
                }
    
            })
            .catch((error) => {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },

        async cercaSchedaPrefinanziamento(codiceProgetto){
            const url = endpoints.prefinanziamentoByCodiceProgetto(codiceProgetto);
            this.$get(url)
            .then((result) => {
                if (result && result.length == 1 && result[0].stato.includes("Erogato")){
                    this.noPrefDone = false;
                    console.log("Trovata scheda prefinanziamento ("+result[0].idScheda+") nello stato =", result[0].stato);
                } else if (result && result.length > 1){
                    console.error("Trovate più schede prefinanziamento per il progetto!?!?", result);
                } else {
                    console.error("Scheda Prefinanziamento per il progetto "+codiceProgetto+" non trovata o ancora in itinere!");
                }

            })
            .catch((error) => {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
            
        },

        async creaNuovaScheda(codiceProgetto, trancheOpt){

            try {
                let lastChecklist = await this.$get(
                    endpoints.retrieveLastVersionTabellone('2_tranche')
                    )

                let lastChecklistVersion = lastChecklist.lastVersion;
                console.log("ultima versione tabellone: ", lastChecklistVersion);

                let anagrafica = {};
                if (lastChecklist){
                    anagrafica = feedFx.feedAnagraficaTranche(this.schedaProgetto);
                    console.log("filled anagrafica=", anagrafica);
                }

                let infoProg = {};
                if (this.schedaProgetto){
                    infoProg = feedFx.feedInfoProgetto(this.schedaProgetto);
                    console.log("filled infoProgetto=", infoProg);
                }

                let tranche = {};
                if (trancheOpt){
                    let calcImp = 0;
                    if (this.schedaProgetto.progetto.costoProgetto){
                        calcImp = this.schedaProgetto.progetto.costoProgetto * trancheOpt.percent / 100.0;
                    } else {
                        console.error("Costo Progetto non definito!?", this.schedaProgetto);
                    }

                    let iban = "";
                    if (this.schedaProgetto.erogazione.coordinateAccredito){
                        iban = this.schedaProgetto.erogazione.coordinateAccredito;
                    }

                    tranche = {
                        "numeroTrancheProgetto" : this.numeroTrancheProgetto,
                        "index" : trancheOpt.value,
                        "importo" : calcImp,
                        "percentualeTrasferimento" : trancheOpt.percent,
                        "coordinateAccredito" : iban,
                        "dataDichiarazioneSpesa" : "",
                        "protocolloDichiarazioneSpesa" : ""
                    }
                }

                //se esistono le coordinate nel progetto, valorizzo il campo
                if (this.schedaProgetto && this.schedaProgetto.content 
                    && this.schedaProgetto.content.erogazione && this.schedaProgetto.content.erogazione.coordinateAccredito){
                    tranche.coordinateAccredito = this.schedaProgetto.content.erogazione.coordinateAccredito;
                }
                console.log("filled tranche=", tranche);

                let url = endpoints.trancheProgetto(codiceProgetto);
                let userIdGroup = this.$getUserGroupId();
                userIdGroup = userIdGroup.replace('/MINT/','');
                userIdGroup = userIdGroup.replace('/Beneficiari/','');
                //console.log("strippato idBeneficiario=", userIdGroup);
                const autore = this.$getUserInfo().preferred_username;
                
                const body = {
                    codiceProgetto: codiceProgetto,
                    idSchemaControllo: '2_tranche',
                    schemaVersione: lastChecklistVersion,
                    idBeneficiario: userIdGroup,
                    tipoControllo: "CircuitoFinanziario",
                    sottoTipoControllo: "Tranche",
                    autoreUltimoAggiornamento: autore,
                    content: {
                        titoloControllo: this.titoloControllo, // inserito dall'utente
                        tranche: tranche,                      // scelta fra le opzioni in base alla rendicontazione
                        autoreUltimoAggiornamento: autore,
                        autocontrollo: {},
                        controllo: {},
                        anagrafica: anagrafica,
                        infoProgetto: infoProg,
                        deroga: false
                    }
                }
                // POST di creazione della scheda controllo
                let result = await this.$post(url, body);
                let schedaCreata = result;
                if (!schedaCreata.idScheda ||
                    !schedaCreata.codiceProgetto)
                {
                    let msg = "Scheda Tranche creata non valida. Controllare il servizio ISF-Controlli"
                    console.error(msg, result);
                    throw new Error(msg);
                }
                console.log(
                    "scheda Tranche creata",
                    "idScheda=", schedaCreata.idScheda,
                    "codiceProgetto=", schedaCreata.codiceProgetto
                );
                
                // avvio il processo
                this.processo = await this.avviaProcesso(schedaCreata.idScheda, schedaCreata.codiceProgetto);
                console.log("Processo avviato, ID=", this.processo);

                //effettuo il claim implicito per l'utente che ha creato il processo
                await this.autoclaim(schedaCreata.idScheda);
                return schedaCreata;

            } catch (error) {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                throw error;
            }
        },

        async avviaProcesso(idScheda, codiceProgetto){
            const url = endpoints.startTrancheProcess;
            const body = {
                codiceProgetto: codiceProgetto,
                idScheda: idScheda
            }
            // POST per avvio processo tranche
            return this.$post(url, body)
        },

        // POST per autoclaim
        async autoclaim(idScheda) {
            const url = endpoints.claimPrefinanziamento+idScheda;
            return this.$post(url)
        },

        

        
    },

    mounted() {
      this.codiceProgetto = this.$route.params.codiceProgetto;
      console.log("Carico i dati del progetto "+this.codiceProgetto)
      this.loadProjectByCodiceProgetto(this.codiceProgetto);
      console.log("Rilevate "+this.richiesteTranche.length+" richieste tranche");
    },

}
</script>