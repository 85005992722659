import { JSONPath as jpath } from 'jsonpath-plus';
import mapUtils from '@/helpers/mapUtils.js'
import store from '@/store/store.js'
import tool from '@/helpers/tools.js'
import Vue from 'vue'
import utils from "@/helpers/utils.js";
import validation from '@/helpers/validations.js'

function enumRifContrattoV1(options) {
  let contrattiIds = [];
  // TASK #10780
  contrattiIds.push(mapUtils.nonDefinito.value)

  if(options) {
    const contratti = options.contratti;
    //inserisco le voci ai contratti selezionabili
    if (contratti && contratti.length > 0){
      for (const contratto of contratti) {
        let contrattoInfo = contratto.riferimentoContratto; 
        contrattiIds.push(contrattoInfo);
      }
    }
  } else   {
    console.log("enumRifContrattoV1: NULL options! Non posso calcolare i contratti disponibili");
  }
  return contrattiIds;

}

function enumMacroProcedureV1() {
  const tipologieProcedura = store.state.configuration.tabelloni['procedure_tipologie'];
  let elencoProcedure = [];
  if(tipologieProcedura && tipologieProcedura.tabellone) {
    for(const [key, value] of Object.entries(tipologieProcedura.tabellone)) {
        elencoProcedure.push({label: value.label, value: key});
    }
  }

  return elencoProcedure;
}

function enumProcedureV1() {
  const tipologieProcedura = store.state.configuration.tabelloni['procedure_tipologie'];
  let elencoProcedure = [];
  if(tipologieProcedura && tipologieProcedura.tabellone) {
    const macroTipologie = Object.values(tipologieProcedura.tabellone);
    if(macroTipologie) {
      const objList = macroTipologie.map(item => item.data);
      elencoProcedure.push({label:"Non Avviata",value:"non avviata"});
      for(const item of objList) {
        for(const [id, obj] of Object.entries(item)) {
          elencoProcedure.push({label: obj.label, value: id});
        }
      }
    }
    
  }

  return elencoProcedure;
}

function idIndicatoreV1(mapElement, scheda, options) {
  let retVal = [];
  if(!options)
    return
  if(!mapElement || !scheda) {
    console.error('idIndicatoreV1 bad params', mapElement, scheda);
    return retVal;
  }
  // costruisce l'array degli indicatori già aggiunti (e non cancellati) ma
  // solo se la enum è modificabile.
  let existingIds = [];
  if(!mapElement.config.readonly) {
    const path = mapUtils.getGenericArrayKey(mapElement.config.path);
    const undeletedIds = Vue.prototype.$getUndeletedValues(path, scheda);
    if(undeletedIds && undeletedIds.length > 0) {
      existingIds = undeletedIds.map( item => { return item.value });
    }
  }
  // recupera la mappa degli indicatori dallo store e
  // la lista degli obiettivi specifici dalla scheda
  const mappaIndicatori = store.state.progetto.indicatori;
  const idOS = "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['obiettivoSpecifico']['codice']";
  let osArray = jpath('$' + idOS, scheda);
  if(!mappaIndicatori || !osArray) {
    console.error('idIndicatoreV1 error: no indicatori or ob. spec.', mappaIndicatori, scheda);
    return retVal;
  }
  // crea un array con soli obiettivi specifici distinti
  osArray = osArray.filter((x, i, a) => a.indexOf(x) === i);
  // caso degli indicatori standard
  if(options === 'STANDARD') {
    // costruisco una lista con tutti gli indicatori di ogni
    // obiettivo specifico (se non già presenti)
    for(const os of osArray) {
      // let i = 1;
      // controllo perchè non tutti gli os hanno indicatori
      if(mappaIndicatori[os]) {
        // aggiunge tutti gli indicatori ancora disponibili
        for(let item of mappaIndicatori[os]) {
          let id = os + '.' + item.id;
          if(existingIds.indexOf(id) === -1) { 
            retVal.push(id);
          }
        }
      }
    }
  } else {
    // caso degli obiettivi custom: 3 obiettivi aggiuntivi per ogni
    // obiettivo specifico (se non già presenti)

    // per ogni obiettivo specifico determina l'ultimo indice:
    // l'indicatore custom partirà da questo indice +1
    
    for(const os of osArray) {
      let i = 1;
      if(mappaIndicatori[os]) {
        for(let item of mappaIndicatori[os]) {
          let lastIndex = item.id.match(/\d/);
          if(lastIndex && lastIndex.length > 0) {
            i = parseInt(lastIndex[0]) + 1;
          }
        }
      }
      if(isNaN(i))
        i = 1;
        // aggiunge i 3 indicatori custom (se non presenti)
      for(let j = 0; j < 3; j++) {
          let id = os + '.C' + i;
          if(existingIds.indexOf(id) === -1)
            retVal.push(id);
          i++;
      }
    }
  }
  return retVal;
}

function calculateEnum(mapElement, scheda, options) {
  switch(mapElement.config.enumRule) {
    case 'enumIdAttivitaV1':
      return enumIdAttivitaV1(mapElement, scheda);
    case 'idIndicatoreV1':
      return idIndicatoreV1(mapElement, scheda, options);
    case 'enumRifContrattoV1':
      return enumRifContrattoV1(options);
    case 'enumProcedureV1':
      return enumProcedureV1();
    case 'enumMacroProcedureV1':
      return enumMacroProcedureV1();
        
    default:
      console.log('unknown enum rule', mapElement.config.enumRule);
      return [];
  }
}


function calculateIstruttoria(mapElement, schedaDoc, ids) {
    if(!mapElement || !mapElement.config || !mapElement.config.calculationRule)  {
        console.error('calculateIstruttoria bad params', mapElement);
        return '';
    }

    switch(mapElement.config.calculationRule) {
      case 'valutazionePunteggio':
        {
          return valutazionePunteggio(mapElement, schedaDoc, ids);
        }
      case 'istrTerminiV1':
        {
          return istrTerminiV1(mapElement, schedaDoc);
        }
      case 'istrBudgetV1':
        {
          return istrBudgetV1(mapElement, schedaDoc);
        }
      default:
        console.log('calculateIstruttoria unknown rule', mapElement.config.calculationRule);
        return '';
    }
}

function valutazionePunteggio(mapElement, schedaDoc, idsScheda) {
  // Funzione di calcolo per la valutazione di Merito della scheda Istruttoria
  let valutazioneComplessiva = 0
  
  let merito = schedaDoc.content.istruttoria.merito
  if(!merito || idsScheda.length === 0) {
    return {value: valutazioneComplessiva}
  }
    for (const id of idsScheda) {
      const values = jpath({resultType: 'all'}, '$' + id, schedaDoc.content);
      
      // Se dato un id trovo un unico valore in scheda
      if(values && values.length === 1) {
        // se l'id appartiene al tab merito e se il valore è una String
        if(id.indexOf('merito') !== -1 && typeof(values[0].value) === 'string') {
          let valueToSum = parseFloat(values[0].value)
          if(!isNaN(valueToSum)) {
            valutazioneComplessiva = valutazioneComplessiva + valueToSum
          }
        }
      }
    }
  
  return {value: valutazioneComplessiva}
}

function istrBudgetV1(mapElement, schedaDoc) {
  let retVal = {};
  if(!schedaDoc) {
    console.error('istrBudgetV1 bad params', schedaDoc);
    return retVal;
  }

  if(schedaDoc.modalitaDiAccesso === "Call for proposal") {
    retVal.value = "SI";
  }
  else {
    retVal.value = "N/A";
    retVal.note = mapElement.config.defaultNote;
  }
  return retVal;
}

function istrTerminiV1(mapElement, schedaDoc) {
  let retVal = {};
  if(!schedaDoc) {
    console.error('istrTerminiV1 bad params', schedaDoc);
    return retVal;
  }

  if(schedaDoc.modalitaDiAccesso === "Call for proposal") {
    retVal.value = "SI";
  }
  else {
    retVal.value = "N/A";
    retVal.note = mapElement.config.defaultNote;
  }
  return retVal;
}
  
function computeDate(computeRule) {
  if(!computeRule) {
    console.error('computeDate bad params', computeRule);
    return null;
  }
  const result = isValidConfigDate(computeRule);
  if(result)
    return result;

  switch(computeRule) {
    case 'nowV1':
      return new Date();
    case 'maxEndDateV1': {
      return maxEndDateV1();
    }
    default:
      console.log('computeDate unknown rule', computeRule);
      return null;
    }
}

function calculateValue(mapElement, scheda, key, actionFromModal, optionalObj) {
    if(!mapElement || !mapElement.config || !mapElement.config.calculationRule)  {
        console.error('calculateValue bad params', mapElement);
        return '';
    }
    let valueReturn = ''
    switch(mapElement.config.calculationRule) {
      // Funzione usata in Controlli (Fase2)
      case 'totaleFatturaV1':
        {
          valueReturn = totaleFatturaV1(mapElement, scheda, key, actionFromModal);
          break;
        }
      case 'totaleContrattoV1':
      case 'importoContrattoTotaleV1':
          valueReturn = importoContrattoTotaleV1(mapElement, scheda, key, actionFromModal);
          break;
      // Funzione usata in: Monitoraggio / Controlli (1B)
      case 'totaleInRowCosts':
      {
        valueReturn = totaleInRowCosts(scheda, key, actionFromModal);
        break;
      }
      case 'progressiveFirstIndexV1':
      {
        valueReturn = progressiveFirstIndexV1(key);
          break;
      }
      case 'progressiveSecondIndexV1':
      {
        valueReturn = progressiveSecondIndexV1(mapElement, scheda, key, actionFromModal);
        break;
      }
      case 'descrizioneIndicatoreV1':
      {
        valueReturn = descrizioneIndicatoreV1(mapElement, scheda, key, actionFromModal);
        break;
      }
      case 'unitaMisuraIndicatoreV1':
      {
        valueReturn = unitaMisuraIndicatoreV1(mapElement, scheda, key, actionFromModal);
        break;
      }
      // EXECUTING BODY
      case 'maxDotazione_EB':
      {
        valueReturn = maxDotazione_EB(mapElement, scheda);
        break;
      }
      case 'importoImpiegato_EB':
      {
        valueReturn = importoImpiegato_EB(mapElement, scheda);
        break;
      }
      case 'risorseResidue_EB':
      {
        valueReturn = risorseResidue_EB(mapElement, scheda);
        break;
      }
      // case 'costoProgettoV1':
      // {
      //   valueReturn = costoProgettoV1(scheda);
      //   break;
      // }
      // case 'durataProgettoMesiV1':
      // {
      //   valueReturn = durataProgettoMesiV1(scheda);
      //   break;
      // }
      case 'dataInizioProgettoV1':
      {
        valueReturn = dataInizioProgettoV1(scheda);
        break;
      }
      case 'dataConclusioneProgettoV1':
      {
        valueReturn = dataConclusioneProgettoV1(scheda);
        break;
      }
      case "dataModificaV1":
      {
        valueReturn = formatDataModificaV1(scheda);
        break;
      }
      case 'importoComunitarioV1':
      {
        valueReturn = importoComunitarioV1(scheda);
        break;
      }
      case 'importoNazionaleV1':
      {
          valueReturn = importoNazionaleV1(scheda);
          break;
      }
      case "dataFirmaConvenzioneV1":
      {
          valueReturn = formatDataFirmaConvenzione(scheda);
          break;
      }
      case 'leggiAutoreControllo':
      {
        valueReturn = leggiAutoreControllo(mapElement, scheda, key);
        break;
      }
      case 'totaleDichiarazioneV1':   //funzione usata nei controlli fase 2
      {
        valueReturn = totaleDichiarazioneV1(scheda, key);
        break;
      }
      case 'importoTotaleIsfV1':   //funzione usata nei controlli fase 2
      {
        // optionalObj è il controllo
        valueReturn = importoTotaleIsfV1(scheda, key, optionalObj);
        break;
      }
      case 'rifDichiarazioneV1':   //funzione usata nei controlli fase 2
      {
        // optionalObj è il controllo
        valueReturn = rifDichiarazioneV1(scheda, key, actionFromModal, optionalObj);
        break;
      }
      case 'rifDichiarazioneAssessmentV1':
      {
        valueReturn = rifDichiarazioneAssessmentV1(scheda, key, actionFromModal, optionalObj);
        break;
      }
      case 'rifDichiarazioneImportoV1':   //funzione usata nei controlli fase 2
      {
        // optionalObj è il cronoprog
        valueReturn = rifDichiarazioneImportoV1(scheda, key, actionFromModal, optionalObj);
        break;
      }
      case 'totaleSpeseV1':
      {
        valueReturn = totaleSpeseV1(mapElement, scheda, key);
        break;
      }
      case 'calcolaStatoV1':
      {
        valueReturn = calcolaStatoV1(scheda, key);
        break;
      }
      case 'oggettoContrattoV1':   //funzione usata nelle disposizioni di pagamento
      {
        // optionalObj è ???
        valueReturn = oggettoContrattoV1(scheda, key, actionFromModal, optionalObj);
        break;
      }
      case 'soggettoContrattoV1':   //funzione usata nelle disposizioni di pagamento
      {
        // optionalObj è ???
        valueReturn = soggettoContrattoV1(scheda, key, actionFromModal, optionalObj);
        break;
      }
      default:
          console.log('calculateValue unknown rule', mapElement.config.calculationRule);
          break;
    }
   
    // GESTIONE CAMPI CHE POSSONO GENERARE MODIFICHE SOSTANZIALI
    if(mapElement.config.canBeSubstancial && scheda.dataEntryConfiguration) {
      handleFieldcanBeSubstancial(scheda, key, valueReturn)
    }
    return valueReturn;
}

//in base alle date inserite ritorna uno stato dell'ENUM definito in StatoFaseAttivita.java
  function calcolaStatoV1(scheda, key) {
    if(!scheda || !key) {
      console.error('calcolaStatoV1 bad params', scheda, key);
      return 'N/D';
    }
    // a partire dalla key, rimuovo l'ultimo campo (['stato']);
    const basePath = key.match(/^.*\W\d+\W/)[0];
    const values = jpath('$' + basePath, scheda);
    if(!values || values.length !== 1) {
      console.error('calcolaStatoV1 multiple values', values);
      return 'N/D';
    }
    // dal valore trovato (dettaglioFase) estraggo la data inizio e fine
    const value = values[0];
    let dataInizioFase = value.dataInizio;
    let dataFineFase = value.dataFine;

    //formato date in ingresso aaaa-mm-gg
    //console.log("fase INIZIO="+dataInizioFase+" FINE="+dataFineFase);
    const trimestre = calcolaInizioFineTrimestre();
    let dataInizioTrimestreCorrente = trimestre.inizio;
    let dataFineTrimestreCorrente = trimestre.fine;
    if(!dataInizioFase || !dataFineFase)
      return "Da realizzare";     //futuro

    if(tool.isDate(dataInizioFase)) {
      let dataStringa = dataInizioFase.getFullYear()+"-"+(dataInizioFase.getMonth()+1)+"-"+dataInizioFase.getDate();
      dataInizioFase = dataStringa;
    }

    if(tool.isDate(dataFineFase)) {
      let dataStringa = dataFineFase.getFullYear()+"-"+(dataFineFase.getMonth()+1)+"-"+dataFineFase.getDate();
      dataFineFase = dataStringa;
    }
    dataInizioFase = dataInizioFase.substring(0,10);
    dataFineFase = dataFineFase.substring(0,10);

    if(dataInizioFase > dataFineTrimestreCorrente) {
      return "Da realizzare";     //futuro
    } else if (dataFineFase < dataInizioTrimestreCorrente){
      return "Realizzato";        //passato
    } else if (dataFineFase > dataInizioTrimestreCorrente && dataFineFase < dataFineTrimestreCorrente) {
      return "In realizzazione";  //si completa ora
    } else {
      return "Realizzato in parte";
    }
  }

  function calcolaInizioFineTrimestre(){
    let date = new Date();
      
    let dataStringa = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();

    dataStringa = dataStringa.substring(0,5);
    let dataStringa1, dataStringa2;
    if (date.getMonth()<=2) {
      dataStringa1 = dataStringa + "01-01"
      dataStringa2 = dataStringa + "03-31"
    } else if (date.getMonth()>2 && date.getMonth()<=5) {
      dataStringa1 = dataStringa + "04-01"
      dataStringa2 = dataStringa + "06-30"
    } else if (date.getMonth()>5 && date.getMonth()<=8) {
      dataStringa1 = dataStringa + "07-01"
      dataStringa2 = dataStringa + "09-30"
    } else {
      dataStringa1 = dataStringa + "10-01"
      dataStringa2 = dataStringa + "12-31"
    }
    
    return {
      inizio: dataStringa1,
      fine: dataStringa2
    };
  }

function importoTotaleIsfV1(scheda, key, optionalObj) {
  
  const rootId = scheda.cronoprogRootId;
  const idControllo = scheda.idSchedaControllo;

  if(!optionalObj) {
    console.log('importoTotaleIsfV1: no optionalObj', key);
    return 'N/D';
  }
  const pagamentiArray = Object.values(optionalObj[rootId]);
  if(pagamentiArray) {
      // array dei soli pagamenti collegati alla dichiarazione di spesa corrente
      const pagamentiFiltered = pagamentiArray.filter(
          pagamento => {
            return pagamento.content.flagged === idControllo
      });
      // di questi pagamenti estraggo calcolo il totale
      if(pagamentiFiltered) {
        let initialVal = 0;
        return pagamentiFiltered.reduce((total, curVal) => {
            const parsedVal = parseFloat(curVal.content.importoFatturaSuISF);
            if(isNaN(parsedVal)) {
              return total;
            } else {
              return total + parsedVal;
            }
          }, initialVal
        );
      }
  }
  return 0;
}

function totaleSpeseV1 (mapElement, scheda, key) {
    let sum = 0
    if(!mapElement || !key) {
      console.log('totaleSpeseV1 bad params', mapElement, key);
      return sum;
    }

    const regx = /\['\w+'\]$/
    const id_spesa = key.replace(regx, "['spese'][*]['spesa']")
    // console.log(key, mapElement.data.deleted);
    // Attraverso questo ID recupero tutte le spese per il calcolo del totale
    const values_all_spese = jpath({resultType: 'all'}, '$'+ id_spesa, scheda);
    if(!values_all_spese || values_all_spese.length === 0)
      return sum

    // Eliminazione di tutti i valori null
    const values_all_spese_cleaned = values_all_spese.filter(element => element.value != null);
    let mappa = scheda.dataEntryConfiguration ? scheda.dataEntryConfiguration : {}
    for(const spesaObj of values_all_spese_cleaned) {
      const path = spesaObj.path.substr(1);
      const mapEl = mappa[path];
      // skip per tutte le spese cancellate
      if(mapEl && mapEl.data && mapEl.data.deleted) {
        continue;
      }
      const spesa = parseFloat(spesaObj.value);
      sum += tool.roundFloat2Decs(spesa);
    }
    return tool.roundFloat2Decs(sum);
}

function oggettoContrattoV1(scheda, key, actionFromModal, optionalObj) {
  if (!actionFromModal) {

    //se esiste il valore nella scheda
    if (optionalObj && !optionalObj.action || optionalObj.action != "add"){
      let value = jpath('$' + key, scheda);
      if(value && value.length === 1) {
        return value[0];
      }
    }

    let idRiferimentoContratto = "['content']['disposizione']['riferimentoContratto']";
    const values = jpath('$' + idRiferimentoContratto, scheda);
    if (values && values.length === 1) {
      const rifContratto = values[0];
      if(!optionalObj) {
        console.error('oggettoContrattoV1 no optionalObj', key)
        return null;
      }
      if(!optionalObj.contratti || optionalObj.contratti && optionalObj.contratti.length === 0) return "N/D"
      else if(optionalObj.contratti.length > 0) {
        for (const contr of optionalObj.contratti){
          if (contr.riferimentoContratto == rifContratto){
            return contr.oggettoContratto;
          } else return "N/D"
        }
      }
    } else {
      return 'N/D';
    }

  } else if (actionFromModal){
    let idRiferimentoContratto = "['content']['disposizione']['riferimentoContratto']";
    let rifContratto = actionFromModal.content[idRiferimentoContratto].value;
    if(!optionalObj) {
      console.error('oggettoContrattoV1: from modalform, no optionalObj', key)
      return null;
    }
    for (const contr of optionalObj.contratti){
      if (contr.riferimentoContratto == rifContratto){
        return contr.oggettoContratto;
      } else return "N/D"
    }
    return "N/D";
  } else {
    console.error("oggetto contratto non calcolabile");
    return "Non disponibile";
  }
}

function soggettoContrattoV1(scheda, key, actionFromModal, optionalObj) {
  if (!actionFromModal){

    //se esiste il valore nella scheda
    if (optionalObj && !optionalObj.action || optionalObj.action != "add"){
      let value = jpath('$' + key, scheda);
      if(value && value.length === 1) {
        return value[0];
      }
    }

    let idRiferimentoContratto = "['content']['disposizione']['riferimentoContratto']";
    const values = jpath('$' + idRiferimentoContratto, scheda);
    if (values && values.length === 1) {
      if(!optionalObj) {
        console.error('soggettoContrattoV1 no optionalObj', key)
        return null;
      }
      const rifContratto = values[0];
      if(!optionalObj.contratti || optionalObj.contratti && optionalObj.contratti.length === 0) return "N/D"
      else if(optionalObj.contratti.length > 0) {
        for (const contr of optionalObj.contratti){
          if (contr.riferimentoContratto == rifContratto){
            return contr.soggettoAttuatore;
          } else return "N/D"
        }
      }
    } else {
      console.log('soggettoContrattoV1: not found ', key);
      return 'N/D';
    }

  } else if (actionFromModal){
    let idRiferimentoContratto = "['content']['disposizione']['riferimentoContratto']";
    let rifContratto = actionFromModal.content[idRiferimentoContratto].value;

    if(!optionalObj) {
        console.error('soggettoContrattoV1 from modalForm1, no optionalObj', key)
        return null;
    }
    for (const contr of optionalObj.contratti){

      if (contr.riferimentoContratto == rifContratto){
        return contr.soggettoAttuatore;
      } else return "N/D"
    }

    return "N/D";
  } else {
    console.error("soggetto attuatore contratto non calcolabile");
    return "Non disponibile";
  }

}

function rifDichiarazioneImportoV1(scheda, key, actionFromModal, optionalObj) {

  const flagPath = "['pagamenti'][*]['content']['flagged']";
  const alfaId = key.match(/\w+/g)[1];
  const flagId = mapUtils.getCronoprogId(alfaId, flagPath);

  let flagValue;

  if(actionFromModal && actionFromModal.content[flagId]) {
    // se siamo in modalForm1 prendo il flag dalla modale
    flagValue = actionFromModal.content[flagId].value;
  }
  else {
    // altrimenti prendo il flag dalla scheda (cronoprog)
    const values = jpath('$' + flagId, scheda);
    // controllo il valore del flag
    if(values && values.length === 1) {
      flagValue = values[0];
    }
    else
      console.log('rifDichiarazioneImportoV1: flag not set for ', flagId, values);
  }

  if(!flagValue) {
    // se non c'è flag, è un pagamento indiretto
    return 'N/D';
  }
  else if(optionalObj && flagValue === optionalObj.idSchedaControllo) {
    const totalePath = "['dichiarazione']['speseTotaliPeriodo']";
    return totaleDichiarazioneV1(optionalObj.content, totalePath);
  }
  else {
    // se il flag è una dichiarazione precedente, allora ritorno il valore non modificato
    const rifDichs = jpath('$' + key, scheda);
    if(rifDichs && rifDichs.length === 1)
      return rifDichs[0];
  }

  return 'N/D';
}

function rifDichiarazioneAssessmentV1(scheda, key, actionFromModal, optionalObj){

  for(const obj in optionalObj){
    const flagPath = "['pagamenti'][*]['content']['flagged']";
    const alfaId = key.match(/\w+/g)[1];
    const flagId = mapUtils.getCronoprogId(alfaId, flagPath);
    let flagValue;

    // prendo il flag dalla scheda (cronoprog)
    const values = jpath('$' + flagId, scheda);
    // controllo il valore del flag
    if(values && values.length === 1) {
      flagValue = values[0];
    }
    else {
      console.log('rifDichiarazioneV1: flag not set for ', flagId, values);
    }

    if(!flagValue) {
    // se non c'è flag, è un pagamento indiretto
      return 'Nessuna';
    }
    else if(obj && flagValue === obj.idSchedaControllo) {
      const dataInserimento = obj.dataInserimento;
      const titolo = obj.content.titoloControllo;
      const data = utils.formatDate(dataInserimento)
      // se il flag è la dichiarazione corrente, allora costruisco il riferimento dichiarazione

      return '"' + titolo + '" del '  + data;
    }
    else {
      // se il flag è una dichiarazione precedente, allora ritorno il valore non modificato
      const rifDichs = jpath('$' + key, scheda);
    if(rifDichs && rifDichs.length === 1)
      return rifDichs[0];
    }
    return 'N/D';
  }
}


function rifDichiarazioneV1(scheda, key, actionFromModal, optionalObj) {
  const flagPath = "['pagamenti'][*]['content']['flagged']";
  const alfaId = key.match(/\w+/g)[1];
  const flagId = mapUtils.getCronoprogId(alfaId, flagPath);

  let flagValue;

  if(actionFromModal && actionFromModal.content[flagId]) {
    // se siamo in modalForm1 prendo il flag dalla modale
    flagValue = actionFromModal.content[flagId].value;
  }
  else {
    // altrimenti prendo il flag dalla scheda (cronoprog)
    const values = jpath('$' + flagId, scheda);
    // controllo il valore del flag
    if(values && values.length === 1) {
      flagValue = values[0];
    }
    else
      console.log('rifDichiarazioneV1: flag not set for ', flagId, values);
  }

  if(!flagValue) {
    // se non c'è flag, è un pagamento indiretto
    return 'Nessuna';
  }
  else if(optionalObj && flagValue === optionalObj.idSchedaControllo) {
    const dataInserimento = optionalObj.dataInserimento;
    const titolo = optionalObj.content.titoloControllo;
    const data = utils.formatDate(dataInserimento)
    // se il flag è la dichiarazione corrente, allora costruisco il riferimento dichiarazione
    return '"' + titolo + '" del '  + data;
    
  }
  else {
    // se il flag è una dichiarazione precedente, allora ritorno il valore non modificato
    const rifDichs = jpath('$' + key, scheda);
    if(rifDichs && rifDichs.length === 1)
      return rifDichs[0];
  }

  return 'N/D';
}

function totaleDichiarazioneV1(scheda, key){
  let importoTotale = 0;
  const regx = /\['\w+'\]$/;
  
  // IDS ( imponibile & iva )
  const id_imponibile = key.replace(regx, "['imponibile']");
  const id_iva = key.replace(regx, "['iva']");
  
  // VALORI ( imponibile & iva )
  let value_imponibile = jpath('$'+ id_imponibile, scheda)[0];
  let value_iva = jpath('$'+ id_iva, scheda)[0];

  if(value_imponibile || value_iva) {
    let value_imponibile_parsed = !isNaN(parseFloat(value_imponibile)) ? parseFloat(value_imponibile) : 0
    let value_iva_parsed = !isNaN(parseFloat(value_iva)) ? parseFloat(value_iva) : 0
    importoTotale = value_imponibile_parsed + value_iva_parsed
  }
  
  if(importoTotale)
    return tool.roundFloat2Decs(importoTotale);
  
  return 'N/D';
}

function totaleFatturaV1(mapElement, scheda_cronoProg, key, actionFromModal) {
  // L'importo totale del Pagamento, in reltà sarebbe una fattura, (Controlli, fase2)
  // si calcola sommando: imponibile + iva (dati presenti in scheda)
  // Se abbiamo actionFromModal questo totale deve essere ricalcolato (dati presenti nella modale)
  if(mapElement) {
    const regx = /\['\w+'\]$/
    // IDS ( imponibile & iva )
    const id_imponibile = key.replace(regx, "['imponibileFattura']")
    const id_iva = key.replace(regx, "['ivaFattura']")
    return calcolo_totale(id_imponibile, id_iva, scheda_cronoProg, actionFromModal)
  }
}

function importoContrattoTotaleV1(mapElement, scheda_cronoProg, key, actionFromModal) {
  // L'importo totale del Contratto (Monitoraggio / Assessment)
  // si calcola sommando: imponibile + iva (dati presenti in scheda)
  // Se abbiamo actionFromModal questo totale deve essere ricalcolato (dati presenti nella modale)
  if(mapElement) {
    const regx = /\['\w+'\]$/
    // IDS ( imponibile & iva )
    const id_imponibile = key.replace(regx, "['importoContratto']");
    const id_iva = key.replace(regx, "['importoContrattoIVA']")
    return calcolo_totale(id_imponibile, id_iva, scheda_cronoProg, actionFromModal)
  }

  return 'N/D';
}

function handleFieldcanBeSubstancial(incomingScheda, key, valueCalculated) {
  const mappa = store.state.progetto.tabelloneProgetti.progetto;
  let isSubstancial = false

  let scheda = tool.genericFunctions.cloneObject(incomingScheda);

  if(!scheda.dataEntryConfiguration[key]) {
    scheda.dataEntryConfiguration[key] = {}
    scheda.dataEntryConfiguration[key].data = {}
  }
  
  isSubstancial = validation.substancialValidationProgetto(key, scheda, mappa, valueCalculated);
  if(isSubstancial) {
    const id = "$" + key
    const idProgetto = scheda.progetto.idProgetto
    // Prendo il valore dalla schedaOriginale ( che è quella prima di qualsiasi modifica )
    const schedaOriginale = store.state.progetto.schedeProgetto[idProgetto].schedaOriginale;
    const temp = jpath(id, schedaOriginale);
    // Costruisco l'oggetto per visualizzarlo nella tabella delle modifiche Sostanziali
    scheda.dataEntryConfiguration[key].data.oldValue = (temp && temp.length === 1) ? temp[0] : ''
    scheda.dataEntryConfiguration[key].data.edited = true
    scheda.dataEntryConfiguration[key].data.editTag = ''
    scheda.dataEntryConfiguration[key].data.isSubstancial = isSubstancial
  }
  persistValue(key, valueCalculated, scheda);
}

function isValidConfigDate(dateString) {
  if(dateString) {
    const date = new Date(dateString);
    if(!isNaN(date.getTime()))
      return date;
  }
  return null;
}

function maxEndDateV1() {
  const result = store.state.progetto.miscConfig;
  if(result.maxEndDate) {
    return isValidConfigDate(result.maxEndDate);
  }
  return null;
}

function dataInizioProgettoV1(scheda) {
  const dataPrefix = "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]";
  const dataInizioId = dataPrefix + "['dataInizio']";

  const dataInizioProgettoId = "['progetto']['dataInizio']";

  // calcola la data inizio minore
  let dataInizio;
  const dates = jpath({resultType: 'all'}, '$' + dataInizioId, scheda);
  if(dates && dates.length > 0) {
    for(const result of dates) {
      if(result.value) {
        // salta le fasi deleted
        const id = result.path.substr(1);
        if(scheda.dataEntryConfiguration) {
          const dataEntry = scheda.dataEntryConfiguration[id];
          if(dataEntry && dataEntry.data && dataEntry.data.deleted) {
            continue;
          }
        }
        let temp = new Date(result.value).getTime();
        if (!dataInizio || temp < dataInizio)
          dataInizio = temp
      }
    }

    persistValue(dataInizioProgettoId, new Date(dataInizio), scheda);
  }

  

  return dataInizio;
}

function dataConclusioneProgettoV1(scheda) {
  // calcola la data fine maggiore
  const dataPrefix = "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]";
  const dataConclusioneId = "['progetto']['dataConclusione']";
  const dataFinedId = dataPrefix + "['dataFine']";
  let dataFine;
  const dates = jpath({resultType: 'all'}, '$' + dataFinedId, scheda);
  if(dates && dates.length > 0) {
    for(const result of dates) {
      if(result.value) {
        // salta le fasi deleted
        const id = result.path.substr(1);
        if(scheda.dataEntryConfiguration) {
          const dataEntry = scheda.dataEntryConfiguration[id];
          if(dataEntry && dataEntry.data && dataEntry.data.deleted) {
            continue;
          }
        }

        let temp = new Date(result.value).getTime();
        if (!dataFine || temp > dataFine)
          dataFine = temp
      }
    }

    persistValue(dataConclusioneId, new Date(dataFine), scheda);
  }

  

  return dataFine;
}

function persistValue(id, value, scheda) {
  let clonedScheda = tool.genericFunctions.cloneObject(scheda);
  const results = jpath({resultType: 'all'}, '$' + id, clonedScheda);
  let doUpdate = false;
  let parent, fieldName;
  if(!results || results.length === 0) {
    console.log('valore non trovato, update', id, clonedScheda);
    const mappa = store.state.progetto.tabelloneProgetti.progetto;
    const retVal = mapUtils.createElementFromScratch(id, clonedScheda, mappa);
    parent = retVal.parent;
    fieldName = retVal.fieldName;
    doUpdate = true;
  }
  else if(results[0] !== value) {
    parent = results[0].parent;
    fieldName = results[0].parentProperty;
    doUpdate = true;
  }

  // se ci sono modifiche il valore viene persistito
  if(doUpdate) {
    parent[fieldName] = value;
    store.dispatch('progetto/updateSchedaProgettoObject', 
    {
      idSchedaProgetto: clonedScheda.progetto.idProgetto,
      schedaProgetto: clonedScheda
    });
  }
}

function computeIndicatori(mapElement, scheda, key, modalContent) {
  if((!mapElement || ! mapElement.config || !scheda) && (!key || !modalContent)) {
    console.error('computeIndicatori bad params', mapElement, scheda, key, modalContent);
    return null;
  }
  const starsId = "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']";
  const indexes = mapElement.config.path.match(mapUtils.indexRegex);
  let realId;
  if(indexes && indexes.length === 1) {
    realId = starsId.replace('*', indexes[0]);
  }
  else
    return null;

  let idIndicatore;
  if(modalContent) {
    idIndicatore = modalContent.content[realId].value;
  }
  else if(scheda) {
    const values = jpath('$' + realId, scheda);
    if(values && values.length === 1) {
      idIndicatore = values[0];
    }
  }

  if(!idIndicatore) {
    console.log('computeIndicatori idIndicatore not yet available', realId, scheda, modalContent);
    return null;
  }

  const temp = idIndicatore.replace(/\./,'&').split('&')
  if(!temp || !temp.length == 2) {
    console.error('computeIndicatori split error', idIndicatore);
    return null;
  }
  const os = temp[0];
  const indic = temp[1];

  const mappaIndicatori = store.state.progetto.indicatori;

  const indicArray = mappaIndicatori[os];
  if(!indicArray)
    return null;
  
  const filtered = indicArray.filter(item => {
    return item.id === indic;
  });


  if(!filtered || filtered.length !== 1)
    return null;

  return filtered;
}

function maxDotazione_EB(mapElement, scheda) {
  if(mapElement.config.calculationRule) {
    let financialTool = scheda.schedaProgetto.progetto.strumentoFinanziario
    return scheda.dotazioniFinanziarie[financialTool];
  }
  return 'N/D';
}

function importoImpiegato_EB(mapElement, scheda) {
  if(mapElement.config.calculationRule) {
    return scheda.importo;
  }
  return 'N/D';
}

function risorseResidue_EB(mapElement, scheda) {
  if(mapElement.config.calculationRule) {
    let financialTool = scheda.schedaProgetto.progetto.strumentoFinanziario
    let dotazioniComplessive = scheda.dotazioniFinanziarie[financialTool]
    let importoImpiegato = scheda.importo
    return dotazioniComplessive - importoImpiegato
  }
  return 'N/D';
}

function unitaMisuraIndicatoreV1(mapElement, scheda, key, actionFromModal) {
  // se la descrizione è già stata inserita dal beneficiario la mantengo
  const value = jpath('$'+key, scheda);
  if(value && value.length === 1 && value[0]) {
    return value[0];
  }


  let retVal = 'N/D';

  const indicatore = computeIndicatori(mapElement, scheda, key, actionFromModal);
  if(!indicatore)
    return retVal;

  return indicatore[0].unitaDiMisura;
}

function descrizioneIndicatoreV1(mapElement, scheda, key, actionFromModal) {
  // se la descrizione è già stata inserita dal beneficiario la mantengo
  const value = jpath('$'+key, scheda);
  if(value && value.length === 1 && value[0]) {
    return value[0];
  }

  let retVal = 'N/D';

  const indicatore = computeIndicatori(mapElement, scheda, key, actionFromModal);
  if(!indicatore)
    return retVal;

  return indicatore[0].descrizione;
}

function totaleInRowCosts(scheda, keyElementToCalculate, actionFromModal) {
  if(!keyElementToCalculate) {
    console.error('totaleInRowCosts bad params', scheda, keyElementToCalculate, actionFromModal);
    return 0;
  }

  // caso del calcolo del totale dei costi indiretti (collapse budget per obiettivi)
  if(keyElementToCalculate.match('ndiretti'))
  {
    const id = keyElementToCalculate + "['importo']";
    const importoArray = jpath({resultType: 'all'}, '$' + id, scheda);
    let importoTot = 0;
    const dataEntry = scheda.dataEntryConfiguration;
    for(const item of importoArray) {
      
      const itemId = item.path.substr(1);
      if(dataEntry && dataEntry[itemId] && dataEntry[itemId].data.deleted) {
        continue;
      }
      const itemValue = parseFloat(item.value);
      if(!isNaN(itemValue))
        importoTot += itemValue;
    }

    return tool.roundFloat2Decs(importoTot);
  }

  const tempKey = keyElementToCalculate.match(/^.*\[[\W\d]+\]/);
  let costoKey;
  let quantitaKey;
  if(tempKey && tempKey.length > 0) {
    const prefix = tempKey[0];
    // le chiavi sono costruite a partire dal prefisso
    costoKey    = prefix + "['costoUnitario']";
    quantitaKey = prefix + "['quantita']";
  }

  let costoUnitario_value;
  let quantita_value;
  if(!actionFromModal) { // Calcolo il campo alla get di Scheda Progetto
    const costoArray = jpath({resultType: 'all'}, '$' + costoKey, scheda);
    const quantitaArray = jpath('$' + quantitaKey, scheda);
    const dataEntry = scheda.dataEntryConfiguration;
    
    // questa funzione è usata sia per la singola entry di budget
    // sia per calcolare i totali
    // nel caso in cui è una singola voce restituisco subito il valore
    if(costoArray && costoArray.length === 1 && !keyElementToCalculate.match(/\*/)) {
        let costo = computeCosto(costoArray[0].value, quantitaArray[0]);
        if(!isNaN(costo)) {
          return tool.roundFloat2Decs(costo);
        }
        else
          return 0;
    }
    else if(costoArray && quantitaArray && costoArray.length === quantitaArray.length) {
      // nel caso in cui ho appena aperto la modale per aggiungere un costo
      // devo restituire zero
      // questo accade quando il path è completo(termina con un oggetto)
      // e contiene asterischi
      const newCostoRegex = /\[\*\]\['\w+'\]$/;
      if(keyElementToCalculate.match(newCostoRegex))
        return 0;

      // se invece bisogna calcolare i totali salto le voci di costo eliminate
      let costo = 0;
      for(let i = 0; i < costoArray.length; i++) {
        // skip deleted values
        const id = costoArray[i].path.substr(1);
        
        if(dataEntry && dataEntry[id] && dataEntry[id].data.deleted) {
          continue;
        }

        let costoTemp = computeCosto(costoArray[i].value, quantitaArray[i]);
        if(!isNaN(costoTemp)) {
          costo += costoTemp;
        }
      }
      return tool.roundFloat2Decs(costo);
    }
  }
  // Calcolo il campo ad ogni aggiunta di una Row
  else {
    let content = actionFromModal.content
    // Recupero i valori che mi servono dalla modale
    // if(!content[costoKey]) {
    //   console.log('eee=', costoKey,content);
    //   return -5;
    // }
    costoUnitario_value = content[costoKey].value
    quantita_value = content[quantitaKey].value
    return computeCosto(costoUnitario_value, quantita_value);
  }

  return 0;
}

function importoComunitarioV1(scheda) {
  let retVal = 0;
  if(!scheda) {
    console.error('importoComunitarioV1 bad params', scheda);
    return retVal;
  }

  const costoProgettoId = "$['progetto']['costoProgetto']";
  const costoValues = jpath(costoProgettoId, scheda);
  if(costoValues && costoValues.length === 1) {
    const costo = costoValues[0];
    const costoParsed = parseFloat(costo);

    const quotaId = "$['erogazione']['percentualeComunitaria']";
    const quotaValues = jpath(quotaId, scheda);
    if(quotaValues && quotaValues.length === 1) { 
      const quota = quotaValues[0];
      const quotaParsed = parseFloat(quota);
      const percentuale = quotaParsed * costoParsed / 100.0;

      if(!isNaN(percentuale))
        retVal = percentuale;
    }
  }

  return retVal;
}

function formatDataFirmaConvenzione(scheda) {
  if(scheda.dataFirmaConvenzione == null | scheda.dataFirmaConvenzione == "undefined") {
    return "";
  } else {
      const date_firmaConvenzione = "$['dataFirmaConvenzione']";
      const dateValues = jpath(date_firmaConvenzione, scheda);
      var date = new Date(dateValues),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
                return [ day, mnth, date.getFullYear() ].join("/");
  }
  
}

function formatDataModificaV1(scheda) {
  console.log("formatDataModifica: ");
  console.log(JSON.stringify(scheda));
  if(scheda.dataModifica == null | scheda.dataModifica == "undefined") {
    return "";
  } else {
      const dateModifica = "$['dataModifica']";
      console.log("dateModifica: " + dateModifica);
      const dateValues = jpath(dateModifica, scheda);
      var date = new Date(dateValues);
      var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      console.log("data formattata: ");
      console.log(date);
      return [ day, mnth, date.getFullYear() ].join("/");
  }
  
}


function importoNazionaleV1(scheda) {

  const quotaId = "$['erogazione']['quotaNazionale']";
  const quotaValues = jpath(quotaId, scheda);
  let quotaNazionale = "";

  if(quotaValues.length == 0 | quotaValues == ""){
    quotaNazionale = "";
  } else {
    let numUpd = new Intl.NumberFormat('it-IT',{ minimumFractionDigits: 2}).format(quotaValues);
    quotaNazionale = '€ ' + numUpd
  }

  return quotaNazionale;
}



function computeCosto(costoUnitario, quantita) {
  if(costoUnitario && quantita) {
    const parsedCosto = parseFloat(costoUnitario);
    const tempTot = parsedCosto * quantita;
    if(isNaN(tempTot))
      return 'N/D';
    return tool.roundFloat2Decs(tempTot);
  }
  return 0;
}


function progressiveFirstIndexV1(key) {
  let result = key.match(/\b\d+\b/);
  if(result.length === 1) {
      let retVal = parseInt(result[0]);
      if(isNaN(retVal)) {
          console.error('firstIndexV1 index isNaN', retVal);
          return -1;
      }

      return retVal + 1;
  }
  else {
      console.error('firstIndexV1 index error', key);
      return -1;
  }
}
// restituisce l'array delle attività presenti, non eliminate
function enumIdAttivitaV1(mapElement, scheda) {
    let returnArray = [];
    if(!scheda) {
      return returnArray;
    }

    let attivitaPath = "['progetto']['cronoProgramma'][*]['idAttivita']";
    returnArray = Vue.prototype.$getUndeletedValues(attivitaPath, scheda);
    return returnArray.map( item => {
      return item.value;
    });
}

function progressiveSecondIndexV1(mapElement, scheda, key, actionFromModal) {
  let pathStr;
  if(key)
    pathStr = key;
  else if(mapElement && mapElement.config)
    pathStr = mapElement.config.path
  else {
    console.error('progressiveSecondIndexV1 bad params', mapElement, key);
      return 'N/D';
  }


  // se siamo in modalform1 calcolo dinamicamente il secondo indice
  if(actionFromModal) {
    const dep = "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['idAttivita']";
    pathStr = mapUtils.singleDepV1(key, actionFromModal, scheda, dep);
  } 
  let result = pathStr.match(mapUtils.indexRegex);
  if(result && result.length > 1) {
      let retVal = parseInt(result[1]);
      if(isNaN(retVal)) {
          console.error('progressiveSecondIndexV1 index isNaN', retVal);
          return -1;
      }
      return retVal + 1;
  }
  else {
      console.log('progressiveSecondIndexV1 not yet available', mapElement, key);
      return 'N/D';
  }
}

function leggiAutoreControllo(mapElement, scheda, key) {
  
  let defaultVal = 'N/D';

  if(!mapElement || ! scheda || !key ){
    console.error("leggiAutoreControllo: NULL params!");
    return defaultVal;
  }

  if (scheda.stato === 'In Compilazione' 
    || scheda.stato === 'Rinviato per Integrazione'){
      if (scheda.autocontrollo.autore)
        return scheda.autocontrollo.autore;
      // else
      //   console.error("scheda.autocontrollo.autore not FOUND!");
  } else if (scheda.stato === 'Inviato' 
    || scheda.stato.includes('Esitato')) {
    if(scheda.controllo.autore)
      return scheda.controllo.autore;
    // else
    //   console.error("scheda.controllo.autore not FOUND!");
  }

  return defaultVal;
}

// ---------------------------------- SEZIONE FUNZIONI GENERICHE ---------------------------------

// Questa è una funzione generica che calcola la somma di due campi in scheda
// ( a partire dagli ids relativi dei due campi )
function calcolo_totale(id_field1, id_field2, scheda, actionFromModal) {
  let totale = 0;
  const value_field1 = actionFromModal ? actionFromModal.content[id_field1].value : jpath('$'+ id_field1, scheda)[0];
  const value_field2 = actionFromModal ? actionFromModal.content[id_field2].value : jpath('$'+ id_field2, scheda)[0];

  if(value_field1) {
    const value_field1_parsed = parseFloat(value_field1);
    if(!isNaN(value_field1_parsed))
      totale += value_field1_parsed;
  }

  if(value_field2) {
    const value_field2_parsed = parseFloat(value_field2);
    if(!isNaN(value_field2_parsed))
      totale += value_field2_parsed;
  }
  // se il totale è diverso da zero lo arrotondo, altrimenti restituisco il valore iniziale (zero)
  if(totale && !isNaN(totale))
    return tool.roundFloat2Decs(totale);
  else
    return 0;
}

export default {
  calculateValue,
  calculateEnum,
  calculateIstruttoria,
  totaleInRowCosts,
  computeDate,
}