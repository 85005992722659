<template>
  <div>

    <div>
      <br>
      <h1 class="text-center">Lista Progetti Ammessi</h1>
    </div>
    <br>

    <div>
      <h2 class="text-center">Assegnazione Diretta</h2>
      <listaProgettiAmmessiTable
        :busyLPA="isBusyLPA"
        :lista-progetti-ammessi="listaProgettiAmmessiAD"/>
    </div>
    <br>
    <div>
      <h2 class="text-center">Call for proposal</h2>
      <div v-if="isIsfOrViewer">
        <!-- se ISF, vedo le graduatorie -->
        <h3 class="text-center">Graduatoria</h3>
        <listaBandi/>
      </div>
    </div>
    <div>
      <!-- progettiInCallForProposal e' true se uso il componente
      e' usato per listare progetti in call for proposal, per isfoper o boper -->
      <!-- se beneficiario -->
      <h3 class="text-center">Convenzione</h3>
      <listaProgettiAmmessiTable
        :busyLPA="isBusyLPA"
        :lista-progetti-ammessi="listaProgettiSovvenzioneIsf" :progetti-in-call-for-proposal="true"/>
    </div>

  </div>
</template>

<script>
import endpoints from "@/components/endpoints.vue";
import listaProgettiAmmessiTable from './listaProgettiAmmessiTable'
import listaBandi from './listaProgettiAmmessiCFP'
import notify from "@/helpers/notifications.js";

export default {
  name: "listaProgettiAmmessi",
  components: {
    listaProgettiAmmessiTable,
    listaBandi,
  },
  data() {
    return {
      listaProgettiAmmessiAD: [], // questo elenco vale anche per ISF
      listaProgettiAmmessiCFP: [], // questo elenco vale per i beneficiari
      listaProgettiSovvenzioneIsf: [], //questo elenco vale per ISF per progetti in sovvenzione che hanno passato la graduatoria
      roles: null,
      groups: null,
      userRole: null,
      userGroup: null,
      isBusyLPA: false
    }
  },
  computed: {
    isIsfOrViewer() {
      return this.userGroup &&( this.userGroup.match('MINT') || this.userGroup==='/others/viewer')
    },
  },
  mounted() {
    this.isBusyLPA = true
    // setto role utente
    this.roles = this.$getUserInfo().roles
    if (this.roles.length > 0)
      this.userRole = this.roles[0]
    // setto group utente
    this.groups = this.$getUserInfo().groups
    if (this.groups.length > 0)
      this.userGroup = this.groups[0]
    // chiamo la get project filtered
    const url = endpoints.retrieveAllSchedeProgetto;
    this.$get(url).then((result) => {
      console.log("Restituite " + result.length + " schede progetto da backend");
      this.parseSchedeProgettoResult(result, 'Assegnazione diretta', this.listaProgettiAmmessiAD, this.filterProgettiAmmissioneDiretta)
      this.parseSchedeProgettoResult(result, 'Call for proposal', this.listaProgettiAmmessiCFP, this.filterProgettiIsfCallForProposal)
      this.parseSchedeProgettoResult(result, 'Call for proposal', this.listaProgettiSovvenzioneIsf, this.filterProgettiIsfCallForProposal)
      this.isBusyLPA = false
    },
      (error) => {
        this.isBusyLPA = false
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        console.error("errore caricamento lista",error);
      });
  },
  methods: {
    parseSchedeProgettoResult: async function(result, modalitaAccesso, lista, filterFunction) {
      result.forEach(
        (element) => {
          //visualizzo i progetti con stato "Ammesso" e/o seguenti fino a "Graduatoria firmata" o "Decreto Ammissione Finanziamento firmato" (incluso)
            if( filterFunction(element, modalitaAccesso) )
              lista.push(element)
        })
      console.log("Trovate " + lista.length + " schede progetto con filtro: " + filterFunction.name);
    },
    // ******** funzioni filtro - inizio *********
    //
    // Per sovvenzione/convenzione lato ISF (dopo la graduatoria)
    filterProgettiIsfCallForProposal: function(element, modalitaAccesso) {
          if (element.infoProgetto &&
              element.infoProgetto.stato &&
              element.infoProgetto.modalitaDiAccesso == modalitaAccesso &&
              (element.infoProgetto.stato.includes('Sovvenzione')
                || element.infoProgetto.stato === 'Ammesso a Sovvenzione'
                || element.infoProgetto.stato.includes('Convenzione')
                || element.infoProgetto.stato.includes('Attuazione')
                || element.infoProgetto.stato.includes('Concluso')
                )){
                  // se matcha il filtro, aggiungo l'elemento alla lista
                  return element
            }
            else
              return null
    },
    filterProgettiBeneficiariCallForProposal: function(element, modalitaAccesso) {
          if (element.infoProgetto &&
              element.infoProgetto.stato &&
              element.infoProgetto.modalitaDiAccesso == modalitaAccesso &&
              (element.infoProgetto.stato.includes('Sovvenzione')
                || element.infoProgetto.stato === 'Ammesso a Sovvenzione'
                || element.infoProgetto.stato.includes('firma')
                || element.infoProgetto.stato.includes('Convenzione')
                || element.infoProgetto.stato.includes('Decreto')
                || element.infoProgetto.stato.includes('Graduatoria')
                )){
                  // se matcha il filtro, aggiungo l'elemento alla lista
                  return element
            }
            else
              return null
    },
    // Per ammissione diretta
    filterProgettiAmmissioneDiretta: function(element, modalitaAccesso) {
            if (element.infoProgetto &&
              element.infoProgetto.stato &&
              element.infoProgetto.modalitaDiAccesso == modalitaAccesso &&
              (element.infoProgetto.stato.includes('Sovvenzione')
                || element.infoProgetto.stato === 'Ammesso'
                || element.infoProgetto.stato.includes('firma')
                || element.infoProgetto.stato.includes('Convenzione')
                || element.infoProgetto.stato.includes('Decreto')
                || element.infoProgetto.stato.includes('Graduatoria')
                || element.infoProgetto.stato.includes('Attuazione')
                || element.infoProgetto.stato.includes('Concluso')
                )){
                  // se matcha il filtro, aggiungo l'elemento alla lista
                  return element 
            }
            else
              return null
    }
    // ******** funzioni filtro - fine *********
  },
}
</script>