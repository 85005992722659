<template>  
  <div id="listaContiAnnuali">  
      <b-form inline class="m-1" style="display: flex;">
        <label for="filterSpese">
          <h4 class="mx-2">Filtra i risultati:</h4>
        </label>
        <b-form-input
          v-model="filter"
          type="search"
          id="filterSpese"
          placeholder="Digita un filtro"
        />
        <b-button
          id="filterSpeseCanc"
          class="mx-1"
          :disabled="!filter"
          @click="filter = ''"
          >Cancella</b-button
        >
      </b-form>
      
      <b-table
        :busy="isBusy"
        primary-key="id"
        emptyText="Nessun dato disponibile"
        :sort-by="sortBy"
        sort-desc
        :filter="filter"
        emptyFilteredText="Nessun dato disponibile"
        :filter-included-fields="getFilterFields()"
        :items="tableData.rows"
        :fields="tableData.header"
        show-empty
        hover
        responsive>
        <template v-slot:table-busy>
          <div class="text-center">
            <strong>Lista in Caricamento...</strong>
          </div>
        </template>
        <template v-slot:cell(Azioni)="data">
          <b-button
            :id="namingIdIcon(x, data.index + 1)"
            variant="outline-primary" 
            :key="x"
            :title="mappingTooltip(x)"
            size="sm" 
            v-for="x in data.value"
            @click="openModal(data.item)"
            >
              <fa-icon
              v-if="x === 'view'"
              :icon="['far', 'eye']"
              class="selector-icons"
              />
          </b-button>
        </template>
      </b-table>

      <modal :hidden="!modalOpen" :buttons="this.buttons" @close="modalOpen = false" style="overflow-y: hidden;" >
        <div class="row-title" slot="header">{{modalHeader}}</div>
        <div slot="body">
           <b-table :items="modalData.rows" 
                  :fields="modalData.header" 
                  emptyText="Nessun dato disponibile"
                  :sort-by="sortBy"
                  sort-desc>
            </b-table>
            <p class="row-title">Lista disposizioni di pagamento</p>
            <div style="height: 400px; overflow-y: auto;">
              <b-table
              :fields="fieldsTableProjects"
              :items="disposizioni"
              :id="'lista_conti_annuali'"
              thead-class="head"
              >
              <template #cell(codiceProgetto)="row">
                <div>
                  {{ row.item.content.codiceProgetto }}
                </div>
              </template>
              <template #cell(numero)="row">
                <div>
                  {{ row.item.content.disposizione.numero }}
                </div>
              </template>
              <template #cell(data)="row">
                <div>
                  {{ dateFormatter(row.item.content.disposizione.data) }}
                </div>
              </template>
              <template #cell(importo)="row">
                <div>
                  {{ currencyFormatter(row.item.content.disposizione.importo) }}
                </div>
              </template>
            </b-table>
            </div>
        </div>
      </modal>
  </div>
</template>

<script>
import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue"
import utils from "@/helpers/utils.js";
import modal from "@/components/modal.vue";

export default {
  name: "listaSpeseCommissioneEuropea",
  components: {
    modal
  },
  data() {

    return {
      actions: ["view"],
      modalHeader: "",
      modalOpen: false,
      tabellone:{},
      dataForList: {},
      isBusy: false,
      sortBy: "anno",
      filter: null,
      resultList: [],
      buttons: [],
      disposizioni: [],
      tableData: {
        header: [
            {
                key: "anno",
                label: "Anno",
                sortable: true,
            },
            {
                key: "dataInvio",
                sortable: true,
                filterByFormatted: true,
                formatter: "dateFormatter",
                label: "Data Invio Conti"
            },
            {
                key: "data_CE",
                label: "Data Accettazione CE",
                filterByFormatted: true,
                formatter: "dateFormatter",
                sortable: true,
            },
            {
                key: "totaleContributo",
                label: "Totale del Contributo",
                sortable: true,
            },
            {
                key: "Azioni",
                thClass: "thst",
            },
            ],
         rows: [],
        },
      modalData: {
        header: [
            {
                key: "campo",
                label: "Campo",
                sortable: false,
            },
            {
                key: "valore",
                sortable: false,
                label: "Valore"
            }
          ],
        rows: [],
      },
      fieldsTableProjects:  [
        { key: 'codiceProgetto', label: "Codice Progetto" },
        { key: 'numero', label: "Disposizione Numero" },
        { key: 'data', label: "Disposizione Data" },
        { key: 'importo', label: "Disposizione Importo" },
      ],
    };
  },
  async mounted() {
      await this.extractData();
  },
  methods: {
    getFilterFields() {
      let filterFields = [];
      for (let item of this.tableData.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },
    currencyFormatter(value) {
      if (value == undefined || value == null)
        return null;
      return utils.formatCurrency(value);
    },
    mappingTooltip(keyMapping) {
      let objMapping = {
        view: "Visualizza",
      };
      let temp = objMapping[keyMapping];
      if (!temp) return "";
      return temp;
    },
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    }, 
    async openModal(rowData) {
      const key = rowData.anno;

      this.modalHeader = "Dettaglio Conto Annuale " + key;

      this.modalData.rows = [
        {campo: "CCI", valore: this.resultList[key].CCI},
        {campo: "Versione", valore: this.resultList[key].versione},
        {campo: "Decisione", valore: this.resultList[key].decisione},
        {campo: "Importo ISF Police", valore: this.resultList[key].importo_ISF_police},
        {campo: "Importo ISF Borders & Visa", valore: this.resultList[key].importo_ISF_borders_visa}
      ]

      this.getProgettiPerAnno(key);
      this.modalOpen = true;
    },
    extractData: async function()  {
      this.isBusy = true;
      this.tableData.rows = [];

      const url = endpoints.getContiAnnuali;
      this.$get(url)
          .then((result) => {
            this.resultList = result;
            let index = 0;

            const objTable = {};
            for (const year in this.resultList) {
              const dataInvio = this.resultList[year].data_invio_conti;
              const dataCE = this.resultList[year].data_accettazione_CE;
              const importo_ISF = this.resultList[year].importo;
              objTable[index] = {year, importo_ISF, dataInvio, dataCE};
              index++;
            }
            
            for(const key in objTable) {
              const newRow = {
                anno: objTable[key].year,
                dataInvio: objTable[key].dataInvio,
                data_CE: objTable[key].dataCE,
                totaleContributo: objTable[key].importo_ISF,
                Azioni: ['view']
              }
              
              this.tableData.rows.push(newRow);
            }
            this.isBusy = false;
          })
          .catch((error) => {
            this.isBusy = false;
            console.error("error lista spese:", error.message);
            notify.error(notify.strings.error, notify.strings.listaBandiKO);
          });
    },
    getProgettiPerAnno(annoSelezionato) {
      let anno = annoSelezionato;

      const url = endpoints.progettiByAnno(anno);
      this.$get(url)
      .then((result) => {
        this.disposizioni = result;
      },
      (error) => {
        //this.isBusy = false
        console.error("errore caricamento disposizioni pagamento", error);
        throw error
      })
    }
    

   
  },
}
</script>