<template>
    <div id="attivita2" v-show="tabName === tabId">
        <InfoCompiling
        :tabName="infoTabName"
        :title="'Informazioni di compilazione'"
        >
        </InfoCompiling>
        <VerticalComponent2
            v-if="loadComplete"
            :idTablePdf="idTablePdf"
            :idScheda="idScheda"
            :storeModule="storeModule"
            :tabelloneType="tabelloneType"
            :componentConfig="componentConfig"
            @refreshValues="refreshData()"/>
    </div>
</template>
<script>
    import VerticalComponent2 from "@/components/verticalComponent2.vue"
    import utils from '@/helpers/utils.js'
    import InfoCompiling from "@/components/infoCompiling.vue"
    export default {
        name: "attivita2",
        components: {
            VerticalComponent2,
            InfoCompiling
        },
        props: {
            idTablePdf: { type: String, default: () => '' },
            infoTabName: { type: String, default: () => '' },
            incomingData: Object,
            idScheda: String,
            tabName: String,
            rootId: String,
            tabelloneType: String,
            dinamicIds: Array,  default: function() { return [] }
        },
        data() {
            return {
                loadComplete: false,
                tabId: "statoavanzamento",
                //tabelloneType: 'cronoprog_monitoraggio',
                storeModule: 'cronoprog',
                componentConfig: {
                    title: "Attività realizzate nel trimestre di riferimento",
                    name: "attivitaRealizzate",
                    notifyUpdate: false,
                    defaultActions: ['edit'],
                    allowedActions: []
                }
            }
        },
        mounted() {
            let actions = utils.viewOnlyMode(this.incomingData, this.componentConfig.defaultActions);
            this.componentConfig.allowedActions = actions;
            this.componentConfig.dinamicIds = this.dinamicIds
            this.componentConfig.rootId = this.rootId
            this.loadComplete = true;
        },
    }
</script>