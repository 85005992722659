import { render, staticRenderFns } from "./listaContiAnnuali.vue?vue&type=template&id=c10ee30a&"
import script from "./listaContiAnnuali.vue?vue&type=script&lang=js&"
export * from "./listaContiAnnuali.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports