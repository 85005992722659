<template>
  <div>
    <table class="table" :id="idTablePdf">
      <caption></caption>
      <thead>
          <tr>
              <th scope="col" v-for="(column, index) in tdata.header" :key="index">{{column}}</th>
              <th scope="col" v-if="tdata.colors===true">Stato*</th>
              <th scope="col" v-if="tdata.rows" :hidden="hideAction(tdata, actions)">Azioni</th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="(item, index) in tdata.rows"
            :hidden="checkAddDelete(item)" 
            :key="index" 
            v-bind:class="{addedCell: checkAdded(item), deletedCell: checkDeleted(item), changeInRow: checkChange(item) }">
              <td v-for="(column, indexColumn) in item.content" :key="indexColumn" :hidden="isHidden(tdata.header, item , indexColumn)" :title="isTooltip(item, indexColumn)"
                v-bind:class="{substantialCell: checkSubstancial(item, indexColumn), modifiedCell: checkModified(item, indexColumn) }">{{myFormatOutput(item, indexColumn)}}
              </td>
              <td v-if="tdata.colors===true">
                  <!-- <img width="32 px" :title="item.content.Stato" :src="require('../assets/'+coloraStato(item.content.Stato)+'.jpeg')"> -->
                  <fa-icon :icon="['fas', 'circle']"  v-bind:class="classFromStato(item)" :title="tooltipFromStato(item)"/>
              </td>
              <td :hidden="isEmptyArray(actions)">
                  <b-button variant="outline-primary" :title="x" v-for="x in actions" v-on:click="doAction(x, index, item)" :key="x">
                    <fa-icon v-if="x==='detail'" :icon="['fas', 'folder']" class="selector-icons"/>
                    <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
                    <fa-icon v-if="x==='view'" :icon="['far', 'eye']" class="selector-icons"/>
                    <fa-icon v-if="x==='delete'" :icon="['far', 'trash-alt']" class="selector-icons"/>
                  </b-button>
              </td>
          </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>

import utils from '@/helpers/utils.js'

export default {
  name: "DynamicTable2",
  props: {
    idTablePdf: String,
    tdata: {
      type: Object,
      required: true
    },
    actions: {
      type:Array,
      default: null
    },
    doAction: Function,
  },

  computed: {
    checkChange(){
      return (item) => {
        if (item.content.Campo || item.content.Valore) //escludo il colore di cancellazione per il modal di dettaglio
          return false;
        
        if(!item.conf)
          return false;

        for(let value of Object.values(item.conf)) {
          // se il campo non è stato modificato nel monitoraggio corrente, allora le modifiche vengono ignorate
          if(!value.data.edited)
            continue;
          if(!(value.data.deleted && value.data.added)) {
            return true;
          }
        }
        return false;
      }
    },
    checkAddDelete(){
      return (item) => {
        if(item && item.conf){
          for(let value of Object.values(item.conf)) {
            if( (value.data.added && value.data.deleted) || (value.data.deleted && !value.data.edited) ) {
              return true;
            }
          }
        }

        return false;
      }
    },
    checkDeleted(){
      return (item) => {
        
        if(item && item.content && item.conf) { // && item.content.Campo && item.content.Valore){

          // console.error("ITEM",item);
          if (item.content.Campo || item.content.Valore) //escludo il colore di cancellazione per il modal di dettaglio
            return false;
          
          for(let value of Object.values(item.conf)) {
            if(!value.data.edited)
              continue;
            if(value.data.deleted)
              return true;
          }
        }
        
        return false;
      }
    },

    checkAdded(){
      return (item) => {
        if(item && item.content && item.conf) {// && item.content.Campo && item.content.Valore){
          if (item.content.Campo || item.content.Valore) //escludo il colore di aggiunta per il modal di dettaglio
            return false;

          for(let conf of Object.values(item.conf)) {
            if(conf.data.added)
              return true;
          }
          /*
          //suppongo che l'ultimo elemento visualizzato si trovi al livello più profondo di array
          //TODO: capire come trovare l'elemento giusto in una riga con campi a diversi livelli
          let len = Object.values(item.conf).length;
          //se esiste questo oggetto, ritorno il suo valore
          if (Object.values(item.conf)[len-1].data 
            && Object.values(item.conf)[len-1].data.added!==undefined 
            && Object.values(item.conf)[len-1].data.added!==null
            && !Object.values(item.conf)[len-1].data.deleted){ //la cancellazione vince sull'aggiunta
              //console.log("trovato elemento aggiunto alla riga "+index, item);
              return Object.values(item.conf)[len-1].data.added;
          }
          */
        }
        return false;
      }
    },

    isHidden(){
      return (header, item, indexColumn) => {

        if (item && item.conf) { //controllo che esista la configurazione per evitare errori bloccanti in caso di mappa errata
          let it = item.conf[indexColumn]
          //se la label del campo in questione non è compresa fra quelle dell'header (campi da visualizzare) ritorno true e nascondo la colonna
          if(it && it.config && it.config.label && !header.includes(it.config.label))
            return true;
        }

        return false;
      }
    },

    isTooltip () {
      return (item, column) => {
        if (column === "Valore" && Object.values(item.conf)[0].data.note){   //seconda colonna di visualizzazzione del dettaglio
          return 'nota: ' + Object.values(item.conf)[0].data.note;
        } else if(item.conf) {

          // workaround nel caso in cui la configurazione sia costruita sulle label e non sugli id
          for(let conf of Object.values(item.conf)) {
            if(conf.config.label === column && conf.data.note){
              return 'nota: ' + conf.data.note;
            }
          }


          if (item && item.conf && item.conf[column] && item.conf[column].data && item.conf[column].data.note) {
            // console.log(column, item.conf[column].data.note);
            return 'nota: ' + item.conf[column].data.note;
          }
            
        }

        return "";
      }
    },
    
    myFormatOutput(){
      return (item, column) => {
        if(item && item.conf && item.conf[column] && item.content && item.content[column]){

          return utils.formatOutput(item.conf[column], item.content[column]);

        } else if (column==="Valore") {//|| column==="Spesa") { //IF necessari per far funzionare la modal in view
          //applico la formattazione definita nell'unico elemento di content
          if (item.conf && item.content[column]!==undefined && item.content[column]!==null){
            return utils.formatOutput(Object.values(item.conf)[0], item.content[column]);
          } else {
            if(!item.conf)
              console.error("Configurazione non trovata!?", item);
            else if(column !== "Valore")
              console.error("Dato non trovato!?", item, column);
            return "";
          }  
        }
        /*        
        else if (column==="Dal") {
          //applico la formattazione definita nell'unico elemento di content
          return utils.formatOutput(Object.values(item.conf)[1], item.content[column]);
        } else if (column==="Al") {
          //applico la formattazione definita nell'unico elemento di content
          return utils.formatOutput(Object.values(item.conf)[2], item.content[column]);
        }
        */
        else if(item.conf) {
          //console.log("nessuna configurazione/formattazione applicata");
          // workaround nel caso in cui la configurazione sia costruita sulle label e non sugli id
          for(let conf of Object.values(item.conf)) {
            if(conf.config.label === column){
              return utils.formatOutput(conf, item.content[column]);
            }
          }

          return item.content[column];
        }

        return null;
      }
    },

    checkSubstancial(){
      return (item, column) => {
        if (column === "Valore"){   //seconda colonna di visualizzazzione del dettaglio
          return Object.values(item.conf)[0].data.isSubstancial;
        } else if(item.conf) {

          // workaround nel caso in cui la configurazione sia costruita sulle label e non sugli id
          for(let conf of Object.values(item.conf)) {
            if(conf.config.label === column){
              return conf.data.isSubstancial;
            }
          }

          if (item && item.conf && item.conf[column] && item.conf[column].data){
            return item.conf[column].data.isSubstancial;
          }
        }

        return false;
      }
    },

    checkModified(){
      return (item, column) => {
        if (column === "Valore"){   //seconda colonna di visualizzazzione del dettaglio
          return Object.values(item.conf)[0].data.edited;
        } else {
          
          if(item && item.conf && item.conf[column] && item.conf[column].data){
            const conf = item.conf[column].data;
            if(!(conf.added || conf.deleted))
              return conf.edited;
            else
              return false;
          }

          if(!item.conf)
            return false;

          // workaround nel caso in cui la configurazione sia costruita sulle label e non sugli id
          for(let conf of Object.values(item.conf)) {
            if(conf.config.label === column){
              if(!(conf.data.added || conf.data.deleted))
                return conf.data.edited;
              else
                return false;
            }
          }

        }

        return false;
      }
    },

    hideAction(){
      return (tdata, actions) => {
        return (!tdata || !actions);
      }
    },

    classFromStato(){

      return (item) => {
        
        //ricavo la chiave dall'indice relativo di visualizzazione
        let chiavi = Object.keys(item.content);
        let chiave = chiavi[6]; //accedo alla 7ima colonna
        //console.log("chiave",chiave);
        let stato = item.content[chiave];
        //console.log("stato", stato);
        
        //ritornare un colore in base allo stato
        if (stato==="Realizzato"){                  //completato nel passato
          return "crono-green-icon";
        } else if (stato==="Realizzato in parte") { //a cavallo di diversi trimestri oltre quello corrente
          return "crono-orange-icon";
        } else if (stato==="In realizzazione") {    //da completare nel trimestre corrente
          return "crono-yellow-icon";
        } else if (stato==="Da realizzare") {       //da completare nel futuro
          return "crono-grey-icon";
        } else { //eventuale errore
          console.error("stato="+stato+" non gestito con nessun colore!?");
          return "crono-white-icon";
        }
      
      }
    },

    tooltipFromStato(){

      return (item) => {
        
        //ricavo la chiave dall'indice relativo di visualizzazione
        let chiavi = Object.keys(item.content);
        let chiave = chiavi[6]; //accedo alla 7ima colonna
        //console.log("chiave",chiave);
        let stato = item.content[chiave];
        //console.log("stato", stato);
        
        return stato;
      }
    }


  },

  data() {
    return {
      //place local var here
    };
  },

  methods: {
    isEmptyArray(arr){
      return !arr;
    },
  }
}
</script>
