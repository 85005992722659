import { JSONPath as jpath } from 'jsonpath-plus';
import mapUtils from '@/helpers/mapUtils.js'
import store from '@/store/store.js'
import { setHours, addHours } from 'date-fns'
import tool from '@/helpers/tools.js'
import Vue from 'vue';

    const viewOnlyMode = function(scheda, actions) { 
        if (!scheda || !actions || !Array.isArray(actions))
            return [];

        return actions.filter(
            action => {
                if(scheda.schedaMonitoraggio) {
                    return caniuse(action, scheda.taskList, scheda.schedaMonitoraggio, scheda.history);
                } else {
                    return caniuse(action, scheda.taskList, scheda.schedaAssessment);
                }
            }
        )
    }


    const isForBeneficiario = function(statoScheda) {
        if(!statoScheda)
            return false;
        return  statoScheda.includes('inserire') || 
                statoScheda.includes('rinviata per integrazione') ||
                statoScheda.includes('in verifica beneficiario');
    }

    const caniuse = function(action, task, scheda, history) {
        if(!scheda || !action)
            return false; // null param, error

        let statoScheda = "sconosciuto";
        if (scheda.stato){
            statoScheda = scheda.stato.toLowerCase();
        }
        let statoTask = null;
        let assignee = null;
        if(task) {
            statoTask = task.candidateRole;
            assignee = task.assignee;
        }

        const userInfo = Vue.prototype.$getUserInfo();
        let group = userInfo.groups[0];
        let roles = userInfo.roles;
        let userid = userInfo.preferred_username;
        // retrocompatbilità con vecchio processo monitoraggio
        const isForBeneficiario1 =  isForBeneficiario(statoScheda);
        // const isRifiutata = statoScheda.includes('rinviata per integrazione') || statoScheda.includes('in verifica beneficiario');
        switch(action) {
            case 'detail':
                return true; // all users can see details
            case 'view':
                // se la scheda è per il beneficiario e se l'utente è un beneficiario approvatore, 
                // ma la scheda non è in carico a lui, allora non ha la view
                if(isForBeneficiario1 && ( roles[0].includes('Beneficiario-Approvatore') && !statoTask ) ) {
                    return false;
                }
                // se la scheda è in carico al beneficiario e il gruppo non è beneficiario allora:
                // 1) se non c'è la history (retrocompatibilità con i vecchi monitoraggi) allora niente view
                // 2) se c'è la history e la scheda è stata inviata a isf, allora c'è la view
                if(isForBeneficiario1 && ( group.includes('MINT') || group ==='/others/viewer')) {
                    if(!history) // retrocompatibilità
                        return false;
                    else {
                        const filtered = history.filter(item => item.stato.startsWith('Inviata'));
                        return(filtered && filtered.length > 0);
                    }
                }

                return true; // all users can view
            case 'save':
            case 'send':
            case 'edit':
            case 'add':
            case 'delete':  // same rules for edit, add and delete field actions
                if(scheda.progetto && scheda.progetto.concluso) return false
                if(!isForBeneficiario1)
                    return false; // only 'da inserire' can be edited

                if(!group.includes('eneficiari'))
                    return false; // only beneficiari can edit
                for(let i in roles) {
                    if(roles[i].includes('peratore')) {
                        if(statoTask && statoTask.includes(roles[i]))
                            return true; // scheda da inserire, beneficiario operatore and task for my role
                        if(!statoTask)
                            return true; // scheda da inserire, beneficiario operatore and no task yet
                    }
                }

                return false; // scheda da inserire and task for different beneficiario
            case 'claim':
                if(assignee)
                    return false; // assigned tasks cannot be claimed
                if(!statoTask || (statoTask.includes('peratore') && group.includes('eneficiari')))
                    return false;
                for(let i in roles) {
                    if(roles[i].includes(statoTask)) {
                        return true; // scheda unclaimed and task for my role (no beneficiari)
                    }
                }
                return false; // scheda unclaimed, but not a task for my role
            case 'complete':
            case 'approve':
            case 'reject':
            case 'unclaim':
                if(!assignee || !statoTask)
                    return false; //unassigned tasks cannot be unclaimed
                if(assignee !== userid)
                    return false; // cannot unclaim task not assigned to me
                for(let i in roles) {
                    if(roles[i].includes(statoTask)) {
                        return true; // scheda claimed by me and task for my role (no beneficiari)
                    }
                }
                return false; // scheda claimed by me, but not a task for my role
            case 'internalNote':
                return group.includes("MINT");
            default:
                return false; // unknown action
        }
    }


    class Config {
        constructor() {
            this.label = "";
            this.tab = "";
            this.defaultValue = "";
            this.type = "string";
            this.readonly = false;
            this.canBeSubstancial = false;
            this.mandatory = false;
            this.mandatoryNote = false;
            this.validationRule = "";
            this.path = "";
            this.substancialRule = "";
        }
    }

    class Data {
        constructor() {
            this.note = "";
            this.oldValue = "";
            this.edited = false;
            this.isSubstancial = false;
        }
    }

    class Field {
        constructor() {
            this.config = new Config();
            this.data = new Data();
        }
    }

    function collectionIdsByObject(data1) {
        let validId = /^[a-z_$][a-z0-9_$]*$/i;
        let result = [];
        doIt(data1, "");
        return result;
      
        function doIt(data, s) {
          if (data && typeof data === "object") {
            if (Array.isArray(data)) {
              for (let i = 0; i < data.length; i++) {
                doIt(data[i], s + "[*]");
              }
            } else {
              for (let p in data) {
                if (validId.test(p)) {
                  doIt(data[p], s + "['" + p + "']");
                } else {
                  doIt(data[p], s + "[\"" + p + "\"]");
                }
              }
            }
          } else {
            result.push(s);
          }
        }
      }

      function buildJson(data) {
        // Dato un json restituisce un array di ids per ogni prop dell'oggetto
        let arrayIds = collectionIdsByObject(data)
        let fieldsConfig =  {
            "label": "",
            "tab": "",
            "type": "",
            "maxLength": 2,
            "readonly": true,
            "canBeSubstancial": true,
            "mandatory": true,
            "mandatoryNote": true,
            "validationRule": "",
            "path": "",
            "substancialRule": ""
        }
        let completeData = []
        let innerObjConfig = {'config': {}};
        // Inserisco un oggetto di config per ogni id
        arrayIds.forEach((id) => {
            for (let prop in fieldsConfig) {
                if (fieldsConfig[prop]) {
                    innerObjConfig['config'][prop] = fieldsConfig[prop];
                    completeData[id] = innerObjConfig
                }
            }
        })
        // Creo il Json di configurazione (stringato), a partire da un obj vuoto
        return JSON.stringify(Object.assign({}, completeData));
      }

    function formatCurrency(amount)
    {
        //for every digit that is followed by 3 digits and a word boundary
        //add currency and a '
        // in case of error returns unformatted input
        if(amount == undefined || amount == null || amount === '') {
            return amount;
        }
            
        try {
            let temp;
            // se il valore non è un numero, provo a sostituire virgole con punti
            if(isNaN(amount))
                temp = amount.replace(',', '.');
            else
                temp = amount;
            // nel caso in cui ancora non sia un numero restituisco il valore così com'è
            if(isNaN(temp))
                return amount;
            // altrimenti passo da stringa a float
            temp = parseFloat(temp);
            // e formatto il valore
            // console.log(amount, temp);
            amount = "€ " + temp.toFixed(2).replace(/\./, ',').replace(/(\d)(?=(\d{3})+\b)/g, "$1.");
        }
        catch(err) {
            console.log('error in format currency', amount, typeof amount, err);
        }
		return amount;
    }

    function formatPercent(amount)
    {
        //for every digit that is followed by 3 digits and a word boundary
        //add currency and a '
        // in case of error returns unformatted input
        if(amount == undefined || amount == null || amount === '') {
            return amount;
        }
            
        try {
            let temp;
            // se il valore non è un numero, provo a sostituire virgole con punti
            if(isNaN(amount))
                temp = amount.replace(',', '.');
            else
                temp = amount;
            // nel caso in cui ancora non sia un numero restituisco il valore così com'è
            if(isNaN(temp))
                return amount;
            // altrimenti passo da stringa a float
            temp = parseFloat(temp);
            // e formatto il valore
            // console.log(amount, temp);
            amount = temp.toFixed(2).replace(/\./, ',').replace(/(\d)(?=(\d{3})+\b)/g, "$1.") + " %" 
        }
        catch(err) {
            console.log('error in format currency', amount, typeof amount, err);
        }
		return amount;
    }

    function formatEnumPayments(pays){
        //console.log("PAYS=", pays);
        //se per qualche motivo è già formattato in stringa non faccio nulla
        if (Array.isArray(pays)){    //manipolo l'array
            const pagamenti = pays;
            let stringaPag = null;
            for (let pay of pagamenti){
                //mi fermo sempre a due caratteri dopo l'ultima occorrenza di "-"
                const index = pay.text.lastIndexOf("-");
                if(index > -1) {
                    const str = pay.text.substring(0, index + 3); 
                
                    if (!stringaPag){ //al primo giro inizializzo
                        stringaPag = str;
                    } else { //poi concateno
                        stringaPag = stringaPag+", "+str;
                    }
                } else return pay.text
            }
    
            return stringaPag;
        } else {
            console.error("formatEnumPayments: Non è stato passato un array!?!");
            return "Non disponibili";
        }
    }
    // WARNING: dopo questa chiamata, controllare sempre con isNaN il valore di ritorno
    /*
    function fromCurrencyToValue(amount) {
        // se il valore è assente lo ritorno subito
        if(amount == undefined || amount == null || amount === '')
            return amount;
        let temp = amount.toString();
        temp = temp.replace(',','.').replace('€','').replace('%','')
        temp = Number(temp);
        if(isNaN(temp)) {
            // se il valore non è un numero lo ritorno, in modo che il successivo
            // controllo isNaN restituisca false
            return temp;
        }
        return parseFloat(temp);
    }
    */

    function formatString(stringa) {
      if(stringa)
        return stringa.toString().trimStart();
      return stringa;
    }

    function formatText(testo) {
        return testo;
    }

    function formatBoolean(value) {
        if(value === true || value === 'true') {
            return 'SI';
        }
        else if(value === false || value === 'false') {
            return 'NO';
        }
    }


    function formatStringArray(valuesArray) {
        if(!valuesArray)
            return "";
        if(!Array.isArray(valuesArray))
            return valuesArray;
        if(valuesArray.length === 1)
            return valuesArray[0];

        const newArray = valuesArray.filter((x, i, a) => a.indexOf(x) === i);
        return newArray.toString().replace(',', ', ');
    }

    function formatEnum(mapElement, value) {
        if(!mapElement.config.enumValues)
            return value;

        for(const item of mapElement.config.enumValues) {
            if(item.label && item.value === value)
                return item.label;
        }

        return value;
    }

    function unformatDate(value) {
        let retVal = value;
        if(tool.isDate(value)) {
            retVal = setHours(value, 12);
        }
        return retVal;
    }


    function unformatOutput(mapElement, value) {
        if(value == undefined || value == null || !mapElement || !mapElement.config || !mapElement.config.type)  {
            return value;
        }
        switch(mapElement.config.type) {
            case 'float':
            case 'percent':
            case 'currency':
                // return fromCurrencyToValue(value);
                return value;
            case 'date':
            case 'year':
                return unformatDate(value);
            default:
                return value;
        }
    }

    function formatOutput(mapElement, value) {
        if(!mapElement.config || !mapElement.config.type || value == undefined || value == null)  {
            return value;
        }
            
        switch(mapElement.config.type) {
            case 'currency':
                return formatCurrency(value);
            case 'date':
                return formatDate(value);
            case 'year':
                return formatYear(value);
            case 'datetime':
                return formatDateTime(value);
            case 'string':
                return formatString(value);
            case 'text':
                return formatText(value);
            case 'boolean':
                return formatBoolean(value);
            case 'percent':
                return formatPercent(value);
            case 'stringarray':
                return formatStringArray(value);
            case 'enumPayments':
                return formatEnumPayments(value);
            case 'enum':
                return formatEnum(mapElement, value);
            default:
                return value; // return unformatted input
        }
    }

    function recursiveMap(key, object, parentPath, elements, scheda) {
        if(!elements)
            elements = {};

        let element;
        if(elements[key]) {
            console.error('already found', elements[key]);
            element = elements[key];
        }
        else
            element = new Field();

        if(object == null) {
            if(key) {
                // still ok, put default value
                element.config.type = 'string';
            }
            else
                return elements;
        } else if(Array.isArray(object) && object.length > 0) {
            let newPath;
            if(parentPath)
                newPath = jpath({resultType: 'path'}, parentPath+'.'+key, scheda)[0] + '[0]';
            else
                newPath = jpath({resultType: 'path'}, '$..'+key, scheda)[0] + '[0]';
            console.log('è un array', key, parentPath, newPath);
            let newObj = object[0]; // vado col primo elemento dell'array, passando il path corrispondente
            return recursiveMap(key, newObj, newPath, elements, scheda);
        } else if(typeof object === 'object') {
            let newPath;
            if(parentPath) {
                let prova = jpath({resultType: 'path'}, parentPath+'.'+key, scheda); 
                if(prova.length > 0) {
                    newPath = prova;// se sono in un oggetto effettivo, setto il path
                } else {
                    newPath = parentPath; // altrimenti vuol dire che sono nel primo elemento dell'array, da analizzare
                }
            } else {
                newPath = jpath({resultType: 'path'}, '$..'+key, scheda)[0];
            }
            console.log('è un oggetto ' + key, parentPath, newPath);

            for(let [key1, value] of Object.entries(object)) {
                elements = recursiveMap(key1, value, newPath, elements, scheda);
            }
            return elements;
        }

        let paths;
        if(parentPath)
            paths = jpath({resultType: 'path'}, parentPath+'.'+key, scheda);
        else
            paths = jpath({resultType: 'path'}, '$..'+key, scheda);
        let path;
        if(paths.length > 0) {
            path = paths[0];
        }
        else {
            console.error('no path, cannot add', key, object);
        }

        if(!element.config.path)
            element.config.path = path;

        if(!element.config.type)
            element.config.type = typeof object;
        if(!element.config.label)
            element.config.label = key;
        elements[path] = element;
        return elements;
    }

    const convertiRiga2 = function(tabledata, index) {
        let rows = [];
        
        if (tabledata && tabledata.rows
            && tabledata.rows[index].content
            && tabledata.rows[index].conf){
                
          for(let keyv in tabledata.rows[index].content) {
            
            let label;
            if(tabledata.rows[index].conf[keyv] && tabledata.rows[index].conf[keyv].config)
                label = tabledata.rows[index].conf[keyv].config.label;
              
            let row = { 
                content: {
                    Campo : label? label : "(label undefined)",
                    Valore : tabledata.rows[index].content[keyv]
                },
                conf: {}
            };
            
            row.conf[keyv]=tabledata.rows[index].conf[keyv];
            rows.push(row);
          }
        } else {
            console.error("convertiRiga2: INVALID param passed=", tabledata);
        }
  
        return {
          header : ['Campo', 'Valore'],
          rows: rows
        }
    }


// possible types: string (input text), number (input text), enum or option(select with label and value),
// boolean (checkbox), date (datepicker), text (textarea)

const getSessionToken = function () {
    if (sessionStorage.getItem("access_token") != undefined 
        && sessionStorage.getItem("access_token") != null
    ){
        return sessionStorage.getItem("access_token")
    }
    return "Invalid_Token_In_Session_Storage"
}

function formatDate(value)
{
    let formattedDate = value;
    try {
        if (!value) return formattedDate;
        let d = new Date(value);
        if(d.getUTCHours() >= 21 || d.getUTCHours() <= 1) {
            d = addHours(d, 12);
        }

        formattedDate = d.toLocaleString("it-IT", { // you can skip the first argument
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        
    }
    catch(err) {
        console.error("Errore in formatDate",err);
    }
    return formattedDate;
}

function formatYear(value)
{
    let formattedDate = value;
    try {
        if (!value) return formattedDate;
        let d = new Date(value);
        if(d.getUTCHours() >= 21 || d.getUTCHours() <= 1) {
            d = addHours(d, 12);
        }
        formattedDate = d.toLocaleString("it-IT", { // you can skip the first argument
            year: "numeric",
            // month: null,
            // day: null,
        });
    }
    catch(err) {
        console.error("Errore in formatDate",err);
    }
    return formattedDate;
}

function formatDateTime(value)
{
    let formattedDate = value;
    try {
        if (!value) return formattedDate;
        let d = new Date(value);
        formattedDate = d.toLocaleString("it-IT", { // you can skip the first argument
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    }
    catch(err) {
        console.error("Errore in formatDateTime",err); 
    }
    return formattedDate;
}

function filesizeFormatter(s)
{
    // input is in bytes
    if (s >= 1048576) //megabytes
      return Math.round(s/1024/1024) + ' MB'
    if (s >= 1024) //kilobytes
      return Math.round(s/1024) + ' KB'
    return Math.round(s) + ' B' // bytes
  }

function filenameOnly(f)
{
    // strip datetime from objectName
    return f.split("_",).slice(2).join("_")
}

function protocolFormatter(p)
{
    if(p === true)
      return "Si"
    else
      return "No"

    }
function formatBeneficiari(idBeneficiario){
    //recupero la mappa dei beneficiari dallo store
    const mappaBenef = store.state.announcements.beneficiari;
    let beneficiario = mappaBenef[idBeneficiario];
    if (beneficiario) {
      return beneficiario.denominazione; 
    } else {
      return idBeneficiario;
    }  
}
function selezionaAttivita(gruppo, incomingData){
              
    let myAct = [];
    if(!gruppo || !incomingData){
          console.error("selezionaAttivita: NULL param passed!? Return empty array", gruppo, progetti);
          return myAct;
    }

    const progetti = Object.values(incomingData);
    for(let proj of progetti) {
        if(!proj.codiceProgetto || !proj.stato){
            console.error("selezionaAttivita valori non trovati! Progetto="+proj.codiceProgetto+" stato="+proj.stato);
            return myAct;
        }
        const statoScheda = proj.stato.toLowerCase();
        const isForBeneficiario1 =  isForBeneficiario(statoScheda);

        const isForUfficioGestione =  statoScheda.startsWith('inviata') || // sia "Inviata" che "Inviata dopo integrazione"
                            statoScheda.includes('in approvazione') ||
                            statoScheda.includes('rinviata per validazione');

        if(statoScheda.includes('pprovata') || proj.concluso){
            continue;
        } else if(gruppo.includes('eneficiari')
            && isForBeneficiario1) {
            myAct.push(proj);
      
        } else if( (gruppo.includes('MINT') || gruppo.includes('viewer'))
            && isForUfficioGestione){
            myAct.push(proj);
      
        }
    }

    return myAct;
}

function createField(){
    return new Field();
}

function elaboraDiffCronoprog(schedaCronoprog, config) {
    let result = {
        substancial : [],
        notSubstancial : []
    }
    if(!schedaCronoprog.content.dataEntryConfiguration){
        return result;
    }
    const arrowAndSpace = ' -> ';
    const arrow = '->';

    for(const [key, item] of Object.entries(schedaCronoprog.content.dataEntryConfiguration)){
        if(!item.data || !item.data.editTag) {
            continue;
        }
        const mapElement = mapUtils.getMapElement(config.tabellone, mapUtils.getGenericArrayKey(key));
        if(!mapElement) {
            continue;
        }
        let values = jpath('$'+key, schedaCronoprog.content);
        let currentVal = null;
        if(values.length > 1){
            console.error("trovati più valori per la chiave ="+key,values);
            currentVal = values[1];   //torno l'ultima
        } else {
            currentVal = values[0]; //torno la prima ed unica
        }
        let tab, label;
        try {
            let splitString = item.data.editTag.split(arrow);
            tab = splitString[0];
            splitString = splitString[1].split(arrowAndSpace);
            label = splitString[0].concat(arrowAndSpace, mapElement.config.label);
        } catch(error) {
            console.error('elaboraDiffCronoprog error, use default info for tag', error);
            const index = item.data.editTag.indexOf(arrow);
            tab = item.data.editTag.substr(0, index)
            label = item.data.editTag.substr(index + 2);
        }

        //imposto i campi comuni
        let row = {
            // content: {
                "Tab": tab,
                "Campo Modificato": label,
                "Note": item.data.note
            // }
        }

        if(item.data.edited && item.data.isSubstancial){
            //riempire array delle diff sostanziali
            row["Attuale"] = formatOutput(mapElement, currentVal);
            row["Precedente"] = formatOutput(mapElement, item.data.oldValue);
            result.substancial.push(row);

        } else if (item.data.deleted || item.data.added) {
            let valore;
            let valorePrec;

            if(item.data.deleted && item.data.added){
                continue;
            } else {
                if (item.data.deleted){
                    valore="(nessuno, cancellato)";
                    valorePrec=formatOutput(mapElement, currentVal);
                }

                if (item.data.added){
                    valorePrec="(nessuno, inserito)";
                    valore=formatOutput(mapElement, currentVal);
                }
            }

            row["Attuale"] = valore;
            row["Precedente"] = valorePrec;

            result.notSubstancial.push(row);
        }
        else if (item.data.edited){
            //riempire array delle differenze non sostanziali
            row["Attuale"] = formatOutput(mapElement, currentVal);
            row["Precedente"] = formatOutput(mapElement, item.data.oldValue);
            result.notSubstancial.push(row);
        }
    }
    return result
}

export default {
    caniuse,
    convertiRiga2,
    getSessionToken,
    formatDate,
    formatDateTime,
    filesizeFormatter,
    filenameOnly,
    protocolFormatter,
    formatBeneficiari,
    formatCurrency,
    selezionaAttivita,
    Field,
    recursiveMap,
    formatOutput,
    unformatOutput,
    createField,
    // fromCurrencyToValue,
    buildJson,
    viewOnlyMode,
    elaboraDiffCronoprog,
    formatBoolean
}
