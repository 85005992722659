let scadenze =  [       //da gennaio a dicembre (vedi manuale operativo) ordinate per index mmgg
  {
    index:  "0110", 
    dataString: "01/10/",
    titolo : "Scadenza Monitoraggio",
    descrizione : "Compilare ed inviare le schede di Monitoraggio per il quarto trimestre del YYYY (periodo di riferimento dal 1 Ottobre al 31 Dicembre)"
  },
  {
    index:  "0215", 
    dataString: "02/15/", //Per Assessment, mettere la data formato ingl. Vedi riga 117
    titolo : "Scadenza Assessment",
    descrizione : "Compilare ed inviare le schede di assessment relative all'anno finanziario ZZZZ (periodo di riferimento da Ottobre anno precedente ad Ottobre ZZZZ)"
  },  
  {
    index:  "0410", 
    dataString: "04/10/",
    titolo : "Scadenza Monitoraggio",
    descrizione : "Compilare ed inviare le schede di Monitoraggio per il primo trimestre del YYYY (periodo di riferimento dal 1 Gennaio al 31 Marzo)"
  },
  {
    index:  "0710", 
    dataString: "07/10/",
    titolo : "Scadenza Monitoraggio",
    descrizione : "Compilare ed inviare le schede di Monitoraggio per il secondo trimestre del YYYY (periodo di riferimento dal 1 Aprile al 30 Giugno)"
  },
  {
    index:  "1010", 
    dataString: "10/10/",
    titolo : "Scadenza Monitoraggio",
    descrizione : "Compilare ed inviare le schede di Monitoraggio per il terzo trimestre del YYYY (periodo di riferimento dal 1 Luglio al 30 Settembre)"
  }
  
];

const estraiAnno = function(date) {
  if(!date)
    return "YYYY"
  else
    return date.getFullYear();
}

const state = {
  scadenzeOrdinate: []
}

const getters = {

  getAllScadenze(state) {
    //ritorno lo state (deve sempre essere inizializzato)
    return state.scadenzeOrdinate;
  },

  getNextScadenza(state){
    //ritorno lo state (deve sempre essere inizializzato)
    return state.scadenzeOrdinate[0];

  }
  
}

const mutations = {
  RESET(state) {
    state.scadenzeOrdinate = [];
  },
  // I pass 'payload' because you can't pass more than 1 object in MUTATIONS!
  SET_SCADENZA(state, payload) {
    state.scadenzeOrdinate.push(payload);
  },

  SET_SCADENZE(state, payload) {
    state.scadenzeOrdinate = payload;
  },

  CLEAR_SCADENZE(state) {
    state.scadenzeOrdinate = [];
  },

}

const actions = {
  // only one parameter
  initScadenziario(context, dataRiferimento) {
    context.commit('CLEAR_SCADENZE');

    let meseCorrente = dataRiferimento.getMonth()+1;
    let giornoCorrente = dataRiferimento.getDate();
        
    let scadenzeAnno = [];
    let scadenzeFuture = [];
    //ciclo su tutte le scadenze, trovo il mese in cui sono
    for (let scad of scadenze){
      
      if (parseInt(meseCorrente) > parseInt(scad.index.substring(0,2))
          || ( parseInt(meseCorrente) == parseInt(scad.index.substring(0,2)) 
              && parseInt(giornoCorrente) > parseInt(scad.index.substring(2,4)))
        ) {
        //inserisco l'anno prossimo per quelle trascorse in base al mese
        scad.dataString = scad.dataString.substring(0,6)+(estraiAnno(dataRiferimento)+1);
        if (scad.descrizione.includes("YYYY")){
          scad.descrizione = scad.descrizione.replace("YYYY", ""+estraiAnno(dataRiferimento));
        } else if (scad.descrizione.includes("ZZZZ")) {
          scad.descrizione = scad.descrizione.replace("ZZZZ", ""+estraiAnno(dataRiferimento));
        }
          
        scadenzeFuture.push(scad);
      } else {
        //inserisco l'anno corrente per le restanti
        scad.dataString = scad.dataString.substring(0,6)+(estraiAnno(dataRiferimento));
        if (scad.descrizione.includes("YYYY")){
          scad.descrizione = scad.descrizione.replace("YYYY", ""+estraiAnno(dataRiferimento));
        } else if (scad.descrizione.includes("ZZZZ")) {
          scad.descrizione = scad.descrizione.replace("ZZZZ", ""+estraiAnno(dataRiferimento)-1);
          scad.descrizione = scad.descrizione.replace("ZZZZ", ""+estraiAnno(dataRiferimento)-1);
          scad.descrizione = scad.descrizione.replace("anno precedente", ""+estraiAnno(dataRiferimento)-2);
        }
        scadenzeAnno.push(scad);
      }
      
    } 
    
      //se esistono scadenze al prossimo anno inserisco in coda
      //inserisco in coda a quelle dell'anno corrente
        scadenzeAnno.push(...scadenzeFuture);

    context.commit('SET_SCADENZE', scadenzeAnno);
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
