<template>
    <div id="inventario2" v-show="tabName === componentConfig.name">
        <InfoCompiling
            :tabName="infoTabName"
            :title="'Informazioni di compilazione'"
        >
        </InfoCompiling>
        <VerticalComponent2 v-if="loadComplete"
            :idTablePdf="idTablePdf"
            :idScheda="idScheda"
            :storeModule="storeModule"
            :tabelloneType="tabelloneType"
            :componentConfig="componentConfig"
            :updateVertical2="incomingData.refresh[componentConfig.name]"
            @refreshValues="refreshData()"/>
    </div>
</template>
<script>
    import VerticalComponent2 from "@/components/verticalComponent2.vue"
    import InfoCompiling from "@/components/infoCompiling.vue"
    import utils from '@/helpers/utils.js'
    export default {
        name: "inventario2",
        components: {
            VerticalComponent2,
            InfoCompiling
        },
        props: {
            idTablePdf: { type: String, default: () => '' },
            infoTabName: { type: String, default: () => '' },
            incomingData: Object,
            tabName: String,
            rootId: String,
            dinamicIds: Array,  default: function() { return [] }
        },
        data() {
            return {
                loadComplete: false,
                idScheda: '',
                tabelloneType: 'cronoprog_monitoraggio',
                storeModule: 'cronoprog',
                componentConfig: {
                    title: "Inventario",
                    name: "inventario2",
                    notifyUpdate: true, // con questo notifico ogni modifica a questo tab
                    defaultActions: ['edit','view'],
                    allowedActions: [],
                    tableIds: [
                        "['attivita'][*]['content']['idAttivita']",
                        "['inventario'][*]['content']['valoreTotale']",
                        "['inventario'][*]['content']['numeroSerie']",
                        "['inventario'][*]['content']['localita']['luogo']",
                        "['inventario'][*]['content']['dataAcquisto']",
                        "['inventario'][*]['content']['descrizione']",
                        "['inventario'][*]['content']['tipoAttrezzatura']"
                        
                        
                    ],
                    relIds: {
                        "['attivita'][*]['content']['idAttivita']": {
                            type: 'attivita',
                        },
                        "['contratti'][*]['content']['riferimentoContratto']": {
                            type: 'contratti',
                        },
                    }
                }
            }
        },
        mounted() {
            this.idScheda = this.incomingData.schedaMonitoraggio.progetto.idProgetto;
            let actions = utils.viewOnlyMode(this.incomingData, this.componentConfig.defaultActions);
            this.componentConfig.allowedActions = actions;
            this.componentConfig.dinamicIds = this.dinamicIds
            this.componentConfig.rootId = this.rootId
            this.loadComplete = true;
        },
        methods: {
            // quando il tab è modificato, lancio verso schedaMonitoraggio un'azione di refresh
            refreshData() {
                this.$emit('refreshTab', this.componentConfig.name);
            }
        }
    }
</script>