import Vue from "vue";
import endpoints from "@/components/endpoints.vue";

const state = {
    tabelloneIstruttoria: {},
    schedeIstruttoria: {}, // DA CAPIRE COME GESTIRLA
}

const getters = {
    getTabelloneIstruttoria(state){
        return state.tabelloneIstruttoria
    },
    getMappa(state) {
        const tab = getters.getTabelloneIstruttoria(state);
        if(tab)
            return tab.mappa;
        console.error("getMappa no mappa in tabellone", tab);
        return {};
    },
    getTypes(state) {
        const tab = getters.getTabelloneIstruttoria(state);
        if(tab)
            return tab.types;
        console.error("getTypes: no types in tabellone", tab);
        return {};
    },

    getSchedaIstruttoria(state){
        return (payload) => {
            if (payload && payload.idSchedaIstruttoria &&
                state.schedeIstruttoria[payload.idSchedaIstruttoria]){
                    return state.schedeIstruttoria[payload.idSchedaIstruttoria]
                }
            return null
        }
    },
    getIstruttoria(state){
        return (payload) => {
            if ( payload &&
                payload.idSchedaIstruttoria &&
                state.schedeIstruttoria[payload.idSchedaIstruttoria]){
                    return state.schedeIstruttoria[payload.idSchedaIstruttoria].content
                }
                else
                    console.error('getIstruttoria required payload missing:',payload)
                ;
                return null
        }
    }
}

const actions = {
    setSchedaIstruttoria(context, payload){
        const infoAllegati = {
            allegatiDisponibili: {
                istruttoria: 0,
            },
            allegatiMancanti: {
                istruttoria: [],
            },
            istruttoria: payload.tabelloneAllegati.istruttoria

            /*
            [
                {type: 'Altro', mandatory:false, recommended:false},
                {type:'Appunto', mandatory:false, recommended:false},
                {type:'Notifica Esito Istruttoria', mandatory:false, recommended:false, task:'Progetto_approvazioneIstruttoria_UfficioGestione-Approvatore'}              
            ]*/
        }
        const url = endpoints.retrieveSchedaIstruttoria(payload.idSchedaIstruttoria)

        return Vue.prototype.$get(url).then((schedaIstruttoria) => {
            schedaIstruttoria.content.infoAllegati = infoAllegati
            let newPayload = {
                scheda: schedaIstruttoria,
                idSchedaIstruttoria: payload.idSchedaIstruttoria
            }
            context.commit("SET_SCHEDA", newPayload);
            // GESTIONE SCHEDA NOTE RISERVATE IN STORE
            const keySchedaNoteRiservate = schedaIstruttoria.noteRiservateKey;
            const version = schedaIstruttoria.noteRiservateVersion;

            if(!keySchedaNoteRiservate)
                return schedaIstruttoria;


            const url_reserved = endpoints.noteRiservate(keySchedaNoteRiservate);
            return Vue.prototype.$get(url_reserved, {version: version}).then((schedaNoteRiservate) => {
                context.commit('ADD_SCHEDA_NOTE_RISERVATE', {
                    schedaNoteRiservate: schedaNoteRiservate,
                    idSchedaIstruttoria: payload.idSchedaIstruttoria
                });
                return schedaIstruttoria;
            },
            (error) => {
                console.error('setSchedaNoteRiservate error', error.response);
                throw error
            });
        },
        (error) => {
            console.error('setSchedaIstruttoria error', error.response);
            throw error
        });
    },
    updateScheda(context, payload){
        context.commit("SET_SCHEDA", payload);
    },
    updateIstruttoria(context, payload) {
        context.commit("SET_ISTRUTTORIA", payload);
    },
    setAvailableFiles(context, payload) {
        context.commit("UPDATE_ALLEGATI_DISPONIBILI", payload);
    },
    setMandatoryMissed(context, payload) {
        context.commit("UPDATE_ALLEGATI_MANCANTI", payload);
    },

    updateParere(context, payload) {
        const istruttoriaUrl = endpoints.saveSchedaIstruttoria(payload.idScheda);
        // payload.content deve contenere solo il parere e la nota temporanea
        // per il resto, payload deve contenere le informazioni che servono
        // per sendSchedaIstruttoria, cioè le stesse della vecchia "complete"
        const body = {
            content: payload.content
        };

        // 1) aggiorna il parere dentro la scheda nel db
        return Vue.prototype.$put(istruttoriaUrl, body).then(
            () => {
                // 2) se è stato richiesto un salva&invia, allora lo esegue

                context.dispatch('sendSchedaIstruttoria', payload).then(
                    response => {
                        return response;
                    }
                ).catch(
                    error => {
                        console.error("updateParereICT error from saveSendSchedaIstruttoria:", error.response);
                        throw error
                    }
                );
            }
        ).catch(
            error => {
                console.error("updateParereICT put error:", error.response);
                throw error
            }
        );
    },

    sendSchedaIstruttoria(context, payload) {
        if(payload.salvataggio === "complete" || payload.salvataggio === "reject") {
            const scheda = payload.schedaIstruttoria;
            const urlComplete = endpoints.complete_schedaProgetto;
            let postBody = {
                idScheda: scheda.idSchedaProgetto,
                processId: scheda.processId,
                titoloProgetto: scheda.titoloProgetto,
                esitoOk: payload.salvataggio === 'complete' ? true : false,
                esitoValutazione: scheda.content.esitoValutazione,
                richiestoParereICT: scheda.content.richiestoParereICT === 'true' ? true: false,
                lavoriPubblici: scheda.content.richiestoParereLIE,
                derogatoria: scheda.procedureDerogatorie && scheda.procedureDerogatorie === true ? true : false,
                punteggioValutazione: scheda.content.istruttoria.merito.valutazionePunteggio,
                notaTemporaneaRiservata: scheda.content.notaTemporaneaRiservata ? scheda.content.notaTemporaneaRiservata : null,
                noteRiservateKey: scheda.noteRiservateKey && scheda.noteRiservateKey !== null ? scheda.noteRiservateKey : null
            }

            return Vue.prototype.$post(urlComplete,postBody).then(
                (responseComplete) => {
                    return responseComplete;
                },
                (error) => {
                    console.error("sendSchedaIstruttoria error:", error.response);
                    throw error
                }
            )
        }
        return true;
    },

    saveSchedaIstruttoria(context, payload) {
        const scheda = payload.schedaIstruttoria;
        const istruttoriaUrl = endpoints.saveSchedaIstruttoria(scheda.idSchedaIstruttoria);
        const urlComplete = endpoints.complete_schedaProgetto
        
        return Vue.prototype.$post(istruttoriaUrl, scheda).then(
            (responseDraft) => {
                if(payload.salvataggio === "complete" || payload.salvataggio === "reject") {

                    let postBody = {
                        idScheda: scheda.idSchedaProgetto,
                        processId: scheda.processId,
                        titoloProgetto: scheda.titoloProgetto,
                        esitoOk: payload.salvataggio === 'complete' ? true : false,
                        esitoValutazione: scheda.content.esitoValutazione,
                        richiestoParereICT: scheda.content.richiestoParereICT === 'true' ? true: false,
                        lavoriPubblici: scheda.content.richiestoParereLIE,
                        derogatoria: scheda.procedureDerogatorie && scheda.procedureDerogatorie === true ? true : false,
                        punteggioValutazione: scheda.content.istruttoria.merito.valutazionePunteggio,
                        notaTemporaneaRiservata: scheda.content.notaTemporaneaRiservata ? scheda.content.notaTemporaneaRiservata : null,
                        noteRiservateKey: scheda.noteRiservateKey && scheda.noteRiservateKey !== null ? scheda.noteRiservateKey : null
                    }

                    return Vue.prototype.$post(urlComplete,postBody).then(
                        (responseComplete) => {
                            return responseComplete
                        },
                        (error) => {
                            console.error("Errore Salvataggio Completo:",error.response)
                            throw error
                        }
                    )
                }
                return responseDraft
            },
            (error) => {
                console.error("Errore salvataggio in Bozza:",error.response)
                throw error
            }
        )
    },
    setTabellone(context) {
        
        // Urls Popolamento / Recupero del Tabellone
        // const urlPostTabellone = endpoints.populateTabIstruttoria
        const urlGetTabellone = endpoints.retrieveTabelloneIstruttoria('istruttoria')

        // return Vue.prototype.$post(urlPostTabellone, tabellone).then(
            // () => {
                // context.commit("SET_TABELLONE_ISTRUTTORIA", setTab)
                return Vue.prototype.$get(urlGetTabellone).then(
                    (getTab) => {
                        context.commit("SET_TABELLONE_ISTRUTTORIA", getTab)
                        return getTab;
                    },
                    (errorGet) => {
                        throw errorGet;
                    },
                )
            /*},
            (errorPost) => {
                throw errorPost;
            },
        )
        */
    },
    clear(context, state){
        context.commit("RESET", state)
    },
}

const mutations = {
    ADD_SCHEDA_NOTE_RISERVATE(state, payload) {
        if(payload && payload.idSchedaIstruttoria && payload.schedaNoteRiservate) {
            state.schedeIstruttoria[payload.idSchedaIstruttoria].schedaNoteRiservate = payload.schedaNoteRiservate;
        }
        else
            console.error('ADD_SCHEDA_NOTE_RISERVATE no key or scheda', payload);
    },
    SET_SCHEDA(state, payload) {
        if(payload && payload.idSchedaIstruttoria && payload.scheda){
            state.schedeIstruttoria[payload.idSchedaIstruttoria] = payload.scheda;
        }
        else
            console.error('SET_SCHEDA no key or scheda', payload);
    },
    SET_ISTRUTTORIA(state, payload) {
        if(payload && payload.content) {
            const schedaIstruttoria = state.schedeIstruttoria[payload.idSchedaIstruttoria];
            
            if(schedaIstruttoria && schedaIstruttoria.content) {
                state.schedeIstruttoria[payload.idSchedaIstruttoria].content.istruttoria = payload.content.istruttoria;
            }
            else
                console.error('SET_ISTRUTTORIA no content', payload, state.schedeIstruttoria[payload.idSchedaIstruttoria]);
        }
        else
            console.error('SET_ISTRUTTORIA no key or scheda', payload);
    },
    UPDATE_ALLEGATI_DISPONIBILI(state, payload){
        if ( payload.obj.idSchedaIstruttoria &&
            state.schedeIstruttoria[payload.obj.idSchedaIstruttoria] &&
            state.schedeIstruttoria[payload.obj.idSchedaIstruttoria].content &&
            state.schedeIstruttoria[payload.obj.idSchedaIstruttoria].content.infoAllegati.allegatiDisponibili[payload.obj.entity] !== undefined)
                state.schedeIstruttoria[payload.obj.idSchedaIstruttoria].content.infoAllegati.allegatiDisponibili[payload.obj.entity] = payload.obj.numeroAllegati
            else
                console.error("UPDATE_ALLEGATI_DISPONIBILI Error: Missing paylod or scheda")
    },
    UPDATE_ALLEGATI_MANCANTI(state, payload){
        if( payload.obj.idSchedaIstruttoria &&
            state.schedeIstruttoria[payload.obj.idSchedaIstruttoria] &&
            state.schedeIstruttoria[payload.obj.idSchedaIstruttoria].content &&
            state.schedeIstruttoria[payload.obj.idSchedaIstruttoria].content.infoAllegati.allegatiMancanti[payload.obj.entity])
                state.schedeIstruttoria[payload.obj.idSchedaIstruttoria].content.infoAllegati.allegatiMancanti[payload.obj.entity] = payload.obj.files
            else
                console.error("UPDATE_ALLEGATI_MANCANTI Error: Missing paylod or scheda")
    },

    UPDATE_RICHIESTA_PARERE_ICT(state, payload){
        if(payload && payload.richiestoParereICT != null && payload.idScheda) {
            state.schedeIstruttoria[payload.idScheda].content.richiestoParereICT = payload.richiestoParereICT;
        }
        else
            console.error('UPDATE_RICHIESTA_PARERE_ICT bad params', payload);
    },

    UPDATE_PARERE_ICT(state, payload){
        if(payload && payload.parereICT && payload.idScheda) {
            state.schedeIstruttoria[payload.idScheda].content.parereICT = payload.parereICT;
        }
        else
            console.error('UPDATE_PARERE_ICT bad params', payload);
    },

    UPDATE_RICHIESTA_PARERE_LIE(state, payload){
        if(payload && payload.richiestoParereLIE != null && payload.idScheda) {
            state.schedeIstruttoria[payload.idScheda].content.richiestoParereLIE = payload.richiestoParereLIE;
        }
        else
            console.error('UPDATE_RICHIESTA_PARERE_LIE bad params', payload);
    },

    UPDATE_PARERE_LIE(state, payload){
        if(payload && payload.parereLIE && payload.idScheda) {
            state.schedeIstruttoria[payload.idScheda].content.parereLIE = payload.parereLIE;
        }
        else
            console.error('UPDATE_PARERE_LIE bad params', payload);
    },

    UPDATE_PARERE_UFF(state, payload){
        if(payload && payload.parereUFF && payload.idScheda) {
            state.schedeIstruttoria[payload.idScheda].content.parereUFF = payload.parereUFF;
        }
        else
            console.error('UPDATE_PARERE_UFF bad params', payload);
    },


    SET_TABELLONE_ISTRUTTORIA(state, payload){
        state.tabelloneIstruttoria = payload
    },
    RESET(state){
        state.tabelloneIstruttoria = {}
        state.schedeIstruttoria = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};