<template>
  <div>
     <div class="card">
        <div class="card-body text-right">
          <h1 class="font-weight-bold">Totale Importi: {{ totaleSpeseEuro }}</h1>
        </div>
      </div>
    <GenericList
      :id="name" 
      :title="'Lista Progetti per Disposizioni di Pagamento'"
      :busyTable="isBusy"
      :headerTable="headerTable"
      :dataTable="tableData"
      :actionsInRow="this.$getActionsListaProgettiDisposizione"
      :actionsMapping="this.buttonLabels"
      :blockButton="isBusy || blockButton"
      :sortBy="'codiceProgetto'"
      @clickActionList="doAction"/>
  </div>
</template>
<script>
import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue"
import GenericList from "@/components/genericList.vue"

export default {

  components: {
    GenericList
  },
  data() {
    return {
      totaleSpeseEuro: "€ 0,00",
      name: "listaProgettiDisposizioni",
      headerTable: [
        {
          key: "codiceProgetto",
          label: "Codice Progetto",
          sortable: true,
        },
        {
          key: "titoloProgetto",
          label: "Titolo Progetto",
          sortable: true,
        },
        {
          key: "strumentoFinanziario",
          label: "Strumento Finanziario",
          sortable: true,
        },
        {
          key: "dataConclusione",
          label: "Data Conclusione",
          formatter: "dateFormatter",
          sortable: true,
        },
        {
          key: "costoProgetto",
          label: "Costo Progetto",
          formatter: "currencyFormatter",
          sortable: true,
        },
        {
          key: "importoRichiesto",
          label: "Importo Richiesto",
          formatter: "currencyFormatter",
          sortable: true,
        },
        {
          key: "importoErogato",
          label: "Importo Erogato",
          formatter: "currencyFormatter",
          sortable: true,
        },
        {
          key: "numeroDisposizioni",
          label: "Disposizioni",
          sortable: true,
        },
        {
          key: "Azioni"
        }
      ],
      buttonLabels: {
        view: "Visualizza Progetto",
        view_controlli_list: "Visualizza Disposizioni di Pagamento",
      },
      blockButton: false,
      isBusy: false,
      tableData: [],
      

    }
  },

  mounted() {
    this.caricaProgettiDisposizioni();
  },

  methods: {
    
    doAction(action, data) {
      if (!data) {
        console.log("scheda vuota!?", data)
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        )
        return
      }

      let codiceProgetto = data.item.codiceProgetto
      //console.log("selezionato codiceProgetto=", codiceProgetto)
      let infoProgetto = data.item
      
      //console.log("infoProgetto=", infoProgetto)
      let idProgetto = infoProgetto.idProgetto
      
      switch (action) {
        case "view":    //visualizzazione della scheda progetto
          this.$router.push({
            name: "schedaProgetto",
            params: { idProgetto: idProgetto },
          })
          break
        case "view_controlli_list":
          this.$router.push({
            name: "listaDisposizioni",
            params: { 
              codiceProgetto: codiceProgetto
            },
          })      
          break
        
        default:
          console.error("Unknown action!", action)
          break
      }
    },

    caricaProgettiDisposizioni: async function() {
      this.isBusy = true
      const url = endpoints.getProgettiDisposizione;

      const urlAttuazioneOrConclusi = endpoints.listActiveProjects;

      Promise.all([this.$get(url), this.$get(urlAttuazioneOrConclusi)])
      .then(results => {
        console.log(this.name, 'progetti:', results[1].length, 'disposizioni', Object.keys(results[0]).length);
        this.extractData(results);
        this.isBusy = false
      }).catch(error => {
        this.isBusy = false;
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        )
        console.error(this.name, "errore caricamento lista progetti con disposizioni di pagamento", error);
        throw error
      })
    }, 

    extractData(results) {
      const result = results[1];
      let totaleSpese=0;

      let disposizioniMap = results[0];
      this.tableData = [];
      for (const infoObj of result) {

        const item = infoObj.infoProgetto;
        const codiceProgetto = item.codiceProgetto;
        let importoRichiesto = 0;
        let importoErogato = 0;
        let numeroDisposizioni = 0;
        if(disposizioniMap[codiceProgetto]) {
          const disp = disposizioniMap[codiceProgetto];
          importoRichiesto = disp.importoRichiesto;
          importoErogato = disp.importoErogato;
          numeroDisposizioni = disp.numeroDisposizioni;
          const importo = parseFloat(disp.importoErogato);
          if(!isNaN(importo))
            totaleSpese += importo;
        }
        item.importoRichiesto = importoRichiesto;
        item.importoErogato = importoErogato;
        item.numeroDisposizioni = numeroDisposizioni;
        this.tableData.push(item);
      }
      this.totaleSpeseEuro = utils.formatCurrency(totaleSpese);
    }
   
  },
}
</script>
