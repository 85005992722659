import endpoints from '@/components/endpoints.vue';
import tools from '@/helpers/tools.js';
import Vue from "vue";

const state = {
  notifiche: [],
}

const getters = {
  getLatest(state) {
      if (tools.objectHasValue(state.notifiche)) {
          // array is ordered so return latest
          // by returning first item of array
        return state.notifiche[0]
      }
      return null;
  },
  getOldest(state) {
    if (tools.objectHasValue(state.notifiche)) {
      // array is ordered so return oldest
      // by returning last item of array
      return state.notifiche[state.notifiche.length-1]
    }
    return null;
  },
  getAll(state) {
      if (tools.objectHasValue(state.notifiche)) {
        return state.notifiche
      }
      return null;
  },
  getMap(state){
    if (tools.objectHasValue(state.notifiche)) {
        return tools.groupBy(state.notifiche, i => i.codiceProgetto)
      }
    return null;
  }
}

const mutations = {
  RESET(state) {
    state.notifiche = [];
  },
  SET_NOTIFICHE(state, payload){
    if(tools.objectHasValue(payload)
        && tools.objectHasValue(payload.data)
    ){
      state.notifiche = payload.data
    }
  },
}

const actions = {
  
  // retrieve BUT DO NOT SAVE TO STORE
  retrieveStoricoAssessment(context, {
    maxFetch: maxFetch
  }) {
    return Vue.prototype.$get(endpoints.assessmentHistory(maxFetch))
    .then(
      result => {
        return result
      }
    );
  },

  // retrieve BUT DO NOT SAVE TO STORE
  retrieveStoricoMonitoraggio(context, {
    maxFetch: maxFetch
  }) {
    return Vue.prototype.$get(endpoints.monitoraggioHistory(maxFetch))
    .then(
      result => {
          return result
      },
    );
  },

  // retrieve BUT DO NOT SAVE TO STORE
  retrieveNotifications(context, {
    maxFetch: maxFetch
  }) {
    const url = endpoints.notification;
    let params;
    if(maxFetch)
    {
      params = {page: 0, pageSize: maxFetch};
    }
    return Vue.prototype.$get(url, params)
    .then((result) => {
        console.log("notifications found:", result.count)
        return result;
    })
  },

  // combination of the two above AND SAVING TO STORE
  retrieveStoricoAzioni(context, payload) {
    const notificationsArray = [];
    let aree = Vue.prototype.$getDashboardAreas();
    if (aree.indexOf("MONITORING") !== -1) { //solo se si ha diritto a vedere monitoraggio
      let monitoraggio = context.dispatch('retrieveStoricoMonitoraggio', { maxFetch: payload.maxFetch });
      notificationsArray.push(monitoraggio);
    }
    if (aree.indexOf("ASSESSMENT") !== -1) { //solo se si ha diritto a vedere assessment
      let assessment = context.dispatch('retrieveStoricoAssessment', { maxFetch: payload.maxFetch });
      notificationsArray.push(assessment);
    }
    if (aree.indexOf("CALL") !== -1 
      || aree.indexOf("EXECUTING_BODY") !== -1
      || aree.indexOf("PROJECT") !== -1) { //solo se si ha diritto a vedere bandi, EB o progetti
      let notifications = context.dispatch('retrieveNotifications', { maxFetch: payload.maxFetch });
      notificationsArray.push(notifications);
    }

    return Promise.all(notificationsArray)
      .then(results => {
        let fetchedData = []
        // concat results of promises
        for(const result of results) {
          if(!result.data) {
            // caso del monitoraggio/assessment
            fetchedData = fetchedData.concat(result);
          }
          else {
            // in caso di notifiche provenienti da bandi/progetti (fase 3/4)
            // bisogna prima adattare il formato al caso monitoraggio/assessment
            for(const notifica of result.data) {
              let periodo = "N/D";
              if(notifica.customData && notifica.customData.toString().includes('periodo')) {
                const customData = notifica.customData.toString();
                const periodoSplit = customData.split('periodo: ');
                if(periodoSplit.length > 1)
                  periodo = periodoSplit[1];
              }
              const item = {
                titolo: notifica.titolo,
                codiceProgetto: notifica.idOggetto,
                dataModifica: notifica.receivedDate,
                modificatoDa: notifica.triggeredBy,
                tipoScheda: notifica.tipoOggetto,
                periodo: periodo, // il periodo non è definito per un progetto
                stato: notifica.eventType,
              }
              fetchedData.push(item);
            }
          }
        }
        // ... and sort them desc IN PLACE
        fetchedData.sort(function (a, b) {
          return new Date(b.dataModifica) - new Date(a.dataModifica)
        });
        // clean store and commit
        if(payload.storeResults) {
          context.commit('RESET')
          context.commit('SET_NOTIFICHE', {
            data: fetchedData,
          });
        }
        return fetchedData;
      });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

