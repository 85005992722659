<template>
  <div id="indicatoriImpl" v-if="loadComplete">
    <DynamicActiveTable2 :idTablePdf="idTablePdf" :tdata="tabledata" :doAction="this.doAction" :actions="this.actions"/>
    <modalForm :fdata="this.editFormData"
               :sch="this.scheda"
               :hidden="!showEditForm"
               :buttons="this.buttons"
               :cfg="incomingData.schedaAssessment.dataEntryConfiguration"
               @close="showEditForm = false"
               @salvaRecord="aggiornaRiga">
      <div class="row-title" slot="header">{{modalHeader}}</div>
    </modalForm>
  </div>
</template>

<script>
import DynamicActiveTable2 from "@/components/dynamicActiveTable2.vue";
import modalForm from "@/components/modalForm.vue";
import tool from '@/helpers/tools.js'
import notify from "@/helpers/notifications.js"
import mapUtils from '@/helpers/mapUtils.js'
import utils from '@/helpers/utils.js'

export default {
  name: "indicatoriImpl",
  components: {
    // DynamicTable2,
     DynamicActiveTable2,
    modalForm
  },

  props: {
    idTablePdf: String,
    incomingData: Object,
    salvaSchedaToStore: Function,
    viewOnlyMode: Function,
    tabName: String
  },
  watch: {
    tabName: function() {
      if(this.$props.tabName === "risultati") {
        this.extractData();
      }
    }
  },
  mounted() {
    this.showEditForm = false;
    this.showModal = false;
    this.extractData();
  },
  data() {
    return {
      loadComplete: false,
      actions: ["edit"],
      tabledata: {
        header: ["ID Indicatore", "Descrizione Indicatore", "Unità di Misura", "Valore di Partenza", "Valore Atteso", "Valore Raggiunto", "Note"],
        rows: [
          {
            content: {
              "ID Indicatore": "Non disponibile",
              "Descrizione Indicatore": "Non disponibile",
              "Unità di Misura": "Non disponibile",
              "Valore di Partenza": "Non disponibile",
              "Valore Atteso": "Non disponibile",
              "Valore Raggiunto": "Non disponibile", 
              "Note": "Non disponibile"
            }
          }
        ]
      },
      buttons: [
            {
              name: 'Salva',
              action: 'salvaRecord',
              show: true,
              param: "edit"
            }
      ],
      editFormData: {},
      modalHeader: "",
      showEditForm: false,
      scheda: {}
    };
  },

  methods: {
    aggiornaRiga(actionFromModal) {
      let clonedScheda = mapUtils.aggiornaScheda(actionFromModal, tool.genericFunctions.cloneObject(this.incomingData.schedaAssessment));
      this.salvaSchedaToStore(clonedScheda);
      this.extractData();
      this.showEditForm = false;
    },

    doAction(actionToDo, index, item) {
        switch(actionToDo) {
          case 'edit':
            this.showEditForm = true;
            this.editFormData = mapUtils.editFormDataFromRiga(item);
            this.modalHeader = "Modifica Indicatore";
            this.buttons[0].show=true;
            break;
          default:
            notify.error("Errore","Azione non gestita");
            console.error("indicatoriImpl: azione='"+actionToDo+"' non gestita!?");
            break;  
        }
    },

    extractData() {
      this.loadComplete = false;
      if(!this.incomingData){
            console.log("indicatori: Invalid inputData!?");
            return;
      }
      this.scheda = this.incomingData.schedaAssessment;
      let inputData = this.incomingData.schedaAssessment;
      this.viewOnlyMode(this.incomingData, this.actions);
      if(!inputData.indicatori) {
        this.tabledata.rows = [];
        return;
      }
        
      
      let rows = [];
      //ciclo per ogni indicatore
      for(let i=0; i< inputData.indicatori.length; i++){

        let item = inputData.indicatori[i];
        
        let row = {};
        const mappa = inputData.dataEntryConfiguration;

          
        let colIds = [];
        //definisco gli id delle colonne da visualizzare
        colIds.push("['indicatori']["+i+"]['indicatoreObiettivo']['idIndicatore']");
        colIds.push("['indicatori']["+i+"]['indicatoreObiettivo']['descrizione']");
        colIds.push("['indicatori']["+i+"]['indicatoreObiettivo']['unitaDiMisura']");
        colIds.push("['indicatori']["+i+"]['indicatoreObiettivo']['valorePartenza']");
        colIds.push("['indicatori']["+i+"]['valoreAtteso']");
        colIds.push("['indicatori']["+i+"]['valoreRaggiunto']");
        colIds.push("['indicatori']["+i+"]['note']");

        let myConf = mapUtils.costruisciConfigurazione(colIds, mappa);


        row = {
              content: {},
              position : [i], //servirà dopo per capire quale riga si sta aggiornando 
              conf : myConf
        };

        //scrivo il valore dei vari campi (stesso ordine usato sopra) con l'ID : valore
        row.content[colIds[0]] = item.indicatoreObiettivo.idIndicatore;
        row.content[colIds[1]] = item.indicatoreObiettivo.descrizione;
        row.content[colIds[2]] = item.indicatoreObiettivo.unitaDiMisura;
        row.content[colIds[3]] = item.indicatoreObiettivo.valorePartenza;
        row.content[colIds[4]] = item.valoreAtteso;
        row.content[colIds[5]] = item.valoreRaggiunto;
        row.content[colIds[6]] = item.note;

        rows.push(row);
      }
    
      this.tabledata.rows = rows;
      this.configureActions();
      this.loadComplete = true;
    },
    configureActions() {
      for(let row of this.tabledata.rows) {
        let conf = row.conf;
        let actions = tool.genericFunctions.cloneObject(this.actions);
        for(let value of Object.values(conf)) {
          if(value.data.deleted){
            actions = actions.filter( value1 => {
              return (value1 !== 'edit' && value1 !== 'delete')
            });
            break;
          }
        }

        for(let j = 0; j < actions.length; j++) {
            if(!utils.caniuse(actions[j], this.incomingData.taskList, this.incomingData.schedaAssessment)) {
              actions.splice(j,1);
              j--;
            }
        }
        row.actions = actions;
      }
    },
  }
};
</script>