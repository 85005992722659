<template>

    <div class="dashboard">
        <div id="userinfo" class="dashboard-row">
            <fa-icon :icon="['fas', 'user-circle']" class="row-icon"/>
            <div class="row-title">
                <strong>&nbsp;Profilo</strong>
            </div>
            <h4>Informazioni di base</h4>
            <div style="padding: 10px;">
                <p>
                    Nome: {{userInfo.given_name || "N/D"}} 
                    <br/>
                    Cognome: {{userInfo.family_name || "N/D"}} 
                </p>
                <p>
                Username: {{userInfo.preferred_username || "N/D"}}
                </p>
                <p>
                E-mail: {{userInfo.email || "N/D" }} 
                </p>
            </div>
            <b-container>
                <b-card id="profiloAttuale" bg-variant="light" title="Profilo attuale" class="dashboard-row">
                    <h4>Gruppo</h4>
                    <ul>
                        <li> &#8226;&nbsp;{{getGroup}}</li>
                    </ul>
                    
                    <h4>Ruolo</h4>
                    <ul>
                        <li> &#8226;&nbsp;{{getRole}}</li>
                    </ul>
                </b-card>
            
                <b-card id="formProfilo" bg-variant="light" title="Cambio profilo" class="dashboard-row" v-if="showChangeProfileForm">
                    <b-row>
                        <b-col cols="1">
                        </b-col>
                        <b-col>
                            <b-form id="scegliProfilo" @submit="onSubmit">
                                <label for="selectProfilo">Scegli tra i profili disponibili: </label>
                                <b-form-select id="selectProfilo" v-model="chosenProfile" :options="profiles" class="mb-3" size="lg">
                                </b-form-select>
                                <b-row style=" float:right">
                                    <b-button   id="buttonCambiaProfilo"
                                                type="submit" 
                                                variant="primary"
                                                :disabled="checkDisabled(chosenProfile)">
                                            Cambia profilo
                                    </b-button>
                                </b-row>
                            </b-form>
                        </b-col>
                        <b-col cols="1">
                                
                        </b-col>
                        
                    </b-row>
                </b-card>
            </b-container>
        </div>
        <b-modal id="confermaCambioProfilo"
            centered size="lg"
            ok-variant='success' ok-title='Cambia profilo'
            cancel-title='Annulla'
            @ok="doChangeProfile()">
            <template v-slot:modal-header><h4><strong>Conferma cambio profilo</strong></h4></template>
            <p class="mb-4">Si sta per passare dal profilo:</p>
            <p class="mb-4">
                <ul>
                    <li>Gruppo: {{formatGroup(currentProfile.group)}}</li>
                    <li>Ruolo: {{formatRole(currentProfile.role)}}</li>
                </ul>
            </p>
            <p class="mb-4">
                al seguente profilo:
            </p>
            <p class="mb-4">
                <strong>
                    <ul>
                        <li>Gruppo: {{formatGroup(chosenProfile.group)}}</li>
                        <li>Ruolo: {{formatRole(chosenProfile.role)}}</li>
                    </ul>
                </strong>
            </p>
            <p class="mb-4">Si desidera procedere?</p>
        </b-modal>
    </div>

</template>


<script>
import { mapGetters, mapMutations } from 'vuex';
import notify from "@/helpers/notifications.js"
    export default {
        name: 'profilo',
        components: {
        },
        data() {
            return {
                userInfo: {},
                currentProfile: { },
                chosenProfile: { group: '', role: ''},
                profiles: [],
                beneficiariMap: {}
            };
        },
        computed: {
             ...mapGetters({
                getBeneficiari: 'announcements/getAllBeneficiariMap'
            }),
            showChangeProfileForm() {
                return this.profiles.length > 1;
            },
            getGroup() {
                let retVal = '';
                if(!this.currentProfile || !this.currentProfile.group)
                    return retVal;
                const group = this.currentProfile.group;
                return this.formatGroup(group);
            },
            getRole() {
                let retVal = '';
                if(!this.currentProfile || !this.currentProfile.role)
                    return retVal;
                const role = this.currentProfile.role;

                return this.formatRole(role);
            }
            
        },
        mounted() {
            this.beneficiariMap = this.getBeneficiari;
            this.setupCurrentProfile();
            this.getUserInfo();
        },
        methods: {
            formatRole(role) {
                let retVal = '';
                if(!role)
                    return retVal;
                let splittedRole = role.split('-');
                if(!splittedRole || splittedRole.length !== 2)
                    return role;
                if(splittedRole[0] === splittedRole[1])
                    retVal = splittedRole[0];
                else
                    retVal = role.replace('-', ' ');

                return retVal;
            },
            formatGroup(group) {
                let retVal = '';
                if(!group)
                    return retVal;
                const idBeneficiario = group.split('/')[2];
                if (!group.includes('MINT')) {
                    let beneficiario = this.beneficiariMap[idBeneficiario];
                    if (beneficiario) {
                        retVal = beneficiario.denominazione 
                    } else {
                        console.error(this.name, idBeneficiario, " non trovato nella mappa dei Beneficiari disponibili ", this.beneficiariMap);
                        retVal = idBeneficiario;
                    }
                } else {
                   retVal = idBeneficiario;
                }
                return retVal;
            },
            ...mapMutations({
                resetProgetti:"progetto/RESET",
                resetControlli: "controlli/RESET",
                resetIrregolarita: "irregolarita/RESET",
                resetCircuitoFinanziario: "circuitoFinanziario/RESET",
                resetDisposizioni: "disposizioni/RESET",
                resetEB: "executingBody/RESET",
                resetIstruttoria: "istruttoria/RESET",
                resetBandi: 'announcements/RESET',
                resetConvenzione: "convenzione/RESET",
                resetMon: 'monitoraggio/RESET',
                resetAss: 'assessment/RESET',
                resetStorico: 'storicoAzioni/RESET',
                resetCal: 'scadenziario/RESET',
                resetConfiguration: "configuration/RESET",
                resetCronoprog: "cronoprog/RESET"
            }),
            resetAll(){
                this.resetProgetti();
                this.resetControlli();
                this.resetIrregolarita();
                this.resetCircuitoFinanziario();
                this.resetDisposizioni();
                this.resetEB();
                this.resetIstruttoria();
                this.resetBandi();
                this.resetConvenzione();
                this.resetMon();
                this.resetAss();
                this.resetStorico();
                this.resetCal();
                this.resetConfiguration();
                this.resetCronoprog();
            },
            doChangeProfile() {
                this.storeChosenProfile();
                this.resetAll();
                sessionStorage.removeItem('hasBeenLoaded');
                
                this.$router.push({ name: "secure" }); // forzo redirect al cruscotto
            },
            onSubmit(event) {
                event.preventDefault();
                this.$bvModal.show('confermaCambioProfilo');
            },
            setupCurrentProfile() {
                const sd = sessionStorage.getItem('currentProfile');
                if (!sd) {
                    console.error(this.name, 'setupCurrentProfile Empty sessionData');
                    notify.error(notify.strings.error, notify.strings.sessionError);
                    return {}; // no session data, error
                }
                const ss = JSON.parse(sd);

                if (!ss) {
                    console.error(this.name, 'setupCurrentProfile Error parsing sessionData');
                    notify.error(notify.strings.error, notify.strings.sessionError);
                    return {}; // no session data, error
                }
                this.currentProfile = ss;
                this.chosenProfile.group = ss.group;
                this.chosenProfile.role = ss.role;
            },
            storeChosenProfile() {
                sessionStorage.setItem('currentProfile', JSON.stringify(this.chosenProfile));
            },
            formatProfile(profile) {
                if(!profile || !profile.group || !profile.role)
                    return '';
                return ''.concat('Gruppo: ', this.formatGroup(profile.group), ', Ruolo: ', this.formatRole(profile.role));
            },
            getUserInfo() {
                const sd = sessionStorage.getItem('sessionData');
                if (!sd) {
                    console.error(this.name, 'getUserInfo Empty sessionData');
                    notify.error(notify.strings.error, notify.strings.sessionError);
                    return {}; // no session data, error
                }
                const ss = JSON.parse(sd);
                if (!ss) {
                    console.error(this.name, 'getUserInfo Error parsing sessionData');
                    notify.error(notify.strings.error, notify.strings.sessionError);
                    return {}; // no session data, error
                }
                this.userInfo = ss.userInfo;
                this.profiles.splice(0, this.profiles.length);
                if(ss.userInfo.profiles) {
                    const profiles = ss.userInfo.profiles;//.split(',');
                    for(const prof of profiles) {
                        const profileSplit = prof.split(':');
                        const profile = { group: profileSplit[0].toString(), role: profileSplit[1].toString() };
                        const option = {
                            text: this.formatProfile(profile),
                            value: profile,
                            disabled: this.checkDisabled(profile)
                        }
                        this.profiles.push(option);
                    }
                }
            },
            checkDisabled(profile) {
                return (profile.group === this.currentProfile.group && profile.role === this.currentProfile.role);
            }
        }
    }
</script>
<style lang="scss">

h4 {
    font-size: 1.8rem;
    font-weight: 500;
    padding-top: 1.6rem;
}

#userinfo{
    font-size: 1.6rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

</style>