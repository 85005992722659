<template>
    <b-container>
        <b-overlay :show="!loadComplete" rounded="sm">
            <b-container>
                <b-row style="font-size: 14px; margin-bottom: 30px;">
                    <b-col cols="9" v-if="this.procOptions.length>1">
                        <b-alert variant="info" show>
                            <strong>Nota:</strong> Non &egrave; possibile controllare più volte un Contratto.
                            <br>I contratti già sottoposti a controllo verranno mostrati in elenco ma non sono selezionabili.
                        </b-alert>
                    </b-col>
                    <b-col cols="9" v-if="!hasControlli1A">
                        <b-alert variant="warning" show>
                            Non &egrave; possibile avviare alcun controllo 1B. Deve esistere almeno un precedente controllo 1A esitato.
                        </b-alert>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="9">
                        <b-form-input id="titolo1B" v-model="titoloControllo" 
                                    placeholder="Titolo del controllo da avviare"
                                    :disabled="isWaitingForSchedaControlloPost"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="9">
                        <h4 class="text-center">Selezionare il contratto da controllare</h4>
                        <b-form-select id="contratto1B" v-model="selectedContract"
                            :options="procOptions"
                            size="lg" 
                            :disabled="isWaitingForSchedaControlloPost"/>
                    </b-col>
                </b-row>
            </b-container>
        </b-overlay>
        <b-container>
            <b-row>
                <b-col class="modal-footer text-right">
                    <b-button
                        class="btn-success"
                        @click="creaNuovaCheckList"
                        :disabled="isCreaSchedaControlloDisabled"
                        >
                        Crea Nuova CheckList
                    </b-button>
                    <b-button variant="danger"
                        class="modal-default-button"
                        @click="closeModal"
                        :disabled="isWaitingForSchedaControlloPost">
                        Chiudi
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>

import endpoints from "@/components/endpoints.vue";
import notify from "@/helpers/notifications.js"
import feedFx from '@/helpers/feedFunctions.js'
import tool from '@/helpers/tools.js'

export default {
    name: "selezioneCheckListControllo1B",
    data(){
        return {
            loadComplete: false,
            sottoTipoControllo: '1B',
            selectedContract: null,
            procOptions: [            //deve contenere le procedure del progetto in questione che non hanno ancora subito controllo di questo tipo
                { value: null, text: 'Seleziona contratto', disabled: true },
            ],
            controlli: [],
            hasControlli1A: true,
            isWaitingForSchedaControlloPost: false,
            codiceProgetto: null,
            cronoprog: null,
            titoloControllo: null,
            procedura1A: null,
            tipologieChecklist: {}
        }
    },

    computed: {
        isCreaSchedaControlloDisabled: function() {
            // disabilitato nel caso l'utente non abbia selezionato
            // nulla nei dropdown oppure se e' in corso la chiamata
            // POST per la creazione della schedacontrollo
            return (!this.selectedContract || this.isWaitingForSchedaControlloPost || !this.titoloControllo
            || this.titoloControllo.toString().trim().length === 0)
        },
    },

    methods: {

        creaNuovaCheckList(){
            console.log("selectedContract=", this.selectedContract);
            this.isWaitingForSchedaControlloPost = true;
            this.creaNuovaSchedaControllo()
              .then(res => {
                // passo alla pagina della scheda controllo appena creata
                this.isWaitingForSchedaControlloPost = false;
                this.$router.push({
                    name: 'schedaControlliAmministrativi',
                    params: {
                        codiceProgetto: this.codiceProgetto,
                        idControllo: res.idSchedaControllo
                    }
                })
              })
              .catch((error) => {
                  console.error("Errore:", error.message);
                  notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                  this.isWaitingForSchedaControlloPost = false;
              });
        },

        async creaNuovaSchedaControllo(){

            const codiceProgetto = this.codiceProgetto;
            const selectedContract = this.selectedContract;

            try {
                // recupero i dati di progetto
                let url = endpoints.getProgettoByCodice(this.codiceProgetto);
                const res = await this.$get(url);
                const infoProgetto = feedFx.feedInfoProgetto(res.schedaProgetto);
                // console.log("letto controllo 1A=", controllo1A);
                // recupero i dati della procedura 1A a cui è legato il contratto selezionato
                const controllo1A = selectedContract.controllo1A;
                //recupero l'ID della checklist applicata durante il controllo 1A che riuso per l'1B
                const selectedChecklist = controllo1A.idSchemaControllo.substring(3);
                const idSchemaControllo = '1B_' + selectedChecklist

                const lastChecklist = await this.$get(
                    endpoints.retrieveLastVersionTabellone(idSchemaControllo)
                );
                const lastChecklistVersion = lastChecklist.lastVersion;

                let anagrafica = {};
                if (lastChecklist){
                    anagrafica = feedFx.feedAnagrafica1B(res.schedaProgetto, selectedContract)
                }
                let userIdGroup = this.$getUserGroupId();
                userIdGroup = userIdGroup.replace('/MINT/','');
                userIdGroup = userIdGroup.replace('/Beneficiari/','');

                const autore = this.$getUserInfo().preferred_username;
                const checklistLabel = this.tipologieChecklist[selectedChecklist].label
                const body = {
                    codiceProgetto: codiceProgetto,
                    idProgetto: this.cronoprog.idProgetto,
                    idSchemaControllo: idSchemaControllo,
                    checklistLabel: checklistLabel,
                    schemaVersione: lastChecklistVersion,
                    idBeneficiario: userIdGroup,
                    tipoControllo: "Amministrativo",
                    sottoTipoControllo: "1B",
                    cronoprogItemId: selectedContract.id,
                    cronoprogRootId: "contratti", // matcha la key all'interno del content di cronoprog
                    autoreUltimoAggiornamento: autore,
                    strumentoFinanziario: infoProgetto.strumentoFinanziario,
                    content: {
                        //tipoProcedura: selectedChecklist, //attributo non utilizzato
                        idAttivita: selectedContract.controllo1A.idAttivita,
                        idProceduraGara: selectedContract.procedura.content.idProceduraGara,
                        titoloControllo: this.titoloControllo, // inserito dall'utente
                        riferimentoContratto: selectedContract.contratto.content.riferimentoContratto,
                        autoreUltimoAggiornamento: autore,
                        autocontrollo: {},
                        controllo: {},
                        anagrafica: anagrafica,
                        infoProgetto: infoProgetto
                    }
                }

                // POST di creazione della scheda controllo
                url = endpoints.getControlliAmministrativi(codiceProgetto);
                let result = await this.$post(url, body)
                let schedaControlloCreata = result;
                if (!schedaControlloCreata.idSchedaControllo ||
                    !schedaControlloCreata.codiceProgetto)
                {
                    let msg = "Scheda controllo creata non valida. Controllare la scheda creata"
                    console.error(msg, result);
                    throw new Error(msg);
                }
                console.log(
                    "schedaControllo creata",
                    "idSchedaControllo=", schedaControlloCreata.idSchedaControllo,
                    "codiceProgetto=", schedaControlloCreata.codiceProgetto
                );

                // checkout del branch di cronoprog a partire dal trunk
                url = endpoints.cronoCheckout;
                const branch = 'controlli' + this.sottoTipoControllo;
                const checkoutBody = {
                    idProgetto: this.cronoprog.idProgetto,
                    version: null,
                    branch: branch,
                    idSchedaControllo: schedaControlloCreata.idSchedaControllo
                }
                let cronoResult = await this.$post(url, checkoutBody);
                if(!cronoResult.content.contratti[selectedContract.id]) {
                    cronoResult.content.contratti[selectedContract.id] =  selectedContract.contratto;
                }
                // dopo aver ottenuto la scheda branch, aggiorno il contratto in fase di controllo
                cronoResult.content.contratti[selectedContract.id].content.controlling = schedaControlloCreata.idSchedaControllo;
                // setto esplicitamente che all'avvio la chiave non sia settata
                cronoResult.content.contratti[selectedContract.id].content.controlled = null;
                url = endpoints.cronoprog(this.cronoprog.idProgetto)
                const params =  {
                    branch: branch,
                    idSchedaControllo: schedaControlloCreata.idSchedaControllo
                }
                let cronoprogResult = await this.$post(url, cronoResult, params);
                console.log("cronoprogResult", cronoprogResult)

                // avvio il processo
                this.processo = await this.avviaProcessoControllo(
                    schedaControlloCreata.idSchedaControllo, schedaControlloCreata.codiceProgetto);
                console.log("Processo avviato, ID=", this.processo);

                //effettuo il claim implicito per l'utente che ha creato il processo
                await this.autoclaim(schedaControlloCreata.idSchedaControllo);
                return schedaControlloCreata
            } catch (error) {
                console.error("modale 1B ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                throw error;
            }
        },

        async avviaProcessoControllo(idSchedaControllo, codiceProgetto){
            //i controlli 1B girano sullo lo stesso processo con 1A, ControlliGatewayController.java sul BE effettua la conversione URL->processo da avviare
            const url = endpoints.startControlloAmministrativo1BProcess;
            const body = {
                codiceProgetto: codiceProgetto,
                idScheda: idSchedaControllo
            }
            // POST per avvio processo controllo
            return this.$post(url, body)
        },

        // POST per autoclaim
        async autoclaim(idSchedaControllo) {
            const url = endpoints.claimControllo+idSchedaControllo;
            return this.$post(url)
        },

        closeModal() {
            this.$emit('closeModalCheckList');
        },

        getControlliPerProgetto(){
            const codiceProgetto = this.codiceProgetto;
            // get controlli per progetto
            let url = endpoints.getControlliAmministrativi(codiceProgetto);
            return this.$get(url)
            .then(
                result => {
                    if (result.length>0){
                        console.log("Trovati "+result.length+" controlli per il progetto "+codiceProgetto, result);
                        this.controlli = result;
                        this.trovaContrattiControllabili();
                    } else {
                        console.log("Nessun controllo trovato per il progetto "+codiceProgetto);
                        //warning in giallo nella modale
                        this.hasControlli1A=false;
                        this.loadComplete = true;
                    }
                }
            ).catch(
                error => {
                    console.error('Errore store dispatch: ', error)
                    notify.error(notify.strings.error, notify.strings.errorLoadControlli);
                }
            );
        },

        trovaContrattiControllabili(){
            //trova controlli 1A esitati
            const controlli1Acompletati = this.controlli.filter((c) => {
                return (c.sottoTipoControllo === '1A' && c.stato.includes('sitato')) // esitato
            })
            // filtra la lista di controlli per trovare quelli 1B, poi restituisce la lista dei cronoprogItemId
            const contrattiControllatiList = this.controlli.filter((c) => { return c.sottoTipoControllo === '1B' }).map(c => {
                return c.cronoprogItemId;
            })
            // prendo dai controlli completati 1A gli id interni di cronoprog
            controlli1Acompletati.forEach(controllo1A => {
                // cerco i contratti che hanno in rel l'id cronoprog della procedura con controllo completato.
                // controllo1A.cronoprogItemId e' l'id cronoprog interno della procedura a cui fanno riferimento.
                //console.debug("controllo1A.cronoprogItemId", controllo1A.cronoprogItemId)
                //console.debug("Object.values(this.cronoprog.content.contratti)", Object.values(this.cronoprog.content.contratti))
                let found = false;
                for (const contratto of Object.values(this.cronoprog.content.contratti)){
                    if(contratto.rel.procedure && contratto.rel.procedure.indexOf(controllo1A.cronoprogItemId) !== -1){
                        // il contratto è disabilitato se esiste già un controllo
                        const isDisabled = contrattiControllatiList.indexOf(contratto.id) !== -1;
                        // aggiungo ogni contratto alla lista che visualizzero'
                        let text = 'procedura: ' + controllo1A.idAttivita + '.' + controllo1A.idProceduraGara;
                        text = text.concat(' ' + controllo1A.oggettoProcedura);
                        text = text.concat(' - contratto: ' + contratto.content.riferimentoContratto);
                        // console.log(contratto);
                        this.procOptions.push({
                            value: {
                                id: contratto.id,
                                controllo1A: controllo1A,
                                contratto: contratto,
                                procedura: this.cronoprog.content.procedure[controllo1A.cronoprogItemId]
                            },
                            text: text,
                            disabled: isDisabled,
                        });
                        found = true;
                        break;
                    }
                }
                // se non è stato trovato un contratto, viene generato uno con dati minimali. I dati del contratto
                // verranno riempiti durante il controllo
                if(!found) {
                    let text = 'procedura: ' + controllo1A.idAttivita + '.' + controllo1A.idProceduraGara;
                    text = text.concat(' ' + controllo1A.oggettoProcedura);
                    text = text.concat(' - nuovo contratto');
                    const newId = tool.genId();
                    const proceduraControllata = this.cronoprog.content.procedure[controllo1A.cronoprogItemId];
                    let rel = proceduraControllata.rel;
                    rel[controllo1A.cronoprogRootId] = [controllo1A.cronoprogItemId];
                    this.procOptions.push({
                        value: {
                            id: newId,
                            controllo1A: controllo1A,
                            contratto: {
                                id: newId,
                                rel: rel,
                                content: {
                                    dataEntryConfiguration: {}
                                }
                            },
                            procedura: proceduraControllata
                        },
                        text: text,
                        disabled: false,
                    });
                }
            })
            this.loadComplete = true;
            //console.debug(">>> this.procOptions", this.procOptions)
        },

    },
    mounted() {
        this.codiceProgetto = this.$route.params.codiceProgetto;
        let url = endpoints.cronoprogProgetti(this.codiceProgetto);
        Promise.all([
            this.$get(url),
            this.$get(endpoints.retrieveTabellone("procedure_checklist"))
        ]).then((results) => {
            this.cronoprog = results[0];
            this.tipologieChecklist = results[1];
            //console.debug(">>> this.cronoprog", this.cronoprog)
            this.getControlliPerProgetto();
        })
        .catch((error) => {
            console.error("ERRORE: ", error.message);
            notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
        });
    },
    watch: {
      // debug only
      /*
      selectedContract: function() {
        console.log("selectedContract", this.selectedContract);
      }
      */
    }

}
</script>