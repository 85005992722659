<template>
  <div>
    <div v-if="!this.loadComplete" class="table-title text-center">Progetto in Caricamento...</div>
    <div v-else>
      <div class="table-title">{{headingProject}}</div>
      <b-table-lite
        thead-class="head"
        :items="fillData.rows"
        :fields="fillData.header"
        v-if="setVisibilityTable" />
      <div>
        <b-card no-body>
          <b-tabs card nav-class="tabs" content-class="content">
            <ColorLegend/>
            <b-tab
              v-if="loadComplete"
              no-body
              title="Generale"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection('Generale')">
              <GenericComponent
                :idTablePdf="conf_export_pdf['generale'].id_table_pdf"
                :name="conf_export_pdf['generale'].name"
                :tabName="tabSelection"
                :rowIds="generaleIds"/>
            </b-tab>
            <b-tab
              v-if="loadComplete"
              no-body
              title="Beneficiario"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection('Beneficiario')">
              <GenericComponent
                :idTablePdf="conf_export_pdf['beneficiario'].id_table_pdf"
                :name="conf_export_pdf['beneficiario'].name"
                :tabName="tabSelection"
                :rowIds="beneficiarioIds" />
            </b-tab>
            <b-tab
              v-if="loadComplete"
              no-body
              title="Progetto"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection('Progetto')">
              <GenericComponent
                :idTablePdf="conf_export_pdf['progetto'].id_table_pdf"
                :name="conf_export_pdf['progetto'].name"
                :wakeUp="wakeUp_tab_Progetto"
                :tabName="tabSelection"
                :rowIds="progettoIds" />
            </b-tab>
            <b-tab
              v-if="loadComplete"
              no-body
              title="Contenuti"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection('Contenuti')">
              <GenericComponent
                :idTablePdf="conf_export_pdf['contenuti'].id_table_pdf"
                :name="conf_export_pdf['contenuti'].name"
                :tabName="tabSelection" :rowIds="contenutiIds" />
            </b-tab>
            <b-tab
              v-if="loadComplete"
              no-body
              title="Attività di progetto"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection('attivita')">
              <AttivitaDiProgetto
                :keyEntityTab="collectionKeyTabs('attivita')"
                @refreshTabCronoProgramma="wakeUp_tab_CronoProgramma = !wakeUp_tab_CronoProgramma"
                @refreshTabBudget="wakeUp_tab_Budget = !wakeUp_tab_Budget"
                name="Attività di progetto"/>
            </b-tab>
            <b-tab
              v-if="loadComplete"
              no-body
              title="Complementarietà e sostenibilità"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection('complementarieta')">
              <ComplementarietaSostenibilita :keyEntityTab="collectionKeyTabs('complementarieta')" name="Complementarietà e sostenibilità" />
            </b-tab>
            <b-tab
              v-if="loadComplete"
              no-body
              title="Governance"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection('Governance')">
              <Governance 
                :keyEntityTab="collectionKeyTabs('governance')" 
                :tabName="tabSelection"
                :riferimento = this.riferimento
                name="Governance" />
            </b-tab>
            <b-tab
              v-if="loadComplete"
              no-body
              title="Budget"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection('budget')">
              <Budget
                :wakeUp="wakeUp_tab_Budget"
                @crudCostsInBudget="wakeUp_tab_Progetto = !wakeUp_tab_Progetto"
                :keyEntityTab="collectionKeyTabs('budget')"
                name="Budget" :tabName="tabSelection" />
            </b-tab>
            <b-tab
              v-if="loadComplete"
              no-body
              title="Cronoprogramma"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection('cronoprogramma')">
              <CronoProgramma
                :idTablePdf="conf_export_pdf['cronoprogramma'].id_table_pdf"
                :name="conf_export_pdf['cronoprogramma'].name"
                :wakeUp="wakeUp_tab_CronoProgramma"
                :tabName="tabSelection"
              />
            </b-tab>
            <b-tab
              v-if="loadComplete"
              no-body
              title="Invio"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection('invio')">
              <Invio
                :tabName="tabSelection"
                :bando="bando"
                :idsToCheck=idsToCheck
                :idSchemaCronoprog="idSchemaCronoprog"
                :riferimento = this.riferimento
                @buildPdf="generateReport"/>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ColorLegend from "@/components/legend.vue"
import GenericComponent from "./GenericComponent.vue"
import Budget from './budget.vue'
import AttivitaDiProgetto from "./attivitaDiProgetto.vue"
import ComplementarietaSostenibilita from './complementarietaSostenibilita.vue'
import Governance from './governance.vue'
import Invio from "./invio.vue"
import { mapGetters } from "vuex"
import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue"
import CronoProgramma from "./cronoProgramma.vue"
import helper_pdf from "@/helpers/exportPdf.js"

export default {
  name:"schedaProgetto",
  components:{
    ColorLegend,
    GenericComponent,
    AttivitaDiProgetto,
    ComplementarietaSostenibilita,
    Governance,
    Budget,
    CronoProgramma,
    Invio
  },
  props: [ 'type' ],
  data() {
    return {
      //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
      riferimento : {
          idOggettoRif: "",
          tipoOggettoRif: "Proposta Scheda Progetto", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
          idBando: "",
          tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
          idProgetto: "",
          codiceProgetto: ""
      },
      generaleIds:[
        "['progetto']['beneficiario']['beneficiario']['denominazione']",
        "['progetto']['titoloProgetto']",
        "['progetto']['descrizioneProgetto']",
        "['progetto']['riservato']",
        "['progetto']['strumentoFinanziario']",
        "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['obiettivoSpecifico']['codice']",
        "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['codiceComposito']",
        "['progetto']['modalitaDiAccesso']",
        "['dataFirmaConvenzione']",
      ],
      beneficiarioIds:[
        "['progetto']['beneficiario']['beneficiario']['denominazione']",
        "['progetto']['beneficiario']['beneficiario']['indirizzo']",
        "['progetto']['referenteProgetto']['nome']",
        "['progetto']['referenteProgetto']['qualifica']",
        "['progetto']['responsabileOperativoProgetto']['nome']",
        "['progetto']['responsabileOperativoProgetto']['qualifica']",
        "['progetto']['beneficiario']['telefono']",
        "['progetto']['beneficiario']['indirizzoPEC']",
        "['progetto']['beneficiario']['email']",
      ],
      progettoIds:[
        "['progetto']['titoloProgetto']",
        // 26-08-21: eliminato budget massimo
        // "['budgetMassimo']",
        "['progetto']['costoProgetto']",
        "['progetto']['costoProgettoIVA']",
        "['progetto']['durataProgettoMesi']",
        "['progetto']['indirizzoConservazioneDocumentale']",
        // "['progetto']['ulterioriFontiFinanziamento']", il benef inserisce direttamente le fonti se esistono
        "['progetto']['ulterioriFontiFinanziamento']",
        "['progetto']['motivazioneAssegnazioneDiretta']",
        "['progetto']['procedureDerogatorie']",
        "['progetto']['dataInizio']",
        "['progetto']['dataConclusione']",
      ],
      contenutiIds:[
        "['analisiFabbisogno']",
        "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['obiettivoSpecifico']['codice']",
        "['progetto']['obiettivoProgetto']['motivazioneOS']",
        "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['codiceComposito']",
        "['progetto']['obiettivoProgetto']['motivazioneON']"
      ],
      idsToCheck:{
        iter2:{
          rootId: 'procedure',
          dinamicIds:[
            "['procedure'][*]['content']['idProceduraGara']",
            "['procedure'][*]['content']['tipologiaProcedura']",
            "['procedure'][*]['content']['oggetto']"
          ]
        }
      },
      codiceProgetto: null,
      schedaProgetto: {},
      tabledata: {
        header: [
          { key: "Tipologia" },
          { key: "TitoloBando", label: "Titolo Bando" },
          { key: "StrumentoFinanziario", label: "Strumento Finanziario" },
          { key: "ObiettivoNazionale", label: "Obiettivo Nazionale" },
          { key: "ObiettivoSpecifico", label: "Obiettivo Specifico" },
        ],
        rows: []
      },
      loadComplete: false,
      tabSelection: "Generale",
      bando: null,
      conf_export_pdf: {
        "generale": {
          id_table_pdf: 'generale_progetto',
          name: "Generale",
          title_page_pdf: 'Generale',
          nameCollapse: '',
        },
        "beneficiario": {
          id_table_pdf: 'beneficiario_progetto',
          name: "Beneficiario",
          title_page_pdf: 'Beneficiario',
          nameCollapse: '',
        },
        "progetto": {
          id_table_pdf: 'progetto_progetto',
          name: "Progetto",
          title_page_pdf: 'Progetto',
          nameCollapse: '',
        },
        "contenuti": {
          id_table_pdf: 'contenuti_progetto',
          name: "Contenuti",
          title_page_pdf: 'Contenuti',
          nameCollapse: '',
        },
        // -------------------- SEZIONI WRAP-COLLAPSES --------------------------------
        // Relative ai Tabs: Attività di Progetto, Complementarietà e sostenibilità, Governance)
        // Si è deciso di uscire la configurazione del WrapCollapses e includerla qui per non avere duplicati ed ottimizzare l'exportPdf
        
        // TAB: Attività di progetto
        "attivita_collapse1": {
          id_table_pdf: 'attivita_collapse1',
          title_page_pdf: 'Attività di progetto',
          nameCollapse: 'Descrizione delle Attività di Progetto',

          title: "Descrizione delle Attività di Progetto",
          name: "DescrizioneAttività",
          nameComponent: 'CollapseAttivitaProgetto',
          dataComponent: {
            dinamicIds:[
              "['progetto']['cronoProgramma'][*]['idAttivita']",
              // "['progetto']['cronoProgramma'][*]['progressivo']",
              "['progetto']['cronoProgramma'][*]['titoloAttivita']",
              "['progetto']['cronoProgramma'][*]['descrizioneAttivita']"
            ],
          }
        },
        "attivita_collapse2": {
          id_table_pdf: 'attivita_collapse2',
          title_page_pdf: 'Attività di progetto',
          nameCollapse: 'Dettaglio della fornitura e/o dei lavori',

          title: 'Dettaglio della fornitura e/o dei lavori',
          name: "Forniture",
          nameComponent: 'VerticalComponent1',
          dataComponent: {
            dinamicIds: [
              "['progetto']['cronoProgramma'][*]['forniture'][*]['idAttivita']",
              "['progetto']['cronoProgramma'][*]['forniture'][*]['tipologia']",
              "['progetto']['cronoProgramma'][*]['forniture'][*]['oggettoFornitura']",
              "['progetto']['cronoProgramma'][*]['forniture'][*]['quantita']",
              "['progetto']['cronoProgramma'][*]['forniture'][*]['localizzazione']['luogo']"
            ]
          }
        },
        "attivita_collapse3": {
          id_table_pdf: 'attivita_collapse3',
          title_page_pdf: 'Attività di progetto',
          nameCollapse: "Ricorso all'utilizzo misto",

          title: "Ricorso all'utilizzo misto",
          name: "UtilizzoMisto",
          tabName: "Attività di progetto",
          nameComponent: 'GenericComponent',
          dataComponent: { 
            dinamicIds: [
              "['progetto']['utilizzoMisto']"
            ],
            inputs: {}
          }
        },
        "attivita_collapse4": {
          id_table_pdf: 'attivita_collapse4',
          title_page_pdf: 'Attività di progetto',
          nameCollapse: "Descrizione dell'iter amministrativo",
          details: true,
          title: "Descrizione dell'iter amministrativo",
          name: "IterAmministrativo",
          nameComponent: 'iter2',
          dataComponent: {
            actions: ['view', 'edit', 'add'],
            dinamicIds: [
              "['attivita'][*]['content']['idAttivita']",
              "['procedure'][*]['content']['idProceduraGara']",
              "['procedure'][*]['content']['tipologiaProcedura']",
              "['procedure'][*]['content']['oggetto']",
              "['procedure'][*]['content']['descrizioneBreve']",
              "['procedure'][*]['content']['tipoIterAmministrativo']",
              "['procedure'][*]['content']['rifNormativo']",
              "['procedure'][*]['content']['baseAsta']",
              "['procedure'][*]['content']['costiManutenzioneIT']",
              "['procedure'][*]['content']['stazioneAppaltante']",
              "['procedure'][*]['content']['identificativoConvenzione']",
              "['procedure'][*]['content']['tipologiaAttivita']",
              "['procedure'][*]['content']['prestazione']",
              "['procedure'][*]['content']['enteAccreditato']",
              "['procedure'][*]['content']['note']"
            ],
            rootId: 'procedure',
            tabelloneType: 'cronoprog_progetto',
            tabName:'Attività di progetto',
            idScheda: this.$route.params.idProgetto
          }
        },
      "attivita_collapse5": {
          id_table_pdf: 'attivita_collapse5',
          title_page_pdf: 'Attività di progetto',
          nameCollapse: "Descrizione dei risultati attesi",

          title: "Descrizione dei risultati attesi",
          name: "RisultatiAttesi",
          tabName: "Attività di progetto",
          nameComponent: 'GenericComponent',
          dataComponent: { 
            dinamicIds:[
              "['descrizioneRisultatiAttesi']"
            ],
          }
        },
       "attivita_collapseIndicatori": {
          id_table_pdf: 'attivita_collapseIndicatori',
          title_page_pdf: 'Attività di progetto',
          nameCollapse: "Indicatori",
          detailsIndicatori: true,
          title: "Indicatori",
          name: "Indicatori",
          nameComponent: 'Indicatori',
          dataComponent: { 
            dinamicIds: [
              "['progetto']['indicatoriObiettivo'][*]['type']", // WARNING il tipo deve essere il primo elemento!
              "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']",
              "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['descrizione']",
              "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['unitaDiMisura']",
              "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['valorePartenza']",
              "['progetto']['indicatoriObiettivo'][*]['valoreAtteso']",
              "['progetto']['indicatoriObiettivo'][*]['fonteDelDato']",
              "['progetto']['indicatoriObiettivo'][*]['modalitaDiCalcolo']",
              "['progetto']['indicatoriObiettivo'][*]['note']",
            ],
          }
        },

        // TAB: Complementarietà e sostenibilità
        "complementarieta_collapse1": {
          id_table_pdf: 'complementarieta_collapse1',
          title_page_pdf: 'Complementarietà e sostenibilità',
          nameCollapse: 'Esperienze pregresse relative alla gestione dei Fondi UE (max 5 elementi)',

          title: 'Esperienze pregresse relative alla gestione dei Fondi UE (max 5 elementi)',
          nameComponent: 'VerticalComponent1',
          name: "EsperienzePregresse",
          dataComponent: {
              inputs: {}, 
              dinamicIds:[
                    "['esperienzePregresse'][*]['inizioSvolgimento']",
                    "['esperienzePregresse'][*]['fineSvolgimento']",
                    "['esperienzePregresse'][*]['oggetto']",
                    "['esperienzePregresse'][*]['importo']",
                    "['esperienzePregresse'][*]['fondoProgramma']",
                    "['esperienzePregresse'][*]['descrizioneEsperienza']"
                ],
            }
        },
        "complementarieta_collapse2": {
          id_table_pdf: 'complementarieta_collapse2',
          title_page_pdf: 'Complementarietà e sostenibilità',
          nameCollapse: 'Complementarietà con progettualità finanziate da altri Strumenti Comunitari e Nazionali',

          title: 'Complementarietà con progettualità finanziate da altri Strumenti Comunitari e Nazionali',
          nameComponent: 'GenericComponent',
          tabName: "Complementarietà e sostenibilità",
          name: "Complementarietà",
          dataComponent: { 
              inputs: {},
              dinamicIds:[
                "['complementarietaAltriStrumenti']",
              ],
            }
        },
        "complementarieta_collapse3": {
          id_table_pdf: 'complementarieta_collapse3',
          title_page_pdf: 'Complementarietà e sostenibilità',
          nameCollapse: 'Sostenibilità futura del progetto',

          title: 'Sostenibilità futura del progetto',
          nameComponent: 'GenericComponent',
          tabName: "Complementarietà e sostenibilità",
          name: "Sostenibilità",
          dataComponent: { 
              inputs: {},
              dinamicIds:[
                "['sostenibilitaFutura']",
              ],
            }
        },

        // TAB: Governance
        "governance_collapse1": {
          id_table_pdf: 'governance_collapse1',
          title_page_pdf: 'Governance',
          nameCollapse: "Descrizione dell'organizzazione",

          title: "Descrizione dell'organizzazione",
          nameComponent: 'GenericComponent',
          tabName: "Governance",
          name: "Descrizione organizzazione",
          dataComponent: {
            inputs: {},
            dinamicIds:[
              "['governanceDescrizione']",
            ]
          }
        },
        "governance_collapse2": {
          id_table_pdf: 'governance_collapse2',
          title_page_pdf: 'Governance',
          nameCollapse: "Principali soggetti coinvolti",

          title: 'Principali soggetti coinvolti',
          nameComponent: 'VerticalComponent1',
          name: "Soggetti coinvolti",
          dataComponent: { 
            inputs: {},
            dinamicIds:[
              "['soggettiCoinvolti'][*]['nome']",
              "['soggettiCoinvolti'][*]['qualifica']",
              "['soggettiCoinvolti'][*]['funzioniAssegnate']"
            ]
          }
        },
        "governance_collapse3": {
          id_table_pdf: 'governance_fileUpload',
          title_page_pdf: 'Governance',
          nameCollapse: "Organigramma del Beneficiario",

          title: 'Organigramma del Beneficiario',
          nameComponent: 'OrganigrammaUpload',
          name: "Organigramma",
          dataComponent: {
            inputs: {},
            dinamicIds:[
              "['soggettiCoinvolti'][*]['nome']",
              "['soggettiCoinvolti'][*]['qualifica']",
              "['soggettiCoinvolti'][*]['funzioniAssegnate']"
            ]
          }
        },

        // TAB: Budget
        "budget_collapse1": {
          id_table_pdf: 'budget_collapse1',
          id_total_cost_budget_pdf: 'total_budget_collapse1',
          title_page_pdf: 'Budget',
          nameCollapse: "Costi del personale",

          title: "Costi del personale",
          nameComponent: 'VerticalComponent1',
          name: "CostiPersonale",
          notifyUpdate: true,
          dataComponent: {
              dinamicIds:[
                "['progetto']['cronoProgramma'][*]['costiPersonale'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiPersonale'][*]['dettaglio']",
                "['progetto']['cronoProgramma'][*]['costiPersonale'][*]['unitaDiMisura']",
                "['progetto']['cronoProgramma'][*]['costiPersonale'][*]['quantita']",
                "['progetto']['cronoProgramma'][*]['costiPersonale'][*]['costoUnitario']",
                "['progetto']['cronoProgramma'][*]['costiPersonale'][*]['totale']"
              ],   
              idTotale:"['progetto']['cronoProgramma'][*]['costiPersonale'][*]['totale']",   
            }
        },
        "budget_collapse2": {
          id_table_pdf: 'budget_collapse2',
          id_total_cost_budget_pdf: 'total_budget_collapse2',
          title_page_pdf: 'Budget',
          nameCollapse: "Costi di viaggio",

          title: 'Costi di viaggio',
          nameComponent: 'VerticalComponent1',
          name: "CostiViaggio",
          notifyUpdate: true,
          dataComponent: {
              dinamicIds:[
                 "['progetto']['cronoProgramma'][*]['costiViaggio'][*]['idAttivita']",
                 "['progetto']['cronoProgramma'][*]['costiViaggio'][*]['dettaglio']",
                 "['progetto']['cronoProgramma'][*]['costiViaggio'][*]['unitaDiMisura']",
                 "['progetto']['cronoProgramma'][*]['costiViaggio'][*]['quantita']",
                 "['progetto']['cronoProgramma'][*]['costiViaggio'][*]['costoUnitario']",
                 "['progetto']['cronoProgramma'][*]['costiViaggio'][*]['totale']"
              ],  
               idTotale:"['progetto']['cronoProgramma'][*]['costiViaggio'][*]['totale']",  
          }
        },
        "budget_collapse3": {
          id_table_pdf: 'budget_collapse3',
          id_total_cost_budget_pdf: 'total_budget_collapse3',
          title_page_pdf: 'Budget',
          nameCollapse: "Servizi, fornitura e lavori",

          title: 'Servizi, fornitura e lavori',
          nameComponent: 'VerticalComponent1',
          name: "CostiServizi",
          notifyUpdate: true,
          dataComponent: {
              dinamicIds:[
                "['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]['dettaglio']",
                "['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]['unitaDiMisura']",
                "['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]['quantita']",
                "['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]['costoUnitario']",
                "['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]['totale']"
              ],  
              idTotale:"['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]['totale']",
          }
        },
        "budget_collapse4": {
          id_table_pdf: 'budget_collapse4',
          id_total_cost_budget_pdf: 'total_budget_collapse4',
          title_page_pdf: 'Budget',
          nameCollapse: "Spese di gara",

          title: 'Spese di gara',
          nameComponent: 'VerticalComponent1',
          name: "SpeseGara",
          notifyUpdate: true,
          dataComponent: {
              dinamicIds:[
                "['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]['dettaglio']",
                "['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]['unitaDiMisura']",
                "['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]['quantita']",
                "['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]['costoUnitario']",
                "['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]['totale']"
              ],   
              idTotale:"['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]['totale']", 
          }
        },
        "budget_collapse5": {
          id_table_pdf: 'budget_collapse5',
          id_total_cost_budget_pdf: 'total_budget_collapse5',
          title_page_pdf: 'Budget',
          nameCollapse: "Spese di informazione e pubblicità",

          title: 'Spese di informazione e pubblicità',
          nameComponent: 'VerticalComponent1',
          name: "SpeseInformazione",
          notifyUpdate: true,
          dataComponent: { 
              dinamicIds:[
                "['progetto']['cronoProgramma'][*]['costiInformazione'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiInformazione'][*]['dettaglio']",
                "['progetto']['cronoProgramma'][*]['costiInformazione'][*]['unitaDiMisura']",
                "['progetto']['cronoProgramma'][*]['costiInformazione'][*]['quantita']",
                "['progetto']['cronoProgramma'][*]['costiInformazione'][*]['costoUnitario']",
                "['progetto']['cronoProgramma'][*]['costiInformazione'][*]['totale']"
              ], 
              idTotale:"['progetto']['cronoProgramma'][*]['costiInformazione'][*]['totale']",   
          }
        },
        "budget_collapse6": {
          id_table_pdf: 'budget_collapse6',
          id_total_cost_budget_pdf: 'total_budget_collapse6',
          title_page_pdf: 'Budget',
          nameCollapse: "Costi di manutenzione",

          title: 'Costi di manutenzione',
          nameComponent: 'VerticalComponent1',
          name: "CostiManutenzione",
          notifyUpdate: true,
          dataComponent: {
              dinamicIds:[
                "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]['dettaglio']",
                "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]['unitaDiMisura']",
                "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]['quantita']",
                "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]['costoUnitario']",
                "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]['totale']"
              ],   
              idTotale:"['progetto']['cronoProgramma'][*]['costiManutenzione'][*]['totale']", 
          }
        },
        "budget_collapse7": {
          id_table_pdf: 'budget_collapse7',
          id_total_cost_budget_pdf: 'total_budget_collapse7',
          title_page_pdf: 'Budget',
          nameCollapse: "Supporto tecnico specialistico",

          title: 'Supporto tecnico specialistico',
          nameComponent: 'VerticalComponent1',
          name: "CostiSupporto",
          notifyUpdate: true,
          dataComponent: {
              dinamicIds:[
                "['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]['dettaglio']",
                "['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]['unitaDiMisura']",
                "['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]['quantita']",
                "['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]['costoUnitario']",
                "['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]['totale']"
              ], 
              idTotale:"['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]['totale']",   
          }
        },
        "budget_collapse8": {
          id_table_pdf: 'budget_collapse8',
          id_total_cost_budget_pdf: 'total_budget_collapse8',
          title_page_pdf: 'Budget',
          nameCollapse: "Costi indiretti",

          title: 'Costi indiretti',
          nameComponent: 'VerticalComponent1',
          name: "CostiIndiretti",
          notifyUpdate: true,
          dataComponent: {
              dinamicIds:[
                "['costiIndiretti'][*]['importo']",
                "['costiIndiretti'][*]['metodoDiCalcolo']",
              ],
              idTotale: "['costiIndiretti'][*]['importo']",
          }
        },
        "budget_collapse9": {
          id_table_pdf: 'budget_collapse9',
          title_page_pdf: 'Budget',
          nameCollapse: "Budget per obiettivi",

          title: 'Budget per obiettivi',
          nameComponent: 'BudgetPerObiettivi',
          name: "BudgetPerObiettivi",
          dataComponent: {
            dinamicIds:[
              // non usati, ma l'array non può essere vuoto
              "['budgetPerObiettivi'][*]['obiettivoNazionale']['codice']",
              "['budgetPerObiettivi'][*]['obiettivoNazionale']['obiettivoSpecifico']['codice']",
              "['budgetPerObiettivi'][*]['costi'][*]['tipoSpesa']",
              "['budgetPerObiettivi'][*]['costi'][*]['importo']"
            ],
          }
        },
        "cronoprogramma": {
          id_table_pdf: 'cronoprogramma_progetto',
          name: "Cronoprogramma",
          title_page_pdf: 'Cronoprogramma',
          nameCollapse: '',
          double_details: true,
          isProgetto: true
        },
        "invio_mandatory": {
            id_table_pdf: 'progetto_mandatory',
            name: '',
            title_page_pdf: 'Invio',
            nameCollapse: 'Campi obbligatori non valorizzati',
        },
        "invio_mod_sostanziali": {
            id_table_pdf: 'progetto_mod_sostanziali',
            name: '',
            title_page_pdf: 'Invio',
            nameCollapse: 'Potenziali Modifiche Sostanziali',
        },
        "invio_mod_no_sostanziali": {
            id_table_pdf: 'progetto_mod_no_sostanziali',
            name: '',
            title_page_pdf: 'Invio',
            nameCollapse: 'Modifiche',
        },
        "invio_note": {
          id_table_pdf: 'note_progetto',
          name: '',
          title_page_pdf: 'Invio',
          nameCollapse: 'Elenco Note',
        },
        "invio_note_riservate": {
          id_table_pdf: 'note_riservate_progetto',
          name: '',
          title_page_pdf: 'Invio',
          nameCollapse: 'Elenco Note Interne',
        },
        "invio_fileUpload": {
          id_table_pdf: 'invio_fileUpload',
          name: '',
          title_page_pdf: 'Invio',
          nameCollapse: 'Allegati'
        },
        "invio_radio_button_mod_sostanziali": {
            id_field: "['possibiliModificheSostanziali']",
            name: "Invio",
            title_page_pdf: 'Dettagli Invio',
            conf_field: { addPage: true, mapping: 'SiNo' },
            descRadio: 'Sono presenti modifiche sostanziali: '
          }
      },
      wakeUp_tab_Budget: false,
      wakeUp_tab_Progetto: false,
      wakeUp_tab_CronoProgramma: false,
      idSchemaCronoprog: null

    }
  },
  computed: {
    ...mapGetters({
      getSchedaProgetto: "progetto/getSchedaProgetto",
      getSchedaComplete: "progetto/getScheda",
      getSchedaBando: "announcements/getSchedaBando",
      getTabellone: "configuration/getTabellone",
      getSchedaCronoprog: 'cronoprog/getSchedaComplete',
    }),
    headingProject() {
      let heading = this.$route.params.idProgetto;
      if(this.codiceProgetto)
        heading = this.codiceProgetto;
      else if (this.schedaProgetto.progetto.titoloProgetto)
        heading = '"' + this.schedaProgetto.progetto.titoloProgetto + '"';
      
        
      return 'Scheda Progetto ' + heading;
    },
    setVisibilityTable() {
      return this.schedaProgetto
    },
    fillData() {
      this.setTabellaBando()
      return this.tabledata
    }
  },
  created() {
    const idProgetto = this.$route.params.idProgetto;
    if(!idProgetto) {
      console.error('schedaprogetto: idProgetto non definito!?')
      return;
    }
    // 1. carico indicatori e allegati
    Promise.all([
      this.$store.dispatch("configuration/setTabelloneVersioned", {type: 'allegati'}),
      this.$store.dispatch("progetto/retrieveIndicatori")
    ]).then(
      () => {
        // 2. carico scheda progetto, aggiungendo gli allegati
        const payProj = {
          idSchedaProgetto: idProgetto,
          versione: this.$route.params.versione,
          type: this.type,
          tabelloneAllegati: this.getTabellone('allegati')
        }
        this.$store.dispatch("progetto/setSchedaProgetto", payProj)
        .then(() => {
            this.schedaProgetto = this.getSchedaProgetto({ idSchedaProgetto: idProgetto })
            this.codiceProgetto = this.schedaProgetto.progetto.codiceProgetto

            if (this.schedaProgetto.progetto.idProgetto){
              this.riferimento.idProgetto = this.schedaProgetto.progetto.idProgetto;
              this.riferimento.idOggettoRif = this.schedaProgetto.progetto.idProgetto;
              this.riferimento.idBando = this.schedaProgetto.idBando;
              this.riferimento.tipoBando = this.schedaProgetto.progetto.modalitaDiAccesso;
                  
              if (this.schedaProgetto.progetto.codiceProgetto){ //se esiste il codice progetto, lo stesso è in attuazione
                this.riferimento.tipoOggettoRif = "Scheda Progetto in Attuazione";
                this.riferimento.codiceProgetto = this.schedaProgetto.progetto.codiceProgetto;
              }
            }
            // 3. carico tutti i tabelloni ed eventualmente le note riservate
            // inizializzo id e versione del tabellone in modo che possa funzionare
            // anche per schede vecchio tipo che non hanno il versionamento esplicito
            let myIdSchema = 'progetto';
            let mySchemaVersione = null;
            if (this.schedaProgetto.idSchema) {
              myIdSchema = this.schedaProgetto.idSchema;
            } else {
              console.warn("idSchema non presente nella schedaProgetto versione: "+this.schedaProgetto.idVersione+ ", idProgetto:", idProgetto);
            }
            if (this.schedaProgetto.schemaVersione) {
              mySchemaVersione = this.schedaProgetto.schemaVersione;
            } else {
              console.warn("schemaVersione non presente nella schedaProgetto versione: "+this.schedaProgetto.idVersione+ ", idProgetto:", idProgetto);
            }

            //preparo il payload con la versione desiderata (dovrebbe sempre esistere in ogni scheda creata con l'applicativo) 
            const payTabellone = {
              type: myIdSchema,
              version: mySchemaVersione
            }
            console.log("carico il tabellone versionato: ", payTabellone); 

            //carico il tabellone di cronoprog progetto dal modulo configuration nello store
            const payTabCrono = {
              type : 'cronoprog_progetto'
            };
            //cerco se esiste una versione specifica nella schedaProgetto
            if (this.schedaProgetto.idSchemaCronoprog && this.schedaProgetto.schemaVersioneCronoprog
                && this.schedaProgetto.schemaVersioneCronoprog != 0){
              payTabCrono.type = this.schedaProgetto.idSchemaCronoprog;            //sovrascrivo il valore di default, schema e versione dovrebbero essere settate contestualemnte
              payTabCrono.version = this.schedaProgetto.schemaVersioneCronoprog;
            }

            //memorizzo questa assunzione per passarla al tab invio, che salva e versiona il cronoprog
            this.idSchemaCronoprog = payTabCrono.type;
          
            //carico il cronoprog relativo nello store (i tab che ne avranno bisogno lo cercano li)
            //se la schedaProgetto ha già una cronoprogKey carico quella versione altrimenti prendo l'ultima versione disponibile
            const payCrono = {idProgetto: idProgetto, branch: 'progetto'};
            if (this.schedaProgetto.cronoprogKey){
              payCrono.cronoprogKey = this.schedaProgetto.cronoprogKey;
              console.log("trovata cronoprogKey:", this.schedaProgetto.cronoprogKey);
            }
            console.log("carico il cronoprog con:", payCrono);

            const promiseArray = [
              this.$store.dispatch('cronoprog/retrieveScheda', payCrono),
              this.$store.dispatch("configuration/setTabelloneVersioned", payTabCrono),
              this.$store.dispatch("configuration/setTabelloneVersioned", payTabellone),
              this.$store.dispatch("progetto/retrieveTipologieProcedure"),
              this.$store.dispatch("configuration/setTabelloneVersioned", {type: 'procedure_tipologie'}),
            ];
            // Gestione scheda NoteRiservate
            const userInfo = this.$getUserInfo();
            const group = userInfo.groups[0];
            if( group.includes("MINT")) {
              promiseArray.push(this.$store.dispatch("progetto/retrieveSchedaNoteRiservate", this.schedaProgetto));
            }

            Promise.all(promiseArray).then(results => {
              console.log('completato caricamento tabelloni e cronoprog');
              // WARNING: log per note riservate non disponibili (vecchi processi)
              if(promiseArray.length === 6 && results[5] === 'ND')
                console.log('skip note Riservate');
              
              this.loadComplete = true
            })
            .catch(error => {
              console.error('error while setSchedaProgetto', error.response)
              notify.error(notify.strings.error, notify.strings.errorLoadProgetto)
            })
          }).catch(
            error => {
              console.error('error mappa indicatori or allegati', error.response)
              notify.error(notify.strings.error, notify.strings.internalConfigurationError);
            }
          )

      })
      .catch(error => {
        console.error('error loading tabellone ', this.idSchemaCronoprog, error.response)
        notify.error(notify.strings.error, notify.strings.errorLoadProgetto)
      })

    
  }, 
  
  methods: {
    // Questo metodo raccoglie le chiavi di configurazione che servono a renderizzare
    // i vari componenti innestati che sono contenuti nel WrapCollapses
    collectionKeyTabs(nameTab) {
      let configWrapCollapses = []
      Object.keys(this.conf_export_pdf).forEach(key => {
        if(key.includes(nameTab)) {
          configWrapCollapses.push(this.conf_export_pdf[key])
        }
      })
      return configWrapCollapses
    },
    generateReport(optPdf) {
      this.generationPdf = true
      // GESTIONE OCCHI
      // CRONOPROGRAMMA ( LA SOURCE E' SCHEDA MONITORAGGIO )
      const tabelloneProgetto = this.getTabellone('progetto');
      const schedaProgetto = this.getSchedaProgetto({ idSchedaProgetto: this.$route.params.idProgetto });
      let source_cronoprogramma = {
          scheda: schedaProgetto,
          ids_dettaglio_fase: [
              "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']",
              "['progetto']['cronoProgramma'][*]['idAttivita']",
              "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['descrizione']",
              "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataInizio']",
              "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataFine']",
              "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['totaleSpese']"
              //"['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['stato']"
          ],
          ids_dettaglio_spese: [
              "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['spesa']",
              "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['da']",
              "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['a']"
          ],
          mappa : tabelloneProgetto
      }
      this.conf_export_pdf['cronoprogramma'].source = source_cronoprogramma;

      // configurazione occhio per iter amministrativo
      let dataComponent = this.conf_export_pdf['attivita_collapse4'].dataComponent;
      const tabelloneType = dataComponent.tabelloneType;
      const tabelloneFull = this.getTabellone(tabelloneType);
      const schedaCronoprog = this.getSchedaCronoprog({idScheda: this.$route.params.idProgetto}).scheda.content;
      // PROCEDURE E CONTRATTI ( LA SOURCE E' SCHEDA CRONOPROG )
      const source_procedure_contratti = {
          scheda: schedaCronoprog,
          ids_eyes: dataComponent,
          mappa: tabelloneFull,
      }
      this.conf_export_pdf['attivita_collapse4'].source = source_procedure_contratti;


      // configurazione occhio per indicatori
      dataComponent = this.conf_export_pdf['attivita_collapseIndicatori'].dataComponent;
      // PROCEDURE E CONTRATTI ( LA SOURCE E' SCHEDA CRONOPROG )
      const source_indicatori = {
          scheda: schedaProgetto,
          ids_eyes: dataComponent,
          mappa: tabelloneProgetto,
      }
      this.conf_export_pdf['attivita_collapseIndicatori'].source = source_indicatori;






      // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)
      // BUG #10677
      // Il radio button sulla presenza di Mod. Sostanziali è presente al fe solo se:
      // esiste una determinata azione tra tutte quelle possibili ( 'showSostanziali' )
      let actions = this.$projectGetActions(this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto}))
      if(actions.indexOf("showSostanziali") !== -1) {
        let source = {
          scheda: this.getSchedaProgetto({ idSchedaProgetto: this.$route.params.idProgetto })
        }
        this.conf_export_pdf['invio_radio_button_mod_sostanziali'].source = source
      } else delete this.conf_export_pdf['invio_radio_button_mod_sostanziali']
      // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)

      helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
      this.generationPdf = false
    },
    setTabellaBando() {
      let bando
      if(this.schedaProgetto) {
        const idBando = this.schedaProgetto.idBando
        if(idBando) {
          let url = endpoints.retrieveBando+idBando
            this.$get(url).then(
              (result) => {
                bando = result.schedaBando.bando
                if(bando != null) {
                  const rowHeader = {
                    Tipologia: bando.modalitaDiAccesso,
                    TitoloBando: bando.titoloBando,
                    StrumentoFinanziario: bando.strumentoFinanziario,
                    ObiettivoNazionale: bando.obiettivoNazionale,
                    ObiettivoSpecifico: bando.obiettivoSpecifico,
                  }
                  this.tabledata.rows.push(rowHeader)
                  this.bando = bando
                }
              },
              (error) => {
                console.log("errore",error)
              }
            )
        } else {
          let progetto = this.schedaProgetto.progetto
          // Elimino dalla tabella Riassuntiva le info legate al Bando e inserisco il codice del Progetto
          this.tabledata.header.splice(1,1)
          this.tabledata.header.unshift({key: 'CodiceProgetto', label: 'Codice Progetto'})
          
          let allObiettiviSpecifici = progetto.obiettivoProgetto.obiettivoNazionale.map(obiettivoNaz => obiettivoNaz.obiettivoSpecifico.codice)
          const rowHeader = {
            CodiceProgetto: progetto.codiceProgetto,
            Tipologia: progetto.modalitaDiAccesso,
            StrumentoFinanziario: progetto.strumentoFinanziario,
            ObiettivoNazionale: progetto.obiettivoProgetto.obiettivoNazionale.map(obiettivoNaz => obiettivoNaz.codiceComposito).toString(),
            ObiettivoSpecifico: [...new Set(allObiettiviSpecifici)].toString(),
          }
          this.tabledata.rows.push(rowHeader)
        }
      }
    },
    setTabSelection(name) {
      console.log("Click sul tab =", name)
      this.tabSelection = name
    }
  }
}
</script>
