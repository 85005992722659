<template>
    <div id="controlPagamenti" v-show="tabName === componentConfig.name">
        <VerticalComponent2 v-if="loadComplete"
            :idTablePdf="idTablePdf"
            :idScheda="idScheda"
            :storeModule="storeModule"
            :tabelloneType="tabelloneType"
            :componentConfig="componentConfig"
            :optionalObj="optionalObj"
            @refreshValues="refreshData()"/>
    </div>
</template>
<script>
    import VerticalComponent2 from "@/components/verticalComponent2.vue"
    export default {
        name: "controlPagamenti",
        components: {
            VerticalComponent2
        },
        props: {
            idTablePdf: { type: String, default: '' },
            idScheda: { type: String, default: () => '' },
            actions:  { type: Array, default: () => [] },
            tabName: String,
            rootId: String,
            optionalObj: Object,
            tableIds: { type: Array, default: () => [] },
            dinamicIds: Array,  default: function() { return [] }
        },
        data() {
            return {
                loadComplete: false,
                tabelloneType: 'cronoprog_controlli',
                storeModule: 'cronoprog',
                componentConfig: {
                    title: "Pagamenti",
                    name: "controlPagamenti",
                    notifyUpdate: true,
                    defaultActions: ['add', 'view', 'edit', 'delete'],
                    allowedActions: [],
                    
                    relIds: {
                        "['contratti'][*]['content']['riferimentoContratto']": {
                            type: 'contratti',
                            canBeEmpty: true,
                            allowModalEdit: true
                        }
                    }
                }
            }
        },
        mounted() {
            if(this.idScheda !== '') {  //"oggetto" : "n° 1 RAMAN BIO",
                this.componentConfig.allowedActions = this.componentConfig.defaultActions.filter(item => this.actions.includes(item))
                this.componentConfig.dinamicIds = this.dinamicIds
                this.componentConfig.tableIds = this.tableIds
                this.componentConfig.rootId = this.rootId
                this.loadComplete = true;
            }
        },
        methods: {
            refreshData() {
                this.$emit('refreshDichiarazione');
            }
        }
    }
</script>