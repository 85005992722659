<template>
  <div>
    <b-container>
      <b-alert :show="getDeletedFiles.length > 0" variant="warning">
        <div style="text-align: center;">
          <p><strong>ATTENZIONE! Questi sono i files eliminati dalla scheda</strong> (Qualsiasi riferimento a tali files è stato rimosso pure dalla Checklist di propria competenza)</p>
          <ul>
            <li style="list-style: square;" v-for="item in getDeletedFiles" :key="item.fileId">
              {{ item.text }}
            </li>
          </ul>
        </div>     
      </b-alert>
      <br>
      <b-alert :show="loadComplete && isSend && retrieveTaskInfo.taskDefinitionKey.includes('Controllo_controded_Beneficiario-Operatore')" variant="warning">
        <div style="text-align: center;">
          <p>Nota: per esprimere delle controdeduzioni, allegare documentazione opportuna</p>
        </div>     
      </b-alert>
      <Collapse :name="'Campi obbligatori non valorizzati (' + this.obbligatoriTable.rows.length + ')'">
        <b-table-lite
          thead-class="head"
          :id="'mandatory_controlli'"
          :items="this.obbligatoriTable.rows"
          :fields="this.obbligatoriTable.header" />
      </Collapse>
      <Collapse v-if="!isParereVisible"
        :name="'Richiesta Integrazioni (' + integrazioni.length + ')'">
        <b-table
          striped hover
          :id="'integrazioni_controlli'"
          :fields="headerTableIntegrazioni"
          :items="integrazioni">
        </b-table>
      </Collapse>
      <Collapse :name="'Note (' + note.length + ')'">
        <br />
          <TableNotes :id="'note_controlli'" v-if="note.length > 0" :notes="note"/>
          <h4 v-else>
            Nessuna nota da mostrare
          </h4>
      </Collapse>
      <Collapse
        v-if="isVisibleReservedNote()"
        :name="'Elenco Note Interne (' + fillNoteRiservate().length + ')'">
        <br />
        <div class="note" v-if="isSend">
          <br>
            <b-form-textarea
              id="textAreaNoteRiservate"
              :disabled="disableButton"
              v-model="noteRiservate"
              placeholder="Inserire qui le note interne"/>
        </div>
        <div class="pulsanti-invio">
          <b-button :id="'cancelNoteRiservate_controlli'"
            v-if="isSend"
            class="mx-3 btnReservedNote"
            :disabled="disableButton"
            variant="danger"
            @click="resetReservedNote()"
            >Cancella Nota Interna</b-button>
        </div>
        <TableNotes
          :id="'note_riservate_controlli'"
          v-if="fillNoteRiservate().length > 0" :notes="fillNoteRiservate()">
        </TableNotes>
        <h4 v-else>
          Nessuna nota da mostrare
        </h4>
      </Collapse>
        <br/>
          <b-card class="mb-2" v-if="isParereVisible">
            <b-row>
              <p class="text-right">Si richiede il parere della Task Force ICT:</p>
              <b-col>
                <b-radio-group
                  size="lg"
                  :disabled="isAdviceDisabled"
                  v-model="richiestoParereICT"
                  >
                  <b-form-radio v-bind:value="true">Sì</b-form-radio>
                  <b-form-radio v-bind:value="false">No</b-form-radio>
                </b-radio-group>
              </b-col>
            </b-row>
            <b-row v-if="richiestoParereICT">
              <b-col class="text-left">
                Parere ICT:
                <b-textarea
                  maxlength="2000"
                  rows="5"
                  id="textarea-parereICT-controlli"
                  :state="enableStateTextAreaICT"
                  :disabled="isParereIctDisabled"
                  placeholder="Inserire il parere"
                  v-model="parereICT"
                />
              </b-col>
            </b-row>
          </b-card>      
          <b-card class="mb-2" v-if="isParereVisible">
            <b-row>
              <p class="text-right">Si richiede il parere della Task force logistica e infrastrutture edili:</p>
              <b-col>
                <b-radio-group
                  size="lg"
                  :disabled="isAdviceDisabled"
                  v-model="richiestoParereLIE"
                  >
                  <b-form-radio v-bind:value="true">Sì</b-form-radio>
                  <b-form-radio v-bind:value="false">No</b-form-radio>
                </b-radio-group>
              </b-col>
            </b-row>
            <b-row v-if="richiestoParereLIE">
              <b-col class="text-left">
                Parere Task force logistica e infrastrutture edili:
                <b-textarea
                  maxlength="2000"
                  rows="5"
                  id="textarea-parereLIE-controlli"
                  :state="enableStateTextAreaLIE"
                  :disabled="isParereLieDisabled"
                  placeholder="Inserire il parere"
                  v-model="parereLIE"
                />
              </b-col>
            </b-row>
          </b-card>  
        
      <b-row class="mt-2" v-if="isSend">
        <b-col />
        <b-col class="text-right">
          <label for="textarea-note">Nota:</label>
        </b-col>
        <b-col sm="8">
          <b-form-textarea
            v-model="notaTemp"
            id="textarea-note"
            size="lg"
            placeholder="Inserire qui le note"
            :disabled="disableButton"/>
        </b-col>
        <b-col sm="2">
          <b-button :id="'cancelNote_progetto'" variant="outline-secondary"
                    :disabled="disableButton"
                    @click="cancelNote()">
                    Cancella Nota
          </b-button>
        </b-col>
        <b-col />
      </b-row>
      <b-row v-if="isEsitoFinaleVisible">
        <b-col cols="3" />
        <b-col><span class="esitoFinale">Esito finale del controllo:</span></b-col>
        <b-col>
          <strong>
            <b-radio-group
              id="esitoFinaleRadioGroup"
              :disabled="isEsitoFinaleDisabled"
              @change="updateEsitoFinale"
              v-model="esitoFinale">
              <b-form-radio id="esitoSi" style="font-size:1.8rem;" value="true">Positivo</b-form-radio>
              <b-form-radio id="esitoNo" style="font-size:1.8rem;" value="false">Negativo</b-form-radio>
            </b-radio-group>
          </strong>
        </b-col>
        <b-col cols="2" />
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            :id="'export_pdf'"
            size="lg"
            variant="secondary"
            class="mx-2"
            :disabled="disableButton"
            @click="preparePdf">
            Export PDF
          </b-button>

          <b-button
            v-if="isSave"
            :id="'save_progetto'"
            size="lg"
            variant="primary"
            class="mx-2"
            :disabled="disableButton"
            @click="salvaControlloPerId('draft',codiceProgetto, idControllo)">
            Salva in Bozza
          </b-button>
          
          <b-button
            v-if="isDelete"
            :id="'delete_controllo'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="disableButton"
            @click="saveSchedaControllo('deleteCotrollo')">
            Elimina Controllo
          </b-button>

          <b-button
            v-if="isSend"
            :id="'send_progetto'"
            size="lg"
            variant="success"
            class="mx-2"
            :disabled="disableButton || disableBtnSend || visibilitySaveCompleteForParereICT || visibilitySaveCompleteForParereLIE"
            @click="saveSchedaControllo('complete')">
            Salva e Invia
          </b-button>
          
          <b-button
            v-if="isReject"
            :id="'reject_progetto'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="disableButton || disableBtnSend"
            @click="saveSchedaControllo('reject')">
            Rifiuta
          </b-button>
        </b-col>
      </b-row>

     
      <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
      <b-modal id="modalCompletaControllo"
            centered size="lg"
            ok-variant='success' ok-title='Invia'
            cancel-title='Annulla'
            @ok="salvaControlloPerId('complete', codiceProgetto, idControllo)">
            <template v-slot:modal-header><h3>Completa controllo</h3></template>
            <p class="mb-4">Si vuole completare il controllo?</p>
            <p class="mb-4">Esito finale del controllo: {{getEsito}}</p>
      </b-modal>
    </b-container>
    
  </div>
</template>
<script>

import endpoints from "@/components/endpoints.vue"
import Collapse from "@/components/collapse.vue"
import notify from "@/helpers/notifications.js"
import TableNotes from "../bandi/tableNotes.vue";
import { mapGetters } from "vuex"
import tools from '@/helpers/tools.js'
import controlliHelper from '@/helpers/controlli.js'
import fileHelper from '@/helpers/allegati.js'
import { JSONPath as jpath } from 'jsonpath-plus'
import mapUtils from '@/helpers/mapUtils.js'
import helper_pdf from "@/helpers/exportPdf.js"

export default {
  name: "InvioControllo",
  components: {
    Collapse,
    TableNotes,
  },
  props: {
    tabName: {
      type: String,
      default: 'Sconosciuto'  //NB: deve essere sempre il padre a stabilire un nome univoco di questo componente nel suo contesto
    },
    actions: {
      type: Array,
      default: function () { return [] }
    },
    idsToCheck: {
      type: Object,
      default: function () { return {} }
    },
    idsCustomEdit: {
      type: Array,
      default: function () { return [] }
    },
    overloadedMap: {
      type: Object,
      default: () => null
    },
  },
  watch: {
    tabName: function() {
      if(this.tabName === this.componentId) {
        this.deletedFiles = []
        this.refreshMandatoryFields()
        this.populateIntegrations()
      }
    }
  },
  data() {
    return {
      schedaNoteRiservate: null,
      noteRiservate: "",
      cronoSchemaId: 'cronoprog_controlli',
      componentId: "InvioControllo",
      disableButton: false,
      esitoFinale: true,
      loadComplete: false,
      modal1Map:{
            deleteCotrollo:{
                title:"Elimina Controllo", text:"Conferma di voler Eliminare il Controllo? "
            },
            complete:{
                title:"Conferma Controllo", text:"Conferma di voler Inviare il Controllo?"
            },
            reject:{
                title:"Rifiuta Controllo", text:"Conferma di voler Rifiutare il Controllo?"
            },
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",
      // saveComplete: false,
      // showCompleteModal: false,
      richiestoParereICT: false,
      richiestoParereLIE: false,
      parereICT: "",
      parereLIE: "",
      deletedFiles: [],
      mappa: {},
      note: [],
      notaTemp: "",
      integrazioni: [],
      headerTableIntegrazioni: [
        { key: "punto", label: "Indice Descrizione" },
        { key: "commenti", label: "Commenti (Ufficio)" },
        { key: "controdeduzioni", label: "Controdeduzioni (Beneficiario)" },
        { key: "esito", label: "Esito Controllo"}
      ],
      scheda: {},
      obbligatoriTable: {
        header: [
          {
            key: "Tab"
          },
          {
            key: "Campo",
            label: "Indice Descrizione"
          }
        ],
        rows: []
      },
      configCronoProg:{},
      config:{},
      myRole:'',
      //Stati BPMN in cui è concesso editare l'esito definitivo del controllo
      esitoFinaleAllowedStates: [
        'Controllo_approvaChecklist_UfficioControlli-Approvatore',
        'Controllo_validazione_UfficioControlli-Operatore',
        'Pagamento_validazione_UfficioEconomicoFinanziario-Operatore',
        'Pagamento_approvaChecklist_UfficioEconomicoFinanziario-Approvatore',
        'Controllo_approvazione_UfficioControlli-Approvatore', //In Loco, prima valutazione
        'Controllo_controded_UfficioControlli-Approvatore', //In Loco, dopo le controdeduzioni
        'Controllo_approvazione_UfficioGestione-Approvatore', //In Loco, prima valutazione
        'Controllo_controded_UfficioGestione-Approvatore' //In Loco, dopo le controdeduzioni
      ]
    }
  },
  mounted() {
    this.myRole = this.$getUserRole();
    this.config = this.getTabellone;
    this.codiceProgetto = this.$route.params.codiceProgetto;
    this.idControllo = this.$route.params.idControllo;
    if(this.overloadedMap)
      this.mappa = this.overloadedMap
    else if (this.config && this.config.content && this.config.content.mappa)
      this.mappa = this.config.content.mappa;
    else
      console.error("No valid config for checklist was founded: ", this.config)

    let scheda = this.getCurrentScheda();
    if (scheda)
      this.scheda = scheda;
    let esitoFin = scheda.content.esitoFinale
    //Di default, setto l'esito positivo, a meno che non sia esplicitamente richiesto
    this.esitoFinale = esitoFin === "false" || esitoFin === false ? "false" : "true"

    if(this.scheda.content.note || this.scheda.content.notaTemporanea) {
      this.note = this.scheda.content.note && this.scheda.content.note.length > 0 ? this.scheda.content.note : [];
      this.notaTemp = this.scheda.content.notaTemporanea.nota
    }
    // NOTE RISERVATE
    this.schedaNoteRiservate = this.getSchedaNoteRiservate();
    if (this.scheda.content?.notaTemporaneaRiservata) {
      this.noteRiservate = this.scheda.content.notaTemporaneaRiservata;
    }
    if(this.scheda.tipoControllo !== "In Loco"){
      this.configCronoProg = this.getTabelloneCronoprog(this.cronoSchemaId).tabellone;
      this.populateIntegrations()
    }
    if(this.isParereVisible)
      this.populatePareri()
    
    this.refreshMandatoryFields()
    this.loadComplete = true
  },
  computed: {
    getEsito() {
      return (this.esitoFinale === true || this.esitoFinale === 'true') ? 'Positivo' : 'Negativo'
    },
    ...mapGetters({
      getScheda: "controlli/getScheda",
      getSchedaCronoprog: 'cronoprog/getSchedaComplete',
      getAllegatiDaCancellare: "controlli/getAllegatiDaCancellare",
      getTabellone: "controlli/getTabellone",
      getTabelloneCronoprog: 'configuration/getTabelloneFull',
      getNoteRiservate: "controlli/getSchedaNoteRiservate"
    }),
    getDeletedFiles() {
      // SE ho più occorrenze farò in modo di visualizzarne sempre una
      let unique = this.deletedFiles.reduce((unique1, o) => {
        if(!unique1.some(obj => obj.fileId === o.fileId))
          unique1.push(o)
        return unique1
      },[])
      return unique
    },
    
    isLastStep(){
      return (this.scheda.content.taskInfo.taskDefinitionKey === "Controllo_approvaChecklist_UfficioControlli-Approvatore"
              ||this.scheda.content.taskInfo.taskDefinitionKey === "Pagamento_approvaChecklist_UfficioEconomicoFinanziario-Approvatore"
              ||this.scheda.content.taskInfo.taskDefinitionKey.match(/^Controllo_controded_Ufficio.*Approvatore$/))
    },

    isEsitoFinaleVisible() {
      return (this.scheda.tipoControllo && this.scheda.tipoControllo.includes('In Loco') 
        || this.myRole.includes('UfficioControlli') 
        || this.myRole.includes('UfficioEconomicoFinanziario') 
        || this.myRole.includes('UfficioGestione'))
    },

    isEsitoFinaleDisabled() {
      return  !(this.scheda && this.scheda.content && this.scheda.content.taskInfo && (
            this.esitoFinaleAllowedStates.includes(this.scheda.content.taskInfo.taskDefinitionKey)
          )
          && this.isSend
        )
    },

    isSave(){
      return this.actions.indexOf('save') !== -1;
    },

    isDelete(){
      return this.actions.indexOf('delete') !== -1;
    },

    isSend(){
      return this.actions.indexOf('send') !== -1;
    },

    isReject(){
      return this.actions.indexOf('reject') !== -1;
    },
    retrieveTaskInfo(){
      return this.scheda.content.taskInfo
    },

    //------PARERI-----
    isParereVisible(){
      return (this.scheda.tipoControllo === "In Loco" && this.scheda.sottoTipoControllo === "Operativo")
    },
    isAdviceDisabled(){
      return this.actions.indexOf('edit') === -1 
        && this.actions.indexOf('askAdvice') === -1
    },
    isParereLieDisabled(){
      return this.actions.indexOf('editLieAdvice') === -1;
    },
    isParereIctDisabled(){
      return this.actions.indexOf('editICTAdvice') === -1;
    },
    //---------------- VALUTAZIONE STATE TEXT-AREA PARERI ---------------------
    enableStateTextAreaICT() { 
      // COSI' DISATTIVO L'ANALISI DELLO STATE DELLA TEXT-AREA DEL PARERE ICT
      if(this.isParereIctDisabled) {
        return null;
      }
      // COSI' VALUTO L'ANALISI DELLO STATE DELLA TEXT-AREA DEL PARERE ICT
      return this.parereICT.trim() !=='' && this.isAdviceDisabled
    },
    enableStateTextAreaLIE() {
      // COSI' DISATTIVO L'ANALISI DELLO STATE DELLA TEXT-AREA DEL PARERE LIE
      if(this.isParereLieDisabled) {
        return null;
      }
      // COSI' VALUTO L'ANALISI DELLO STATE DELLA TEXT-AREA DEL PARERE LIE
      return this.parereLIE.trim() !=='' && this.isAdviceDisabled
    },
    //---------------- VALUTAZIONE STATE TEXT-AREA PARERI ---------------------

    // ABILITAZIONE / DISABILITAZIONE 'COMPLETE' IN PRESENZA DI RICHIESTA DI PARERI ICT/LIE
    visibilitySaveCompleteForParereICT() {
       return (this.actions.indexOf('editICTAdvice') !== -1  && this.parereICT.length === 0 );
    },
    visibilitySaveCompleteForParereLIE() {
       return (this.actions.indexOf('editLieAdvice') !== -1  && this.parereLIE.length === 0 );
    },

    disableBtnSend() {
      return this.obbligatoriTable.rows.length > 0
       //WARNING decommentare solo per debug
       //return false;
    }
  },
  methods: {
    preparePdf() {
      let scheda = this.updatePareriInStore(this.getCurrentScheda())
      if(this.isEsitoFinaleVisible) {
        scheda.content.esitoFinale = this.esitoFinale
      }
      let contentScheda = scheda.content
      let typeScheda = 'Scheda: Controllo'
      let optPdf = helper_pdf.handleNamingPdf(contentScheda, typeScheda)
      this.$emit('buildPdf', optPdf)
    },
    isVisibleReservedNote() {
      let userInfo = this.$getUserInfo();
      let group = userInfo.groups[0];
      return group.includes("MINT") && this.schedaNoteRiservate;
    },
    getSchedaNoteRiservate() {
      if (this.scheda.noteRiservateKey) {
        return this.getNoteRiservate({ noteRiservatekey: this.scheda.noteRiservateKey })
      }
    },
    fillNoteRiservate() {
      return this.schedaNoteRiservate.content?.noteRiservate || [];
    },
    populateIntegrations() {
      let integrazioni = []
      let contentScheda = this.getCurrentScheda().content
      let allIds = Object.keys(this.mappa)
      for (const rowId of allIds) {
        const jpathAuto = jpath('$' + rowId, contentScheda.autocontrollo)
        const jpathControl = jpath('$' + rowId, contentScheda.controllo)
        // Object.keys(this.mappa).forEach((rowId) => {
        if(jpathControl &&
          jpathAuto &&
          jpathControl.length === 1 &&
          //jpathControl[0].esito === 'Negativo' &&
          jpathControl[0].commenti !== null) {
          integrazioni.push({
            punto: 'Punto_' + this.mappa[rowId].config.idField,
            commenti: jpathControl.length === 1 && jpathControl[0].commenti ? jpathControl[0].commenti : null,
            controdeduzioni: jpathAuto.length === 1 && jpathAuto[0].controDeduzione ? jpathAuto[0].controDeduzione : null,
            esito: jpathControl.length === 1 && jpathControl[0].esito ? jpathControl[0].esito : null,
          })
        } 
      }
      this.integrazioni = integrazioni
    },
    populatePareri(){
      if(this.scheda.content.richiestoParereICT)
        this.richiestoParereICT = this.scheda.content.richiestoParereICT
      if(this.scheda.content.parereICT)
        this.parereICT = this.scheda.content.parereICT
      if(this.scheda.content.richiestoParereLIE)
        this.richiestoParereLIE = this.scheda.content.richiestoParereLIE
      if(this.scheda.content.parereLIE)
        this.parereLIE = this.scheda.content.parereLIE
    },
    getCurrentScheda() {
      let scheda = this.getScheda({ codiceProgetto: this.$route.params.codiceProgetto, id: this.$route.params.idControllo })
      return tools.genericFunctions.cloneObject(scheda);
    },
    getCronoprog(idScheda) {
      return this.getSchedaCronoprog({idScheda: idScheda}).scheda
    },
    cancelNote(){
      this.notaTemp = "";
    },
    resetReservedNote() {
      this.noteRiservate = ""
    },
    updatePareriInStore(body) {
      //Persisto i pareri nello store e per l'API di salvataggio
      if(this.isParereVisible){
        if(!this.isAdviceDisabled){
          body.content.richiestoParereICT = this.richiestoParereICT
          body.content.richiestoParereLIE = this.richiestoParereLIE
        }

        if(!this.isParereIctDisabled)
          body.content.parereICT = this.parereICT
        
        if(!this.isParereLieDisabled)
          body.content.parereLIE = this.parereLIE
      }
      this.updateSchedaInStore(body)
      return body
    },
    salvaControlloPerId(action, codice, id) {
      this.$bvModal.hide('deleteControllo');
      // this.showCompleteModal = false
      this.disableButton = true
      
      let body = this.getCurrentScheda();
      
      //elimino adesso la taskInfo per non persisterlo
      delete body.content.taskInfo;

      //Setto la nota temporanea
      const autore = this.$getUserInfo().preferred_username;
      body.content.notaTemporanea = {
          nota: this.notaTemp,
          utente: autore
      }
      //Setto la nota temporanea riservata
      body.content.notaTemporaneaRiservata = this.noteRiservate;

      //setto l'autore dell'ultimo aggiornamento fuori e dentro content
      body.content.autoreUltimoAggiornamento = autore;
      body['autoreUltimoAggiornamento'] = autore;
      
      const taskDefKey = this.retrieveTaskInfo.taskDefinitionKey;

      //in base al tipo di task setto l'autore nell'autocontrollo o nel controllo
      if (taskDefKey.includes('Beneficiario') && body.content.autocontrollo){ 
        body.content.autocontrollo.autore = autore;
      } else if (taskDefKey.includes('UfficioControlli-Operatore') 
        || taskDefKey.includes('UfficioEconomicoFinanziario-Operatore')
      ) {
        body.content.controllo.autore = autore;        
      }
      //Persisto i pareri
      body = this.updatePareriInStore(body)

      //Request Body
      let entity = { controllo: body }

      const idProgetto = this.scheda.content.infoProgetto.idProgetto;
      let cronoprog = {}
      
      //Il cronoprogramma viene salvato solo dal benef. operatore, quando ancora nella scheda non vi è nessuna cronoprogKey
      if(idProgetto && !this.scheda.cronoprogKey && this.myRole.includes("Beneficiario-Operatore")){
        cronoprog = this.getCronoprog(idProgetto) 
        entity.cronoprog = cronoprog
      }
      const url = endpoints.getControlloById(this.scheda.tipoControllo, codice, id);
      const sottoTipoControllo = this.scheda.sottoTipoControllo;
      const params = {
        branch: 'controlli' + sottoTipoControllo
      }
      console.info("URL: ", url, params);

      this.$post(url, entity, params)
      .then((result) => {
          console.log(codice, id, " Controllo scritto=", result.controllo);

          this.attachFileObjects().then(() => {
            if (action === 'draft') {
              notify.success(notify.strings.success, notify.strings.saveControlloOk);
              this.disableButton = false
              return;
            }
            //Se ho salvato anche cronoprog, nella response ci sarà: prendo la key più recente
            const tabelloneCronoprog = this.getTabelloneCronoprog(this.cronoSchemaId);
            let cronoInfo = {
              cronoKey: null,
              cronoSchemaVersion: tabelloneCronoprog ? tabelloneCronoprog.version : null,
              cronoSchemaId: this.cronoSchemaId
            }

            if(result.cronoprog) {      
              cronoInfo.cronoKey = result.cronoprog.key
              // cronoInfo.cronoSchemaVersion = this.getTabelloneCronoprog(this.cronoSchemaId).version
              console.info("Setto nella scheda la cronoprogKey:", cronoInfo);
            }

            //se siamo all'ultimo task umano dei rispettivi processi ControlliAmministrativi.bpmn, CircuitoFinanziario.bpmn
            if (taskDefKey === 'Controllo_approvaChecklist_UfficioControlli-Approvatore'
                || taskDefKey === 'Pagamento_approvaChecklist_UfficioEconomicoFinanziario-Approvatore'
              ){
              if(action !== 'reject') {
                console.log("Esito finale=", this.esitoFinale);
                let act = 'completeKO';
                
                if (this.esitoFinale === true || this.esitoFinale === 'true'){
                  act = 'completeOK';
                }
                this.completaTask(act, codice, id, body, cronoInfo);
              } else {
                this.completaTask(action, codice, id, body, cronoInfo);
              }
            //Controlli in Loco
            } else if(this.scheda.tipoControllo == "In Loco"){
              
              if(action !== 'reject'){
                if( // Primo parere in Loco
                    taskDefKey === 'Controllo_approvazione_UfficioControlli-Approvatore' 
                    || taskDefKey === 'Controllo_approvazione_UfficioGestione-Approvatore'
                    || taskDefKey === 'Controllo_controded_UfficioControlli-Approvatore'
                    || taskDefKey === 'Controllo_controded_UfficioGestione-Approvatore'
                  ){
                    console.info("Esito finale= ", this.esitoFinale)
                    let act = 'completeKO';

                    if (this.esitoFinale === true || this.esitoFinale === 'true')
                      act = 'completeOK';

                    this.completaTask(act, codice, id, body, cronoInfo);  //Complete OK/KO
                }
                else if (!this.isAdviceDisabled){ //Controlli In Loco Operativi, primo step
                  //Ingaggio i referenti strategici
                  let act = "complete"
                  if(this.richiestoParereICT && this.richiestoParereLIE)
                    act = "completeFull"
                  else if(this.richiestoParereICT)
                    act = "completeICT"
                  else if(this.richiestoParereLIE)
                    act = "completeLIE"

                  console.info("Action: ",act)
                  this.completaTask(act, codice, id, body, cronoInfo); 
                }
                else if (
                  taskDefKey === 'Controllo_controded_Beneficiario-Operatore'
                ){
                  let act = 'completeKO';
                  if (this.hasControdeduzioni())
                    act = 'completeOK'
                  
                  console.info("Controdeduzioni = ", act)
                  this.completaTask(act, codice, id, body, cronoInfo);  //Complete OK/KO
                }
                else{
                  this.completaTask(action, codice, id, body, cronoInfo); //Normal complete
                }
                  
              }
              else
                this.completaTask(action, codice, id, body, cronoInfo); //Reject
            }
            else {
              this.completaTask(action, codice, id, body, cronoInfo);
            }           
          },
          (error) => {
            console.error("ERRORE attachFile: ", error.message);
            notify.error(notify.strings.error, notify.strings.errorSaveControllo);
            this.disableButton = false
            throw error;
          });
      },
      (error) => {
          console.error("ERRORE post controllo: ", error.message);
          notify.error(notify.strings.error, notify.strings.errorSaveControllo);
          this.disableButton = false
          throw error;
      });  
    },
    updateEsitoFinale() {
      let scheda = this.getCurrentScheda()
      scheda.content.esitoFinale = this.esitoFinale
      this.updateSchedaInStore(scheda)
    },
    updateSchedaInStore(scheda) {
      this.$store.dispatch("controlli/updateScheda", {
        codiceProgetto: this.$route.params.codiceProgetto,
        id: this.idControllo,
        content: scheda,
      });
    }, 
    hasControdeduzioni(){
      let scheda = this.getCurrentScheda()
      if(scheda.content.allegati){
        return Object.values(scheda.content.allegati).filter( allegato => { return allegato.documentType === "Controdeduzioni"} ).length > 0
      }
      
      return false
    },
    saveSchedaControllo(action){
      //Apro la modale se mi trovo all'ultimo step
      if(this.isLastStep && action == 'complete'){
        // this.showCompleteModal = true
        this.$bvModal.show('modalCompletaControllo');
        return
      }
      else{
            this.setModal=action;
            this.$bvModal.show('modalCompletaInvia');
      } 
      
      //API di scrittura del controllo per ID
    },
    actionModal(){
      if(this.setModal=='deleteCotrollo'){
        this.deleteSchedaControllo();
      }
      else{
            this.salvaControlloPerId(this.setModal, this.codiceProgetto, this.idControllo)

      }

    },

    //Setta ad "ATTACHED" lo stato dei file caricati ed elimina quelli cancellati
    async attachFileObjects(){
      const uri = endpoints.filesBasePathV2
      const allegati = this.getCurrentScheda().content.allegati
      const toDelete = tools.genericFunctions.cloneObject(this.getAllegatiDaCancellare)
      const list = fileHelper.attachFileReqBuilder( allegati, toDelete )

      if(list && list.trim().length > 0){
        const ownerProcessStatus= this.retrieveTaskInfo.taskDefinitionKey
        
        this.$put(uri,
        {
          "list" : list,
          "status" : ownerProcessStatus
        }
        ).then(
          (result) => {
            console.log("attached Files:", result)
            this.$store.dispatch("controlli/clearAllegatiDaCancellare")
          },
          (error) => {
              console.error("ERRORE: ", error.message);
              notify.error(notify.strings.error, notify.strings.unexpectedLoadStatus(error.message));
              throw error;
          }
        )
      }        
    },

    completaTask(action, codiceProgetto, idControllo, scheda, cronoInfo){
      let urlCompletaControllo = endpoints.completeControllo;

      let processId = scheda.processId;
      let taskId = this.retrieveTaskInfo.taskId;
      
      let bodyForPost = {
        idScheda: idControllo,
        processId: processId,
        taskId: taskId,
        cronoprogKey: cronoInfo.cronoKey,
        cronoSchemaVersion: cronoInfo.cronoSchemaVersion,
        cronoSchemaId: cronoInfo.cronoSchemaId,
        azione: action
      };
      
      return this.$post(urlCompletaControllo, bodyForPost)
      .then(
          (respComplete) => {
              console.log("complete controllo ok, response=", respComplete);
              notify.success(notify.strings.success, notify.strings.saveCompleteOk);
              this.disableButton = false
              let routerName = this.scheda.tipoControllo.includes("In Loco") ? 'listaControlliLoco' : 'listaControlliAmm'
              this.$router.push({ name: routerName });
          },
          (error) => {
            console.error("ERRORE: ", error.message);
            notify.error(notify.strings.error, notify.strings.errorCompleteControllo);
            throw error;
          }
      );
    },
    refreshMandatoryFields(){
        this.checkCampiObbligatori()
    },
    handleVisibilityFilesCanceled(scheda, campo, toCheck) {
      let values = jpath({resultType: 'all'}, '$' + campo, toCheck)
      if(scheda.content.allegati && values && values.length === 1) {
        let { deletedFiles } = controlliHelper.updateSituationFiles(values[0], Object.keys(scheda.content.allegati), this.deletedFiles) 
        this.deletedFiles = deletedFiles ? deletedFiles : []
        if(this.actions.indexOf('checkAutocontrollo') > -1) {
          scheda.content.autocontrollo = toCheck
        } else if (this.actions.indexOf('checkControllo') > -1){
          scheda.content.controllo = toCheck
        }
        this.updateSchedaInStore(scheda)
      }
    },
    checkAltriCampiObbligatori(scheda) {
      let idsToCheck = this.idsToCheck['dinamicIds']
      // Questo check verifica la compilazione di campi obbligatori, guardando i campi di Verbale
      if(idsToCheck.length > 0){
        if(scheda.tipoControllo.includes("In Loco")){
          console.log("Altri campi obbligatori: ", idsToCheck)
          this.pushObbligatori(idsToCheck, this.config, scheda)
        }
        else{
          // Amministrativo
          // Questo check verifica la compilazione di campi obbligatori, guardando:
          // 1) la scheda Controllo per i campi di CheckList
          // 2) la scheda cronoprog per i campi di Procedura / Contratto / Pagamento, per le fasi ( 1A, 1B e 2 )

          // Occorre differenziare le fasi 1A e 1B dalla fase 2
          // Per le primi due fasi devo controllare un unico oggetto: Procedura o contratto
          // Per fase 2 devo controllare una lista di oggetti ( in rif. a Pagamenti e a Dichiarazione )
          let idScheda = scheda?.content?.infoProgetto?.idProgetto
          let scheda_cronoprog = this.getCronoprog(idScheda)
          
          if(this.configCronoProg && Object.keys(this.configCronoProg).length > 0 && Object.keys(scheda_cronoprog).length > 0) {
            if(this.idsCustomEdit.length > 0)
              this.checkObbligatoriCustomEdit(scheda)
            if(scheda.sottoTipoControllo === '2')
              this.checkObbligatoriFase2(scheda, idsToCheck, scheda_cronoprog)
            else {
              //Non posso usare this.pushObbligatori perche qui devo calcolare il CronoprogId
              for(const id of idsToCheck) {
                let mapElement = this.configCronoProg[id]
                if( !mapElement || !mapElement.config || !mapElement.config.mandatory) {
                  continue;
                }
                let cronoprogItemId = scheda.cronoprogItemId
                if(!cronoprogItemId) {
                  continue;
                }
                let idToCheck = mapUtils.getCronoprogId(cronoprogItemId, id)
                let values = jpath('$'+ idToCheck, scheda_cronoprog.content)
                if(!values || values.length === 0 || (values[0] == null || values[0] == undefined || values[0].toString().trim() === "")) {
                  // if(mapElement.config.label && mapElement.config.tab) {
                    this.obbligatoriTable.rows.push({
                      Campo: mapElement.config.label,
                      Tab: mapElement.config.tab
                    })
                  // }
                }
              }
            }
          }
        }
      }

    },
    checkObbligatoriCustomEdit(scheda) {
      // FUNZIONALITA' DELL'EDIT CUSTOM PER RUOLO
      for(const id of this.idsCustomEdit) {
        let mapElement = this.config[id]
        if(mapElement && mapElement.config && mapElement.config.editCustom) {
          let role = this.$getUserInfo().roles[0]
          if(mapElement.config.editCustom.includes(role))
            this.checkValueAndPushMandatory(mapElement, scheda)
        }
      }
    },
    checkObbligatoriFase2(scheda, idsToCheck, scheda_cronoprog) {
      // CONTROLLO OBBLIGATORI PAGAMENTI
      for(const id of idsToCheck) {
        const mapElement = this.configCronoProg[id]
        if(! mapElement || !mapElement.config || !mapElement.config.mandatory) {
          continue;
        }
        const pagamenti = scheda_cronoprog.content.pagamenti;
        //WARNING: in questa funzione si è assunto che tutte le chiavi siano attributi di pagamento, vedi ids_pagamenti.rootId.dinamicIds di schedaControlliAmministrativi.vue
        let regexField = id.match(/'(.*?)'/g)
        let fieldToCheck = regexField[regexField.length - 1].replaceAll(/"|'/g, '');
        if (fieldToCheck == "riferimentoContratto"){ //NB: questa chiave: "['contratti'][*]['content']['riferimentoContratto']" dovrebbe stare sentro relIds, vedi pagamenti2.vue
          console.log("SKIP controllo su riferimentoContratto, attributo obbligatorio di contratto", id);
          continue;
        }
        for(const key of Object.keys(pagamenti)) {
          let pagamento = pagamenti[key]
          // Se il pagamento fa parte della dichiarazione di spesa corrente
          if(pagamento.content.flagged !== scheda.idSchedaControllo) {
            continue;
          }
          let contratto_rel = pagamento.rel.contratti.length === 1 ? pagamento.rel.contratti[0] : ''
          let valueToCheck = pagamento.content[fieldToCheck];
          if(    valueToCheck == null 
              || valueToCheck == undefined 
              || valueToCheck.toString().trim() === "") {
            // if(mapElement.config.label && mapElement.config.tab) {
              // L'identificativo del pagamento può essere:
              // o il suo numero di fattura o il rifContratto del contratto relazionato
              let identifier_payment = 'N/D'
              if(pagamento.content['numeroFattura'] && pagamento.content['numeroFattura'].toString())
                identifier_payment = pagamento.content['numeroFattura']
              else if(contratto_rel !== '') {
                const contratto = scheda_cronoprog.content.contratti[contratto_rel];
                let content_contratto_rel = contratto && contratto.content ? contratto.content : {}
                identifier_payment = Object.keys(content_contratto_rel).length > 0  && content_contratto_rel.riferimentoContratto ? content_contratto_rel.riferimentoContratto : identifier_payment
              } 
              this.obbligatoriTable.rows.push({
                Campo: mapElement.config.label + ' (' + identifier_payment + ')',
                Tab: mapElement.config.tab
              })
            // }
          }
        }
      }
      // CONTROLLO OBBLIGATORI DICHIARAZIONE
      for(const id_dichiaraz of this.idsToCheck['rowIdsDichiarazione']) {
        const mapElement = this.config[id_dichiaraz];
        if(!mapElement || !mapElement.config.mandatory) {
          continue;
        }
        const values = jpath('$'+ id_dichiaraz, scheda.content)
        if(!values || values.length === 0 || (values[0] == null || values[0] == undefined || values[0].toString().trim() === "")) {
            this.obbligatoriTable.rows.push({
              Campo: mapElement.config.label,
              Tab: mapElement.config.tab
            })
        }
      }
    },
    checkCampiObbligatori() {
      this.obbligatoriTable.rows = []
      let scheda = this.getCurrentScheda()
      // in questo caso i campi della configurazione sono gli stessi sia per controllo che per autocontrollo
      const groups = this.$getUserInfo().groups;
      if(!groups || groups.length === 0) {
        console.error('check campi obbligatori: groups error', this.$getUserInfo());
        return;
      }
      const group = groups[0];
      let toCheck;
      if(group.match("eneficiari") && this.scheda.tipoControllo !== "In Loco") {
        toCheck = scheda.content.autocontrollo;
      } else {
        toCheck = scheda.content.controllo;
      }
      //prendo i campi obbligatori
      for (const campo in this.mappa) {
        if (this.mappa[campo] && this.mappa[campo].config && this.mappa[campo].config.mandatory) {
          this.handleVisibilityFilesCanceled(scheda, campo, toCheck)
          let checkValid = controlliHelper.checkValidValue( this.mappa[campo].config, toCheck )
          //Se non c'è un valore valido lo pusho nella tabella degli obbligatori
          if (!checkValid) {
              this.obbligatoriTable.rows.push({
                Tab: this.mappa[campo].config.tab,
                Campo: 'Punto_' + this.mappa[campo].config.idField,
              })
          }
        }
      }
      this.checkAltriCampiObbligatori(scheda)
    },
    rimuoviPagamentiControllati(cronoprog){
      // lista dei pagamenti assegnati a qualche dichiarazione di spesa
      const flagList = jpath({resultType: 'all'}, "$['pagamenti'][*]['content']['flagged']", cronoprog.content);
      if(flagList) {
        // filtro sui pagamenti legati alla dichirarazione corrente
        const pagamentiDichCorrente = flagList.filter(
          item => {
            return item.value === this.idControllo;
          }
        );
        // per ciascuno di questi pagmaenti resetto il flag prima di eliminare la dichiarazione di spesa
        for(const pagamento of pagamentiDichCorrente) {
          pagamento.parent[pagamento.parentProperty] = '';
        }
      }

      return cronoprog
    },
    //Rimuove dalla scheda cronoprog controlling e controlled
    clearControlReferences(cronoprog){
      cronoprog.content[this.scheda.cronoprogRootId][this.scheda.cronoprogItemId].content.controlling = null
      cronoprog.content[this.scheda.cronoprogRootId][this.scheda.cronoprogItemId].content.controlled = null
      return cronoprog
    },
    deleteSchedaControllo() {
      this.disableButton = true
      const uri = endpoints.deleteControllo(this.idControllo)
      // console.log("DEL", uri)

      const idProgetto = this.scheda.content.infoProgetto.idProgetto;

      //Chiama la PUT di cronoprog che rimuove controlling e controlled
      let requestBody = {
        idProgetto: idProgetto ,
        cronoprogRootId: this.scheda.cronoprogRootId ,
        cronoprogItemId: this.scheda.cronoprogItemId,
      }

      this.$put(uri, requestBody)
        .then((result) => {
            console.log("delete controllo ok, response=", result);
            notify.success(notify.strings.success, notify.strings.deleteControlloOk);
            this.disableButton = false
            this.$router.push({ name: "listaControlliAmm" });
        },
        (error) => {
            console.error("ERRORE: ", error.message);
            notify.error(notify.strings.error, notify.strings.errorDeleteControllo);
            this.disableButton = false
            throw error;
        })
    },
    //Funzione di utils
    pushObbligatori(idsToCheck, config, scheda) {
      for(const id of idsToCheck) {
        let mapElement = config[id]
        if(mapElement && mapElement.config && mapElement.config.mandatory) {
          this.checkValueAndPushMandatory(mapElement, scheda)
        }
      }
    },
    checkValueAndPushMandatory(mapElement, scheda) {
    let values = jpath('$'+ mapElement.config.path, scheda.content)
      if(values && values.length === 0 || values[0] == null || values[0] === "") {
        if(mapElement.config.label && mapElement.config.tab) {
          this.obbligatoriTable.rows.push({
            Campo: mapElement.config.label,
            Tab: mapElement.config.tab
          })
        }
      }
    }
  }
};
</script>
