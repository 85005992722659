<template>
  <div>
    <br>
    <div class="table-title">Graduatoria del bando: {{ this.$route.params.idBando }}</div>
      <b-table-lite
      thead-class="head"
      :items="fillResumeData.rows"
      :fields="fillResumeData.header"
      />
    <div>
    <keep-alive>
      <b-card no-body>
        <b-tabs card nav-class="tabs" content-class="content">
           <ColorLegend/>
          <b-tab
            no-body
            title="Graduatoria"
            title-item-class="tab1"
            title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['lista_progetti'].name)">
            <!-- content of tab graduatoria -->

            <b-form inline class="m-1" style="display: flex;">
              <label for="filterProgettiInput"
                ><h4 class="mx-2">Filtra i risultati:</h4>
              </label>
              <b-form-input
                v-model="filter"
                type="search"
                id="filterProgettiInput"
                placeholder="Digita un filtro"
              />
              <b-button
                id="filterProgettiButton"
                class="mx-1"
                :disabled="!filter"
                @click="filter = ''"
                >Cancella</b-button>
            </b-form>
            <b-table
              :id="conf_export_pdf['lista_progetti'].id_table_pdf"
              :busy="isBusy"
              primary-key="id"
              emptyText="Nessun dato disponibile"
              :sort-by="sortBy"
              sort-desc
              :filter="filter"
              emptyFilteredText="Nessun dato disponibile"
              :filter-included-fields="getFilterFields()"
              :items="tabledata.rows"
              :fields="tabledata.header"
              show-empty
              hover
              responsive
            >
              <template v-slot:table-busy>
                <div class="text-center">
                  <strong>Scheda in Caricamento...</strong>
                </div>
              </template>
              <template v-slot:cell(Azioni)="data">
                <b-button
                  :id="namingIdIcon(x, data.index + 1)"
                  variant="outline-primary"
                  size="sm"
                  :key="x"
                  :title="mappingTooltip(x)"
                  v-for="x in data.value"
                  @click="doAction(x, data)"
                >
                  <fa-icon
                    v-if="x === 'view'"
                    :icon="['far', 'eye']"
                    class="selector-icons"
                  />
                  <fa-icon
                    v-if="x === 'accept_isf_oper'"
                    :icon="['fas', 'check']"
                    class="selector-icons"
                  />
                  <fa-icon
                    v-if="x === 'reject_isf_oper'"
                    :icon="['fas', 'times-circle']"
                    class="selector-icons"
                  />
                </b-button>
              </template>
            </b-table>


          </b-tab>
          <b-tab
            no-body
            title="Invio"
            title-item-class="tab1"
            title-link-class="titleLink"
            v-on:click="setTabSelection('invio')">
            <!-- content of invio tab -->
              <Invio v-if="schedaIsNotBlank" 
              :tabName="tabSelection" 
              :schedaConvenzione="scheda"
              :parereGraduatoriaMancante="mancaAlmenoUnParere"
              :riferimento = this.riferimento
              @buildPdf="generateReport"/>            
          </b-tab>
        </b-tabs>
      </b-card>
    </keep-alive>
    </div>
  </div>
</template>

<script>
import utils from "@/helpers/utils.js";
import Invio from "./invioConvenzioneCFP.vue";
import notify from "@/helpers/notifications.js";
import { mapGetters } from 'vuex';
import helper_pdf from "@/helpers/exportPdf.js"
import ColorLegend from "@/components/legend.vue"

export default {
    name: "schedaGraduatoria",
    components:{
        Invio,
        ColorLegend
    },
    data() {
        return {
            //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
            riferimento : {
                idOggettoRif: "",
                tipoOggettoRif: "Scheda Graduatoria Bando", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
                idBando: "",
                tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
                idProgetto: "",
                codiceProgetto: ""
            },
            idBando: this.$route.params.idBando,
            conf_export_pdf: {
              lista_progetti: {
                id_table_pdf: 'list_project_graduatoria',
                name: "Graduatoria",
                title_page_pdf: 'Graduatoria',
                nameCollapse: ''
              },
              "invio_note": {
                id_table_pdf: 'note_graduatoria',
                name: "",
                title_page_pdf: 'Invio',
                nameCollapse: 'Note'
              },
              "invio_fileUpload": {
                id_table_pdf: 'invio_fileUpload',
                name: "",
                title_page_pdf: 'Invio',
                nameCollapse: 'Allegati'
              }
            },
            generaleIds:[
               "['progetto']['beneficiario']['beneficiario']['denominazione']",
               "['progetto']['titoloProgetto']",
               "['progetto']['strumentoFinanziario']",
               "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['obiettivoSpecifico']['codice']",
               "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['codiceComposito']",
               "['progetto']['modalitaDiAccesso']",
               "['progetto']['costoProgetto']",
               "['progetto']['costoProgettoIVA']",
               "['progetto']['durataProgettoMesi']",
               "['progetto']['ulterioriFontiFinanziamento']"
            ],
            graduatoriaIds:[
              "['progetto']['titoloProgetto']"
              //TODO: aggiungere campi del bando relativo
            ],
            erogazioneIds:[
              "['erogazione']['percentualeComunitaria']",
              "['erogazione']['circuitoFinanziario']",
              "['erogazione']['quotaComunitaria']",
              "['erogazione']['percentualePrefinanziamento']",
              "['erogazione']['coordinateAccredito']"
            ],
            // store della scheda convenzione
            // dato che esiste solo qui e il tab invio e' figlio di questo componente,
            // non ha senso creare uno store dedicato
            schedaConvenzione: null,
            scheda: null, //scheda completa
            // gestione della tabella di graduatoria -- inizio
            actions: ["view", "edit_in_graduatoria"],
            sortBy: "punteggioValutazione",
            filter: null,
            isBusy: false,
            tabledata: {
              header: [
                {
                    key: "idProgetto",
                    value: "ID Progetto"
                },
                {
                    key: "titoloProgetto",
                    label: "Titolo Progetto"
                },
                {
                    key: "punteggioValutazione",
                    label: "Punteggio"
                },
                {
                  key: "beneficiario",
                  label: "Beneficiario",
                  formatter: this.beneficiarioFormatter,
                },                
                {
                    key: "ammesso",
                    label: "Ammissione"
                },
                 {
                    key: "dataAmmissioneAStipula",
                    sortable: true,
                    filterByFormatted: true,
                    formatter: this.dateFormatter,
                    label: "Ammesso il"
                },
                {
                  key: "Azioni",
                  thClass: "thst",
                },
              ],
              rows: [],
            },
            // gestione della tabella di graduatoria -- fine
            // default loading state of the page
            loadComplete: false,
            // set default tab to select at first page load
            tabSelection: "Graduatoria",
            resumeTableData: {
              header: [
                {
                  key: "Tipologia"
                },
                {
                  key: "TitoloBando",
                  label: "Titolo Bando"
                },
                {
                    key: "StrumentoFinanziario",
                    label: "Strumento Finanziario"
                },
                {
                    key: "ObiettivoNazionale",
                    label: "Obiettivo Nazionale"
                },
                 {
                    key: "ObiettivoSpecifico",
                    label: "Obiettivo Specifico"
                },
              ],
              rows: [],
            }
    };
  },
  mounted() {
    this.$store.dispatch("configuration/setTabelloneVersioned", {type: 'allegati'}).then( tabellone => {
      this.$store.dispatch(
        "convenzione/setSchedaConvenzione",
        {
          idBando: this.idBando,
          tabelloneAllegati: tabellone
        }
      )
      .then(result => {
        // salvo la response
        if (result && result.schedaConvenzione){
          this.schedaConvenzione = result.schedaConvenzione;

          if (this.schedaConvenzione){
            this.riferimento.idOggettoRif = this.schedaConvenzione.idConvenzione;
            this.riferimento.idBando = this.idBando;
            this.riferimento.tipoBando = this.schedaConvenzione.tipoProgetto;
          }

          this.scheda = result
          this.setTabellaRiassuntiva()
        }
        else
          throw new Error("schedaConvenzione object is null")
        // verifico che abbia l'array progetti per il bando popolato
        if (this.hasProgetti(result.schedaConvenzione)) {
          console.debug("Trovati " 
          + this.schedaConvenzione.progetti.length
          + " progetti per il bando " + this.idBando)
          this.extractData();
        } else {
          // se non ha progetti non costruisco la tabella
          console.error("SchedaConvenzione non ha progetti")
        }
      })
      .catch(err => {
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        console.log("Errore caricamento schedaConvenzione.", err);
      })
    })
    .catch(err => {
      notify.error(
        notify.strings.error,
        notify.strings.internalErrorPleaseReportAction
      );
      console.log("Errore caricamento tabellone.", err);
    })
  },
  computed: {
    ...mapGetters({
      getSchedaConvenzione: "convenzione/getSchedaConvenzione",
    }),  
    mancaAlmenoUnParere(){
      let scheda = this.getSchedaConvenzione({ idBando: this.schedaConvenzione.idBando })
      return scheda.progetti.filter( p => { return p.ammesso === "Ammesso da sistema" }).length > 0
    },
    schedaIsNotBlank() {
      return this.hasProgetti(this.schedaConvenzione)
    },
    fillResumeData() {
      return this.resumeTableData
    },  
  },
  methods: {
    generateReport(optPdf) {  
      helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
    },
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    },
    // ogni stato ammesso nel processo di graduatoria inizia con 'Ammesso'
    isAmmesso(state){
      return state.toLowerCase().startsWith('ammesso')
    },
    // funzioni per la b-table
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    currencyFormatter(value) {
      if (value == undefined || value == null) return null;
      return utils.formatCurrency(value);
    },
    setStatoInTabella(idProgetto, stato){
      this.$store.dispatch(
        "convenzione/updateStatoProgettoAmmesso",
        {
          idBando: this.idBando,
          idProgetto: idProgetto,
          stato: stato
        }
      )
    },
    doAction(action, data) {
      if (!data) {
        console.log("scheda convenzione vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      // ammissione manuale da parte di ISF oper e superiori
      // le stringhe ammetti/rifiuta degli stati
      // devono matchare quelli definiti nell'enum StatoAmmissioneGraduatoria
      switch (action) {
        case "accept_isf_oper":
          // toggle action, 1 azioni al posto 1 in array 'azioni'
          if(data.item.Azioni.length == 3)
            data.item.Azioni.splice(1, 2, "reject_isf_oper")
          else
            data.item.Azioni.splice(1, 1, "reject_isf_oper")
          this.setStatoInTabella(data.item.idProgetto, "Ammesso da ISF")
          break;
        case "reject_isf_oper":
          // toggle action, 1 azioni al posto 1 in array 'azioni'
          if(data.item.Azioni.length == 3)
            data.item.Azioni.splice(1, 2, "accept_isf_oper")
          else
            data.item.Azioni.splice(1, 1, "accept_isf_oper")
          this.setStatoInTabella(data.item.idProgetto, "Non ammesso da ISF")
          break;
        case 'view':
          this.$router.push({
            name: "schedaConvenzione",
            params: { idProgetto: data.item.idProgetto },
          });
          break;
        default:
          console.error("Unknown action!", action);
          break;
      }
      this.extractData();
    },
    setTabSelection(name){
      console.log("Click sul tab =", name);
      this.tabSelection = name
    },
    hasProgetti(scheda) {
      return scheda &&
        scheda.progetti &&
        scheda.progetti.length > 0
    },
    extractData: function() {

      // get actions per la tabella
      const azioni = this.$getActionsGraduatoria(this.scheda);
      const localActions = azioni.filter((item) => {
        return this.actions.indexOf(item) != -1;
      });
      
      this.tabledata.rows = [];
      this.schedaConvenzione.progetti.forEach((i) => {
        if (localActions.length > 0) {  

          // copio l'array eliminando il toggle graduatoria superfluo
          let arr = localActions.filter(p => p !== 'edit_in_graduatoria')
          if (localActions.includes('edit_in_graduatoria')) {
            if(i.ammesso === "Ammesso da sistema"){
              arr.push("accept_isf_oper")
              arr.push("reject_isf_oper")
            }
            else if (i.ammesso === "Ammesso da ISF")
              arr.push("reject_isf_oper")
            else
              arr.push("accept_isf_oper")
          }
          let newRow = {
            idProgetto: i.idProgetto,
            titoloProgetto: i.titoloProgetto,
            punteggioValutazione: i.punteggioValutazione,
            beneficiario: i.idBeneficiario,
            ammesso: i.ammesso,
            dataAmmissioneAStipula: i.dataAmmissioneAStipula,
            Azioni: arr,
          };
          this.tabledata.rows.push(newRow);
        } else {
            console.log("NO actions, skip element=");
        }
      })
    },
    mappingTooltip(keyMapping) {
      let objMapping = {
        accept_isf_oper: "Ammetti progetto",
        reject_isf_oper: "Rifiuta progetto",
        view: "Visualizza Dati Progetto",
      };
      let temp = objMapping[keyMapping];
      if (!temp) return "";
      return temp;
    },
    getFilterFields() {
      let filterFields = [];
      for (let item of this.tabledata.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
    beneficiarioFormatter(idBeneficiario) {
      if (!idBeneficiario) 
        return "Sconosciuto";
      return utils.formatBeneficiari(idBeneficiario);
    },   
    //Costruisce la tabella riuassuntiva del bando
    setTabellaRiassuntiva() {
      this.resumeTableData.rows = []
      let scheda = this.getSchedaConvenzione({ idBando: this.$route.params.idBando })
      const row = {
        Tipologia: scheda.tipoProgetto,
        TitoloBando: scheda.titoloBando,
        StrumentoFinanziario: scheda.strumentoFinanziario,
        ObiettivoNazionale: scheda.obiettivoNazionale,
        ObiettivoSpecifico: scheda.obiettivoSpecifico
      }
      this.resumeTableData.rows.push(row)
    }
  }, // close 'methods'

};
</script>
