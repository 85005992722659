<template>
  <div id="attivitaInfo">
    <DynamicTable2 :idTablePdf="idTablePdf" :tdata="tabledata" :doAction="this.doAction" :actions="this.actions"/>
    <modal :hidden="!showModal" :buttons="this.buttons" @close="showModal = false">
      <div class="row-title" slot="header">{{modalHeader}}</div>
      <div slot="body">
        <DynamicTable2 :tdata="this.modalData"/>
      </div>
    </modal>
    <modalForm :fdata="this.editFormData"
               :sch="this.scheda"
               :hidden="!showEditForm"
               :buttons="this.buttons"
               :cfg="incomingData.schedaAssessment.dataEntryConfiguration"
               @close="showEditForm = false"
               @salvaRecord="aggiornaRiga">
      <div class="row-title" slot="header">{{modalHeader}}</div>
    </modalForm>
  </div>
</template>


<script>
import DynamicTable2 from "@/components/dynamicTable2.vue";
import modalForm from "@/components/modalForm.vue";
import modal from "@/components/modal.vue";
import tool from '@/helpers/tools.js'
import notify from "@/helpers/notifications.js"
import mapUtils from '@/helpers/mapUtils.js'

export default {
  name: "attivitaInfo",
  components: {
    DynamicTable2,
    modalForm,
    modal
  },

  props: {
    idTablePdf: String,
    incomingData: null,
    salvaSchedaToStore: Function,
    viewOnlyMode: Function,
    tabName: String
  },
  watch: {
    tabName: function() {
      if(this.$props.tabName === "risultati") {
        this.extractData();
      }
    }
  },
  mounted() {
    this.showEditForm = false;
    this.showModal = false;
    this.extractData();
  },
  data() {
    return {
      showEditForm: false,
      editFormData: {},
      showModal: true,
      modalData: {},
      actions: ['edit'],
      tabledata: {
        header: ["Attività Informazione e Pubblicità"],
        rows: [
          {
            content: {
              "Attività Informazione e Pubblicità": "Non disponibile"
            }
          }
        ]
      },
      buttons: [
        {
          name: 'Salva',
          action: 'salvaRecord',
          show: true,
          param: "edit"
        }
      ],
      modalHeader: "",
      scheda: {} 
    };
  },
  methods: {
     aggiornaRiga(actionFromModal) {
      let clonedScheda = mapUtils.aggiornaScheda(actionFromModal, tool.genericFunctions.cloneObject(this.incomingData.schedaAssessment));
      this.salvaSchedaToStore(clonedScheda);
      this.showEditForm = false;
      this.extractData();
    },

    doAction(actionToDo, index, item) {

        switch(actionToDo) {
          case 'edit':
            this.showEditForm = true;
            this.editFormData = mapUtils.editFormDataFromRiga(item);
            this.modalHeader = "Attività Informazione e Pubblicità";
            this.buttons[0].show=true;
            break;
          default:
            notify.error("Errore","Azione non gestita");
            console.error("attivitaInfo: azione='"+actionToDo+"' non gestita!?");
            break;
        }
    },

    extractData() {
      if(!this.incomingData){
            console.log("attivitaInfo: Invalid input Data in attivitaInfo!?");
            return;
      }
      this.scheda = this.incomingData.schedaAssessment;
      let inputData = this.incomingData.schedaAssessment;
      
      this.viewOnlyMode(this.incomingData, this.actions);
      
      let rows = [];
    
      let row = {};
      const mappa = inputData.dataEntryConfiguration;
      let colIds = [];
      //definisco gli id delle colonne da visualizzare
      colIds.push("['attivitaInformazione']");
      
      let myConf = mapUtils.costruisciConfigurazione(colIds, mappa);
      //console.log("costruita configurazione ", myConf);

      row = {
            content: {},
            conf : myConf
      };

      //scrivo il valore dei vari campi (stesso ordine usato sopra) con l'ID : valore
      row.content[colIds[0]] = inputData.attivitaInformazione;
        
      rows.push(row);
      
      this.tabledata.rows = rows;
      
    }
  }
};
</script>