import Vue from "vue";
import endpoints from "@/components/endpoints.vue";

const state = {
    schede: {}
};

const getters = {
    // restituisce il content della scheda
    getScheda(state) {
        return payload => {
            if(payload && payload.idScheda && state.schede[payload.idScheda]) {
                return state.schede[payload.idScheda].content;
            }
            return {};
        }
    },
    // predisposta per restituire eventualmente anche le taskinfo
    getSchedaComplete(state) {
        return (payload) => {
            if(payload && payload.idScheda && state.schede[payload.idScheda]) {
                return {
                    scheda: state.schede[payload.idScheda]
                }
            }
            return {};
        }
    },
};
const actions = {
    /* UNUSED
    retrieveSchedaByCodice(context, payload){
        context.commit('RESET');
        const codiceProgetto = payload.codiceProgetto;
        const url = endpoints.cronoprogProgetti(codiceProgetto)
        return Vue.prototype.$get(url).then(
            res => {
                context.commit('SET_SCHEDA', {idScheda: codiceProgetto, scheda: res});
                return res
            }
        )
    },
    */
    retrieveScheda(context, payload) {
        context.commit('RESET');
        const idProgetto = payload.idProgetto;
        const cronoprogKey = payload.cronoprogKey;
        const url = endpoints.cronoprog(idProgetto, cronoprogKey);
        let params = {
            branch: payload.branch
        };
        if(payload.idSchedaControllo)
            params.idSchedaControllo = payload.idSchedaControllo;
        return Vue.prototype.$get(url, params).then(
            result => {
                context.commit('SET_SCHEDA', {idScheda: idProgetto, scheda: result});
                return result;
            }
        );
    },
    // setta l'intera scheda
    setSchedaComplete(context, payload) {
        if(payload)
            context.commit('SET_SCHEDA', payload);
    },
    // setta solo il content della scheda
    setScheda(context, payload) {
        if(payload)
            context.commit('SET_SCHEDA_CONTENT', payload);
    },

};
const mutations = {
    RESET(state) {
        state.schede = {}
    },
    SET_SCHEDA(state, payload) {
        if(payload.idScheda)
            state.schede[payload.idScheda] = payload.scheda;
    },
    SET_SCHEDA_CONTENT(state, payload) {
        if(payload.idScheda && payload.scheda && state.schede[payload.idScheda]) {
            state.schede[payload.idScheda].content = payload.scheda;
        }
        else {
            console.error('SET_SCHEDA_CONTENT no idScheda or scheda', payload, state.schede[payload.idScheda]);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};