<template>
    <div>
        <WrapCollapses
          @creationAttivitaProgetto="canRefreshCronoProgramma()"
          @updateOrDeleteAttivitaProgetto="doBudgetRefresh()"
          :config="keyEntityTab"
        />
    </div>
</template>
<script>
import WrapCollapses from "@/components/WrapCollapses.vue";
export default {
  name: "attivitaDiProgetto",
  components: {
      WrapCollapses
  },
  props: {
    name: String,
    keyEntityTab: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.title = this.name;
  },
  data() {
    return {
      title: '',
    };
  },
  methods: {
    canRefreshCronoProgramma() {
      console.log("Hai creato una nuova attività, il tab CronoProgramma sarà refreshato")
      this.$emit('refreshTabCronoProgramma')
    },
    doBudgetRefresh() {
      console.log("Hai cancellato un'attività, il tab Budget sarà refreshato")
      this.$emit('refreshTabBudget')
    }
  }
}
</script>