import { JSONPath as jpath } from 'jsonpath-plus';
import tool from '@/helpers/tools.js'
import validation from '@/helpers/validations.js'
import utils from '@/helpers/utils.js'
import notify from "@/helpers/notifications.js"
import calculation from '@/helpers/calculations.js';
import Vue from "vue"

const replaceWithStarsRegex = /\[(\d+)\]/g;
const arrayRegex = /\[[\W\d]+\]/;
const indexRegex = /\b\d+\b/g;
const childrenRegex = /\['\w+'\]|\[[\W\d]+\]/g;
const lastIndexRegex = /\b\d+\b(?!.*\b\d+\b)/;
const nonDefinito = {
  key: "'_nessuno_'",
  value: 'Non definito/a'
  
};


function getMapElement(mappa, id) {
    let mapElement = mappa[id];
    if (!mapElement) {
      let defaultId = getGenericArrayKey(id);
      mapElement = mappa[defaultId];
    }
    return mapElement;
}

function getCronoprogIds(alfaId, dinamicIds) {
  if(Array.isArray(dinamicIds) && alfaId) {
    return dinamicIds.map(item => {
      return item.replace('*', "'" + alfaId + "'");
    })
  }
  return '';
}

function getCronoprogId(alfaId, dinamicId) {
  if(dinamicId && alfaId) {
    return dinamicId.replace('*', "'" + alfaId + "'");
  }
  return [];
}

function getGenericArrayKey(id) {
  //data una generica chiave, sostituisce le eventuali cifre (posizione i-esima nell'array) con *
  let defaultId = id.replace(replaceWithStarsRegex, "[*]");
  // se defaultId è uguale all'id di partenza e non contiene asterisci,
  // allora non c'erano indici di array fin dall'inizio: di conseguenza
  // assumo che l'id appartenga a cronoprog e quindi 
  // sostituisco per convenzione il secondo elemento dell'id con un asterisco
  if(id === defaultId && defaultId.indexOf('*') === -1 
    && !id.includes("['content']['disposizione']")  //non sto processando una chiave del tabellone disposizione di pagamento che ha chiavi che iniziano per...
    && (id.startsWith("['attivita']")               //applico la sostituzione solo per elementi di cronoprog che sappiamo avere chiavi che iniziano per...
        || id.startsWith("['procedure']") 
        || id.startsWith("['contratti']") 
        || id.startsWith("['pagamenti']")
        || id.startsWith("['inventario']"))) { 
    
      const matchToReplace = id.match(/'\w+'/g);
      if(matchToReplace.length > 1) {
        defaultId = id.replace(matchToReplace[1], '*');
      }
    
  }
  return defaultId;
}

function getSchedaValueFromMapElement(scheda, id) {
    let values = jpath('$'+id, scheda);
    if (values.length>1){
        console.error("!? trovato più di un valore per l'id="+id, values);
    }
    return values[0];
}

//ritorna tutti gli eventuali elementi trovati
function getSchedaValuesFromMapElement(scheda, id) {
  return jpath('$'+id, scheda);
}

//ritorna tutti gli eventuali valori trovati arricchiti da diversi metadati
function getSchedaValuesFromMapElementPlus(id, scheda) {
  return jpath({resultType: 'all'},'$'+id, scheda);
}

function getParentFromMapElement(scheda, id) {
    return jpath({resultType: 'parent'}, '$'+id, scheda);
}

// crea una configurazione di default, con il path corretto
function cloneConf(newId, mappa) {
  if(!newId || !mappa) {
    console.error('errore parametri in cloneConf', newId, mappa);
    return null;
  }
  let newConf = tool.genericFunctions.cloneObject(getMapElement(mappa, newId));
  // compatibilità con fase 3/4 scheda Progetto
  if(!newConf.data)
    newConf.data = {};
  // configuration reset
  newConf.data.oldValue = '';
  newConf.data.note = '';
  newConf.config.path = newId;
  if(newConf.data.deleted)
    delete newConf.data.deleted;
  if(newConf.data.added)
    delete newConf.data.added;
  if(newConf.data.editTag)
    delete newConf.data.editTag;


  return newConf;
}

// crea un campo con un valore di default a seconda del tipo
function cloneValue(newId, conf) {
  if(!newId || !conf) {
    console.error('errore parametri in cloneValue', newId, conf)
    return null;
  }

  let newValue = {};
  newValue.id = newId;
  // value reset
  switch(conf.config.type) {
    case 'date':
    case 'datetime':
      newValue.value = '';
      break;
    case 'int':
    case 'float':
    case 'currency':
    case 'number':
      newValue.value = '';
      break;
    case 'enum':
      // if(conf.config.enumValues)
      //   newValue.value = conf.config.enumValues[0];
      // else
        newValue.value = null;
      break;
    case 'boolean':
        newValue.value = false;
        break;
    default:
      newValue.value = '';
  }
  return newValue;
}

function convertiTipo2Stringa(type){
  if (type==="text" || type==="string")
      return "testo";
  else if (type==="currency")
      return "valuta";
  else if (type==="float")
      return "numero decimale";
  else if (type==="int")
      return "numero intero";
  else if (type==="date")
      return "data";
  else if (type==="datetime")
      return "data ed orario";
  else if (type==="enum")
      return "enumerazione";
  else if (type==="boolean")
      return "si/no";
  else
      return "sconosciuto";
}

function costruisciTabella(colIds, mappa, scheda) {
    if(!colIds || !Array.isArray(colIds)
      || !mappa || !scheda){
      console.error("NULL value passed!? Impossibile costruire la tabella");
      return;
    }

    //ciclo le colonne per costruire l'header
    let myHeader = [];
    let myRows = [];
    
    for (const colId of colIds) {
              
      let item = getMapElement(mappa, colId);
      
      if(item){
        myHeader.push(item.config.label);
      } else {
        console.error("chiave "+colId+" non trovata nella mappa", mappa);
        //se non riesco a trovare nulla nella mappa metto l'ultima parte della chiave come label
        let indexStart = colId.lastIndexOf("[")+2;
        let indexStop = colId.lastIndexOf("]")-1;
        let lastField = colId.substring(indexStart, indexStop);
        myHeader.push(lastField);
      }
      
    }

    
    //cerco nella scheda per vedere se e quanti dati ci sono al path indicato
    //NB: ipotizzo che l'ultima colonna visualizzata sia quella al livello foglia (più profondo) degli array
    let values = getParentFromMapElement(scheda, colIds[colIds.length-1]);      
    //TODO: genera errore:"TypeError: Assegnazione a proprietà di sola lettura non consentita in modalità strict" su IE
    //console.log("trovati "+values.length+" elementi nell'array", values);
      
    
    if (values.length>0){
      
      for (let j=0; j < values.length; j++){
        let row = {
          content : {},
          conf : {}
        };

        let myContent = {};
        let myMaps = {};
          
        for (const colId of colIds) {
          
          let realId = colId.replace("*", j);
          //console.log("realId ", realId);
          //cerco nella mappa per chiave [0...N] per ricavare gli eventuali dati modificati
          let item = getMapElement(mappa, realId);
          
          //coppia di id : valore sia per il dato che per la configurazione
          myContent[realId] = getSchedaValueFromMapElement(scheda, realId);
            
          if (item.config.path.indexOf("*") === -1) {
            myMaps[realId] = item;
          } else {
            let newItem = cloneConf(realId, mappa);
            //sostituisco il map element generico con quello della riga i-esima
            // item.config.path = item.config.path.replace("*",j);  

            myMaps[realId] = newItem;
            //NB: funziona solo se è presente un solo asterisco
            //TODO: si dovrebbe capire come gestire l'eventuale presenza di più asterisco!?
          }
        }
        

        row['content'] = myContent;
        row['conf'] = myMaps;

        myRows.push(row);
      }

    }

    return {
      header: myHeader,
      rows: myRows
    };
}

function costruisciConfigurazione(colIds, mappa){

  if(!colIds|| !Array.isArray(colIds) || !mappa){
      console.error("NULL value passed!? Impossibile costruire la configurazione");
      return;
  }

  let myMaps = {};
        
  for (let realId of colIds){

    if (realId.indexOf("*") !== -1){
      console.log("La chiave passata contiene asterischi", realId);
    }
    //cerco nella mappa per chiave [0...N] per ricavare gli eventuali dati modificati
    let item = getMapElement(mappa, realId);
    
    if (item) {
      let newItem = tool.genericFunctions.cloneObject(item);
      if(newItem.config.path.indexOf("*") !== -1) { //al posto della chiave specifica mi è stata ritornata una generica
        //rinomino il path generico con la chiave reale
        // console.log("*** elemento generico di array");
        newItem.config.path=realId;
      }

      myMaps[realId] = newItem;
    } else {
      //chiave usata per un campo calcolato/visualizzato che non esiste concretamente sulla base dati
      console.log("chiave '"+realId+"' non trovata nella mappa delle configurazioni", mappa);
      //creo una configurazione di base cablata
      let newConf = utils.createField();
      //rinomino il path generico con la chiave reale
      newConf.config.path=realId;
      myMaps[realId] = newConf; 
      console.log("creato ex-novo mapElement ", myMaps[realId]);
    }
  }

  return myMaps;
}

function recursiveElement(child, nextElementArray) {
  if(nextElementArray.length === 0)
    return child;
  let name = nextElementArray.pop();
  let obj = {};
  obj[name] = child;
  return recursiveElement(obj, nextElementArray);
}

function createEditTagCronoProg(scheda, mappa, chiave, rootId, componentName, actionFromModal) {
  let editTag = '';
  if(!mappa || !chiave || chiave.indexOf('*')  !== -1) {
    console.log("ID", chiave)
    console.error('createEditTagCronoProg bad params (no stars allowed)', chiave, mappa);
    return editTag;
  }

  let tagArray = [];
  const mychildrenRegex = /\w+|\[[\W\d]+\]/g;
  const children = chiave.match(mychildrenRegex)
  const length_child = children.length
  let key = children[1]
  let field = children[length_child - 1]
  if(componentName)
    tagArray.push(componentName, '->');
  
  let progressiveRow = getProgressivoByEntity(scheda, rootId, key, chiave, actionFromModal)
  let riferim_progressive = rootId === 'pagamenti' ? ' Fattura: ' : ' Attività: '
  tagArray.push("(" + riferim_progressive + progressiveRow + ")" + ' -> ' + field);
  for(const item of tagArray)
    editTag += item;

  return editTag;
}

function createEditTag1(mappa, chiave, componentName) {
    let editTag = '';
    if(!mappa || !chiave || chiave.indexOf('*')  !== -1 ) {
      console.error('createEditTag1 bad params (no stars allowed)', chiave, mappa);
      return editTag;
    }
    
  // questa regex crea un array di campi e indici di array
    const children = chiave.match(childrenRegex);
    let id = '';
    let tagArray = [];
    if(componentName)
      tagArray.push(componentName, '->');
    let skip = false;
    for(const token of children) {
      // costruisco un id incrementale, aggiungendo di volta in
      // volta un elemento di "children"
      id += token;
      // se stiamo prendendo in considerazione un indice
      // lo riporto nel tagArray tra parentesi; i valori partono da 1
      if(token.match(indexRegex)) {
        let index = parseInt(token.match(/\d+/)[0]) + 1;
        tagArray.push('(' + index + ')', '->');
        continue;
      }
      const mapElement = getMapElement(mappa, id);
      // console.log('check id', id, mapElement);
      // qui sostituisco la entry basata sul nome del campo con
      // la relativa label presa da arrayDep
      // l'intero tag viene saltato se il campo è readonly o SEMPRE nascosto
      if(mapElement && (mapElement.config.readonly || 
        (mapElement.config.hiddenRule && mapElement.config.hiddenRule.match('alwaysHidden'))) ) {
        skip = true;
        break;
      }
      /* TODO SISTEMARE CON ARRAYDEP
      else if(mapElement) {
        if(mapElement.config.arrayDep) {
          // WARNING: gestisce solo il primo elemento di arrayDep
          // modificare se si prevedono più dipendenze
          let depId = mapElement.config.arrayDep[0];
          // vado avanti se la dipendenza è l'elemento stesso (es. idAttivita)
          if(depId === id) {
            continue;
          }
          let mapEl = getMapElement(mappa, depId);
          // se trovo una dipendenza, la aggiungo alla posizione 2,
          // cioè dopo il nome del tab/collapse e il simbolo '->'
          tagArray.splice(2, 0, mapEl.config.label);
        }
        // altrimenti viene inserita la label nel tagArray
        tagArray.push(mapElement.config.label);
      }
      */
    }
    if(!skip) {
      if(tagArray.length > 1 && tagArray[0] === 'progetto')
        tagArray.splice(0,1);
      
      for(const item of tagArray)
        editTag += item;
    }
  
    return editTag;
}



function createEditTag(scheda, tagColIds, conf, key) {
  if(!scheda || !tagColIds || !Array.isArray(tagColIds) || ! conf || !key)
    return;
  
  conf.data.editTag ="";
  // costruisce le informazioni aggiuntive per il tab invio
  let indexes = [];
  for(let m of key.match(/(\[(\d+)\])/g)) { // costruisce un array di indici da sostituire
    indexes.push(m.replace('[','').replace(']',''));
  }
  for(let path of tagColIds) {
    let iof = path.indexOf('*');
    let jj = 0;
    while(iof !== -1 && jj < indexes.length) {

      let index = indexes[jj];
      path = path.replace("*", index);
      jj++;
      iof = path.indexOf('*');
    }

    let value = jpath('$' + path, scheda)[0];
    if(value == undefined || value == null)
      continue;
    let label = getMapElement(scheda.dataEntryConfiguration, path).config.label;
    conf.data.editTag += label + ': ' + value + '->';
  }
}


const replace_nth = function (s, f, r, n) {
    // From the given string s, replace f with r of nth occurrence
    return s.replace(RegExp("^(?:.*?" + f + "){" + n + "}"), x => x.replace(RegExp(f + "$"), r));
};

function singleDepV1(key, actionFromModal, scheda, dep) {
  let newId = key;
  const idAttivitaPath = "$['progetto']['cronoProgramma'][*]['idAttivita']";
  let newIndex, value;
  // per ogni elemento di actionModal
  for(const obj of Object.values(actionFromModal.content)) {
      // presa la chiave con gli asterischi, se è quella specificata dal tabellone
      // allora prende l'indice (= value -1)
      const id = getGenericArrayKey(obj.id);

      if(id === dep) {
        value = obj.value;
        break;
      }
  }

  // sostituisce l'indice nel posto giusto
  if(value != undefined) {
    const indexArr = jpath(idAttivitaPath, scheda);
    newIndex = indexArr.indexOf(value);
    if(newIndex !== -1) {
      newId = replace_nth(newId, "\\*", newIndex, 1);
    }
    else {
      console.error('singleDepV1 index not found', value, dep, indexArr);
      throw new Error('singleDepV1 index not found, value: ' + value + ' dep: ' + dep);  
    }
  }
  else {
    console.error('singleDepV1 unknown error', key, actionFromModal);
    throw new Error('singleDepV1 unknown error for key: ' + key);
  }
  return lastArrayDep(newId, scheda);
}

function lastArrayDep(newId, scheda) {
  // here fix last index
  // qui invece sostituisce l'ultimo indice con la lunghezza del rispettivo array
  const starMatch = newId.match(/\*/g);
  if(starMatch && starMatch.length > 0) {
    if(starMatch.length === 1) {
      const currentElements = jpath('$' + newId, scheda);
      const lastIndex = currentElements.length;
      newId = newId.replace('*', lastIndex);
    }
    else {
      console.error('lastArrayDep multiple index, cannot update', newId);
      throw new Error('lastArrayDep multiple index, cannot update, newId: ' + newId);
    }
  }
  return newId;
}

// calcola gli indici degli array per i campi provenienti da actionModal
// sulla base delle dipendenze specificate nel tabellone
function computeArrayDeps(key, actionFromModal, updatedConfiguration, scheda) {
  if(!key || !actionFromModal || !updatedConfiguration || !scheda) {
    console.error('computeArrayDeps bad params', key, updatedConfiguration, scheda);
    throw new Error('computeArrayDeps bad params, key: '+key);
  }

  let newId, dep;
  if(updatedConfiguration.config.arrayDepRule) {
    switch(updatedConfiguration.config.arrayDepRule) {
      case 'fornitureDepV1':
        dep = "['progetto']['cronoProgramma'][*]['forniture'][*]['idAttivita']";
        newId = singleDepV1(key, actionFromModal, scheda, dep);
        break;
      case 'proceduraDepV1':
        dep = "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['idAttivita']";
        newId = singleDepV1(key, actionFromModal, scheda, dep);
        break;
      case 'costiPersonaleDepV1':
        dep = "['progetto']['cronoProgramma'][*]['costiPersonale'][*]['idAttivita']";
        newId = singleDepV1(key, actionFromModal, scheda, dep);
        break;
      case 'costiViaggioDepV1':
        dep = "['progetto']['cronoProgramma'][*]['costiViaggio'][*]['idAttivita']";
        newId = singleDepV1(key, actionFromModal, scheda, dep);
        break;
      case 'costiServiziFornitureDepV1':
        dep = "['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]['idAttivita']";
        newId = singleDepV1(key, actionFromModal, scheda, dep);
        break;
      case 'costiSpeseGaraDepV1':
        dep = "['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]['idAttivita']";
        newId = singleDepV1(key, actionFromModal, scheda, dep);
        break;
      case 'costiInformazioneDepV1':
        dep = "['progetto']['cronoProgramma'][*]['costiInformazione'][*]['idAttivita']";
        newId = singleDepV1(key, actionFromModal, scheda, dep);
        break;
      case 'costiManutenzioneDepV1':
        dep = "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]['idAttivita']";
        newId = singleDepV1(key, actionFromModal, scheda, dep);
        break;
      case 'costiSupportoTecnicoDepV1':
        dep = "['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]['idAttivita']";
        newId = singleDepV1(key, actionFromModal, scheda, dep);
        break;
      default:
        console.error('computeArrayDeps unknown function', updatedConfiguration);
        throw new Error('computeArrayDeps unkwnown function: ' + updatedConfiguration.config.arrayDepRule);
    }
  } else {
    newId = lastArrayDep(key, scheda);
  }

  // una volta completata la nuova chiave, viene creata la nuova
  // coppia (nuovaChiave: oggetto) e viene eliminata la precedente
  // sia nel dato che nella configurazione
  actionFromModal.content[newId] = actionFromModal.content[key];
  delete actionFromModal.content[key];
  actionFromModal.content[newId].id = newId;

  actionFromModal.conf[newId] = actionFromModal.conf[key];
  delete actionFromModal.conf[key];
  actionFromModal.conf[newId].config.path = newId;

  return {
    actionFromModal: actionFromModal,
    key: newId
  }
}

function updateComponent(actionFromModal, scheda, rowObj, mappa, actions, componentName, optionalObj) {
  
  let schedaComplete = scheda.schedaProgetto ? scheda.schedaProgetto : scheda
  let clonedScheda = tool.genericFunctions.cloneObject(schedaComplete);

    for(let [incomingKey, field] of Object.entries(actionFromModal.content)) {
        let key = incomingKey;

        // const mapElement = getMapElement(mappa, key);
        // ritorno al valore non formattato (ad esempio in caso di currency)
        // let updatedValue = utils.unformatOutput(mapElement, field.value);
        let updatedValue = field.value;
        
        
        let updatedConfiguration = actionFromModal.conf[key];
        updatedConfiguration.config.path = key;
        // la modifica della if sottostante consente la gestione dei campi che 
        // non hanno dipendenza di array (ad esempio costi indiretti)
        if(key.indexOf('*') !== -1) {
          const result = computeArrayDeps(key, actionFromModal, updatedConfiguration, clonedScheda);
          actionFromModal = result.actionFromModal;
          key = result.key;

        }

        if(updatedConfiguration.config.readonly) {
          if(updatedConfiguration.config.calculationRule) {
            updatedValue = calculation.calculateValue(updatedConfiguration, clonedScheda, key, null, optionalObj);
          }/* 
          WARNING: commentato per consentire funzionamento del type degli indicatori, non dovrebbe 
                    creare problemi agli altri componenti, solo maggiore computazione
          else {
            continue;
          }*/
        }
        /* // TASK #13750: commentato per consentire la persistenza delle note sempre
        if(updatedValue == undefined) {
            // nessuna azione se il nuovo valore è undefined
            continue;
        }
        */
        // TODO sistemare/aggiungere
        // if(currentTab && mainTab && currentTab !== mainTab) { // WARNING i nomi dei tab devono essere consistenti col tabellone
        //     console.log('skip edit (not in main tab)) for field: ', updatedConfiguration.config.label);
        //     continue;
        // }
        
        let jPathResult = jpath({resultType: 'all'}, '$'+key, clonedScheda)[0];
        let parent;
        let fieldToUpdate;
        if(jPathResult)
        {
            parent = jPathResult.parent;
            fieldToUpdate = jPathResult.parentProperty;
        }
        if(!parent) {
            const retVal = createElementFromScratch(key, clonedScheda, mappa);
            parent = retVal.parent;
            fieldToUpdate = retVal.fieldName;
            clonedScheda = retVal.scheda;
        }

        let config = clonedScheda.dataEntryConfiguration;
        if(!config) {
            config = {};
        }

        // update configuration
        if(!config[key]) {
            config[key] = {};
        }

        if(parent[fieldToUpdate] !== updatedValue && 
            !((parent[fieldToUpdate] == null || parent[fieldToUpdate] == undefined) && updatedValue === '')
        ) { // se il campo è stato modificato (tranne se settiamo a stringa vuota un campo null o undefined)
        // allora controllo la configurazione

              
                updatedConfiguration.data.edited = true; // se è la prima modifica setto il flag edited e salvo il valore originale
                updatedConfiguration.data.oldValue = parent[fieldToUpdate];
                updatedConfiguration.data.editTag = createEditTag1(mappa, key, componentName);
              // }

            /*TODO: migliorare questo controllo, al momento non funziona bene su progetti appena creati...
              } else {
                if(updatedValue == updatedConfiguration.data.oldValue || // se è stato riportato il valore iniziale (anche se nullo)
                ((updatedConfiguration.data.oldValue == null || updatedConfiguration.data.oldValue == undefined) && updatedValue === '')
                ) {
                // se il valore era già stato modificato
                updatedConfiguration.data.oldValue = null;              // e stiamo riportando il valore all'originale
                updatedConfiguration.data.edited = false;          // ripristino i valori all'originale e cancello la nota
                updatedConfiguration.data.note = '';                // warning: solo == e non === altrimenti problemi di typeof
                }
            }*/

            parent[fieldToUpdate] = updatedValue; // setto il nuovo valore
            if(updatedConfiguration.config.canBeSubstancial) {
                config[key].data = updatedConfiguration.data; // configurazione prima del controllo substancial
                clonedScheda.dataEntryConfiguration = config;
                updatedConfiguration.data.isSubstancial = validation.substancialValidationProgetto(key, clonedScheda, mappa, updatedValue);
            }

            config[key].data = updatedConfiguration.data; // configurazione aggiornata
        }
        /* // TASK #13750: commentato per consentire la persistenza delle note sempre
        else {
            if(!config[key].data) {
              config[key].data = {};
            }

          // aggiorno comunque la nota a prescindere dalla modifica
          // if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
          config[key].data.note = updatedConfiguration.data.note;
        }*/
        // TASK #13750: note sempre persistite
        if(!config[key].data) {
          config[key].data = {};
        }
        config[key].data.note = updatedConfiguration.data.note;
        clonedScheda.dataEntryConfiguration = config;
        // update displayed data
        if(rowObj) {
          const retVal = setupComponentRow(rowObj, key, updatedValue, mappa, clonedScheda, scheda.taskInfo, actions);
          rowObj = retVal.rowObj;
        }
    }

    return {
      clonedScheda: clonedScheda,
      rowObj: rowObj
    }
    
}

function setupComponentRow(rowObj, realId, value, mappa, scheda, taskInfo, actions) {

    const retVal = {
      skip: false,
      rowObj
    };
    const dataMap = scheda.dataEntryConfiguration;
    let data = {};
    if(dataMap && dataMap[realId] && dataMap[realId].data)
        data = dataMap[realId].data;
  
    if(data.deleted && !data.edited) {
      retVal.skip = true;
      return retVal;
    }
    // 1) get formatted value
    const idWithStars = getGenericArrayKey(realId);
    let mapElement = getMapElement(mappa, realId);
    if(!mapElement) {
        mapElement = Vue.prototype.$getDefaultConfig();
    }

    if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
      mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda);
    }

    const formattedVal = value;

    rowObj[idWithStars] = {
        id: realId,
        value: formattedVal
    }

    // 2) setup note and color variants
    if(data.deleted) {
        rowObj._rowVariant = 'secondary';
        rowObj.edited = true;
        rowObj.deleted = true;
    }
    else if(data.added && !Vue.prototype.$disableEditAddColors(scheda)) {
        rowObj._rowVariant = 'success';
        rowObj._cellVariants = {};
        rowObj.edited = true;
        rowObj.added = true;
    }
    else if(data.edited && !Vue.prototype.$disableEditAddColors(scheda)) {
        if(data.oldValue != null) {
            rowObj._cellVariants[idWithStars] = data.isSubstancial ? 'danger': 'warning';
            rowObj.edited = true;
        }
    }
    rowObj[idWithStars].note = data.note;
    
    // 3) setup actions
    const schedaComplete = {
      schedaProgetto: scheda,
      taskInfo: taskInfo
    }
    // questo controllo ottimizza il calcolo delle azioni eseguendolo una sola volta
    // ma soprattutto elimina la sovrascrittura delle azioni quando ci sono 
    // campi calcolati che quindi non vengono messi a deleted, ad es. il 
    // totale dei vari budget.
    // WARNING: presuppone che il primo elemento della riga sia "valido",
    // nel senso che ha una parte "data" con info sufficienti
    // per consentire un corretto calcolo delle action
    if(!rowObj.Azione || rowObj.Azione.length === 0)
      rowObj.Azione = computeActions(data, schedaComplete, actions);
    retVal.rowObj = rowObj;
    return retVal;
}

function computeActions(data, schedaComplete, possibleActions) {
  let retVal = [];
  if(!schedaComplete || !possibleActions) {
    console.error('computeActions bad params', data, schedaComplete, possibleActions);
    return retVal;
  }
  const allowedActions = Vue.prototype.$projectGetActions(schedaComplete);
  // commentando la riga sottostante consento la view per gli array tipo iter amministrativo
  // if(allowedActions.indexOf('edit_in_list_progetti') !== -1) {
    const action = 'view';
    if(possibleActions.indexOf(action) !== -1)
      retVal.push(action);
  // }
  if(!data || !data.deleted) {
    if(allowedActions.indexOf('edit') !== -1) {
      let action1 = 'edit';
      if(possibleActions.indexOf(action1) !== -1)
        retVal.push(action1);
      action1 = 'delete';
      if(possibleActions.indexOf(action1) !== -1)
        retVal.push(action1);
    }
  }
  return retVal;
}

function updateComponent2(actionFromModal, scheda, rowObj, mappa, componentConfig, optionalObj) { //, rimodulated
    
    let clonedScheda = tool.genericFunctions.cloneObject(scheda);
    const rootId = componentConfig.rootId;
    const possibleActions = componentConfig.defaultActions;
    const allowedActions = componentConfig.allowedActions;
    const componentName = componentConfig.title;

    for(let [key, field] of Object.entries(actionFromModal.content)) {
        // WARNING: si suppone che la stringa da sostituire con asterischi si trovi sempre 
        // nel secondo elemento dell'id
        // es: "['attivita'][*]['content']['idAttivita']"
        // oppure "['procedure'][*]['content']['baseAsta']"
        // console.log('ricevuta', key, field, clonedScheda);
        const mapElement = getMapElement(mappa, key);
        // se il campo è un relative aggiorno il rel e passo avanti senza modificare
        // il valore del campo stesso (essendo questo solo un riferimento)
        // es.: pagamenti ha come relative il contratto, quindi 
        // dalla modale devo modificare il link al contratto (relative),
        // non il riferimentoContratto (che è appunto un campo dell'oggetto contratto)
        if(field.isRelId) {
          const relIdKeyWithStars = getGenericArrayKey(key);
          // il vecchio valore del rel è isRelId, il nuovo valore è field.value,
          // l'id dell'elemento è rowObj.rowId, il tipo di elemento è rootId,
          // il tipo di rel è relids[relIdKeyWithStars].type
          const elem = clonedScheda[rootId][rowObj.rowId];
          const relId = componentConfig.relIds[relIdKeyWithStars];
          if(relId && relId.type && elem && elem.rel) {
            // se abbiamo aggiunto una nuvoa riga, allora creo il relative di 
            // tipo relId.type (es. per pagamenti credo il relative "contratti")
            if(!elem.rel[relId.type]) {
              elem.rel[relId.type] = [];
            }

            const indexToReplace = elem.rel[relId.type].indexOf(field.isRelId)
            // se l'indice vecchio esiste, allora lo sostituiamo col nuovo
            if(indexToReplace !== -1) {
               elem.rel[relId.type].splice(indexToReplace, 1, field.value)
            }
            else {
              // se invece non esiste, lo aggiungiamo all'array di relatives
              elem.rel[relId.type].push(field.value);
            }

            const candidateRel = actionFromModal.conf[key].config.enumValues.filter(item => {
              return item.value === field.value;
            })
            // aggiorno il link al relativa nella riga della tabella
            // con la label del relative, preso dagli enumvalues
            if(candidateRel && candidateRel.length === 1) {
              const key1 = key.replace('*', field.value);
              rowObj = setupComponentRow2(
                rowObj,
                key1,
                mapElement,
                candidateRel[0].label,
                clonedScheda,
                possibleActions,
                allowedActions,
                null
              );
            }
            continue;
          }
        }
        
        // ritorno al valore non formattato (ad esempio in caso di currency)
        // let updatedValue = utils.unformatOutput(mapElement, field.value);
        let updatedValue = field.value;

        let updatedConfiguration = actionFromModal.conf[key];
        updatedConfiguration.config.path = key;
        // la modifica della if sottostante consente la gestione dei campi che 
        // non hanno dipendenza di array (ad esempio costi indiretti)
        if(key.indexOf('*') !== -1) {
          const result = computeArrayDeps(key, actionFromModal, updatedConfiguration, clonedScheda);
          actionFromModal = result.actionFromModal;
          key = result.key;
        }

        // se l'elemento è readonly l'aggiornamento è più semplice
        if(updatedConfiguration.config.readonly) {
          // se c'è una regola di calcolo, la applico per determinare il valore aggiornato
          if(updatedConfiguration.config.calculationRule) {
            updatedValue = calculation.calculateValue(updatedConfiguration, clonedScheda, key, null, optionalObj);
          } else if (!key.includes(rootId)) {
            // altrimenti, se si tratta di un campo appartenente ad un altro oggetto
            // (ad es. idAttivita per una riga di inventario)
            // allora semplicemente setto questo valore nella riga della tabella senza toccare la scheda
            rowObj = setupComponentRow2(
              rowObj,
              key,
              mapElement,
              updatedValue,
              clonedScheda,
              possibleActions,
              allowedActions,
              null
            );
            continue;
          }
          else {
            // altrimenti passo al prossimo elemento
            continue;
          }
        }
        /* // TASK #13750: commentato per consentire la persistenza delle note sempre
        if(updatedValue == undefined) {
            // nessuna azione se il nuovo valore è undefined
            const retVal = createElementFromScratch(key, clonedScheda, mappa);
            clonedScheda = retVal.scheda;
            rowObj = setupComponentRow2(rowObj, key, mapElement, updatedValue, clonedScheda, possibleActions, allowedActions, null);
            continue;
        }
        */
        // TODO sistemare/aggiungere
        // if(currentTab && mainTab && currentTab !== mainTab) { // WARNING i nomi dei tab devono essere consistenti col tabellone
        //     console.log('skip edit (not in main tab)) for field: ', updatedConfiguration.config.label);
        //     continue;
        // }
        
        let jPathResult = jpath({resultType: 'all'}, '$'+key, clonedScheda)[0];
        let parent;
        let fieldToUpdate;
        if(jPathResult)
        {
            parent = jPathResult.parent;
            fieldToUpdate = jPathResult.parentProperty;
        }
        if(!parent) {
            const retVal = createElementFromScratch(key, clonedScheda, mappa);
            parent = retVal.parent;
            fieldToUpdate = retVal.fieldName;
            clonedScheda = retVal.scheda;
        }

        let config = clonedScheda.dataEntryConfiguration;
        if(!config) {
            config = {};
        }

        // update configuration
        if(!config[key]) {
            config[key] = {};
        }

        if(parent[fieldToUpdate] !== updatedValue && 
            !((parent[fieldToUpdate] == null || parent[fieldToUpdate] == undefined) && updatedValue === '')
        ) { // se il campo è stato modificato (tranne se settiamo a stringa vuota un campo null o undefined)
        // allora controllo la configurazione
            //if(!updatedConfiguration.data.edited) {
              
              updatedConfiguration.data.edited = true; // se è la prima modifica setto il flag edited e salvo il valore originale
              updatedConfiguration.data.oldValue = parent[fieldToUpdate];
              // updatedConfiguration.data.editTag = createEditTag1(mappa, key, componentName)
              updatedConfiguration.data.editTag = createEditTagCronoProg(scheda, mappa, key, componentConfig.rootId, componentName, actionFromModal)

            /*TODO: migliorare questo controllo, al momento non funziona bene su progetti appena creati...
              } else {
                if(updatedValue == updatedConfiguration.data.oldValue || // se è stato riportato il valore iniziale (anche se nullo)
                ((updatedConfiguration.data.oldValue == null || updatedConfiguration.data.oldValue == undefined) && updatedValue === '')
                ) {
                // se il valore era già stato modificato
                updatedConfiguration.data.oldValue = null;              // e stiamo riportando il valore all'originale
                updatedConfiguration.data.edited = false;          // ripristino i valori all'originale e cancello la nota
                updatedConfiguration.data.note = '';                // warning: solo == e non === altrimenti problemi di typeof
                }
            }*/

            parent[fieldToUpdate] = updatedValue; // setto il nuovo valore
            if(updatedConfiguration.config.canBeSubstancial) {
                config[key].data = updatedConfiguration.data; // configurazione prima del controllo substancial
                clonedScheda.dataEntryConfiguration = config;
                updatedConfiguration.data.isSubstancial = validation.substancialValidationProgetto(key, clonedScheda, mappa, updatedValue);
            }

            config[key].data = updatedConfiguration.data; // configurazione aggiornata
        }
        /*
        else {
            if(!config[key].data) {
              config[key].data = {};
            }

          // aggiorno comunque la nota a prescindere dalla modifica
          // if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
          config[key].data.note = updatedConfiguration.data.note;
        }*/
        if(!config[key].data) {
          config[key].data = {};
        }
        config[key].data.note = updatedConfiguration.data.note;
        clonedScheda.dataEntryConfiguration = config;
        // update displayed data
        if(rowObj) {
          rowObj = setupComponentRow2(rowObj, key, mapElement, updatedValue, clonedScheda, possibleActions, allowedActions, null);
        }
    }

    return {
      clonedScheda: clonedScheda,
      rowObj: rowObj
    }
}

function setupComponentRow2(rowObj, realId, mapElement, value, scheda, possibleActions, allowedActions, options) {
    // 1) get formatted value
    const genericId = mapElement.config.path;
    if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
      mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda);
    }
    else if(options && options.idSchedaControllo && options.sottoTipoControllo === "2" && mapElement.config.type === 'flag') {
      // WARNING: codice cablato per i soli flag di pagamenti
      // questo codice serve a mostrare il flag nel tab Pagamenti fase 2
      const flagResults = jpath('$' + realId, scheda);
      // di default il pagamento non è inserito nella dichiarazione di spesa corrente
      value = 'NO';
      if(flagResults && flagResults.length > 0) {
        const flagVal = flagResults[0];
        // se il valore del flag coincide con l'id della dichiarazione di spesa corrente, allora
        // il pagamento è riferito a questa dichiarazione e verrà visualizzata la stringa 'SI'
        if(flagVal === options.idSchedaControllo)
          value = 'SI';
      }
    }
    const formattedVal = value;

    rowObj[genericId] = {
        id: realId,
        value: formattedVal
    }

    // 2) setup note and color variants
    const dataMap = scheda.dataEntryConfiguration;
    let data = {};
    if(dataMap && dataMap[realId] && dataMap[realId].data)
        data = dataMap[realId].data;

    if(data.added && !Vue.prototype.$disableEditAddColors(scheda)) {
        rowObj._rowVariant = 'success';
        rowObj._cellVariants = {};
        rowObj.edited = true;
        rowObj.added = true;
    }
    else if(data.edited && !Vue.prototype.$disableEditAddColors(scheda)) {  // && data.oldValue != null
      rowObj._cellVariants[genericId] = data.isSubstancial ? 'danger': 'warning';
      rowObj.edited = true;
    }

    rowObj[genericId].note = data.note;
    
    // 3) setup actions
    // questo controllo ottimizza il calcolo delle azioni eseguendolo una sola volta
    // ma soprattutto elimina la sovrascrittura delle azioni quando ci sono 
    // campi calcolati che quindi non vengono messi a deleted, ad es. il 
    // totale dei vari budget.
    // WARNING: presuppone che il primo elemento della riga sia "valido",
    // nel senso che ha una parte "data" con info sufficienti
    // per consentire un corretto calcolo delle action
    if(!rowObj.Azioni || rowObj.Azioni.length === 0)
      rowObj.Azioni = computeActions2(data, possibleActions, allowedActions);
    return rowObj;
}

function computeActions2(data, possibleActions, allowedActions) {
  let retVal = [];
  if(!possibleActions) {
    console.error('computeActions2 bad params', data, possibleActions);
    return retVal;
  }
  const action = 'view';
  if(possibleActions.indexOf(action) !== -1)
    retVal.push(action);

  if(allowedActions.indexOf('edit') !== -1) {
    let action1 = 'edit';
    if(possibleActions.indexOf(action1) !== -1)
      retVal.push(action1);
    action1 = 'delete';
    if(possibleActions.indexOf(action1) !== -1)
      retVal.push(action1);
  }
  return retVal;
}



//dal valore emesso in uscita da modalForm cerca la modifiche e le applica alla scheda monitoraggio
function aggiornaScheda(emitFromModal, clonedSchedaMonitoraggio, tagColIds, currentTab){
  
  if(!emitFromModal || !clonedSchedaMonitoraggio){
    console.error("aggiornaScheda: NULL param passed!?");
    return;
  }

  let actionFromModal = emitFromModal;

  for(let [key, field] of Object.entries(actionFromModal.content)) {
    let removeConf = false;
    let updatedConfiguration = actionFromModal.conf[key];
    if(updatedConfiguration.config.readonly)
      continue;

    let mainTab = updatedConfiguration.config.tab;
    if(currentTab && mainTab && currentTab !== mainTab) { // WARNING i nomi dei tab devono essere consistenti col tabellone
      console.log('skip edit (not in main tab)) for field: ', updatedConfiguration.config.label);
      continue;
    }

    let updatedValue = field.value;
    /* // TASK #13750: commentato per consentire la persistenza delle note sempre
    if(updatedValue == undefined) {
      // nessuna azione se il nuovo valore è undefined
      continue;
    }
    */ 
    let parent = jpath({resultType: 'parent'}, '$'+key, clonedSchedaMonitoraggio)[0];
    if(parent == undefined) {
      let arrayIndex = parseInt(key.match(/(\[(\d+)\])(?!.*\[(\d+)\])/g)[0].replace('[','').replace(']','')); // conserva l'indice dell'ultimo array della chiave
      let temp = key.replace(/(\[(\d+)\])(?!.*\[(\d+)\])/g, "[*]"); // trasforma in * l'ultimo indice di array

      let last = temp.lastIndexOf("[*]");
      let defaultKey = temp.substring(0,last); // chiave fino all'ultimo array

      temp = temp.substring(last);
      let nextElements = temp.match(/(\w*)(?='\])/g).filter((val) => {return val !== ''}); // elementi da creare dentro l'ultimo array
      let fieldName = nextElements[0]; // primo elemento dentro l'ultimo array
      let secondElementName = nextElements[1]; // elemento successivo annidato

      let previousElements = jpath({resultType: 'all'}, '$'+defaultKey, clonedSchedaMonitoraggio);
      if(previousElements.length === 0) {
        console.error('elemento non trovato', key, defaultKey);
        // TODO verificare notification
        notify.error(notify.strings.error, notify.strings.operationError('Modifica di ' + updatedConfiguration.config.label));
        return;
      }

      let parentArray = previousElements[0].parent; // ultimo array
      let arrayName = previousElements[0].parentProperty; // nome dell'ultimo array
      // let newElement = {};
      // console.log(nextElements, fieldName, arrayName, temp);
      if(!parentArray[arrayName])
        parentArray[arrayName] = []; // se non esiste l'array ne crea uno vuoto

      let ele = recursiveElement(null, nextElements); // crea ricorsivamente gli oggetti annidati

      if(parentArray[arrayName][arrayIndex] == undefined) {
         parentArray[arrayName].push(ele);
      }
      else { // TODO da fare ricorsivamente e non con una serie di if successivi
        let newElement = parentArray[arrayName][arrayIndex]; // elemento dell'array
        if(!newElement[fieldName]) // se l'lemento dell'array è vuoto aggiungo tutto l'oggetto annidato
          newElement[fieldName] = ele[fieldName];
        else { // aggiungo soltanto l'oggetto più interno WARNING: funziona solo con due livelli
          // console.log('qqq', fieldName, secondElementName, newElement[fieldName], ele[fieldName]);
          newElement[fieldName][secondElementName] = ele[fieldName][secondElementName];
        }

      }
      parent = jpath({resultType: 'parent'}, '$'+key, clonedSchedaMonitoraggio)[0];
    }

    let fieldToUpdate = jpath('$'+key+'~', clonedSchedaMonitoraggio)[0];
    if(parent[fieldToUpdate] !== updatedValue && 
        !((parent[fieldToUpdate] == null || parent[fieldToUpdate] == undefined) && updatedValue === '')
      ){ // se il campo è stato modificato (tranne se settiamo a stringa vuota un campo null o undefined)
         // allora controllo la configurazione
      if(!updatedConfiguration.data.edited) {
        updatedConfiguration.data.edited = true; // se è la prima modifica setto il flag edited e salvo il valore originale
        updatedConfiguration.data.oldValue = parent[fieldToUpdate];
      } else {
        if(updatedValue == updatedConfiguration.data.oldValue || // se è stato riportato il valore iniziale (anche se nullo)
            ((updatedConfiguration.data.oldValue == null || updatedConfiguration.data.oldValue == undefined) && updatedValue === '')
          ) {// WARNING: solo == e non === altrimenti problemi di typeof
          
          // se il valore era già stato modificato
          // e stiamo riportando il valore all'originale
          // ripristino i valori all'originale e cancello la nota
          updatedConfiguration.data.oldValue = null;
          updatedConfiguration.data.edited = false;
          // TASK #13750: le note non vengono mai resettate
          // updatedConfiguration.data.note = '';
          //  se l'elemento era parte di un array, rimuovo la sua configurazione 
          if(updatedConfiguration.config.path.match(/(\[(\d+)\])/)) {
              // non aggiorno la configurazione
              removeConf = true;
              // TASK #13750: non rimuovo la configurazione per via delle note
              // delete clonedSchedaMonitoraggio.dataEntryConfiguration[key];
          }
        }
      }

      parent[fieldToUpdate] = updatedValue; // setto il nuovo valore

      // check substancial
      if(updatedConfiguration.config.canBeSubstancial) {
        if(!removeConf)
          clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione prima del controllo substancial
        updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedSchedaMonitoraggio);
      }

      if(!removeConf) {// se l'elemento è presente
          clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione aggiornata
          // costruisce le informazioni aggiuntive per il tab invio
          if(updatedConfiguration.data.edited && !updatedConfiguration.data.editTag && tagColIds && Array.isArray(tagColIds)) {
            createEditTag(clonedSchedaMonitoraggio, tagColIds, updatedConfiguration, key);
          }
      }
    }
    /*
    else {
      // aggiorno comunque la nota se il campo è modificato o aggiunto
      if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
        clonedSchedaMonitoraggio.dataEntryConfiguration[key].data.note = updatedConfiguration.data.note;
    }*/
    // TASK #13750: le note vengono sempre persistite
    if(!clonedSchedaMonitoraggio.dataEntryConfiguration[key]) {
      clonedSchedaMonitoraggio.dataEntryConfiguration[key] = cloneConf(key, clonedSchedaMonitoraggio.dataEntryConfiguration);
    }
    clonedSchedaMonitoraggio.dataEntryConfiguration[key].data.note = updatedConfiguration.data.note;
  }

  return clonedSchedaMonitoraggio;
}

//data una riga selezionata prepara i dati per essere modificabili su modalForm
function editFormDataFromRiga(item){
  
  if(!item || !item.conf || !item.content){
    console.error("editFormDataFromRiga: NULL param passed!?");
    return;
  }

  let editFormData = {
    content:{},
    conf: tool.genericFunctions.cloneObject(item.conf)
  };

  for(let [key,value] of Object.entries(item.content)){
    editFormData.content[key] = {
      'id' : key,
      'value': value
    };
  }

  return editFormData;
}

//applica la configurazione emessa da modalForm alla scheda monitoraggio passata
function aggiornaConfigurazioneScheda(emitFromModal, clonedSchedaMonitoraggio){
  
  if(!emitFromModal || !clonedSchedaMonitoraggio){
    console.error("aggiornaConfigurazioneScheda: NULL param passed!?");
    return;
  }

  let actionFromModal = emitFromModal; 
    for(let [key, field] of Object.entries(actionFromModal.conf)) {
    let updatedConfiguration = field;
    clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione aggiornata
  } 
  
  return clonedSchedaMonitoraggio;
}

function controllaMandatori(scheda){
  //ripulisco l'array
  let errori = [];
  //leggere mappone
  const mappa = scheda.dataEntryConfiguration;
  if(!scheda || !mappa){
      console.error("Scheda o Mappa di configurazione non presente!?");
      return errori;
  }
  //ciclare per tutti gli elementi della mappa configurazione


  // qui inserisco un filtro per togliere gli elementi che sono già controllati
  // tramite il tabellone di cronoprog
  // WARNING: iterAmministrativo contiene procedure, contratti, pagamenti e inventario
  // il filtro rimuove anche gli elementi non obbligatori, quelli eliminati e quelli con asterisco
  const filteredMap = Object.entries(mappa).filter(
    item => !item[0].includes('iterAmministrativo') 
          && item[1].config.mandatory
          && !item[1].data.deleted
          && !item[0].includes('*') 
  )

  for (const entry of filteredMap){
      let temp = "";
      const item = entry[1];
      if(item.data.editTag) {
          temp = item.data.editTag + ' ';
      }
      //imposto i campi comuni
      let row = {
          content: {
              "Tab": item.config.tab,
              "Nome Campo": temp + item.config.label,
              "Tipo dato": convertiTipo2Stringa(item.config.type)
          }
      }

      //controlla se esiste un valore
      const values = getSchedaValuesFromMapElement(scheda, entry[0]);
      if (Array.isArray(values)) { //devo ciclare per tutti i valori
          for (const value of values){
              if (value == undefined || value == null) {
                errori.push(row);
              }
          }
      } else if (values == undefined || values == null) {
        errori.push(row);
      }
  }
  
  return errori;

}
// ritorna l'ultimo indice di array          
function getLastArrayIndexOfKey(key){
  if(!key){
    console.error("NULL param passed!");
    return null;
  }
  let objs = key.match(/(\[(\d+)\])(?!.*\[(\d+)\])/g);
  let bracketNumber = objs[0];
  let lastDigit = bracketNumber.length-1;
  return bracketNumber.substring(1,lastDigit);
}

function createElementFromScratch(rowId, incomingScheda, mappa) {
    if(!rowId || !incomingScheda || !mappa) {
      console.error('createElementFromScratch bad params', rowId, incomingScheda, mappa);
      return {};
    }

    if(rowId.indexOf('*') !== -1) {
      console.error('createElementFromScratch: rowId cannot contain stars!', rowId);
      return {
        fieldName: "",
        parent: {},
        object: {},
        scheda: incomingScheda
      }
    }

    let scheda = tool.cloneObject(incomingScheda);
    
    // questa regex crea un array di campi e indici di array
    
    const objectRegex = /\['\w+'\]/;
    const fieldRegex = /\w+/;

    const children = rowId.match(childrenRegex);

    let currentObject = scheda;
    let fieldName;
    let parent;
    for(let i = 0; i < children.length; i++) {
      let child = children[i];
      if(!currentObject) {
        console.error('createElementFromScratch cannot continue, no current Object for', rowId);
        return {};
      }

      let nextChild;
      if(i < children.length - 1)
        nextChild = children[i + 1];
      fieldName = child.match(fieldRegex)[0];
      // array elements case
      if(nextChild && nextChild.match(arrayRegex)) {
        let indexToCreate = 0;
        let startIndex = 0;
        // find index to create
        const temp = nextChild.match(/\b\d+\b/);
        if(temp && temp.length === 1 && !isNaN(temp[0]))
          indexToCreate = parseInt(temp[0]);

        if(!currentObject[fieldName]) {
          // array not found, let's create  
          currentObject[fieldName] = [];
        }
        else {
          // search for indexToCreate inside array
          const currentIndex = currentObject[fieldName].length - 1;
          if(indexToCreate <= currentIndex)
          {
            // indexToCreate exists, nothing to do
            // console.log('indice esistente', indexToCreate);
            startIndex = indexToCreate + 1;
          }
          else {
            // indexToCreate not found, create elements up to indexToCreate
            startIndex = currentIndex + 1;
            // console.log('crea elementi da a', startIndex, indexToCreate);
          }
        }
        // push elements
        for(let k = startIndex; k <= indexToCreate; k++) {
          // console.log('crea elementi', k);
          currentObject[fieldName].push({});
        }
        // update current object
        parent = currentObject;
        currentObject = currentObject[fieldName][indexToCreate];
        // increase two steps
        i++;
        // child = children[i];
        // currentPath += child;
      }
      else if(child.match(objectRegex)) {
        // console.log('object child', child, currentPath, currentObject);
        // object not found
        if(!currentObject[fieldName]) {
          // if it's not last element, create object
          if(nextChild) {
            // console.log('crea oggetto', child);
            currentObject[fieldName] = {};
          }
          else {
            // if it is last element, set default value
            // console.log('create default value for ', fieldName, currentObject, scheda);
            
            const conf = getMapElement(mappa, rowId);
            const defaultElement = cloneValue(rowId, conf);
            if(defaultElement)
              currentObject[fieldName] = defaultElement.value;
            else
              console.error('no default element for ', fieldName, 'as part of', rowId);
          }
        }
        // update current object
        parent = currentObject;
        currentObject = currentObject[fieldName];
        // console.log('a questo punti', scheda);
      }
      else {
        console.log('unexpected property', child);
      }
    }
    return {
      fieldName: fieldName,
      parent: parent,
      object: currentObject,
      scheda: scheda
    }
}

// FUNZIONI PER CONTROLLO OBBLIGATORI (DI CRONOPROG) VALEVOLE PER MONITORAGGIO E ASSESSMENT (POSSIBILE UTILIZZO FUTURO PER PROGETTI)
function getMandatoryFieldsCronoprog(scheda, idsToCheck, tab_cronoProg) { 
  let mandatoryMissed = []
  if(!scheda || !idsToCheck) {
    console.error('getMandatoryFieldsCronoprog: bad params', idsToCheck, scheda);
    return mandatoryMissed
  }

  for(const item of Object.values(idsToCheck)) {
    
    let dinamicIds = item.dinamicIds;
    // per tutti gli id da controllare (dichiarati nella configurazione dei tab)
    for(const id of dinamicIds) {
      const mapElement = tab_cronoProg[id]
      // controllo i soli campi obbligatori
      if (mapElement && mapElement.config && mapElement.config.mandatory) {
        // prendo la radice dell'elemento (es: 'inventario', 'pagamenti');
        const rootId = id.match(/\w+/)[0];

        let alfaIds = Object.keys(scheda[rootId]);
        // per tutti gli elementi contenuti dentro l'oggetto radice (pagamenti, contratti, ecc.)
        for(const alfaId of alfaIds) {
          // con questo escludo i pagamenti non associati ad alcun contratto
          if(alfaId === nonDefinito.key)
            continue;

          let idToCheck = getCronoprogId(alfaId, id);
          let valueMatch = jpath('$'+ idToCheck, scheda);
          // se non ci sono valori, oppure il valore è null, undefined o soli spazi, allora
          // lo aggiungo all'elenco degli obbligatori non valorizzati
          if(valueMatch.length === 0 || (valueMatch[0] == null || valueMatch[0] == undefined || valueMatch[0].toString().trim().length === 0)) {
            let progressiveRow = getProgressivoByEntity(scheda, rootId, alfaId, id);
            if(progressiveRow !== '-1' && mandatoryMissed.filter(element => element.id === idToCheck).length === 0) {
              let riferim_progressive = rootId === 'pagamenti' ? ' Fattura: ' : ' Attività: '
              const row = {
                "id": idToCheck,
                content: {
                  "Tab": mapElement.config.tab,
                  "Nome Campo": mapElement.config.label + " (" + riferim_progressive + progressiveRow + " )",
                  "Tipo dato": convertiTipo2Stringa(mapElement.config.type)
                }
              }
              mandatoryMissed.push(row)
            } else {
              console.error('getMandatoryFieldsCronoprog errore o elemento già presente', progressiveRow, id, idToCheck);
            }
          }
        }
      }
    }
  }
  return mandatoryMissed
}

function findEntityContentByEntity(scheda, alfaId) { // FUNGE DA EDIT TAG
  let entityContent = null
  Object.keys(scheda).forEach(rootId => {
    if(typeof scheda[rootId] === 'object' && scheda[rootId] !== null && alfaId in scheda[rootId]) entityContent = scheda[rootId][alfaId]
  })
  return entityContent
}

// FUNGE DA EDIT TAG (SIA PER GLI OBBLIGATORI CHE PER COMPORRE LO STESSO EDIT TAG)
function getProgressivoByEntity(scheda, rootId, alfaId, id, actionFromModal) {
  let entityContent = findEntityContentByEntity(scheda, alfaId).content
  let compositionId = id.match(/'(.*?)'/g)
  let field = compositionId[ compositionId.length - 1].substring(1, compositionId[ compositionId.length - 1].length - 1)
  let progressive = 'ND'
  if(id.includes("attivita")) {
    if(rootId === 'contratti' || rootId === 'inventario') {
      // Qui mi serve recuperare l'id dell'attività relazionata
      if (alfaId in scheda[rootId]) {
        let id_procedura_rel = scheda[rootId][alfaId].rel['attivita'][0]
        entityContent = scheda['attivita'][id_procedura_rel].content
      }
    }
    progressive = entityContent && entityContent.idAttivita ? entityContent.idAttivita : progressive
  } else if(id.includes("procedure")) {
      if(rootId === 'contratti') {
        progressive = '-1';
        // Qui il rootId è sempre contratti mi serve recuperare allora l'id della procedura relazionata
        if(alfaId in scheda[rootId]) { // CERCO IN contratti
          let rel_proc = scheda[rootId][alfaId].rel.procedure;
          const id_procedura_rel = rel_proc[0];
          const content_procedura_rel = scheda.procedure[id_procedura_rel].content;

          if(!content_procedura_rel[field]) {
            // IL CAMPO E' VERAMENTE MANCANTE
            let id_attivita_rel = scheda.procedure[id_procedura_rel].rel['attivita'][0]
            entityContent = scheda['attivita'][id_attivita_rel].content
            progressive = entityContent.idAttivita ? entityContent.idAttivita : progressive
          } else {
            // IL CAMPO E' FALSAMENTE MANCANTE
            progressive = '-1'
          }
        } else if(alfaId in scheda['procedure']) { // ALTRIMENTI CERCO IN PROCEDURE
          let id_attivita_rel = scheda.procedure[alfaId].rel['attivita'][0]
          entityContent = scheda['attivita'][id_attivita_rel].content
          progressive = entityContent.idAttivita ? entityContent.idAttivita : progressive
        }
    }
    else if (rootId === 'procedure'){ //Serve ad iter amministrativo su scheda progetto
      progressive = 'ND'
      const rel_attivita = scheda[rootId][alfaId].rel.attivita;
      if(rel_attivita){
        let originalContent = scheda[rootId][alfaId].content
        if(!originalContent) {  // COMPOSIZIONE EDITTAG PER CREAZIONE DI UN PROCEDURE IN DESCRIZIONE DELL'ITER AMMINISTRATIVO IN PROGETTO
          Object.keys(actionFromModal.content).forEach(entry =>{
            if(entry.indexOf('idProceduraGara') !== -1 && actionFromModal.content[entry].value) {
              originalContent = {}
              originalContent['idProceduraGara'] = actionFromModal.content[entry].value
            }
          })
        }
        let id_attivita_rel = scheda[rootId][alfaId].rel['attivita'][0]
        entityContent = scheda['attivita'][id_attivita_rel].content
        progressive = entityContent.idAttivita ? entityContent.idAttivita : progressive
        if(typeof originalContent === 'object' && originalContent !== null)
          progressive = progressive + " Procedura: " + originalContent.idProceduraGara
      }
    }
  } else if(id.includes("contratti")) {
    if(rootId === 'contratti') {
      let id_procedura_rel = scheda[rootId][alfaId].rel['attivita'][0]
      entityContent = scheda['attivita'][id_procedura_rel].content
      progressive = entityContent.idAttivita ? entityContent.idAttivita : progressive
    } else if(rootId === 'inventario') {
      // Qui mi serve recuperare l'id dell'attività relazionata, partendo da un contratto
      let id_contratto_rel = scheda[rootId][alfaId].rel['contratti'][0]
      let content_contratto_rel = scheda['contratti'][id_contratto_rel].content
      if(!content_contratto_rel[field]) {
        let id_attivita_rel = scheda['contratti'][id_contratto_rel].rel['attivita'][0]
        entityContent = scheda['attivita'][id_attivita_rel].content
        progressive = entityContent.idAttivita ? entityContent.idAttivita : progressive
      } else {
        progressive = '-1'
      }
    } else {
      progressive = entityContent.riferimentoContratto ? entityContent.riferimentoContratto : progressive
    }
  } else if(id.includes("pagamenti")) {
    // Qui il rootId è sempre pagamenti mi serve recuperare allora l'id dell'attività relazionata  
    if(rootId === 'pagamenti') {
      if(!entityContent) {  // COMPOSIZIONE EDITTAG PER CREAZIONE DI UN PAGAMENTO IN MONITORAGGIO
        Object.keys(actionFromModal.content).forEach(entry =>{
          if(entry.indexOf('numeroFattura') !== -1 && actionFromModal.content[entry].value) {
            progressive = actionFromModal.content[entry].value
          }
        })
      } else {
        progressive = entityContent.numeroFattura ? entityContent.numeroFattura : progressive
      }
    }
  } else if(id.includes("inventario")) {
    // Qui mi serve recuperare l'id dell'attività relazionata
    let id_attivita_rel = scheda[rootId][alfaId].rel['attivita'][0]
    entityContent = scheda['attivita'][id_attivita_rel].content
    progressive = entityContent.idAttivita ? entityContent.idAttivita : progressive
  }
  return progressive
}
// ------------------> FUNZIONI PER CONTROLLO OBBLIGATORI (DI CRONOPROG)

export default {
    getMapElement,
    getSchedaValueFromMapElement,
    costruisciTabella,
    costruisciConfigurazione,
    cloneConf,
    cloneValue,
    aggiornaScheda,
    editFormDataFromRiga,
    aggiornaConfigurazioneScheda,
    recursiveElement,
    createEditTag,
    getSchedaValuesFromMapElement,
    getSchedaValuesFromMapElementPlus,
    convertiTipo2Stringa,
    controllaMandatori,
    getGenericArrayKey,
    getLastArrayIndexOfKey,
    createElementFromScratch,
    replaceWithStarsRegex,
    indexRegex,
    lastIndexRegex,
    updateComponent,
    setupComponentRow,
    computeActions,
    createEditTag1,
    createEditTagCronoProg,
    singleDepV1,
    setupComponentRow2,
    updateComponent2,
    getCronoprogIds,
    getCronoprogId,
    // clearRimodulatedCronoprog,
    getMandatoryFieldsCronoprog,
    nonDefinito
}
