<template>

    <b-container>
        <b-row style="font-size: 14px; margin-bottom: 30px;">
            <b-col cols="9" v-if="!controllato && saldo">
                <b-alert variant="info" show>
                    <strong>Nota:</strong> Inserire un titolo per il controllo. 
                </b-alert>    
            </b-col>
            <b-col cols="9" v-if="controllato">
                <b-alert variant="warning" show>
                    Esiste già un controllo In Loco Finanziario per il progetto selezionato. Non &egrave; possibile avviarne di nuovi.
                </b-alert>
            </b-col>
            <b-col cols="9" v-if="!saldo">
                <b-alert variant="warning" show>
                    Il progetto selezionato non &egrave; nella fase di "Saldo". 
                    <br> Per progetti in anticipazione attendere l'esito dell'ultima richiesta tranche.
                    <br> Per progetti a rimborso attendere l'esito dell'ultima dichiarazione di spesa.
                </b-alert>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="9" v-if="!controllato && saldo">
                <b-form-input id="titoloFin" v-model="titoloControllo"
                              placeholder="Descrizione breve del controllo finanziario"
                              :disabled="isWaitingForSchedaPost"/>
                              
            </b-col>
        </b-row>
        
        <b-row>
            <b-col class="modal-footer text-right">
                <b-button
                    class="btn-success"
                    @click="creaScheda"
                    :disabled="isCreaSchedaDisabled">
                    Crea Nuovo Controllo Finanziario
                </b-button>
                <b-button variant="danger"
                    class="modal-default-button"
                    @click="closeModal"
                    :disabled="isWaitingForSchedaPost">
                    Chiudi
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import endpoints from "@/components/endpoints.vue";
import notify from "@/helpers/notifications.js"
import feedFx from '@/helpers/feedFunctions.js'

export default {
    name: "selezioneCheckListControlloFin",
    props: {
        controllato: {
            type: Boolean,
            required: true
        }
    },
    data(){
        return {
            saldo: false,
            selectedTr: 0,
            isWaitingForSchedaPost: false,
            codiceProgetto: null, // il codice progetto preso dal path
            titoloControllo: null,
        }
    },
    computed: {
        isCreaSchedaDisabled: function() {
            // disabilitato nel caso l'utente non abbia inserito il titolo
            // oppure se e' in corso la chiamata POST per la creazione della schedacontrollo
            // oppure il progetto non è in saldo o è stato già controllato
            return (!this.titoloControllo 
                || this.isWaitingForSchedaPost 
                || !this.saldo 
                || this.controllato)
        },
    },
    methods: {

        creaScheda(){

            this.isWaitingForSchedaPost = true;

            this.creaNuovaScheda().then(res => {
                console.log("visualizzo la scheda=", res.idSchedaControllo);
                
                // passo alla pagina della scheda controllo appena creata
                this.$router.push({
                    name: 'schedaControlliInLoco',
                    params: {
                        codiceProgetto: this.codiceProgetto,
                        idControllo: res.idSchedaControllo
                    }
                })

                this.isWaitingForSchedaPost = false;
            })
            .catch((error) => {
                console.error("Errore:", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },

        closeModal() {
            this.$emit('closeModalCheckList')
        },

        loadProjectByCodiceProgetto() {
            //carico i dati del progetto per vedere prefinanziamento e tranche definite
            // Rimosso Paletto: si punta sempre alla versione in Attuazione
            this.$get(endpoints.getProgettoByCodice(this.codiceProgetto))
            .then((result) => {
                const response = result.schedaProgetto
                this.schedaProgetto = response;
                // Rimosso Paletto: se il Progetto è in Attuazione non si possono fare altra azioni
                // if (result && result.content.stato === "In Attuazione"){
                //     this.schedaProgetto = result.content;
                // } else {
                //     console.error("Progetto in rimodulazione! Alcuni dati potrebbero non essere stabili");
                // }


                /* MEV02:REF004
                * Eliminazione del controllo sullo del progetto: anche checklist dei progetti conclusi possono create o modificate
                */
                this.saldo=true;
                /* 
                if (response.erogazione && response.erogazione.circuitoFinanziario){
                    let circuito = response.erogazione.circuitoFinanziario;
                   // console.log("Controllo sullo stato di Saldo del progetto "+this.codiceProgetto+ " con circuito Finanziario: "+circuito);
                    //in base al tipo di progetto verificare se è in fase di saldo
                    
                    if (circuito == "In Anticipazione"){
                        //circuito anticipazione: -> il processo di richiesta saldo (ultima tranche disponibile) è concluso
                        //In questo caso bisogna consultare la lista delle tranche
                        this.leggereTrancheProgetto();
                    } else if(circuito == "A Rimborso"){
                        //circuito rimborso: -> esiste una dichiarazione di spesa  conclusa (controllo fase 2) con il booleano saldo=true
                        //In questo secondo caso usare l'apposita API/Query di ricerca di un controllofase2 esitato con attributo dichiarazioneSaldo=true
                     this.ricercaDichiarazioneSaldo();
                    } else {
                        console.error("Condizioni di Saldo non definite per il progetto "+this.codiceProgetto+ " con circuito Finanziario: "+circuito);
                    }
                    
                } else {
                    console.error("Nel progetto non sono state definite le modalità di erogazione o il circuito finanziario!");
                } 
                */
            })
            .catch((error) => {
                console.error("ERRORE nel caricamento dati del progetto: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },

        leggereTrancheProgetto(){
            const url = endpoints.trancheProgetto(this.codiceProgetto);
            this.$get(url)
            .then((result) => {
                console.log("Trovate "+result.length+" richieste di tranche");
                // Rimosso Paletto: si punta sempre alla versione in Attuazione
                //if (this.schedaProgetto.erogazione && this.schedaProgetto.content.erogazione.tranche){                    
                if (this.schedaProgetto.erogazione && this.schedaProgetto.erogazione.tranche) {
                    console.log("Tranche definite nel progetto: ", this.schedaProgetto.erogazione.tranche.length);
                    if (this.schedaProgetto.erogazione.tranche.length == result.length) {
                        this.saldo=true;
                        console.log("Progetto in fase di saldo! Vedi richiesta tranche: ", result[result.length-1].idScheda);
                    }
                }
            })
            .catch((error) => {
                console.error("ERRORE nel caricamento richieste tranche del progetto: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            })
        },

        ricercaDichiarazioneSaldo(){
            const url = endpoints.dichiarazioneSaldoProgetto(this.codiceProgetto);
            this.$get(url)
            .then((result) => {
                if (result.length>0 && result[0].dichiarazioneSaldo == "true"){
                    this.saldo=true;
                    console.log("Progetto in fase di saldo! Vedi dichiarazione di spesa: ", result[0].idSchedaControllo);
                }
            })
            .catch((error) => {
                console.error("ERRORE nel caricamento dichiarazioni di spesa del progetto: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            })
        },

        async creaNuovaScheda(){

            try {
                let lastChecklist = await this.$get(
                    endpoints.retrieveLastVersionTabellone('finanziario')
                    )
                let lastChecklistVersion = lastChecklist.lastVersion;
                console.log("ultima versione tabellone: ", lastChecklistVersion);

                let anagrafica = {};
                if (lastChecklist){
                    anagrafica = feedFx.feedAnagraficaFinanz(this.schedaProgetto);
                    console.log("filled anagrafica=", anagrafica);
                }

                let infoProg = {};
                if (this.schedaProgetto){
                    infoProg = feedFx.feedInfoProgetto(this.schedaProgetto);
                     //se è stato calcolato un importo finale da un Final Assessment lo utilizzo come costo del progetto
                    if(this.schedaProgetto.importoFinale){
                        infoProg.costoProgetto=this.schedaProgetto.importoFinale;
                        anagrafica.costoProgetto=this.schedaProgetto.importoFinale;
                    }
                }
               
                
                console.log("filled infoProgetto=", infoProg);
                let url = endpoints.getControlliLoco(this.codiceProgetto);
                let userIdGroup = this.schedaProgetto.progetto.beneficiario.beneficiario.idBeneficiario;
                const autore = this.$getUserInfo().preferred_username;
                
                const body = {
                    codiceProgetto: this.codiceProgetto,
                    idSchemaControllo: 'finanziario',
                    schemaVersione: lastChecklistVersion,
                    idBeneficiario: userIdGroup,
                    tipoControllo: "In Loco",
                    sottoTipoControllo: "Finanziario",
                    autoreUltimoAggiornamento: autore,
                    strumentoFinanziario: infoProg.strumentoFinanziario,
                    content: {
                        titoloControllo: this.titoloControllo, // inserito dall'utente
                        autoreUltimoAggiornamento: autore,
                        controllo: {},
                        anagrafica: anagrafica,
                        infoProgetto: infoProg,
                    }
                }
                // POST di creazione della scheda controllo
                console.log("POST to URL="+url+" with body:", body);
                let schedaCreata = await this.$post(url, body);
                if (!schedaCreata.idSchedaControllo ||
                    !schedaCreata.codiceProgetto)
                {
                    let msg = "Scheda Controllo creata non valida. Controllare il servizio ISF-Controlli"
                    console.error(msg, schedaCreata);
                    throw new Error(msg);
                }
                console.log(
                    "scheda Controllo In Loco creata",
                    "idScheda=", schedaCreata.idSchedaControllo,
                    "codiceProgetto=", schedaCreata.codiceProgetto
                );
                
                // avvio il processo
                this.processo = await this.avviaProcesso(schedaCreata.idSchedaControllo, schedaCreata.codiceProgetto);
                console.log("Processo avviato, ID=", this.processo);

                //effettuo il claim implicito per l'utente che ha creato il processo
                await this.autoclaim(schedaCreata.idSchedaControllo);
                return schedaCreata;

            } catch (error) {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                throw error;
            }
        },

        avviaProcesso(idScheda, codiceProgetto){
            const url = endpoints.startControlloInLocoFinanziario;
            const body = {
                codiceProgetto: codiceProgetto,
                idScheda: idScheda
            }
            // POST per avvio processo tranche
            return this.$post(url, body)
        },

        // POST per autoclaim
        autoclaim(idScheda) {
            const url = endpoints.claimPrefinanziamento+idScheda;
            console.log("CLAIM by POST to URL", url);
            return this.$post(url)
        },

        

        
    },

    mounted() {
      this.codiceProgetto = this.$route.params.codiceProgetto;
      //console.log("Carico i dati del progetto "+this.codiceProgetto)
      this.loadProjectByCodiceProgetto();
      
      console.log("Rilevata altro controllo Finanziario? ", this.controllato);      
    },

}
</script>