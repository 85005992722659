<template>
    <b-container>
        <b-row>
            <b-col cols="9">
                <b-form-select
                :disabled="disabledPagamenti"
                v-model="selected"
                :options="listaPagamentiPossibili()"
                @input="managePagamenti" required />
            </b-col>
        </b-row>
        <span>
            <b-row v-for="(item, index) in getPagamentiScelti" :key="index" class="list-small ">
                <b-col cols="7">
                    {{item.text}}
                </b-col>
                <b-col>
                    <b-button title="Rimuovi Fattura" variant="outline-primary" size="sm" @click="removePagamento(index)">
                        <fa-icon :icon="['far', 'trash-alt']" class="small-icons"/>
                    </b-button>
                </b-col>
            </b-row>
        </span>
    </b-container>
</template>

<script>
import utils from "@/helpers/utils.js"
import { mapGetters } from 'vuex'

export default {
    name:"selezionePagamenti",
    data(){
        return{
            selected: null,
        }
    },
    watch: {
      disabledPagamenti: function() {
        if(this.disabledPagamenti) {
          this.selected = null
        }
      }
    },

    props: {
        values: Array,
        scheda: Object,
        disabledPagamenti: Boolean,
        pagamentiPossibili: Array
    },
                
    computed: {
        ...mapGetters({
            getAllDisposizioni: 'disposizioni/getAllSchede'
        }),
        getPagamentiScelti() {
            return !this.disabledPagamenti ? this.values : []
        }
    },

    mounted() {
        console.log("pagamenti selezionabili: ", this.pagamentiPossibili.length);
        console.log("pagamenti inseriti nella scheda: ", this.values.length);
    },

    methods:{
        listaPagamentiPossibili() {
            let ids_payment_used = []
            let paymentsAvailable = [ { text: "Nessuno", value: null }]
            
            // Recupero gli ids di tutte le disposizioni create per un dato codice Progetto
            let keys_disposizione = Object.keys(this.getAllDisposizioni).filter(key => this.getAllDisposizioni[key].codiceProgetto === this.$route.params.codiceProgetto);
            if(keys_disposizione.length > 0) {
                keys_disposizione.forEach(key => {
                    this.getAllDisposizioni[key].content.disposizione.pagamenti.forEach(pagamento => {
                        console.log(pagamento)
                        if(ids_payment_used.indexOf(pagamento.value.id) === -1) ids_payment_used.push(pagamento.value.id)
                    })
                });
            }
            
            for (const pay of this.pagamentiPossibili){
                if(ids_payment_used.indexOf(pay.id) === -1) {
                    let testo = "Numero: " + pay.numeroFattura + " del " + utils.formatDate(pay.dataFattura)+" importo: " + utils.formatCurrency(pay.totaleFattura); 
                    let opt = {
                        text: testo,
                        value: { 
                            text: testo,
                            value: pay
                        }
                    };
                    paymentsAvailable.push(opt);
                }
            }
            // Questi sono i reali pagamenti disponibili, ovvero tra i possibili quelli non utilizzati
            return paymentsAvailable;
        },
        managePagamenti() {
            this.addPagamento();
        },

        addPagamento() {
            //se non esiste già l'elemento selezionato
            const temp = this.values.filter(item => {return item.text === this.selected.text});
            if (temp.length === 0){
                //NB: modifica reattiva del valore passante
                if (this.values){
                    this.values.push(this.selected);
                } else {
                    console.error("Impossibile aggiungere su array NULL!?");
                }
            }
        },

        removePagamento(index) {
            //NB: modifica reattiva del valore passante
            if (this.values){
                this.values.splice(index, 1);
            } else {
                console.error("Impossibile eliminare da array NULL!?");
            }
        }
    }
}
             
            
</script>