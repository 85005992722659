<template>
    <div>
        <div class="table-title">Nuovo Intervento {{ title }}</div>
        <b-card no-body>
            <b-tabs card nav-class="tabs" content-class="content">
                <ColorLegend/>
                <b-tab
                    no-body
                    title="Anagrafica Intervento"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection('Anagrafica Intervento')">
                        <GenericComponent
                        v-if="this.loadComplete"
                        :idTablePdf="conf_export_pdf['generale'].id_table_pdf"
                        :tabName="tabSelection"
                        :actionAllowed="['edit']"
                        name="Anagrafica Intervento"
                        :store="'executing'"
                        :rowIds="anagraficaIds" />
                </b-tab>
                <b-tab
                    no-body
                    title="Parere Ufficio Economico Finanziario"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection('Parere Ufficio Economico Finanziario')">
                        <GenericComponent
                        v-if="this.loadComplete"
                        :idTablePdf="conf_export_pdf['beneficiario'].id_table_pdf"
                        @executingUpdate="executingUpdate"
                        :tabName="tabSelection"
                        :actionAllowed="['edit_parere_nota_EB']"
                        name="Parere Ufficio Economico Finanziario"
                        :store="'executing'"
                        :rowIds="parereUfficioIds" />
                </b-tab>
                <b-tab
                    no-body
                    title="Invio"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection('invio')">
                    <Invio v-if="this.loadComplete" 
                        :tabName="tabSelection" 
                        :updatedScheda="updatedScheda"
                        :riferimento = this.riferimento
                        @buildPdf="generateReport"
                        />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>
<script>
import GenericComponent from "../progetti/GenericComponent.vue";
import { mapGetters } from "vuex";
import notify from "@/helpers/notifications.js";
import Invio from './invio.vue';
import ColorLegend from "@/components/legend.vue";
import helper_pdf from "@/helpers/exportPdf.js"

export default {
    name:"schedaExecuting",
    components:{
        GenericComponent,
        Invio,
        ColorLegend
    },
    data() {
        return {
            title: '',
            //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
            riferimento : {
                idOggettoRif: "",
                tipoOggettoRif: "Scheda Executing Body", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
                idBando: "",
                tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
                idProgetto: "",
                codiceProgetto: ""
            },
            anagraficaIds:[
               "['progetto']['titoloProgetto']",
               "['progetto']['strumentoFinanziario']",
               "['progetto']['modalitaDiAccesso']",
               "['progetto']['costoProgetto']",
               "['progetto']['costoProgettoIVA']"
            ],
            parereUfficioIds:[
               "['progetto']['titoloProgetto']",
               "['progetto']['strumentoFinanziario']",
               "['executingBody']['dotazioneComplessiva']",
               "['executingBody']['importoLineaIntervento']",
               "['executingBody']['risorseResidue']",
               "['progetto']['costoProgetto']",
               "['executingBody']['parere']",
               "['executingBody']['note']"
            ],
            schedaExecuting:{},
            tabSelection: "Anagrafica Intervento",
            loadComplete: false,
            updatedScheda: false,
            conf_export_pdf: {
                "generale": {
                id_table_pdf: 'generale_exbody',
                name: "Generale",
                title_page_pdf: 'Generale',
                nameCollapse: '',
                },
                "beneficiario": {
                    id_table_pdf: 'beneficiario_exbody',
                    name: "Beneficiario",
                    title_page_pdf: 'Beneficiario',
                    nameCollapse: '',
                },
                "invio_mandatory": {
                    id_table_pdf: 'mandatory_exbody',
                    name: 'invio',
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Campi obbligatori non valorizzati',
                },
                "invio_fileUpload": {
                    id_table_pdf: 'invio_fileUpload',
                    name: "",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Allegati',
                },
                "invio_parere_uff_econom": {
                    id_table_pdf: 'invio_parere_uff_econom',
                    name: "",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Parere Ufficio Economico Finanziario',
                },
                "invio_note": {
                    id_table_pdf: 'note_exbody',
                    name: "",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Elenco Note',
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            //getTabelloneExecuting: "progetto/getTabelloneExecuting",
            getSchedaExecuting: "executingBody/getSchedaProgetto"
        })
    },
    mounted() {
        const idExecuting = this.$route.params.idExecutingBody;
        // Caricamento configurazione per ExecutingBody nello Store
        Promise.all([
                this.$store.dispatch("configuration/setTabelloneVersioned", {type: 'allegati'}),
                this.$store.dispatch("executingBody/setTabelloneExecuting")
        ])
        .then(
            results => {
                // Caricamento scheda ExecutingBody nello Store
                this.$store.dispatch("executingBody/setSchedaExecuting", 
                    { idSchedaProgetto: idExecuting, tabelloneAllegati: results[0] }).then(
                    () => {
                        this.schedaExecuting = this.getSchedaExecuting({ idSchedaProgetto: idExecuting });
                        
                        if (this.schedaExecuting.progetto.idProgetto) {
                            this.riferimento.idProgetto = this.schedaExecuting.progetto.idProgetto;
                            this.riferimento.idOggettoRif = this.schedaExecuting.progetto.idProgetto;
                            this.riferimento.idBando = this.schedaExecuting.idBando;
                            this.riferimento.tipoBando = this.schedaExecuting.progetto.modalitaDiAccesso;
                            
                        }
                        this.title = this.$route.params.idExecutingBody;
                        if(this.schedaExecuting.progetto.titoloProgetto)
                            this.title = '"' + this.schedaExecuting.progetto.titoloProgetto + '"';
                        this.loadComplete = true;
                    }).catch(error => {
                        console.error('error executingBody', error.response);
                        notify.error(notify.strings.error, notify.strings.errorLoadExecuting);
                    }
                );
            }).catch(error => {
                console.error('error tabellone', error.response);
                notify.error(notify.strings.error, notify.strings.internalConfigurationError);
            }
        );
    },
    methods: {
        generateReport(optPdf) {
            this.generationPdf = true
            helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
            this.generationPdf = false
        },
        setTabSelection(name){
            this.tabSelection = name
        },
        executingUpdate() {
            this.updatedScheda = !this.updatedScheda
        }
    }
}
</script>