<template>
  <div>
    <b-alert :show="getDeletedFiles.length > 0" variant="warning">
      <div style="text-align: center;">
        <p><strong>ATTENZIONE! Questi sono i files che hai cancellato dalla scheda</strong>
          (Qualsiasi riferimento di questi è stato rimosso pure dalla Checklist di tua competenza)</p>
        <ul>
          <li style="list-style: square;" v-for="item in getDeletedFiles" :key="item.fileId">
            {{ item.text }}
          </li>
        </ul> 
      </div>    
    </b-alert>
    <Collapse :icon_parere="true" :iconInfo="handleColor(getDataRows)" :name="title">
      <b-table-lite :id="idTablePdf" thead-class="head" :items="this.tabledata.rows" :fields="innerHeader">
        <template v-slot:cell(descrizione)="data">
          <span v-html="data.item.descrizione"></span>
        </template>
        <template v-slot:cell(docsRiferimento)="data">
          <ul v-for="(id, index) in data.item.docsRiferimento" :key="index">
            <li style="list-style: circle">{{config.content.tipologiaAllegati[id].label}}<br /></li>
          </ul>
        </template>
        <template v-slot:cell(filesRiferimento_auto)="data">
          <ol v-for="(doc, index) in data.item.filesRiferimento_auto" :key="index">
            <li style="list-style: square">{{doc.text}}</li>
          </ol>
        </template>
        <template v-slot:cell(filesRiferimento)="data">
          <ol v-for="(doc, index) in data.item.filesRiferimento" :key="index">
            <li style="list-style: square">{{doc.text}}</li>
          </ol>
        </template>
        <template v-slot:cell(azione)="data">
          <b-button :id="namingIdButton(x, data.index + 1)" variant="outline-primary" :key="x" v-for="x in data.value" size="sm" @click="doAction(x, data.item.id)">
              <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
          </b-button>
        </template>
      </b-table-lite>
    </Collapse>
    <b-modal :id="'modalCheck-' + this.name" hide-footer
             size="xl"
             scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content">
      <template v-slot:modal-title>
        <h3>Modifica valore</h3>
      </template>
      <modalCheck
        :fdata="this.editFormData"
        :fdata_viewMode="this.data_auto"
        :types="config.content.types"
        :attachmentsType="config.content.tipologiaAllegati"
        :buttons="this.buttons"
        :parentName="this.name"
        @editField="editRiga"
        />
    </b-modal>
  </div>
</template>

<script>
import { JSONPath as jpath } from 'jsonpath-plus'
import Collapse from "@/components/collapse.vue"
import tools from '@/helpers/tools.js'
import helper from '@/helpers/controlli.js'
import mapUtils from '@/helpers/mapUtils.js'
import notify from "@/helpers/notifications.js"
import modalCheck from "./modalCheckListControlli.vue"
import { mapGetters } from "vuex"

export default {
  name: "CheckControllo",
  components: {
      modalCheck,
      Collapse
    },
  props: {
    idTablePdf: {
      type: String,
      default: () => ''
    },
    headerTable: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: () => {}
    },
    actions: {
      type: Array,
      default: () => []
    },
    tabName: {
      type: String
    },
    title: {
        type: String,
        default: () => 'CheckList'
    },
  },
  data() {
    return {
      innerHeader: [],
      componentId: "controllo",
      deletedFiles: [],
      mappa: {},
      editFormData: {conf:{}, content: {}},
      data_auto: {conf:{}, content: {}},
      tabledata: {
        rows: [],
      },
      buttons: [
          {
            name: "Modifica",
            action: "editField",
            show: true,
            param: "edit",
          },
        ],
      name: 'ControlloCheckList'
    }
  },
  watch: {
      tabName: function() {
        if(this.tabName === this.componentId) {
          this.deletedFiles = []
          this.extractData()
        }
      }
    },
  computed: {
    ...mapGetters({
      getScheda: "controlli/getScheda",
    }),
    getDataRows() {
      return this.tabledata.rows
    },
    getDeletedFiles() {
        // SE ho più occorrenze farò in modo di visualizzarne sempre una
        let unique = this.deletedFiles.reduce((unique1, o) => {
          if(!unique1.some(obj => obj.fileId === o.fileId))
            unique1.push(o)
          return unique1
        },[])
        return unique
    },
  },
  mounted() {
    if (!this.config || !this.config.content || !this.config.content.mappa) {
      console.error("NO config valid!", this.config);
      return
    }



    // Visualizzazione in tooltip delle note
    // Hover sugli esiti
    this.innerHeader = tools.genericFunctions.cloneObject(this.headerTable);
    if(this.innerHeader && this.innerHeader.length > 2) {
      this.innerHeader[2].tdAttr = this.tooltipAuto;
      this.innerHeader[3].tdAttr = this.tooltip
    }
    this.mappa = this.config.content.mappa
    this.extractData()
  },
  methods: {
    // Costruzione id per Buttoni (Azioni nel componente)
    namingIdButton(action, index) {
      return this.$builtIdDinamically(this.name, action, index)
    },
    tooltip(value, key, item) {
      if(item.note)
        return {title: 'nota di Controllo: ' + item.note};
      return {};
    },
    tooltipAuto(value, key, item) {
      if(item.noteAuto)
        return {title: 'nota di Autocontrollo: ' + item.noteAuto};
      return {};
    },
    handleColor(data) {
      return helper.handleEsitoIcon(data)
    },
    getSchedaControllo() {
      let scheda = this.getScheda({ codiceProgetto: this.$route.params.codiceProgetto, id: this.$route.params.idControllo })
      return tools.genericFunctions.cloneObject(scheda);
    },
    getContent(scheda) {
      return scheda.content
    },
    setContent(scheda, content) {
      scheda.content = content
      return scheda
    },
    extractData() {
      if (!this.mappa) {
        console.error("Invalid mappa", this.mappa)
        return
      }
      // Recupera la scheda facendo un clone della stessa
      let scheda = this.getSchedaControllo()
      let content = this.getContent(scheda)
        
      if(content.controllo == null) {
        content.controllo = {}
        scheda = this.setContent(scheda, content)
        this.updateSchedaInStore(scheda)
      }
      let controllo = tools.keyFinder(content, 'controllo')
      this.tabledata.rows = []
      let updateScheda = false
      let allIds = Object.keys(this.mappa)
      for (const rowId of allIds) {
        let mapElement = this.mappa[rowId]
        if (!mapElement) {
            console.error("no config for ", rowId)
            notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction)
            return
        }
        let result
        let note
        
        const values = jpath({resultType: 'all'}, '$' + rowId, controllo)
        const jpathAuto = jpath({resultType: 'all'}, '$' + rowId, content.autocontrollo)
        let valuesAuto
        if(jpathAuto.length === 1)
          valuesAuto = jpathAuto[0].value
        if (values && values.length === 0) {
          // console.log('readonly e aggiunto', rowId)
          result = mapElement.config.defaultValue
          const retVal = mapUtils.createElementFromScratch(
            rowId,
            controllo,
            this.mappa
          )
  
          controllo = retVal.scheda
          retVal.parent[retVal.fieldName] = result
          updateScheda = true
        } else if (values && values.length === 1) {
          // console.log('readonly e letto', rowId)
          result = values[0].value
        }
        else {
          console.error(this.name, 'è un array?', rowId, values)
        }

        if(!scheda.content.controllo.dataEntryConfiguration)
          scheda.content.controllo.dataEntryConfiguration = {}
        const confAuto = scheda.content.autocontrollo.dataEntryConfiguration
        const conf = scheda.content.controllo.dataEntryConfiguration
        if (note) {
          // Creo la configurazione per le note
          conf[rowId] = {
            data: {
              note: note
            }
          }
          updateScheda = true
        }

        let row = {
          // AUTOCONTROLLO
          esito_auto: valuesAuto && valuesAuto.esito ? valuesAuto.esito : null,
          filesRiferimento_auto: valuesAuto && valuesAuto.chooseFiles && valuesAuto.chooseFiles.length > 0 ? valuesAuto.chooseFiles : [],
          noteAuto: confAuto && confAuto[rowId] && confAuto[rowId].data && confAuto[rowId].data.note ? confAuto[rowId].data.note : null,
          //CONTROLLO
          descrizione: mapElement.config.descrizione, // Commentata la limitCharactersDesc per BUG #10838
          docsRiferimento: mapElement.config.docsRiferimento,
          esito: result && result.esito ? result.esito : null,
          filesRiferimento: result && result.chooseFiles && result.chooseFiles.length > 0 ? result.chooseFiles : [],
          id: rowId,
          note: conf[rowId] && conf[rowId].data && conf[rowId].data.note ? conf[rowId].data.note : null
        }

        // Check se i filesRiferimento sono effettivamente presenti in scheda o sono stati cancellati
        if(content.allegati && values && values.length === 1) {
          let { allFilesRiferimento, deletedFiles } = helper.updateSituationFiles(values[0], Object.keys(content.allegati), this.deletedFiles)
          row.filesRiferimento = allFilesRiferimento 
          this.deletedFiles = deletedFiles
        }
        // Qui avviene la gestione delle azioni
        row.azione = this.actions.length > 0 && this.actions.indexOf('edit') > -1 ? this.actions.filter(action => {return action !== 'viewDichiarazione'}) : []
        this.tabledata.rows.push(row)
      }
      if(updateScheda) {
        content.controllo = controllo
        scheda = this.setContent(scheda, content)
        this.updateSchedaInStore(scheda)
      }
    },
    editRiga(dataFromModal) {
      this.$bvModal.hide("modalCheck-" + this.name)
        let scheda = this.getSchedaControllo()
        let content = this.getContent(scheda)

        let result = mapUtils.updateComponent(dataFromModal, scheda.content.controllo, null, this.mappa, null, this.title)
        for(const [key, item] of Object.entries(result.clonedScheda.dataEntryConfiguration)) {
          const itemToAdd = tools.genericFunctions.cloneObject(item)
          result.clonedScheda.dataEntryConfiguration[key] = itemToAdd
        }
        content.controllo = result.clonedScheda
        scheda = this.setContent(scheda, content)
        this.updateSchedaInStore(scheda)
        this.extractData()
    },
    doAction(action, id) {
      this.editFormData = { content: {}, conf: {} }
      // get fresh data from storage
      const scheda = this.getSchedaControllo()
      let content = this.getContent(scheda)
      let mapElement = tools.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, id))
      
      // Attraverso questo metodo popolo le info relative ad AutoControllo
      // Queste verranno mostrate in SOLA VISUALIZZAZIONE
      this.populateDataViewMode(scheda, id)
      const dataEntry = scheda.content.controllo.dataEntryConfiguration

      if (dataEntry && dataEntry[id]) {
        mapElement.data = tools.genericFunctions.cloneObject(
          dataEntry[id].data
        )
      } else {
        mapElement.data = this.$getDefaultData()
      }


      let val = { id: id, value: {esito: null, chooseFiles: [], commenti: null }}
      const values = jpath("$" + id, content.controllo)
      if (!values || values.length === 0) {
        console.log("valore non trovato in scheda: ", id);
      } else if(values.length === 1) {
        val.value = {
          esito: values[0].esito ? values[0].esito : null,
          chooseFiles: values[0].chooseFiles && values[0].chooseFiles.length > 0 ? values[0].chooseFiles : [],
          commenti: values[0].commenti ? values[0].commenti : null
        }
      }
      // Recupero i files che sono in scheda (dentro content)
      let filesAllegati = helper.populateListFilesForModal(content)
      val.genericFiles = filesAllegati

      this.editFormData.conf[id] = mapElement
      this.editFormData.content[id] = tools.genericFunctions.cloneObject(val)
      this.$bvModal.show("modalCheck-" + this.name)
    },
    populateDataViewMode(scheda, id) {
      let content = this.getContent(scheda)
      let mapElement = tools.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, id))
      const dataEntry = scheda.content.autocontrollo.dataEntryConfiguration

      if (dataEntry && dataEntry[id]) {
        mapElement.data = tools.genericFunctions.cloneObject(
          dataEntry[id].data
        )
      } else {
        mapElement.data = this.$getDefaultData()
      }

      let val = { id: id, value: {esito: null, chooseFiles: [], controDeduzione: null }}
      const values = jpath("$" + id, content.autocontrollo)
      if (!values || values.length === 0) {
        console.log("valore non trovato in scheda: ", id)
      } else if(values.length === 1) {
        val.value = {
          esito: values[0].esito ? values[0].esito : null,
          chooseFiles: values[0].chooseFiles && values[0].chooseFiles.length > 0 ? values[0].chooseFiles : [],
          controDeduzione: values[0].controDeduzione ? values[0].controDeduzione : null,
        }
      }
      // Recupero i files che sono in scheda (dentro content, probabile serva un metodo per la composizione del name)
      let filesAllegati = helper.populateListFilesForModal(content)
      val.genericFiles = filesAllegati

      this.data_auto.conf[id] = mapElement
      this.data_auto.content[id] = tools.genericFunctions.cloneObject(val)
    },
    updateSchedaInStore(scheda) {
      this.$store.dispatch("controlli/updateScheda", {
        codiceProgetto: this.$route.params.codiceProgetto,
        id: this.$route.params.idControllo,
        content: scheda
      })
    }
  }
}
</script>