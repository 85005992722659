<template>
    <div id="pagamenti2Assessment" v-show="tabName === componentConfig.name">
        <VerticalComponent2 v-if="loadComplete"
            :idTablePdf="idTablePdf"
            :idScheda="idScheda"
            :storeModule="storeModule"
            :tabelloneType="tabelloneType"
            :componentConfig="componentConfig"
            :optionalObj="optionalObj"
            @refreshValues="refreshData()"/>
    </div>
</template>
<script>
    import VerticalComponent2 from "@/components/verticalComponent2.vue"
    import utils from '@/helpers/utils.js'
    export default {
        name: "pagamenti2Assessment",
        components: {
            VerticalComponent2
        },
        props: {
            idTablePdf: { type: String, default: () => '' },
            incomingData: Object,
            tabName: String,
            rootId: String,
            dinamicIds: Array,  default: function() { return [] },
            optionalObj: Object,
        },
        data() {
            return {
                loadComplete: false,
                idScheda: '',
                tabelloneType: 'cronoprog_assessment',
                storeModule: 'cronoprog',
                componentConfig: {
                    title: "Pagamenti",
                    name: "pagamenti2",
                    notifyUpdate: false,
                    defaultActions: ['view'],
                    allowedActions: [],
                    tableIds: [
                        "['contratti'][*]['content']['riferimentoContratto']",
                        "['pagamenti'][*]['content']['riferimento']",
                        "['pagamenti'][*]['content']['importoPagamento']",
                        "['pagamenti'][*]['content']['numeroFattura']",
                        "['pagamenti'][*]['content']['dataFattura']",
                        "['pagamenti'][*]['content']['oggettoFattura']",
                        "['pagamenti'][*]['content']['importoFatturaSuISF']",
                        "['pagamenti'][*]['content']['rifDichiarazione']"
                    ],
                    relIds: {
                        "['contratti'][*]['content']['riferimentoContratto']": {
                            type: 'contratti',
                            canBeEmpty: true,
                            allowModalEdit: true
                        }
                    }
                }
            }
        },
        mounted() {
            this.idScheda = this.incomingData.schedaAssessment.idProgetto;
            let actions = utils.viewOnlyMode(this.incomingData, this.componentConfig.defaultActions);
            this.componentConfig.allowedActions = actions;
            this.componentConfig.dinamicIds = this.dinamicIds
            this.componentConfig.rootId = this.rootId
            this.loadComplete = true;
        },
    }
</script>