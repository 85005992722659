<template>
  <div>
    <b-table-lite :id="idTablePdf" thead-class="head" :items="this.tabledata.rows" :fields="this.tabledata.header">
        <template v-slot:cell(stato)="data">
            <GenericCircleIcon :iconInfo="handleColor(data)"/>
        </template>
        <template v-slot:cell(azione)="data">
            <b-button :id="namingIdButton(x, data.index + 1)" variant="outline-primary" :key="x" v-for="x in data.value" size="sm" @click="doAction(x, data.item.id)">
                <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
            </b-button>
        </template>
    </b-table-lite>

    <b-modal :id="'modalCheck-' + this.name" hide-footer
             size="lg"
             scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content">
      <template v-slot:modal-title>
        <h3>Modifica valore</h3>
      </template>
      <modalCheck :fdata="this.editFormData"
                  :buttons="this.buttons"
                  :parentName="this.name"
                  @editField="editRiga"/>
    </b-modal>
  </div>
</template>

<script>
  import modalCheck from "@/components/modalCheckList.vue";
  import GenericCircleIcon from "@/components/GenericCircleIcon.vue";
  import tool from '@/helpers/tools.js'
  import notify from "@/helpers/notifications.js"
  import { JSONPath as jpath } from 'jsonpath-plus';
  import mapUtils from '@/helpers/mapUtils.js'
  import { mapGetters } from "vuex";
  import calculation from "@/helpers/calculations.js";

  export default {
    name: "GenericCheck",
    components: {
      modalCheck,
      GenericCircleIcon
    },
    props: {
      name: {
        type: String,
        default: ''
      },
      rowIds: {
        type: Array,
        default: () => [
        ]
      },
      idTablePdf: { type: String, default: () => '' }
    },
    
    computed: {
      ...mapGetters({
        getMappa: "istruttoria/getMappa",
        getSchedaIstruttoria: "istruttoria/getSchedaIstruttoria",
        getIstruttoria: "istruttoria/getIstruttoria",
      }),
    },
    data() {
      return {
          noteCurrent: {}, // memorizzo, per il campo che sto modificando, l'id del campo della CheckList e il valore corrente delle note 
          tabledata: {
              header: [
                {
                  key: 'criterio',
                },
                {
                  key: 'descrizione',
                },
              ],
              rows: [
              ],
              
          },
          editFormData: {conf:{}, content: {}},
          mappa: null,
          scheda: null,
          actions: ['edit'],
          buttons: [
          {
            name: "Modifica",
            action: "editField",
            show: true,
            param: "edit",
          },
        ],
      };
    },

    methods: {
      // Costruzione id per Buttoni (Azioni nel componente)
      namingIdButton(action, index) {
        return this.$builtIdDinamically(this.name, action, index)
      },

      handleColor(data) {
        const stato = data.value;
        const id = data.item.id;
        
        // console.log(id);
        if(this.mappa[id]) {
          const type = this.mappa[id].config.type;
          if(type === 'enumSiNo' || type === 'enum3') {
            let retVal = {
              color: 'dark',
              type: 'circle-fill'
            }
            // console.log(stato);
            if(!stato) {
              retVal.type = 'circle'
              return retVal;
            }

            switch(stato) {
              case "SI":
                retVal.color = 'success'
                break;
              case "NO":
                retVal.color = 'danger';
                break;
              default:
                retVal.color = 'secondary';
                break;
            }
            return retVal;
          }
        }

        return {
          color: '',
          type: ''
        };
      },
      
      getScheda() {
        return this.getIstruttoria({idSchedaIstruttoria: this.$route.params.idIstruttoria});
      },
      getSchedaDoc() {
        return this.getSchedaIstruttoria({idSchedaIstruttoria: this.$route.params.idIstruttoria});
      },
      updateValue(rowId, newValue, scheda, valuesFromJPath) {
        // se l'elemento non esiste nella scheda allora
        // viene aggiunto, altrimenti viene aggiornato
        if (valuesFromJPath && valuesFromJPath.length === 0) {
          // console.log('calcolato e aggiunto', rowId);
          const retVal = mapUtils.createElementFromScratch(
            rowId,
            scheda,
            this.mappa
          );
          
          scheda = retVal.scheda;
          retVal.parent[retVal.fieldName] = newValue;
        }
        else if(valuesFromJPath && valuesFromJPath.length === 1) {
          // console.log('calcolato e letto', rowId);
          const parent = valuesFromJPath[0].parent;
          const fieldName = valuesFromJPath[0].parentProperty;
          parent[fieldName] = newValue;
        }
        else {
          console.error(this.name, 'è un array?', rowId, valuesFromJPath);
        }
        return scheda;
      },
      extractData() {
          if (!this.mappa) {
              console.error(this.name, "Invalid mappa", this.mappa);
              return;
          }
          let scheda = this.getScheda();
          if (!scheda) {
              console.error(this.name, "Invalid scheda", scheda);
              return;
          }
          scheda = tool.genericFunctions.cloneObject(scheda);
          // console.log('inizio', scheda);
          this.tabledata.rows = [];
          let updateScheda = false;
          for (const rowId of this.rowIds) {
              // il componente gestisce 3 casi:
              // 1) elemento calcolato
              // 2) elemento readonly con valore di default
              // 3) elemento modificabile
              let mapElement = this.mappa[rowId];
              if (!mapElement) {
                  console.error("no config for ", rowId);
                  notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                  return;
              }

              let note;
              let result;
              // in ogni caso serve sapere se l'elemento esiste in scheda
              const values = jpath({resultType: 'all'}, '$' + rowId, scheda);

              // 1) caso di un elemento calcolato: viene calcolato ogni volta
              // se non esiste in scheda viene aggiunto, altrimenti solo aggiornato
              if(mapElement.config.calculationRule) {
                  const retObj = calculation.calculateIstruttoria(mapElement, this.getSchedaDoc(), this.rowIds);
                  result = retObj.value;
                  // nota calcolata
                  note = retObj.note;
                  // aggiornamento del valore
                  scheda = this.updateValue(rowId, result, scheda, values);
                  // WARNING aggiorna la scheda solo se l'elemento è readonly
                  if(mapElement.config.readonly) {
                    updateScheda = true;
                  }
              }
              else if(mapElement.config.readonly && mapElement.config.defaultValue) {
                // 2) caso di un elemento readonly con valore di default
                // se non esiste in scheda viene aggiunto, altrimento solo letto
                if (values && values.length === 0) {
                  // console.log('readonly e aggiunto', rowId);
                  result = mapElement.config.defaultValue;
                  const retVal = mapUtils.createElementFromScratch(
                    rowId,
                    scheda,
                    this.mappa
                  );
          
                  scheda = retVal.scheda;
                  retVal.parent[retVal.fieldName] = result;
                  updateScheda = true;
                }
                else if (values && values.length === 1) {
                  // console.log('readonly e letto', rowId);
                  result = values[0].value;
                }
                else {
                  console.error(this.name, 'è un array?', rowId, values);
                }

                if(mapElement.config.defaultNote)
                  note = mapElement.config.defaultNote;
              }
              else {
                // 3) caso di un elemento da leggere dalla scheda
                // se esiste lo leggo
                if (values && values.length === 1) {
                  // console.log('modificabile e letto', rowId);
                  result = values[0].value;
                }
                else if(values && values.length > 1) {
                  console.error(this.name, 'array non gestito', rowId, values);
                }
              }

              if(!scheda.istruttoria.dataEntryConfiguration)
                scheda.istruttoria.dataEntryConfiguration = {};


              const conf = scheda.istruttoria.dataEntryConfiguration;
              // scheda.istruttoria.dataEntryConfiguration[rowId];
              // aggiungo le note se presenti
              if(conf[rowId] && conf[rowId].data) {
                note = this.noteCurrent.id === rowId ? this.noteCurrent.note : conf[rowId].data.note;
              }
              else if (note) {
                // altrimenti se le note sono automatiche e non ancora presenti
                // vengono aggiunte nella sezione "data" del tabellone
                // 1) creo la configurazione
                conf[rowId] = {
                  data: {
                    note: note
                  }
                }
                // 2) predispongo aggiornamento store
                updateScheda = true;
              }

              let row = {
                criterio: mapElement.config.criterio,
                descrizione: mapElement.config.descrizione,
                valore: result,
                stato: result,
                // minimo: mapElement.config.min,
                massimo: mapElement.config.max,
                id: rowId,
                note: note
              }

              // TODO aggiungere dati di istruttoriaGetActions              
              if(!mapElement.config.readonly) {
                // Qui aggiorno le azione principalmente se l'edit del componente è ancora possibile
                let scheda1 = this.getSchedaDoc()
                const azioni = this.$istruttoriaGetActions(scheda1);
                row.azione = azioni.length > 0 && azioni.indexOf('edit') > -1 ? this.actions : [];
              }
              else {
                // caso readonly: verificare calcolo automatico e note di default

                // row.stato = "SI";
              }

              this.tabledata.rows.push(row);
          }
          if(updateScheda) {
            this.updateSchedaInStore(scheda);
          }
      },
      updateSchedaInStore(scheda) {
        // console.log('ppp',scheda);
        this.$store.dispatch("istruttoria/updateIstruttoria", {
          idSchedaIstruttoria: this.$route.params.idIstruttoria,
          content: scheda,
        });
      },
      setupHeader() {
        for(const rowId of this.rowIds) {
          const mapElement = this.mappa[rowId];
          if(mapElement) {
            if(mapElement.config.type === 'enumSiNo' || mapElement.config.type === 'enum3') {
              this.tabledata.header.push({
                key: 'stato',
              });
            }
            else if(mapElement.config.type === 'range') {
              this.tabledata.header.push();
              this.tabledata.header.push(
                // {
                //   key: 'minimo',
                //   label: 'Valore minimo'
                // },
                {
                  key: 'massimo',
                  label: 'Valore massimo'
                }
              );
            }
          }
        }

        this.tabledata.header.push(

          {
            key: 'valore',
          },
          {
            key: "note"
          },
          {
            key: 'azione',
          }
        
        );
      },
      doAction(action, id) {
        // console.log('acion', action, id);
        this.editFormData = { content: {}, conf: {} };
        // get fresh data from storage
        const scheda = this.getScheda();

        let mapElement = tool.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, id));
        
        const dataEntry = scheda.istruttoria.dataEntryConfiguration;

        if (dataEntry && dataEntry[id]) {
          mapElement.data = tool.genericFunctions.cloneObject(
            dataEntry[id].data
          );
        } else {
          mapElement.data = this.$getDefaultData();
        }

        let val = { id: id, value: null};
        const values = jpath("$" + id, scheda);
        if (values.length === 0) {
          console.log("valore non trovato in scheda: ", id);
        } else {
          val.value = values[0];
        }

        // BUG#6438
        if(this.noteCurrent && this.noteCurrent.note && this.noteCurrent.id === id) {
          mapElement.data.note = this.noteCurrent.note
        }
        this.editFormData.conf[id] = mapElement;
        this.editFormData.content[id] = tool.genericFunctions.cloneObject(val);

        this.$bvModal.show("modalCheck-" + this.name);
      },
      updateNoteFieldEditing(id, dataFromModal) {
        this.noteCurrent = {}
        let note = dataFromModal.conf[id].data.note
        this.noteCurrent = {id: id, note: note}
      },
      editRiga(dataFromModal) {
        this.$bvModal.hide("modalCheck-" + this.name);
        let clonedScheda = tool.genericFunctions.cloneObject(this.getScheda());
        let schedaComplete = {
          schedaProgetto: clonedScheda,
          taskInfo: {}
        }
        
        // BUG#6438
        // GenericCheckList aggiorna in maniera totale i campi (nel senso che li ricalcola TUTTI)
        // Prevedo questa funzione per aggiornare o comunque non perdere il valore corrente delle note
        let idField = Object.keys(dataFromModal.conf)[0]
        this.updateNoteFieldEditing(idField, dataFromModal)

        // setup value
        // riuso una funzione realizzata per scheda progetto
        let result = mapUtils.updateComponent(dataFromModal, schedaComplete, null, this.mappa, null, this.title);
        // nella funzione riusata updateComponent la configurazione viene scritta dentro clonedScheda,
        // invece deve trovarsi dentro istruttoria. Allora
        // aggiungo la configurazione dentro la scheda istruttoria
        for(const [key, item] of Object.entries(result.clonedScheda.dataEntryConfiguration)) {
          const itemToAdd = tool.genericFunctions.cloneObject(item);
          result.clonedScheda.istruttoria.dataEntryConfiguration[key] = itemToAdd;
        }
        this.updateSchedaInStore(result.clonedScheda);
        this.extractData();
        this.$emit('istruttoriaUpdated');
      }
    },
    mounted() {
      this.mappa = this.getMappa;
      this.setupHeader();
      this.extractData();
    }
};
</script>

