<template>
  <div id="invio">
        <collapse :name="'Campi obbligatori non valorizzati ('+errori.rows.length+')'">
            <br/>
            <DynamicTable :idTablePdf="'monitoring_mandatory'" v-bind:tdata="errori"/>
        </collapse>

        <collapse :name="'Modifiche Sostanziali ('+tabledata.rows.length+')'">
            <br/>
            <b-table-lite   id="monitoring_sost" 
                            thead-class="head"
                            :items="tabledata.rows"
                            :fields="tabledata.header"/>
        </collapse>
        
        <collapse :name="'Modifiche Non Sostanziali ('+tabledata2.rows.length+')'">
            <br/>
            <b-table-lite   id="monitoring_nonsost"
                            thead-class="head"
                            :items="tabledata2.rows"
                            :fields="tabledata2.header"/>
        </collapse>

        <collapse :name="'Elenco Note ('+ fillNote.length+')'">
            <br/>
            <TableNotes :id="'invio_note'" :notes="fillNote"/>
        </collapse>

        <br>

        <collapse v-if="isVisibleInternalNote" :name="'Elenco Note Interne ('+ fillNoteRiservate.length+')'">
            <br/>
            <TableNotes :id="'invio_note_riservate'" :notes="fillNoteRiservate"/>
            <div class="note" v-if="!isHiddenNoteFn()">
            <br>
                <b-form-textarea
                    id="textAreaNoteRiservate"
                    :disabled="this.disableButtons"
                    v-model="noteRiservate"
                    placeholder="Inserire qui le note interne"/>
            </div>
            <div class="pulsanti-invio" v-if="!isHiddenNoteFn()">
                <b-button :id="'cancelNoteRiservate_monitoraggio'"
                    class="mx-3 btnReservedNote"
                    :disabled="this.disableButtons"
                    variant="danger"
                    @click="resetReservedNote()"
                    >Cancella Nota Interna</b-button>
            </div>
        </collapse>

        <br>
        
        <div class="note" v-if="!isHiddenNoteFn()">
            <b-form-textarea
                id="textAreaNote"
                :disabled="this.disableButtons"
                v-model="note"
                placeholder="Inserire qui le note"/>
        </div>

        <br/>
        <div v-if="errori.rows.length>0" class="warning-text">
            <strong>ATTENZIONE: l'assenza di valore su campi obbligatori può comportare errori nel processo di approvazione.
                <br/>
                Verificare i dati del progetto e/o dell'ultimo monitoraggio
            </strong>
        </div>
        <b-alert :show="isSostanziale()" variant="danger">
            <p>ATTENZIONE: in presenza di modifiche sostanziali non è possibile continuare il monitoraggio, ma bisogna
            modificare la relativa scheda progetto.
            </p>
            <p>Cliccando sull'apposito pulsante potrai modificare la scheda progetto
            direttamente dalla lista dei tuoi progetti in attuazione.
            </p>
            Attenzione: tutte le modifiche alla scheda monitoraggio andranno perdute.
        </b-alert>
        <br/>

        <div class="pulsanti-invio">
            <b-button
                :disabled="disableButtons"
                variant="secondary"
                class="bt1"
                @click="preparePdf">Export PDF</b-button>
            <b-button :id="'save_monitoraggio'"
                class="mx-3"
                :disabled="this.disableButtons"
                variant="primary"
                @click="save"
                v-if="!isHiddenSaveFn()">Salva in Bozza</b-button>
            <b-button :id="'send_monitoraggio'"
                class="mx-3"
                :disabled="this.disableButtons"
                variant="success"
                @click="openCompletaMonitoraggio('send')"
                v-if="!isHiddenSendFn()">Salva e Invia scheda di monitoraggio</b-button>
            <b-button :id="'approve_monitoraggio'"
                class="mx-3"
                :disabled="this.disableButtons"
                variant="success"
                @click="openCompletaMonitoraggio('approve')"
                v-if="!isHiddenApprove">Approva</b-button>
            <b-button :id="'reject_monitoraggio'"
                class="mx-3"
                :disabled="this.disableButtons"
                variant="danger"
                @click="openCompletaMonitoraggio('reject')"
                v-if="!isHiddenReject">Rifiuta</b-button>
            <b-button :id="'cancelNote_monitoraggio'"
                class="mx-3"
                :disabled="this.disableButtons"
                variant="danger"
                @click="resetNote()"
                v-if="!isHiddenNoteFn()">Cancella Nota</b-button>
            <b-button :id="'gotoSchedaProgetto'"
                class="mx-3"
                :disabled="this.disableButtons"
                variant="info"
                @click="gotoSchedaProgetto()"
                v-if="!isHiddenGotoProgettoFn()">Vai alla lista progetti in attuazione</b-button>

           <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
        </div>
    </div>
</template>


<script>
import DynamicTable from "@/components/dynamicTable.vue";
import TableNotes from "@/components/TableNotes.vue"
import { mapGetters } from 'vuex';
import tools from '@/helpers/tools.js'
import utils from '@/helpers/utils.js'
import notify from "@/helpers/notifications.js"
import collapse from "@/components/collapse.vue"
import { JSONPath as jpath } from 'jsonpath-plus';
import mapUtils from '@/helpers/mapUtils.js'
import helper_pdf from "@/helpers/exportPdf.js"

export default {
    name: "invio",
    components: {
        DynamicTable,
        TableNotes,
        collapse
    },
    props: {
        incomingData: Object,
        tabName: String,
        idsToCheck: Object,  default: function() { return {} }
    },
    computed: {
        isSostanziale() {
            return () => {
                return this.tabledata.rows.length > 0;
            }
        },
        ...mapGetters({
            // getNoteInBozza: 'monitoraggio/getNoteInBozza',
            getScheda: 'monitoraggio/getScheda',
            getSchedaCronoprog: 'cronoprog/getSchedaComplete',
            getTabelloneFull: 'configuration/getTabelloneFull'
        }),
        isHiddenSaveFn() {
            return () => {
                return this.isHiddenSave || this.isSostanziale();
            }
        },
        isHiddenSendFn() {
            return () => {
                return this.isHiddenSend || this.isSostanziale();
            }
        },
        isHiddenNoteFn() {
            return () => {
                return this.isHiddenNote || this.isSostanziale();
            }
        },
        isHiddenGotoProgettoFn() {
            return () => {
                return !this.isSostanziale();
            }
        },
        fillNote: function() {
            if(!this.incomingData || !this.incomingData.schedaMonitoraggio || !this.incomingData.schedaMonitoraggio.note)
                return [];
            return this.incomingData.schedaMonitoraggio.note;
        },
        fillNoteRiservate: function() {
            const scheda = this.incomingData.schedaNoteRiservate;
            if(!scheda)
                return [];
            return scheda?.content?.noteRiservate || [];
        },
    },
    watch: {
        tabName: function() {
            if(this.$props.tabName === "invio") {
                this.extractData();
            }
        }
    },
    data() {
        return {
            note: "",
            noteRiservate: "",
            schemaVersioneCronoprog: null,
            loadComplete: false,
            isVisibleInternalNote: false,
            isHiddenSave: false,
            isHiddenSend: false,
            isHiddenApprove: false,
            isHiddenReject: false,
            isHiddenNote: false,
            disableButtons: false,
            tab_cronoProg: {},
            modal1Map:{
            approve:{
                title:"Conferma Monitoraggio", text:"Conferma di voler Approvare il Monitoraggio? "
            },
            send:{
                title:"Conferma Assessment", text:"Conferma di voler Inviare il Monitoraggio?"
            },
            reject:{
                title:"Rifiuta Assessment", text:"Conferma di voler Rifiutare il Monitoraggio?"
            },
            default:{
                title:"", text:""
            }
            

        },
        
        setModal:"default",
            tabledata: {
                header: ["Tab", "Campo Modificato", "Attuale" , "Precedente" , "Note"],
                rows: []
            },

            tabledata2: {
                header: ["Tab", "Campo Modificato", "Attuale" , "Precedente", "Note"],
                rows: []
            },

            errori: {
                header: ["Tab", "Nome Campo", "Tipo dato"],
                rows: []
            }
        };
    },
    mounted() {
        const tabelloneFull = this.getTabelloneFull('cronoprog_monitoraggio');
        this.tab_cronoProg = tabelloneFull.tabellone;
        this.schemaVersioneCronoprog = tabelloneFull.version;
        this.extractData();
        this.fillNoteInBozza();
        this.fillNoteRiservateInBozza();
        this.loadComplete = true;
    },
    methods: {
        preparePdf() {
            let scheda = this.incomingData.schedaMonitoraggio
            let typeScheda = 'Monitoraggio'
            let optPdf = helper_pdf.handleNamingPdf(scheda, typeScheda)
            this.$emit('buildPdf', optPdf)
        },
        extractData() {
            if(!this.incomingData) {
                console.error('invio: dati non trovati');
                return;
            }
            this.getVisibility();
            this.elaboraDiff();
            this.elaboraDiffCronoprog();
            this.fillError();
        },
        fillError() {
            this.errori.rows = [];
            let errs = mapUtils.controllaMandatori(this.incomingData.schedaMonitoraggio);
            // A questi obbligatori vanno aggiunti quelli di Cronoprog
            const mandatoryCronoProgMissed = this.getMandatoryCronoProgMissed();
            if(mandatoryCronoProgMissed.length > 0)
                errs = errs.concat(mandatoryCronoProgMissed)
            this.errori.rows = errs;
        },
        getCronoprog(idScheda) {
            return this.getSchedaCronoprog({idScheda: idScheda}).scheda
        },
        getMandatoryCronoProgMissed() { 
            const idProgetto = this.incomingData.schedaMonitoraggio.progetto.idProgetto;
            let scheda = this.getCronoprog(idProgetto).content;
            return mapUtils.getMandatoryFieldsCronoprog(scheda, this.idsToCheck, this.tab_cronoProg);
        },
        trovaValoreAttuale(chiave){
            let values = jpath('$'+chiave, this.incomingData.schedaMonitoraggio);
            if(values.length>1){
                console.error("trovati più valori per la chiave ="+chiave,values);
                return values[1];   //torno l'ultima
            }
            return values[0]; //torno la prima ed unica
        },

        elaboraDiff(){
            this.tabledata.rows = [];
            this.tabledata2.rows = [];
            if(!this.incomingData.schedaMonitoraggio.dataEntryConfiguration){
                console.error("Mappa di configurazione non presente!?");
                return;
            }
            //ciclare per tutti gli elementi della mappa configurazione
            // let chiavi = Object.keys(this.incomingData.schedaMonitoraggio.dataEntryConfiguration);
            for (const [key, item] of Object.entries(this.incomingData.schedaMonitoraggio.dataEntryConfiguration)){
                // let item = this.incomingData.schedaMonitoraggio.dataEntryConfiguration[chiavi[i]];
                // skip di tutti gli elementi generici (con asterischi)
                if(item.config.path.indexOf('*') !== -1)
                    continue;
                
                let temp = "";
                if(item.data.editTag) {
                    temp = item.data.editTag + ' ';
                }
                //imposto i campi comuni
                let row = {
                    // content: {
                        "Tab": item.config.tab,
                        "Campo Modificato": temp + item.config.label,
                        "Note": item.data.note
                    // }
                }

                if (item.data.deleted && !item.data.edited)
                    continue;

                if(item.data.edited && item.data.isSubstancial){
                    const currentVal = this.trovaValoreAttuale(key);
                    //riempire array delle diff sostanziali
                    row["Attuale"] = utils.formatOutput(item, currentVal);
                    row["Precedente"] = utils.formatOutput(item, item.data.oldValue);

                    this.tabledata.rows.push(row);

                } else if (item.data.deleted || item.data.added) {

                    let valore;
                    let valorePrec;

                    if(item.data.deleted && item.data.added){
                        continue;
                    } else {
                        const currentVal = this.trovaValoreAttuale(key);
                        if (item.data.deleted){
                            valore="(nessuno, cancellato)";
                            valorePrec=utils.formatOutput(item, currentVal);
                        }

                        if (item.data.added){
                            valorePrec="(nessuno, inserito)";
                            valore=utils.formatOutput(item, currentVal);
                        }

                    }

                    row["Attuale"] = valore;
                    row["Precedente"] = valorePrec;


                    this.tabledata2.rows.push(row);
                }
                else if (item.data.edited){
                    const currentVal = this.trovaValoreAttuale(key);
                    //riempire array delle differenze non sostanziali
                    row["Attuale"] = utils.formatOutput(item, currentVal);
                    row["Precedente"] = utils.formatOutput(item, item.data.oldValue);

                    this.tabledata2.rows.push(row);
                }
            }
        },  

        elaboraDiffCronoprog(){
            const idProgetto = this.incomingData.schedaMonitoraggio.progetto.idProgetto
            const schedaCronoprog = this.getCronoprog(idProgetto)
            let result = utils.elaboraDiffCronoprog(schedaCronoprog, this.getTabelloneFull('cronoprog_monitoraggio'))
            this.tabledata.rows = [...this.tabledata.rows, ...result.substancial]
            this.tabledata2.rows = [...this.tabledata2.rows, ...result.notSubstancial]
        },

        fillNoteInBozza(){
            this.note = this.incomingData.schedaMonitoraggio.notaTemporanea;
        },

        fillNoteRiservateInBozza(){
            this.noteRiservate = this.incomingData.schedaMonitoraggio.notaTemporaneaRiservata;
        },
    
        async save() {
            if(!this.incomingData) {
                console.log("invio.save: Invalid scheda!?");
                return null;
            }
            this.disableButtons = true;
            //Request Body
            const scheda = tools.genericFunctions.cloneObject(this.incomingData.schedaMonitoraggio);
            scheda.notaTemporanea = this.note;
            let entity = { monitoraggio: {content: scheda} };
            scheda.notaTemporaneaRiservata = this.noteRiservate;

            const idProgetto = this.incomingData.schedaMonitoraggio.progetto.idProgetto;
            let cronoprog = {}
            if(idProgetto && 
                (this.incomingData.taskList.name === "Beneficiario-Operatore_inserimentoDati") || 
                 this.incomingData.taskList.name === "Compilazione Monitoraggio") {
                //Se sono al primo step, salvo anche la scheda cronoprog
                cronoprog = this.getCronoprog(idProgetto) 
                entity.cronoprog = cronoprog
            }

            console.log("Save Request Body: ", entity)
            
            let progetto = this.incomingData.schedaMonitoraggio.progetto.codiceProgetto;
            let periodo = this.incomingData.schedaMonitoraggio.idPeriodo;

            let retValue = null;
            await this.$store.dispatch('monitoraggio/saveSchedaMonitoraggio', {
                codiceProgetto: progetto,
                idPeriodo: periodo,
                scheda: entity
            }).then(
                result => {
                    if (tools.objectHasValue(result)) {
                        notify.success(notify.strings.monitoring, notify.strings.saveSchedaOk)
                        retValue = result;
                    }else {
                        notify.error(notify.strings.monitoring, notify.strings.saveSchedaError)
                        retValue = null;
                    }
                }).catch(
                (error) =>{
                    console.error("saveSchedaMonitoraggio error: ", error.message);
                    notify.error(notify.strings.monitoring, notify.strings.saveSchedaError);
                    retValue = null;
                    return;
                })

            this.disableButtons = false;
            if (!retValue.cronoprog){
                retValue.cronoprog = {
                    key: scheda.cronoprogKey
                };
            }
            return retValue;
        },

        actionModal(){
            switch (this.setModal) {
                    case "send":
                        return this.send();
                    case "reject":
                       return this.reject();
                    case "approve":
                       return this.approve();
                    
                    default:
                        console.log("Case false");
            }
        },
        openCompletaMonitoraggio(action){
            this.setModal = action;
            this.$bvModal.show('modalCompletaInvia');
        },

        async send() {
            if(!this.incomingData) {
                console.log("invio.save: Invalid scheda!?");
                return null;
            }

            const scheda = this.incomingData.schedaMonitoraggio;

            const codiceProgetto = scheda.progetto.codiceProgetto;
            const idPeriodo = scheda.idPeriodo;

            console.log("cliccato su send per ", codiceProgetto, idPeriodo);
            const saveResult = await this.save();
            if(saveResult == null) {
                console.log('salvataggio fallito');
                return false;
            }
            this.disableButtons = true;
            let modificaSostanziale = false;

            // la key di cronoprog e la versione del suo tabellone vengono passati sempre
            // ma il backend li setta solo nelle giuste fasi del processo
            const cronoKey = saveResult.cronoprog.key;
            const schemaVersioneCronoprog = this.schemaVersioneCronoprog;

            if (tools.objectHasValue(this.tabledata)) {
                modificaSostanziale = this.tabledata.rows.length>0;
            }

            console.log("modificaSostanziale=" + modificaSostanziale);

            await this.$store.dispatch('monitoraggio/completeSchedaMonitoraggio', {
                codiceProgetto: codiceProgetto,
                idPeriodo: idPeriodo,
                approvazione: true,
                cronoprogKey: cronoKey,
                schemaVersioneCronoprog: schemaVersioneCronoprog,
                modificaSostanziale: modificaSostanziale
            }).then(
                () => {
                    notify.success(notify.strings.success, notify.strings.sendSchedaSuccess)
                    this.$store.dispatch('monitoraggio/clearSchedaMonitoraggio', {
                        codiceProgetto: codiceProgetto,
                        idPeriodo: idPeriodo,
                    });

                    this.$router.push({ name: "secure" });
                    return true;
                },
                error => {
                    console.log('error SEND', error.response);
                    notify.error(notify.strings.monitoring, notify.strings.sendSchedaError);
                    this.disableButtons = false;
                    return false;
                }
            );
        },

        async approve() {
            if(!this.incomingData) {
                console.log("invio.save: Invalid scheda!?");
                return null;
            }

            const scheda = this.incomingData.schedaMonitoraggio;

            const codiceProgetto = scheda.progetto.codiceProgetto;
            const idPeriodo = scheda.idPeriodo;

            console.log("cliccato su approve per ", codiceProgetto, idPeriodo, this.incomingData.taskList);
            const saveResult = await this.save();
            if(saveResult == null) {
                console.log('salvataggio fallito');
                this.disableButtons = false;
                return false;
            }

            this.disableButtons = true;

            // le approvazioni non salvano mai cronoprog, quindi
            // la cronoprogKey non cambia: allora la prendo dalla scheda
            const cronoKey = scheda.cronoprogKey;
            const schemaVersioneCronoprog = this.schemaVersioneCronoprog;
            
            let modificaSostanziale = false;
            if (tools.objectHasValue(this.tabledata)) {
                modificaSostanziale = this.tabledata.rows.length>0;
            }
            console.log("modificaSostanziale=" + modificaSostanziale);

            await this.$store.dispatch('monitoraggio/completeSchedaMonitoraggio', {
                codiceProgetto: codiceProgetto,
                idPeriodo: idPeriodo,
                approvazione: true,
                schemaVersioneCronoprog: schemaVersioneCronoprog,
                cronoprogKey: cronoKey,
                modificaSostanziale: modificaSostanziale
            }).then(
                () => {
                    notify.success(notify.strings.success, notify.strings.approvedRequestSuccess)
                    this.$store.dispatch('monitoraggio/clearSchedaMonitoraggio', {
                        codiceProgetto: codiceProgetto,
                        idPeriodo: idPeriodo,
                    });
                    this.$router.push({ name: "secure" });
                    return true;
                },
                error => {
                    console.log('error APPROVE', error.response);
                    notify.error(notify.strings.monitoring, notify.strings.approvedRequestError);
                    this.disableButtons = false;
                    return false;
                }
            );
        },

        async reject() {
            if(!this.incomingData) {
                console.log("invio.save: Invalid scheda!?");
                return null;
            }
            const scheda = this.incomingData.schedaMonitoraggio;

            const codiceProgetto = scheda.progetto.codiceProgetto;
            const idPeriodo = scheda.idPeriodo;
            console.log("cliccato su reject per ", codiceProgetto, idPeriodo);

            const saveResult = await this.save();
            if(!saveResult) {
                console.log('salvataggio fallito');
                this.disableButtons = false;
                return false;
            }
            this.disableButtons = true;
            /// i rifiuti non salvano mai cronoprog, quindi
            // la cronoprogKey non cambia: allora la prendo dalla scheda
            const cronoKey = scheda.cronoprogKey;
            const schemaVersioneCronoprog = this.schemaVersioneCronoprog;
            
            await this.$store.dispatch('monitoraggio/completeSchedaMonitoraggio', {
                codiceProgetto: codiceProgetto,
                idPeriodo: idPeriodo,
                cronoprogKey: cronoKey,
                schemaVersioneCronoprog: schemaVersioneCronoprog,
                approvazione: false
            }).then(
                () => {
                    notify.success(notify.strings.success, notify.strings.rejectRequestSuccess)
                    this.$store.dispatch('monitoraggio/clearSchedaMonitoraggio', {
                        codiceProgetto: codiceProgetto,
                        idPeriodo: idPeriodo,
                    });
                    this.$router.push({ name: "secure" });
                    return true;
                },
                error => {
                    console.log('error REJECT', error.response);
                    this.disableButtons = false;
                    notify.error(notify.strings.monitoring, notify.strings.rejectRequestError);
                    return false;
                }
            );
        },

        resetNote() {
            this.note = ""
        },

        resetReservedNote() {
            this.noteRiservate = ""
        },

        getVisibility() {
            const schedaCompleta = this.incomingData;
            if(!schedaCompleta) {
                console.log("invio.getVisibility: Invalid scheda!?");
                return;
            }
            const scheda = schedaCompleta.schedaMonitoraggio;
            const task = schedaCompleta.taskList;
            // WARNING: se le note riservate non vengono caricate (vecchi processi), allora non viene visualizzato il collapse
            this.isVisibleInternalNote = utils.caniuse("internalNote", task, scheda) && this.incomingData.schedaNoteRiservate;
            this.isHiddenSave = !utils.caniuse("save", task, scheda);
            this.isHiddenSend = !utils.caniuse("send", task, scheda);
            this.isHiddenApprove = !utils.caniuse("approve", task, scheda) || this.isSostanziale();
            this.isHiddenReject = !utils.caniuse("reject", task, scheda) || this.isSostanziale();

            this.isHiddenNote = this.isHiddenSave && this.isHiddenSend && this.isHiddenApprove && this.isHiddenReject;
        },
        gotoSchedaProgetto() {
            this.$router.push({
                name: 'listaProgettiModifica'
            });
        }
    }
}
</script>