<template>
    <div id="collapseAttivitaProgetto">
        <b-table-lite :id="idTablePdf" thead-class="head" :items="getItems" :fields="tabledata.header">
            <template v-slot:cell(Azione)="data">
                <b-button variant="outline-primary" :key="x" v-for="x in data.value" size="sm" v-on:click="doAction(x, data.index)">
                    <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
                </b-button>
            </template>

        </b-table-lite>
        <b-modal :id="'modalForm1-' + this.name" hide-footer
             size="lg" scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content">
             <template v-slot:modal-title>
                <h3>{{headerMsg}}</h3>
            </template>
            <ModalForm
                :fdata="this.editFormData"
                :sch="this.getScheda()"
                :buttons="this.buttons"
                :parentName="this.name"
                :cfg="mappa"
                @editField="editRiga"/>
        </b-modal>
    </div>
</template>
<script>
import ModalForm from "@/components/modalForm1.vue";
import mapUtils from '@/helpers/mapUtils.js'
import { JSONPath as jpath } from 'jsonpath-plus';
import { mapGetters } from "vuex";
import tool from '@/helpers/tools.js'
import utils from "@/helpers/utils.js";
import calculation from '@/helpers/calculations.js';

export default {
    name:"Vertical",
    data() {
        return {
            editLine: -1,
            headerMsg: '',
            mappa: {},
            tabledata: {
                header: [],
                "rows": []
            },
            buttons: [
                {
                    name: "Modifica",
                    action: "editField",
                    show: true,
                    param: "edit",
                },
            ],

            editFormData: {},
            lastStarRegex: /\*(?!.*\*)/,
            isIdRegex: /\[.+\]/,
            actions: ['edit'],
            // deleteIndex: null,
            costiArray: [
                "['progetto']['cronoProgramma'][*]['costiPersonale'][*]",
                "['progetto']['cronoProgramma'][*]['costiViaggio'][*]",
                "['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]",
                "['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]",
                "['progetto']['cronoProgramma'][*]['costiInformazione'][*]",
                "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]",
                "['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]",
                "['costiIndiretti'][*]"
                

            ],
            elencoObiettivi: [],
            obiettivoId: "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]",
            importoId: "['budgetPerObiettivi'][*]['costi'][*]['importo']",
            budgetPerObiettiviId: "['budgetPerObiettivi'][*]['costi'][*]",
            totaleAmmissibileId: "['budgetPerObiettivi'][*]['costi'][*]['totaleAmmissibile']",
            totalePerObiettiviId: "['budgetPerObiettivi'][*]['costi'][*]['totalePerObiettivi']",
            tipoSpesaId: "['budgetPerObiettivi'][*]['costi'][*]['tipoSpesa']"
        }
    },
    components: {
        ModalForm
    },
    props: {
        inputs: {
            type: Object
        },
        dinamicIds: {
            type: Array,
            required: true
        },
        name: String,
        title: String,
        updateBudgetObiettivi: Boolean,
        idTablePdf: { type: String, default: () => '' },
    },
    watch: {
        updateBudgetObiettivi: function() {
            this.extractData();
        }
    },
    mounted() {
        this.mappa = this.getTabelloneProgetto('progetto');
        if(this.mappa) {
            const scheda = tool.genericFunctions.cloneObject(this.getScheda());
            let temp = jpath({resultType:'all'}, '$' + this.obiettivoId, scheda);
            if(temp && temp.length > 0)
                this.elencoObiettivi = temp;
            this.extractData();
        }
        else
            console.log(this.name, 'error in mappa', this.mappa);
    },
    computed: {
        ...mapGetters({
            getTabelloneProgetto: "configuration/getTabellone",
            getSchedaProgetto: "progetto/getSchedaProgetto",
            getSchedaComplete: "progetto/getScheda"
        }),
        getItems() {
            let items = [];
            for(const obj of this.tabledata.rows){
                let item = {
                    note: {}
                };
                for(const key in obj)
                {
                    if(!key.match(this.isIdRegex)){
                        item[key] = obj[key];
                    }
                    else {
                        item[key] = obj[key].value;
                       
                        // se c'è la nota, allora la devo accoppiare all'id corrispondente alla colonna
                        // di un dato obiettivo specifico. Questo id è del tipo obiettivoId,
                        // cioè ad esempio "['progetto']['obiettivoProgetto']['obiettivoNazionale'][0]"
                        if(obj[key].note) {
                            // prendo l'id specifico della colonna: il primo indice è quello della colonna
                            // de''obiettivo specifico, mentre il secondo indice corrisponde alla riga
                            const id = obj[key].id;
                            // con questa regex ricavo il primo indice
                            const matchArr = id.match(/\d+/);
                            if(matchArr && matchArr.length > 0) {
                                const index = matchArr[0];
                                // sostituisco il primo indice nell'id obiettivo
                                const noteId = this.obiettivoId.replace('*', index);
                                // accoppio la nota
                                item.note[noteId] = obj[key].note;
                            }
                        }
                    }
                }
               
            
                items.push(item);
            }
           
            return items;
        }
    },
    methods: {
        tooltip(value, key, item) {
            if(item.note[key])
                return {title: 'nota: ' + item.note[key]};
            return {};
        },
        updateSchedaInStore(scheda) {
            this.$store.dispatch('progetto/updateSchedaProgettoObject',
                {
                    idSchedaProgetto: this.$route.params.idProgetto,
                    schedaProgetto: scheda
                });
        },
        extractData() {
            if(!this.mappa){
                lastRow[this.tipoSpesaId]={id: this.tipoSpesaId, value: 'TOTALE'};
            }
            if(!this.mappa) {
                console.error(this.name, 'Invalid mappa', this.mappa);
                return;
            }
            
            const lastRow = {
                _rowVariant: 'info',
                [this.tipoSpesaId]: {id: this.tipoSpesaId, value: 'TOTALE'}
            };

            let updateScheda = false;
            const schedaComplete = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto});
            let scheda = schedaComplete.schedaProgetto;
            if(!scheda) {
                console.error(this.name, 'Invalid scheda', scheda);
                return;
            }
            scheda = tool.genericFunctions.cloneObject(scheda);

            const spesaLabel = this.mappa[this.tipoSpesaId].config.label;
            const totaleAmmissibileLabel = this.mappa[this.totaleAmmissibileId].config.label;
            const totalePerObiettiviLabel = this.mappa[this.totalePerObiettiviId].config.label;
            // setup header
            
            this.tabledata.header = [
                {
                    key: this.tipoSpesaId,
                    label: spesaLabel,
                },
                {
                    key: this.totaleAmmissibileId,
                    label: totaleAmmissibileLabel,
                    formatter: this.currencyFormatter
                },
                {
                    key: this.totalePerObiettiviId,
                    label: totalePerObiettiviLabel,
                    formatter: this.currencyFormatter
                }
            ];
            
            let obiettiviArray = [];
            // aggiunge una colonna per ogni obiettivo specifico
            for(const item of this.elencoObiettivi) {
                const key = item.path.replace('$', '');
                let temp = key.match(mapUtils.indexRegex);
                if(temp && temp.length === 1)
                    obiettiviArray.push(temp[0]);
                const headerColumn = {
                    key: key,
                    label: item.value.codiceComposito,
                    formatter: this.currencyFormatter,
                    tdAttr: this.tooltip
                }
               
                this.tabledata.header.push(headerColumn);
            }
            // aggiunge la colonna delle azioni
            this.tabledata.header.push({ key: 'Azione', label: 'Azione'});

            // add rows to table
            this.tabledata.rows = [];
            
            const actions = this.$projectGetActions(schedaComplete);
            let azioni = [];
            if(actions.indexOf('edit') !== -1)
                azioni.push('edit');
            // aggiunge la colonna delle tipologie di spesa
            // e quella dei totali dei collapse budget
            let totaleMap = {};

            // primo ciclo sulle voci di costo per creare le righe della tabella
            let totaleTotaleAmmissibile = 0;
            for(let costoId of this.costiArray) {
                
                let row = {'_cellVariants': {}};
                // colonna contenente l'id del tipo di costo "Personale, Viaggi, ecc" -> non mostrata
                row[costoId] = {id: costoId, value: this.mappa[costoId].config.defaultValue};
                
                // prima colonna: tipo di spesa
                row[this.tipoSpesaId] = {id: this.tipoSpesaId, value: this.mappa[costoId].config.label};
                // seconda colonna: totale ammissibile = totale delle voci di budget per quel 
                // dato tipo di spesa (personale, viaggi, ecc)
                const totale = calculation.totaleInRowCosts(scheda, costoId);
                row[this.totaleAmmissibileId] = {id: this.totaleAmmissibileId, value: totale};
                totaleTotaleAmmissibile += totale;

                // colonna delle azioni
                row.Azione = azioni;
                this.tabledata.rows.push(row);
                // prepara il totale per obiettivi, per ciascuna voce di budget,
                // inizializzato a zero
                totaleMap[costoId] = 0;
            }
            lastRow[this.totaleAmmissibileId] = {id: this.totaleAmmissibileId, value: tool.roundFloat2Decs(totaleTotaleAmmissibile)};


            // ciclo per tutte le colonne (tutti gli obiettivi del progetto)
            for(const indiceObiettivo of obiettiviArray) {
                let totaleObbiettivoNazionale=0;
                // ricavo dalla scheda i budget per quel dato obiettivo
                const budgetId = this.budgetPerObiettiviId.replace(/\*/, indiceObiettivo);
                let results = jpath('$' + budgetId, scheda);
                
                // verrà usato più giù per settare l'importo del budget
                const id = this.obiettivoId.replace(/\*/, indiceObiettivo);

                // data una colonna, ciclo per tutte le righe, cioè voci di costo (viaggi, servizi, ecc)
                for(let costoIndex in this.costiArray) {
                    let row = this.tabledata.rows[costoIndex];
                    const currentId = this.costiArray[costoIndex];
                    const mapElement = this.mappa[currentId];
                   

                    let importo = -1;

                    if(mapElement && mapElement.config.defaultValue) {
                        const tipoSpesa = mapElement.config.defaultValue;
                        if(results && results.length > 0) {
                            for(const budgetObj of results) {
                                if(budgetObj.tipoSpesa === tipoSpesa) {
                                    importo = budgetObj.importo;
                                    break;
                                }
                            }
                        }

                        // se non ho trovato la voce di budget nella scheda, allora 
                        // la creo con importo zero
                        if(importo === -1) {
                            // creo il tipo di voce di costo
                            const tipoId = this.tipoSpesaId.replace(/\*/,indiceObiettivo).replace(/\*/, costoIndex);
                            let retVal = mapUtils.createElementFromScratch(tipoId, scheda, this.mappa);
                            scheda = retVal.scheda;

                            // creo il campo importo
                            importo = 0;
                            const realImportoId = this.importoId.replace(/\*/,indiceObiettivo).replace(/\*/, costoIndex);
                            retVal = mapUtils.createElementFromScratch(realImportoId, scheda, this.mappa);
                            scheda = retVal.scheda;
                            updateScheda = true;
                        }
                    } else {
                        console.error('extractdata: mapElement non trovato', currentId, this.mappa);
                        continue;
                    }

                    // aggiunge la colonna dell'obiettivo specifico
                    // l'id è ad esempio "['progetto']['obiettivoProgetto']['obiettivoNazionale'][0]"
                    row[id] = {id: id, value: importo};
                    const parsedImporto = parseFloat(importo);
                    if(!isNaN(parsedImporto))
                        totaleObbiettivoNazionale += parsedImporto;
                    
                    lastRow[id]={id:id,value:totaleObbiettivoNazionale};
                    let importoFloat = parseFloat(importo);
                    // qui viene man mano sommato il totale per tutti gli obiettivi
                    if(!isNaN(importoFloat)) {
                        totaleMap[currentId] += importoFloat;
                    } else {
                        importoFloat = 0;
                    }
                    //se modificata, coloro opportunamente la riga iesima
                    // row = this.coloraRiga(row, id, mapElement, costo);
                    const impId = this.importoId.replace(/\*/,indiceObiettivo).replace(/\*/, costoIndex);
                    this.settaColoriNota(row, id, impId, importoFloat, this.mappa, scheda);
                }
            }
            // ultimo ciclo sulle righe per settare il totale per obiettivi
            let totaleTotalePerObbiettivi = 0;
            for(let costoIndex in this.costiArray) {
                let row = this.tabledata.rows[costoIndex];
                const costoId = this.costiArray[costoIndex];
                row[this.totalePerObiettiviId] = {id: this.totalePerObiettiviId, value: totaleMap[costoId]};
                const val = parseFloat(totaleMap[costoId]);
                if(!isNaN(val))
                    totaleTotalePerObbiettivi += val;
            }
            lastRow[this.totalePerObiettiviId] = {id: this.totalePerObiettiviId, value: tool.roundFloat2Decs(totaleTotalePerObbiettivi)};
            this.tabledata.rows.push(lastRow);
            if(updateScheda) {
                this.updateSchedaInStore(scheda)
            }
            
        },
        currencyFormatter(value) {
            
            if (value == undefined || value == null)
                return null;
            return utils.formatCurrency(value);
        },
        getScheda() {
            return this.getSchedaProgetto({idSchedaProgetto: this.$route.params.idProgetto});
        },
        editRiga(actionFromModal) {
            
            this.$bvModal.hide('modalForm1-' + this.name);
            let rowObj = this.tabledata.rows[this.editLine];
           
            const schedaComplete = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto});
            
            //////////////////////////////////////////////////
            // codice ad hoc per la gestione del tipo spesa, che non viene passato nella modale e viene
            // quindi aggiunto qui per consentire il corretto aggiornamento della scheda
            for(let index in this.elencoObiettivi) {
               
               
                // aggiungo l'id del tipo di costo "Personale, Viaggi, ecc" -> non mostrata
                const costoId = this.costiArray[this.editLine];
                const realTipoSpesaId = this.tipoSpesaId.replace(/\*/, index).replace(/\*/, this.editLine);
                actionFromModal.content[realTipoSpesaId] = {id: realTipoSpesaId, value: this.mappa[costoId].config.defaultValue};
                let config = this.$getDefaultConfig();
                config.path = this.tipoSpesaId;
                actionFromModal.conf[realTipoSpesaId] = { config: config, data: this.$getDefaultData() };

            }
            //////////////////////////////////////////////////

            const retVal = mapUtils.updateComponent(actionFromModal, schedaComplete, null, this.mappa, this.actions, this.title);
            const clonedScheda = retVal.clonedScheda;
             
            let totPerObiettivi = 0;
            for(let index in this.elencoObiettivi) {
                const rowKey = this.obiettivoId.replace(/\*/, index);
                const modalKey = this.importoId.replace(/\*/, index).replace(/\*/, this.editLine);
                rowObj[rowKey].value = actionFromModal.content[modalKey].value;
                // const costo = utils.fromCurrencyToValue(rowObj[rowKey].value);
                const costo = parseFloat(rowObj[rowKey].value);
                if(!isNaN(costo))
                    totPerObiettivi += costo;
                //siccome costruisco in loco rowObj e chiamo  mapUtils.updateComponent(actionFromModal, schedaComplete, NULL...)
                //gestire qui la colorazione e le note (di solito gestito con la funzione mapUtils.setupComponentRow(...))
                rowObj = this.settaColoriNota(rowObj, rowKey, modalKey, costo, this.mappa, clonedScheda);
                 
            }
            rowObj[this.totalePerObiettiviId].value = totPerObiettivi;
            this.updateSchedaInStore(clonedScheda);
            this.tabledata.rows.splice(this.editLine, 1, rowObj);
            this.extractData();
        },

        settaColoriNota(rowObj, rowKey, realId, value, mappa, scheda){
            // 1) get formatted value
            //console.log("realId=", realId);
            const idWithStars = mapUtils.getGenericArrayKey(realId);
            
            let mapElement = mapUtils.getMapElement(mappa, realId);
            if(!mapElement) {
                mapElement = this.$getDefaultConfig();
            }
            const formattedVal = utils.formatOutput(mapElement, value);

            rowObj[idWithStars] = {
                id: realId,
                value: formattedVal,
                _cellVariants: {}
            }
            
            // 2) setup note and color variants
            const dataMap = scheda.dataEntryConfiguration;
            let data = {};
            if(dataMap && dataMap[realId] && dataMap[realId].data)
                data = dataMap[realId].data;
            
            if(data.deleted) {
                rowObj._rowVariant = 'secondary';
            }
            else if(data.added && !this.$disableEditAddColors(scheda)) {
                rowObj._rowVariant = 'success';
                rowObj._cellVariants = {};
            }
            else if(data.edited && !this.$disableEditAddColors(scheda)) {
                rowObj._cellVariants[rowKey] = data.isSubstancial ? 'danger': 'warning';
            }

            if (data.note){
                rowObj[idWithStars].note = data.note;
            }
            return rowObj;   
        },

        doAction(actionToDo, rowIndex) {
            switch(actionToDo) {
                case 'edit':
                    {
                       
                        const costoId = this.costiArray[rowIndex];
                        let msg = '';
                        if(this.mappa)
                            msg += this.mappa[costoId].config.label;
                        msg += ' (importo totale ammissibile: € ' 
                            + this.tabledata.rows[rowIndex][this.totaleAmmissibileId].value + ')';
                        this.headerMsg = msg;
                        this.editFormData = {content:{}, conf: {}};
                        // get fresh data from storage
                        const scheda = this.getScheda();
                        for(const obIndex in this.elencoObiettivi) {
                            const fieldId = this.importoId.replace(/\*/, obIndex).replace(/\*/, rowIndex);
                            const values = jpath('$'+ fieldId, scheda);
                            let val = {'id': fieldId};
                            if(values.length === 0) {
                                console.error('valore non trovato in scheda: ', val.id);
                            }
                            else {
                                val.value = values[0];
                            }
                            this.editFormData.content[fieldId] = tool.genericFunctions.cloneObject(val);

                            let mapElement = tool.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, fieldId));
                            mapElement.config.label += " per l'obiettivo " + this.elencoObiettivi[obIndex].value.codiceComposito;
                            const dataEntry = scheda.dataEntryConfiguration;

                            if(dataEntry && dataEntry[fieldId]) {
                                mapElement.data = tool.genericFunctions.cloneObject(dataEntry[fieldId].data);
                            }
                            else {
                                mapElement.data = this.$getDefaultData();
                            }
                            this.editFormData.conf[fieldId] = mapElement;
                        }
                        this.editLine = rowIndex;
                        this.$bvModal.show('modalForm1-' + this.name);
                    }
                    break;
                default:
                    this.editFormData = {};
                break;
            }
        }
    }
}
</script>
