import endpoints from "@/components/endpoints.vue";
import Vue from "vue";

const state = {
    schede: {},
    tabellone: {},
    allegatiDaCancellare: [],
    schedaNoteRiservate: null
}

const getters = {
    getScheda(state){
        return (payload) => {
            if(payload.id){
                if(state.schede[payload.id])
                    return state.schede[payload.id]
                else
                    console.error('irregolarita/getScheda missing scheda in store:', payload.id)
            }
            else
                console.error('irregolarita/getScheda required payload missing:', payload)

            return null
        }
    },
    getTabellone(state) {
        return state.tabellone
    },    
    getAllegatiDaCancellare(state){
        return state.allegatiDaCancellare;
    },
    getSchedaNoteRiservate(state) {
        return state.schedaNoteRiservate;
    }
}
 
const mutations = {
    SET_SCHEDA(state, payload){
        if(payload.id && payload.scheda){
            state.schede[payload.id] = payload.scheda
        }
        else
            console.error("SET_SCHEDA Error in Payload:", payload)
    },
    RESET(state){
        state.schede = {};
        state.tabellone = {};
        state.allegatiDaCancellare = [];
        state.schedaNoteRiservate = null;
    },
    SET_TABELLONE(state, payload) {
        if(payload && payload.content){
            state.tabellone = payload.content
          }
          else
            console.error("SET_TABELLONE Error in Payload:", payload)
    },
    SET_DA_CANCELLARE(state, payload){
        if(payload && payload.fileId){
            state.allegatiDaCancellare.push(payload.fileId)
        }
        else
          console.error("SET_DA_CANCELLARE Error in payload: ", payload)
    },
    RESET_DA_CANCELLARE(state){
        state.allegatiDaCancellare = []
    },
    ADD_SCHEDA_NOTE_RISERVATE(state, payload) {
        if(payload) {
            state.schedaNoteRiservate = payload;
        }
        else
            console.error('ADD_SCHEDA_NOTE_RISERVATE no scheda', payload);
    },
}

const actions = {
    setSchedaIrregolarita(context, payload){
        let codiceProgetto = payload.codiceProgetto
        let id = payload.idScheda
        if(codiceProgetto && id){
            const url = endpoints.getIrregolaritaByID(codiceProgetto, id)
            return Vue.prototype.$get(url).then(
                scheda => {
                    console.log("Irregolarita letta = ", scheda)
                    context.commit("SET_SCHEDA", {
                        id: id,
                        scheda: scheda
                    })

                    // GESTIONE SCHEDA NOTE RISERVATE IN STORE
                    if(!scheda.noteRiservateKey) return scheda;
                    else {
                        const keySchedaNoteRiservate = scheda.noteRiservateKey;
                        const version = scheda.noteRiservateVersion || 1000;
                        const url_reserved = endpoints.noteRiservate(keySchedaNoteRiservate)
                        return Vue.prototype.$get(url_reserved, {version: version}).then((schedaNoteRiservate) => {
                            context.commit('ADD_SCHEDA_NOTE_RISERVATE', schedaNoteRiservate);
                            return scheda;
                        },
                        (error) => {
                            console.error('setSchedaNoteRiservate error', error.response);
                            throw error
                        });
                    }
                },
                (error) => {
                    console.error("Errore setSchedaIrregolarita: ", error.message)
                    throw error 
                }
            )
        }
        else{
            console.error("Errore setSchedaIrregolarita: Required payload missing ", payload)
            return null
        }
    },
    //Recupera la lista delle irregolarità di un determinato progetto
    getIrregolaritaByCodice(context, payload){
        if(payload.codiceProgetto){
            let url = endpoints.getIrregolaritaByCodice(payload.codiceProgetto)
            return Vue.prototype.$get(url)
            .then( result => {
                return result
            },
            error => {
                console.error("Errore getIrregolaritaByCodice: ", error.message)
                throw error 
            }
            )
        }
        else{
            console.error("Errore getIrregolaritaByCodice: Required payload missing ", payload);
            throw new Error("Errore getIrregolaritaByCodice: Required payload missing ", payload);
        }
    },
    updateScheda(context, payload){
        let id = payload.id
        let scheda = payload.content
        if(id && scheda){
            context.commit("SET_SCHEDA", {
                id: id,
                scheda: scheda
            })
            return scheda              
        }
        else{
            console.error("Errore update Scheda Irregolarità: Required payload missing ", payload)
            return null
        }
    },
    //Necessario salvare il tabellone anche qui per Documentazione.vue
    setTabellone(context, payload){
        let tabellone = payload.tabellone
        if(tabellone){
            context.commit("SET_TABELLONE", {
                content: tabellone
            })
        }
        else{
            console.error("Errore setTabellone Irregolarita: Required payload missing ", payload)
        }
    },
    clear(context){
        context.commit("RESET")
    },
    setAllegatoDaCancellare(context, payload){
        context.commit("SET_DA_CANCELLARE", {
            fileId: payload.fileId
        })
    },
    clearAllegatiDaCancellare(context){
        context.commit("RESET_DA_CANCELLARE")
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}