<template>
  <div>
    <h1 class="text-center">Lista Progetti in valutazione-istruttoria</h1>
    <b-form inline class="m-1" style="display: flex;">
      <label for="filterProgettiInput"
        ><h4 class="mx-2">Filtra i risultati:</h4></label
      >
      <b-form-input
        v-model="filter"
        type="search"
        id="filterProgettiInput"
        placeholder="Digita un filtro"
      />
      <b-button
        id="filterProgettiButton"
        class="mx-1"
        :disabled="!filter"
        @click="filter = ''"
        >Cancella</b-button
      >
      <b-button
        :disabled="this.isBusy"
        id="progettiIstruttoriaButton"
        @click="filterIstruttoria()"
        >{{filterIstrButtonText}}
      </b-button>
    </b-form>
    
    <b-table
      :busy="isBusy"
      primary-key="id"
      emptyText="Nessun dato disponibile"
      :sort-by="sortBy"
      sort-desc
      :filter="filter"
      emptyFilteredText="Nessun dato disponibile"
      :filter-included-fields="getFilterFields()"
      :items="currentRows"
      :fields="tabledata.header"
      show-empty
      hover
      responsive
    >
      <template v-slot:table-busy>
        <div class="text-center">
          <strong>Lista in Caricamento...</strong>
        </div>
      </template>
      <template v-slot:cell(Azioni)="data">
        <b-button
          :id="namingIdIcon(x, data.index + 1)"
          :disabled="clicked"
          variant="outline-primary"
          size="sm"
          :key="x"
          :title="mappingTooltip(x)"
          v-for="x in data.value"
          @click="doAction(x, data)"
        >
          <fa-icon
            v-if="x === 'edit_in_list_progetti'"
            :icon="['far', 'edit']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'view'"
            :icon="['far', 'eye']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'claim'"
            :icon="['far', 'hand-paper']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'unclaim'"
            :icon="['fas', 'times-circle']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'edit_istruttoria'"
            :icon="['fas', 'clipboard-list']"
            class="selector-icons"
          />
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js";
import endpoints from "@/components/endpoints.vue";

export default {
  name: "listaProgetti",
  components: {
  },
  data() {
    return {
      clicked: false,
      actions: ["view", "claim", "unclaim", "edit_in_list_progetti", "edit_istruttoria"],
      sortBy: "dataInserimento",
      filter: null,
      isBusy: false,
      currentRows: [],
      filterIstrButtonText: 'Mostra progetti in istruttoria',
      isFilterIstruttoria: false,
      tabledata: {
        header: [
          {
            key: "codiceProgetto",
            sortable: true,
          },
          {
            key: "modalitaDiAccesso",
            label: "Modalità",
            sortable: true,
          },
          {
            key: "titoloProgetto",
            label: "Titolo",
            sortable: true,
          },
          {
            key: "dataInserimento",
            sortable: true,
            filterByFormatted: true,
            formatter: this.dateTimeFormatter,
            label: "Data Inserimento",
          },
          {
            key:"dataFineProgetto",
            label:"Data Fine Progetto",
            filterByFormatted: true,
            formatter: this.dateFormatter,
            sortable: true,
          },
          {
            key: "strumentoFinanziario",
            sortable: true,
          },
          {
            key: "stato",
            sortable: true,
          },
          {
            key: "Azioni",
            thClass: "thst",
          },
        ],
        rows: [],
      },
      listaProgetti: []
    };
  },
   computed: {
    ...mapGetters({
      getBeneficiari: "announcements/getAllBeneficiariMap",
    }),
   },
  mounted() {
    this.extractData();
  },
  methods: {
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },
    dateTimeFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    beneficiarioFormatter(idBeneficiario) {
      if (!idBeneficiario) 
        return "Sconosciuto";
      return utils.formatBeneficiari(idBeneficiario);
    },
    filterIstruttoria() {
      this.isFilterIstruttoria = !this.isFilterIstruttoria;
      if(this.isFilterIstruttoria) {
        this.currentRows = this.tabledata.rows.filter(item => item.stato === 'In Istruttoria' 
        || item.stato === 'Finalizzazione Istruttoria' 
        || item.stato === 'Revisione Istruttoria');
        this.filterIstrButtonText = 'Mostra tutti i progetti';
      } else {
        this.currentRows = this.tabledata.rows;
        this.filterIstrButtonText = 'Mostra progetti in istruttoria';
      } 
    },

    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      const idProgetto = data.item.idSchedaProgetto;
      let idIstruttoria = data.item.idIstruttoria != null ? data.item.idIstruttoria : data.item.idSchedaProgetto;
      switch (action) {
        case "edit_in_list_progetti":
        case "view":
          this.$router.push({
            name: "schedaProgetto",
            params: { idProgetto: idProgetto },
          });
          break;
        case "claim":
          this.clicked = true;
          this.$store
            .dispatch("progetto/claimSchedaProgetto", {
              idProgetto: idProgetto,
            })
            .then(
              (resp) => {
                const indexProgettoRow = this.getProgettoIndex(data.item);
                const azioni = this.$projectGetActions(resp);
                const updateActions = azioni.filter((item) => {
                  // console.log(this.actions)
                  return this.actions.indexOf(item) != -1;
                });
                let obj = resp.infoProgetto;
                obj.Azioni = updateActions;
                this.tabledata.rows[indexProgettoRow].Azioni = obj.Azioni;
                this.clicked = false;
              },
              (error) => {
                notify.error(
                  notify.strings.error,
                  notify.strings.errorClaimScheda
                );
                console.error("claim error", error);
                this.clicked = false;
              }
            );
          break;
        case "unclaim":
          this.clicked = true;
          this.$store
            .dispatch("progetto/unclaimSchedaProgetto", {
              idProgetto: idProgetto,
            })
            .then(
              (resp) => {
                const indexProgettoRow = this.getProgettoIndex(data.item);
                const azioni = this.$projectGetActions(resp);
                const updateActions = azioni.filter((item) => {
                  // console.log(this.actions)
                  return this.actions.indexOf(item) != -1;
                });
                let obj = resp.infoProgetto;
                obj.Azioni = updateActions;
                this.tabledata.rows[indexProgettoRow].Azioni = obj.Azioni;
                this.clicked = false;
              },
              (error) => {
                notify.error(
                  notify.strings.error,
                  notify.strings.errorUnclaimScheda
                );
                console.error("unclaim error", error);
                this.clicked = false;
              }
            );
          break;
        case "edit_istruttoria":
          this.$router.push({
            name: "schedaIstruttoria",
            params: { idIstruttoria: idIstruttoria },
          });
          break;
        default:
          console.error("Unknown action!", action);
          break;
      }
    },
    getProgettoIndex(schedaRow) {
      return this.tabledata.rows.map( schedaProgetto => schedaProgetto.idSchedaProgetto).indexOf(schedaRow.idSchedaProgetto);
    },
    extractData: async function() {
      let userIdGroup = this.$getUserGroupId();
      if(!userIdGroup.includes("Beneficiari")){
        this.tabledata.header.splice(3, 0,{key:"beneficiario",
          label: "Beneficiario",
          sortable: true,
          formatter:"beneficiarioFormatter",
          sortByFormatted: true});
      }
      this.isBusy = true;
      const url = endpoints.retrieveAllSchedeProgetto;
      this.$get(url).then((result) => {
        this.listaProgetti = result;
        console.log("Progetti in istruttoria trovati=",result.length);
        this.listaProgetti.forEach(
          (element) => {
            
            //visualizzo i progetti con stati relativi all'istruttoria, processo ProgettoValutazione
            const info = element.infoProgetto;
            if (element.infoProgetto &&
              element.infoProgetto.stato &&
              (element.infoProgetto.stato=='Da prendere in carico'
                || element.infoProgetto.stato=='In Istruttoria'
                || element.infoProgetto.stato=='Finalizzazione Istruttoria'
                || element.infoProgetto.stato=='Revisione Istruttoria'
                || element.infoProgetto.stato=='Redazione Appunto'
                || element.infoProgetto.stato=='Ammesso'
                || element.infoProgetto.stato=='Non Ammesso'
                || element.infoProgetto.stato.includes('Attuazione')
                || element.infoProgetto.stato.includes('Concluso')
                )){

                  //setto le azioni
                  //const info = element.infoProgetto;
                  const azioni = this.$projectGetActions(element);
                  let actions = ["view", "claim", "unclaim", "edit_in_list_progetti"]
                  if(info.idIstruttoria){
                    // console.log("Il progetto ", info.idProgetto, " ha una istruttoria: ", element.infoProgetto.idIstruttoria)
                    actions.push("edit_istruttoria")
                  }
                  //Filtro le azioni disponibili
                  const localActions = azioni.filter((item) => {
                    return actions.indexOf(item) != -1;
                  });
                  let newRow = {
                    codiceProgetto: info.codiceProgetto,
                    modalitaDiAccesso: info.modalitaDiAccesso,
                    titoloProgetto: info.titoloProgetto,
                    dataInserimento: info.dataInserimento,
                    dataFineProgetto: info.dataConclusione,
                    beneficiario: info.idBeneficiario,
                    strumentoFinanziario: info.strumentoFinanziario,
                    stato: info.stato,
                    Azioni: localActions,
                    idSchedaProgetto: info.idProgetto,
                    idIstruttoria: info.idIstruttoria,
                  };
                  if (azioni.length > 0) {
                    this.tabledata.rows.push(newRow);
                  }
                  
            }
            
          },
          (error) => {
            notify.error(
              notify.strings.error,
              notify.strings.internalErrorPleaseReportAction
            );
            throw error;
          }
        );
        this.currentRows = this.tabledata.rows;
        this.isBusy = false;
      },
      (error) => {
        this.isBusy = false;
         notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        console.error("errore caricamento lista",error);
      });
    },
    mappingTooltip(keyMapping) {
      let objMapping = {
        edit_in_list_progetti: "Modifica",
        view: "Visualizza",
        claim: "Presa in carico",
        unclaim: "Rilascio presa in carico",
        edit_istruttoria: "Vai in Scheda Istruttoria"
      };
      let temp = objMapping[keyMapping];
      if (!temp) return "";
      return temp;
    },
    getFilterFields() {
      let filterFields = [];
      for (let item of this.tabledata.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
  },
};
</script>
<style scoped lang="scss"></style>
