// generic function list
import { v4 as uuid } from "uuid";
import appinfo from "@/helpers/info";
import crypto from 'crypto'
const genericFunctions = 
{
    //clonazione personalizzata, più veloce e flessibile
    /*cloneObject(obj){ //TODO: controllare per array ed oggetti stringa
        
        if (obj==null || obj==undefined || Array.isArray(obj) || typeof (obj) !== 'object'){
            console.log("cloneObject: invalid input!");
            return null;
        }

        if (obj instanceof Date){
            var temp = new obj.constructor(); //or new Date(obj);
            console.log("cloneObject: Date() detected!", temp);
        } else {
            var temp = obj.constructor();
        }

        for (var key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                obj['isActiveClone'] = null;
                temp[key] = genericFunctions.cloneObject(obj[key]);
                delete obj['isActiveClone'];
            }
        }
    
        console.log("cloneObject: cloned",temp);
        return temp;
    
    },*/   
    
    //dovrebbe essere il modo più pratico ma lento
    //NB: Attenzione alla conversione di date
    cloneObject(inputObj){
        return JSON.parse(JSON.stringify(inputObj));
    },
/*
    setViewMode(stato)
    {
      switch(stato)
      {
          case 'DA_INSERIRE':
              return false;
          case 'INVIATA':
          case 'APPROVATA':
            return true;
          default:
              return true;
      }
    },
*/
}

function roundFloat2Decs(value) {
  if(value && !isNaN(value))
    return Math.round((value + Number.EPSILON) * 100) / 100;
  
  return value;
}

function isInt(value) {
  if (isNaN(value) || value.toString().indexOf('.') !== -1) {
    return false;
  }
  const x = parseFloat(value);
  return (x | 0) === x;
}

const isEmptyObject = function(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

// Dato un oggetto (per es. scheda) recupera il valore di una property (key) a qualsiasi livello
// NB: la key deve essere una String
const keyFinder = function(object, key) {
  if(object == null) return ''
    if(object[key]) return object[key];
    for(let subkey in object) {
      if(!object[subkey] || typeof object[subkey] !== "object") continue;
      let match = keyFinder(object[subkey], key);
      if(match) return match;
    }
    return null;
  }

const objectHasValue = function(obj) {
    return (
        obj != undefined
        && obj != null
        && !isEmptyObject(obj)
    )
}

const isDate = function(value) {
  return(Object.prototype.toString.call(value) === '[object Date]');
};

const groupBy = function (list, keyGetter) {
  const map = new Map ();
  list.forEach (item => {
    const key = keyGetter (item);
    const collection = map.get (key);
    if (!collection) {
      map.set (key, [item]);
    } else {
      collection.push (item);
    }
  });
  return map;
};

const cloneObject = function(inputObj){
    return JSON.parse(JSON.stringify(inputObj));
}


const genIdMaxLength = appinfo.genIdMaxLength;

/**
 *
 * @param {string} text
 */
const sha256 = function (text) {
  return crypto.createHash("sha256").update(text, "utf8").digest("hex");
};


const regex = /^\d+$/;
/**
 * return a substring of a SHA156 with the specified maxLen
 */
const genId = function (maxLen) {
  let id;
  let len = genIdMaxLength;

  if (maxLen)
    len = maxLen;

  do {
    id = sha256(uuid() + Date.now()).substring(0, len);
  } while (id.startsWith("0") || regex.test(id));

  return id;
};


export default {
    genericFunctions,
    isEmptyObject,
    objectHasValue,
    groupBy,
    cloneObject,
    keyFinder,
    roundFloat2Decs,
    isInt,
    genId,
    isDate
}
