<template>
  <div>
    <b-container>
      <Collapse :name="'Campi obbligatori non valorizzati (' + this.tabledata.rows.length + ')'">
        <b-table-lite
          :id="'progetto_mandatory'"
          thead-class="head"
          :items="this.tabledata.rows"
          :fields="this.tabledata.header" />
      </Collapse>
      <Collapse :name="'Potenziali Modifiche Sostanziali ('+this.modificheSost.rows.length+')'">
        <br/>
          <div v-if="leggiModificheSost()">
            <b-row no-gutters>
              <b-col cols="6">
                <b-form-input
                  v-model="filterModSost"
                  type="search"
                  id="filterModificheSostanziali"
                  placeholder="Digita un filtro per le Modifiche Sostanziali"
                />
              </b-col>
              <b-col cols="2" class="ml-3">
                <b-button
                  id="filterModificheSostanzialiBtn"
                  style="height:100%;"
                  :disabled="!filterModSost"
                  @click="filterModSost = ''"
                  >Cancella</b-button
                >
              </b-col>
            </b-row>
            <b-table
              :id="'progetto_mod_sostanziali'"
              thead-class="head"
              :items="this.modificheSost.rows"
              :fields="this.modificheSost.header"
              :filter="filterModSost"
              show-empty
              emptyFilteredText="Nessun modifica Sostanziale disponibile"
              :filter-included-fields="getFilterFieldsSubstancial()">
            </b-table>  
          </div>
          <div v-else>
            Nessuna potenziale modifica sostanziale rilevata
          </div>           
      </Collapse>
      <Collapse :name="'Modifiche ('+this.modifiche.rows.length+')'">
        <br/>
          <div v-if="leggiModifiche()">
            <b-row no-gutters>
              <b-col cols="6">
                <b-form-input
                  v-model="filterModifiche"
                  type="search"
                  id="filterModifiche"
                  placeholder="Digita un filtro per le Modifiche"
                />
              </b-col>
              <b-col cols="2" class="ml-3">
                <b-button
                  id="filterModificheSostanzialiBtn"
                  style="height:100%;"
                  :disabled="!filterModifiche"
                  @click="filterModifiche = ''"
                  >Cancella</b-button
                >
              </b-col>
            </b-row>
            <b-table
            :id="'progetto_mod_no_sostanziali'"
              thead-class="head"
              :items="this.modifiche.rows"
              :fields="this.modifiche.header"
              :filter="filterModifiche"
              show-empty
              emptyFilteredText="Nessun modifica disponibile"
              :filter-included-fields="getFilterFields()">
            </b-table>
          </div>
          <div v-else>
            Nessuna modifica rilevata
          </div>
      </Collapse>
      <Collapse :name="'Elenco Note (' + allNotes.length + ')'">
        <br />
        <TableNotes :id="'note_progetto'" v-if="allNotes.length > 0" :notes="allNotes"> </TableNotes>
        <h4 v-else>
          Nessuna nota da mostrare
        </h4>
      </Collapse>
      <Collapse
        v-if="isVisibleReservedNote"
        :name="'Elenco Note Interne (' + fillNoteRiservate.length + ')'">
        <br />
        <div class="note" v-if="blockNote">
          <br>
            <b-form-textarea
              id="textAreaNoteRiservate"
              :disabled="disableButton"
              v-model="noteRiservate"
              placeholder="Inserire qui le note interne"/>
        </div>
        <div class="pulsanti-invio">
          <b-button :id="'cancelNoteRiservate_progetto'"
            v-if="blockNote"
            class="mx-3 btnReservedNote"
            :disabled="disableButton"
            variant="danger"
            @click="resetReservedNote()"
            >Cancella Nota Interna</b-button>
        </div>
        <TableNotes
          :id="'note_riservate_progetto'"
          v-if="fillNoteRiservate.length > 0" :notes="fillNoteRiservate">
        </TableNotes>
        <h4 v-else>
          Nessuna nota da mostrare
        </h4>
      </Collapse>
      <Collapse :name= numeroAllegati v-if="loadComplete"> 
          <fa-icon v-if="this.loading" :icon="['fas', 'sync']" spin/>
          <FileUpload v-if="!this.loading"
          :idTablePdf="'invio_fileUpload'"
          :rid="this.$route.params.idProgetto"
          :ownerProcessStatus="this.schedaComplete.taskInfo.taskDefinitionKey"
          :actions= actionsArray
          :entity="this.entity"
          :availableFiles = this.availableFiles
          :documentTypes = this.availableDocumentTypes
          :riferimento = this.riferimento
          name= "files"
          @doRefresh="getFilesPerRid($route.params.idProgetto)"/>
      </Collapse>
      <br/>
      <b-card v-if="showAskSostanziali">
        <b-row>
          <b-col cols="3" />
          <b-col>Sono presenti modifiche sostanziali:</b-col>
          <b-col>
            <b-radio-group
              size="lg"
              :disabled="isAskSostanzialiDisabled || disableButton"
              @input="updateSostanziali()"
              v-model="isModificheSostanziali">
              <b-form-radio value="true">Sì</b-form-radio>
              <b-form-radio value="false">No</b-form-radio>
            </b-radio-group>
          </b-col>
          <b-col cols="2" />
        </b-row>
      </b-card>
      <!-- questo messaggio viene visualizzato solo se un progetto in attuazione entra in rimodulazione-->
      <b-row>
        <div v-if="isARwarning" class="warning-text">
          <strong>ATTENZIONE: la presenza di modifiche sostanziali comporta il vaglio e l'approvazione dell'Autorità Responsabile</strong>
        </div>    
      <br/>
      </b-row>
      <b-row>
        <div v-if="this.allegatiMancanti.length > 0">
          <ul>
            <span :class=" missingFileBlockButton===true ? 'text-danger' : 'text-warning' ">Attenzione: Mancano ancora allegati </span>
            <li v-for="missingFiles in this.allegatiMancanti" :key="missingFiles.type" style="list-style-type: circle;">
              <span class="text-center"> {{ missingFiles.type }}</span>
            </li> 
          </ul>
        </div>
      </b-row>
      <b-alert :show="showAlertTravelCosts" variant="warning">
        La somma degli importi della sezione “Costi di Viaggio” supera il 5% del totale del budget di Progetto.
      </b-alert>
      <b-alert :show="showAlertMaintenanceCosts" variant="warning">
        La somma degli importi della sezione “Costi di Manutenzione” supera l’8% del totale del budget di Progetto.
      </b-alert>
      <b-row class="mt-2" v-if="blockNote">
        <b-col />
        <b-col class="text-right">
          <label for="textarea-note">Nota:</label>
        </b-col>
        <b-col sm="8">
          <b-form-textarea
            v-model="note"
            id="textarea-note"
            size="lg"
            placeholder="Inserire qui le note"
            :disabled="disableButton"/>
        </b-col>
        <b-col sm="2">
          <b-button :id="'cancelNote_progetto'"
                    variant="outline-secondary"
                    @click="cancelNote()"
                    :disabled="disableButton">
                    Cancella Nota
          </b-button>
        </b-col>
        <b-col />
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            :id="'export_pdf'"
            size="lg"
            variant="secondary"
            class="mx-2"
            :disabled="disableButton"
            @click="preparePdf">
            Export PDF
          </b-button>
          <b-button
            v-if="actionsArray.indexOf('save') !== -1"
            :id="'save_progetto'"
            size="lg"
            variant="primary"
            class="mx-2"
            :disabled="disableButton"
            @click="saveSchedaProgetto('draft')">
            Salva in Bozza
          </b-button>
          <template>
            <b-button
              v-if="actionsArray.indexOf('send') !== -1"
              :id="'send_progetto'"
              size="lg"
              variant="success"
              class="mx-2"
              :disabled="disableButton || missingFileBlockButton || disableBtnSend"
              @click="openCompletaProgetto('complete')">
              Salva e Invia
            </b-button>
          </template>
          <b-button
            v-if="actionsArray.indexOf('reject') !== -1"
            :id="'reject_progetto'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="disableButton"
            @click="openCompletaProgetto('reject')">
            Rifiuta
          </b-button>
          <b-button
            v-if="actionsArray.indexOf('deleteProject') !== -1"
            :id="'deleteProject_progetto'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="disableButton"
            @click="openCompletaProgetto('deleteProject')">
            Elimina Progetto
          </b-button>
          <b-button
            v-if="actionsArray.indexOf('cancelEdit') !== -1"
            :id="'cancelEdit_progetto'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="disableButton"
            @click="openCompletaProgetto('cancelEdit')">
            Interrompi Modifica
          </b-button>
          <b-button
            v-if="actionsArray.indexOf('denyEdit') !== -1"
            :id="'denyEdit_progetto'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="disableButton"
            @click="openCompletaProgetto('denyEdit')">
            Rifiuta e termina modifica
          </b-button>
          <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="executeAction(setModal)">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
        </b-col>
      </b-row>
    </b-container>
    <ModalConfirmation
      :hidden="!showModal"
      :questionText="modalMessage" 
      @confirm="executeAction(redButtonAction)"
      @close="showModal = false" />
    <b-modal id="bandoScadutoModal" 
      centered size="lg"
      ok-only ok-title="Chiudi" ok-variant="danger">
      <template v-slot:modal-header><h3>Attenzione!</h3></template>
      <h4 class="mb-4">I termini per la presentazione delle proposte progettuali sono scaduti.</h4>
      <h4 class="mb-4">Pertanto l'invio della scheda progetto non è più possibile.</h4>
    </b-modal>
    
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import tool from '@/helpers/tools.js'
import ModalConfirmation from "@/components/modalConfirmation.vue";
import Collapse from "@/components/collapse.vue";
import FileUpload from '@/components/FileUpload.vue'
import TableNotes from "../bandi/tableNotes.vue";
import notify from "@/helpers/notifications.js";
import endpoints from '@/components/endpoints.vue'
import { JSONPath as jpath } from 'jsonpath-plus';
import calculation from '@/helpers/calculations.js';
import utils from '@/helpers/utils.js'
import mapUtils from '@/helpers/mapUtils.js'
import helper_pdf from "@/helpers/exportPdf.js"
import visibility from '@/helpers/visibility.js';

export default {
  name: "invio",
  props: {
    tabName: String,
    bando: Object,
    idSchemaCronoprog: String,
    idsToCheck: { type: Object, default: () =>  {} },
    //informazioni di riferimento per tutti i file caricati
    riferimento: {type: Object, required: true }

  },
  watch: {
    tabName: function() {
      if(this.tabName === this.name) {
        this.refreshMandatoryFields();
        this.getFilesPerRid(this.$route.params.idProgetto);
        if(!this.$disableEditAddColors(this.schedaProgetto)){ //se la colorazione è abilitata, conto anche le modifiche
            this.elaboraDiff();
            this.elaboraDiffCronoprog();
        }
     }
    }
  },
  data() {
    return {
      name: "invio",
      noteRiservate: "",
      loadComplete: false,
      isModificheSostanziali: 'false',
      filterModSost: null,
      filterModifiche: null,
      messages: [
        "Vuoi eliminare questo progetto?",
        "Vuoi interrompere la modifica di questo progetto?",
        "Vuoi rifiutare e interrompere la modifica di questo progetto?",
      ],
      modalMessage: '',
      redButtonAction: '',
      entity: "progetti",
      schedaProgetto: {},
      schedaComplete: {},
      richiestoParereICT: "",
      richiestoParereEdile: "",
      richiestoParereDeroga: "",
      parereReferente: "",
      tabellone:{},
      allNotes: [],
      allegatiDisponibili: 0,
      allegatiMancanti: [],
      allegatiObbligatoriMancanti: [],
      availableFiles: [],
      availableDocumentTypes: [],
      note:"",

    modal1Map:{
            complete:{
                title:"Conferma Invio", text:"Conferma di voler Inviare il Progetto?"
            },
            deleteProject:{
                title:"Elimina Progetto", text:"Conferma di voler Eliminare il Progetto?"
            },
            reject:{
                title:"Rifiuta Progetto", text:"Conferma di voler Rifiutare il Progetto?"
            },
            cancelEdit:{
                title:"Interrompere Modifica", text:"Conferma di voler Interrompere la Modifica?"
            },
            denyEdit:{
                title:"Rifiuta Progetto", text:"Conferma di voler Rifiutare il Progetto e Terminare la Modifica?"
            },
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",

      showAlertTravelCosts:false,
      showAlertMaintenanceCosts:false,
      showModal: false,
      loading: true,
       tabledata: {
        header: [
          {
            key: "Tab",
          },
          {
            key: "Campo",
          },
        ],
        rows: [],
      },
      disableButton: false,
      actionsArray: [],
      modificheSost: {
        header: ["Tab", "Campo Modificato", "Attuale" , "Precedente" , "Note"],
        rows: []
      },
      modifiche: {
        header: ["Tab", "Campo Modificato", "Attuale" , "Precedente" , "Note"],
        rows: []
      }
    };
  },
  components: {
    Collapse,
    TableNotes,
    ModalConfirmation,
    FileUpload,
  },
  mounted() {
    this.schedaProgetto = this.getSchedaProgetto({
      idSchedaProgetto: this.$route.params.idProgetto,
    });
    this.schedaComplete = this.getSchedaComplete({
      idSchedaProgetto: this.$route.params.idProgetto,
    })
    this.tabellone = this.getTabelloneProgetto('progetto');
    if(this.schedaProgetto.note || this.schedaProgetto.notaTemporanea) {
      this.allNotes = this.schedaProgetto.note && this.schedaProgetto.note.length > 0 ? this.schedaProgetto.note : [];
      this.note = this.schedaProgetto.notaTemporanea
    }
    this.fillNoteRiservateInBozza();

    const sostanziali = this.schedaProgetto.possibiliModificheSostanziali;
    if(sostanziali && sostanziali === true )
        this.isModificheSostanziali = 'true';
      else
        this.isModificheSostanziali = 'false';

    this.actionsArray = this.$projectGetActions(this.schedaComplete);
    // console.log(this.actionsArray)
    this.refreshMandatoryFields();
    this.refreshAttachmentsInfo();
  // },
  // mounted() {
    //console.log("passed idSchemaCronoprog: ", this.idSchemaCronoprog);
    this.getFilesPerRid(this.$route.params.idProgetto);
    if(!this.$disableEditAddColors(this.schedaProgetto)){ //se la colorazione è abilitata, conto anche le modifiche
        this.elaboraDiff();
        this.elaboraDiffCronoprog();
    }
    this.loadComplete = true;
  },
  computed: {
    ...mapGetters({
      getTabelloneProgetto: "configuration/getTabellone",
      getSchedaProgetto: "progetto/getSchedaProgetto",
      getSchedaComplete: "progetto/getScheda",
      getSchedaCronoprog: 'cronoprog/getSchedaComplete',
      getTabelloneCronoprogFull: 'configuration/getTabelloneFull'
    }),
    isVisibleReservedNote() {
      const userInfo = this.$getUserInfo();
      const group = userInfo.groups[0];
      return group.includes("MINT") && this.schedaComplete.schedaNoteRiservate;
    },
    isAskSostanzialiDisabled() {
      return this.actionsArray.indexOf("askSostanziali") === -1;
    },
    disableBtnSend() {
      let campiObbligatori = this.tabledata.rows.length
      return campiObbligatori > 0;
      // WARNING decommentare solo per debug
      // return false;
    },
    missingFileBlockButton(){
      let schedaCurrent = this.getSchedaComplete({
        idSchedaProgetto: this.$route.params.idProgetto,
      })
      let allegatiObbligatori = []
      let entities = Object.entries(schedaCurrent.infoAllegati.allegatiMancanti)

      entities.forEach(value => {
        value[1].forEach(element =>{
                  if (element.mandatory===true){
                    allegatiObbligatori.push(element)
                  }
        })
      });
      
      return allegatiObbligatori.length > 0

    },
    numeroAllegati() {
      return "Allegati (" + this.allegatiDisponibili + ")";
    },
    blockNote() {
      return this.actionsArray.indexOf("save") !== -1;
    },
    showAskSostanziali() {
      return this.actionsArray.indexOf("showSostanziali") !== -1;
    },
    isARwarning(){
      return this.$enableARwarnigMessage(this.schedaComplete.schedaProgetto, this.modificheSost.rows.length);
    },
    fillNoteRiservate: function() {
      let schedaCurrent = this.schedaComplete;
      if(!schedaCurrent || !schedaCurrent.schedaNoteRiservate)
          return [];
      return schedaCurrent.schedaNoteRiservate?.content?.noteRiservate || [];
    }
  },
  methods: {
    preparePdf() {
      let scheda = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto})
      let contentScheda = scheda.schedaProgetto
      let typeScheda = 'Progetto'
      let optPdf = helper_pdf.handleNamingPdf(contentScheda, typeScheda)
      this.$emit('buildPdf', optPdf)
    },
    fillNoteRiservateInBozza(){
        this.noteRiservate = this.schedaProgetto.notaTemporaneaRiservata;
    },
    resetReservedNote() {
      this.noteRiservate = ""
    },
    visibilityAlert(schedaProgetto, costoProgetto) {
      // Check: costiViaggio > 5% CostoProgetto --> 1° ALERT
      let idCostiViaggio = "['progetto']['cronoProgramma'][*]['costiViaggio'][*]"
      const totaleCostsTravel = calculation.totaleInRowCosts(schedaProgetto, idCostiViaggio)
      let fivePercentCost = parseFloat(costoProgetto) * 5 / 100
      this.showAlertTravelCosts = parseFloat(totaleCostsTravel) > parseFloat(fivePercentCost)

      let strumentoFinanziario = tool.keyFinder(schedaProgetto.progetto, 'strumentoFinanziario')
      // Check: costiManutenzione > 8% CostoProgetto --> 2° ALERT
      if(strumentoFinanziario && strumentoFinanziario === 'ISF1') {
        let idCostiManutenzione = "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]"
        const totaleCostsMaintenance = calculation.totaleInRowCosts(schedaProgetto, idCostiManutenzione)
        let eightPercentCost = parseFloat(costoProgetto) * 8 / 100
        this.showAlertMaintenanceCosts = parseFloat(totaleCostsMaintenance) > parseFloat(eightPercentCost)
      }
    },
    //Metodi per Allegati
      getFilesPerRid(rid) {
        if(!rid)
        {
          console.error('getFilesPerRid no rid', rid);
          return;
        }
        this.loading = true
        let uri = endpoints.postFilesBasePath + rid
        this.$get(uri)
            .then(
                resp =>  {
                  let temp = resp.filter( item => item.documentType !== 'Organigramma del beneficiario' )
                  //this.availableFiles = resp
                  this.availableFiles = this.$availableFilesFilter(temp, this.$getUserInfo(), this.schedaComplete.infoAllegati[this.entity])
                  this.currentMandatoryFiles();
                  this.refreshAttachmentsInfo()
                  this.loading = false
                }
            ).catch(error => {
                console.error("Error getFilesPerRid: ", error);
                notify.error(
                  notify.strings.error,
                  notify.strings.getFilesPerRid
                )
                this.loading = false
            });
      },
      currentMandatoryFiles(){
        let schedaCurrent = this.schedaComplete;
        let clonedScheda = tool.genericFunctions.cloneObject(schedaCurrent);
        let allegatiMandatory = this.$calculateMandatoryFiles(this.entity, this.$getUserInfo().roles[0], clonedScheda, this.availableFiles);

        this.$updateMandatoryFilesInStore(allegatiMandatory, this.entity, this.$route.params.idProgetto)
        this.$updateAvailableLengthInStore(this.availableFiles.length, this.entity, this.$route.params.idProgetto);
        this.availableDocumentTypes = this.$availableDocumentTypes(clonedScheda.infoAllegati[this.entity], this.schedaComplete.taskInfo.taskDefinitionKey)

      },
    refreshAttachmentsInfo() {
      const schedaCurrent = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto})
      this.allegatiDisponibili = schedaCurrent.infoAllegati.allegatiDisponibili[this.entity];

      let temp = []
      let entities = Object.entries(schedaCurrent.infoAllegati.allegatiMancanti)

      entities.forEach(value => {
        value[1].forEach(element =>{
                    temp.push(element)
        })
      });

      let clonedLista = tool.genericFunctions.cloneObject(temp);
      // aggiunge la dicitura di obbligatorietà
      const descrizione = ' (Obbligatorio)'
      clonedLista.forEach(element => {
        if (element.mandatory===true)
          element.type = element.type + descrizione
      });
      this.allegatiMancanti = clonedLista;
      this.allegatiObbligatoriMancanti = this.allegatiMancanti.filter(doc => {return doc.mandatory === true;});
    },
    refreshMandatoryFields() {
      this.valoriMancanti();
      this.checkExistFasi();
    },
    existAtLeastOneValidActivity(scheda) {
      const idAttivita = "['progetto']['cronoProgramma'][*]['idAttivita']";
      return this.$getUndeletedValues(idAttivita, scheda);
    },
    checkFaseMissingInTable(fase, numeroAttivita) {
      let numb_activity = parseInt(numeroAttivita.charAt(0)) + 1
      let exist = false
      this.tabledata.rows.forEach(row => {
        if(row.Campo === fase) {
          exist = true
        }
      })
      if(!exist) {
        this.tabledata.rows.push({
          Campo: 'Fase_' + fase + '_Attività_' + numb_activity,
          Tab: 'Cronoprogramma'
        })
      }
    },
    checkExistFasi() {
      let scheda = this.getSchedaProgetto({ idSchedaProgetto: this.$route.params.idProgetto });
      let fasiMancantiIniziali = [
        { key: "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']", label: "Pianificazione"},
        { key: "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']", label: "Attuazione"},
        { key: "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']", label: "Chiusura"}
      ]
      
      const validActivities = this.existAtLeastOneValidActivity(scheda);

      if(validActivities.length === 0) {
        fasiMancantiIniziali.forEach(el => {
          this.tabledata.rows.push({
            Campo: el.label,
            Tab: this.tabellone[el.key].config.tab
          })
        })
      } else {
        // Se ho almeno un attività valida, devo controllare se ho tutte le fasi (Pianificazione, Attuazione, Chiusura)
        let key = "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']"
        let valueMatch = jpath({resultType: 'all'}, '$' + key, scheda);
        let conf = {};
        if(scheda.dataEntryConfiguration) {
          conf = scheda.dataEntryConfiguration;
        }

        if(valueMatch.length > 0) {
          valueMatch.forEach(element => {
            let idMatch = element.path.substr(1).replace('fase', 'descrizione')
            let split_path = element.path.split("['cronoProgramma'][")
            let valid = true
            let path = "['idAttivita']"
            let split = element.path.substr(1).split("['dettaglioFase']")
            let pathActInConf = split[0] + path
            if(!conf[idMatch] && conf[pathActInConf] && conf[pathActInConf].data.deleted) {
              valid = false
            }
            
            if(conf[idMatch] && conf[idMatch].data.deleted) {
              valid = false
            }

            if(valid && element.parent.descrizione === "") {
              this.checkFaseMissingInTable(element.value, split_path[1])
            }
          })
        }
      }
      return this.tabledata
    },
    valoriMancanti() {

      this.tabledata.rows = [];
      const idProgetto = this.$route.params.idProgetto
      let scheda = this.getSchedaProgetto({ idSchedaProgetto: idProgetto });
      
        // prendo gli id mandatory true
      let arrayIdMandatory = [];
      for (const key in this.tabellone) {
        if(this.tabellone[key] && this.tabellone[key].config.mandatory != undefined && this.tabellone[key].config.mandatory !== false) {
          if(!key.match('[*]')) {
            arrayIdMandatory.push(key)
          }
        }
      }
      arrayIdMandatory.forEach(id => {
         let value = jpath('$'+id, scheda);
         if(value.length === 0 || value[0] == null || value[0] === "") {
           if(this.tabellone[id].config.label != undefined || this.tabellone[id].config.label.length != undefined) {
              if(this.tabellone[id].config.tab && this.tabellone[id].config.label) {
                this.tabledata.rows.push({
                  Campo:this.tabellone[id].config.label,
                  Tab: this.tabellone[id].config.tab
                })
              }
            }
          }
      });
      // Per ogni obiettivo: la somma di ogni singolo budget
      // deve essere uguale al Totale ammissibile per quel costo
      this.checkBudgetObiettivi(scheda)

      // Controllo che per ogni obiettivo specifico (distinto)
      // ci sia almeno un indicatore NON cancellato in scheda
      this.checkOneIndicatorForEachObSpecifico(scheda)

      //Controlli i campi obbligatori di cronoprog
      this.getMandatoryCronoProgMissed(scheda);
    },
    checkIvaLowerCostoProgetto(costoProgetto) {
      let idIvaScheda = "['progetto']['costoProgettoIVA']"
      let ivaScheda = this.trovaValoreAttuale(idIvaScheda)
      if(costoProgetto != null && ivaScheda != null){
        let costoProgetto1 = parseFloat(costoProgetto);
        let ivaScheda1 = parseFloat(ivaScheda);
        if(!isNaN(costoProgetto1) && !isNaN(ivaScheda1) && ivaScheda1 > costoProgetto1){
          this.tabledata.rows.push({
            Campo: "L'IVA deve essere minore del costo di Progetto",
            Tab: 'Progetto'
          })
        } 
      }
    },
    /* UNUSED
    // 26-08-21: eliminato controllo sul budget massimo
    checkCostoProgettoLowerBudgetMax(scheda, costoProgetto) {
      let budgetMax = tool.keyFinder(scheda, 'budgetMassimo');
      if(!budgetMax) {
        console.log("Non è stato definito il Budget Massimo del Progetto! Non posso applicare nessun controllo sul Budget");
        return;
      }
      
      let costoLowerBudget = false
      if(budgetMax != null && costoProgetto != null) {
        costoLowerBudget = parseFloat(costoProgetto) <= parseFloat(budgetMax) ? true : false
      }
      if(!costoLowerBudget) {
        this.tabledata.rows.push({
          Campo: "Il costo di Progetto deve essere inferiore al Budget Massimo",
          Tab: 'Progetto'
        })
      }
      // Gestione visibilità alert
      this.visibilityAlert(scheda, costoProgetto)
    },
    */

    checkBudgetObiettivi(scheda) {
      // Questi rappresentano i valori in scheda riassuntati in Tab:Budget , Sezione: 'Budget per obiettivi' 
      let costiBudgetPerObiettivi = [
        { key: "['progetto']['cronoProgramma'][*]['costiPersonale'][*]", tipoSpesa: "Personale", label: "Costi del personale Non coerenti"},
        { key: "['progetto']['cronoProgramma'][*]['costiViaggio'][*]", tipoSpesa: "Viaggi", label: "Costi di viaggio Non coerenti"},
        { key: "['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]", tipoSpesa: "Servizi", label: "Costi Servizi forniture e lavori Non coerenti"},
        { key: "['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]", tipoSpesa: "Spese di gara", label: "Costi Spese di gara Non coerenti"},
        { key: "['progetto']['cronoProgramma'][*]['costiInformazione'][*]", tipoSpesa: "Informazione e pubblicita'", label: "Costi Informazione e pubblicità Non coerenti"},
        { key: "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]", tipoSpesa: "Manutenzione", label: "Costi di manutenzione Non coerenti"},
        { key: "['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]", tipoSpesa: "Supporto tecnico", label: "Costi Supporto tecnico Non coerente"},
        { key: "['costiIndiretti'][*]", tipoSpesa: "Costi indiretti", label: "Costi indiretti Non coerenti"}
      ]
      // Il costo del Progetto è la somma delle singoli voci di costo
      // let costoProgetto = 0
      costiBudgetPerObiettivi.forEach(el => {
        let idCostoInScheda = el.key
        let tipoSpesa = el.tipoSpesa 
        let sommaSingoliBudget = 0
        let idBudgetPerObiettivi = "['budgetPerObiettivi'][*]['costi'][*]"
        let allBudgets = jpath('$' + idBudgetPerObiettivi, scheda);
        allBudgets.forEach(budget => {
          if(tipoSpesa && tipoSpesa === budget.tipoSpesa) {
            const importo = parseFloat(budget.importo);
            if(!isNaN(importo))
              sommaSingoliBudget = sommaSingoliBudget + importo;
          }
        })
        
        // Corrisponde al 'Totale ammisibile' di ogni riga in Tab:Budget , Sezione: 'Budget per obiettivi'
        const totaleInRow = calculation.totaleInRowCosts(scheda, idCostoInScheda);
        let budgetNONCoerente = (Math.fround(sommaSingoliBudget) !== Math.fround(totaleInRow));
        if(budgetNONCoerente) {
          this.tabledata.rows.push({
            Campo: 'Budget per obiettivi: ' + el.label,
            Tab: this.tabellone[idCostoInScheda].config.tab
          })
        }
      })

      // Il costo del Progetto è un campo editabile presente in scheda
      const id_costo_progetto = "['progetto']['costoProgetto']"
      const value_costo_progetto = this.trovaValoreAttuale(id_costo_progetto)
      const costoProgetto = value_costo_progetto ? value_costo_progetto : 0


      // Controllo che CostoProgetto <= BudgetMassimo
      // 26-08-21: eliminato controllo sul budget massimo
      // this.checkCostoProgettoLowerBudgetMax(scheda, costoProgetto)

      // Controllo che IVA < CostoProgetto
      this.checkIvaLowerCostoProgetto(costoProgetto)
    },
    checkOneIndicatorForEachObSpecifico(scheda) {
      let idOb = "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['obiettivoSpecifico']['codice']"
      let allObSpecifici = jpath('$' + idOb, scheda);
      // Rimuovo gli eventuali duplicati tra tutti gli obiettivi specifici
      let removeDuplicates = (allObSpecifici) => allObSpecifici.filter((v,i) => allObSpecifici.indexOf(v) === i)
      let distinctObiettivi = removeDuplicates(allObSpecifici)

      // Setto l'id per il recupero degli Indicatori in scheda
      let idIndicatori = "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']"
      let indicatoriEffettivi = []

      const validValues = this.$getUndeletedValues(idIndicatori, scheda);
      for(const result of validValues) {
        let indicatore = result.value;
        let splitIndicatore = indicatore.split('.')
        let obSpecificoEffettivo = splitIndicatore[0]
        if(indicatoriEffettivi.indexOf(obSpecificoEffettivo) === -1) {
          indicatoriEffettivi.push(obSpecificoEffettivo)
        }
      }

      let difference = distinctObiettivi.filter(x => indicatoriEffettivi.indexOf(x) === -1);
      difference.forEach(el => {
        this.tabledata.rows.push({
            Campo: "Inserire Indicatore per Obiettivo Specifico: " + el,
            Tab: 'Attività di progetto'
          })
      })
    },
    getMandatoryCronoProgMissed(scheda){
      const idProgetto = scheda.progetto.idProgetto
      let cronoprogContent = this.getCronoprog(idProgetto).content;
      let tabCronoprog = this.getTabelloneCronoprogFull(this.idSchemaCronoprog).tabellone;
      
      let res =  mapUtils.getMandatoryFieldsCronoprog(cronoprogContent, this.idsToCheck, tabCronoprog);
      res.forEach( mandatory => {
        
        this.tabledata.rows.push({
          Campo:mandatory.content["Nome Campo"],
          Tab: mandatory.content.Tab
        })
      })
      
    },
    getCronoprog(idScheda) {
        return this.getSchedaCronoprog({idScheda: idScheda}).scheda
    },
    cancelNote(){
      this.note = " ";
    },

    actionModal(){
         switch (this.setModal) {
                    case "complete":
                        return this.saveSchedaProgetto('complete');
                    case "reject":
                       return this.saveSchedaProgetto('reject');
                    case "delete":
                       return this.approve();
                    case "cancelEdit":
                       return this.approve();
                    case "denyEdit":
                       return this.approve();
                    
                    default:
                        console.log("Case false");
                    }
    },

    openModalDeleteAction(action) {
      switch(action) {
        case 'deleteProject':
          this.modalMessage = this.messages[0];
          break;
        case 'cancelEdit':
          this.modalMessage = this.messages[1];
          break;
        case 'denyEdit':
          this.modalMessage = this.messages[2];
          break;
        default:
          console.log("invio progetti: unmanaged action", action);
          break;
      }
      this.redButtonAction = action;
      this.showModal = true;
    },
    async executeAction(action) {
      this.disableButton = true;
      this.showModal = false;
      let actionToDo;

      switch(action) {
         case "complete":
                this.saveSchedaProgetto('complete');
                return
         case "reject":
                  this.saveSchedaProgetto('reject');  
                  return              
        case 'deleteProject':
          actionToDo = 'deleteProgetto';
          break;
        case 'cancelEdit':
          actionToDo = 'cancelEdit'; //Interrompi Modifica

          /* UNUSED. WARNING: il cronoprog rimane su un branch morto
          cronoprog = tool.genericFunctions.cloneObject(this.getCronoprog(this.$route.params.idProgetto))
          try{
            cronoprog = mapUtils.clearRimodulatedCronoprog(
              cronoprog, 
              ['attivita','procedure'],
              'delete'
            )
            
            cronoprog = await this.saveCronoprogOnly(cronoprog)
            console.info("Cleared cronoprog: ", cronoprog)
          } catch(error){
            console.error(error.message)
            this.disableButton = false;
            return
          }
          */
          break;
        case 'denyEdit':
          actionToDo = 'denyEdit';
          this.saveSchedaProgetto(actionToDo); //Rifiuta e Termina modifica
          return;
        default:
          console.log("invio progetti: unmanaged action", action);
          this.disableButton = false;
          return;
      }
      
      this.$store.dispatch("progetto/"+ actionToDo, {
        idProgetto: this.$route.params.idProgetto,
      }).then(
        () => {
          notify.success(
            notify.strings.success,
            notify.strings[actionToDo + 'ProgettoOk']
          );
          this.disableButton = false;
          this.$router.push({ name: "secure" });
        }).catch(
        error => {
          this.disableButton = false;
          console.log(actionToDo, "error: ", error);
          notify.error(
            notify.strings.error,
            notify.strings[actionToDo + 'ProgettoError']
          );
        }
      );
    },
    updateSostanziali() {
      let updatedValue;
      if(this.isModificheSostanziali == true || this.isModificheSostanziali == "true")
        updatedValue = true;
      else
        updatedValue = false;
      this.$store.dispatch("progetto/updateParam", {
          idProgetto: this.$route.params.idProgetto,
          key: 'possibiliModificheSostanziali',
          value: updatedValue
      }).then(
        () => {
                //Aggiorno i file obbligatori
                this.currentMandatoryFiles()
                this.refreshAttachmentsInfo()
        }
      );
    },
     openCompletaProgetto(action){
            this.setModal=action;
            this.$bvModal.show('modalCompletaInvia');

            },

    async saveSchedaProgetto(salvataggio) {
      let schedaProgetto = this.getSchedaProgetto({
        idSchedaProgetto: this.$route.params.idProgetto,
      });

      if((this.bando && (salvataggio === 'draft' || salvataggio === 'complete'))) {
        const isBandoValid = this.$canSaveSendProject(salvataggio, this.bando, schedaProgetto.stato, schedaProgetto.vistoDa);
        if(!isBandoValid) {
          this.$bvModal.show("bandoScadutoModal");
          return;
        }
      }

      this.disableButton = true;
      this.updateSostanziali();
      

      let clonedScheda = tool.genericFunctions.cloneObject(schedaProgetto);
      clonedScheda.notaTemporanea = this.note
      clonedScheda.notaTemporaneaRiservata = this.noteRiservate;
      schedaProgetto = clonedScheda;

      
      //salvataggio del relativo cronoprog
      //POST Body inglobato schedaProgetto + schedaCronoprog
      let entity = { 
        progetto: schedaProgetto,
        cronoprog: null
      }

      //valori di non versionamento del cronoprog
      let myCronoprogKey = null;
      let myIdSchemaCronoprog = null;
      let mySchemaVersioneCronoprog = 0;  //valore numerico perchè è stato definito come int in schedaProgetto.java
      //salvo la scheda cronoprog solo se siamo al primo step dei processi di creazione e modifica di un progetto
      if (schedaProgetto.progetto.idProgetto && this.isFirstStep(this.schedaComplete)){
        let cronoprog = this.getSchedaCronoprog({idScheda: schedaProgetto.progetto.idProgetto}); 
        console.log("salvo schedaCronoprog:", cronoprog);
        entity.cronoprog = cronoprog.scheda;
        //versiono il relativo tabellone solo quando salvo cronoprog
        let tabCronoprog = await this.getTabelloneCronoprogFull(this.idSchemaCronoprog);

        myCronoprogKey = cronoprog.scheda.key;
        myIdSchemaCronoprog = this.idSchemaCronoprog;
        mySchemaVersioneCronoprog = tabCronoprog.version;
        console.log("cronoprogKey: "+myCronoprogKey+", idSchemaCronoprog: ", myIdSchemaCronoprog+", schemaVersioneCronoprog: ", mySchemaVersioneCronoprog);
      }

      //Se la modifica viene interrotta, ripulisco il cronoprog
      /* UNUSED. WARNING: rimodulated non necessario in presenza dei branch
      if(salvataggio === "denyEdit"){
        let  cronoprog = tool.genericFunctions.cloneObject(this.getCronoprog(this.$route.params.idProgetto))
        cronoprog = mapUtils.clearRimodulatedCronoprog(
          cronoprog, 
          ['attivita','procedure'],
          'delete'
        )
        console.info("Cleared cronoprog: ", cronoprog)
        entity.cronoprog = cronoprog;
      }

      if(this.isLastStep(this.schedaComplete)
      && salvataggio === 'complete'){
          let cronoprog = await this.getLastCronoprog(this.$route.params.idProgetto)
          if(!cronoprog.content){
            console.error("Errore in recupero ultima scheda cronoprog")
            console.error("Non posso settare rimodulated=false")
            return
          }
          cronoprog = mapUtils.clearRimodulatedCronoprog(
          cronoprog, 
          ['attivita','procedure'],
          'toggle'
        )
        console.info("Scheda Cronoprog in Attuazione: ", cronoprog)
        entity.cronoprog = cronoprog;
      }*/


      await this.$store
        .dispatch("progetto/saveSchedaProgetto", {
          schedaProgetto: entity,
          salvataggio: salvataggio,
          parereReferenteIct: this.richiestoParereICT === "true",
          parereReferenteEdile: this.richiestoParereEdile === "true",
          parereReferenteDeroga: this.richiestoParereDeroga === "true",
          isModificheSostanziali: this.isModificheSostanziali,
          cronoprogKey: myCronoprogKey,
          idSchemaCronoprog: myIdSchemaCronoprog,
          schemaVersioneCronoprog: mySchemaVersioneCronoprog
        })
        .then(
          () => {
            notify.success(
              notify.strings.success,
              notify.strings.saveProgettoOk
            );
            if (salvataggio === "complete" || salvataggio === "reject" || salvataggio === 'denyEdit') {
              this.$router.push({ name: "secure" });
            }
          }).catch(
            error => {
              if(error.response) {
                  if(error.response.status === "410" || error.response.status === 410){
                    console.log("Avviso bando scaduto!", error.response);
                    this.$bvModal.show("bandoScadutoModal");
                  }
                  else {
                    console.error("save Scheda Progetto Error: ", error.response);
                    notify.error(
                        notify.strings.error,
                        notify.strings.errorSaveProgetto
                    );
                  }
              }
              else {
                console.error("save Scheda Progetto Error: ", error);
                notify.error(
                  notify.strings.error,
                  notify.strings.errorSaveProgetto
                );
              }
            }
        );
      this.disableButton = false;
    },
/*
    async saveCronoprogOnly(cronoprog){
      const url = endpoints.cronoprog(this.$route.params.idProgetto)
      return this.$post(url, cronoprog)
    },
*/
    isFirstStep(schedaProgetto){
      if (schedaProgetto.taskInfo.taskDefinitionKey == "Progetto_compilazione_Beneficiario-Operatore"
        || schedaProgetto.taskInfo.taskDefinitionKey == "Progetto_modifica_Beneficiario-Operatore"){
        console.log("Siamo al primo step del processo di creazione/modifica progetto, taskDefinitionKey: ", schedaProgetto.taskInfo.taskDefinitionKey);
        return true;
      } else {
        return false;
      }
    },
/* UNUSED. WARNING: rimodulated non necessario in presenza dei branch
    isLastStep(schedaProgetto){
      //Ultimo step del processo di modifica, l'ultimo step della sovvenzione di convenzione è gestito nel BE di Progetti
        return schedaProgetto.taskInfo.taskDefinitionKey == "Progetto_rimodulato_UfficioGestione-Operatore";
        // console.log("Siamo all'ultimo step del processo di creazione/modifica progetto, taskDefinitionKey: ", schedaProgetto.taskInfo.taskDefinitionKey);
        //console.log("Non salvo la schedaCronoprog, Siamo in altri step del processo di creazione/modifica progetto: ", schedaProgetto.taskInfo.taskDefinitionKey);
    },
*/
    leggiModificheSost(){
      return this.modificheSost.rows.length;
    }, 

    leggiModifiche(){
      return this.modifiche.rows.length;
    },

    elaboraDiff(){
        let modificheSost = {
          rows: []
        };
        let modifiche = {
          rows: []
        };

        if(!this.schedaComplete.schedaProgetto.dataEntryConfiguration) {
            console.log("Mappa di configurazione (data) non presente, non posso controllare le modifiche");
            this.modifiche.rows = modifiche.rows;
            this.modificheSost.rows = modificheSost.rows;
            return;
        }

        const mappone = tool.genericFunctions.cloneObject(this.schedaComplete.schedaProgetto.dataEntryConfiguration);
        
        if(!this.tabellone){
            console.error("Tabellone (config) non presente, non posso controllare le modifiche");
            this.modifiche.rows = modifiche.rows;
            this.modificheSost.rows = modificheSost.rows;
            return;
        }

        const tabellone = this.tabellone;

        
        //ciclare per tutti gli elementi della mappa configurazione
        // let chiavi = Object.keys(mappone);
        // for (const chiave of chiavi){
        for(const [chiave, item] of Object.entries(mappone)) {
            //con la chiave accedo al tabellone e prendo la parte config
            let chiaveStar = mapUtils.getGenericArrayKey(chiave);
            
            let myConfig = tabellone[chiaveStar];
            // se non c'è la configurazione o il campo è in sola lettura
            // allora presumibilmente non è stato modificato direttamente
            // dall'utente, quindi non lo mostro
            if (!myConfig || (myConfig.config.readonly && !myConfig.config.canBeSubstancial)
              || (myConfig.config.hiddenRule && this.hiddenRuleIsTrue(myConfig, chiave))) {
              continue;
            } else {
              item.config = myConfig.config;
            }

            // BUG #13236: anche i campi deleted hanno edited
            if(!item.data.edited)
              continue;



            let campoModificato = item.config.label;
            if (item.data.editTag) {
                campoModificato = item.data.editTag+item.config.label;
            } 
            // else if(chiave.match(mapUtils.indexRegex)) {
              // tag = mapUtils.createEditTag1(this.tabellone, chiave);
            // }
            //imposto i campi comuni
            // let campoModificato = temp + item.config.label;
            // if(tag)
            //   campoModificato = tag;
            let row = {
                content: {
                    "Tab": item.config.tab,
                    "Campo Modificato": campoModificato,
                    "Note": item.data.note
                }
            }

            if(item.data.isSubstancial){
                //riempire array delle diff sostanziali
                row.content["Attuale"] = utils.formatOutput(item, this.trovaValoreAttuale(chiave));
                row.content["Precedente"] = utils.formatOutput(item, item.data.oldValue);

                modificheSost.rows.push(row.content);

            } else if (item.data.deleted || item.data.added) {

                let valore;
                let valorePrec;

                if(item.data.deleted && item.data.added){
                    continue;
                } else {

                  if (item.data.deleted){
                    valore="(nessuno, cancellato)";
                    valorePrec=utils.formatOutput(item, item.data.oldValue);
                  }

                  if (item.data.added){
                    valorePrec="(nessuno, inserito)";
                    valore=utils.formatOutput(item, this.trovaValoreAttuale(chiave));
                  }
                }

                row.content["Attuale"] = valore;
                row.content["Precedente"] = valorePrec;


                modifiche.rows.push(row.content);
            }
            else {
                //riempire array delle differenze non sostanziali
                row.content["Attuale"] = utils.formatOutput(item, this.trovaValoreAttuale(chiave));
                row.content["Precedente"] = utils.formatOutput(item, item.data.oldValue);

                modifiche.rows.push(row.content);
            } 
        }

        // console.log("trovate "+modificheSost.rows.length+ " modifiche sostanziali");
        this.modificheSost.rows = modificheSost.rows;

        // console.log("trovate "+modifiche.rows.length+" modifiche");
        this.modifiche.rows = modifiche.rows;
    },

    elaboraDiffCronoprog(){
      const idProgetto = this.$route.params.idProgetto
      const schedaCronoprog = this.getCronoprog(idProgetto)
      let result = utils.elaboraDiffCronoprog(schedaCronoprog, this.getTabelloneCronoprogFull('cronoprog_progetto'))
      
      this.modificheSost.rows = [...this.modificheSost.rows, ...result.substancial]
      this.modifiche.rows = [...this.modifiche.rows, ...result.notSubstancial]
    },
    
    hiddenRuleIsTrue(myConfig, chiave){
      if (myConfig && myConfig.config && myConfig.config.hiddenRule){
        //console.log("evaluate hiddenRule", myConfig.config.hiddenRule);
        //dalla chiave di un attributo opzionale ricavo la chiave dell'attributo "type" sempre presente
        let chiaveType = chiave.replace('modalitaDiCalcolo', 'type');
        let jPathResult = jpath({resultType: 'all'}, '$'+chiaveType, this.schedaComplete.schedaProgetto)[0];  //esiste sempre e solo elemento
        let indType = jPathResult.value;  //estraggo lo stringa CUSTOM o STANDARD
        //console.log("Tipo Indicatore:", indType);
        if (visibility.computeHidden(chiave, null, myConfig, indType)){

          return true;
        } 
      } 
      return false;
    }, 

    trovaValoreAttuale(chiave){
        if (!chiave){
          console.error("chiave errata!");
          return null;
        }

        let values = jpath('$'+chiave, this.schedaComplete.schedaProgetto);
        if (!values){
          console.error("nessun valore attuale trovato!");
          return null;
        }
        if (values.length > 1){
            console.error("trovati più valori per la chiave ="+chiave, values);
            return values[1];   //torno l'ultima
        }
        return values[0]; //torno la prima ed unica
    },
    getFilterFieldsSubstancial() {
      let filterFields = [];
      for (let item of this.modificheSost.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
    getFilterFields() {
      let filterFields = [];
      for (let item of this.modifiche.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
    //All'ultimo step del processo di modifica vi è la necessita di settare a false tutti gli item rimodulated
    //Questa modifica deve essere fatta sulla attuale current version di cronoprog
    /* UNUSED. WARNING: rimodulated non necessario in presenza dei branch
    async getLastCronoprog(idProgetto){
      const url = endpoints.cronoprog(idProgetto)
      return this.$get(url)
    }*/
  }
};
</script>
