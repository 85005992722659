<template>
  <div id="scostamenti" >
    <InfoCompiling
        :tabName="infoTabName"
        :title="'Informazioni di compilazione'"
        >
        </InfoCompiling>
    <DynamicTable2 :idTablePdf="idTablePdf" :tdata="tabledata" :doAction="this.doAction" :actions="this.actions"/>
    <modalForm v-if="showEditForm"
               :fdata="this.editFormData"
               :sch="this.scheda"
               :hidden="!showEditForm"
               :buttons="this.buttons"
               :cfg="incomingData.schedaMonitoraggio.dataEntryConfiguration"
               @close="showEditForm = false"
               @salvaRecord="aggiornaRiga">
      <h3 slot="header">{{modalHeader}}</h3>
    </modalForm>
  </div>
</template>

<script>
import DynamicTable2 from "@/components/dynamicTable2.vue";
import modalForm from "@/components/modalForm.vue";
import tool from '@/helpers/tools.js'
import mapUtils from '@/helpers/mapUtils.js'
import { JSONPath as jpath } from 'jsonpath-plus';
import InfoCompiling from "@/components/infoCompiling.vue"

export default {
  name: "scostamenti",
  components: {
    DynamicTable2,
    modalForm,
    InfoCompiling
  },

  props: {
    idTablePdf: { type: String, default: () => '' },
    infoTabName: { type: String, default: () => '' },
    incomingData: Object,
    salvaSchedaToStore: Function,
    viewOnlyMode: Function,
    tabName: String
  },
  watch: {
    tabName: function() {
      if(this.$props.tabName === "statoavanzamento") {
        this.extractData();
      }
    }
  },
  data() {
    return {
      actions: ["edit"],
      tabledata: {
        header: ["Scostamenti sul cronoprogramma"],
        rows: [
          {
            content: {
              "Scostamenti sul cronoprogramma": "Non disponibile"
            }
          }
        ]
      },
      buttons: [
            {
              name: 'Salva',
              action: 'salvaRecord',
              show: true,
              param: "edit"
            }
      ],
      editFormData: {},
      modalHeader: "",
      showEditForm: false,
      colIds: [
        "['scostamentiCronoprogramma']"
      ],
      scheda: {}
    };
  },
  mounted() {
    this.extractData();
  },

  methods: {
    aggiornaRiga(actionFromModal) {
      this.showEditForm = false;
        let clonedSchedaMonitoraggio = mapUtils.aggiornaScheda(actionFromModal,
                                                               tool.genericFunctions.cloneObject(this.incomingData.schedaMonitoraggio));
        this.salvaSchedaToStore(clonedSchedaMonitoraggio);
        this.extractData();
    },
    doAction(actionToDo, index) {
      this.scheda = this.incomingData.schedaMonitoraggio;
        let value;
        let mapElement;
        const mappa = this.incomingData.schedaMonitoraggio.dataEntryConfiguration;
        switch(actionToDo) {
          case 'edit':
            this.editFormData = {content:{}, conf: {}};
            
            // let content = 
            this.colIds.forEach(columnId => {
              let id = columnId.replace('*', index);
              let values = jpath('$'+id, this.incomingData.schedaMonitoraggio);
              if(values.length === 0) {
                console.error('valore non trovato in scheda: ', id);
                return;
              }
              let ele = mapUtils.getMapElement(mappa, id);
              mapElement = tool.genericFunctions.cloneObject(ele);
              mapElement.config.path = mapElement.config.path.replace('*', index);
              value = {'value': values[0], 'id': id};
              this.editFormData.conf[id] = mapElement;
              this.editFormData.content[id] = tool.genericFunctions.cloneObject(value);
            });
          
            this.modalHeader = "Modifica Scostamento";
            this.buttons[0].show=true;
            this.showEditForm = true;
            this.numeroRiga = index; //memorizzo il numero di riga in editing per poter salvare il dato
            break;
        }
    },

    extractData() {
      if(!this.incomingData){
            console.log("scostamenti: Invalid inputData!?");
            return;
      }
      let inputData = this.incomingData.schedaMonitoraggio;
      this.viewOnlyMode(this.incomingData, this.actions);
      const mappa = inputData.dataEntryConfiguration;
      this.tabledata = mapUtils.costruisciTabella(this.colIds, mappa, inputData);
    }
  }
};
</script>