<template>
  <div>
    <div class="card">
        <div class="card-body text-right">
          <h1 class="font-weight-bold">Totale Importi: {{ totaleSpeseEuro }}</h1>
        </div>
      </div>
    <GenericList
      :id="'listaProgetti'+dynamicID" 
      :title="'Lista Progetti '+dynamicTitle"
      :busyTable="isBusy"
      :sort-by="sortBy"
      :headerTable="headerTable"
      :dataTable="dataForList"
      :actionsInRow="this.$getActionsProgettiPrefinanziabili"
      :actionsMapping="this.buttonLabels"
      :blockButton="isBusy"
      @clickActionList="doAction"
    >
    </GenericList>
    <ModalConfirmation
        :hidden="!showCompleteModal"
        :contentText="'Vuoi avviare il prefinanziamento per il progetto '+codiceProgetto+' ?'"
        @confirm="creaNuovaCheckList(codiceProgetto, infoProgetto)"
        @close="closeModal()"
    />
  </div>
</template>
<script>

import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue"
import GenericList from "@/components/genericList.vue"
import ModalConfirmation from "@/components/modalConfirmation.vue"

export default {
  name: "listaProgettiPrefinanziabili",
  components: {
    GenericList,
    ModalConfirmation
  },
  data() {
    return {
      totaleSpeseEuro: "€ 0,00",
      totaleSpese1: 0,
      totaleSpese2: 0,
      totaleSpese3: 0,
      sortBy:"codiceProgetto",
      dataForList: [],
      headerTable: [
          {
            key: "codiceProgetto",
            label: "Codice Progetto",
            sortable: true,
          },
          {
            key: "modalitaDiAccesso",
            label: "Modalità",
            sortable: true,
          },
          {
            key: "titoloProgetto",
            label: "Titolo",
            sortable: true,
          },
          {
            key: "dataFirmaConvenzione",
            sortable: true,
            filterByFormatted: true,
            formatter: "dateFormatter",
            label: "Data Firma Convenzione"
          },
          {
            key: "costoProgetto",
            label: "Costo Progetto",
            formatter: "currencyFormatter",
            sortable: true,
          },
          {
            key: "strumentoFinanziario",
            sortable: true,
          },
          {
            key: "stato",
            sortable: true,
            label: "Stato Prefinanziamento",
          },
          {
            key: "tipologiaModalitaErogazione",
            label: "Circuito Finanziario",
            sortable: true,
          },
          {
            key: "Azioni",
            thClass: "thst",
          },
        ],
      buttonLabels: {
        start_pref: "Avvia processo di Prefinanziamento",
        view: "Visualizza Progetto",
        view_controlli_list: "Visualizza Prefinanziamento",
        claim: "Prendi in Carico",
        unclaim: "Rilascia presa in Carico"
      },
      isBusy: false,
      showCompleteModal: false,
      codiceProgetto: null,
      infoProgetto: null,
      prefinanziamenti: [],
      progettiPrefinanziabili: [],
      progettiSenzaPref: [],
      progettiInPref: [],
      progettiPref: []
    }
  },
  
  watch: {
    '$route.path': function() {
      //console.log("URL modificato=", this.$route.path)
      this.switchArray() //ripopola this.dataForList
    },


  },

  mounted() {
    this.getAllPref()
  },
  
  computed: {

    dynamicTitle(){
      //console.log("cambio il titolo")

      //ogni volta che cambio URL cambio il titolo della pagina
      if (this.$route.path.includes("finanziabili")){
        return "Prefinanziabili"
      } else if (this.$route.path.includes("finanziamento")){
        return "In Prefinanziamento"
      } else if (this.$route.path.includes("finanziati")) {
        return "Prefinanziati"
      } else {
        console.error("URL non gestito!?")
      }


      return "sconosciuta"
    },

    dynamicID(){
      //console.log("cambio l'ID del componente")

      //ogni volta che cambio URL cambio il titolo della pagina
      if (this.$route.path.includes("finanziabili")){
        return "Prefinanziabili"
      } else if (this.$route.path.includes("finanziamento")){
        return "InPrefinanziamento"
      } else if (this.$route.path.includes("finanziati")) {
        return "Prefinanziati"
      } else {
        console.error("URL non gestito!?")
      }

      return "sconosciuto"
    },

    
    

  },

  methods: {
    
    switchArray(){
      console.log("ripopolo i dati visualizzati in base al path=", this.$route.path)
      let size = this.dataForList.length
      //console.log("cancello "+size+" elementi")
      this.dataForList.splice(0, size)
      
      //ogni volta che cambio URL cambio il titolo della pagina
      if (this.$route.path.includes("finanziabili")){
        this.totaleSpeseEuro = utils.formatCurrency(this.totaleSpese1);
        for (let i of this.progettiSenzaPref)
          this.dataForList.push(i)
          

        //console.log("N progetti senza pref=", this.dataForList.length)
          
      } else if (this.$route.path.includes("finanziamento")){
        this.totaleSpeseEuro = utils.formatCurrency(this.totaleSpese2);
        for (let i of this.progettiInPref)
          this.dataForList.push(i)
          
        
        //console.log("N progetti in pref=", this.dataForList.length)

      } else if (this.$route.path.includes("finanziati")) {
        this.totaleSpeseEuro = utils.formatCurrency(this.totaleSpese3);
        for (let i of this.progettiPref)
          this.dataForList.push(i)
          
        
        //console.log("N progetti pref=", this.dataForList.length)
        
      } else {
        console.error("URL non gestito!?")
      }

      console.log("N progetti da visualizzare=", this.dataForList.length)

    },

    getAllPref: async function() {
      
      this.isBusy = true
      const url = endpoints.getAllPrefinanziamenti
      //console.log("getAll URL=", url)

      this.$get(url)
      .then((result) => {
        
        //console.log("prefinanziamenti trovati:", result.length)
        if (result.length > 0){
          this.prefinanziamenti = result
        } else {
          this.prefinanziamenti = []
        }
        //memorizzo i prefinanziamenti trovati e leggo i progetti in attuazione
        this.loadProgettiAttuazione();

      },
      (error) => {
        this.isBusy = false
          notify.error(
            notify.strings.error,
            notify.strings.internalErrorPleaseReportAction
          )
        console.error("errore caricamento lista",error)
        throw error
      })

    },

    closeModal(){
      this.codiceProgetto=null
      this.infoProgetto=null
      this.showCompleteModal = false
    },

    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data)
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        )
        return
      }
      let codiceProgetto = data.item.codiceProgetto
      //console.log("selezionato codiceProgetto=", codiceProgetto)
      let infoProgetto = data.item
      let schedaPref = this.cercaPrefinanziamento(codiceProgetto)
      
      //console.log("infoProgetto=", infoProgetto)
      let idProgetto = infoProgetto.idProgetto
      
      switch (action) {
        case "view":    //visualizzazione della scheda progetto
          this.$router.push({
            name: "schedaProgetto",
            params: { idProgetto: idProgetto },
          })
          break
        case "start_pref": //visualizzazione del prefinanziamento per il progetto selezionato
          this.codiceProgetto = codiceProgetto
          this.infoProgetto = infoProgetto
          this.showCompleteModal = true
          //this.creaNuovaCheckList(codiceProgetto, infoProgetto)
          break
        case "view_controlli_list":
          if(schedaPref && schedaPref.idScheda)
              this.$router.push({
                name: "schedaPrefinanziamento",
                params: { 
                  codiceProgetto: codiceProgetto,
                  idPrefinanziamento: schedaPref.idScheda
                },
              })
          else
            console.error("Prefinanziamento Not Found!", action)        
          break
        case "claim":
            if(schedaPref && schedaPref.idScheda){
              let url = endpoints.claimPrefinanziamento+schedaPref.idScheda
              this.claimUnclaim(url)
            }
            else
              console.error("Prefinanziamento Not Found!", action)
          break
        case "unclaim":
            if(schedaPref && schedaPref.idScheda){
              let url = endpoints.unclaimPrefinanziamento+schedaPref.idScheda
              this.claimUnclaim(url)
            }
            else
              console.error("Prefinanziamento Not Found!", action)
          break
        default:
          console.error("Unknown action!", action)
          break
      }
    },
    
    isAlreadyPrefinanziato(codiceProj){
      if (!codiceProj || !this.prefinanziamenti){
        console.error("isAlreadyPrefinanziato: NULL params!?")
        return null
      }
      
      //cerco fra i prefinanziamenti trovati se questo codice esiste già
      for (let p of this.prefinanziamenti){
        if (p.codiceProgetto === codiceProj){
          //console.log("FIND prefinanziamento!")
          return true
        }
      }
      
      console.log("non esistono prefinanziamenti per il progetto ", codiceProj)
      return false
      
    },

    cercaPrefinanziamento(codiceProj){
      if (!codiceProj || !this.prefinanziamenti){
        console.error("cercaPrefinanziamento: NULL params!?")
        return null
      }
      
      //cerco fra i prefinanziamenti trovati se questo codice esiste già
      for (let p of this.prefinanziamenti){
        if (p.codiceProgetto === codiceProj){
          //console.log("FIND prefinanziamento!")
          return p
        }
      }
      
      console.log("cercaPrefinanziamento: non esistono prefinanziamenti per il codice progetto ", codiceProj)
      return false
      
    },

    isInPrefinanziamento(codiceProj){
      let p = this.cercaPrefinanziamento(codiceProj)
      return (p.stato !== 'Erogato')
    },

    isPrefinanziato(codiceProj){
      let p = this.cercaPrefinanziamento(codiceProj)
      return (p.stato === 'Erogato')
    },

    loadProgettiAttuazione: async function() {
      this.isBusy = true
      const url = endpoints.listActiveProjects;
      this.$get(url)
      .then((result) => {
        this.progettiPrefinanziabili = result
        console.log("Trovati "+result.length+" progetti prefinanziabili")
        this.extractData(result)
        
        this.isBusy = false
      },
      (error) => {
        this.isBusy = false
          notify.error(
            notify.strings.error,
            notify.strings.internalErrorPleaseReportAction
          )
        console.error("errore caricamento lista progetti", error)
        throw error
      })
    }, 

    extractData(result) {
      
      this.dataForList = []
      this.progettiSenzaPref = []
      this.progettiInPref = []
      this.progettiPref = []
      
      //scelgo solo i progetti con prefinanziamento>0 che non hanno già processo in corso
      for (let item of result) {
        if (item.infoProgetto.importoPrefinanziamento > 0 
          && !this.isAlreadyPrefinanziato(item.infoProgetto.codiceProgetto)
          ){

            //aggiungo solo i progetti con prefinanziamento che non hanno già un processo incorso o concluso
            //console.log("prefinanziamento NON PRESENTE")
            //sovrascrivo lo stato del progetto con quello del prefinanziamento che non esiste ancora
            item.infoProgetto.concluso = item.infoProgetto.stato === "Concluso" ? true : false;
            item.infoProgetto.stato = "Da prefinanziare";
            this.progettiSenzaPref.push(item.infoProgetto) 
            const obj1 = parseFloat(item.infoProgetto.costoProgetto);
            if(!isNaN(obj1)){
              this.totaleSpese1 = this.totaleSpese1 + obj1;
            }
            
        } else if (item.infoProgetto.importoPrefinanziamento > 0
          && this.isInPrefinanziamento(item.infoProgetto.codiceProgetto) 
          ){
           
            //aggiungo i progetti con prefinanziamento in corso
            //Aggiungo la scheda prefinanziamento come dato nascosto nella riga, perche mi serve per calcolare le azioni
            item.infoProgetto.schedaPrefinanziamento = this.cercaPrefinanziamento(item.infoProgetto.codiceProgetto)
            console.log("stato Prefinanziamento:", item.infoProgetto.schedaPrefinanziamento.stato);
            //console.log("prefinanziamento IN CORSO")
            //sovrascrivo lo stato del progetto con quello della relativa schedaPrefinanziamento (più utile all'utente)
            item.infoProgetto.stato = item.infoProgetto.schedaPrefinanziamento.stato;
            this.progettiInPref.push(item.infoProgetto)
            const obj2 = parseFloat(item.infoProgetto.costoProgetto);
            if(!isNaN(obj2)){
              this.totaleSpese2 = this.totaleSpese2 + obj2;
            }
            
        } else if (item.infoProgetto.importoPrefinanziamento > 0
          && this.isPrefinanziato(item.infoProgetto.codiceProgetto)
          ) {
            
            //aggiungo i progetti con prefinanziamento erogato
            //console.log("prefinanziamento EROGATO")
            //sovrascrivo lo stato del progetto con quello finale del prefinanziamento
            item.infoProgetto.stato = "Erogato";
            this.progettiPref.push(item.infoProgetto)
            const obj3 = parseFloat(item.infoProgetto.costoProgetto);
            if(!isNaN(obj3)){
              this.totaleSpese3 = this.totaleSpese3 + obj3;
            }

        }         
        else {
          //console.log("skip progetto senza prefinanziamento=",item.infoProgetto)
        }

      }
      console.log("Trovati "+this.progettiSenzaPref.length+" senza prefinanziamento")
      console.log("Trovati "+this.progettiInPref.length+" in prefinanzimento")
      console.log("Trovati "+this.progettiPref.length+" prefinanziati")
      
      
      

      this.switchArray()
    },

    creaNuovaCheckList(codiceProgetto, infoProgetto){
      this.isBusy = true
      this.showCompleteModal = false
      this.creaNuovaSchedaPrefinanz(codiceProgetto, infoProgetto)
    },

        buildInfoProgetto(infoProgetto){
          console.log("IN infoProgetto ", infoProgetto);
          let infoProj = {
            codiceProgetto : infoProgetto.codiceProgetto,
            idProgetto : infoProgetto.idProgetto,
            modalitaDiAccesso: infoProgetto.modalitaDiAccesso,
            titolo : infoProgetto.titoloProgetto,
            stato : infoProgetto.stato,
            dataConclusione: infoProgetto.dataConclusione
          }
          console.log("OUT infoProgetto ", infoProj);
          
          return infoProj;
        },
        buildAnagrafica (infoProgetto){
          return {
            idBeneficiario : infoProgetto.idBeneficiario,
            codiceProgetto : infoProgetto.codiceProgetto,
            titoloProgetto : infoProgetto.titoloProgetto,
            strumentoFinanziario : infoProgetto.strumentoFinanziario,
            costoProgetto : infoProgetto.costoProgetto,
            importoPrefinanziamento : infoProgetto.importoPrefinanziamento,
            percentualePrefinanziamento :  (infoProgetto.importoPrefinanziamento/infoProgetto.costoProgetto)*100,
            dataFirmaDecreto : infoProgetto.dataFirmaDecreto,
            dataFirmaConvenzione : infoProgetto.dataFirmaConvenzione 
          }
        },
        postScheda(versionChecklist, codiceProgetto, infoProgetto) {
          const autore = this.$getUserInfo().preferred_username
          const dataCreazione = new Date()

          let anagr = this.buildAnagrafica(infoProgetto, autore, dataCreazione);
          let infoProj = this.buildInfoProgetto(infoProgetto);
          const url = endpoints.newPrefinanziamento(codiceProgetto)
          let idBenef = infoProgetto.idBeneficiario
          const body = {
            codiceProgetto: codiceProgetto,
            idSchemaControllo: '2_prefinanziamento',
            schemaVersione: versionChecklist,
            idBeneficiario: idBenef,
            tipoControllo: "CircuitoFinanziario",
            sottoTipoControllo: "Prefinanziamento",
            dataInserimento: dataCreazione,
            autore: autore,
            dataUltimoAggiornamento: dataCreazione,
            autoreUltimoAggiornamento: autore,
            content: {
                tipoProcedura: 'prefinanziamento',
                dataUltimoAggiornamento: dataCreazione,
                autoreUltimoAggiornamento: autore,
                controllo: {},
                anagrafica: anagr,
                infoProgetto: infoProj
            }
          }
          // POST di creazione della scheda controllo
          this.$post(url, body)
          .then(scheda => {
              if (scheda.idScheda && scheda.codiceProgetto) {
                // avvio il processo
                this.avviaProcessoPrefinanz(scheda)
              }
          })
          .catch((error) => {
            console.error("Errore POST SchedaPrefinanziamento:", error.message)
            notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction)
          })
        },
        creaNuovaSchedaPrefinanz(codiceProgetto, infoProgetto) {
          // Per creare una nuova scheda di Prefinanziamento
          this.getCurrentCheckList(codiceProgetto, infoProgetto)
        },
        getCurrentCheckList(codiceProgetto, infoProgetto) {
          let url = endpoints.retrieveLastVersionTabellone('2_prefinanziamento')
          this.$get(url)
          .then(checklist => {
            let lastVersion = checklist.lastVersion
            if(lastVersion) {
              this.postScheda(lastVersion, codiceProgetto, infoProgetto)
            }
          })
          .catch((error) => {
            console.error("Errore recupero versione CheckList:", error.message)
            notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction)
            this.isBusy = false;
          })
        },
        avviaProcessoPrefinanz(scheda) {
          const url = endpoints.startPrefinanziamentoProcess
          const body = {
              codiceProgetto: scheda.codiceProgetto,
              idScheda: scheda.idScheda
          }
          // POST per avvio processo prefinanziamento
          this.$post(url, body)
          .then(processo => {
            if(processo) 
              this.autoclaim(scheda)
          })
          .catch((error) => {
            console.error("Errore in avvio processo Prefinanziamento:", error.message)
            notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction)
          })
        },
        claimUnclaim(url, scheda) {
          this.isBusy = true
          // Se scheda è valorizzata --> 1° claim/unclaim
          // (quindi router push verso quella scheda)
          this.$post(url)
          .then(() => {
              if(scheda)
                this.$router.push({
                  name: "schedaPrefinanziamento",
                  params: { 
                    codiceProgetto: scheda.codiceProgetto,
                    idPrefinanziamento: scheda.idScheda
                  }
                })
              else 
                this.getAllPref()
          },
          (error) => {
              console.error("ERRORE: ", error.message)
              if (url.includes('unclaim'))
                  notify.error(notify.strings.error, notify.strings.errorUnclaimScheda)
              else
                  notify.error(notify.strings.error, notify.strings.errorClaimScheda)
              throw error
          })          
        },
        // POST per autoclaim
        autoclaim(scheda) {
            const url = endpoints.claimPrefinanziamento + scheda.idScheda
            this.$post(url)
            .then(() => {
              this.claimUnclaim(url, scheda)
            })
            .catch((error) => {
              console.error("Errore in primo claim / Unclaim:", error.message)
              notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction)
            })
        },
    
  },
}
</script>
