<template>
  <div id="verticalComponent1">
    
    <b-button
      style="margin-left: auto;"
      class="align-right"
      @click="doAction('add')"
      variant="outline-primary"
      :disabled="disableAdd"
      v-if="visibilityAdd">
      <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
      Aggiungi
    </b-button>
    <b-table
      :id="idTablePdf"
      :tbodyTrClass="editedRowClass"
      :items="getItems"
      :fields="tabledata.header"
      :sort-by="sortBy">
      <template v-slot:cell(Azione)="data">
        <b-button
        :id="namingIdButton(x, data.index + 1)"
          variant="outline-primary"
          :key="x" v-for="x in data.value"
          size="sm"
          v-on:click="doAction(x, data.item)">
          <fa-icon v-if="x === 'edit'" :icon="['far', 'edit']" class="selector-icons"/>
          <fa-icon v-if="x === 'delete'" :icon="['far', 'trash-alt']" class="selector-icons"/>
          <fa-icon v-if="x === 'view'" :icon="['far', 'eye']" class="selector-icons"/>
        </b-button>
      </template>
    </b-table>
    <b-modal
      :id="'modalForm1-' + this.name"
      size="lg"
      scrollable centered
      dialog-class="modal1-content"
      content-class="modal1-content"
      hide-footer>
      <template v-slot:modal-title>
        <h3>Modifica</h3>
      </template>
      <ModalForm
        :fdata="this.editFormData"
        :sch="this.getScheda()"
        :buttons="this.buttons"
        :parentName="this.name"
        :cfg="mappa"
        @editField="editRiga"/>
    </b-modal>
    <b-modal :id="'modalData-' + this.name"
             size="lg"
             scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content"
             ok-only ok-variant='danger' ok-title='Chiudi'>
             <template v-slot:modal-title>
                <h3>Visualizzazione</h3>
             </template>
             <DynamicTable2 :tdata="this.modalData"/>
    </b-modal>
     <div class="card " v-if="visibilityTotBudget">
        <div class="card-body text-right "><h1 class="font-weight-bold" :id="idTotalPdf">Totale {{this.title}}: {{(this.totaleListaBudgetEuro)}}</h1> </div>
    </div>
  </div>
</template>
<script>
import ModalForm from "./modalForm1.vue";
import mapUtils from "@/helpers/mapUtils.js";
import { JSONPath as jpath } from "jsonpath-plus";
import calculation from "@/helpers/calculations.js";
import utils from '@/helpers/utils.js'
import { mapGetters } from "vuex";
import tool from "@/helpers/tools.js";
import DynamicTable2 from "@/components/dynamicTable2.vue";
import visibility from '@/helpers/visibility.js';

export default {
  name: "Vertical1",
  data() {
    return {
      sortBy: '',
      mappa: {},
      disableAdd: false,
      tabledata: {
        header: [],
        rows: [],
      },
      buttons: [
        {
          name: "Modifica",
          action: "editField",
          show: true,
          param: "edit",
        },
      ],
      totaleListaBudgetEuro:"",
      editFormData: {},
      modalData: {},
      lastStarRegex: /\*(?!.*\*)/,
      isIdRegex: /\[.+\]/,
      editLine: -1
    };
  },
  components: {
    ModalForm,
    DynamicTable2
  },
  props: {
    actions: {
      type: Array,
      default: () => {
        return ['edit', 'delete'];
      }
    },
    dinamicIds: {
      type: Array,
      required: true,
    },
    tableIds: {
      type: Array,
    },
    name: String,
    idTotale:String,
    title: String,
    updateVertical1: Boolean,
    notifyUpdate: Boolean,
    idTablePdf: { type: String, default: () => '' },
    idTotalPdf: { type: String, default: () => '' }
  },
  watch: {
    updateVertical1: function() {
        this.extractData();
    }
  },
  created() {
    this.mappa = this.getTabelloneProgetto('progetto');
    if(this.mappa) {
      this.extractData();
    } 
    else 
      console.log(this.name, "error in mappa", this.mappa);
  },
  computed: {
    ...mapGetters({
      getTabelloneProgetto: "configuration/getTabellone",
      getSchedaProgetto: "progetto/getSchedaProgetto",
      getSchedaComplete: "progetto/getScheda",
    }),
    getItems() {
      let items = [];
      for (const obj of this.tabledata.rows) {
        let item = {
          note: {},
        };
        for (const key in obj) {
          if (!key.match(this.isIdRegex)) {
            item[key] = obj[key];
          } else {
            item[key] = obj[key].value;
            item.note[key] = obj[key].note;
          }
        }
        items.push(item);
      }
      return items;
    },
    visibilityTotBudget() {
      return this.idTotale;
  },
    visibilityAdd() {
      
      const actions = this.$projectGetActions(
        this.getSchedaComplete({ idSchedaProgetto: this.$route.params.idProgetto})
      );
      return actions.indexOf('add') !== -1;
    },
  },
  methods: {
    editedRowClass(item) {
      if(item.edited && !item.deleted)
        return 'changeInRow';
      else if(item.deleted)
        return 'deletedCell';
      return '';
    },
    // Costruzione id per Buttoni (Azioni nel componente)
    namingIdButton(action, index) {
      return this.$builtIdDinamically(this.name, action, index)
    },

    tooltip(value, key, item) {
      if (item.note[key]) return { title: "nota: " + item.note[key] };
      return {};
    },
    updateSchedaInStore(scheda) {
      this.$store.dispatch("progetto/updateSchedaProgettoObject", {
        idSchedaProgetto: this.$route.params.idProgetto,
        schedaProgetto: scheda,
      });
      if(this.notifyUpdate)
        this.$emit('refreshValues')
    },
    // per ogni riga della tabella determina gli indici di array
    computeArrayIndexes(scheda) {
      let multiplicity;
      for (let id1 of this.dinamicIds) {
        multiplicity = jpath({ resultType: "all" }, "$" + id1, scheda);
        if (multiplicity && multiplicity.length > 0) {
          break;
        }
      }

      if (!multiplicity || multiplicity.length === 0) {
        return {};
      }

      const rowMultiplicity = multiplicity.length;
      let indexArray = [];
      for(let row of multiplicity) {
        indexArray.push(row.path.match(mapUtils.indexRegex));
      }
      return {
        rowMultiplicity: rowMultiplicity,
        indexArray: indexArray
      }
    },
    formatter(value, key) {
      const mapElement = this.mappa[key];
      if(!mapElement)
        return value;
      return utils.formatOutput(mapElement, value);
    },
    calcoloTotaleBudget(scheda) {
        if(this.idTotale) {
          let arrayTotal=jpath({ resultType: "all" },"$"+this.idTotale,scheda);
           let totaleListaBudget = 0;
           for (const tot1 of arrayTotal) {
             const patch1=tot1.path.replace('$','');
             let valore = parseFloat(tot1.value);
             if(scheda.dataEntryConfiguration && scheda.dataEntryConfiguration[patch1]
                && scheda.dataEntryConfiguration[patch1].data.deleted){
                  valore = 0.00;
               }
               if(!isNaN(valore))
                totaleListaBudget += valore;
            }
            this.totaleListaBudgetEuro = utils.formatCurrency(totaleListaBudget);
        }
    },
    extractData() {
      if (!this.mappa) {
        console.error(this.name, "Invalid mappa", this.mappa);
        return;
      }
     
      const schedaComplete = this.getSchedaComplete({
        idSchedaProgetto: this.$route.params.idProgetto,
      });
      let scheda = schedaComplete.schedaProgetto;
      if (!scheda) {
        console.error(this.name, "Invalid scheda", scheda);
        return;
      }
      
      let ids = this.dinamicIds;
      if(this.tableIds)
        ids = this.tableIds;

      if(!ids || ids.length === 0) {
        console.error(this.name, "Invalid ids", this.tableIds, this.dinamicIds);
        return;
      }
      
      this.tabledata.rows = [];
      this.tabledata.header = [];
      // setup header
      for (const colId of ids) {
        let mapElement = this.mappa[colId];
       
        if (!mapElement) {
          console.error("no config, get default one for ", colId);
          mapElement = this.$getDefaultConfig();
          mapElement.config.path = colId;
        }
        
        const headerColumn = {
          key: colId,
          label: mapElement.config.label,
          tdAttr: this.tooltip,
          formatter: this.formatter,
          sortable: true
        };
        this.tabledata.header.push(headerColumn);
      }
      this.tabledata.header.push({ key: "Azione", label: "Azione" });
      this.sortBy = this.tabledata.header[0].key;

      // calcola il numero di righe e gli indici di array per ognuna
      const mult = this.computeArrayIndexes(scheda);
      // numero di righe della tabella
      const rowMultiplicity = mult.rowMultiplicity;
      // array degli indici da sostituire negli id al posto degli asterischi
      const indexArray = mult.indexArray;

      // add rows to table
      let updateScheda = false;
      let ind = 0;
      for (let i = 0; i < rowMultiplicity; i++) {
        let rowObj = {
          _cellVariants: {},
          Azione: [],
          index: ind
        };

        let skip = false;
        for (const colId of this.dinamicIds) {
          let id = colId;
          for(let indexItem of indexArray[i]) {
            id = id.replace(/\*/, indexItem);
          }
        
          const mapElement = this.mappa[colId];
          // il valore viene calcolato automaticamente o ricavato dalla scheda
          let retVal = this.getValue(colId, id, mapElement, scheda);
          scheda = retVal.scheda;
          const result = retVal.result;
          updateScheda = updateScheda || retVal.updateScheda;
          // setup della riga
          
          const setupVal = mapUtils.setupComponentRow(
            rowObj,
            id,
            result,
            this.mappa,
            scheda,
            schedaComplete.taskInfo,
            this.actions
          );
          if(setupVal.skip) {
            skip = true;
            // BUG #19620: questo break impediva il calcolo del totale per righe cancellate
            // questo mancato calcolo innescava il problema sul calcolo dei totali
            // delle righe di budget aggiunte successivamente
            // break;
          }

          rowObj = setupVal.rowObj;
        }
        if(!skip) {
          this.tabledata.rows.push(rowObj);
          ind++;
        }
      }
      // la scheda viene aggiornata se ci sono state modifiche
     
      if (updateScheda) {
        this.updateSchedaInStore(scheda);
      }
      this.calcoloTotaleBudget(scheda);
      this.disableAdd = this.$disableAddBtnInVerticalComp(scheda, this.name);
    },
    
    getValue(colId, id, mapElement, scheda) {
      let result;
      let updateScheda = false;

      const results = jpath("$" + id, scheda);
      
      // a prescindere dal fatto che sia presente in scheda o meno, 
      // se è prevista una calculation rule allora il campo
      // viene ricalcolato e il result viene sovrascritto
      // qui si inserisce il valore calcolato se previsto dal tabellone
      if(mapElement.config.calculationRule) {
        result = calculation.calculateValue(mapElement, this.getScheda(), id);

      }
      // se il campo non esiste nella scheda viene creato
      // e si aggiorna la scheda
      if (results.length === 0) {
          // stampo un log solo se il valore non deve essere calcolato
          // altrimenti è probabile che esso non sia presente nella scheda
          if(!mapElement.config.calculationRule) {
            console.log('valore non trovato in scheda: ', id);
          }
          const retVal = mapUtils.createElementFromScratch(
            id,
            scheda,
            this.mappa
          );
          retVal.parent[retVal.fieldName]=result;
          scheda = retVal.scheda;
          result = retVal.object;
          updateScheda = true;
      } else if (results.length === 1) {
          result = results[0];
      } else {
        console.error(this.name, "is an array?", colId, id, results);
      }
     // a prescindere dal fatto che sia presente in scheda o meno, 
      // se è prevista una calculation rule allora il campo
      // viene ricalcolato e il result viene sovrascritto
      // qui si inserisce il valore calcolato se previsto dal tabellone
      if(mapElement.config.calculationRule) {
        result = calculation.calculateValue(mapElement, this.getScheda(), id);
      }
      return {
        scheda: scheda,
        result: result,
        updateScheda: updateScheda
      }
    },

    updateSchedaInDeletedMode(index) {
      const schedaComplete = this.getSchedaComplete({
        idSchedaProgetto: this.$route.params.idProgetto,
      });
      let clonedScheda = tool.genericFunctions.cloneObject(schedaComplete.schedaProgetto);
      let rowObj = this.tabledata.rows[index];
      // reset delle azioni, verranno calcolate da setupComponentRow
      rowObj.Azione = [];
      let maxOccurrencesOfIndex = 0;
      let indexArray = [];
      // questo loop cerca una chiave contenente il massimo numero di indici
      // gli indici sono conservati nell'array indexes
      for(const key in rowObj) {
        if(!key.match(this.isIdRegex))
          continue;
        const entry = rowObj[key];
        const entryId = entry.id;
        const temp = entryId.match(/\d+/g);
        if(temp && temp.length > 0) {
          if(temp.length > maxOccurrencesOfIndex) {
            maxOccurrencesOfIndex = temp.length;
            indexArray = temp;
          }
        }
      }

      for (const id1 of this.dinamicIds) {
        let id = id1;
        for(let j = 0; j < indexArray.length; j++) {
          id = id.replace(/\*/, indexArray[j]);
         
        }

        if(!clonedScheda.dataEntryConfiguration) {
          clonedScheda.dataEntryConfiguration = {};
        }
        
        let conf = clonedScheda.dataEntryConfiguration[id];
        
        if (conf && conf.data) {
          conf.data.edited = true;
          conf.data.deleted = true;
          if(!conf.data.editTag) 
            conf.data.editTag = mapUtils.createEditTag1(this.mappa, id, this.title);
        }
        else {
          // fix nel caso in cui i data di configurazione non siano presenti
          const editTag = mapUtils.createEditTag1(this.mappa, id, this.title);
          clonedScheda.dataEntryConfiguration[id] = {
            data: {
              edited: true,
              deleted: true,
              editTag: editTag
            }
          }
         
        }

        // value is:
        // 1) calculated if a rule exists
        // 2) taken from tabledata
        // 3) otherwise taken from scheda
        let value = "";
        const mapElement = mapUtils.getMapElement(this.mappa, id1);
        if (mapElement.config.calculationRule) {
          value = calculation.calculateValue(mapElement, clonedScheda, id);
        } else if (rowObj[id1]) {
          value = rowObj[id1].value;
        } else {
          const temp = jpath("$" + id, clonedScheda);
          if (temp.length === 1) {
            value = temp[0];
          } else {
            console.error(this.name, "is this field an array?", id);
          }
        }
        const setupVal = mapUtils.setupComponentRow(
          rowObj,
          id,
          value,
          this.mappa,
          clonedScheda,
          schedaComplete.taskInfo,
          this.actions
        );
        rowObj = setupVal.rowObj;
      }
     
      this.tabledata.rows[index] = rowObj;
      
      
      this.updateSchedaInStore(clonedScheda);
      this.calcoloTotaleBudget(clonedScheda);
      this.disableAdd = this.$disableAddBtnInVerticalComp(clonedScheda, this.name);
      
    },
    getScheda() {
      return this.getSchedaProgetto({
        idSchedaProgetto: this.$route.params.idProgetto,
      });
    },
    editRiga(actionFromModal) {
      this.$bvModal.hide("modalForm1-" + this.name);
      let rowObj = this.tabledata.rows[this.editLine];
      let added = false;
      const schedaComplete = this.getSchedaComplete({
        idSchedaProgetto: this.$route.params.idProgetto,
      });
     
      if (!rowObj) {
        rowObj = {
          _cellVariants: {},
          index: this.tabledata.rows.length
        };
        added = true;
      }
     
      const retVal = mapUtils.updateComponent(
        actionFromModal,
        schedaComplete,
        rowObj,
        this.mappa,
        this.actions,
        this.title
      );
      const clonedScheda = retVal.clonedScheda;
      rowObj = retVal.rowObj;

      this.updateSchedaInStore(clonedScheda);
      
      if (added)
        this.tabledata.rows.push(rowObj);
      else
        this.tabledata.rows.splice(this.editLine, 1, rowObj);
      this.calcoloTotaleBudget(clonedScheda);
      this.disableAdd = this.$disableAddBtnInVerticalComp(clonedScheda, this.name);


    },
    addRiga() {
      this.editFormData = { content: {}, conf: {} };
      let mapElement;
      this.editLine = this.tabledata.rows.length;
      const scheda = this.getScheda();
      for (const colId of this.dinamicIds) {
        mapElement = tool.genericFunctions.cloneObject(
          mapUtils.getMapElement(this.mappa, colId)
        );
        // replace path with correct id not possible
        // mapElement.config.path = colId;
        mapElement.data = this.$getDefaultData();
        
        mapElement.data.added = true;

        let value = null;
        // automatic value calculation
        if (mapElement.config.calculationRule) {
          value = calculation.calculateValue(mapElement, scheda, colId);
          
        }

        if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
            mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda);
        }
        let val = { id: colId };
        val.value = value;
        this.editFormData.conf[colId] = mapElement;
        this.editFormData.content[colId] = val;
      }
    },
    doAction(actionToDo, item) {
      const index = item ? item.index : -1;
      let delMsg = "Si sta per eliminare un elemento. "
      delMsg = delMsg.concat("L'operazione non è reversibile. Procedere?");
      switch (actionToDo) {
        case "add":
          this.addRiga();
          this.$bvModal.show("modalForm1-" + this.name);
          break;
        case "delete":
          this.$bvModal.msgBoxConfirm(delMsg, {
            title: "Conferma eliminazione",
            okVariant: 'success',
            cancelVariant: 'danger',
            okTitle: 'Elimina',
            cancelTitle: 'Chiudi',
            titleClass: 'card-title',
            dialogClass: 'card-body'
          })
          .then(value => {
            if(value) {
                this.updateSchedaInDeletedMode(index);
              }
          })
         
          break;
        case "edit":
          {
            this.editFormData = { content: {}, conf: {} };
            // get fresh data from storage
            const scheda = this.getScheda();

            const row = this.tabledata.rows[index];

            for (const column in row) {
              // skip non consistent info
              if (!column.match(this.isIdRegex))
                continue;

              const id = row[column].id;
              let mapElement = tool.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, id));
              // skip hidden values
              if(mapElement.config.hiddenRule) {
                const hidden = visibility.computeHidden(id, scheda, mapElement);
                if(hidden)
                  continue;
              }
              const dataEntry = scheda.dataEntryConfiguration;
              // ricava la parte "data" dalla scheda o ne crea una di default
              if (dataEntry && dataEntry[id]) {
                mapElement.data = tool.genericFunctions.cloneObject(
                  dataEntry[id].data
                );
              } else {
                mapElement.data = this.$getDefaultData();
              }
              // calcolo automatico degli enumValues, la funzione è definita nel tabellone
              if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
                // TODO questo controllo presuppone che tutte le enum calcolate diventino readonly in modifica
                // se non è così bisogna pensare ad un'alternativa (readonlyRule?)
                mapElement.config.readonly = true;
                mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda);
              }
              let val = { id: id, value: null};
              // TODO capire se serve && !mapElement.config.readonly
              if (mapElement.config.calculationRule ) {
                val.value = calculation.calculateValue(mapElement, scheda, id);
              }
              else {
                const values = jpath("$" + id, scheda);
                if (values.length === 0) {
                  console.error("valore non trovato in scheda: ", id);
                } else {
                  val.value = values[0];
                }
              }
              this.editFormData.conf[id] = mapElement;
              this.editFormData.content[id] = tool.genericFunctions.cloneObject(val);
              this.editLine = index;
            }
            this.$bvModal.show("modalForm1-" + this.name);
          }
          break;
        case "view":
          {
            const scheda = this.getScheda();

            const row = this.tabledata.rows[index];
            this.modalData = {};
            let modalRows = [];
            for (const column in row) {
                // skip non consistent info
                if (!column.match(this.isIdRegex))
                  continue;
                
                const id = row[column].id;
                const temp = mapUtils.getMapElement(this.mappa, id)
                let mapElement = tool.genericFunctions.cloneObject(temp);
                // compute field visibility
                if(mapElement.config.hiddenRule) {
                  const hidden = visibility.computeHidden(id, scheda, mapElement);
                  if(hidden)
                    continue;
                }
                const dataEntry = scheda.dataEntryConfiguration;

                if (dataEntry && dataEntry[id]) {
                  mapElement.data = tool.genericFunctions.cloneObject(
                    dataEntry[id].data
                  );
                 
                } else {
                  mapElement.data = this.$getDefaultData();
                }
                let value;
                if(mapElement.config.calculationRule) {
                  value = calculation.calculateValue(mapElement, scheda, id);
                  

                }
                else {
                  const values = jpath("$" + id, scheda);
                  if (values.length === 0) {
                    console.error("valore non trovato in scheda: ", id);
                  } else {
                    value = values[0];
                  }
                }

                let modalRow = { 
                  content: {
                      Campo : mapElement.config.label,
                      Valore : value,
                  },
                  conf: {} 
                };
                modalRow.conf[id] = mapElement;
                modalRows.push(modalRow);
            }

            this.modalData = {
              header : ['Campo', 'Valore'],
              rows: modalRows
            }
            this.$bvModal.show("modalData-" + this.name);
          }
          break;
        default:
          this.editFormData = {};
          break;
      }
    },
  },
};
</script>
