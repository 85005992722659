<template>
    <div id="collapseAttivitaProgetto">
        <b-button class="align-right" variant="outline-primary"
                  @click="doAction('add')"
                  v-if="visibilityAdd">
            <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
            Aggiungi
        </b-button>
        <b-table
            :id="idTablePdf"
            :sort-by="sortBy"
            :tbodyTrClass="editedRowClass"
            :items="getItems" :fields="tabledata.header">
            <template v-slot:cell(Azione)="data">
                <b-button :id="namingIdButton(x, data.index + 1)"
                          variant="outline-primary" :key="x" v-for="x in data.value"
                          size="sm" v-on:click="doAction(x, data.item)">
                    <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
                    <!-- TASK #10111 è stata rimossa l'azione di delete di un'attività -->
                    <fa-icon v-if="x==='delete'" :icon="['far', 'trash-alt']" class="selector-icons"/>
                </b-button>
            </template>
        
        </b-table>
        <b-modal id="deleteAttivita"
             size="lg" scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content"
             ok-variant='success' ok-title='Elimina'
             cancel-variant='danger' cancel-title='Chiudi'
             @ok="updateSchedaInDeletedMode(deleteIndex)">
             <template v-slot:modal-title>
                <h3>Elimina attività di progetto</h3>
            </template>
            <h3>
                <strong>Attenzione:</strong>
                <br/>
                L'eliminazione di una attività di progetto comporta la cancellazione
                di tutte le informazioni ad essa collegate, tra cui le 
                relative fasi di progetto, iter amministrativo, voci di budget.
            </h3>
            <br/>
            <h3>
                Si desidera continuare?
            </h3>
        </b-modal>
        <b-modal :id="'modalForm1-' + this.name" hide-footer
             size="lg" scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content">
             <template v-slot:modal-title>
                <h3>Modifica valore</h3>
            </template>
            <ModalForm
                :fdata="this.editFormData"
                :sch="this.getScheda()"
                :buttons="this.buttons"
                :parentName="this.name"
                :cfg="mappa"
                @editField="editRiga"/>
        </b-modal>
    </div>
</template>
<script>
import ModalForm from "@/components/modalForm1.vue";
import mapUtils from '@/helpers/mapUtils.js'
import { JSONPath as jpath } from 'jsonpath-plus';
import calculation from '@/helpers/calculations.js';
import { mapGetters } from "vuex";
import tool from '@/helpers/tools.js'
import notify from "@/helpers/notifications.js"
import visibility from '@/helpers/visibility.js';
import utils from '@/helpers/utils.js'

export default {
    name:"Vertical",
    data() {
        return {
            sortBy:  "",
            editLine: -1,
            mappa: {},
            tabledata: {
                header: [],
                "rows": []
            },
            buttons: [
                {
                    name: "Modifica",
                    action: "editField",
                    show: true,
                    param: "edit"
                },
                {
                    name: "Elimina",
                    action: "deleteAttivita",
                    show: true,
                    param: "delete"
                }
            ],
            editFormData: {},
            lastStarRegex: /\*(?!.*\*)/,
            isIdRegex: /\[.+\]/,
            actions: ['edit','delete'], // TASK #10111 è stata rimossa l'azione di delete di un'attività
            deleteIndex: null
        }
    },
    components: {
        ModalForm
    },
    props: {
        inputs: {
            type: Object
        },
        idTablePdf: String,
        dinamicIds: {
            type: Array,
            required: true
        },
        name: String,
        title: String,
        updateAttivita: Boolean
    },
    watch: {
        updateAttivita: function() {
            this.extractData();
        }
    },
    created() {
        this.mappa = this.getTabelloneProgetto('progetto');
        if(this.mappa) {
            this.extractData();
        }
        else
            console.log(this.name, 'error in mappa', this.mappa);
    },
    computed: {
        ...mapGetters({
            getTabelloneProgetto: "configuration/getTabellone",
            getSchedaProgetto: "progetto/getSchedaProgetto",
            getSchedaComplete: "progetto/getScheda",
            getSchedaCronoprog: 'cronoprog/getSchedaComplete',
        }),
        getItems() {
            let items = [];
            for(const obj of this.tabledata.rows){
                let item = {
                    note: {}
                };
                for(const key in obj)
                {
                    if(!key.match(this.isIdRegex)){
                        item[key] = obj[key];
                    }
                    else {
                        item[key] = obj[key].value;
                        item.note[key] = obj[key].note;
                    }
                }
                items.push(item);
            }
            return items;
        },
        visibilityAdd() {
      
            const actions = this.$projectGetActions(
                this.getSchedaComplete({ idSchedaProgetto: this.$route.params.idProgetto})
            );
            return actions.indexOf('add') !== -1;
        },
    },
    methods: {
        editedRowClass(item) {
            if(item.edited && !item.deleted)
                return 'changeInRow';
            else if(item.deleted)
                return 'deletedCell';
            return '';
        },
        // Costruzione id per Buttoni (Azioni nel componente)
        namingIdButton(action, index) {
        return this.$builtIdDinamically(this.name, action, index)
        },

        tooltip(value, key, item) {
            if(item.note[key])
                return {title: 'nota: ' + item.note[key]};
            return {};
        },
        getCronoprog(idScheda) {
            return this.getSchedaCronoprog({idScheda: idScheda}).scheda
        },
        updateSchedaInStore(scheda) {
            this.$store.dispatch('progetto/updateSchedaProgettoObject', {
                idSchedaProgetto: this.$route.params.idProgetto,
                schedaProgetto: scheda
            });
        },
        formatter(value, key) {
            const mapElement = this.mappa[key];
            if(!mapElement)
                return value;
            return utils.formatOutput(mapElement, value);
        },
        extractData() {
            if(!this.dinamicIds || this.dinamicIds.length === 0 || !this.mappa) {
                console.error(this.name, 'Invalid dinamicIds/mappa',
                this.dinamicIds, this.mappa);
                return;
            }
            let updateScheda = false;
            const schedaComplete = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto});
            let scheda = schedaComplete.schedaProgetto;
            if(!scheda) {
                console.error(this.name, 'Invalid scheda', scheda);
                return;
            }

            // setup header
            this.tabledata.header = [];
            for(const colId of this.dinamicIds) {
                let mapElement = this.mappa[colId];
                if(!mapElement) {
                    console.error('no config, get default one for ', colId);
                    mapElement = this.$getDefaultConfig();
                    mapElement.config.path = colId;
                }
                // compute field visibility
                if(mapElement.config.hiddenRule) {
                  const hidden = visibility.computeHidden(colId, scheda, mapElement);
                  if(hidden)
                    continue;
                }
                const headerColumn = {
                    key: colId,
                    label: mapElement.config.label,
                    tdAttr: this.tooltip,
                    formatter: this.formatter,
                    sortable: true
                }
                this.tabledata.header.push(headerColumn);
            }
            this.tabledata.header.push({ key: 'Azione', label: 'Azione'});
            this.sortBy = this.tabledata.header[0].key;

            // TODO choose id with largest number of array indices
            // currently first available id is chosen
            // suppongo che l'indice di riga sia l'ultimo disponibile sugli id
            let temp;
            for(let id1 of this.dinamicIds) {
                temp = jpath({resultType: 'all'}, '$' + id1, scheda);
                if(temp && temp.length !== 0) {
                    break;
                }
            }

            if(!temp || temp.length === 0) {
                return;
            }
            
            // for all results, get the highest index 
            // of most deeply nested array
            let maxIndex = -1;
            for(const item of temp) {
                const indexes = item.path.match(mapUtils.lastIndexRegex);
                if(indexes.length === 0) {
                    console.error(this.name, 'unknown index', item.path);
                    continue;
                }
                const tempIndex = parseInt(indexes[0]);
                if(!isNaN(tempIndex) && tempIndex > maxIndex)
                    maxIndex = tempIndex;
            }

            if(maxIndex < 0) {
                console.error(this.name, 'index error', temp);
                return;
            }
            
            // add rows to table
            this.tabledata.rows = [];
            for(let i = 0; i <= maxIndex; i++) {
                let rowObj = {
                    '_cellVariants': {},
                    Azione: [],
                    index: i
                };
                for(const colId of this.dinamicIds) {
                    const id = colId.replace(this.lastStarRegex, i);                    
                    const results = jpath('$' + id, scheda);

                    let result;
                    if(results.length === 0) {
                        const retVal = mapUtils.createElementFromScratch(id, scheda, this.mappa);
                        scheda = retVal.scheda;
                        result = retVal.object;
                        updateScheda = true;
                        
                    }
                    else if (results.length === 1) {
                        result = results[0];
                    }
                    else {
                        console.error('is an array?', colId, results);
                    }
                    const setupVal = mapUtils.setupComponentRow(rowObj, id, result, this.mappa,
                                                        scheda, schedaComplete.taskInfo, this.actions);
                    rowObj = setupVal.rowObj;
                }
                this.tabledata.rows.push(rowObj);
            }
            if(updateScheda) {
                this.updateSchedaInStore(scheda)
            }
        },
        updateSchedaInDeletedMode(index) {
            const scheda = this.getScheda();
            let clonedScheda = tool.genericFunctions.cloneObject(scheda);

            if(!clonedScheda.dataEntryConfiguration)
                clonedScheda.dataEntryConfiguration = {};
            let conf = clonedScheda.dataEntryConfiguration;

            // id ad hoc per cercare tutti gli elementi presenti nell'attività scelta
            const searchId ="$.progetto.cronoProgramma[" + index + "]..*";
            const paths = jpath({resultType: 'path'}, searchId, clonedScheda);
            if(paths) {
                for(const path of paths) {
                    const realId = path.substr(1);
                    // se l'id termina con un numero di array allora non è un campo, quindi
                    // deve essere saltato
                    if(realId.match(/\d+]$/))
                        continue;

                    if(!conf[realId]) {
                        conf[realId] = {
                            data: {}
                        }
                    }

                    conf[realId].data.deleted = true;
                }
                this.updateSchedaInStore(clonedScheda);
            }
            this.$emit('aggiornaCronoprogramma', index);
        },
        getScheda() {
            return this.getSchedaProgetto({idSchedaProgetto: this.$route.params.idProgetto});
        },
        editRiga(actionFromModal) {    
            this.$bvModal.hide('modalForm1-' + this.name);
            let rowObj = this.tabledata.rows[this.editLine];
            const idAttivitaID = "['progetto']['cronoProgramma'][*]['idAttivita']";
            let added = false;
            const scheda1 = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto});
            const schedaComplete = tool.genericFunctions.cloneObject(scheda1);
            if(!rowObj) {
                rowObj = {
                    '_cellVariants': {},
                    index: this.tabledata.rows.length
                };
                added = true;
            } else {
                const oldIdAttivita = rowObj[idAttivitaID].value;
                const realId = idAttivitaID.replace('*', this.editLine);
                const newIdAttivita = actionFromModal.content[realId].value;
                if(oldIdAttivita !== newIdAttivita)
                    this.updateIdAttivitaInScheda(schedaComplete, oldIdAttivita, newIdAttivita);
            }
            // Contestualmente ad una modifica in schedaProgetto deve aggiornarsi anche CronoProg
            this.updateCronoProgInStore(actionFromModal, added, schedaComplete)
            const retVal = mapUtils.updateComponent(actionFromModal, schedaComplete, rowObj, this.mappa, this.actions, this.title);
            const clonedScheda = retVal.clonedScheda;
            rowObj = retVal.rowObj;

            this.updateSchedaInStore(clonedScheda);
            if(added) {
                // #BUG 6036 sposto la creazione delle fasi al termine dell'aggiunta
                // dell'attività di progetto
                this.createFasi();
                this.tabledata.rows.push(rowObj);
            }
            else {
                // this.tabledata.rows.splice(this.editLine, 1, rowObj);
                this.extractData();
            }
        },
        updateCronoProgInStore(actionFromModal, added, schedaComplete) {
            const contentModal = actionFromModal.content;
            const allIdsContent = Object.keys(contentModal);
            // content dell'attività che scenderà dalla modale
            let attivita_by_modal = {}
            const idScheda = schedaComplete.schedaProgetto.progetto.idProgetto;
            let schedaCronoProg = tool.genericFunctions.cloneObject(this.getCronoprog(idScheda));

            // popolamento delle property dell'attività
            allIdsContent.forEach(id => {
                const regexIds = id.match(/[^[\]]+(?=])/g);
                const prop_attivita = regexIds[regexIds.length - 1].replaceAll("'", "");
                attivita_by_modal[prop_attivita] = contentModal[id].value
            })
            if(added) { // ADD ATTIVITA'
                const newId = tool.genId()
                let new_attivita = {}
                new_attivita['id'] = newId
                new_attivita['rel'] = null
                new_attivita['content'] = attivita_by_modal
                // new_attivita.content.rimodulated = true     // TASK #10542 //Commentata per nuova gestione dei branch cronoprog
                schedaCronoProg.content.attivita[newId] = new_attivita
                //config = this.buildEntriesInDataEntryConf(config, confModal, newId)
            } else {    // EDIT ATTIVITA'
                // Per trovare l'attività che devo editare in CronoProg dovrò servirmi dell'idAttivita
                // però nella modale questo campo potrebbe essere modificato(vanificando tutto!)
                // vado quindi a prelevare l'idAttivita che ha la ROW dell'attività che sto modificando
                const property_attivita_in_table = Object.keys(this.tabledata.rows[this.editLine]);
                let id_attivita_to_edit
                // Recupero l'id dell'Attività che si vuole modificare
                property_attivita_in_table.forEach(column => {
                    // Le colonne della tabella corrispondono a property di attività
                    if(column.includes('idAttivita')) {
                        id_attivita_to_edit = this.tabledata.rows[this.editLine][column].value;
                    }
                })
                // Tra tutte le attività di CronoProg cerco quella che ha l'idAttivita della row che sto modificando
                let all_attivita_in_cronoProg = schedaCronoProg.content.attivita
                let ids_all_attivita_in_cronoProg = Object.keys(all_attivita_in_cronoProg)
                ids_all_attivita_in_cronoProg.forEach(key => {
                    // let int_idAttivita_cronoProg = parseInt(all_attivita_in_cronoProg[key].content.idAttivita)
                    // let int_idAttivita_current = parseInt(id_attivita_to_edit)
                    // int_idAttivita_cronoProg === int_idAttivita_current || 
                    if(all_attivita_in_cronoProg[key].content.idAttivita === id_attivita_to_edit) {
                        let content_attivita_to_update = all_attivita_in_cronoProg[key].content
                        content_attivita_to_update = this.updateObject(content_attivita_to_update, attivita_by_modal)
                        schedaCronoProg.content.attivita[key].content = content_attivita_to_update
                        //config = this.buildEntriesInDataEntryConf(config, confModal, key)
                    }
                })
            }
            // Aggiornamento della DataEntryConfiguration (Store)
            //schedaCronoProg.content.dataEntryConfiguration = config
            // Aggiornamento SchedaCronoProg
            this.$store.dispatch('cronoprog/setScheda', {
                idScheda: idScheda,
                scheda: schedaCronoProg.content
            })

        },
        buildEntriesInDataEntryConf(config, confModal, alfaId) {
            let allIdsConf = Object.keys(confModal)
            allIdsConf.forEach(id => {
                let regexIds = id.match(/[^[\]]+(?=])/g)
                let prop_attivita = regexIds[regexIds.length - 1].replaceAll("'", "")
                let composition_id_dataEntryConf = "['attivita']" + "['" + alfaId + "']" + "['content']" + "['" + prop_attivita + "']"
                config[composition_id_dataEntryConf] = {}
                // config[composition_id_dataEntryConf]['data'] = {}
                config[composition_id_dataEntryConf]['data'] = confModal[id].data;
            })
            return config
        },
        //  Aggiorno un oggetto sulla base di un altro, ma solo considerando le property comuni
        updateObject(target, src) {
            const res = {}
            Object.keys(target).forEach(k => res[k] = (src[k] ? src[k] : target[k]))
            return res
        },
        addRiga() {
            this.editFormData = {content:{}, conf: {}};
            let mapElement;
            const newIndex = this.tabledata.rows.length;
            this.editLine = newIndex;
            const scheda = this.getScheda();
            if(this.dinamicIds) { // Per ogni id ho n colonne
                for(const colId of this.dinamicIds) {
                    const id = colId.replace(this.lastStarRegex, newIndex);
                    mapElement = tool.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, id));
                    // replace path with correct id;
                    mapElement.config.path = id;
                    let value = '';
                    
                    
                    // automatic value calculation
                    if(mapElement.config.calculationRule) {
                        value = calculation.calculateValue(mapElement, scheda, id);
                    }

                    mapElement.data = this.$getDefaultData();
                    
                    mapElement.data.added = true;

                    let val = {'id': id};
                    val.value = value;
                    this.editFormData.conf[id] = mapElement;
                    this.editFormData.content[id] = val;
                }
            }
            // #BUG 6036 sposto la creazione delle fasi al termine dell'aggiunta
            // dell'attività di progetto
            // this.createFasi();
        },

        createFasi() {
            const idAttivita = this.tabledata.rows.length;
            const fasiIds = [
                "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['descrizione']",
                "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['localizzazione']['luogo']",
                "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataInizio']",
                "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataFine']"
            ];
            let scheda = this.getScheda();
            let faseId = "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']";
            if(!this.mappa || !this.mappa[faseId]) {
                console.error(this.name, 'error in mappa.fase', this.mappa);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            }

            const fasi = this.mappa[faseId].config.enumValues;
            faseId = faseId.replace(/\*/, idAttivita);

            for(let i = 0; i < fasi.length; i++)
            {
                let id = faseId.replace(/\*/, i);

                let retVal = mapUtils.createElementFromScratch(id, scheda, this.mappa);
                scheda = retVal.scheda;
                let parent = retVal.parent;
                let fieldName = retVal.fieldName;
                parent[fieldName] = fasi[i];

                // creo anche la configurazione
                // if(!scheda.dataEntryConfiguration)
                //     scheda.dataEntryConfiguration = {};
                // scheda.dataEntryConfiguration[id] = {
                //     data: {
                //         added: true
                //     }
                // }

                for(const starsId of fasiIds) {
                    let id1 = starsId.replace(/\*/, idAttivita);
                    id1 = id1.replace(/\*/, i);
                    retVal = mapUtils.createElementFromScratch(id1, scheda, this.mappa);
                    scheda = retVal.scheda;

                    // creo anche la configurazione
                    // if(!scheda.dataEntryConfiguration)
                    //     scheda.dataEntryConfiguration = {};
                    // scheda.dataEntryConfiguration[id1] = {
                    //     data: {
                    //         added: true
                    //     }
                    // }
                }
            }
            this.$emit('creationPhases');
            this.updateSchedaInStore(scheda);
        },

        updateIdAttivitaInScheda(schedaComplete, oldIdValue, newIdValue) {
            const idsToCheck = [
                "['progetto']['cronoProgramma'][*]['forniture'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiPersonale'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiViaggio'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiServiziForniture'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiSpeseGara'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiInformazione'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiManutenzione'][*]['idAttivita']",
                "['progetto']['cronoProgramma'][*]['costiSupportoTecnico'][*]['idAttivita']"
            ];
            const scheda = schedaComplete.schedaProgetto;
            for(const starId of idsToCheck) {
                const realId = starId.replace('*', this.editLine);
                const jpathRes = jpath({resultType: 'all'}, '$' + realId, scheda);
                if(!jpathRes || jpathRes.length === 0) {
                    continue;
                }

                for(const res of jpathRes) {
                    if(res.value === oldIdValue) {
                        res.parent[res.parentProperty] = newIdValue;
                    }
                }
            }
            this.$emit('updateIdAttivita');
        },

        doAction(actionToDo, item) {
            const index = item ? item.index : -1;
            switch(actionToDo) {
                case 'add':
                    this.addRiga();
                    this.$bvModal.show('modalForm1-' + this.name);
                    break;
                case 'delete':
                    this.deleteIndex = index;
                    this.$bvModal.show('deleteAttivita');
                    break;
                case 'edit':
                    {
                        this.editFormData = {content:{}, conf: {}};
                        // get fresh data from storage
                        const scheda = this.getScheda();
                        const row = this.tabledata.rows[index]
                        for (const column in row) {
                            // skip non consistent info
                            if(!column.match(this.isIdRegex))
                                continue;

                            const id = row[column].id;

                            const values = jpath('$'+id, scheda);
                            let mapElement = tool.genericFunctions.cloneObject(mapUtils.getMapElement(this.mappa, id));
                            mapElement.config.path = id;
                            const dataEntry = scheda.dataEntryConfiguration;

                            if(dataEntry && dataEntry[id]) {
                                mapElement.data = tool.genericFunctions.cloneObject(dataEntry[id].data);
                            }
                            else {
                                mapElement.data = this.$getDefaultData();
                            }
                            let val = {'id': id};
                            if(values.length === 0) {
                                console.error('valore non trovato in scheda: ', id);
                            }
                            else {
                                val.value = values[0];
                            }

                            this.editFormData.conf[id] = mapElement;
                            this.editFormData.content[id] = tool.genericFunctions.cloneObject(val);
                            this.editLine = index;
                        }

                        this.$bvModal.show('modalForm1-' + this.name);
                    }
                    break;
                default:
                    this.editFormData = {};
                break;
            }
        }
    }
}
</script>
