<template>
    <div class="mb-5" style="text-align: right;" v-if="retrieveInfoCompiling() !== ''">
        <b-button
            variant="secondary"
            :id="'info-btn-' + tabName"
            v-b-toggle="'collapse-' + tabName">{{ title }}</b-button>
        <b-collapse :id="'collapse-' + tabName" class="mt-2">
        <b-card>
            <p class="card-text" style="text-align: left;"><span v-html="retrieveInfoCompiling()"></span></p>
        </b-card>
        </b-collapse>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "infoCompiling",
  props: {
    tabName: { type: String, default: function() { return '' } },
    title: { type: String, default: function() { return '' } }
  },
  computed: {
    ...mapGetters({
        getTabelloneInfo: 'configuration/getTabellone'
    })
  },
  methods: {
    retrieveInfoCompiling() {
        let info = this.getTabelloneInfo('info_monitoraggio');
        return info?.tabs?.[this.tabName]?.info || ''
    }
  }
}
</script>