<template>
  <div>
    <GenericList
      :id="'listaProgettiIrregolari'" 
      :title="'Lista Progetti con Irregolarità'"
      :busyTable="isBusy"
      :headerTable="headerTable"
      :sort-by="sortBy"
      :dataTable="dataForList"
      :actionsInRow="this.$getActionsProgettiIrregolari"
      :actionsMapping="this.objMapping"
      :blockButton="isBusy || blockButton"
      @clickActionList="doAction"
    >
    </GenericList>

  </div>
</template>
<script>

import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue"
import GenericList from "@/components/genericList.vue"

export default {
  name: "listaProgettiIrregolari",
  components: {
    GenericList
  },
  data() {
    return {
      dataForList: [],
      sortBy: "codiceProgetto",
      headerTable: [
          {
            key: "idProgetto",
            label: "Id Progetto",
            sortable: true,
          },
          {
            key: "codiceProgetto",
            label: "Codice Progetto",
            sortable: true,
          },
          {
            key: "titolo",
            label: "Titolo",
            sortable: true,
          },
          {
            key: "dataConclusione",
            sortable: true,
            filterByFormatted: true,
            formatter: "dateFormatter",
            label: "Data Conclusione",
          },
          {
            key: "costoProgetto",
            label: "Risorse complessive",
            formatter: "currencyFormatter",
            sortable: true,
          },
          {
            key: "Azioni",
            thClass: "thst",
          },
        ],
      objMapping: {
        view: "Visualizza Progetto",
        view_irregolarita_list: "Visualizza Irregolarità"
      },
      blockButton: false,
      isBusy: false,
    };
  },
    
  mounted() {
    this.extractData();
  },
  
  methods: {
    
    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      let idProgetto = data.item.idProgetto;
      let codiceProgetto = data.item.codiceProgetto;
      // const urlClaim = endpoints.claimProgetto + idProgetto
      switch (action) {
        case "view":    //visualizzazione della scheda progetto
          this.$router.push({
            name: "schedaProgetto",
            params: { idProgetto: idProgetto },
          });
          break;
        case "view_irregolarita_list": //visualizzazione dei controlli per il progetto selezionato
        console.info("CODICE: ",codiceProgetto)
          this.$router.push({
            name: "listaIrregolarita",
            params: { codiceProgetto: codiceProgetto },
          })
          break;
        
        default:
          console.error("Unknown action!", action);
          break;
      }
    },
    
    extractData: async function() {
      this.isBusy = true;
      const url = endpoints.getAllIrregolarita;
      this.$get(url)
      .then((result) => {
        this.dataForList = result
        this.isBusy = false;
      },
      (error) => {
        this.isBusy = false;
          notify.error(
            notify.strings.error,
            notify.strings.internalErrorPleaseReportAction
          );
        console.error("errore caricamento lista",error);
        throw error;
      });
      
    },
    
  },
};
</script>