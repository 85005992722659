import Vue from "vue";
import endpoints from "@/components/endpoints.vue";

const state = {
    schedeControlli: {},
    tabelloneControlli: {},
    tabelloneProceduraEContratto: {},
    allegatiDaCancellare: [],
    schedaNoteRiservate: null
}

const getters = {
    getTabelloneProcedura(state) {
        return state.tabelloneProceduraEContratto
    },
    // Recupera tutti i files allegati ad una scheda di Autocontrollo
    getAttachedFiles(state) {
        return (payload) => {
            if ( payload &&
                payload.codiceProgetto &&
                payload.id &&
                state.schedeControlli[payload.codiceProgetto] &&
                state.schedeControlli[payload.codiceProgetto][payload.id]){
                    return state.schedeControlli[payload.codiceProgetto][payload.id].content.allegati
                }
                else
                    console.error('controlli/getScheda required payload missing:', payload)
                ;
                return null
        }
    },
    //recupera una scheda (controllo/contratto/pagamento) col suo ID
    // Nello store controlli i documenti sono organizzati in liste flat sotto codice progetto
    getScheda(state) {
        return (payload) => {
            if ( payload &&
                payload.codiceProgetto &&
                payload.id &&
                state.schedeControlli[payload.codiceProgetto] &&
                state.schedeControlli[payload.codiceProgetto][payload.id]){
                    return state.schedeControlli[payload.codiceProgetto][payload.id]
                }
                else
                    console.error('controlli/getScheda required payload missing:', payload)
                ;
                return null
        }
    },
    getTabellone(state) {
        return state.tabelloneControlli;
    },
    getTabProcedura(state) {
        return (payload) => {
            if (payload && payload.idControllo &&
                state.tabelloneControlli[payload.idControllo]) {
                    return state.tabelloneControlli[payload.idControllo]
            }
            else {
                console.error('getTabellone payload error', payload)
            }
            return {}
        }
    },
    getAllegatiDaCancellare(state){
        return state.allegatiDaCancellare;
    },
    getSchedaNoteRiservate(state) {
        return (payload) => {
            if ( payload &&
                payload.noteRiservatekey &&
                state.schedaNoteRiservate &&
                state.schedaNoteRiservate[payload.noteRiservatekey]) {
                    return state.schedaNoteRiservate[payload.noteRiservatekey];
                }
                else
                    console.error('controlli/get SchedaNoteRiservate required payload missing:', payload);
                return null
        }
    }
}

const actions = {
    setControlloAmministrativo(context, payload) {
      let codProgetto = payload.codiceProgetto
      let idSchedaControllo = payload.idSchedaControllo
      const url = endpoints.getControlloAmministrativo(codProgetto, idSchedaControllo)
      return Vue.prototype.$get(url)
        .then(scheda => {
        //   console.log("Controllo letto=", scheda)
          context.commit("SET_SCHEDA", {
            codiceProgetto: codProgetto,
            id: idSchedaControllo,
            scheda: scheda,
          });
          // GESTIONE SCHEDA NOTE RISERVATE IN STORE
          if(!scheda.noteRiservateKey) return scheda;
          else {
            const keySchedaNoteRiservate = scheda.noteRiservateKey;
            const version = scheda.noteRiservateVersion || 1000;
            const url_reserved = endpoints.noteRiservate(keySchedaNoteRiservate)
            return Vue.prototype.$get(url_reserved, {version: version}).then((schedaNoteRiservate) => {
                context.commit('ADD_SCHEDA_NOTE_RISERVATE', {
                    schedaNoteRiservate: schedaNoteRiservate
                });
                return scheda;
            },
            (error) => {
                console.error('setSchedaNoteRiservate error', error.response);
                throw error
            });
          }
        },
        (error) => {
          console.error("ERRORE: ", error.message)
          throw error
        })
    },
    setTabelloneProcedura(context) {
        const get_tab_procedura = endpoints.getProceduraControllo
        return Vue.prototype.$get(get_tab_procedura)
        .then(tab_procedura => {
            context.commit("SET_TABELLONE_PROCEDURA", {
                content: tab_procedura
            });
            return tab_procedura
        },
        (error) => {
            console.error("ERRORE CARICAMENTO TABELLONE, url="+get_tab_procedura, error.message)
            throw error;
        })
    },
    setTabelloneControllo(context, payload) {
        let schedaControllo = payload.schedaControllo
        // In base a idSchemaControllo e schemaVersione
        // chiamo la configurazione di CheckList opportuna
        //let idSchedaControllo = schedaControllo.idSchedaControllo;
        let idSchema = schedaControllo.idSchemaControllo;
        let versione = schedaControllo.schemaVersione;
        let sottoTipo = schedaControllo.sottoTipoControllo;

        let compositeUrl = null;
        //creo l'URL composto
        if (sottoTipo && idSchema && versione){
            compositeUrl =  'controlli/' + idSchema + '/' + versione
        } else {
            console.error("Malformed schedaControllo! Check creation modal");
            return {};
        }

        const urlTab = endpoints.getTabelloneControllo(compositeUrl)
        return Vue.prototype.$get(urlTab)
        .then(tabellone => {
            context.commit("SET_TABELLONE", {
                content: tabellone
            });
            return tabellone
        },
        (error) => {
            console.error("ERRORE CARICAMENTO TABELLONE, url="+urlTab, error.message)
            throw error;
        })
    },
    updateScheda(context, payload) {
        context.commit("SET_SCHEDA", {
            codiceProgetto: payload.codiceProgetto,
            id: payload.id,
            scheda: payload.content,
        })
    },
    getAllSchedeControlli(context, payload){
        //const infoAllegati;
        if (payload.codiceProgetto){
            let url = endpoints.getControlliAmministrativi(payload.codiceProgetto)
            return Vue.prototype.$get(url).then(
                (result) => {
                    return result
                },
                (error) => {
                    throw error
                }
            )
        } else {
            const msg = "action getAllSchedeControlli: NO codiceProgetto passed!!!";
            console.error(msg);
            throw new Error(msg);
        }
        
    },
    getAllSchedeInLoco(context, payload){
        //const infoAllegati;
        if (payload.codiceProgetto){
            let url = endpoints.getControlliLoco(payload.codiceProgetto)
            return Vue.prototype.$get(url).then(
                (result) => {
                    return result
                },
                (error) => {
                    throw error
                }
            )
        } else {
            const msg = "action getAllSchedeInLoco: NO codiceProgetto passed!!!";
            console.error(msg);
            throw new Error(msg);
        }
        
    },
    clearControlli(context) {
        context.commit("RESET");
    },
    setAllegatoDaCancellare(context, payload){
        context.commit("SET_DA_CANCELLARE", {
            fileId: payload.fileId
        })
    },
    clearAllegatiDaCancellare(context){
        context.commit("RESET_DA_CANCELLARE")
    }
}

const mutations = {
  RESET(state) {
    state.schedeControlli = {};
    state.tabelloneControlli = {};
    state.tabelloneProceduraEContratto = {};
    state.allegatiDaCancellare = [];
    state.schedaNoteRiservate = null;
  },
  SET_TABELLONE(state, payload) {
    if(payload && payload.content){
        state.tabelloneControlli = payload.content
      }
      else
        console.error("SET_TABELLONE_CONTROLLO Error in Payload:", payload)
  },
  SET_TABELLONE_PROCEDURA(state, payload) {
    if(payload && payload.content) {
        state.tabelloneProceduraEContratto = payload.content
      }
    else
      console.error("SET_TABELLONE_PROCEDURA Error in Payload:", payload)

  },
  ADD_SCHEDA_NOTE_RISERVATE(state, payload) {
    if(payload && payload.schedaNoteRiservate) {
        if(state.schedaNoteRiservate == null)
                state.schedaNoteRiservate = {};
        state.schedaNoteRiservate[payload.schedaNoteRiservate.noteRiservateKey] = {};
        state.schedaNoteRiservate[payload.schedaNoteRiservate.noteRiservateKey] = payload.schedaNoteRiservate;
    }
    else
        console.error('ADD_SCHEDA_NOTE_RISERVATE no key or scheda', payload);
  },
  SET_SCHEDA(state, payload) {
      if(payload && payload.codiceProgetto && payload.scheda && payload.id){
        if( !state.schedeControlli[payload.codiceProgetto] )
            state.schedeControlli[payload.codiceProgetto] = {}
        
        state.schedeControlli[payload.codiceProgetto][payload.id] = payload.scheda
      }
      else
        console.error("SET_SCHEDA Error in Payload:", payload)
  },
  SET_DA_CANCELLARE(state, payload){
      if(payload && payload.fileId){
          state.allegatiDaCancellare.push(payload.fileId)
      }
      else
        console.error("SET_DA_CANCELLARE Error in payload: ", payload)
  },
  RESET_DA_CANCELLARE(state){
      state.allegatiDaCancellare = []
  }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}