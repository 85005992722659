<template>
    <div>
        <div class="table-title">Controlli Amministrativi Progetto: {{codiceProgetto}}</div>
            <b-card-body v-if="schedaProgetto !== null">
                <Collapse :name="'Fase 1A'">
                    <GenericList
                        :id="'listaControlliAmmFase1A'" 
                        :title="''"
                        :busyTable="isBusy"
                        :createEntity="this.canCreate('1A')"
                        :headerTable="tableControlliHeader"
                        :dataTable="controlliFase('1A')"
                        :actionsInRow="this.$getActionsControlliAmm"
                        :actionsMapping="this.objMapping"
                        :blockButton="isBusy"
                        @clickActionList="doAction"/>
                </Collapse>
                <Collapse :name="'Fase 1B'">
                    <GenericList
                        :id="'listaControlliAmmFase1B'" 
                        :title="''"
                        :busyTable="isBusy"
                        :createEntity="this.canCreate('1B')"
                        :headerTable="tableControlliHeader"
                        :dataTable="controlliFase('1B')"
                        :actionsInRow="this.$getActionsControlliAmm"
                        :actionsMapping="this.objMapping"
                        :blockButton="isBusy"
                        @clickActionList="doAction"/>
                </Collapse>
                <Collapse :name="'Fase 2'">
                    <GenericList
                        :id="'listaControlliAmmFase2'" 
                        :title="''"
                        :busyTable="isBusy"
                        :createEntity="this.canCreate('2')"
                        :headerTable="tableControlliHeader2"
                        :dataTable="controlliFase('2')"
                        :actionsInRow="this.$getActionsControlliAmm"
                        :actionsMapping="this.objMapping"
                        :blockButton="isBusy"
                        @clickActionList="doAction"/>
                </Collapse>
            </b-card-body>
            <b-modal
                id="modalFormNuovaCheckList"
                hide-footer
                size="xl"
                hide-header-close
                no-close-on-backdrop
                scrollable
                centered
                dialog-class="modal1-content"
                content-class="modal1-content">
                <template  v-slot:modal-title>
                    <div v-if="popup1A">
                        <h3>Crea Nuova CheckList di Controllo Fase 1A</h3>
                    </div>
                    <div v-if="popup1B">
                        <h3>Crea Nuova CheckList di Controllo Fase 1B</h3>
                    </div>
                    <div v-if="popup2">
                        <h3>Crea Nuova CheckList di Controllo Fase 2</h3>
                    </div>
                </template>
                <selezioneCheckListControllo1A v-if="popup1A" @closeModalCheckList="closeModal"/>
                <selezioneCheckListControllo1B v-if="popup1B" @closeModalCheckList="closeModal"/>
                <selezioneCheckListControllo2 v-if="popup2" 
                    @closeModalCheckList="closeModal"
                    :saldoDone="saldoDone"
                    />
            </b-modal>
            <b-modal
                id="modalNuovaIrregolarita"
                :title="'Nuova Segnalazione per il progetto ' + codiceProgetto"
                hide-footer
                size="xl"
                no-close-on-backdrop
                scrollable
                centered
            >
            <modalNuovaIrregolarita
                :isBusy="isBusy"
                :codiceProgetto="codiceProgetto"
                :processDefKey="'ProgettoSegnalazioneContr'"
                @closeModalCreateIrregolarita="closeModalCreateIrregolarita"
            />
            </b-modal>
    </div>
</template>

<script>
import Collapse from "@/components/collapse.vue"
import selezioneCheckListControllo1A from "./selezioneCheckListControllo1A.vue"
import selezioneCheckListControllo1B from "./selezioneCheckListControllo1B.vue"
import selezioneCheckListControllo2 from "./selezioneCheckListControllo2.vue"
import modalNuovaIrregolarita from "../irregolarita/modalNuovaIrregolarita.vue"
import notify from "@/helpers/notifications.js"
import GenericList from "@/components/genericList.vue"
import endpoints from "@/components/endpoints.vue";


export default {
    name: "listaControlliAmministrativiProgetto",
    components: {
        Collapse,
        selezioneCheckListControllo1A,
        selezioneCheckListControllo1B,
        selezioneCheckListControllo2,
        GenericList,
        modalNuovaIrregolarita
    },
    data(){
        return {
            schedaProgetto: null,
            componentId: "listaControlliAmministrativiProgetto",
            popup1A: false,
            popup1B: false,
            popup2: false,
            codiceProgetto: "",
            // monitoraggio: {}, UNUSED
            controllo: {},
            controlli: [],
            filter: null,
            sortBy: "idAttivita",
            tableControlliHeader: [
                {
                    key: "sottoTipoControllo",
                    label: "Fase"
                },
                {
                    key: "dataInserimento",
                    formatter: "dateFormatter",
                    label: "Data Inserimento",
                },
                {
                    key: "idAttivita",
                    label: "Id Attività"
                },
                {
                    key: "idProceduraGara",
                    label: "Id Procedura"
                },
                {
                    key: "oggettoProcedura",
                    label: "Oggetto Procedura"
                },
                {
                    key: "titoloControllo",
                    label: "Titolo Controllo"
                },
                {
                    key: "stato",
                    label: "Stato"
                },
                {
                    key: "Azioni"
                }
            ],
            tableControlliHeader2: [
                {
                    key: "sottoTipoControllo",
                    label: "Fase"
                },
                {
                    key: "dataInserimento",
                    formatter: "dateFormatter",
                    label: "Data Inserimento",
                },
                {
                    key: "idAttivita",
                    label: "Id Attività"
                },
                {
                    key: "idProceduraGara",
                    label: "Id Procedura"
                },
                {
                    key: "oggettoProcedura",
                    label: "Oggetto Procedura"
                },
                {
                    key: "titoloControllo",
                    label: "Titolo Controllo"
                },
                /* WARNING: non presente nella scheda controllo di fase 2
                {
                    key: "riferimentoContratto",
                    label: "Numero Contratto"
                },
                */
                {
                    key: "stato",
                    label: "Stato"
                },
                {
                    key: "Azioni"
                }
            ],
            objMapping: {
                view: "Visualizza Controllo",
                edit: "Modifica Controllo",
                unclaim: "Rilascia presa in carico",
                claim: "Prendi in carico",
                start_irr: "Avvia Segnalazione"
            },
            isBusy: true,
            role: "sconosciuto",
            saldoDone: false
        };
    },
    mounted(){
        this.codiceProgetto = this.$route.params.codiceProgetto
        this.prepareData()
    },
    computed: {

        controlliFase() {
          return fase => {
            if (this.controlli && this.controlli.length){
                return this.controlli.filter(controllo => {
                    return (controllo.sottoTipoControllo === fase
                          && controllo.stato !== "Eliminato dall'utente"
                          && ((this.role.includes('Beneficiario'))             //Beneficiario vede sempre tutte le schede controllo (1A,1B e 2) in tutti gli stati
                            || (controllo.stato !== "In Compilazione"          //Gli Uffici vedono le schede solo dopo lo stato iniziale
                                && (this.role.includes('UfficioControlli')
                                    || this.role.includes('UfficioEconomicoFinanziario')
                                    || this.role.includes('UfficioGestione')
                                    || this.role.includes('AutoritaResponsabile')
                                    || this.role.includes('fficioContabilitaSpeciale')
                                    || this.role.includes("SegreteriaTecnicaAmministrativa-Direttore")
                                    || this.role.includes("Visualizzatore")
                                    )
                                )
                            ))
                })
            } else return [];
          }
        },
    },
    methods: {
        async prepareData() {
            // Carico la scheda Progetto (per un progetto in stato Concluso il bottone di creazione non sarà visibile)
            await this.getSchedaProgettoByCodice()
            this.role = this.$getUserRole(); 
            this.loadControls();
        },
        async getSchedaProgettoByCodice() {
            let codice_progetto = this.$route.params.codiceProgetto
            this.$get(endpoints.getSchedaProgettoWithIdMax(codice_progetto))
            .then(scheda => {
                this.schedaProgetto = scheda
            })
            .catch((error) => {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },
        canCreate(fase){
            if (this.role.includes("Beneficiario-Operatore") && this.schedaProgetto && this.schedaProgetto.stato !== 'Concluso'){
                return "Nuovo Controllo Fase " + fase;      //label del pulsante
            } else {
                return "";                              //label vuota significa non posso nemmeno vedere il pulsante (vedi implementazione componente genericList)
            }
        },

        openModalCreateCheckList1A() {
            this.popup1A = true;
            this.popup1B = false;
            this.popup2 = false;
            this.$bvModal.show('modalFormNuovaCheckList')
        },

        openModalCreateCheckList1B() {
            this.popup1A = false;
            this.popup1B = true;
            this.popup2 = false;
            this.$bvModal.show('modalFormNuovaCheckList')
        },

        openModalCreateCheckList2() {
            this.popup1A = false;
            this.popup1B = false;
            this.popup2 = true;
            this.$bvModal.show('modalFormNuovaCheckList')
        },

        closeModal(){
            this.popup1A = false;
            this.popup1B = false;
            this.popup12 = false;
            this.$bvModal.hide('modalFormNuovaCheckList')
        },

        getFilterFields() {
            let filterFields = [];
            for (let item of this.tableControlliHeader) {
                filterFields.push(item.key);
            }
            return filterFields;
        },

        loadControls(){
            const codiceProgetto = this.codiceProgetto;
            if (!codiceProgetto){
                console.error("loadControls: codiceProgetto NULL!");
                return;
            }
            
            //Setto nello store i controlli relativi a questo progetto
            this.$store.dispatch("controlli/getAllSchedeControlli",
                { codiceProgetto: codiceProgetto }
            ).then(
                (schedeControlli) => {
                    this.controlli = schedeControlli;
                    console.log("trovate "+this.controlli.length+" schede controllo per il progetto "+codiceProgetto);
                    this.isBusy = false;
                    /* UNUSED
                    this.loadLastMonitoraggioByCodice();
                    */

                    // Commentato per consentire la creazione di più dichiarazioni di spesa con il chexbox in modale ("Dichiarazione di spesa saldo Progetto") abilitato
                    // for (let i = 0; i < this.controlli.length; i++) {
                    //     //console.log("taskInfo=", this.controlli[i].content.taskInfo);
                    //     //per ogni elemento richiedo le azioni possibili su di esso
                    //     //this.controlli[i].Azioni = this.$getActionsControlliAmm(this.controlli[i], this.componentId);
                    //     if (this.controlli[i].sottoTipoControllo == "2" && this.controlli[i].dichiarazioneSaldo == "true"){
                    //         console.log("Trovata dichiarazione di saldo! (Progetto a Rimborso)");
                    //         this.saldoDone = true;
                    //     }
                    // }
                }
            ).catch( error => {
                this.isBusy = false;
                console.error('Errore store dispatch getAllSchedeControlli: ', error.message)
                notify.error(notify.strings.error, notify.strings.errorLoadControlli);
            })
        },

        doAction(action, data) {
            if (!data) {
                console.log("scheda controllo vuota", data);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                return;
            }
            
            let idControllo = null;
            if (data && data.item && data.item.idSchedaControllo)
                idControllo = data.item.idSchedaControllo;
            
            const urlc = endpoints.claimControllo+idControllo;
            const urlu = endpoints.unclaimControllo+idControllo;       

            switch (action) {
                case "createEntity":    //ogni volta che viene cliccato il pulsante nuova Check List
                    if (data.id=='listaControlliAmmFase1A'){
                        this.openModalCreateCheckList1A();
                    } else if(data.id=='listaControlliAmmFase1B'){
                        this.openModalCreateCheckList1B();
                    } else {
                        this.openModalCreateCheckList2();
                    }
                    break;
                case "edit": 
                    this.$router.push({ name: "schedaControlliAmministrativi" , params: { 
                        codiceProgetto: this.$route.params.codiceProgetto,
                        idControllo: idControllo
                    }})
                    break;   
                    //modifica e visualizza si spostano ugualmente di pagina, i permessi sono gestiti dopo
                case "view":
                    this.$router.push({ name: "schedaControlliAmministrativi" , params: { 
                            codiceProgetto: this.$route.params.codiceProgetto,
                            idControllo: idControllo
                        }
                    });
                    break;
                case "claim":
                    this.claimUnclaim(urlc);
                    break;
                case "unclaim":
                    this.claimUnclaim(urlu);
                    break;
                case "start_irr":
                  this.openModalCreateIrregolarita();
                  break;  
                default:
                    console.error("Unknown action!", action);
                    break;
            }
        },

        claimUnclaim(url) {
            //console.log("invoco URL=", url);
            // appena si fa click su claim/unclaim i pulsanti si devono disabilitare
            this.isBusy = true;    
            this.$post(url)
            .then((result) => {
                if (url.includes('unclaim'))
                    console.log("UNCLAIM success", result);
                else 
                    console.log("CLAIM success", result);
                
                //refresh della pagina
                this.loadControls();
            },
            (error) => {
                console.error("ERRORE: ", error.message);
                if (url.includes('unclaim'))
                    notify.error(notify.strings.error, notify.strings.errorUnclaimScheda);
                else
                    notify.error(notify.strings.error, notify.strings.errorClaimScheda);
                throw error;
            });
        },
        /*UNUSED
        loadLastMonitoraggioByCodice() {
            const codice = this.codiceProgetto;
            // get last scheda monitoraggio by progetto
            this.$get(endpoints.lastSchedaMonitoraggio(codice))
            .then((r) => {
                this.monitoraggio = r
            })
            .catch(() => {
                console.log("Nessun monitoraggio per il progetto con codice:", codice)
            });
            this.isBusy = false;
        },
        */
        openModalCreateIrregolarita(){
            this.$bvModal.show('modalNuovaIrregolarita')
        },
        closeModalCreateIrregolarita(){
            this.$bvModal.hide('modalNuovaIrregolarita')
        },
    },
};
</script>
