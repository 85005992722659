<template>
  <div>
    
    <listaCRUD
      v-if="!isBusy"
      :id="'listaDisposizioniProgetto'" 
      :title="'Lista Disposizioni di Pagamento per il Progetto '+this.codiceProgetto"
      :busyTable="isBusy"
      :ruoloAmmesso="'UfficioEconomicoFinanziario-Operatore'"
      :ruoloAmmessoNew="'UfficioContabilitaSpeciale'"
      :elemento="'Disposizione di Pagamento'"
      :mappa="this.tabellone"
      :dinamicIds="dinamicIds"
      :tableIds="tableIds"
      :dataTable="tableData"
      :actions="actionsInRow"
      :actionsMapping="this.buttonLabels"
      :blockButton="isBusy || blockButton"
      :store="'disposizioni'"
      :storeFunctions=this.storeFunctions
      @clickActionList="doAction"
      @doRefresh="caricaDisposizioniProgetto"/>

  </div>
</template>
<script>

import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue"
import listaCRUD from "@/components/listaCRUD.vue"

export default {
  name: "listaDisposizioni",
  components: {
    listaCRUD
  },
  data() {
    return {
      dinamicIds: [
        // "['idScheda']",
        "['content']['disposizione']['riferimentoContratto']",
        "['content']['disposizione']['oggettoContratto']",
        "['content']['disposizione']['soggettoAttuatore']",
        "['content']['disposizione']['riferimentoCircuito']",
        "['content']['disposizione']['importoCircuito']",
        "['content']['disposizione']['dataContiAnnuali']",
        "['content']['disposizione']['numero']",
        "['content']['disposizione']['data']",
        "['content']['disposizione']['oggetto']",
        "['content']['disposizione']['importo']",
        "['content']['disposizione']['pagamenti']"
      ],
      tableIds: [
        //"['idScheda']",
        "['content']['disposizione']['riferimentoContratto']",
        "['content']['disposizione']['oggettoContratto']",
        "['content']['disposizione']['soggettoAttuatore']",
        "['content']['disposizione']['riferimentoCircuito']",
        // "['content']['disposizione']['importoCircuito']",
        "['content']['disposizione']['dataContiAnnuali']",
        "['content']['disposizione']['numero']",
        "['content']['disposizione']['data']",
        //"['content']['disposizione']['oggetto']",
        "['content']['disposizione']['importo']"
        //"['content']['disposizione']['pagamenti']"
      ],
      
      buttonLabels: {
        view: "Visualizza Disposizione Pagamento",
        edit: "Modifica Disposizione Pagamento",
        delete: "Elimina Disposizione Pagamento"
      },
      actionsInRow:[],
      blockButton: false,
      isBusy: true,
      disposizioni: [],
      tableData: [],
      popupC: false,
      popupR: false,
      popupU: false,
      popupD: false,
      codiceProgetto: "",
      role: "",
      modalData: {},
      isIdRegex: /\[.+\]/,
      tabledata: {
        header: [],
        rows: [],
      },
      tabellone:{},
      storeFunctions:{
        getScheda: "getScheda", //get dallo store
        setScheda: "setSchedaDisposizione", //GET
        deleteScheda: "deleteSchedaDisposizione", //DELETE
        updateScheda: "updateSchedaDisposizione", //PUT
        updateInStore: "updateSchedaDisposizioneInStore", //update only in store
        createScheda: "createSchedaDisposizione", //POST
        setAllSchede: "setAllSchede",
        getAllSchede: "getAllSchede",
      }
    }
  },

  mounted() {
    this.codiceProgetto = this.$route.params.codiceProgetto;
    console.log("carico le disposizioni di pagamento per il Progetto ", this.codiceProgetto);
    this.caricaDisposizioniProgetto();
    this.role = this.$getUserRole(); 
    console.log("ruolo=", this.role);
    this.actionsInRow=this.$getActionsListaDisposizioni('listaDisposizioniProgetto')
    console.info("Actions: ", this.actionsInRow)
  },
  
  computed: {
    
    getItems() {
      let items = [];
      for (const obj of this.tabledata.rows) {
        // console.log(obj);
        let item = {
          note: {},
        };
        for (const key in obj) {
          if (!key.match(this.isIdRegex)) {
            item[key] = obj[key];
          } else {
            item[key] = obj[key].value;
            item.note[key] = obj[key].note;
          }
        }
        items.push(item);
      }
      return items;
    },

  },

  methods: {
    // canCreate(){
    //     if (this.role.includes("UfficioEconomicoFinanziario-Operatore"))
    //         return "Nuova Disposizione di Pagamento";      //label del pulsante
    //     else
    //         return "";                              //label vuota significa non posso nemmeno vedere il pulsante (vedi implementazione componente genericList)
    // },

    doAction(action, data) {
      if (!data) {
        console.log("scheda disposizione vuota", data)
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        )
        return
      }
       
      switch (action) {
        case "edit":    //edit su modale dedicata
          this.openEditModal();
          break
        case "view":    //visualizzazione su modale dedicata
          this.openViewModal();
          break
        case "delete":  //cancellazione previo conferma
          this.openDeleteModal();
          break
        case "createEntity":    //creazione nuova disposizione su modale dedicata
          this.openCreationModal();
          break;
        
        default:
          console.error("Unknown action!", action)
          break
      }
    },

    caricaConfigurazione(){
      this.$store.dispatch("configuration/setTabellone", 'disposizioni')
      .then( result => {
        this.tabellone = result;
        // console.log("Configurazione: ", this.tabellone)
        this.isBusy = false
      })
    },

    openCreationModal() {
      this.popupC = true;
      this.$bvModal.show('modalFormCU');
    },

    openViewModal() {
      this.popupR = true;
      this.$bvModal.show('modalFormRD');
    },

    openEditModal() {
      this.popupU = true;
      this.$bvModal.show('modalFormCU');
    },

    openDeleteModal() {
      this.popupD = true;
      this.$bvModal.show('modalFormRD');
    },

    closeModal(){
      this.popupC = false;
      this.popupR = false;
      this.popupU = false;
      this.popupD = false;
      this.$bvModal.hide('modalFormCU');
      this.$bvModal.hide('modalFormRD');
    },

    caricaDisposizioniProgetto: async function() {
      this.isBusy = true
      const url = endpoints.disposizioniProgetto(this.codiceProgetto);
      this.$get(url)
      .then((result) => {
        this.disposizioni = result;
        console.log("Trovate "+result.length+" schede disposizioni pagamento");
        this.extractData(result);
        this.caricaConfigurazione();
      },
      (error) => {
        //this.isBusy = false
          notify.error(
            notify.strings.error,
            notify.strings.internalErrorPleaseReportAction
          )
        console.error("errore caricamento disposizioni pagamento", error);
        this.isBusy = false;
        throw error
      })
    }, 

    extractData(result) {
      this.tableData = [];
      for (let i = 0; i < result.length; i++) {
        let item = result[i];
        //console.log("item=", item);
        this.tableData.push(item);
      }
      
      console.log("Trovate "+this.disposizioni.length+" schede richiesta disposizioni per il progetto "+this.codiceProgetto);
    },

   
  },
}
</script>
