<template>
  <div id="archivio" class="elencoProgetti">
    <div class="table-title">Scheda Monitoraggio - Archivio progetto {{ codiceProgetto }} </div>
    <b-table hover show-empty id="archivioMonitoraggioTable"
              :busy="isBusy"
              primary-key="Periodo"
              :items="tabledata.rows"
              :fields="tabledata.header"
              :sort-by="sortBy"
              sort-desc
              empty-text="Nessun dato trovato">
      <template v-slot:cell(Azioni)="data">
        <b-button variant="outline-primary" :key="x" v-for="x in data.item.actions" size="sm" v-on:click="doAction(x, data.item)">
          <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
          <fa-icon v-if="x==='view'" :icon="['far', 'eye']" class="selector-icons"/>
        </b-button>
      </template>
    </b-table>
  </div>
</template>


<script>
import utils from '@/helpers/utils.js'
import { mapGetters} from 'vuex'
import notify from "@/helpers/notifications.js"

export default {
  name: "ArchivioComponent",
  computed: {
    ...mapGetters({
        getTaskList: 'monitoraggio/getTaskList',
    }),
  },
  data() {
    return {
      isBusy: false,
      archivio: {},
      sortBy: 'idPeriodo',
      codiceProgetto : null,
      tabledata: {
          "header": [
              {
                  key: 'Codice',
                  thClass: 'thst',
              },
              {
                  key: 'Titolo',
                  thClass: 'thst'
              },
              {
                  key: 'Periodo',
                  thClass: 'thst'
              },
              {
                  key:'dataApprovazione',
                  thClass: 'thst',
                  formatter: this.dateFormatter,
              },
              {
                  key: 'Stato',
                  thClass: 'thst'
              },
              {
                  key: 'Azioni',
                  thClass: 'thst'
              },
          ],
          "rows": []
      }
    };
  },

  mounted() {
    this.codiceProgetto = this.$route.params.codiceProgetto;
    this.getArchivioSchede();
  },

  methods: {
    getArchivioSchede: async function() {
        this.isBusy = true;
        //accesso allo store per leggere i progetti
        await this.$store.dispatch('selettore/retrieveArchivioByCodiceProgetto', this.codiceProgetto)
        .then(
            result => {
              this.archivio = result;
            }
        ).catch(
          error => {
            console.error('errore get archivio', error);
            notify.error(notify.strings.error, notify.strings.selettoreMonitoraggioKO);
            this.isBusy = false;
            return;
          }
        );
        this.extractData();
        this.isBusy = false;
    },

    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },

    setAction(scheda) {
      let azioni = [];
      let task = this.getTaskList(scheda.codiceProgetto, scheda.idPeriodo);
      
      if(utils.caniuse('edit', task, scheda, scheda.history) || utils.caniuse('unclaim', task, scheda, scheda.history)) {
          azioni.push('edit');
      }
      else if(utils.caniuse('view', task, scheda, scheda.history)) {
          azioni.push('view');
      }

      return azioni;
    },

    doAction(actionToDo, item) {
      const info = this.archivio[item.idPeriodo];
      switch(actionToDo) {
        
        case 'edit':
        case 'view': // same action for edit and view
          this.$router.push({ name: "schedaMonitoraggio" , params: {codiceProgetto: info.codiceProgetto, idPeriodo: info.idPeriodo}});
          break;
        default:
          console.log("Nothing to do for " + actionToDo);
          break;
      }
    },

    extractData() {
      if(!this.archivio){
        console.log("archivio: Invalid inputData!?", this.archivio);
        return;
      }

      let rows = [];
      const inputData = Object.values(this.archivio);
      //ciclare per l'array di oggetti di tipo "SelettoreSchedaMonitoraggio.java" passato
      for(const item of inputData){
        let row = {
          Codice: item.codiceProgetto,
          Titolo: item.titolo,
          Periodo: item.periodo,
          Stato: item.stato,
          idPeriodo: item.idPeriodo,
          actions: this.setAction(item),
          dataApprovazione: item.dataApprovazione,
          key: item.key,
          history: item.history
        };

        rows.push(row);
      }

      this.tabledata.rows = rows;
    }
  }
};
</script>
