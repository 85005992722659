
import { JSONPath as jpath } from 'jsonpath-plus';
import mapUtils from "@/helpers/mapUtils.js";


function computeDisabled(key, modalContent, mapElement, optionalObj) {
  if(!key || !modalContent) {
    console.error('computeHidden bad params', key, modalContent);
    return false;
  }

  switch(mapElement.config.disabledRule) {
    case 'pagamentiFlagV1': {
      return pagamentiFlagV1(key, modalContent, mapElement, optionalObj);
    }
    default:
      console.log('unknown disabled-rule', mapElement.config.disabledRule);
      return false;
  }
}

function pagamentiFlagV1(key, modalContent, mapElement, optionalObj) {
  if(!key || !modalContent || !modalContent.content[key] || !mapElement)
    return true;
  const rifContrId = "['contratti'][*]['content']['riferimentoContratto']";
  
  if(modalContent.content[rifContrId]) {
    const val = modalContent.content[rifContrId].value;
    // se il pagamento ha un contratto definito che è diverso
    // da quello della dichiarazione di spesa corrente, 
    // allora non si può aggiugnere alla suddetta dichiarazione e
    // quindi deve essere disabilitato
    if(val && val !== mapUtils.nonDefinito.key && optionalObj.cronoprogItemId !== val) {
      return true;
    }
  }

  const idScheda = modalContent.idScheda;
  const value = modalContent.content[key].value;
  // se il pagamento non ha flag, oppure è legato alla dichiarazione di spesa corrente,
  // allora il flag è abilitato e l'utente può decidere se includerlo o meno 
  // nella dichiarazione di spesa corrente, purchè (controllo soprastante)
  // sia un pagamento indiretto oppure legato al contratto oggetto della
  // corrente dichiarazione di spesa
  if(!value || value.toString() === idScheda)
    return false;
  
  return true;
}

function computeHiddenRow(tab, id, mapElement, scheda){

  switch(mapElement.config.hiddenRowRule){
    case 'hideInventarioV1':

      if(tab!==mapElement.config.tab)
        return hideInventarioV1(id, scheda);
      return false;

      default:
        console.error('unknown hidden-rule', mapElement.config.hiddenRule);
        return false;
      }
  }
function hideInventarioV1(id, scheda){

  const results = jpath("$" + id, scheda);

        if(results && results.length>0 && results[0]==true){
          return true;
        }
        
        else return false;
      

}
function computeHidden(key, modalContent, mapElement, optObj) {

  if(!key || (!modalContent && !optObj) || !mapElement) {
    console.error('computeHidden bad params', key, modalContent, mapElement, optObj);
    return false;
  }
 
  

  switch(mapElement.config.hiddenRule) {
    case 'iterAmminV1':
      return iterAmminV1(key, modalContent);
    case 'hideForStandardIndicatoreV1':
      return hideForStandardIndicatoreV1(key, modalContent, optObj);
    case 'alwaysHiddenV1':
      return true;
    default:
      console.error('unknown hidden-rule', mapElement.config.hiddenRule);
      return false;
  }
}

function hideForStandardIndicatoreV1(key, modalContent, optObj) {
  const typeId = "['progetto']['indicatoriObiettivo'][*]['type']";
  let obj;
  if (modalContent){//se siamo su una modale
    if(modalContent.content[typeId])
    {
      obj = modalContent.content[typeId];
    }
    else {
      const indexRegex = /\d+/;
      const indexes = key.match(indexRegex);
      // in questo caso dobbiamo avere un solo indice,
      // modificare se cambia la posizione dell'indicatore nella scheda
      if(indexes && indexes.length === 1) {
        // sostituisco il primo indice all'id del tipo indicatore
        // e controllo se è standard o custom
        // se è standard il campo modalitaDiCalcolo è nascosto
        const index = indexes[0];
        const id = typeId.replace(/\*/, index);
        obj = modalContent.content[id];
        
      }
    }
  } else if (optObj) { //se siamo su TAB invio o altrove, modalContent=null
    obj = {
      value : optObj
    };
  }

  if(obj && obj.value === 'STANDARD')
    return true;
  
  return false;
}

// true = hidden, false = visible
function iterAmminV1(key, modalContent) {
  
  if(!key || !modalContent) {
    console.error('iterAmminV1 bad params', key, modalContent);
    return false;
  }

  const tipoIterPath = "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['tipoIterAmministrativo']"
  // add index numbers to generic tipoIterPath
  const indexes = key.match(mapUtils.indexRegex);
  let tipoIterKey = tipoIterPath;
  if(indexes && indexes.length > 0) {
    for(const index of indexes) {
      tipoIterKey = tipoIterKey.replace('*', index);
    }
  }

  let value;
  if(modalContent.content) {
    value = modalContent.content[tipoIterKey].value;
  }
  else {
    let temp = jpath('$'+tipoIterKey, modalContent);
    if(temp && temp.length > 0)
      value = temp[0];
  }

  if(!value) {
    return true;
  }
  let fieldIds = [];
  switch(value) {
    case "Procedure di appalto":
      fieldIds = [
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['oggetto']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['descrizioneBreve']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['tipologiaProcedura']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['rifNormativo']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['baseAsta']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['stazioneAppaltante']"
      ];
      break;
    case "CONSIP/Accordi Quadro":
      fieldIds = [
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['oggetto']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['descrizioneBreve']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['identificativoConvenzione']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['tipologiaAttivita']"
      ];
      break;
    case "Enti In House":
      fieldIds = [
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['oggetto']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['prestazione']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['enteAccreditato']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['stazioneAppaltante']"
      ];
      break;
    case "Altre modalità attuative":
      fieldIds = [
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['oggetto']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['prestazione']",
        "['progetto']['cronoProgramma'][*]['iterAmministrativo'][*]['stazioneAppaltante']"
      ];
      break;
    default:
      break;
  }
  // replace index numbers of key with stars
  const keyWithStars = key.replace(mapUtils.indexRegex, '*');
  return fieldIds.indexOf(keyWithStars) === -1;
}

export default {
  computeHidden,
  computeDisabled,
  computeHiddenRow
}