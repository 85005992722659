<template>
  <div>
    <b-table-lite thead-class="head"
      :id="idTablePdf"
      :items="this.tabledata.rows"
      :fields="headerTable">
      <template v-slot:cell(azione)="data">
        <b-button :id="namingIdButton(x, data.index + 1)" variant="outline-primary" :key="x" v-for="x in data.value" size="sm" @click="doAction(x, data)">
            <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
        </b-button>
      </template>
    </b-table-lite>
    <b-modal :id="'modalForm1-' + this.name" hide-footer
             size="lg"
             scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content">
      <template v-slot:modal-title>
        <h3>Modifica valore</h3>
      </template>
      <modalForm  :fdata="this.editFormData"
                  :sch="getScheda()"
                  :buttons="this.buttons"
                  :parentName="this.name"
                  @editField="editRiga"/>
    </b-modal>
  </div>
</template>

<script>
  import tools from '@/helpers/tools.js'
  import utils from '@/helpers/utils.js'
  import { mapGetters } from 'vuex'
  import mapUtils from '@/helpers/mapUtils.js'
  import { JSONPath as jpath } from 'jsonpath-plus'
  import modalForm from "@/components/modalForm1.vue"
  import notify from "@/helpers/notifications.js"
  import calculation from "@/helpers/calculations.js";

  export default {
    name: "generale",
    components: {
      modalForm,
    },
    props: {
      idTablePdf: {
        type: String,
        default: ''
      },
      tabName: {
        type: String,
        default: 'Sconosciuto'  //NB: deve essere sempre il padre a stabilire un nome univoco di questo componente nel suo contesto
      },
      name: { type: String },
      // MODULO DI STORE DI RIFERIMENTO 
      store: { type: String, required: true },

      // Intestazione Tabella
      headerTable: { type: Array, required: true },
      // RIFERIMENTO AL PATH DOVE VERRA' SCRITTA LA DataEntryConfiguration
      pathDataEntryConfiguration: { type: String, required: true },

      // PROPS PER IL RECUPERO DINAMICO DELLA SCHEDA
      fnGetter: { type: String, required: true },
      idEntity: {type: Object, required: true },

      // PROPS PER IL RECUPERO DINAMICO DEL TABELLONE
      fnGetterTab: { type: String, required: true },
      rowIds: { type: Array, required: true },

      // RIFERIMENTO AL SETTER DINAMICO DELLA SCHEDA NELLO STORE
      fnSetter: { type: String },
      actions: { type: Array },


      optionalObj: Object,
      doRefresh: Boolean
    },
    watch: {
      tabName: function() {
        if(this.tabName === this.name) {
          this.extractData()
        }
      },
      doRefresh: function() {
        this.extractData();
      }
    },
    data() {
      return {
        buttons: [
          {
            name: 'Modifica',
            action: 'editField',
            show: true,
            param: 'edit'
          },
        ],
        config: null,
        editFormData: {},
        editLine: 0,
        tabledata: {
          rows: []
        }
      }
    },
    computed: {
      ...mapGetters({
        getBeneficiari: 'announcements/getAllBeneficiariMap'
      })
    },
    methods: {
      // Costruzione id per Buttoni (Azioni nel componente)
      namingIdButton(action, index) {
        return this.$builtIdDinamically(this.name, action, index)
      },
      tooltip(value, key, item) {
        if(item.note)
          return {title: 'nota: ' + item.note};
        return {};
      },
      getScheda() {
        let scheda = this.$store.getters[`${this.store}/${this.fnGetter}`](this.idEntity)
        let clonedScheda = tools.genericFunctions.cloneObject(scheda)
        return clonedScheda
      },
      doAction(actionToDo, data) {
        this.editFormData = {content:{}, conf: {}};
        let id = data.item.id
        // get fresh data from storage
        const scheda = this.getScheda();
        const values = jpath('$'+id, scheda[this.pathDataEntryConfiguration]);
        let mapElement = mapUtils.getMapElement(this.config, id);
        const dataEntry = scheda[this.pathDataEntryConfiguration].dataEntryConfiguration;

        if(dataEntry && dataEntry[id]) {
          mapElement.data = tools.genericFunctions.cloneObject(dataEntry[id].data);
        }
        else {
          mapElement.data = this.$getDefaultData();
        }
        let value = {'id': id};
        if(values.length === 0) {
          console.error('valore non trovato in scheda: ', id);
        }
        else {
          value.value = values[0];
        }
        this.editFormData.conf[id] = mapElement;
        this.editFormData.content[id] = tools.genericFunctions.cloneObject(value);
        this.editLine = data.index;
        this.$bvModal.show("modalForm1-" + this.name);
      },
      extractData() {
        // Senza Tabellone non posso andare avanti nella computazione
        if(this.rowIds && !this.config) {
          console.error('GenericViewerCard: Invalid config', this.rowIds, this.config)
          return true
        }

        let updateScheda = false
        let scheda = this.getScheda()
        
        // Senza Scheda non posso andare avanti nella computazione
        if(!scheda) {
          console.error('GenericViewerCard: Invalid scheda', scheda)
          return
        }
        let pathDataEntryConfiguration = scheda[this.pathDataEntryConfiguration]
        this.tabledata.rows = []
        for(const rowId of this.rowIds) {
          let mapElement = this.config[rowId]
          if(!mapElement) {
            console.error('no config, get default one for ', rowId)
            mapElement = this.$getDefaultConfig()
            mapElement.config.path = rowId
          }
          let result
          // GESTIONE DI CASI DI CAMPI CALCOLATI A RUNTIME
          if (mapElement.config.calculationRule) {
            result = calculation.calculateValue(mapElement, pathDataEntryConfiguration, rowId, null, this.optionalObj);
            const retVal = mapUtils.createElementFromScratch(rowId, pathDataEntryConfiguration, this.config);
            retVal.parent[retVal.fieldName] = result;
            pathDataEntryConfiguration = retVal.scheda;
            updateScheda = true;
          } else if(mapElement.config.type === 'enum' && mapElement.config.enumRule) {
              mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda);
              const value = jpath('$'+rowId, pathDataEntryConfiguration)[0];
              if(value)
                result = value
          }
          else {
            // GESTIONE DI CASI DI CAMPI RECUPERATI DALLA SCHEDA
            //const results = jpath('$'+rowId, scheda); 
            //invece che nella scheda cerco nel content
            const results = jpath('$'+rowId, pathDataEntryConfiguration);
            if(results.length === 0) {
              // CASO DI CAMPO NON TROVATO NELLA SCHEDA
              const retVal = mapUtils.createElementFromScratch(rowId, pathDataEntryConfiguration, this.config);
              pathDataEntryConfiguration = retVal.scheda;
              result = retVal.object;
              updateScheda = true;
            }
            else if (results.length === 1) {
              // CASO DI CAMPO TROVATO NELLA SCHEDA
              result = results[0];
            }
            else if (results.length > 1) {
              // CASO DI CAMPO TROVATO NELLA SCHEDA DI TIPO ARRAY: Concateno tutti gli elementi dell'array in una stringa
              result = this.concatenateArrayInString(results);
            } else {
              console.error(this.name, 'Undefined element is an array?', rowId, results);
            }
          }
          let row = this.setupRow(rowId, result)
          
          if(!mapElement.config.readonly && (mapElement.config.tab === this.name || this.$parent.$parent.$parent.$parent.title) ) {
            if(this.actions.indexOf('edit') > -1) {
              if(mapElement.config.editCustom && mapElement.config.editCustom.length > 0) {
                let role = this.$getUserInfo().roles[0]
                row.azione = mapElement.config.editCustom.includes(role) ? ['edit'] : []
              }
              else
                row.azione = ['edit']
            }
          }
          this.tabledata.rows.push(row)
        }
        //console.log(scheda)
        if(updateScheda) {
          scheda = this.setContent(scheda, pathDataEntryConfiguration)
          this.updateSchedaInStore(scheda)
        }
      },
      editRiga(actionFromModal) {
        this.$bvModal.hide('modalForm1-' + this.name);
        let clonedScheda = this.getScheda();
        let config = clonedScheda[this.pathDataEntryConfiguration].dataEntryConfiguration;
        if(!config) {
          config = {};
        }
        for(let [key, field] of Object.entries(actionFromModal.content)) {

          const mapElement = mapUtils.getMapElement(this.config, key);
          // ritorno al valore non formattato (ad esempio in caso di currency)
          let updatedValue = utils.unformatOutput(mapElement, field.value);
          if(updatedValue == undefined) {
            // nessuna azione se il nuovo valore è undefined
            continue;
          }
          let updatedConfiguration = actionFromModal.conf[key];
          const jPathResult = jpath({resultType: 'all'}, '$'+key, clonedScheda[this.pathDataEntryConfiguration])[0];
          const parent = jPathResult ? jPathResult.parent : undefined;

          if(parent == undefined) {
            console.log('error: should exist', key, jPathResult);
            notify.error(notify.strings.error, notify.strings.operationError('Modifica di ' + updatedConfiguration.config.label));
            continue;
          }
          let fieldToUpdate = jPathResult.parentProperty;
          // let fieldToUpdate = jpath('$'+key+'~', clonedScheda)[0];

          // update configuration
          if(!config[key]) {
            config[key] = {};
          }
          if(parent[fieldToUpdate] !== updatedValue && 
              !((parent[fieldToUpdate] == null || parent[fieldToUpdate] == undefined) && updatedValue === '')
          ){ // se il campo è stato modificato (tranne se settiamo a stringa vuota un campo null o undefined)
            // allora controllo la configurazione
            //if(!updatedConfiguration.data.edited) { // se è la prima modifica setto il flag edited e salvo il valore originale
              

              updatedConfiguration.data.edited = true; 
              updatedConfiguration.data.oldValue = parent[fieldToUpdate];
              
            /*TODO: migliorare questo controllo, al momento non funziona bene su progetti appena creati...
            } else {

              if(updatedValue == updatedConfiguration.data.oldValue || // se è stato riportato il valore iniziale (anche se nullo)
                ((updatedConfiguration.data.oldValue == null || updatedConfiguration.data.oldValue == undefined) && updatedValue === '')
              ) {
                // se il valore era già stato modificato
                updatedConfiguration.data.oldValue = null;              // e stiamo riportando il valore all'originale
                updatedConfiguration.data.edited = false;          // ripristino i valori all'originale e cancello la nota
                updatedConfiguration.data.note = '';                // warning: solo == e non === altrimenti problemi di typeof
              }
            }
            */

            parent[fieldToUpdate] = updatedValue; // setto il nuovo valore
            // if(updatedConfiguration.config.canBeSubstancial) {
            //   config[key].data = updatedConfiguration.data; // configurazione prima del controllo substancial
            //   updatedConfiguration.data.isSubstancial = validation.substancialValidationProgetto(key, clonedScheda, this.mappa, null);
            // }

            config[key].data = updatedConfiguration.data; // configurazione aggiornata
          }
          else {
            if(!config[key].data) {
              //console.log('create conf.data for ', key);
              config[key].data = {}
            }
            // aggiorno comunque la nota se il campo è modificato o aggiunto
            // Aggiornamento della nota indipendentemente se ho modificato il campo
            //if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
            config[key].data.note = updatedConfiguration.data.note;
          }
        }
        clonedScheda[this.pathDataEntryConfiguration].dataEntryConfiguration = config;
        clonedScheda = this.setContent(clonedScheda, clonedScheda[this.pathDataEntryConfiguration])
        this.updateSchedaInStore(clonedScheda)
        
        // update displayed data
        //invece che aggiornare una sola riga del dato ricarico tutto per far funzionare le computed
        this.extractData();
      },
      setupRow(rowId, value) {
        let mapElement = mapUtils.getMapElement(this.config, rowId);
        if(!mapElement) {
          console.error('no config, get default one for ', rowId);
          mapElement = this.$getDefaultConfig();
          mapElement.config.path = rowId;
        }

        const config = mapElement.config
        const id = config.path
        const formatter = config.type
        let formattedVal
        if(id && formatter && formatter!== '') {
          formattedVal = (value != undefined && value != null) ? this.formatter(value, formatter, mapElement) : null
        }

        const scheda = this.getScheda();
        const dataMap = scheda[this.pathDataEntryConfiguration].dataEntryConfiguration;
        let data = {};
        if(dataMap && dataMap[rowId] && dataMap[rowId].data)
          data = dataMap[rowId].data;
        let row = {
          id: rowId,
          campo: mapElement.config.label,
          descrizione: formattedVal,
          _cellVariants: {},
          note: dataMap && dataMap[rowId] && dataMap[rowId].data ? data.note : null
        };

        if (data.note) //il settaggio di una nota è indipendente dal fatto che il campo è stato editato o meno
          row.note = data.note;
        return row;
      },
      updateSchedaInStore(scheda) {
        let payloadSetter = Object.assign(this.idEntity, { content: scheda })
        this.$store.dispatch(`${this.store}/${this.fnSetter}`, payloadSetter)
      },
      setContent(scheda, content) {
        scheda[this.pathDataEntryConfiguration] = content
        return scheda
      },

      // E' una funzione che chiama un formatter dinamicamente
      formatter(valueCell, formatter, mapElement) {
        switch(formatter) {
          case 'beneficiario':
            valueCell = this[formatter](valueCell, mapElement)
            break;
          default:
            valueCell = utils.formatOutput(mapElement, valueCell)
        }
        return valueCell;
      },

      // ---- FORMATTERS ----
      concatenateArrayInString(array) {
        // crea un array con soli obiettivi specifici distinti
        const newArray = array.filter((x, i, a) => a.indexOf(x) === i);
        return newArray.toString().replace(',', ', ');
      },

      stringarray(array, mapElement) {
        // crea un array con soli obiettivi specifici distinti
        if(!Array.isArray(array)) {
          return this.string(array, mapElement)
        }
        const newArray = array.filter((x, i, a) => a.indexOf(x) === i);
        return newArray.toString().replace(',', ', ');
      },
      
      beneficiario(idBeneficiario) {
        let mappaBenef = this.getBeneficiari;
        let descBeneficiario = "sconosciuto"
        if(mappaBenef[idBeneficiario]) {
            descBeneficiario = mappaBenef[idBeneficiario].denominazione 
        } else {
          console.error("GeneralViewCard.vue: "+idBeneficiario+" non trovato nella mappa dei Beneficiari disponibili ", mappaBenef);
        }
        return descBeneficiario
      }
      
      // ---- FORMATTERS ----
    },
    created() {
      // Recupero dinamico del tabellone
      this.config = this.$store.getters[`${this.store}/${this.fnGetterTab}`]
      if(this.config) {
        this.extractData()
      }
      else
        console.log(this.name, 'error in mappa', this.config)
    }
}
</script>

