<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body" />{{questionText}}
          </div>
          <div class="modal-body">
            <slot name="body" />
            <h3>
              {{contentText}}
            </h3>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <b-button variant="success" class="modal-default-button" @click="$emit('confirm')">
                Conferma
              </b-button>
              <b-button variant="danger" class="modal-default-button" @click="$emit('close')">
                Annulla
              </b-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>
  
<script>
export default {
  name: "modal",
  props: {
    questionText:
    {
      type: String,
      default: function() {
        return ''
      }
    },
    contentText: { type: String, default: () => '' }
  },
  data() {
    return {      
    };
  },
}
</script>
