<template>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <slot name="header"/>
                  <h4>Selezionare Strumento Finanziario</h4>
              </div>
              <table class="modal-table">
                <tr>
                  <td class="modal-field"><h4>Modalità Selezione</h4></td>
                  <td class="modal-field">
                    <b-form-select
                      id="select"
                      v-model="toolSelected"
                      :options="financialTools"
                      required>
                    </b-form-select>
                  </td>
                </tr>
                </table>
                <div class="modal-footer">
                  <slot name="footer">
                    <b-button 
                      @click="createNewExecutingBody()" 
                      variant="success" 
                      class="modal-default-button"
                      :disabled="blockButton"
                      >
                      Crea Nuova Scheda Executing Body
                    </b-button>
                    <b-button variant="danger" 
                      class="modal-default-button" 
                      @click="closeModal"
                      :disabled="blockButton"
                      >
                      Chiudi
                    </b-button>
                  </slot>
                </div>   
            </div>  
        </div>
      </div>           
     </transition>
</template>

<script>
import notify from "@/helpers/notifications.js";
export default {
  name: "selectFinancialTool",
  data() {
    return {
        toolSelected: null,
        blockButton: false,
        financialTools: [
        {
          text: "ISF1",
          value: "ISF1"
        },
        {
          text: "ISF2",
          value: "ISF2"
        },
      ],
    }
  },
  mounted() {
    this.toolSelected = this.financialTools[0].value
  },
  methods: {
    closeModal() {
      this.$emit('executingCloseModalCreate')
    },
    createNewExecutingBody() {
        let financialToolSelected = {strumentoFinanziario : this.toolSelected}
        this.$store.dispatch("executingBody/createSchedaExecutingByList", financialToolSelected).then(
          (result) => {
              this.$router.push({
                  name: 'schedaExecuting',
                  params: { idExecutingBody: result.idProgetto },
              });
              
              return result;
          })
          .catch(
              (error) => {
              this.blockButton = false;
              this.closeModal();
              console.error('selectTypeBando error:', error.message);
              notify.error(notify.strings.error, notify.strings.errorCreateNewProgetto)
            }
          )
    }
  }
};
</script>
