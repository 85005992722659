import Vue from "vue";
import endpoints from "@/components/endpoints.vue";

const state = {
    listaConvenzione: [],
    schedeConvenzione: {}
};

const getters = {
    getListaConvenzione(state){
        return state.listaConvenzione;
    },
    getScheda(state){
        return (payload) => {
            if(payload &&
                payload.idBando &&
                state.schedeConvenzione[payload.idBando]){
                    return state.schedeConvenzione[payload.idBando]
                }
                return null
        }
    },
    getSchedaConvenzione(state){
        return (payload) => {
            if(payload &&
                payload.idBando &&
                state.schedeConvenzione[payload.idBando])
                    return state.schedeConvenzione[payload.idBando].schedaConvenzione
                else{
                    console.error('getSchedaConvenzione payload error', payload)
                    return null
                    }
        }
    }
}

const actions = {
    pushSchedaInListConvenzione(context, payload){
        context.commit("UPDATE_LISTA_CONVENZIONE", payload)
    },
    setSchedaConvenzione(context, payload){
        const infoAllegati = {
            allegatiDisponibili: {
                graduatoria: 0,
            },
            allegatiMancanti: {
                graduatoria: [],
            },
            /*
            graduatoria: [
                {
                    type: "Decreto Approvazione Graduatoria",
                    mandatory: false,
                    recommended: false,
                    task: "Progetto_graduatoria_UfficioGestione-Approvatore",
                    group:'MINT'
                },
                {
                    type: "Decreto di ammissione a finanziamento",
                    mandatory: false,
                    recommended: false,
                    task: "Progetto_graduatoria_UfficioGestione-Approvatore",
                    group:'MINT'
                },
                {
                    type: "Decreto Approvazione Graduatoria - Firmato",
                    mandatory: false,
                    recommended: false,
                    task: "Progetto_graduatoria_AutoritaResponsabile-AutoritaResponsabile",
                },
                {
                    type: "Decreto di ammissione a finanziamento - Firmato",
                    mandatory: false,
                    recommended: false,
                    task: "Progetto_firmeAR_AutoritaResponsabile-AutoritaResponsabile"
                },
                {
                    type: "Documento di stipula convenzione",
                    mandatory: false,
                    recommended: false,
                    task: "Progetto_invioDocGraduatoria_UfficioGestione-Operatore"
                },
                {
                    type: "Informativa obblighi di pubblicita",
                    mandatory: false,
                    recommended: false,
                    task: "Progetto_invioDocGraduatoria_UfficioGestione-Operatore"
                },                 
                { type: "Altro", mandatory: false, recommended: false },
                { 
                    type: "Appunto",
                    mandatory: false,
                    recommended: false,
                    task: "Progetto_graduatoria_UfficioGestione-Approvatore" 
                },
            ]*/
        }
        if(payload.tabelloneAllegati) {
            infoAllegati.graduatoria = payload.tabelloneAllegati.graduatoria;
        }
        const url = endpoints.getGraduatoria(payload.idBando)
        return Vue.prototype.$get(url).then(
            (result) => {
                result.infoAllegati = infoAllegati
                let newPayload = {
                    scheda: result,
                    idBando: payload.idBando
                }
                context.commit("SET_SCHEDA", newPayload)
                return result
                },
                (error) => {
                    console.error('setSchedaConvenzione error', error.response)
                    throw error
            }
        )
    },
    updateScheda(context, payload){
        context.commit("SET_SCHEDA", payload);
    },
    setAvailableFiles(context, payload) {
        context.commit("UPDATE_ALLEGATI_DISPONIBILI", payload);
    },
    setMandatoryMissed(context, payload) {
        context.commit("UPDATE_ALLEGATI_MANCANTI", payload);
    },
    saveSchedaConvenzione(context, payload){
        const scheda = payload.schedaConvenzione
        const schedaConvenzione = scheda.schedaConvenzione
        //console.log(scheda)
        // Due casistiche possibili
        // 1)   Salvataggio in Bozza (endpoint)
        let draftSchedaConvenzione = endpoints.save_draft_schedaConvenzione(schedaConvenzione.idBando);
        // 2)   Salvataggio Completo (endpoint) (Uso la complete di Bando usando idBando)
        let completeSchedaConvenzione = endpoints.complete_schedaBando;

        console.log("URL:",draftSchedaConvenzione)
        return Vue.prototype.$put(draftSchedaConvenzione, schedaConvenzione).then(
            (responseDraft) => {
                console.log("Salva in bozza ok")
                //Se voglio fare anche la Complete
                if(payload.salvataggio === "complete" || payload.salvataggio === "reject"){
                    let bodyForPost = {
                        idScheda: schedaConvenzione.idBando,
                        processId: schedaConvenzione.processIdGraduatoria,
                        titoloBando: schedaConvenzione.idBando,
                        richiestoParereICT: false,                       
                        esitoOk: payload.salvataggio === 'complete' ? true : false,
                    }
                    console.log("BpmRequest:",bodyForPost)

                    return Vue.prototype.$post(completeSchedaConvenzione, bodyForPost).then(
                        (responseComplete) => {
                            console.log("Complete ok", responseComplete)
                            return responseComplete
                        },
                        (error) => {
                            console.error("Errore Salva e Invia:", error.message)
                        }
                    )

                }
                return responseDraft
            },
            (error) => {
                console.error("Errore in Salvataggio in Bozza:", error.message)
                throw error
            }
        )
    },
    clear(context, state){
        context.commit("RESET", state)
    },        
    updateStatoProgettoAmmesso(context, payload){
        if (payload && payload.idBando && payload.stato && payload.idProgetto){
            context.commit("SET_STATO_PROGETTO", payload);
        }
    },
}

const mutations = {
    SET_SCHEDA(state, payload){
        if(payload && payload.idBando && payload.scheda){
            state.schedeConvenzione[payload.idBando] = payload.scheda
        }
        else
            console.error("SET_SCHEDA no key or scheda", payload)

    },
    UPDATE_ALLEGATI_DISPONIBILI(state, payload){
        if(payload.obj.idBando &&
            state.schedeConvenzione[payload.obj.idBando] &&
            state.schedeConvenzione[payload.obj.idBando].infoAllegati.allegatiDisponibili[payload.obj.entity] !== undefined) 
                state.schedeConvenzione[payload.obj.idBando].infoAllegati.allegatiDisponibili[payload.obj.entity] = payload.obj.numeroAllegati
            else
                console.error("UPDATE_ALLEGATI_DISPONIBILI Error: Missing paylod or scheda")
    },
    UPDATE_ALLEGATI_MANCANTI(state, payload){
        if(payload.obj.idBando &&
            state.schedeConvenzione[payload.obj.idBando] &&
            state.schedeConvenzione[payload.obj.idBando].infoAllegati.allegatiMancanti[payload.obj.entity])
                state.schedeConvenzione[payload.obj.idBando].infoAllegati.allegatiMancanti[payload.obj.entity] = payload.obj.files
            else
                console.error("UPDATE_ALLEGATI_MANCANTI Error: Missing paylod or scheda")    
    },
    RESET(state){
        state.listaConvenzione = {}
        state.schedeConvenzione = {}
    } ,
    SET_STATO_PROGETTO(state, payload){
        let p = state.schedeConvenzione[payload.idBando].schedaConvenzione.progetti.filter(
                    (q) => q.idProgetto === payload.idProgetto
                );
        // verifico che solo 1 elemento corrisponda alla ricerca
        if (p.length == 1) {
            p[0].ammesso = payload.stato;
        }
    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};