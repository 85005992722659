<template>
  <div>
    <b-form inline class="m-1" style="display: flex;">
      <label for="filterProgettiInput"
        ><h4 class="mx-2">Filtra i risultati:</h4></label
      >
      <b-form-input
        v-model="filter"
        type="search"
        id="filterProgettiInput"
        placeholder="Digita un filtro"
      />
      <b-button
        id="filterProgettiButton"
        class="mx-1"
        :disabled="!filter"
        @click="filter = ''"
        >Cancella</b-button
      >
      
    </b-form>
     <div class="card">
        <div class="card-body text-right">
          <h1 class="font-weight-bold">Totale Importi: {{ totaleSpeseEuro }}</h1>
        </div>
      </div>
    <b-table
      :busy="busyLPA"
      primary-key="id"
      emptyText="Nessun dato disponibile"
      :sort-by="sortBy"
      sort-desc
      :filter="filter"
      emptyFilteredText="Nessun dato disponibile"
      :filter-included-fields="getFilterFields()"
      :items="tabledata.rows"
      :fields="tabledata.header"
      show-empty
      hover
      responsive
    >
      <template v-slot:table-busy>
        <div class="text-center">
          <strong>Lista in Caricamento...</strong>
        </div>
      </template>
      <template v-slot:cell(Azioni)="data">
        <b-button
          :id="namingIdIcon(x, data.index + 1)"
          :disabled="clicked"
          variant="outline-primary"
          size="sm"
          :key="x"
          :title="mappingTooltip(x)"
          v-for="x in data.value"
          @click="doAction(x, data)"
        >
          <fa-icon
            v-if="x === 'start_sovvenzione_process'"
            :icon="['far', 'hand-paper']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'start_convenzione_process'"
            :icon="['far', 'hand-paper']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'view'"
            :icon="['far', 'eye']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'claim'"
            :icon="['far', 'hand-paper']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'unclaim'"
            :icon="['fas', 'times-circle']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'edit_in_list_progetti'"
            :icon="['far', 'edit']"
            class="selector-icons"
          />
          <fa-icon
            v-if="x === 'edit_in_list_sovvenzione'"
            :icon="['far', 'edit']"
            class="selector-icons"
          />
        </b-button>
      </template>
    </b-table>
    
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js";
import endpoints from "@/components/endpoints.vue";

export default {
  name: "listaProgettiAmmessiAM",
  components: {
  },
  props: [
    'listaProgettiAmmessi',
    'progettiInCallForProposal',
    'busyLPA'
  ],
  data() {
    return {
      // filtra solo quelli in assegnazione diretta
      clicked: false,
      actions: [
        "view", "claim", "unclaim", "start_convenzione_process", "edit_in_list_progetti",
        "start_sovvenzione_process", "edit_in_list_sovvenzione",
        ],
      sortBy: "dataInserimento",
      totaleSpeseEuro: "€ 0,00",
      filter: null,
      tabledata: {
        header: [
          {
            key: "codiceProgetto",
            sortable: true,
          },
          {
            key: "titoloProgetto",
            label: "Titolo",
            sortable: true,
          },
          {
            key:"beneficiario",
            sortable: true,
            formatter:"beneficiarioFormatter",
            label:"Beneficiario"
          },
          {
            key: "dataInserimento",
            sortable: true,
            filterByFormatted: true,
            formatter: this.dateTimeFormatter,
            label: "Data",
          },
          {
            key:"dataFineProgetto",
            label:"Data Fine Progetto",
            filterByFormatted: true,
            formatter: this.dateFormatter,
            sortable:true
          },
          {
            // 26-08-21: modificato budgetMassimo->costoProgetto
            key: "costoProgetto",
            label: "Costo progetto",
            formatter: this.currencyFormatter,
            sortable: true,
          },
          {
            key: "strumentoFinanziario",
            sortable: true,
          },
          {
            key: "stato",
            sortable: true,
          },
          {
            key: "Azioni",
            thClass: "thst",
          },
        ],
        rows: [],
      },
      listaProgetti: []
    };
  },
   computed: {
    ...mapGetters({
      getBeneficiari: "announcements/getAllBeneficiariMap",
    }),
   },
  mounted() {
    // uso questo componente per elencare progetti in call for proposal
    // in questo caso, inserisco anche l'header idBando tra le colonne.
    if (this.progettiInCallForProposal) {
      this.tabledata.header.splice(0,0,{
            key: "idBando",
            label: "ID Bando",
          }) 
    }
    if(this.progettiInCallForProposal || this.listaProgettiAmmessi){
          let userIdGroup = this.$getUserGroupId();
          if(userIdGroup.includes("Beneficiari")){
            for(const col in this.tabledata.header){
              if(this.tabledata.header[col].key.match("beneficiario")){
                this.tabledata.header.splice(col, 1);
              }
            }
          }
        }
    this.extractData();
  },
  watch: {
    listaProgettiAmmessi: function() {
      this.extractData()
    }
  },
  methods: {
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },
    dateTimeFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    currencyFormatter(value) {
      if (value == undefined || value == null) return null;
      return utils.formatCurrency(value);
    },
    beneficiarioFormatter(value) {
      if (!value) 
      return "Sconosciuto";
      return utils.formatBeneficiari(value);
    },
    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      let idProgetto = data.item.idSchedaProgetto;
      
      switch (action) {
        case "start_sovvenzione_process":
          if(data && data.item){
            let urlAvvioProcesso = endpoints.startProgettoSovvenzione
            let requestBody={
              'idScheda' : idProgetto,
            }
            return this.$post(urlAvvioProcesso, requestBody)
              .then(
                (resp) => {
                  console.log("Processo di sovvenzione per il progetto " + idProgetto + " avviato correttamente")
                  let processId = resp.id
                  if (processId!=null && processId!=undefined){
                    //Setto la scheda aggiornata nello store
                    this.$store.dispatch(
                      "progetto/setSchedaProgetto",
                      { idSchedaProgetto: idProgetto }
                    ).then(result => {
                      if(result && result.schedaProgetto && result.taskInfo){
                            //Effettuo il claim automatico e aggiorno la tabella
                              this.updateTableAfterAction(data, 'claim', idProgetto)
                          }
                    }).catch(
                      (error) => {
                          console.error('Errore aggiornamento scheda nello store:', error.message);
                          notify.error(notify.strings.error, notify.strings.errorStartProcessoSovvenzione);
                          throw error;                        
                      }
                    )
                  }
                }
              )
              .catch(
                  (error) => {
                      console.error('Start Processo di sovvenzione process error:', error.message);
                      notify.error(notify.strings.error, notify.strings.errorStartProcessoSovvenzione);
                      throw error;
                      
                  }
              )
          } else {
            console.error("Impossibile avviare il processo di convenzione per il progetto = ", idProgetto);
          }
          break;
        case "start_convenzione_process":
          if (data && data.item && data.item.stato==='Ammesso'){
            
            let urlAvvioProcesso = endpoints.startProgettoConvenzione;
            let myBody = { 
              'idScheda': idProgetto
            };

            return this.$post(urlAvvioProcesso, myBody)
                .then(
                    (resp) => {
                        console.log("Processo di Convenzione per il Progetto "+idProgetto+" avviato correttamente, response=", resp);
                        let processId = resp.id;
                        if (processId!=null && processId!=undefined){
                          this.updateTableAfterAction(data, 'claim', idProgetto)
                        }
                        
                    }
                )
                .catch(
                    (error) => {
                        console.error('Start ProgettoConvenzione process error:', error.message);
                        notify.error(notify.strings.error, notify.strings.errorStartProgettoConvenzione);
                        throw error;
                        
                    }
                )

          } else {
            console.error("Impossibile avviare il processo di convenzione per progetti in stato=", data.item.stato);
          }
          break;
        case "view":
          this.$router.push({
            name: "schedaConvenzione",
            params: { idProgetto: idProgetto },
          });
          break;
        case "claim":
          this.updateTableAfterAction(data, 'claim', idProgetto)
          break;
        case "unclaim":
          this.updateTableAfterAction(data, 'unclaim', idProgetto)
          break;
        case "edit_in_list_progetti":
        case "edit_in_list_sovvenzione":
          this.$router.push({
            name: "schedaConvenzione",
            params: { idProgetto: idProgetto },
          });
          break;
        default:
          console.error("Unknown action!", action);
          break;
      }
    },

    extractData: function() {
        if(!this.listaProgettiAmmessi || this.listaProgettiAmmessi && this.listaProgettiAmmessi.length === 0) {
          return;
        }
        let totaleSpese=0;
        this.listaProgettiAmmessi.forEach(
          (element) => {
              //setto le azioni
              const info = element.infoProgetto;
              const obj = parseFloat(info.costoProgetto);
              const azioni = this.$getActionsPerProgettoConvenzione(element);
              const localActions = azioni.filter((item) => {
                return this.actions.indexOf(item) != -1;
              });
              let newRow = {
                //modalitaDiAccesso: info.modalitaDiAccesso,
                titoloProgetto: info.titoloProgetto,
                dataInserimento: info.dataInserimento,
                dataFineProgetto: info.dataConclusione,
                // 26-08-21: modificato budgetMassimo->costoProgetto
                costoProgetto: info.costoProgetto,
                strumentoFinanziario: info.strumentoFinanziario,
                stato: info.stato,
                Azioni: localActions,
                idSchedaProgetto: info.idProgetto,
                codiceProgetto: info.codiceProgetto,
                beneficiario: info.idBeneficiario
              };
              // uso questo componente per elencare progetti in call for proposal
              // in questo caso, dato che inserisco anche l'header idBando, devo
              // popolare la key in newRow
              if (this.progettiInCallForProposal){
                newRow.idBando = info.idBando
              }
              if (azioni.length > 0) {
                if(!isNaN(obj)){
                  totaleSpese = totaleSpese + obj;
                }
                this.tabledata.rows.push(newRow);
              } else {
                console.log("NO actions, skip element=", element);
              }
      });
        this.totaleSpeseEuro = utils.formatCurrency(totaleSpese);

      
    },
        /**
     * @param {string} data: table data
     * @param {string} taskAction: claim/unclaim
     * @param {string} idProgetto: id del Progetto da aggiornare nella tabella
     */
    updateTableAfterAction(data, taskAction, idProgetto){
      this.clicked = true
      let refToStore = ""
      switch (taskAction) {
        case 'claim':
          refToStore = "progetto/claimSchedaProgetto"
          break;
        case 'unclaim':
          refToStore = "progetto/unclaimSchedaProgetto"
          break;     
        default:
          console.error('Azione non gestita updateTableAfterAction:', taskAction);  
          break;
      }
      //Effettuo il claim/unclaim
      this.$store.dispatch(
        refToStore, {
          idProgetto: idProgetto,
        })
        .then(
          (resp) => {
            //Aggiorno l'elemento della tabella con le azioni nuove
            const azioni = this.$getActionsPerProgettoConvenzione(resp)
            const updateActions = azioni.filter((item) => {
              return this.actions.indexOf(item) != -1;
            });
            data.item.Azioni = updateActions
            this.clicked = false            
          },
          (error) => {
              notify.error(
              notify.strings.error,
              notify.strings.internalErrorPleaseReportAction
            );
            this.clicked = false
            console.error(taskAction," error", error);
          }
        )
    },
    mappingTooltip(keyMapping) {
      let objMapping = {
        start_convenzione_process: "Avvia processo di stipula Convenzione",
        edit_in_list_progetti: "Modifica Dati progetto",
        view: "Visualizza Dati Progetto",
        claim: "Presa in carico",
        unclaim: "Rilascio presa in carico",
        edit_in_list_sovvenzione: "Elabora progetto",
      };
      let temp = objMapping[keyMapping];
      if (!temp) return "";
      return temp;
    },
    getFilterFields() {
      let filterFields = [];
      for (let item of this.tabledata.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
  },
};
</script>
<style scoped lang="scss"></style>
