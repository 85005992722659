<template>
  <div>
    <h1 class="text-center">Lista Progetti</h1>
    <b-form inline class="m-1" style="display: flex;">
      <label for="filterProgettiInput"
        ><h4 class="mx-2">Filtra i risultati:</h4></label
      >
      <b-form-input
        v-model="filter"
        type="search"
        id="filterProgettiInput"
        placeholder="Digita un filtro"
      />
      <b-button
        id="filterProgettiButton"
        class="mx-1"
        :disabled="!filter"
        @click="filter = ''"
        >Cancella</b-button
      >
    
    </b-form>
    <div class="card">
        <div class="card-body text-right">
          <h1 class="font-weight-bold">Totale Importi: {{ totaleSpeseEuro }}</h1>
        </div>
      </div>
    <b-table
      :busy="isBusy"
      primary-key="id"
      emptyText="Nessun dato disponibile"
      :sort-by="sortBy"
      sort-asc
      :filter="filter"
      emptyFilteredText="Nessun dato disponibile"
      :filter-included-fields="getFilterFields()"
      :items="tabledata.rows"
      :fields="tabledata.header"
      show-empty
      hover
      responsive
    >
      <template v-slot:table-busy>
        <div class="text-center">
          <strong>Lista in Caricamento...</strong>
        </div>
      </template>
      <template v-slot:cell(Azioni)="data">
        <b-button
          :id="namingIdIcon(x, data.index + 1)"
          variant="outline-primary"
          size="sm"
          :key="x"
          :title="mappingTooltip(x)"
          v-for="x in data.value"
          @click="doAction(x, data)"
        >
          
          <fa-icon
            v-if="x === 'view'"
            :icon="['far', 'eye']"
            class="selector-icons"
          />
          
        </b-button>
      </template>
    </b-table>
    
  </div>
</template>
<script>

import utils from "@/helpers/utils.js";
import notify from "@/helpers/notifications.js";
import endpoints from "@/components/endpoints.vue";

export default {
  name: "listaProgetti",
  components: {},
  data() {
    return {
      totaleSpeseEuro: "€ 0,00",
      actions: ["view"],
      sortBy: "codiceProgetto",
      filter: null,
      isBusy: false,
      tabledata: {
        header: [
          {
            key: "codiceProgetto",
            label: "Codice Progetto",
            sortable: true,
          },
          {
            key: "modalitaDiAccesso",
            label: "Modalità",
            sortable: true,
          },
          {
            key: "titoloProgetto",
            label: "Titolo",
            sortable: true,
          },
          {
            key: "dataInserimento",
            sortable: true,
            filterByFormatted: true,
            formatter: this.dateTimeFormatter,
            label: "Data Inserimento",
          },
          {
            key:"dataFineProgetto",
            label:"Data Fine Progetto",
            filterByFormatted: true,
            formatter: this.dateFormatter,
            sortable: true
          },
          {
            // 26-08-21: modificato budgetMassimo->costoProgetto
            key: "costoProgetto",
            label: "Costo progetto",
            formatter: this.currencyFormatter,
            sortable: true,
          },
          {
            key: "strumentoFinanziario",
            sortable: true,
          },
          {
            key: "stato",
            sortable: true,
          },
          {
            key: "Azioni",
            thClass: "thst",
          },
        ],
        rows:[],
      },
      listaProgetti: []
    };
  },
  mounted() {
    this.extractData();
  },
  methods: {
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },  
    dateTimeFormatter(value) {
      if (!value) return null;
      return utils.formatDateTime(value);
    },
    beneficiarioFormatter(value) {
      if (!value) 
      return "Sconosciuto";
      return utils.formatBeneficiari(value);
    },
    currencyFormatter(value) {
      if (value == undefined || value == null) return null;
      return utils.formatCurrency(value);
    },
    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      const idProgetto = data.item.idSchedaProgetto;
      switch (action) {
        case "view":
          this.$router.push({
            name: "schedaProgetto",
            params: { idProgetto: idProgetto },
          });
          break;
        
        default:
          console.error("Unknown action!", action);
          break;
      }
    },
    getProgettoIndex(schedaRow) {
      return this.tabledata.rows.map( schedaProgetto => {
        return schedaProgetto.idSchedaProgetto;
      }).indexOf(schedaRow.idSchedaProgetto);
    },
    extractData: async function() {
      let userIdGroup = this.$getUserGroupId();
      if(!userIdGroup.includes("Beneficiari")){
        this.tabledata.header.splice(3, 0,{key:"beneficiario", sortable: true, formatter:"beneficiarioFormatter", sortByFormatted: true});
      } 
      this.isBusy = true;
      const url = endpoints.retrieveAllSchedeProgetto;
      let totaleSpese = 0;
      this.$get(url).then(async(result) => {
        this.listaProgetti = result;
          this.listaProgetti.forEach(
            (element) => {
              //setto le azioni
              const info = element.infoProgetto;
              let azioni = [];
              azioni = this.$projectGetActions(element);
              azioni.filter((item) => {
                return this.actions.indexOf(item) != -1;
              });

              if (azioni.length > 1){ //se trovo più di un'azione la resetto, qui al massimo si può solo "vedere" un progetto
                azioni = ['view'];
              } else if (azioni.length === 0) {
                console.log("NO azioni, skip element=",element);
                return;
              }
                
              const obj = parseFloat(info.costoProgetto);
              if(!isNaN(obj)){
                totaleSpese = totaleSpese + obj;
              } else {
                console.error('errore costo progetto', info.idProgetto, info.codiceProgetto, info.costoProgetto);
              }

              let newRow = {
                  codiceProgetto: info.codiceProgetto,
                  modalitaDiAccesso: info.modalitaDiAccesso,
                  titoloProgetto: info.titoloProgetto,
                  dataInserimento: info.dataInserimento,
                  dataFineProgetto: info.dataConclusione,
                  // 26-08-21: modificato budgetMassimo->costoProgetto
                  costoProgetto: info.costoProgetto,
                  strumentoFinanziario: info.strumentoFinanziario,
                  stato: info.stato,
                  Azioni: azioni,
                  idSchedaProgetto: info.idProgetto,
                  idIstruttoria: info.idIstruttoria,
                  beneficiario: info.idBeneficiario
              };
                this.tabledata.rows.push(newRow);
            },
           
            (error) => {
                notify.error(
                  notify.strings.error,
                  notify.strings.internalErrorPleaseReportAction
                );
                throw error;
            }
          ); 
          this.totaleSpeseEuro = utils.formatCurrency(totaleSpese);
          this.isBusy = false;
          
        },
        (error) => {
          this.isBusy = false;
          notify.error(
            notify.strings.error,
            notify.strings.internalErrorPleaseReportAction
          );
          console.error("errore caricamento lista", error);
        }
      );
     
    },

    mappingTooltip(keyMapping) {
      let objMapping = {
        view: "Visualizza",
      };
      let temp = objMapping[keyMapping];
      if (!temp) return "";
      return temp;
    },
    getFilterFields() {
      let filterFields = [];
      for (let item of this.tabledata.header) {
        filterFields.push(item.key);
      }
      return filterFields;
    },
  },
};
</script>
