<template>
  <div>
    <h3 class="text-center"> {{ title }} </h3>
    <b-table-lite
      :id="idTablePdf"
      thead-class="head"
      :items="fillData.rows"
      :fields="fillData.header">

      <template v-slot:cell(Descrizione)="data">
        <span v-if="isBeneficiari(data.item.id)" v-html="formatBeneficiari(data)"/>
        <span v-else>{{data.value}}</span>
      </template>
      
      <template v-slot:cell(Azione)="data">
        <b-button @click="$bvModal.show('modalForm1-bandi')"
          variant="outline-primary"
          :key="x"
          v-for="x in data.value"
          size="sm"
          v-on:click="doAction(x, data.index)">
          <fa-icon
            v-if="x === 'edit'"
            :icon="['far', 'edit']"
            class="selector-icons"/>
        </b-button>
      </template>
    </b-table-lite>

    <b-modal id="modalForm1-bandi" hide-footer
             size="lg"
             scrollable centered
             dialog-class="modal1-content"
             content-class="modal1-content">
      <template v-slot:modal-title>
        <h3>Modifica campo</h3>
      </template>
      <modalForm1 :fdata="this.editFormData"
                  :sch="this.scheda"
                  :buttons="this.buttons"
                  :parentName="this.name"
                  @editField="editRiga"/>
    </b-modal>
  </div>
</template>
<script>
import modalForm1 from "@/components/modalForm1.vue";
import utils from "@/helpers/utils.js";
import tool from  "@/helpers/tools.js";
import { mapGetters } from "vuex";


export default {
  name: "generale",
  components: {
    modalForm1
  },
  props: {
    idTablePdf: { type: String, default: () => '' }
  },
  data() {
    return {
      name: 'bandi',
      title: '',
      editFormData: {},
      beneficiari: [],
      mappa: {},
      obiettivoSpecifico: [],
      obiettiviSpecifici: {},
      strumentoFinanziario: {},
      obiettivoNazionale: {},

      enumValues: [
        {
          label: "Selezionare Obiettivo Specifico",
          value: "",
        },
      ],
      isf: "",
      enumValues2: [
        {
          label: "Selezionare prima un Obiettivo Specifico",
          value: "",
        },
      ],
      scheda: {},
      editLine: 0,
      // showEditForm: false,
      buttons: [
        {
          name: "Modifica",
          action: "editField",
          show: true,
          param: "edit",
        },
      ],
      tabledata: {
        header: [
          {
            key: "Campo",
          },
          {
            key: "Descrizione",
            tdAttr: this.tooltip,
            formatter: this.formatField
          },
          {
            key: "Azione",
          },
        ],
        rows: [],
      },
    };
  },

  methods: {
    formatBeneficiari(data) {
      let retVal = '';
      if(!data.value || data.value.length === 0)
        return retVal;
      
      for(const beneficiario of data.value) {
        const temp = this.beneficiari.filter(item => {return item.value === beneficiario});
        if(temp.length === 1)
          retVal += temp[0].text + '<br>';
      }
      return retVal;
    },
    tooltip(value, key, item) {
      if(item.note)
        return {title: 'nota: ' + item.note};
      return {};
    },
    formatField(value, key, item)
    {
      if(item.id ==  "['bando']['obiettivoSpecifico']") {
        if(!value)
          return '';
        return value + " - " + this.obiettiviSpecifici[value];
      } else if(item.id === "['bando']['obiettivoNazionale']") {
        let valObiettivoSpecifico = this.scheda.schedaBando.bando.obiettivoSpecifico;
        if(!valObiettivoSpecifico)
          return '';
        const valoriObiettivoNazionali = this.obiettivoNazionale[this.isf][valObiettivoSpecifico].filter(item1 =>{
          return item1.codice === value;
        })
        if(valoriObiettivoNazionali.length > 0) {
          return valoriObiettivoNazionali[0].codice + " - " + valoriObiettivoNazionali[0].titolo;
        }
      }
      const mapElement = this.mappa[item.id];
      return utils.formatOutput(mapElement, value);
    },
    setEnumValues2(chiave) {
      this.enumValues2 = [
        {
          label: "Selezionare un Obiettivo Nazionale",
          value: "",
        },
      ];
      for (let key in this.obiettivoNazionale[this.isf]) {
        if (key === chiave) {
          this.obiettivoNazionale[this.isf][key].forEach((element) => {
            let text = element.codice  + " " + element.titolo;
            let val = { label: text, value:element.codice };
            this.enumValues2.push(val);
          });
        }
      }
    },
    editRiga(returnData) {
      this.$bvModal.hide('modalForm1-bandi');
      const id = this.tabledata.rows[this.editLine].id;
      let newDescrizione = utils.unformatOutput(returnData.conf[id], returnData.content[id].value);
      // edit configuration data
      let data = returnData.conf[id].data;
      data.edited = true;
      // WARNING: poichè oldValue è una stringa, converto idBeneficiari
      // in stringa con virgole: si dovrà ricovnertire se viene
      // abilitato il ripristino del valore precedente
      if(id === "['bando']['idBeneficiari']") {
        const temp = this.tabledata.rows[this.editLine].Descrizione;
        let oldValue = '';
        for(const item of temp) {
          oldValue += item + ',';
        }
        data.oldValue = oldValue;
      }
      else
        data.oldValue = this.tabledata.rows[this.editLine].Descrizione;
      this.$store.dispatch("announcements/updateBandoField", {
        idSchedaBando: this.getIdSchedaBando,
        key: this.tabledata.rows[this.editLine].Path,
        value: newDescrizione,
        id: id,
        data: data,
      });
      // update table
      
      this.tabledata.rows[this.editLine].Descrizione = newDescrizione;
      const note = returnData.conf[id].data.note;
      this.tabledata.rows[this.editLine].note = note;

      // obiettivo nazionale must be reset on change of obiettivo specifico 
      if (id === "['bando']['obiettivoSpecifico']") {
        let chiave = this.tabledata.rows[this.editLine].Descrizione;
        this.$store.dispatch("announcements/updateBandoField", {
          idSchedaBando: this.getIdSchedaBando,
          key: 'obiettivoNazionale',
          value: "",
          id: "['bando']['obiettivoNazionale']",
          data: {}
        });
        this.tabledata.rows.forEach(element => {
          if(element.id == "['bando']['obiettivoNazionale']"){
            element.Descrizione = null
          }
        });
        this.setEnumValues2(chiave);
      }
      if(id === "['bando']['titoloBando']") {
        this.setTitle();
      }
    },
    setSelect(bando) {
      
      for (let key in this.obiettiviSpecifici) {
        let option = {
          value: key,
          label: this.obiettiviSpecifici[key],
        };
        this.obiettivoSpecifico.push(option);
      }
      for (let key in this.obiettivoNazionale) {
        if (key === bando.strumentoFinanziario) {
          this.isf = key;
          for (let chiave in this.obiettivoNazionale[key]) {
            this.obiettivoSpecifico.forEach((element) => {
              if (chiave == element.value) {
                let text =  element.value + " " + element.label;
                let val = { label: text, value:element.value };
                this.enumValues.push(val);
              }
            });
          }
        }
      }
    },
    setTitle() {
      const bando = this.scheda.schedaBando.bando;
      let titolo = bando.idBando;
      if(bando.titoloBando)
        titolo = '"' + bando.titoloBando + '"';
      this.title = 'Bando ' + titolo;
    },
    extractData() {
     
      const obiettivi = this.getFinancialTools;
      this.obiettiviSpecifici = obiettivi.obiettiviSpecifici;
      this.obiettivoNazionale = obiettivi.obiettiviNazionali;
      this.scheda = this.getSchedaBando({idSchedaBando: this.getIdSchedaBando});
      if(!this.scheda || !this.scheda.schedaBando || !this.scheda.schedaBando.bando) {
        console.error('generale: error in scheda', this.getIdSchedaBando, this.scheda);
        return;
      }
      const schedaBando = this.scheda.schedaBando;
      const bando = schedaBando.bando;
      
      this.setTitle();
      this.setSelect(bando);
      this.mappa = this.getConfigurationSchedaBando;
      this.beneficiari = this.getAllBeneficiari;
      const actions = this.$getActions(this.scheda);
      this.tabledata.rows = [];
      let fields = this.getCallForProposalFields;
       // se il prim oselect e selezionato setto anche il secondo
      if(bando.obiettivoSpecifico) {
        this.setEnumValues2(bando.obiettivoSpecifico)
      }
      if (bando.modalitaDiAccesso != "Call for proposal")
        fields = this.getAssDirettaFields;
      fields.forEach((key) => {
          const mancauno = key.substring(11);
          const giusto = mancauno.substring(0, mancauno.length - 2);
          const descr = bando[giusto];
          let azione = '';

          let label = "N/D";
          //controllo più robusto nel caso di singolo elemento mancante dal tabellone di configurazione
          if (this.mappa[key] && this.mappa[key].config){
            const config = this.mappa[key].config;
            label = config.label;

            if(!config.readonly)
              azione = actions.filter(item => {return item === 'edit'});
          } else {
            console.error("Configurazione mancante per l'elemento=", key);
          }

          let note = '';
          const data = this.scheda.schedaBando.dataEntryConfiguration;
          
          if(data && data[key]) {
            note = data[key].note;
          }

          const newRow = {
            id:key,
            Campo: label,
            Descrizione: descr,
            Path: giusto,
            Azione: azione,
            note: note
          };
          this.tabledata.rows.push(newRow);
      });
    },
    doAction(actionToDo, index) {
      switch (actionToDo) {
        case "edit":
          this.editLine = index;
          if (this.mappa) {
            const row = tool.genericFunctions.cloneObject(this.tabledata.rows[index]);
            let data;
            
            const dataEntry = this.scheda.schedaBando.dataEntryConfiguration;
            if(!dataEntry || !dataEntry[row.id]) {
              data = {
                note: '',
                oldValue: '',
                edited: false
              }
            } 
            else
              data = tool.genericFunctions.cloneObject(dataEntry[row.id]);

            const config = tool.genericFunctions.cloneObject(this.mappa[row.id].config);
            // all fields are mandatory
            config.mandatory = true;

            this.editFormData = {
              content: {
                [row.id]: {
                  id: row.id,
                  value: row.Descrizione,
                  // enumValues: this.enumValues,
                },
              },
              conf: {
                [row.id]: {
                  config: config,
                  data: data
                },
              },
            };
            if ("['bando']['obiettivoNazionale']" == row.id) {
              this.editFormData.content[row.id].enumValues = this.enumValues2;
            } else if ("['bando']['obiettivoSpecifico']" == row.id) {
              this.editFormData.content[row.id].enumValues = this.enumValues;
            }
          }
      }
    },
  },
  mounted() {
    this.extractData();
  },
  computed: {
    ...mapGetters({
      getConfigurationSchedaBando:
        "announcements/retrieveConfigurationSchedaBando",
      getFinancialTools: "announcements/retrieveFinancialTools",
      getSchedaBando: "announcements/getSchedaBando",
      getAllBeneficiari: "announcements/getAllBeneficiari",
      getCallForProposalFields: "announcements/getCallForProposalFields",
      getAssDirettaFields: "announcements/getAssegnazioneDirettaFields"
    }),
    getIdSchedaBando() {
      // Recupero l'ID_SCHEDA_BANDO dall'url corrente
      return this.$route.params.idBando;
    },

    isBeneficiari: function() {
      return id => {
        const config = this.mappa[id].config;
        return config.type === 'enumstring';
      }
    },

    fillData: function() {
      return this.tabledata;
    },
  },
};
// TODO aggiungere validazione data fine successiva a data inizio e viceversa
</script>
