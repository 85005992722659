import Vue from "vue";
import endpoints from "@/components/endpoints.vue";
import notify from "@/helpers/notifications.js";
import axios from "axios";
import utils from '@/helpers/utils.js';

const state = {
    tabelloneProgetti: {},
    schedeProgetto: {},
    indicatori: {},
    miscConfig: {},
    tipologieProcedure: [{ value: null, text: 'Seleziona tipologia', disabled: true }]
};

const getters = {
    getSchedeProgetto(state) {
        return state.schedeProgetto
    },
    /* Non più usato dal task #10551 
    //Adesso tutti i tabelloni versionati sono gestiti dal modulo configuration dello store
    getTabelloneProgetti(state) {
        return type => {
            return state.tabelloneProgetti[type];
        }
    },
    */
    getScheda(state) {
        return (payload) => {
            if (payload &&
                payload.idSchedaProgetto &&
                state.schedeProgetto[payload.idSchedaProgetto]) {
                return state.schedeProgetto[payload.idSchedaProgetto];
            }
            return null;
        }
    },
    getSchedaProgetto(state) {
        return (payload) => {
            if (payload &&
                payload.idSchedaProgetto &&
                state.schedeProgetto[payload.idSchedaProgetto]) {
                return state.schedeProgetto[payload.idSchedaProgetto].schedaProgetto;
            } else
                console.error('getSchedaProgetto payload error', payload);

            return null;
        }
    },
    getIndicatori(state) {
        return state.indicatori
    },
    getMiscConfig(state) {
        return state.miscConfig
    },
    getTipologieProcedure(state){
        //manipolo il dato restituito dall'API per essere pronto alla visualizzazione su tendina b-form-select
        const r = state.tipologieProcedure;
        let arr = []
        let tipologieProcedure = [{ value: null, text: 'Seleziona tipologia', disabled: true }];
        for(let macroarea in r){
            arr.push({
                value: macroarea,
                text: '-- ' + r[macroarea].label + ' --',
                disabled: true
            })
            for(let k in r[macroarea].data){
                arr.push({
                    value: r[macroarea].data[k].schemaProcedura,
                    text: r[macroarea].data[k].label
                })
            }
        }
        tipologieProcedure = tipologieProcedure.concat(arr)
        return tipologieProcedure;
    }
};

const actions = {
    retrieveSchedaNoteRiservate(context, payload) {
        const keySchedaNoteRiservate = payload.noteRiservateKey;
        const version = payload.noteRiservateVersion;
        // WARNING: workaround per i vecchi processi
        if(!payload.noteRiservateKey || !payload.noteRiservateVersion) {
            return Promise.resolve('ND');
        }
        return axios({
          method: "GET",
          url: endpoints.noteRiservate(keySchedaNoteRiservate),
          headers: { 'token': utils.getSessionToken(),
          params: {version: version} }
        }).then(
          result => {
            if(result.data) {
              let schedaNoteRiservate = result.data;
              context.commit('ADD_SCHEDA_NOTE_RISERVATE', {
                schedaNoteRiservate: schedaNoteRiservate,
                idProgetto: payload.progetto.idProgetto
              });
            } else {
              console.log('no results schedaNoteRiservate ', keySchedaNoteRiservate);
            }
            return result.data;
          },
          error => { 
              console.error('retrieveSchedaNoteRiservate error', error);
              return null;
            }
        );
    },
    setupMiscConfig(context) {
        const url = endpoints.getMiscConfig;
        return Vue.prototype.$get(url).then(
            result => {
                context.commit("SET_MISC_CONFIG", result);
            }
        ).catch(
            error => {
                console.error('setupMiscConfig error: ', error);
                throw error;
            }
        )
    },
    setObjectStorageOrganigramma(context, payload){
        context.commit("UPDATE_OBJECT_STORAGE",payload);
    },
    // Settaggio Allegati Mancanti
    setMandatoryMissed(context, payload) {
        context.commit("UPDATE_ALLEGATI_MANCANTI", payload);
    },
    setAvailableFiles(context, payload) {
        context.commit("UPDATE_ALLEGATI_DISPONIBILI", payload);
    },
    deleteProgetto(context, payload) {
        const url = endpoints.deleteProgetti + payload.idProgetto;
        return Vue.prototype.$delete(url).then(
            (result) => {
                return result;
            },
            (error) => {
                console.error("deleteProgetto error=",error);

                throw error;
            }
        )
    },
    cancelEdit(context, payload) {
        const url = endpoints.cancelEditProgetti(payload.idProgetto);
        return Vue.prototype.$delete(url).then(
            result => {
                return result;
            }).catch(
                error => {
                    console.error("cancelEdit error=",error);
                    throw error;
                }
        )
    },
    claimSchedaProgetto(context, payload) {
        const urlClaim = endpoints.claimProgetto + payload.idProgetto;
        return Vue.prototype.$post(urlClaim).then(
            (respComplete) => {
                return respComplete;
            },
            (error) => {
                console.error("Error Claim/Unclaim", error.response);
                notify.error(notify.strings.error, notify.strings.errorLoadProgetto);
                throw error;
            }
        );
    },
    unclaimSchedaProgetto(context, payload) {
        const urlUnclaim = endpoints.unclaimProgetto + payload.idProgetto;
        return Vue.prototype.$post(urlUnclaim).then(
            (respComplete) => {
                return respComplete;
            },
            (error) => {
                console.error("Error Claim/Unclaim", error.response);
                notify.error(notify.strings.error, notify.strings.errorLoadProgetto);
                throw error;
            }
        );
    },

    // Beneficiario(oper) può creare un nuovo progetto a partire da un bando
    // dove è stato aggiunto come beneficiario
    async createSchedaProgetto(context, payload) {
        let urlNewProgetto = endpoints.newProgettoByListBandi;
        
        
        // Leggo l'ultima versione vigente del tabellone Progetto e tabellone cronoprog, poi 
        // scrivo idSchema e schemaVersione per entrambi sulla schedaProgetto
        const idSchema = 'progetto';
        const idSchemaCronoprog = 'cronoprog_progetto';
        let obj = payload;

        const promises = [
            Vue.prototype.$get(endpoints.retrieveLastVersionTabellone(idSchema)),
            Vue.prototype.$get(endpoints.retrieveLastVersionTabellone(idSchemaCronoprog))
        ];

        if(!context.state.miscConfig) {
            promises.push(context.dispatch('setupMiscConfig'));
        }
        try {
            const schemas = await Promise.all(promises);
            console.log("ultime versioni tabellone progetto: ", schemas[0].lastVersion, schemas[1].lastVersion);
            obj.idSchema = idSchema;
            obj.schemaVersione = schemas[0].lastVersion;
            obj.idSchemaCronoprog = idSchemaCronoprog;
            obj.schemaVersioneCronoprog = schemas[1].lastVersion;

        } catch(error) {
            console.log("createSchedaProgetto: Errore nella lettura delle versioni tabellone progetto:", error);
            throw error;
        }

        return Vue.prototype.$post(urlNewProgetto, payload).then(
            (response) => {
                console.log("Scheda Progetto creata correttamente, idProgetto=", response.idProgetto);
                let urlAvvioProcesso = endpoints.startProgettoProcess(context.state.miscConfig.processoCreazione);
                let idSchedaProgetto = response.idProgetto;

                const schedaProgetto = response.content;
                const body = {
                    idScheda: idSchedaProgetto,
                    idSchema: schedaProgetto.idSchema,
                    idSchemaCronoprog: schedaProgetto.idSchemaCronoprog,
                    schemaVersione: schedaProgetto.schemaVersione,
                    schemaVersioneCronoprog: schedaProgetto.schemaVersioneCronoprog,
                }
                return Vue.prototype.$post(urlAvvioProcesso, body)
                    .then(
                        (resp) => {
                            console.log("Processo per il Progetto avviato correttamente, ", resp);
                            return response;
                        }
                    )
                    .catch(
                        (error) => {
                            console.error('Start Progetto process error:', error.message);
                            throw error;
                        }
                    )
                    // return response;
            },
            (error) => {
                if (error.response && error.response.status && error.response.status != 429 && error.response.status != 410)
                    console.error("create Progetto error:", error.response);
                throw error;
            }
        );
    },
    // Save Scheda Progetto
    saveSchedaProgetto(context, payload) {
        // Due casistiche possibili
        // 1)   Salvataggio in Bozza (endpoint)
        let draftSchedaProgetto = endpoints.save_draft_schedaProgetto;
        // 2)   Complete Task (endpoint)
        let completeSchedaProgettoUrl = endpoints.complete_schedaProgetto;
        const scheda = payload.schedaProgetto.progetto;
        const body = payload.schedaProgetto;
        //console.log("POST to URL "+draftSchedaProgetto+" with BODY:", body);
        // Se salvo in Draft faccio solo la prima POST
        return Vue.prototype.$post(draftSchedaProgetto, body, { branch: 'progetto'}).then(
            (responseDraft) => {
                //console.log("Salvataggio schedaProgetto+schedaCronoprog OK, response:", responseDraft);
                // Se voglio salvare completamente faccio un altra chiamata (POST)
                if (payload.salvataggio === "complete" || payload.salvataggio === "reject" || payload.salvataggio === "denyEdit") {
                    // Setto l'oggetto per la post del salvataggio completo
                    const progetto = scheda.progetto;
                    let procId = scheda.processId;
                    if (scheda.processId2){
                        procId = scheda.processId2;
                        //console.log("found processId2=", procId);   //il processo in corso è quello di stipula della convenzione
                    }
                    let modificheSostanziali = false;
                    if(payload.isModificheSostanziali && (payload.isModificheSostanziali == 'true' || payload.isModificheSostanziali == true)) {
                        modificheSostanziali = true
                    }

                    //Variabile bpmn che serve per la fase iniziale di Convenzione Per Ammissione Diretta
                    let rifiutaAmmissioneISF = false;
                    if ( payload.rifiutaAmmissione && (payload.rifiutaAmmissione == 'true' || payload.rifiutaAmmissione == true) )
                        rifiutaAmmissioneISF = true

                    let rifiutoDefinitivo = false;
                    let esitoOk = false;
                    if(payload.salvataggio === "denyEdit") {
                        rifiutoDefinitivo = true;
                        esitoOk = true;
                    }
                    else if(payload.salvataggio === 'complete') {
                        esitoOk = true;
                    }

                    //estraggo la cronoprogKey dalla response precedente, quella passata esplicitamente nel payload è una versione indietro
                    let cronoKey = payload.cronoprogKey;
                    if (!cronoKey && body.progetto.cronoprogKey){
                        cronoKey = body.progetto.cronoprogKey;
                        console.log("cronoprogKey non passata allo store, la recupero dal progetto:", cronoKey);
                    }

                    if (body.cronoprog != null){ //se ho passato dal FE un cronoprog da scrivere
                        if (responseDraft && responseDraft.cronoprog && responseDraft.cronoprog.key){
                            console.log("returned cronoprog : ", responseDraft.cronoprog);
                            cronoKey = responseDraft.cronoprog.key;
                        } else { //NB: non si dovrebbe mai passare da qui! Questa è una pezza, una versione obsoleta è meglio di niente
                            console.error("cronoprogKey non trovata nella response!? Setto il default: "+cronoKey+
                                            ". Verificare lo stato della schedaProgetto: ", responseDraft.progetto);
                        }
                    }

                    let bodyForPost = {
                        idScheda: progetto.idProgetto,
                        processId: procId,
                        titoloProgetto: progetto.titoloProgetto,
                        // lavoriPubblici: payload.parereReferenteDeroga,
                        // punteggioValutazione: 70,
                        // richiestoParereICT: payload.parereReferenteIct,
                        // derogatoria: payload.parereReferenteEdile,
                        esitoOk: esitoOk,
                        // variabili per il processo ModificaProgetto
                        rifiutaAmmissione: rifiutaAmmissioneISF,
                        rifiuta: rifiutoDefinitivo,
                        sostanziali: modificheSostanziali,
                        cronoprogKey: cronoKey,
                        idSchemaCronoprog: payload.idSchemaCronoprog,
                        schemaVersioneCronoprog: payload.schemaVersioneCronoprog
                    };
                    //console.log("bodyForPost=",bodyForPost);
                    return Vue.prototype.$post(completeSchedaProgettoUrl, bodyForPost).then(
                        (respComplete) => {
                            // console.log("Salvataggio Completo ok", respComplete);
                            return respComplete;
                        },
                        (error) => {
                            console.error("POST error Complete Task Progetto:", error.response);
                            throw error;
                        }
                    );
                }
                return responseDraft;
            },
            (error) => {
                console.error("save error Salvataggio in Draft:", error.response);
                throw error;
            }
        );
    },
    setSchedaProgetto(context, payload) {
        const infoAllegati = {
          allegatiDisponibili: {
            progetti: 0,
            organigramma: 0,
            convenzione: 0,
          },
          allegatiMancanti: {
            progetti: [],
            organigramma: [],
            convenzione: [],
          },
          ...payload.tabelloneAllegati
        };
        let url;
        if(!payload.type)
            url = endpoints.retrieveSchedaProgetto(payload.idSchedaProgetto);
        else if(payload.type === 'modifica')
            url = endpoints.retrieveSchedaProgettoModifica(payload.idSchedaProgetto);
        else if(payload.type === 'attuazione') {
            const key = payload.idSchedaProgetto.concat('_v', payload.versione);
            url = endpoints.retrieveSchedaProgettoAttuazione(key);
        }

        return Vue.prototype.$get(url).then(
            (result) => {
                context.commit("RESET_SCHEDE");
                result.infoAllegati = infoAllegati
                let scheda = {
                    scheda: result,
                    idSchedaProgetto: payload.idSchedaProgetto
                }
                context.commit("SET_SCHEDA", scheda)
                return result
            },
            (error) => {
                throw error;
            },
        )
    },

    // modifico SchedaProgetto
    updateSchedaProgettoObject(context, payload) {
        context.commit("SET_SCHEDA_PROGETTO", payload);
    },
    updateScheda(context, payload){
        context.commit("SET_SCHEDA", payload);
    },
    updateParam(context, payload) {
        context.commit("UPDATE_PARAM", payload);
    },
    retrieveIndicatori(context) {
        const url = endpoints.retrieveMappaIndicatori;
        return Vue.prototype.$get(url).then(
            (result) => {
                context.commit("SET_INDICATORI", result)
                return result
            },
            (error) => {
                throw error;
            },
        )
    },
    //Aggiorna il campo di Erogazione "ammesso", che serve per i progetti AD
    updateStatoProgettoAmmesso(context, payload){
        if (payload && payload.ammesso != undefined && payload.idSchedaProgetto){
            context.commit("SET_STATO_PROGETTO_AMMESSO", payload);
        }
    },
    retrieveTipologieProcedure(context) {
        const url = endpoints.retrieveTabellone("procedure_tipologie");
        return Vue.prototype.$get(url)
            .then((result) => {
                context.commit("SET_TIPOLOGIE", result);
                //console.log("tipologie di procedura:", result);
                return result;
            })
            .catch(error => {
                console.error("retrieveTipologieProcedure: errore nello store progetto!");
                throw error;
            })
        
    }
};

const mutations = {
    ADD_SCHEDA_NOTE_RISERVATE(state, payload) {
        let idSchedaProgetto = payload.idProgetto
        if (idSchedaProgetto && state.schedeProgetto[idSchedaProgetto]) {
            state.schedeProgetto[idSchedaProgetto].schedaNoteRiservate = payload.schedaNoteRiservate;
        } else{
            console.error("ADD_SCHEDA_NOTE_RISERVATE Error: Missing paylod or scheda")
        }
        
    },
    SET_STATO_PROGETTO_AMMESSO(state, payload){
        if( payload.idSchedaProgetto &&
            state.schedeProgetto[payload.idSchedaProgetto] &&
            state.schedeProgetto[payload.idSchedaProgetto].schedaProgetto &&
            state.schedeProgetto[payload.idSchedaProgetto].schedaProgetto.erogazione )
                state.schedeProgetto[payload.idSchedaProgetto].schedaProgetto.erogazione.ammesso = payload.ammesso;
            else{
                console.error("SET_STATO_PROGETTO_AMMESSO Error: Missing paylod or scheda")
            }
    },
    SET_MISC_CONFIG(state, payload) {
        state.miscConfig = payload;
    },
    SET_INDICATORI(state, payload) {
        state.indicatori = payload;
    },
    SET_TIPOLOGIE(state, payload) {
        state.tipologieProcedure = payload;
    },
    UPDATE_OBJECT_STORAGE(state, payload){
        if (payload && payload.idSchedaProgetto && payload.file) {
            state.schedeProgetto[payload.idSchedaProgetto].schedaProgetto.organigrammaDelBeneficiario = payload.file;
        }
    },
    UPDATE_ALLEGATI_MANCANTI(state, payload) {
        if( payload.obj.idSchedaProgetto &&
            state.schedeProgetto[payload.obj.idSchedaProgetto] &&
            state.schedeProgetto[payload.obj.idSchedaProgetto].infoAllegati.allegatiMancanti[payload.obj.entity] )
                state.schedeProgetto[payload.obj.idSchedaProgetto].infoAllegati.allegatiMancanti[payload.obj.entity] = payload.obj.files;
            else{
                console.error("UPDATE_ALLEGATI_MANCANTI Error: Missing paylod or scheda")
            }
    },
    UPDATE_ALLEGATI_DISPONIBILI(state, payload) {
        if( payload.obj.idSchedaProgetto &&
            state.schedeProgetto[payload.obj.idSchedaProgetto] &&
            state.schedeProgetto[payload.obj.idSchedaProgetto].infoAllegati.allegatiDisponibili[payload.obj.entity] !== undefined )
                state.schedeProgetto[payload.obj.idSchedaProgetto].infoAllegati.allegatiDisponibili[payload.obj.entity] =  payload.obj.numeroAllegati;
            else{
                console.error("UPDATE_ALLEGATI_DISPONIBILI Error: Missing paylod or scheda")
            }
    },
    // set entire scheda (schedaprogetto+taskinfo)
    SET_SCHEDA(state, payload) {
        if (payload && payload.idSchedaProgetto && payload.scheda) {
            state.schedeProgetto[payload.idSchedaProgetto] = payload.scheda;
        } else
            console.error('SET_SCHEDA no key or scheda', payload);
    },
    // only set schedaprogetto (not taskinfo)
    SET_SCHEDA_PROGETTO(state, payload) {
        if (payload && payload.idSchedaProgetto && payload.schedaProgetto) {
            if (!state.schedeProgetto[payload.idSchedaProgetto])
                state.schedeProgetto[payload.idSchedaProgetto] = {};
            state.schedeProgetto[payload.idSchedaProgetto].schedaProgetto = payload.schedaProgetto;

            // Questo oggetto è stato aggiunto per tracciare meglio le Modifiche Sostanziali!
            if (!state.schedeProgetto[payload.idSchedaProgetto].schedaOriginale)
                state.schedeProgetto[payload.idSchedaProgetto].schedaOriginale = payload.schedaProgetto;
        } else
            console.error('SET_SCHEDA_PROGETTO no key or schedaprogetto', payload);
    },
    SET_TABELLONE_PROGETTO(state, payload) {
        if(!payload.type || ! payload.tabellone)
            console.error('SET_TABELLONE_PROGETTO no type or tabellone', payload);
        state.tabelloneProgetti[payload.type] = payload.tabellone;
    },
    UPDATE_PARAM(state, payload) {
        let scheda = state.schedeProgetto[payload.idProgetto];
        if (payload.key && scheda && scheda.schedaProgetto) {
            state.schedeProgetto[payload.idProgetto].schedaProgetto[payload.key] = payload.value;
        } else
            console.error('no key or scheda', payload, scheda);
    },
    RESET(state) {
        state.tabelloneProgetti = {};
        state.schedeProgetto = {};
        state.indicatori = {};
        state.miscConfig = {};
    },
    RESET_SCHEDE(state) {
        state.schedeProgetto = {};
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};