export default {

    install(Vue) {

        //funzione per gestire le azioni concesse su elenco progetti con disposizioni di pagamento
        Vue.prototype.$getActionsListaProgettiDisposizione = function(scheda, callerId) {
            let actions = [];

            if (!scheda || !callerId){
                console.error("getActionsListaProgettiDisposizione: NULL params!");
                return actions;
            }


            if (callerId === 'listaProgettiDisposizioni') {   //al momento unico componente che invoca questa funzione
                actions = ['view','view_controlli_list'];
            }

            return actions;

        }

        //funzione per gestire le azioni concesse sulle disposizioni di pagamento per un dato progetto
        Vue.prototype.$getActionsListaDisposizioni = function( callerId) {
            let actions = [];

            let user = Vue.prototype.$getUserInfo()
            let role = user.roles && user.roles.length > 0
              ? user.roles[0]
              : console.error("getActionsListaDisposizioni Ruoli dell'utente malformati")

            if (!callerId){
                console.error("getActionsListaDisposizioni: NULL params!");
                return actions;
            }


            if (callerId === 'listaDisposizioniProgetto') {   //al momento unico componente che invoca questa funzione
                actions = ['view'];
                if(role.includes("EconomicoFinanziario-Operatore")||role.includes("UfficioContabilitaSpeciale"))
                    actions.push('edit', 'delete')
            }

            return actions;

        }

        //funzione per gestire le azioni concesse su elenco progetti potenzialmente irregolari
        Vue.prototype.$getActionsProgettiIrregolari = function(scheda, callerId) {
            let actions = [];

            if (!scheda || !callerId){
                console.error("getActionsProgettiIrregolari: NULL params!");
                return actions;
            }

            if (callerId === 'listaProgettiIrregolari') {   //al momento unico componente che invoca questa funzione

                actions = ['view','view_irregolarita_list'];

            }

            return actions;

        }

        //funzione per gestire le azioni concesse su elenco progetti in anticipazione
        Vue.prototype.$getActionsProgettiAnticipazione = function(scheda, callerId) {
            let actions = [];

            if (!scheda || !callerId){
                console.error("getActionsProgettiAnticipazione: NULL params!");
                return actions;
            }


            if (callerId === 'listaProgettiAnticipazione') {   //al momento unico componente che invoca questa funzione
                actions = ['view','view_controlli_list'];
            }

            return actions;

        }

        Vue.prototype.$getActionsListaTranche = function(scheda, callerId) {
            let actions = [];
            if (!scheda || !callerId){
                console.error("getActionsListaTranche: NULL params!");
                return actions;
            }
            
            if(callerId === 'listaTrancheProgetto'){
                //actions.push('view')
                try{
                    let taskInfo;
                    if (scheda.content)
                        taskInfo = scheda.content.taskInfo; 
                    
                    //Operatore che unisce Array
                    actions = [...couldClaim(taskInfo), ...actions]
                    //console.info("Merged Arrays of Actions: ", actions)

                }catch(error){
                    console.error("getActionsListaTranche, Error: ", error.message)
                    return []
                }
            }
   

            return actions
        }

        Vue.prototype.$getActionsListaIrregolarita = function(scheda, callerId) {
            let actions = [];
            if (!scheda || !callerId){
                console.error("getActionsListaIrregolarita: NULL params!");
                return actions;
            }
            
            if(callerId === 'listaSegnalazione'
            || callerId === 'listaProcedimento'
            || callerId === 'listaProvvedimento'
            ){
                //actions.push('view')
                try{
                    let taskInfo;
                    if (scheda.content)
                        taskInfo = scheda.content.taskInfo; 
                    
                    //Operatore che unisce Array
                    actions = [...couldClaim(taskInfo), ...actions]
                    //console.info("Merged Arrays of Actions: ", actions)

                }catch(error){
                    console.error("getActionsListaIrregolarita, Error: ", error.message)
                    return []
                }
            }
   

            return actions
        }

        Vue.prototype.$getActionsIrregolarita = function(scheda, callerId){
            let actions = [];
            const VIEW = ['view']

            if (!scheda || !callerId){
                console.error("getActionsIrregolarita: NULL params!");
                return actions;
            }

            let user = Vue.prototype.$getUserInfo()
            let role = user.roles && user.roles.length > 0
              ? user.roles[0]
              : console.error("Ruoli dell'utente malformati")

            let taskInfo = scheda.content && scheda.content.taskInfo ? scheda.content.taskInfo : undefined

            let Anagrafica = {
                'UfficioControlli-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioGestione-Operatore' : isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioControlli-Approvatore': VIEW,
                'UfficioGestione-Approvatore': VIEW,
                'SegreteriaTecnicaAmministrativa-Direttore': VIEW
            }
            
            // TASK 10093: Alla conclusione di un'irregolarità, nel caso di una scheda con stato: 'Procedimento Irregolarità avviato', ovvero Segnalazione accettata: Si
            // UfficioControlli-Operatore e UfficioGestione-Operatore possono editare files e salvare dei commenti conclusivi
            // Ciò si traduce nel rendere: Documentazione abilitato ad allegare e alla visibilità di un bottone "Salva Commenti"
            let Documentazione
            if(scheda.stato &&  scheda.stato !== 'Procedimento Irregolarità avviato') {
                Documentazione = {
                    'UfficioControlli-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save'] : VIEW,
                    'UfficioGestione-Operatore' : isTaskAssignedToMe(taskInfo, user) === true ? ['save'] : VIEW,
                    'UfficioControlli-Approvatore': VIEW,
                    'UfficioGestione-Approvatore': VIEW,
                    'SegreteriaTecnicaAmministrativa-Direttore': VIEW
                }
            } else {
                Documentazione = {
                    'UfficioControlli-Operatore': ['save'],
                    'UfficioGestione-Operatore' : ['save'],
                    'UfficioControlli-Approvatore': VIEW,
                    'UfficioGestione-Approvatore': VIEW,
                    'SegreteriaTecnicaAmministrativa-Direttore': VIEW
                }
            }
            
            let Invio
            if(scheda.stato &&  scheda.stato !== 'Procedimento Irregolarità avviato') {
                Invio = {
                    'UfficioControlli-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send', 'delete'] : VIEW,
                    'UfficioGestione-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send', 'delete'] : VIEW,
                    'UfficioControlli-Approvatore': isTaskAssignedToMe(taskInfo, user) === true ? ['send', 'reject', 'editEsito'] : VIEW,
                    'UfficioGestione-Approvatore': isTaskAssignedToMe(taskInfo, user) === true ? ['send', 'reject', 'editEsito'] : VIEW,
                    'SegreteriaTecnicaAmministrativa-Direttore': isTaskAssignedToMe(taskInfo, user) === true ? ['send', 'reject'] : VIEW,
                }
            } else {
                Invio = {
                    'UfficioControlli-Operatore': ['save_comments'],
                    'UfficioGestione-Operatore': ['save_comments'],
                    'UfficioControlli-Approvatore': VIEW,
                    'UfficioGestione-Approvatore': VIEW,
                    'SegreteriaTecnicaAmministrativa-Direttore': VIEW,
                }
            }
            // Tab dove possono essere aggiunti/editati commenti o semplicemente visualizzati
            let EsitoFinale = {
                'UfficioControlli-Operatore': ['view', 'view_commenti', 'edit_commenti','save'],
                'UfficioGestione-Operatore': ['view', 'edit_commenti', 'save'],
                'UfficioControlli-Approvatore': [ 'view', 'view_commenti'],
                'UfficioGestione-Approvatore': [ 'view','view_commenti'],
                'SegreteriaTecnicaAmministrativa-Direttore': [ 'view', 'view_commenti'],
            }

            //azioni possibili per ogni TAB
            let mapActions = {
                'Anagrafica': { actions: Anagrafica[role] },
                'Documentazione': {  actions: Documentazione[role] },
                'EsitoFinale': {  actions: EsitoFinale[role] },
                'Invio': {  actions: Invio[role] }
            }  

            actions = mapActions[callerId] && mapActions[callerId].actions ? mapActions[callerId].actions : VIEW
                
            return actions            
        }

        Vue.prototype.$getActionsListaInLoco = function(scheda, callerId){
            let actions = [];
            if (!scheda || !callerId){
                console.error("getActionsListaInLoco: NULL params!");
                return actions;
            }
            
            if(callerId === 'listaInLocoFinanziari' || callerId === 'listaInLocoOperativi'){
                // actions.push('view')
                try{
                    let taskInfo;
                    if (scheda.content)
                        taskInfo = scheda.content.taskInfo; 
                    
                    //Operatore che unisce Array
                    actions = [...couldClaim(taskInfo), ...actions]
                    //console.info("Merged Arrays of Actions: ", actions)

                    //Se il controllo si è concluso negativamente, vi è la possibilita di avviare una segnalazione
                    if(scheda.stato.includes("Negativamente") ){                       
                        const user = Vue.prototype.$getUserInfo();
                        const roles = user.roles;
                        const role = roles[0];
                        if(role.includes("fficioControlli-Operator"))
                            actions.push("start_irr")                        
                    }

                }catch(error){
                    console.error("getActionsListaInLoco, Error: ", error.message)
                    return []
                }
            }
   

            return actions
        }

        //Azioni concesse sui tab scheda in loco
        Vue.prototype.$getActionsControlliInLoco = function(scheda, callerId){
            let actions = [];
            const VIEW = ['view']

            if (!scheda || !callerId){
                console.error("getActionsControlliInLoco: NULL params!");
                return actions;
            }

            let user = Vue.prototype.$getUserInfo()
            let role = user.roles && user.roles.length > 0
              ? user.roles[0]
              : console.error("Ruoli dell'utente malformati")
            let taskInfo = scheda.content && scheda.content.taskInfo ? scheda.content.taskInfo : undefined

            let Generale = {
                'ReferenteStrategico-ReferenteStrategico': VIEW,
                'TaskForceLie-TaskForceLie' : VIEW,
                'Beneficiario-Operatore': VIEW,
                'Beneficiario-Approvatore': VIEW,
                'UfficioControlli-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioControlli-Approvatore': VIEW,
                'UfficioGestione-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioGestione-Approvatore': VIEW
            }
            let Verbale = {
                'ReferenteStrategico-ReferenteStrategico': VIEW,
                'TaskForceLie-TaskForceLie' : VIEW,
                'Beneficiario-Operatore':  VIEW,
                'Beneficiario-Approvatore': VIEW,
                'UfficioControlli-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioControlli-Approvatore': VIEW,
                'UfficioGestione-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioGestione-Approvatore': VIEW
            }
            let Documentazione = {
                'ReferenteStrategico-ReferenteStrategico': VIEW,
                'TaskForceLie-TaskForceLie' : VIEW,
                'Beneficiario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save'] : VIEW,
                'Beneficiario-Approvatore': VIEW,
                'UfficioControlli-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save'] : VIEW,
                'UfficioControlli-Approvatore': VIEW,
                'UfficioGestione-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save'] : VIEW,
                'UfficioGestione-Approvatore': VIEW
            }
            let ControlloFinanziario = {
                'ReferenteStrategico-ReferenteStrategico': VIEW,
                'TaskForceLie-TaskForceLie' : VIEW,
                'Beneficiario-Operatore': VIEW ,
                'Beneficiario-Approvatore': VIEW,
                'UfficioControlli-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioControlli-Approvatore': VIEW,
                'UfficioGestione-Operatore':VIEW,
                'UfficioGestione-Approvatore': VIEW
            }
            let ControlloOperativo = {
                'ReferenteStrategico-ReferenteStrategico': VIEW,
                'TaskForceLie-TaskForceLie' : VIEW,
                'Beneficiario-Operatore': VIEW ,
                'Beneficiario-Approvatore': VIEW,
                'UfficioControlli-Operatore': VIEW,
                'UfficioControlli-Approvatore': VIEW,
                'UfficioGestione-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioGestione-Approvatore': VIEW
            }
            let Invio = {
                'ReferenteStrategico-ReferenteStrategico': isTaskAssignedToMe(taskInfo, user) === true ? ['send', 'editICTAdvice'] : VIEW,
                'TaskForceLie-TaskForceLie' : isTaskAssignedToMe(taskInfo, user) === true ? ['send', 'editLieAdvice'] : VIEW,
                'Beneficiario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send'] : VIEW,
                'Beneficiario-Approvatore': isTaskAssignedToMe(taskInfo, user) === true ? ['send', 'reject'] : VIEW,
                'UfficioControlli-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send'] : VIEW,
                'UfficioControlli-Approvatore': isTaskAssignedToMe(taskInfo, user) === true ? ['send', 'reject'] : VIEW,
                'UfficioGestione-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send', 'askAdvice'] : VIEW,
                'UfficioGestione-Approvatore': isTaskAssignedToMe(taskInfo, user) === true ? ['send', 'reject'] : VIEW,
            }

            //azioni possibili per ogni TAB
            let mapActions = {
                'Generale': { actions: Generale[role] },
                'Verbale': { actions: Verbale[role]},
                'Documentazione': {  actions: Documentazione[role] },
                'ControlloFinanziario': {  actions: ControlloFinanziario[role] },
                'ControlloOperativo': {  actions: ControlloOperativo[role] },
                'Invio': {  actions: Invio[role] }
            }  

            actions = mapActions[callerId] && mapActions[callerId].actions ? mapActions[callerId].actions : VIEW
            
            return actions
        }

        //funzione per gestire le azioni concesse su tab tranches
        Vue.prototype.$getActionsTranche = function(scheda, callerId) {
            let actions = [];
            const VIEW = ['view']

            if (!scheda || !callerId){
                console.error("getActionsTranche: NULL params!");
                return actions;
            }

            let user = Vue.prototype.$getUserInfo()
            let role = user.roles && user.roles.length > 0
              ? user.roles[0]
              : console.error("Ruoli dell'utente malformati")
            let taskInfo = scheda.content && scheda.content.taskInfo ? scheda.content.taskInfo : undefined

            let Anagrafica = {
                'Beneficiario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'Beneficiario-Approvatore': VIEW,
                'UfficioEconomicoFinanziario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioEconomicoFinanziario-Approvatore': VIEW
            }
            let RichiestaTranche = {
                'Beneficiario-Operatore':  isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'Beneficiario-Approvatore': VIEW,
                'UfficioEconomicoFinanziario-Operatore': VIEW,
                'UfficioEconomicoFinanziario-Approvatore': VIEW,
            }
            let ApprovazioneTranche = {
                'Beneficiario-Operatore':  VIEW,
                'Beneficiario-Approvatore': VIEW,
                'UfficioEconomicoFinanziario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioEconomicoFinanziario-Approvatore': VIEW,
            }
            let Documentazione = {
                'Beneficiario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save'] : VIEW,
                'Beneficiario-Approvatore': VIEW,
                'UfficioEconomicoFinanziario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save'] : VIEW,
                'UfficioEconomicoFinanziario-Approvatore': VIEW,
            }
            let Controllo = {
                'Beneficiario-Operatore': VIEW ,
                'Beneficiario-Approvatore': VIEW,
                'UfficioEconomicoFinanziario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : VIEW,
                'UfficioEconomicoFinanziario-Approvatore': VIEW
            }
            let Invio = {
                'Beneficiario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send'] : VIEW,
                'Beneficiario-Approvatore': isTaskAssignedToMe(taskInfo, user) === true ? ['send', 'reject'] : VIEW,
                'UfficioEconomicoFinanziario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send', 'reject'] : VIEW,
                'UfficioEconomicoFinanziario-Approvatore': isTaskAssignedToMe(taskInfo, user) === true ? ['send', 'reject'] : VIEW,
            }

            //azioni possibili per ogni TAB (tranche)
            let mapActions = {
                'Anagrafica': { actions: Anagrafica[role] },
                'RichiestaTranche': { actions: RichiestaTranche[role]},
                'ApprovazioneTranche': { actions: ApprovazioneTranche[role]},
                'Documentazione': {  actions: Documentazione[role] },
                'Controllo': {  actions: Controllo[role] },
                'Invio': {  actions: Invio[role] }
            }  

            actions = mapActions[callerId] && mapActions[callerId].actions ? mapActions[callerId].actions : VIEW 
            
            return actions
        }

        //funzione per gestire le azioni concesse su elenco prefinanziamenti
        Vue.prototype.$getActionsProgettiPrefinanziabili = function(scheda, callerId) {
            let actions = [];
            const user = Vue.prototype.$getUserInfo();
            const roles = user.roles;
            const role = roles[0];
            if (!scheda || !callerId){
                console.error("getActionsProgettiPrefinanziabili: NULL params!");
                return actions;
            }


            if (callerId === 'listaProgettiPrefinanziabili') {   //al momento unico componente che invoca questa funzione
                actions = ['view'];
                if(role.includes("EconomicoFinanziario") && !scheda.concluso)
                    actions.push('start_pref')
            }

            if (callerId === 'listaProgettiInPrefinanziamento') {
                actions = ['view', 'view_controlli_list'];
                try{

                    let taskInfo;
                    if (scheda.schedaPrefinanziamento.content)
                        taskInfo = scheda.schedaPrefinanziamento.content.taskInfo;

                    
                    if(taskInfo) {
                        const assignee = taskInfo.taskAssignee
                        let candidateRole
                        if (taskInfo.taskDefinitionKey){
                            const s = taskInfo.taskDefinitionKey.split('_')
                            if (s.length !== 3) {
                                throw new Error("getActionsControlliAmm: taskDefinitionKey malformato.")
                            } else { 
                                candidateRole = s[2];
                            }
                        }

                        // l'user matcha con chi ha preso in carico il processo
                        if(assignee===user.sub){
                            actions.push('unclaim')
                        }

                        if(role.includes("Beneficiario-Operatore")){
                            console.log("");
                        } else if(candidateRole && role === candidateRole && !assignee){
                            actions.push("claim")
                        }

                        return actions;
                    }
                } catch(error) {
                    console.error("getActionsProgettiPrefinanziabili, Error: ", error.message);
                    return [];
                }
            }

            if (callerId === 'listaProgettiPrefinanziati') {
                actions = ['view', 'view_controlli_list'];
            }


            return actions;

        }

        // FUNZIONI COMUNI
        
        function getCandidateRole(taskInfo) {
          //COSTRUZIONE DEL CANDIDATE ROLE
          return taskInfo.taskDefinitionKey && taskInfo.taskDefinitionKey.split('_').length === 3
          ? taskInfo.taskDefinitionKey.split('_')[2]: '';
        }

        function couldClaim(taskInfo) {
            let actions = []
            if(taskInfo){
                const user = Vue.prototype.$getUserInfo();
                const roles = user.roles;
                const role = roles[0];

                const assignee = taskInfo.taskAssignee
                const candidateRole = getCandidateRole(taskInfo)

                if(assignee==user.sub){
                    actions.push('unclaim')
                    // if(candidateRole.includes('pprovator'))
                    //     actions.push('edit')
                    // else
                        actions.push('edit') //Operatori
                }
                else if(candidateRole && 
                    role === candidateRole && 
                    !assignee)
                        actions.push('claim','view')
                else //Qualcun altro
                    actions.push('view')
            }    
            return actions;
        }

        function isTaskAssignedToMe(taskInfo, user) {
          if(!taskInfo){ //processo concluso
              return false;
          }  
          const assignee = taskInfo.taskAssignee
          // Recupero il primo ruolo dall'utente loggato
          let role = user.roles && user.roles.length > 0
            ? user.roles[0]
            : console.error("Ruoli dell'utente malformati")
          // qui torno un booleano (true se il task è assegnato a me)
          return role ? assignee === user.sub : false
        }

        //funzione per gestire le azioni concesse sui tab della scheda prefinanziamento
        Vue.prototype.$getActionsPrefinanziamento = function(scheda, callerId) {
          let actions = [];
          if (!scheda || !callerId){
            console.error("getActionsPrefinanziamento: NULL params!");
            return actions;
          }
          let user = Vue.prototype.$getUserInfo()
          let role = user.roles && user.roles.length > 0
            ? user.roles[0]
            : console.error("Ruoli dell'utente malformati")
          let taskInfo = scheda.content && scheda.content.taskInfo ? scheda.content.taskInfo : undefined
          
          // COMMENTATE PERCHE' NON USATE
          //let stato = scheda.stato ? scheda.stato : console.error("STATO IS NULL!? callerId="+callerId, scheda)
          // //let candidateRole
          // if(taskInfo && taskInfo.taskDefinitionKey)
            //candidateRole = getCandidateRole(taskInfo)
          
          let Anagrafica = {
            'Beneficiario-Operatore': ['view'],
            'Beneficiario-Approvatore': ['view'],
            'UfficioEconomicoFinanziario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : ['view'],
            'UfficioEconomicoFinanziario-Approvatore': ['view']
          }
          let NotaAnticipo = {
            'Beneficiario-Operatore': ['view'],
            'Beneficiario-Approvatore': ['view'],
            'UfficioEconomicoFinanziario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : ['view'],
            'UfficioEconomicoFinanziario-Approvatore': ['view']
          }
          let Documentazione = {
            'Beneficiario-Operatore': ['view'],
            'Beneficiario-Approvatore': ['view'],
            'UfficioEconomicoFinanziario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save'] : ['view'],
            'UfficioEconomicoFinanziario-Approvatore': ['view'],
          }
          let Controllo = {
            'Beneficiario-Operatore': ['view'],
            'Beneficiario-Approvatore': ['view'],
            'UfficioEconomicoFinanziario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['edit'] : ['view'],
            'UfficioEconomicoFinanziario-Approvatore': ['view']
          }
          let Invio = {
            'Beneficiario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send'] : ['view'],
            'Beneficiario-Approvatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send'] : ['view'],
            'UfficioEconomicoFinanziario-Operatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send'] : ['view'],
            'UfficioEconomicoFinanziario-Approvatore': isTaskAssignedToMe(taskInfo, user) === true ? ['save', 'send', 'reject'] : ['view'],
          }
          //azioni possibili per ogni TAB (prefinanziamento)
          let mapActions = {
            'Anagrafica': { actions: Anagrafica[role] },
            'NotaAnticipo': {  actions: NotaAnticipo[role] },
            'Documentazione': {  actions: Documentazione[role] },
            'Controllo': {  actions: Controllo[role] },
            'Invio': {  actions: Invio[role] }
          }  
          actions = mapActions[callerId] && mapActions[callerId].actions ? mapActions[callerId].actions : ['view']
          return actions
        }

        //funzione per gestire le azioni concesse su elenco progetti
        Vue.prototype.$getActionsProgettiControlli = function(scheda, callerId) {
            let actions = [];

            if (!scheda || !callerId){
                console.error("getActionsProgettiControlli: NULL params!");
                return actions;
            }

            if (callerId === 'listaControlliAmm') {   

                actions = ['view', 'view_controlli_list'];

            }

            if (callerId === 'listaControlliLoco') {
                actions = ['view', 'view_controlli_list'];
            }

            return actions;

        }

        //funzione per gestire le azioni concesse su schedeControllo e relativi TAB
        Vue.prototype.$getActionsControlliAmm = function(scheda, callerId) {

            let actions = [];
            
            if (!scheda || !callerId){
                console.error("getActionsControlliAmm: NULL params!");
                return actions;
            }
            //componente che chiama la funzione
            //console.log("caller callerId=", callerId);

            try {
                let taskInfo;
                if (scheda.content)
                    taskInfo = scheda.content.taskInfo;
                let stato;
                if (scheda.stato)
                    stato = scheda.stato;
                else 
                    console.error("STATO IS NULL!? callerId="+callerId, scheda);
                
                const user = Vue.prototype.$getUserInfo();
                const roles = user.roles;
                const role = roles[0];

                if (!taskInfo 
                    || !stato
                    || !taskInfo.taskDefinitionKey
                ) {
                    let aree = Vue.prototype.$getDashboardAreas();
                    //se all'utente è stato concesso di vedere l'area controlli su navbar e dashboard posso vedere l'elenco dei progetti controllabili
                    if (aree.includes('CONTROL')){   

                        //definisco qui le azioni base di un dato componente
                        if (callerId==='listaControlliAmmFase1A' 
                            || callerId==='listaControlliAmmFase1B' 
                            || callerId==='listaControlliAmmFase2'
                            || callerId==='Anagrafica'
                            ){
                            
                            actions = ['view'];

                        }

                        //processo concluso
                        if (stato && stato.includes('Esitato')){
                            actions = ['view'];

                            if ((callerId === 'Controllo' && role.includes('Beneficiario')) //il beneficiario non vede mai il tab Controllo
                                || (callerId === 'AutoControllo' 
                                && (role.includes('UfficioControlli') 
                                    || role.includes('UfficioEconomicoFinanziario')
                                    || role.includes('UfficioGestione')
                                    )
                                )      //l'ufficio controllore non vede mai il tab AutoControllo
                            ){
                                actions = [];
                            }

                            if ((callerId === 'Procedura' && scheda.content.sottoTipoControllo !== '1A')
                                || (callerId === 'Contratto' && scheda.content.sottoTipoControllo !== '1B') 
                                || (callerId === 'Pagamenti' && scheda.content.sottoTipoControllo !== '2')    
                            ){
                                actions = [];
                            }

                            //Se un controllo è concluso negativamente potrei avviare un processo di irregolarita
                            if(callerId.includes('listaControlliAmmFase') 
                            && scheda.stato.includes('Negativamente')
                            && role.includes('UfficioControlli-Operator')){
                                actions.push('start_irr')
                            }
                                

                        }

                    }
                    
                    //console.log("getActionsControlliAmm: base actions for component="+callerId+" array is:", actions)
                
                    return actions;
                }
                
                let candidateRole
                const assignee = taskInfo.taskAssignee

                if (taskInfo.taskDefinitionKey){
                    const s = taskInfo.taskDefinitionKey.split('_')
                    if (s.length !== 3) {
                        throw new Error("getActionsControlliAmm: taskDefinitionKey malformato.")
                    } else { 
                        candidateRole = s[2];
                        //console.log("candidateRole", candidateRole)
                    }
                }

                // l'user matcha con chi ha preso in carico il processo
                if (assignee === user.sub) {
                    
                    if (callerId == 'listaControlliAmmFase1A' 
                        || callerId == 'listaControlliAmmFase1B' 
                        || callerId == 'listaControlliAmmFase2' 
                        ){
                        actions = ['unclaim', 'edit'];    

                    } else if (callerId === 'AutoControllo' && role.includes('Beneficiario')){      //TAB dedicato al Beneficiario
                        
                        if (candidateRole.includes('Beneficiario-Operatore'))        //solo l'operatore edita le checklist
                            actions = ['edit'];
                        
                        if (role.includes('Beneficiario-Approvatore'))
                            actions.push('view');


                    } else if (callerId === 'Controllo' && (role.includes('UfficioControlli') 
                        || role.includes('UfficioEconomicoFinanziario')
                        || role.includes('UfficioGestione')
                        )
                            ){     //TAB dedicato all'Ufficio Controllore
                        
                        if (candidateRole.includes('UfficioControlli-Operatore') 
                            || candidateRole.includes('UfficioEconomicoFinanziario-Operatore')
                            )        //solo l'operatore edita le checklist
                            actions = ['edit'];
                    
                        if (role.includes('UfficioControlli-Approvatore') 
                            || role.includes('UfficioEconomicoFinanziario-Approvatore')
                            || role.includes('UfficioGestione-Approvatore')
                            )
                            actions.push('view');

                    } else if ((callerId === 'Procedura' && scheda.content.sottoTipoControllo === '1A') //TAB Procedura solo per controlli 1A
                            || (callerId === 'Contratto' && scheda.content.sottoTipoControllo === '1B') //TAB Contratto solo per controlli 1B
                            || (callerId === 'Dichiarazione' && scheda.content.sottoTipoControllo === '2')  //TAB Dichiarazione solo per controlli fase 2
                            ){               
                        
                        if (candidateRole.includes('Beneficiario-Operatore'))        //solo Beneficiario operatore edita le checklist
                            actions = ['edit'];
                        else 
                            actions = ['view'];


                    } else if (callerId === 'Pagamenti' && scheda.content.sottoTipoControllo === '2') { //TAB Pagamenti solo per controlli fase 2
                        
                        if (candidateRole.includes('Beneficiario-Operatore'))        //solo Beneficiario operatore può aggiungere o cancellare pagamenti
                            actions = ['edit', 'delete', 'add'];    //il componente usato per questo TAB si auto setta la 'view' per fare vedere il dato completo nella modale
                        else 
                            actions = ['view'];

                    } else if (callerId === 'InvioControllo'){           //TAB invio    
                        
                        actions = ['send'];                         //tutti hanno salva ed invia

                        if (candidateRole.includes('Operatore')){   //solo gli operatori hanno salva in bozza 
                            actions.push('save');
                        }
                        
                        if (candidateRole.includes('Approvatore')                       //l'approvatore può sempre respingere 
                            || candidateRole.includes('UfficioControlli-Operatore')
                            || candidateRole.includes('UfficioEconomicoFinanziario-Operatore')
                            ){    //anche Ufficio-Operatore può respingere
                            actions.push('reject');
                        }

                        if (role.includes('Beneficiario') && candidateRole.includes('Beneficiario-Operatore')){
                            actions.push('checkAutocontrollo');
                            //if(scheda.content.sottoTipoControllo === '2')
                                actions.push('delete');
                        }

                        if (role.includes('UfficioControlli') && candidateRole.includes('UfficioControlli-Operatore')
                            || role.includes('UfficioEconomicoFinanziario') && candidateRole.includes('UfficioEconomicoFinanziario-Operatore')
                            ){
                            actions.push('checkControllo');
                        }


                    } else if (callerId === 'DocumentazioneControllo') {
                        if (candidateRole.includes('Operatore')){   //solo gli operatori caricano documenti
                            actions = ['save'];
                        } else {
                            actions = ['view'];
                        }
                    } else if (callerId === 'Anagrafica') {
                        if (candidateRole.includes('Operatore'))
                            actions = ['edit']
                        actions.push('view')
                    }
                    
                    return actions;
                    
                }

                // user ha il profilo nel processo ed il task del processo non e' gia' preso in carico da qualcuno
                if (candidateRole && role === candidateRole && (!assignee)) {
                    
                    if (callerId==='listaControlliAmmFase1A'
                        || callerId==='listaControlliAmmFase1B'
                        || callerId==='listaControlliAmmFase2'
                        ){ //unici componenti in cui si può fare claim di un controllo
                        actions.push("claim");
                        actions.push("view");
                    } else if ((callerId === 'AutoControllo' && role.includes('Beneficiario'))
                        || (callerId === 'Controllo' && (role.includes('UfficioControlli') 
                            || role.includes('UfficioEconomicoFinanziario')
                            || role.includes('UfficioGestione')
                            ))
                        || (callerId === 'Procedura' && scheda.content.sottoTipoControllo === '1A')
                        || (callerId === 'Contratto' && scheda.content.sottoTipoControllo === '1B')
                        || (callerId === 'Pagamenti' && scheda.content.sottoTipoControllo === '2' )
                        ){
                        actions.push("view");
                    }
                }
                //Task preso in carico da un utente col mio stesso ruolo
                else if(candidateRole && role === candidateRole && assignee){
                    if (callerId==='listaControlliAmmFase1A'
                    || callerId==='listaControlliAmmFase1B'
                    || callerId==='listaControlliAmmFase2'
                    ){ //unici componenti in cui si può fare claim di un controllo
                    actions.push("view");
                    }
                }
            
                // if no edit actions, add view only
                if (!actions.includes('edit') 
                    && (callerId === 'DocumentazioneControllo'
                        || callerId ==='InvioControllo'
                        || (callerId === 'AutoControllo' && role.includes('Beneficiario'))
                        || (callerId === 'Controllo' && (role.includes('UfficioControlli') 
                            || role.includes('UfficioEconomicoFinanziario')
                            || role.includes('UfficioGestione')
                        ))
                        )
                    ) {
                    
                    if (!actions.includes('view') && scheda.stato !== 'In Compilazione'){
                        actions.push('view');
                    }
                }
                // console.info(candidateRole)
                //processo appena concluso, refresh delle liste
                if (candidateRole === 'Nessuno-Nessuno' 
                    && (callerId === 'listaControlliAmmFase1A'
                        || callerId === 'listaControlliAmmFase1B'
                        || callerId === 'listaControlliAmmFase1B')
                    ){
                        // console.info("HELLO THERE")
                    if (!actions.includes('view')){
                        console.error("Process just closed, adding 'view' for callerId=", callerId);
                        actions.push('view');
                    }
                }
                if((callerId ==='AutoControllo'||callerId ==='Controllo')&&(role.includes('Visualizzatore')||role.includes('AutoritaResponsabile'))){
                    if (actions.length === 0){
                        console.log("Nessuna azione, inserisco almeno la view");
                        actions.push('view');
                    }
                }
                if(callerId==='Controllo' && role.includes("UfficioContabilitaSpeciale")){
                    if (actions.length === 0){
                        console.log("Nessuna azione, inserisco almeno la view");
                        actions.push('view');
                    }
                }
                //processo in corso, concedo almeno la view per i TAB sempre visibili
                if (callerId === 'DocumentazioneControllo' 
                    || (callerId === 'AutoControllo' && role.includes('Beneficiario'))
                    || (callerId === 'Controllo' && (role.includes('UfficioControlli') 
                        || role.includes('UfficioEconomicoFinanziario')
                        || role.includes('UfficioGestione')
                        ))
                    || (callerId === 'Procedura' && scheda.content.sottoTipoControllo === '1A')
                    || (callerId === 'Contratto' && scheda.content.sottoTipoControllo === '1B')
                    || (callerId === 'Pagamenti' && scheda.content.sottoTipoControllo === '2' )
                    || callerId === 'InvioControllo'
                    ){
                        if (actions.length === 0){
                            console.log("Nessuna azione, inserisco almeno la view");
                            actions.push('view');
                        }
                }

                return actions;

            } catch (err) {
                console.error("getActionsControlliAmm, Error: ", err.message);
                return [];
            }


        }
    }

}