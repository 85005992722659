import { JSONPath as jpath } from 'jsonpath-plus';
import convenzioneAD from "./convenzione/assegnazioneDiretta.js";

export default {

    install(Vue) {
        class MapElement {
            constructor() {
                this.config = new Config();
            }
        }

        class Config {
            constructor() {
                this.label = '';
                this.tab = '';
                this.type = "string";
                this.readonly = false;
                this.mandatory = false;
                this.path = '';
            }
        }

        class Data {
            constructor() {
                this.note = '';
                this.edited = false;
                this.editTag = '';
            }
        }


        Vue.prototype.$getDefaultData = function() {
            return new Data();
        }

        Vue.prototype.$getDefaultConfig = function() {
            return new MapElement();
        }

        Vue.prototype.$setButton = function() {
            const user = getUserInfo();

            const roles = user.roles;
            return roles.indexOf("UfficioGestione-Operatore") > -1
        }

        Vue.prototype.$getUndeletedValues = getUndeletedValues;

        // per un dato id (array), determina la lista degli elementi validi
        // (cioè non eliminati)
        // gli elementi vengono cercati direttamente dentro la scheda
        // e per ognuno di essi si controlla se è eliminato nella configurazione
        function getUndeletedValues(id, scheda) {
            let validValues = [];
            if(!id || !scheda) {
                console.error('getUndeletedValues bad params', id, scheda);
            }
            let values = jpath({resultType: 'all'}, '$' + id, scheda);
            if(values.length === 0) {
                    return validValues;
            } else if(!scheda.dataEntryConfiguration) {
                // se non possiamo sapere se gli elementi sono eliminati, allora
                // si presuppone che siano tutti validi
                validValues = values;
            } else {
                for (const obj of values) {
                    let idMatch = obj.path.substr(1);
                    let conf = scheda.dataEntryConfiguration;
                    if(!conf[idMatch] || !conf[idMatch].data.deleted) 
                        validValues.push(obj);
                }
            }
            return validValues;
        }

        // Settaggio id per icone(Azioni) nelle liste
        Vue.prototype.$builtIdDinamically = function() {
            let id = ''
            for (let i = 0; i < arguments.length; i++) {
                id = id === '' ? arguments[i] : id + '-' + arguments[i]
              }
            return id
        }

        Vue.prototype.$disableAddBtnInVerticalComp = function(scheda, name) {
            let countValidElements = 0
            if(name === 'EsperienzePregresse') {
                const esperienzaId = "['esperienzePregresse'][*]['fineSvolgimento']";
                const validExperiences = getUndeletedValues(esperienzaId, scheda);
                countValidElements = validExperiences.length;
            }
            return countValidElements >= 5;
        }

        Vue.prototype.$setButtonNewScheda = function() {
            const user = getUserInfo();

            const roles = user.roles;
            let visibility = false
            for (let role of roles) {
                if(role.indexOf("Beneficiario-Operatore") > -1) {
                    visibility = true
                    break;
                }
            }
            return visibility
        }
        Vue.prototype.$getUserInfo = getUserInfo;

        function getUserInfo() {
            const sd = sessionStorage.getItem('sessionData')
            if (!sd) {
                console.error('Empty session storage in generic functions');
                return {}; // no session data, error
            }
            let user = {};
            const ss = JSON.parse(sd);
            if (!ss) {
                return user;
            }
            user = ss.userInfo;
            
            const ss1 = sessionStorage.getItem('currentProfile');
            if(!ss1) {
                return user;
            }
            // sovrascrivo gruppo e ruolo con quelli del current profile
            const currentProfile = JSON.parse(ss1);
            if(currentProfile) {
                user.groups[0] = currentProfile.group;
                user.roles[0]  = currentProfile.role;
            }
            return user;
        }

        Vue.prototype.$getUserGroupId = function() {
            return getUserInfo().groups[0];
        }

        Vue.prototype.$getUserRole = function() {
            return getUserInfo().roles[0];
        }



        Vue.prototype.getIdBeneficiario = function() {
            const user = getUserInfo();
            let groupBeneficiario = user.groups[0];
            const groupSplit = groupBeneficiario.split('/');
            const lastIndex = groupSplit.length - 1;
            return groupSplit[lastIndex];
        }

        Vue.prototype.$getUserRoles = function() {
            return getUserInfo().roles;
        }

        // funzione viewIct 
        function viewIct(role, parereIct, actions) {
            if (parereIct && role.match('trategico')) {
                if (actions.indexOf('view') === -1) {
                    actions.push('view');
                }
            }
        }
        // questa funzione stabilisce se i beneficiari (operatore o approvatore) di
        // un progetto call for proposal (che quindi prevede termini di presentazione)
        // possono ancora fare salvaInBozza o salvaEInvia
        // se succede un problema, il comportamento di default prevede che
        // si possa salvare e/o inviare (return true)
        Vue.prototype.$canSaveSendProject = function(salvataggio, bando, stato, vistoDa) {
            if(!salvataggio || ! bando || !stato || !vistoDa) {
                console.error('canSaveSendProject bad params', salvataggio, bando, stato, vistoDa);
                return true;
            }

            // 1) se il bando non è call for proposal non ci sono scadenze,
            // quindi si può fare salva&invia
            const modalitaDiAccesso = bando.modalitaDiAccesso;
            if(modalitaDiAccesso !== 'Call for proposal')
                return true;
            // ricavo il gruppo di appartenenza
            const userInfo = getUserInfo();
            if(!userInfo && !userInfo.groups) {
                console.error('canSaveSendProject cannot get userInfo');
                return true;
            }
            const group = userInfo.groups[0];
            // 2) se l'utente non appartiene al gruppo beneficiari allora può salvare/inviare
            if(!group.match('eneficiar'))
                return true;

            // preparo i parametri da passare alla funzione bandoIsStillValid
            const dateFineBando = new Date(this.bando.dataFineProposte);
            const oraFineBando = bando.oraFineProposte;
            const dataInizioBando = bando.dataInizioProposte;

            // 3) se lo stato è bozza o rinviato per modifiche (quindi ancora non in attuazione!), allora
            // la funzione già usata altrove determina se il bando è ancora valido:
            // in quel caso si può salvare/inviare
            if(stato.match('In bozza') || stato === 'Rinviato per modifiche')
                return bandoIsStillValid(dateFineBando, oraFineBando, dataInizioBando);
            // comportamento di default: è possibile salvare/inviare
            return true;
        }

        Vue.prototype.$bandoIsStillValid = bandoIsStillValid;

        function bandoIsStillValid(dateFineBando, oraFineBando, dataInizioBando) {
            let hh = oraFineBando.substring(0, 2); //hh max
            let mm = oraFineBando.substring(3, 5); //mm max
            let ss = oraFineBando.substring(6, 8); //ss max
            let fineBandoWithTime = new Date(dateFineBando.getFullYear(), dateFineBando.getMonth(), dateFineBando.getDate(),
                parseInt(hh),
                parseInt(mm),
                parseInt(ss));
            let inizioBando = new Date(dataInizioBando)
            let now = new Date();
            return now <= fineBandoWithTime && now >= inizioBando
        }

        Vue.prototype.$getActions = function(scheda) {
            let actions = [];

            if (!scheda || !scheda.taskInfo ||
                (!scheda.schedaBando && !scheda.infoBando)) {
                console.error('getActions: no scheda', scheda);
                return actions; // null param, error
            }
            const user = getUserInfo();

            const group = user.groups[0];
            let temp = group.match(/\w+$/);
            let subgroup = '';
            if (temp.length === 1) {
                subgroup = temp[0];
            }

            const roles = user.roles;
            let candidateRole = '';

            temp = scheda.taskInfo.taskDefinitionKey.match(/_(\w+)_(.+)$/);
            if (temp.length === 3) {
                candidateRole = temp[2];
            }
            let schedaBando;
            let statoScheda;
            if (scheda.schedaBando)
                schedaBando = scheda.schedaBando;
            else if (scheda.infoBando)
                schedaBando = scheda.infoBando;
            else {
                console.error('getActions error: no schedabando', scheda);
                return actions;
            }

            statoScheda = schedaBando.stato;
            const parereIct = schedaBando.richiestoParereICT;

            let infoSchedaAssigne = scheda.taskInfo.taskAssignee
            for (let role of roles) {
                // operatore can view, and edit only inBozza
                if (role.match('UfficioGestione-Operatore')) {
                    if (statoScheda.match('ozza') || statoScheda.match('odifiche'))
                        actions.push('edit', 'edit_in_list_bandi', 'save', 'send', 'askIctAdvice', 'delete');
                    else
                        actions.push('view');
                    return actions;
                }
            }
            // other possible actions: add, delete, complete, approve
            // candidate role can edit
            if (roles.indexOf(candidateRole) !== -1) { // Utente si trova

                // Bando assegnato al ruolo corrente
                if (infoSchedaAssigne === user.sub) {
                    actions.push('save', 'send', 'unclaim', 'edit_in_list_bandi');

                    // Bando assegnato a qualcun'altro con lo stesso ruolo
                } else if (infoSchedaAssigne && infoSchedaAssigne !== user.sub) {
                    actions.push('view');

                    // Bando NON assegnato
                } else {
                    actions.push('claim', 'view');
                }
                // return actions
            }

            switch (statoScheda) {
                case 'In bozza':
                case "Eliminato dall'utente":
                    break;
                case 'In verifica':
                    for (let role of roles) {
                        if (role.match('pprovatore')) { // approvatore can reject
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('reject');
                            }
                        }
                    }
                    break;
                case 'Rinviato per modifiche':
                    for (let role of roles) {
                        if (role.match('pprovatore')) { // approvatore can view this state
                            actions.push('view');
                        }
                    }
                    break;
                case 'In revisione Ufficio Gestione':
                    for (let role of roles) {
                        if (role.match('pprovatore')) { // approvatore can reject
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('reject');
                            }
                        }
                        viewIct(role, parereIct, actions)
                    }
                    break;
                case 'In valutazione Referente ICT / Task force':
                    for (let role of roles) {
                        if (role.match('trategico')) { // approvatore can reject
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('editIctAdvice');
                            }
                        }

                        if (role.match('estione')) { // Ufficio Gestione can view
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }
                    }
                    break;
                case 'In valutazione Direttore STA':

                    for (let role of roles) {

                        if (role.match('estione')) { // Ufficio Gestione can view
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                            break;
                        }
                        if (role.match('irettore')) { // Direttore STA can reject
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('reject');
                            }
                            break;
                        } else if (role.match('SegreteriaTecnicaAmministrativa-Ufficio')) {
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                            break;
                        }
                        viewIct(role, parereIct, actions)
                    }
                    break;
                case 'In valutazione Autorità Responsabile':
                    for (let role of roles) {
                        // Ufficio Gestione and Direttore STA can view
                        if (role.match('estione') || role.match('SegreteriaTecnicaAmministrativa')|| role.match('isualizzatore')) {
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                            break;
                        }
                        if (role.match('esponsabile')) { // AR can reject
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('reject');
                            }
                            break;
                        }
                        viewIct(role, parereIct, actions)
                    }
                    break;
                case 'Approvato':
                    // Ufficio Controlli can view
                    if (group.match('ontrolli')) {
                        if (actions.indexOf('view') === -1) {
                            actions.push('view');
                        }
                        break;
                    }
                    for (let role of roles) {
                        // Ufficio Gestione, Direttore STA and AR can view
                        if (role.match('estione') || role.match('SegreteriaTecnicaAmministrativa') ||
                            role.match('esponsabile') || role.match('isualizzatore')) {
                            if (actions.indexOf('view') === -1 && actions.indexOf('unclaim') === -1) {
                                actions.push('view');
                            }
                            break;
                        }
                        viewIct(role, parereIct, actions)
                    }
                    break;
                case 'Graduatoria approvata':
                case 'Inviato':
                    {
                        // Ufficio Controlli e Ufficio Economico can view
                        if (group.match('ontrolli') || group.match('conomicoFinanz')) {
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                            break;
                        }

                        for (let role of roles) {
                            // Ufficio Gestione, Direttore STA and AR can view
                            if (role.match('estione') || role.match('SegreteriaTecnicaAmministrativa') ||
                                role.match('esponsabile')  || role.match('isualizzatore')) {
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                break;

                            }
                            viewIct(role, parereIct, actions)
                        }
                        let beneficiari = schedaBando.idBeneficiari;
                        if (beneficiari != null && beneficiari.indexOf(subgroup) !== -1) {
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                            // In lista_Bandi il beneficiario OPERATORE può aggiungere un Progetto
                            // se il dato bando non è ancora "scaduto"
                            // oraFineProposte è l'orario max di presentazione
                            for (let role of roles) {
                                if (!role.match('eneficiario-Operatore')) {
                                    continue;
                                }
                                let modalitaDiAccesso = scheda.infoBando.modalitaDiAccesso
                                let dateFineBando = new Date(scheda.infoBando.dataFineProposte);
                                let oraFineBando = scheda.infoBando.oraFineProposte
                                let dateInizioBando = scheda.infoBando.dataInizioProposte
                                if (modalitaDiAccesso == 'Assegnazione diretta'
                                    || (dateFineBando && oraFineBando && Vue.prototype.$bandoIsStillValid(dateFineBando, oraFineBando, dateInizioBando))) {
                                    actions.push('new_project'); // Push Icon
                                }
                            }
                        }
                    }
                    break;
                default:
                    console.error('unmanaged state: ', statoScheda, scheda);
                    break;
            }
            // ict puo vedere se il suo parere e richiesto sempre il bando in qualsiasi stato lui si trovi
            return actions;

        }
 
        function visibilityAllApprovedCards(role, actions) {
            if ((role.match('eneficiario-Operatore')     ||  //BEN-OPER
                role.match('eficiario-Approvatore')     ||  //BEN-APPR
                role.match('fficioGestione-Operatore')   ||  //ISF-OPER
                role.match('fficioGestione-Approvatore')||  //ISF-APPR
                role.match('egreteriaTecnicaAmminis')   ||  //DIRSTA
                role.match('esponsabile')               ||//AUTRESP
                role.match('isualizzatore')             ||   //viewer
                // TASK #10725
                role.match('fficioControlli-Operatore')   ||  //UFFCONTROLOPER
                role.match('fficioControlli-Approvatore')   ||  //UFFCONTROLAPPR
                role.match('fficioEconomicoFinanziario-Operatore')   ||  //UFFECONOMOPER
                role.match('fficioEconomicoFinanziario-Approvatore')  //UFFECONOMAPPR
                
                ) && actions.indexOf('view_project_in_attuazione')  === -1) {
                actions.push('view_project_in_attuazione')
            }
            return actions
        }

        // ATTIVAZIONE CONTROLLO SU MANDATORY FIELDS PER SCHEDA CONVENZIONE
        Vue.prototype.$isActiveCheckMandatory = function(scheda) {
            let checkActive = false
            let statoScheda = scheda && scheda.schedaProgetto && scheda.schedaProgetto.stato
            let taskDefKey = scheda && scheda.taskInfo && scheda.taskInfo.taskDefinitionKey
            const roles = Vue.prototype.$getUserInfo().roles;
            if (roles.indexOf('Beneficiario-Operatore') !== -1 &&
                statoScheda === 'Convenzione da firmare' &&
                taskDefKey === 'Progetto_convenzione_Beneficiario-Operatore') {
                checkActive = true
            }
            return checkActive
        }

        Vue.prototype.$projectGetActions = function(scheda) {
            let actions = [];

            if (!scheda || !scheda.taskInfo ||
                (!scheda.schedaProgetto && !scheda.infoProgetto)) {
                console.error('projectGetActions: no scheda', scheda);
                return actions; // null param, error
            }
            let schedaProgetto;
            if (scheda.schedaProgetto)
                schedaProgetto = scheda.schedaProgetto;
            else if (scheda.infoProgetto)
                schedaProgetto = scheda.infoProgetto;
            else {
                console.error('projectGetActions error: no schedabando', scheda);
                return actions;
            }

            const user = getUserInfo();

            const roles = user.roles;
           

            let statoScheda;
            if (schedaProgetto.stato == null) {
                statoScheda = 'In bozza'
            } else {
                statoScheda = schedaProgetto.stato
            }

            // claim unclaim
            let infoSchedaAssigne = scheda.taskInfo.taskAssignee


            let variableMap = scheda.taskInfo.variableMap;
            if(!variableMap)
                variableMap = {};

            let candidateRole = '';

            const group = user.groups[0];
            let temp;

            const procDefId = scheda.taskInfo.procDefId;

            temp = scheda.taskInfo.taskDefinitionKey.match(/_(\w+)_(.+)$/);
            if (temp.length === 3) {
                candidateRole = temp[2];
            }
            // ------------ IMPORTANTE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ---------------
            // DESCRIZIONE AZIONI 
            // NB: AZIONI: 'save'/'send' --------------------------------> SCHEDA PROGETTO
            // NB: AZIONI: 'send_istruttoria'/'save_istruttoria' --------> SCHEDA ISTRUTTORIA
            // NB: AZIONE: 'view_project_in_attuazione' -----------------> VISIBILITA' SCHEDE APPROVATE PER SCHEDA PROGETTO
            // NB: view_attuazione
            // NB: edit_modifica
            for (let role of roles) {

                //-----------VISIBILITA' SCHEDE APPROVATE------------------
                // Gestione visibilità schede Approvate (a partire da una scheda Progetto)
                //actions = visibilityAllApprovedCards(role, actions)
                //-----------VISIBILITA' SCHEDE APPROVATE------------------

                if (role.match('Beneficiario-Operatore')) {
                    actions.push('view_project_in_attuazione')
                    if (statoScheda.match('ozza') && candidateRole === 'Beneficiario-Operatore') {
                        actions.push('edit', 'edit_in_list_progetti', 'save', 'send', 'delete', 'deleteProject', 'add');
                    }
                    else if (statoScheda === ('Rinviato per modifiche'))      //quando il progetto viene respinto e ritorna in mano all'operatore può fare le stesse azione di quando è stato appena creato (cancellazione inclusa)
                        actions.push('edit', 'edit_in_list_progetti', 'save', 'send', 'delete', 'deleteProject', 'add');
                    else if (statoScheda === 'Convenzione da firmare' && candidateRole === 'Beneficiario-Operatore'){
                        // Progetto assegnato al ruolo corrente
                        if (infoSchedaAssigne === user.sub) {
                            actions.push('save', 'send', 'unclaim', 'edit_in_list_progetti', 'reject', 'edit');
                        } else {
                            actions.push('claim', 'view');
                        }
                    } else if (statoScheda === 'In Attuazione'){
                        actions.push('view', 'edit_start_modifica');
                        if(schedaProgetto.dataRimodulazione!=null &&actions.indexOf('showSostanziali') === -1){
                            actions.push('showSostanziali');
                        }
                    } 
                    
                    else if (statoScheda.match('modulazione') && candidateRole === 'Beneficiario-Operatore') {
                        actions.push('edit_in_list_modifica', 'edit', 'save', 'send', 'delete', 'cancelEdit', 'add');
                    } 
                    else{
                        if(procDefId && procDefId.match('odific'))
                            actions.push('view_in_list_modifica');
                        else
                            actions.push('view');
                    }
                        
                    return actions;
                }
                //l'approvatore ISF deve poter vedere il progetto che ha approvato che percorre l'iter di ProgettoValutazione
                // if (role.match('Beneficiario-Approvatore')) {
                    
                //     if (statoScheda=='In bozza'                 //l'approvatore deve vedere cosa stanno facendo gli operatori
                //         || statoScheda=='Inviato'               //l'approvatore deve vedere questo stato di invio all'ISF relativo
                //         || statoScheda=='Da prendere in carico' //l'approvatore deve vedere quando ISF inizia la valutazione 
                //         || statoScheda.includes('Istruttoria')  //l'approvatore deve vedere quando ISF inizia l'istruttoria
                //         || statoScheda.includes('Ammesso')      //l'approvatore deve vedere l'esito della valutazione
                //         || statoScheda.includes('Non Ammesso')
                //         ){    
                //             if (actions.indexOf('view') === -1) {
                //                 actions.push('view');
                //             }
                //     }
                // }
            }
            
            if (roles.indexOf(candidateRole) !== -1) {
                for (let role of roles) { 
                    actions = visibilityAllApprovedCards(role, actions)
                }
                // Progetto assegnato al ruolo corrente
                if (infoSchedaAssigne === user.sub) {
                    actions.push('unclaim');
                    if(group.match('eneficiari')) {
                        if(procDefId && procDefId.match('odific'))
                            actions.push('edit_in_list_modifica');
                        else
                            actions.push('edit_in_list_progetti');
                    }
                // Progetto assegnato a qualcun'altro con lo stesso ruolo
                } else if (infoSchedaAssigne && infoSchedaAssigne !== user.sub) {
                    if(procDefId && procDefId.match('odific'))
                        actions.push('view_in_list_modifica');
                    else if (actions.indexOf('view') === -1) {
                        actions.push('view');
                    }
                } else {
                    actions.push('claim');
                    if(procDefId && procDefId.match('odific'))
                        actions.push('view_in_list_modifica');
                    else if (actions.indexOf('view') === -1) {
                        actions.push('view');
                    }
                }
            }

            switch (statoScheda) {
                //stato/descrizione dichiarati in un ENUM nel backend: StatoProgetto.java
                case 'Concluso':
                    if (group.includes('ISF') 
                    || group.includes('SegreteriaTecnicaAmministrativa')
                    || group.includes('AutoritaResponsabile')
                    || group.includes('viewer')
                    || group.includes('UfficioControlli')
                    || group.includes('UfficioEconomico')
                    || group.includes('UfficioContabilitaSpeciale')
                    || group.includes('Beneficiari')){
                        if (actions.indexOf('view') === -1) {
                            actions.push('view');
                        }
                        if (actions.indexOf('edit_istruttoria') === -1)
                            actions.push('edit_istruttoria');
                    }
                    break;
                case 'In bozza':
                    for (let role of roles) {
                        if (role.match('pprovatore')) { // approvatore can reject sia al primo giro (progetto appena creato) che alla N esima revisione
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('save', 'send', 'reject');
                            }
                        }
                        if (role.match('peratore')) {
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('save', 'send', 'reject', 'edit_in_list_modifica');
                            }
                        }
                    }
                    break;
                case 'Rinviato per modifiche':
                    for (let role of roles) {
                        if (role.match('eficiario-Approvatore')) { // approvatore can reject sia al primo giro (progetto appena creato) che alla N esima revisione
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('save', 'send', 'reject');
                            } else { //se non ha task da completare deve almeno poter vedere il progetto respinto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                            }
                        }
                    }
                    break;
                case 'Inviato':
                    for (let role of roles) {
                        if (role.match('eficiario-Approvatore')) { // approvatore can reject
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('save', 'send', 'reject');
                            } else { // Beneficiario Approvatore deve poter vedere il progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                            }
                        }
                        if (role.match('fficioGestione-Operator')) { // operatore can reject
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('save', 'send', 'edit_in_list_progetti', 'reject');
                            } else { //l'operatore deve poter vedere il progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                            }
                        }
                        if(role.match('isualizzatore')){
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }
                        if (role.match('eneficiari')) {
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }
                    }
                    break;
                case "Da prendere in carico":
                    for (let role of roles) {
                        // WARNING questo caso non dovrebbe mai succedere: isf1appr non può
                        // prendere in carico la sched in questo stato
                        // qui è operatore che prende in carico e fa partire l'istruttoria
                        if (role.match('fficioGestione-Approvatore')) { // approvatore can reject
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('save', 'send', 'edit_in_list_progetti', 'reject');
                            } else { //l'operatore deve poter vedere il progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                            }
                        }
                        if (role.match('fficioGestione-Operatore')) { // approvatore can reject
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('send', 'edit_istruttoria');
                            }
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }
                        if(role.match('isualizzatore')){
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }
                        if (role.match('eneficiari')) { // Tutti i Beneficiari interessati possono vedere SOLO il progetto
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }
                    }
                    break;
                
                case 'In Istruttoria':
                case 'Revisione Istruttoria':   //in fase di revisione le azioni coincidono con la prima istruttoria
                
                    for (let role of roles) {
                        if (role.match('eferenteStrategico-ReferenteStrategic')) {
                            //Se il referente ICT è stato coinvolto può visualizzare:
                            
                            if(variableMap.lavoriIct && variableMap.lavoriIct.value == 'true') {
                                // La scheda Progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                // La scheda Istruttoria (se prende in carico poi potrà inserire il suo parere)
                                actions.push('edit_istruttoria');
                            }
                        }
                        if (role.match('fficioControlli-')) {
                            //Se l'Ufficio COntrolli è stato coinvolto può visualizzare:
                            if(variableMap.derogatoria && variableMap.derogatoria.value == 'true') {
                                // La scheda Progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                // La scheda Istruttoria (se prende in carico poi potrà inserire il suo parere)
                                actions.push('edit_istruttoria');
                            }
                        }
                        if(role.match('isualizzatore')){
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                            actions.push('edit_istruttoria');
                        }
                        
                        if (role.match('askForceLie-TaskForceLi')) {
                            //Se il referente LIE è stato coinvolto può visualizzare:
                            if(variableMap.lavoriPubblici && variableMap.lavoriPubblici.value == 'true') {
                                // La scheda Progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                // La scheda Istruttoria (se prende in carico poi potrà inserire il suo parere)
                                actions.push('edit_istruttoria');
                            }
                        }

                        if (role.match('fficioGestione-Operatore')) { // operatore can view and edit
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('edit_istruttoria');
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                            } else { //l'operatore deve poter vedere il progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                if(actions.indexOf('edit_istruttoria') === -1) {
                                    actions.push('edit_istruttoria');
                                }
                            }
                        }
                        
                        if (role.match('fficioGestione-Approvatore')) {
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('edit_istruttoria');
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                            } else { //l'aprovatore deve poter vedere il progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                //ma non l'istruttoria che sta compilando l'operatore
                            }
                        }
                        if (role.match('eneficiari')) { // Tutti i Beneficiari interessati possono vedere SOLO il progetto
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }
                    }
                    break;
                case 'Finalizzazione Istruttoria':
                    for (let role of roles) {
                        if (role.match('eferenteStrategico-ReferenteStrategic')) {
                            //Se il referente ICT è stato coinvolto può visualizzare:
                            
                            if(variableMap.lavoriIct && variableMap.lavoriIct.value == 'true') {
                                // La scheda Progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                // La scheda Istruttoria (se prende in carico poi potrà inserire il suo parere)
                                actions.push('edit_istruttoria');
                            }
                        }
                        if (role.match('fficioControlli-')) {
                            //Se l'Ufficio COntrolli è stato coinvolto può visualizzare:
                            if(variableMap.derogatoria && variableMap.derogatoria.value == 'true') {
                                // La scheda Progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                // La scheda Istruttoria (se prende in carico poi potrà inserire il suo parere)
                                actions.push('edit_istruttoria');
                            }
                        }
                        
                        if (role.match('askForceLie-TaskForceLi')) {
                            //Se il referente LIE è stato coinvolto può visualizzare:
                            if(variableMap.lavoriPubblici && variableMap.lavoriPubblici.value == 'true') {
                                // La scheda Progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                // La scheda Istruttoria (se prende in carico poi potrà inserire il suo parere)
                                actions.push('edit_istruttoria');
                            }
                        }
                        if(role.match('isualizzatore')){
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                            if(actions.indexOf('edit_istruttoria') === -1) {
                                actions.push('edit_istruttoria');
                            }
                        }
                        if (role.match('fficioGestione-Operatore')) { // approvatore view and edit
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('edit_istruttoria');
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                            } else { //l'operatore deve poter vedere il progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                if(actions.indexOf('edit_istruttoria') === -1) {
                                    actions.push('edit_istruttoria');
                                }
                            }
                        }
                        // TASK #14377: retrocompatibilità con rimodulazione senza istruttoria
                        const procCondition = scheda.taskInfo && scheda.taskInfo.procDefId
                                                && scheda.taskInfo.procDefId.startsWith('ProgettoModifica1');

                        if (role.match('fficioGestione-Approvatore') || (role.match('AutoritaResponsabile') && procCondition)) {
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('send_istruttoria', 'edit_istruttoria');
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                            } else { //l'operatore deve poter vedere il progetto
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                                if(actions.indexOf('edit_istruttoria') === -1) {
                                    actions.push('edit_istruttoria');
                                }
                            }
                        }
                        if (role.match('eneficiari')) { // Tutti i Beneficiari interessati possono vedere SOLO il progetto
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }
                    }
                    break;
                case 'Non Ammesso':
                    {
                        if (actions.indexOf('view') === -1) {
                            actions.push('view');
                        }
                        
                        for (let role of roles) {
                            if (role.match('fficioGestione') ||
                                role.match('egreteriaTecnicaAmminis') ||
                                role.match('esponsabile')  || role.match('isualizzatore'))  {  // Aggiunta Visibilità per DIRSTA AUTRESP (BUG #6409)
                                if (actions.indexOf('edit_istruttoria') === -1) {
                                    actions.push('edit_istruttoria');
                                }
                            }
                            // Gestione visibilità Progetto & Istruttoria ruoli (refstrat, reflie e uffcontrol)
                            const infoProgetto = scheda.infoProgetto;
                            let usersVisitedScheda = infoProgetto && infoProgetto.vistoDa && infoProgetto.vistoDa.length > 0 ? infoProgetto.vistoDa : []
                            const vistoDa = usersVisitedScheda.map(item => {return item.preferred_username});
                            let preferred_username = user.preferred_username
                            if ((role.includes('fficioControlli') ||
                                role.match('askForceLie-TaskForceLi') ||
                                role.match('eferenteStrategico-ReferenteStrategic')) &&
                                vistoDa.includes(preferred_username)) {
                                    actions.push("edit_istruttoria")
                                    if (actions.indexOf('view') === -1) {
                                        actions.push('view');
                                    }
                            }
                        }
                    }
                    break;
                case 'Ammesso':
                    for (let role of roles) {
                        // Gestione visibilità Progetto & Istruttoria ruoli (refstrat, reflie e uffcontrol)
                        const infoProgetto = scheda.infoProgetto;
                        let usersVisitedScheda = infoProgetto && infoProgetto.vistoDa && infoProgetto.vistoDa.length > 0 ? infoProgetto.vistoDa : []
                        let preferred_username = user.preferred_username
                        const vistoDa = usersVisitedScheda.map(item => {return item.preferred_username});
                        if ((role.includes('fficioControll') ||
                            role.match('askForceLie-TaskForceLi') ||
                            role.match('eferenteStrategico-ReferenteStrategic')) &&
                            vistoDa.includes(preferred_username)) {
                                actions.push("edit_istruttoria")
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                        }

                        // Aggiunta Visibilità per DIRSTA AUTRESP (BUG #6409)
                        if (role.match('egreteriaTecnicaAmminis') || role.match('esponsabile')  || role.match('isualizzatore'))  {  
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                            if (actions.indexOf('edit_istruttoria') === -1) {
                                actions.push('edit_istruttoria');
                            }
                        }

                        //se sono un ISF (inserito dentro il ciclo del multiruolo)
                        if (group.match('ISF')){
                            if (role.match('-Operatore')){
                                actions.push('start_convenzione_process', 'edit_istruttoria');
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                            }

                            if (role.match('-Approvatore')){
                                if (actions.indexOf('edit_istruttoria') === -1) {
                                    actions.push('edit_istruttoria');
                                }
                                if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                                }
                            }

                        }

                        //Aggiunta visibilità per Beneficiario-Approvatore (BUG #6441)
                        if (role.match('eficiario-Approvatore')) { // approvatore can always view
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }

                    }
                    break;
                case 'Decreto Ammissione Finanziamento da firmare':
                case 'Graduatoria provvisoria da firmare':
                    for (let role of roles) {
                        //se sono un ISF operatore
                        if (group.includes('ISF') && role.includes('fficioGestione-Operator')){
                            let found = false;
                            let foundEdit = false;
                            for (let action of actions){
                                if (action=='view'){
                                    found=true;
                                }
                                if (action=="edit_in_list_progetti"){
                                    foundEdit = true;
                                }
                            }
                            if (!found && !foundEdit && actions.indexOf('view') === -1)
                                actions.push('view');
                        }
                        
                        //Aggiunta visibilità per Beneficiario-Approvatore (BUG #6441)
                        if (role.includes('eficiario-Approvatore')) { // approvatore can always view
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }
                        if(role.match('isualizzatore')){
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                        }

                    }
                    break;
                case 'Decreto Ammissione Finanziamento in revisione Direttore STA':
                case 'Graduatoria in firma Direttore STA':
                    //se sono ISF
                    if (group.includes('ISF')){
                        if (actions.indexOf('view') === -1) {
                            actions.push('view');
                        }
                    }
                    if(group.match('viewer')){
                        if (actions.indexOf('view') === -1) {
                            actions.push('view');
                        }
                    }
                    break;
                case 'Decreto Ammissione Finanziamento in firma AR':
                    if(group.match("MINT") ||group.match('viewer')){
                        if (infoSchedaAssigne !== user.sub && actions.indexOf("view") === -1) {
                            actions.push("view");
                            }
                    }
                    break;
                case 'Graduatoria in firma AR':
                     //se sono ISF o dirsta
                     if (group.includes('ISF') || group.includes('SegreteriaTecnicaAmministrativa')||group.match('viewer')){
                        if (actions.indexOf('view') === -1) {
                            actions.push('view');
                        }
                    }
                    break;
                case 'Decreto Ammissione Finanziamento firmato':
                case 'Graduatoria definitiva firmata':
                     //se sono ISF, dirsta, autresp
                     if (group.includes('ISF') && roles[0] && roles[0].includes('fficioGestione-Approvator')
                        || group.includes('SegreteriaTecnicaAmministrativa')
                        || group.includes('AutoritaResponsabile')  || group.match('viewer')){
                        if (actions.indexOf('view') === -1) {
                            actions.push('view');
                        }
                    }
                    break;
                case 'Ammesso a Sovvenzione':
                        //se sono un ISF
                        if (group.includes('ISF') 
                           || group.includes('SegreteriaTecnicaAmministrativa')
                           || group.includes('AutoritaResponsabile') || group.match('viewer')){
                               if (actions.indexOf('view') === -1) {
                                   actions.push('view');
                               }
                       }
                       break;    
                case 'Convenzione da firmare':
                     //se sono ISF, autresp
                     if (group.includes('ISF')
                        || group.includes('AutoritaResponsabile')
                        || group.includes('viewer')
                        || roles[0].includes('eneficiari')){
                        let found = false;
                        let foundEdit = false;
                        for (let action of actions){
                            if (action=='view'){
                                found=true;
                                break;
                            }
                            if (action=='edit_in_list_progetti'){
                                foundEdit = true;
                            }
                        }
                        if (!found && !foundEdit)
                            actions.push('view');
                    }
                    break;
                case 'Convenzione in firma AR':
                     //se sono ISF, Beneficiario
                     if (group.includes('ISF') || group.includes('Beneficiari')|| group.includes('viewer')){
                        if (actions.indexOf('view') === -1) {
                            actions.push('view');
                        }
                    }
                    break;
                case 'Convenzione firmata':
                     //se sono un ISF
                     if (group.includes('ISF') 
                        || group.includes('SegreteriaTecnicaAmministrativa')
                        || group.includes('AutoritaResponsabile')
                        || group.includes('viewer')
                        || group.includes('Beneficiari')){
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                    }
                    break;
                case 'In Attuazione':
                    //se sono un ISF
                    if (group.includes('ISF') 
                    || group.includes('SegreteriaTecnicaAmministrativa')
                    || group.includes('AutoritaResponsabile')
                    || group.includes('UfficioControlli')
                    || group.includes('viewer')
                    || group.includes('UfficioEconomicoFinanziario')
                    || group.includes('UfficioContabilitaSpeciale')
                    || group.includes('Beneficiari')){
                        if (actions.indexOf('view') === -1) {
                            actions.push('view');
                        }
                        if(schedaProgetto.dataRimodulazione!=null &&actions.indexOf('showSostanziali') === -1){
                            actions.push('showSostanziali');
                        }
                        if (actions.indexOf('edit_istruttoria') === -1)
                            actions.push('edit_istruttoria');
                    }
                    for (let role of roles) {
                        actions = visibilityAllApprovedCards(role, actions)
                        if(role.match('fficioGestione-Operatore')){
                            actions.push("start_irr")
                        }
                    }
                    break;
                case "Eliminato dall'utente":
                    break;
                case 'In Rimodulazione':
                    for (let role of roles) {
                        if (role.match('eficiario-Approvatore')) {
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('save', 'send', 'reject');
                            }
                        }
                        if( role.match('fficioContabilitaSpeciale')){
                            actions.push('view_project_in_attuazione');
                            if (actions.indexOf( 'showSostanziali') === -1) {
                                actions.push( 'showSostanziali');
                            }
                        }
                    }
                    
                    break;
                case 'Rinviato per Modifiche Rimodulazione':
                    for (let role of roles) {
                        if (role.match('eficiario-Approvatore')) {
                            actions = visibilityAllApprovedCards(role, actions)
                            if (actions.indexOf('view_in_list_modifica') === -1) {
                                actions.push('view_in_list_modifica');
                            }
                        }
                        if( role.match('fficioContabilitaSpeciale')){
                            if (actions.indexOf( 'view_project_in_attuazione') === -1) {
                                actions.push('view_project_in_attuazione');
                            }
                            if (actions.indexOf( 'showSostanziali') === -1) {
                                actions.push( 'showSostanziali');
                            }
                        }
                    }
                    
                    break;
                case 'In Verifica Rimodulazione':
                    for (let role of roles) {
                        if (role.match('eficiario-Approvatore')|| role.match('isualizzatore')) {
                            actions.push('view_project_in_attuazione');
                            if (actions.indexOf('view_in_list_modifica') === -1) {
                                actions.push('view_in_list_modifica');
                            }
                            if (actions.indexOf( 'showSostanziali') === -1) {
                                actions.push( 'showSostanziali');
                            }
                        }
                        if( role.match('fficioContabilitaSpeciale')){
                            if (actions.indexOf( 'view_project_in_attuazione') === -1) {
                                actions.push('view_project_in_attuazione');
                            }
                            if (actions.indexOf( 'showSostanziali') === -1) {
                                actions.push( 'showSostanziali');
                            }
                        }
                        if (role.match('UfficioGestione-Operatore')) {
                            actions = visibilityAllApprovedCards(role, actions)
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('edit_in_list_modifica', 'save', 'send', 'reject', 'showSostanziali', 'askSostanziali');
                            } else if (actions.indexOf('view_in_list_modifica') === -1) {
                                actions.push('view_in_list_modifica',);
                            }
                            if (actions.indexOf( 'showSostanziali') === -1) {
                                actions.push( 'showSostanziali');
                            }
                        }
                        if (role.match('UfficioGestione-Approvatore') || role.match('esponsabile') || role.match('egreteriaTecnicaAmminis')) {
                            actions = visibilityAllApprovedCards(role, actions)
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('edit_in_list_modifica', 'save', 'send', 'reject', 'showSostanziali');
                            } else if (actions.indexOf('view_in_list_modifica') === -1) {
                                actions.push('view_in_list_modifica');
                            }
                            if (actions.indexOf( 'showSostanziali') === -1) {
                                actions.push( 'showSostanziali');
                            }
                        }
                        
                        
                        // azione aggiuntiva solo per isf approvatore e solo in questo stato
                        // WARNING: non mettere in else-if
                        if (role.match('UfficioGestione-Approvatore')) {
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('denyEdit');
                            }
                        }
                    }
                    break;
                case 'Finalizzazione Rimodulazione':
                    for (let role of roles) {
                        if (role.match('UfficioGestione-Operatore')) {
                            actions = visibilityAllApprovedCards(role, actions)
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('edit_in_list_modifica', 'save', 'send', 'showSostanziali');
                            } else if (actions.indexOf('view_in_list_modifica') === -1) {
                                actions.push('view_in_list_modifica');
                            }
                            else if (actions.indexOf( 'showSostanziali') === -1) {
                                actions.push( 'showSostanziali');
                            }
                        }
                        if( role.match('fficioContabilitaSpeciale')){
                            if (actions.indexOf( 'view_project_in_attuazione') === -1) {
                                actions.push('view_project_in_attuazione');
                            }
                            if (actions.indexOf( 'showSostanziali') === -1) {
                                actions.push( 'showSostanziali');
                            }
                        }
                        if (role.match('UfficioGestione-Approvatore')||role.match('isualizzatore')|| role.match('esponsabile')) {
                            actions = visibilityAllApprovedCards(role, actions)
                            if (actions.indexOf('view_in_list_modifica') === -1) {
                                actions.push('view_in_list_modifica');
                            }
                            if (actions.indexOf( 'showSostanziali') === -1) {
                                actions.push( 'showSostanziali');
                            }
                        }
                    }
                    break;
                case 'Modifica rifiutata':
                    break;
                case 'Modifica interrotta dal beneficiario':
                    break;
                default:
                    console.error('unmanaged state: ', statoScheda, schedaProgetto);
                    break;
            }
            return actions;
        }

        //------------------------ ISTRUTTORIA ----------------------------
        Vue.prototype.$istruttoriaGetActions = function(scheda){
            let actions = [];
            if (!scheda || !scheda.content.taskInfo ||
                (!scheda.idSchedaIstruttoria && !scheda.status)) {
                    console.error('getActions: no scheda', scheda);
                    return actions; // null param, error
            }

            const user = getUserInfo();
            const roles = user.roles;

            let candidateRole = '';

            let temp;
            
            temp = scheda.content.taskInfo.taskDefinitionKey.match(/_(\w+)_(.+)$/);

            if (temp) {
                if(temp.length === 3)
                    candidateRole = temp[2];
            }

            let statoScheda = '';
            if(scheda.status)
                statoScheda = scheda.status

            // claim unclaim
            const taskInfo = scheda.content.taskInfo;
            let infoSchedaAssigne = scheda.content.taskInfo.taskAssignee

            for(let role of roles){
                if (role.match('UfficioGestione-Operatore')) {
                    if (statoScheda.match('inalizzazione') && infoSchedaAssigne === user.sub)
                        actions.push('edit', 'edit_istruttoria', 'save_istruttoria', 'send_istruttoria');
                    else if (statoScheda.match('struttoria') && infoSchedaAssigne === user.sub)
                        actions.push('edit', 'edit_istruttoria', 'save_istruttoria', 'send_istruttoria', 'askIctAdvice', 'askLieAdvice');
                    else if (statoScheda.match('odifich') && infoSchedaAssigne === user.sub)
                        actions.push('edit', 'edit_istruttoria', 'save_istruttoria', 'send_istruttoria');
                    else
                        actions.push('view', 'edit_istruttoria');
                    return actions;
                }
               
              
            }
            
            if (roles.indexOf(candidateRole) !== -1) {
                // Progetto assegnato al ruolo corrente
                if (infoSchedaAssigne === user.sub) {
                    actions.push('save_istruttoria', 'send_istruttoria', 'unclaim', 'edit_istruttoria');

                    // Progetto assegnato a qualcun'altro con lo stesso ruolo
                } else if (infoSchedaAssigne != null && infoSchedaAssigne !== user.sub) {
                    actions.push('view');
                    
                    // Progetto NON assegnato
                } else {
                    actions.push('claim', 'view');
                }
            }

            switch (statoScheda) {
                case "In Istruttoria":
                    for (let role of roles) {
                        if (role.match('eferenteStrategico-ReferenteStrategic')) {
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('save_istruttoria', 'send_istruttoria', 'editIctAdvice')
                            }
                        }
                        if (role.match('askForceLie-TaskForceLi')) { 
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('save_istruttoria', 'send_istruttoria', 'editLieAdvice');
                            }
                        }
                        if (role.match('UfficioGestione-Operatore') && scheda.procedureDerogatorie) {
                            actions.push('askUffAdvice');
                        }
                        if (role.match('fficioControlli-')) { 
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('save_istruttoria', 'send_istruttoria', 'editUffAdvice');
                            }
                        }
                        
                    }
                    break;
                case 'Finalizzazione Istruttoria':
                    for (let role of roles) {
                        if (role.match('UfficioGestione-Operatore') && scheda.procedureDerogatorie) {
                            actions.push('askUffAdvice');
                        }
                        // TASK #14377: retrocompatibilità con rimodulazione senza istruttoria
                        const procCondition = taskInfo && taskInfo.procDefId
                                                && taskInfo.procDefId.startsWith('ProgettoModifica1');

                        if (role.match('fficioGestione-Approvator') || role.match('AutoritaResponsabile') && procCondition) { 
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('reject');
                            }
                        }
                    }
                    break;
                case 'Istruttoria in Approvazione AR':
                        for (let role of roles) {
                            const procCondition = taskInfo && taskInfo.procDefId
                                                    && taskInfo.procDefId.startsWith('ProgettoModifica1');
                            if (role.match('fficioGestione-Approvator') || role.match('AutoritaResponsabile') && procCondition) { 
                                if (infoSchedaAssigne === user.sub) {
                                    actions.push('reject');
                                }
                            }
                        }
                    break;
                default:
                    break;
            }
            return actions;
        }

        // ----------------------- EXECUTING BODY -------------------------


        Vue.prototype.$handleVisibilityNewExecuting = function() {
            const user = getUserInfo();

            const roles = user.roles;
            let visibility = false
            for (let role of roles) {
                if(role.indexOf("UfficioAcquisti-UfficioAcquisti") > -1) {
                    visibility = true
                    break;
                }
            }
            return visibility
        }

        Vue.prototype.$checkForIconCircle = function(scheda, idsToCheck) {
            let check = null // Check complessivo
            //  Per tutti gli idsToCheck, il check complessivo può avere questi 4valori:
            //  empty: almeno un valore non presente in scheda (undefined)
            //  SI: tutti i valori sono positivi
            //  NO: esiste almeno un valore negativo
            if(idsToCheck.length > 0) {
                let allValues = []
                for (const id of idsToCheck) {
                    const values = jpath("$" + id, scheda.content);
                    allValues.push(values[0])
                }
                if(allValues.indexOf(undefined) > -1) {
                    check = 'Incompleto'
                } else {
                    // Controllo sulla valutazione del punteggio di schedaIstruttoria
                    if(idsToCheck[0].indexOf('merito') !== -1) {
                        let valutazione = scheda.content.istruttoria.merito.valutazionePunteggio
                        check = valutazione < 60 ? 'Negativo' : 'Positivo'
                    } else {
                    // Per tutti gli altri controlli basta un NO per far diventare il check totalmente Negativo
                        check = allValues.indexOf('NO') > -1 ? 'Negativo' : 'Positivo'
                    }
                }
                return check
            }
            if (!scheda || !scheda.executingBody) {
                console.error('check Icon Circle: no executingBody', scheda);
                return check; // null param
            } else if(scheda.executingBody.parere != null) {
                let parere = scheda.executingBody.parere
                check = parere === 'true' ||  parere === true ? 'Positivo' : 'Negativo';
            }
            
            return check
        }

        Vue.prototype.$executingBodyGetActions = function(scheda) {
            let actions = [];
            if (!scheda || !scheda.taskInfo ||
                (!scheda.schedaProgetto && !scheda.infoProgetto)) {
                console.error('getActions: no scheda', scheda);
                return actions; // null param, error
            }
            let schedaProgetto;
            if (scheda.schedaProgetto)
                schedaProgetto = scheda.schedaProgetto;
            else if (scheda.infoProgetto)
                schedaProgetto = scheda.infoProgetto;
            else {
                console.error('executingBodyGetActions error: no scheda', scheda);
                return actions;
            }

            const user = getUserInfo();
            const roles = user.roles;

            let statoScheda = schedaProgetto.stato
            // claim unclaim
            let infoSchedaAssigne = scheda.taskInfo.taskAssignee

            let candidateRole = '';

            const group = user.groups[0];
            let temp;

            temp = scheda.taskInfo.taskDefinitionKey.match(/_(\w+)_(.+)$/);
            if (temp.length === 3) {
                candidateRole = temp[2];
            }
            //uabst vede sempre ed edita solo in bozza

            for (let role of roles) {
                // operatore can view, and edit only inBozza
                
                if (role.match('UfficioAcquisti-UfficioAcquisti')) {
                    if (statoScheda.match('ozza'))
                        actions.push('edit', 'edit_in_list_executing', 'save', 'send', 'delete');
                    else if (statoScheda.match('edazione'))
                        actions.push('edit_in_list_executing', 'save', 'send');
                    else if (statoScheda.match('odifich') && candidateRole.match('UfficioAcquisti-UfficioAcquisti'))
                        actions.push('edit', 'edit_in_list_executing', 'save', 'send');
                    else
                        actions.push('view');
                    return actions;
                }
            }
            // fine UfficioAcquisti
            if (roles.indexOf(candidateRole) !== -1) { // Utente si trova
                // Progetto assegnato al ruolo corrente
                if (infoSchedaAssigne === user.sub) {
                    actions.push('save', 'send', 'unclaim', 'edit_in_list_executing');

                    // Progetto assegnato a qualcun'altro con lo stesso ruolo
                } else if (infoSchedaAssigne && infoSchedaAssigne !== user.sub) {
                    actions.push('view');

                    // Progetto NON assegnato
                } else {
                    actions.push('claim', 'view');
                }
                // return actions
            }

            switch (statoScheda) {
                case 'In bozza':
                    // ISF non deve vedere nulla MAI per questo processo!
                    // if (group.includes("ISF")){
                    //     actions.push('view');
                    // }
                    break;
                case 'Rinviato per modifiche':
                case 'In valutazione Economico Finanziaria':
                    //Ufficio ecnomico operatore puo vedere l'intervento anche quando non è in carico a lui
                    for (let role of roles) {
                        if(role.indexOf("UfficioEconomicoFinanziario-Operatore") !== -1)
                            if (actions.indexOf('view') === -1 && 
                                infoSchedaAssigne !== user.sub) 
                                actions.push('view');
                        }
                    for (let role of roles) {
                        if(role.indexOf("UfficioEconomicoFinanziario-Operatore") !== -1 && infoSchedaAssigne === user.sub) {
                            actions.push('send', 'edit_parere_nota_EB');
                            //break;
                        }
                        if(role.indexOf("UfficioEconomicoFinanziario-Approvatore") !== -1 || role.match('SegreteriaTecnicaAmministrativa')) {
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('reject');
                            }
                        }
                    }
                    break;
                case 'In valutazione Direttore STA':
                    // ISF non deve vedere nulla MAI per questo processo!
                    // if (group.includes("ISF")){
                    //     actions.push('view');
                    // }
                    for (let role of roles) {
                        if (role.match('SegreteriaTecnicaAmministrativa')) { // STA can reject
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('reject');
                                break;
                            }
                        }
                    }
                    if (group.match('conomicoFinanz')) {
                        if (actions.indexOf('view') === -1) {
                            actions.push('view');
                        }
                        break;
                    }
                    break;
                case 'Redazione Appunto':
                    // ISF non deve vedere nulla MAI per questo processo!
                    // if (group.includes("ISF")){
                    //     actions.push('view');
                    // }
                    if (group.match('conomicoFinanz')) {
                        actions.push('view');
                        break;
                    }
                    break;
                case 'Intervento in Attuazione':
                    // ISF non deve vedere nulla MAI per questo processo!
                    // if (group.includes("ISF")){
                    //     actions.push('view');
                    // }
                    for (let role of roles) {
                        if (role.match('esponsabile') || role.match('isualizzatore') || role.match('SegreteriaTecnicaAmministrativa') || group.match('conomicoFinanz') ||  role.match('fficioContabilitaSpeciale')) {
                            if (actions.indexOf('view') === -1) {
                                    actions.push('view');
                            }
                            break;
                        }
                    }
                    break;
                case 'In valutazione Autorità Responsabile':
                    // ISF non deve vedere nulla MAI per questo processo!
                    // if (group.includes("ISF")){
                    //     actions.push('view');
                    // }
                    if (group.match('conomicoFinanz')|| group.match('viewer')) {
                        actions.push('view');
                        break;
                    }
                    for (let role of roles) {
                        if (role.match('SegreteriaTecnicaAmministrativa')) {
                            if (actions.indexOf('view') === -1) {
                                actions.push('view');
                            }
                            break;
                        }
                        if(role.match('esponsabile')) {
                            if (infoSchedaAssigne === user.sub) {
                                actions.push('reject');
                                break;
                            }
                        }
                    }
                    break;
                default:
                    console.error('unmanaged state: ', statoScheda, schedaProgetto);
                    break;
            }
            return actions
        }

        //in base all'utenza loggata decide cosa fare vedere sulla dashboard
        Vue.prototype.$getDashboardAreas = function() {
            let actions = [];

            const user = getUserInfo();

            let group = null;
            let subgroup = null;
            if (user && user.groups && user.groups.length > 0){
                group = user.groups[0];
                let temp = group.match(/\w+$/);
                if (temp.length === 1) {
                    subgroup = temp[0];
                }
            } else {
                console.error("User with no groups!?", user);
            }

            if(!group || !subgroup)
                return actions;

            if (group.includes("eneficiari")
                || (group.includes("MINT") && subgroup.includes("ISF"))){

                actions.push("MONITORING");
                actions.push("ASSESSMENT");
                actions.push("CALL");
                actions.push("PROJECT");
                actions.push("CONTROL");
                actions.push("IRREGOLARITA");

            } else if((group.includes("MINT") && subgroup.includes("SegreteriaTecnicaAmministrativa"))
                    || (group.includes("MINT") && subgroup.includes("AutoritaResponsabile"))
                    || (group.includes("others") && subgroup.includes("viewer"))
            ) {
                
                actions.push("MONITORING");
                actions.push("ASSESSMENT");
                actions.push("CALL");
                actions.push("EXECUTING_BODY");
                actions.push("PROJECT");
                actions.push("CONTROL");
                actions.push("IRREGOLARITA");

            } 
            else if(group.includes("MINT") && subgroup.includes("UfficioContabilitaSpeciale"))
            {
                //actions.push("MONITORING");
                //actions.push("ASSESSMENT");
               //actions.push("CALL");
                //actions.push("EXECUTING_BODY");
                actions.push("PROJECT");
                actions.push("CONTROL");
               // actions.push("IRREGOLARITA");
            } else if (group.includes("MINT") && (subgroup.includes("UfficioControlli"))){
                
                actions.push("PROJECT");
                actions.push("CONTROL");
                actions.push("IRREGOLARITA");
                actions.push("MONITORING");

            } else if (group.includes("MINT") && subgroup.includes("UfficioEconomicoFinanziario")) {

                actions.push("EXECUTING_BODY");
                actions.push("CONTROL");
                actions.push("PROJECT");
                actions.push("IRREGOLARITA");
                actions.push("MONITORING");

            } else if (group.includes("MINT") && subgroup.includes("ReferenteStrategico")) {
                
                actions.push("CALL");
                actions.push("PROJECT");
                actions.push("CONTROL");

            } else if (group.includes("MINT") && subgroup.includes("TaskForceLie")) {
                
                actions.push("PROJECT");
                actions.push("CONTROL");

            } else if (group.includes("MINT") && subgroup.includes("UfficioAcquisti")) {
                
                actions.push("EXECUTING_BODY");

            } else {
                console.error("getDashboardAreas: utente/gruppo non gestito!?", group, subgroup);
            }

            if(actions.length==0)
                console.error("!?Nessuna area di visibilità concessa al gruppo =", subgroup);

            return actions;
        }

        Vue.prototype.$getActionsGraduatoria = function(scheda) {

            let actions = [];
            const schedaConvenzione = scheda.schedaConvenzione
            if (!scheda 
                || !scheda.taskInfo 
                || !schedaConvenzione.progetti) 
            {
                console.error('getActions: no scheda', scheda);
                return actions; // null param, error
            }

            const user = getUserInfo();

            const group = user.groups[0];
            let temp;
            // let subgroup = '';
            // if (temp.length === 1) {
            //     subgroup = temp[0];
            // }
            
            const roles = user.roles;
            let candidateRole = '';
            
            temp = scheda.taskInfo.taskDefinitionKey.match(/_(\w+)_(.+)$/);
            if (temp && temp.length === 3) {
                //state = temp[1];
                candidateRole = temp[2];
            }

            let statoScheda;
            if (schedaConvenzione.statoConvenzione){
                statoScheda = schedaConvenzione.statoConvenzione
            }
            
            let infoSchedaAssigne
            if(scheda.taskInfo.taskAssignee){
                infoSchedaAssigne = scheda.taskInfo.taskAssignee
            }

            // for (let role of roles) {
            //     // operatore can view, and edit only inBozza
            //     if (role.match('UfficioGestione-Operatore')) {
            //         if (statoScheda.match('ozza')){
            //             actions.push('view', 'edit', 'edit_in_graduatoria', 'save', 'send');
            //             return actions;
            //         }
            //     }
            // }
            
            // other possible actions: add, delete, complete, approve
            // candidate role can edit
            
            if (roles.indexOf(candidateRole) !== -1) { // Utente si trova

                // Graduatoria assegnata al ruolo corrente
                if (infoSchedaAssigne === user.sub) {
                    actions.push('view', 'save', 'send', 'unclaim');

                    // Bando assegnato a qualcun'altro con lo stesso ruolo
                } else if (infoSchedaAssigne && infoSchedaAssigne !== user.sub) {
                    actions.push('view');

                    // Bando NON assegnato
                } else {
                    actions.push('claim', 'view');
                }

            }

            switch (statoScheda) {
              case "Graduatoria in bozza":
                for (let role of roles) {
                    if (role.match("fficioGestion")){
                        if (infoSchedaAssigne !== user.sub && actions.indexOf("view") === -1) {
                            actions.push("view");
                          }
                    }
                    if (role.match("fficioGestione-Operator")) {
                      // approvatore can reject
                      if (infoSchedaAssigne === user.sub) {
                        actions.push('edit', 'edit_in_graduatoria');
                      }
                    }
                  }
                break;
              case "Graduatoria provvisoria da firmare":
                for (let role of roles) {
                    if (role.match("fficioGestion")){
                        if (infoSchedaAssigne !== user.sub && actions.indexOf("view") === -1) {
                            actions.push("view");
                            }
                    }
                    if (role.match("pprovatore")) {
                        // approvatore can reject
                        if (infoSchedaAssigne === user.sub) {
                        actions.push("reject");
                        }
                    }
                }
                break;
              case "Graduatoria in firma Direttore STA":
                for (let role of roles) {
                    if (role.match("fficioGestion") || role.match("irettore")){
                        if (infoSchedaAssigne !== user.sub && actions.indexOf("view") === -1) {
                            actions.push("view");
                          }
                    }
                    if (role.match("irettore")) {
                      // AR can reject
                      if (infoSchedaAssigne === user.sub) {
                        actions.push("reject");
                      }
                    }
                }
                break;
              case "Graduatoria in Approvazione AR":
                for (let role of roles) {
                    if (role.match("fficioGestion") || role.match("irettore") || role.match("esponsabile") || role.match('isualizzatore')){
                        if (infoSchedaAssigne !== user.sub && actions.indexOf("view") === -1) {
                            actions.push("view");
                            }
                    }
                    if (role.match("esponsabile")) {
                    // AR can reject
                    if (infoSchedaAssigne === user.sub) {
                        actions.push("reject");
                    }
                    }
                }
                break;
              case "Graduatoria in firma AR":
                if(group.match("MINT") ||group.match('viewer')){
                    if (infoSchedaAssigne !== user.sub && actions.indexOf("view") === -1) {
                        actions.push("view");
                        }
                }
                  
                  break;

              case "Graduatoria definitiva firmata":
                if (group.match("MINT") ||group.match('viewer')) {
                  if (actions.indexOf("view") === -1) {
                    actions.push("view");
                  }
                }
                
                break;
              default:
                console.error(
                  "unmanaged state: ",
                  statoScheda,
                  schedaConvenzione
                );
                break;
            }

            return actions;

        }

        Vue.prototype.$getActionsPerProgettoConvenzione = function(scheda) {
          return convenzioneAD.assegnazioneDirettaUtils(scheda);
        }

        Vue.prototype.$getActionsPerBandoConvenzione = function(bando){

            // non controllo lo stato e la dataora fine del bando
            // perche' questa funzione suppone che i dati in tabella
            // siano stati gia' filtrati.
            // se cosi' non fosse, filtrare prima tali dati in modo che
            // solo i bandi con stato 'Inviato' e dataora fine < new Date()
            // vengano inclusi

            let actions = [];

            try {

                if (!bando 
                    ||!bando.taskInfo 
                    ||!bando.infoBando
                ) {
                    throw new Error("getActionsPerBandoConvenzione: oggetto bando non trovato o malformato.");
                }

                const user = getUserInfo();

                const roles = user.roles;
                let candidateRole
                let assignee = bando.taskInfo.taskAssignee

                let statoConvenzione
                if(bando && bando.schedaConvenzione && bando.schedaConvenzione.statoConvenzione)
                    statoConvenzione = bando.schedaConvenzione.statoConvenzione

                const s = bando.taskInfo.taskDefinitionKey.split('_')
                if (s.length !== 3) {
                    throw new Error("taskDefinitionKey malformato.")
                } else { candidateRole = s[2] }

                // l'user matcha con chi ha preso in carico il processo
                if (assignee === user.sub) {
                    return [
                    "unclaim",
                    "edit",
                    "edit_in_list_graduatoria",
                    "save",
                    "send",
                    "reject"
                    ];
                }

                for (const role of roles) {
                
                    // il processo2 deve essere avviato, solo isfOper puo' farlo
                    //Controllo che il processo non sia partito e che la statoConvenzione non ci sia
                    if (role == "UfficioGestione-Operatore" 
                            && candidateRole == "Nessuno-Nessuno" 
                            && !statoConvenzione) {
                        actions.push("start_graduatoria_process");
                        //break; // exit the loop
                        return actions
                    }

                    // user ha il profilo nel processo, add claim capabilities
                    // e il processo non e' gia' assegnato a qualcuno
                    if (role == candidateRole && (!assignee)) {
                      console.debug("current role matches");
                      actions.push("claim");
                    }
                }

                // if no edit capabilities, add view only
                if (!actions.includes('edit')) {
                    console.debug("no 'edit' cap, adding 'view'")
                    actions.push('view')
                }

                console.debug("actions array is:", actions)
                return actions

            } catch (err) {
                console.error("getActionsPerBandoConvenzione, Error: ",
                    err.message, ". arg 'bando'=", bando);
                return []
            }

        }

        Vue.prototype.$disableEditAddColors = function(schedaProgetto){

            if(!schedaProgetto)
                return false;
            const progetto = schedaProgetto.progetto;
            if(!progetto)
                return false;
            // le modifiche si colorano quando il progetto è passato oltre l'attuazione e quindi
            // possiede un codice progetto
            return (!progetto.codiceProgetto);
            /*
            if (schedaProgetto && schedaProgetto.progetto
                && schedaProgetto.progetto.codiceProgetto) {   //durante il processo di creazione la colorazione non deve vedersi mai fino allo stato "In Attuazione"
                // && schedaProgetto.vistoDa
                // && schedaProgetto.vistoDa.length > 0){
                //console.log("colorazione modifiche abilitata");
                return false;
            }

            if (schedaProgetto && schedaProgetto.stato && schedaProgetto.stato.includes("imodulazione")){ //keyword in tutti gli stati del processo di rimodulazione (vedi StatoProgetto.java)
                //console.log("colorazione modifiche abilitata");
                return false;
            }

            return true;
            */
        }

        Vue.prototype.$enableARwarnigMessage = function(schedaProgetto, lenModificheSost){
            return (schedaProgetto && schedaProgetto.stato.includes('odulazione') //se la scheda è in stato RIMODULAZIONE o VERIFICA_RIMODULAZIONE
                && lenModificheSost && lenModificheSost>0);
        }
    }
}