import Vue from 'vue';


export default {
  assegnazioneDirettaUtils: function(scheda) {
    let actions = [];

    if (!scheda || !scheda.taskInfo ||
        (!scheda.schedaProgetto && !scheda.infoProgetto)) {
        console.error('projectGetActions: no scheda', scheda);
        return actions; // null param, error
    }
    let schedaProgetto;
    if (scheda.schedaProgetto)
        schedaProgetto = scheda.schedaProgetto;
    else if (scheda.infoProgetto)
        schedaProgetto = scheda.infoProgetto;


    const user = Vue.prototype.$getUserInfo();

    const roles = user.roles;

    let statoScheda;
    if (schedaProgetto) {
      if (schedaProgetto.stato == null) {
        statoScheda = "In bozza";
      } else {
        statoScheda = schedaProgetto.stato;
      }
    } else return [] // fallback: empty actions array

    // claim unclaim
    let infoSchedaAssigne = scheda.taskInfo.taskAssignee


    let candidateRole = '';

    const group = user.groups[0];
    let temp = scheda.taskInfo.taskDefinitionKey.split('_')
    if (temp.length === 3) {
        candidateRole = temp[2];
    }
    
    // console.log("candidateRole=",candidateRole);
    // beneficiario vede sempre ed edita solo in bozza
    

    // IMPORTANTE!!!!
    // NB: Le azioni di 'save'/'send' riguardano la scheda Progetto
    // NB: Le azioni di 'send_istruttoria'/'save_istruttoria' riguardano la scheda Istruttoria
    for (let role of roles) {
        // operatore can view always, and edit only inBozza

        if (role.match('Beneficiario-Operatore')) {
            if (statoScheda.match('ozza') && candidateRole === 'Beneficiario-Operatore')
                actions.push('edit', 'edit_in_list_progetti', 'save', 'send', 'delete', 'add');
            else if (statoScheda.match('odifich'))
                actions.push('edit', 'edit_in_list_progetti', 'save', 'send', 'add');
            else if (statoScheda === 'Convenzione da firmare' && candidateRole === 'Beneficiario-Operatore'){
                // Progetto assegnato al ruolo corrente
                if (infoSchedaAssigne === user.sub) {
                    actions.push('save', 'send', 'unclaim', 'edit_in_list_sovvenzione', 'edit');
                } else {
                    actions.push('claim', 'view');
                }
            } else if (statoScheda === 'In Attuazione'){
                actions.push('view');
            } 
            else if (statoScheda === 'In Rimodulazione'){
                actions.push('view', 'edit_start_modifica', 'edit', 'save', 'send', 'delete', 'add');
            } else if (statoScheda === "Ammesso a Sovvenzione") {
              actions.push('start_sovvenzione_process');
              actions.push("view");
             }
            else
                actions.push('view');
            return actions;
        }
    }
    //Se l'utente si trova
    if (roles.indexOf(candidateRole) !== -1) {

        // Progetto assegnato al ruolo corrente
        if (infoSchedaAssigne === user.sub) {
            actions.push('save', 'send', 'unclaim');

            if (
              statoScheda === "Ammesso a Sovvenzione" &&
              roles[0].match("Beneficiario-Operatore")) {
                actions.push('edit')
            } else {
              actions.push("edit_in_list_sovvenzione");
              // remove 'view' action
              actions = actions.filter(function(i) {
                return i !== 'view';
              });
            }

            // if(group.match('eneficiari'))
            //     actions.push('edit_in_list_progetti');
        // Progetto assegnato a qualcun'altro con lo stesso ruolo
        } else if (infoSchedaAssigne && infoSchedaAssigne !== user.sub) {
            if (actions.indexOf('view') === -1) {
                actions.push('view');
            }
        } else {
            actions.push('claim');
            if (actions.indexOf('view') === -1) {
                actions.push('view');
            }
        }
    }

    switch (statoScheda) {
      //parte degli stato/descrizione dichiarati in un ENUM nel backend: StatoProgetto.java
      case "Ammesso":
        //se sono un ISF
        if (
          group.includes("ISF") &&
          roles[0] &&
          roles[0].includes("fficioGestione-Operator")
        ) {
          actions.push("start_convenzione_process", "edit");
        } else {
          if (actions.indexOf("view") === -1) {
            actions.push("view");
          }
        }
        break;
      case "Decreto Ammissione Finanziamento da firmare":
      case "Graduatoria provvisoria da firmare":
        //Aggiunta visibilità per Beneficiario-Approvatore (BUG #6441)
        if (group.includes("eneficiari")) {
            if (actions.indexOf('view') === -1) {
                actions.push('view');
            }
        }
        //se sono un ISF approvatore
        if (
          group.includes("ISF") &&
          roles[0] &&
          roles[0].includes("fficioGestione-Approv") &&
          infoSchedaAssigne === user.sub
        ) {
          actions.push("reject");
        }
        //se sono ISF, posso avere l'"occhio" di visualizzazione se non ho l'Edit ('edit_in_list_sovvenzione')
        if (group.includes("ISF")) {
          if (actions.indexOf("view") === -1 && actions.indexOf('edit_in_list_sovvenzione') === -1) {
            actions.push("view");
          }
        }
        break;
      case "Decreto Ammissione Finanziamento in firma Direttore STA":
      case "Decreto Ammissione Finanziamento in revisione Direttore STA":
      case "Graduatoria in firma Direttore STA":
        //Aggiunta visibilità per Beneficiario-Approvatore (BUG #6441)
        if (group.includes("eneficiari")) {
          if (actions.indexOf('view') === -1) {
              actions.push('view');
          }
        }
        //se sono ISF
        if (group.includes("ISF")) {
          if (actions.indexOf("view") === -1) {
            actions.push("view");
          }
        }
        //se sono un Direttore sta
        if (
          group.includes("MINT") &&
          roles[0] &&
          roles[0].includes("irettore") &&
          infoSchedaAssigne === user.sub
        ) {
          actions.push("reject");
        }
        break;
      case "Decreto Ammissione Finanziamento in firma AR":
        if(group.match("MINT") ||group.match('viewer')){
          if (infoSchedaAssigne !== user.sub && actions.indexOf("view") === -1) {
              actions.push("view");
              }
      }
      break;
      case "Graduatoria in firma AR":
        //Aggiunta visibilità per Beneficiario-Approvatore (BUG #6441)
        if (group.includes("eneficiari") ||group.match('viewer')) {
          if (actions.indexOf('view') === -1) {
              actions.push('view');
          }
        }
        //se sono ISF o dirsta
        if (
          group.includes("ISF") ||
          group.includes("SegreteriaTecnicaAmministrativa") ||
          group.includes("viewer")
        ) {
          if (actions.indexOf("view") === -1) {
            actions.push("view");
          }
        }
        //se sono AR
        if (
          group.includes("MINT") &&
          roles[0] &&
          roles[0].includes("utoritaResponsabil") && 
          infoSchedaAssigne === user.sub
        ) {
          actions.push("reject");
        }
        
        break;
      case "Decreto Ammissione Finanziamento firmato":
      case "Graduatoria definitiva firmata":
        if(  group.includes("viewer")){
          actions.push('view');
        }
        break;
      case "Ammesso a Sovvenzione":
        //Aggiunta visibilità per Beneficiario-Approvatore (BUG #6441)
        if (group.includes("eneficiari")) {
          if (actions.indexOf('view') === -1) {
              actions.push('view');
          }
        }
        //se sono ISF, dirsta, autresp
        if (group.includes("ISF") ||
          group.includes("SegreteriaTecnicaAmministrativa") ||
          group.includes("AutoritaResponsabile") ||
          group.includes("viewer")
        ) {
          if (actions.indexOf("view") === -1 && infoSchedaAssigne!== user.sub) {
            actions.push("view");
          }
        }
        break;
      case "Convenzione da firmare":
        //Beneficiari can view
        if (group.includes("Beneficiari") &&
          infoSchedaAssigne !== user.sub) {
          if (actions.indexOf("view") === -1) {
            actions.push("view");
          }
        }
        //se sono Beneficiario Approvatore
        if (
          group.includes("eneficiar") &&
          roles[0] &&
          roles[0].includes("eficiario-Approvatore") &&
          infoSchedaAssigne === user.sub
        ) {
          actions.push("reject");
          }

        //se sono ISF operatore
        if (
          group.includes("ISF") &&
          roles[0] &&
          roles[0].includes("fficioGestione-Operator") &&
          infoSchedaAssigne === user.sub
        ) {
          actions.push("reject");
        }

        //se sono un altro ISF, dirsta, autresp
        if (
          (group.includes("ISF") && infoSchedaAssigne !== user.sub) ||
          group.includes("SegreteriaTecnicaAmministrativa") ||
          group.includes("AutoritaResponsabile") ||
          group.includes("viewer")
        ) {
          if (actions.indexOf("view") === -1) {
            actions.push("view");
          }
        }
        break;
      case "Convenzione in firma AR":
        //se sono ISF, Beneficiario o dirsta
        if (group.includes("ISF") 
          || group.includes("Beneficiari")
          || group.includes("SegreteriaTecnicaAmministrativa")
          ||group.match('viewer')
          ) {
          if (actions.indexOf("view") === -1) {
            actions.push("view");
          }
        }
        break;
      case "Convenzione firmata":
        break;
      case "In Attuazione":
      case 'Concluso':
        if (group.includes('ISF') 
        || group.includes('SegreteriaTecnicaAmministrativa')
        || group.includes('AutoritaResponsabile')
        || group.includes('UfficioControlli')
        || group.includes('UfficioContabilitaSpeciale')
        || group.includes('UfficioEconomicoFinanziario')
        || group.includes("viewer")
        || group.includes('Beneficiari')){
            if (actions.indexOf('view') === -1) {
                actions.push('view');
            }
        }
        break;
      // seconda meta' per progetti call for proposal, lato beneficiario
      default:
        console.error("unmanaged state: ", statoScheda, schedaProgetto);
        break;
    }
    //console.log('ACTIONS', actions)
    return actions;
  },
};
