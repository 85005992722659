<template>
  <b-container>
    <span hidden>{{finale}}</span> <!-- campo fittizio per controllo finale -->
    <div v-for="field of Object.values(returnData.content)" :key="field.id">
      <div class="row justify-content-md-center">
        <div class="col-md-auto">
          <div v-html="htmlFromReply(field.id)"></div>
        </div>
      </div>
        <!-- CASISTICA SOLO AUTOCONTROLLO -->
        <div v-if="readOnlyData == null">
          <b-row>
            <b-col cols="6">
              <div v-if="getDocs(field).length > 0">
                <h3><label class="mr-sm-2">Documentazione di riferimento</label></h3>
                <ul v-for="(doc, index) in getDocs(field)" :key="index">
                  <li style="list-style: circle; font-size: 15px">{{doc.label}}</li>
                </ul>
              </div>  
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <h3><label class="mr-sm-2">Esito</label></h3>
              <b-select
                size="lg"
                v-model="returnData.content[field.id].value.esito"
                :state="computeState(field, 'esito')"
                :id="field.id">
                <b-form-select-option v-for="option in getEnum(field)"
                  :selected="option.value === field.value.esito"  
                  :key="option.value"
                  :value="option.value">
                  {{option.label}}
                </b-form-select-option>
              </b-select>
              <b-form-invalid-feedback :id="field.id+'-g-feedback'" force-show>
                <h5>{{errorStrings[field.id + 'esito']}}</h5>
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
            <b-row>
              <b-col cols="6">
                <h3><label class="mr-sm-2">Note</label></h3>
                <b-textarea :hidden="returnData.conf[field.id].config.suppressNote"
                  :state="isValid[field.id+'_nota'] === false ? false : null"
                  placeholder="Note"
                  :id="field.id+'_nota0'"
                  v-model="returnData.conf[field.id].data.note"
                  maxlength="3000"/>
                </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <h3><label class="mr-sm-2">Estremi di documentazione</label></h3>
                <b-form-checkbox
                  v-for="option in returnData.content[field.id].genericFiles"
                  v-model="returnData.content[field.id].value.chooseFiles"
                  :size="'lg'"
                  :key="option.fileId"
                  :value="option"
                  :state="getDocs(field).length > 0 ? computeState(field, 'chooseFiles') : null"
                  name="flavour-4a"
                  stacked
                  :id="option.fileId + '_estremi0'"
                >
                  {{ option.text }}
                </b-form-checkbox>
                <b-form-invalid-feedback :id="field.id+'-g-feedback'" force-show>
                  <h5>{{errorStrings[field.id + 'chooseFiles']}}</h5>
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" v-if="returnData.content[field.id].value.deduzioneOn === true">
                <h3><label class="mr-sm-2">Controdeduzione</label></h3>
                <b-textarea
                  placeholder="Inserisci qui"
                  :id="field.id + 'controdeduzione0'"
                  v-model="returnData.content[field.id].value.controDeduzione"
                  maxlength="500"/> 
              </b-col>
              <b-col cols="6"></b-col>
            </b-row>
        </div>
        <div v-else>
          <!-- CASISTICA AUTOCONTROLLO E CONTROLLO -->
          <b-row>
            <b-col cols="6">
              <div v-if="getDocs(field).length > 0">
                <h3><label class="mr-sm-2">Documentazione di riferimento</label></h3>
                <ul v-for="(doc, index) in getDocs(field)" :key="index">
                  <li style="list-style: circle; font-size: 15px">{{doc.label}}</li>
                </ul>
              </div>  
            </b-col>
            <b-col cols="6">
              <div v-if="getDocs(field).length > 0">
                <h3><label class="mr-sm-2">Documentazione di riferimento</label></h3>
                <ul v-for="(doc, index) in getDocs(field)" :key="index">
                  <li style="list-style: circle; font-size: 15px">{{doc.label}}</li>
                </ul>
              </div>  
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <h3><label class="mr-sm-2">Esito</label></h3>
              <b-select
                size="lg"
                v-model="readOnlyData.content[field.id].value.esito"
                disabled
                :id="field.id">
                <b-form-select-option v-for="option in getEnum(field)"
                  :selected="option.value === field.value.esito"  
                  :key="option.value"
                  :value="option.value">
                  {{option.label}}
                </b-form-select-option>
              </b-select>
            </b-col>
            <b-col cols="6">
              <h3><label class="mr-sm-2">Esito</label></h3>
              <b-select
                size="lg"
                v-model="returnData.content[field.id].value.esito"
                :state="computeState(field, 'esito')"
                :id="field.id">
                <b-form-select-option v-for="option in getEnum(field)"
                  :selected="option.value === field.value.esito"  
                  :key="option.value"
                  :value="option.value">
                  {{option.label}}
                </b-form-select-option>
              </b-select>
              <b-form-invalid-feedback :id="field.id+'-g-feedback'" force-show>
                  <h5>{{errorStrings[field.id + 'esito']}}</h5>
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <h3><label class="mr-sm-2">Note</label></h3>
              <b-textarea :hidden="readOnlyData.conf[field.id].config.suppressNote"
                :state="isValid[field.id+'_nota'] === false ? false : null"
                disabled
                placeholder="Note"
                :id="field.id+'_nota1'"
                v-model="readOnlyData.conf[field.id].data.note"
                maxlength="3000"/>
            </b-col>
            <b-col cols="6">
              <h3><label class="mr-sm-2">Note</label></h3>
              <b-textarea :hidden="returnData.conf[field.id].config.suppressNote"
                :state="isValid[field.id+'_nota'] === false ? false : null"
                placeholder="Note"
                :id="field.id+'_nota2'"
                v-model="returnData.conf[field.id].data.note"
                maxlength="3000"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <h3><label class="mr-sm-2">Estremi di documentazione</label></h3>
              <b-form-checkbox
                  v-for="option in readOnlyData.content[field.id].genericFiles"
                  v-model="readOnlyData.content[field.id].value.chooseFiles"
                  disabled
                  :size="'lg'"
                  :key="option.fileId"
                  :value="option"
                  name="flavour-4a"
                  stacked
                  :id="option.fileId + '_estremi1'"
              >
                  {{ option.text }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="6">
              <h3><label class="mr-sm-2">Estremi di documentazione</label></h3>
              <b-form-checkbox
                  v-for="option in returnData.content[field.id].genericFiles"
                  v-model="returnData.content[field.id].value.chooseFiles"
                  :size="'lg'"
                  :key="option.fileId"
                  :value="option"
                  :state="getDocs(field).length > 0 ? computeState(field, 'chooseFiles') : null"
                  name="flavour-4a"
                  stacked
                  :id="option.fileId + '_estremi2'"
              >
                  {{ option.text }}
              </b-form-checkbox>
              <b-form-invalid-feedback :id="field.id+'-g-feedback'" force-show>
                  <h5>{{errorStrings[field.id + 'chooseFiles']}}</h5>
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" >
              <div v-if="readOnlyData.content[field.id].value.controDeduzione">
                <h3><label class="mr-sm-2">Controdeduzione</label></h3>
                <b-textarea
                  disabled
                  :id="field.id + '_controdeduzione1'"
                  v-model="readOnlyData.content[field.id].value.controDeduzione"
                  maxlength="500"/>
              </div>
            </b-col>
            <b-col cols="6" v-if="returnData.content[field.id].value.esito === 'Negativo'">
              <h3><label class="mr-sm-2">Commenti</label></h3>
              <b-textarea
                :state="computeState(field, 'commenti')"
                placeholder="Inserisci qui"
                :id="field.id + '_commenti'"
                v-model="returnData.content[field.id].value.commenti"
                maxlength="500"/>
              <b-form-invalid-feedback :id="field.id+'-g-feedback'" force-show>
                  <h5>{{errorStrings[field.id + 'commenti']}}</h5>
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </div>
    </div>
    <div class="modal-footer">
        <b-button id="checklistSuccessButton" variant="success" v-for="bt in buttons" :hidden="!bt.show" :key="bt.name" 
        class="modal-default-button" @click="controlloFinale(bt.action, returnData)">
          {{bt.name}}
        </b-button>
        <b-button id="checklistDangerButton" variant="danger" class="modal-default-button"
                  @click="$bvModal.hide('modalCheck-' + parentName)">
          Chiudi
        </b-button>
    </div>
  </b-container>
</template>
  
<script>

import tool from '@/helpers/tools.js'
import notify from "@/helpers/notifications.js"

export default {
  name: "modalForm",
  props: {
    parentName: {
      type: String,
      required: true
    },
    fdata: {
      type: Object,
      required: true
    },
    fdata_viewMode: {
      type: Object
    },
    buttons:
    {
      type: Array,
    },
    types: {
      type: Object
    },
    attachmentsType: {
      type: Object
    }
  },
  data() {
    return {
      readOnlyData: null,
      selectedFiles: [],
      isValid: {},
      errorStrings: {},
      initialData: null,
      returnData: {},
      finale: false,
      enumTypes: {}
    };
  },

  computed: {
    computeState() {
      return (field, type) => {
        if( 
            ( (type === 'esito' || type === 'commenti')
            && !field.value[type]
            && this.isValid[field.id + type] == null) ||
            (type === 'chooseFiles'
            && field.value[type].length === 0
            && this.isValid[field.id + type] == null) ) {
            return null;
        }
        return this.validate(field.id, field.value[type], type);
      }
    },
    getDocs: function() {
      return field => {
        if(this.fdata.conf && this.fdata.conf[field.id].config.docsRiferimento && this.fdata.conf[field.id].config.docsRiferimento.length > 0) { // se docsRiferimento è un Array con length > 0
          let enumValues = [];
          //if(field.value == null || field.value == undefined) // valore di default
          for(let value of this.fdata.conf[field.id].config.docsRiferimento) {
            enumValues.push({'label': this.attachmentsType[value].label, 'value': value});
          }
          return enumValues;
        }
        return []
      }
    },

    getEnum: function() {
      return field => {
        const conf = this.returnData.conf[field.id]
        if(conf) {
          let enumValues = [];
          if(!field.value.esito) // valore di default
            enumValues.push({'label': 'Non selezionato', 'value': field.value.esito});
          for(let value of this.enumTypes[conf.config.type]) {
            enumValues.push({'label': value, 'value': value});
          }
          return enumValues;
        }
        return null;
      }
    },
    
    getType: function() {
      return field => {
        const conf = this.returnData.conf[field.id];
        if(conf)
            return conf.config.type;
      }
    },
  },
  created() {
    this.readOnlyData = tool.genericFunctions.cloneObject(this.$props.fdata_viewMode);

    this.enumTypes = this.types
    if(!this.$props.fdata || !this.$props.fdata.conf || !this.$props.fdata.content) {
      console.error('modalForm1 bad params', this.$props.fdata);
      notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
      );
      this.$bvModal.hide('modalForm1-' + this.parentName);
    }

    this.initialData = tool.genericFunctions.cloneObject(this.$props.fdata);
    this.returnData = tool.genericFunctions.cloneObject(this.$props.fdata);

    for(let key in this.$props.fdata.conf) {
      this.isValid[key] = null;
      this.isValid[key+'_nota'] = true;
      this.errorStrings[key] = '';
      this.errorStrings[key+'_nota'] = '';
    }
  },
  
  methods: {
    htmlFromReply (id) {
        return this.returnData.conf[id].config.descrizione
    },
    controlloFinale(action, data) {
      this.finale = !this.finale;
      let globalCheck = []
      let idField = Object.keys(this.returnData.content)[0]
      for(let key in this.returnData.content[idField].value) {
        // L'obbligatorietà del campo Commenti dipende se è stato scelto un esito 'Negativo'
        if(key === 'commenti' && this.returnData.content[idField].value.esito !== 'Negativo')
          break;
        // BUG #9416 --> Per esito 'N/A' i files non sono più obbligatori 
        if(key === 'chooseFiles' && this.returnData.content[idField].value.esito === 'N/A')
          break;
        let isValid = this.validate(idField, this.returnData.content[idField].value[key], key);
        globalCheck.push(isValid)
      }
      let checker = globalCheck => globalCheck.every(Boolean);
      if(checker(globalCheck))
        this.$emit(action, data);
      else
        return false;
    },

    validate(id, value, type) {
      let errors = [];

      // TASK #10721: checklist: commento obbligatorio in caso di esito negativo
      if(type === 'commenti') {
        if(this.returnData.content[id].value.esito === 'Negativo' &&
          (this.returnData.content[id].value.commenti === null ||
          this.returnData.content[id].value.commenti.toString().trim() === '')) {
          // errors = []
          errors.push("Campo obbligatorio")
        } else if(this.returnData.content[id].value.commenti.toString().trim() !== '') {
          this.errorStrings[id + 'commenti'] = ''
        }
      } else if(type === 'esito' && !value) {
        errors.push("Campo obbligatorio");
      }


      if(errors && errors.length > 0) {
        this.errorStrings[id + type] = errors[0];
        this.isValid[id + type] = false;
      } else {
        this.errorStrings[id + type] = '';
        this.isValid[id + type] = true;
      }
      return this.isValid[id + type];
    }
  }
}
</script>