<template>
  <div>
    <GenericList v-if="!isBusy"
      :id="'listaControlliLoco'" 
      :title="'Lista Controlli In Loco'"
      :busyTable="isBusy"
      :headerTable="headerTable"
      :dataTable="dataForList"
      :actionsInRow="this.$getActionsProgettiControlli"
      :actionsMapping="this.objMapping"
      :blockButton="isBusy"
      :sortBy="'codiceProgetto'"
      @clickActionList="doAction"/>
  </div>
</template>
<script>

import utils from '@/helpers/utils.js'
import notify from "@/helpers/notifications.js";
import GenericList from "@/components/genericList.vue";
import endpoints from "@/components/endpoints.vue";

export default {
  name: "listaControlliLoco",
  components: {
    GenericList
  },
  data() {
    return {
      dataForList: [],
      headerTable: [
        {
          key: "codiceProgetto",
          label: "Codice Progetto",
          sortable: true,
        },
        {
            key: "modalitaDiAccesso",
            label: "Modalità",
            sortable: true,
          },
          {
            key: "titoloProgetto",
            label: "Titolo",
            sortable: true,
          },
          {
            key:"dataConclusione",
            label:"Data Fine Progetto",
            filterByFormatted: true,
            formatter: this.dateFormatter,
            sortable:true
          },
          {
            key: "stato",
            sortable: true,
          },
          {
            key: "controlliAttivi",
            label: "Controlli in Corso",
            sortable: true,
          },
          {
            key: "Azioni",
            thClass: "thst",
          },
      ],
      objMapping: {
        view: "Visualizza Progetto",
        view_controlli_list: "Dettaglio Controlli"
      },
      isBusy: true
    }
  },
  
  mounted() {
    this.extractData();
  },
  
  methods: {
    toggleBtn() {
      this.blockButton = !this.blockButton
    },
    // QUESTO E' PER TESTARE LA DISABILITAZIONE DEI PULSANTI (CANCELLABILE)
    /* UNUSED
    doSomething(action, data) {
      if(action === 'createEntity') {
        console.log('TYPE ACTION From the child:', data)
        this.blockButton = !this.blockButton
        setTimeout(function () { this.toggleBtn() }.bind(this), 1000)
      }
      
    },
    fillData: function() {
      return this.tabledata;
    },*/
    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      let idProgetto = data.item.idProgetto;
      let codiceProgetto = data.item.codiceProgetto;

      switch (action) {
        case "view":
          this.$router.push({
            name: "schedaProgetto",
            params: { idProgetto: idProgetto },
          });
          break;
        case "view_controlli_list":
          this.$router.push({
            name: "listaControlliLocoDettaglio",
            params: { 
                      codiceProgetto: codiceProgetto,
                      idProgetto: idProgetto 
                    },
          })
          break;
        default:
          console.error("Unknown action!", action);
          break;
      }
    },
    dateFormatter(value) {
      if (!value) return null;
      return utils.formatDate(value);
    },

    
    extractData: async function() {
      let userIdGroup = this.$getUserGroupId();
      if(!userIdGroup.includes("Beneficiari")){
        this.headerTable.splice(3, 0,{key:"idBeneficiario", label:"Beneficiario", sortable:true,  formatter: "beneficiarioFormatter"});
      }
      this.isBusy = true;
      const url = endpoints.listActiveProjects;
      this.$get(url).then((result) => {
        const url2 = endpoints.getStatsControlliByType('inloco');
        this.$get(url2).then((stats) => {
          this.dataForList = result.map(
            item => {
              item.infoProgetto.controlliAttivi = stats[item.infoProgetto.codiceProgetto] ? stats[item.infoProgetto.codiceProgetto] : 0
              return item.infoProgetto
          });
          this.isBusy = false;
        }).catch(error => {
          //Provo a caricare la lista senza statistiche
          this.dataForList = result.map(item => { return item.infoProgetto })
          notify.error(
              notify.strings.error,
              "Impossibile caricare statistiche"
          );
          console.error("errore caricamento lista",error);
          this.isBusy = false;
        })
      }).catch(
          (error) => {
            notify.error(
              notify.strings.error,
              notify.strings.loadListError
            );
            console.error("errore caricamento lista", error);
            this.isBusy = false;
          }
      );
    }
  }
};
</script>

