import endpoints from "@/components/endpoints.vue";
import tools from '@/helpers/tools.js'
import Vue from "vue";



const state = {
  schede: {}
}

const getters = {
  getScheda(state) {
    return (payload) => {

      if (tools.objectHasValue(payload)
        && tools.objectHasValue(state.schede[payload.codiceProgetto])
        && tools.objectHasValue(state.schede[payload.codiceProgetto][payload.idPeriodo])
        ){
          return state.schede[payload.codiceProgetto][payload.idPeriodo]    
        }
        return null;
    }
  },
  getSchedaAssessment(state, getters1) {
    return (payload) => {
      let s = getters1.getScheda(payload)
      // console.log(s)
      if (s){
        return s.schedaAssessment
      }
      return null

    }
  },
  getTaskList(state) {
    return (codiceProgetto, idPeriodo) => {
      if(!codiceProgetto || !idPeriodo)
        return null;
      if(state.schede[codiceProgetto] && state.schede[codiceProgetto][idPeriodo]) {
        return state.schede[codiceProgetto][idPeriodo].taskList;
      }

      return null;
    }
  },
  getTaskId(state, getters1) {
    return (codiceProgetto, idPeriodo) => {
        let task = getters1.getTaskList(codiceProgetto, idPeriodo);
        if(!task)
          return null;
      return task.id;
    }
  },
}

const mutations = {
  RESET(state) {
    state.schede = {};
  },

  CLEAR_TASKS(state) {
    for(let i in state.schede) {
      if(Object.values(state.schede[i]))
        Object.values(state.schede[i])[0].taskList = {};
    }
  },

  CLEAR_SCHEDE(state) {
    Object.values(state.schede).forEach(e => {
      e.schedaAssessment = null;
    });
  },

  CLEAR_SCHEDA_ASSESSMENT(state, payload) {
    if(!payload || !payload.codiceProgetto || !payload.idPeriodo)
    {
      console.error('cancellazione scheda assessment fallita', payload);
      return;
    }
    let progetto = payload.codiceProgetto;
    let periodo = payload.idPeriodo;
  

    if(!state.schede[progetto] || !state.schede[progetto][periodo]) {
      console.error('cancellazione: scheda non trovata', payload);
      return;
    }
    state.schede[progetto][periodo].schedaAssessment = null;
  },
  ADD_SCHEDA_NOTE_RISERVATE(state, payload) {
    let cod = payload.codiceProgetto
    let periodo = payload.idPeriodo
    state.schede[cod][periodo].schedaNoteRiservate = payload.schedaNoteRiservate;
  },
  SET_SCHEDA_ASSESSMENT(state, payload) {
      let temp = state.schede[payload.codiceProgetto];
      if(!temp)
        state.schede[payload.codiceProgetto] = {};
      temp = state.schede[payload.codiceProgetto][payload.idPeriodo];
      if(!temp)
        state.schede[payload.codiceProgetto][payload.idPeriodo] = {};

      state.schede[payload.codiceProgetto][payload.idPeriodo].schedaAssessment = payload.scheda;
    },

  SET_TASK(state, payload) {
    let cod = payload.codiceProgetto
    let periodo = payload.idPeriodo
    let temp = state.schede[cod];
    if(!temp) {
      state.schede[cod] = {};
    }
    temp = state.schede[cod][periodo];
    if(!temp) {
      state.schede[cod][periodo] = {};
    }

    state.schede[cod][periodo].taskList = payload.taskList;
  },


  SET_SCHEDA(state, payload) {
    if (payload.codiceProgetto
      && payload.idPeriodo
    ){
      if (!state.schede[payload.codiceProgetto]){
        state.schede[payload.codiceProgetto] = {}
      }
      if (!state.schede[payload.codiceProgetto][payload.idPeriodo]){
        state.schede[payload.codiceProgetto][payload.idPeriodo] = {}
      }
      if (payload.scheda){
        state.schede[payload.codiceProgetto][payload.idPeriodo].schedaAssessment = payload.scheda
      }
      if (payload.taskList){
        state.schede[payload.codiceProgetto][payload.idPeriodo].taskList = payload.taskList
      }
    }
  }

}

const actions = {
  retrieveSchedaNoteRiservate(context, payload) {
    const keySchedaNoteRiservate = payload.noteRiservateKey;
    const version = payload.noteRiservateVersion;
    // WARNING: workaround per i vecchi processi
    if(!payload.noteRiservateKey || !payload.noteRiservateVersion) {
      return Promise.reject(new Error('note riservate non disponibili'));
    }
    return Vue.prototype.$get(endpoints.noteRiservate(keySchedaNoteRiservate), {version: version})
      .then(
      result => {
        if(result) {
          let schedaNoteRiservate = result;
          context.commit('ADD_SCHEDA_NOTE_RISERVATE', {
            schedaNoteRiservate: schedaNoteRiservate,
            codiceProgetto: payload.codiceProgetto,
            idPeriodo: payload.idPeriodo
          });
          console.log('result schedaNoteRiservate ' + keySchedaNoteRiservate, schedaNoteRiservate);
        } else {
          console.log('no results schedaNoteRiservate ' + keySchedaNoteRiservate);
        }
      },
      error => { throw error; }
      );
  },
  claimTask(context, id) {
    return Vue.prototype.$post(endpoints.claim(id))
    .then(
      result => {
        return result;
      },
      error => {
        if(error.response && error.response.data)
              return error.response.data;
        return error;
      }
      );
  },

  unclaimTask(context, id) {
    return Vue.prototype.$post(endpoints.unclaim(id))
    .then(
        result => {
          return result;
        },
        error => {
          throw error;
        }
      );
  },
  
  retrieveSingleTask(context, id) {
    return Vue.prototype.$get(endpoints.task(id))
    .then(
      result => {
          let task = result;
          context.commit('SET_TASK', {
            taskList: task,
            codiceProgetto: task.processVariables.codiceProgetto.value,
            idPeriodo: task.processVariables.idPeriodo.value
          });
          return result;
      },
      error => {
        throw error;
      }
    );
  },

  retrieveTasks(context) {
    // clear tasks at once!
    context.commit('CLEAR_TASKS');
    return Vue.prototype.$get(endpoints.tasksForMe)
    .then(
      result => {
        // console.log('aaa', result);
        for(const task of result) {
          if(!task.processDefinitionId.includes('ssessment')) { // add only tasks connected to schede assessment
            continue;
          }
          if(!task.processVariables || !task.processVariables.codiceProgetto || ! task.processVariables.idPeriodo) {
            continue; // variables contain periodo and progetto, cannot commit otherwise
          }
            
          context.commit('SET_TASK', {
            taskList: task,
            codiceProgetto: task.processVariables.codiceProgetto.value,
            idPeriodo: task.processVariables.idPeriodo.value
          });
        }
        return result;
      },
      error => { // TODO aggiungere throw
        console.error("retrieve tasks error: " + error);
        return [];
      }
    );
  },

  retrieveSchedaAssessment(context, payload) {
    return Vue.prototype.$get(endpoints.schedaAssessment(payload.key, payload.isFinal))
    .then(
      result => {
        context.commit('SET_SCHEDA_ASSESSMENT', {
          codiceProgetto: result.codiceProgetto, 
          idPeriodo: result.idPeriodo,
          scheda: result
        })
        return result;
      },
      error => {
        console.error("retrieveSchedaAssessment error: ", error.response);
        throw error;
      }
    )
  },

  // only one parameter
  localSaveSchedaAssessment(context, {
    codiceProgetto: codiceProgetto, 
    idPeriodo: idPeriodo,
    scheda: scheda
  }) {
    context.commit('SET_SCHEDA_ASSESSMENT', {
      codiceProgetto: codiceProgetto, 
      idPeriodo: idPeriodo,
      scheda: scheda
    })
  },

  clearSchedaAssessment(context, {
    codiceProgetto: codiceProgetto,
    idPeriodo: idPeriodo,
  }) {
    context.commit('CLEAR_SCHEDA_ASSESSMENT', {
      codiceProgetto: codiceProgetto,
      idPeriodo: idPeriodo,
    });
  },

  saveSchedaAssessment(context, {
    codiceProgetto: codiceProgetto,
    idPeriodo: idPeriodo,
    scheda: scheda
  }) {
    // first save it locally...
    context.commit('SET_SCHEDA_ASSESSMENT', {
      codiceProgetto: codiceProgetto,
      idPeriodo: idPeriodo,
      scheda: scheda.assessment
    })
    // ...then save it remotely
    return Vue.prototype.$post(endpoints.schedaAssessmentPost, scheda, { branch: 'assessment'})
    .then(
      result => {
        console.log("POST to save scheda assessment succeeded", scheda);
        
        if(result.assessment.result) {
          console.log("tasklist:",result.assessment.result)
          context.commit('SET_TASK', {
            taskList: result.assessment.result,
            codiceProgetto: codiceProgetto,
            idPeriodo: idPeriodo
          });
        }
        return result
      },
      error => {
        console.log("POST to save scheda got an ERROR", error.response);
        throw error;
      })
  },

  completeSchedaAssessment(context, {
    codiceProgetto: codiceProgetto,
    idPeriodo: idPeriodo,
    cronoprogKey: cronoprogKey,
    schemaVersioneCronoprog: schemaVersioneCronoprog,
    approvazione: approvazione
  }) {
    // get taskid e noteInBozza from scheda
    if (!tools.objectHasValue(context.state.schede[codiceProgetto])
      || !tools.objectHasValue(context.state.schede[codiceProgetto][idPeriodo])
      // || !tools.objectHasValue(state.schede[codiceProgetto][idPeriodo].taskList)
      // || !tools.objectHasValue(state.schede[codiceProgetto][idPeriodo].taskList.id)
    ){
      const errString = "scheda non trovata per progetto: " + codiceProgetto + " anno: " + idPeriodo;
      // restituisce una promise con il solo errore
      return new Promise((resolve, reject) => {
        reject({response: {status: errString}});
      });
    }

    let scheda = context.state.schede[codiceProgetto][idPeriodo];
    console.log("complete.scheda:",codiceProgetto, idPeriodo, scheda)
    if(!scheda.taskList) {
      // restituisce una promise con il solo errore
      const errString = "task non trovato per progetto: " + codiceProgetto + " anno: " + idPeriodo;
      return new Promise((resolve, reject) => {
        reject({response: {status: errString}});
      });
    }

    let taskId = scheda.taskList.id
    let data = {
      "variables": {
        "result": { "value": approvazione, "type": "boolean" },
        "cronoprogKey": { "value": cronoprogKey, "type":"string"},
        "schemaVersioneCronoprog": { "value": schemaVersioneCronoprog, "type":"integer"}
      }
    }

    console.log("taskId: " + taskId)
    // console.log("noteInBozza: " + scheda.noteInBozza)
    return Vue.prototype.$post(endpoints.taskComplete(taskId), data)
    .then(
      result => {
        console.log("POST to complete scheda succeeded")
        context.commit('SET_SCHEDA', {
          codiceProgetto: codiceProgetto,
          idPeriodo: idPeriodo,
        });

        return {
          code: 'OK',
          value: result
        };
      },
      error => {
        console.log("POST to save scheda got an ERROR", error.response);
        context.commit('SET_SCHEDA', {
          codiceProgetto: codiceProgetto,
          idPeriodo: idPeriodo,
        });
        throw error;
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
