<template>
  <div>
    <table class="table" :id="idTablePdf">
      <thead>
          <tr>
              <th scope="col" v-for="(column, index) in tdata.header" :key="index"> {{column}}</th>
              <th scope="col" v-if="tdata.colors===true">Stato*</th>
              <th scope="col" v-if="tdata.rows" :hidden="hideAction">Azioni</th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="(item, index) in tdata.rows" :key="index" :hidden="checkAddDelete(item)" v-bind:class="{addedCell: checkAdded(item), deletedCell: checkDeleted(item), changeInRow: checkChange(item)}">
              <td v-for="(column, indexColumn) in item.content" :key="indexColumn" :hidden="isHidden(tdata.header, item, indexColumn)" :title="isTooltip(item, indexColumn)"
                  v-bind:class="{substancialCell: checkSubstancial(item, indexColumn), modifiedCell: checkModified(item, indexColumn) }">{{myFormatOutput(item, indexColumn)}}</td>
              <td v-if="tdata.colors===true">
                  <fa-icon :icon="['fas', 'circle']"  v-bind:class="classFromStato(item)" :title="tooltipFromStato(item)"/>
              </td>  
              <td :hidden="hideAction">
                  <b-button variant="outline-primary" :title="x" v-for="x in item.actions" v-on:click="doAction(x, index, item)" :key="x">
                    <fa-icon v-if="x==='detail'" :icon="['fas', 'folder']" class="selector-icons"/>
                    <fa-icon v-if="x==='edit'" :icon="['far', 'edit']" class="selector-icons"/>
                    <fa-icon v-if="x==='view'" :icon="['far', 'eye']" class="selector-icons"/>
                    <fa-icon v-if="x==='delete'" :icon="['far', 'trash-alt']" class="selector-icons"/>
                  </b-button>
              </td>
          </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>

import utils from '@/helpers/utils.js'

export default {
  name: "DynamicActiveTable2",
  props: {
    idTablePdf: { type: String, default: () => '' },
    tdata: {
      type: Object,
      required: true
    },
    doAction: Function,
  },
  
  computed: {
    checkChange(){
      return (item) => {
        
        if (item.content.Campo || item.content.Valore) //escludo il colore di cancellazione per il modal di dettaglio
          return false;
        
        for(let value of Object.values(item.conf)) {
          if((value.data.deleted && !value.data.added) || (value.data.edited && !value.data.added))
            return true;
        }
        return false;
      }
    },
    checkAddDelete(){
      return (item) => {
        if(item && item.conf){
          for(let value of Object.values(item.conf)) {
            if(value.data.added && value.data.deleted){
              return true;
            }
          }
        }

        return false;
      }
    },
    checkDeleted(){
      return (item) => {
        
        if(item && item.content) { // && item.content.Campo && item.content.Valore){
          if (item.content.Campo || item.content.Valore) //escludo il colore di cancellazione per il modal di dettaglio
            return false;
          
          for(let value of Object.values(item.conf)) {
            if(value.data.deleted)
              return true;
          }
        }
        
        return false;
      }
    },

    checkAdded(){
      return (item) => {
        if(item && item.content) {// && item.content.Campo && item.content.Valore){

          if (item.content.Campo || item.content.Valore) //escludo il colore di aggiunta per il modal di dettaglio
            return false;

          for(let conf of Object.values(item.conf)) {
            if(conf.data.added)
              return true;
          }
        }
      
        return false;
      }
    },

    isHidden(){
      return (header, item, indexColumn) => {
        
        if (item && item.conf) { //controllo che esista la configurazione per evitare errori bloccanti in caso di mappa errata
          
          let it = item.conf[indexColumn]; //ricavo la configurazione specifica per questo campo
          if(it && it.config) {
            //se la label del campo in questione non è compresa fra quelle dell'header (campi da visualizzare) ritorno true e nascondo la colonna
            if(it.config.label && !header.includes(it.config.label)) {
              return true;
            }
            // else if(it.data.added && it.data.deleted) {
            //   return true;
            // }
          }

        }

        return false;
      }
    },

    isTooltip () {
      return (item, column) => {
        if (column === "Valore" && Object.values(item.conf)[0].data.note){   //seconda colonna di visualizzazzione del dettaglio
          return 'nota: ' + Object.values(item.conf)[0].data.note;
        } else {

          // workaround nel caso in cui la configurazione sia costruita sulle label e non sugli id
          for(let conf of Object.values(item.conf)) {
            if(conf.config.label === column && conf.data.note){
              return 'nota: ' + conf.data.note;
            }
          }

          if (item && item.conf && item.conf[column] && item.conf[column].data && item.conf[column].data.note) {
            return 'nota: ' + item.conf[column].data.note;
          }
        }

        return "";
      }
    },

    myFormatOutput(){
      return (item, column) => {
        if(item && item.conf && item.conf[column] && item.content && item.content[column]){

          return utils.formatOutput(item.conf[column], item.content[column]);

        } else if (column==="Valore" || column==="Descrizione") { //|| column==="Spesa"  //IF necessari per far funzionare la modal in view
          //applico la formattazione definita nell'unico elemento di content
          if (item.conf && item.content[column] != undefined && item.content[column] != null){
            return utils.formatOutput(Object.values(item.conf)[0], item.content[column]);
          } else {
            if(!item.conf)
              console.error("Configurazione non trovata!?", item);
            else
              if(column !== "Valore" && column !== "Descrizione")
                console.error("Dato non trovato!?", item.content, column);
            return "";
          }  
        } /*else if (column==="Dal") {
          //applico la formattazione definita nell'unico elemento di content
          return utils.formatOutput(Object.values(item.conf)[1], item.content[column]);
        } else if (column==="Al") {
          //applico la formattazione definita nell'unico elemento di content
          return utils.formatOutput(Object.values(item.conf)[2], item.content[column]);
        }
        */
        else {
          //console.log("nessuna configurazione/formattazione applicata");
          // workaround nel caso in cui la configurazione sia costruita sulle label e non sugli id
          for(let conf of Object.values(item.conf)) {
            if(conf.config.label === column){
              return utils.formatOutput(conf, item.content[column]);
            }
          }
          return item.content[column];
        }
      }
    },

    checkSubstancial(){
      return (item, column) => {
        if (column === "Valore"){   //seconda colonna di visualizzazzione del dettaglio
          return Object.values(item.conf)[0].data.isSubstancial;
        } else {
          // workaround nel caso in cui la configurazione sia costruita sulle label e non sugli id
          for(let conf of Object.values(item.conf)) {
            if(conf.config.label === column){
              return conf.data.isSubstancial;
            }
          }

          if (item && item.conf && item.conf[column] && item.conf[column].data){
            return item.conf[column].data.isSubstancial;
          }
        }

        return false;
      }
    },

    checkModified(){
      return (item, column) => {
        if (column === "Valore"){   //seconda colonna di visualizzazzione del dettaglio
          return Object.values(item.conf)[0].data.edited;
        } else {
          
          // workaround nel caso in cui la configurazione sia costruita sulle label e non sugli id
          for(let conf of Object.values(item.conf)) {
            if(conf.config.label === column){
              if(!(conf.data.added || conf.data.deleted))
                return conf.data.edited;
              else
                return false;
            }
          }

          if(item && item.conf && item.conf[column] && item.conf[column].data){
            const conf = item.conf[column].data;
            if(!(conf.added || conf.deleted))
              return conf.edited;
            else
              return false;
          }
        }

        return false;
      }
    },

    hideAction(){
      if(this.tdata && this.tdata.rows) {
        for(let row of this.tdata.rows) {
          if(row.actions && row.actions.length > 0)
            return false;
        }
      }
      return true;
    },

    classFromStato(){

      return (item) => {
        
        //ricavo la chiave dall'indice relativo di visualizzazione
        let chiavi = Object.keys(item.content);
        let chiave = chiavi[6]; //accedo alla 7ima colonna
        //console.log("chiave",chiave);
        let stato = item.content[chiave];
        
        //ritornare un colore in base allo stato
        if (stato==="Realizzato"){                  //completato nel passato
          return "crono-green-icon";
        } else if (stato==="Realizzato in parte") { //a cavallo di diversi trimestri oltre quello corrente
          return "crono-orange-icon";
        } else if (stato==="In realizzazione") {    //da completare nel trimestre corrente
          return "crono-yellow-icon";
        } else if (stato==="Da realizzare") {       //da completare nel futuro
          return "crono-grey-icon";
        } else { //eventuale errore
          console.error("stato="+stato+" non gestito con nessun colore!?");
          return "crono-white-icon";
        }
      
      }
    },

    tooltipFromStato(){

      return (item) => {
        
        //ricavo la chiave dall'indice relativo di visualizzazione
        let chiavi = Object.keys(item.content);
        let chiave = chiavi[6]; //accedo alla 7ima colonna
        //console.log("chiave",chiave);
        return item.content[chiave];
      }
    },
  },
}
</script>

<style scoped>
  img
  {
    cursor: pointer;
  }
</style>
<style lang="scss">
// .firstColumn {
//   width: 20%;
//   font-weight: bold;
//   border-right: 1px solid;
//   border-bottom: 2px dashed;
// }
// .otherColumn {
//   border-bottom: 2px dashed;
// }
// .action {
//   border-bottom: 2px dashed;
//   width: 20%;
// }
.table {
  thead {
        border: 1px solid $bluehome;
        background-color: $primary;
        color: $white;
        text-align: center;
    }
  td {
    border: 1px solid $bluehome;
  }

}
</style>
