<template>
    <div id="elencoProgetti" class="elencoProgetti">
    <div class="table-title">Scheda Monitoraggio {{title}} </div>
    <DynamicTable :tdata="this.bannerTable" />
    <b-tabs v-if="!this.disableTabs" card nav-class="tabs" content-class="content">
        <ColorLegend/>
        <b-tab no-body title="Generale" title-item-class="tab1" title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['generale'].name)">
            <generale
                :idTablePdf="conf_export_pdf['generale'].id_table_pdf"
                :salvaSchedaToStore="this.salvaSchedaToStore"
                :incomingData="this.inputData" 
                :infoTabName="conf_info_compiling['generale'].name_tab"
                :tabName="tabSelection"/>
        </b-tab>
        <b-tab no-body title="Cronoprogramma" title-item-class="tab1" title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['cronoprogramma'].name)">
            <cronoProgramma
                :idTablePdf="conf_export_pdf['cronoprogramma'].id_table_pdf"
                :salvaSchedaToStore="this.salvaSchedaToStore" 
                :incomingData="this.inputData" 
                :viewOnlyMode="this.viewOnlyMode"
                :infoTabName="conf_info_compiling['cronoprogramma'].name_tab"
                :tabName="tabSelection"/>
        </b-tab>
        <b-tab no-body title="Stato di Avanzamento"  title-item-class="tab1" title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['attivita'].name)">
            <collapse :name="conf_export_pdf['attivita'].nameCollapse">
                <attivita2
                    :idTablePdf="conf_export_pdf['attivita'].id_table_pdf"
                    :incomingData="this.inputData"
                    :idScheda="this.inputData.schedaMonitoraggio.progetto.idProgetto"
                    :dinamicIds="idsToCheck.attivita2.dinamicIds"
                    :rootId="idsToCheck.attivita2.rootId"
                    :tabelloneType="this.tabelloneType"
                    :infoTabName="conf_info_compiling['attivita'].name_tab"
                    :tabName="tabSelection"/>
            </collapse>
            <collapse :name="conf_export_pdf['scostamento'].nameCollapse">
                <scostamento v-if="loadComplete"
                    :idTablePdf="conf_export_pdf['scostamento'].id_table_pdf"
                    :salvaSchedaToStore="this.salvaSchedaToStore"
                    :incomingData="this.inputData"
                    :viewOnlyMode="this.viewOnlyMode"
                    :infoTabName="conf_info_compiling['scostamento'].name_tab"
                    :tabName="tabSelection"/>
            </collapse>
            <collapse :name="conf_export_pdf['criticita'].nameCollapse">
                <criticita2
                    :idTablePdf="conf_export_pdf['criticita'].id_table_pdf"
                    :incomingData="this.inputData"
                    :dinamicIds="idsToCheck.criticita2.dinamicIds"
                    :rootId="idsToCheck.criticita2.rootId"
                    :infoTabName="conf_info_compiling['criticita'].name_tab"
                    :tabName="tabSelection"/>
            </collapse>
            <collapse :name="conf_export_pdf['indicatori'].nameCollapse">
                <indicatori v-if="loadComplete"
                    :idTablePdf="conf_export_pdf['indicatori'].id_table_pdf"
                    :salvaSchedaToStore="this.salvaSchedaToStore" 
                    :incomingData="this.inputData" 
                    :viewOnlyMode="this.viewOnlyMode"
                    :infoTabName="conf_info_compiling['indicatori'].name_tab"
                    :tabName="tabSelection"/>
            </collapse>
        </b-tab>
        <b-tab no-body title="Procedure e Contratti" title-item-class="tab1" title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['procedure_contratti'].name)">
            <procedureContratti2
                    :idTablePdf="conf_export_pdf['procedure_contratti'].id_table_pdf"
                    :incomingData="this.inputData"
                    :dinamicIds="idsToCheck.procedureContratti2.dinamicIds"
                    :rootId="idsToCheck.procedureContratti2.rootId"
                    :infoTabName="conf_info_compiling['procedure_contratti'].name_tab"
                    :tabName="tabSelection"/>
        </b-tab>
        <b-tab no-body title="Pagamenti" title-item-class="tab1" title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['pagamenti'].name)">
            <pagamenti2
                    :idTablePdf="conf_export_pdf['pagamenti'].id_table_pdf"
                    :incomingData="this.inputData"
                    :dinamicIds="idsToCheck.pagamenti2.dinamicIds"
                    :infoTabName="conf_info_compiling['pagamenti'].name_tab"
                    :rootId="idsToCheck.pagamenti2.rootId"
                    :tabName="tabSelection"/>
        </b-tab>
        
        <b-tab no-body title="Localizzazione" title-item-class="tab1" title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['localizzazione'].name)">
            <localizzazione2
                :idTablePdf="conf_export_pdf['localizzazione'].id_table_pdf"
                :incomingData="this.inputData"
                :idScheda="this.inputData.schedaMonitoraggio.progetto.idProgetto"
                :dinamicIds="idsToCheck.localizzazione2.dinamicIds"
                :rootId="idsToCheck.localizzazione2.rootId"
                :tabelloneType="this.tabelloneType"
                :infoTabName="conf_info_compiling['localizzazione'].name_tab"
                :tabName="tabSelection"
                @refreshTab="refreshTab"/>
        </b-tab>
        <b-tab no-body title="Inventario" title-item-class="tab1" title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['inventario'].name)">
            <inventario2
                    :idTablePdf="conf_export_pdf['inventario'].id_table_pdf"
                    :incomingData="this.inputData"
                    :dinamicIds="idsToCheck.inventario2.dinamicIds"
                    :rootId="idsToCheck.inventario2.rootId"
                    :infoTabName="conf_info_compiling['inventario'].name_tab"
                    :tabName="tabSelection"
                    @refreshTab="refreshTab"/>
        </b-tab>
        <b-tab title="Invio" title-item-class="tab1" title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['invio_mandatory'].name)">
            <invio v-if="loadComplete"
                :salvaSchedaToStore="this.salvaSchedaToStore" 
                :incomingData="this.inputData"
                :idsToCheck="idsToCheck"
                :tabName="tabSelection"
                @buildPdf="generateReport"/>
        </b-tab>
        </b-tabs>
        <b-tabs v-else>
            <b-card class="align-center">Non disponibile</b-card>
        </b-tabs>
    </div>
</template>


<script>
import ColorLegend from "@/components/legend.vue"
import DynamicTable from "@/components/dynamicTable.vue"
import generale from "./generale.vue"
import attivita2 from "./attivita2.vue"
import localizzazione2 from "./localizzazione2.vue"
import procedureContratti2 from "./procedureContratti2.vue"
import scostamento from "./scostamenti.vue"
import criticita2 from "./criticita2.vue"
import indicatori from "./indicatori.vue"
import pagamenti2 from "./pagamenti2.vue"
import cronoProgramma from "./cronoProgramma.vue"
import inventario2 from "./inventario2.vue"
import invio from "./invio.vue"
import collapse from "@/components/collapse.vue"
import notify from "@/helpers/notifications.js"
import { mapGetters } from 'vuex'
import utils from '@/helpers/utils.js'
import tool from '@/helpers/tools.js'
import helper_pdf from "@/helpers/exportPdf.js"

    export default {
        name: 'tab',
        components: {
            ColorLegend,
            generale,
            attivita2,
            localizzazione2,
            procedureContratti2,
            scostamento,
            criticita2,
            indicatori,
            // pagamenti,
            pagamenti2,
            cronoProgramma,
            inventario2,
            invio,
            collapse,
            DynamicTable
        },
        computed: {

            ...mapGetters({
                getScheda: 'monitoraggio/getScheda',
                getSchedaMonitoraggio: 'monitoraggio/getSchedaMonitoraggio',
                getSchedaCronoprog: 'cronoprog/getSchedaComplete',
                getTabelloneFull: 'configuration/getTabelloneFull',
                getDescBeneficiario: 'announcements/getDescBeneficiario',
                getTabelloneInfo: 'configuration/getTabellone',
                getBeneficiari: 'announcements/getAllBeneficiariMap',
                getTaskList: 'monitoraggio/getTaskList',
            }),
            isBeneficiario: function() {
                const userInfo = this.$getUserInfo();
                for(const group of userInfo.groups)
                {
                    if(group.search("MINT") >= 0)
                        return false;
                }
                return true;
            },
        },
        data() {
            return {
                schedaKey: 'ND',
                loadComplete: false,
                idPeriodo: null,
                codiceProgetto: null,
                idsToCheck: {
                    attivita2: {
                        rootId: 'attivita',
                        dinamicIds: [
                            "['attivita'][*]['content']['idAttivita']",
                            "['attivita'][*]['content']['titoloAttivita']",
                            "['attivita'][*]['content']['attivitaProgettuali']"
                        ],
                    },
                    criticita2: {
                        rootId: 'attivita',
                        dinamicIds: [
                            "['attivita'][*]['content']['idAttivita']",
                            "['attivita'][*]['content']['titoloAttivita']",
                            "['attivita'][*]['content']['criticitaProgettuali']"
                        ],
                    },
                    procedureContratti2: {
                        rootId: 'contratti',
                        dinamicIds: [
                            "['attivita'][*]['content']['idAttivita']",
                            "['attivita'][*]['content']['titoloAttivita']",
                            "['procedure'][*]['content']['idProceduraGara']",
                            "['procedure'][*]['content']['tipologiaProcedura']",
                            "['procedure'][*]['content']['oggetto']",
                            "['procedure'][*]['content']['status']",
                            "['procedure'][*]['content']['tipologiaProceduraAvviata']",
                            "['procedure'][*]['content']['provvedimentoStaModificaIter']",
                            "['procedure'][*]['content']['provvedimentoOggetto']",
                            "['procedure'][*]['content']['cig']",
                            "['procedure'][*]['content']['cup']",
                            "['procedure'][*]['content']['baseAsta']",
                            // per il monitoraggio, costi manutenzione solo nel contratto
                            // vedi 19_Scheda di monitoraggio 04.06.2020.xlsx
                            // "['procedure'][*]['content']['costiManutenzioneIT']", 
                            "['procedure'][*]['content']['pubblicazioneGuue']",
                            "['procedure'][*]['content']['pubblicazioneGuri']",
                            "['procedure'][*]['content']['pubblicazioneSitoMit']",
                            "['procedure'][*]['content']['pubblicazioneAnac']",
                            "['procedure'][*]['content']['pubblicazioneSitoCommittente']",
                            "['procedure'][*]['content']['pubblicazioneQuotidiani']",
                            "['procedure'][*]['content']['aggiudicazioneGuue']",
                            "['procedure'][*]['content']['aggiudicazioneGuri']",
                            "['procedure'][*]['content']['aggiudicazioneSitoMit']",
                            "['procedure'][*]['content']['aggiudicazioneAnac']",
                            "['procedure'][*]['content']['aggiudicazioneSitoCommittente']",
                            "['procedure'][*]['content']['soggettoAggiudicatario']",
                            "['procedure'][*]['content']['aggiudicazioneQuotidiani']",
                            "['procedure'][*]['content']['note']",
                            "['contratti'][*]['content']['riferimentoContratto']",
                            "['contratti'][*]['content']['dataContratto']",
                            "['contratti'][*]['content']['oggettoContratto']",
                            "['contratti'][*]['content']['importoContratto']",
                            "['contratti'][*]['content']['importoContrattoIVA']",
                            "['contratti'][*]['content']['importoContrattoTotale']",
                            "['contratti'][*]['content']['costiManutenzioneIT']",
                            "['contratti'][*]['content']['importoANAC']",
                            "['contratti'][*]['content']['importoContrattoSuISF']",
                            "['contratti'][*]['content']['soggettoAttuatore']",
                            "['contratti'][*]['content']['note']"
                        ]
                    },
                    pagamenti2: {
                        rootId: 'pagamenti',
                        dinamicIds: [
                            "['attivita'][*]['content']['idAttivita']",
                            //"['attivita'][*]['content']['titoloAttivita']",
                            "['contratti'][*]['content']['riferimentoContratto']",
                            "['pagamenti'][*]['content']['riferimento']",
                            "['pagamenti'][*]['content']['importoPagamento']",
                            "['pagamenti'][*]['content']['numeroFattura']",
                            "['pagamenti'][*]['content']['dataFattura']",
                            "['pagamenti'][*]['content']['oggettoFattura']",
                            "['pagamenti'][*]['content']['imponibileFattura']",
                            "['pagamenti'][*]['content']['ivaFattura']",
                            "['pagamenti'][*]['content']['importoFatturaSuISF']",
                            "['pagamenti'][*]['content']['totaleFattura']",
                            "['pagamenti'][*]['content']['note']"
                        ]
                    },
                    inventario2: {
                        rootId: 'inventario',
                        dinamicIds: [
                            "['inventario'][*]['content']['isOnlyLocalizzazione']",
                            "['attivita'][*]['content']['idAttivita']",
                            "['attivita'][*]['content']['titoloAttivita']",
                            "['inventario'][*]['content']['beneAcquisito']",
                            "['inventario'][*]['content']['valoreTotale']",
                            "['inventario'][*]['content']['numeroSerie']",
                            "['inventario'][*]['content']['localita']['luogo']",
                            "['inventario'][*]['content']['dataAcquisto']",
                            "['inventario'][*]['content']['descrizione']",
                            "['inventario'][*]['content']['tipoAttrezzatura']"
                            
                        ],
                    },
                    localizzazione2: {
                        rootId: 'inventario',
                        dinamicIds: [
                            "['inventario'][*]['content']['isOnlyLocalizzazione']",
                            "['attivita'][*]['content']['idAttivita']",
                            "['attivita'][*]['content']['titoloAttivita']",
                            "['contratti'][*]['content']['riferimentoContratto']",
                            //"['inventario'][*]['content']['numeroSerie']",
                            "['inventario'][*]['content']['beneAcquisito']",
                            "['inventario'][*]['content']['localita']['luogo']",
                            "['inventario'][*]['content']['modifiche']"
                            
                        ]
                    }
                },
                tabelloneType: 'cronoprog_monitoraggio',
                bannerTable: {
                    header: [
                    "Codice Progetto",
                    "Titolo Progetto",
                    "Obiettivo Specifico",
                    "Obiettivo Nazionale",
                    "Trimestre",
                    "Data Chiusura Progetto",
                    ],
                    rows: [
                        {
                            content: {}
                        }
                    ]
                },
                title: "",
                inputData: { 
                    schedaMonitoraggio: {},
                    history: {},
                    refresh: {
                        inventario2: false,
                        localizzazione2: false
                    } 
                },
                tabSelection: "",
                // CONF PER INFO DI COMPILAZIONE
                conf_info_compiling: {
                    "generale": {
                        name_tab: 'generale'
                    },
                    "cronoprogramma": {
                        name_tab: 'cronoprogramma'
                    },
                    "attivita": {
                        name_tab: 'attivita'
                    },
                    "scostamento": {
                        name_tab: 'scostamento'
                    },
                    "criticita": {
                        name_tab: 'criticita'
                    },
                    "indicatori": {
                        name_tab: 'indicatori'
                    },
                    "procedure_contratti": {
                        name_tab: 'procedure_contratti'
                    },
                    "pagamenti": {
                       name_tab: 'pagamenti'
                    },
                     "localizzazione": {
                        name_tab: 'localizzazione'
                    },
                    "inventario": {
                        name_tab: 'inventario'
                    }    
                },
                // CONF PER GENERAZIONE PDF
                conf_export_pdf: {
                    "generale": {
                        id_table_pdf: 'generale_mon',
                        name: 'generale',
                        title_page_pdf: 'Generale',
                        nameCollapse: '',
                    },
                    "cronoprogramma": {
                        id_table_pdf: 'cronoprogramma_mon',
                        name: 'cronoprogramma',
                        title_page_pdf: 'Cronoprogramma',
                        nameCollapse: '',
                        double_details: true
                    },
                    "attivita": {
                        id_table_pdf: 'attivita_mon',
                        name: 'statoavanzamento',
                        title_page_pdf: 'Stato di Avanzamento',
                        nameCollapse: 'Attività realizzate nel trimestre di riferimento',
                    },
                    "scostamento": {
                        id_table_pdf: 'scostamento_mon',
                        name: 'statoavanzamento',
                        title_page_pdf: 'Stato di Avanzamento',
                        nameCollapse: 'Scostamenti sul cronoprogramma',
                    },
                    "criticita": {
                        id_table_pdf: 'criticita_mon',
                        name: 'statoavanzamento',
                        title_page_pdf: 'Stato di Avanzamento',
                        nameCollapse: 'Criticità',
                    },
                    "indicatori": {
                        id_table_pdf: 'indicatori_mon',
                        name: 'statoavanzamento',
                        title_page_pdf: 'Stato di Avanzamento',
                        nameCollapse: 'Indicatori',
                    },
                    "procedure_contratti": {
                        id_table_pdf: 'procedure_contratti_mon',
                        name: 'procedureContratti2',
                        title_page_pdf: 'Procedure e Contratti',
                        nameCollapse: '',
                        details: true
                    },
                    "pagamenti": {
                        id_table_pdf: 'pagamenti_mon',
                        name: 'pagamenti2',
                        title_page_pdf: 'Pagamenti',
                        nameCollapse: '',
                        details: true
                    },
                     "localizzazione": {
                        id_table_pdf: 'localizzazione_mon',
                        name: 'localizzazione2',
                        title_page_pdf: 'Localizzazione',
                        nameCollapse: ''
                    },
                    "inventario": {
                        id_table_pdf: 'inventario_mon',
                        name: 'inventario2',
                        title_page_pdf: 'Inventario',
                        nameCollapse: ''
                        
                    },
                   
                    "invio_mandatory": {
                        id_table_pdf: 'monitoring_mandatory',
                        name: 'invio',
                        title_page_pdf: 'Invio',
                        nameCollapse: 'Campi obbligatori non valorizzati',
                    },
                    "invio_sostanziali": {
                        id_table_pdf: 'monitoring_sost',
                        name: '',
                        title_page_pdf: 'Invio',
                        nameCollapse: 'Modifiche Sostanziali',
                    },
                    "invio_no_sostanziali": {
                        id_table_pdf: 'monitoring_nonsost',
                        name: '',
                        title_page_pdf: 'Invio',
                        nameCollapse: 'Modifiche Non Sostanziali',
                    },
                    "invio_note": {
                        id_table_pdf: 'invio_note',
                        name: '',
                        title_page_pdf: 'Invio',
                        nameCollapse: 'Elenco Note',
                    },
                    "invio_note_riservate": {
                        id_table_pdf: 'invio_note_riservate',
                        name: '',
                        title_page_pdf: 'Invio',
                        nameCollapse: 'Elenco Note Interne',
                    }
                },
                disableTabs: true,
                tabellone: {},
            };
        },

        methods: {
            generateReport(optPdf) {
                this.generationPdf = true
                // GESTIONE OCCHI
                // CRONOPROGRAMMA ( LA SOURCE E' SCHEDA MONITORAGGIO )
                let source_cronoprogramma = {
                    scheda: this.inputData.schedaMonitoraggio,
                    ids_dettaglio_fase: [
                        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']",
                        "['progetto']['cronoProgramma'][*]['idAttivita']",
                        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['descrizione']",
                        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataInizio']",
                        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataFine']",
                        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['totaleSpese']",
                        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['stato']"
                    ],
                    ids_dettaglio_spese: [
                        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['spesa']",
                        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['da']",
                        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['a']"
                    ],
                    mappa : this.inputData.schedaMonitoraggio.dataEntryConfiguration
                }
                const tabelloneFull = this.getTabelloneFull('cronoprog_monitoraggio');
                const tabelloneCronoprog = tabelloneFull.tabellone;
                const idProgetto = this.inputData.schedaMonitoraggio.progetto.idProgetto;
                const schedaCronoprog = this.getSchedaCronoprog({idScheda: idProgetto}).scheda.content;
                this.conf_export_pdf['cronoprogramma'].source = source_cronoprogramma;
                // PROCEDURE E CONTRATTI ( LA SOURCE E' SCHEDA CRONOPROG )
                const source_procedure_contratti = {
                    scheda: schedaCronoprog,
                    ids_eyes: this.idsToCheck.procedureContratti2,
                    mappa: tabelloneCronoprog,
                }
                this.conf_export_pdf['procedure_contratti'].source = source_procedure_contratti
                
                // PAGAMENTI ( LA SOURCE E' SCHEDA CRONOPROG )
                const source_pagamenti = {
                    scheda: schedaCronoprog,
                    ids_eyes: this.idsToCheck.pagamenti2,
                    mappa: tabelloneCronoprog
                }
                this.conf_export_pdf['pagamenti'].source = source_pagamenti

                // INVENTARIO ( LA SOURCE E' SCHEDA CRONOPROG )
                const source_inventario = {
                    scheda: schedaCronoprog,
                    ids_eyes: this.idsToCheck.inventario2,
                    mappa: tabelloneCronoprog
                }
                this.conf_export_pdf['inventario'].source = source_inventario
                
                // GESTIONE OCCHI
                helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
                this.generationPdf = false
            },
            setTabSelection(name){
                this.tabSelection = name
            },

            getCronoprog(payload) {
                return this.$store.dispatch('cronoprog/retrieveScheda', payload);
            },

            caricaScheda: async function() {
                if(!this.codiceProgetto || !this.idPeriodo) {
                    console.error('scheda con idKey nullo', this.codiceProgetto, this.idPeriodo);
                    return false;
                }
                // carico i task camunda
                await this.$store.dispatch('monitoraggio/retrieveTasks');

                const idKey = this.idPeriodo.toString().concat('_').concat(this.codiceProgetto);
                try {
                    await this.$store.dispatch('monitoraggio/retrieveSchedaMonitoraggio',{
                        key: idKey
                    });
                } catch(error) {
                    console.error('Errore caricamento scheda monitoraggio', error);
                    notify.error(
                        notify.strings.error, 
                        notify.strings.selettoreMonitoraggioKO
                    );
                    return false;
                }
                
                let scheda = this.getScheda({codiceProgetto: this.codiceProgetto, idPeriodo:this.idPeriodo});
                let schedaMonitoraggio = scheda.schedaMonitoraggio;
                // Gestione scheda NoteRiservate
                let userInfo = this.$getUserInfo();
                let group = userInfo.groups[0];
                if( group.includes("MINT")) {
                    await this.$store.dispatch("monitoraggio/retrieveSchedaNoteRiservate", schedaMonitoraggio)
                    .then(() => {
                        scheda = this.getScheda({codiceProgetto: this.codiceProgetto, idPeriodo:this.idPeriodo});
                    })
                    .catch(error => {
                        console.log('caricaSchedaNoteRiservate error:', error.message);
                    })
                }

                this.schedaKey = scheda.key;
                // caricamento tabelloni
                const payloadMon = { 
                    type: 'monitoraggio',
                    version: schedaMonitoraggio.schemaVersione,
                }

                const payloadCrono = {
                    type: this.tabelloneType,
                    version: schedaMonitoraggio.schemaVersioneCronoprog
                };
                await Promise.all([
                    this.$store.dispatch("configuration/setTabellone", 'info_monitoraggio'),
                    this.$store.dispatch("configuration/setTabelloneVersioned", payloadMon),
                    // tabellone cronoprog
                    this.$store.dispatch("configuration/setTabelloneVersioned", payloadCrono),
                    this.$store.dispatch("configuration/setTabelloneVersioned", {type: 'procedure_tipologie'})
                ]).then( results => {
                    this.tabellone = results[0];
                    
                }).catch(error => {
                    console.error('Errore caricamento tabelloni', error);
                    notify.error(
                        notify.strings.error, 
                        notify.strings.selettoreMonitoraggioKO
                    );
                    return false;
                });

                if(!schedaMonitoraggio.dataEntryConfiguration) {
                    const tabelloneFull = this.getTabelloneFull('monitoraggio');
                    console.log('aggiungo il tabellone, versione:', tabelloneFull.version);
                    const tabelloneMon = tabelloneFull.tabellone;
                    let conf =  tool.genericFunctions.cloneObject(tabelloneMon);
                    for(let key of Object.keys(conf)) {
                        conf[key].data = {};
                    }
                    schedaMonitoraggio = tool.genericFunctions.cloneObject(schedaMonitoraggio);
                    schedaMonitoraggio.dataEntryConfiguration = conf;
                }

                const payload = {
                    idProgetto: schedaMonitoraggio.progetto.idProgetto,
                    cronoprogKey: schedaMonitoraggio.cronoprogKey,
                    branch: 'monitoraggio'
                };
                await this.getCronoprog(payload).then(
                    result => {
                        console.log('cronoprog:', result.key);
                        // console.log('ora2', new Date().getTime());
                        // this.inputData.cronoprog = result;
                        // aggiungo (laddove mancante) un contratto per ogni procedura
                        // trovata (necessario per far funzionare il tab procedure e contratti
                        // finchè non si sceglierà una via diversa (separare in due tab, oppure
                        // il tab consente di aggiungere il contratto appena diventa disponibile, ecc)
                        this.setupCronoprog(result);
                    }
                ).catch(error => {
                    console.error('caricaScheda getCronoprog error', error);
                    notify.error(notify.strings.error, notify.strings.selettoreMonitoraggioKO);
                    return false;
                })
                try {
                    this.salvaSchedaToStore(schedaMonitoraggio);
                    return this.extractData(scheda);
                } catch(error) {
                    console.error('caricaScheda error in salvaScheda or extractData', error);
                    notify.error(notify.strings.error, notify.strings.selettoreMonitoraggioKO);
                    return false;
                }
            },
            setupCronoprog(cronoprog) {
                let updateCronoprog = false;
                let contrMap = {};
                const contratti = Object.values(cronoprog.content.contratti);
                // se ci sono contratti, allora costruisco una mappa alfaIdProc->alfaIdContr
                // a partire dai rel di ogni contratto
                if(contratti.length > 0)  {
                    for(const contratto of contratti) {
                        if(contratto.rel && contratto.rel.procedure) {
                            const alfaIdProc = contratto.rel.procedure[0];
                            contrMap[alfaIdProc] = contratto.id;
                        }
                        else
                            console.error("trovato contratto non legato ad una procedura", contratto);
                    
                    }
                }
                // facendo uso della mappa costruita sopra, aggiungo un contratto alle procedure
                // che non ne hanno e salvo nello store
                for(const proc of Object.values(cronoprog.content.procedure)) {
                    // in questo caso abbiamo una procedura senza contratto
                    if(!Object.keys(contrMap).includes(proc.id) 
                    //&& !proc.content.rimodulated //Commentato per nuova gestione cronoprog
                    ) {
                        
                        updateCronoprog = true;
                        const newId = tool.genId();
                        console.log('crea contratto per procedura:', proc.id, 'con id:', newId);
                        // creo un contratto. I relatives sono gli stessi di procedura.
                        cronoprog.content.contratti[newId] = {
                            id: newId,
                            rel: proc.rel,
                            content: {
                                dataEntryConfiguration: null
                            }
                        }
                        // aggiungo la procedura ai relatives del contratto
                        cronoprog.content.contratti[newId].rel['procedure'] = [proc.id];
                    }
                }
                if(updateCronoprog) {
                    this.$store.dispatch('cronoprog/setScheda', {
                        idScheda: cronoprog.idProgetto,
                        scheda: cronoprog.content
                    });
                }
            },

            extractData(schedaCompleta) {
                if(!schedaCompleta || !schedaCompleta.schedaMonitoraggio) {
                    console.log("extractData bad params", schedaCompleta);
                    notify.error(notify.strings.error, notify.strings.selettoreMonitoraggioKO);
                    return false;
                }
                // qui aggiungo a inputData la tasklist, che prima era restituita direttamente dallo store
                // TODO forse si può ottimizzare
                const taskList = this.getTaskList(this.codiceProgetto, this.idPeriodo);
                this.inputData.taskList = taskList;
                this.inputData.history = schedaCompleta.history;
                this.inputData.schedaNoteRiservate = schedaCompleta.schedaNoteRiservate;
                const scheda = schedaCompleta.schedaMonitoraggio;
                if(!scheda) {
                    console.log("extractData(scheda) undefined o null", schedaCompleta);
                    return false;
                }
                let dataChiusura;
                if(tool.isDate(scheda.progetto.dataConclusione)) {
                    dataChiusura = scheda.progetto.dataConclusione.getFullYear()+"-"
                        +(scheda.progetto.dataConclusione.getMonth()+1)+"-"+scheda.progetto.dataConclusione.getDate();
                } else if (scheda.progetto.dataConclusione){
                    dataChiusura = scheda.progetto.dataConclusione.substring(0,10);
                }

                if(scheda.dataEntryConfiguration) {
                    const dConclId = "['progetto']['dataConclusione']";
                    const conf = scheda.dataEntryConfiguration[dConclId];

                    if(conf && conf.data && conf.data.edited) { // mostra la data di conclusione senza modifiche
                        dataChiusura = utils.formatOutput(conf, conf.data.oldValue);
                    }
                    else {
                        dataChiusura = utils.formatOutput(conf, scheda.progetto.dataConclusione);
                    }
                }
                //gestione multiobiettivo
                let obNaz  = "";
                let obSpec = "";
                
                if (scheda.progetto.obiettivoProgetto.obiettivoNazionale && scheda.progetto.obiettivoProgetto.obiettivoNazionale.length > 0) {
                    obNaz = scheda.progetto.obiettivoProgetto.obiettivoNazionale.map(obiettivoNaz => obiettivoNaz.codiceComposito).toString();
                    let allObiettiviSpecifici = scheda.progetto.obiettivoProgetto.obiettivoNazionale.map(obiettivoNaz => obiettivoNaz.obiettivoSpecifico.codice)
                    
                    if (allObiettiviSpecifici && allObiettiviSpecifici.length>0){
                        obSpec = [...new Set(allObiettiviSpecifici)].toString();
                    }
                    console.log("obNaz:"+obNaz+", obSpec:"+obSpec);
                }
                this.bannerTable.rows[0].content = {
                    "Codice Progetto": scheda.progetto.codiceProgetto,
                    "Titolo Progetto": scheda.progetto.titoloProgetto,
                    "Obiettivo Specifico": obSpec,
                    "Obiettivo Nazionale": obNaz,
                    "Trimestre": scheda.trimestreRiferimento,
                    "Data Chiusura Progetto": dataChiusura
                }
                return true;
            },

            salvaSchedaToStore(scheda) {

                let payload = {
                    codiceProgetto: scheda.progetto.codiceProgetto, 
                    idPeriodo: scheda.idPeriodo,
                    scheda: scheda
                }
                this.$store.dispatch('monitoraggio/localSaveSchedaMonitoraggio', payload);

                this.inputData.schedaMonitoraggio = scheda;    //refresh del dato su tutti i tab alimentati da questo dato
            },

            //funzione spostata qui perchè comune a tutti i tab
            viewOnlyMode(scheda, actions) { 
                if (!scheda || !actions || !Array.isArray(actions))
                    return false;
                for(let j= 0; j < actions.length; j++) {
                    if(!utils.caniuse(actions[j], scheda.taskList, scheda.schedaMonitoraggio, scheda.history)) {
                        actions.splice(j,1);
                        j--;
                    }
                }
            },
            refreshTab(incomingTab) {
                switch(incomingTab) {
                    case 'inventario2':
                        // refresh di localizzazione su modifica di inventario
                        this.inputData.refresh.localizzazione2 = !this.inputData.refresh.localizzazione2;
                        break;
                    case 'localizzazione2':
                        // refresh di localizzazione su modifica di inventario
                        this.inputData.refresh.inventario2 = !this.inputData.refresh.inventario2;
                        break;
                    default:
                        console.log('no refresh action for', incomingTab);
                        break;
                }    
            }
        },

        mounted() {
            this.$store.commit('monitoraggio/RESET');
            this.disableTabs = true;
            this.codiceProgetto = this.$route.params.codiceProgetto;
            this.idPeriodo = this.$route.params.idPeriodo;
            this.title = this.getDescBeneficiario();

            
            this.caricaScheda().then(
                result => {
                    if (result) {
                    // this.inputData = result // set inputData only when it's ready
                    this.tabSelection = "generale"
                    this.disableTabs = false;
                    this.loadComplete = true;
                } else {
                    console.log(">>> schedaMonitoraggio: result of caricaScheda is invalid!");
                }
            });
        }
    }
</script>
