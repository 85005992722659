<template>
    <div class="collapseContainer">
        <div class="fixx">
            <div v-bind:class = "icon_parere === true ? 'adjustIcon':''" @click="showOrHide()">
                <span class="toggleButton">{{val}} {{name}}</span>
                <GenericCircle v-if="icon_parere === true && Object.keys(iconInfo).length > 0" :iconInfo="iconInfo"></GenericCircle>
                <CheckParere v-if="icon_parere === true && Object.keys(iconInfo).length === 0" :updatedScheda="updatedScheda" :idsScheda="checkIdsScheda"></CheckParere>
            </div>
        </div>
        <div v-show="!hidden">
            <slot>Contenuto di Default (vuoto)</slot>
        </div>
    </div>
</template>

<script>
import CheckParere from '@/components/IconCheckCircle.vue'
import GenericCircle from '@/components/GenericCircleIcon.vue';

    export default {
        name: 'collapse',
        components: {
            CheckParere,
            GenericCircle
        },
        props: {
            checkIdsScheda: {
                type: Array,
                default: function() {
                    return []
                }
            },
            'name': {
                type: String,
                required: true
            },
            icon_parere: {
                type: Boolean,
                default: false
            },
            updatedScheda: {
                type: Boolean,
                default: false
            },
            iconInfo: {
                type: Object,
                default: function() {
                    return {}
                }
            }
        },
        data() {
            return {
                val: '+',
                title: '',
                hidden: true
            }
        },

        methods: {
            showOrHide() {
                    if(this.val === '+')
                        this.val = '-'
                    else
                        this.val = '+'
                
                this.hidden = !this.hidden;
               
               
            },
        },
        created() {
            this.title = this.$parent.title;
        }
        
    }

</script>

<style scoped>
    .collapseContainer {
        background-color: #FFFFFF;
        border: 1px solid #CCCCCC;
        padding: 20px;
        margin-top: 10px;
    }
   

</style>
<style >

 .toggleButton {
        font-weight: bold;
        font-size: 20px;
    }
    .adjustIcon {
        display: inline-flex;
    }
    .fixx :hover {
        cursor:pointer;
    }
</style>