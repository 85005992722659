<template>
  <div>
    <b-container>
      <Collapse
        :name="'Campi obbligatori non valorizzati (' + this.campiObbligatori.length + ')'">
        <b-table :id="'istruttoria_mandatory'" striped hover :fields="headerTableMandatory" :items="campiObbligatori"></b-table>
      </Collapse>
      <Collapse :icon_parere="true" :iconInfo="setEsitoComplessivo" :name="'Esito Valutazione'">
        <b-table :id="'istruttoria_esito'" striped hover :fields="headerTableCheck" :items="esitiTabs" :tbody-tr-class="rowClass"></b-table>
      </Collapse>
      <Collapse :name= numeroAllegati>
        <fa-icon v-if="this.loading" :icon="['fas', 'sync']" spin/>
        <FileUpload v-if="!this.loading"
          :idTablePdf="'invio_fileUpload'"
          :rid="this.$route.params.idIstruttoria"
          :ownerProcessStatus="this.schedaComplete.content.taskInfo.taskDefinitionKey"
          :actions= actionsArray
          :entity="this.entity"
          :availableFiles= this.availableFiles
          :documentTypes = this.availableDocumentTypes
          :riferimento = this.riferimento
          name= "files" 
          @doRefresh="getFilesPerRid($route.params.idIstruttoria)"/>
      </Collapse>
      <Collapse :name="'Elenco Note (' + allNotes.length + ')'">
        <br />
        <TableNotes :id="'note_istruttoria'" v-if="allNotes.length > 0" :notes="allNotes"> </TableNotes>
        <h4 v-else>
          Nessuna nota da mostrare
        </h4>
      </Collapse>
      <Collapse
        v-if="isVisibleReservedNote"
        :name="'Elenco Note Interne (' + fillNoteRiservate().length + ')'">
        <br />
        <div class="note" v-if="blockNote">
          <br>
            <b-form-textarea
              id="textAreaNoteRiservate"
              :disabled="disableButton"
              v-model="noteRiservate"
              placeholder="Inserire qui le note interne"/>
        </div>
        <div class="pulsanti-invio">
          <b-button :id="'cancelNoteRiservate_istruttoria'"
            v-if="blockNote"
            class="mx-3 btnReservedNote"
            :disabled="disableButton"
            variant="danger"
            @click="resetReservedNote()"
            >Cancella Nota Interna</b-button>
        </div>
        <TableNotes
          :id="'note_riservate_istruttoria'"
          v-if="fillNoteRiservate().length > 0" :notes="fillNoteRiservate()">
        </TableNotes>
        <h4 v-else>
          Nessuna nota da mostrare
        </h4>
      </Collapse>
      <br/>
        <div class="col-sm-12"> 
          <b-card>
            <b-row>
              <p class="text-right">Si richiede il parere della Task Force ICT:</p>
              <b-col>
                <b-radio-group
                  size="lg"
                  :disabled="disableButton || isRichiestoParereICT"
                  v-model="richiestoParereICT"
                  >
                  <b-form-radio value="true">Sì</b-form-radio>
                  <b-form-radio value="false">No</b-form-radio>
                </b-radio-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-left">
                Parere ICT:
                <b-textarea
                  rows="5"
                  id="textarea-parereICT-istruttoria"
                  :state="enableStateTextAreaICT"
                  :disabled="textAreaParereIctDisabled"
                  placeholder="Inserire il parere"
                  v-model="parereICT"
                />
              </b-col>
            </b-row>
          </b-card>
        </div>
        <br />
        <div class="col-sm-12">
          <b-card>
            <b-row>
              <p class="text-right">Si richiede il parere della Task force logistica e infrastrutture edili:</p>
              <b-col>
                <b-radio-group
                  size="lg"
                  :disabled="disableButton || isRichiestoParereLIE"
                  v-model="richiestoParereLIE"
                  >
                  <b-form-radio value="true">Sì</b-form-radio>
                  <b-form-radio value="false">No</b-form-radio>
                </b-radio-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-left">
                Parere Task force logistica e infrastrutture edili:
                <b-textarea
                  rows="5"
                  id="textarea-parereLIE-istruttoria"
                  :state="enableStateTextAreaLIE"
                  :disabled="textAreaParereLieDisabled"
                  placeholder="Inserire il parere"
                  v-model="parereLIE"
                />
              </b-col>
            </b-row>
          </b-card>  
        </div>
        <br />
        <div class="col-sm-12" v-if="isRichiestoParereUFF && this.schedaComplete.procedureDerogatorie">
          <b-card>
            <b-row>
              <p class="text-right">Si richiede il parere dell'Ufficio Controlli</p>
            </b-row>
            <b-row>
              <b-col class="text-left">
                Parere UFFICIO CONTROLLI:
                <b-textarea
                  rows="5"
                  id="textarea-parereDeroga-istruttoria"
                  :state="enableStateTextAreaUFF"
                  :disabled="disableButton || textAreaParereUffDisabled"
                  placeholder="Inserire il parere"
                  v-model="parereUFF"
                />
              </b-col>
            </b-row>
          </b-card>  
        </div>
        <br />
        <b-row>
        <div v-if="this.allegatiMancanti.length > 0">
          <ul>
            <span :class="this.allegatiMancanti.filter(function(doc){return doc.mandatory === true;}).length > 0 ? 'text-danger' : 'text-warning' ">
              Attenzione: Mancano ancora allegati 
            </span>
            <li v-for="missingFiles in this.allegatiMancanti" :key="missingFiles.type" style="list-style-type: circle;">
              <span class="text-center"> {{ missingFiles.type }}</span>
            </li> 
          </ul>
        </div>          
        </b-row>
        <b-row class="mt-2" v-if="blockNote">
          <b-col />
          <b-col class="text-right">
          <label for="textarea-note">Nota:</label>
          </b-col>
          <b-col sm="8">
          <b-form-textarea
              v-model="note"
              id="textarea-note"
              size="lg"
              placeholder="Inserire qui le note"
              :disabled="disableButton"
          />
          </b-col>
          <b-col sm="2">
          <b-button :id="'cancelNote_istruttoria'" variant="outline-secondary" :disabled="disableButton" @click="cancelNote()"
              >Cancella Nota</b-button
          >
          </b-col>
          <b-col />
        </b-row>
        <b-row>
        <b-col class="text-right">
          <b-button
            :id="'export_pdf'"
            size="lg"
            variant="secondary"
            class="mx-2"
            :disabled="disableButton"
            @click="preparePdf">
            Export PDF
          </b-button>
          <b-button
            v-if="actionsArray.indexOf('save_istruttoria') !== -1"
            :id="'save_istruttoria'"
            size="lg"
            variant="primary"
            class="mx-2"
            :disabled="disableButton"
            @click="saveSchedaIstruttoria('draft')"
          >
            Salva in Bozza
          </b-button>
          <!-- silenziamento if tutti i campi compilati  -->
          <template>
            <b-button
              v-if="actionsArray.indexOf('send_istruttoria') !== -1"
              :id="'send_istruttoria'"
              size="lg"
              variant="success"
              class="mx-2"
              :disabled="disableButton || setVisibilitySaveButtonIct || setVisibilitySaveButtonLie || setVisibilitySaveButtonUff || disableBtnSend"
              @click="setValueModal('complete')"
            >
              Salva e Invia
            </b-button>
          </template>
          <b-button
            v-if="actionsArray.indexOf('reject') !== -1"
            :id="'reject_istruttoria'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="disableButton"
            @click="setValueModal('reject')"
          >
            Rifiuta
          </b-button>
        </b-col>
      </b-row>
      <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
    </b-container>
  </div>
</template>

<script>
import TableNotes from "../bandi/tableNotes.vue";
import Collapse from "@/components/collapse.vue";
import FileUpload from '@/components/FileUpload.vue';
import notify from "@/helpers/notifications.js";
import endpoints from '@/components/endpoints.vue'
import { mapGetters } from 'vuex';
import tool from '@/helpers/tools.js';
import { JSONPath as jpath } from 'jsonpath-plus';
import helper_pdf from "@/helpers/exportPdf.js"


export default {
  name: "invio",
  props: {
    tabName: String,
    updatedScheda: Boolean,
    ids: Object,
    tabellone: Object,
    //informazioni di riferimento per tutti i file caricati
    riferimento: {type: Object, required: true }
  },
  components: {
    Collapse,
    TableNotes,
    FileUpload
  },
  data() {
    return {
        note:"",
        noteRiservate: "",
        allNotes: [],
        entity: "istruttoria",
        modal1Map:{
            complete:{
                title:"Conferma Istruttoria", text:"Conferma di voler Inviare l'Istruttoria ? "
            },
            
            reject:{
                title:"Rifiuta Istruttoria", text:"Conferma di voler Rifiutare l'Istruttoria?"
            },
             
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",
        esitoValutazione: false,
        actionsArray: [],
        schedaComplete: {},
        esitiTabs: [],
        campiObbligatori: [],
        disableButton: false,
        richiestoParereICT: false,
        richiestoParereLIE: false,
        richiestoParereUFF: false,
        loading: false,
        allegatiDisponibili: 0,
        allegatiMancanti: [],
        allegatiObbligatoriMancanti: [],
        availableFiles: [],
        availableDocumentTypes: [],
        parereICT: "",
        parereLIE: "",
        parereUFF: "",
        rowsTable: [],
        mappingRowValutazione : {
          'ammissibilita':  'Valutazione di Ammissibilità',
          'merito': 'Valutazione di Merito',
          'derogatorie': 'Valutazione Procedure Derogatorie',
          'iterAmministrativo': 'Valutazione Iter Amministrativo'
        },
        headerTableMandatory: [
          { key: "tab", label: "Tab" },
          { key: "campo", label: "Nome Campo" }
        ],
        headerTableCheck: [
          { key: "tab", label: "Scheda" },
          { key: "esito", label: "Esito" }
        ],
    }
  },
  watch: {
    updatedScheda: function() {
      this.valutazioneSingoliEsiti();
      this.valoriObbligatoriMancanti();
    }
  },
  created() {
    if(!this.$route.params.idIstruttoria) {
      console.error('invio istruttoria: no idIstruttoria in route params', this.$route.params.idIstruttoria);
      return;
    }

    this.schedaComplete = this.getSchedaComplete({idSchedaIstruttoria: this.$route.params.idIstruttoria})
    // PARERE ICT
    const reqParereICT = this.schedaComplete.content.richiestoParereICT;
    if (reqParereICT != undefined && reqParereICT != null)
      this.richiestoParereICT = reqParereICT;
    const parereICT = this.schedaComplete.content.parereICT;
    if (parereICT)
      this.parereICT = parereICT;
    // PARERE LIE
    const reqParereLIE = this.schedaComplete.content.richiestoParereLIE;
    if (reqParereLIE != undefined && reqParereLIE != null)
      this.richiestoParereLIE = reqParereLIE;
    const parereLIE = this.schedaComplete.content.parereLIE;
    if (parereLIE)
      this.parereLIE = parereLIE;
    // PARERE UFFICIO CONTROLLI
    const reqParereUFF = this.schedaComplete.procedureDerogatorie;
    if (reqParereUFF != undefined && reqParereUFF != null)
      this.richiestoParereUFF = reqParereUFF;
    const parereUFF = this.schedaComplete.content.parereUFF;
    if (parereUFF)
      this.parereUFF = parereUFF;
      
    // NOTE
    this.note = this.schedaComplete.content.notaTemporanea;
    this.allNotes = this.schedaComplete.content.note && this.schedaComplete.content.note.length > 0
        ? this.schedaComplete.content.note
        : [];
    // NOTE RISERVATE
    if (this.schedaComplete.content?.notaTemporaneaRiservata) {
      this.noteRiservate = this.schedaComplete.content.notaTemporaneaRiservata;
    }
    
    this.actionsArray = this.$istruttoriaGetActions(this.schedaComplete);
    this.refreshAttachmentsInfo();
  },
  mounted() {
    this.getFilesPerRid(this.$route.params.idIstruttoria);
    this.valutazioneSingoliEsiti();
    this.valoriObbligatoriMancanti();
  },
  computed: {
    ...mapGetters({
      getSchedaComplete: "istruttoria/getSchedaIstruttoria",
      getIstruttoria: "istruttoria/getIstruttoria"
    }),
    disableBtnSend() { 
      return this.campiObbligatori.length > 0
      
    },
    blockNote() {
      return  this.actionsArray.indexOf('save_istruttoria') !== -1;
    },
    numeroAllegati() {
      return "Allegati (" + this.allegatiDisponibili + ")";
    },   
    // actualFiles() {
    //   let schedaCurrent = this.getIstruttoria({idSchedaIstruttoria:this.$route.params.idIstruttoria})
    //   let allegatiDisponibili = schedaCurrent.infoAllegati.allegatiDisponibili[this.entity]
    //   let collapseName = "Allegati ("+allegatiDisponibili+")"
    //   return collapseName
    // },
    // missingFilesList(){
    //   let descrizione = ' (Obbligatorio)'
    //   let schedaCurrent = this.getIstruttoria({idSchedaIstruttoria: this.$route.params.idIstruttoria})
    //   let allegatiMancanti = tool.genericFunctions.cloneObject(schedaCurrent.infoAllegati.allegatiMancanti[this.entity])

    //   allegatiMancanti.forEach(element => {
    //     if (element.mandatory===true)
    //       element.type = element.type + descrizione
    //   });
    //   return allegatiMancanti
    // },
    // ESITO GENERALE
    setEsitoComplessivo() {
      let genericCheck = {}
      let allEsiti = []
      this.esitiTabs.forEach(element => {
        allEsiti.push(element.esito)
      })
      if(allEsiti.indexOf('Incompleto') > -1) {
          genericCheck = {  type:'circle', color:'secondary'  }
      } else {
          genericCheck = allEsiti.indexOf('Negativo') > -1 ? {  type:'circle-fill', color:'danger' } : {  type:'circle-fill', color:'success'  }
      }
      return genericCheck
    },
    //----------------------------------------------------------------------
    // VALUTAZIONE DEGLI STATES NELLE SINGOLE TEXTAREA
    // Lo :state fa capire al ruolo di turno quale textArea bisogna fillare per poter andare avanti
    enableStateTextAreaICT() {
      // COSI' DISATTIVO L'ANALISI DELLO STATE DELLA TEXT-AREA RELATIVA
      if(this.textAreaParereIctDisabled) {
        return null;
      }
      // COSI' VALUTO L'ANALISI DELLO STATE DELLA TEXT-AREA RELATIVA
      return this.parereICT.trim() !=='' && this.isRichiestoParereICT
    },
    enableStateTextAreaLIE() {
      // COSI' DISATTIVO L'ANALISI DELLO STATE DELLA TEXT-AREA RELATIVA
      if(this.textAreaParereLieDisabled) {
        return null;
      }
      // COSI' VALUTO L'ANALISI DELLO STATE DELLA TEXT-AREA RELATIVA
      return this.parereLIE.trim() !=='' && this.isRichiestoParereLIE
    },
    enableStateTextAreaUFF() {
      // COSI' DISATTIVO L'ANALISI DELLO STATE DELLA TEXT-AREA RELATIVA
      if(this.textAreaParereUffDisabled) {
        return null;
      }
      // COSI' VALUTO L'ANALISI DELLO STATE DELLA TEXT-AREA RELATIVA
      return this.parereUFF.trim() !=='' && this.isRichiestoParereUFF
    },
    //----------------------------------------------------------------------
    // GESTIONE PARERI
    isRichiestoParereICT() {
      return this.actionsArray.indexOf("askIctAdvice") === -1;
    },
    isRichiestoParereLIE() {
      return this.actionsArray.indexOf("askLieAdvice") === -1;
    },
    isRichiestoParereUFF() {
      return this.actionsArray.indexOf("askUffAdvice") === -1;
    },
    textAreaParereIctDisabled() {
      //block textarea parere ict
      if(this.actionsArray.indexOf("editIctAdvice") === -1) {
        return  true;
      } else {
        return (
          this.actionsArray.indexOf("editIctAdvice") !== -1 &&
          this.richiestoParereICT != "true" &&
          this.richiestoParereICT != true
        );
      }
    },
    textAreaParereLieDisabled() {
      //block textarea parere lie
      if(this.actionsArray.indexOf("editLieAdvice") === -1) {
        return  true;
      } else {
        return (
          this.actionsArray.indexOf("editLieAdvice") !== -1 &&
          this.richiestoParereLIE != "true" &&
          this.richiestoParereLIE != true
        );
      }
    },
    textAreaParereUffDisabled() {
      //block textarea parere uffControl
      if(this.actionsArray.indexOf("editUffAdvice") === -1) {
        return  true;
      } else {
        return (
          this.actionsArray.indexOf("editUffAdvice") !== -1 &&
          this.richiestoParereUFF != "true" &&
          this.richiestoParereUFF != true
        );
      }
    },
    setVisibilitySaveButtonIct() {
       return (this.actionsArray.indexOf("editIctAdvice") !== -1  && this.parereICT.length === 0 );
    },
    setVisibilitySaveButtonLie() {
       return (this.actionsArray.indexOf("editLieAdvice") !== -1  && this.parereLIE.length === 0 );
    },
    setVisibilitySaveButtonUff() {
       return (this.actionsArray.indexOf("editUffAdvice") !== -1  && this.parereUFF.length === 0 );
    },
  },
  methods: {
    resetReservedNote() {
        this.noteRiservate = ""
    },
    preparePdf() {
      // Chiamo questa funzione per aggiornare i pareri in Scheda nello Store (Nessuna Invocazione API perchè il parametro passante è vuoto)
      this.saveSchedaIstruttoria()
      let contentScheda = this.getSchedaComplete({idSchedaIstruttoria: this.$route.params.idIstruttoria})
      let typeScheda = 'Istruttoria'
      let optPdf = helper_pdf.handleNamingPdf(contentScheda, typeScheda)
      this.$emit('buildPdf', optPdf)
      this.disableButton = false;
    },
    isVisibleReservedNote() {
      let userInfo = this.$getUserInfo();
      let group = userInfo.groups[0];
      return group.includes("MINT");
    },
    fillNoteRiservate() {
      let schedaCurrent = this.getSchedaComplete({ idSchedaIstruttoria: this.$route.params.idIstruttoria })
      if(!schedaCurrent || !schedaCurrent.schedaNoteRiservate)
          return [];
      return schedaCurrent.schedaNoteRiservate?.content?.noteRiservate || [];
    },
      //Metodi per Allegati
      getFilesPerRid(rid) {
        let uri = endpoints.postFilesBasePath + rid
        this.$get(uri)
          .then(
              resp =>  {
                console.log('getFilesPerRid response: ', resp)
                this.availableFiles = this.$availableFilesFilter( resp, this.$getUserInfo(), this.getIstruttoria({ idSchedaIstruttoria: this.$route.params.idIstruttoria }).infoAllegati[this.entity])
                this.currentMandatoryFiles();
                this.refreshAttachmentsInfo()
                this.loading = false
              }
          ).catch(error => {
              console.error("Error getFilesPerRid: ", error);
              notify.error(
                notify.strings.error,
                notify.strings.getFilesPerRid
              )
              this.loading = false
          });
          this.loading = true
      },
      currentMandatoryFiles(){
        let schedaCurrent = this.getIstruttoria({ idSchedaIstruttoria: this.$route.params.idIstruttoria })
        let clonedScheda = tool.genericFunctions.cloneObject(schedaCurrent);
        let allegatiMandatory = this.$calculateMandatoryFiles(this.entity, this.$getUserInfo().roles[0], clonedScheda, this.availableFiles);

        this.$updateMandatoryFilesInStore(allegatiMandatory, this.entity, this.$route.params.idIstruttoria)
        this.$updateAvailableLengthInStore(this.availableFiles.length, this.entity, this.$route.params.idIstruttoria);
        this.availableDocumentTypes = this.$availableDocumentTypes(clonedScheda.infoAllegati[this.entity], this.schedaComplete.content.taskInfo.taskDefinitionKey)
      },
      refreshAttachmentsInfo() {
        const schedaCurrent = this.getIstruttoria({ idSchedaIstruttoria: this.$route.params.idIstruttoria })
        this.allegatiDisponibili = schedaCurrent.infoAllegati.allegatiDisponibili[this.entity];
        const temp = schedaCurrent.infoAllegati.allegatiMancanti[this.entity];
        let clonedLista = tool.genericFunctions.cloneObject(temp);
        // aggiunge la dicitura di obbligatorietà
        const descrizione = ' (Obbligatorio)'
        clonedLista.forEach(element => {
          if (element.mandatory===true)
            element.type = element.type + descrizione
        });
        this.allegatiMancanti = clonedLista;
        this.allegatiObbligatoriMancanti = this.allegatiMancanti.filter(doc => {return doc.mandatory === true;});
      },
      valoriObbligatoriMancanti() {
        let schedaIstruttoria = this.getSchedaComplete({idSchedaIstruttoria: this.$route.params.idIstruttoria})
        let valoriMancanti = []
        let mappa = this.tabellone.mappa
        Object.keys(this.ids).forEach(key => {
          Object.keys(this.ids[key]).forEach(idCampo => {
            let datoMancante = {}
            let id = this.ids[key][idCampo]
            const values = jpath("$" + id, schedaIstruttoria.content);
            if(values.length === 0) {
              datoMancante.tab = this.mappingRowValutazione[key]
              datoMancante.campo = mappa[id].config.descrizione
              valoriMancanti.push(datoMancante)
            }
          })
        })
        this.campiObbligatori = valoriMancanti
      },
      rowClass(item, type) {
        let styleInRow
        if (item && type === 'row' && item.esito !== 'Incompleto') {
          styleInRow = item.esito === 'Positivo' ? 'text-success' : 'text-danger'
        }
        return styleInRow
      },
      valutazioneSingoliEsiti() {
        let esitoValutazione = false
        let rowsTable = []
        let schedaCurrent = this.getSchedaComplete({ idSchedaIstruttoria: this.$route.params.idIstruttoria })
        Object.keys(this.ids).forEach(key => {
          let check_parere = this.$checkForIconCircle(schedaCurrent, this.ids[key]);
          let row = {tab: this.mappingRowValutazione[key], esito: check_parere}
          rowsTable.push(row)
        })

        let allEsiti = rowsTable.map(row => row.esito);
        esitoValutazione = allEsiti.indexOf('Negativo') > -1 || allEsiti.indexOf('Incompleto') > -1
                          ? false : true
        this.esitoValutazione = esitoValutazione
        this.esitiTabs = rowsTable
    },
    cancelNote() {
        this.note = " ";
    },
    // Gestisco gli aggiornamenti in scheda di: nota e pareri
    // Clono la scheda, la modifico e la restituisco prima del salvataggio
    updateSchedaIstruttoria(scheda) {
      let clonedScheda = tool.genericFunctions.cloneObject(scheda);
      clonedScheda.content.notaTemporanea = this.note
      clonedScheda.content.notaTemporaneaRiservata = this.noteRiservate
      clonedScheda.content.esitoValutazione = this.esitoValutazione
      if (!this.isRichiestoParereICT) {
        clonedScheda.content.richiestoParereICT = this.richiestoParereICT
      }

      if (!this.isRichiestoParereLIE) {
        clonedScheda.content.richiestoParereLIE = this.richiestoParereLIE
      }

      if (!this.isRichiestoParereUFF) {
        clonedScheda.content.richiestoParereUFF = this.richiestoParereUFF
      }

      return clonedScheda
    },
    
    saveSchedaIstruttoria(salvataggio) {
      // Questa funzione è stata modificata per ottimizzare l'export del pdf
      // in termini di aggiornamento dei pareri
      const idIstruttoria = this.$route.params.idIstruttoria;
      this.disableButton = true;
      
      // questo è il payload necessario per il solo update del
      // parere nella scheda istruttoria del db
      let payload = {
        idScheda: idIstruttoria,
        content: {
          notaTemporanea: this.note,
          notaTemporaneaRiservata: this.noteRiservate
        }
      }
      
      // AGGIORNAMENTO RICHIESTA PARERE ICT NELLO STORE (per Export)
      const commitPayload_req_ict = {
        idScheda: idIstruttoria,
        richiestoParereICT: this.richiestoParereICT
      }
      this.$store.commit('istruttoria/UPDATE_RICHIESTA_PARERE_ICT', commitPayload_req_ict)

      // AGGIORNAMENTO PARERE ICT NELLO STORE (per Export)
      if(!this.textAreaParereIctDisabled) {
        payload.content.parereICT = this.parereICT;

        const commitPayload = {
          idScheda: idIstruttoria,
          parereICT: this.parereICT
        }
        this.$store.commit('istruttoria/UPDATE_PARERE_ICT', commitPayload);
        // Di fatto chiamo l'api solo se ho cliccato in salva / reject
        if(salvataggio)
          this.updateParereInIstruttoria(salvataggio, payload)
          return
      }

      // AGGIORNAMENTO RICHIESTA PARERE LIE NELLO STORE (per Export)
      let commitPayload_req_lie = {
        idScheda: idIstruttoria,
        richiestoParereLIE: this.richiestoParereLIE
      }
      this.$store.commit('istruttoria/UPDATE_RICHIESTA_PARERE_LIE', commitPayload_req_lie)

      // AGGIORNAMENTO PARERE LIE NELLO STORE (per Export)
      if(!this.textAreaParereLieDisabled) {
        payload.content.parereLIE = this.parereLIE;

        const commitPayload = {
          idScheda: idIstruttoria,
          parereLIE: this.parereLIE
        };
        this.$store.commit('istruttoria/UPDATE_PARERE_LIE', commitPayload)
        // Di fatto chiamo l'api solo se ho cliccato in salva / reject
        if(salvataggio)
          this.updateParereInIstruttoria(salvataggio, payload)
          return
      }
      if(!this.textAreaParereUffDisabled) {
        payload.content.parereUFF = this.parereUFF;

        const commitPayload = {
          idScheda: idIstruttoria,
          parereUFF: this.parereUFF
        };
        this.$store.commit('istruttoria/UPDATE_PARERE_UFF', commitPayload);
        // Di fatto chiamo l'api solo se ho cliccato in salva / reject
        if(salvataggio)
          this.updateParereInIstruttoria(salvataggio, payload)
          return
      }
      else {
        // Aggiornamento intera Scheda Istruttoria
        let schedaIstruttoria = this.updateSchedaIstruttoria(this.getSchedaComplete({idSchedaIstruttoria: idIstruttoria}));
        // Di fatto chiamo l'api solo se ho cliccato in salva / reject
        if(salvataggio)
          this.saveWholeScheda(salvataggio, schedaIstruttoria)
      }
    },
    setValueModal(action){
      this.setModal=action;
      this.$bvModal.show('modalCompletaInvia');
    },

    actionModal(){
         switch (this.setModal) {
                    case "complete":
                        return this.saveSchedaIstruttoria('complete');
                    case "reject":
                      return this.saveSchedaIstruttoria('reject');
                    default:
                        console.log("Case false");
                    }
    },
    updateParereInIstruttoria(salvataggio, incomingPayload) {

      // incomingPayload contiene solo idScheda e content (con dentro
      // parere e nota temporanea)


      // questo payload deve contenere, oltre all'update del parere,
      // anche tutto il necessario per un eventuale aggiornamento dello
      // stato della scheda tramite la complete
      const payload = incomingPayload;

       // 'draft' (salvaInBozza) o 'complete'/'reject' (salva&invia)
      payload.salvataggio = salvataggio;
      const idIstruttoria = this.$route.params.idIstruttoria;
      payload.schedaIstruttoria = this.getSchedaComplete({idSchedaIstruttoria: idIstruttoria});

      this.$store.dispatch("istruttoria/updateParere", payload).then(
          () => {
            notify.success(
              notify.strings.success,
              notify.strings.saveProgettoOk
            );
            this.disableButton = false;
            if (salvataggio === "complete" || salvataggio === "reject") {
              this.$router.push({ name: "secure" });
            }
          },
          (error) => {
            console.log("udpate parere in Scheda Progetto Error: ", error);
            notify.error(
              notify.strings.error,
              notify.strings.errorSaveProgetto
            );
          }
        );
    },



    async saveWholeScheda(salvataggio, schedaIstruttoria) {
      await this.$store.dispatch("istruttoria/saveSchedaIstruttoria", {
          schedaIstruttoria: schedaIstruttoria,
          salvataggio: salvataggio,
          // parereReferenteIct: this.richiestoParereICT === "true",
          // parereReferenteEdile: this.richiestoParereEdile === "true",
          // parereReferenteDeroga: this.richiestoParereDeroga === "true",
        }).then(
          () => {
            notify.success(
              notify.strings.success,
              notify.strings.saveProgettoOk
            );
            this.disableButton = false;
            if (salvataggio === "complete" || salvataggio === "reject") {
              this.$router.push({ name: "secure" });
            }
          },
          (error) => {
            console.log("save Scheda Progetto Error: ", error);
            notify.error(
              notify.strings.error,
              notify.strings.errorSaveProgetto
            );
          }
        );
    }


  }
}
</script>