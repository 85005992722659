<template>
  <div id="verticalComponent">
    <b-button
      :id="'add-'+name"
      style="margin-left: auto;"
      class="align-right"
      @click="doAction('add')"
      variant="outline-primary"
      :disabled="disableAdd"
      v-if="visibilityAdd">
      <fa-icon :icon="['far', 'plus-square']" class="selector-icons" />
      Aggiungi
    </b-button>
    <b-table
      :id="idTablePdf"
      :tbodyTrClass="editedRowClass"
      :items="getItems"
      :fields="tabledata.header"
      :sort-by="sortBy">
      <template v-slot:cell(Azione)="data">
        <b-button
          :id="namingIdButton(x, data.index + 1)"
          variant="outline-primary"
          :key="x"
          v-for="x in data.value"
          size="sm"
          v-on:click="doAction(x, data.item)">
          <fa-icon
            v-if="x === 'edit'"
            :icon="['far', 'edit']"
            class="selector-icons" />
          <fa-icon
            v-if="x === 'delete'"
            :icon="['far', 'trash-alt']"
            class="selector-icons" />
        </b-button>
      </template>
    </b-table>
    <b-modal
      :id="'modalForm1-' + this.name"
      hide-footer
      size="lg"
      scrollable
      centered
      dialog-class="modal1-content"
      content-class="modal1-content">
      <template v-slot:modal-title>
        <h3>Modifica valore</h3>
      </template>
      <ModalForm
        :fdata="this.editFormData"
        :sch="this.getScheda()"
        :buttons="this.buttons"
        :parentName="this.name"
        :cfg="mappa"
        @editField="editRiga" />
    </b-modal>
  </div>
</template>
<script>
import ModalForm from "./modalForm1.vue";
import mapUtils from "@/helpers/mapUtils.js";
import { JSONPath as jpath } from "jsonpath-plus";
import calculation from "@/helpers/calculations.js";
import { mapGetters } from "vuex";
import tool from "@/helpers/tools.js";
import utils from '@/helpers/utils.js'

export default {
  name: "Vertical",
  data() {
    return {
      sortBy: '',
      currentLastIndex: -1,
      actions: ['edit', 'delete'],
      mappa: {},
      disableAdd: false,
      tabledata: {
        header: [],
        rows: [],
      },
      buttons: [
        {
          name: "Modifica",
          action: "editField",
          show: true,
          param: "edit",
        },
      ],
      editFormData: {},
      lastStarRegex: /\*(?!.*\*)/,
      isIdRegex: /\[.+\]/,
      editLine: -1
    };
  },
  components: {
    ModalForm,
  },
  props: {
    inputs: {
      type: Object,
    },
    dinamicIds: {
      type: Array,
      required: true,
    },
    name: String,
    title: String,
    idTablePdf: { type: String, default: () => '' },
  },
  created() {
    this.mappa = this.getTabelloneProgetto('progetto');
    if (this.mappa) {
      this.extractData();
    } else console.log(this.name, "error in mappa", this.mappa);
  },
  computed: {
    ...mapGetters({
      getTabelloneProgetto: "configuration/getTabellone",
      getSchedaProgetto: "progetto/getSchedaProgetto",
      getSchedaComplete: "progetto/getScheda",
    }),
    getItems() {
      let items = [];
      for (const obj of this.tabledata.rows) {
        let item = {
          note: {}
        };
        for (const key in obj) {
          if (!key.match(this.isIdRegex)) {
            item[key] = obj[key];
          } else {
            item[key] = obj[key].value;
            item.note[key] = obj[key].note;
          }
        }
        items.push(item);
      }
      return items;
    },
    visibilityAdd() {
      const actions = this.$projectGetActions(
        this.getSchedaComplete({ idSchedaProgetto: this.$route.params.idProgetto })
      );
      return actions.indexOf('add') !== -1;
    },
  },
  methods: {
    editedRowClass(item) {
      if(item.edited && !item.deleted)
        return 'changeInRow';
      else if(item.deleted)
        return 'deletedCell';
      return '';
    },
    // Costruzione id per Buttoni (Azioni nel componente)
    namingIdButton(action, index) {
      return this.$builtIdDinamically(this.name, action, index)
    },

    tooltip(value, key, item) {
      if (item.note[key]) return { title: "nota: " + item.note[key] };
      return {};
    },
    updateSchedaInStore(scheda) {
      this.$store.dispatch("progetto/updateSchedaProgettoObject", {
        idSchedaProgetto: this.$route.params.idProgetto,
        schedaProgetto: scheda,
      });
    },
    retrieveKeyFieldToCalculate(conf) {
        let keyFieldToCalculate = []
        Object.keys(conf).forEach(key => {
        if(conf[key].config && conf[key].config.calculationRule) {
            keyFieldToCalculate.push(key)
        }
      })
      return keyFieldToCalculate
    },
    formatter(value, key) {
      const mapElement = this.mappa[key];
      if(!mapElement)
        return value;
      return utils.formatOutput(mapElement, value);
    },
    extractData() {
      if (!this.dinamicIds || this.dinamicIds.length === 0 || !this.mappa) {
        console.error(
          this.name,
          "Invalid dinamicIds/mappa",
          this.dinamicIds,
          this.mappa
        );
        return;
      }
      let updateScheda = false;
      const schedaComplete = this.getSchedaComplete({
        idSchedaProgetto: this.$route.params.idProgetto,
      });
      let scheda = schedaComplete.schedaProgetto;
      if (!scheda) {
        console.error(this.name, "Invalid scheda", scheda);
        return;
      }
      this.tabledata = {
        header: [], 
        rows: []
      }
      // setup header
      for (const colId of this.dinamicIds) {
        let mapElement = this.mappa[colId];
        if (!mapElement) {
          console.error("no config, get default one for ", colId);
          mapElement = this.$getDefaultConfig();
          mapElement.config.path = colId;
        }
        const headerColumn = {
          key: colId,
          label: mapElement.config.label,
          tdAttr: this.tooltip,
          formatter: this.formatter,
          sortable: true
        };
        this.tabledata.header.push(headerColumn);
      }
      this.tabledata.header.push({ key: "Azione", label: "Azione" });
      this.sortBy = this.tabledata.header[0].key;

      // TODO choose id with largest number of array indices
      // currently first available id is chosen
      // suppongo che l'indice di riga sia l'ultimo disponibile sugli id
      let temp;
      for (let id1 of this.dinamicIds) {
        temp = jpath({ resultType: "all" }, "$" + id1, scheda);
        if (temp && temp.length !== 0) {
          break;
        }
      }

      if (!temp || temp.length === 0) {
        return;
      }

      // for all results, get the highest index
      // of most deeply nested array
      let maxIndex = -1;
      for (const item of temp) {
        const indexes = item.path.match(mapUtils.lastIndexRegex);
        if (indexes.length === 0) {
          console.error(this.name, "unknown index", item.path);
          continue;
        }
        const tempIndex = parseInt(indexes[0]);
        if(!isNaN(tempIndex) && tempIndex > maxIndex)
            maxIndex = tempIndex;
      }

      if (maxIndex < 0) {
        console.error(this.name, "index error", temp);
        return;
      }
      this.currentLastIndex = maxIndex;

      // add rows to table
      for (let i = 0; i <= maxIndex; i++) {
        let rowObj = {
          realIndex: i,
          _cellVariants: {},
          Azione: [],
        };
        const len = Object.entries(rowObj).length;
        for (const colId of this.dinamicIds) {
          const id = colId.replace(this.lastStarRegex, i);
          // Recuperiamo gli id dei campi che devono essere calcolati a runtime
          let idElementsByCalculate = this.retrieveKeyFieldToCalculate(this.mappa)
          const results = jpath("$" + id, scheda);

          let result;
          if (results.length === 0) {

            const retVal = mapUtils.createElementFromScratch(
              id,
              scheda,
              this.mappa
            );
            // Logica di calcolo
            if(idElementsByCalculate.indexOf(colId) > -1) {
                //es. colId : "['costiPersonale'][*]['totale']"
                let genericPathId = colId.match(/\[[^\]]*]/g);
                let keyScheda = genericPathId[0].replace(/\[|\]|'/g,'');

                //es. id : "['costiPersonale'][0]['totale']"
                let elementPathId = id.match(/\[[^\]]*]/g);
                let indexElement = elementPathId[1].replace(/\[|\]|'/g,'');

                let valueCalculated = calculation.calculateValue(this.mappa[colId], scheda, id)
                retVal.scheda[keyScheda][indexElement].totale = valueCalculated
                retVal.object = valueCalculated
            }
            scheda = retVal.scheda;
            result = retVal.object;
            updateScheda = true;
          } else if (results.length === 1) {
            result = results[0];
          } else {
            console.error("is an array?", colId, results);
          }
          const setupVal = mapUtils.setupComponentRow(
            rowObj,
            id,
            result,
            this.mappa,
            scheda,
            schedaComplete.taskInfo,
            this.actions
          );
          rowObj = setupVal.rowObj;
        }
        if(Object.entries(rowObj).length !== len)
          this.tabledata.rows.push(rowObj);
      }
      if (updateScheda) {
        this.updateSchedaInStore(scheda);
      }
      // set dello stato del bottone add
      this.disableAdd = this.$disableAddBtnInVerticalComp(scheda, this.name);
    },
    updateSchedaInDeletedMode(item) {
      const index = item.realIndex;
      // TODO funziona solo per campi con un solo indice di array
      // aggiungere gestione mutlti-asterisco
      const schedaComplete = this.getSchedaComplete({
        idSchedaProgetto: this.$route.params.idProgetto,
      });
      let clonedScheda = tool.genericFunctions.cloneObject(
        schedaComplete.schedaProgetto
      );
      let rowObj = this.tabledata.rows[index];
      // reset delle azioni, verrano calcolate da setupComponentRow
      rowObj.Azione = [];
      
      for (const id1 of this.dinamicIds) {
        const id = id1.replace(this.lastStarRegex, index);
        if(!clonedScheda.dataEntryConfiguration) {
          clonedScheda.dataEntryConfiguration = {};
        }
        
        let conf = clonedScheda.dataEntryConfiguration[id];
        if (conf && conf.data) {
          conf.data.edited = true;
          conf.data.deleted = true;
        }
        else {
          // fix nel caso in cui i data di configurazione non siano presenti
          clonedScheda.dataEntryConfiguration[id] = {
            data: {
              edited: true,
              deleted: true
            }
          }
        }
        if(!clonedScheda.dataEntryConfiguration[id].data.editTag) {
          clonedScheda.dataEntryConfiguration[id].data.editTag = mapUtils.createEditTag1(this.mappa, id, this.title); // null=titolo del collapse
        }

        // only change color variants, not value
        // value is taken from scheda, but in case
        // of error it is taken from tabledata
        let value = "";
        if (rowObj[id1]) value = rowObj[id1].value;
        const temp = jpath("$" + id, clonedScheda);
        if (temp.length === 1) {
          value = temp[0];
        } else console.error(this.name, "is this field an array?", id);
        const setupVal = mapUtils.setupComponentRow(
          rowObj,
          id,
          value,
          this.mappa,
          clonedScheda,
          schedaComplete.taskInfo,
          this.actions
        );
        rowObj = setupVal.rowObj;
      }
      this.tabledata.rows[index] = rowObj;
      this.updateSchedaInStore(clonedScheda);
      // refresh dello stato del bottone add dopo un elimina
      this.disableAdd = this.$disableAddBtnInVerticalComp(clonedScheda, this.name);
    },
    getScheda() {
      return this.getSchedaProgetto({
        idSchedaProgetto: this.$route.params.idProgetto,
      });
    },
    editRiga(actionFromModal) {
      // Se actionFromModal contiene un campo che deve essere calcolato,
      if(this.retrieveKeyFieldToCalculate(actionFromModal.conf).length > 0) {
        let conf = actionFromModal.conf
        let content = actionFromModal.content
        let keyFieldToCalculate = this.retrieveKeyFieldToCalculate(conf)[0]
        let value = calculation.calculateValue(actionFromModal.conf[keyFieldToCalculate], this.getScheda(), keyFieldToCalculate, actionFromModal);
        content[keyFieldToCalculate].value = value
      }
      this.$bvModal.hide("modalForm1-" + this.name);
      let rowObj = this.tabledata.rows[this.editLine];
      const schedaComplete = this.getSchedaComplete({
        idSchedaProgetto: this.$route.params.idProgetto,
      });
     
      if (!rowObj) {
        this.currentLastIndex++;
        rowObj = {
          _cellVariants: {},
          realIndex: this.currentLastIndex
        };
      }
     
      const retVal = mapUtils.updateComponent(
        actionFromModal,
        schedaComplete,
        rowObj,
        this.mappa,
        this.actions,
        this.title
      );
      const clonedScheda = retVal.clonedScheda;

      this.updateSchedaInStore(clonedScheda);
      this.extractData();
    },
    addRiga() {
      this.editFormData = { content: {}, conf: {} };
      let mapElement;
      const newIndex = this.currentLastIndex + 1;
      this.editLine = newIndex;
      const scheda = this.getScheda()
      if (this.dinamicIds) {
        // Per ogni id ho n colonne
        for (const colId of this.dinamicIds) {
          const id = colId.replace(this.lastStarRegex, newIndex);
          mapElement = tool.genericFunctions.cloneObject(
            mapUtils.getMapElement(this.mappa, id)
          );
          // replace path with correct id;
          mapElement.config.path = id;
          mapElement.data = this.$getDefaultData();
          
          mapElement.data.added = true;

          let value = "";
          // automatic value calculation
          if (mapElement.config.calculationRule) {
            if(mapElement.config.type === 'enum') {
              
              mapElement.config.enumValues = calculation.calculateEnum(mapElement, scheda);
            } else {
              value = calculation.calculateValue(mapElement, scheda, id);
            }
          }

          
          let val = { id: id };
          val.value = value;
          this.editFormData.conf[id] = mapElement;
          this.editFormData.content[id] = val;
        }
      }
    },
    doAction(actionToDo, item) {
      const index = item ? item.realIndex : -1;
      let delMsg = "Si sta per eliminare definitivamente un elemento. "
      delMsg = delMsg.concat("L'elemento eliminato non comparirà tra le modifiche e l'operazione non è reversibile. Procedere?");
      switch (actionToDo) {
        case "add":
          this.addRiga();
          this.$bvModal.show("modalForm1-" + this.name);
          break;
        case "delete":
          this.$bvModal.msgBoxConfirm(delMsg, {
            title: "Conferma eliminazione",
            okVariant: 'success',
            cancelVariant: 'danger',
            okTitle: 'Elimina',
            cancelTitle: 'Chiudi',
            titleClass: 'card-title',
            dialogClass: 'card-body'
          })
          .then(value => {
            if(value) {
                this.updateSchedaInDeletedMode(item);
              }
          })

          break;
        case "edit":
          {
            this.editFormData = { content: {}, conf: {} };
            // get fresh data from storage
            const scheda = this.getScheda();

            const row = this.tabledata.rows[index];
            for (const column in row) {
              // skip non consistent info
              if (!column.match(this.isIdRegex)) continue;

              const id = row[column].id;
              const values = jpath("$" + id, scheda);
              let mapElement = mapUtils.getMapElement(this.mappa, id);
              const dataEntry = scheda.dataEntryConfiguration;

              if (dataEntry && dataEntry[id]) {
                mapElement.data = tool.genericFunctions.cloneObject(
                  dataEntry[id].data
                );
              } else {
                mapElement.data = this.$getDefaultData();
              }
              let val = { id: id };
              if (values.length === 0) {
                console.error("valore non trovato in scheda: ", id);
              } else {
                val.value = values[0];
              }
              this.editFormData.conf[id] = mapElement;
              this.editFormData.content[id] = tool.genericFunctions.cloneObject(val);
              this.editLine = index;
            }
            this.$bvModal.show("modalForm1-" + this.name);
          }
          break;
        default:
          this.editFormData = {};
          break;
      }
    },
  },
};
</script>
