<template>
  <div id="modificheProgetto">
    <div v-if="isMappa">
      <DynamicTable2 :idTablePdf="idTablePdf" v-bind:tdata="this.fillData" :doAction="this.doAction" :actions="this.actions"/>
    </div>
    <div v-else>
      <DynamicTable :idTablePdf="idTablePdf" v-bind:tdata="this.fillData" :doAction="this.doAction" :actions="this.actions"/>
    </div>
    <modal :hidden="!showModal" :buttons="this.buttons" @close="showModal = false">
      <div class="row-title" slot="header">{{modalHeader}}</div>
      <div v-if="!isMappa" slot="body">
        <DynamicTable :tdata="this.modalData"/>
      </div>
      <div v-if="isMappa" slot="body">
        <DynamicTable2 :tdata="this.modalData"/>
      </div>
    </modal>
    <modalForm  v-bind:fdata="this.editFormData"
                v-bind:sch="this.scheda"
                :hidden="!showEditForm"
                :buttons="this.buttons"
                :cfg="incomingData.schedaAssessment.dataEntryConfiguration"
                @close="showEditForm = false"
                @salvaRecord="aggiornaRiga">
      <div class="row-title" slot="header">{{modalHeader}}</div>
    </modalForm>
  </div>
</template>


<script>
import DynamicTable from "@/components/dynamicTable.vue";
import DynamicTable2 from "@/components/dynamicTable2.vue";
import modalForm from "@/components/modalForm.vue";
import modal from "@/components/modal.vue";
import tool from '@/helpers/tools.js'
import notify from "@/helpers/notifications.js"
import mapUtils from '@/helpers/mapUtils.js'

export default {
  name: "modificheProgetto",
  components: {
    DynamicTable,
    DynamicTable2,
    modalForm,
    modal
  },

  props: {
    idTablePdf: { type: String, default: () => '' },
    incomingData: null,
    salvaSchedaToStore: Function,
    viewOnlyMode: Function
  },

  computed: {
      
      fillData: function() {
        this.extractData(this.incomingData);
        return this.tabledata;
      },

      isMappa: function(){
      if(this.incomingData && this.incomingData.schedaAssessment.dataEntryConfiguration){
          return true;
        }

        return false;
      }
  },

  data() {
    return {
      showEditForm: false,
      editFormData: {},
      cont : {},
      numeroRiga: 0,
      lastAction: "",
      showModal: true,
      modalData: {},
      actions: ['edit'],
      tabledata: {
        header: ["Modifiche Progetto"],
        rows: [
          {
            content: {
              "Modifiche Progetto": "Non disponibile"
            }
          }
        ]
      },
      buttons: [
            {
              name: 'Salva',
              action: 'salvaRecord',
              show: true,
              param: "edit"
            }
      ],
      modalHeader: "",
      mappa: null,
      scheda: {}

    };
  },

  mounted() {
    this.showEditForm = false;
    this.showModal = false;
  },

  methods: {
      
     aggiornaRiga(actionFromModal) {
      if (this.mappa){
        let clonedScheda = mapUtils.aggiornaScheda(actionFromModal, tool.genericFunctions.cloneObject(this.incomingData.schedaAssessment));
        this.salvaSchedaToStore(clonedScheda);
      } else { 
        this.tabledata.rows[this.numeroRiga].content = actionFromModal.content; 
        this.preparaScheda(this.tabledata);
      }
      this.showEditForm = false;
    },

    preparaScheda(tableData) {
      let scheda = tool.genericFunctions.cloneObject(this.incomingData.schedaAssessment);
      
      if(tableData && scheda){
       
       if (this.lastAction==="edit"){
          
          //sovrascrivo solo i campi modificabili
          let riga = tableData.rows[this.numeroRiga].content;
          
          scheda.modificheSchedaProgetto = riga['Modifiche Progetto']; 
        } else {
          notify.error("Errore","Azione non gestita");
          console.error("persistenza dell'azione '"+this.lastAction+"' non gestita!?");
        }
        
        //persisto il dato nello store
        this.salvaSchedaToStore(scheda);
      } else {
        notify.error("Errore","Nessun dato da persistere");
        console.error("modificheProgetto.preparaScheda: dati passanti NULL!?");
      }
      
    },

    doAction(actionToDo, index, item) {
        this.lastAction=actionToDo; //memorizzo l'ultima l'azione selezionata, servirà in fase di persistenza
        this.numeroRiga=index;      //memorizzo il numero di riga impattato

        switch(actionToDo) {
          case 'edit':
            this.showEditForm = true;
            if (this.mappa) {
              this.editFormData = mapUtils.editFormDataFromRiga(item);
            } else {
              this.cont = tool.genericFunctions.cloneObject(this.tabledata.rows[index].content);
              this.editFormData = {
                content : this.cont,
                editable : [true ] //NB: array che decide quali campi sono editabili e quali no
              }
            }
            this.modalHeader = "Modifiche Progetto";
            this.buttons[0].show=true;
            break;
          default:
            notify.error("Errore","Azione non gestita");
            console.error("modificheProgetto: azione='"+actionToDo+"' non gestita!?");
            break;
        }
    },

    extractData(schedaCompleta) {
      if(!schedaCompleta){
            console.log("modificheProgetto: Invalid input Data in modificheProgetto!?");
            return;
      }
      this.scheda = schedaCompleta.schedaAssessment;
      let inputData = schedaCompleta.schedaAssessment;
      
      this.viewOnlyMode(schedaCompleta, this.actions);
      
      let rows = [];
    
      let row = {};
      let mappa = inputData.dataEntryConfiguration;
      if (mappa) {
        this.mappa = mappa;
        
        let colIds = [];
        //definisco gli id delle colonne da visualizzare
        colIds.push("['modificheSchedaProgetto']");
        
        let myConf = mapUtils.costruisciConfigurazione(colIds, mappa);

        row = {
              content: {},
              conf : myConf
        };

        //scrivo il valore dei vari campi (stesso ordine usato sopra) con l'ID : valore
        row.content[colIds[0]] = inputData.modificheSchedaProgetto;
        
      } else {

        row = {
          content: {
            "Modifiche Progetto": (inputData.modificheSchedaProgetto)? inputData.modificheSchedaProgetto : ""
          }
        };
      }

      rows.push(row);
      
      this.tabledata.rows = rows;
      
    }
  }
};
</script>