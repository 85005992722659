<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header"/>
            <b-button variant="danger" class="modal-default-button" @click="$emit('close')">
               <fa-icon :icon="['far', 'window-close']" class="close-icons"/>
            </b-button>
          </div>
          <table class="modal-table">
            <caption></caption>
            <th style="display:none"></th>
            <span v-for="(field, name, index) in returnData.content" v-bind:key="index">
            <!-- <tr v-for="(field, name, index) in returnData.content" v-bind:key="index" class="modal-field"> -->
              <tr v-if="checkRelation(field)" class="modal-field" >
                <td class="modal-label" style="width:10vw;">
                  <strong>
                    Verificare anche i seguenti campi:
                  </strong>
                </td>
                <td class="modal-label">
                  <strong>
                    <span v-for="x of getRelation(field)" :key="x.label">{{x.tab}} -> {{x.label}}<br></span>
                  </strong>
                </td>
              </tr>
              <tr class="modal-field">
                <td class="modal-label">
                  <label> {{returnData.conf[field.id].config.label}} </label>
                </td>
                <td v-if="checkDate(field)" class="modal-field">
                  <b-form-datepicker  v-if="checkDate(field)"
                              boundary="window"
                              locale="it"
                              hide-header dropright
                              label-help="Usa i cursori per navigare la data"
                              
                              @input="validate(field.id, field.value)"

                              v-model="returnData.content[field.id].value" 
                              :disabled="checkDisabled(field)"
                              :state="computeState(field)"
                              :id="field.id"
                              value-as-date/>
                </td>
                <td v-else-if="checkTextArea(field)" class="modal-field">
                  <textarea class="form-control"
                      :class="{'form-control is-invalid': !isValid[field.id]}"
                      :id='field.id' @input=aggiorna :name='name' :value='field.value'
                      :disabled="checkDisabled(field)"></textarea>
                </td>
                <td v-else-if="isEnum(field)" class="modal-field">
                  <select :id='field.id' @change=aggiorna :name='field.id' :disabled="checkDisabled(field)">
                    <option v-for="option in getEnum(field)" :selected="option.value === field.value"  
                            :key="option.value" :value="option.value">{{option.label}}</option>
                  </select>
                </td>
                <td v-else class="modal-field">
                  <input class="form-control"
                      :class="{'form-control is-invalid': !isValid[field.id]}"
                      type="text" :id='field.id' @input=aggiorna :name='name' :value='format(field)'
                      :disabled="checkDisabled(field)"> 
                </td>
                <td class="modal-field">
                  <textarea :hidden="checkDisabled(field) || returnData.conf[field.id].config.suppressNote"
                      :class="{'form-control is-invalid': !isValid[field.id+'_nota']}"
                      placeholder="Note"
                      :id="field.id+'_nota'"
                      @input="aggiornaNota($event, field)"
                      :value="returnData.conf[field.id].data.note"></textarea>
                </td>
                <td class="modal-field">
                  <div class="error"><ul> <li v-for="errs of errorList(field.id)" :key="errs">{{errs}}</li></ul></div>
                </td>
                
              </tr>
              
            <!-- </tr> -->
            </span>
            
          </table>
          <div class="modal-footer">
            <slot name="footer">
              <b-button variant="success" v-for="bt in buttons" :hidden="!bt.show" :key="bt.name" 
              class="modal-default-button" @click="controlloFinale(bt.action, returnData)">
                {{bt.name}}
              </b-button>
              <b-button variant="danger" class="modal-default-button" @click="$emit('close')">
                Chiudi
              </b-button>
            </slot>
          </div>

        </div>
      </div>
    </div>
  </transition>

</template>
  
<script>

import tool from '@/helpers/tools.js'
import validation from '@/helpers/validations.js'
import mapUtils from '@/helpers/mapUtils.js'

export default {
  name: "modalForm",
  components: {
  },
  props: {
    fdata: {
      type: Object,
      required: true
    },
    buttons:
    {
      type: Array,
    },
    sch: {
      type: Object,
      required: true  //chiunque pensi di utilizzare questa form di editing deve sempre passare la scheda contente tutti i dati
    },
    cfg: {
      type: Object
    }
  },
  data() {
    return {
      returnData: {},
      isValid: {},
      errorStrings: {},
      initialData: null
    };
  },
  watch: {
    fdata: function() {
      this.setupData();
    }
  },

  computed: {
    checkRelation() {
      return field => {
        const id = mapUtils.getGenericArrayKey(field.id);
        if(!id)
          return false;
        const mapElement = mapUtils.getMapElement(this.cfg, id);
        if(!mapElement)
          return false;
        const config = mapElement.config;
        return config && config.relatedTo && config.relatedTo.length > 0;
      }
    },
    getRelation() {
      return field => {
        const retVal = [];
        const id = mapUtils.getGenericArrayKey(field.id);
        if(!id)
          return retVal;
        const mapElement = mapUtils.getMapElement(this.cfg, id);
        if(!mapElement)
          return retVal;
        const config = mapElement.config;
        if(!config || !config.relatedTo || config.relatedTo.length === 0)
          return retVal;
        const rels = config.relatedTo;
        for(const rel of rels) {

          if(!this.cfg[rel] ||!this.cfg[rel].config) {
            console.error('relazione non trovata', rel);
            continue;
          }
          const conf = this.cfg[rel].config;
          retVal.push({
            tab: conf.tab,
            label: conf.label
          })
        }
        return retVal;
      }
    },
    computeState() {
      return field => {
        if(this.checkDisabled(field))
          return null;
        // WARNING: questo controllo dovrebbe dare uno state null all'apertura della modalform
        // verificare che sia efficace e non mascheri un bug
        if(!field.value && this.isValid[field.id] == null)
          return null;
        return this.validate(field.id, field.value);
      }
    },

    errorList() {
      
      return id => {
        let list = [];
        if(this.errorStrings[id])
          list.push(this.errorStrings[id]);
        if(this.errorStrings[id+'_nota'])
          list.push(this.errorStrings[id+'_nota']);
        return list;
      }
    },

    checkDisabled: function() {
      return field => {
        let disabled = this.returnData.conf[field.id].config.readonly;
        let tab = this.returnData.conf[field.id].config.tab;
        if(tab && this.$parent.$parent && this.$parent.$parent.title) {
          disabled = disabled || (tab !== this.$parent.$parent.title);
        }

        return disabled;
      }
    },

    getEnum: function() {
      return field => {
        if(this.returnData.conf && this.returnData.conf[field.id].config.type === 'enum' && this.returnData.conf[field.id].config.enumValues) { // se il  campo è di tipo enum
          let enumValues = [];
          if(field.value == null || field.value == undefined) // valore di default
            enumValues.push({'label': 'Non selezionato', 'value': field.value});
          for(let value of this.returnData.conf[field.id].config.enumValues) {
            enumValues.push({'label': value, 'value': value});
          }
          return enumValues;
        }// se il campo non è enum ma ho bisogno comunque della tendina 
        else if(this.returnData.content[field.id] && this.returnData.content[field.id].enumValues) { 
          return this.returnData.content[field.id].enumValues;
        }
        return null;
      }
    },
    
    isEnum: function() {
      return field => {
        if(this.returnData.conf && this.returnData.conf[field.id].config.type === 'enum') { // se il  campo è di tipo enum
          return true;
        }// se il campo non è enum ma ho bisogno comunque della tendina 
        else if(this.returnData.content[field.id] && this.returnData.content[field.id].enumValues) { 
          return true;
        }
        return false;
      }
    },

    isBeneficiari: function() {
      return field => {
        return (this.returnData.conf && this.returnData.conf[field.id].config.type === 'enumstring');
      }
    },

    checkDate: function() {
      return field => {
        if(this.returnData.conf &&
           this.returnData.conf[field.id].config.type === 'date') {
          return true;      
        }
        return false;
      }
    },

    checkTextArea: function() {
      return field => {
        if(this.returnData.conf &&
           this.returnData.conf[field.id].config.type === 'text') {
          return true;      
        }
        return false;
      }
    },

  },

  created() {
    this.setupData();
  },
/* UNUSED
  updated() {
    if(this.$props.returnData && this.$props.returnData.conf) {
      this.returnData = tool.genericFunctions.cloneObject(this.$props.fdata);
      for(let key of Object.keys(this.$props.returnData.conf)) {
        if(this.isValid[key] == undefined) 
          this.$set(this.isValid, key, true);
          
        if(this.isValid[key+'_nota'] == undefined) 
          this.$set(this.isValid, key+'_nota', true);

        // set default error strings for each field
        if(this.errorStrings[key] == undefined)
          this.$set(this.errorStrings, key, '');
        if(this.errorStrings[key+'_nota'] == undefined)
          this.$set(this.errorStrings, key+'_nota', '');
      }
    }
  },
*/
  methods: {
    setupData() {
      if(this.$props.fdata && this.$props.fdata.conf) {
        this.initialData = tool.genericFunctions.cloneObject(this.$props.fdata);
        this.returnData = tool.genericFunctions.cloneObject(this.$props.fdata);
        for(let key of Object.keys(this.$props.fdata.conf)) {
          if(this.isValid[key] == undefined) 
            this.$set(this.isValid, key, true);
          if(this.isValid[key+'_nota'] == undefined) 
            this.$set(this.isValid, key+'_nota', true);

          // set default error strings for each field
          if(this.errorStrings[key] == undefined)
            this.$set(this.errorStrings, key, '');
          if(this.errorStrings[key+'_nota'] == undefined)
            this.$set(this.errorStrings, key+'_nota', '');
        }
      }
    },
    controlloFinale(action, data) {
        let retVal = true;
        for(let key in this.returnData.content) {
          retVal = retVal && this.validate(key, this.returnData.content[key].value);
          retVal = retVal && this.validateNota(key + '_nota', this.returnData.conf[key].data.note, this.returnData.content[key]);
          // this.updateValue(key);
        }
        if(retVal)
          this.$emit(action, data);
        else
          return false;
    },

    validateNota(noteId, value, field) {
      let id = field.id;

      // niente validazione se il campo è eliminato
      if(this.returnData.conf[id].data.deleted) {
          return true;
      }

      let isDatumChanged = false;
      if(this.initialData) {
        if(this.initialData.content[id] && this.initialData.content[id].value != field.value) {
          isDatumChanged = true;
        }
      }

      if((value == null || value === '') && this.returnData.conf[id].config.mandatoryNote) {
        if(isDatumChanged || (!isDatumChanged && this.returnData.conf[id].data.edited)) {
          if(this.isValid[noteId] !== undefined)
            this.isValid[noteId] = false;
          if(this.errorStrings[noteId] !== undefined)
            this.errorStrings[noteId] = validation.strings.mandatoryNote;
        }
      }
      else {
          if(this.isValid[noteId] !== undefined)
            this.isValid[noteId] = true;
          if(this.errorStrings[noteId] !== undefined)
            this.errorStrings[noteId] = '';
      }
      return this.isValid[noteId];
    },


    aggiornaNota (event, field) { // validazione per nota non vuota
      let target = event.target;
      let id = field.id;
    
      this.validateNota(target.id, target.value, field);
      
      if(this.returnData.conf && this.returnData.conf[id]) {
        this.returnData.conf[id].data.note = target.value;
      }
    },

    validate(id, value) {
      let config = this.returnData.conf[id];
      let tab;
      if(this.$parent.$parent && this.$parent.$parent.title)
        tab = this.$parent.$parent.title;
      //passo tutta la scheda di monitoraggio per avere visibilità di tutti i dati disponibili
      let errors = validation.defaultValidation(config, value, tab, this.sch, this.returnData); 
      
      if(errors.length > 0) {
        this.errorStrings[id] = errors[0];
        this.isValid[id] = false;
      }
      else {
        this.errorStrings[id] = '';
        this.isValid[id] = true;
      }

      return this.isValid[id];
    },
/* UNUSED
    updateValue(id)
    {
      if(!id)
        return;
      let conf = this.returnData.conf[id];
      let content = this.returnData.content[id];
      if(!conf || !content)
        return;
      if(conf.config.type === 'currency') {
        this.returnData.content[id].value = utils.fromCurrencyToValue(content.value);
      }
    },
*/
    format(field) {
      if(!field)
        return '';
      if(field.value == null || field.value == undefined)
        return field.value;
      let conf = this.returnData.conf[field.id];
      if(!conf)
        return field.value;
      // BUG #15130: rimossa conversione
      // if(conf.config.type === 'currency') {
      //   let temp = field.value.toString();
      //   temp = temp.replace('.',',');
      //   return temp;
      // }
      return field.value;
    },

    aggiorna ({ type, target }) {

      const event = {
          type,
          isCheckbox: target.type === 'checkbox',
          target: {
            id: target.id,
            type: target.type,
            name: target.name,
            value: target.value,
            checked: target.checked
          }
      }

      //setto dinamicamente il valore della proprietà variata
      if(target.selectedIndex == undefined) {
        if(this.returnData.conf) {
          this.returnData.content[event.target.id].value = event.target.value;
          this.validate(event.target.id, event.target.value);
        }
        else {
          this.returnData.content[event.target.name] = event.target.value;
        }
      }
      else {
        if(this.returnData.conf) {
            this.returnData.content[event.target.id].value = target[target.selectedIndex].value;
            /*
            {
              label: target[target.selectedIndex].label,
              value: target[target.selectedIndex].value,
            }
            */
        }
        else {
          this.returnData.content[event.target.name] = {
            label: target[target.selectedIndex].label,
            value: target[target.selectedIndex].value,
          }
        }
      }
    }
  }
}


</script>

<style>

input {
  border: 1px solid silver;
  border-radius: 4px;
  background: white;
  padding: 5px 10px;
}

.dirty {
  border-color: #5A5;
  background: #EFE;
}

.dirty:focus {
  outline-color: #8E8;
}

.error {
  border-color: red;
  background: #FDD;
}

.error:focus {
  outline-color: #F99;
}

ul {
  list-style-type:none;
}

</style>