import endpoints from "@/components/endpoints.vue";
import tools from '@/helpers/tools.js'
import Vue from 'vue'

const state = {
  schede: {},
  baseVersion: {}
}

const getters = {
  getScheda(state) {
    return (payload) => {

      if (tools.objectHasValue(payload)
        && tools.objectHasValue(state.schede[payload.codiceProgetto])
        && tools.objectHasValue(state.schede[payload.codiceProgetto][payload.idPeriodo])
        ){
          return state.schede[payload.codiceProgetto][payload.idPeriodo]    
        }
        return null;
    }
  },
  getSchedaMonitoraggio(state, getters1) {
    return (payload) => {
      let s = getters1.getScheda(payload)
      // console.log(s)
      if (s){
        return s.schedaMonitoraggio
      }
      return null

    }
  },
  getTaskList(state) {
    return (codiceProgetto, idPeriodo) => {
      if(!codiceProgetto || !idPeriodo)
        return null;
        
      if(state.schede[codiceProgetto] && state.schede[codiceProgetto][idPeriodo])
        return state.schede[codiceProgetto][idPeriodo].taskList;

      return null;
    }
  },
  getTaskId(state, getters1) {
    return (codiceProgetto, idPeriodo) => {
        let task = getters1.getTaskList(codiceProgetto, idPeriodo);
        if(!task)
          return null;
      return task.id;
    }
  }
}

const mutations = {

  RESET(state) {
    state.schede = {};
    state.baseVersion = {};
  },

  CLEAR_TASKS(state) {
    for(let i in state.schede) {
      if(Object.values(state.schede[i]))
        Object.values(state.schede[i])[0].taskList = {};
    }

  },

  CLEAR_SCHEDE_MONITORAGGIO(state) {
    Object.values(state.schede).forEach(e => {
      e.schedaMonitoraggio = null;
    });
    state.baseVersion = {};
  },

  CLEAR_SCHEDA_MONITORAGGIO(state, payload) {
    if(!payload || !payload.codiceProgetto || !payload.idPeriodo)
    {
      console.error('cancellazione scheda monitoraggio fallita', payload);
      return;
    }
    let progetto = payload.codiceProgetto;
    let periodo = payload.idPeriodo;
  

    if(!state.schede[progetto] || !state.schede[progetto][periodo]) {
      console.error('cancellazione: scheda non trovata', payload);
      return;
    }
    state.schede[progetto][periodo].schedaMonitoraggio = null;
    state.baseVersion = {};
  },

  SET_BASEVERSION(state, payload) {
    state.baseVersion = payload.baseVersion;
  },


  // I pass 'payload' because you can't pass more than 1 object in MUTATIONS!
  SET_SCHEDA_MONITORAGGIO(state, payload) {
      let temp = state.schede[payload.codiceProgetto];
      if(!temp)
        state.schede[payload.codiceProgetto] = {};
      temp = state.schede[payload.codiceProgetto][payload.idPeriodo];
      if(!temp)
        state.schede[payload.codiceProgetto][payload.idPeriodo] = {};

      state.schede[payload.codiceProgetto][payload.idPeriodo].schedaMonitoraggio = payload.scheda;
      if(payload.key)
        state.schede[payload.codiceProgetto][payload.idPeriodo].key = payload.key;
      if(payload.history)
        state.schede[payload.codiceProgetto][payload.idPeriodo].history = payload.history;
    },
    ADD_SCHEDA_NOTE_RISERVATE(state, payload) {
      let cod = payload.codiceProgetto
      let periodo = payload.idPeriodo
      state.schede[cod][periodo].schedaNoteRiservate = payload.schedaNoteRiservate;
    },
    SET_TASK(state, payload) {
      let cod = payload.codiceProgetto
      let periodo = payload.idPeriodo
      let temp = state.schede[cod];
      if(!temp)
        state.schede[cod] = {};
      temp = state.schede[cod][periodo];
      if(!temp)
        state.schede[cod][periodo] = {};
      state.schede[cod][periodo].taskList = payload.taskList;
  },
}


const actions = {
  retrieveSchedaNoteRiservate(context, payload) {
    const keySchedaNoteRiservate = payload.noteRiservateKey;
    const version = payload.noteRiservateVersion;
    // WARNING: workaround per i vecchi processi
    if(!payload.noteRiservateKey || !payload.noteRiservateVersion) {
      return Promise.reject(new Error('note riservate non disponibili'));
    }

    return Vue.prototype.$get(endpoints.noteRiservate(keySchedaNoteRiservate), {version: version})
    .then(
      result => {
        if(result) {
          let schedaNoteRiservate = result;
          context.commit('ADD_SCHEDA_NOTE_RISERVATE', {
            schedaNoteRiservate: schedaNoteRiservate,
            codiceProgetto: payload.progetto.codiceProgetto,
            idPeriodo: payload.idPeriodo
          });
          console.log('result schedaNoteRiservate ' + keySchedaNoteRiservate, schedaNoteRiservate);
        } else {
          console.log('no results schedaNoteRiservate ', keySchedaNoteRiservate);
        }
      },
      error => { 
        throw error;
      }
    );
  },
  claimTask(context, id) {
    return Vue.prototype.$post(endpoints.claim(id))
    .then(
      result => {
        return result;
      },
      error => {
        throw error;
      }
    );
  },
  unclaimTask(context, id) {
    return Vue.prototype.$post(endpoints.unclaim(id))
    .then(
        result => {
          return result;
        },
        error => {
          throw error;
        }
      );
  },

  retrieveSingleTask(context, id) {
    return Vue.prototype.$get(endpoints.task(id))
    .then(
      result => {
          let task = result;
          context.commit('SET_TASK', {
            taskList: task,
            codiceProgetto: task.processVariables.codiceProgetto.value,
            idPeriodo: task.processVariables.idPeriodo.value
          });
          return result;
      },
      error => {
        throw error;
      }
      );
  },
  retrieveTasks(context) {
    // clear tasks at once!
    context.commit('CLEAR_TASKS');
    return Vue.prototype.$get(endpoints.tasksForMe)
    .then(
      result => {
        for(const task of result) {
          if(!task.processVariables || !task.processVariables.codiceProgetto || ! task.processVariables.idPeriodo)
            continue; // variables contain periodo and progetto, cannot commit otherwise
          
          if(!task.processDefinitionId.includes('onitoraggio')) { // add only tasks connected to schede monitoraggio
            continue;
          }

          context.commit('SET_TASK', {
            taskList: task,
            codiceProgetto: task.processVariables.codiceProgetto.value,
            idPeriodo: task.processVariables.idPeriodo.value
          });
        }
        return result;
      },
      error => { // TODO aggiungere throw
        console.error("retrieve tasks error: " + error);
        return [];
      }
    );
  },
  retrieveSchedaMonitoraggio(context, {
    key: schedaMonitoraggioKey
  }) {
    return Vue.prototype.$get(endpoints.schedaMonitoraggio(schedaMonitoraggioKey))
    .then(
      result => {
        let baseVersion;
        if(result.baseVersion) {
          baseVersion = result.baseVersion;
        } else {
          baseVersion = result.schedaMonitoraggio;
        }
        context.commit('SET_BASEVERSION', {
          baseVersion: baseVersion
        });

        context.commit('SET_SCHEDA_MONITORAGGIO', {
          codiceProgetto: result.schedaMonitoraggio.progetto.codiceProgetto, 
          idPeriodo: result.schedaMonitoraggio.idPeriodo,
          scheda: result.schedaMonitoraggio,
          key: result.key,
          history: result.history
        });
      },
      error => {
        console.error("retrieveSchedaMonitoraggio error: ", error.response);
        throw error;
      }
    )
  },
  // only one parameter
  localSaveSchedaMonitoraggio(context, {
    codiceProgetto: codiceProgetto, 
    idPeriodo: idPeriodo,
    scheda: scheda
  }) {
    context.commit('SET_SCHEDA_MONITORAGGIO', {
      codiceProgetto: codiceProgetto, 
      idPeriodo: idPeriodo,
      scheda: scheda
    })
  },

  clearSchedaMonitoraggio(context, {
    codiceProgetto: codiceProgetto,
    idPeriodo: idPeriodo,
  }) {
    context.commit('CLEAR_SCHEDA_MONITORAGGIO', {
      codiceProgetto: codiceProgetto,
      idPeriodo: idPeriodo,
    });
  },

  saveSchedaMonitoraggio(context, {
    codiceProgetto: codiceProgetto,
    idPeriodo: idPeriodo,
    scheda: scheda
  }) {
    // first save it locally...
    context.commit('SET_SCHEDA_MONITORAGGIO', {
      codiceProgetto: codiceProgetto,
      idPeriodo: idPeriodo,
      scheda: scheda.monitoraggio
    })
    // ...then save it remotely
    return Vue.prototype.$post(endpoints.schedaMonitoraggioPost, scheda, { branch: 'monitoraggio'})
    .then(
      result => {
          console.log("POST to save scheda monitoraggio succeeded", scheda);
          if(result.monitoraggio.result) {
            const tasklist = result.monitoraggio.result;
            console.log("tasklist:", tasklist)
            context.commit('SET_TASK', {
              taskList: tasklist,
              codiceProgetto: codiceProgetto,
              idPeriodo: idPeriodo
            });
          }
          return result;
      },
      error => {
        console.log("POST to save scheda got an ERROR", error.response);
        throw error;
      })
  },

  completeSchedaMonitoraggio(context, {
    codiceProgetto: codiceProgetto,
    idPeriodo: idPeriodo,
    approvazione: approvazione,
    cronoprogKey: cronoprogKey,
    schemaVersioneCronoprog: schemaVersioneCronoprog,
    modificaSostanziale: modificaSostanziale
  }) {
    // get taskid e noteInBozza from scheda
    if (!tools.objectHasValue(context.state.schede[codiceProgetto])
      || !tools.objectHasValue(context.state.schede[codiceProgetto][idPeriodo])
      // || !tools.objectHasValue(state.schede[codiceProgetto][idPeriodo].taskList)
      // || !tools.objectHasValue(state.schede[codiceProgetto][idPeriodo].taskList.id)
    ){
      // TODO gestire errore gracefully
      console.log("Impossibile trovare la scheda per codiceProgetto: " + codiceProgetto
        + " e idPeriodo:" + idPeriodo)
      return null;
    }

    let scheda = context.state.schede[codiceProgetto][idPeriodo];
    console.log("complete.scheda:",scheda)
    if(!scheda.taskList) {
        console.log("task non trovato, probabilmente beneficiario operatore");
        return null;
    }

    let taskId = scheda.taskList.id
    let data = {
      "variables": {
        "result": { "value": approvazione, "type": "boolean" },
        // "note": { "value": scheda.noteInBozza, "type": "string" },
        "cronoprogKey": { "value": cronoprogKey, "type":"string"},
        "schemaVersioneCronoprog": { "value": schemaVersioneCronoprog, "type":"integer"},
        "modificaSostanziale": { "value": modificaSostanziale, "type": "boolean"}
      }
    }

    console.log("taskId: " + taskId)
    // console.log("noteInBozza: " + scheda.noteInBozza)
    return Vue.prototype.$post(endpoints.taskComplete(taskId), data)
    .then(
      result => {
        return {
          code: 'OK',
          value: result
        };
      },
      error => {
        console.log("POST to complete scheda got an ERROR", error.response);
        throw error;
      })
  },

  retrieveStats() {
    // first clear the storage
    return Vue.prototype.$get(endpoints.getStats('schedaMonitoraggio'))
    .then(
      result => {
        return result
      },
      error => {
        throw error;
      });
  },

  retrieveStatsDetails(context, {
    idCategoria: categoria
  }) {
    // first clear the storage
    return Vue.prototype.$get(endpoints.schedaMonitoraggioStatsDetails(categoria))
    .then(
      result => {
        return result
      },
      error => {
        throw error;
      });
  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
