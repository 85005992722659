<template>
  <div>
    <Collapse :icon_parere="true" :updatedScheda="updatedScheda" :checkIdsScheda="ids.generale" :name="'Generale'">
      <GenericCheckList
        :idTablePdf="collectionKeyTabs().length > 0 ? collectionKeyTabs()[0].id_table_pdf : ''"
        :tabName="tabName"
        name="Generale"
        @istruttoriaUpdated="istruttoriaUpdated"
        :rowIds="ids.generale" />
    </Collapse>
    <Collapse :icon_parere="true" :updatedScheda="updatedScheda" :checkIdsScheda="ids.acquisizione" :name="'Acquisizioni tramite Consip o Accordi quadro'">
      <GenericCheckList
        :idTablePdf="collectionKeyTabs().length > 0 ? collectionKeyTabs()[1].id_table_pdf : ''"
        :tabName="tabName"
        name="Acquisizioni tramite Consip o Accordi quadro"
        @istruttoriaUpdated="istruttoriaUpdated"
        :rowIds="ids.acquisizione" />
    </Collapse>
    <Collapse :icon_parere="true" :updatedScheda="updatedScheda" :checkIdsScheda="ids.affidamento" :name="'Affidamenti ad Enti in house'">
      <GenericCheckList
        :idTablePdf="collectionKeyTabs().length > 0 ? collectionKeyTabs()[2].id_table_pdf : ''"
        :tabName="tabName"
        name="Affidamenti ad Enti in house"
        @istruttoriaUpdated="istruttoriaUpdated"
        :rowIds="ids.affidamento" />
    </Collapse>
    <Collapse :icon_parere="true" :updatedScheda="updatedScheda" :checkIdsScheda="ids.modalitaAttuative" :name="'Altre modalità attuative'">
      <GenericCheckList
        :idTablePdf="collectionKeyTabs().length > 0 ? collectionKeyTabs()[3].id_table_pdf : ''"
        :tabName="tabName"
        name="Altre modalità attuative"
        @istruttoriaUpdated="istruttoriaUpdated"
        :rowIds="ids.modalitaAttuative" />
    </Collapse>
  </div>
</template>

<script>
import Collapse from "@/components/collapse.vue";
import GenericCheckList from '@/views/istruttorie/GenericCheckList.vue'

export default {
  name: "valutazioneIter",
  props: {
    tabName: String,
    ids: Object,
    conf_export_pdf: Object
  },
  components: {
    Collapse,
    GenericCheckList
  },
  data() {
    return {
        updatedScheda: false
    }
  },
  methods: {
    istruttoriaUpdated() {
      this.updatedScheda = !this.updatedScheda
      this.$emit('istruttoriaUpdated');
    },
    collectionKeyTabs() {
      // Questo metodo raccoglie le chiavi di configurazione che servono a renderizzare
      // i vari componenti innestati che sono contenuti nel tab di Valutazione Iter Amministrativo
      let configWrapCollapses = []
      if(this.conf_export_pdf) {
        Object.keys(this.conf_export_pdf).forEach(key => {
          if(key.includes('amministrativo')) {
            configWrapCollapses.push(this.conf_export_pdf[key])
          }
        })
      }
      return configWrapCollapses
    }
  }
}
</script>