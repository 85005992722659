<template>
    <div id="procedura2" v-show="tabName === componentConfig.name">
        <Generic2 v-if="loadComplete"
            :idTablePdf="idTablePdf"
            :idScheda="idScheda"
            :storeModule="storeModule"
            :tabelloneType="tabelloneType"
            :componentConfig="componentConfig"
            @refreshValues="refreshData()"/>
    </div>
</template>
<script>
    import Generic2 from "@/components/GenericComponent2.vue"
    // import utils from '@/helpers/utils.js'
    export default {
        name: "procedura2",
        components: {
            Generic2
        },
        props: {
            idEntity: Object,
            tabName: String,
            idTablePdf: { type: String, default: () => '' },
            dinamicIds: { type: Array, default: () => { return [] } },
            allowedActions: { type: Array, default: () => { return [] } }
        },
        computed: {},
        data() {
            return {
                loadComplete: false,
                idScheda: '',
                tabelloneType: 'cronoprog_controlli',
                storeModule: 'cronoprog',
                componentConfig: {
                    title: "Procedura",
                    name: "procedura2",
                    defaultActions: ['edit'],
                    allowedActions: [],
                    rootId: 'procedure',
                    alfaId: ''
                }
            }
        },
        mounted() {
            this.idScheda = this.idEntity.idProgetto;
            this.componentConfig.alfaId = this.idEntity.procAlfaId;
            this.componentConfig.allowedActions = this.allowedActions;
            this.componentConfig.dinamicIds = this.dinamicIds;
            this.loadComplete = true;
        },
    }
</script>