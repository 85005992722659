<template>
  <div id="scadenze" class="elencoProgetti">
    <div class="table-title">Dettaglio Scadenze </div>
    <div>
        <b-table
          :fields="tabledata.header"
          :items="tabledata.rows"
          sortable sort-by="Data" medium
          responsive hover>
        </b-table>
    </div>
  </div>
</template>


<script>

import { mapGetters} from 'vuex'
import endpoints from '@/components/endpoints.vue'
import utils from '@/helpers/utils.js'

export default {
  name: "ScadenzeComponent",
  computed: {
            ...mapGetters({
                getScadenze: 'scadenziario/getAllScadenze',
                getBeneficiari: 'announcements/getAllBeneficiariMap',
            }),
        },
  data() {
    return {
      idProgetto : null,
      tabledata: {
        header: [
          { key:"Data", sortable: true, formatter: this.dateFormatter },
          { key:"Codice Progetto", class: 'text-center' },
          { key:"Tipologia"},
          { key:"Descrizione"},
          { key:"Beneficiario"}
          ],
        rows: [
          {
            Data: "Non disponibile",
            Tipologia: "Non disponibile",
            Descrizione: "Non disponibile"          
          }
        ],
      }
    };
  },

  mounted() {
    let date = new Date();
    this.$store.dispatch('scadenziario/initScadenziario', date)
      .then(() => {
        this.extractData(this.getScadenze);
      })
  },

  methods: {
    
    extractData(inputData) {
      
      let rows = [];

      //ciclare per l'array di oggetti di tipo "SelettoreSchedaMonitoraggio.java" passato
      for(const item of inputData){
        let row = {
            Data: new Date(item.dataString),
            Tipologia: item.titolo,
            Descrizione: item.descrizione
        };

        rows.push(row);
      }

      //Scadenze Controlli
      let uri = endpoints.getScadenzeControlli
      this.$get(uri)
        .then( resp => {
          if(resp && resp.length > 0){
            const mappaBenef = this.getBeneficiari;
            resp.forEach(element => {
              let row = {
                Data: element.dataScadenza,
                Tipologia: element.tipologiaScheda,
                Descrizione: element.descrizione,
                Beneficiario: mappaBenef[element.idBeneficiario] ? mappaBenef[element.idBeneficiario].denominazione : element.idBeneficiario,
                'Codice Progetto': element.codiceProgetto
              }
              rows.push(row)
            });
          }

          this.tabledata.rows = rows;
        })
      
    },

    dateFormatter(d){
      return utils.formatDate(d)
    }
  }
};
</script>
