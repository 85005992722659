import Vue from "vue";
import endpoints from "@/components/endpoints.vue";
import tools from '@/helpers/tools.js'

const state = {
    allBandi: [],
    conf: {},
    financialTools: {},
    beneficiari: {},
    schede: {},
    callForProposalFields: [
        "['bando']['idBeneficiari']",
        "['bando']['risorse']",
        "['bando']['strumentoFinanziario']",
        "['bando']['obiettivoSpecifico']",
        "['bando']['obiettivoNazionale']",
        "['bando']['titoloBando']",
        "['bando']['descrizioneFabbisogno']",
        "['bando']['ambitiProgettuali']",
        "['bando']['ambitoTerritoriale']",
        "['bando']['budgetMassimo']",
        "['bando']['dataInizioProposte']",
        "['bando']['dataFineProposte']",
        "['bando']['oraFineProposte']",
    ],
    assegnazioneDirettaFields: [
        "['bando']['idBeneficiari']",
        "['bando']['strumentoFinanziario']",
        "['bando']['obiettivoSpecifico']",
        "['bando']['obiettivoNazionale']",
        "['bando']['titoloBando']",
        "['bando']['descrizioneFabbisogno']",
        "['bando']['ambitiProgettuali']",
        "['bando']['ambitoTerritoriale']",
        "['bando']['budgetMassimo']",
    ],
};

const getters = {
    getAssegnazioneDirettaFields(state) {
        return state.assegnazioneDirettaFields;
    },
    getCallForProposalFields(state) {
        return state.callForProposalFields;
    },
    getState(state) {
        return state;
    },
    getSchedaBando(state) {
        return (payload) => {
            if (tools.objectHasValue(payload)) {
                return state.schede[payload.idSchedaBando];
            }
            return null;
        }
    },
    getScheda(state) {
        return (payload) => {
            if (payload &&
                payload.idSchedaBando &&
                state.schede[payload.idSchedaBando]){
                    return state.schede[payload.idSchedaBando];
                }
                return null;
        }
    },
    getAllBandi(state) {
        return state.allBandi;
    },
    retrieveConfigurationSchedaBando(state) {
        return state.conf;
    },
    retrieveFinancialTools(state) {
        return state.financialTools;
    },
    //ritorna un array pronto per la visualizzazione in menu a tendina
    getAllBeneficiari(state) {
        let result = state.beneficiari;
        let allBeneficiari = [];
        for (let key in result) {
            if(!result[key].type || result[key].type === 'beneficiari') {
                let objBeneficiario = {};
                objBeneficiario.text = result[key].denominazione;
                objBeneficiario.value = key;
                allBeneficiari.push(objBeneficiario);
            }
        }
        console.info("GET ARRAY allBeneficiari=", allBeneficiari);
        return allBeneficiari;
    },
    //ritorna una mappa utile per tutti gli altri casi
    getAllBeneficiariMap(state){
        return state.beneficiari;
    },

    getDescBeneficiario(state) {
        return (idBen) => {
            let idBeneficiario = idBen;
            if(!idBeneficiario)
                idBeneficiario = Vue.prototype.getIdBeneficiario();
            const mappaBenef = state.beneficiari;
            const beneficiario = mappaBenef[idBeneficiario];
            if (beneficiario) {
                return beneficiario.denominazione; 
            } else {
                return idBeneficiario;
            }
        }
    },
};
const actions = {
    

    // Settaggio Allegati Mancanti
    setMandatoryMissed(context, payload) {
        context.commit("UPDATE_ALLEGATI_MANCANTI", payload);
    },
    setAvailableFiles(context, payload) {
        context.commit("UPDATE_ALLEGATI_DISPONIBILI", payload);
    },
    // Delete SchedaBando
    deleteSchedaBando(context, payload) {
        const url = endpoints.deleteSchedaBando(payload.idBando);
        return Vue.prototype.$delete(url).then(
            (result) => {
                return result;
            },
            (error) => {
                throw error;
            }
        )
    },
    // Listen last modify of Scheda Bando
    updateSchedaBandoField(context, payload) {
        context.commit("UPDATE_PROPERTY_SCHEDA_BANDO", payload);
    },
    updateScheda(context, payload){
        context.commit("SET_SCHEDA", payload);
    },
    // Listen last modify of Bando
    updateBandoField(context, payload) {
        context.commit("UPDATE_PROPERTY_BANDO", payload);
    },
    // Save Scheda Bando
    saveSchedaBando(context, payload) {
        // Due casistiche possibili

        // 1)   Salvataggio in Bozza (endpoint)
        let draftSchedaBando = endpoints.save_draft_schedaBando;
        // 2)   Salvataggio Completo (endpoint)
        let completeSchedaBando = endpoints.complete_schedaBando;

        const scheda = payload.scheda;
        const schedaBando = scheda.schedaBando;
        // Se salvo in Draft faccio solo la PUT
        return Vue.prototype.$put(draftSchedaBando, schedaBando).then(
            (responseDraft) => {
                console.log("Salvataggio in Draft ok");
                // Se voglio salvare completamente faccio un altra chiamata (POST)
                if (payload.salvataggio === "complete" || payload.salvataggio === "reject") {
                    // Setto l'oggetto per la post del salvataggio completo
                    const bando = scheda.schedaBando.bando;

                    let bodyForPost = {
                        idScheda: bando.idBando,
                        processId: schedaBando.processId,
                        titoloBando: bando.titoloBando,
                        esitoOk: payload.salvataggio === 'complete' ? true : false,
                    };
                    if (schedaBando.richiestoParereICT != undefined && 
                        schedaBando.richiestoParereICT != null) {
                        if (schedaBando.richiestoParereICT === "true" ||
                            schedaBando.richiestoParereICT === true) {
                            bodyForPost.richiestoParereICT = true;
                        } else {
                            bodyForPost.richiestoParereICT = false;
                        }
                    }
                    return Vue.prototype.$post(completeSchedaBando, bodyForPost).then(
                        (respComplete) => {
                            console.log("Salvataggio Completo ok", respComplete);
                            return respComplete;
                        },
                        (error) => {
                            console.log("save error Salvataggio Completo:", error.response);
                            throw error;
                        }
                    );
                }
                return responseDraft;
            },
            (error) => {
                console.log("save error Salvataggio in Draft:", error.response);
                throw error;
            }
        );
    },

    //funzione che scrive la mappa dei beneficiari cosi come è servita da Config-NT
    getBeneficiariFromConfig(context) {
        const url = endpoints.retrieveAllBeneficiariFromConfig;
        Vue.prototype.$get(url).then(
            (result) => {
                context.commit("POPULATE_BENEFICIARI", {
                    allBeneficiari: result,
                });
                return result;
            },
            (error) => {
                throw error;
            }
        );
    },
    // Retrieve Configuration for Financial Tools
    getfinancialTools(context) {
        const url = endpoints.retrieveFinancialTools;
        Vue.prototype.$get(url).then(
            (result) => {
                context.commit("POPULATE_FINANCIAL_TOOLS", {
                    financialTools: result,
                });
                return result;
            },
            (error) => {
                throw error;
            }
        );
    },
    claim_unclaim_SchedaBando(context, payload) {
        // type: claim/unclaim
        let actionUrl = endpoints.claim_un_claim_endpoint(payload.type, payload.idSchedaBando);
        return Vue.prototype.$post(actionUrl).then(
            (respComplete) => {
                context.commit("UPDATE_PROPERTY_SCHEDA_BANDO_IN_LIST_BANDI", respComplete);
                console.log("Claim/Unclaim ok", respComplete);
                return respComplete;
            },
            (error) => {
                console.log("Error Claim/Unclaim", error.response);
                throw error;
            }
        );
    },
    // Retrieve Configuration
    getConfSchedaBando(context) {
        const url = endpoints.retrieveTabellone('bando');
        Vue.prototype.$get(url).then(
            (result) => {
                context.commit("SET_CONF_SCHEDA_BANDO", {
                    conf: result,
                });
                return result;
            },
            (error) => {
                console.error("ERROR while retrieving Bando Configuration from url="+url, error.response.data);
                throw error;
            }
        );
    },
    populateBandiInStore(context, payload) {
        context.commit("PUSH_ALL_BANDI", payload);
    },
    setSchedaBando(context, payload) {
        const infoAllegati = {
            allegatiDisponibili: {
                bandi: 0
            },
            allegatiMancanti: {
                bandi: [],
            },
            /*
            bandi: [
                {type:'Decreto di approvazione', mandatory:false, recommended:false, task: 'Bando_valutazioneAR_AutoritaResponsabile-AutoritaResponsabile', group:'MINT'},
                {type:'Lettera di invito ai beneficiari', mandatory:false, recommended:false, task: 'Bando_invioBeneficiari_UfficioGestione-Approvatore'},
                {type:'Altro', mandatory:false, recommended:false},
                {type:'Appunto', mandatory:false, recommended:false, task: 'Bando_valutazioneDirettore_SegreteriaTecnicaAmministrativa-Direttore', group:'MINT'}
            ],
            */
        }
        infoAllegati.bandi = payload.tabelloneAllegati.bandi;
        payload.infoAllegati = infoAllegati;
        context.commit("SET_SCHEDA_BANDO", payload);
    },
    clearBandiState(context) {
        context.commit("RESET_LIST_BANDI");
    },
};
const mutations = {
    RESET(state) {
        state.allBandi = [];
        state.conf = {};
        //dati che non cambiano quasi mai, dannoso resettarli ad ogni login/logout
        //state.financialTools = {};
        //state.beneficiari = {};
        state.schede = {};
    },
    UPDATE_ALLEGATI_MANCANTI(state, payload) {
        if(payload.obj.idSchedaBando &&
            state.schede[payload.obj.idSchedaBando] &&
            state.schede[payload.obj.idSchedaBando].infoAllegati.allegatiMancanti[payload.obj.entity] )
                state.schede[payload.obj.idSchedaBando].infoAllegati.allegatiMancanti[payload.obj.entity] = payload.obj.files;
            else{
                console.error("UPDATE_ALLEGATI_MANCANTI Error: Missing paylod or scheda")
            }
    },
    UPDATE_ALLEGATI_DISPONIBILI(state, payload) {
        if(payload.obj.idSchedaBando &&
            state.schede[payload.obj.idSchedaBando] &&
            state.schede[payload.obj.idSchedaBando].infoAllegati.allegatiDisponibili[payload.obj.entity] !== undefined)
                state.schede[payload.obj.idSchedaBando].infoAllegati.allegatiDisponibili[payload.obj.entity] =  payload.obj.numeroAllegati;
            else{
                console.error("UPDATE_ALLEGATI_DISPONIBILI Error: Missing paylod or scheda")
            }
    },
    RESET_LIST_BANDI(state) {
        state.allBandi = [];
    },
    RESET_SCHEDE(state) {
        state.schede = {};
    },
    SET_CONF_SCHEDA_BANDO(state, payload) {
        state.conf = payload.conf;
    },
    PUSH_ALL_BANDI(state, payload) {
        state.allBandi = payload.allBandi;
    },
    SET_SCHEDA(state, payload) {
        if (payload && payload.idSchedaBando && payload.scheda) {
            state.schede[payload.idSchedaBando] = payload.scheda;
        } else
            console.error('SET_SCHEDA no key or scheda', payload);
    },
    SET_SCHEDA_BANDO(state, payload) {
        if (payload.idSchedaBando) {
            state.schede[payload.idSchedaBando] = payload.schedaBando;
            state.schede[payload.idSchedaBando].infoAllegati = payload.infoAllegati;
        }
    },
    CLEAN_SCHEDA_BANDO(state, payload) {
        if (payload.idSchedaBando) {
            delete state.schede[payload.idSchedaBando];
        } else console.error(" CLEAN_SCHEDA_BANDO no id", payload);
    },
    UPDATE_PROPERTY_SCHEDA_BANDO_IN_LIST_BANDI(state, schedaUpdated) {
        // Aggiorno la schedaBando corrente in allBandi
        const indexSchedaBando = state.allBandi.map(
            schedaBando => {
                return schedaBando.infoBando.idBando;
            }).indexOf(schedaUpdated.infoBando.idBando);
        state.allBandi.splice(indexSchedaBando, 1, schedaUpdated)
    },
    UPDATE_PROPERTY_SCHEDA_BANDO(state, payload) {
        let scheda = state.schede[payload.idSchedaBando];
        if (payload.key && scheda && scheda.schedaBando) {
            state.schede[payload.idSchedaBando].schedaBando[payload.key] = payload.value;
        } else
            console.error('no key or scheda', payload, scheda);
    },
    UPDATE_PROPERTY_BANDO(state, payload) {
        let scheda = state.schede[payload.idSchedaBando];
        if (payload.key && scheda && scheda.schedaBando) {
            const schedaBando = scheda.schedaBando;
            state.schede[payload.idSchedaBando].schedaBando.bando[payload.key] = payload.value;
            // update configuration data
            if (!schedaBando.dataEntryConfiguration) {
                state.schede[payload.idSchedaBando].schedaBando.dataEntryConfiguration = {};
            }
            state.schede[payload.idSchedaBando].schedaBando.dataEntryConfiguration[payload.id] = payload.data;
        }
    },
    POPULATE_BENEFICIARI(state, payload) {
        state.beneficiari = payload.allBeneficiari;
    },
    POPULATE_FINANCIAL_TOOLS(state, payload) {
        state.financialTools = payload.financialTools;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};