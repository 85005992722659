<template>
    <div id="procedureContratti2" v-show="tabName === componentConfig.name">
        <InfoCompiling
            :tabName="infoTabName"
            :title="'Informazioni di compilazione'"
        >
        </InfoCompiling>
        <VerticalComponent2 v-if="loadComplete"
            :idTablePdf="idTablePdf"
            :idScheda="idScheda"
            :storeModule="storeModule"
            :tabelloneType="tabelloneType"
            :componentConfig="componentConfig"
            @refreshValues="refreshData()"/>
    </div>
</template>
<script>
    import VerticalComponent2 from "@/components/verticalComponent2.vue"
    import InfoCompiling from "@/components/infoCompiling.vue"
    import utils from '@/helpers/utils.js'
    export default {
        name: "procedureContratti2",
        components: {
            VerticalComponent2,
            InfoCompiling
        },
        props: {
            idTablePdf: { type: String, default: () => '' },
            infoTabName: { type: String, default: () => '' },
            incomingData: Object,
            tabName: String,
            rootId: String,
            dinamicIds: Array,  default: function() { return [] }
        },
        data() {
            return {
                loadComplete: false,
                idScheda: '',
                tabelloneType: 'cronoprog_monitoraggio',
                storeModule: 'cronoprog',
                componentConfig: {
                    title: "Procedure e Contratti",
                    name: "procedureContratti2",
                    notifyUpdate: false,
                    defaultActions: ['view', 'edit'],
                    allowedActions: [],
                    tableIds: [
                        "['attivita'][*]['content']['idAttivita']",
                        "['attivita'][*]['content']['titoloAttivita']",
                        "['procedure'][*]['content']['idProceduraGara']",
                        "['procedure'][*]['content']['tipologiaProcedura']",
                        "['procedure'][*]['content']['tipologiaProceduraAvviata']",
                        "['procedure'][*]['content']['oggetto']",
                        "['contratti'][*]['content']['importoContrattoSuISF']",
                        "['procedure'][*]['content']['status']"
                    ],
                    relIds: {
                        "['attivita'][*]['content']['idAttivita']": {
                            type: 'attivita'
                        },
                        "['procedure'][*]['content']['idProceduraGara']": {
                            type: "procedure"
                        },
                        "['contratti'][*]['content']['riferimentoContratto']": {
                            type:'contratti'
                        }
                    }
                }
            }
        },
        mounted() {
            this.idScheda = this.incomingData.schedaMonitoraggio.progetto.idProgetto;
            let actions = utils.viewOnlyMode(this.incomingData, this.componentConfig.defaultActions);
            this.componentConfig.allowedActions = actions;
            this.componentConfig.dinamicIds = this.dinamicIds
            this.componentConfig.rootId = this.rootId
            this.loadComplete = true;
        },
    }
</script>