<template>
    <b-container>
        <b-row>
            <b-col cols="9">
                <b-form-select v-model="selected" :options="beneficiari" size="lg"
                               @input="manageBeneficiari" required />
            </b-col>
        </b-row>
        <span v-if="isProposal">
            <b-row v-for="(item, index) in getBeneficiariScelti" :key="item.value" class="list-small ">
                <b-col cols="7">
                    {{item.text}}
                </b-col>
                <b-col>
                    <b-button title="Rimuovi beneficiario" variant="outline-primary" size="sm" @click="removeBeneficiario(index)">
                        <fa-icon :icon="['far', 'trash-alt']" class="small-icons"/>
                    </b-button>
                </b-col>
            </b-row>
        </span>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name:"selezioneBeneficiari",
    data(){
        return{
            selected: null,
            beneficiari: [],
            isProposal: false
        }
    },
    props: {
        value: Array,
        scheda: Object
    },
                
    computed: {
    ...mapGetters({
            getBeneficiari: 'announcements/getAllBeneficiari'
        }),
        getBeneficiariScelti() {
            let retVal = [];
            for(const item of this.value) {
                const temp = this.beneficiari.filter( beneficiario => { return beneficiario.value === item});
                retVal.push(...temp);
            }
            return retVal;
        },

    },
    mounted() {
        this.beneficiari = this.getBeneficiari;
        if(this.scheda && this.scheda.schedaBando) {
            const bando = this.scheda.schedaBando.bando;
            if(bando && bando.modalitaDiAccesso === 'Call for proposal')
                this.isProposal = true;
        }
        if(!this.isProposal && this.value && this.value.length > 0) {
            this.selected = this.value[0];
        }
    },
    methods:{
        manageBeneficiari() {
            if(this.isProposal) {
                this.addBeneficiario();
            }
            else
                this.value.splice(0, 1, this.selected);
        },
        addBeneficiario() {
            const temp = this.value.filter(item => {return item === this.selected});
            if(temp.length === 0)
                this.value.push(this.selected);
        },

        removeBeneficiario(index) {
            this.value.splice(index, 1);
        }
    }
}
             
            
</script>