<template>
    <b-container>
        <b-overlay :show="!loadComplete" rounded="sm">
            <b-container>
                <b-row style="font-size: 14px; margin-bottom: 30px;">
                    <b-col cols="9" v-if="hasProcedureNonControllate">
                        <b-alert variant="info" show>
                            <strong>Nota:</strong> Non &egrave; possibile creare nuovi controlli per procedure gi&agrave; controllate.
                            <br>Quelle che lo sono state, verranno mostrate in elenco ma non saranno selezionabili.
                        </b-alert>
                        <b-alert variant="secondary" :show=isRimodulazione>
                            <strong>Nota:</strong> Non &egrave; possibile creare nuovi controlli per progetti in Rimodulazione.
                        </b-alert>
                    </b-col>
                    <b-col cols="9" v-if="!hasProcedureNonControllate">
                        <b-alert variant="warning" show>
                            Non &egrave; possibile avviare alcun controllo. Tutte le procedure sono gi&agrave; state controllate.
                        </b-alert>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="9">
                        <b-form-input id="titolo1A" v-model="titoloControllo" 
                                      :disabled="isWaitingForSchedaControlloPost"
                                      placeholder="Titolo del controllo da avviare (Campo Obbligatorio)"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="9">
                        <h4 class="text-center">Selezionare la Procedura da controllare</h4>
                        <b-form-select id="proc1A" size="lg" v-model="selectedProc"
                                       :disabled="isWaitingForSchedaControlloPost" :options="procOptions"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="9">
                        <h4 class="text-center">Scegliere la tipologia da avviare (Tipologia Procedura Avviata)</h4>
                        <b-form-select id="tipo1A" size="lg" v-model="selectedTipoProc" :options="tipologieProcedure"
                            :disabled="!tipologieProcedure || tipologieProcedure.length===0 || isWaitingForSchedaControlloPost"
                            @input="populateElencoChecklist()"/>
                    </b-col>
                </b-row>
                <b-row style="margin-bottom: 50px;">
                    <b-col cols="9">
                        <h4 class="text-center">Scegliere la lista di controllo</h4>
                        <b-form-select id="lista1A" size="lg" v-model="selectedChecklist" :options="elencoChecklist"
                            :disabled="!elencoChecklist || elencoChecklist.length===0 || isWaitingForSchedaControlloPost"/>
                    </b-col>
                </b-row>
            </b-container>
        </b-overlay>
        <b-container>
            <b-row>
                <b-col class="modal-footer text-right">
                    <b-button
                        class="btn-success"
                        @click="creaSchedaControllo"
                        :disabled="isCreaSchedaControlloDisabled">
                        Crea Nuova CheckList
                    </b-button>
                    <b-button variant="danger"
                        class="modal-default-button"
                        @click="closeModal"
                        :disabled="isWaitingForSchedaControlloPost">
                        Chiudi
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>

import endpoints from "@/components/endpoints.vue";
import notify from "@/helpers/notifications.js"
import feedFx from '@/helpers/feedFunctions.js'

export default {
    name: "selezioneCheckListControllo1A",
    data(){
        return {
            loadComplete: false,
            sottoTipoControllo: '1A',
            selectedTipoProc: null,
            selectedChecklist: null,
            tipologieProcedure: [{ value: null, text: 'Seleziona tipologia', disabled: true }],
            tipologieChecklist: null,
            elencoChecklistBase: [{ value: null, text: 'Seleziona checklist', disabled: true }],
            elencoChecklist: this.elencoChecklistBase,
            selectedProc: null,
            procOptions: [            //deve contenere le procedure del progetto in questione che non hanno ancora subito controllo di questo tipo
                { value: null, text: 'Seleziona procedura', disabled: true },
            ],
            controlli: [],
            processo: {},
            schedaProgetto: null, // explictly define vars
            monitoraggio: null,
            hasProcedureNonControllate: true,
            isWaitingForSchedaControlloPost: false,
            isRimodulazione: false,
            codiceProgetto: null, // il codice progetto preso dal path
            cronoprog: null, // la scheda cronoprog (erede del +fresco)
            titoloControllo: null,
        }
    },
    computed: {
        isCreaSchedaControlloDisabled: function() {
            // disabilitato nel caso l'utente non abbia selezionato
            // nulla nei dropdown oppure se e' in corso la chiamata
            // POST per la creazione della schedacontrollo
            return (
                (!this.selectedProc || !this.selectedTipoProc || !this.selectedChecklist || !this.titoloControllo
                || this.titoloControllo.toString().trim().length === 0)
                || this.isWaitingForSchedaControlloPost
                )
        },
    },
    methods: {

        creaSchedaControllo(){
            this.isWaitingForSchedaControlloPost = true;

            this.creaNuovaSchedaControllo(
                this.codiceProgetto, this.selectedChecklist, this.selectedProc)
            .then(res => {
                // passo alla pagina della scheda controllo appena creata
                this.isWaitingForSchedaControlloPost = false;
                this.$router.push({
                    name: 'schedaControlliAmministrativi',
                    params: {
                        codiceProgetto: this.codiceProgetto,
                        idControllo: res.idSchedaControllo
                    }
                })
            })
            .catch((error) => {
                console.error("Errore:", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                this.isWaitingForSchedaControlloPost = false;
            });
        },

        closeModal() {
            this.$emit('closeModalCheckList')
        },

        loadProjectByCodiceProgetto(codice) {
            // get scheda progetto by codice
            // necessario ancora farla per essere sicuri
            // che il progetto non sia in rimodulazione
            this.$get(endpoints.getSchedaProgettoWithIdMax(codice)) //
            .then((result) => {
                this.isRimodulazione = false
                this.schedaProgetto = result.content;
                this.getControlli(codice)
            })
            .catch((error) => {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },

        estraiProcedureProgetto(){
            // se il progetto e' definito e ha un cronoprogramma
            if (this.schedaProgetto && this.cronoprog){
                let procedureAggiornate = Object.values(this.cronoprog.content.procedure)
                // console.debug(">>>> procedureAggiornate", procedureAggiornate)

                //escludo le eventuali procedure inserite durante il processo di rimodulazione (sono temporanee fino al termine del processo, quindi non controllabili)
                //Commentato per nuova gestione di cronoprog branch
                // procedureAggiornate = procedureAggiornate.filter(i => !i.content.rimodulated);
                
                // se non c'e' almeno una procedura che non e' stata controllata
                // mostro warning per avvertire che non ci sono controlli avviabili
                if (!procedureAggiornate.find(i => !(i.content.controlling || i.content.controlled))){
                    //console.log('Tutte le procedure sono state controllate: nessun controllo avviabile')
                    this.hasProcedureNonControllate = false;
                }

                this.procOptions = this.procOptions.concat(procedureAggiornate.map(i => {
                    // reading idAttivita (label) from relative parent in cronoprog obj
                    // supporting only 1 attivita as parent for now
                    let internalIdAtt = this.cronoprog.content.attivita[i.rel.attivita[0]].id
                    let idAtt = this.cronoprog.content.attivita[internalIdAtt].content.idAttivita
                    return {
                        value: {
                            idAttivita: idAtt,
                            idProceduraGara: i.content.idProceduraGara,
                            id: i.id, // internal id
                            value: i,
                        },
                        text: idAtt + '.' + i.content.idProceduraGara + ' ' + i.content.oggetto,
                        disabled: i.content.controlling || i.content.controlled
                    }
                }))

            } else {
                console.log("Nessuna procedura trovata nel progetto ", this.schedaProgetto.progetto.codiceProgetto);
            }
            this.loadComplete = true;
        },

        getControlli(codiceProgetto){
            // get controlli per progetto
            let url = endpoints.getControlliAmministrativi(codiceProgetto)
            return this.$get(url)
            .then(
                result => {
                    if (result.length!==0){
                        console.log("Trovati "+result.length+" controlli per il progetto "+codiceProgetto);
                        this.controlli = result;
                    } else {
                        console.log("Nessun controllo trovato per il progetto "+codiceProgetto);
                        this.controlli = [];
                    }
                    this.estraiProcedureProgetto();
                }
            ).catch(
                error => {
                    console.error('Errore store dispatch: ', error)
                    notify.error(notify.strings.error, notify.strings.errorLoadControlli);
                }
            );
        },

        async creaNuovaSchedaControllo(codiceProgetto, selectedChecklist, selectedProc){

            try {
                const cronoSchemaId = 'cronoprog_controlli';
                let lastCrono = await this.$get(endpoints.retrieveLastVersionTabellone(cronoSchemaId));
                const cronoVersion = lastCrono.lastVersion;


                let lastChecklist = await this.$get(
                    endpoints.retrieveLastVersionTabellone(this.sottoTipoControllo + '_' + selectedChecklist)
                    )
                let lastChecklistVersion = lastChecklist.lastVersion;

                let anagrafica = {};
                if (lastChecklist){
                    anagrafica = feedFx.feedAnagrafica1A(this.schedaProgetto, selectedProc.value.content);
                }

                let infoProgetto = {};
                if (this.schedaProgetto){
                    infoProgetto = feedFx.feedInfoProgetto(this.schedaProgetto);
                }

                let url = endpoints.getControlliAmministrativi(codiceProgetto);
                let userIdGroup = this.$getUserGroupId();
                userIdGroup = userIdGroup.replace('/MINT/','').replace('/Beneficiari/','');
                const autore = this.$getUserInfo().preferred_username;
                // aggiorno i dati interni della procedura settando l'ID equivalente (vedi ENUM TipologiaProceduraAmministrativa.java)
                selectedProc.value.content.tipologiaProceduraAvviata = this.selectedTipoProc.id;
                const checklistLabel = this.tipologieChecklist[selectedChecklist].label

                const body = {
                    codiceProgetto: codiceProgetto,
                    idProgetto: this.cronoprog.idProgetto,
                    idSchemaControllo: this.sottoTipoControllo + '_' + selectedChecklist,
                    checklistLabel: checklistLabel,
                    schemaVersione: lastChecklistVersion,
                    idBeneficiario: userIdGroup,
                    tipoControllo: "Amministrativo",
                    sottoTipoControllo: this.sottoTipoControllo,
                    cronoprogItemId: selectedProc.id,
                    cronoprogRootId: "procedure", // matcha il nome della key all'interno del content di cronoprog
                    autoreUltimoAggiornamento: autore,
                    strumentoFinanziario: infoProgetto.strumentoFinanziario,
                    cronoSchemaId: cronoSchemaId,
                    cronoSchemaVersion: cronoVersion,
                    content: {
                        idAttivita: selectedProc.idAttivita, // utile ai soli fini della visualizzazione nel selettore
                        idProceduraGara: selectedProc.idProceduraGara, // utile ai soli fini della visualizzazione nel selettore
                        titoloControllo: this.titoloControllo,  // inserito dall'utente
                        //tipoProcedura: selectedChecklist,     //attributo non utilizzato
                        autoreUltimoAggiornamento: autore,
                        autocontrollo: {},
                        controllo: {},
                        anagrafica: anagrafica,
                        infoProgetto: infoProgetto
                    }
                }
                // POST di creazione della scheda controllo
                let result = await this.$post(url, body);
                let schedaControlloCreata = result;
                if (!schedaControlloCreata.idSchedaControllo ||
                    !schedaControlloCreata.codiceProgetto)
                {
                    let msg = "Scheda controllo creata non valida. Controllare la scheda creata"
                    console.error(msg, result);
                    throw new Error(msg);
                }
                console.log(
                    "schedaControllo creata",
                    "idSchedaControllo=", schedaControlloCreata.idSchedaControllo,
                    "codiceProgetto=", schedaControlloCreata.codiceProgetto
                );
                // dopo aver ricevuto la response dal BE di controlli,
                // faccio la POST di cronoprog con la procedura aggiornata
                // e l'ID della scheda controllo creata
                // N.B: Bisogna settare controlling e controlled prima, sul trunk di cronoprog
                url = endpoints.cronoprog(this.cronoprog.idProgetto)
                this.cronoprog.content.procedure[selectedProc.id].content.controlling = schedaControlloCreata.idSchedaControllo;
                // setto esplicitamente che all'avvio la chiave non sia settata
                this.cronoprog.content.procedure[selectedProc.id].content.controlled = null;
                await this.$post(url, this.cronoprog);

                // checkout del branch di cronoprog a partire dal trunk
                url = endpoints.cronoCheckout;
                const branch = 'controlli' + this.sottoTipoControllo;
                const checkoutBody = {
                    idProgetto: this.cronoprog.idProgetto,
                    version: null,
                    branch: branch,
                    idSchedaControllo: schedaControlloCreata.idSchedaControllo
                }
                let cronoResult = await this.$post(url, checkoutBody);
                // dopo aver ottenuto la scheda branch, aggiorno la procedura
                // in fase di controllo
                // cronoResult.content.procedure[selectedProc.id].content.controlling = schedaControlloCreata.idSchedaControllo;
                cronoResult.content.procedure[selectedProc.id].content.tipologiaProceduraAvviata = this.selectedTipoProc.id;
                // setto esplicitamente che all'avvio la chiave non sia settata
                // cronoResult.content.procedure[selectedProc.id].content.controlled = null;
                url = endpoints.cronoprog(this.cronoprog.idProgetto)
                const params =  {
                    branch: branch,
                    idSchedaControllo: schedaControlloCreata.idSchedaControllo
                }
                let cronoprogResult = await this.$post(url, cronoResult, params);
                console.log("cronoprogResult", cronoprogResult)

                // avvio il processo
                this.processo = await this.avviaProcessoControllo(
                    schedaControlloCreata.idSchedaControllo, schedaControlloCreata.codiceProgetto);
                console.log("Processo avviato, ID=", result);

                //effettuo il claim implicito per l'utente che ha creato il processo
                await this.autoclaim(schedaControlloCreata.idSchedaControllo);
                return schedaControlloCreata

            } catch (error) {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                throw error;
            }
        },

        avviaProcessoControllo(idSchedaControllo, codiceProgetto){
            const url = endpoints.startControllo(this.sottoTipoControllo);
            const body = {
                codiceProgetto: codiceProgetto,
                idScheda: idSchedaControllo
            }
            // POST per avvio processo controllo
            return this.$post(url, body)
        },

        // POST per autoclaim
        async autoclaim(idSchedaControllo) {
            const url = endpoints.claimControllo+idSchedaControllo;
            return this.$post(url)
        },

        retrieveTipologieProcedureChecklist(){
            this.$get(endpoints.retrieveTabellone("procedure_tipologie"))
            .then((r) => {
                //this.tipologieProcedure = r
                let arr = []
                for(let macroarea in r){
                    arr.push({
                        value: macroarea,
                        text: '-- ' + r[macroarea].label + ' --',
                        disabled: true
                    })
                    for(let k in r[macroarea].data){
                        arr.push({
                            value: {//al posto del value singolo passo un oggetto: schemaProcedure lowercase , id UPPERCASE della procedura selezionata (vedi ENUM TipologiaProceduraAmministrativa.java)
                                schemaProcedura: r[macroarea].data[k].schemaProcedura,
                                id: k
                            },
                            text: r[macroarea].data[k].label
                        })
                    }
                }
                this.tipologieProcedure = this.tipologieProcedure.concat(arr)
                return this.$get(endpoints.retrieveTabellone("procedure_checklist"))
            })
            .then((rr) => {
                this.tipologieChecklist = rr
                let url = endpoints.cronoprogProgetti(this.codiceProgetto)
                return this.$get(url)
            })
            .then((cr) => {
                this.cronoprog = cr
                // console.debug(">>> this.cronoprog", this.cronoprog)

                //API di lettura di un progetto con codice
                this.loadProjectByCodiceProgetto(this.codiceProgetto);
            })
            .catch((error) => {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },

        populateElencoChecklist(){
            if(this.tipologieChecklist &&
                this.selectedTipoProc) {
                let schemaKeys = this.tipologieChecklist[this.selectedTipoProc.schemaProcedura].schemas
                let labels = schemaKeys.map(schemaKey => {
                    return {
                        value: schemaKey,
                        text: this.tipologieChecklist[schemaKey].label
                    }
                })
                this.elencoChecklist = this.elencoChecklistBase.concat(labels)
            }
        },
    },

    mounted() {
      this.codiceProgetto = this.$route.params.codiceProgetto
      this.retrieveTipologieProcedureChecklist()
    },

    watch: {
      selectedTipoProc: function() {
        this.selectedChecklist = null
      }
    },

}
</script>
