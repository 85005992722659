import endpoints from "@/components/endpoints.vue";
import Vue from 'vue'

// async ops, call backend APIs here
const actions = {
  retrieveElencoProgetti() {
    return Vue.prototype.$get(endpoints.selettore)
    .then(
      result => {
        let projects = {};
        for(const item of result) {
          projects[item.codiceProgetto] = item
        }
        return projects;
      },
    )
  },
  retrieveArchivioByCodiceProgetto(context, codiceProgetto){
    return Vue.prototype.$get(endpoints.archivio(codiceProgetto))
    .then(
      result => {
        let monitoraggi = {};
        if(result.length === 0)
          return monitoraggi;
        for(const item of result) {
          monitoraggi[item.idPeriodo] = item;
        }
        return monitoraggi;

      },
      error => {
        console.error("retrieveArchivioByCodiceProgetto error: ", error);
        throw error;
      }
    )
  }
  
}

export default {
  namespaced: true,
  actions
}