<template>
    <div>
        <WrapCollapses
        :config="configWrapCollapses"
        @refreshOrganigramma="getFilesPerRid()"/>
    </div>
</template>
<script>
import WrapCollapses from "@/components/WrapCollapses.vue";
import { mapGetters } from "vuex";
import endpoints from '@/components/endpoints.vue'
import notify from "@/helpers/notifications.js";
import tool from '@/helpers/tools.js'
export default {
    name: "governance",
    components: {
        WrapCollapses
    },
    props: {
      name: String,
      tabName: String,
      keyEntityTab: {
        type: Array,
        required: true
      },
      //informazioni di riferimento per tutti i file caricati
      riferimento: { type: Object }

    },
    data() {
      return {
        title: '',
        scheda: {},
        entity: "organigramma",
        loadAllFiles: false,
        configWrapCollapses: [
          // {
          //   title: "Descrizione dell'organizzazione",
          //   nameComponent: 'GenericComponent',
          //   tabName: "Governance",
          //   name: "Descrizione organizzazione",
          //   dataComponent: { 
          //     dinamicIds:[
          //       "['governanceDescrizione']",
          //     ],
          //   }
          // },
          // {
          //   title: 'Principali soggetti coinvolti',
          //   nameComponent: 'VerticalComponent',
          //   name: "Soggetti coinvolti",
          //   dataComponent: { 
          //     dinamicIds:[
          //       "['soggettiCoinvolti'][*]['nome']",
          //       "['soggettiCoinvolti'][*]['qualifica']",
          //       "['soggettiCoinvolti'][*]['funzioniAssegnate']"
          //     ],
          //   }
          // }
        ],
      };
    },
    computed: {
      ...mapGetters({
        getScheda: "progetto/getScheda"
      })
    },
    watch: {
    tabName: function() {
      // console.log(this.tabName, this.name)
      if(this.tabName === this.name) {
        this.getFilesPerRid();
      }
    }
  },
    methods: {
      prepareDataForFileUpload(availableFiles) {
        this.configWrapCollapses.splice(2,1, this.keyEntityTab[2]);
        let inputs = this.configWrapCollapses[2].dataComponent.inputs
        inputs.idProgetto= this.$route.params.idProgetto
        inputs.entity= 'organigramma'
        inputs.ownerProcessStatus = this.scheda.taskInfo.taskDefinitionKey
        inputs.actionsArray = this.$projectGetActions(this.scheda);
        //inputs.availableFiles = availableFiles
        inputs.availableDocumentTypes = this.availableDocumentTypes
        inputs.availableFiles = availableFiles;
        inputs.riferimento = this.riferimento;
      },
      getFilesPerRid() {
            const rid = this.$route.params.idProgetto;
            let uri = endpoints.postFilesBasePath + rid;
            return this.$get(uri)
                .then(
                    resp =>  {
                      console.log('getFilesPerRid (Organigramma) response: ', resp);
                      const availableFiles = resp.filter( item => item.documentType === 'Organigramma del beneficiario' )
                      this.currentMandatoryFiles(availableFiles);
                      this.prepareDataForFileUpload(availableFiles)
                      //Only for Organigramma
                      if(availableFiles.length > 0){
                          //Carico l'Organigramma del beneficiario all'interno della schedaProgetto
                          const objname = availableFiles[0].objectName
                          console.log("Hanno caricato l'organigramma=", objname)
                          let copyFile = availableFiles[0];
                          copyFile.rid = this.$route.params.idProgetto;
                          this.$store.dispatch(
                            "progetto/setObjectStorageOrganigramma",
                            { 
                              file: copyFile,
                              idSchedaProgetto: rid
                            }
                          )
                      }
                    }
                ).catch(error => {
                    console.error("Error getFilesPerRid (Organigramma): ", error);
                    notify.error(
                      notify.strings.error,
                      notify.strings.getOrganigrammaPerRid
                    )
                });
      },
      currentMandatoryFiles(availableFiles) {
        const schedaCurrent = this.getScheda({idSchedaProgetto: this.$route.params.idProgetto})
        const clonedScheda = tool.genericFunctions.cloneObject(schedaCurrent);
        const allegatiMandatory = this.$calculateMandatoryFiles(this.entity, this.$getUserInfo().roles[0], clonedScheda, availableFiles);

        this.$updateMandatoryFilesInStore(allegatiMandatory, this.entity, this.$route.params.idProgetto)
        this.$updateAvailableLengthInStore(availableFiles.length, this.entity, this.$route.params.idProgetto);
        this.availableDocumentTypes = this.$availableDocumentTypes(clonedScheda.infoAllegati[this.entity], this.scheda.taskInfo.taskDefinitionKey)
      },
    },
    mounted() {
      const idProgetto = this.$route.params.idProgetto;
      this.scheda = this.getScheda({ idSchedaProgetto: idProgetto });
      this.title = this.name;
      this.configWrapCollapses = this.keyEntityTab
      this.configWrapCollapses[2].dataComponent.inputs.availableFiles = []
      this.getFilesPerRid();
    }
  }
</script>