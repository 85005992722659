<template>
    <div>
      <b-overlay :show="!loadComplete || generationPdf" rounded="sm">
      <div class="table-title">Scheda Richiesta Tranche per il Progetto: {{this.$route.params.codiceProgetto}} </div>
      <div v-if="loadComplete">
        <div class="table-title">Titolo scheda: {{schedaTranche.content.titoloControllo}}</div>

        <b-table-lite
          thead-class="head"
          :items="this.tabledata.rows"
          :fields="this.tabledata.header"
          v-if="loadComplete" />

        <b-card no-body>
            <b-tabs card nav-class="tabs" content-class="content">
                <ColorLegend/>
                <b-tab
                    v-if="loadComplete && isViewAllowed('Anagrafica')"
                    no-body
                    title="Anagrafica"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['anagrafica'].name)">
                    <GeneralViewerCard
                        :idTablePdf="conf_export_pdf['anagrafica'].id_table_pdf"
                        :name="conf_export_pdf['anagrafica'].name"
                        :tabName="tabSelection" 
                        :pathDataEntryConfiguration="'content'"
                        :store="'circuitoFinanziario'"
                        :fnGetterTab="'getTabellone'"
                        :headerTable="headerAnagrafica"
                        :rowIds="rowIds_anagrafica"
                        :fnGetter="'getScheda'"
                        :idEntity="{
                            codiceProgetto: this.$route.params.codiceProgetto,
                            id: this.$route.params.idScheda
                        }"
                        :actions="actionsMap[mappingTabs[1]]"
                        :fnSetter="'updateScheda'"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete"
                    no-body
                    title="Richiesta Tranche"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['richiesta_tranche'].name)">
                    <GeneralViewerCard
                        :idTablePdf="conf_export_pdf['richiesta_tranche'].id_table_pdf"
                        :name="conf_export_pdf['richiesta_tranche'].name"
                        :tabName="tabSelection"
                        :pathDataEntryConfiguration="'content'"
                        :store="'circuitoFinanziario'"
                        :fnGetterTab="'getTabellone'"
                        :headerTable="headerAnagrafica"
                        :rowIds="rowIds_tranche"
                        :fnGetter="'getScheda'"
                        :idEntity="{
                            codiceProgetto: this.$route.params.codiceProgetto,
                            id: this.$route.params.idScheda
                        }"
                        :fnSetter="'updateScheda'"
                        :actions="actionsMap[mappingTabs[2]]"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete"
                    no-body
                    title="Approvazione Tranche"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['approvazione_tranche'].name)">
                    <GeneralViewerCard
                        :idTablePdf="conf_export_pdf['approvazione_tranche'].id_table_pdf"
                        :name="conf_export_pdf['approvazione_tranche'].name"
                        :tabName="tabSelection"
                        :pathDataEntryConfiguration="'content'"
                        :store="'circuitoFinanziario'"
                        :fnGetterTab="'getTabellone'"
                        :headerTable="headerAnagrafica"
                        :rowIds="rowIds_ApprovazioneTranche"
                        :fnGetter="'getScheda'"
                        :idEntity="{
                            codiceProgetto: this.$route.params.codiceProgetto,
                            id: this.$route.params.idScheda
                        }"
                        :fnSetter="'updateScheda'"
                        :actions="actionsMap[mappingTabs[3]]"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete && isViewAllowed('Documentazione')"
                    no-body
                    title="Documentazione"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['documentazione'].name)">
                    <Documentazione
                        :ownerProcessStatus= this.retrieveTaskDefinitionKey
                        :actions="actionsMap[mappingTabs[4]]"
                        :entity = this.store
                        :getters = this.getters
                        :setters = this.setters
                        :payload = this.payload
                        :riferimento = this.riferimento
                        tabName="Documentazione"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete && isViewAllowed('Controllo')"
                    no-body
                    title="Checklist"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['checklist'].name)">
                    <Controllo
                        :idTablePdf="conf_export_pdf['checklist'].id_table_pdf"
                        :title="conf_export_pdf['checklist'].nameCollapse"
                        :tabName="tabSelection.toLowerCase()"
                        :config="getTabellone"
                        :dataObject="'controllo'"
                        :store="'circuitoFinanziario'"
                        :fnGetter="'getScheda'"
                        :fnSetter="'updateScheda'"
                        :idEntity="{
                            codiceProgetto: this.$route.params.codiceProgetto,
                            id: this.$route.params.idScheda
                        }"
                        name="Check List Controllo"
                        :headerTable="headerTableCheckList"
                        :actions="actionsMap[mappingTabs[5]]"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete && isViewAllowed('Invio')"
                    no-body
                    title="Invio"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['Invio_mandatory'].name)">
                    <Invio
                     :tabName="tabSelection"
                     :idsCustomEdit="ids_custom_edit"
                     :config="getTabellone"
                     :idsToCheck="idsToCheck"
                     :actions="actionsMap[mappingTabs[6]]"
                     :datiSpesa="datiSpesa"
                     @buildPdf="generateReport"
                    />
                </b-tab>
            </b-tabs>
        </b-card>
      </div>
      </b-overlay>
    </div>
</template>

<script>
import Invio from "../tranche/invio.vue"
import Documentazione from "@/components/Documentazione.vue"
import Controllo from '@/views/controlli/CheckListSingleControl'
import GeneralViewerCard from '@/components/GeneralViewerCard.vue'
import notify from "@/helpers/notifications.js"
import { mapGetters } from "vuex"
import utils from "@/helpers/utils.js"
import endpoints from "@/components/endpoints.vue"
import tools from '@/helpers/tools.js'
import helper_pdf from "@/helpers/exportPdf.js"
import ColorLegend from "@/components/legend.vue"

export default {
  name: "schedaTranche",
  components: {
    GeneralViewerCard,
    Controllo,
    Invio,
    Documentazione,
    ColorLegend
  },
  data() {
    return {
        //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
        riferimento : {
            idOggettoRif: "",
            tipoOggettoRif: "Scheda Richiesta Tranche", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
            idBando: "",
            tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
            idProgetto: "",
            codiceProgetto: ""
        },
        mappingTabs: {
            1: 'Anagrafica',
            2: 'RichiestaTranche',
            3: 'ApprovazioneTranche',
            4: 'Documentazione',
            5: 'Controllo',
            6: 'Invio'
        },
        conf_export_pdf: {
            "anagrafica": {
                id_table_pdf: 'anagrafica',
                name: "Anagrafica",
                title_page_pdf: 'Anagrafica',
                nameCollapse: '',
            },
            "richiesta_tranche": {
                id_table_pdf: 'richiesta_tranche',
                name: "Richiesta Tranche",
                title_page_pdf: 'Richiesta Tranche',
                nameCollapse: ''
            },
            "approvazione_tranche": {
                id_table_pdf: 'approvazione_tranche',
                name: "Approvazione Tranche",
                title_page_pdf: 'Approvazione Tranche',
                nameCollapse: ''
            },
            "documentazione": {
                id_table_pdf: 'fileTable',
                name: "Documentazione",
                title_page_pdf: 'Documentazione',
                nameCollapse: '',
            },
            "checklist": {
                id_table_pdf: 'checklist_controllo',
                name: "Controllo",
                title_page_pdf: 'CheckList',
                nameCollapse: 'CheckList Controllo',
            },
            "Invio_force_richiesta": {
                id_field: "['content']['deroga']",
                name: "Invio",
                title_page_pdf: 'Dettagli Invio',
                conf_field: { addPage: true, mapping: 'SiNo' },
                descRadio: 'Richiedere comunque la tranche di finanziamento? '
            },
            "Invio_mandatory": {
                id_table_pdf: 'mandatory_tranche',
                name: "Invio",
                title_page_pdf: 'Invio',
                nameCollapse: 'Campi obbligatori non valorizzati',
            },
            "Invio_note": {
                id_table_pdf: 'note_tranche',
                name: "",
                title_page_pdf: 'Invio',
                nameCollapse: 'Elenco Note',
            },
            "Invio_note_riservate": {
                id_table_pdf: 'note_riservate_tranche',
                name: "",
                title_page_pdf: 'Invio',
                nameCollapse: 'Elenco Note Interne'
            }
        },
        headerTableCheckList: [
            { key: 'descrizione' },
            { key: 'esito', label: 'Esito' },
            { key: 'docsRiferimento', label: 'Documentazione di riferimento' },
            { key: 'filesRiferimento', label: 'Documenti di riferimento' },
            { key: 'azione' }
        ],
        headerAnagrafica: [
            { key: "campo" },
            {
                key: "descrizione",
                tdAttr: this.tooltip,
                formatter: ""
            },
            { key: "azione" }
        ],
        rowIds_anagrafica: [
          "['anagrafica']['autore']",
          "['anagrafica']['dataInserimento']",
          "['anagrafica']['verificatore']",
          "['anagrafica']['dataVerifica']",
          "['anagrafica']['idBeneficiario']",
          "['anagrafica']['codiceProgetto']",
          "['anagrafica']['titoloProgetto']",
          "['anagrafica']['strumentoFinanziario']",
          "['anagrafica']['costoProgetto']",
          "['anagrafica']['dataFirmaDecreto']",
          "['anagrafica']['dataFirmaConvenzione']",
        ],
        rowIds_tranche: [
          "['tranche']['importo']",
          "['tranche']['percentualeTrasferimento']",
          "['tranche']['coordinateAccredito']",
        ],
        ids_custom_edit: [
            // IDS soggetti a edit Custom
            // considerare nel computo degli obbligatori
            "['anagrafica']['autore']",           // edit beneficiarioOper
            "['anagrafica']['dataInserimento']",  // edit beneficiarioOper
            "['anagrafica']['verificatore']",     // edit ufficioEconomOper
            "['anagrafica']['dataVerifica']",     // edit ufficioEconomOper
        ],
        rowIds_ApprovazioneTranche: [
          "['tranche']['dataDichiarazioneSpesa']",
          "['tranche']['protocolloDichiarazioneSpesa']",
        ],
        idsToCheck: [],
        schedaTranche: {},
        tabSelection: "Anagrafica",
        store: "circuitoFinanziario",
        actionsMap: {},
        loadComplete: false,
        generationPdf: false,
        fakeStatus: "Tranche_tranche-concluso_Nessuno-Nessuno" ,
        getters: {
            scheda: "getScheda",
            tabellone: "getTabellone"
        },
        setters: {
            scheda: "updateScheda",
            allegatoDaCancellare: "setAllegatoDaCancellare",
            clearAllegatiDaCancellare: "clearAllegatiDaCancellare"
        },
        payload: {
            scheda: {          
                codiceProgetto: this.$route.params.codiceProgetto, 
                id: this.$route.params.idScheda
            },
        },
        tabledata: {
            header: [
                {
                    key: "codiceProgetto"
                },
                {
                    key: "titolo"
                },
                {
                    key: "stato"
                },
                {
                    key: "dataConclusione",
                    formatter: this.dateFormatter
                }
            
            ],
            rows: [
                {
                    "codiceProgetto": "Non disponibile",
                    "titolo": "Non disponibile",
                    "stato": "Non disponibile",
                    "dataConclusione": null
                }
            ],
        },
        soglieTranche: [],
        datiSpesa: {
            tranche: 0,
            soglia: 0,
            spesa: 0
        }
    }
  },
  mounted() {
    this.loadSchedaAndConfigs();
  },
  computed: {
    ...mapGetters({
        getScheda: "circuitoFinanziario/getScheda",
        getTabellone: "circuitoFinanziario/getTabellone"
    }),
    retrieveTaskDefinitionKey() {
      if (this.schedaTranche 
            && this.schedaTranche.content 
            && this.schedaTranche.content.taskInfo 
            && this.schedaTranche.content.taskInfo.taskDefinitionKey)
        return this.schedaTranche.content.taskInfo.taskDefinitionKey
      else 
        return this.fakeStatus
    }
  },
  methods: {
    generateReport(optPdf) {
        this.generationPdf = true;
        if(this.datiSpesa.spesa < this.datiSpesa.soglia) {
            let source = {
                scheda: this.getCurrentScheda()
            }
            this.conf_export_pdf['Invio_force_richiesta'].source = source
        } else delete this.conf_export_pdf['Invio_force_richiesta']
        helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
        this.generationPdf = false;
    },
    getCurrentScheda() {
      let scheda = this.getScheda({ codiceProgetto: this.$route.params.codiceProgetto, id: this.$route.params.idScheda })
      let clonedScheda = tools.genericFunctions.cloneObject(scheda)
      return clonedScheda
    },
    dateFormatter(value) {
      if (!value) 
        return "Non disponibile";
      return utils.formatDate(value);
    },
    populateTabledata(){
      //console.log(" schedaTranche = ", this.schedaTranche);
      //agisco direttamente sulla prima riga inizializzata con "Non disponibile"
      this.tabledata.rows[0].codiceProgetto = this.schedaTranche.codiceProgetto;
      if (this.schedaTranche.content.infoProgetto){
        
        if (this.schedaTranche.content.infoProgetto.titolo)
          this.tabledata.rows[0].titolo = this.schedaTranche.content.infoProgetto.titolo;
        
        if(this.schedaTranche.content.infoProgetto.stato)  
          this.tabledata.rows[0].stato = this.schedaTranche.content.infoProgetto.stato;

        if(this.schedaTranche.content.infoProgetto.dataConclusione)  
          this.tabledata.rows[0].dataConclusione = this.schedaTranche.content.infoProgetto.dataConclusione;
      
      }
    },
    //Campi obbligatori per il tab invio, diversi in base al ruolo
    populateIdsToCheck(){
        if(this.$getUserRole().includes('UfficioEconomicoFinanziario'))
            this.idsToCheck = this.rowIds_ApprovazioneTranche
        else
            this.idsToCheck = this.rowIds_tranche
    },
    setTabSelection(name) {
      this.tabSelection = name
    },
    loadActionMap(){
      let scheda = this.schedaTranche;
      
      // Prendo la lista degli IDs dei tabs previsti per questa scheda dal mappingTabs
      let allIndices = Object.keys(this.mappingTabs)
        for (const index of allIndices) {
            let tabName = this.mappingTabs[index]
            this.actionsMap[tabName] = this.$getActionsTranche(scheda, tabName)
        }
    },
    isViewAllowed(tabName) { //se ho diritto ad almeno un'azione su questo TAB devo vederlo
      return this.actionsMap[tabName].length > 0
    },
    loadTabellone(){
        this.$store.dispatch("circuitoFinanziario/setTabellone", {
            scheda: this.schedaTranche
        }).then(() => {
            this.populateTabledata();
            this.populateIdsToCheck();
            //carico tutte le azioni concesse per i vari TAB
            this.loadActionMap();
            this.loadComplete = true;
        }).catch(error => {
            console.error('Errore store dispatch tabellone: ', error.message)
            notify.error(notify.strings.error, notify.strings.errorGetPrefinanziamento)
        })
    },
    loadSchedaAndConfigs(){
        const codice = this.$route.params.codiceProgetto;
        const id = this.$route.params.idScheda;
        this.riferimento.idOggettoRif = this.$route.params.idScheda;
        this.riferimento.codiceProgetto = this.$route.params.codiceProgetto;
  
        this.$store.dispatch("circuitoFinanziario/setSchedaTranche",
        {
            codiceProgetto: codice,
            idScheda: id
        }).then((scheda)=> {
            this.schedaTranche = scheda;
            this.loadTabellone();
            this.loadSchedaProgetto(codice);
        }).catch(error => {
            console.error('Errore store dispatch: ', error.message)
            notify.error(notify.strings.error, notify.strings.serverError)
        })
    },
    loadSchedaProgetto(){
        const codice = this.$route.params.codiceProgetto;
        //carico i dati del progetto per vedere prefinanziamento e tranche definite
        this.$get(endpoints.getSchedaProgettoWithIdMax(codice))
        .then((result) => {
            if (result.content.progetto.idProgetto){    //idProgetto deve sempre esistere
                this.riferimento.idProgetto = result.content.progetto.idProgetto;
                this.riferimento.tipoBando = result.content.progetto.modalitaDiAccesso;
                this.riferimento.idBando = result.content.idBando;
            }

            if (result.content.erogazione){    
                let percentPref = 0;
                let costoPref = 0;
                let sogliaPref = 0;
                let costoProj = 0;
                if (result.content.erogazione.percentualePrefinanziamento){
                    percentPref = result.content.erogazione.percentualePrefinanziamento;
                    costoProj = result.content.progetto.costoProgetto;
                    console.info("Costo del progetto: ", costoProj)
                    costoPref = costoProj*(percentPref/100);
                    sogliaPref = costoPref*75/100;
                    console.info("Progetto prefinanziato con percentuale="+percentPref+ 
                        "%, importo prefinanziato="+this.costoPref+" Euro, 75% dell'importo prefinanziato="+ sogliaPref+ " Euro");
                    this.isPrefinanziato = true;
                    
                } else {
                    console.log("Per questo progetto non è stato richiesto prefinanziamento");
                    this.isPrefinanziato = false;
                }

                if (result.content.erogazione.tranche){
                    console.log("Tranche di progetto=", result.content.erogazione.tranche);
                    this.trancheOptions = result.content.erogazione.tranche;
                    let importiTranche = [costoPref];

                    if (costoProj){
                        //per ogni tranche calcolo l'importo definito nel progetto partendo dalla percentuale
                        for (let i=0; i < this.trancheOptions.length; i++){
                            this.trancheOptions[i].disabled = true; //disabilito tutte le tranche (solo visualizzazione)
                            const percTranche = this.trancheOptions[i].percent;
                            const importoTranche = (percTranche/100) * costoProj;
                            //console.log("percentuale Tranche "+(i+1)+" = "+percTranche+ " %");
                            //console.log("importo Tranche "+(i+1)+" = "+importoTranche+ " Euro");
                            importiTranche.push(importoTranche);
                        }

                        //per ogni tranche calcolo l'importo soglia per richiedere la successiva
                        let totale = costoPref;
                        this.soglieTranche[0] = costoPref * 75 / 100;
                        
                        for (let j=1; j < importiTranche.length - 1; j++){
                            const importo = parseFloat(importiTranche[j]);
                            if(!isNaN(importo)) {
                                const trancheCorrente = importo * 75/100;
                                this.soglieTranche[j] = totale + trancheCorrente;
                                totale += importo;
                            } else {
                                console.error("loadSchedaProgetto importo errato", importiTranche[j]);
                            }
                        }
                        console.log("calcolate soglieTranche=", this.soglieTranche);
                    } else {
                        console.error("Non è stato definito il costoProgetto per "+codice+"!? Impossibile calcolare gli importi delle tranche");
                    }
                }

            } else {
                console.error("Nel progetto non sono definite le modalità di erogazione!");
                this.isTranche = false;
            }

            this.loadDichiarazioniSpesaByCodiceProgetto();

        })
        .catch((error) => {
            console.error("ERRORE: ", error.message);
            notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
        });
    },
    async loadDichiarazioniSpesaByCodiceProgetto(){
        const codiceProgetto = this.$route.params.codiceProgetto;
        console.log("Carico le dichiarazioni di spesa per il progetto:", codiceProgetto);
        
        const url = endpoints.getAllControlli2;
        const params = {
            codiceProgetto: codiceProgetto
        }
        let dichSpesa = await this.$get(url, params);

        let totaleSpesaRendicontata = 0;
            
        if (dichSpesa && Object.keys(dichSpesa).length > 0){

            this.dichiarazioniSpesa = dichSpesa.filter( scheda => { return scheda.stato.includes("Esitato")});
            console.log("Dichiarazioni di spesa esitate: ", this.dichiarazioniSpesa);
            
            for (let item of this.dichiarazioniSpesa){
                if (item.content.dichiarazione 
                    && item.content.dichiarazione.importoTotaleISF ) {
                        const importoTotale = parseFloat(item.content.dichiarazione.importoTotaleISF);
                       if(!isNaN(importoTotale)) {
                            console.log("Trovata dichiarazione di spesa "+item.idSchedaControllo+" con importo = "+item.content.dichiarazione.importoTotaleISF+" Euro");
                            totaleSpesaRendicontata += parseFloat(item.content.dichiarazione.importoTotaleISF);
                        } else {
                            console.error("loadDichiarazioniSpesaByCodiceProgetto importo errato", item.content.dichiarazione.importoTotaleISF);
                        }
                    }
            } 

        }

        console.log("totaleSpesaRendicontata="+totaleSpesaRendicontata+ " Euro (somma di tutte le dichiarazioni di spesa esitate)");

        if (totaleSpesaRendicontata > 0) { //se è stato rendicontato qualcosa, calcolo in quale tranche ci troviamo
            
            for (let i=0; i <this.soglieTranche.length; i++ ){

                if (totaleSpesaRendicontata >= this.soglieTranche[i]){
                    if (i === 0){
                        console.log("La spesa rendicontata supera il 75% del prefinanziamento richiesto ("
                                     +this.soglieTranche[i]+" Euro), si può richiedere la tranche 1");
                    } else {
                        console.log("La spesa rendicontata supera la relativa soglia ("+this.datiSpesa.soglia+" Euro), si può richiedere la tranche "+(i+1));
                    }

                    this.trancheOptions[i].disabled = false;    //abilito solo la tranche i-esima
                } else {
                    //compare il warning per l'utente visibile sul TAB invio
                    console.log("Non si può ancora richiedere la tranche "+(i+1));
                }
            
            }
   
        } 
        
        //passo al TAB invio i dati della tranche che sto visualizzando
        this.datiSpesa.spesa = totaleSpesaRendicontata;
        const index = this.schedaTranche.content.tranche.index;
        this.datiSpesa.tranche = index+1;
        this.datiSpesa.soglia = this.soglieTranche[index];
                        
    }


  }
}
</script>