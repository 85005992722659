<template>
    <div>
        <div class="table-title" v-if="getInfoScheda.length > 0">Valutazione Scheda Progetto - Istruttoria
            <br>
            {{getTitle}}
        </div>
        <b-table striped hover :items="getInfoScheda" :fields="fields"></b-table>
        <b-card no-body v-if="this.loadComplete">
            <b-tabs card nav-class="tabs" content-class="content">
                <ColorLegend/>
                <b-tab
                    v-if="loadComplete"
                    no-body
                    title="Generale"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['generale'].name)">
                    <Generale
                        :idTablePdf="conf_export_pdf['generale'].id_table_pdf"
                        :name="conf_export_pdf['generale'].name"
                        :infoScheda="getInfoScheda"
                        :fieldToShowInTable="fields"
                        :tabName="tabSelection"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete"
                    no-body
                    title="Valutazione di Ammissibilità"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['ammissibilita'].name)">
                    <GenericCheckList
                        :idTablePdf="conf_export_pdf['ammissibilita'].id_table_pdf"
                        :name="conf_export_pdf['ammissibilita'].name"
                        :tabName="tabSelection"
                        @istruttoriaUpdated="istruttoriaUpdated"
                        :rowIds="ammissibilitaIds"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete"
                    no-body
                    title="Valutazione di Merito"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['merito'].name)">
                    <GenericCheckList
                        :idTablePdf="conf_export_pdf['merito'].id_table_pdf"
                        :name="conf_export_pdf['merito'].name"
                        :tabName="tabSelection"
                        @istruttoriaUpdated="istruttoriaUpdated"
                        :rowIds="meritoIds"
                    />
                </b-tab>
                <b-tab
                    v-if="loadComplete"
                    no-body
                    title="Valutazione Iter Amministrativo"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['amministrativo_generale'].name)">
                    <ValutazioneIter
                        :conf_export_pdf="conf_export_pdf"
                        :idTablePdf="conf_export_pdf['amministrativo_generale'].id_table_pdf"
                        :name="conf_export_pdf['amministrativo_generale'].name"
                        :ids="iterAmministrativoIds"
                        @istruttoriaUpdated="istruttoriaUpdated"
                        :tabName="tabSelection"
                    />
                </b-tab>
                <b-tab
                    v-if="schedaIstruttoria.procedureDerogatorie === true && loadComplete"
                    no-body
                    title="Valutazione Procedure Derogatorie"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection(conf_export_pdf['derogatorie'].name)">
                    <GenericCheckList
                        :idTablePdf="conf_export_pdf['derogatorie'].id_table_pdf"
                        :name="conf_export_pdf['derogatorie'].name"
                        :tabName="tabSelection"
                        @istruttoriaUpdated="istruttoriaUpdated"
                        :rowIds="derogatorieIds"
                    />
                </b-tab>
                <b-tab
                    no-body
                    title="Invio"
                    title-item-class="tab1"
                    title-link-class="titleLink"
                    v-on:click="setTabSelection('invio')">
                    <Invio v-if="this.loadComplete"
                        :tabellone="tabelloneIstruttoria"
                        :ids="idsCheckComplessivo"
                        :updatedScheda="updatedScheda"
                        :tabName="tabSelection"
                        :riferimento = this.riferimento
                        @buildPdf="generateReport"/>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import notify from "@/helpers/notifications.js"
import Invio from './invio.vue'
import ValutazioneIter from './valutazioneIter.vue'
import GenericCheckList from '@/views/istruttorie/GenericCheckList.vue'
import Generale from "./generale.vue";
import tool from '@/helpers/tools.js'
import helper_pdf from "@/helpers/exportPdf.js"
import ColorLegend from "@/components/legend.vue"


export default {
    name:"schedaIstruttoria",
    components:{
        Invio,
        ValutazioneIter,
        GenericCheckList,
        Generale,
        ColorLegend
    },
    data() {
        return {
            tabSelection: "Generale",
            //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
            riferimento : {
                idOggettoRif: "",
                tipoOggettoRif: "Scheda Istruttoria Progetto", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
                idBando: "",
                tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
                idProgetto: "",
                codiceProgetto: ""
            },
            updatedScheda: false,
            schedaIstruttoria: {},
            loadComplete: false,
            tabelloneIstruttoria: {},
            conf_export_pdf: {
                "generale": {
                    id_table_pdf: 'generale_istruttoria',
                    name: "Generale",
                    title_page_pdf: 'Generale',
                    nameCollapse: '',
                },
                "ammissibilita": {
                    id_table_pdf: 'ammissibilita_istruttoria',
                    name: "Valutazione di Ammissibilità",
                    title_page_pdf: 'Valutazione di Ammissibilità',
                    nameCollapse: '',
                },
                "merito": {
                    id_table_pdf: 'merito_istruttoria',
                    name: "Valutazione di Merito",
                    title_page_pdf: 'Valutazione di Merito',
                    nameCollapse: '',
                },
                // Il tab 'Valutazione Iter Amministrativo' ha 4 Collapse:
                // Collapse Generale
                "amministrativo_generale": {
                    id_table_pdf: 'amministrativo_generale_istruttoria',
                    name: "Valutazione Iter Amministrativo",
                    title_page_pdf: 'Valutazione Iter Amministrativo',
                    nameCollapse: 'Generale'
                },
                // Collapse Acquisizioni tramite Consip o Accordi quadro
                "amministrativo_acquisizione": {
                    id_table_pdf: 'amministrativo_acquisizione_istruttoria',
                    name: "Valutazione Iter Amministrativo",
                    title_page_pdf: 'Valutazione Iter Amministrativo',
                    nameCollapse: 'Acquisizioni tramite Consip o Accordi quadro',
                },
                // Collapse Affidamenti ad Enti in house
                "amministrativo_affidamento": {
                    id_table_pdf: 'amministrativo_affidamento_istruttoria',
                    name: "Valutazione Iter Amministrativo",
                    title_page_pdf: 'Valutazione Iter Amministrativo',
                    nameCollapse: 'Affidamenti ad Enti in house',
                },
                // Collapse Altre modalità attuative
                "amministrativo_modalitaAttuative": {
                    id_table_pdf: 'amministrativo_modalitaAttuative_istruttoria',
                    name: "Valutazione Iter Amministrativo",
                    title_page_pdf: 'Valutazione Iter Amministrativo',
                    nameCollapse: 'Altre modalità attuative',
                },
                "derogatorie": {
                    id_table_pdf: 'derogatorie_istruttoria',
                    name: "Valutazione Procedure Derogatorie",
                    title_page_pdf: 'Valutazione Procedure Derogatorie',
                    nameCollapse: '',
                },
                "invio_mandatory": {
                    id_table_pdf: 'istruttoria_mandatory',
                    name: "",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Campi obbligatori non valorizzati',
                },
                "invio_esito": {
                    id_table_pdf: 'istruttoria_esito',
                    name: "",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Esito Valutazione',
                },
                "invio_fileUpload": {
                    id_table_pdf: 'invio_fileUpload',
                    name: "",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Allegati',
                },
                "invio_note": {
                    id_table_pdf: 'note_istruttoria',
                    name: "",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Elenco Note',
                },
                "invio_note_riservate": {
                    id_table_pdf: 'note_riservate_istruttoria',
                    name: "",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Elenco Note Interne',
                },
                "invio_richiesta_parere_ICT": {
                    id_field: "['content']['richiestoParereICT']",
                    name: "Invio",
                    title_page_pdf: 'Dettagli Invio',
                    conf_field: { addPage: true, mapping: 'SiNo' },
                    descRadio: 'Si richiede il parere della Task Force ICT: '
                },
                "invio_parere_ict": {
                    id_field: "['content']['parereICT']",
                    name: "Invio",
                    title_page_pdf: 'Dettagli Invio',
                    conf_field: { addPage: false },
                    descRadio: 'Parere ICT: '
                },
                "invio_richiesta_parere_LIE": {
                    id_field: "['content']['richiestoParereLIE']",
                    name: "Invio",
                    title_page_pdf: 'Dettagli Invio',
                    conf_field: { addPage: true, mapping: 'SiNo' },
                    descRadio: 'Si richiede il parere della Task force logistica e infrastrutture edili: '
                },
                "invio_parere_LIE": {
                    id_field: "['content']['parereLIE']",
                    name: "Invio",
                    title_page_pdf: 'Dettagli Invio',
                    conf_field: { addPage: false },
                    descRadio: 'Parere Task force logistica e infrastrutture edili: '
                },
                "invio_parere_UFF": {
                    id_field: "['content']['parereUFF']",
                    name: "Invio",
                    title_page_pdf: 'Dettagli Invio',
                    conf_field: { addPage: true },
                    descRadio: 'Parere UFFICIO CONTROLLI: '
                }
            },
            fields: [
                {   key: "modalitaDiAccesso", label: "Tipologia" },
                {   key: "titoloProgetto",label: "Titolo Progetto", showGeneral: true},
                {   key: "beneficiario", label: "Beneficiario", showGeneral: true },
                {   key: "strumentoFinanziario", label: "Strumento Finanziario",thClass: 'd-none', tdClass: 'd-none', showGeneral: true },
                {   key: "dataCompilazione", label: "Data Compilazione", thClass: 'd-none', tdClass: 'd-none', showGeneral: true, formatter: 'dateFormatter' }
            ],
            generaleIds: [],
            ammissibilitaIds: [
                "['istruttoria']['ammissibilita']['termini']",
                "['istruttoria']['ammissibilita']['budget']",
                "['istruttoria']['ammissibilita']['limitiTemporali']",
                "['istruttoria']['ammissibilita']['territorialita']",
                "['istruttoria']['ammissibilita']['correttezzaFormale']",
                "['istruttoria']['ammissibilita']['modalitaTrasmissione']",
                "['istruttoria']['ammissibilita']['soggettoProponente']",
                "['istruttoria']['ammissibilita']['completezzaFormale']",
                "['istruttoria']['ammissibilita']['costi']",
                "['istruttoria']['ammissibilita']['attivita']",

                // "['istruttoria']['merito']['complementarietaCondivisa']",
                // "['istruttoria']['merito']['complementarietaDiretta']",
                // "['istruttoria']['ammissibilita']['costi']",
                // "['istruttoria']['ammissibilita']['attivita']",
                // "['istruttoria']['iterAmministrativo']['procedure']"
            ],
            meritoIds: [
                "['istruttoria']['merito']['coerenzaStrategicaOS']",
                "['istruttoria']['merito']['coerenzaStrategicaON']",
                "['istruttoria']['merito']['coerenzaBaseline']",
                "['istruttoria']['merito']['complementarietaCondivisa']",
                "['istruttoria']['merito']['complementarietaDiretta']",
                "['istruttoria']['merito']['indicatoriSicurezza']",
                "['istruttoria']['merito']['indicatoriObiettivi']",
                "['istruttoria']['merito']['indicatoriCalcolo']",
                "['istruttoria']['merito']['qualitaProgettualeFondo']",
                "['istruttoria']['merito']['qualitaProgettualeFasi']",
                "['istruttoria']['merito']['qualitaProgettualeObiettivi']",
                "['istruttoria']['merito']['fattibilitaInChiarezza']",
                "['istruttoria']['merito']['fattibilitaInCoerenza']",
                "['istruttoria']['merito']['fattibilitaInBudget']",
                "['istruttoria']['merito']['capacitaBeneficiarioAdeguatezza']",
                "['istruttoria']['merito']['capacitaBeneficiarioSostenibilita']",
                "['istruttoria']['merito']['capacitaBeneficiarioEsperienzePregresse']",
                "['istruttoria']['merito']['valutazionePunteggio']"
            ],
            iterAmministrativoIds: {
                generale: [
                    "['istruttoria']['iterAmministrativo']['procedure']",
                    "['istruttoria']['iterAmministrativo']['numeroAttivitaGenerale']",
                    "['istruttoria']['iterAmministrativo']['compilazioneGenerale']",
                    "['istruttoria']['iterAmministrativo']['compilazioneDerogatorie']"
                ],
                acquisizione: [
                    "['istruttoria']['iterAmministrativo']['numeroAttivitaAcquisizione']",
                    "['istruttoria']['iterAmministrativo']['compilazioneAcquisizione']"
                ],
                affidamento: [
                    "['istruttoria']['iterAmministrativo']['numeroAttivitaAffidamento']",
                    "['istruttoria']['iterAmministrativo']['compilazioneAffidamento']",
                    "['istruttoria']['iterAmministrativo']['compilazioneCongruita']"
                ],
                modalitaAttuative: [
                    "['istruttoria']['iterAmministrativo']['numeroAttivitaModAttuative']",
                    "['istruttoria']['iterAmministrativo']['compilazioneModAttuative']"
                ]
            },
            derogatorieIds: [
                "['istruttoria']['derogatorie']['condizioni']"
            ]
        }
    },
    computed: {
        ...mapGetters({
            getSchedaIstruttoria: "istruttoria/getSchedaIstruttoria",
            getBeneficiari: 'announcements/getAllBeneficiari'
        }),
        getTitle() {
            if(this.schedaIstruttoria.titoloProgetto)
                return this.schedaIstruttoria.titoloProgetto;
            else
                return this.schedaIstruttoria.idSchedaIstruttoria;
        },
        idsCheckComplessivo() {
            // Il check complessivo valuta tutti i valori in scheda
            return {
                ...(true) && {ammissibilita: this.ammissibilitaIds},
                ...(true) && {merito: this.meritoIds},
                ...(true) && {iterAmministrativo: this.getAllIdsIterAmministrativo },
                ...(this.schedaIstruttoria.procedureDerogatorie) && {derogatorie: this.derogatorieIds}
            }
        },
        getAllIdsIterAmministrativo() {
            let allIds = []
            Object.keys(this.iterAmministrativoIds).forEach(key => {
                allIds = allIds.concat(this.iterAmministrativoIds[key])
            })
            return allIds
        },
        getInfoScheda() {
            let infoScheda = []
            let clonedScheda = tool.genericFunctions.cloneObject(this.schedaIstruttoria);
            
            const beneficiario = this.getBeneficiari.filter( beneficiario1 => { return beneficiario1.value === clonedScheda.idBeneficiario});
            
            if(beneficiario && beneficiario.length === 1) {

                clonedScheda.beneficiario = beneficiario[0].text
                infoScheda.push(clonedScheda)
                return infoScheda
            }
            return []
        }
    },
    mounted() {
        const idIstruttoria = this.$route.params.idIstruttoria;
        // Operazioni in STORE
        // Caricamento configurazione per schedaIstruttoria
        Promise.all([
            this.$store.dispatch("configuration/setTabelloneVersioned", {type: 'allegati'}),
            this.$store.dispatch("istruttoria/setTabellone")
        ])
        .then(
            tabelloni => {
                const tabIstruttoria = tabelloni[1];
                // Caricamento schedaIstruttoria
                this.$store.dispatch("istruttoria/setSchedaIstruttoria", 
                    { idSchedaIstruttoria: idIstruttoria, tabelloneAllegati: tabelloni[0] })
                .then(
                    () => {
                        this.schedaIstruttoria = this.getSchedaIstruttoria({ idSchedaIstruttoria: idIstruttoria });

                        if (this.schedaIstruttoria.idSchedaIstruttoria){
                            this.riferimento.idProgetto = this.schedaIstruttoria.idSchedaProgetto;
                            this.riferimento.idOggettoRif = this.schedaIstruttoria.idSchedaIstruttoria;
                            this.riferimento.idBando = this.schedaIstruttoria.idBando;
                            this.riferimento.tipoBando = this.schedaIstruttoria.modalitaDiAccesso;
                            this.riferimento.codiceProgetto = this.schedaIstruttoria.codiceProgetto;
                        }
                        
                        this.tabelloneIstruttoria = tabIstruttoria ? tabIstruttoria : {}
                        this.loadComplete = true;
                    }).catch(error => {
                        console.error('error schedaIstruttoria', error.response);
                        notify.error(notify.strings.error, notify.strings.errorLoadIstruttoria);
                    }
                );
            }).catch(error => {
                console.error('error tabellone', error.response);
                notify.error(notify.strings.error, notify.strings.internalConfigurationError);
            }
        );
    },
    methods: {
        generateReport(optPdf) {
            
            // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)
            let source = {
                scheda: this.getSchedaIstruttoria({ idSchedaIstruttoria: this.$route.params.idIstruttoria })
            }
            this.conf_export_pdf['invio_richiesta_parere_ICT'].source = source
            this.conf_export_pdf['invio_parere_ict'].source = source

            this.conf_export_pdf['invio_richiesta_parere_LIE'].source = source
            this.conf_export_pdf['invio_parere_LIE'].source = source

            this.conf_export_pdf['invio_parere_UFF'].source = source

            // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)

            helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
        },
        setTabSelection(name){
            this.tabSelection = name
        },
        istruttoriaUpdated() {
            this.updatedScheda = !this.updatedScheda
        }
    }
}
</script>