<template>
  <div>
    <div class="table-title">Finanziamento e Convenzione del progetto: {{ this.$route.params.idProgetto}}</div>
    <b-table-lite
      thead-class="head"
      :items="fillData.rows"
      :fields="fillData.header"
      v-if="setVisibilityTable">
    </b-table-lite>
    <div>
      <b-card no-body>
        <b-tabs card nav-class="tabs" content-class="content">
          <ColorLegend/>
          <b-tab
            v-if="loadComplete"
            no-body
            title="Generale"
            title-item-class="tab1"
            title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['generale'].name)">
             <GenericComponent
              :idTablePdf="conf_export_pdf['generale'].id_table_pdf"
              :name="conf_export_pdf['generale'].name"
              :tabName="tabSelection"
              :rowIds="generaleIds"
              :store="'convenzione'"/>
          </b-tab>
          <b-tab
            v-if="loadComplete"
            no-body
            title="Erogazione"
            title-item-class="tab1"
            title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['erogazione'].name)">
            <GenericComponent
              :idTablePdf="conf_export_pdf['erogazione'].id_table_pdf"
              :name="conf_export_pdf['erogazione'].name"
              :tabName="tabSelection"
              @convenzioneUpdate="convenzioneUpdate"
              :rowIds="erogazioneIds"
              :store="'convenzione'"/>
          </b-tab>
          <b-tab
            v-if="loadComplete"
            no-body
            title="Invio"
            title-item-class="tab1"
            title-link-class="titleLink"
            v-on:click="setTabSelection('invio')">
            <Invio
              :updatedScheda="updatedScheda"
              :tabName="tabSelection"
              :riferimento = this.riferimento
              @buildPdf="generateReport"/>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import GenericComponent from "./GenericComponent.vue"
import Invio from "./invioConvenzione.vue";
import { mapGetters } from "vuex";
import notify from "@/helpers/notifications.js";
import endpoints from "@/components/endpoints.vue";
import helper_pdf from "@/helpers/exportPdf.js"
import ColorLegend from "@/components/legend.vue"


export default {
    name:"schedaConvenzione",
    components:{
        GenericComponent,
        Invio,
        ColorLegend
    },
    data() {
      return {
        //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
        riferimento : {
            idOggettoRif: "",
            tipoOggettoRif: "Scheda Convenzione Progetto", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
            idBando: "",
            tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
            idProgetto: "",
            codiceProgetto: ""
        },
        updatedScheda: false,
        conf_export_pdf: {
          generale: {
            id_table_pdf: 'generale_convenzione',
            name: "Generale",
            title_page_pdf: 'Generale',
            nameCollapse: '',
          },
          erogazione: {
            id_table_pdf: 'erogazione_convenzione',
            name: "Erogazione",
            title_page_pdf: 'Erogazione',
            nameCollapse: '',
          },
          convenzione_mandatory: {
            id_table_pdf: 'convenzione_mandatory',
            name: "",
            title_page_pdf: 'Invio',
            nameCollapse: 'Campi obbligatori non valorizzati',
          },
          invio_note: {
            id_table_pdf: 'note_convenzione',
            name: "",
            title_page_pdf: 'Invio',
            nameCollapse: 'Note',
          },
          "invio_note_riservate": {
              id_table_pdf: 'invio_note_riservate',
              name: '',
              title_page_pdf: 'Invio',
              nameCollapse: 'Elenco Note Interne',
          },
          invio_fileUpload: {
              id_table_pdf: 'invio_fileUpload',
              name: "",
              title_page_pdf: 'Invio',
              nameCollapse: 'Allegati',
          },
        },
        generaleIds:[
          "['progetto']['beneficiario']['beneficiario']['denominazione']",
          "['progetto']['titoloProgetto']",
          "['progetto']['strumentoFinanziario']",
          "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['obiettivoSpecifico']['codice']",
          "['progetto']['obiettivoProgetto']['obiettivoNazionale'][*]['codiceComposito']",
          "['progetto']['modalitaDiAccesso']",
          "['progetto']['costoProgetto']",
          "['progetto']['costoProgettoIVA']",
          "['progetto']['durataProgettoMesi']",
          "['progetto']['ulterioriFontiFinanziamento']",
          "['dataFirmaConvenzione']" //Fix EV5 26/06/2023
        ],
        graduatoriaIds:[
          "['progetto']['titoloProgetto']"
          //TODO: aggiungere campi del bando relativo
        ],
        erogazioneIds:[
          "['erogazione']['percentualeComunitaria']",
          "['erogazione']['circuitoFinanziario']",
          "['erogazione']['quotaComunitaria']",
          "['erogazione']['percentualePrefinanziamento']",
          "['erogazione']['coordinateAccredito']",
          "['erogazione']['quotaNazionale']"
        ],
        schedaProgetto:{},
        tabledata: {
          header: [
            { key: "Tipologia" },
            { key: "TitoloProgetto", label: "Titolo Progetto" },
            { key: "StrumentoFinanziario", label: "Strumento Finanziario" },
            { key: "ObiettivoNazionale", label: "Obiettivo Nazionale" },
            { key: "ObiettivoSpecifico", label: "Obiettivo Specifico" },
          ],
          rows: [],
        },
        loadComplete: false,
        tabSelection: "Generale"
      }
    },
    methods: {
      generateReport(optPdf) {
        this.generationPdf = true
        helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
        this.generationPdf = false
      },
      setTabellaBando() {
        let bando;
        if(this.schedaProgetto) {
          const idBando = this.schedaProgetto.idBando
          if(idBando) {
              let url = endpoints.retrieveBando+idBando
                  this.$get(url).then(
                    (result) => {
                      bando = result.schedaBando.bando
                      if(bando != null) {
                          const rowHeader = {
                              Tipologia: bando.modalitaDiAccesso,
                              TitoloProgetto: bando.titoloBando,
                              StrumentoFinanziario: bando.strumentoFinanziario,
                              ObiettivoNazionale: bando.obiettivoNazionale,
                              ObiettivoSpecifico: bando.obiettivoSpecifico,
                          };
                          this.tabledata.rows.push(rowHeader);
                      }
                    },
                    (error) => {
                      console.log("errore",error);
                    }
                  )
          }
        }
      },
      convenzioneUpdate() {
        this.updatedScheda = !this.updatedScheda
      },
      setTabSelection(name){
        console.log("Click sul tab =", name);
        this.tabSelection = name
      }
    },
    mounted() {
        const idProgetto = this.$route.params.idProgetto;
        this.$store.dispatch("configuration/setTabelloneVersioned", {type: 'allegati'}).then(result => {
          // carica la scheda progetto nello store
          this.$store.dispatch("progetto/setSchedaProgetto",
          { idSchedaProgetto: idProgetto, tabelloneAllegati: result }).then(
            () => {
              this.schedaProgetto = this.getSchedaProgetto({ idSchedaProgetto: idProgetto });

              if (this.schedaProgetto.progetto.idProgetto){
                this.riferimento.idProgetto = this.schedaProgetto.progetto.idProgetto;
                this.riferimento.idOggettoRif = this.schedaProgetto.progetto.idProgetto;
                this.riferimento.idBando = this.schedaProgetto.idBando;
                this.riferimento.tipoBando = this.schedaProgetto.progetto.modalitaDiAccesso;    
              }
              this.setTabellaBando();

              const promiseArray = [
                this.$store.dispatch("configuration/setTabellone", 'convenzione')
              ];
              // Gestione scheda NoteRiservate
              const userInfo = this.$getUserInfo();
              const group = userInfo.groups[0];
              if( group.includes("MINT")) {
                promiseArray.push(this.$store.dispatch("progetto/retrieveSchedaNoteRiservate", this.schedaProgetto));
              }
              Promise.all(promiseArray).then(() => {
                this.loadComplete = true;
              }).catch(error => {
                console.error('error tabellone/noteRiservate', error.response);
                notify.error(notify.strings.error, notify.strings.errorLoadProgetto);
              });

          }).catch(error => {
              console.error('error progetto', error.response);
              notify.error(notify.strings.error, notify.strings.errorLoadProgetto);
          });
        }
      ).catch(error => {
          console.error('error allegati', error.response);
          notify.error(notify.strings.error, notify.strings.internalConfigurationError);
      });
    },
    computed: {
      ...mapGetters({
        getSchedaProgetto: "progetto/getSchedaProgetto"
      }),
      setVisibilityTable(){
        return this.schedaProgetto && this.schedaProgetto.idBando;
      },
      fillData: function() {
        return this.tabledata;
      },
    }
};
</script>
