<script>

export default {

  name: "endpoints",

  // all backend API paths should be defined here.
  // per-env variables are defined in .env.* files
  root: process.env.VUE_APP_ROOT_API,
  tasksForMe: process.env.VUE_APP_ROOT_API + '/tasks/user/taskByGroup',
  // statistica: process.env.VUE_APP_ROOT_API + '/schedaProgetto/statistica/progetti',

  //*********************************** CONFIG ****************************************
  retrieveTabellone: function(type) {
    return process.env.VUE_APP_ROOT_API + "/configuration/" + type;
  },
  retrieveLastVersionTabellone: function(type) {
    return process.env.VUE_APP_ROOT_API + "/configuration/" + type + '/lastVersion'
  },
  retrieveVersionedTabellone: function(payload) {
    return process.env.VUE_APP_ROOT_API + "/configuration/"
            + payload.type + '/' + payload.version;
  },

  //*********************************** CRONOPROG ****************************************
  cronoprog: function(idProgetto, cronoprogKey) {
    let retVal = process.env.VUE_APP_ROOT_API.concat('/cronoprog/');
    if(cronoprogKey) {
      retVal = retVal.concat('key/').concat(cronoprogKey);
    } else {
      retVal = retVal.concat(idProgetto);
    }
    return retVal;
  },

  //crea uno scheletro vuoto di schedaCronoprog per un progetto creato dall'applicativo (senza ancora codiceProgetto)
  newCronoprog: function(idProgetto) {
    let retVal = process.env.VUE_APP_ROOT_API.concat('/cronoprog/new/'+idProgetto);
    return retVal;
  },

  cronoprogProgetti: function(key) {
    if(key) {
      return process.env.VUE_APP_ROOT_API + "/cronoprog/progetti/" + key
    } else {
      return process.env.VUE_APP_ROOT_API + "/cronoprog/progetti"
    }
  },

  cronoCheckout: process.env.VUE_APP_ROOT_API + "/cronoprog/checkout",



  //*********************************** API BANDO ****************************************

    listaBandi: function(type) {
      if (type)
        return process.env.VUE_APP_ROOT_API + '/schedaBando/list/' + type;
      else 
        return process.env.VUE_APP_ROOT_API + '/schedaBando/list/ALL';
    },

    // Per creare un bando occorre avere un idScheda (che viene restituita da questa get)
    newBando: function(type) {
      return process.env.VUE_APP_ROOT_API + '/schedaBando/new/' + type;
    },

    // Con l'idScheda creare un processo associato al bando
    startBandoProcess: function(processDefinition) {
        return process.env.VUE_APP_ROOT_API + '/bpm/process/start/' + processDefinition
    },

    // Get and Configuration
    retrieveBando: process.env.VUE_APP_ROOT_API + '/schedaBando/',
    //retrieveAllBeneficiari: process.env.VUE_APP_ROOT_API + '/info/beneficiari',
    retrieveFinancialTools: process.env.VUE_APP_ROOT_API + '/info/obiettivi',
    // retrieveConfBando: process.env.VUE_APP_ROOT_API + '/configuration/bandi',
    retrieveMappaIndicatori: process.env.VUE_APP_ROOT_API + '/info/indicatori',
    retrieveAllBeneficiariFromConfig: process.env.VUE_APP_ROOT_API + '/configuration/beneficiari',
    
    // Post 
    save_draft_schedaBando: process.env.VUE_APP_ROOT_API + '/schedaBando/',
    complete_schedaBando: process.env.VUE_APP_ROOT_API + '/bpm/task/complete/',

    // Delete
    deleteSchedaBando: function(idSchedaBando) {
      return process.env.VUE_APP_ROOT_API + '/schedaBando/' + idSchedaBando
    },

    // CLAIM/UNCLAIM
    claim_un_claim_endpoint: function(type, idSchedaBando) {
      return process.env.VUE_APP_ROOT_API + '/bpm/'+ type +'/' + idSchedaBando
    },
  //*********************************** API CONTROLLI *************************************

    getControlliAmministrativi: function(codiceProgetto) {
      return process.env.VUE_APP_ROOT_API + '/controlli/amministrativo/progetto/' + codiceProgetto
    },
    
    dichiarazioneSaldoProgetto: function(codiceProgetto) {
      return process.env.VUE_APP_ROOT_API + '/controlli/amministrativo/progetto/' + codiceProgetto+ '?dichiarazionesaldo=true'
    },

    getControlloAmministrativo: function(codiceProgetto, idControllo) {
      return process.env.VUE_APP_ROOT_API + '/controlli/amministrativo/progetto/' + codiceProgetto + '/' + idControllo
    },

    getControlliLoco: function(codiceProgetto){
      return process.env.VUE_APP_ROOT_API + '/controlli/inloco/progetto/' + codiceProgetto
    },

    getControlloLoco: function(codiceProgetto, idControllo){
      return process.env.VUE_APP_ROOT_API + '/controlli/inloco/progetto/' + codiceProgetto + '/' + idControllo
    },

    getControlloById: function(tipologia, codiceProgetto, idControllo){
      return process.env.VUE_APP_ROOT_API + '/controlli/' + tipologia.replace(" ","").toLowerCase() + '/progetto/' + codiceProgetto + '/' + idControllo
    },

    getTabelloneControllo: function(idSchemaKey) {
      return process.env.VUE_APP_ROOT_API + '/configuration/' + idSchemaKey
    },

    deleteControllo: function(idControllo) {
      return process.env.VUE_APP_ROOT_API + '/controlli/' + idControllo
    },

    getProceduraControllo: process.env.VUE_APP_ROOT_API + '/configuration/procedura',

    startControlloAmministrativo1BProcess: process.env.VUE_APP_ROOT_API + '/controlli/process/start/1B', 
    startControlloAmministrativo2Process: process.env.VUE_APP_ROOT_API + '/controlli/process/start/2', 


    startControllo(type) {
      return process.env.VUE_APP_ROOT_API + '/controlli/process/start/' + type;
    },

    startControlloInLocoFinanziario: process.env.VUE_APP_ROOT_API + '/controlli/process/start/finanziario',
    startControlloInLocoOperativo: process.env.VUE_APP_ROOT_API + '/controlli/process/start/operativo',

    getAllControlli: process.env.VUE_APP_ROOT_API + '/controlli/amministrativo/all',
    getAllControlli1A: process.env.VUE_APP_ROOT_API + '/controlli/amministrativo/1A',
    getAllControlli1B: process.env.VUE_APP_ROOT_API + '/controlli/amministrativo/1B',
    getAllControlli2: process.env.VUE_APP_ROOT_API + '/controlli/amministrativo/2',
    getAllControlliInlocoOperativi: process.env.VUE_APP_ROOT_API + '/controlli/inloco/operativo',
    getAllControlliInlocoFinanziari: process.env.VUE_APP_ROOT_API + '/controlli/inloco/finanziario',
    controlliAmmFase2: process.env.VUE_APP_ROOT_API + '/controlli/controlliAmmFase2',

    getStatsControlli: process.env.VUE_APP_ROOT_API + '/controlli/stats',
    getStatsControlliByType: function(type) {
      return process.env.VUE_APP_ROOT_API + '/controlli/stats/' + type
    },
    getStatsTotalePagamenti: process.env.VUE_APP_ROOT_API + '/cronoprog/stats/totalePagamenti',
    pagamentiSchedaCronoProg: process.env.VUE_APP_ROOT_API + '/cronoprog/pagamentiCronoProg',

    irregolarita: process.env.VUE_APP_ROOT_API + '/irregolarita/',
    getAllIrregolarita: process.env.VUE_APP_ROOT_API + '/irregolarita/all',
    getIrregolaritaByCodice: function(codiceProgetto){
      return process.env.VUE_APP_ROOT_API + '/irregolarita/progetto/' + codiceProgetto
    },
    getIrregolaritaByID: function(codiceProgetto, id){
      return process.env.VUE_APP_ROOT_API + '/irregolarita/progetto/' + codiceProgetto + "/" + id
    },
    deleteIrregolaritaByID: function(codiceProgetto, id){
      return process.env.VUE_APP_ROOT_API + '/irregolarita/progetto/' + codiceProgetto + "/" + id
    },
    startIrregolaritaProcess: function(defKey){
      return process.env.VUE_APP_ROOT_API + '/irregolarita/process/start/' + defKey
    },
    completeIrregolarita: process.env.VUE_APP_ROOT_API + '/irregolarita/task/complete',

    claimControllo: process.env.VUE_APP_ROOT_API + '/bpm/controllo/claim/',
    unclaimControllo: process.env.VUE_APP_ROOT_API + '/bpm/controllo/unclaim/',
    completeControllo: process.env.VUE_APP_ROOT_API + '/bpm/controllo/task/complete',
    getScadenzeControlli: process.env.VUE_APP_ROOT_API + '/controlli/scadenze',
    
  
   //*********************************** API CIRCUITO FINANZIARIO *************************************

    newPrefinanziamento: function(codiceProgetto) {
      return process.env.VUE_APP_ROOT_API + '/circuitofinanziario/prefinanziamento/progetto/' + codiceProgetto
    },
    prefinanziamentoByCodiceProgetto: function(codiceProgetto) {
      return process.env.VUE_APP_ROOT_API + '/circuitofinanziario/prefinanziamento/progetto/' + codiceProgetto
    },
    prefinanziamentoById: function(codiceProgetto, id) {
      return process.env.VUE_APP_ROOT_API + '/circuitofinanziario/prefinanziamento/progetto/' + codiceProgetto + '/' + id
    },
    getAllPrefinanziamenti: process.env.VUE_APP_ROOT_API + '/circuitofinanziario/prefinanziamento/all',
    startPrefinanziamentoProcess: process.env.VUE_APP_ROOT_API + '/circuitofinanziario/process/start/prefinanziamento', 
    claimPrefinanziamento: process.env.VUE_APP_ROOT_API + '/bpm/circuitofinanziario/claim/',
    unclaimPrefinanziamento: process.env.VUE_APP_ROOT_API + '/bpm/circuitofinanziario/unclaim/',
    completePrefinanziamento: process.env.VUE_APP_ROOT_API + '/bpm/circuitofinanziario/complete',
    getStatsPagamenti: process.env.VUE_APP_ROOT_API + '/circuitofinanziario/stats',
    
    trancheStats: process.env.VUE_APP_ROOT_API + '/circuitofinanziario/tranche/stats',

    trancheProgetto: function(codiceProgetto) {
      return process.env.VUE_APP_ROOT_API + '/circuitofinanziario/tranche/progetto/' + codiceProgetto
    },
    schedaTrancheById: function(codiceProgetto, id) {
      return process.env.VUE_APP_ROOT_API + '/circuitofinanziario/tranche/progetto/' + codiceProgetto + '/' + id
    },
    startTrancheProcess: process.env.VUE_APP_ROOT_API + '/circuitofinanziario/process/start/tranche', 
    
    getProgettiDisposizione: process.env.VUE_APP_ROOT_API + '/circuitofinanziario/disposizioni/progetti',
    disposizioniProgetto: function(codiceProgetto) {
      return process.env.VUE_APP_ROOT_API + '/circuitofinanziario/disposizioni/progetto/' + codiceProgetto
    },
    progettiByAnno: function(anno) {
      return process.env.VUE_APP_ROOT_API + '/circuitofinanziario/disposizioni/progetti/' + anno
    },
    disposizioneByID: function(codiceProgetto, id){
      return process.env.VUE_APP_ROOT_API + '/circuitofinanziario/disposizioni/progetto/' + codiceProgetto +"/"+id
    },

    getContiAnnuali: process.env.VUE_APP_ROOT_API + '/configuration/listaContiAnnuali',
  //*********************************** API CONVENZIONE *************************************

    getGraduatoria: function(idBando) {
      return process.env.VUE_APP_ROOT_API + '/progetti/convenzione/graduatoria/' + idBando
    },
    save_draft_schedaConvenzione: function(idBando) {
      return process.env.VUE_APP_ROOT_API + '/progetti/convenzione/' + idBando
    },
    getBandiConvenzione: process.env.VUE_APP_ROOT_API + '/progetti/convenzione/bandi',

  //*********************************** API PROGETTO ****************************************

  //*** API PROGETTO
    // retrieveTabelloneProgetto: process.env.VUE_APP_ROOT_API + "/configuration/progetti",
    retrieveSchedaProgetto: function(idProgetto) {
      return process.env.VUE_APP_ROOT_API + "/schedaProgetto/" + idProgetto;
    },
    retrieveSchedaProgettoModifica: function(idProgetto) {
      return process.env.VUE_APP_ROOT_API + "/schedaProgetto/modifica/" + idProgetto;
    },
    retrieveSchedaProgettoAttuazione: function(keyProgetto) {
      return process.env.VUE_APP_ROOT_API + "/schedaProgetto/attuazione/" + keyProgetto;
    },
    retrieveListaProgetti: function(idList) {
      return process.env.VUE_APP_ROOT_API + "/schedaProgetto/progetti?list=" + idList;
    },
    getAllProjects_in_Attuazione: function(idProgetto) {
      return process.env.VUE_APP_ROOT_API + "/schedaProgetto/attuazione?idProgetto=" + idProgetto
    },

    getAttuazioneByTrimestre: function(idProgetto, idPeriodo) {
      return process.env.VUE_APP_ROOT_API + "/schedaProgetto/" + idProgetto + "/byTrimestre/" + idPeriodo
    },

    retrieveAllSchedeProgetto: process.env.VUE_APP_ROOT_API + "/schedaProgetto/list",
    listSchedeProgettoForModifica: process.env.VUE_APP_ROOT_API + "/schedaProgetto/listModifica",
    listSchedeProgettoInAttuazione: process.env.VUE_APP_ROOT_API + "/schedaProgetto/listAttuazione",
    listSchedeProgettoInAttuazioneOrConcluse: process.env.VUE_APP_ROOT_API + "/schedaProgetto/listAttuazioneOrConclusi" ,
    listExtraction: process.env.VUE_APP_ROOT_API + "/schedaProgetto/listExtraction" ,
    listActiveProjects: process.env.VUE_APP_ROOT_API + "/schedaProgetto/listActive" ,
    listSchedeProgettoConcluse: process.env.VUE_APP_ROOT_API + "/schedaProgetto/listConclusi",
    save_draft_schedaProgetto: process.env.VUE_APP_ROOT_API + '/schedaProgetto/',
    complete_schedaProgetto: process.env.VUE_APP_ROOT_API + '/bpm/progetto/task/complete',
    newProgettoByListBandi: process.env.VUE_APP_ROOT_API + '/schedaProgetto/new',
    startProgettoProcess: function(procDefId) {

      let processName = "ProgettoValutazione";
      if(procDefId)
        processName = procDefId;
      return process.env.VUE_APP_ROOT_API + '/bpm/progetto/start/' + processName;
    },
    startProgettoConvenzione: process.env.VUE_APP_ROOT_API + '/bpm/progetto/start/ProgettoConvenzione',
    startProgettoGraduatoria: process.env.VUE_APP_ROOT_API + '/bpm/progetto/start/ProgettoGraduatoria',
    startProgettoSovvenzione: process.env.VUE_APP_ROOT_API + '/bpm/progetto/start/ProgettoSovvenzione',
    startModificaProgettoProcess: function(procDefId) {
      let processName = "ProgettoModifica";
      if(procDefId)
        processName = procDefId;
      return process.env.VUE_APP_ROOT_API + '/bpm/progetto/start/' + processName;
    },
    claimProgetto: process.env.VUE_APP_ROOT_API + '/bpm/progetto/claim/',
    unclaimProgetto: process.env.VUE_APP_ROOT_API + '/bpm/progetto/unclaim/',
    deleteProgetti:  process.env.VUE_APP_ROOT_API + '/schedaProgetto/',
    cancelEditProgetti: function(id) {
      return process.env.VUE_APP_ROOT_API + '/schedaProgetto/cancelEdit/' + id
    },
    getProgettoByCodice: function(codiceProgetto) {
      return process.env.VUE_APP_ROOT_API + '/schedaProgetto/' + codiceProgetto +'/attuazione/'
    },
    getMiscConfig: process.env.VUE_APP_ROOT_API + "/configuration/misc/miscellaneous",
    getSchedaProgettoWithIdMax: function (codice){
      return process.env.VUE_APP_ROOT_API + '/schedaProgetto/maxVersione/' + codice
    },
    listSchedeProgettoAnticipazione: process.env.VUE_APP_ROOT_API + "/schedaProgetto/circuitoFinanziario/Anticipazione",
    //API disponibili nel BE ma al momento non usate nel FE
    //listSchedeProgettoDelegazione: process.env.VUE_APP_ROOT_API + "/schedaProgetto/circuitoFinanziario/Delegazione",
    //listSchedeProgettoRimborso: process.env.VUE_APP_ROOT_API + "/schedaProgetto/circuitoFinanziario/Rimborso",
    
  //*********************************** API ALLEGATI ****************************************

    presignedPutUri:
      process.env.VUE_APP_ROOT_API + "/files/presignedUri/upload/",
    presignedGetUri:
      process.env.VUE_APP_ROOT_API + "/files/presignedUri/download/",
    postFilesBasePath: 
      process.env.VUE_APP_ROOT_API + "/files/",

  //API Allegati v2
    filesBasePathV2: 
      process.env.VUE_APP_ROOT_API + "/files/v2/",
    presignedPutUriV2:
      process.env.VUE_APP_ROOT_API + "/files/v2/presignedUri/upload/",
    presignedGetUriV2:
      process.env.VUE_APP_ROOT_API + "/files/v2/presignedUri/download/",

    filesListV2: 
      process.env.VUE_APP_ROOT_API + "/files/v2/list/files",

  //*********************************** API EXECUTING BODY ****************************************
   
   retrieveSchedaExecuting: function(idExecuting) {
      return process.env.VUE_APP_ROOT_API + "/schedaProgetto/" + idExecuting;
    },
  //  retrieveTabelloneExecuting: process.env.VUE_APP_ROOT_API + "/configuration/executingBody",
   listSchedeExecutingBody: process.env.VUE_APP_ROOT_API + "/schedaProgetto/list?executingBody=true",
   newExecutingByList: process.env.VUE_APP_ROOT_API + '/schedaProgetto/new?executingBody=true',
   startExecutingProcess: process.env.VUE_APP_ROOT_API + '/bpm/progetto/start/ProgettoExecutingBody',
  //  get_max_dotaz_complessiva: process.env.VUE_APP_NGINX_FE_CONFIG + '/executing_body/dotazione_finanziaria.json',
   get_importo_impegnato: function(financialTool) {
      return process.env.VUE_APP_ROOT_API + "/schedaProgetto/executingBody/importoImpegnato/" + financialTool;
    },
   //get_importo_impegnato: process.env.VUE_APP_ROOT_API + '/schedaProgetto/executingBody/importoImpegnato/{{strumentoFinanziario}}',

  //*********************************** API ISTRUTTORIE **************************************
  newSchedaIstruttoria: process.env.VUE_APP_ROOT_API + "/istruttorie",
  retrieveSchedaIstruttoria: function(idIstruttoria) {
    return process.env.VUE_APP_ROOT_API + "/istruttorie/schede/" + idIstruttoria
  },
  populateTabIstruttoria: process.env.VUE_APP_ROOT_API + "/istruttorie/schema",
  retrieveTabelloneIstruttoria: function(idSchemaIstruttoria) {
    return process.env.VUE_APP_ROOT_API + "/istruttorie/schema/types/" + idSchemaIstruttoria
  },
  saveSchedaIstruttoria: function(idIstruttoria) {
    return process.env.VUE_APP_ROOT_API + "/istruttorie/schede/" + idIstruttoria
  },
  
  //*********************************** API SCHEDA NOTE RISERVATE ****************************************

  noteRiservate: function(keyGroupNoteRiservate) {
    if (keyGroupNoteRiservate){
      return process.env.VUE_APP_ROOT_API + '/noteRiservate/' + keyGroupNoteRiservate
    }
  },
  //*********************************** API SCHEDA NOTE RISERVATE ******************************

  //*********************************** API NOTIFICHE ****************************************

   notification: process.env.VUE_APP_ROOT_API + '/notification/',

  //*********************************** API SCHEDA MONITORAGGIO ******************************


  startProcess: function(processDefinition) {
      return process.env.VUE_APP_ROOT_API + '/tasks/process/start/' + processDefinition
  },

  saveFirst: function(type, codiceProgetto, idPeriodo) {
    return process.env.VUE_APP_ROOT_API.concat('/', type, '/saveFirst/', codiceProgetto, '/', idPeriodo);
  },

  schedaMonitoraggio: function(id) {
    if (!id){
      return process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/'
    }
    return process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/' + id
  },
  lastSchedaMonitoraggio: function(id) {
    return process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/last/' + id
  },
  getStats: function(schedaType, id){  // id should be idPeriodo
    const baseUrl = process.env.VUE_APP_ROOT_API.concat('/').concat(schedaType).concat('/stats')
    if (!id){
      return baseUrl;
    }
    return baseUrl.concat('/').concat(id);
  },
  schedaMonitoraggioStatsDetails: function(idCategoria, idPeriodo){
    if (!idCategoria){
      return "" // se non viene passato il primo parametro non e' possibile chiamare l'API
    }
    // se non viene passato idPeriodo ma idCategoria chiamo solo per idCategoria...
    if (!idPeriodo){
      return process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/statsDetails/' + idCategoria
    }
    // ...altrimenti chiamo per entrambi i parametri
    return process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/' + idCategoria + '/' + idPeriodo
  },
  schedaAssessment: function(key, isFinal) {
    let path = process.env.VUE_APP_ROOT_API + '/schedaAssessment/';
    if (key){
      path = path.concat(key);
    }
    if(isFinal != undefined) {
      path = path.concat('?isFinal=' + isFinal);
    }

    return path;
  },
  schedaMonitoraggioPost: process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/',
  schedaAssessmentPost: process.env.VUE_APP_ROOT_API + '/schedaAssessment/',
  schedaProgetto: process.env.VUE_APP_ROOT_API + '/schedaProgetto',
  selettore: process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/selettore',
  selettoreAss: function(isFinal) {
    let  path = process.env.VUE_APP_ROOT_API + '/schedaAssessment/selettore';
    if(isFinal != undefined)
      path = path.concat('?isFinal=' + isFinal);
    
    return path;
  },
  claim: function(id) {
    if (!id){
      return process.env.VUE_APP_ROOT_API + '/tasks/user/claim/'
    }
    return process.env.VUE_APP_ROOT_API + '/tasks/user/claim/' + id
  },
  unclaim: function(id) {
    if (!id){
      return process.env.VUE_APP_ROOT_API + '/tasks/user/unclaim/'
    }
    return process.env.VUE_APP_ROOT_API + '/tasks/user/unclaim/' + id
  },
  task: function(id) {
    if (!id){
      return process.env.VUE_APP_ROOT_API + '/tasks/user/'
    }
    return process.env.VUE_APP_ROOT_API + '/tasks/user/' + id
  },
  taskComplete: function(id) {
    if (!id){
      return process.env.VUE_APP_ROOT_API + '/tasks/user/complete/'
    }
    return process.env.VUE_APP_ROOT_API + '/tasks/user/complete/' + id
  },
  archivio: function(id) { 
    if (!id){
      return process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/selettore/archivio/'
    }
    return process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/selettore/archivio/' + id
  },
  archivioAss: function(id, isFinal) { 
    let path = process.env.VUE_APP_ROOT_API + '/schedaAssessment/selettore/archivio/';
    if (id){
      path = path.concat(id);
    }
    if(isFinal != undefined)
      path = path.concat('?isFinal=' + isFinal);
    return path
  },
  monitoraggioHistory: function(max) {
    if (!max){
      // if not specified, return latest 50
      return process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/history/50'
    }
    return process.env.VUE_APP_ROOT_API + '/schedaMonitoraggio/history/' + max
  },
  assessmentHistory: function(max) {
    if (!max){
      // if not specified, return latest 50
      return process.env.VUE_APP_ROOT_API + '/schedaAssessment/history/50'
    }
    return process.env.VUE_APP_ROOT_API + '/schedaAssessment/history/' + max
  },
  admin: {
    login: process.env.VUE_APP_ROOT_API + '/auth/login',
    logout: process.env.VUE_APP_ROOT_API + '/auth/logout',
    userInfo: process.env.VUE_APP_ROOT_API + '/auth/token'
  }
}

</script>
