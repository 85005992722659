<template>
  <div id="indicatori" v-if="loadComplete">
    <InfoCompiling
        :tabName="infoTabName"
        :title="'Informazioni di compilazione'"
        >
        </InfoCompiling>
    <DynamicActiveTable2 :idTablePdf="idTablePdf" :tdata="tabledata" :doAction="this.doAction" :actions="this.actions"/>
    <modalForm v-if="showEditForm"
               :fdata="this.editFormData"
               :sch="this.scheda"
               :hidden="!showEditForm"
               :buttons="this.buttons"
               :cfg="incomingData.schedaMonitoraggio.dataEntryConfiguration"
               @close="showEditForm = false"
               @salvaRecord="aggiornaRiga">
      <h3 slot="header">{{modalHeader}}</h3>
    </modalForm>
  </div>
</template>


<script>
import DynamicActiveTable2 from "@/components/dynamicActiveTable2.vue";
import modalForm from "@/components/modalForm.vue";
import tool from '@/helpers/tools.js'
import mapUtils from '@/helpers/mapUtils.js'
import { JSONPath as jpath } from 'jsonpath-plus';
import utils from '@/helpers/utils.js'
import InfoCompiling from "@/components/infoCompiling.vue"

export default {
  name: "indicatori",
  components: {
    // DynamicTable2,
    DynamicActiveTable2,
    modalForm,
    InfoCompiling
  },

  props: {
    idTablePdf: String,
    infoTabName: { type: String, default: () => '' },
    incomingData: Object,
    salvaSchedaToStore: Function,
    viewOnlyMode: Function,
    tabName: String
  },

  watch: {
    tabName: function() {
      if(this.$props.tabName === "statoavanzamento") {
        this.extractData();
      }
    }
  },

  data() {
    return {
      loadComplete: false,
      actions: ["edit"],
      tabledata: {
        header: ["ID Indicatore", "Descrizione", "Unità di misura", "Valore di Partenza", "Valore Atteso", "Valore Raggiunto", "Note"],
        rows: [
          {
            content: {
              "ID Indicatore": "Non disponibile",
              "Descrizione": "Non disponibile",
              "Unità di misura": "Non disponibile",
              "Valore di Partenza": "Non disponibile",
              "Valore Atteso": "Non disponibile",
              "Valore Raggiunto": "Non disponibile", 
              "Note": "Non disponibile"
            }
          }
        ]
      },
      buttons: [
            {
              name: 'Salva',
              action: 'salvaRecord',
              show: true,
              param: "edit"
            }
      ],
      editFormData: {},
      modalHeader: "",
      showEditForm: false,
      colIds: [
        "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']",
        "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['descrizione']",
        "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['unitaDiMisura']",
        "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['valorePartenza']",
        "['progetto']['indicatoriObiettivo'][*]['valoreAtteso']",
        "['progetto']['indicatoriObiettivo'][*]['valoreRaggiunto']",
        "['progetto']['indicatoriObiettivo'][*]['note']"
      ],
      tagColIds: [
        "['progetto']['indicatoriObiettivo'][*]['indicatoreObiettivo']['idIndicatore']",
      ],
      scheda: {}

    };
  },
  mounted() {
    this.extractData();
  },
   
  methods: {
    
    aggiornaRiga(actionFromModal) {
      this.showEditForm = false;
      if(this.incomingData.schedaMonitoraggio.dataEntryConfiguration) {
        let clonedSchedaMonitoraggio = mapUtils.aggiornaScheda(actionFromModal,
                                                               tool.genericFunctions.cloneObject(this.incomingData.schedaMonitoraggio),
                                                               this.tagColIds,
                                                               this.$parent.title);
        this.salvaSchedaToStore(clonedSchedaMonitoraggio);
        this.extractData();
      }
      else {
        this.tabledata.rows[this.numeroRiga].content = actionFromModal.content; 
        this.preparaScheda(this.tabledata);
      }
    },

    preparaScheda(tableData) {
      let scheda = tool.genericFunctions.cloneObject(this.incomingData.schedaMonitoraggio);
      
      if(scheda){
        //sovrascrivo solo i campi modificabili
        let riga = tableData.rows[this.numeroRiga].content;
          
        scheda.progetto.indicatoriObiettivo[this.numeroRiga].valoreRaggiunto = riga['Valore Raggiunto']; 
        scheda.progetto.indicatoriObiettivo[this.numeroRiga].note = riga['Note']; 

      } else 
        console.error("indicatori.preparaScheda: dati passanti NULL!?");
      
      //persisto il dato nello store
      this.salvaSchedaToStore(scheda);
    },

    doAction(actionToDo, index) {
      this.scheda = this.incomingData.schedaMonitoraggio;
      
        let value;
        let mapElement;
        const mappa = this.scheda.dataEntryConfiguration;
        switch(actionToDo) {
          case 'edit':
            this.editFormData = {content:{}, conf: {}};
            this.colIds.forEach(columnId => {
              let id = columnId.replace('*', index);
              let values = jpath('$'+id, this.incomingData.schedaMonitoraggio);
              if(values.length === 0) {
                console.error('valore non trovato in scheda: ', id);
                return;
              }
              let ele = mapUtils.getMapElement(mappa, id);
              mapElement = tool.genericFunctions.cloneObject(ele);
              mapElement.config.path = mapElement.config.path.replace('*', index);
              value = {'value': values[0], 'id': id};
              this.editFormData.conf[id] = mapElement;
              this.editFormData.content[id] = tool.genericFunctions.cloneObject(value);
            });
            this.showEditForm = true;
            this.numeroRiga = index; //memorizzo il numero di riga in editing per poter salvare il dato
            this.modalHeader = "Modifica Indicatore";
            this.buttons[0].show=true;
            break;
        }
    },

    extractData() {
      this.loadComplete = false;
      if(!this.incomingData){
            console.log("indicatori: Invalid inputData!?");
            return;
      }
      let inputData = this.incomingData.schedaMonitoraggio;
      this.viewOnlyMode(this.incomingData, this.actions);
      
      const mappa = inputData.dataEntryConfiguration;
      this.tabledata = mapUtils.costruisciTabella(this.colIds, mappa, inputData);
      this.configureActions();
      this.loadComplete = true;
    },

    configureActions() {
      for(let row of this.tabledata.rows) {
        let conf = row.conf;
        let actions = tool.genericFunctions.cloneObject(this.actions);
        for(let value of Object.values(conf)) {
          if(value.data.deleted){
            actions = actions.filter( value1 => {
              return (value1 !== 'edit' && value1 !== 'delete')
            });
            break;
          }
        }

        for(let j = 0; j < actions.length; j++) {
            if(!utils.caniuse(actions[j], this.incomingData.taskList, this.incomingData.schedaMonitoraggio, this.incomingData.history)) {
              actions.splice(j,1);
              j--;
            }
        }
        row.actions = actions;
      }
    },
  }
};
</script>
