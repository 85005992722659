<template>
  <div class="h1 ml-1">
    <b-icon v-if="iconInfo.color && iconInfo.type" :icon="iconInfo.type" :variant="iconInfo.color"></b-icon>
  </div>
</template>

<script>
export default {
    name: "GenericCircle",
    computed: {
    },
    props: {
        iconInfo:
        {
            type: Object,
            required: true
        },
    }
}
</script>
