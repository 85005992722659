<template>
                  
<div :id="entity">
  <div class="row justify-content-center align-items-center"> 
    <h4 v-if="canUploadFiles()">Aggiungi Allegato</h4> 
  </div>
  <div class="row justify-content-center align-items-center">
    <p>File supportati: {{acceptedFile}}</p>
  </div>

  <!-- start | attachments drop area -->
  <b-container class="bv-example-row" :disabled="setDownload">
    <b-row v-if="canUploadFiles()">
      <b-col md="12">
        <div v-cloak :id="'upload-'+entity" class="dropzone grey"  
            @dragover="this.dragover" 
            @dragleave="this.dragleave" 
            @drop.prevent="dropHandler"
            @dragover.prevent>
          <b-button v-cloak :id="'sfoglia-'+entity" class="sfoglia" :disabled = this.charging>
            <input type="file" :id="'fileinput-'+entity" name="fileinput" @change="inputHandler"
              accept=".pdf,.jpeg,.jpg,.txt,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip" class="fileinput">
            <label :for="'fileinput-'+entity" class="sfoglialabel">
              Sfoglia
            </label>
          </b-button>
          o trascina qui
        </div>
      </b-col>
    </b-row>
    <!-- end | attachments drop area -->


    <!-- start | recent uploads section -->
    <b-row class="bv-example-row">
    <b-col md="12">
      
      <div v-if="fileList.length !== 0">
        <h4 class="text-center">File da Caricare</h4>
      <div v-if="fileList.length === 0">
        Tutto fatto!
      </div>
        <b-button variant="outline-danger" @click="emptyRecentList" id="clean" style="float: right;" size="sm" :disabled="setDownload">Pulisci elenco</b-button>
        <br>
        <ul>
          <li :key="key" v-for="(file, key) in fileList">
            <b-container>
              <b-form @submit.stop.prevent="addAndStart(file['file'])" inline> 
              <b-row>
                <b-col><h4>{{ file['file'].name }} <br/> {{ file['file'].size }} Byte</h4></b-col>
                <b-col>
                  <b-form-select 
                    id="select"
                    class="mb-2 mr-sm-2 mb-sm-0 select"
                    required
                    placeholder="Tipologia"
                    :disabled="setDownload"
                    v-model="file['file'].documentType"
                    v-bind:name="file['file'].name"
                    >
                    <option v-for="documentType in documentTypes"
                    :key="documentType.type"
                    :value="documentType.type">{{documentType.type}}
                    </option>
                  </b-form-select>
                  <b-form-text>Tipologia Documento</b-form-text>
                </b-col>
                <b-col>              
                  <input type="text"
                    id="estremi"
                    class="mb-1 mr-sm-1 mb-sm-0"
                    maxLength="254"
                    :disabled="setDownload"
                    v-model="file['file'].notes"
                    v-bind:name="file['file'].name"
                    placeholder="Estremi Documento"
                  >
                </b-col>
                <b-col>
                  <b-button 
                    type="submit"
                    size="sm" 
                    v-bind:disabled="charging"
                    class="btn-success"
                    id="Carica"
                    >
                    Carica
                  </b-button>
                </b-col>
              </b-row>
              </b-form>
                <span v-if="file['uploadStatus'] == 'ERROR'">
                Fallito &nbsp;<fa-icon :icon="['fas', 'times']"/>
                </span>
                <span v-else-if="!file['uploadStatus']">
                  In caricamento &nbsp;<fa-icon :icon="['fas', 'sync']" spin/>
                </span>
                <span v-else-if="file['uploadStatus'] == 'PENDING'">
                  In caricamento &nbsp;<fa-icon :icon="['fas', 'sync']" spin/>
                </span>
                <span v-else-if="file['uploadStatus'] == 'FINISHED'">
                  Completato &nbsp;<fa-icon :icon="['fas', 'check']"/>
                </span>
            </b-container>
          </li>
        </ul>
      </div>
    </b-col>
    </b-row>
    <!-- end | recent uploads section -->


    <!-- start | download table section -->
    <b-row v-cloak class="bv-example-row">
    <b-col md="12">
      <div v-if="downloadSectionEnabled" class="overflow-auto">
        <h3>File disponibili</h3>
        <b-table :id="idTablePdf" :emptyText=" 'Nessun dato trovato' "
            :items="this.availableFiles"
            :fields="this.availableFilesColumns"
            :per-page="fileTableRowsPerPage" :currentPage="fileTableCurrentPage"
            sortable sort-by="uploadDate" sort-desc medium
            show-empty hover responsive head-variant="secondary">
          <template v-slot:cell(name)="row">
            {{ row.item.firstName }} {{ row.item.lastName }}
          </template>
          <template v-slot:cell(actions)="row">
            <b-button size="sm" variant="outline-primary" :disabled="setDownload"
                @click="downloadAction(row.item.objectName)">
                <fa-icon :icon="['fas', 'file-download']" class="selector-icons"/>
            </b-button>
            &nbsp;
            <b-button :hidden="isHiddenDelete(row.item)" size="sm" :disabled="setDownload"
              @click="deleteAction(row.item.objectName)" variant="outline-primary">
              <fa-icon
                 :icon="['far', 'trash-alt']"
                 class="selector-icons"
                />
            </b-button>
          </template>
        </b-table>
        <b-pagination
          class="centerPagInRow"
          v-model="fileTableCurrentPage"
          :total-rows="fileTableTotalrows"
          :per-page="fileTableRowsPerPage"
          aria-controls="fileTable"
        ></b-pagination>
      </div>
      <div v-else>
        <h4 class="text-center">Nessun file disponibile</h4>
      </div>
    </b-col>
    </b-row>
    <!-- end | download table section -->

  </b-container>
  

</div>

</template>

<script>

import endpoints from '@/components/endpoints.vue'
import notify from "@/helpers/notifications.js";
import axios from 'axios';
import utils from '@/helpers/utils.js'
import {mapGetters} from 'vuex';

export default {
    name: 'FileUpload',

    props: {
      name: null,
      rid: {
        default: null,
      },
      ownerProcessStatus: {
        type: String,
        default: null
      },
      actions: {
        default: null,
      },
      entity: {
        type: String,
        default: null,
      },
      availableFiles: {type: Array, default: () => [] }, //Files già caricati, forniti dal tab 
      documentTypes: Array, //Tipi di file disponibili
      idTablePdf: { type: String, default: () => '' },
      //informazioni di riferimento per tutti i file caricati
      riferimento: { type: Object }

    },
    data() {
      return {
        files: {}, // metadata store of selected, uploading and uploaded files. lives until page refresh.
        fileList: [],
        fileTableCurrentPage: 1,
        fileTableRowsPerPage: 5,
        userInfo: this.$getUserInfo(),
        //availableFiles: [], //file già caricati       
        charging : false, //Diventa true durante il download o upload
        availableFilesColumns: [
          {
            key: 'objectName',
            sortable: true,
            label: 'Nome file',
            formatter: this.filenameOnly
          },
          {
            key: 'uploadDate',
            sortable: true,
            label: 'Caricato il',
            formatter: this.dateTimeFormatter
          },
          {
            key: 'documentType',
            label: 'Tipologia',
            sortable: true,
            class: 'text-center',
          },
          {
            key: 'owner',
            label: 'Caricato da',
            sortable: true,
            class: 'text-center',
          },
          {
            key: 'notes',
            label: 'Estremi',
            sortable: true,
            class: 'text-center',
          },
          {
            key: 'fileSize',
            sortable: true,
            formatter: this.filesizeFormatter,
            label: 'Dimensioni',
            class: 'text-right',
          },
          {
            key: 'protocolled',
            label: 'Protocollato',
            formatter: this.protocolFormatter,
            class: 'text-center',
          },
          {
            key: 'protocolDate',
            label: 'Data Protocollo',
            formatter: this.dateFormatter,
            class: 'text-center',
          },
          {
            key: 'protocolId',
            label: 'Id Protocollo',
            class: 'text-center',
          },
          {
            key: 'actions',
            label: ''
          },
        ],
        acceptedFile:".pdf,.jpeg,.jpg,.txt,.doc,.docx,.png,.tif"
      }
    },

    computed: {
      setDownload() {
        return this.charging 
      },
      downloadSectionEnabled(){
        return this.availableFiles.length !== 0;
      },
      fileTableTotalrows(){
        return this.availableFiles.length
      },
      ...mapGetters({
            getSchedaProgetti: 'progetto/getScheda',
            getSchedaBandi: 'announcements/getScheda',
            getSchedaExecuting: 'executingBody/getScheda',
            getIstruttoria: 'istruttoria/getIstruttoria',
        }),
    },
    methods: {
      dateTimeFormatter(value) {
        return utils.formatDateTime(value)
      },
      dateFormatter(value) {
        return utils.formatDate(value)
      },
      canUploadFiles() {
        if(this.$regIndexOf('save',this.actions)=== -1)
          return false
          
        return !(this.entity === 'organigramma' && this.userInfo.roles[0] !=='Beneficiario-Operatore');
      },

      isHiddenDelete(item) {
        // if not owner, cannot delete
        if(this.userInfo.preferred_username !== item.owner) {
          return true;
        }
        
        if(this.$regIndexOf('save',this.actions)=== -1)
          return true;
        
        //Se il processo bpm è andato avanti, non puoi più cancellare un file, anche se sei il proprietario
        return (this.ownerProcessStatus !== item.ownerProcessStatus);
      },

      //
      // methods to handle drag n drop and file input
      //
      dragover(event) {
        event.preventDefault();
        if (!event.currentTarget.classList.contains('green')) {
          event.currentTarget.classList.remove('grey');
          event.currentTarget.classList.add('green');
        }
      },
      dragleave(event) {
        // Clean up
        event.currentTarget.classList.add('grey');
        event.currentTarget.classList.remove('green');
      },
      inputHandler(event){
        let inputFiles = event.target.files;
        if(!inputFiles) return;
        let file = inputFiles[0] // upload one at time
        //console.log("file is: ",file)
        this.uploadPreparation(file)
      },
      dropHandler(e){
        // Clean up
        event.currentTarget.classList.add('grey');
        event.currentTarget.classList.remove('green');
        let droppedFiles = e.dataTransfer.files;
        if(!droppedFiles) return;
        let file = droppedFiles[0] // upload one at time
        //console.log("file is: ",file)
        this.uploadPreparation(file)
      },
      uploadPreparation(file){
         this.fileList.push({
          "name": file.name,
          "file": file,
          "uploadStatus": 'WAITING'
        })
      },
      addAndStart(file){
        let now = this.formatDate(new Date())
        let objectName = now + "_" + file.name
        
        this.files[objectName] = {}
        this.files[objectName]['file'] = file
        //console.log("this.files: ", this.files)

        this.uploadFileProcedure(file, objectName, this.rid, this.ownerProcessStatus)
          .then(() => {
            console.log("upload ended")
            // upload ended, set status to FINISHED to
            // do something es. remove spinning icon
            const currentStatus = this.fileList.find(
              item => item.filename === objectName
              ).uploadStatus = this.files[objectName]['meta']['uploadStatus']
            
            //if all went well, remove from the recent list
            if(currentStatus === "FINISHED")
            {

              let index=this.fileList.indexOf(
                this.fileList.filter(
                    item => item.filename === objectName
                )[0]);
           
              console.log("Caricato con successo");
              this.fileList.splice(index,1);
            }

            // reload attchments list
            this.$emit('doRefresh');
            this.charging = false
          })
          .catch(e => {
            // in case of error, set upload status to ERROR and behave in html accordingly
            console.log('uploadFileProcedure error', e)
            this.fileList.find(
              item => item.filename === objectName
              ).uploadStatus = "ERROR"

            this.charging = false
          })
        // uploading... sets initial status to PENDING, handle it and
        // do something like showing some spinning icon
        // this.fileList.push({
        //   "filename": objectName,
        //   "file": file,
        //   "uploadStatus": 'PENDING'
        // })
        this.charging = true
        //Trova il file a partire dal nome e ne aggiorna lo stato
        this.fileList.find(
              item => item.name === file.name
              ).uploadStatus = "PENDING"
        //Aggiorna 'filename' (data+nome) per la find precedente
        this.fileList.find(
              item => item.name === file.name
              ).filename = objectName
      },

      //
      // date formatters
      //
    /**
     * pad a number with zeros
     * @param {number} num number to pad
     * @param {number} padding how much padding you want
     * @returns
     */
      pad(num, padding){
        return (String("0".repeat(padding) + num).slice(-padding));
      },
      /**
       * return date in the format YYYY-MM-DD_hhmmss
       * @param {Date} d the date
       * @returns
       */
      formatDate(d) {
      return (
        d.getFullYear() + '-' +
        this.pad(d.getMonth() + 1, 2) + '-' +
        this.pad(d.getDate(), 2) + '_' +
        this.pad(d.getHours(), 2) +
        this.pad(d.getMinutes(), 2) +
        this.pad(d.getSeconds(), 2)
      )
      },

      //
      // utilities and methods used by btable
      //
      protocolFormatter(p){
        return utils.protocolFormatter(p)
      },
      filenameOnly(f){
        if (f && f.length>0){
          return decodeURI(utils.filenameOnly(f));
        } else {
          console.error("NO filename");
          return "no filename";
        }
      },
      filesizeFormatter(s){
        return utils.filesizeFormatter(s)
      },
      //
      // Download Methods
      //
      forceFileDownload(response, objectName){
        const blob = new Blob([response.data]);
        //console.log("blob file:",blob)
        if(window.navigator.msSaveOrOpenBlob){
          //IE11
          console.log("IE download")
          window.navigator.msSaveOrOpenBlob(blob,this.filenameOnly(objectName));
        } else {
          //Chrome, Firefox, ...
          const url = window.URL.createObjectURL(blob)
          console.log("forceFileDownload url:",url);
          const link = document.createElement('a')
          link.href = url
          // link.setAttribute('download', objectName) // if you want upload_datetime + filename
          link.setAttribute('download', this.filenameOnly(objectName)) // if you want filename only
          document.body.appendChild(link)
          link.click()
        }
      },
      downloadAction(objectName){
        console.log("downloadAction ", objectName)
        this.getPresignedGetUri(this.rid, objectName)
          .then(resp => {
            axios({
              method: 'get',
              url: resp.uri,
              responseType: 'arraybuffer'
                  })
                    .then(response => {
                      //console.log("promise download")
                      //fine animazione download
                      this.charging =false
                      this.forceFileDownload(response, objectName)
                    })
          }).catch(
            (error) => {
              console.log("Errore nel download", error);
              notify.error(
                notify.strings.error,
                notify.strings.downloadError(this.filenameOnly(objectName))
              )
              this.charging =false
            }
          )
        //avvio animazione di download
        this.charging =true
        notify.info(
          notify.strings.download,
          notify.strings.downloading(this.filenameOnly(objectName))
        )
      },
      //
      // Delete
      //
      deleteAction(objectName){
        console.log("deleteAction ", objectName);
        let uri = endpoints.postFilesBasePath + this.rid + "/" + objectName;

        this.$delete(uri, { "status" : this.ownerProcessStatus }).then(
          () => {
            notify.success(
              notify.strings.success,
              notify.strings.deleteSuccess(objectName)
            );
            this.$emit('doRefresh');
          }).catch(
            (error) => {
              console.error("errore nell'eliminazione del file", objectName, error);
              notify.error(
                notify.strings.error,
                notify.strings.deleteError(objectName)
              )
          });

      },

      //
      // all methods needed to handle file uploading operations below
      //
      // 1. get presigned uri for put operation
      async getPresignedPutUri(rid, objectName){
        let bucketName ="isf-protocol";
        let reqUri = endpoints.presignedPutUri + bucketName + "/" + rid + "/" + objectName;
        //console.log("reqUri is: " + reqUri)
        return this.$get(reqUri)
            .then(
                resp =>  {
                  //console.log('getPresignedPutUri. response: ', resp)
                  // start -- removing http(s)://domain:port from resp.uri
                  let uri = resp.uri
                  uri = uri.split(":")[2];
                  uri = uri.substring(uri.indexOf("/"));
                  
                  //BUG FIX: in base all'ambiente in cui si esegue, personalizza l'URL di minio
                  let url = '';
                  if (process.env.VUE_APP_MINIO_ENDPOINT){
                    url = process.env.VUE_APP_MINIO_ENDPOINT+uri
                  } else {
                    // WARNING: deve essere a questo valore per eseguire correttamente la build per vm
                    url = uri;
                  }

                  resp.uri = url;
                  console.log('new PUT uri: ', resp.uri)
                  // end --
                  return resp
                }
            ).catch(error => {
                console.log(error);
                notify.error(
                  notify.strings.error,
                  notify.strings.cantConnectToServer
                )
            });
      },

      // 2. post to portal-files
      async postToPortalFiles(rid, objectName, ownerProcessStatus, filesize, notes, documentType) {
        let skipProtocol = false;

        const doc = this.documentTypes.find(item => item.type === documentType);
        if(doc && doc.skipProtocol) {
          skipProtocol = doc.skipProtocol;
          console.log('ski prot for', documentType);
        } else {
          console.log('no skip for ', documentType);
        }
        let reqBody = {
                        "idScheda": rid,
                        "objectName": objectName,
                        "ownerProcessStatus": ownerProcessStatus,
                        "fileSize": filesize,
                        "documentType": documentType,
                        "topic": this.name,
                        "notes": notes,
                        "published": false,
                        "idOggettoRif": this.riferimento.idOggettoRif,
                        "tipoOggettoRif": this.riferimento.tipoOggettoRif,
                        "idBando": this.riferimento.idBando,
                        "tipoBando": this.riferimento.tipoBando,
                        "codiceProgetto": this.riferimento.codiceProgetto,
                        "idProgetto": this.riferimento.idProgetto,
                        skipProtocol: skipProtocol
                      }
        console.log("DOCTYPE:",documentType, reqBody.skipProtocol);
        
        

        return this.$post(endpoints.postFilesBasePath, reqBody)
            .then(
                resp =>  {
                  return resp;
                }
            ).catch(error => {
                console.log("postToPortalFiles error", error);
                notify.error(
                  notify.strings.error,
                  notify.strings.cantConnectToServer
                )
            });
      },

      // 3. put to minio
      async uploadNew(file, endpoint){
          const url = endpoint;
          
          console.log("creating new formdata to url", url);
          let formData = new FormData();
          formData.append('file', file);
          //console.log("BODY IS: ", file)
          let hdrs = {
            'Content-Type': 'multipart/form-data'
          }
          return axios.put(url, file, { headers: hdrs })
              .then(resp =>  {
                    console.log("uploaded. filename: ", file.name)
                    console.log("response from minio: ", resp)
                    return resp
                  }
              ).catch(error => {
                  console.log(error);
                  console.error(JSON.stringify(error.message));
                  notify.error(
                    notify.strings.error,
                    notify.strings.cantConnectToServer
                  )
              });
      },

      // bundle everything
      async uploadFileProcedure(file, objectName, rid, ownerProcessStatus) {
        let filename = objectName;

        // init array item for filename
        if (this.files[filename] == undefined || this.files[filename] == null){
          this.files[filename] = {}
        }

        // step 1
        let presignedPutUri = await this.getPresignedPutUri(rid, objectName)
        //console.log("presignedPutUri response object: ", presignedPutUri)
        this.files[filename]['presignedPutUri'] = presignedPutUri
        // initially this.files[filename]['meta']['uploadStatus'] is null, there's no meta info
        console.log("step 1: files: ", this.files)

        // step 2
        let meta = await this.postToPortalFiles(rid, presignedPutUri.objectName, ownerProcessStatus, file.size, file.notes, file.documentType)
        console.log("post to portal-files reps: " , meta)
        this.files[filename]['meta'] = meta
        console.log("step 2: files: ", this.files)

        // step 3
        //console.log(presignedPutUri.uri);
        let minioResponse = await this.uploadNew(file,presignedPutUri.uri)
        // return if http status != 200
        if (minioResponse.status != 200){
          notify.error(
            notify.strings.error,
            notify.strings.uploadFallito
          )
          return;
        }
        console.log("step 3: files: ", this.files)

        // step 4
        let putUri = endpoints.postFilesBasePath + rid + "/" + objectName;
        let putResp = await this.$put(putUri)
              .then(resp =>  {
                    console.log("PUT to portal-files OK. Response: ", resp)
                    // filename.meta.uploadStatus in response is to 'FINISHED' if all went well
                    return resp
                  }
              ).catch(error => {
                  console.error(JSON.stringify(error.message));
                  notify.error(
                    notify.strings.error,
                    notify.strings.cantConnectToServer
                  )
              });
        this.files[filename]['meta'] = putResp
        //console.log("putResp is: ", putResp)
        console.log("step 4: files: ", this.files)


        // upload ended ok
        if ( this.files[filename]['meta'] != undefined
          && this.files[filename]['meta'] != null
          && this.files[filename]['meta']['uploadStatus'] == "FINISHED"
          ){
          // notify done 
          notify.success(
            notify.strings.success,
            notify.strings.uploadSuccess(this.filenameOnly(filename))
          )
          return this.files[filename]
        } else {
          console.log("unexpected upload status for filename: " + filename)
          console.log(this.files[filename]['meta']['uploadStatus'])
          notify.error(
            notify.strings.error,
            notify.strings.unexpectedLoadStatus(filename)
          )
          throw new Error("Unexpected upload status for filename: " + filename)
        }
      },

      //
      // other methods
      // ...
      // use this to get an uri to download files directly from object storage
      async getPresignedGetUri(rid, objectName){
        let bucketName ="isf-protocol";
        let reqUri = endpoints.presignedGetUri + bucketName + "/" + rid + "/" + objectName;
        //console.log("reqUri is: " + reqUri)
        return this.$get(reqUri)
            .then(
                resp =>  {
                  console.log('getPresignedGetUri. response: ', resp)
                  // start -- removing http(s)://domain:port from resp.uri
                  let uri = resp.uri
                  uri = uri.split(":")[2];
                  uri = uri.substring(uri.indexOf("/"));

                  //BUG FIX: in base all'ambiente in cui si esegue, personalizza l'URL di minio
                  let url = '';
                  if (process.env.VUE_APP_MINIO_ENDPOINT){
                    url = process.env.VUE_APP_MINIO_ENDPOINT+uri
                  } else {
                    // WARNING: deve essere a questo valore per eseguire correttamente la build per vm
                    url = uri;
                  }
                  resp.uri= url
                  console.log('new GET uri: ', resp.uri)
                  // end --
                  return resp
                }
            ).catch(error => {
                console.log(error);
                 notify.error(
                    notify.strings.error,
                    notify.strings.cantConnectToServer
                  )
            });
      },
      emptyRecentList(){
        this.fileList = []
      }

    },
}
</script>

<style scoped lang="scss">
h4 {
  color: $blueisf;
}
.sfoglia {
  background-color:$blueisf;
  margin-left: 10px;
}
.sfoglia:hover {
  background-color:$blueisf;
}
#select {
  width: 200px;
}
[v-cloak] > * { display: none; }

[v-cloak]::before {
  content: "Loading...";
  padding-top: 20px;
  padding-bottom: 20px;
}

.fileinput {
  display: none;
}
.sfoglialabel {
  cursor:pointer;
}

.dropzone {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 25px;
}
.grey {
  background-color: $lightgrey;
  border-style: dashed;
  border-width: 2px;
  border-color: $lightgrey;
}
.green {
  //background-color: $lightgrey;
  border-style: solid;
  border-width: 2px;
  border-color: $grey;
}
.fileInputText p {
  color: $black;
  font-size: 1.5em;
}
.text-right {
  text-align: right;
}
.centerPagInRow {
  justify-content: center;
}
</style>
