<template>
  <div>
    <b-overlay :show="!loadComplete || generationPdf" rounded="sm">
    <div class="table-title">Scheda Controllo in Loco {{this.controllo.sottoTipoControllo}} per il Progetto: {{this.$route.params.codiceProgetto}} </div>
      <div v-if="loadComplete">
        <div class="table-title">Titolo scheda: {{controllo.content.titoloControllo}}</div>
        <b-table-lite
          thead-class="head"
          :items="tabledata.rows"
          :fields="tabledata.header"
          v-if="loadComplete" />
        
        <b-card no-body>
          <b-tabs card nav-class="tabs" content-class="content">
            <ColorLegend/>
            <b-tab
              v-if="loadComplete"
              no-body
              :title="conf_export_pdf['Generale'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Generale'].name)">
              <GeneralViewerCard
                :idTablePdf="conf_export_pdf['Generale'].id_table_pdf"
                :name="conf_export_pdf['Generale'].name"
                :tabName="tabSelection"
                :headerTable="headerTabGenerale"
                :pathDataEntryConfiguration="'content'"
                :store="'controlli'"
                :fnGetterTab="'getTabellone'"
                :rowIds="rowIdsGenerale[this.controllo.sottoTipoControllo]"
                :fnGetter="'getScheda'"
                :idEntity="{
                  codiceProgetto: this.$route.params.codiceProgetto,
                  id: this.$route.params.idControllo
                }"
                :fnSetter="'updateScheda'"
                :actions="actionsMap[mappingTabs[1]]"
              />
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('Documentazione')"
              no-body
              :title="conf_export_pdf['Documentazione'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Documentazione'].name)">
              <Documentazione
                :ownerProcessStatus= this.retrieveTaskDefinitionKey
                :actions="actionsMap[mappingTabs[2]]"
                :entity= this.entity
                :getters = this.getters
                :setters = this.setters
                :payload = this.payload
                :riferimento = this.riferimento
                tabName="Documentazione"
              />
            </b-tab>
            <b-tab
              v-if="loadComplete && isFinanziario() && isViewAllowed('ControlloFinanziario')"
              no-body
              :title="conf_export_pdf['Controllo'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Controllo'].name)">
              <Controllo
                v-if="loadComplete"
                :idTablePdf="conf_export_pdf['Checklist_documentale'].id_table_pdf"
                :title="conf_export_pdf['Checklist_documentale'].title_page_pdf"
                :tabName="tabSelection.toLowerCase()"
                :config="updateTabellone('documentale')"
                :pointingTab="'documentale'"
                :dataObject="'controllo'"
                :store="'controlli'"
                :fnGetter="'getScheda'"
                :fnSetter="'updateScheda'"
                :idEntity="{
                  codiceProgetto: this.$route.params.codiceProgetto,
                  id: this.$route.params.idControllo}"
                :name="'Check List Controllo Documentale'"
                :headerTable="headerChecklist"
                :actions="actionsMap[mappingTabs[3]]"
              />
              <Controllo
                v-if="loadComplete"
                :idTablePdf="conf_export_pdf['Checklist_fisico'].id_table_pdf"
                :title="conf_export_pdf['Checklist_fisico'].title_page_pdf"
                :tabName="tabSelection.toLowerCase()"
                :config="updateTabellone('fisico')"
                :pointingTab="'fisico'"
                :dataObject="'controllo'"
                :store="'controlli'"
                :fnGetter="'getScheda'"
                :fnSetter="'updateScheda'"
                :idEntity="{
                  codiceProgetto: this.$route.params.codiceProgetto,
                  id: this.$route.params.idControllo}"
                :name="'Check List Controllo Fisico'"
                :headerTable="headerChecklist"
                :actions="actionsMap[mappingTabs[3]]"
              />
            </b-tab>
            <b-tab
              v-if="loadComplete && !isFinanziario() && isViewAllowed('ControlloOperativo')"
              no-body
              :title="conf_export_pdf['Controllo'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Controllo'].name)">
              <Controllo
                v-if="loadComplete"
                :idTablePdf="conf_export_pdf['Checklist_operativo'].id_table_pdf"
                :title="conf_export_pdf['Checklist_operativo'].title_page_pdf"
                :tabName="tabSelection.toLowerCase()"
                :config="getTabellone"
                :dataObject="'controllo'"
                :store="'controlli'"
                :fnGetter="'getScheda'"
                :fnSetter="'updateScheda'"
                :idEntity="{
                  codiceProgetto: this.$route.params.codiceProgetto,
                  id: this.$route.params.idControllo}"
                :headerTable="headerChecklist"
                :actions="actionsMap[mappingTabs[4]]"
              />
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('Verbale')"
              no-body
              :title="conf_export_pdf['Verbale'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Verbale'].name)">
              <GeneralViewerCard
                :idTablePdf="conf_export_pdf['Verbale'].id_table_pdf"
                :name="conf_export_pdf['Verbale'].name" 
                :tabName="tabSelection"
                :headerTable="headerTabGenerale"
                :pathDataEntryConfiguration="'content'"
                :store="'controlli'"
                :fnGetterTab="'getTabellone'"
                :rowIds="rowIdsVerbale"
                :fnGetter="'getScheda'"
                :fnSetter="'updateScheda'"
                :idEntity="{
                  codiceProgetto: this.$route.params.codiceProgetto,
                  id: this.$route.params.idControllo
                }"
                :doRefresh="refreshDich"
                :actions="actionsMap[mappingTabs[5]]"/>
              <br/>
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('Invio')"
              no-body
              title="Invio"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Invio_mandatory'].name)">
              <Invio 
                v-if="loadComplete"
                :idsToCheck="arrayMandatory"
                :tabName="tabSelection"
                :actions="actionsMap[mappingTabs[6]]"
                :overloadedMap="setChecklistForInvio"
                @buildPdf="generateReport"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Invio from "./invio.vue"
import Documentazione from "@/components/Documentazione.vue"
import notify from "@/helpers/notifications.js"
import Controllo from '@/views/controlli/CheckListSingleControl'
import { mapGetters } from "vuex"
import GeneralViewerCard from "@/components/GeneralViewerCard.vue"
import utils from "@/helpers/utils.js"
import helper_pdf from "@/helpers/exportPdf.js"
import tools from '@/helpers/tools.js'
import ColorLegend from "@/components/legend.vue"


export default {
  name: "schedaControlliInLoco",
  components: {
    Documentazione,
    Controllo,
    GeneralViewerCard,
    Invio,
    ColorLegend
  },
  data() {
    return {
      //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
      riferimento : {
        idOggettoRif: "",
        tipoOggettoRif: "Scheda Controlli In Loco", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
        idBando: "",
        tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
        idProgetto: "",
        codiceProgetto: ""
      },
      refreshDich: false,
      entity: "controlli",
      idsToCheck:[],
      headerTableCheckList: [],
      conf_export_pdf: {
        "Generale": {
          id_table_pdf: 'anagrafica',
          name:"Generale",
          title_page_pdf: 'Generale',
          nameCollapse: '',
        },
        "Procedura": {
          id_table_pdf: 'procedura',
          name:"procedura2",
          title_page_pdf: 'Procedura',
          nameCollapse: '',
        },
        "Contratto": {
          id_table_pdf: 'contratto',
          name:"contratto2",
          title_page_pdf: 'Contratto',
          nameCollapse: '',
        },
        "Documentazione": {
          id_table_pdf: 'fileTable',
          name:"Documentazione",
          title_page_pdf: 'Documentazione',
          nameCollapse: '',
        },
        "Verbale": {
          id_table_pdf: 'dichiarazione',
          name:"Verbale",
          title_page_pdf: 'Verbale',
          nameCollapse: '',
        },
        "Controllo": {
          id_table_pdf: 'controllo',
          name:"controllo",
          title_page_pdf: 'Controllo',
          nameCollapse: '',
        },
        "Checklist_documentale": {
          id_table_pdf: 'checklist_controllo',
          name:"Check List Documentale",
          title_page_pdf: 'CheckList Controllo Documentale',
          nameCollapse: 'CheckList Controllo',
        },
        "Checklist_operativo": {
          id_table_pdf: 'controllo_operativo',
          name:"controlOperativo",
          title_page_pdf: 'Controllo Operativo',
          nameCollapse: 'CheckList Controllo Operativo',
        },
        "Checklist_fisico": {
          id_table_pdf: 'checklist_fisico',
          name:"Check List Controllo",
          title_page_pdf: 'CheckList Controllo Fisico',
          nameCollapse: 'CheckList Controllo',
        },
        "Invio_mandatory": {
          id_table_pdf: 'mandatory_controlli',
          name:"InvioControllo",
          title_page_pdf: 'Invio',
          nameCollapse: 'Campi obbligatori non valorizzati',
        },
        "Invio_integrazioni": {
          id_table_pdf: 'integrazioni_controlli',
          name:"Invio",
          title_page_pdf: 'Invio',
          nameCollapse: 'Richiesta Integrazioni',
        },
        "Invio_note": {
          id_table_pdf: 'note_controlli',
          name:"Invio",
          title_page_pdf: 'Invio',
          nameCollapse: 'Elenco Note',
        },
        "Invio_note_riservate": {
          id_table_pdf: 'note_riservate_controlli',
          name:"Invio",
          title_page_pdf: 'Invio',
          nameCollapse: 'Elenco Note Interne',
        },
        // L'ESITO FINALE DEL CONTROLLO SARA' INSERITO IN FONDO NELL'ULTIMA PAGINA DISPONIBILE
        "Invio_esito_finale": {
          id_field: "['content']['esitoFinale']",
          name: "Invio",
          title_page_pdf: 'Dettagli Invio',
          conf_field: { addPage: true, mapping: 'PosNeg' },
          descRadio: 'Esito finale del controllo: '
        },
        // ( RICHIESTA PARERE / PARERE ) VALIDI PER CONTROLLI IN LOCO DI TIPO OPERATIVI
        "Invio_richiesta_parere_ICT": {
          id_field: "['content']['richiestoParereICT']",
          name: "Invio",
          title_page_pdf: 'Dettagli Invio',
          conf_field: { addPage: true, mapping: 'SiNo' },
          descRadio: 'Si richiede il parere della Task Force ICT: '
        },
        "Invio_parere_ict": {
          id_field: "['content']['parereICT']",
          name: "Invio",
          title_page_pdf: 'Dettagli Invio',
          conf_field: { addPage: false },
          descRadio: 'Parere ICT: '
        },
        "Invio_richiesta_parere_LIE": {
          id_field: "['content']['richiestoParereLIE']",
          name: "Invio",
          title_page_pdf: 'Dettagli Invio',
          conf_field: { addPage: true, mapping: 'SiNo' },
          descRadio: 'Si richiede il parere della Task force logistica e infrastrutture edili: '
        },
        "Invio_parere_LIE": {
          id_field: "['content']['parereLIE']",
          name: "Invio",
          title_page_pdf: 'Dettagli Invio',
          conf_field: { addPage: false },
          descRadio: 'Parere Task force logistica e infrastrutture edili: '
        }
        // ( RICHIESTA PARERE / PARERE ) VALIDI PER CONTROLLI IN LOCO DI TIPO OPERATIVI
      },
      headerTabGenerale: [
        { key: "campo" },
        {
          key: "descrizione",
          tdAttr: this.tooltip,
          formatter: ""
        },
        { key: "azione" }
      ],
      rowIdsGenerale: {
        "Operativo": [
          "['anagrafica']['verificatore']",
          "['anagrafica']['dataVerifica']",
          "['anagrafica']['luogoVerifica']",
          "['anagrafica']['controllore']",
          "['anagrafica']['idBeneficiario']",
          "['anagrafica']['codiceProgetto']",
          "['anagrafica']['titoloProgetto']",
          "['anagrafica']['costoProgetto']",
          "['anagrafica']['strumentoFinanziario']",
          "['anagrafica']['obiettivoSpecifico']",
          "['anagrafica']['obiettivoNazionale'][*]['codiceComposito']",
          "['anagrafica']['infoContratti']",
        ],
        "Finanziario": [
          "['anagrafica']['verificatore']",
          "['anagrafica']['dataVerifica']",
          "['anagrafica']['luogoVerifica']",
          "['anagrafica']['controllore']",
          "['anagrafica']['idBeneficiario']",
          "['anagrafica']['codiceProgetto']",
          "['anagrafica']['titoloProgetto']",
          "['anagrafica']['costoProgetto']",
          "['anagrafica']['strumentoFinanziario']",
          "['anagrafica']['obiettivoSpecifico']",
          "['anagrafica']['obiettivoNazionale'][*]['codiceComposito']",
          "['anagrafica']['infoContratti']",
        ]
      },
      rowIdsVerbale : [
          "['verbale']['statoAvanzamento']",
          "['verbale']['descrizione']",
          "['verbale']['esito']",
          "['verbale']['criticita']",
          "['verbale']['raccomandazioni']",
          "['verbale']['irregolarita']",
          "['verbale']['osservazioni']",
      ],
      headerChecklist: [
            { key: 'descrizione' },
            { key: 'esito', label: 'Esito' },
            { key: 'note', label: 'Note' },
            { key: 'docsRiferimento', label: 'Documentazione di riferimento' },
            { key: 'filesRiferimento', label: 'Documenti di riferimento' },
            { key: 'azione' }
        ],
      controllo: {},
      tabSelection: "Generale",
      loadComplete: false,
      generationPdf: false,
      actionsMap: {},
      getters: {
        scheda: "getScheda",
        tabellone: "getTabellone"
      },
      setters: {
        scheda: "updateScheda",
        allegatoDaCancellare: "setAllegatoDaCancellare",
        clearAllegatiDaCancellare: "clearAllegatiDaCancellare"
      },
      payload: {
        scheda: {          
          codiceProgetto: this.$route.params.codiceProgetto, 
          id: this.$route.params.idControllo},
      },
      tabledata: {
        header: [
          {
            key: "codiceProgetto"
          },
          {
            key: "titolo"
          },
          {
            key: "stato"
          },
          {
            key: "dataConclusione",
            formatter: this.dateFormatter
          }
          
        ],
        rows: [
          {
            "codiceProgetto": "Non disponibile",
            "titolo": "Non disponibile",
            "stato": "Non disponibile",
            "dataConclusione": null
          }
        ],
      },
      mappingTabs: {
        1: 'Generale',
        2: 'Documentazione',
        3: 'ControlloFinanziario',
        4: 'ControlloOperativo',
        5: 'Verbale',
        6: 'Invio'
      }
    }
  },
  mounted() {
    //API di lettura del controllo per ID
    this.loadControlAndConfigs()
    //this.tab_procedura = procedura_json
    this.riferimento.idOggettoRif = this.$route.params.idControllo;
    this.riferimento.codiceProgetto = this.$route.params.codiceProgetto;
  },
  computed: {
    ...mapGetters({
      getTabellone: "controlli/getTabellone",
      getScheda: "controlli/getScheda",
    }),
    arrayMandatory(){
      let rows = this.rowIdsVerbale;
      rows = rows.concat(this.rowIdsGenerale[this.controllo.sottoTipoControllo]);
      return {dinamicIds: rows};
    },
    retrieveTaskDefinitionKey() {
      if (this.controllo && this.controllo.content && this.controllo.content.taskInfo && this.controllo.content.taskInfo.taskDefinitionKey)
        return this.controllo.content.taskInfo.taskDefinitionKey
      else 
        return "Controllo_controllo-concluso_Nessuno-Nessuno"      //fake status non più tornato dal backend ISF-controlli
    },
    getIdProgetto() {
      let idProgetto = this.controllo?.content?.infoProgetto?.idProgetto
      if(idProgetto)
        return idProgetto
      return ''
    },
    //Assegna la checklist da controllare
    setChecklistForInvio(){
      //Se In Loco Finanziario, sovrascrivo la mappa, con il merge delle 2 checklist
      if(this.isFinanziario())
        return Object.assign(this.getTabellone.content.documentale.mappa, 
                              this.getTabellone.content.fisico.mappa)
      else
      //Se In Loco Operativo, lascio la mappa di default
        return null
    }
  },
  methods: {
    updateTabellone(typeCollapse) {
      let clonedTab = tools.genericFunctions.cloneObject(this.getTabellone)
      typeCollapse === 'documentale' ? delete clonedTab.content['fisico'] : delete clonedTab.content['documentale']
      return clonedTab
    },
    callRefreshDich() {
      this.refreshDich = !this.refreshDich;
    },
    getControllo() {
      const payload = {
        codiceProgetto: this.$route.params.codiceProgetto, 
        id: this.$route.params.idControllo
      };
      return this.getScheda(payload)
    },
    generateReport(optPdf) {
      this.generationPdf = true

      // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)
      let source = {
        scheda: this.getControllo()
      }
      
      if(this.getControllo().tipoControllo === "In Loco" && this.getControllo().sottoTipoControllo === "Operativo") {
        this.conf_export_pdf['Invio_richiesta_parere_ICT'].source = source
        //se il parere ICT non è richiesto non includo la relativa text area, BUG FIX 10685
        if (source.scheda.content.richiestoParereICT){
          this.conf_export_pdf['Invio_parere_ict'].source = source
        } else {
          delete this.conf_export_pdf['Invio_parere_ict'];
        }  
        this.conf_export_pdf['Invio_richiesta_parere_LIE'].source = source
        //se il parere LIE non è richiesto non includo la relativa text area, BUG FIX 10685
        if (source.scheda.content.richiestoParereLIE){
          this.conf_export_pdf['Invio_parere_LIE'].source = source
        } else {
          delete this.conf_export_pdf['Invio_parere_LIE'];
        }
        
      } else {
        delete this.conf_export_pdf['Invio_richiesta_parere_ICT']
        delete this.conf_export_pdf['Invio_parere_ict']
        delete this.conf_export_pdf['Invio_richiesta_parere_LIE']
        delete this.conf_export_pdf['Invio_parere_LIE']
      }
      this.conf_export_pdf['Invio_esito_finale'].source = source
      
      // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)

      helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
      this.generationPdf = false
    },
    dateFormatter(value) {
      if (!value) 
        return "Non disponibile";
      return utils.formatDate(value);
    },
    loadTabelloneControllo() {
      this.$store.dispatch("controlli/setTabelloneControllo", {
        schedaControllo: this.controllo
      }).then(
        () => {
          this.loadActionMap();
          this.loadComplete = true
        }
      ).catch( error => {
        console.error('Errore store dispatch: TAB_CONTROLLO', error.message)
        notify.error(notify.strings.error, notify.strings.errorLoadControlli)
      })
    },
    loadActionMap(){
      let scheda = this.controllo;
      //lista degli ID dei tab previsti per questa scheda
      let allIndices = Object.keys(this.mappingTabs)
      for (let index of allIndices) {
        let tabName = this.mappingTabs[index]
        this.actionsMap[tabName] = this.$getActionsControlliInLoco(scheda, tabName);
      }

    },
    isViewAllowed(tabName) { //se ho diritto ad almeno un'azione su questo TAB devo vederlo
      return this.actionsMap[tabName].length > 0
    },
    //Se il controllo in loco è di tipo finanziario, devo caricare la checklist doppia
    isFinanziario(){
      return this.controllo.sottoTipoControllo.includes('Finanziario')
    },
    // Carico il controllo Amministrativo corrente nello Store
    loadControlAndConfigs() {
      const codice = this.$route.params.codiceProgetto;
      const id = this.$route.params.idControllo;
      this.$store.dispatch("controlli/setControlloAmministrativo", {
        codiceProgetto: codice,
        idSchedaControllo: id
      }).then(
        (schedaControllo) => {
          this.controllo = schedaControllo;
          if (schedaControllo.content.infoProgetto.idProgetto){
            this.riferimento.idProgetto = schedaControllo.content.infoProgetto.idProgetto;
          }
          this.populateTabledata();
          this.loadTabelloneControllo();
      })
    },

    populateTabledata(){
      //agisco direttamente sulla prima riga inizializzata con "Non disponibile"
      this.tabledata.rows[0].codiceProgetto = this.controllo.codiceProgetto;
      if (this.controllo.content.infoProgetto){
        if (this.controllo.content.infoProgetto.titolo)
          this.tabledata.rows[0].titolo = this.controllo.content.infoProgetto.titolo;
        if(this.controllo.content.infoProgetto.stato)  
          this.tabledata.rows[0].stato = this.controllo.content.infoProgetto.stato;
        if(this.controllo.content.infoProgetto.dataConclusione)  
          this.tabledata.rows[0].dataConclusione = this.controllo.content.infoProgetto.dataConclusione;
      }
    },
    setTabSelection(name) {
      console.log("Click sul tab =", name)
      this.tabSelection = name
    },
  }
}
</script>