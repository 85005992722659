<template>

  <div id="cronoProgramma_monitoraggio">
    <InfoCompiling
      :tabName="infoTabName"
      :title="'Informazioni di compilazione'"
    >
    </InfoCompiling>
    <div class="card ">
        <div class="card-body text-right "><h1 class="font-weight-bold">Totale Spese CronoProgramma: {{totaleSpeseCronoProgrammaEuro}}</h1> </div>
    </div>
    <br/>
    <DynamicTable2Crono :idTablePdf="idTablePdf" :tdata="tabledata" :tabName="name"
                        :doAction="this.doAction" :actions="this.actions"
                        :sortBy="sortBy"  :disableColors="false"/>
    <modal :hidden="!showModal" :buttons="this.buttons" @close="showModal = false" >
      <div class="row-title" slot="header">{{modalHeader}}</div>
      <div slot="body">
        <DynamicTable2 :tdata="this.modalData" :disableColors="false"/>
        <br/>
        <div class="row-title">Dettaglio Spese</div>
        <DynamicTable2 :tdata="this.modalData2" :disableColors="false"/>
      </div>
    </modal>
    
    <b-modal
      :id="'modalForm1-' + this.name"
      size="lg"
      scrollable centered
      dialog-class="modal1-content"
      content-class="modal1-content"
      hide-footer>
      <template v-slot:modal-title>
        <h3>Modifica</h3>
      </template>
        <modalForm  v-if="showEditForm"
                v-bind:fdata="this.editFormData"
                v-bind:sch="this.scheda"
                :hidden="!showEditForm"
                :buttons="this.buttons"
                :parentName="name"
                :cfg="mappa"
                @close="showEditForm = false"
                @salvaRecord="aggiornaRiga"
                @changeRows="changeRows">
          <div class="row-title" slot="header">{{modalHeader}}</div>
        </modalForm>
    </b-modal>
    <div class="card">
        <div class="card-body text-right "><h1 class="font-weight-bold">Totale Spese CronoProgramma: {{totaleSpeseCronoProgrammaEuro}}</h1> </div>
    </div>
  </div>
</template>


<script>
import DynamicTable2 from "@/components/dynamicTable2.vue";
import DynamicTable2Crono from "@/components/DynamicTableCronoProgetti.vue"
import modal from "@/components/modal.vue";
import modalForm from "@/components/modalForm2level.vue";
import tool from '@/helpers/tools.js'
import utils from '@/helpers/utils.js'
import mapUtils from '@/helpers/mapUtils.js'
import { JSONPath as jpath } from 'jsonpath-plus';
import validation from '@/helpers/validations.js';
import { addHours } from 'date-fns';
import InfoCompiling from "@/components/infoCompiling.vue"


export default {
  name: "cronoProgramma",
  components: {
    DynamicTable2,
    DynamicTable2Crono,
    modal,
    modalForm,
    InfoCompiling
  },
  props: {
    idTablePdf: String,
    incomingData: Object,
    salvaSchedaToStore: Function,
    viewOnlyMode: Function,
    tabName: String,
    infoTabName: { type: String, default: () => '' }
  },
  watch: {
    tabName: function() {
      if(this.$props.tabName === this.name) {
        this.extractData();
      }
    }
  },
 
  data() {
    return {
      name: 'Cronoprogramma',
      sortBy:'',
      totaleSpeseCronoProgrammaEuro:"",
      editFormData: {},
      showModal: false,
      modalData: {},
      modalData2: {},
      modalHeader: "",
      showEditForm: false,
      actions: ['edit', 'view'],
      buttons: [
        {
          name: 'Salva',
          action: 'salvaRecord',
          show: true,
          param: "edit"
        },
        {
          name: 'Conferma',
          action: 'eliminaRecord',
          show: true,
          param: "delete"
        },
        {
          name: 'Aggiungi',
          action: 'aggiungiRecord',
          show: true,
          param: "add"
        }
      ],
      tabledata: {
        header: ["Fase", "Id Attività", "Titolo Attività", "Descrizione Fase", "Data Inizio", "Data Fine", "Totale Spese"],
        rows: [],
        colors: true    //attributo per gestire le icone colorate
      },
      colIds1: [
            "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']",
            "['progetto']['cronoProgramma'][*]['idAttivita']",
            "['progetto']['cronoProgramma'][*]['titoloAttivita']",
            "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['descrizione']",
            "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataInizio']",
            "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['dataFine']",
            "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['totaleSpese']",
            "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['stato']",
      ],
      colIds2: [
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['spesa']",
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['da']",
        "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['spese'][*]['a']"
      ],
      tagColIds: [
          "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['fase']",
          "['progetto']['cronoProgramma'][*]['idAttivita']",
      ],
      position: [],
      scheda : {},
      mappa:{}
    };
  },

  mounted() {
    this.extractData();
  },

  methods: {

    changeRows(dataFromModal) {
        let indexes;
        let defaultPath;
        let row;

        const actionFromChange = dataFromModal;
        const index = parseInt(actionFromChange.index);
        const regex = /\b\d+\b(?!.*\b\d+\b)/;

        switch(actionFromChange.action) {
          case 'delete':
            row = actionFromChange.data.content2;

            for(let key of Object.keys(row)) {
                const lastElementIndex = parseInt(regex.exec(key)[0]);
                if(lastElementIndex !== index) {
                  continue;
                }
                actionFromChange.data.conf[key].data.deleted = true;
                actionFromChange.data.conf[key].data.edited = true;
                actionFromChange.data.conf[key].data.oldValue = actionFromChange.data.content2[key].value;
                // costruisce le informazioni aggiuntive per il tab invio
                if(!actionFromChange.data.conf[key].data.editTag) {
                  mapUtils.createEditTag(this.incomingData.schedaMonitoraggio, this.tagColIds, actionFromChange.data.conf[key], key);
                }
            }
            break;
          case 'add':
            defaultPath = Object.keys(actionFromChange.data.content)[0]; // WARNING assicurarsi di prendere il path più interno possibile
            indexes = [];
            for(let m of defaultPath.match(/(\[(\d+)\])/g)) { // costruisce un array di indici da sostituire
              indexes.push(m.replace('[','').replace(']',''));
            }
            
            for(let path of this.colIds2) { // per ogni campo da aggiungere nella nuova riga, sostituisce gli indici trovati
              let iof = path.indexOf('*');
              let jj = 0;
              while(iof !== -1 && jj < indexes.length) {
                let index = indexes[jj];
                path = path.replace("*", index);
                jj++;
                iof = path.indexOf('*');
              }
              let newId = path.replace("*", actionFromChange.index); // sostituisce l'ultimo indice con quello della nuova riga

              let newConf = mapUtils.cloneConf(newId, this.incomingData.schedaMonitoraggio.dataEntryConfiguration); // clona la configurazione
              newConf.data.added = true;
              newConf.data.edited = true;
              newConf.data.editTag ="";
              // costruisce le informazioni aggiuntive per il tab invio
              for(let path of this.tagColIds) {
                let iof = path.indexOf('*');
                let jj = 0;
                while(iof !== -1 && jj < indexes.length) {
                  let index = indexes[jj];
                  path = path.replace("*", index);
                  jj++;
                  iof = path.indexOf('*');
                }

                let value = jpath('$'+path, this.incomingData.schedaMonitoraggio)[0];
                let label = mapUtils.getMapElement(this.incomingData.schedaMonitoraggio.dataEntryConfiguration, path).config.label;
                newConf.data.editTag += label + ': ' + value + '->';
              }
              actionFromChange.data.conf[newId] = newConf;
              let newValue = mapUtils.cloneValue(newId, newConf); // clona il valore
              actionFromChange.data.content2[newId] = newValue;
            }
            break;
          default:
            console.log('azione non gestita', actionFromChange.action);
            break;
        }
        // BUG #13735: workaround per evitare che le date indietreggino di un giorno:
        // se le date sono state modificate, allora bootstrap vue le fa diventare di tipo Date
        // in questo caso aggiungo 12 ore per essere sicuro che il giorno non diminuisca
        [...Object.entries(actionFromChange.data.content),
          ... Object.entries(actionFromChange.data.content2)].forEach(item => {
          if(item[1].value instanceof Date) {
            item[1].value = addHours(item[1].value, 12);
          }
        });
        this.editFormData = actionFromChange.data;
    },

    aggiornaRiga(actionFromModal) {
        this.showEditForm = false;
        this.$bvModal.hide('modalForm1-' + this.name);
        let clonedSchedaMonitoraggio = tool.genericFunctions.cloneObject(this.incomingData.schedaMonitoraggio);
        const mappa = clonedSchedaMonitoraggio.dataEntryConfiguration;
        for(let [key, field] of Object.entries(actionFromModal.content)) {
          let removeConf = false;
          let updatedConfiguration = tool.genericFunctions.cloneObject(actionFromModal.conf[key]);
          if(updatedConfiguration.config.readonly)
            continue;

          // controllo sul main tab
          let mainTab = updatedConfiguration.config.tab;
          let currentTab = this.$parent.title;
          if(currentTab && mainTab && currentTab !== mainTab) { // WARNING i nomi dei tab devono essere consistenti col tabellone
            console.log('skip edit (not in main tab)) for field: ', updatedConfiguration.config.label);
            continue;
          }

          const mapElement = mapUtils.getMapElement(mappa, key);
          let updatedValue = utils.unformatOutput(mapElement, field.value);
          /* // TASK #13750: commentato per consentire la persistenza delle note sempre
          if(updatedValue == undefined) {
            // nessuna azione se il nuovo valore è undefined
            continue;
          }*/

          let parent = jpath({resultType: 'parent'}, '$'+key, clonedSchedaMonitoraggio)[0];
          if(!parent) {
            console.error('TODO non gestito', key);
            continue;
          }
          let fieldToUpdate = jpath('$'+key+'~', clonedSchedaMonitoraggio)[0];
          if(parent[fieldToUpdate] !== updatedValue){ // se il campo è stato modificato controllo la configurazione
            if(!updatedConfiguration.data.edited) {
              updatedConfiguration.data.edited = true; // se è la prima modifica setto il flag edited e salvo il valore originale
              updatedConfiguration.data.oldValue = parent[fieldToUpdate];
              updatedConfiguration.data.editTag = mapUtils.createEditTag1(mappa, key, this.tabName);
            } else {
              if(updatedValue == updatedConfiguration.data.oldValue) {// warning: solo == e non === altrimenti problemi di typeof
                // se il valore era già stato modificato
                // e stiamo riportando il valore all'originale
                // ripristino i valori all'originale e cancello la nota
                updatedConfiguration.data.oldValue = null;
                updatedConfiguration.data.edited = false;
                // TASK #13750: le note non vengono mai resettate
                // updatedConfiguration.data.note = '';
                //  se l'elemento era parte di un array, rimuovo la sua configurazione 
                if(updatedConfiguration.config.path.match(/(\[(\d+)\])/)) {
                    // non aggiorno la configurazione
                    removeConf = true;
                    // TASK #13750: non rimuovo la configurazione per via delle note
                    // delete clonedSchedaMonitoraggio.dataEntryConfiguration[key];
                }
              }
            }

            parent[fieldToUpdate] = updatedValue; // setto il nuovo valore

            // check substancial
            if(updatedConfiguration.config.canBeSubstancial) {
              if(!removeConf) {
                clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione prima del controllo substancial
              }
              updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedSchedaMonitoraggio);
            }

            if(!removeConf) // se l'elemento di configurazione è presente
              clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione aggiornata
          }
          /* // TASK #13750: le note vengono sempre persistite
          else {
              // aggiorno comunque la nota a prescindere dalla modifica
              // if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
              if(!clonedSchedaMonitoraggio.dataEntryConfiguration[key]) {
                  clonedSchedaMonitoraggio.dataEntryConfiguration[key] = mapUtils.cloneConf(key, mappa);
              }
              
          }*/
          // TASK #13750: le note vengono sempre persistite
          if(!clonedSchedaMonitoraggio.dataEntryConfiguration[key]) {
            clonedSchedaMonitoraggio.dataEntryConfiguration[key] = mapUtils.cloneConf(key, mappa);
          }
          clonedSchedaMonitoraggio.dataEntryConfiguration[key].data.note = updatedConfiguration.data.note;
        }

        // gestione spese
        // for(let i in actionFromModal.content2.rows) {
          // let row = actionFromModal.content2.rows[i];
        for(let [key, field] of Object.entries(actionFromModal.content2)) {
            let removeConf = false;
            let updatedConfiguration = tool.genericFunctions.cloneObject(actionFromModal.conf[key]);
            if(updatedConfiguration.config.readonly)
              continue;

            if(updatedConfiguration.data.deleted) {
              clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // per le righe cancellate aggiorno solo la configurazione
              continue;
            }
            
            const mapElement = mapUtils.getMapElement(mappa, key);
            let updatedValue = utils.unformatOutput(mapElement, field.value);
            /* // TASK #13750: commentato per consentire la persistenza delle note sempre
            if(updatedValue == undefined) {
              // nessuna azione se il nuovo valore è undefined
              continue;
            }
            */

            const element = jpath({resultType: 'all'}, '$'+key, clonedSchedaMonitoraggio)[0];
            let parent, fieldToUpdate2;
            if(element == undefined || element == null) {
              const res = mapUtils.createElementFromScratch(key, clonedSchedaMonitoraggio, clonedSchedaMonitoraggio.dataEntryConfiguration);
              parent = res.parent;
              fieldToUpdate2 = res.fieldName;
              clonedSchedaMonitoraggio = res.scheda;
            } else {
              parent = element.parent;
              fieldToUpdate2 = element.parentProperty;
            }
            
            if(!clonedSchedaMonitoraggio.dataEntryConfiguration[key]) {
              const mapEl = mapUtils.getMapElement(clonedSchedaMonitoraggio.dataEntryConfiguration, key);
              clonedSchedaMonitoraggio.dataEntryConfiguration[key] = mapEl;
            }

            if(parent[fieldToUpdate2] !== updatedValue){ // se il campo è stato modificato controllo la configurazione
              // check substancial
              const data = updatedConfiguration.data;
              if(updatedConfiguration.config.canBeSubstancial && !data.added && !data.deleted) {
                clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione prima del controllo substancial
                updatedConfiguration.data.isSubstancial = validation.substancialValidation(key, clonedSchedaMonitoraggio);
              }

              if(!updatedConfiguration.data.edited) {
                updatedConfiguration.data.edited = true; // se è la prima modifica setto il flag edited e salvo il valore originale
                updatedConfiguration.data.oldValue = parent[fieldToUpdate2];
                updatedConfiguration.data.editTag = mapUtils.createEditTag1(mappa, key, this.tabName);
              } else {
                if(updatedValue == updatedConfiguration.data.oldValue) {// warning: solo == e non === altrimenti problemi di typeof
                  
                  // se il valore era già stato modificato
                  // e stiamo riportando il valore all'originale
                  // ripristino i valori all'originale e cancello la nota
                  updatedConfiguration.data.oldValue = null;
                  updatedConfiguration.data.edited = false;          
                  // TASK #13750: le note non vengono mai resettate
                  // updatedConfiguration.data.note = '';
                  //  se l'elemento era parte di un array, rimuovo la sua configurazione 
                  if(updatedConfiguration.config.path.match(/(\[(\d+)\])/)) {
                      // non aggiorno la configurazione
                      removeConf = true;
                      // TASK #13750: non rimuovo la configurazione per via delle note
                      // delete clonedSchedaMonitoraggio.dataEntryConfiguration[key];
                  }
                }
              }

              // costruisce le informazioni aggiuntive per il tab invio
              if(actionFromModal.conf[key].data.edited && !actionFromModal.conf[key].data.editTag) {
                mapUtils.createEditTag(this.incomingData.schedaMonitoraggio, this.tagColIds, actionFromModal.conf[key], key);
              }

              parent[fieldToUpdate2] = updatedValue; // setto il nuovo valore
              if(!removeConf) // se l'elemento è presente
                clonedSchedaMonitoraggio.dataEntryConfiguration[key] = updatedConfiguration; // configurazione aggiornata
            }
            else {
              // aggiorno comunque la nota a prescindere dalla modifica
              // if(updatedConfiguration.data.edited || updatedConfiguration.data.added)
              
            }
            // TASK #13750: le note vengono sempre persistite
            if(!clonedSchedaMonitoraggio.dataEntryConfiguration[key]) {
              clonedSchedaMonitoraggio.dataEntryConfiguration[key] = mapUtils.cloneConf(key, mappa);
            }
            clonedSchedaMonitoraggio.dataEntryConfiguration[key].data.note = updatedConfiguration.data.note;
          }
        // }

        this.salvaSchedaToStore(clonedSchedaMonitoraggio);
        this.extractData();
    },
    convertiRiga(inputItem) {
      const data = {
        header : ['Campo', 'Valore'],
        rows: []
      };
      if(!inputItem){
        console.error("convertiRiga: NULL param passed!?");
        return data;
      }
      for(let keyv in inputItem.content) {
          const mapElement = inputItem.conf[keyv];
          let row = {
              content: {
                Campo : mapElement.config.label,
                Valore : inputItem.content[keyv]
              },
              conf: {
                [keyv]: mapElement
              }
          };
          data.rows.push(row);
      }
      return data;
    },
    doAction(actionToDo, item) {
        this.scheda = this.incomingData.schedaMonitoraggio;
        switch(actionToDo) {
          case 'view':
            this.modalData = this.convertiRiga(item);
            this.modalData2 = this.extractSpese2(item);
            this.showModal = true;
            this.modalHeader = "Visualizza Cronoprogramma";
            this.buttons[0].show=false;
            this.buttons[1].show=false;
            this.buttons[2].show=false;
            break;
          case 'edit': {
              let clonedCont2 = tool.genericFunctions.cloneObject(item.content2);
              let clonedConf = tool.genericFunctions.cloneObject(item.conf);
              this.editFormData = {content:{}, content2:clonedCont2, conf: clonedConf};
              
              for(let [key, field] of Object.entries(item.content)) {
                let value = tool.genericFunctions.cloneObject(field);
                let obj = {'value': value, 'id': key};
                this.editFormData.content[key] = obj;
              }
            this.modalHeader = "Modifica Cronoprogramma";
            this.buttons[0].show=true;
            this.buttons[1].show=false;
            this.buttons[2].show=false;
            this.showEditForm = true;
            this.$bvModal.show('modalForm1-' + this.name);
            break;
          }
        }
    },
    setupHeader() {
      // setup header
      this.tabledata.header = [];
      for(const colId of this.colIds1) {
          let mapElement = this.mappa[colId];
          if(!mapElement) {
              console.error('no config, get default one for ', colId);
              mapElement = this.$getDefaultConfig();
              mapElement.config.path = colId;
          }
          // // compute field visibility
          // if(mapElement.config.hiddenRule) {
          //   const hidden = visibility.computeHidden(colId, scheda, mapElement);
          //   if(hidden)
          //     continue;
          // }
          const headerColumn = {
              key: colId,
              label: mapElement.config.label,
              tdAttr: this.tooltip,
              sortable: true,
              formatter: this.formatter,
              tdClass: this.getTdClass
          }
          this.tabledata.header.push(headerColumn);
      }
      this.tabledata.header.push({ key: 'azione', label: 'Azione'});
      this.sortBy = this.tabledata.header[1].key;
    },
    formatter(value, key) {
      const starId = mapUtils.getGenericArrayKey(key);
      const mapElement = this.mappa[starId];
      if(!mapElement) {
        
        return value;
      }
      return utils.formatOutput(mapElement, value);
    },
    extractData() {
      if(!this.incomingData){
          console.log("cronoProgramma: Invalid inputData!?");
          return;
      }
      const inputData = tool.genericFunctions.cloneObject(this.incomingData.schedaMonitoraggio);

      


      let totaleSpeseCronoProgramma = 0;
      this.viewOnlyMode(this.incomingData, this.actions);
      let rows = [];
      //ciclo per "AttivitaProgetto.java"
      if(!inputData.progetto.cronoProgramma)
        return; 
      const mappa = inputData.dataEntryConfiguration;
      this.mappa= mappa;
      this.setupHeader();
      const generic_id_totale_spese = "['progetto']['cronoProgramma'][*]['dettaglioFase'][*]['totaleSpese']"
      const mapSpese = mappa[generic_id_totale_spese];


      for(let j = 0; j < inputData.progetto.cronoProgramma.length; j++){

        let idAtt = inputData.progetto.cronoProgramma[j].idAttivita;
        const titolo = inputData.progetto.cronoProgramma[j].titoloAttivita;
        if(!inputData.progetto.cronoProgramma[j].dettaglioFase)
          continue;

        //ciclo per "AttivitaDettaglioFase.java"
        for(let i=0; i< inputData.progetto.cronoProgramma[j].dettaglioFase.length; i++){

          let item = inputData.progetto.cronoProgramma[j].dettaglioFase[i];
          let row = {};
          

            let displayedColIds = [];
            //definisco gli id delle colonne da visualizzare
            displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['fase']");
            displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['idAttivita']");
            displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['titoloAttivita']");
            displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['descrizione']");

            if(inputData.dataEntryConfiguration[displayedColIds[3]] && inputData.dataEntryConfiguration[displayedColIds[3]].data.deleted) {
                continue;
            }

            displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['dataInizio']");
            displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['dataFine']");
            displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['totaleSpese']");
            displayedColIds.push("['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['stato']");

            let cont2 = {};
            let spesaTotale = 0;
            if(item.spese && item.spese.length>0){ //se sono presenti spesi inserisco anche le chiavi valorizzate
              for(let s=0; s<item.spese.length; s++){
                let idSpesa = "['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['spese']["+s+"]['spesa']";
                let idDa = "['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['spese']["+s+"]['da']";
                let idA = "['progetto']['cronoProgramma']["+j+"]['dettaglioFase']["+i+"]['spese']["+s+"]['a']";
                displayedColIds.push(idSpesa);
                displayedColIds.push(idDa);
                displayedColIds.push(idA);
              
                cont2[idSpesa] = {'value': item.spese[s].spesa, 'id': idSpesa};
                cont2[idDa] = {'value': item.spese[s].da, 'id': idDa};
                cont2[idA] = {'value': item.spese[s].a, 'id': idA};

                let valueSpesa = parseFloat(item.spese[s].spesa);
                if(!isNaN(valueSpesa)) { // se il valore numerico è valido
                  if(!mappa[idSpesa] || !mappa[idSpesa].data.deleted) {
                    // se non c'è configurazione per questa spesa o la spesa non è stata eliminata, allora aggiungo al totale
                    spesaTotale += valueSpesa;
                  }
                }
              }
            }
            let myConf = mapUtils.costruisciConfigurazione(displayedColIds, mappa);

            // BUG #10686: aggiornamento totale spese su scheda
            const formatted_value = utils.formatOutput(mapSpese, spesaTotale);
            inputData.progetto.cronoProgramma[j].dettaglioFase[i].totaleSpese = formatted_value;

            row = {
                  content: {},
                  position : [j,i], //servirà dopo per capire quale riga si sta aggiornando 
                  // content2: item.spese,
                  content2: cont2,
                  conf : myConf,
                  azione: this.actions,
            };

            //scrivo i campi con l'ID : valore
            row.content[displayedColIds[0]] = item.fase;
            row.content[displayedColIds[1]] = idAtt;
            row.content[displayedColIds[2]] = titolo;
            row.content[displayedColIds[3]] = item.descrizione;
            row.content[displayedColIds[4]] = item.dataInizio;
            row.content[displayedColIds[5]] = item.dataFine;
            row.content[displayedColIds[6]] = formatted_value;
            row.content[displayedColIds[7]] = this.calcolaStato(item.dataInizio, item.dataFine);

            totaleSpeseCronoProgramma += tool.roundFloat2Decs(spesaTotale);


            if(inputData.dataEntryConfiguration) {
            // caso di riga di fase eliminata o aggiunta
            for(let ind = 0; ind < 7; ind++) {
              const key = displayedColIds[ind];
              const mapEl = inputData.dataEntryConfiguration[key];
              
              if(mapEl && (mapEl.data.deleted && !mapEl.data.added)) {
                row.deleted = true;
                break;
              } else if(mapEl && (mapEl.data.added && !mapEl.data.deleted)) {
                row.added = true;
                break;
              }
            }
            // caso di edit di riga, oppure qualunque modifica alle spese
            for(const realId of displayedColIds) {

              const mapEl = inputData.dataEntryConfiguration[realId];
              if(mapEl && mapEl.data.edited) {
                row.edited = true;
              }
            }
          }

          rows.push(row);
        }
      }
      this.totaleSpeseCronoProgrammaEuro = utils.formatCurrency(totaleSpeseCronoProgramma);
      this.tabledata.rows = rows;
      this.tabledata.colors = true;


      // BUG #10686:aggiornamento del totale delle spese nella scheda sullo store
      // this.salvaSchedaToStore(inputData);
    },
    tooltip(value, key, item) {
        if(item.note[key])
            return {title: 'nota: ' + item.note[key]};
        return {};
    },
    getTdClass(value, key, item) {
      if(item.added || item.deleted)
        return '';
      for(const ele in item.conf) {
        const key1 = mapUtils.getGenericArrayKey(ele);
        if(key1 === key && item.conf[ele].data.edited) {
          return 'modifiedCell';
        }
      }
    },
    calcolaTotaleSpese(arraySpese){
      let totale = 0; //valore di default
      if(!arraySpese)
        return totale;

      for (const spesaObj of arraySpese){
        let value = 0;
        if(spesaObj.spesa) {
          let temp = parseFloat(spesaObj.spesa);
          if(!isNaN(temp)) {
            value = temp;
          }
        }
        totale = totale + tool.roundFloat2Decs(value);
      }
      return tool.roundFloat2Decs(totale);
    },


    getSpeseArray(returnData) {

      let retValue = [];

      if(!returnData.content2 || !returnData.conf)
        return retValue;

      const regex = /\b\d+\b(?!.*\b\d+\b)/;
      for(const [key, obj] of Object.entries(returnData.content2)) {
        const index = regex.exec(key)[0];

        const conf = returnData.conf[key];
        let deleted = (conf && conf.data.deleted) ? true : false;

        if(!retValue[index])
          retValue.splice(index, 1, {index: index, content: {}, conf: {}, deleted: deleted});
        const label = conf.config.label;
        retValue[index].content[label] = obj.value;
        retValue[index].conf[key] = conf;
      }
  
    
      return retValue;
      
    },
    extractSpese2(riga){
      if (!riga || !riga.content2){ // || !riga.content2.rows
        console.error("extractSpese2: INVALID riga passed=",riga);
        return;
      }

      const rows = this.getSpeseArray(riga);
      
     return {
        header : ["Spesa", "Dal", "Al"],
        rows : rows,
        // conf: spesaConf
      };
    },


    //in base alle date inserite ritorna uno stato dell'ENUM definito in StatoFaseAttivita.java
    calcolaStato(dataInizioFase, dataFineFase){
      //formato date in ingresso aaaa-mm-gg
      //console.log("fase INIZIO="+dataInizioFase+" FINE="+dataFineFase);
      let dataInizioTrimestreCorrente = this.calcolaInizioTrimestre();
      let dataFineTrimestreCorrente = this.calcolaFineTrimestre();
      if(!dataInizioFase || !dataFineFase)
        return "Da realizzare";     //futuro

      if(tool.isDate(dataInizioFase)) {
        let dataStringa = dataInizioFase.getFullYear()+"-"+(dataInizioFase.getMonth()+1)+"-"+dataInizioFase.getDate();
        dataInizioFase = dataStringa;
      }

      if(tool.isDate(dataFineFase)) {
        let dataStringa = dataFineFase.getFullYear()+"-"+(dataFineFase.getMonth()+1)+"-"+dataFineFase.getDate();
        dataFineFase = dataStringa;
      }

      dataInizioFase = dataInizioFase.substring(0,10);
      dataFineFase = dataFineFase.substring(0,10);

      if(dataInizioFase > dataFineTrimestreCorrente) {
        return "Da realizzare";     //futuro
      } else if (dataFineFase < dataInizioTrimestreCorrente){
        return "Realizzato";        //passato
      } else if (dataFineFase > dataInizioTrimestreCorrente && dataFineFase < dataFineTrimestreCorrente) {
        return "In realizzazione";  //si completa ora
      } else {
        return "Realizzato in parte";
      }
    },

    calcolaInizioTrimestre(){
      let date = new Date();
        
      let dataStringa = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();

      if (date.getMonth()<=2) {
        dataStringa = dataStringa.substring(0,5)+"01-01"
      } else if (date.getMonth()>2 && date.getMonth()<=5) {
        dataStringa = dataStringa.substring(0,5)+"04-01"
      } else if (date.getMonth()>5 && date.getMonth()<=8) {
        dataStringa = dataStringa.substring(0,5)+"07-01"
      } else {
        dataStringa = dataStringa.substring(0,5)+"10-01"
      }
      
      return dataStringa;
    },

    calcolaFineTrimestre(){
      let date = new Date();
      let dataStringa = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
      
      if (date.getMonth()<=2) {
        dataStringa = dataStringa.substring(0,5)+"03-31"
      } else if (date.getMonth()>2 && date.getMonth()<=5) {
        dataStringa = dataStringa.substring(0,5)+"06-30"
      } else if (date.getMonth()>5 && date.getMonth()<=8) {
        dataStringa = dataStringa.substring(0,5)+"09-30"
      } else {
        dataStringa = dataStringa.substring(0,5)+"12-31"
      }
      
      return dataStringa;
    }
   
  }
};
</script>
