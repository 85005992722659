import Vue from 'vue'
import App from './App.vue'
import store from './store/store.js' // import state management module
import Router from './router.js'
import { setHours } from 'date-fns'
import 'intersection-observer'

// keycloack integration
import Keycloak from 'keycloak-js'
import jwt_decode from 'jwt-decode'

const keycloakInitOptions = {
    url: process.env.VUE_APP_KEYCLOAK_URI,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    onLoad: 'login-required',
    enableLogging: false,
}
const keycloakClient = Keycloak(keycloakInitOptions)
    // making keycloak client global in the Vue instance
Vue.prototype._keycloakClient = keycloakClient

// importing and enabling bootstrap
// comment the following 4 lines to disable it site-wide
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

// STORY #15031: gli gli import di bootstrap sono inseriti tutti dentro _variables.scss,
// a sua volta importato da _grafica.scss
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
import './styles/_grafica.scss'

Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVue);

import HttpPlugin from '@/plugins/http.plugin';
Vue.use(HttpPlugin);

import UtilsPlugin from '@/plugins/utils.plugin';
Vue.use(UtilsPlugin);

import FilesPlugin from '@/plugins/files.plugin';
Vue.use(FilesPlugin);

import ControlPlugin from '@/plugins/control.plugin';
Vue.use(ControlPlugin);


Vue.config.productionTip = false

// setting app-wide variables
Vue.prototype._isProduction = process.env.NODE_ENV === 'production'
Vue.prototype._appTitle = process.env.VUE_APP_TITLE; // this is read from vue.config.js
Vue.prototype._appVersion = process.env.VUE_APP_VERSION; // this is read from vue.config.js

// notification system
import Notifications from 'vue-notification'
Vue.use(Notifications)

// font-awesome 5 (svg + js)
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core'
import { // solid icons (icone piene)
    faUser,
    faFolder as fasFolder,
    faBell,
    faWallet,
    faClipboardList,
    faChevronRight,
    faCommentDots,
    faSignOutAlt,
    faMinusCircle,
    faThumbsUp,
    faThumbsDown,
    faSync,
    faCheck,
    faTimes,
    faPencilAlt,
    faExclamationCircle,
    faTimesCircle,
    faUserCircle as fasUserCircle,
    faCircle,
    faFileDownload as fasFileDownload,
    faScroll as fasScroll,
    faAward as fasAward,
    faUserEdit as fasUserEdit,
    faSearchDollar,
    faHandHoldingUsd,
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import { // regular icons (icone solo bordo)
    faEnvelope,
    faFolder as farFolder,
    faCalendarAlt,
    faEye,
    faEdit,
    faHandPaper,
    faTrashAlt,
    faPlusSquare,
    faWindowClose,
    faUserCircle as farUserCircle
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
faLibrary.add(
    // import solid
    faUser, fasFolder, faBell,
    faWallet, faClipboardList, faChevronRight,
    faCommentDots, faSignOutAlt, faMinusCircle,
    faThumbsUp, faThumbsDown, faPencilAlt, faExclamationCircle, faTimesCircle, fasUserCircle, faCircle, 
    faSearchDollar, faHandHoldingUsd, faExclamationTriangle,
    faSync, faCheck, faTimes, fasFileDownload, fasScroll, fasAward,
    // now import regular
    faEnvelope, farFolder, faCalendarAlt,
    faEye, faEdit, faHandPaper, faTrashAlt, faPlusSquare, faWindowClose, farUserCircle,
    fasUserEdit
)
Vue.component('fa-icon', FontAwesomeIcon)


// expand javascript capabilities... and your possibilities... lol
String.prototype.replaceAll = function(search, replacement) {
    let target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

// filters
Vue.filter('firstUpperCase', function(value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
})

Vue.filter('lowercase', function(value) {
    if (!value) return '';
    value = value.toString();
    return value.toLowerCase();
});


Vue.filter('noUnderscores', function(value) {
    if (!value) return '';
    value = value.toString();
    return value.replaceAll("_", " ")
})

Vue.filter('dateIT', function(value) {
    if (!value) return '';
    let d = new Date(value)
    setHours(d, 12);
    // if (d.toLocaleDateString().includes('nvalid')) { // workaround per IE11
    //     let temp = value.replace('+0000', 'Z');
    //     d = new Date(temp);
    // }
    let year = d.getFullYear()
    let month = (d.getMonth() + 1).toString()
    let day = d.getDate()
    return (day + '/' + month + '/' + year)
})

Vue.filter('numberIT', function(num) {
    if(!num || isNaN(num)){
        return '€ 0';
    }
    
    return (
        '€ ' + num
        .toFixed(2) // always two decimal digits
        .replace('.', ',') // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    ); // use . as a separator
})


// configuration of login via keycloak
keycloakClient.init({ onLoad: keycloakInitOptions.onLoad }).success((auth) => {
    if (!auth) {
        window.location.reload(); // ricarica la pagina
    } else {
        console.log("Autenticato con successo");
    }

    // saving tokens and userinfo
    sessionStorage.setItem("access_token", keycloakClient.token);
    sessionStorage.setItem("refresh_token", keycloakClient.refreshToken);

    const userInfo = jwt_decode(keycloakClient.token);
    sessionStorage.setItem('sessionData', JSON.stringify({
        userInfo: userInfo
    }));
    // setto il profilo iniziale: è il primo dei profili disponibili, se esistono,
    // oppure la concatenazione del gruppo e ruolo di default dell'utente
    const currentProfile = sessionStorage.getItem('currentProfile');
    
    if(!currentProfile) {
        
        let newProfile;
        if(userInfo.profiles) {
            const profiles = userInfo.profiles;//.split(',');
            if(profiles && profiles.length) {
                const profileSplit = profiles[0].split(':');
                newProfile = { group: profileSplit[0].toString(), role: profileSplit[1].toString() };
            }
        } else {
            newProfile = { group: userInfo.groups[0], role: userInfo.roles[0]};
        }
        console.log('new profile',newProfile);
        sessionStorage.setItem('currentProfile', JSON.stringify(newProfile));
    } else {
        console.log('old profile', currentProfile);
        
        if(!userInfo.profiles) {
            // se c'è stato un nuovo login con utente diverso e questo non ha profili, si resetta
            // il currentProfile all'unico profilo esistente, cioè quello del nuovo utente
            const newProfile = { group: userInfo.groups[0], role: userInfo.roles[0]};
            sessionStorage.setItem('currentProfile', JSON.stringify(newProfile));
            console.log('set unique profile', newProfile);
        } else if(userInfo.profiles && userInfo.profiles.length) {
            // se il nuovo login ha diversi profili, controllo se il currentProfile è previsto tra questi
            // se sì, lascio il current profile
            // se no, allora sovrascrivo il current profile con gruppo e ruolo dell'utente,
            // che dovrebbe coincidere per convenzione con il primo profilo disponibile.
            const foundProfile = userInfo.profiles.find(profile => (profile.group === currentProfile.group && profile.role === currentProfile.role));
            if(!foundProfile) {
                const newProfile = { group: userInfo.groups[0], role: userInfo.roles[0]};
                sessionStorage.setItem('currentProfile', JSON.stringify(newProfile));
                console.log('old profile non found in user profiles, set default one', newProfile);
            }
        }
    }
    new Vue({
        router: Router,
        store,
        render: h => h(App),
    }).$mount('#app');

    setTimeout(() => {
        keycloakClient.updateToken(70).success((refreshed) => {
            if (refreshed) {
                console.log('Token refreshed' + refreshed);
            } else {
                console.log('Token not refreshed, valid for ' +
                    Math.round(keycloakClient.tokenParsed.exp + keycloakClient.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
        }).error(() => {
            console.error('Failed to refresh token');
        });

    }, 60000)

}).error((err) => {
    console.error("Errore generico: Autenticazione Fallita", err);
});