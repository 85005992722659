<template>
  <div>
    <b-container>
      <Collapse
        :name="'Campi obbligatori non valorizzati (' + this.campiObbligatori.length + ')'">
        <b-table striped hover
          :id="'mandatory_exbody'"
          :fields="headerTableMandatory"
          :items="campiObbligatori">
        </b-table>
      </Collapse>
      <Collapse :name= numeroAllegati>
          <fa-icon v-if="this.loading" :icon="['fas', 'sync']" spin/>
          <FileUpload
            v-if="!this.loading"
            :idTablePdf="'invio_fileUpload'"
            :rid="this.$route.params.idExecutingBody"
            :ownerProcessStatus="this.schedaComplete.taskInfo.taskDefinitionKey"
            :actions= actionsArray
            :entity="this.entity"
            :availableFiles = this.availableFiles
            :documentTypes = this.availableDocumentTypes
            :riferimento = this.riferimento
            name= "files"
            @doRefresh="getFilesPerRid($route.params.idExecutingBody)"/>
      </Collapse>
      <Collapse
        :icon_parere="true"
        :updatedScheda="updatedScheda"
        :name="'Parere Ufficio Economico Finanziario'">
        <b-table striped hover
          :id="'invio_parere_uff_econom'"
          :items="getParere"
          :fields="fields">
        </b-table>
      </Collapse>
      <Collapse :name="'Note (' + allNotes.length + ')'">
          <br />
            <TableNotes
              v-if="allNotes.length > 0"
              :id="'note_exbody'" 
              :notes="allNotes">
            </TableNotes>
          <h4 v-else>
            Nessuna nota da mostrare
          </h4>
        </Collapse>
        <b-row>
          <div v-if="this.allegatiMancanti.length > 0">
            <ul>
              <span :class=" missingFileBlockButton===true ? 'text-danger' : 'text-warning' ">Attenzione: Mancano ancora allegati </span>
              <li v-for="missingFiles in this.allegatiMancanti" :key="missingFiles.type" style="list-style-type: circle;">
                <span class="text-center"> {{ missingFiles.type }}</span>
              </li>
            </ul>
          </div>
        </b-row>
        <b-row class="mt-2" v-if="blockNote">
          <b-col />
          <b-col class="text-right">
            <label for="textarea-note">Nota:</label>
          </b-col>
          <b-col sm="8">
            <b-form-textarea
              v-model="note"
              id="textarea-note"
              size="lg"
              placeholder="Inserire qui le note"
            />
          </b-col>
          <b-col sm="2">
            <b-button :id="'cancelNote_EB'" variant="outline-secondary" @click="cancelNote()"
              >Cancella Nota</b-button
            >
          </b-col>
          <b-col />
        </b-row>
        <div class="pulsanti-invio">
            <b-button
                :disabled="disableButton"
                variant="secondary"
                class="bt1"
                @click="preparePdf">Export PDF</b-button>
            <b-button
                v-if="actionsArray.indexOf('save') !== -1"
                :id="'save_EB'"
                size="lg"
                variant="primary"
                class="mx-2"
                :disabled="disableButton"
                @click="saveSchedaProgetto('draft')"
            >
                Salva in Bozza
            </b-button>
            <!-- silenziamento if tutti i campi compilati  -->
            <b-button
              v-if="actionsArray.indexOf('send') !== -1"
              :id="'send_EB'"
              size="lg"
              variant="success"
              class="mx-2"
              :disabled="disableButton || missingFileBlockButton || disableBtnSend"
              @click="setValueModal('complete')"
              >
              Invia Intervento
            </b-button>
            <b-button
                v-if="actionsArray.indexOf('reject') !== -1"
                :id="'reject_EB'"
                size="lg"
                variant="danger"
                class="mx-2"
                :disabled="disableButton"
                @click="setValueModal('reject')"
            >
                Rifiuta
            </b-button>
        </div>
        <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import tools from '@/helpers/tools.js'
import notify from "@/helpers/notifications.js"
import Collapse from "@/components/collapse.vue"
import TableNotes from "../bandi/tableNotes.vue"
import FileUpload from '@/components/FileUpload.vue'
import endpoints from '@/components/endpoints.vue'
import { JSONPath as jpath } from 'jsonpath-plus'
import helper_pdf from "@/helpers/exportPdf.js"

export default {
  name: "invio",
  props: {
    tabName: String,
    updatedScheda: Boolean,
    //informazioni di riferimento per tutti i file caricati
    riferimento: { type: Object, required: true }
  },
  components: {
    Collapse,
    TableNotes,
    FileUpload
  },
  watch: {
    tabName: function() {
      if(this.tabName === this.name) {
        this.valoriObbligatoriMancanti();
      }
    }
  },
  data() {
    return {
      name: "invio",
      loading: false,
       modal1Map:{
            complete:{
                title:"Conferma Intervento", text:"Conferma di voler Inviare l'Intervento ? "
            },
            
            reject:{
                title:"Rifiuta Intervento", text:"Conferma di voler Rifiutare l'Intervento?"
            },
             
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",
      disableButton: false,
      actionsArray: [],
      schedaComplete: {},
      entity: "executingBody",
      allNotes: [],
      note:"",
      allegatiDisponibili: 0,
      allegatiMancanti: [],
      allegatiObbligatoriMancanti: [],
      availableFiles: [],
      availableDocumentTypes: [],
      headerTableMandatory: [
          { key: "campo", label: "Campo" },
          { key: "tab", label: "Tab" }
        ],
      campiObbligatori: [],
      fields: [
        { key: "parere", label: "Parere" },
        { key: "note", label: "Note" }
      ],
    };
  },
  computed: {
    ...mapGetters({
      getSchedaExecuting: "executingBody/getSchedaProgetto",
      getSchedaComplete: "executingBody/getScheda",
    }),
    missingFileBlockButton(){
      //console.log("Block button? ", this.allegatiObbligatoriMancanti.length > 0)
      return this.allegatiObbligatoriMancanti.length > 0
    },
    numeroAllegati() {
      if(this.loading===true)
        return "Allegati ( ... ) "
      else
        return "Allegati (" + this.allegatiDisponibili + ")";
    },
    getParere() {
      // Recupero la scheda Corrente
      let scheda = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idExecutingBody,});
      if(scheda && scheda.schedaProgetto && scheda.schedaProgetto.executingBody) {
        let mappingParere = this.mappingParere(scheda.schedaProgetto.executingBody.parere)
        let note = scheda.schedaProgetto.executingBody.note
        return [{parere: mappingParere, note: note}]
      }
      return [{parere: null, note: null}]
    },
    blockNote() {
     return  this.actionsArray.indexOf("edit_in_list_executing") !== -1;
    },
    disableBtnSend() { 
      return this.campiObbligatori.length > 0
    },
  },
  created() {
    this.schedaComplete = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idExecutingBody})
    const schedaProgetto = this.schedaComplete.schedaProgetto;
      this.note = schedaProgetto.notaTemporanea;
      this.allNotes = schedaProgetto.note && schedaProgetto.note.length > 0
        ? schedaProgetto.note
        : [];
    this.actionsArray = this.$executingBodyGetActions(this.schedaComplete);
    this.refreshAttachmentsInfo();
  },
  mounted() {
    this.getFilesPerRid(this.$route.params.idExecutingBody);
    this.valoriObbligatoriMancanti();
  },
  methods: {
    preparePdf() {
        let scheda = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idExecutingBody});
        let typeScheda = 'ExecutingBody';
        let optPdf = helper_pdf.handleNamingPdf(scheda, typeScheda);
        this.$emit('buildPdf', optPdf);
    },
    valoriObbligatoriMancanti() {
      let infoUser = this.$getUserInfo()
      const roles = infoUser.roles;
      let campiMancanti = []
      let campoMancante = {campo:"", tab:""}
      for (let role of roles) {
        if(role.match('UfficioAcquisti-UfficioAcquisti')) {
          let scheda = this.getSchedaExecuting({idSchedaProgetto: this.$route.params.idExecutingBody})
          let idMandatoryUabst = [
            { key: "['progetto']['titoloProgetto']", label: "Titolo del Progetto"},
            { key: "['progetto']['costoProgetto']", label: "Costo del Progetto"},
            { key: "['progetto']['costoProgettoIVA']", label: "Iva del Progetto"}
            ]
          idMandatoryUabst.forEach(elem => {
            let id = elem.key
            const values = jpath('$'+id, scheda);
            campoMancante = {}
            if(values.length === 0 || values[0] == null || values[0] === "") {
              campoMancante.campo = elem.label
              campoMancante.tab = 'Anagrafica Intervento'
              campiMancanti.push(campoMancante)
            }
          })
        } else if(role.match("UfficioEconomicoFinanziario-Operatore")) {
            let scheda = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idExecutingBody})
            let schedaExecuting = scheda.schedaProgetto.executingBody
            let parere = schedaExecuting.parere;
            let note = schedaExecuting.note;
            if(parere == null){
              let obj = {campo:"", tab:""};
              obj.campo = "Parere"
              obj.tab = 'Parere Ufficio Economico Finanziario'
              campiMancanti.push(obj)
            }
            if(note == null){
              let obj = {campo:"", tab:""};
              obj.campo = "Note"
              obj.tab = 'Parere Ufficio Economico Finanziario'
              campiMancanti.push(obj)
            }
          }
      }
      this.campiObbligatori = campiMancanti;
    },
    //Metodi per Allegati
      getFilesPerRid(rid) {
        let uri = endpoints.postFilesBasePath + rid
        this.$get(uri)
            .then(
                resp =>  {
                  console.log('getFilesPerRid response: ', resp)
                  this.availableFiles = this.$availableFilesFilter( resp, this.$getUserInfo(), this.getSchedaComplete({idSchedaProgetto: this.$route.params.idExecutingBody,}).infoAllegati[this.entity])
                  this.currentMandatoryFiles();
                  this.refreshAttachmentsInfo()
                  this.loading = false
                }
            ).catch(error => {
                console.error("Error getFilesPerRid: ", error);
                notify.error(
                  notify.strings.error,
                  notify.strings.getFilesPerRid
                )
                this.loading = false
            });
        this.loading = true
      },
      currentMandatoryFiles(){
        let schedaCurrent = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idExecutingBody})
        let clonedScheda = tools.genericFunctions.cloneObject(schedaCurrent);
        let allegatiMandatory = this.$calculateMandatoryFiles(this.entity, this.$getUserInfo().roles[0], clonedScheda, this.availableFiles);

        this.$updateMandatoryFilesInStore(allegatiMandatory, this.entity, this.$route.params.idExecutingBody)
        this.$updateAvailableLengthInStore(this.availableFiles.length, this.entity, this.$route.params.idExecutingBody);
        this.availableDocumentTypes = this.$availableDocumentTypes(clonedScheda.infoAllegati[this.entity], this.schedaComplete.taskInfo.taskDefinitionKey)
      },
    refreshAttachmentsInfo() {
      const schedaCurrent = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idExecutingBody})
      this.allegatiDisponibili = schedaCurrent.infoAllegati.allegatiDisponibili[this.entity];
      const temp = schedaCurrent.infoAllegati.allegatiMancanti[this.entity];
      let clonedLista = tools.genericFunctions.cloneObject(temp);
      // aggiunge la dicitura di obbligatorietà
      const descrizione = ' (Obbligatorio)'
      clonedLista.forEach(element => {
        if (element.mandatory===true)
          element.type = element.type + descrizione
      });
      this.allegatiMancanti = clonedLista;
      this.allegatiObbligatoriMancanti = this.allegatiMancanti.filter(doc => {return doc.mandatory === true;});
    },    
    mappingParere(parereCurrent) {
      let parere = null
      if(parereCurrent == null) {
        return null
      }
      parere = parereCurrent === 'true' || parereCurrent === true ? 'Positivo' : 'Negativo'
      return parere
    },
    async saveSchedaProgetto(salvataggio) {
     
      this.disableButton = true;
      let schedaProgetto = this.getSchedaExecuting({
        idSchedaProgetto: this.$route.params.idExecutingBody,
      });
      let clonedScheda = tools.genericFunctions.cloneObject(schedaProgetto);
      clonedScheda.notaTemporanea = this.note
      schedaProgetto = clonedScheda;

      //salvataggio del relativo cronoprog
      //POST Body inglobato schedaProgetto + schedaCronoprog
      let entity = { 
        progetto: schedaProgetto,
        cronoprog: null
      }

      

      await this.$store
        .dispatch("executingBody/saveSchedaExecuting", {
          schedaProgetto: entity,
          salvataggio: salvataggio
        })
        .then(
          () => {
            notify.success(
              notify.strings.success,
              notify.strings.saveProgettoOk
            );
            if (salvataggio === "complete" || salvataggio === "reject") {
              this.$router.push({ name: "secure" });
            }
          },
          (error) => {
            console.log("save Scheda Progetto Error: ", error);
            notify.error(
              notify.strings.error,
              notify.strings.errorSaveProgetto
            );
          }
        );
      this.disableButton = false;
    },

    setValueModal(action){
      this.setModal=action;
      this.$bvModal.show('modalCompletaInvia');
    },

    actionModal(){
         switch (this.setModal) {
                    case "complete":
                        return this.saveSchedaProgetto('complete');
                    case "reject":
                      return this.saveSchedaProgetto('reject');
                    default:
                        console.log("Case false");
                    }
    },

    cancelNote(){
      this.note = " ";
    },
  }
}
</script>

<style> 
.pulsanti-invio{
    padding-bottom: 1.7rem;
}
</style>