<template>
  <div>
    <b-container v-if="loadComplete">
      <Collapse :name="'Campi obbligatori non valorizzati (' + this.tabledata.rows.length + ')'"> 
          <b-table-lite
          :id="'convenzione_mandatory'"
          thead-class="head"
          :items="tabledata.rows"
          :fields="tabledata.header"
        >
        </b-table-lite>
      </Collapse>
      <Collapse :name="'Note (' + allNotes.length + ')'">
        <br />
        <TableNotes :id="'note_convenzione'" v-if="allNotes.length > 0" :notes="allNotes"> </TableNotes>
        <h4 v-else>
          Nessuna nota da mostrare
        </h4>
      </Collapse>
      <Collapse
        v-if="isVisibleReservedNote"
        :name="'Elenco Note Interne (' + fillNoteRiservate.length + ')'">
        <br />
        <div class="note" v-if="blockNote">
          <br>
            <b-form-textarea
              id="textAreaNoteRiservate"
              :disabled="disableButton"
              v-model="noteRiservate"
              placeholder="Inserire qui le note interne"/>
        </div>
        <div class="pulsanti-invio">
          <b-button :id="'cancelNoteRiservate_progetto'"
            v-if="blockNote"
            class="mx-3 btnReservedNote"
            :disabled="disableButton"
            variant="danger"
            @click="resetReservedNote()"
            >Cancella Nota Interna</b-button>
        </div>
        <TableNotes
          :id="'note_riservate_progetto'"
          v-if="fillNoteRiservate.length > 0" :notes="fillNoteRiservate">
        </TableNotes>
        <h4 v-else>
          Nessuna nota da mostrare
        </h4>
      </Collapse>
      <Collapse :name= numeroAllegati> 
          <FileUpload
          :idTablePdf="'invio_fileUpload'"
          :rid="this.$route.params.idProgetto"
          :ownerProcessStatus="this.schedaComplete.taskInfo.taskDefinitionKey"
          :actions= actionsArray
          :entity="this.entity"
          :availableFiles = this.availableFiles
          :documentTypes = this.availableDocumentTypes
          :riferimento = this.riferimento
          name= "files"
          @doRefresh="getFilesPerRid($route.params.idProgetto)"/>
      </Collapse>
      <br />
      <b-row>
        <b-table v-if="this.modalitaDiAccesso === 'Assegnazione diretta' "
          :id="'summary_project'"
          primary-key="id"
          emptyText="Nessun dato disponibile"
          :items="projdata.rows"
          :fields="projdata.header"
          show-empty
          responsive>
          <template v-slot:table-busy>
            <div class="text-center">
              <strong>Non disponibile</strong>
            </div>
          </template>
          <template v-slot:cell(Azioni)="data">
            <b-button
              :id="namingIdIcon(x, data.index + 1)"
              variant="outline-primary"
              size="sm"
              :key="x"
              :title="mappingTooltip(x)"
              :disabled="enableParere"
              v-for="x in data.value"
              @click="doAction(x, data)"
            >
              <fa-icon
                v-if="x === 'accept_isf_oper'"
                :icon="['fas', 'check']"
                class="selector-icons"
              />
              <fa-icon
                v-if="x === 'reject_isf_oper'"
                :icon="['fas', 'times-circle']"
                class="selector-icons"
              />
            </b-button>
          </template>
        </b-table>
        <div v-if="this.noSelection">
          <ul>
          <span :class="'text-danger'">Attenzione: Ci sono ancora progetti per cui non è stato espresso un giudizio</span>
          </ul>
        </div>
      </b-row>  
      <b-row>
        <div v-if="this.allegatiMancanti.length > 0">
          <ul>
            <span :class=" missingFileBlockButton===true ? 'text-danger' : 'text-warning' ">Attenzione: Mancano ancora allegati </span>
            <li v-for="missingFiles in this.allegatiMancanti" :key="missingFiles.type" style="list-style-type: circle;">
              <span class="text-center"> {{ missingFiles.type }}</span>
            </li> 
          </ul>
        </div>
      </b-row>
      <b-row class="mt-2" v-if="blockNote">
        <b-col />
        <b-col class="text-right">
          <label for="textarea-note">Nota:</label>
        </b-col>
        <b-col sm="8">
          <b-form-textarea
            v-model="note"
            id="textarea-note"
            size="lg"
            placeholder="Inserire qui le note"
            :disabled="disableButton"
          />
        </b-col>
        <b-col sm="2">
          <b-button :id="'cancelNote_convenzione'" variant="outline-secondary" :disabled="disableButton" @click="cancelNote()"
            >Cancella Nota</b-button
          >
        </b-col>
        <b-col />
      </b-row>
      <b-row>
        <b-col class="text-right">
           <b-button
            :id="'export_pdf'"
            size="lg"
            variant="secondary"
            class="mx-2"
            :disabled="disableButton"
            @click="preparePdf">
            Export PDF
          </b-button>
          <b-button
            v-if="actionsArray.indexOf('save') !== -1"
            :id="'save_convenzione'"
            size="lg"
            variant="primary"
            class="mx-2"
            :disabled="disableButton"
            @click="saveSchedaProgetto('draft')"
          >
            Salva in Bozza
          </b-button>
          <!-- silenziamento if tutti i campi compilati  -->
          <template>
            <b-button
              v-if="actionsArray.indexOf('send') !== -1"
              :id="'send_convenzione'"
              size="lg"
              variant="success"
              class="mx-2"
              :disabled="disableButton || missingFileBlockButton || disableBtnSend || noSelection"
              @click="setValueModal('complete')"
            >
              Salva e Invia
            </b-button>
          </template>
          <b-button
            v-if="actionsArray.indexOf('reject') !== -1"
            :id="'reject_convenzione'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="disableButton"
            @click="setValueModal('reject')"
          >
            Rifiuta
          </b-button>
        </b-col>
      </b-row>
       <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
    </b-container>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import tool from '@/helpers/tools.js'
import utils from '@/helpers/utils.js'
import Collapse from "@/components/collapse.vue";
import FileUpload from '@/components/FileUpload.vue'
import TableNotes from "../bandi/tableNotes.vue";
import notify from "@/helpers/notifications.js";
import { JSONPath as jpath } from 'jsonpath-plus';
import endpoints from '@/components/endpoints.vue'
import helper_pdf from "@/helpers/exportPdf.js"

export default {
  name: "invio",
  props: {
    tabName: String,
    updatedScheda: Boolean,
    //informazioni di riferimento per tutti i file caricati
    riferimento: { type: Object, required: true }
    
  },
  data() {
    return {
      loadComplete: false,
      noteRiservate: '',
      entity: "convenzione",
      schedaProgetto: {},
      schedaComplete: {},
      richiestoParere: true,
      tabellone:{},
      allNotes: [],
      note:"",
      allegatiDisponibili: 0,
      availableDocumentTypes: [],
      allegatiMancanti: [],
      allegatiObbligatoriMancanti: [],
      availableFiles: [],
      showModal: false,
       modal1Map:{
            complete:{
                title:"Conferma Intervento", text:"Conferma di voler Inviare ? "
            },
            
            reject:{
                title:"Rifiuta Intervento", text:"Conferma di voler Rifiutare ?"
            },
             
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",
      ammesso: null, //Parere definitivo di ISF
      modalitaDiAccesso: "",
       tabledata: {
        header: [
          {
            key: "Campo",
          },
          {
            key: "Tab",
          },
        ],
        rows: [],
      },
      disableButton: false,
      actionsArray: [],
      projdata: {
        header: [
          "idProgetto",
          "titoloProgetto",
          "beneficiario",
          { 
            key:"ammesso",
            value:"Ammesso da ISF",
            formatter: this.ammessoFormatter,
            class: 'text-center'
          },
          {
            key:"punteggioValutazione",
            formatter: this.punteggioFormatter,
            class: 'text-center'
          },
          {
            key:"dataValutazione",
            formatter:this.dataValutazioneFormatter,
          },
          "Azioni"
          ],
        rows: [],
      },
    };
  },
  components: {
    Collapse,
    TableNotes,
    FileUpload,
  },
  watch: {
    updatedScheda: function() {
      this.campiMandatoryMancanti();
    }
  },
  mounted() {
    this.schedaProgetto = this.getSchedaProgetto({
      idSchedaProgetto: this.$route.params.idProgetto,
    });
    this.schedaComplete = this.getSchedaComplete({
      idSchedaProgetto: this.$route.params.idProgetto,
    })
    this.tabellone = this.getTabelloneProgetto('convenzione');
    if(this.schedaProgetto.note != null || this.schedaProgetto.notaTemporanea != null) {
      this.allNotes = this.schedaProgetto.note && this.schedaProgetto.note.length > 0 ? this.schedaProgetto.note : [];
      this.note = this.schedaProgetto.notaTemporanea
    }
    this.actionsArray = this.$getActionsPerProgettoConvenzione(
      this.getSchedaComplete({
        idSchedaProgetto: this.$route.params.idProgetto,
      })
    );

    this.fillNoteRiservateInBozza();

    //Controllo se è già stato espresso un parere 
    const reqParere = this.schedaComplete.schedaProgetto.erogazione && this.schedaComplete.schedaProgetto.erogazione.ammesso ? this.schedaComplete.schedaProgetto.erogazione.ammesso : null
    if ( reqParere != undefined && reqParere != null)
    {
      this.ammesso = reqParere
      this.richiestoParere = false
    }
    else
    {
      this.richiestoParere = true
    }
      
    this.modalitaDiAccesso = this.schedaComplete.schedaProgetto.progetto.modalitaDiAccesso
    // console.log("schedaProgetto:",this.schedaComplete.schedaProgetto.progetto)

    // console.log(this.actionsArray)
    this.campiMandatoryMancanti();
    this.visualizzaDatiProgetto(this.schedaProgetto);
    this.getFilesPerRid(this.$route.params.idProgetto);
    this.loadComplete = true;
  },
  computed: {
    ...mapGetters({
      getTabelloneProgetto: "configuration/getTabellone",
      getSchedaProgetto: "progetto/getSchedaProgetto",
      getSchedaCronoprog: 'cronoprog/getSchedaComplete',
      getSchedaComplete: "progetto/getScheda"
    }),
    isVisibleReservedNote() {
      const userInfo = this.$getUserInfo();
      const group = userInfo.groups[0];
      return group.includes("MINT");
    },
    fillNoteRiservate: function() {
      const schedaCurrent = this.schedaComplete;
      if(!schedaCurrent || !schedaCurrent.schedaNoteRiservate)
          return [];
      return schedaCurrent.schedaNoteRiservate?.content?.noteRiservate || [];
    },
    enableParere(){
      return !(this.modalitaDiAccesso === "Assegnazione diretta" &&
          this.schedaComplete.taskInfo.taskDefinitionKey === "Progetto_decreto_UfficioGestione-Operatore" &&
          this.actionsArray.indexOf('send') !== -1)
    },   
    noSelection(){
      // Il parere non viene controllato per progetti non in 'Assegnazione diretta', perchè già precedentemente espresso
      if (this.actionsArray.indexOf('save') === -1 || this.ammesso==true || this.ammesso==false || this.modalitaDiAccesso != 'Assegnazione diretta')
        return false;
      return true;
    },
    // Questa funzione controlla l'effettivo fill dei campi obbligatory solo quando:
    // 1)Il ruolo loggato è Beneficiario Operatore
    // 2)Lo stato della scheda è 'Convenzione da firmare'
    checkMandatory() {
      let checkMandatoryActive = false
      let scheda = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto})
      checkMandatoryActive = this.$isActiveCheckMandatory(scheda)
      console.info("checkMandatoryActive: ", checkMandatoryActive)
      return checkMandatoryActive
    },
    disableBtnSend() {
      return this.tabledata.rows.length > 0
      // WARNING decommentare solo per debug
      // return false;
    },
    numeroAllegati() {
      return "Allegati (" + this.allegatiDisponibili + ")";
    },
    missingFileBlockButton(){
      return this.allegatiObbligatoriMancanti.length > 0
    },
    blockNote() {
     return  this.actionsArray.indexOf("edit_in_list_sovvenzione") !== -1 || this.actionsArray.indexOf("edit_in_list_progetti") !== -1
    },
  },
  methods: {
    fillNoteRiservateInBozza(){
        this.noteRiservate = this.schedaProgetto.notaTemporaneaRiservata;
    },
    resetReservedNote() {
      this.noteRiservate = ""
    },
    // Costruzione id per Icone (Azioni nelle liste)
    namingIdIcon(action, index) {
      return this.$builtIdDinamically(action, index)
    },
    preparePdf() {
      let scheda = this.getSchedaComplete({ idSchedaProgetto: this.$route.params.idProgetto })
      let contentScheda = scheda.schedaProgetto
      let typeScheda = 'Convenzione'
      let optPdf = helper_pdf.handleNamingPdf(contentScheda, typeScheda)
      this.$emit('buildPdf', optPdf)
    },
    //Metodi per Allegati
      getFilesPerRid(rid) {
        let uri = endpoints.postFilesBasePath + rid
        this.$get(uri)
            .then(
                resp =>  {
                  console.log('getFilesPerRid response: ', resp)
                  const scheda = this.getSchedaComplete({idSchedaProgetto: this.$route.params.idProgetto})
                  if(scheda && scheda.infoAllegati && scheda.infoAllegati[this.entity]){
                    this.availableFiles = this.$availableFilesFilter(resp, this.$getUserInfo(), scheda.infoAllegati[this.entity])
                    this.currentMandatoryFiles();
                    this.refreshAttachmentsInfo()
                  }
                  else
                    throw new Error("Scheda Malformata o InfoAllegati Mancante")
                }
            ).catch(error => {
                console.error("Error getFilesPerRid: ", error.message);
                notify.error(
                  notify.strings.error,
                  notify.strings.getFilesPerRid
                )
            });
      },
      currentMandatoryFiles(){
        let schedaCurrent =  this.getSchedaComplete({ idSchedaProgetto: this.$route.params.idProgetto })
        let clonedScheda = tool.genericFunctions.cloneObject(schedaCurrent);
        let allegatiMandatory = this.$calculateMandatoryFiles(this.entity, this.$getUserInfo().roles[0], clonedScheda, this.availableFiles);

        this.$updateMandatoryFilesInStore(allegatiMandatory, this.entity, this.$route.params.idProgetto)
        this.$updateAvailableLengthInStore(this.availableFiles.length, this.entity, this.$route.params.idProgetto);
        this.availableDocumentTypes = this.$availableDocumentTypes(clonedScheda.infoAllegati[this.entity], schedaCurrent.taskInfo.taskDefinitionKey)
      },
    refreshAttachmentsInfo() {
      const schedaCurrent = this.getSchedaComplete({ idSchedaProgetto: this.$route.params.idProgetto })
      this.allegatiDisponibili = schedaCurrent.infoAllegati.allegatiDisponibili[this.entity];
      const temp = schedaCurrent.infoAllegati.allegatiMancanti[this.entity];
      let clonedLista = tool.genericFunctions.cloneObject(temp);
      // aggiunge la dicitura di obbligatorietà
      const descrizione = ' (Obbligatorio)'
      clonedLista.forEach(element => {
        if (element.mandatory===true)
          element.type = element.type + descrizione
      });
      this.allegatiMancanti = clonedLista;
      this.allegatiObbligatoriMancanti = this.allegatiMancanti.filter(doc => {return doc.mandatory === true;});
    },
    campiMandatoryMancanti() {
      this.tabledata.rows = []
      if(!this.checkMandatory)
        return;
      // La verifica dei campi mandatory mancanti risiede in un controllo di questi nella scheda Progetto
      // Di fatto ogni cambiamento con GenericComponent in schedaConvenzione.vue si riflette nello store di Progetti
      let scheda = this.getSchedaProgetto({idSchedaProgetto: this.$route.params.idProgetto});
      
      let mandatoryFields = [];

      for(const [key, value] of Object.entries(this.tabellone)) {
        if(value.config.mandatory)
          mandatoryFields.push(key);
      }

      for(const id of mandatoryFields) {
        const values = jpath("$" + id, scheda);
        if (values.length === 0) console.log('valore non trovato in scheda: ', id);
        else if (values.length === 1) {
            let valueInScheda = values[0]
            if(valueInScheda === "" || valueInScheda == null) {
              this.tabledata.rows.push({
                Campo:  this.tabellone[id].config.label,
                Tab: this.tabellone[id].config.tab
              })
            }
        } else {
          console.error("is an array?", id);
        }
      }
    },
    cancelNote(){
      this.note = " ";
    },
    async saveSchedaProgetto(salvataggio) {
     
      this.disableButton = true;
      let schedaComplete = this.getSchedaComplete({
        idSchedaProgetto: this.$route.params.idProgetto,
      });
      let schedaProgetto = schedaComplete.schedaProgetto
      let clonedScheda = tool.genericFunctions.cloneObject(schedaProgetto);
      clonedScheda.notaTemporanea = this.note;
      clonedScheda.notaTemporaneaRiservata = this.noteRiservate;
      schedaProgetto = clonedScheda;

      //salvataggio del relativo cronoprog
      //POST Body inglobato schedaProgetto + schedaCronoprog
      let entity = { 
        progetto: schedaProgetto,
        cronoprog: null
      }

      console.log("salvo l'oggetto progetto+cronoprog:", entity);

      await this.$store
        .dispatch("progetto/saveSchedaProgetto", {
          schedaProgetto: entity,
          salvataggio: salvataggio,
          rifiutaAmmissione: !this.ammesso
        })
        .then(
          () => {
            notify.success(
              notify.strings.success,
              notify.strings.saveProgettoOk
            );
            if (salvataggio === "complete" || salvataggio === "reject") {
              this.$router.push({ name: "secure" });
            }
          },
          (error) => {
            console.log("save Scheda Progetto Error: ", error);
            notify.error(
              notify.strings.error,
              notify.strings.errorSaveProgetto
            );
          }
        );
      this.disableButton = false;
    },

     setValueModal(action){
      this.setModal=action;
      this.$bvModal.show('modalCompletaInvia');
    },

    actionModal(){
         switch (this.setModal) {
                    case "complete":
                        return this.saveSchedaProgetto('complete');
                    case "reject":
                      return this.saveSchedaProgetto('reject');
                    default:
                        console.log("Case false");
                    }
    },

    //Popola la tabella
    visualizzaDatiProgetto(i){
      // copio l'array eliminando il toggle graduatoria superfluo
      let arr = []; 
      this.projdata.rows = [];
      if (this.ammesso == null || this.ammesso == undefined){
          arr.push("accept_isf_oper")
          arr.push("reject_isf_oper")
      }
      else if (this.ammesso === true){
          arr.push("reject_isf_oper")
      } else {
          arr.push("accept_isf_oper")
      }
      
      let newRow = {
        idProgetto: i.progetto.idProgetto,
        titoloProgetto: i.progetto.titoloProgetto,
        punteggioValutazione: i.punteggioValutazione,
        beneficiario: i.progetto.beneficiario.beneficiario.denominazione,
        ammesso: i.erogazione && i.erogazione.ammesso ? i.erogazione.ammesso : null,
        dataValutazione: i.dataValutazione,
        Azioni: arr,
      };
      this.projdata.rows.push(newRow);
    },

    mappingTooltip(keyMapping) {
      let objMapping = {
        accept_isf_oper: "Ammetti progetto",
        reject_isf_oper: "Rifiuta progetto",
        view: "Visualizza Dati Progetto",
      };
      let temp = objMapping[keyMapping];
      if (!temp) return "";
      return temp;
    },

    doAction(action, data) {
      if (!data) {
        console.log("scheda progetto vuota", data);
        notify.error(
          notify.strings.error,
          notify.strings.internalErrorPleaseReportAction
        );
        return;
      }
      // ammissione manuale da parte di ISF oper e superiori
      // le stringhe ammetti/rifiuta degli stati
      // devono matchare quelli definiti nell'enum StatoAmmissioneGraduatoria
      switch (action) {
        case "accept_isf_oper":
          data.item.Azioni = [];
          data.item.Azioni.push("reject_isf_oper");
          this.ammesso = true;
          this.setAmmesso(true)
          break;
        case "reject_isf_oper":
          data.item.Azioni = [];
          data.item.Azioni.push("accept_isf_oper");
          this.ammesso = false;
          this.setAmmesso(false)
          break;
        
        default:
          console.error("Unknown action!", action);
          break;
      }
      //Ricostruisco la tabella 
      this.visualizzaDatiProgetto(
            this.getSchedaProgetto({
            idSchedaProgetto: this.$route.params.idProgetto,
            })
      )
    },

    //Setta la scelta nello store e nel be
    setAmmesso(ammesso){   
      //this.ammesso = ammesso
      this.$store.dispatch(
        "progetto/updateStatoProgettoAmmesso",
        {
          idSchedaProgetto: this.$route.params.idProgetto,
          ammesso: ammesso
        }
      ).then(
        () => {
          this.getFilesPerRid(this.$route.params.idProgetto);
        }
      )
    },
    ammessoFormatter(ammesso){
      if(ammesso === true)
        return "Ammesso da ISF"
      else if (ammesso === false)
        return "Non ammesso"
      else 
        return "Ammesso d'ufficio"
    },
    punteggioFormatter(value){
      if(value)
        return value
      else
        return "N/D"
    },
    dataValutazioneFormatter(value){
      return utils.formatDate(value)
    },
    getCronoprog(idScheda) {
        return this.getSchedaCronoprog({idScheda: idScheda}).scheda
    },
  },
};
</script>
