import endpoints from "@/components/endpoints.vue";
import Vue from 'vue'

// async ops, call backend APIs here
const actions = {
  retrieveElencoProgetti( context, isFinal ) {
    return Vue.prototype.$get(endpoints.selettoreAss(isFinal))
    .then(
      result => {
        let assessments = {};
        for(const item of result) {
            assessments[item.codiceProgetto] = item
        }
        return assessments;
      },
    )
  },
  retrieveArchivioByCodiceProgetto(context, payload){
    const codiceProgetto = payload.codiceProgetto;
    const final = payload.final;
    return Vue.prototype.$get(endpoints.archivioAss(codiceProgetto, final))
    .then(
      result => {
        let archivio = {};
        for(const item of result) {
            archivio[item.periodo] = item
        }
        return archivio;
      },
      error => {
        console.error("retrieveArchivioByCodiceProgetto error: ", error);
        throw error;
      }
    )
  },
 
}

export default {
  namespaced: true,
  actions
}
