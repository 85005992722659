<template>
  <div>
    <b-table
          :id="idTablePdf"
          :sort-by="sortBy"
          :tbodyTrClass="editedRowClass"
          :items="getItems" :fields="tdata.header">
          <template v-slot:cell(azione)="data">
              <b-button
                  :id="namingIdButton(x, data.index + 1)"
                  variant="outline-primary"
                  :key="x" v-for="x in data.value"
                  size="sm"
                  v-on:click="doAction(x, data.item)">
                  <fa-icon v-if="x === 'edit'" :icon="['far', 'edit']" class="selector-icons"/>
                  <fa-icon v-if="x === 'delete'" :icon="['far', 'trash-alt']" class="selector-icons"/>
                  <fa-icon v-if="x === 'view'" :icon="['far', 'eye']" class="selector-icons"/>
              </b-button>
          </template>
      </b-table>
  </div>
</template>
  
<script>

import mapUtils from '@/helpers/mapUtils.js'
export default {
  props: {
    idTablePdf: String,
    tdata: {
      type: Object,
      required: true
    },
    actions: {
      type:Array,
      default: null
    },
    doAction: Function,
    tabName: String,
    sortBy: String,
    disableColors: Boolean
  },
  data() {
    return {
    };
  },

  computed: {
    getItems() {
      let items = [];
      for(const obj of this.tdata.rows){
          obj.note = {};
          const confset = obj.conf;
          for(const [key, value] of Object.entries(obj.content)) {
              const key1 = mapUtils.getGenericArrayKey(key);
              obj[key1] = value;
              obj.note[key1] = confset[key].data.note;
          }
          items.push(obj);
      }
      return items;
    },
  },

  methods: {
    isEmptyArray(arr) {
      return !arr;
    },
    // Costruzione id per Buttoni (Azioni nel componente)
    namingIdButton(action, index) {
      return this.$builtIdDinamically(this.name, action, index)
    },

    editedRowClass(item) {
      let res = '';
      if(this.disableColors)
        return res;
      if(item.edited) {
        res += 'changeInRow';
      }
      if(item.added && !item.deleted) {
        res += ' addedCell';
      } else if(item.deleted && !item.added) {
          res += ' deletedCell';
      }
      return res;
    },
  }
}
</script>
