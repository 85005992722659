<template>
  <div>
    <Collapse :name="'Campi obbligatori non valorizzati (' + this.tabledata.rows.length + ')'"
      v-if="this.setTabsforBeneficiari">
      <br />
      <h4 v-if="!existMandatoryFields">
        Nessun campo obbligatorio da valorizzare
      </h4>
      <b-table-lite v-else
        :id="'bando_mandatory'"
        thead-class="head"
        :items="this.tabledata.rows"
        :fields="this.tabledata.header"
      >
      </b-table-lite>
    </Collapse>
    <Collapse :name= numeroAllegati>
      <br />
      <div>
        <FileUpload
          :idTablePdf="'invio_fileUpload'"
          :rid="getIdSchedaBando"
          :ownerProcessStatus= getScheda().taskInfo.taskDefinitionKey
          :actions= actionsArray
          :entity= this.entity
          :availableFiles = this.availableFiles
          :documentTypes = this.availableDocumentTypes
          :riferimento = this.riferimento
          name= "files"
          @doRefresh="getFilesPerRid(getIdSchedaBando)"/>
      </div>
    </Collapse>
    <Collapse :name="'Note (' + allNotes.length + ')'"
    v-if="this.setTabsforBeneficiari">
      <br />
      <TableNotes :id="'note_bando'" v-if="allNotes.length > 0" :notes="allNotes"></TableNotes>
      <h4 v-else>
        Nessuna nota da mostrare
      </h4>
    </Collapse>
   
    <b-card
      class="text-center mx-5"
      title="Parere del Referente Strategico / Task Force ICT"
      v-if="this.setTabsforBeneficiari">
      <b-container>
        <b-row>
          <b-col class="text-left">Si richiede il parere della Task Force ICT: </b-col>
          <b-col>
            <b-radio-group
              size="lg"
              :disabled="isRichiestaParereDisabled"
              v-model="richiestoParereICT"
            >
              <b-form-radio value="true">Sì</b-form-radio>
              <b-form-radio value="false">No</b-form-radio>
            </b-radio-group>
          </b-col>
          <b-col cols="4" />
        </b-row>
        <b-row>
          <b-col class="text-left">
            Parere del Referente Strategico / Task Force ICT:
          </b-col>
          <b-col cols="5">
            <b-textarea
              rows="5"
              :disabled="isParereIctDisabled"
              :state="parereIctTextArea"
              id="textarea-parereICT"
              placeholder="Inserire il parere"
              v-model="parereICT"
            />
          </b-col>
          <b-col cols="2" />
        </b-row>
      </b-container>
    </b-card>
    <b-container fluid
      v-if="actionsArray.length > 0 && actionsArray.indexOf('view') === -1">
    <b-row>
       <div v-if="this.allegatiMancanti.length > 0">
        <ul>
          <span :class=" missingFileBlockButton===true ? 'text-danger' : 'text-warning' ">Attenzione: Mancano ancora allegati </span>
          <li v-for="missingFiles in this.allegatiMancanti" :key="missingFiles.type" style="list-style-type: circle;">
            <span class="text-center"> {{ missingFiles.type }}</span>
          </li> 
        </ul>
      </div>
    </b-row>
      <b-row class="mt-2">
        <b-col />
        <b-col class="text-right">
          <label for="textarea-note">Nota:</label>
        </b-col>
        <b-col sm="8">
          <b-form-textarea
            v-model="note"
            :disabled="blockButton"
            id="textarea-note"
            size="lg"
            placeholder="Inserire qui le note"
          />
        </b-col>
        <b-col sm="2">
          <b-button :id="'cancelNote_bando'"
                    variant="outline-secondary" 
                    @click="cancelNote()"
                    :disabled="blockButton">Cancella Nota</b-button>
        </b-col>
        <b-col />
      </b-row>
    </b-container>
    <div v-if="visibilityMessageMissingFields">
       <h3 class="text-center text-danger font-weight-bold">Attenzione: è necessario compilare tutti i campi per poter proseguire</h3>
    </div>
    <b-container>
      <b-row>
        <b-col class="text-right">
          <b-button
            :id="'export_pdf'"
            size="lg"
            variant="secondary"
            class="mx-2"
            :disabled="blockButton"
            @click="preparePdf">
            Export PDF
          </b-button>
          <b-button
            v-if="actionsArray.indexOf('save') !== -1"
            :id="'save_bando'"
            size="lg"
            variant="primary"
            class="mx-2"
            :disabled="blockButton"
            @click="saveSchedaBando('draft')">
              Salva in Bozza
          </b-button>
          <!-- silenziamento if tutti i campi compilati  -->
          <template>
            <b-button
              v-if="actionsArray.indexOf('send') !== -1"
              :id="'send_bando'"
              size="lg"
              variant="success"
              class="mx-2"
              :disabled="existMandatoryFields || blockButton || setVisibilitySaveButtonIct || missingFileBlockButton"
              @click="setValueModal('complete')">
              Salva e Invia
            </b-button>
          </template>
          <b-button
            v-if="actionsArray.indexOf('reject') !== -1"
            :id="'reject_bando'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="blockButton"
            @click="setValueModal('reject')">
              Rifiuta
          </b-button>
          <b-button
            v-if="actionsArray.indexOf('delete') !== -1"
            :id="'delete_bando'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="blockButton"
            @click="setValueModal('delete')">
              Elimina Bando
          </b-button>
          
        </b-col>
      </b-row>
       <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
    </b-container>
    
  </div>
</template>
<script>
import Collapse from "@/components/collapse.vue";
import notify from "@/helpers/notifications.js";
import locale from "@/helpers/locale_it.js";
import TableNotes from "./tableNotes";
import FileUpload from '@/components/FileUpload.vue'
import { mapGetters } from "vuex";
import tool from '@/helpers/tools.js'
import endpoints from '@/components/endpoints.vue'
import helper_pdf from "@/helpers/exportPdf.js"

export default {
  name: "invio",
  props: {
    tabName: String,
    //informazioni di riferimento per tutti i file caricati
    riferimento: { type: Object, required: true }
  },
  watch: {
    tabName: function() {
      if(this.tabName === this.name) {
        this.refreshMandatoryFields();
        this.currentMandatoryFiles();
        this.refreshAttachmentsInfo()
      }
    }
  },
  data() {
    return {
      tabledata: {
        header: [
          {
            key: "Campo",
          },
        ],
        rows: [],
      },
      entity: 'bandi',
      name: 'invio',
      parereObbligatorioIct : false,
      blockButton: false,
       modal1Map:{
            complete:{
                title:"Conferma Bando", text:"Conferma di voler Inviare il Bando ? "
            },
            
            reject:{
                title:"Rifiuta Bando", text:"Conferma di voler Rifiutare il Bando?"
            },
             delete:{
                title:"Cancella Bando", text:"Conferma di voler Cancellare il Bando?"
            },
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",
      richiestoParereICT: "false",
      actionsArray: [],
      parereICT: "",
      note: "",
      allNotes: [],
      showModal: false,
      messages: locale.messages,
      allegatiDisponibili: 0,
      allegatiMancanti: [],
      allegatiObbligatoriMancanti: [],
      availableDocumentTypes: [],
      availableFiles: [],
    };
  },
  components: {
    Collapse,
    TableNotes,
    FileUpload,
    //ModalConfirmation
  },
  created() {
    const scheda = this.getScheda();
    const schedaBando = scheda.schedaBando;
    this.note = schedaBando.notaTemporanea;
    this.allNotes = schedaBando.note && schedaBando.note.length > 0
        ? schedaBando.note
        : [];
    this.actionsArray = this.$getActions(scheda);
    // refresh attachments info
    this.refreshAttachmentsInfo();

    const isParere = schedaBando.richiestoParereICT;
    if (isParere != undefined && isParere != null)
      this.richiestoParereICT = isParere;
    const parere = schedaBando.parereICT;
    if (parere)
      this.parereICT = parere;
    this.refreshMandatoryFields();
  },
  mounted() {
    this.getFilesPerRid(this.getIdSchedaBando);
  },
  computed: {
    ...mapGetters({
      getSchedaBando: "announcements/getSchedaBando",
      getConfigurationSchedaBando:
        "announcements/retrieveConfigurationSchedaBando",
      getCallForProposalFields: "announcements/getCallForProposalFields",
      getAssDirettaFields: "announcements/getAssegnazioneDirettaFields"
    }),
    numeroAllegati() {
      return "Allegati (" + this.allegatiDisponibili + ")";
    },
    visibilityMessageMissingFields() {
      return this.tabledata.rows.length > 0 && this.getScheda().schedaBando.stato !== "Eliminato dall'utente"
    },
    existMandatoryFields() {
      return this.tabledata.rows.length > 0;
    },
    setVisibilitySaveButtonIct() {
      // trim(): serve a non Salvare nel caso di inserimento di soli spazi
      return (this.actionsArray.indexOf("editIctAdvice") !== -1 && !(this.parereICT.trim() !=='' && this.parereICT.length > 0))
    },

    parereIctTextArea() {
      if(this.actionsArray.indexOf("editIctAdvice") === -1)
        return null;
      return this.parereICT.trim() !=='' && this.parereICT.length > 0;
    },

    isRichiestaParereDisabled() {
      return this.actionsArray.indexOf("askIctAdvice") === -1;
    },
    isParereIctDisabled() {
      //block textarea parere ict da isf1appr
      if(this.actionsArray.indexOf("editIctAdvice") === -1) {
        return  true;
      }else {
        return (
          this.actionsArray.indexOf("editIctAdvice") !== -1 &&
          this.richiestoParereICT != "true" &&
          this.richiestoParereICT != true
        );
      }
    },
    getIdSchedaBando() {
      // Recupero l'ID_SCHEDA_BANDO dall'url corrente
      return this.$route.params.idBando;
    },
    missingFileBlockButton(){
      //console.log("Block button? ", this.allegatiObbligatoriMancanti.length > 0)
      return this.allegatiObbligatoriMancanti.length > 0
    },
    setTabsforBeneficiari(){
      let idGroup = this.$getUserInfo().groups[0]
      if( idGroup.match('eneficiari') && this.getScheda( {idSchedaBando : this.getIdSchedaBando} ).taskInfo.taskDefinitionKey.match('ando_inviato_Nessuno-Nessun'))
        return false
      
      return true
    },
  },

  methods: {
    preparePdf() {
      this.updateSchedaBandoInStore()
      let scheda = this.getScheda()
      console.log(scheda)
      let contentScheda = scheda.schedaBando
      let typeScheda = 'Bando'
      let optPdf = helper_pdf.handleNamingPdf(contentScheda, typeScheda)
      this.$emit('buildPdf', optPdf)
    },
    
    //Metodi per Allegati
      getFilesPerRid(rid) {
        let uri = endpoints.postFilesBasePath + rid
        this.$get(uri)
            .then(
                resp =>  {
                  console.log('getFilesPerRid response: ', resp)
                  this.availableFiles = this.$availableFilesFilter(resp, this.$getUserInfo(), this.getScheda().infoAllegati[this.entity])
                  this.currentMandatoryFiles();
                  this.refreshAttachmentsInfo()
                }
            ).catch(error => {
                console.error("Error getFilesPerRid: ", error);
                notify.error(
                  notify.strings.error,
                  notify.strings.getFilesPerRid
                )
            });
      },
      currentMandatoryFiles(){
        let schedaCurrent = this.getScheda( {idSchedaBando : this.getIdSchedaBando} )
        let clonedScheda = tool.genericFunctions.cloneObject(schedaCurrent);
        let allegatiMandatory = this.$calculateMandatoryFiles(this.entity, this.$getUserInfo().roles[0], clonedScheda, this.availableFiles);

        this.$updateMandatoryFilesInStore(allegatiMandatory, this.entity, this.getIdSchedaBando)
        this.$updateAvailableLengthInStore(this.availableFiles.length, this.entity, this.getIdSchedaBando);
        this.availableDocumentTypes = this.$availableDocumentTypes(clonedScheda.infoAllegati[this.entity], this.getScheda().taskInfo.taskDefinitionKey)
      },
    getScheda() {
      return this.getSchedaBando({
        idSchedaBando: this.getIdSchedaBando,
      });
    },
    refreshAttachmentsInfo() {
      const schedaCurrent = this.getScheda();
      this.allegatiDisponibili = schedaCurrent.infoAllegati.allegatiDisponibili[this.entity];
      const temp = schedaCurrent.infoAllegati.allegatiMancanti[this.entity];
      let clonedLista = tool.genericFunctions.cloneObject(temp);
      // aggiunge la dicitura di obbligatorietà
      const descrizione = ' (Obbligatorio)'
      clonedLista.forEach(element => {
        if (element.mandatory===true)
          element.type = element.type + descrizione
      });
      this.allegatiMancanti = clonedLista;
      this.allegatiObbligatoriMancanti = this.allegatiMancanti.filter(doc => {return doc.mandatory === true;});
    },
    getConfSchedaBando(bando) {
      return bando.modalitaDiAccesso === 'Assegnazione diretta' ? this.getAssDirettaFields : this.getCallForProposalFields
    },
    refreshMandatoryFields() {
      this.tabledata.rows = [];
      // aggiungere controllo 
      const scheda = this.getScheda();
      if(!scheda || !scheda.schedaBando) {
        console.error('no scheda in storage', this.getIdSchedaBando, scheda);
        return;
      }

      let bando = scheda.schedaBando.bando;
      let mappa = this.getConfSchedaBando(bando)
      for (const key in bando) {
        if (bando[key] == null || bando[key] == "") {
          mappa.forEach((key_conf) => {
            if(key_conf.indexOf(key) > -1) {
              this.tabledata.rows.push({
                Campo: this.getConfigurationSchedaBando[key_conf].config.label,
              });
            }
          });
        }
      }
    },
    cancelNote(){
      this.note = "";
    },
    
    changeBlockButton() {
      this.blockButton = !this.blockButton
    },

    updateSchedaBandoInStore() {
      const objNotaUpdated = {
        idSchedaBando: this.getIdSchedaBando,
        key: "notaTemporanea",
        value: this.note,
      };
      this.$store.dispatch(
        "announcements/updateSchedaBandoField",
        objNotaUpdated
      );

      if (!this.isRichiestaParereDisabled) {
        const richiestoParereObj = {
          idSchedaBando: this.getIdSchedaBando,
          key: "richiestoParereICT",
          value: this.richiestoParereICT,
        };
        this.$store.dispatch(
          "announcements/updateSchedaBandoField",
          richiestoParereObj
        );
      }

      if (!this.isParereIctDisabled) {
        const parereObj = {
          idSchedaBando: this.getIdSchedaBando,
          key: "parereICT",
          value: this.parereICT,
        };
        this.$store.dispatch("announcements/updateSchedaBandoField", parereObj);
      }
    },
    setValueModal(action){
      this.setModal=action;
      this.$bvModal.show('modalCompletaInvia');
    },
    actionModal(){
         switch (this.setModal) {
                    case "complete":
                        return this.saveSchedaBando('complete');
                    case "reject":
                      return this.saveSchedaBando('reject');
                     case "delete":
                      return this.deleteSchedaBando();  
                    
                    default:
                        console.log("Case false");
                    }
    },
    
    async deleteSchedaBando() {
      this.changeBlockButton();
      let scheda = this.getScheda();
      await this.$store.dispatch(
          "announcements/deleteSchedaBando",
          {idBando: scheda.schedaBando.bando.idBando}
        ).then(
          (response) => {
            console.log('delete scheda response', response)
            notify.success(
                notify.strings.success,
                notify.strings.deleteSchedaOk
              );
              this.$router.push({ name: "secure" });
          },
          (error) => {
            console.log("delete Scheda Bando Error: ", error);
            notify.error(notify.strings.error, notify.strings.errorDeleteScheda);
          }
        )
        this.showModal = false;
        this.changeBlockButton();
    },
    async saveSchedaBando(salvataggio) {
      // Aggiorno lo Store
      this.changeBlockButton();
      this.updateSchedaBandoInStore();
     
      // Recupero la Scheda Bando Corrente
      let scheda = this.getScheda();

      console.log('current scheda:', scheda);
      await this.$store
        .dispatch("announcements/saveSchedaBando", {
          scheda: scheda,
          salvataggio: salvataggio,
        })
        .then(
          () => {
            notify.success(
              notify.strings.success,
              notify.strings.saveCompleteSchedaOk
            );
            if (salvataggio === "complete" || salvataggio === "reject") {
              this.$router.push({ name: "secure" });
            }
          },
          (error) => {
            console.log("save Scheda Bando Error: ", error);
            notify.error(notify.strings.error, notify.strings.errorSaveCompleteScheda);
          }
        );
      this.changeBlockButton();
    }
  }
};
</script>
