<template>
  <div>
      <div class="table-title"> Irregolarità Progetto: {{codiceProgetto}}</div>
      <b-card-body>
         <Collapse :name="'Segnalazione'">
            <GenericList
                :id="'listaSegnalazione'" 
                :title="''"
                :busyTable="isBusy"
                :createEntity="this.canCreateIrregolarita()"
                :headerTable="tableIrregolaritaHeader"
                :dataTable="schedeIrregolarita['Segnalazione']"
                :actionsInRow="this.$getActionsListaIrregolarita"
                :actionsMapping="this.objMapping"
                :blockButton="isBusy"
                :sortBy="'dataInserimento'"
                @clickActionList="doAction"
            />
        </Collapse>
        <!-- <Collapse :name="'Procedimento'">
            <GenericList
                :id="'listaProcedimento'" 
                :title="''"
                :busyTable="isBusy"
                :headerTable="tableIrregolaritaHeader"
                :dataTable="schedeIrregolarita['Procedimento']"
                :actionsInRow="this.$getActionsListaIrregolarita"
                :actionsMapping="this.objMapping"
                :blockButton="isBusy"
                @clickActionList="doAction"/>
        </Collapse>
        <Collapse :name="'Provvedimento'">
            <GenericList
                :id="'listaProvvedimento'" 
                :title="''"
                :busyTable="isBusy"
                :headerTable="tableIrregolaritaHeader"
                :dataTable="schedeIrregolarita['Provvedimento']"
                :actionsInRow="this.$getActionsListaIrregolarita"
                :actionsMapping="this.objMapping"
                :blockButton="isBusy"
                @clickActionList="doAction"/>
        </Collapse> -->
        <b-modal
            id="modalNuovaIrregolarita"
            :title="'Nuova Segnalazione per il progetto ' + codiceProgetto"
            hide-footer
            size="xl"
            no-close-on-backdrop
            scrollable
            centered
        >
        <modalNuovaIrregolarita
            :isBusy="isBusy"
            :codiceProgetto="codiceProgetto"
            :processDefKey="'ProgettoSegnalazione'"
            @closeModalCreateIrregolarita="closeModalCreateIrregolarita"
        />
        </b-modal>
      </b-card-body>
  </div>
</template>

<script>

import Collapse from "@/components/collapse.vue"
import GenericList from "@/components/genericList.vue"
import notify from "@/helpers/notifications.js"
import endpoints from "@/components/endpoints.vue";
import modalNuovaIrregolarita from "./modalNuovaIrregolarita.vue"

export default {
    name: "listaIrregolarita",
    components: {
        GenericList,
        Collapse,
        modalNuovaIrregolarita
    },
    data(){
        return {
            codiceProgetto: "",
            isBusy: true,
            role: "sconosciuto",
            schedeIrregolarita: {
                "Segnalazione":[],
                "Procedimento":[],
                "Provvedimento":[]
            },
            tableIrregolaritaHeader:[
                {
                    key: "titoloControllo",
                    label: "Irregolarità"
                },
                {
                    key: "dataInserimento",
                    formatter: "dateFormatter",
                    label: "Data Inserimento",
                },
                {
                key: "stato",
                label: "Stato"
                },
                {
                key: "Azioni"
                }
            ],
            objMapping: {
                view: "Visualizza Irregolarità",
                edit: "Modifica Irregolarità",
                unclaim: "Rilascia presa in carico",
                claim: "Prendi in carico",
            },
        }
    },
    mounted(){
        this.codiceProgetto = this.$route.params.codiceProgetto
        this.role = this.$getUserRole();
        this.loadControls()
    },
    methods:{
        canCreateIrregolarita(){
            if (this.role.includes("UfficioGestione-Operatore"))
                return "Nuova Segnalazione"
            else
                return ""
        },
        openModalCreateIrregolarita(){
            this.$bvModal.show('modalNuovaIrregolarita')
        },
        closeModalCreateIrregolarita(){
            this.$bvModal.hide('modalNuovaIrregolarita')
        },
        loadControls(){
            const codiceProgetto = this.codiceProgetto;
            if (!codiceProgetto){
                console.error("loadControls: codiceProgetto NULL!");
                return;
            }
        this.$store.dispatch("irregolarita/getIrregolaritaByCodice", 
        { codiceProgetto: codiceProgetto })
        .then( schedeIrregolarita => {
            console.log("Segnalazioni #", schedeIrregolarita["Segnalazione"].length)
            console.log("Procedimenti #", schedeIrregolarita["Procedimento"].length)
            console.log("Provvedimenti #", schedeIrregolarita["Provvedimento"].length)
            this.schedeIrregolarita = schedeIrregolarita
            this.isBusy = false
        })
        .catch( error => {
            this.isBusy = false;
            console.error('Errore store dispatch getIrregolaritaByCodice: ', error.message)
            notify.error(notify.strings.error, notify.strings.errorLoadControlli);
        })

        },
        doAction(action, data){
            //console.log("action: ", action, "data: ", data)
            if (!data) {
                console.log("scheda controllo vuota", data);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                return;
            }

            let idControllo = null;
            if (data && data.item && data.item.idSchedaControllo)
                idControllo = data.item.idSchedaControllo;
            
            const urlc = endpoints.claimControllo+idControllo;
            const urlu = endpoints.unclaimControllo+idControllo;

            switch(action){
                case "createEntity":
                  this.openModalCreateIrregolarita();
                  break;  
                case "view":
                case "edit":
                    this.$router.push({ name: "schedaIrregolarita" , params: { 
                        codiceProgetto: this.codiceProgetto,
                        idControllo: idControllo
                    }})
                    break;
                case "claim":
                    this.claimUnclaim(urlc);
                    break;
                case "unclaim":
                    this.claimUnclaim(urlu);
                    break;
                default:
                    console.error("Unknown action!", action);
                    break;
            }
        },
        claimUnclaim(url){
            this.isBusy = true;    
            this.$post(url)
            .then((result) => {
                if (url.includes('unclaim'))
                    console.log("UNCLAIM success", result);
                else 
                    console.log("CLAIM success", result);
                
                //refresh della pagina
                this.loadControls();
            },
            (error) => {
                console.error("ERRORE: ", error.message);
                if (url.includes('unclaim'))
                    notify.error(notify.strings.error, notify.strings.errorUnclaimScheda);
                else
                    notify.error(notify.strings.error, notify.strings.errorClaimScheda);
                this.isBusy = false;
                throw error;
            });
        }
    }

}
</script>
