<template>
  <div>
    <b-container>
      <b-alert :show="getDeletedFiles.length > 0" variant="warning">
        <div style="text-align: center;">
          <p><strong>ATTENZIONE! Questi sono i files eliminati dalla scheda</strong> (Qualsiasi riferimento a tali files è stato rimosso pure dalla Checklist di propria competenza)</p>
          <ul>
            <li style="list-style: square;" v-for="item in getDeletedFiles" :key="item.fileId">
              {{ item.text }}
            </li>
          </ul>
        </div>     
      </b-alert>
      <Collapse :name="'Campi obbligatori non valorizzati (' + this.obbligatoriTable.rows.length + ')'">
        <b-table-lite
          :id="'mandatory_pref'"
          thead-class="head"
          :items="this.obbligatoriTable.rows"
          :fields="this.obbligatoriTable.header" />
      </Collapse>
      <Collapse :name="'Note (' + note.length + ')'">
        <br />
          <TableNotes :id="'note_pref'" v-if="note.length > 0" :notes="note"> </TableNotes>
          <h4 v-else>
            Nessuna nota da mostrare
          </h4>
      </Collapse>
      <Collapse
        v-if="isVisibleReservedNote()"
        :name="'Elenco Note Interne (' + fillNoteRiservate().length + ')'">
        <br />
        <div class="note" v-if="isSend">
          <br>
            <b-form-textarea
              id="textAreaNoteRiservate"
              :disabled="disableButtons"
              v-model="noteRiservate"
              placeholder="Inserire qui le note interne"/>
        </div>
        <div class="pulsanti-invio">
          <b-button :id="'cancelNoteRiservate_controlli'"
            v-if="isSend"
            class="mx-3 btnReservedNote"
            :disabled="disableButtons"
            variant="danger"
            @click="resetReservedNote()"
            >Cancella Nota Interna</b-button>
        </div>
        <TableNotes
          :id="'note_riservate_pref'"
          v-if="fillNoteRiservate().length > 0" :notes="fillNoteRiservate()">
        </TableNotes>
        <h4 v-else>
          Nessuna nota da mostrare
        </h4>
      </Collapse>
      <b-row class="mt-2" v-if="isSend">
        <b-col />
        <b-col class="text-right">
          <label for="textarea-note">Nota:</label>
        </b-col>
        <b-col sm="8">
          <b-form-textarea
            v-model="notaTemp"
            id="textarea-note"
            :disabled="disableButtons"
            size="lg"
            placeholder="Inserire qui le note"/>
        </b-col>
        <b-col sm="2">
          <b-button :id="'cancelNote_prefinanziamento'" :disabled="disableButtons" variant="outline-secondary" @click="cancelNote()"
            >Cancella Nota</b-button
          >
        </b-col>
        <b-col />
      </b-row>
      <b-row v-if="isEsitoFinaleVisible">
        <b-col cols="3" />
        <b-col><span class="esitoFinale">Esito finale del controllo:</span></b-col>
        <b-col>
          <strong>
            <b-radio-group
              size="lg"
              :disabled="isEsitoFinaleDisabled || disableButtons"
              @change="updateEsitoFinale"
              v-model="esitoFinale">
              <b-form-radio style="font-size: 1.8rem;" value="true">Positivo</b-form-radio>
              <b-form-radio style="font-size: 1.8rem;" value="false">Negativo</b-form-radio>
            </b-radio-group>
          </strong>
        </b-col>
        <b-col cols="2" />
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            :id="'export_pdf'"
            :disabled="disableButtons"
            size="lg"
            variant="secondary"
            class="mx-2"
            @click="preparePdf">
            Export PDF
          </b-button>
          <b-button
            v-if="isSave"
            :id="'save_progetto'"
            :disabled="disableButtons"
            size="lg"
            variant="primary"
            class="mx-2"
            @click="savePrefinanziamento('draft')">
            Salva in Bozza
          </b-button>
          
          <b-button
            v-if="isSend"
            :id="'send_progetto'"
            size="lg"
            variant="success"
            class="mx-2"
            :disabled="disableBtnSend || disableButtons"
            @click="salvataggio('complete')">
            Salva e Invia
          </b-button>
          
          <b-button
            v-if="isReject"
            :id="'reject_progetto'"
            :disabled="disableButtons"
            size="lg"
            variant="danger"
            class="mx-2"
            @click="salvataggio('reject')">
            Rifiuta
          </b-button>
        </b-col>
      </b-row>
      <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
      <b-modal id="modalCompletaPref"
            centered size="lg"
            ok-variant='success' ok-title='Invia'
            cancel-title='Annulla'
            @ok="savePrefinanziamento('complete')">
            <template v-slot:modal-header><h3>Completa prefinanziamento</h3></template>
            <p class="mb-4">Si vuole completare il prefinanziamento?</p>
            <p class="mb-4">Esito finale: {{getEsito}}</p>
      </b-modal>
    </b-container>
  </div>
</template>
<script>

import endpoints from "@/components/endpoints.vue"
import Collapse from "@/components/collapse.vue"
import TableNotes from "../bandi/tableNotes.vue";
import notify from "@/helpers/notifications.js"
import fileHelper from '@/helpers/allegati.js'
import { mapGetters } from "vuex"
import tools from '@/helpers/tools.js'
import { JSONPath as jpath } from 'jsonpath-plus'
import controlliHelper from '@/helpers/controlli.js'
import helper_pdf from "@/helpers/exportPdf.js"

export default {
  name: "InvioPrefinanziamento",
  components: {
    Collapse,
    TableNotes,
  },
  props: {
    tabName: {
      type: String,
      default: 'Sconosciuto'  //NB: deve essere sempre il padre a stabilire un nome univoco di questo componente nel suo contesto
    },
    actions: {
      type: Array,
      default: function () { return ['save', 'send', 'reject'] }
    },
    config: {
      type: Object,
      default: () => {}
    },
    idsToCheck: {
      type: Array,
      default: function () { return [] }
    },
    idsCustomEdit: {
      type: Array,
      default: function () { return [] }
    }
  },
  data() {
    return {
      scheda: {},
      taskInfo: {},
      schedaNoteRiservate: null,
      noteRiservate: "",
      name: "Invio",
      esitoFinale: true,
       modal1Map:{
            complete:{
                title:"Conferma Prefinanziamento", text:"Conferma di voler Inviare il Prefinanziamento? "
            },
            
            reject:{
                title:"Rifiuta Prefinanziamento", text:"Conferma di voler Rifiutare il Prefinanaziamento?"
            },
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",
      mappa: {},
      deletedFiles: [],
      note: [],
      notaTemp: "",
      obbligatoriTable: {
        header: [
          {
            key: "Tab"
          },
          {
            key: "Campo",
            label: "Nome Campo"
          }
        ],
        rows: []
      },
      disableButtons: false,
        
    }
  },
 
  watch: {
    tabName: function() {
      if(this.tabName === this.name) {
        this.deletedFiles = []
        this.refreshMandatoryFields()
      }
    }
  },
  mounted() {
    this.codiceProgetto = this.$route.params.codiceProgetto;
    this.idPrefinanziamento = this.$route.params.idPrefinanziamento
    let scheda = this.getCurrentScheda();
    if(scheda) 
      this.scheda = scheda

    this.taskInfo = scheda.content.taskInfo;

    let esitoFin = scheda.content.esitoFinale
    //Di default, setto l'esito positivo, a meno che non sia esplicitamente richiesto
    this.esitoFinale = esitoFin == "false" || esitoFin == false ? "false" : "true"
    
    if(this.scheda.content.note || this.scheda.content.notaTemporanea) {
      this.note = this.scheda.content.note && this.scheda.content.note.length > 0 ? this.scheda.content.note : [];
      this.notaTemp = this.scheda.content.notaTemporanea.nota
    }
    // NOTE RISERVATE
    this.schedaNoteRiservate = this.getSchedaNoteRiservate();
    if (this.scheda.content?.notaTemporaneaRiservata) {
      this.noteRiservate = this.scheda.content.notaTemporaneaRiservata;
    }
    this.mappa = this.config.content && this.config.content.mappa ? this.config.content.mappa : []
    this.refreshMandatoryFields()
  },
  computed: {
    getEsito() {
      return (this.esitoFinale === true || this.esitoFinale === 'true') ? 'Positivo' : 'Negativo'
    },
    ...mapGetters({
      getScheda: "circuitoFinanziario/getScheda",
      getAllegatiDaCancellare: "circuitoFinanziario/getAllegatiDaCancellare",
      getNoteRiservate: "circuitoFinanziario/getSchedaNoteRiservate",
    }),
    isSave() {
      return this.actions.indexOf("save") !== -1;
    }, 
    isSend(){
      return this.actions.indexOf('send') !== -1;
    },
    isReject(){
      return this.actions.indexOf('reject') !== -1;
    },
    disableBtnSend() {
      return this.obbligatoriTable.rows.length > 0
      // WARNING decommentare solo per debug
      // return false;
    },
    isEsitoFinaleVisible() {
      const role = this.$getUserRole();
      return role.includes('UfficioEconomicoFinanziario');
    },
    isEsitoFinaleDisabled() {
      return  !(this.taskInfo && (
             this.taskInfo.taskDefinitionKey === 'Progetto_prefinanziamento_UfficioEconomicoFinanziario-Operatore'
             || this.taskInfo.taskDefinitionKey === 'Progetto_approvazione_UfficioEconomicoFinanziario-Approvatore'
           )
           && this.isSend
         )
    },
    isLastStep(){

      let role = this.$getUserInfo().roles[0];
      
      // Controllo se l'utente è UfficioEconomicoFinanziario-Approvatore vuol dire che siamo all'ultimo step e quindi faccio il return del true
      if( role != null && role.includes("UfficioEconomicoFinanziario") && role.includes("Approvatore") ){
        return true
      } else {
        return (this.taskInfo.taskDefinitionKey === 'Progetto_approvazione_UfficioEconomicoFinanziario-Approvatore')
      }
      
    },
    retrieveTaskInfo(){
      return this.getCurrentScheda().content.taskInfo
    },
    getDeletedFiles() {
      // SE ho più occorrenze farò in modo di visualizzarne sempre una
      let unique1 = this.deletedFiles.reduce((unique, o) => {
        if(!unique.some(obj => obj.fileId === o.fileId))
          unique.push(o)
        return unique
      },[])
      return unique1
    }
  },
  methods: {
    preparePdf() {
      this.updateEsitoFinale()
      let contentScheda = this.getCurrentScheda().content
      let typeScheda = 'Prefinanziamento'
      let optPdf = helper_pdf.handleNamingPdf(contentScheda, typeScheda)
      this.$emit('buildPdf', optPdf)
    },
    isVisibleReservedNote() {
      let userInfo = this.$getUserInfo();
      let group = userInfo.groups[0];
      return group.includes("MINT") && this.schedaNoteRiservate;
    },
    getSchedaNoteRiservate() {
      if (this.scheda.noteRiservateKey) {
        return this.getNoteRiservate({ noteRiservatekey: this.scheda.noteRiservateKey })
      }
    },
    fillNoteRiservate() {
      return this.schedaNoteRiservate.content?.noteRiservate || [];
    },
    //Setta ad "ATTACHED" lo stato dei file caricati ed elimina quelli cancellati
    async attachFileObjects(){
      const allegati = this.getCurrentScheda().content.allegati
      
        const uri = endpoints.filesBasePathV2
        const toDelete = tools.genericFunctions.cloneObject(this.getAllegatiDaCancellare)
        const list = fileHelper.attachFileReqBuilder( allegati, toDelete )

        if(list && list.trim().length > 0){
          console.log("list: ",list)
          const ownerProcessStatus= this.retrieveTaskInfo.taskDefinitionKey
          this.$put(uri,
          {
            "list" : list,
            "status" : ownerProcessStatus
          }
          ).then(
            (result) => {
              console.log("attached Files:", result)
              this.$store.dispatch("circuitoFinanziario/clearAllegatiDaCancellare")
            },
            (error) => {
                console.error("attachFileObjects: ", error.message);
                notify.error(notify.strings.error, notify.strings.unexpectedLoadStatus(error.message));
                throw error;
            }
          )          
        } 
    },  

    completaTask(action, idScheda, processId, taskId){
      
      if (!action || !idScheda || !processId || !taskId){
        console.error("completaTask: NO params!?");
        throw new Error("completaTask: NO params!?");
      }

      const url= endpoints.completePrefinanziamento;

      let bodyForPost = {
        idScheda: idScheda,
        processId: processId,
        taskId: taskId,
        azione: action
      };
      
      return this.$post(url, bodyForPost)
      .then(
          (respComplete) => {
              console.log("complete ok, return to home ", respComplete);
              notify.success(notify.strings.success, notify.strings.saveCompleteOk);
              this.disableButtons = false;
              this.$router.push({ name: "secure" });
          },
          (error) => {
            console.error("ERRORE: ", error.message);
            notify.error(notify.strings.error, notify.strings.errorCompletePrefinanziamento);
            this.disableButtons = false;
            throw error;
          }
      );
    },
    
    salvataggio(action){
      //Apro la modale se mi trovo all'ultimo step
      //In questo caso coincide con l'ultimo step di uff econom appr
      if(this.isLastStep && action == 'complete'){
        this.$bvModal.show('modalCompletaPref');
        
      }
      
      else{
         this.setModal=action;
          this.$bvModal.show('modalCompletaInvia');
      }
      
    },
    actionModal(){

        this.savePrefinanziamento(this.setModal);

    },

    savePrefinanziamento(action){    
      const url = endpoints.prefinanziamentoById(this.codiceProgetto, this.idPrefinanziamento)
      let body = this.getCurrentScheda();

      //lo memorizzo per riusarlo dopo
      this.taskInfo = body.content.taskInfo;  
      //elimino adesso il taskInfo per non persisterlo
      delete body.content.taskInfo;    
      
      const autore = this.$getUserInfo().preferred_username;
      body.content.notaTemporanea = {
          nota: this.notaTemp,
          utente: autore
      }
      //Setto la nota temporanea riservata
      body.content.notaTemporaneaRiservata = this.noteRiservate;

      //setto l'autore dell'ultimo aggiornamento fuori e dentro content
      body.content.autoreUltimoAggiornamento = autore;
      body['autoreUltimoAggiornamento'] = autore;      

      //disabilito i pulsanti durante le chiamate API
      this.disableButtons = true;
        
      this.$post(url, body)
      .then((result) => {
        this.scheda = result
        console.log("Result= ", result)
        this.attachFileObjects().then(() => {
          if (action != "draft"){
            console.log("savePrefinanziamento() - taskDefinitionKey: ", this.taskInfo.taskDefinitionKey);
            //Se siamo all'ultimo task, si decreta l'esito definitivo
            if (action == "complete"
              && this.taskInfo.taskDefinitionKey == "Progetto_prefinanziamento_UfficioEconomicoFinanziario-Operatore") {
              this.esitoFinale == true || this.esitoFinale == 'true' ? action = "completeOK" : action = "completeKO";
              console.log("conditional action=", action);
            }  
            
            this.completaTask(action, body.idScheda, body.processId, this.taskInfo.taskId);
          } else {
            notify.success(notify.strings.success, notify.strings.savePrefinanziamentoOk);
            this.disableButtons = false;
          }
        })
      },
      (error) => {
        console.error("Errore savePrefinanziamento: ", error.message);
        notify.error(notify.strings.error, notify.strings.errorSavePrefinanziamento);
        this.disableButtons = false;
        throw error;        
      })
    },
    getCurrentScheda() {
      let scheda = this.getScheda({ codiceProgetto: this.codiceProgetto, id: this.idPrefinanziamento })
      return tools.genericFunctions.cloneObject(scheda);
    },
    cancelNote(){
      this.notaTemp = "";
    },
    resetReservedNote() {
      this.noteRiservate = "";
    },
    refreshMandatoryFields(){
      //Se tra le azioni ho dei 'check', allora controllo i campi obbligatori
      // if(this.$regIndexOf('check',this.actions) !== -1)
      this.checkCampiObbligatori()
    },
    checkCampiObbligatori() {
      this.obbligatoriTable.rows = []
      let scheda = this.getCurrentScheda()
      let controllo = scheda.content.controllo
      // CAMPI OBBLIGATORI EDIT CUSTOM
      if(this.idsCustomEdit.length > 0)
        this.checkObbligatoriCustomEdit(scheda)
      //prendo i campi obbligatori
      for (const campo in this.mappa) {
        if (this.mappa[campo] && this.mappa[campo].config && this.mappa[campo].config.mandatory) {
          this.handleVisibilityFilesCanceled(scheda, campo, controllo)
          let checkValid = controlliHelper.checkValidValue( this.mappa[campo].config, controllo )
          //Se non c'è un valore valido lo pusho nella tabella degli obbligatori
          if (!checkValid) {
              this.obbligatoriTable.rows.push({
                Tab: this.mappa[campo].config.tab,
                Campo: 'Punto_' + this.mappa[campo].config.idField,
              })
          }
        }
      }
      this.checkAltriCampiObbligatori(scheda)
    },
    checkObbligatoriCustomEdit(scheda) {
      // FUNZIONALITA' DELL'EDIT CUSTOM PER RUOLO
      for(const id of this.idsCustomEdit) {
        let mapElement = this.config[id]
        if(mapElement && mapElement.config && mapElement.config.editCustom) {
          let role = this.$getUserInfo().roles[0]
          if(mapElement.config.editCustom.includes(role))
            this.checkValueAndPushMandatory(mapElement, scheda)
        }
      }
    },
    checkValueAndPushMandatory(mapElement, scheda) {
    let values = jpath('$'+ mapElement.config.path, scheda.content)
      if(values && values.length === 0 || values[0] == null || values[0] === "") {
        if(mapElement.config.label && mapElement.config.tab) {
          this.obbligatoriTable.rows.push({
            Campo: mapElement.config.label,
            Tab: mapElement.config.tab
          })
        }
      }
    },
    checkAltriCampiObbligatori(scheda) {
      if(this.idsToCheck.length > 0 && Object.keys(this.config).length > 0) {
        for(const id of this.idsToCheck) {
          let mapElement = this.config[id]
          if (mapElement && mapElement.config && mapElement.config.mandatory) {
              let values = jpath('$'+ mapElement.config.path, scheda.content)
              if(values && values.length === 0 || values[0] == null || values[0] === "") {
              if(mapElement.config.label && mapElement.config.tab) {
                this.obbligatoriTable.rows.push({
                  Campo: mapElement.config.label,
                  Tab: mapElement.config.tab
                })
              }
            }
          }
        }
      }
    },
    handleVisibilityFilesCanceled(scheda, campo, controllo) {
      let values = jpath({resultType: 'all'}, '$' + campo, controllo)
      if(scheda.content.allegati && values && values.length === 1) {
        let { deletedFiles } = controlliHelper.updateSituationFiles(values[0], Object.keys(scheda.content.allegati), this.deletedFiles) 
        this.deletedFiles = deletedFiles ? deletedFiles : []
        scheda.content.controllo = controllo
        this.updateSchedaInStore(scheda)
      }
    },
    updateSchedaInStore(scheda) {
      this.$store.dispatch("circuitoFinanziario/updateScheda", {
        codiceProgetto: this.codiceProgetto,
        id: this.idPrefinanziamento,
        content: scheda,
      });
    }, 
    updateEsitoFinale(){ 
      let scheda = this.getCurrentScheda()
      scheda.content.esitoFinale = this.esitoFinale
      this.updateSchedaInStore(scheda)     

    }
  }
}
</script>