<template>
    <div id="pagamentiList">
        <b-table-lite thead-class="head" v-if="loadComplete"
            :id="idTablePdf"
            :items="getItems()"
            :fields="tabledata.header"
        />
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import tool from "@/helpers/tools.js";
    import mapUtils from "@/helpers/mapUtils.js";
    import { JSONPath as jpath } from "jsonpath-plus";
    import calculation from "@/helpers/calculations.js";
    import utils from '@/helpers/utils.js'

    export default {
        name: "pagamentiList",
        computed: {
            ...mapGetters({
                getTabellone: "configuration/getTabellone"
            }),
            getItems() {
                return () => {
                    let items = [];
                    for (const obj of this.tabledata.rows) {
                        let item = {
                            rowId: obj.rowId
                        };
                        for (const key in obj) {
                            item[key] = obj[key].value;
                        }
                        items.push(item);
                    }
                    return items;
                }
            }
        },
        props: {
            idTablePdf: {
                type: String,
                default: ''
            },
            idScheda: String,
            tabName: String
        },
        watch: {
            tabName: function() {
                if(this.tabName.includes('ontrollo')) {
                    this.extractData();
                }
            }
        },
        data() {
            return {
                tabledata: {
                    header: []
                },
                loadComplete: false,
                tabelloneType: 'cronoprog_controlli',
                mappa: {},
                storeModule: 'cronoprog',
                componentConfig: {
                    rootId: 'pagamenti',
                    title: "Pagamenti Dichiarazione di Spesa",
                    name: "pagamentiList",
                    tableIds: [
                        "['contratti'][*]['content']['riferimentoContratto']",
                        "['pagamenti'][*]['content']['numeroFattura']",
                        "['pagamenti'][*]['content']['dataFattura']",
                        "['pagamenti'][*]['content']['totaleFattura']",
                    ],
                    relIds: {
                        "['contratti'][*]['content']['riferimentoContratto']": {
                            type: 'contratti',
                            canBeEmpty: true,
                            allowModalEdit: true
                        }
                    },
                }
            }
        },
        mounted() {
            if(this.idScheda) {
                this.idControllo = this.$route.params.idControllo;
                this.mappa = this.getTabellone(this.tabelloneType);
                if(this.mappa) {
                    this.extractData();
                } 
                else 
                    console.error(this.componentConfig.name, "error in mappa", this.mappa);
                this.loadComplete = true;
            }
        },
        methods: {
            getScheda() {
                return this.$store.getters[this.storeModule + "/getScheda"]({
                    idScheda: this.idScheda,
                });
            },
            getValue(colId, id, mapElement, scheda) {
                let result;
                let updateScheda = false;
                const results = jpath("$" + id, scheda);
                let retVal;
                // se il campo non esiste nella scheda viene creato
                // e si aggiorna la scheda
                if (results.length === 0) {
                    // stampo un log solo se il valore non deve essere calcolato
                    // altrimenti è probabile che esso non sia presente nella scheda
                    if(!mapElement.config.calculationRule) {
                    console.log(this.componentConfig.name, 'getValue: valore non trovato in scheda: ', id);
                    }
                    // qui è contemplato il caso di un relative non definito (ad esempio un pagamento sfuso)
                    if(id.includes(mapUtils.nonDefinito.key)) {
                    result = 'Non definito/a';
                    } else {
                    // altrimenti, l'elemento viene creato da zero
                    retVal = mapUtils.createElementFromScratch(
                        id,
                        scheda,
                        this.mappa
                    );
                    scheda = retVal.scheda;
                    result = retVal.object;
                    updateScheda = true;
                    }
                } else if (results.length === 1) {
                    result = results[0];
                } else {
                    console.error(this.componentConfig.name, "is an array?", colId, id, results);
                }

                // a prescindere dal fatto che sia presente in scheda o meno, 
                // se è prevista una calculation rule allora il campo
                // viene ricalcolato e il result viene sovrascritto
                // qui si inserisce il valore calcolato se previsto dal tabellone
                if(mapElement.config.calculationRule) {
                    result = calculation.calculateValue(mapElement, scheda, id, null, null);
                    const results1 = jpath({resultType: 'all'}, "$" + id, scheda);
                    if(!results1 || results1.length !== 1) {
                        console.error(this.componentConfig.name, '-> id per calculation non trovato', id);
                    } else {
                        const parent = results1[0].parent;
                        const fieldName = results1[0].parentProperty;
                        parent[fieldName] = result;
                        updateScheda = true;
                    }
                }

                return {
                scheda: scheda,
                result: result,
                updateScheda: updateScheda
                }
            },
            formatter(value, key) {
                const mapElement = this.mappa[key];
                if(!mapElement)
                    return value;
                return utils.formatOutput(mapElement, value);
            },
            extractData() {
                if (!this.mappa) {
                    console.error(this.componentConfig.name, "Invalid mappa", this.mappa);
                    return;
                }

                const schedaTemp = this.getScheda({
                    idScheda: this.idScheda,
                });
                let scheda = tool.genericFunctions.cloneObject(schedaTemp);
                if (!scheda) {
                    console.error(this.componentConfig.name, "Invalid scheda", this.idScheda, scheda);
                    return;
                }

                this.tabledata.rows = [];
                this.tabledata.header = [];
                // setup header
                for (const colId of this.componentConfig.tableIds) {
                    let mapElement = this.mappa[colId];
                    if (!mapElement) {
                        console.error(this.componentConfig.name, "no config, get default one for ", colId);
                        mapElement = this.$getDefaultConfig();
                        mapElement.config.path = colId;
                    }
                    const headerColumn = {
                        key: colId,
                        label: mapElement.config.label,
                        tdAttr: this.tooltip,
                        formatter: this.formatter
                    };
                    this.tabledata.header.push(headerColumn);
                }
                let idArray = [];
                // array di tutti pagamenti di cronoprog
                const pagamentiArray = Object.values(scheda[this.componentConfig.rootId]);
                if(pagamentiArray) {
                    // array dei soli pagamenti collegati alla dichiarazione di spesa corrente
                    const pagamentiFiltered = pagamentiArray.filter(
                        pagamento => {
                            return pagamento.content.flagged === this.idControllo
                    });
                    // di questi pagamenti estraggo il solo id e li visualizzo in tabella
                    if(pagamentiFiltered) {
                        idArray = pagamentiFiltered.map(item => {
                            return item.id
                        });
                    }
                }
                // add rows to table
                // per tutte le righe da inserire nella tabella costruisco il rowobj
                for (const rowId of idArray) {
                    let rowObj = {
                        _cellVariants: {},
                        Azioni: [],
                        rowId: rowId
                    };
                    this.relRootArray = [];
                    for (const colId of this.componentConfig.tableIds) {
                        let id;
                        // per ogni colonna della tabella, se il colId corrispondente contiene
                        // il rootId (ad es. "inventario") allora prendo l'identificativo da idArray,
                        // altrimenti devo prenderlo dalla mappa di relatives
                        if(colId.includes(this.componentConfig.rootId)) {
                            id = colId.replace('*', "'" + rowId + "'");
                        }
                        else {
                            // qui prendo l'identificativo dalla mappa di relatives
                            const relMap = scheda[this.componentConfig.rootId][rowId].rel;
                            // da colId isolo la radice del relative
                            // ad es., per la colonna idAttivita è "attivita"
                            const relRootId = colId.match(/\w+/)[0];
                            // se nella mappa di relatives c'è questa radice, allora prendo
                            // per convenzione il primo indice (zero) dell'array
                            const relArray = relMap[relRootId];
                            
                            if(relArray && relArray.length > 0) {
                                const relArrayId = relArray[0];
                                // l'id da cui cercare il valore si ottiene sostituendo nel colId
                                // l'identificativo pescato dai relatives
                                id = colId.replace('*', "'" + relArrayId + "'");
                            } else {
                                // se non trovo un "papà", passo avanti
                                console.log('no relative for colId', colId, relRootId, relArray);
                                continue;
                            }
                        }
                        const mapElement = this.mappa[colId];
                        // il valore viene calcolato automaticamente o ricavato dalla scheda
                        let retVal = this.getValue(colId, id, mapElement, scheda);
                        scheda = retVal.scheda;
                        const result = retVal.result;
                        // setup della riga
                        rowObj = mapUtils.setupComponentRow2(
                            rowObj,
                            id,
                            mapElement,
                            result,
                            scheda,
                            [],
                            [],
                            null
                        );
                    }
                    this.tabledata.rows.push(rowObj);
                }
            }
        }
    }
</script>