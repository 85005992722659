<template>

    <b-container>
        <b-row style="font-size: 14px; margin-bottom: 30px;">
            <b-col cols="9" v-if="!controllato && !bozza">
                <b-alert variant="info" show>
                    <strong>Nota:</strong> Inserire un titolo per il controllo.
                    <br> Può essere effettuato un solo controllo Operativo per anno finanziario.
                    <br> Ai sensi dell’art. 38 del Regolamento (UE) n. 514/2014, l’esercizio finanziario del Fondo Sicurezza Interna copre le spese sostenute 
            e le entrate affluenti e contabilizzate dall’Autorità Responsabile nel periodo che va dal 16 ottobre dell’anno «N-1» al 15 ottobre dell’anno «N»
                </b-alert>    
            </b-col>
            <b-col cols="9" v-if="controllato">
                <b-alert variant="warning" show>
                    Esiste già un controllo In Loco Operativo per il progetto selezionato per l'anno finanziario corrente. 
                    <br> Non &egrave; possibile avviarne di nuovi.
                </b-alert>
            </b-col>
            <b-col cols="9" v-if="bozza">
                <b-alert variant="warning" show>
                    Esiste già un controllo In Loco Operativo in bozza per il progetto selezionato. 
                    <br> Non &egrave; possibile avviarne di nuovi prima di averlo inviato ed aver definito l'anno finanziario di riferimento.
                </b-alert>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="9" v-if="!controllato && !bozza">
                <b-form-input id="titoloOper" v-model="titoloControllo"
                              placeholder="Descrizione breve del controllo operativo"
                              :disabled="isWaitingForSchedaPost"/>
            </b-col>
        </b-row>
        
        <b-row>
            <b-col class="modal-footer text-right">
                <b-button
                    class="btn-success"
                    @click="creaScheda"
                    :disabled="isCreaSchedaDisabled">
                    Crea Nuovo Controllo Operativo
                </b-button>
                <b-button variant="danger"
                    class="modal-default-button"
                    @click="closeModal"
                    :disabled="isWaitingForSchedaPost">
                    Chiudi
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import endpoints from "@/components/endpoints.vue";
import notify from "@/helpers/notifications.js"
import feedFx from '@/helpers/feedFunctions.js'

export default {
    name: "selezioneCheckListControlloFin",
    data(){
        return {
            controllato: false,
            bozza: false,
            selectedTr: 0,
            isWaitingForSchedaPost: false,
            codiceProgetto: null, // il codice progetto preso dal path
            titoloControllo: null,
        }
    },
    computed: {
        isCreaSchedaDisabled: function() {
            // disabilitato nel caso l'utente non abbia inserito il titolo
            // oppure se e' in corso la chiamata POST per la creazione della schedacontrollo
            // oppure il progetto è stato già controllato in questo anno finanziario
            return (!this.titoloControllo 
                || this.isWaitingForSchedaPost 
                || this.controllato
                || this.bozza)
        },
    },
    methods: {

        creaScheda(){

            this.isWaitingForSchedaPost = true;

            this.creaNuovaScheda(
                this.codiceProgetto)
            .then(res => {
                console.log("visualizzo la scheda=", res.idSchedaControllo);
                
                // passo alla pagina della scheda controllo appena creata
                this.$router.push({
                    name: 'schedaControlliInLoco',
                    params: {
                        codiceProgetto: this.codiceProgetto,
                        idControllo: res.idSchedaControllo
                    }
                })

                this.isWaitingForSchedaPost = false;
            })
            .catch((error) => {
                console.error("Errore:", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },

        closeModal() {
            this.$emit('closeModalCheckList')
        },

        loadProjectByCodiceProgetto(codice) {
            //carico i dati del progetto per vedere prefinanziamento e tranche definite
            // Rimosso Paletto: si punta sempre alla versione in Attuazione
            this.$get(endpoints.getProgettoByCodice(codice))
            .then((result) => {
                const response = result.schedaProgetto
                this.schedaProgetto = response;
                // Rimosso Paletto: se il Progetto è in Attuazione non si possono fare altra azioni
                // if (result && result.content.stato === "In Attuazione"){
                //     this.schedaProgetto = result.content;
                // } else {
                //     console.error("Progetto in rimodulazione! Alcuni dati potrebbero non essere stabili");
                // }
    
            })
            .catch((error) => {
                console.error("ERRORE nel caricamento dati del progetto: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });
        },

        async creaNuovaScheda(codiceProgetto){

            try {
                let lastChecklist = await this.$get(
                    endpoints.retrieveLastVersionTabellone('operativo')
                    )
                let lastChecklistVersion = lastChecklist.lastVersion;
                console.log("ultima versione tabellone: ", lastChecklistVersion);

                let anagrafica = {};
                if (lastChecklist){
                    //al momento le due tipologie di controllo hanno lo stesso TAB anagrafica
                    anagrafica = feedFx.feedAnagraficaFinanz(this.schedaProgetto);
                    console.log("filled anagrafica=", anagrafica);
                }

                let infoProg = {};
                if (this.schedaProgetto){
                    infoProg = feedFx.feedInfoProgetto(this.schedaProgetto);
                    //se è stato calcolato un importo finale da un Final Assessment lo utilizzo come costo del progetto
                    if(this.schedaProgetto.importoFinale){
                        infoProg.costoProgetto=this.schedaProgetto.importoFinale;
                        anagrafica.costoProgetto=this.schedaProgetto.importoFinale;
                    }
                }
                console.log("filled infoProgetto=", infoProg);
                let url = endpoints.getControlliLoco(codiceProgetto);
                let userIdGroup = this.schedaProgetto.progetto.beneficiario.beneficiario.idBeneficiario;
                //console.log("dal progetto idBeneficiario=", userIdGroup);
                const autore = this.$getUserInfo().preferred_username;
                
                const body = {
                    codiceProgetto: codiceProgetto,
                    idSchemaControllo: 'operativo',
                    schemaVersione: lastChecklistVersion,
                    idBeneficiario: userIdGroup,
                    tipoControllo: "In Loco",
                    sottoTipoControllo: "Operativo",
                    autoreUltimoAggiornamento: autore,
                    strumentoFinanziario: infoProg.strumentoFinanziario,
                    content: {
                        titoloControllo: this.titoloControllo, // inserito dall'utente
                        autoreUltimoAggiornamento: autore,
                        controllo: {},
                        anagrafica: anagrafica,
                        infoProgetto: infoProg,
                    }
                }
                // POST di creazione della scheda controllo
                console.log("POST to URL="+url+" with body:", body);
                let schedaCreata = await this.$post(url, body);
                if (!schedaCreata.idSchedaControllo ||
                    !schedaCreata.codiceProgetto)
                {
                    let msg = "Scheda Controllo creata non valida. Controllare il servizio ISF-Controlli"
                    console.error(msg, schedaCreata);
                    throw new Error(msg);
                }
                console.log(
                    "scheda Controllo In Loco creata",
                    "idScheda=", schedaCreata.idSchedaControllo,
                    "codiceProgetto=", schedaCreata.codiceProgetto
                );
                
                // avvio il processo
                this.processo = await this.avviaProcesso(schedaCreata.idSchedaControllo, schedaCreata.codiceProgetto);
                console.log("Processo avviato, ID=", this.processo);

                //effettuo il claim implicito per l'utente che ha creato il processo
                await this.autoclaim(schedaCreata.idSchedaControllo);
                return schedaCreata;

            } catch (error) {
                console.error("ERRORE: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
                throw error;
            }
        },

        async avviaProcesso(idScheda, codiceProgetto){
            const url = endpoints.startControlloInLocoOperativo;
            const body = {
                codiceProgetto: codiceProgetto,
                idScheda: idScheda
            }
            // POST per avvio processo tranche
            return this.$post(url, body)
        },

        // POST per autoclaim
        async autoclaim(idScheda) {
            const url = endpoints.claimPrefinanziamento+idScheda;
            console.log("CLAIM by POST to URL", url);
            return this.$post(url)
        },

        caricaControlliOperativiProgetto(codiceProgetto){
            const url = endpoints.getControlliLoco(codiceProgetto);
            this.$get(url)
            .then((result) => {                
                //filtro solo i controlli operativi
                this.controlli = result.filter(controllo => {
                    return (controllo.sottoTipoControllo === "Operativo"
                          && controllo.stato !== "Eliminato dall'utente"
                          && ( 
                               (this.role.includes('UfficioGestione') || (this.role.includes('UfficioControlli'))) //Ufficio Gestione e Controlli vede sempre tutte le schede in loco in tutti gli stati
                                                                                   
                          || (
                               controllo.stato !== "In Compilazione"  //I beneficiari vedono le schede solo dopo lo stato iniziale
                                && (
                                    this.role.includes('UfficioEconomicoFinanziario')
                                    || this.role.includes('Beneficiario')
                                   )
                             )
                            )
                    )
                })

                console.log("Rilevati altri controlli Operativi:", this.controlli.length);
                //se esiste una scheda in bozza non ha ancora anno finaziario definito quindi non ne posso creare altre
                this.bozza = this.esisteUnaBozza(this.controlli); 
                if (this.bozza === true){
                    console.error("Esiste almeno una scheda controllo in bozza per cui non è possibile definire l'anno finanziario!");
                } else {
                    //controllare se ne esiste uno per l'anno finanziario in corso
                    this.annoFinanziarioControllato(this.controlli);
                }
                
            })
            .catch((error) => {
                console.error("ERRORE nel caricamento controlli in loco per il progetto: ", error.message);
                notify.error(notify.strings.error, notify.strings.internalErrorPleaseReportAction);
            });

        },

        esisteUnaBozza(controlli){
            if (!controlli || controlli.length==0){
                console.log("Nessun controllo, quindi nessuna bozza");
                return false;
            }

            let bozze = controlli.filter(controllo => {
                    return (controllo.sottoTipoControllo === "Operativo"
                          && controllo.stato === "Avviato"
                          && controllo.content.dataVerifica
                          && controllo.content.dataVerifica.getFullYear() == new Date().getFullYear()
                    )
            })
            
            if (bozze && bozze.length>0){
                console.error("esistono "+bozze.length+" bozze di controllo da definire!");
                return true;
            }
        },

        annoFinanziarioControllato(controlli){
            /*
            Ai sensi dell’art. 38 del Regolamento (UE) n. 514/2014, l’esercizio finanziario del Fondo Sicurezza Interna copre le spese sostenute 
            e le entrate affluenti e contabilizzate dall’Autorità Responsabile nel periodo che va dal 16 ottobre dell’anno «N-1» al 15 ottobre dell’anno «N»
            */
           if (!controlli || controlli.length == 0){
               console.log("Nessun controllo operativo precedente rilevato");
               this.controllato = false;
           }

            let now = new Date();
            //estraggo l'anno corrente
            const anno = now.getFullYear();
            const inizioAnnoFinanziario = new Date(anno-1, 9, 16);
            const fineAnnoFinanziario = new Date(anno, 9, 16);
            console.log("Now is="+now+", Inizio anno finanziario "+anno+"="+inizioAnnoFinanziario+", Fine anno finanziario "+anno+"="+fineAnnoFinanziario);

           for (let i=0; i< controlli.length; i++){
               
               if (!controlli[i].dataVerifica){
                   console.error(i+" controllo senza dataVerifica!?");
                   //alzo questo flag per bloccare la creazione di altre bozze senza anno finanziario di riferimento
                   this.bozza = true;
                   continue;
               }

               //console.log(i+" controllo data=", controlli[i].dataVerifica);
               //estraggo dalla data stringa nel formato YYYY-MM-DD una Date()
               let dataStrPart = controlli[i].dataVerifica.split("-");
               const dataControllo = new Date(dataStrPart[0], dataStrPart[1]-1, dataStrPart[2]);

               //cerco se esiste un controllo con data 16 Ottobre anno-1 (incluso) ed il 16 ottobre anno corrente (escluso)
               if (inizioAnnoFinanziario.getTime() <= dataControllo.getTime() 
                    && dataControllo.getTime() < fineAnnoFinanziario.getTime()){
                   console.log("I:"+ inizioAnnoFinanziario.getTime()+ ", C:"+dataControllo.getTime()+", F:"+fineAnnoFinanziario.getTime());
                   console.error("Anno finanziario "+anno+" coperto dal controllo "+controlli[i].idSchedaControllo+" del "+controlli[i].dataVerifica);
                   this.controllato = true;
               } else {
                   console.log("Data del controllo "+dataControllo+" fuori dall'anno finanziario corrente: ", anno);
               }
               
           }

        }

        
    },

    mounted() {
      this.codiceProgetto = this.$route.params.codiceProgetto;
      this.loadProjectByCodiceProgetto(this.codiceProgetto);
      
      this.role = this.$getUserRole(); 
      this.caricaControlliOperativiProgetto(this.codiceProgetto);    
    },

}
</script>