<template>
  <div class="h1 ml-1">
    <b-icon
      v-if="iconParere &&
      iconParere.type &&
      iconParere.color"
      :icon="iconParere.type"
      :variant="iconParere.color">
    </b-icon>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "iconCheckCircle",
  props: {
    idsScheda: {
        type: Array,
        default: function() {
          return []
        }
    },
    updatedScheda: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconParere: {
        type:"circle", color: "secondary"
      },
      mappingParere: {
        'null':    { type:"circle-fill", color: "secondary"},
        'Positivo':   { type:"circle-fill", color: "success"},
        'Negativo':  { type:"circle-fill",  color: "danger"},
        'Incompleto':   { type:"circle",  color: "secondary"}
      }
    }
  },
  watch: {
    updatedScheda: function() {
      this.updatedIconParere()
    }
  },
  computed: {
    ...mapGetters({
      getSchedaExecuting: "executingBody/getSchedaProgetto",
      getSchedaIstruttoria: "istruttoria/getSchedaIstruttoria"
    }),
  },
  created() {
    this.updatedIconParere()
  },
  methods: {
    updatedIconParere() {
      let params = this.$route.params
      if(Object.values(params).length === 1) {
        let valueParam = Object.values(params)[0]
        let schedaCurrent = this.retrieveSchedaCurrent(valueParam)
        let check_parere = this.$checkForIconCircle(schedaCurrent, this.idsScheda);
        this.iconParere = this.mappingParere[check_parere]
      }
    },
    retrieveSchedaCurrent(param) {
      let scheda =  this.idsScheda.length > 0 ?
                    this.getSchedaIstruttoria({ idSchedaIstruttoria: param }) :
                    this.getSchedaExecuting({ idSchedaProgetto: param })
      return scheda
    }
  }
}

</script>
