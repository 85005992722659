import Vue from 'vue';
import VueRouter from 'vue-router';

import UserComponent from './views/user.vue';
import ArchivioComponent from './views/monitoring/archivio.vue';
import SelettoreComponent from './views/monitoring/selettore.vue';
import DashboardComponent from './views/dashboard.vue'
import SchedaMonitoraggioComponent from './views/monitoring/schedaMonitoraggio.vue'
import PageNotFoundComponent from './views/pageNotFound.vue';
import ListaExecutingBody from "./views/executingBody/listaExecutingBody.vue"
import schedaExecuting from './views/executingBody/schedaExecuting.vue';
import schedaIstruttoria from './views/istruttorie/schedaIstruttoria.vue';
import SchedaBando from './views/bandi/schedaBando.vue';
import SchedaProgetto from "./views/progetti/schedaProgetto.vue"
import listaControlliAmministrativi from "./views/controlli/listaControlliAmministrativi.vue"
import listaControlliLocoDettaglio from "./views/controlli/listaControlliLocoDettaglio.vue"
import schedaControlliAmministrativi from "./views/controlli/schedaControlliAmministrativi.vue"
import schedaControlliInLoco from "./views/controlli/schedaControlliInLoco.vue"
import ListaProgetti from "./views/progetti/listaProgetti.vue"
import SelettoreInterimComponent from './views/assessment/selettoreInterim.vue';
import SchedaInterimComponent from './views/assessment/schedaInterim.vue';
import ArchivioAssComponent from './views/assessment/archivioAss.vue';
import ScadenzeComponent from './views/dashboard/scadenze.vue';
import NotificheComponent from './views/dashboard/notifiche.vue'
import DettaglioSintesiComponent from './views/dashboard/dettaglioImporti.vue'
import DettaglioAttivitaComponent from './views/dashboard/dettaglioAttivita.vue'
import DettaglioAttivitaBandiComponent from './views/dashboard/dettaglioAttivitaBandi.vue'
import DettaglioAttivitaEBComponent from './views/dashboard/dettaglioAttivitaEB.vue'
import DettaglioAttivitaProgettiComponent from './views/dashboard/dettaglioAttivitaProgetti.vue'
import DettaglioAttivitaControlliComponent from './views/dashboard/dettaglioAttivitaControlli.vue'
import ListaBandiComponent from './views/bandi/listaBandi.vue'
import ListaControlliAmm from "./views/controlli/listaControlli.vue"
import ListaControlliLoco from "./views/controlli/listaControlliLoco.vue"
import ListaProgettiIrregolari from "./views/irregolarita/listaProgettiIrregolari.vue"
import ListaIrregolarita from "./views/irregolarita/listaIrregolarita.vue"
import SchedaIrregolarita from "./views/irregolarita/schedaIrregolarita.vue"
import ListaProgettiAmmessi from "./views/progetti/listaProgettiAmmessi.vue"
import ListaProgettiIstruttoria from "./views/progetti/listaProgettiIstruttoria.vue"
import ListaProgettiModifica from "./views/progetti/listaProgettiModifica.vue"
import ListaProgettiAttuazione from "./views/progetti/listaProgettiInAttuazione.vue"
import SchedaConvenzione from "./views/progetti/schedaConvenzione.vue"
import SchedaGraduatoria from "./views/progetti/schedaGraduatoria.vue"
import ListaProgettiPrefinanziati from "./views/prefinanziamento/listaProgettiPrefinanziati.vue"
import SchedaPrefinanziamento from "./views/prefinanziamento/schedaPrefinanziamento.vue"
import ListaProgettiAnticipazione from "./views/tranche/listaProgettiAnticipazione.vue"
import ListaTranche from "./views/tranche/listaTranche.vue"
import SchedaTranche from "./views/tranche/schedaTranche.vue"
import ListaProgettiDisposizioni from "./views/disposizioni/listaProgettiDisposizioni.vue"
import ListaDisposizioni from "./views/disposizioni/listaDisposizioni.vue"
import ListaContiAnnuali from "./views/progetti/listaContiAnnuali.vue"

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [{
            path: '/',
            secure: false,
            redirect: {
                name: 'secure',
                component: DashboardComponent,
            },
        },
        {
            path: '/cruscotto',
            name: 'secure',
            secure: true,
            component: DashboardComponent,
        },
        {
            path: '/cruscotto/profilo',
            name: 'user',
            secure: true,
            component: UserComponent,
        },
        {
            path: '/cruscotto/listaExecutingBody',
            name: 'listaExecutingBody',
            secure: true,
            component: ListaExecutingBody,
        },
        {
            path: '/cruscotto/schedaExecuting/:idExecutingBody',
            name: 'schedaExecuting',
            secure: true,
            component: schedaExecuting,
            props: true
        },
        {
            path: '/cruscotto/schedaIstruttoria/:idIstruttoria',
            name: 'schedaIstruttoria',
            secure: true,
            component: schedaIstruttoria,
            props: true
        },
        {
            path: '/cruscotto/schedaBando/:idBando',
            name: 'schedaBando',
            secure: true,
            component: SchedaBando,
            props: true
        },
        {
            path: '/cruscotto/schedaProgetto/:idProgetto',
            name: 'schedaProgetto',
            secure: true,
            component: SchedaProgetto,
            props: true
        },
        {
            path: '/cruscotto/controlli/amministrativo/progetto/:codiceProgetto',
            name: 'listaControlliAmministrativi',
            secure: true,
            component: listaControlliAmministrativi,
            props: true
        },
        {
            path: '/cruscotto/controlli/inloco/progetto/:codiceProgetto',
            name: 'listaControlliLocoDettaglio',
            secure: true,
            component: listaControlliLocoDettaglio,
            props: true
        },
        {
            path: '/cruscotto/controlli/amministrativo/progetto/:codiceProgetto/:idControllo',
            name: 'schedaControlliAmministrativi',
            secure: true,
            component: schedaControlliAmministrativi,
            props: true
        },
        {
            path: '/cruscotto/controlli/inloco/progetto/:codiceProgetto/:idControllo',
            name: 'schedaControlliInLoco',
            secure: true,
            component: schedaControlliInLoco,
            props: true
        },
        {
            path: '/cruscotto/schedaProgettoAttuazione/:idProgetto/:versione',
            name: 'schedaProgettoAttuazione',
            secure: true,
            component: SchedaProgetto,
            props: () => ({ type: 'attuazione' })
                
        },
        {
            path: '/cruscotto/schedaProgettoModifica/:idProgetto',
            name: 'schedaProgettoModifica',
            secure: true,
            component: SchedaProgetto,
            props: () => ({ type: 'modifica' })
                
        },
        {
            path: '/cruscotto/progetti',
            name: 'selettore',
            secure: true,
            component: SelettoreComponent,
        },
        {
            path: '/cruscotto/archivioMonitoraggio/:codiceProgetto',
            name: 'archivioMonitoraggio',
            secure: true,
            component: ArchivioComponent,
            props: true
        },
        {
            path: '/cruscotto/progetti/:codiceProgetto/:idPeriodo',
            name: 'schedaMonitoraggio',
            secure: true,
            component: SchedaMonitoraggioComponent,
            props: true
        },
        {
            path: '/cruscotto/interimAssessment',
            name: 'selettoreInterim',
            secure: true,
            component: SelettoreInterimComponent,
        },
        {
            path: '/cruscotto/finalAssessment',
            name: 'selettoreFinal',
            secure: true,
            component: SelettoreInterimComponent,
        },
        {
            path: '/cruscotto/assessment/interim/:codiceProgetto/:idPeriodo',
            name: 'schedaInterim',
            secure: true,
            component: SchedaInterimComponent,
        },
        {
            path: '/cruscotto/assessment/final/:codiceProgetto/:idPeriodo',
            name: 'schedaFinal',
            secure: true,
            component: SchedaInterimComponent,
        },

        {
            path: '/cruscotto/notifiche',
            name: 'notifiche',
            secure: true,
            component: NotificheComponent,
        },
        {
            path: '/cruscotto/assessment/archivio/interim/:codiceProgetto',
            name: 'archivioAssessInterim',
            secure: true,
            component: ArchivioAssComponent,
            props:true
            },
        {
            path: '/cruscotto/assessment/archivio/final/:codiceProgetto',
            name: 'archivioAssessFinal',
            secure: true,
            component: ArchivioAssComponent,
            props:true
        },
        {
            path: '/cruscotto/scadenze',
            name: 'scadenze',
            secure: true,
            component: ScadenzeComponent,
            props: true
        },
        {
            path: '/cruscotto/dettaglioSintesi',
            name: 'dettaglioImporti',
            secure: true,
            component: DettaglioSintesiComponent,
            props: true
        },
        {
            path: '/cruscotto/dettaglioAttivita',
            name: 'dettaglioAttivita',
            secure: true,
            component: DettaglioAttivitaComponent,
            props: true
        },
        {
            path: '/cruscotto/dettaglioAttivitaBandi',
            name: 'dettaglioAttivitaBandi',
            secure: true,
            component: DettaglioAttivitaBandiComponent,
            props: true
        },
        {
            path: '/cruscotto/dettaglioAttivitaExecutingBody',
            name: 'dettaglioAttivitaEB',
            secure: true,
            component: DettaglioAttivitaEBComponent,
            props: true
        },
        {
            path: '/cruscotto/dettaglioAttivitaProgetti',
            name: 'dettaglioAttivitaProgetti',
            secure: true,
            component: DettaglioAttivitaProgettiComponent,
            props: true
        },
        {
            path: '/cruscotto/dettaglioAttivitaControlli',
            name: 'dettaglioAttivitaControlli',
            secure: true,
            component: DettaglioAttivitaControlliComponent,
            props: true
        },
        {
            path: '/cruscotto/listaBandi/:type',
            name: 'listaBandi',
            secure: true,
            component: ListaBandiComponent,
        },
        {
            path: '/cruscotto/listaProgetti',
            name: 'listaProgetti',
            secure: true,
            component: ListaProgetti,
        },
        {
            path: '/cruscotto/listaControlliAmministrativi',
            name: 'listaControlliAmm',
            secure: true,
            component: ListaControlliAmm,
        },
        {
            path: '/cruscotto/listaControlliInLoco',
            name: 'listaControlliLoco',
            secure: true,
            component: ListaControlliLoco,
        },
        {
            path: '/cruscotto/irregolarita',
            name: 'progettiIrregolari',
            secure: true,
            component: ListaProgettiIrregolari,
        },
        {
            path: '/cruscotto/irregolarita/:codiceProgetto',
            name: 'listaIrregolarita',
            secure: true,
            props: true,
            component: ListaIrregolarita,
        },
        {
            path: '/cruscotto/irregolarita/:codiceProgetto/:idControllo',
            name: 'schedaIrregolarita',
            secure: true,
            props: true,
            component: SchedaIrregolarita,
        },
        {
            path: '/cruscotto/listaProgettiIstruttoria',
            name: 'listaProgettiIstruttoria',
            secure: true,
            component: ListaProgettiIstruttoria,
        },
        {
            path: '/cruscotto/listaProgettiAmmessi',
            name: 'listaProgettiAmmessi',
            secure: true,
            component: ListaProgettiAmmessi,
        },
        {
            path: '/cruscotto/listaProgettiModifica',
            name: 'listaProgettiModifica',
            secure: true,
            component: ListaProgettiModifica,
        },
        {
            path: '/cruscotto/listaProgettiInAttuazione/:idProgetto',
            name: 'listaProgettiAttuazione',
            secure: true,
            component: ListaProgettiAttuazione,
        },
        {
            path: '/cruscotto/schedaConvenzione/:idProgetto',
            name: 'schedaConvenzione',
            secure: true,
            component: SchedaConvenzione,
            props: true
        },
        {
            path: '/cruscotto/graduatoria/:idBando',
            name: 'graduatoria',
            secure: true,
            component: SchedaGraduatoria,
            props: true
        },
        {
            path: '/cruscotto/listaProgettiPrefinanziabili',
            name: 'listaProgettiPrefinanziamento',
            secure: true,
            component: ListaProgettiPrefinanziati
        },
        {
            path: '/cruscotto/listaProgettiInPrefinanziamento',
            name: 'listaProgettiPrefinanziamentoItinere',
            secure: true,
            component: ListaProgettiPrefinanziati
        },
        {
            path: '/cruscotto/listaProgettiPrefinanziati',
            name: 'listaProgettiPrefinanziamentoCompletato',
            secure: true,
            component: ListaProgettiPrefinanziati
        },
        {
            path: '/cruscotto/prefinanziamento/progetto/:codiceProgetto/:idPrefinanziamento',
            name: 'schedaPrefinanziamento',
            secure: true,
            component: SchedaPrefinanziamento,
            props: true
        },
        {
            path: '/cruscotto/listaProgettiAnticipazione',
            name: 'listaProgettiAnticipazione',
            secure: true,
            component: ListaProgettiAnticipazione
        },
        {
            path: '/cruscotto/listaTranche/:codiceProgetto',
            name: 'listaTranche',
            secure: true,
            component: ListaTranche
        },
        {
            path: '/cruscotto/tranche/progetto/:codiceProgetto/:idScheda',
            name: 'schedaTranche',
            secure: true,
            component: SchedaTranche,
            props: true
        },
        {
            path: '/cruscotto/listaProgettiDisposizioni',
            name: 'listaProgettiDisposizioni',
            secure: true,
            component: ListaProgettiDisposizioni
        },
        {
            path: '/cruscotto/listaDisposizioni/:codiceProgetto',
            name: 'listaDisposizioni',
            secure: true,
            component: ListaDisposizioni
        },
        {
            path: '/cruscotto/listaContiAnnuali',
            name: 'listaContiAnnuali',
            secure: true,
            component: ListaContiAnnuali
        },
        {
            path: "*",
            name: "notfound",
            secure: false,
            component: PageNotFoundComponent
        }
    ],
});

export default router;
