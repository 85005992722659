var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"show":!_vm.loadComplete || _vm.generationPdf,"rounded":"lg"}},[_c('div',{staticClass:"table-title"},[_vm._v("Scheda Controllo Amministrativo per il Progetto: "+_vm._s(this.$route.params.codiceProgetto))]),(_vm.loadComplete)?_c('div',[_c('div',{staticClass:"table-title"},[_vm._v("Fase controllo: "+_vm._s(this.controllo.sottoTipoControllo)+" Titolo: "+_vm._s(this.controllo.content.titoloControllo)+" ")]),_c('b-table-lite',{attrs:{"thead-class":"head","items":_vm.tabledata.rows,"fields":_vm.tabledata.header}}),_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":"","nav-class":"tabs","content-class":"content"}},[_c('ColorLegend'),(_vm.loadComplete)?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Anagrafica'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Anagrafica'].name)}}},[_c('GeneralViewerCard',{attrs:{"idTablePdf":_vm.conf_export_pdf['Anagrafica'].id_table_pdf,"name":_vm.conf_export_pdf['Anagrafica'].name,"tabName":_vm.tabSelection,"headerTable":_vm.headerAnagrafica,"pathDataEntryConfiguration":'content',"actions":_vm.actionsMap['Anagrafica'],"store":'controlli',"fnGetterTab":'getTabellone',"rowIds":_vm.rowIdsAnagrafica[this.controllo.sottoTipoControllo],"fnGetter":'getScheda',"idEntity":{
                codiceProgetto: this.$route.params.codiceProgetto,
                id: this.$route.params.idControllo
              },"fnSetter":'updateScheda'}})],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Procedura'))?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Procedura'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Procedura'].name)}}},[_c('procedura2',{attrs:{"idTablePdf":_vm.conf_export_pdf['Procedura'].id_table_pdf,"name":_vm.conf_export_pdf['Procedura'].name,"allowedActions":_vm.actionsMap['Procedura'],"dinamicIds":_vm.rowIds_procedura,"idEntity":{
                idProgetto: _vm.controllo.content.infoProgetto.idProgetto,
                procAlfaId: _vm.getCronoprogItemId
              },"tabName":_vm.tabSelection}})],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Contratto'))?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Contratto'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Contratto'].name)}}},[_c('contratto2',{attrs:{"idTablePdf":_vm.conf_export_pdf['Contratto'].id_table_pdf,"name":_vm.conf_export_pdf['Contratto'].name,"allowedActions":_vm.actionsMap['Contratto'],"dinamicIds":_vm.rowIds_contratto,"idEntity":{
                idProgetto: _vm.controllo.content.infoProgetto.idProgetto,
                procAlfaId: _vm.getCronoprogItemId

              },"tabName":_vm.tabSelection}})],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('DocumentazioneControllo'))?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Documentazione'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Documentazione'].name)}}},[_c('Documentazione',{attrs:{"ownerProcessStatus":this.retrieveTaskDefinitionKey,"actions":_vm.actionsMap['DocumentazioneControllo'],"entity":this.entity,"getters":this.getters,"setters":this.setters,"payload":this.payload,"riferimento":this.riferimento,"tabName":"DocumentazioneControllo"}})],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Pagamenti'))?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Dichiarazione'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Dichiarazione'].name)}}},[_c('GeneralViewerCard',{attrs:{"idTablePdf":_vm.conf_export_pdf['Dichiarazione'].id_table_pdf,"name":_vm.conf_export_pdf['Dichiarazione'].name,"tabName":_vm.tabSelection,"headerTable":_vm.headerAnagrafica,"pathDataEntryConfiguration":'content',"store":'controlli',"fnGetterTab":'getTabellone',"rowIds":_vm.rowIdsDichiarazione,"fnGetter":'getScheda',"fnSetter":'updateScheda',"idEntity":{
                codiceProgetto: this.$route.params.codiceProgetto,
                id: this.$route.params.idControllo
              },"optionalObj":_vm.getCronoprogFromStore({idScheda: this.getIdProgetto}),"doRefresh":_vm.refreshDich,"actions":_vm.actionsMap['Dichiarazione']}}),_c('br')],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Pagamenti'))?_c('b-tab',{attrs:{"title":_vm.conf_export_pdf['Pagamenti'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Pagamenti'].name)}}},[_c('Pagamenti',{attrs:{"idTablePdf":_vm.conf_export_pdf['Pagamenti'].id_table_pdf,"name":_vm.conf_export_pdf['Pagamenti'].name,"idScheda":_vm.getIdProgetto,"actions":_vm.actionsMap['Pagamenti'],"dinamicIds":_vm.ids_pagamenti.dinamicIds,"tableIds":_vm.ids_pagamenti.tableIds,"rootId":_vm.ids_pagamenti.rootId,"tabName":_vm.tabSelection,"optionalObj":_vm.getControllo()},on:{"refreshDichiarazione":function($event){return _vm.callRefreshDich()}}})],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('AutoControllo'))?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['AutoControllo_collapse'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['AutoControllo_collapse'].name)}}},[(_vm.isViewAllowed('Pagamenti'))?_c('Collapse',{attrs:{"name":_vm.conf_export_pdf['AutoControllo_collapse'].nameCollapse}},[_c('ListaPagamenti',{attrs:{"idTablePdf":_vm.conf_export_pdf['AutoControllo_collapse'].id_table_pdf,"tabName":_vm.tabSelection.toLowerCase(),"idScheda":_vm.getIdProgetto}})],1):_vm._e(),(_vm.loadComplete)?_c('AutoControllo',{attrs:{"idTablePdf":_vm.conf_export_pdf['AutoControllo_AutoControllo'].id_table_pdf,"title":_vm.conf_export_pdf['AutoControllo_AutoControllo'].title_page_pdf,"tabName":_vm.tabSelection.toLowerCase(),"config":_vm.getTabellone,"dataObject":'autocontrollo',"store":'controlli',"fnGetter":'getScheda',"fnSetter":'updateScheda',"idEntity":{
                codiceProgetto: this.$route.params.codiceProgetto,
                id: this.$route.params.idControllo},"name":"Check List AutoControllo","headerTable":_vm.headerAutocontrollo,"actions":_vm.actionsMap['AutoControllo']}}):_vm._e()],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('Controllo'))?_c('b-tab',{attrs:{"no-body":"","title":_vm.conf_export_pdf['Controllo_collapse'].title_page_pdf,"title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Controllo_collapse'].name)}}},[(_vm.isViewAllowed('Pagamenti'))?_c('Collapse',{attrs:{"name":_vm.conf_export_pdf['Controllo_collapse'].nameCollapse}},[_c('ListaPagamenti',{attrs:{"idTablePdf":_vm.conf_export_pdf['Controllo_collapse'].id_table_pdf,"tabName":_vm.tabSelection.toLowerCase(),"idScheda":_vm.getIdProgetto}})],1):_vm._e(),(_vm.loadComplete)?_c('Controllo',{attrs:{"idTablePdf":_vm.conf_export_pdf['Controllo_Controllo'].id_table_pdf,"title":_vm.conf_export_pdf['Controllo_Controllo'].title_page_pdf,"tabName":_vm.tabSelection.toLowerCase(),"config":_vm.getTabellone,"headerTable":_vm.headerControllo,"name":"Check List Controllo","actions":_vm.actionsMap['Controllo']}}):_vm._e()],1):_vm._e(),(_vm.loadComplete && _vm.isViewAllowed('InvioControllo'))?_c('b-tab',{attrs:{"no-body":"","title":"Invio","title-item-class":"tab1","title-link-class":"titleLink"},on:{"click":function($event){return _vm.setTabSelection(_vm.conf_export_pdf['Invio_mandatory'].name)}}},[(_vm.loadComplete)?_c('Invio',{attrs:{"idsCustomEdit":_vm.ids_custom_edit,"idsToCheck":_vm.idsToCheck,"tabName":_vm.tabSelection,"actions":_vm.actionsMap['InvioControllo']},on:{"buildPdf":_vm.generateReport}}):_vm._e()],1):_vm._e()],1)],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }