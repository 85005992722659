<template>
  <div>
    <div class="table-title">Scheda Bando - {{ title }}</div>
    <div>
      <b-card no-body>
        <b-tabs
          v-if="this.schedaBandoLoaded"
          card
          nav-class="tabs"
          content-class="content"
        >
          <ColorLegend/>
          <!-- mettere qui la bannertable -->
          <b-tab
            no-body
            title="Generale"
            title-item-class="tab1"
            title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['generale'].name)"
          >
            <Generale :idTablePdf="conf_export_pdf['generale'].id_table_pdf" />
          </b-tab>
          <b-tab
            v-if="!this.setTabInvio()"
            no-body
            title="Invio"
            title-item-class="tab1"
            title-link-class="titleLink"
            v-on:click="setTabSelection(conf_export_pdf['bando_mandatory'].name)"
          >
            <Invio 
              :tabName="tabSelection" 
              :riferimento = this.riferimento
              @buildPdf="generateReport"/>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import Generale from "./generale.vue"
import Invio from "./invio.vue"
import endpoints from "@/components/endpoints.vue"
import { mapGetters } from "vuex"
import notify from "@/helpers/notifications.js"
import helper_pdf from "@/helpers/exportPdf.js"
import ColorLegend from "@/components/legend.vue"


export default {
  idPeriodo: null,
  codiceProgetto: null,
  bandoCurrent: {},
  components: {
    Generale,
    Invio,
    ColorLegend
  },
  data() {
    return {
      //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
      riferimento : {
          idOggettoRif: "",
          tipoOggettoRif: "Scheda Bando", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
          idBando: "",
          tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
          idProgetto: "",
          codiceProgetto: ""
      },
      bannerTable: {
        header: ["Codice Bando", "Titolo Bando", "Data Chiusura Bando"],
        rows: [
          {
            content: {},
          },
        ],
      },
      conf_export_pdf: {
        generale: {
          id_table_pdf: 'generale_bando',
          name: "generale",
          title_page_pdf: 'Generale',
          nameCollapse: '',
        },
        bando_mandatory: {
          id_table_pdf: 'bando_mandatory',
          name: "invio",
          title_page_pdf: 'Invio',
          nameCollapse: 'Campi obbligatori non valorizzati',
        },
        invio_fileUpload: {
          id_table_pdf: 'invio_fileUpload',
          name: "",
          title_page_pdf: 'Invio',
          nameCollapse: 'Allegati',
        },
        note_bando: {
          id_table_pdf: 'note_bando',
          name: "",
          title_page_pdf: 'Invio',
          nameCollapse: 'Note',
        },
        invio_richiesta_parere_ICT: {
          id_field: "['richiestoParereICT']",
          name: "Invio",
          title_page_pdf: 'Dettagli Invio',
          conf_field: { addPage: true, mapping: 'SiNo' },
          descRadio: 'Si richiede il parere della Task Force ICT: '
        },
        invio_parere_ict: {
          id_field: "['parereICT']",
          name: "Invio",
          title_page_pdf: 'Dettagli Invio',
          conf_field: { addPage: false },
          descRadio: 'Parere ICT: '
        }
      },
      title: "",
      inputData: null,
      tabSelection: "",
      disableTabs: true,
      schedaBandoLoaded: false,
      schedaBandoCorrente: {},
    };
  },

  computed: {
    ...mapGetters({
      getSchedaBando: "announcements/getSchedaBando"
    })
  },
  mounted() {
    // Recupero l'ID_SCHEDA_BANDO dall'url corrente
    let idSchedaBando
    if (this.$route.params.idBando !== '') {
        idSchedaBando = this.$route.params.idBando
    }
    // Recupero la SCHEDA_BANDO corrente e la setto nello Store
    let urlGetBando = endpoints.retrieveBando + idSchedaBando;

    Promise.all([
      this.$store.dispatch("configuration/setTabelloneVersioned", {type: 'allegati'}),
      this.$get(urlGetBando)
    ]).then((results) => {
        const bandoResult = results[1];
        const bando = bandoResult.schedaBando.bando;
        const tabelloneAllegati = results[0];
        if (bandoResult.schedaBando.bando){
          this.riferimento.idBando = bando.idBando;
          this.riferimento.idOggettoRif = bando.idBando;
          this.riferimento.tipoBando = bando.modalitaDiAccesso;
          console.log("schedaBando riferimento:", this.riferimento);
        }

        // setto titolo scheda bando
        this.title = bando.modalitaDiAccesso;
        this.schedaBandoCorrente = bandoResult;
        this.$store.dispatch("announcements/setSchedaBando", {
          idSchedaBando: idSchedaBando,
          schedaBando: bandoResult,
          tabelloneAllegati: tabelloneAllegati
        });
        this.schedaBandoLoaded = true;
      })
      .catch((error) => {
        console.log("schedaBando error: ", error.response);
        notify.error(notify.strings.error, notify.strings.bandoNotFound);
      });
  },
  methods: {
    setTabInvio() {
      let idGroup = this.$getUserInfo().groups[0]
      return ( idGroup.match('eneficiari') && 
        !this.schedaBandoCorrente.taskInfo.taskDefinitionKey.match('ando_inviato_Nessuno-Nessun'));
    },

    generateReport(optPdf) {
      this.generationPdf = true
      // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)
      let source = {
        scheda: this.getScheda().schedaBando
      }
      this.conf_export_pdf['invio_richiesta_parere_ICT'].source = source
      this.conf_export_pdf['invio_parere_ict'].source = source
       helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
      this.generationPdf = false
    },

    setTabSelection(name) {
      this.tabSelection = name;
    },

    getScheda() {
      return this.getSchedaBando({
        idSchedaBando: this.$route.params.idBando,
      });
    }
  },
};
</script>
