<template scope="props">
    <div id="dettaglioImporti" class="elencoProgetti">
        <div class="table-title">Dettaglio dati di Sintesi <span v-if="descBeneficiario !== ''"> {{descBeneficiario}}</span></div>

        <b-form inline           
        label-align-sm="left"
        class="filtro-notifiche">
        <label class="mr-sm-2" for="filterInput">Filtra risultati</label>
        <b-form-input
        v-model="filter"
        type="search"
        id="filterInput"
        placeholder="Digita un filtro"/>
        <b-button :disabled="!filter" @click="filter = ''">Pulisci filtro</b-button>
        
        </b-form>

        <b-table :filter="filter" :busy="isBusy" id="elencoImporti" primary-key="dataModifica" hover :items="tabledata.rows" :fields="tabledata.header" :sort-by="sortBy" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/helpers/utils.js'
import endpoints from '@/components/endpoints.vue';

    export default {
        name: 'elencoImporti',
        computed: {
             ...mapGetters({
                getDescBeneficiario: 'announcements/getDescBeneficiario'
            }),
        },

        components: {
        },

        data() {
            return {
                descBeneficiario: "",
                isBusy: true,
                sortBy: 'Codice',
                filter: null,
                tabledata: {
                    "header": [
                        {
                            key: 'Codice',
                            thClass: 'thst',
                            sortDirection: 'asc',
                            sortable: true,
                        },
                        {
                            key: 'Titolo',
                            thClass: 'thst',
                            sortable: true,
                        },
                        {
                            key: 'Importo Assegnato',
                            thClass: 'thst',
                            sortable: true,
                            filterByFormatted: true,
                            formatter: this.currencyFormatter
                        },
                        {
                            key: 'Importo Erogato',
                            thClass: 'thst',
                            sortable: true,
                            filterByFormatted: true,
                            formatter: this.currencyFormatter
                        },
                        {
                            key: 'Stato',
                            thClass: 'thst',
                            sortable: true,
                        },
                    ],
                    "rows": []
                }
            };
        },

        methods: {
            currencyFormatter(value) {
                return utils.formatCurrency(value);
            },

            async extractData()
            {
                let rows = [];
                const url1 = endpoints.listSchedeProgettoInAttuazioneOrConcluse
                const url2 = endpoints.getStatsTotalePagamenti

                let schede = await this.$get(url1)
                let pagamenti = await this.$get(url2)

                schede.forEach(
                    (infoProgetto) => {
                        let row = {
                            "Codice":"Non disponibile",
                            "Titolo": "Non disponibile",
                            "Importo Assegnato": 0,
                            "Importo Erogato": 0,
                            "Stato": "Non disponibile",
                        }

                        let info = infoProgetto.infoProgetto
                        if(info){
                            row["Codice"] = info.codiceProgetto
                            row["Titolo"] = info.titoloProgetto
                            row["Stato"] = info.stato
                            if(info.costoProgetto && !isNaN(info.costoProgetto))
                                row["Importo Assegnato"] = info.costoProgetto
                        }

                        let pagamento = pagamenti.find((item) => { return item.codiceProgetto === info.codiceProgetto })
                        row["Importo Erogato"] = pagamento.pagamenti
                        rows.push(row)
                    }
                )
                this.tabledata.rows = rows;
                this.isBusy = false;
            },
        },

        created(){
           this.extractData()
        },

        mounted(){
            this.descBeneficiario = this.getDescBeneficiario();
        }
    }

</script>
