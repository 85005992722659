<template>
  <div>
    <b-container>
      <Collapse :name="'Note (' + allNotes.length + ')'">
        <br />
        <TableNotes :id="'note_graduatoria'" v-if="allNotes.length > 0" :notes="allNotes"> </TableNotes>
        <h4 v-else>
          Nessuna nota da mostrare
        </h4>
      </Collapse>
      <Collapse name= "Allegati">
      <br/> 
        <b-form-select
            size="lg" 
            v-model="actualRid"
            @change="getFilesPerRid(actualRid)">
                <option value="" disabled selected>
                    Scegli un progetto
                </option>
                <option v-for="progetto in listaProgetti"               
                :key="progetto.titoloProgetto"
                :value="progetto.idProgetto">
                    {{progetto.idProgetto}}: {{ getDescBeneficiario }}
                </option>
        </b-form-select>
          <fa-icon v-if="!this.loadComplete" :icon="['fas', 'sync']" spin/>
          <FileUpload v-if="this.ridIsNotBlank && this.loadComplete"
          :idTablePdf="'invio_fileUpload'"
          :rid="this.actualRid"
          :ownerProcessStatus="this.schedaConvenzione.taskInfo.taskDefinitionKey"
          :actions= actionsArray
          :entity="this.entity"
          :availableFiles = this.availableFiles
          :documentTypes = this.availableDocumentTypes
          :riferimento = this.rifUpload
          name= "files"
          @doRefresh="getFilesPerRid(actualRid)"/>
      </Collapse>
      <b-row>
        <div v-if="parereGraduatoriaMancante">
          <ul>
          <span :class="'text-danger'">Attenzione: Ci sono ancora progetti per cui non è stato espresso un giudizio</span>
          </ul>
        </div>
      </b-row>      
      <b-row>
        <div v-if="Object.values(this.tipologieFileMancanti).length > 0">
          <ul>
            <span :class=" areTotalMandatoryMissing===true ? 'text-danger' : 'text-warning'">Attenzione: Mancano ancora allegati </span>
            <li v-for="file in Object.values(this.tipologieFileMancanti)" :key="file.type" style="list-style-type: circle;">
              <span class="text-center"> {{ file.title }}</span>
            </li> 
          </ul>
        </div>
      </b-row>
      <b-row class="mt-2" v-if="blockNote">
        <b-col />
        <b-col class="text-right">
          <label for="textarea-note">Nota:</label>
        </b-col>
        <b-col sm="8">
          <b-form-textarea
            v-model="note"
            id="textarea-note"
            size="lg"
            placeholder="Inserire qui le note"
            :disabled="disableButton"
          />
        </b-col>
        <b-col sm="2">
          <b-button :id="'cancelNote_convenzioneCFP'" variant="outline-secondary" :disabled="disableButton" @click="cancelNote()"
            >Cancella Nota</b-button
          >
        </b-col>
        <b-col />
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            :id="'export_pdf'"
            size="lg"
            variant="secondary"
            class="mx-2"
            :disabled="disableButton"
            @click="preparePdf">
            Export PDF
          </b-button>
          <b-button
            v-if="actionsArray.indexOf('save') !== -1"
            :id="'save_convenzioneCFP'"
            size="lg"
            variant="primary"
            class="mx-2"
            :disabled="disableButton"
            @click="saveSchedaConvenzione('draft')"
          >
            Salva in Bozza
          </b-button>
          <!-- silenziamento if tutti i campi compilati  -->
          <template>
            <b-button
              v-if="actionsArray.indexOf('send') !== -1"
              :id="'send_convenzioneCFP'"
              size="lg"
              variant="success"
              class="mx-2"
              :disabled="disableButton || areTotalMandatoryMissing || parereGraduatoriaMancante"
              @click="setValueModal('complete')"
            >
              Salva e Invia
            </b-button>
          </template>
          <b-button
            v-if="actionsArray.indexOf('reject') !== -1"
            :id="'reject_convenzioneCFP'"
            size="lg"
            variant="danger"
            class="mx-2"
            :disabled="disableButton"
            @click="setValueModal('reject')"
          >
            Rifiuta
          </b-button>
         
        </b-col>
      </b-row>
       <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
    </b-container>
  </div>
</template>
<script>

import Collapse from "@/components/collapse.vue";
import FileUpload from '@/components/FileUpload.vue'
import TableNotes from "../bandi/tableNotes.vue";
import notify from "@/helpers/notifications.js";
import endpoints from '@/components/endpoints.vue';
import tool from '@/helpers/tools.js';
import { mapGetters } from 'vuex';
import helper_pdf from "@/helpers/exportPdf.js"

export default {
  name: "invio",
  props: {
    tabName: String,
    schedaConvenzione: {},
    parereGraduatoriaMancante: Boolean,
    //informazioni di riferimento per tutti i file caricati
    riferimento: {type: Object, required: true }
  },
  data() {
    return {
      messages: "Vuoi eliminare questo progetto?",
       modal1Map:{
            complete:{
                title:"Conferma Intervento", text:"Conferma di voler Inviare ? "
            },
            
            reject:{
                title:"Rifiuta Intervento", text:"Conferma di voler Rifiutare?"
            },
             
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",
      entity: "graduatoria",
      tabellone:{},
      allNotes: [],
      note:"",
      allegatiDisponibili: 0,
      availableFiles: [],
      availableDocumentTypes: [],
      missingMandatoryFiles: {},
      missingRecommendedFiles: {},
      missingMandatoryProjects: -1, //numero di progetti 'consultati'
      tipologieFileMancanti: {},
      totalNumberOfMissingMandatory: -1,
      totalNumberOfMissing: -1,
      showModal: false,
      loadComplete: false,
      actualRid:"", //id del progetto attualmente selezionato nella sezione allegati
       tabledata: {
        header: [
          {
            key: "Campo",
          },
          {
            key: "Tab",
          },
        ],
        rows: [],
      },
      disableButton: false,
      actionsArray: [],
      rifUpload: {}
    };
  },
  components: {
    Collapse,
    TableNotes,
    FileUpload,
  },
  mounted() {
    let content = this.schedaConvenzione.schedaConvenzione
    if(content.note != null || content.notaTemporanea != null) {
      this.allNotes = content.note && content.note.length > 0 ? content.note : [];
      this.note = content.notaTemporanea
    }
    this.actionsArray = this.$getActionsGraduatoria(this.schedaConvenzione)
    //console.log("actions=", this.actionsArray)

    //Recupero le varie schede progetto e le setto nello store
    const idList = []
    this.listaProgetti.forEach(progetto => {
        idList.push(progetto.idProgetto)
    });
    if( idList.length > 0 ){
      const idString = idList.toString()
      this.setListaProgettiInGraduatoria(idString).then(
          () => {
              //Recupero le informazioni sui file obbligatori al caricamento della pagina 
              idList.forEach( idProgetto => {
                this.getFilesPerRid(idProgetto)
              })          
              this.loadComplete = true;
          },    
      ).catch(
          (error) => {
              console.error("setListaProgettiInGraduatoria error:", error.message)
              this.loadComplete = true;
          }
      )}
      this.loadComplete = true;
  },
  watch: {
    listaProgetti: function(){
      //ogni volta che la lista dei progetti ammessi cambia, aggiorno l'oggetto che conta i file obbligatori
       this.missingMandatoryFiles = {}
       this.tipologieFileMancanti =  {}
       this.listaProgetti.forEach(progetto => {
         this.getFilesPerRid(progetto.idProgetto)
       });
    },
    totalNumberOfMissing: function(){
       this.tipologieFileMancanti =  {}
       this.listaProgetti.forEach(progetto => {
         this.getFilesPerRid(progetto.idProgetto)
       });
    }
  },
  computed: {
    listaProgetti(){
      return this.$graduatoriaFilterAvailableProjects(this.getSchedaComplete({ idBando: this.schedaConvenzione.schedaConvenzione.idBando }))
    },
    ...mapGetters({
      getSchedaComplete: "convenzione/getScheda",
      getSchedaConvenzione: "convenzione/getSchedaConvenzione",
      getDescBeneficiario: 'announcements/getDescBeneficiario'
    }),    
    ridIsNotBlank(){
        return this.actualRid.length > 0
    },
    areTotalMandatoryMissing(){
      // Controlla se ci sono ancora allegati obbligatori per i vari progetti nella graduatoria.
      // this.listaProgetti è popolata con i progetti ammessi nella graduatoria
      if ( this.listaProgetti && this.listaProgetti.length > 0)
        return !(this.totalNumberOfMissingMandatory===0 && this.missingMandatoryProjects === this.listaProgetti.length)
      else //non c'è nessun progetto ammesso, non controllo gli allegati
        return false
    },
    blockNote() {
     return  this.actionsArray.indexOf("save") !== -1;
    },
  },
  methods: {
    preparePdf() {
      let contentScheda =  this.getSchedaComplete({ idBando: this.schedaConvenzione.schedaConvenzione.idBando })
      let typeScheda = 'Graduatoria'
      let optPdf = helper_pdf.handleNamingPdf(contentScheda, typeScheda)
      this.$emit('buildPdf', optPdf)
    },
    //Metodi per Allegati
    getFilesPerRid(rid) {
        //arricchisco le informazioni di riferimento con il progetto scelto dal menu a tendina
        this.rifUpload = tool.genericFunctions.cloneObject(this.riferimento);
        this.rifUpload.idProgetto = rid;

        let uri = endpoints.postFilesBasePath + rid
        this.$get(uri)
            .then(
                resp =>  {
                  this.availableFiles = resp;
                  this.currentMandatoryFiles();
                  this.loadComplete = true;
                }
            ).catch(error => {
                console.error("Error getFilesPerRid: ", error);
                notify.error(
                  notify.strings.error,
                  notify.strings.getFilesPerRid
                )
                this.loadComplete = true;
            });
            this.loadComplete = false
    },
    currentMandatoryFiles(){
        //aggiorno anche le tipologie di file ancora mancanti

        let schedaCurrent =  this.getSchedaComplete({ idBando: this.schedaConvenzione.schedaConvenzione.idBando })
        let clonedScheda = tool.genericFunctions.cloneObject(schedaCurrent);
        // allegaty mandatory è l'array di allegati obbligatori mancanti
        let allegatiMandatory = this.$calculateMandatoryFiles(this.entity, this.$getUserInfo().roles[0], clonedScheda, this.availableFiles);
        
          if(this.availableFiles 
          && this.availableFiles.length > 0 
          && this.availableFiles[0] 
          && this.availableFiles[0].idScheda)
        {
          let idProgetto = this.availableFiles[0].idScheda
          this.missingMandatoryFiles[idProgetto] = allegatiMandatory.filter(doc => {return doc.mandatory === true}).length
          this.missingRecommendedFiles[idProgetto] = allegatiMandatory.filter(doc => {return doc.recommended === true}).length
          //Totale file obbligatori mancanti
          this.totalNumberOfMissingMandatory = Object.values(this.missingMandatoryFiles).reduce((acc, current) => acc + current)
          //Totale di tutti i file (obbligatori, o raccomandati)
          this.totalNumberOfMissing = Object.values(this.missingRecommendedFiles).reduce((acc, current) => acc + current) + this.totalNumberOfMissingMandatory
          //numero di progetti 'consultati': serve a controllare che non ci siano piu file obbligatori
          this.missingMandatoryProjects = Object.values(this.missingMandatoryFiles).length        
        }
        //Salvo le tipologie di file mancanti
        allegatiMandatory.forEach( element => {
          this.tipologieFileMancanti[element.type] = element
        });
        //Aggiungo la dicitura di Obbligatorietà
        const descrizione = ' (Obbligatorio)'
        Object.values(this.tipologieFileMancanti).forEach( element =>{
          element.title = element.type
        if (element.mandatory === true)
          element.title = element.title + descrizione
          })

        //Scrive nello store i file mancanti
        //this.$updateMandatoryFilesInStore(allegatiMandatory, this.entity, schedaCurrent.schedaConvenzione.idBando)
        //Scrive nello store il numero di file gia caricati
        //this.$updateAvailableLengthInStore(this.availableFiles.length, this.entity, schedaCurrent.schedaConvenzione.idBando);
        this.availableDocumentTypes = this.$availableDocumentTypes(clonedScheda.infoAllegati[this.entity], schedaCurrent.taskInfo.taskDefinitionKey)
    },

    //setta nello store le schede progetto che serviranno per gli allegati
    async setListaProgettiInGraduatoria(list){
        const url = endpoints.retrieveListaProgetti(list)
        return this.$get(url).then(
            (result) => {

                if( result && result.length > 0){
                    result.forEach(scheda => {
                        this.$store.dispatch("progetto/setSchedaProgetto",
                        { idSchedaProgetto: scheda.idProgetto })
                    });
                    //console.log("result=", result)
                    return true
                }
                else{
                   throw new Error("lista progetti vuota!")
                }                    
            },
            (error) => {
                console.error(error.message)
                throw error;
            }
        )
    },
    cancelNote(){
      this.note = " ";
    },
    getDenominazioneBeneficiario (idBeneficiario){
      const mappaBenef = this.getBeneficiari;
      let beneficiario = mappaBenef[idBeneficiario];
      if (beneficiario) {
        return beneficiario.denominazione; 
      } else {
        return idBeneficiario;
      }  
    },

     setValueModal(action){
      this.setModal=action;
      this.$bvModal.show('modalCompletaInvia');
    },

    actionModal(){
         switch (this.setModal) {
                    case "complete":
                        return this.saveSchedaConvenzione('complete');
                    case "reject":
                      return this.saveSchedaConvenzione('reject');
                    default:
                        console.log("Case false");
                    }
    },


    async saveSchedaConvenzione(salvataggio) {
     
      this.disableButton = true;
      let schedaConvenzione = this.getSchedaComplete({
        idBando: this.schedaConvenzione.schedaConvenzione.idBando
      });
      let clonedScheda = tool.genericFunctions.cloneObject(schedaConvenzione);
      clonedScheda.schedaConvenzione.notaTemporanea = this.note
      // schedaConvenzione = clonedScheda
      this.$store
        .dispatch("convenzione/saveSchedaConvenzione", {
          schedaConvenzione: clonedScheda,
          salvataggio: salvataggio
        })
        .then(
          () => {
            notify.success(
              notify.strings.success,
              notify.strings.saveConvenzioneOk
            );
            this.disableButton = false;
            if (salvataggio === "complete" || salvataggio === "reject") {
            this.$router.push({ name: "secure" });
            }
          },
          (error) => {
            console.log("save Scheda Convenzione Error: ", error);
            notify.error(
              notify.strings.error,
              notify.strings.errorSaveProgetto
            );
          }
        );
    },
  },
};
</script>
