import tools from "@/helpers/tools.js";
import calculations from "@/helpers/calculations.js";
import mapUtils from "@/helpers/mapUtils.js";
import { JSONPath as jpath } from 'jsonpath-plus'
import utils from '@/helpers/utils.js';
import jsPDF from 'jspdf'
import 'jspdf-autotable'


const rowHeight = 35;
const titleFontSize = 30;
const subtitleFontSize = 30;
const beneficiarioFontSize = 12;
const vSpacing = 50;
const tabTitleSize = 16;
const collapseNameSize = 14;
const baseYMargin = 30;

const baseXMargin = 20;
const interlineaSize = 15;
const collapseTopMargin = baseYMargin + tabTitleSize + interlineaSize;

function printPagePdf(pdf, titleTab, nameCollapse, header, infoRow, paddingY) {
  let rows = infoRow.data
  if(!paddingY) // Crea uno shift in verticale sulla pagina del pdf nel caso di print di 2 tabelle (CronoProgramma)
    paddingY = baseYMargin;
  const finalY = 10 + paddingY
  // scrivo il titolo
  pdf.setFontSize(tabTitleSize)
  pdf.text(titleTab, centerText(pdf, titleTab), paddingY + interlineaSize);
  // se c'è il collapse, scrivo il sottotitolo
  if(nameCollapse !== '') {
    pdf.setFontSize(collapseNameSize)
    pdf.text('\n' + nameCollapse, baseXMargin, collapseTopMargin);
  }
  pdf.autoTable({
    columns: header,
    body: rows,
    startY: nameCollapse !== '' ? rowHeight + collapseTopMargin : finalY + interlineaSize,
    margin: { horizontal: 10 },
    styles: { overflow: 'linebreak' },
    bodyStyles: { valign: 'top' },
    columnStyles: { text: { cellWidth: 'auto' }},
    headStyles:{ valign: 'middle', halign: 'center' },
    // COLORAZIONE ROWS DELETED (BUG #10623)
    didParseCell: function (data) {
      if (infoRow.style_row_deleted &&
          infoRow.style_row_deleted.length > 0 &&
          infoRow.style_row_deleted.indexOf(data.row.index) !== -1 &&
          data.section === "body") {
            data.cell.styles.textColor = 'red';
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.fillColor = [217, 226, 239]; // RGB di Secondary Color 
      }
    },
    // COLORAZIONE ROWS DELETED
    theme: "striped"
  });
  return pdf
}

function keysToReclaim(dataTable) {

  const strToRemove = [
    ' (Click to sort Ascending)',
    ' (Click to sort Descending)',
    ' (Click to clear sorting)',
    ' (Click to sort ascending)',
    ' (Click to sort descending)'
  ];

  let clone_dataTable = tools.genericFunctions.cloneObject(dataTable)
  let keys = [
    { key: "Id", action: 'remove' },
    { key: "Azione", action: 'remove' },
    { key: "Azioni", action: 'remove' },
    { key: "Stato", action: 'remove' },
    { key: " (Click to clear sorting)", action: 'remove' },
    { key: "Azioni (Click to clear sorting)", action: 'remove' },
    


    { key: "Nome file (Click to sort Ascending)", substringToReclaim: ' (Click to sort Ascending)', action: 'clean' },
    { key: "Caricato il (Click to sort Ascending)", substringToReclaim: ' (Click to sort Ascending)', action: 'clean' },
    { key: "Tipologia (Click to sort Ascending)", substringToReclaim: ' (Click to sort Ascending)', action: 'clean' },
    { key: "Caricato da (Click to sort Ascending)", substringToReclaim: ' (Click to sort Ascending)', action: 'clean' },
    { key: "Estremi (Click to sort Ascending)", substringToReclaim: ' (Click to sort Ascending)', action: 'clean' },
    { key: "Dimensioni (Click to sort Ascending)", substringToReclaim: ' (Click to sort Ascending)', action: 'clean' },
    { key: "Protocollato (Click to clear sorting)", substringToReclaim: ' (Click to clear sorting)', action: 'clean' },
    { key: "Data Protocollo (Click to clear sorting)", substringToReclaim: ' (Click to clear sorting)',action: 'clean' },
    { key: "Id Protocollo (Click to clear sorting)", substringToReclaim: ' (Click to clear sorting)',action: 'clean' },
    // GRADUATORIA
    { key: "Id Progetto (Click to clear sorting)", substringToReclaim: ' (Click to clear sorting)',action: 'clean' },
    { key: "Titolo Progetto (Click to clear sorting)", substringToReclaim: ' (Click to clear sorting)',action: 'clean' },
    { key: "Punteggio (Click to clear sorting)", substringToReclaim: ' (Click to clear sorting)',action: 'clean' },
    { key: "Beneficiario (Click to clear sorting)", substringToReclaim: ' (Click to clear sorting)',action: 'clean' },
    { key: "Ammissione (Click to clear sorting)", substringToReclaim: ' (Click to clear sorting)',action: 'clean' },
    { key: "Ammesso il (Click to sort Ascending)", substringToReclaim: ' (Click to sort Ascending)',action: 'clean' }
  ]
  
  clone_dataTable.columns.forEach((column, index) => {
    for(const string of strToRemove) {

      const comparestr = string.toLowerCase();
      const columnLower = column.toLowerCase();

      if(columnLower.includes(comparestr)) {
        clone_dataTable.columns[index] = clone_dataTable.columns[index].replace(string, '');
      }
    }
  });

  keys.forEach(element => {
    let key = element.key
    let action = element.action
    // let substringToReclaim = element.substringToReclaim
    let index = clone_dataTable.columns.indexOf(key)
    if (index !== -1) {
      if(action === 'remove') {
        clone_dataTable.columns.splice(index, 1)
        clone_dataTable.data.forEach((el) => { el.splice(index, 1) })
      }
      // if(action === 'clean' && clone_dataTable.columns[index].includes(substringToReclaim))
      //   clone_dataTable.columns[index] = clone_dataTable.columns[index].replace(substringToReclaim,'')
    }
  })
  return clone_dataTable
}

function dateInPdf() {
  let today = new Date()
  let dd = today.getDate()

  let mm = today.getMonth() + 1 
  let yyyy = today.getFullYear()
  if(dd < 10)
    dd='0'+dd
  if(mm < 10) 
    mm = '0' + mm 
  today = dd + '-' + mm + '-' + yyyy
  return today
}

function takePartialString(x) {
  let white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
}

// Scomposizione di una stringa in n blocchi di caratteri (NON TAGLIA LE PAROLE!)
function wordWrap(str, maxCharacters) {
  let lineSeparator = "/foo/";
  let res = '';
  if(!str || str.length === 0)
    return res;
  while (str.length > maxCharacters) {                 
    let found = false;
    // Inserisce un separatore di linea ad ogni blocco
    for (let i = maxCharacters - 1; i >= 0; i--) {
      if (takePartialString(str.charAt(i))) {
          res = res + [str.slice(0, i), lineSeparator].join('');
          str = str.slice(i + 1);
          found = true;
          break;
      }
    }
    // Inserisce un separatore di linea alla maxWidth position, poichè la stringa è troppo lunga per andare a capo
    if (!found) {
      res += [str.slice(0, maxCharacters), lineSeparator].join('');
      str = str.slice(maxCharacters);
    }
  }
  return res + str;
}

function handleNamingPdf(scheda, type) {
  let concatenationTitle = []
  let concatenationNaming = []
  let namePdf;
  let beneficiario;

  switch(type) {
    case 'Bando':
      concatenationTitle.push({ text: 'Id Bando: ' + scheda.bando.idBando, fSize: titleFontSize })
      concatenationTitle.push({ text: 'Scheda: ' + type, fSize: subtitleFontSize })
      // concatenationTitle.push({text: 'Beneficiario: ' + scheda.progetto.beneficiario.beneficiario.denominazione, fSize: beneficiarioFontSize})

      concatenationNaming.push(type)
      concatenationNaming.push(scheda.bando.idBando)
      concatenationNaming.push(dateInPdf())
      namePdf = concatenationNaming.join('_')
      break;
    case 'Progetto':
      concatenationTitle.push({ text: 'Id Progetto: ' + scheda.progetto.idProgetto, fSize: titleFontSize })
      if(scheda.progetto.codiceProgetto)
        concatenationTitle.push({ text: 'Codice Progetto: ' + scheda.progetto.codiceProgetto, fSize: titleFontSize })
      concatenationTitle.push({ text: 'Scheda: ' + type, fSize: subtitleFontSize })
      //concatenationTitle.push({text: 'Beneficiario: ' + scheda.progetto.beneficiario.beneficiario.denominazione, fSize: beneficiarioFontSize})
      beneficiario = wordWrap(scheda.progetto.beneficiario.beneficiario.denominazione, 75).split("/foo/")
      beneficiario.forEach((row, index_word) => {
        if(index_word === 0 ) concatenationTitle.push({text: 'Beneficiario: ' + row, fSize: beneficiarioFontSize})
        else concatenationTitle.push({text: row, fSize: beneficiarioFontSize})
      })
      
      concatenationNaming.push(type)
      if(scheda.progetto.codiceProgetto)
        concatenationNaming.push(scheda.progetto.codiceProgetto.replace(/\./g,'-'));
      else
        concatenationNaming.push(scheda.progetto.idProgetto);
      concatenationNaming.push(dateInPdf())
      namePdf = concatenationNaming.join('_')
      break;
    case 'Convenzione':
      concatenationTitle.push({ text: 'Id Progetto: ' + scheda.progetto.idProgetto, fSize: titleFontSize })
      concatenationTitle.push({ text: 'Scheda: ' + type, fSize: subtitleFontSize })
      // concatenationTitle.push({text: 'Beneficiario: ' + scheda.progetto.beneficiario.beneficiario.denominazione, fSize: beneficiarioFontSize})

      concatenationNaming.push(type)
      concatenationNaming.push(scheda.progetto.idProgetto)
      concatenationNaming.push(dateInPdf())
      namePdf = concatenationNaming.join('_')
      break;
    case 'ExecutingBody':
        concatenationTitle.push({ text: 'Intervento a Titolarità', fSize: subtitleFontSize })
        concatenationNaming.push(type)
        concatenationNaming.push(dateInPdf())
        namePdf = concatenationNaming.join('_')
        break;
    case 'Graduatoria':
      //concatenationTitle.push({ text: 'Id Bando: ' + scheda.progetto.idProgetto, fSzize: titleFontSize })
      concatenationTitle.push({ text: 'Scheda: ' + type, fSize: subtitleFontSize })
      // concatenationTitle.push({text: 'Beneficiario: ' + scheda.progetto.beneficiario.beneficiario.denominazione, fSize: beneficiarioFontSize})

      concatenationNaming.push(type)
      concatenationNaming.push(dateInPdf())
      namePdf = concatenationNaming.join('_')
      break;
    case 'Monitoraggio':
      beneficiario = scheda.progetto.beneficiario ? scheda.progetto.beneficiario.beneficiario.denominazione : '';
      concatenationTitle.push({text: 'Codice Progetto: ' + scheda.progetto.codiceProgetto, fSize: titleFontSize});
      concatenationTitle.push({text: 'Scheda: ' + type, fSize: subtitleFontSize});
      concatenationTitle.push({text: 'Periodo: ' + scheda.trimestreRiferimento, fSize: subtitleFontSize});
      beneficiario = wordWrap(beneficiario, 75).split("/foo/")
      beneficiario.forEach((row, index_word) => {
        if(index_word === 0 ) concatenationTitle.push({text: 'Beneficiario: ' + row, fSize: beneficiarioFontSize})
        else concatenationTitle.push({text: row, fSize: beneficiarioFontSize})
      })

      concatenationNaming.push(type)
      concatenationNaming.push(scheda.progetto.codiceProgetto.replace(/\./g,'-'))
      concatenationNaming.push(dateInPdf())
      namePdf = concatenationNaming.join('_')
      break;
    case 'Istruttoria':
      concatenationTitle.push({text: 'Id Istruttoria: ' + scheda.idSchedaIstruttoria, fSize: titleFontSize});
      concatenationTitle.push({text: 'Scheda: ' + type, fSize: subtitleFontSize});

      concatenationNaming.push(type)
      concatenationNaming.push(scheda.idSchedaIstruttoria.replace(/\./g,'-'))
      concatenationNaming.push(dateInPdf())
      namePdf = concatenationNaming.join('_')
      break;
    case 'Scheda: Controllo':
      concatenationTitle.push({text: 'Codice Progetto: ' + scheda.infoProgetto.codiceProgetto, fSize: titleFontSize});
      concatenationTitle.push({text: type + ' ' + scheda.tipoControllo, fSize: subtitleFontSize});
      concatenationTitle.push({text: 'Tipo: ' + scheda.sottoTipoControllo, fSize: subtitleFontSize});

      concatenationNaming.push(type + scheda.tipoControllo)
      concatenationNaming.push(scheda.sottoTipoControllo)
      concatenationNaming.push(scheda.infoProgetto.codiceProgetto.replace(/\./g,'-'))
      concatenationNaming.push(scheda.infoProgetto.idProgetto)
      concatenationNaming.push(dateInPdf())
      namePdf = concatenationNaming.join('_')
      break;
    case 'Interim Assessment':
    case 'Final Assessment':
      beneficiario = scheda.beneficiario ? scheda.beneficiario.denominazione : '';
      concatenationTitle.push({text: 'Codice Progetto: ' + scheda.codiceProgetto, fSize: titleFontSize});
      concatenationTitle.push({text: 'Scheda: ' + type, fSize: subtitleFontSize});
      concatenationTitle.push({text: 'Anno: ' + scheda.annoFinanziario, fSize: titleFontSize});

      beneficiario = wordWrap(beneficiario, 75).split("/foo/")
      beneficiario.forEach((row, index_word) => {
        if(index_word === 0 ) concatenationTitle.push({text: 'Beneficiario: ' + row, fSize: beneficiarioFontSize})
        else concatenationTitle.push({text: row, fSize: beneficiarioFontSize})
      })

      concatenationNaming.push(type)
      concatenationNaming.push(scheda.codiceProgetto.replace(/\./g,'-'))
      concatenationNaming.push(scheda.idProgetto)
      concatenationNaming.push(dateInPdf())
      namePdf = concatenationNaming.join('_')
      break;
    case 'Tranche':
    case 'Prefinanziamento':
      concatenationTitle.push({text: 'Codice Progetto: ' + scheda.codiceProgetto, fSize: titleFontSize});
      concatenationTitle.push({text: 'Scheda: ' + type, fSize: subtitleFontSize});

      concatenationNaming.push(type)
      concatenationNaming.push(scheda.codiceProgetto.replace(/\./g,'-'))
      concatenationNaming.push(scheda.idProgetto)
      concatenationNaming.push(dateInPdf())
      namePdf = concatenationNaming.join('_')
      break;
    case 'Irregolarità':
      concatenationTitle.push({text: 'Codice Progetto: ' + scheda.codiceProgetto, fSize: titleFontSize});
      concatenationTitle.push({text: 'Scheda: ' + type, fSize: subtitleFontSize});

      concatenationNaming.push(type)
      concatenationNaming.push(scheda.codiceProgetto.replace(/\./g,'-'))
      concatenationNaming.push(scheda.idSchedaControllo)
      concatenationNaming.push(dateInPdf())
      namePdf = concatenationNaming.join('_')
      break;
      case 'Disposizioni di Pagamento':
        concatenationTitle.push({text: 'Codice Progetto: ' + scheda.codiceProgetto, fSize: titleFontSize});
        concatenationTitle.push({text: 'Scheda: ' + type, fSize: subtitleFontSize});
  
        concatenationNaming.push(type)
        concatenationNaming.push(scheda.codiceProgetto.replace(/\./g,'-'))
        
        concatenationNaming.push(dateInPdf())
        namePdf = concatenationNaming.join('_')
        break;
      default:
      namePdf = type
  }
  return { namePdf: namePdf, concatenationTitle: concatenationTitle }
}

function centerText(pdf, text) {
  return (pdf.internal.pageSize.width / 2) -
                (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / 2);
}

function savingFilePdf(optPdf, conf_export_pdf) {
  let pdf = new jsPDF('p', 'pt', 'A4')
  if(optPdf.namePdf.split('_').length < 2) {
    pdf.setFontSize(20)
    pdf.text('Non è stato possibile generare alcun Pdf.', 10, 60)
    pdf.save(optPdf.namePdf)
    return true
  }

  Object.keys(conf_export_pdf).forEach((key, indexKey) => {
    let id_table_pdf = conf_export_pdf[key].id_table_pdf
    let titleTab = conf_export_pdf[key].title_page_pdf
    let nameCollapse = conf_export_pdf[key].nameCollapse
    let infoByTable
    if(document.getElementById(id_table_pdf) != null) {
      console.log(document.getElementById(id_table_pdf))
      infoByTable = pdf.autoTableHtmlToJson(document.getElementById(id_table_pdf))

      // GESTIONE CANCELLAZIONE ROW IN TABELLA (BUG #10623)
      let style_row_deleted = []
      if(infoByTable && infoByTable.data && infoByTable.data.length > 0) {
        infoByTable.data.forEach((infoData, indexRow) => {
          // Per essere registrata nel pdf la cancellazione deve settare uno style in row di tipo 'table-secondary'
          if(infoData._element && infoData._element._prevClass && infoData._element._prevClass.includes('table-secondary')) {
            style_row_deleted.push(indexRow) 
          }
        });
      }

      let info = keysToReclaim(infoByTable)
      if(indexKey === 0) {
        
        for(let i = 0; i < optPdf.concatenationTitle.length; i++) {
          const item = optPdf.concatenationTitle[i];
          if(item.fSize) {
            pdf.setFontSize(item.fSize)
          }
          pdf.text('\n' + item.text, centerText(pdf, item.text), i * (rowHeight + vSpacing) + vSpacing);
          
        }
      }
      pdf.addPage()

      // PER TAB BUDGET --> VISUALIZZAZIONE DEL TOTALE PER OGNI VOCE DI COSTO NEL PDF
      let id_total_cost_budget_pdf = conf_export_pdf[key].id_total_cost_budget_pdf
      if(document.getElementById(id_total_cost_budget_pdf) != null) {
        let contentDiv = document.getElementById(id_total_cost_budget_pdf).innerHTML;
        let xOffset = (pdf.internal.pageSize.width / 2) - (pdf.getStringUnitWidth(contentDiv) * pdf.internal.getFontSize() / 2); 
        pdf.setFontSize(collapseNameSize)
        pdf.text('\n' + contentDiv, xOffset * 1.85, collapseTopMargin);
      }

      pdf = printPagePdf(pdf, titleTab, nameCollapse, info.columns, {data: info.data, style_row_deleted: style_row_deleted})
    }
    const headerDetail1 = ['Campo', 'Valore'];
    // ------------------------------ GESTIONE DOPPI OCCHI ----------------------
    // (PRINT DI DUE TABELLE: UNA PER DETTAGLIO FASE E L'ALTRA PER DETTAGLIO SPESE)
    if(conf_export_pdf[key].double_details) {
      // CASISTICA CUSTOM PER CRONOPROGRAMMA ( DI PROGETTO E DI MONITORAGGIO )
      let source = conf_export_pdf[key].source
      let details_doubleTables = createDoubleDetails(
        source.ids_dettaglio_fase,
        source.scheda,
        conf_export_pdf[key],
        source.mappa
      )
      if(details_doubleTables && details_doubleTables.length > 0) {
        
        const headerDetail2 = ['Spesa', 'Dal', 'Al']
        pdf = printDetails2TableInPdf(pdf, titleTab, nameCollapse, details_doubleTables, headerDetail1, headerDetail2)
      }
    }

    // ------------------------------ GESTIONE OCCHI (DATI DA CRONOPROG) ------------------------------
    // if(conf_export_pdf[key].details) {
    // BUG #15509 la riga di codice precedente è stata arricchita con quella che segue
    // per gestire la visibilità del dettaglio dei pagamenti nelle schede Final / Interim Assessment
    if(conf_export_pdf[key].details && id_table_pdf && document.getElementById(id_table_pdf) != null) {
      let source = conf_export_pdf[key].source
      let detailsElements = createDetailsCronoprog(
        source.idScheda,
        source.ids_eyes,
        source.mappa,
        source.scheda
      )
      printDetailsInPdf(pdf, titleTab, nameCollapse, detailsElements, headerDetail1);
    }
    // ---------------- GESTIONE OCCHI DISPOSIZIONI DI PAGAMENTO ----------------
    if(conf_export_pdf[key].details_disposizioni) {
      let sources = conf_export_pdf[key].sources
      let detailsElements = []
      sources.forEach((source, index_source) => {
        detailsElements.push(createDetailsDisposizioni(source))
        if(detailsElements.length > 0 && index_source === sources.length - 1) {
          pdf = printDetailsInPdf(pdf, titleTab, nameCollapse, detailsElements, headerDetail1);
        }
      })
    }
    // ---------------- GESTIONE OCCHI INDICATORI ----------------
    if(conf_export_pdf[key].detailsIndicatori) {
      let source = conf_export_pdf[key].source
      let detailsElements = createDetailsIndicatori(
        source.ids_eyes,
        source.mappa,
        source.scheda
      )
      printDetailsInPdf(pdf, titleTab, nameCollapse, detailsElements, headerDetail1);
    }
  
  })
  
  
  // ------ GESTIONE Elementi che hanno RadioButton e / o TextArea (TARB) ---------
  const elementsTARB = Object.keys(conf_export_pdf).filter(k =>
    conf_export_pdf[k].conf_field)
  if(elementsTARB.length > 0) {
    elementsTARB.forEach(key => {
      let idField = conf_export_pdf[key].id_field
      let source = conf_export_pdf[key].source.scheda
      let descRadio = conf_export_pdf[key].descRadio
      let titlePage = conf_export_pdf[key].title_page_pdf
      let conf_field = conf_export_pdf[key].conf_field
      const results = jpath('$' + idField, source)
      let textValue = 'N/D'
      
      if(results && results.length === 1) {
        let valueRadio = results[0] === null ? textValue : results[0]
        if(conf_field.mapping) { // RADIO BUTTON ( Ho bisogno di un Mapping )
          if(valueRadio === false || valueRadio === 'false') textValue = conf_field.mapping === 'SiNo' ? 'No' : 'Negativo'
        if(valueRadio === true || valueRadio === 'true') textValue = conf_field.mapping === 'SiNo' ? 'Si' : 'Positivo'
        } else textValue = valueRadio
      }

      if(conf_field.addPage) { // Se la configurazione prevede l'addPage, aggiungo una pagina
        pdf.addPage()
        pdf.setFontSize(20)
        pdf.text(titlePage, centerText(pdf, titlePage), 20)
      }
        
      if(conf_field.mapping) { // CASISTICA RADIO BUTTON
        pdf.setFontSize(15)
        pdf.text(descRadio + textValue, centerText(pdf, descRadio + textValue), 100);
      } else { // CASISTICA TEXTAREA
        pdf.setFontSize(15)
        let splitTitle = pdf.splitTextToSize(descRadio + '\n' + textValue, 500)
        pdf = noWrapText(pdf, splitTitle)
      }
    })
  }
  pdf = insertNumbPage(pdf);
  pdf.save(optPdf.namePdf)
}

function insertNumbPage(pdf) {
  const pageCount = pdf.internal.getNumberOfPages();
  for(let i = 1; i <= pageCount; i++) { 
    pdf.setPage(i); 
    pdf.setFontSize(15);
    const pageSize = pdf.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const footer = `Pagina ${i} / ${pageCount}`;
    pdf.text(footer, pageWidth / 2 - (pdf.getTextWidth(footer) / 2), pageHeight - 30, { baseline: 'bottom' });
  }
  return pdf;
}

function noWrapText(doc, splitTitle) {
  let y = 150
  for (let i = 0; i < splitTitle.length; i++) {                
    if (y > 800) {
      y = 50;
      doc.addPage()
    }
    doc.text(30, y, splitTitle[i], {maxWidth: 500, align: "justify"})
    // Spaziatura tra le righe
    y = y + 20;
  }
  return doc
}

function printDetailsInPdf(pdf, titleTab, nameCollapse, detailsElements, headerDetail) {
  detailsElements.forEach((element, indexEye) => {
    let infoRowEyes = []
    indexEye = indexEye + 1
    element.data.forEach((el, indexFieldEye) => {
      let info = []
      info = [
        el.Campo,
        el.Valore !== '' ? el.Valore : ' '
      ] 
      infoRowEyes.push(info)
      if(indexFieldEye === element.data.length -1) {
        let titleDetail = 'Dettagli ' + titleTab + ' (' + indexEye + '/' + detailsElements.length + ')'
        pdf.addPage()
        pdf = printPagePdf(pdf, titleDetail, nameCollapse, headerDetail, {data: infoRowEyes, style_row_deleted: null})
      }
    })
  })
  return pdf
}

function printDetails2TableInPdf(pdf, titleTab, nameCollapse, detailsElements, headerDetail_1, headerDetail_2) {
  detailsElements.forEach((element, indexEye) => {
    let infoRowEyesTable1 = []
    
    let infoTable1 = element.data
    let infoTable2 = element.detailsSpese
    indexEye = indexEye + 1
    infoTable1.forEach(el => {
      const rowInTable1 = [
        el.Campo,
        el.Valore !== '' ? el.Valore : ' '
      ] 
      infoRowEyesTable1.push(rowInTable1)
    })
    // inserisce il dettaglio fase
    let titleDetail = 'Dettagli ' + titleTab + ' (' + indexEye + '/' + detailsElements.length + ')'
    pdf.addPage()
    pdf = printPagePdf(pdf, titleDetail, nameCollapse, headerDetail_1, {data: infoRowEyesTable1, style_row_deleted: null});
    // inserisce il dettaglio spese
    const padY = (infoRowEyesTable1.length + 1) * rowHeight;
    pdf = printPagePdf(pdf, 'Dettaglio Spese', nameCollapse, headerDetail_2, {data: infoTable2, style_row_deleted: null}, padY)
  })
  return pdf
}

// Printing della tabella "Dettaglio delle spese" di Cronoprogramma
function detailsSpeseMonitoraggio(indexes, modalData, scheda, mappa, conf_export_pdf) {
  // Scompatto il realId (che è l'id che tiene conto degli indici in array)
  // poi cambierò l'ultima parte di questo con gli id relativi al dettaglio delle spese:
  // 1) ['spese'][*]['spesa']
  // 2) [*]['spese'][*]['da']
  // 3)['spese'][*]['a']
  if(!conf_export_pdf || !conf_export_pdf.source || !conf_export_pdf.source.ids_dettaglio_spese) {
    console.error('detailsSpeseMonitoraggio bad params', conf_export_pdf);
    return modalData;
  }
  let basePath = conf_export_pdf.source.ids_dettaglio_spese[0];
  if(!basePath) {
    console.error('detailsSpeseMonitoraggio bad ids_dettaglio_spese', conf_export_pdf);
    return modalData;
  }
  for(const index of indexes) {
    basePath = basePath.replace('[*]', index);
  }

  basePath = basePath.match(/^.*\W\*\W/)[0];

  const listaSpese = jpath({resultType: 'all'}, '$' + basePath, scheda);
  if(!listaSpese || listaSpese.length === 0) {
    return modalData;
  }
  const speseLength = listaSpese.length;
  for(let i = 0; i < speseLength; i++) {
    let row = [];
    let totalIndexes = indexes.concat('[' + i + ']');
    let deleted = false;
    for(const starsId of conf_export_pdf.source.ids_dettaglio_spese) {
      let id = starsId
      for(const index of totalIndexes) {
        id = id.replace('[*]', index);
      }

      let checkDeleted = scheda.dataEntryConfiguration ?
        mapUtils.getMapElement(scheda.dataEntryConfiguration, id) :
        null
      if(checkDeleted && checkDeleted.data && checkDeleted.data.deleted) {
        deleted = true;
        break;
      }
      const values = jpath('$' + id, scheda);
      if(values && values.length === 1) {
        const mapElement = mapUtils.getMapElement(mappa, id);
        const formatted = utils.formatOutput(mapElement, values[0]);
        row.push(formatted);
      }
    }

    if(!deleted)
      modalData.detailsSpese.push(row);
  }

  return modalData
}

function createDoubleDetails(ids, scheda, conf_export_pdf, tabellone) {
  const mappa = tabellone
  // primo loop per trovare la massima profondità di array
  let deepestIndexId = '';
  let maxDepth = 0;
  // l'elemento di array più profondo deve essere obbligatorio, in modo da essere certi che
  // sia presente nella scheda e il successivo jpath trovi effettivamente gli indici massimi
  // di ciascuno degli array presenti nell'elemento con la massima profondità di array
  for(const id of ids) {
    const starsArray = id.match(/\*/g);
    if(starsArray && starsArray.length > maxDepth) {
      const conf = mapUtils.getMapElement(mappa, id);
      if(conf && conf.config.mandatory) {
        maxDepth = starsArray.length;
        deepestIndexId = id;
      }
    }
  }
  // primo jpath per stabilire gli indici massimi per ciascun array
  const jpathForIndexes = jpath({resultType: 'all'}, '$' + deepestIndexId, scheda)
  if(!jpathForIndexes || jpathForIndexes.length === 0)
    return null;

  // indexesArray conterrà, per ogni riga di cui fare l'export,
  // tutti gli indici di array corrispondenti
  const indexesArray = [];
  for(const item of jpathForIndexes) {
    const path = item.path;
    const matchArray = path.match(/\[\d+\]/g);
    if(matchArray) {
      indexesArray.push(matchArray);
    }
  }

  // per ciascuna riga, sostituisco gli indici nell'id di ogni elemento
  // con questo realId eseguo jpath per recuperare il valore
  const retVal = [];
  for(const indexes of indexesArray) {
    let modalData = {
      data: [],
      detailsSpese: [] // Questo verrà popolato solo nel caso di Cronoprogramma (Monitoraggio e Progetto)
    }
    let deleted = false
    let realId
    for(const id of ids) {
      const mapElement = mapUtils.getMapElement(mappa, id);
      let row = {
        Campo: mapElement.config.label,
        Valore: ''
      };
      realId = id;
      for(const index of indexes) {
        realId = realId.replace('[*]', index);
      }
      
      // Uniformando il calcolo dei dettagli per Monitoraggio e Progetto si deve tenere conto che:
      // in Monitoraggio: "data" sta in dataEntryConfiguration (mappa)
      // in Progetto: "data" sta in scheda
      let checkDeleted;
      if(conf_export_pdf.isProgetto && scheda.dataEntryConfiguration) {
        checkDeleted = mapUtils.getMapElement(scheda.dataEntryConfiguration, realId)
      } else {
        checkDeleted = mapUtils.getMapElement(mappa, realId)
      }
        
        

      if(checkDeleted && checkDeleted.data && checkDeleted.data.deleted) {
        deleted = true
        continue;
      }
      let value
      if(mapElement.config.calculationRule) {
        value = calculations.calculateValue(mapElement, scheda, realId);
      } else {
        const values = jpath('$' + realId, scheda);
        if(values && values.length === 1) {
          value = values[0];
        }
      }

      const formatted = utils.formatOutput(mapElement, value);
      row.Valore = formatted;
      modalData.data.push(row);
    }
    if(conf_export_pdf.double_details)
    //detailsSpeseMonitoraggio(realId, indexes, modalData, scheda, mappa, conf_export_pdf)
      modalData = detailsSpeseMonitoraggio(indexes, modalData, scheda, mappa, conf_export_pdf)

    if(!deleted)
      retVal.push(modalData);
  }
  return retVal;
}


function createDetailsIndicatori(dataComponent, mappa, scheda) {
  if(!dataComponent || !mappa || !scheda) {
    console.error('createDetailsProgetto bad params', dataComponent, mappa, scheda);
    return null;
  }
  const ids = dataComponent.dinamicIds;
  let retVal = [];

  const firstId = ids[0];

  const checkIndexes = jpath({resultType: 'all'}, '$' + firstId, scheda);

  if(!checkIndexes || !checkIndexes.length ) {
    console.log('createDetailsIndicatori: nessun indicatore per occhio pdf');
    return retVal;
  }

  const indexesToShow = [];
  const conf = scheda.dataEntryConfiguration;

  
  for(const entry of checkIndexes) {
    const entryId = entry.path.substr(1);
    if(conf && conf[entryId] && conf[entryId].data && conf[entryId].data.deleted) {
      continue;
    }

    const matches = entryId.match(mapUtils.lastIndexRegex);
    if(matches && matches.length)
      indexesToShow.push(matches[0]);
  }
  

  // per tutte le entry trovate in una data sezione di cronoprog (ad. es pagamenti)
  for(const index of indexesToShow) {
    let modalData = {
      header : ['Campo', 'Valore'],
      data: []
    }
    // per tutti gli id della tabella da creare
    for(const id of ids) {
      const mapElement = mapUtils.getMapElement(mappa, id);

      if(!mapElement) {
        console.error('tableModalView no mapElement for', id);
        continue;
      }

      let row = {
        Campo: mapElement.config.label
      };

      const realId = id.replace('*', index);
      const results = jpath('$' + realId, scheda);
      if(results && results.length > 0) {
          const res = results[0];
          const formatted = utils.formatOutput(mapElement, res);
          row.Valore = formatted;
      }

      // aggiungo una nuova riga alla tabella
      modalData.data.push(row);

    }
    // aggiungo una nuova tabella "occhio"
    retVal.push(modalData);
  }
  return retVal;
}


function createDetailsCronoprog(idControllo, idsObj, mappa, scheda) {
  if(!idsObj || !mappa || !scheda) {
    console.error('tableModalView bad params', idsObj, mappa, scheda);
    return null;
  }
  const ids = idsObj.dinamicIds;

  let retVal = [];
  const rootIdSignature = "'" + idsObj.rootId + "'";

  // per tutte le entry trovate in una data sezione di cronoprog (ad. es pagamenti)
  for(const item of Object.values(scheda[idsObj.rootId])) {
    const alfaId = item.id;

    let modalData = {
      header : ['Campo', 'Valore'],
      data: []
    }
    // per tutti gli id della tabella da creare
    for(const id of ids) {
      const mapElement = mapUtils.getMapElement(mappa, id);

      if(!mapElement) {
        console.error('tableModalView no mapElement for', id);
        continue;
      }

      let row = {
        Campo: mapElement.config.label
      };

      // in questo caso il campo non è un relative, 
      // quindi cerco il suo valore direttamente nella scheda
      if(id.includes(rootIdSignature)) {
        const realId = id.replace('*', "'" + alfaId + "'");
        const results = jpath('$' + realId, scheda);
        if(results && results.length > 0) {
            const res = results[0];
            // nel caso in cui sia di tipo flag, se il valore del flag coincide
            // con l'id della dichiarazione di spesa corrente, allora il pagamento 
            // è riferito a questa dichiarazione e verrà visualizzata la stringa 'SI'
            if(mapElement.config.type === 'flag') {
                row.Valore = 'NO';
                if(res === idControllo) {
                  row.Valore = 'SI';
                }
            }
            else {
              // altrimenti è un valore normale da formattare
              const formatted = utils.formatOutput(mapElement, results[0]);
              row.Valore = formatted;
            }
        }
      }
      else {
        // in questo caso bisogna cercare nei relatives
        // qui prendo l'identificativo dalla mappa di relatives
        const relMap = scheda[idsObj.rootId][alfaId].rel;
        // da colId isolo la radice del relative
        // ad es., per la colonna idAttivita è "attivita"
        const relRootId = id.match(/\w+/)[0];
        // se nella mappa di relatives c'è questa radice, allora prendo
        // per convenzione il primo indice (zero) dell'array
        const relArray = relMap[relRootId];
        let realId1;
        if(relArray && relArray.length > 0) {
          const relArrayId = relArray[0];
          // l'id da cui cercare il valore si ottiene sostituendo nel colId
          // l'identificativo pescato dai relatives
          realId1 = id.replace('*', "'" + relArrayId + "'");
        } else {
          // se non trovo un "papà", passo avanti
          console.log('tableModalView no relative for colId', id, relRootId, relArray);
          continue;
        }
        // se il valore non è presente in scheda, allora potrebbe essere 
        // un relative non obbligatorio (es. pagamento sfuso):
        // in questo caso inserisco la label di default
        if(realId1.includes(mapUtils.nonDefinito.key)) {
          row.Valore = mapUtils.nonDefinito.value;
        } else {
          // altrimenti prendo il valore del relativa dal cronoprog, 
          // correttamente formattato
          const results = jpath('$' + realId1, scheda);
          if(results && results.length === 1) {
            const formatted = utils.formatOutput(mapElement, results[0]);
            row.Valore = formatted;
          }
        }
      }
      // aggiungo una nuova riga alla tabella
      modalData.data.push(row);

    }
    // aggiungo una nuova tabella "occhio"
    retVal.push(modalData);

  }
  return retVal;
}

function createDetailsDisposizioni(source) {
  // Queste sources contengono tanti oggetti quanti sono le rows della tabella di disposizioni
  let modalData = {
    data: []
  }
  const ids = source.ids_eyes
  const scheda = source.scheda
  const mappa = source.mappa.tabellone
  ids.forEach((id, index) => {
    const mapElement = mapUtils.getMapElement(mappa, id)
    let row_pdf = {
      Campo: mapElement.config.label
    }
    const results = jpath('$' + id, scheda);
    if(results && results.length === 1) {
      let formatted = utils.formatOutput(mapElement, results[0])
      // BLOCCO IF COMMENTATO PER BUG #13110
      // if(formatted !== null) {
      //   // BUG #10570 --> Per l'exportPdf nei dettagli della lista
      //   // per il campo Fatture (id: ['content']['disposizione']['pagamenti'])
      //   // è stato concatenato l'importo
      //   if(id === "['content']['disposizione']['pagamenti']") {
      //     debugger
      //     let id_importo = "['content']['disposizione']['importo']"
      //     const mapElement_importo = mapUtils.getMapElement(mappa, id_importo)
      //     const results_importo = jpath('$' + id_importo, scheda)
      //     if(results_importo && results_importo.length === 1) {
      //       const importo_formatted = utils.formatOutput(mapElement_importo, results_importo[0])
      //       formatted = formatted + ' ' + importo_formatted
      //     }
      //   }
      // }
      row_pdf.Valore = formatted || "";
    }
    // aggiungo una nuova riga alla tabella
    modalData.data.push(row_pdf)
    if(index === ids.length -1) {
      return modalData
    }
  })
  return modalData
}

export default {
  printPagePdf,
  handleNamingPdf,
  savingFilePdf,
}
