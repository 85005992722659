<template>

    <div class="dashboard">
      
      <div class="dashboard-row">
          <div class="row-title">
              <fa-icon icon="bell" class="dashboard-icons"/>&nbsp;Ultima notifica
          </div>
          
          <b-overlay :show="!notifyLoaded" rounded="lg">
            <b-card v-if="notifyLoaded && lastNotification">
                <b-card-title>{{lastNotification.dataModifica | dateIT }}</b-card-title>
                <b-card-text>
                    {{formatLastNotification}}
                    <!-- {{ lastNotification.tipoScheda | firstUpperCase }} codice: {{ lastNotification.codiceProgetto }} (periodo: {{ lastNotification.periodo }}) in stato "{{ lastNotification.stato | noUnderscores | firstUpperCase }}". -->
                </b-card-text>
                <b-card-text class="text-muted">
                    Eseguito da: {{ lastNotification.modificatoDa }}
                </b-card-text>
            </b-card>

            <b-card v-else>
                <b-card-text>Nessuna notifica disponibile.</b-card-text>
            </b-card>
          </b-overlay>

          <div class="row-link">
            <router-link :to="{ name: 'notifiche'}">Tutte le notifiche <fa-icon icon="chevron-right"/></router-link>
          </div>
      </div>

      <div class="dashboard-row" v-if=isScadenze>
            <div class="row-title">
                <fa-icon :icon="['far', 'calendar-alt']" class="dashboard-icons"/>&nbsp;Prossima scadenza
            </div>
            <b-card v-if="prossimaScadenza && dataScadenzaFormatted">
                <b-card-title>
                    {{dataScadenzaFormatted}}
                </b-card-title>
                <b-card-text>
                    {{prossimaScadenza.codiceProgetto}} {{prossimaScadenza.tipologiaScheda}}
                </b-card-text>
                <b-card-text class="text-muted"  v-if="prossimaScadenza.descrizione">{{prossimaScadenza.descrizione}}</b-card-text>
                <b-card-text class="text-muted"  v-else>(Descrizione non disponibile)</b-card-text>
            </b-card>

            <b-card v-else>
                <b-card-text>Dati non disponibili.</b-card-text>
            </b-card>
          
            <div class="row-link">
                <router-link :to="{ name: 'scadenze'}">Tutte le scadenze <fa-icon icon="chevron-right"/></router-link>
            </div>
      </div>  

      <div class="dashboard-row" v-if=isDatisintesi>
        <div class="row-title">
            <fa-icon icon="wallet" class="dashboard-icons"/>&nbsp;Dati di sintesi
        </div>

        <b-container class="bv-example-row">

        <b-row>  
            <b-col>  
            <b-card title="Progetti Approvati">
                <b-card-text>
                    Progetti Approvati: {{progettiAttuazione.length}}
                        
                </b-card-text>
            </b-card>
            </b-col>
        </b-row>
        
        <b-row>
            <b-col>
                <b-card title="Importo Assegnato">
                    <b-card-text v-if="importoAssegnato >= 0">
                        Importo Assegnato: {{ importoAssegnato | numberIT }}
                    </b-card-text>
                    <b-card-text v-else>
                        Importo Assegnato: Dato non disponibile
                    </b-card-text>
                </b-card>
            </b-col>
            
            <b-col>
                <b-card title="Importo Erogato">
                    <b-card-text v-if="importoErogato >= 0">
                    Importo Erogato: {{ importoErogato | numberIT}}
                    </b-card-text>
                    <b-card-text v-else>
                        Importo Erogato: Dato non disponibile
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>


        </b-container>

        <div class="row-link">
            <router-link :to="{ name: 'dettaglioImporti'}">
                <a>Dettaglio dati di sintesi <fa-icon icon="chevron-right"/></a>
            </router-link>
        </div>
      </div>    

      <div class="dashboard-row">
          <div class="row-title">
            <fa-icon icon="clipboard-list" class="dashboard-icons"/>&nbsp;Totale attività <strong>({{ this.contaTotaleAttivita() }}) </strong>
          </div>

            <b-container v-if=isMonitoring>

                <b-row>                    
                    <b-col>
                    <b-card title="Monitoraggio" style="text-align: center;">
                        <b-row>                    
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'selettore'}" >Monitoraggio ({{monitoraggi}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="monStats.stats"
                                    @on-user-click="showClickedPieChartData"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'selettoreInterim'}" >Interim Assessment ({{interimAss}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="interimStats"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'selettoreFinal'}" >Final Assessment ({{finalAss}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="finalStats"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="this.statsDetailsTable.isToShow && this.statsDetailsTable.data && this.statsDetailsTable.data.length > 0">
                            <b-col cols="12">
                                <b-row v-if="this.statsDetailsTable.title !== ''">
                                    <b-col cols="11">
                                        <h3 id="statsTitle">Dettaglio schede monitoraggio "{{ this.statsDetailsTable.title | lowercase }}"</h3>
                                    </b-col>
                                    <b-col cols="1">
                                        <div class="align-right">
                                            <button variant="outline-secondary" size="sm" @click="toggleStatsDetailsTablePane">Chiudi</button>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-table hover 
                                        :items="this.statsDetailsTable.data"
                                        :fields="this.statsDetailsTable.columns"
                                    />    
                                </b-row>
                            </b-col>
                        </b-row>

                    </b-card>
                    </b-col>
                </b-row>
            </b-container>

            <div v-if=isDettaglioMonitoraggioVisible class="row-link">
                <router-link :to="{ name: 'dettaglioAttivita'}">
                    <a>Dettaglio attività Monitoraggio<fa-icon icon="chevron-right"/></a>
                </router-link>
            </div>

            <b-container v-if=isCall>

                <b-row>                    
                    <b-col>
                    <b-card title="Bandi" style="text-align: center;">
                        
                        <b-row>                    
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaBandi', params: {type: 'CALL_FOR_PROPOSAL'}}">Call for Proposal ({{this.myCFP.length}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.bcfpStats"
                                    @on-user-click="showClickedPieChartDataBandi"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaBandi', params: {type: 'ASSEGNAZIONE_DIRETTA'}}">Assegnazione diretta ({{this.myAD.length}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.badStats"
                                    @on-user-click="showClickedPieChartDataBandi"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            
                        </b-row>

                    </b-card>
                    </b-col>
                </b-row>
            </b-container>

            <!-- <div v-if=isCall class="row-link">
                <router-link :to="{ name: 'dettaglioAttivitaBandi'}">
                    <a>Dettaglio attività Bandi<fa-icon icon="chevron-right"/></a>
                </router-link>
            </div> -->
            
            <b-container v-if=isEB>

                <b-row>                    
                    <b-col>
                    <b-card title="Executing Body" style="text-align: center;">
                        
                        <b-row>                    
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaExecutingBody'}">Executing body ({{this.myEB.length}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.bebStats"
                                    @on-user-click="showClickedPieChartDataBandi"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                        </b-row>

                    </b-card>
                    </b-col>
                </b-row>
            </b-container>

            <!-- <div v-if=isEB class="row-link">
                <router-link :to="{ name: 'dettaglioAttivitaEB'}">
                    <a>Dettaglio attività Executing Body<fa-icon icon="chevron-right"/></a>
                </router-link>
            </div>     -->
            
            <b-container v-if=isProject>

                <b-row>                    
                    <b-col>
                    <b-card title="Schede Progetto" style="text-align: center;">
                        
                        <b-row>                    
                            <b-col cols="5">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaProgettiModifica'}">Proposte Progettuali ({{this.myProposte.length}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.ppStats"
                                    @on-user-click="showClickedPieChartDataProgetti"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            <b-col cols="5" v-if="isProgettiIstruttoria">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaProgettiIstruttoria'}">Ammissione ({{this.myAmmissione.length}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.pvStats"
                                    @on-user-click="showClickedPieChartDataProgetti"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            <b-col cols="5" v-if="isProgettiAmmessi">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaProgettiAmmessi'}">Convenzione ({{this.myConvenzione.length}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.pcStats"
                                    @on-user-click="showClickedPieChartDataProgetti"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            <b-col cols="5">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaProgetti'}">Progetti ({{this.myAttuazione.length}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.paStats"
                                    @on-user-click="showClickedPieChartDataProgetti"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                        </b-row>

                    </b-card>
                    </b-col>
                </b-row>
            </b-container>
            
            <!-- <div v-if=isProject class="row-link">
                <router-link :to="{ name: 'dettaglioAttivitaProgetti'}">
                    <a>Dettaglio attività Progetti<fa-icon icon="chevron-right"/></a>
                </router-link>
            </div> -->
            
            
            <b-container v-if=isControl>

                <b-row>                    
                    <b-col>
                    <b-card title="Controlli" style="text-align: center;">
                        
                        <b-row>                    
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaControlliAmm'}">Controlli Amministrativi ({{this.myControlliAmm}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.caStats"
                                    @on-user-click="showClickedPieChartDataControlli"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaControlliLoco'}">Controlli In Loco ({{this.myControlliLoco}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.cilStats"
                                    @on-user-click="showClickedPieChartDataControlli"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'progettiIrregolari'}">Irregolarità ({{this.myIrrego}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.irrStats"
                                    @on-user-click="showClickedPieChartDataControlli"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                        </b-row>

                    </b-card>
                    </b-col>
                </b-row>
            </b-container>
            
            <!-- <div v-if=isControl class="row-link">
                <router-link :to="{ name: 'dettaglioAttivitaControlli'}">
                    <a>Dettaglio attività Controlli<fa-icon icon="chevron-right"/></a>
                </router-link>
            </div> -->

            <b-container v-if=isPagamenti>

                <b-row>                    
                    <b-col>
                    <b-card title="Pagamenti" style="text-align: center;">
                        
                        <b-row>                    
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaProgettiPrefinanziamentoItinere'}">Prefinanziamento ({{this.myPrefinanziamento}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.prefStats"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaControlliAmm'}">Rendicontazioni di Spesa ({{this.myRendicontate}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.rendicontStats"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <b-card-text>
                                    <router-link :to="{ name: 'listaProgettiAnticipazione'}">Tranche - Saldo ({{this.myTranche}})</router-link>
                                    <br/>
                                </b-card-text>
                                <div>
                                    <PieChartJs :chartData="this.trancheStats"
                                    class="customCanvas"/>
                                </div>
                            </b-col>
                        </b-row>

                    </b-card>
                    </b-col>
                </b-row>
            </b-container>          

            <!-- <div v-if=isPagamenti class="row-link">
                <router-link :to="{ name: 'dettaglioAttivitaControlli'}">
                    <a>Dettaglio Circuito Finanziario<fa-icon icon="chevron-right"/></a>
                </router-link>
            </div> -->
            
      </div>  


    </div>
</template>


<script>
import tools from '@/helpers/tools.js'
import { mapGetters } from 'vuex'
import notify from "@/helpers/notifications.js"
import utils from '@/helpers/utils.js'
import PieChartJs from '@/components/vue-chartjs/piechart.vue'
import endpoints from '@/components/endpoints.vue';
import dashboardUtils from "@/helpers/dashboardUtils.js";

    export default {
        name: 'DashboardComponent',
        components: {
            PieChartJs,
        },

        computed: {
            ...mapGetters({
                allNotifications: 'storicoAzioni/getAll',
                notificationsMap: 'storicoAzioni/getMap',
                getScadenza: 'scadenziario/getNextScadenza',
                getAssProject: 'selettoreAss/getElencoProgetti',
                getBeneficiari: 'announcements/getAllBeneficiariMap'
                // getStats: 'monitoraggio/getStats',
                // getStatsDetails: 'monitoraggio/getStatsDetails',
            }),
            formatLastNotification() {
                const last = this.lastNotification;
                let result = last.tipoScheda.concat(' codice: ').concat(last.codiceProgetto);
                if(last.periodo === 'N/D')
                    result = result.concat(' (periodo: ').concat(last.periodo).concat(' )');
                result = result.concat('  in stato ').concat(last.stato);
                return result;
            },
            //Alcuni utenti non vedono la pagina di dettaglio
            //Task #10714
            isDettaglioMonitoraggioVisible(){
                return dashboardUtils.isMonitoring(this.aree)
                && !this.roles.includes(
                    "AutoritaResponsabile-AutoritaResponsabile",
                    "SegreteriaTecnicaAmministrativa-Direttore"
                )
            },

            isMonitoring(){
                return dashboardUtils.isMonitoring(this.aree);
            },

            isCall(){
                return dashboardUtils.isCall(this.aree);
            },

            isEB(){
                return dashboardUtils.isEB(this.aree);
            },
            
            isProject(){
                return dashboardUtils.isProject(this.aree);
            },

            isControl(){
                return dashboardUtils.isControl(this.aree);
            },

            isPagamenti(){
                return dashboardUtils.isPagamenti(this.aree);
            },

            isProgettiIstruttoria(){
                return dashboardUtils.isProgettiIstruttoria(this.aree);
            },

            isProgettiAmmessi(){
                return dashboardUtils.isProgettiAmmessi(this.aree);
            },

            isDatisintesi(){
                return dashboardUtils.isDatisintesi(this.aree);
            },

            isScadenze(){
                return dashboardUtils.isScadenze(this.aree);
            },

            dataScadenzaFormatted(){
                return this.dateFormatter(this.prossimaScadenza.dataScadenza)
            }

        },

        data() {
            return {
                currentProfile: {},
                prossimaScadenza: Date,
                roles: [],
                monStats: {},
                interimStats: [
                    { "categoryId": "REGOLARI", "count": 0, "label": "inserite" },
                    { "categoryId": "ASSENTI", "count": 0, "label": "da inserire" }],
                finalStats: [
                    { "categoryId": "REGOLARI", "count": 0, "label": "inserite" },
                    { "categoryId": "ASSENTI", "count": 0, "label": "da inserire" }
                ],
                scadenze: [],
                gruppo: null,
                monitoraggi: 0,
                approvati: 0,
                inviti: 0,
                interimAss: 0,
                finalAss: 0,
                istruttorie: 0,
                autocontrollo: 0,
                myCFP: [],
                myAD: [],
                myEB: [],
                bandiCFP: [],
                bcfpStats:[
                    {
                        categoryId: "BOZZA",
                        count: 0,
                        label: "In bozza"
                    },
                    {
                        categoryId: "IN_VALUTAZIONE",
                        count: 0,   
                        label: "In Valutazione"
                    },
                    {
                        categoryId: "APERTO",
                        count: 0,   
                        label: "Aperto"
                    },
                    {
                        categoryId: "SCADUTO",
                        count: 0,   
                        label: "Scaduto"
                    },
                    {
                        categoryId: "GRADUATORIA_APPROVATA",
                        count: 0,   
                        label: "Graduatoria approvata"
                    }
                    
                ],
                bandiAD: [],
                badStats:[
                    {
                        categoryId: "APERTO",
                        count: 0,
                        label: "Aperto"
                    },
                    {
                        categoryId: "IN_VALUTAZIONE",
                        count: 0,
                        label: "In Valutazione"
                    },
                    {
                        categoryId: "VALUTATO",
                        count: 0,
                        label: "Valutato"
                    },
                    {
                        categoryId: "BOZZA",
                        count: 0,
                        label: "In bozza"
                    }
                ],
                bandiEB:[],
                bebStats: [
                    
                    {
                        categoryId: "BOZZA",
                        count: 0,
                        label: "In bozza"
                    },
                    {
                        categoryId: "IN_VALUTAZIONE",
                        count: 0,
                        label: "In Valutazione"
                    },
                    {
                        categoryId: "AMMESSO",
                        count: 0,
                        label: "Ammesso"
                    },
                    {
                        categoryId: "NON_AMMESSO",
                        count: 0,
                        label: "Non Ammesso"
                    },
                    
                ],
                progettiProposte: [],
                ppStats: [
                    {
                        categoryId: "BOZZA",
                        count: 0,
                        label: "In bozza"
                    }, 
                    {
                        categoryId: "INVIATO",
                        count: 0,
                        label: "Inviato"
                    },
                    {
                        categoryId: "INVALIDO",
                        count: 0,
                        label: "Rinviato per modifiche"
                    }
                ],
                progettiValutati: [],
                pvStats:[
                    {
                        categoryId: "DA_PRENDERE_IN_CARICO",
                        count: 0,
                        label: "Da prendere in carico"
                    },
                    {
                        categoryId: "IN_VALUTAZIONE",
                        count: 0,
                        label: "In Istruttoria"
                    },
                    {
                        categoryId: "AMMESSO",
                        count: 0,
                        label: "Ammesso"
                    },
                    {
                        categoryId: "NON_AMMESSO",
                        count: 0,
                        label: "Non Ammesso"
                    }
                ],
                progettiConvenzione: [],
                pcStats:[
                    {
                        categoryId: "DECRETO_DA_FIRMARE",
                        count: 0,   
                        label: "Decreto da firmare"
                    },
                    {
                        categoryId: "GRADUATORIA_DA_FIRMARE",
                        count: 0,   
                        label: "Graduatoria da firmare"
                    },
                    {
                        categoryId: "CONVENZIONE_DA_FIRMARE",
                        count: 0,   
                        label: "Convenzione da firmare"
                    },
                    {
                        categoryId: "CONVENZIONE_FIRMATA",
                        count: 0,   
                        label: "Convenzione firmata"
                    }
                ],
                progettiAttuazione: [],
                progettiEsclusi: ["44.2.6", "84.2.5", "97.2.6", "120.2.4", "124.1.1", "95.2.4"], //Lista progetti definanziati esclusi dal contatore "Progetti approvati"
                paStats:[
                    {
                        categoryId: "ATTUAZIONE",
                        count: 0,
                        label: "In Attuazione"
                    },
                    {
                        categoryId: "RIMODULAZIONE",
                        count: 0,
                        label: "In Rimodulazione"
                    },
                    {
                        categoryId: "RINVIATO",
                        count: 0,
                        label: "Rinviato per rimodulazione"
                    },
                    {
                        categoryId: "CONCLUSO",
                        count: 0,
                        label: "Concluso"
                    },
                ],
                controlli:0,
                importoAssegnato: 0,
                importoErogato: 0,
                aree: [],
                statsDetailsTable: {
                    isToShow: false, // statsDetails <div> starts closed
                    title: "",
                    // columns setup. the line below should never change at runtime
                    columns: [
                        { key: "codiceProgetto", label: "Codice Progetto", sortable: true, sortDirection: 'asc' }, 
                        // titolo progetto
                        { key: "descrizioneBeneficiario", label: "Descrizione Beneficiario", sortable: true },
                        { key: "stato", label: "Stato" },
                        { key: "dataInvio", label: "Data Invio" },
                    ],
                    data: [],
                },
                lastNotification: null,     //proveniente da Monitoraggio o Assessment
                notifyLoaded: false,
                myProposte: [],
                myAmmissione: [],
                myConvenzione: [],
                myAttuazione: [],
                myControlliAmm: 0,
                myControlliLoco: 0,
                myIrrego: 0,
                controlliAmm: {},
                controlliLoco: {},
                irregol: {},
                caStats: [
                    {
                        categoryId: "BOZZA",
                        count: 0,
                        label: "In compilazione"
                    }, 
                    {
                        categoryId: "INVIATO",
                        count: 0,
                        label: "Inviato"
                    },
                    {
                        categoryId: "INVALIDO",
                        count: 0,
                        label: "Rinviato per integrazioni"
                    },
                    {
                        categoryId: "CONCLUSO",
                        count: 0,
                        label: "Esitato"
                    }
                ],
                cilStats: [
                    {
                        categoryId: "BOZZA",
                        count: 0,
                        label: "Avviato"
                    }, 
                    {
                        categoryId: "INVIATO",
                        count: 0,
                        label: "Controdeduzioni"
                    },
                    {
                        categoryId: "INVALIDO",
                        count: 0,
                        label: "Sospeso"
                    },
                    {
                        categoryId: "CONCLUSO",
                        count: 0,
                        label: "Esitato"
                    }
                ],
                irrStats: [
                    {
                        categoryId: "BOZZA",
                        count: 0,
                        label: "In verifica"
                    }, 
                    {
                        categoryId: "INVIATO",
                        count: 0,
                        label: "Notificata"
                    },
                    {
                        categoryId: "INVALIDO",
                        count: 0,
                        label: "Irregolarità accertata"
                    },
                    {
                        categoryId: "CONCLUSO",
                        count: 0,
                        label: "Irregolarità non accertata"
                    }
                ],
                myPrefinanziamento: 0,
                myRendicontate: 0,
                myTranche: 0,
                prefinanziamento: {},
                rendicontate: {},
                tranche: {},
                prefStats: [
                    {
                        categoryId: "BOZZA",
                        count: 0,
                        label: "Nuovo"
                    }, 
                    {
                        categoryId: "INVIATO",
                        count: 0,
                        label: "In Corso"
                    },
                    {
                        categoryId: "INVALIDO",
                        count: 0,
                        label: "Rinviato per integrazioni"
                    },
                    {
                        categoryId: "CONCLUSO",
                        count: 0,
                        label: "Evaso"
                    }
                ],
                rendicontStats: [
                    {
                        categoryId: "BOZZA",
                        count: 0,
                        label: "Nuova"
                    }, 
                    {
                        categoryId: "INVIATO",
                        count: 0,
                        label: "In Corso"
                    },
                    {
                        categoryId: "INVALIDO",
                        count: 0,
                        label: "Rinviata per integrazioni"
                    },
                    {
                        categoryId: "CONCLUSO",
                        count: 0,
                        label: "Esitata"
                    }
                ],
                trancheStats: [
                    {
                        categoryId: "BOZZA",
                        count: 0,
                        label: "Nuovo"
                    }, 
                    {
                        categoryId: "INVIATO",
                        count: 0,
                        label: "In Corso"
                    },
                    {
                        categoryId: "INVALIDO",
                        count: 0,
                        label: "Rinviato per integrazioni"
                    },
                    {
                        categoryId: "CONCLUSO",
                        count: 0,
                        label: "Evaso"
                    }
                ]
            };
        },

        created() {
            //Invece che caricare i valori cablati in una mappa Java, chiamo Config-NT per avere la mappa dei Beneficiari
            this.$store.dispatch("announcements/getBeneficiariFromConfig");
            
            const userInfo = this.$getUserInfo();
            sessionStorage.setItem('hasBeenLoaded', "true")
            this.title = userInfo.groups[0];
            this.gruppo = userInfo.groups[0];

            this.aree = this.$getDashboardAreas();

            this.caricaSelettori();          
            this.caricaConfigurazione();
        },

        mounted() {
            //le scadenze esistono solo per gli utenti coinvolti nel monitoraggio o nei controlli
            if (this.isMonitoring || this.isControl){
                this.caricaScadenze();
            }
            this.roles = this.$getUserRoles();
            const profString = sessionStorage.getItem('currentProfile');
            if(profString)
                this.currentProfile = JSON.parse(profString);
            else
                this.currentProfile = {};
            this.$emit('refreshProfile', this.currentProfile);
        },

        methods: {
            caricaConfigurazione() {
                // carica la configurazione delle date (progetto)
                // e della dotazione finanziaria (executing body), essendo
                // un'operazione interna e comunque non bloccante
                // non inserisco un controllo di sequenzialità (.then)
                this.$store.dispatch("progetto/setupMiscConfig").catch(
                error => {
                    console.error('error miscConfig', error.response);
                    notify.error(notify.strings.error, notify.strings.internalConfigurationError);
                });
            },

            toggleStatsDetailsTablePane(){
                this.statsDetailsTable.isToShow = !this.statsDetailsTable.isToShow
            },

            showClickedPieChartData(data){
                if(tools.objectHasValue(data) && data.categoryId != null){ // if I don't know categoryId, I set it to null in piechart.vue
                    console.log("fetching data for /schedaMonitoraggio/statsDetails con idCategoria: " + data.categoryId)
                    this.$store.dispatch('monitoraggio/retrieveStatsDetails',{
                        idCategoria: data.categoryId,
                        idPeriodo: null // unused at the moment
                    })
                    .then(
                        // retrieve sucecss
                        // NB. result is already the data returned,
                        // because the vuex actions promise already 
                        // returns result.data from the API
                        result => {
                            this.statsDetailsTable.title = data.label

                            // NB. don't change result. you should not change the store manually...

                            // cloning object
                            this.statsDetailsTable.data = tools.cloneObject(result)
                            // preparing data for table to show...
                            this.statsDetailsTable.data.forEach(item => {
                                item.dataInvio = utils.formatDate(item.dataInvio)
                                if (item.stato !== "")
                                    item.stato = item.stato.replaceAll("_", " ")
                            })
                            this.statsDetailsTable.isToShow = true
                        }
                    ).catch(
                        error => {
                            console.log(error)
                            if (error.response && error.response.status){
                                console.log("errore dispatch stats details, request status: " + error.response.status)
                                notify.error(
                                    notify.strings.error, 
                                    notify.strings.cantFetchServerData + notify.strings.requestStatus(error.response.status)
                                )
                            } else {
                                console.log("eccezione nell'elaborazione del result da monitoraggio/restriveStatsDetails")
                                notify.error(notify.strings.error, notify.strings.cantProcessData)
                            }
                        }
                    )
                }
            },

            showClickedPieChartDataBandi(data){
                console.log("TODO: implementare listaBandi filtrata per lo stato=", data);
            },

            showClickedPieChartDataProgetti(data){
                console.log("TODO: implementare listaProgetti filtrata per lo stato=", data);
            },

            showClickedPieChartDataControlli(data){
                console.log("TODO: implementare listaControlli filtrata per lo stato=", data);
            },
              //Calcolato con i pagamenti inseriti in cronoprog
            async calcolaImportoAssegnato(){
                let total = 0;
                //ritorna la lista dei progetti dell'utente loggato
                let url = endpoints.listExtraction;
                
                let listProg = await this.$get(url);
                if(!listProg || listProg.length == 0){
                    this.costoProgetto = total;
                    console.log("Non esistono schede su cui eseguire calcoli");
                    return;
                }
               
                for (const element of Object.values(listProg)) {
                    total=total+element.costoProgetto;
                }
               
                this.importoAssegnato = tools.roundFloat2Decs(total);
                this.calcolaImportoErogato(Object.keys(listProg));
            },
            async calcolaImportoErogato(codProgperImporto){     
                        
                let total = 0;
                let totaleisf = 0;
                //controlli ammfase 2 esitati positivamente della lista dei progetti dell'utente loggato
                let url = endpoints.controlliAmmFase2;
                let reqBody = {"codiceProgetti":codProgperImporto,}
                let controlliFase2 = await this.$post(url,reqBody);
                 if(!controlliFase2 || controlliFase2.length == 0){
                    this.importoErogato = total;
                    console.log("Non esistono controlli fase2 esitati positivamente sui progetti dell'utnete corrente");
                    return;
                }
                
                //restituisce i pagamenti dei progetti che hanno contrAmmfase2 esitati positivamente
                url = endpoints.pagamentiSchedaCronoProg;
                reqBody = {"codiceProgetti": Object.keys(controlliFase2),}
                let pagamentiCronoProg = await this.$post(url,reqBody);
                if(!pagamentiCronoProg || pagamentiCronoProg.length == 0){
                    this.importoErogato = total;
                    console.log("Non esistono Pagamenti su cui eseguire calcoli");
                    return;
                }
               //controllo dei pagamenti flaggati correttamente e calcolo totaleImportofatturaIsf
                for(const [key, value] of Object.entries(controlliFase2)) {
                      if(Object.keys(pagamentiCronoProg).includes(key)){    
                        for (const item of value){
                            let iter=Object.entries(pagamentiCronoProg).find(([keys]) => keys === key )[1]['iter'];
                            for(const it of iter){
                                if(it['flagged']==item['idSchedaControllo']){
                                    totaleisf += parseFloat(it['importoFatturaSuISF']);
                               }
                            }
                            
                        }
                    }    

                }
                total = totaleisf + parseFloat(this.monStats.budgetPagato);
                this.importoErogato = tools.roundFloat2Decs(total);
            },

            contaTotaleAttivita(){
                return this.monitoraggi +this.interimAss + this.finalAss 
                    + this.myCFP.length + this.myAD.length + this.myEB.length 
                    + this.myProposte.length + this.myAmmissione.length + this.myConvenzione.length + this.myAttuazione.length
                    + this.controlli;
            },
            insertDescBeneficiario(lastNotify) {
                // dal codiceProgetto, prelevo l'id del beneficiario ed inserisco la sua denominazione del beneficiario
                if(lastNotify.codiceProgetto && lastNotify.codiceProgetto !== "" && lastNotify.codiceProgetto.indexOf("Beneficiario: ") !== -1) {
                    let split_codice_progetto = lastNotify.codiceProgetto.split("Beneficiario: ")
                    if(split_codice_progetto && split_codice_progetto.length === 2) {
                        const mappaBenef = this.getBeneficiari
                        let idBeneficiario = split_codice_progetto[1]
                        if(mappaBenef[idBeneficiario] && mappaBenef[idBeneficiario].denominazione) {
                            let denominaz_beneficiario = mappaBenef[idBeneficiario].denominazione
                            lastNotify.codiceProgetto = split_codice_progetto[0] + "Beneficiario: " + denominaz_beneficiario
                        }
                        else console.error("dashboard.vue: "+ idBeneficiario +" non trovato nella mappa dei Beneficiari disponibili ", mappaBenef)
                    }
                }
                return lastNotify
            },
            caricaStoricoAzioni() {
                // carica dal db un array contenente l'ultimo monitoraggio/assessment e l'ultima notifica bandi/progetti
                // già ordinati per data decrescente
                this.$store.dispatch('storicoAzioni/retrieveStoricoAzioni', { maxFetch: 1, storeResults: false }).then(
                    result => {
                        if(result && result.length > 0) {
                            let lastNotify = result[0]
                            this.lastNotification = this.insertDescBeneficiario(lastNotify)
                            
                        }
                        this.notifyLoaded = true
                    }
                ).catch(
                    error => {
                        this.notifyLoaded = true
                        console.error('caricaStoricoAzioni error: ', error);
                        notify.error(notify.strings.error, notify.strings.serverError);
                })
            },
            // TODO oltre a tornare il grafico, deve tornare anche il calcolo degli importi totalePagato, così ci evitiamo 
            // la chiamata a selettore e il calcolo qui
            retrieveMonStats: function() {
                return this.$store.dispatch('monitoraggio/retrieveStats')
                .then(
                    result => {
                        this.monStats = result;
                        this.monitoraggi = this.monStats.conteggioAttivita;
                    }
                ).catch(
                    error => {
                        notify.error(notify.strings.error, notify.strings.cantFetchServerData)
                        if (error.response && error.response.status){
                            console.log("errore dispatch monitoraggio stats, request status: " + error.response.status)
                        } else {
                            console.log("errore dispatch monitoraggio stats")
                        }
                    }
                )
            },

            retrieveAssessmentStats: function() {
                const url = endpoints.getStats('schedaAssessment');
                this.$get(url)
                .then(
                    result => {
                        this.interimStats = result.interim.stats;
                        this.finalStats = result.final.stats;
                        this.interimAss = result.interim.conteggioAttivita;
                        this.finalAss = result.final.conteggioAttivita;
                    }
                ).catch(
                    error => {
                        notify.error(notify.strings.error, notify.strings.cantFetchServerData)
                        if (error.response && error.response.status){
                            console.log("errore dispatch assessment stats, request status: " + error.response.status)
                        } else {
                            console.log("errore dispatch assessment stats")
                        }
                    }
                )
            },

            caricaSelettori: async function() {
                this.notifyLoaded = false
                this.caricaStoricoAzioni(); //questa funzione che carica tutte le notifiche deve essere invocata anche quando non c'è monitoring fra le aree funzionali

                if (this.isMonitoring){
                    //le informazioni sulle schede monitoraggio devono essere disponibili solo a chi ha visibilità di quest'area
                    await this.retrieveMonStats();
                    this.retrieveAssessmentStats();
                    //importi erogati ed assegnati hanno senso solo nel contesto del monitoraggio
                    this.calcolaImportoAssegnato();
                }

                if (this.isCall){
                    this.caricaListaBandi();
                }

                if (this.isEB){
                    this.caricaListaEB();
                }

                if (this.isProject){
                    this.caricaListaProgetti();
                }

                if (this.isControl){
                    this.caricaListaControlli();
                }

                if (this.isPagamenti){
                    this.caricaListaPagamenti();  
                }

            },
            caricaScadenze: function() {
                //Scadenze 'vecchie' per monitoraggi e assessment
                this.$store.dispatch('scadenziario/initScadenziario',new Date())
                    .then(() => {
                       
                        const actualDate = this.getScadenza
                        //Adatto i dati alla struttura
                        this.prossimaScadenza = {
                            dataScadenza: new Date(actualDate.dataString).toISOString(),
                            descrizione: actualDate.descrizione,
                            tipologiaScheda: actualDate.titolo,
                            codiceProgetto: ''
                        }

                        //Chiamate API scadenze
                        //Scadenze Controlli
                        let uriScadenzeControlli = endpoints.getScadenzeControlli      
                        this.$get(uriScadenzeControlli)
                            .then( resp => {
                                if(resp && resp.length > 0){
                                    
                                    resp.forEach(element => {
                                        if(new Date(element.dataScadenza).getTime() - new Date(this.prossimaScadenza.dataScadenza).getTime() < 0){
                                            this.prossimaScadenza = element
                                            console.info("Prossima scadenza: ", this.prossimaScadenza)
                                        }
                                    });
                                }
                            })    
                                                                 
                    })
                    
            },

            dateFormatter(d){
                return utils.formatDate(d)
            },

            caricaListaProgetti: async function() {
                const url = endpoints.retrieveAllSchedeProgetto;
               this.$get(url)
                    .then((result) => {
                        let listaProgetti = result;
                        listaProgetti.forEach(
                            (element) => {
                                if (element && element.infoProgetto && 
                                    (element.infoProgetto.stato=="In bozza" 
                                        || element.infoProgetto.stato=="Inviato" 
                                        || element.infoProgetto.stato=="Rinviato per modifiche") 
                                    ){
                                    
                                    this.progettiProposte.push(element);
                                
                                } else if (element && element.infoProgetto && 
                                            (element.infoProgetto.stato=="Ammesso" 
                                                || element.infoProgetto.stato=="Non Ammesso"
                                                || element.infoProgetto.stato=="Da prendere in carico"
                                                || element.infoProgetto.stato=="In Istruttoria"
                                                || element.infoProgetto.stato=="Finalizzazione Istruttoria"
                                            )
                                    ) {
                                            
                                    this.progettiValutati.push(element);

                                } else if (element && element.infoProgetto && 
                                            (element.infoProgetto.stato.includes("Decreto Ammissione Finanziamento")
                                            || element.infoProgetto.stato.includes("Graduatoria")
                                            || element.infoProgetto.stato.includes("Convenzione"))
                                        ) {
                                            
                                    this.progettiConvenzione.push(element);

                                } else if (element && element.infoProgetto && 
                                            (element.infoProgetto.stato=="In Attuazione" 
                                                || element.infoProgetto.stato=="In Rimodulazione"
                                                || element.infoProgetto.stato=="Rinviato per Modifiche Rimodulazione"
                                                || element.infoProgetto.stato=="In Verifica Rimodulazione"
                                                || element.infoProgetto.stato=="Finalizzazione Rimodulazione"
                                                || element.infoProgetto.stato=="Concluso") &&
                                            !this.progettiEsclusi.includes(element.infoProgetto.codiceProgetto)
                                    ) {
                                    
                                    this.progettiAttuazione.push(element);
                                }
                                
                            },
                            (error) => {
                                throw error;
                            }
                        );

                        console.log("Progetti: "+this.progettiProposte.length+" proposte, "
                            +this.progettiValutati.length+" valutati, "
                            +this.progettiConvenzione.length+" convenzione, "
                            +this.progettiAttuazione.length+" attuazione");
                        this.aggiornaStatisticheProgetti();
                    });
            },

            aggiornaStatisticheProgetti(){
                
                if (this.progettiProposte.length>0){
                    
                    let arr0 = this.progettiProposte.filter(item => {
                        return item.infoProgetto.stato === "In bozza";
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.ppStats.splice(0,1, {
                        categoryId: "BOZZA",
                        count: arr0.length,
                        label: "In bozza"
                    });
                
                    let arr1 = this.progettiProposte.filter(item => {
                        return item.infoProgetto.stato === "Inviato";
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.ppStats.splice(1,1, {
                        categoryId: "INVIATO",
                        count: arr1.length,
                        label: "Inviato"
                    });
                
                    let arr2 = this.progettiProposte.filter(item => {
                        return item.infoProgetto.stato === "Rinviato per modifiche";
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.ppStats.splice(2,1, {
                        categoryId: "INVALIDO",
                        count: arr2.length,
                        label: "Rinviato per modifiche"
                    });

                    //conto il totale delle proposte di progetto in cui posso/devo compiere qualche azione
                    this.myProposte = this.progettiProposte.filter(item => {
                        return item.taskInfo.taskDefinitionKey.indexOf(this.roles[0]) !== -1;
                    });
                
                }

                if (this.progettiValutati.length>0){
                    
                    let arr0 = this.progettiValutati.filter(item => {
                        return item.infoProgetto.stato === "Da prendere in carico";
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.pvStats.splice(0,1, {
                        categoryId: "DA_PRENDERE_IN_CARICO",
                        count: arr0.length,
                        label: "Da prendere in carico"
                    });

                    let arr1 = this.progettiValutati.filter(item => {
                        return (item.infoProgetto.stato === "In Istruttoria"
                                || item.infoProgetto.stato === "Revisione Istruttoria"
                                || item.infoProgetto.stato === "Finalizzazione Istruttoria");
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.pvStats.splice(1,1, {
                        categoryId: "IN_VALUTAZIONE",
                        count: arr1.length,
                        label: "In Istruttoria"
                    });
                
                    let arr2 = this.progettiValutati.filter(item => {
                        return item.infoProgetto.stato === "Ammesso";
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.pvStats.splice(2,1, {
                        categoryId: "AMMESSO",
                        count: arr2.length,
                        label: "Ammesso"
                    });
                
                    let arr3 = this.progettiValutati.filter(item => {
                        return item.infoProgetto.stato === "Non Ammesso";
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.pvStats.splice(3,1, {
                        categoryId: "NON_AMMESSO",
                        count: arr3.length,
                        label: "Non Ammesso"
                    });
                
                    //conto il totale dei progetti in valutazione in cui posso/devo compiere qualche azione
                    this.myAmmissione = this.progettiValutati.filter(item => {
                        return item.taskInfo.taskDefinitionKey.indexOf(this.roles[0]) !== -1;
                    });
                }

                if (this.progettiConvenzione.length>0){

                    let arr0 = this.progettiConvenzione.filter(item => {
                        return (item.infoProgetto.stato === "Decreto Ammissione Finanziamento da firmare"
                                || item.infoProgetto.stato === "Decreto Ammissione Finanziamento in revisione Direttore STA"
                                || item.infoProgetto.stato === "Decreto Ammissione Finanziamento in firma AR"
                                );
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.pcStats.splice(0,1, {
                        categoryId: "DECRETO_DA_FIRMARE",
                        count: arr0.length,
                        label: "Decreto da firmare"
                    });

                    let arr1 = this.progettiConvenzione.filter(item => {
                        return (item.infoProgetto.stato.includes("Graduatoria"));
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.pcStats.splice(1,1, {
                        categoryId: "GRADUATORIA_DA_FIRMARE",
                        count: arr1.length,
                        label: "Graduatoria da firmare"
                    });
                
                    let arr2 = this.progettiConvenzione.filter(item => {
                        return (item.infoProgetto.stato === "Convenzione da firmare"
                                || item.infoProgetto.stato === "Convenzione in firma AR");
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.pcStats.splice(2,1, {
                        categoryId: "CONVENZIONE_DA_FIRMARE",
                        count: arr2.length,
                        label: "Convenzione da firmare"
                    });
                
                    let arr3 = this.progettiConvenzione.filter(item => {
                        return (item.infoProgetto.stato === "Convenzione firmata"
                                || item.infoProgetto.stato === "Decreto Ammissione Finanziamento firmato");
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.pcStats.splice(3,1, {
                        categoryId: "CONVENZIONE_FIRMATA",
                        count: arr3.length,
                        label: "Convenzione firmata"
                    });

                    //conto il totale dei progetti in convenzione in cui posso/devo compiere qualche azione
                    this.myConvenzione = this.progettiConvenzione.filter(item => {
                        return item.taskInfo.taskDefinitionKey.indexOf(this.roles[0]) !== -1;
                    });
                }

                if (this.progettiAttuazione.length>0){

                    let arr0 = this.progettiAttuazione.filter(item => {
                        return item.infoProgetto.stato === "In Attuazione";
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.paStats.splice(0,1, {
                        categoryId: "ATTUAZIONE",
                        count: arr0.length,
                        label: "In Attuazione"
                    });

                    let arr1 = this.progettiAttuazione.filter(item => {
                        return (item.infoProgetto.stato === "In Rimodulazione"
                                || item.infoProgetto.stato === "In Verifica Rimodulazione"
                                || item.infoProgetto.stato === "Finalizzazione Rimodulazione"
                                );
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.paStats.splice(1,1, {
                        categoryId: "RIMODULAZIONE",
                        count: arr1.length,
                        label: "In Rimodulazione"
                    });
                
                    let arr2 = this.progettiAttuazione.filter(item => {
                        return item.infoProgetto.stato === "Rinviato per Modifiche Rimodulazione";
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.paStats.splice(2,1, {
                        categoryId: "RINVIATO",
                        count: arr2.length,
                        label: "Rinviato per rimodulazione"
                    });
                
                    let arr3 = this.progettiAttuazione.filter(item => {
                        return item.infoProgetto.stato === "Concluso";
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.paStats.splice(3,1, {
                        categoryId: "CONCLUSO",
                        count: arr3.length,
                        label: "Concluso"
                    });

                    //conto il totale dei progetti in attuazione in cui posso/devo compiere qualche azione
                    this.myAttuazione = this.progettiAttuazione.filter(item => {
                        return item.taskInfo.taskDefinitionKey.indexOf(this.roles[0]) !== -1;
                    });
                }
            },

            caricaListaBandi: async function() {
                const url = endpoints.listaBandi();
                this.$get(url)
                    .then((result) => {
                        let listaBandi = result;
                        listaBandi.forEach(
                            (element) => {
                                if (element && element.infoBando 
                                    && element.infoBando.modalitaDiAccesso=="Call for proposal" 
                                    ){
                                    
                                    this.bandiCFP.push(element);
                                
                                } else if (element && element.infoBando 
                                    && element.infoBando.modalitaDiAccesso=="Assegnazione diretta"
                                    ) {
                                            
                                    this.bandiAD.push(element);

                                }
                                
                            },
                            (error) => {
                                throw error;
                            }
                        );

                        console.log("Bandi: Call For Proposal="+this.bandiCFP.length+", Assegnazione Diretta="+this. bandiAD.length);
                        this.aggiornaStatisticheBandi();
                    });
            },

            caricaListaEB(){

                //leggo la lista dei bandi Executing Body
                const urlGet = endpoints.listSchedeExecutingBody;
               this.$get(urlGet)
                .then((result) => {
                    for (let i=0; i<result.length; i++){
                        this.bandiEB.push(result[i]);
                    }

                    this.aggiornaStatisticheEB();
                },
                (error) => {
                    notify.error(
                        notify.strings.error,
                        notify.strings.internalErrorPleaseReportAction
                    );
                    throw error;
                });

            },

            aggiornaStatisticheBandi(){
                
                if (this.bandiCFP.length>0){
                    
                    let arr0 = this.bandiCFP.filter(item => {
                        return (item.infoBando.stato === "In bozza"
                                ||item.infoBando.stato === "Rinviato per modifiche");
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.bcfpStats.splice(0,1, {
                        categoryId: "BOZZA",
                        count: arr0.length,
                        label: "In bozza"
                    });

                    let arr1 = this.bandiCFP.filter(item => {
                        return (item.infoBando.stato === "In verifica"
                            || item.infoBando.stato === "In revisione Ufficio Gestione"
                            || item.infoBando.stato.includes("valutazione")
                            || item.infoBando.stato === "Approvato"
                        );
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.bcfpStats.splice(1,1, {
                        categoryId: "IN_VALUTAZIONE",
                        count: arr1.length,
                        label: "In Valutazione"
                    });


                    let arr2 = this.bandiCFP.filter(item => {
                        return (item.infoBando.stato === "Inviato" 
                            && this.$bandoIsStillValid(new Date(item.infoBando.dataFineProposte), item.infoBando.oraFineProposte, item.infoBando.dataInizioProposte)
                        );
                    });
                    this.inviti = arr2.length;  //il numero di inviti coincidi con i bandi "aperti" a cui posso candidarmi
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.bcfpStats.splice(2,1, {
                        categoryId: "APERTO",
                        count: arr2.length,
                        label: "Aperto"
                    });
                
                    let arr3 = this.bandiCFP.filter(item => {
                        return (item.infoBando.stato === "Inviato" 
                            && !this.$bandoIsStillValid(new Date(item.infoBando.dataFineProposte), item.infoBando.oraFineProposte, item.infoBando.dataInizioProposte)
                        );
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.bcfpStats.splice(3,1, {
                        categoryId: "SCADUTO",
                        count: arr3.length,
                        label: "Scaduto"
                    });
                
                    let arr4 = this.bandiCFP.filter(item => {
                        return item.infoBando.stato === "Graduatoria approvata";
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.bcfpStats.splice(4,1, {
                        categoryId: "GRADUATORIA_APPROVATA",
                        count: arr4.length,
                        label: "Graduatoria approvata"
                    });

                    //conto il totale dei bandi CFP in cui posso/devo compiere qualche azione
                    this.myCFP = this.bandiCFP.filter(item => {
                        return item.taskInfo.taskDefinitionKey.indexOf(this.roles[0]) !== -1;
                    });
                    
                    
                }

                if (this.bandiAD.length>0){
                    
                    let arr0 = this.bandiAD.filter(item => {
                        return (item.infoBando.stato === "Inviato");
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.badStats.splice(0,1, {
                        categoryId: "APERTO",
                        count: arr0.length,
                        label: "Aperto"
                    });

                    let arr1 = this.bandiAD.filter(item => {
                        return (item.infoBando.stato.includes("valutazione")
                            || item.infoBando.stato === "In verifica"
                            || item.infoBando.stato === "In revisione Ufficio Gestione"
                            || item.infoBando.stato === "Approvato" 
                        );
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.badStats.splice(1,1, {
                        categoryId: "IN_VALUTAZIONE",
                        count: arr1.length,
                        label: "In Valutazione"
                    });
                
                    let arr2 = this.bandiAD.filter(item => {
                        return (item.infoBando.stato === "Graduatoria approvata");
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.badStats.splice(2,1, {
                        categoryId: "VALUTATO",
                        count: arr2.length,
                        label: "Valutato"
                    });

                    //raggruppo tutti gli stati precedenti ad inviato
                    let arr3 = this.bandiAD.filter(item => {
                        return (item.infoBando.stato === "In bozza"
                            || item.infoBando.stato === "Rinviato per modifiche"
                        );
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.badStats.splice(3,1, {
                        categoryId: "BOZZA",
                        count: arr3.length,
                        label: "In bozza"
                    });
                
                    //conto il totale dei bandi AD in cui posso/devo compiere qualche azione
                    this.myAD = this.bandiAD.filter(item => {
                        return item.taskInfo.taskDefinitionKey.indexOf(this.roles[0]) !== -1;
                    });
                }

               
            },

            aggiornaStatisticheEB(){
                
                if (this.bandiEB.length>0){
                    let arr0 = this.bandiEB.filter(item => {
                        return (item.infoProgetto.stato === "In bozza"
                        );
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.bebStats.splice(0,1, {
                        categoryId: "BOZZA",
                        count: arr0.length,
                        label: "In bozza"
                    });

                    let arr1 = this.bandiEB.filter(item => {
                        return (item.infoProgetto.stato === "In valutazione Economico Finanziaria"
                            || item.infoProgetto.stato === "In valutazione Direttore STA"
                            || item.infoProgetto.stato === "In valutazione Autorità Responsabile"
                            || item.infoProgetto.stato === "Redazione Appunto"
                            || item.infoProgetto.stato === "Rinviato per modifiche"
                        );
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.bebStats.splice(1,1, {
                        categoryId: "IN_VALUTAZIONE",
                        count: arr1.length,
                        label: "In Valutazione"
                    });

                    let arr2 = this.bandiEB.filter(item => {
                        return (item.infoProgetto.stato === "Intervento in Attuazione");
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.bebStats.splice(2,1, {
                        categoryId: "AMMESSO",
                        count: arr2.length,
                        label: "Ammesso"
                    });

                    let arr3 = this.bandiEB.filter(item => {
                        return (item.infoProgetto.stato === "Non Ammesso"
                            || item.infoProgetto.stato === "Eliminato dall'utente"
                        );
                    });
                    //splice necessario per aggiornare reattivamente il diagramma a torta
                    this.bebStats.splice(3,1, {
                        categoryId: "NON_AMMESSO",
                        count: arr3.length,
                        label: "Non ammesso"
                    });

                    //conto il totale degli EB in cui posso/devo compiere qualche azione
                    this.myEB = this.bandiEB.filter(item => {
                        return item.taskInfo.taskDefinitionKey.indexOf(this.roles[0]) !== -1;
                    });
                }

            },

            caricaListaControlli: async function() {
                const url = endpoints.getStatsControlli;
                this.$get(url)
                    .then((result) => {
                        let statsControlli = result;
                        if ( statsControlli &&  Object.keys(statsControlli).length>0 ){
                            this.controlliAmm = statsControlli.amministrativo
                            this.pieChartBuilder(this.controlliAmm, this.caStats)
                            this.myControlliAmm = statsControlli.myAmministrativo;

                            this.controlliLoco = statsControlli.inLoco
                            this.pieChartBuilder(this.controlliLoco, this.cilStats)
                            this.myinLoco = statsControlli.myinLoco

                            this.rendicontate = result.rendicontate
                            this.pieChartBuilder(this.rendicontate, this.rendicontStats)
                            this.myRendicontate = result.myRendicontate
                        }                     
                    });
            },

            caricaListaPagamenti: async function(){
                const url = endpoints.getStatsPagamenti;
                this.$get(url)
                .then((result) => {
                    this.prefinanziamento = result.prefinanziamento
                    this.pieChartBuilder(this.prefinanziamento, this.prefStats)
                    this.myPrefinanziamento = result.myPrefinanziamento
                    
                    this.tranche = result.tranche
                    this.pieChartBuilder(this.tranche, this.trancheStats)
                    this.myTranche = result.myTranche
                })
            },

            /**
             * @param {Object} stats Risultato della API, opportunamente strutturato
             *                       Esempio: stats: {
             *                                          BOZZA: 1,
             *                                          INVIATO: 0,
             *                                          INVALIDO: 0,
             *                                          CONCLUSO: 3
             *                                       }
             * @param {Object} chart Grafico da popolare
             */
            pieChartBuilder(stats, chart){
                if(stats){
                    let index = 0
                    for(const stat in stats){
                    //console.log("Index: "+index +" "+ stat +" = "+ `${stats[stat]}`)
                    let currentLabel = chart[index].label
                    chart.splice( index, 1, {
                        categoryId: stat,
                        count: `${stats[stat]}`,
                        label: currentLabel
                    })
                    index++
                    }
                }
            },


        }
    }
</script>

<style lang="scss">

// overriding canvas (chart) css settings
.customCanvas, .customCanvas canvas {
    height: auto !important;
    width: 20rem !important;
    //float: right;
    // use flex to align stuff
    /*display: flex;
    align-items: center;
    justify-content: center;*/
}

#statsTitle {
    text-align: left;
    font-size: 1.8rem;
}

</style>