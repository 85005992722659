import Vue from 'vue'

const defaultDuration = 3000;

const warn = function(title, message, duration) {
    Vue.notify({
        type: 'warn',
        title: title,
        text: message,
        duration: duration || defaultDuration,
    });
}

const info = function(title, message, duration) {
    Vue.notify({
        type: 'info',
        title: title,
        text: message,
        duration: duration || defaultDuration,
    });
}


const error = function(title, message, duration) {
    Vue.notify({
        type: 'error',
        title: title,
        text: message,
        duration: duration || defaultDuration,
    });
}

const success = function(title, message, duration) {
    Vue.notify({
        type: 'success',
        title: title,
        text: message,
        duration: duration || defaultDuration,
    });
}

const strings = {
    // titles
    // (never put dots at the end of a title string!)
    success: "Operazione riuscita",
    error: "Errore",
    loginError: "Login fallito",
    loginTimeout: "Timeout Login",
    monitoring: "Monitoraggio",
    download: "Download",

    // messages
    operationSuccess: function(operationName) {
        if (operationName) { return operationName + ': Azione eseguita correttamente.' }
        return "Azione eseguita correttamente."
    },
    operationError: function(operationName) {
        if (operationName) { return operationName + ": Operazione fallita." }
        return "Operazione fallita."
    },
    logoutSuccess: "Logout eseguito.",
    logoutError: "Impossibile effettuare la logout.",
    logoutServerError: "Impossibile completare la logout con il server. ",
    cantConnectToServer: "Impossibile contattare il server. ",
    cantFetchServerData: "Impossibile scaricare i dati dal server. ",
    cantProcessData: "Impossibile elaborare i dati richiesti. ",
    serverError: "Caricamento dati dal server fallito. ",
    emptyArchive: "Archivio dati di sessione vuoto. Per favore, <a href=\"/cruscotto\">torna al Cruscotto</a>.",
    noArchivedDataForProject: function(str) {
        return "Nessuna scheda trovata in archivio per il progetto " + str +
            ".<br/>Per favore, <a href=\"/cruscotto/progetti\">seleziona un altro progetto</a>."

    },
    noArchivedDataForAssessment: function(str) {
        return "Nessuna scheda di assessment trovata in archivio per il progetto " + str +
            ".<br/>Per favore, <a href=\"/cruscotto/\">seleziona un altro progetto</a>."

    },
    invalidData: "Dati invalidi. Per favore, <a href=\"/cruscotto\">torna al Cruscotto</a>.",
    requestStatus: function(str) {
        if (str.toString() === "403") {
            return "<br/>Stato della richiesta: " + str + ".<br/>Per favore Esci e riesegui la Login."
        }
        return " Stato della richiesta: " + str
    },
    saveSchedaOk: "Scheda monitoraggio salvata correttamente.",
    assessmentLoadingOk: "Scheda assessment caricata correttamente.",
    assessmentLoadingError: "Errore caricamento scheda assessment.",
    saveSchedaError: "Errore salvataggio scheda monitoraggio.",
    saveAssessmentOk: "Scheda assessment salvata correttamente.",
    saveAssessmentError: "Errore salvataggio scheda assessment.",
    saveAssessmentDupKeyError: 'Scheda non salvata, chiave duplicata. Controllare i dati presenti su DB',
    sendAssessmentOk: "Scheda assessment inviata correttamente.",
    sendAssessmentError: "Errore invio scheda assessment.",
    approveAssessmentOk: "Scheda assessment approvata correttamente.",
    approveAssessmentError: "Errore approvazione scheda assessment.",
    rejectAssessmentOk: "Scheda assessment rifiutata correttamente.",
    rejectAssessmentError: "Errore durante il rifiuto della scheda assessment.",
    invalidSendData: "Dati scheda non validi. Per favore ricarica la pagina.",
    diffDataError: "Dati dal server non validi. Per favore ricarica la pagina.",
    diffDataSuccess: "Differenze caricate correttamente dal server.",
    saveSchedaSuccess: "Scheda salvata con successo.",
    sendSchedaSuccess: "Scheda inviata con successo.",
    sendSchedaError: "Errore invio Scheda monitoraggio.",
    approvedRequestSuccess: "Approvazione inviata con successo.",
    rejectRequestSuccess: "Rifiuto scheda elaborato con successo.",
    approvedRequestError: "Approvazione fallita.",
    rejectRequestError: "Rifiuto scheda fallito.",
    noContrattoError: "Nessun contratto trovato, impossibile aggiungere",
    noProject: "Nessun progetto di competenza trovato",
    noAssessment: function(str) {
        return "Nessuna scheda di " + str + " assessment trovata"
    },
    internalError: "Impossibile completare l'azione.",
    internalConfigurationError: "Errore nel caricamento della configurazione." +
        "<br/>Per favore, contatta il supporto indicando le azioni effettuate.",
    internalErrorPleaseReportAction: "Impossibile completare l'azione." +
        "<br/>Per favore, contatta il supporto indicando le azioni effettuate.",
    selettoreMonitoraggioKO: "Errore nel caricamento dati di Monitoraggio",
    selettoreAssessmentKO: "Errore nel caricamento dati di Assessment",
    sessionError: "Errore nella sessione dati." +
    "<br/>Si prega di contattare il supporto tecnico.",
    
    avvioMonitoraggioError: "Errore avvio monitoraggio, si prega di contattare l'assistenza",


    loadListError: "Errore nel caricamento della lista",
    loadSchedaError: "Errore nel caricamento della scheda",


    
    // BANDO
    listaBandiKO: "Errore nel caricamento dei bandi",
    saveCompleteSchedaOk: "Scheda Bando salvata con successo.",
    deleteSchedaOk: "Scheda Bando eliminata con successo.",
    deleteProgettoOk: "Scheda Progetto eliminata con successo.",
    errorCreateNewBando: "Errore nella creazione di un bando",
    bandoNotFound: "Non è stato possibile recuperare alcun bando",
    errorSaveCompleteScheda: "Errore nel salvataggio del bando",
    deleteProgettoError: "Errore nell'eliminazione del bando",
    errorClaimScheda: "Errore Presa in carico: scheda già in carico ad un altro utente",
    errorBandoExpired: "Operazione non più disponibile. Il bando è scaduto",
    errorUnclaimScheda: "Errore nel rilascio presa in carico",
    notificationKO: "Errore nella lettura delle notifiche",
    errorLoadProgetto: "Errore nel caricamento del progetto",


    // MODIFICA PROGETTO
    cancelEditProgettoOk: "Modifica alla Scheda Progetto interrotta con successo.",
    cancelEditProgettoError: "Errore nell'interruzione della modifica Scheda Progetto",

    denyEditProgettoOk: "Modifica alla Scheda Progetto rifiutata e interrotta con successo.",
    denyEditProgettoError: "Errore nell'operazione di rifiuto della modifica Scheda Progetto",

    //pdf
    errorPdf: "Errore nella generazione della scheda pdf: alcuni dati potrebbero essere incompleti.",

    //EXECUTING_BODY
    errorLoadExecuting: "Errore nel caricamento della scheda ExecutingBody",

    //ISTRUTTORIA
    errorLoadIstruttoria: "Errore nel caricamento della scheda Istruttoria",
    
    //CONTROLLI
    errorLoadControlli: "Errore nel caricamento del controllo",
    errorLoadControllo: "Errore nel caricamento della Scheda di Controllo",
    saveControlloOk: "Scheda Controllo salvata con successo.",
    errorSaveControllo: "Errore nel salvataggio del Controllo",
    saveCompleteOk: "Scheda Controllo salvata e inviata con successo.",
    errorCompleteControllo: "Errore nel completamento dell'operazione su Controllo",
    deleteControlloOk: "Scheda Controllo eliminata con successo",
    errorDeleteControllo: "Errore nella eliminazione del Controllo",
    
    // PREFINANZIAMENTO
    errorGetPrefinanziamento: "Errore nel caricamento del prefinanziamento",
    savePrefinanziamentoOk: "Scheda Prefinanziamento salvata con successo.",
    errorSavePrefinanziamento: "Errore nel salvataggio del Prefinanziamento",
    errorCompletePrefinanziamento: "Errore nel completamento dell'operazione su Prefinanziamento",

    // TRANCHES
    saveTrancheOk: "Richiesta Tranche salvata con successo",
    errorSaveTranche: "Errore nel salvataggio della Richiesta Tranche",
    errorCompleteTranche: "Errore nel completamento dell'operazione su Richiesta Tranche",   
    
    // IRREGOLARITA
    saveIrregolaritaOk: "Scheda Irregolarita salvata con successo",
    errorSaveIrregolarita: "Errore nel salvataggio della Scheda Irregolarita",
    errorCompleteIrregolarita: "Errore nel completamento dell'operazione su Irregolarita",

    // PROGETTO 
    saveProgettoOk: "Scheda Progetto salvata con successo.",
    saveConvenzioneOk: "Scheda Convenzione salvata con successo.",
    errorSaveProgetto: "Errore nel salvataggio del progetto",
    errorSaveConvenzione: "Errore nel salvataggio della Scheda Convenzione",
    errorCreateNewProgetto: "Errore nella creazione di un progetto",
    errorCreateNewProgettoBandoExpired: "Non è possibile creare un progetto oltre la data/ora di scadenza del bando",
    errorStartProgettoConvenzione: "Errore nell'avvio del processo di convenzione per il progetto selezionato",
    errorStartProcessoGraduatoria: "Errore nell'avvio del processo di convenzione per il progetto selezionato",
    errorStartProcessoSovvenzione: "Errore nell'avvio del processo di convenzione per il progetto selezionato",
    errorStartProgettoModifica: "Errore nell'avvio del processo di modifica per il progetto selezionato",

    //ALLEGATI
    deleteError: function(str) {
        return "Errore nell'eliminazione del file" + str
    },
    uploadFallito: "Caricamento Fallito",
    deleteSuccess: function(str) {
        return str + " eliminato"
    },
    uploadSuccess: function(str) {
        return str + " caricato con successo"
    },
    unexpectedLoadStatus: function(str) {
        return str + ":caricamento fallito"
    },
    getFilesPerRid: "Impossibile ottenere l'elenco degli allegati",
    getOrganigrammaPerRid: "Impossibile ottenere l'elenco dei file di organigramma del beneficiario",
    downloading: function(str) {
        return "Download di " + str + " in corso..."
    },
    downloadError: function(str) {
        return "Errore nel download di " + str
    }
}

// usage:
//
// import notify from "@/helpers/notifications.js"
// ...
// notify.error(notify.strings.error, notify.strings.emptyArchive)
// notify.error(notify.strings.error, notify.strings.noArchivedDataForProject(this.idProgetto), 4000)

export default {
    warn,
    info,
    error,
    success,
    strings
}