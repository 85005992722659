import Vue from 'vue'
import Vuex from 'vuex'
import 'es6-promise/auto' // IE needs this to run vuex
import VuexPersist from 'vuex-persist'
import selettoreStore from './modules/selettore.js'
// import userStore from './modules/user.js'
import executingStore from "./modules/executingBody.js"
import istruttoriaStore from "./modules/istruttoria.js"
import progettoStore from "./modules/progetto.js"
import controlliStore from "./modules/controlli.js"
import irregolaritaStore from "./modules/irregolarita.js"
import circuitoFinanziarioStore from "./modules/circuitoFinanziario.js"
import disposizioniStore from "./modules/disposizioni.js"
import bandoStore from './modules/bando.js'
import convenzioneStore from './modules/convenzione.js'
import monitoraggioStore from './modules/monitoraggio.js'
import selettoreAsses from './modules/selettoreAssessment.js'
import assessmentStore from './modules/assessment.js'
import storicoAzioniStore from './modules/storicoAzioni.js'
import calendarStore from './modules/calendar.js'
import configStore from './modules/configuration.js'
import cronoprogStore from './modules/cronoprog.js'

Vue.use(Vuex)

// set debug when NODE_ENV env var is not set to 'production'
const debugEnabled = process.env.NODE_ENV !== 'production'

// config of VuexPersist plugin
const vuexPersist = new VuexPersist({
    key: 'ISF',
    storage: window.localStorage // easily switch between sessionStorage and localStorage
})

export default new Vuex.Store({
    modules: {
        // use the defined key to navigate one of yours vuex modules.
        // e.g. in a component recall it as: this.$store.state.[module].[property]
        // note: there's no need to import store.js into your component
        selettore: selettoreStore,
        progetto: progettoStore,
        controlli: controlliStore,
        irregolarita: irregolaritaStore,
        circuitoFinanziario: circuitoFinanziarioStore,
        disposizioni: disposizioniStore,
        executingBody: executingStore,
        istruttoria: istruttoriaStore,
        announcements: bandoStore,
        convenzione: convenzioneStore,
        monitoraggio: monitoraggioStore,
        selettoreAss: selettoreAsses,
        assessment: assessmentStore,
        storicoAzioni: storicoAzioniStore,
        scadenziario: calendarStore,
        configuration: configStore,
        cronoprog: cronoprogStore
    },
    strict: debugEnabled,
    plugins: [vuexPersist.plugin]
        //plugins: debugEnabled ? [createLogger()] : []
})