import Vue from "vue";
import endpoints from "@/components/endpoints.vue";
import notify from "@/helpers/notifications.js";

const state = {
    tabelloni: {},
};

const getters = {
    //restituisce solo le chiavi dentro il content
    getTabellone(state) {
        return type => {
            if(state.tabelloni[type])
                return state.tabelloni[type].tabellone;
            return {};
        }
    },
    //restituisce sia il content che la versione
    getTabelloneFull(state) {
        return type => {
            return state.tabelloni[type];
        }
    },
};

const actions = {
    setTabellone(context, type) {
        const url = endpoints.retrieveTabellone(type);
        return Vue.prototype.$getAll(url).then(
            (result) => {
                
                const payload = {
                    type: type,
                    tabellone: result.data
                }
                //Se mi viene restituita una versione del tabellone negli header,
                // la inserisco tra i campi
                if(result.headers['document-version'])
                    payload.version=result.headers['document-version']
                
                context.commit("SET_TABELLONE", payload);
                return result.data;
            },
            (error) => {
                console.error("Error tabellone", type, error);
                notify.error(notify.strings.error, notify.strings.internalConfigurationError);
                throw error;
            },
        )
    },
    //Recupera eventualmente la versione specifica del tabellone
    setTabelloneVersioned(context, payload) {
        const url = payload.version ? endpoints.retrieveVersionedTabellone(payload) 
        : endpoints.retrieveTabellone(payload.type)
        return Vue.prototype.$getAll(url).then(
            (result) => {
                const p = {
                    type: payload.type,
                    tabellone: result.data
                }
                //Se mi viene restituita una versione del tabellone negli header,
                // la inserisco tra i campi
                if(result.headers['document-version']) {
                    p.version=result.headers['document-version']
                } else {
                    p.version = payload.version;
                }
                
                context.commit("SET_TABELLONE", p);
                return result.data;
            },
            (error) => {
                console.error("Error tabellone", payload.type, error.response);
                notify.error(notify.strings.error, notify.strings.internalConfigurationError);
                throw error;
            },
        )
    },
};

const mutations = {
    SET_TABELLONE(state, payload) {
        if(!payload.type || ! payload.tabellone)
            console.error('SET_TABELLONE no type or tabellone', payload);
        state.tabelloni[payload.type] = {
            tabellone: payload.tabellone,
            version: payload.version
        }
    },
    RESET(state) {
        state.tabelloni = {};
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};