<template>
  <div>
    <b-overlay :show="!loadComplete || generationPdf" rounded="lg">
      <div class="table-title">Scheda Controllo Amministrativo per il Progetto: {{this.$route.params.codiceProgetto}}</div>
      <div v-if="loadComplete">
        <div class="table-title">Fase controllo: {{this.controllo.sottoTipoControllo}} Titolo: {{this.controllo.content.titoloControllo}}           
        </div>
      
        <b-table-lite
          thead-class="head"
          :items="tabledata.rows"
          :fields="tabledata.header"/>
        <b-card no-body>
          <b-tabs card nav-class="tabs" content-class="content">
            <ColorLegend/>
            <b-tab
              v-if="loadComplete"
              no-body
              :title="conf_export_pdf['Anagrafica'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Anagrafica'].name)">
              <GeneralViewerCard
                :idTablePdf="conf_export_pdf['Anagrafica'].id_table_pdf"
                :name="conf_export_pdf['Anagrafica'].name"
                :tabName="tabSelection"
                :headerTable="headerAnagrafica"
                :pathDataEntryConfiguration="'content'"
                :actions="actionsMap['Anagrafica']"
                :store="'controlli'"
                :fnGetterTab="'getTabellone'"
                :rowIds="rowIdsAnagrafica[this.controllo.sottoTipoControllo]"
                :fnGetter="'getScheda'"
                :idEntity="{
                  codiceProgetto: this.$route.params.codiceProgetto,
                  id: this.$route.params.idControllo
                }"
                :fnSetter="'updateScheda'"
              />
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('Procedura')"
              no-body
              :title="conf_export_pdf['Procedura'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Procedura'].name)">
              <procedura2
                :idTablePdf="conf_export_pdf['Procedura'].id_table_pdf"
                :name="conf_export_pdf['Procedura'].name"
                :allowedActions="actionsMap['Procedura']"
                :dinamicIds="rowIds_procedura"
                :idEntity="{
                  idProgetto: controllo.content.infoProgetto.idProgetto,
                  procAlfaId: getCronoprogItemId
                }"
                :tabName="tabSelection"/>
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('Contratto')"
              no-body
              :title="conf_export_pdf['Contratto'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Contratto'].name)">
              <contratto2
                :idTablePdf="conf_export_pdf['Contratto'].id_table_pdf"
                :name="conf_export_pdf['Contratto'].name"
                :allowedActions="actionsMap['Contratto']"
                :dinamicIds="rowIds_contratto"
                :idEntity="{
                  idProgetto: controllo.content.infoProgetto.idProgetto,
                  procAlfaId: getCronoprogItemId

                }"
                :tabName="tabSelection"/>
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('DocumentazioneControllo')"
              no-body
              :title="conf_export_pdf['Documentazione'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Documentazione'].name)">
              <Documentazione
                :ownerProcessStatus= this.retrieveTaskDefinitionKey
                :actions="actionsMap['DocumentazioneControllo']"
                :entity= this.entity
                :getters = this.getters
                :setters = this.setters
                :payload = this.payload
                :riferimento = this.riferimento
                tabName="DocumentazioneControllo"
              />
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('Pagamenti')"
              no-body
              :title="conf_export_pdf['Dichiarazione'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Dichiarazione'].name)">
              <GeneralViewerCard
                :idTablePdf="conf_export_pdf['Dichiarazione'].id_table_pdf"
                :name="conf_export_pdf['Dichiarazione'].name" 
                :tabName="tabSelection"
                :headerTable="headerAnagrafica"
                :pathDataEntryConfiguration="'content'"
                :store="'controlli'"
                :fnGetterTab="'getTabellone'"
                :rowIds="rowIdsDichiarazione"
                :fnGetter="'getScheda'"
                :fnSetter="'updateScheda'"
                :idEntity="{
                  codiceProgetto: this.$route.params.codiceProgetto,
                  id: this.$route.params.idControllo
                }"
                :optionalObj="getCronoprogFromStore({idScheda: this.getIdProgetto})"
                :doRefresh="refreshDich"
                :actions="actionsMap['Dichiarazione']"/>
              <br/>
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('Pagamenti')"
              :title="conf_export_pdf['Pagamenti'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Pagamenti'].name)">
              <Pagamenti
                :idTablePdf="conf_export_pdf['Pagamenti'].id_table_pdf"
                :name="conf_export_pdf['Pagamenti'].name"
                :idScheda="getIdProgetto"
                :actions="actionsMap['Pagamenti']"
                :dinamicIds="ids_pagamenti.dinamicIds"
                :tableIds="ids_pagamenti.tableIds"
                :rootId="ids_pagamenti.rootId"
                :tabName="tabSelection"
                :optionalObj="getControllo()"
                @refreshDichiarazione="callRefreshDich()"/>
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('AutoControllo')"
              no-body
              :title="conf_export_pdf['AutoControllo_collapse'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['AutoControllo_collapse'].name)">
              <Collapse :name="conf_export_pdf['AutoControllo_collapse'].nameCollapse"
                v-if="isViewAllowed('Pagamenti')">
                <ListaPagamenti
                  :idTablePdf="conf_export_pdf['AutoControllo_collapse'].id_table_pdf"
                  :tabName="tabSelection.toLowerCase()"
                  :idScheda="getIdProgetto"/>
              </Collapse>
              <AutoControllo
                v-if="loadComplete"
                :idTablePdf="conf_export_pdf['AutoControllo_AutoControllo'].id_table_pdf"
                :title="conf_export_pdf['AutoControllo_AutoControllo'].title_page_pdf"
                :tabName="tabSelection.toLowerCase()"
                :config="getTabellone"
                :dataObject="'autocontrollo'"
                :store="'controlli'"
                :fnGetter="'getScheda'"
                :fnSetter="'updateScheda'"
                :idEntity="{
                  codiceProgetto: this.$route.params.codiceProgetto,
                  id: this.$route.params.idControllo}"
                name="Check List AutoControllo"
                :headerTable="headerAutocontrollo"
                :actions="actionsMap['AutoControllo']"
              />
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('Controllo')"
              no-body
              :title="conf_export_pdf['Controllo_collapse'].title_page_pdf"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Controllo_collapse'].name)">
              <Collapse :name="conf_export_pdf['Controllo_collapse'].nameCollapse"
                v-if="isViewAllowed('Pagamenti')">
                <ListaPagamenti
                  :idTablePdf="conf_export_pdf['Controllo_collapse'].id_table_pdf"
                  :tabName="tabSelection.toLowerCase()"
                  :idScheda="getIdProgetto"/>
              </Collapse>
              <Controllo
                :idTablePdf="conf_export_pdf['Controllo_Controllo'].id_table_pdf"
                :title="conf_export_pdf['Controllo_Controllo'].title_page_pdf"
                v-if="loadComplete"
                :tabName="tabSelection.toLowerCase()"
                :config="getTabellone"
                :headerTable="headerControllo"
                name="Check List Controllo"
                :actions="actionsMap['Controllo']"
              />
            </b-tab>
            <b-tab
              v-if="loadComplete && isViewAllowed('InvioControllo')"
              no-body
              title="Invio"
              title-item-class="tab1"
              title-link-class="titleLink"
              v-on:click="setTabSelection(conf_export_pdf['Invio_mandatory'].name)">
              <Invio 
                v-if="loadComplete"
                :idsCustomEdit="ids_custom_edit"
                :idsToCheck="idsToCheck"
                :tabName="tabSelection"
                :actions="actionsMap['InvioControllo']"
                @buildPdf="generateReport"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Collapse from "@/components/collapse.vue"
import Pagamenti from "./controlPagamenti.vue"
import ListaPagamenti from "./pagamentiList.vue"
import procedura2 from "./procedura2.vue"
import contratto2 from "./contratto2.vue"
import Invio from "./invio.vue"
import Documentazione from "@/components/Documentazione.vue"
import notify from "@/helpers/notifications.js"
import AutoControllo from './CheckListSingleControl'
import Controllo from './CheckListControllo.vue'
import { mapGetters } from "vuex"
import GeneralViewerCard from "@/components/GeneralViewerCard.vue"
import utils from "@/helpers/utils.js"
import helper_pdf from "@/helpers/exportPdf.js"
import tools from '@/helpers/tools.js'
import ColorLegend from "@/components/legend.vue"


export default {
  name: "schedaControlliAmministrativi",
  components: {
    AutoControllo,
    Documentazione,
    Controllo,
    GeneralViewerCard,
    Invio,
    procedura2,
    contratto2,
    Pagamenti,
    Collapse,
    ListaPagamenti,
    ColorLegend
  },
  data() {
    return {
      //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
      riferimento : {
        idOggettoRif: "",
        tipoOggettoRif: "Scheda Controlli Amministrativi", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
        idBando: "",
        tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
        idProgetto: "",
        codiceProgetto: ""
      },
      refreshDich: false,
      entity: "controlli",
      idsToCheck:[],
      // headerTableCheckList: [],
      conf_export_pdf: {
        "Anagrafica": {
          id_table_pdf: 'anagrafica_controlli',
          name:"Anagrafica",
          title_page_pdf: 'Anagrafica',
          nameCollapse: '',
        },
        "Procedura": {
          id_table_pdf: 'procedura_controlli',
          name:"procedura2",
          title_page_pdf: 'Procedura',
          nameCollapse: '',
        },
        "Contratto": {
          id_table_pdf: 'contratto_controlli',
          name:"contratto2",
          title_page_pdf: 'Contratto',
          nameCollapse: '',
        },
        "Documentazione": {
          id_table_pdf: 'fileTable',
          name:"Documentazione",
          title_page_pdf: 'Documentazione',
          nameCollapse: '',
        },
        "Dichiarazione": {
          id_table_pdf: 'dichiarazione_controlli',
          name:"Dichiarazione",
          title_page_pdf: 'Dichiarazione',
          nameCollapse: '',
        },
        "Pagamenti": {
          id_table_pdf: 'pagamenti_controlli',
          name:"controlPagamenti",
          title_page_pdf: 'Pagamenti',
          nameCollapse: '',
          details: true
        },
        "AutoControllo_collapse": {
          id_table_pdf: 'pagamenti_autocontrollo',
          name:"AutoControllo",
          title_page_pdf: 'AutoControllo',
          nameCollapse: 'Pagamenti Dichiarazione di Spesa',
        },
        "AutoControllo_AutoControllo": {
          id_table_pdf: 'checklist_autocontrollo',
          name:"Check List AutoControllo",
          title_page_pdf: 'CheckList AutoControllo',
          nameCollapse: 'CheckList AutoControllo',
        },
        "Controllo_collapse": {
          id_table_pdf: 'pagamenti_controllo',
          name:"Controllo",
          title_page_pdf: 'Controllo',
          nameCollapse: 'Pagamenti Dichiarazione di Spesa',
        },
        "Controllo_Controllo": {
          id_table_pdf: 'checklist_controllo',
          name:"Check List Controllo",
          title_page_pdf: 'CheckList Controllo',
          nameCollapse: 'CheckList Controllo',
        },
        "Invio_mandatory": {
          id_table_pdf: 'mandatory_controlli',
          name:"InvioControllo",
          title_page_pdf: 'Invio',
          nameCollapse: 'Campi obbligatori non valorizzati',
        },
        "Invio_integrazioni": {
          id_table_pdf: 'integrazioni_controlli',
          name:"",
          title_page_pdf: 'Invio',
          nameCollapse: 'Richiesta Integrazioni',
        },
        "Invio_note": {
          id_table_pdf: 'note_controlli',
          name:"",
          title_page_pdf: 'Invio',
          nameCollapse: 'Elenco Note',
        },
        "Invio_note_riservate": {
          id_table_pdf: 'note_riservate_controlli',
          name:"",
          title_page_pdf: 'Invio',
          nameCollapse: 'Elenco Note Interne',
        },
        // L'ESITO FINALE DEL CONTROLLO SARA' INSERITO IN FONDO NELL'ULTIMA PAGINA DISPONIBILE
        "Invio_esito_finale": {
          id_field: "['content']['esitoFinale']",
          name: "Invio",
          title_page_pdf: 'Dettagli Invio',
          conf_field: { addPage: true, mapping: 'PosNeg' },
          descRadio: 'Esito finale del controllo: '
        }
      },
      headerAnagrafica: [
        { key: "campo" },
        {
          key: "descrizione",
          tdAttr: this.tooltip,
          formatter: ""
        },
        { key: "azione" }
      ],
      rowIdsAnagrafica: {
        "2": [
          "['anagrafica']['autore']",
          "['anagrafica']['dataInserimento']",
          "['anagrafica']['verificatore']",
          "['anagrafica']['dataVerifica']",
          "['anagrafica']['responsabileOperativoProgetto']",
          "['anagrafica']['codiceProgetto']",
          "['anagrafica']['titoloProgetto']",
          "['anagrafica']['costoProgetto']",
          "['anagrafica']['strumentoFinanziario']",
          "['anagrafica']['obiettivoSpecifico']",
          "['anagrafica']['obiettivoNazionale'][*]['codiceComposito']",
          "['anagrafica']['soggettoAttuatore']",
          "['anagrafica']['oggettoProcedura']",
          "['anagrafica']['tipologiaProcedura']",
          "['anagrafica']['numeroContratto']",
          "['anagrafica']['dataContratto']",
          "['anagrafica']['dataApprovazioneContratto']",
          "['anagrafica']['oggettoContratto']",
          "['anagrafica']['importoContratto']",
          
        ],
        "1B": [
          "['anagrafica']['autore']",
          "['anagrafica']['dataInserimento']",
          "['anagrafica']['verificatore']",
          "['anagrafica']['dataVerifica']",
          "['anagrafica']['idBeneficiario']",
          "['anagrafica']['codiceProgetto']",
          "['anagrafica']['titoloProgetto']",
          "['anagrafica']['costoProgetto']",
          "['anagrafica']['strumentoFinanziario']",
          "['anagrafica']['obiettivoSpecifico']",
          "['anagrafica']['obiettivoNazionale'][*]['codiceComposito']",
          "['anagrafica']['oggettoProcedura']",
          "['anagrafica']['tipologiaProcedura']",
          "['anagrafica']['soggettoAggiudicatario']",
        ],
        "1A": [
          "['anagrafica']['autore']",
          "['anagrafica']['dataInserimento']",
          "['anagrafica']['verificatore']",
          "['anagrafica']['dataVerifica']",
          "['anagrafica']['idBeneficiario']",
          "['anagrafica']['responsabileOperativoProgetto']",
          "['anagrafica']['codiceProgetto']",
          "['anagrafica']['titoloProgetto']",
          "['anagrafica']['costoProgetto']",
          "['anagrafica']['strumentoFinanziario']",
          "['anagrafica']['obiettivoSpecifico']",
          "['anagrafica']['obiettivoNazionale'][*]['codiceComposito']",
          "['anagrafica']['oggettoProcedura']",
          "['anagrafica']['tipologiaProcedura']",
        ]
      },
      rowIdsDichiarazione : [
          "['dichiarazione']['dal']",
          "['dichiarazione']['al']",
          "['dichiarazione']['importoTotaleRicevuto']",
          "['dichiarazione']['circuito']",
          "['dichiarazione']['speseTotaliPeriodo']",
          "['dichiarazione']['imponibile']",
          "['dichiarazione']['iva']",
          "['dichiarazione']['speseSostenute']",
          "['dichiarazione']['importoTotaleISF']",
      ],
      headerAutocontrollo: [
        {
          key: 'descrizione'
        },
        {
          key: 'esito',
          label: 'Esito'
        },
        {
          key: 'note',
          label: 'Note'
        },
       
       
        {
          key: 'docsRiferimento',
          label: 'Documentazione di riferimento'
        },
        {
          key: 'filesRiferimento',
          label: 'Documenti di riferimento'
        },
        {
          key: 'azione',
        }
      ],
      headerControllo: [
        {
          key: 'descrizione'
        },
        {
          key: 'docsRiferimento',
          label: 'Documentazione di riferimento'
        },
        {
          key: 'esito_auto', // Esito riferito ad Auto Controllo
          label: 'Esito AutoControllo'
        },
        {
          key: 'noteAuto', // Esito riferito ad Auto Controllo
          label: 'Note AutoControllo'
        },

        {
          key: 'esito', // Esito riferito a Controllo
          label: 'Esito Controllo'
        },
        {
          key: 'note', // Esito riferito a Controllo
          label: 'Note Controllo'
        },
        {
          key: 'filesRiferimento_auto', // Files riferiti ad Auto Controllo
          label: 'Documenti di riferimento AutoControllo'
        },
        {
          key: 'filesRiferimento', // Files riferiti a Controllo
          label: 'Documenti di riferimento Controllo'
        },
        {
          key: 'azione',
        }
      ],
      controllo: {},
      cronoprog: {},
      tabSelection: "Anagrafica",
      loadComplete: false,
      generationPdf: false,
      configAnagrafica: {},
      actionsMap: {},
      getters: {
        scheda: "getScheda",
        tabellone: "getTabellone"
      },
      setters: {
        scheda: "updateScheda",
        allegatoDaCancellare: "setAllegatoDaCancellare",
        clearAllegatiDaCancellare: "clearAllegatiDaCancellare"
      },
      payload: {
        scheda: {          
          codiceProgetto: this.$route.params.codiceProgetto, 
          id: this.$route.params.idControllo},
      },
      ids_custom_edit: [
        // IDS soggetti a edit Custom
        // considerare nel computo degli obbligatori
        "['anagrafica']['autore']",           // edit beneficiarioOper
        "['anagrafica']['dataInserimento']",  // edit beneficiarioOper
        "['anagrafica']['verificatore']",     // edit ufficioControllore
        "['anagrafica']['dataVerifica']",     // edit ufficioControllore
      ],
      rowIds_procedura: [
        //stesso ordine di visualizzazione nella modale del monitoraggio
        "['procedure'][*]['content']['idProceduraGara']",
        "['procedure'][*]['content']['tipologiaProcedura']",
        "['procedure'][*]['content']['oggetto']",
        "['procedure'][*]['content']['status']",
        "['procedure'][*]['content']['tipologiaProceduraAvviata']",
        "['procedure'][*]['content']['descrizioneBreve']",
        "['procedure'][*]['content']['tipoIterAmministrativo']",
        "['procedure'][*]['content']['rifNormativo']",
        "['procedure'][*]['content']['baseAsta']",
        "['procedure'][*]['content']['costiManutenzioneIT']",
        "['procedure'][*]['content']['stazioneAppaltante']",
        "['procedure'][*]['content']['identificativoConvenzione']",
        "['procedure'][*]['content']['tipologiaAttivita']",
        "['procedure'][*]['content']['prestazione']",
        "['procedure'][*]['content']['enteAccreditato']",
        
        "['procedure'][*]['content']['provvedimentoStaModificaIter']",
        "['procedure'][*]['content']['provvedimentoOggetto']",
        "['procedure'][*]['content']['cig']",
        "['procedure'][*]['content']['cup']",
        "['procedure'][*]['content']['pubblicazioneGuue']",
        "['procedure'][*]['content']['pubblicazioneGuri']",
        "['procedure'][*]['content']['pubblicazioneSitoMit']",
        "['procedure'][*]['content']['pubblicazioneAnac']",
        "['procedure'][*]['content']['pubblicazioneSitoCommittente']",
        "['procedure'][*]['content']['pubblicazioneQuotidiani']",
        "['procedure'][*]['content']['aggiudicazioneGuue']",
        "['procedure'][*]['content']['aggiudicazioneGuri']",
        "['procedure'][*]['content']['aggiudicazioneSitoMit']",
        "['procedure'][*]['content']['aggiudicazioneAnac']",
        "['procedure'][*]['content']['aggiudicazioneSitoCommittente']",
        "['procedure'][*]['content']['soggettoAggiudicatario']",
        "['procedure'][*]['content']['aggiudicazioneQuotidiani']",
        "['procedure'][*]['content']['note']",
        
        
        
        
        
      ],
      rowIds_contratto: [
        //stesso ordine di visualizzazione nella modale del monitoraggio
        "['contratti'][*]['content']['riferimentoContratto']",
        "['contratti'][*]['content']['codiceAtto']",
        "['contratti'][*]['content']['dataContratto']",
        "['contratti'][*]['content']['oggettoContratto']",
        "['contratti'][*]['content']['importoContratto']",
        "['contratti'][*]['content']['importoContrattoIVA']",
        "['contratti'][*]['content']['importoContrattoTotale']",
        "['contratti'][*]['content']['importoANAC']",
        "['contratti'][*]['content']['importoContrattoSuISF']",
        "['contratti'][*]['content']['soggettoAttuatore']",
        "['contratti'][*]['content']['note']"
      ],
      ids_pagamenti: {
        rootId: 'pagamenti',
        dinamicIds: [
          "['pagamenti'][*]['content']['flagged']",
          "['contratti'][*]['content']['riferimentoContratto']",
          "['pagamenti'][*]['content']['rifDichiarazione']",
          "['pagamenti'][*]['content']['importoDaRimborsare']",
          "['pagamenti'][*]['content']['numeroFattura']",
          "['pagamenti'][*]['content']['dataFattura']",
          "['pagamenti'][*]['content']['oggettoFattura']",
          "['pagamenti'][*]['content']['imponibileFattura']",
          "['pagamenti'][*]['content']['ivaFattura']",
          "['pagamenti'][*]['content']['importoFatturaSuISF']",
          "['pagamenti'][*]['content']['riferimentoCircuito']",
          "['pagamenti'][*]['content']['importoCircuito']",
          "['pagamenti'][*]['content']['totaleFattura']",
          "['pagamenti'][*]['content']['note']"
        ],
        tableIds: [
          "['pagamenti'][*]['content']['flagged']",
          "['contratti'][*]['content']['riferimentoContratto']",
          "['pagamenti'][*]['content']['numeroFattura']",
          "['pagamenti'][*]['content']['dataFattura']",
          "['pagamenti'][*]['content']['oggettoFattura']",
          "['pagamenti'][*]['content']['importoFatturaSuISF']",

        ]
      },
      tabledata: {
        header: [
          {
            key: "codiceProgetto"
          },
          {
            key: "titolo"
          },
          {
            key: "stato"
          },
          {
            key: "dataConclusione",
            formatter: this.dateFormatter
          }
          
        ],
        rows: [
          {
            "codiceProgetto": "Non disponibile",
            "titolo": "Non disponibile",
            "stato": "Non disponibile",
            "dataConclusione": null
          }
        ],
      }
    }
  },
  mounted() {
    //prima di caricare altri tabelloni resetto lo store
    this.$store.commit('controlli/RESET');
    //API di lettura del controllo per ID
    this.loadControlAndConfigs(this.$route.params.codiceProgetto, this.$route.params.idControllo)
    //this.tab_procedura = procedura_json
    this.riferimento.idOggettoRif = this.$route.params.idControllo;
    this.riferimento.codiceProgetto = this.$route.params.codiceProgetto;
  },
  computed: {
    ...mapGetters({
      getTabellone: "controlli/getTabellone",
      getScheda: "controlli/getScheda",
      getCronoprogFromStore: "cronoprog/getScheda",
      getTabelloneCronoprog: "configuration/getTabellone"
    }),
    retrieveTaskDefinitionKey() {
      if (this.controllo && this.controllo.content && this.controllo.content.taskInfo && this.controllo.content.taskInfo.taskDefinitionKey)
        return this.controllo.content.taskInfo.taskDefinitionKey
      else 
        return "Controllo_controllo-concluso_Nessuno-Nessuno"      //fake status non più tornato dal backend ISF-controlli
    },
    getIdProgetto() {
      let idProgetto = this.controllo?.content?.infoProgetto?.idProgetto
      if(idProgetto)
        return idProgetto
      return ''
    },
    getCronoprogItemId() {
      return this.controllo.cronoprogItemId;
    },
  },
  methods: {
    callRefreshDich() {
      this.refreshDich = !this.refreshDich;
    },
    getControllo() {
      const payload = {
        codiceProgetto: this.$route.params.codiceProgetto, 
        id: this.$route.params.idControllo
      };
      return this.getScheda(payload)
    },
    getCurrentScheda() {
      let scheda = this.getScheda({ codiceProgetto: this.$route.params.codiceProgetto, id: this.$route.params.idControllo })
      let clonedScheda = tools.genericFunctions.cloneObject(scheda)
      return clonedScheda
    },
    generateReport(optPdf) {
      this.generationPdf = true

      // GESTIONE OCCHI
      let source = {
        idScheda: this.$route.params.idControllo,
        scheda: this.getCronoprogFromStore({idScheda: this.getIdProgetto}),
        ids_eyes: this.ids_pagamenti,
        mappa : this.getTabelloneCronoprog('cronoprog_controlli')
      }
      
      // CONDIZIONE PER ABILITARE I DETTAGLI NEL PDF
      // In questo caso il Dettaglio dei Pagamenti verrà abilitato solo per Fase 2
      if(this.getControllo().sottoTipoControllo !== '2')
        this.conf_export_pdf['Pagamenti'].details = false
      this.conf_export_pdf['Pagamenti'].source = source
      // GESTIONE OCCHI

      // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)
      let role = this.$getUserRole()
      if( this.getCurrentScheda().tipoControllo && this.getCurrentScheda().tipoControllo.includes('In Loco') ||
        role.includes('UfficioControlli') ||
        role.includes('UfficioEconomicoFinanziario') ||
        role.includes('UfficioGestione')) {
          let source_controllo = {  scheda: this.getCurrentScheda() }
          this.conf_export_pdf['Invio_esito_finale'].source = source_controllo
      } else delete this.conf_export_pdf['Invio_esito_finale']
      // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)

      helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
      this.generationPdf = false
    },
    dateFormatter(value) {
      if (!value) 
        return "Non disponibile";
      return utils.formatDate(value);
    },
    loadTabelloneControllo() {
      this.$store.dispatch("controlli/setTabelloneControllo", {
        schedaControllo: this.controllo
      }).then(
        () => {
          this.setTabelloneProcedura()
        }
      ).catch( error => {
        console.error('Errore store dispatch: TAB_CONTROLLO', error.message)
        notify.error(notify.strings.error, notify.strings.errorLoadControlli)
      })
    },
    setTabelloneProcedura() {
      Promise.all([
        this.$store.dispatch("controlli/setTabelloneProcedura", {}),
        this.$store.dispatch("configuration/setTabelloneVersioned", { type: 'cronoprog_controlli', version: this.controllo.cronoSchemaVersion }),
        this.$store.dispatch("configuration/setTabellone", 'procedure_tipologie')
      ]).then(
        () => {

          // aggiungo la descrizione della checklist nei nomi dei collapse di autocontrollo e controllo

          const checklistLabel = this.controllo.checklistLabel;

          if (checklistLabel) {
                const descProcedura = ' ( ' + checklistLabel + ' ) ';
                let expPdf = this.conf_export_pdf['AutoControllo_AutoControllo'];
                expPdf.nameCollapse = expPdf.nameCollapse.concat(descProcedura);
                expPdf.title_page_pdf = expPdf.title_page_pdf.concat(descProcedura);

                expPdf = this.conf_export_pdf['Controllo_Controllo'];
                expPdf.nameCollapse = expPdf.nameCollapse.concat(descProcedura);
                expPdf.title_page_pdf = expPdf.title_page_pdf.concat(descProcedura);
          }


          let tipoControllo = this.controllo.sottoTipoControllo
          let mappingIdsToCheck = {
            "1A": { dinamicIds: this.rowIds_procedura },
            "1B": { dinamicIds: this.rowIds_contratto },
            "2": { dinamicIds: this.ids_pagamenti.dinamicIds, rowIdsDichiarazione: this.rowIdsDichiarazione}
          }
          if(Object.keys(mappingIdsToCheck).indexOf(tipoControllo) > -1)
            this.idsToCheck = mappingIdsToCheck[tipoControllo]
          //carico le azioni concesse per ogni TAB
          this.loadActionMap();
          this.loadComplete = true
        }
      ).catch( error => {
        console.error('Errore store dispatch: TAB_PROCEDURA: ', error.message)
        notify.error(notify.strings.error, notify.strings.errorLoadControlli)
      })
    },
    loadActionMap(){
      let scheda = this.controllo;
      //lista degli ID dei tab previsti per questa scheda
      let tabNames = ["Anagrafica", "DocumentazioneControllo", "Controllo", "AutoControllo", "Procedura", "Dichiarazione", "Contratto", "Pagamenti", "InvioControllo"];

      for (let tabName of tabNames) {
        this.actionsMap[tabName] = this.$getActionsControlliAmm(scheda, tabName);
      }

    },
    isViewAllowed(tabName) { //se ho diritto ad almeno un'azione su questo TAB devo vederlo
      return this.actionsMap[tabName].length > 0
    },
    // Carico il controllo Amministrativo corrente nello Store
    loadControlAndConfigs(codice, id) {

      this.$store.dispatch("controlli/setControlloAmministrativo", {
        codiceProgetto: codice,
        idSchedaControllo: id
      }).then(
        (schedaControllo) => {
          this.controllo = schedaControllo;
          if (schedaControllo.content.infoProgetto.idProgetto){
            this.riferimento.idProgetto = schedaControllo.content.infoProgetto.idProgetto;
          }
          this.populateTabledata();

          const cronoprogKey = schedaControllo.cronoprogKey
          console.log('cronokey in scheda:', cronoprogKey);
          this.getCronoprog().then(
            result => {
              console.log('current cronoprog key:', result.key);
              this.cronoprog = result;
              this.loadTabelloneControllo();
            }
          ).catch( error => {
            console.error('Errore get cronoprog: ', error.message)
            notify.error(notify.strings.error, notify.strings.errorLoadControlli)
          })
        }
      ).catch( error => {
        console.error('loadControlAndConfigs Errore store dispatch: ', error.message)
        notify.error(notify.strings.error, notify.strings.errorLoadControllo)
      })
    },

    getCronoprog() {
      const idProgetto = this.controllo.content.infoProgetto.idProgetto;
      const sottoTipoControllo = this.controllo.sottoTipoControllo;
      const cronoprogKey = this.controllo.cronoprogKey;
      const idSchedaControllo = this.controllo.idSchedaControllo;
      const payload = {
        idProgetto: idProgetto,
        cronoprogKey: cronoprogKey,
        branch: 'controlli' + sottoTipoControllo,
        idSchedaControllo: idSchedaControllo
      }
      return this.$store.dispatch('cronoprog/retrieveScheda', payload)
    },
    populateTabledata(){
      //agisco direttamente sulla prima riga inizializzata con "Non disponibile"
      this.tabledata.rows[0].codiceProgetto = this.controllo.codiceProgetto;
      if (this.controllo.content.infoProgetto){
        if (this.controllo.content.infoProgetto.titolo)
          this.tabledata.rows[0].titolo = this.controllo.content.infoProgetto.titolo;
        if(this.controllo.content.infoProgetto.stato)  
          this.tabledata.rows[0].stato = this.controllo.content.infoProgetto.stato;
        if(this.controllo.content.infoProgetto.dataConclusione)  
          this.tabledata.rows[0].dataConclusione = this.controllo.content.infoProgetto.dataConclusione;
      }
    },

    setTabSelection(name) {
      console.log("Click sul tab =", name)
      this.tabSelection = name
    },
  }
}
</script>