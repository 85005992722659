<template>
  <div id="invioAss">
    <collapse :name="'Campi obbligatori non valorizzati ('+tabledata.rows.length+')'">
        <br/>
        <div v-if="tabledata.rows.length>0">
            <DynamicTable :idTablePdf="'mandatory_ass'" :tdata="tabledata"/>
        </div>
        <div v-else>
            Nessuna campo obbligatorio da valorizzare
        </div>           
    </collapse>

    
        <div v-if="tabledata.rows.length>0" class="warning-text">
            <br/>
            <strong>ATTENZIONE: l'assenza di valore su campi obbligatori può comportare errori nel processo di approvazione.
            <br/>
            Verificare i dati del progetto e/o dell'ultimo monitoraggio</strong>
        </div>    
    <br/>

    <div class="section-title">
        <collapse class="mb-5" :name="'Note (' + fillNote.length + ')'">
            <br />
            <TableNotes :id="'note_ass'" v-if="fillNote.length > 0" :notes="fillNote"/>
            <h4 v-else>
                Nessuna nota da mostrare
            </h4>
        </collapse>
    </div>
    <collapse v-if="isVisibleInternalNote" :name="'Elenco Note Interne ('+ fillNoteRiservate.length+')'">
            <br/>
            <TableNotes :id="'invio_note_riservate'" :notes="fillNoteRiservate"/>
            <div class="note" v-if="!isHiddenNote">
            <br>
                <b-form-textarea
                    id="textAreaNoteRiservate"
                    :disabled="this.disableButtons"
                    v-model="noteRiservate"
                    placeholder="Inserire qui le note interne"/>
            </div>
            <div class="pulsanti-invio" v-if="!isHiddenNote">
                <b-button :id="'cancelNoteRiservate_monitoraggio'"
                    class="mx-3 btnReservedNote"
                    :disabled="this.disableButtons"
                    variant="danger"
                    @click="resetReservedNote()"
                    >Cancella Nota Interna</b-button>
            </div>
            <br/>
    </collapse>


    <div class="note" v-if="!isHiddenNote">
        <br/>
        <b-form-textarea id="textarea_note_assessment" :disabled="disableButtons" v-model="note" placeholder="Inserire qui le note (opzionali)"/><br>
    </div>
    <div v-if="this.incomingData" class="pulsanti-invio">
        <b-button :id="'export_pdf_assessment'" variant="secondary" :disabled="disableButtons" class="bt1" @click="preparePdf"> Export PDF</b-button>
        <b-button :id="'save_assessment'" :disabled="disableButtons" variant="primary" class="bt1" @click="save" v-if="!isHiddenSave">Salva in Bozza</b-button>
        <b-button :id="'send_assessment'" :disabled="disableButtons" variant="success" class="bt1" @click="openCompletaAssessment('send')" v-if="!isHiddenSend">Salva e Invia scheda di assessment</b-button>
        <b-button :id="'approve_assessment'" :disabled="disableButtons" variant="success" class="bt1" @click="openCompletaAssessment('approve')" v-if="!isHiddenApprove">Approva</b-button>
        <b-button :id="'reject_assessment'" :disabled="disableButtons" variant="danger" class="bt1" @click="openCompletaAssessment('reject')" v-if="!isHiddenReject">Rifiuta</b-button>
        <b-button :id="'cancelNote_assessment'" :disabled="disableButtons" variant="danger" class="bt1" @click="reset" v-if="!isHiddenNote">Cancella Nota</b-button>
        <b-modal id="modalCompletaInvia"
                centered size="lg"
                ok-variant='success' ok-title='Invia'
                cancel-title='Annulla'
                @ok="actionModal()">
                <template v-slot:modal-header><span class="esitoFinale">{{modal1Map[setModal].title}}</span></template>
                <p class="mb-4">{{modal1Map[setModal].text}}</p>
            </b-modal>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import utils from '@/helpers/utils.js'
import notify from "@/helpers/notifications.js"
import DynamicTable from "@/components/dynamicTable.vue";
import collapse from "@/components/collapse.vue"
import mapUtils from '@/helpers/mapUtils.js'
import TableNotes from "@/components/TableNotes.vue"
import helper_pdf from "@/helpers/exportPdf.js"
import tools from '@/helpers/tools.js'


export default {
    name: "invioAss",
    components: {
        DynamicTable,
        collapse,
        TableNotes
    },

    props: {
        incomingData: Object,
        tabName : String,
        idsToCheck: Object,  default: function() { return {} }
    },
    watch: {
        tabName: function() {
            if(this.$props.tabName === "invioAss") {
                this.extractData();
            }
        }
    },
    computed: {
        ...mapGetters({
            // getNoteInBozza: 'assessment/getNoteInBozza',
            getSchedaCronoprog: 'cronoprog/getSchedaComplete',
            getTabelloneFull: 'configuration/getTabelloneFull'
        }),

        fillNote: function() { // TODO queste sono le note in scheda assessment, aggiungere le note in bozza
            if(!this.incomingData || !this.incomingData.schedaAssessment || !this.incomingData.schedaAssessment.note)
                return [];
            return this.$props.incomingData.schedaAssessment.note;
        },
        fillNoteRiservate: function() {
            const scheda = this.incomingData.schedaNoteRiservate;
            if(!scheda)
                return [];
            return scheda?.content?.noteRiservate || [];
        }
  },

  data() {
    return {
        noteRiservate: '',
        isVisibleInternalNote: false,
        loadComplete: false,
        note: "",
        schemaVersioneCronoprog: null,
        isHiddenSave: true,
        isHiddenSend: true,
        isHiddenApprove: true,
        isHiddenReject: true,
        isHiddenNote: true,
        disableButtons: false,
        
        modal1Map:{
            approve:{
                title:"Conferma Assessment", text:"Conferma di voler Approvare l'Assessment? "
            },
            send:{
                title:"Conferma Assessment", text:"Conferma di voler Inviare l'Assessment?"
            },
            reject:{
                title:"Rifiuta Assessment", text:"Conferma di voler Rifiutare l'Assessment?"
            },
            default:{
                title:"x", text:"x"
            }
            

        },
        
        setModal:"default",
        tab_cronoProg: {},
        tabledata: {
            header: ["Tab", "Nome Campo", "Tipo dato"],
            rows: []
        },
    };
  },
  mounted() {
    const tabelloneFull = this.getTabelloneFull('cronoprog_assessment');
    this.tab_cronoProg = tabelloneFull.tabellone;
    this.schemaVersioneCronoprog = tabelloneFull.version;
    this.extractData();
    this.fillNoteInBozza();
    this.fillNoteRiservateInBozza();
    this.loadComplete = true;
  },

  methods: {
    fillNoteRiservateInBozza() {
        this.noteRiservate = this.incomingData.schedaAssessment.notaTemporaneaRiservata;
    },
    extractData() {
        this.elaboraMandatori();
        this.getVisibility();
        
    },
    preparePdf() {
        let type = "Interim";
        if(this.$route.name === 'schedaFinal')
            type = 'Final';
        const scheda = this.incomingData.schedaAssessment
        let typeScheda = type + ' Assessment';
        let optPdf = helper_pdf.handleNamingPdf(scheda, typeScheda)
        this.$emit('buildPdf', optPdf)
    },
    getCronoprog(idScheda){
        return this.getSchedaCronoprog({idScheda: idScheda}).scheda
    },
    getMandatoryCronoProgMissed() { 
      const idProgetto = this.incomingData.schedaAssessment.idProgetto
      const schedaCrono = this.getCronoprog(idProgetto).content
      return mapUtils.getMandatoryFieldsCronoprog(schedaCrono, this.idsToCheck, this.tab_cronoProg)
    },
    fillNoteInBozza(){
       this.note = this.incomingData.schedaAssessment.notaTemporanea;
    },

    async save() {
        if(!this.incomingData) {
            console.error("invio.save: Invalid scheda!?");
            return false;
        }
        const scheda = tools.genericFunctions.cloneObject(this.incomingData.schedaAssessment);
        this.disableButtons = true;
        let progetto = scheda.codiceProgetto;
        let periodo = scheda.idPeriodo;
        
        scheda.notaTemporanea = this.note;
        scheda.notaTemporaneaRiservata = this.noteRiservate;
        const idProgetto = scheda.idProgetto
        console.log("cliccato su save per ", progetto, periodo, idProgetto);

        let entity = { assessment: scheda };
        let cronoprog = {}
        if(idProgetto && this.incomingData.taskList.name === "Beneficiario-Operatore_inserimentoDati") {
            //Se sono al primo step, salvo anche la scheda cronoprog
            cronoprog = this.getCronoprog(idProgetto) 
            entity.cronoprog = cronoprog
        }


        console.log("Save Request Body: ", entity);
        
        let retValue = null;
        await this.$store.dispatch('assessment/saveSchedaAssessment', {
            codiceProgetto: progetto,
            idPeriodo: periodo,
            scheda: entity
        }).then(
            result => {
                console.log("save RESULT: ", result);
                
                if (tools.objectHasValue(result)) {
                    retValue = result;
                    notify.success(notify.strings.success, notify.strings.saveAssessmentOk);
                } else  {
                    notify.error(notify.strings.error, notify.strings.saveAssessmentError);
                    retValue = null;
                }
            },
            error => {
                notify.error(notify.strings.error, notify.strings.saveAssessmentError + notify.strings.requestStatus(error.response.status));
                retValue = null;
            }
        );
        this.disableButtons = false;
        if (!retValue.cronoprog){
            retValue.cronoprog = {
                key: scheda.cronoprogKey
            };
        }
        return retValue;
    },
    actionModal(){
         switch (this.setModal) {
                    case "send":
                        return this.send();
                    case "reject":
                       return this.reject();
                    case "approve":
                       return this.approve();
                    
                    default:
                        console.log("Case false");
                    }
    },
    openCompletaAssessment(action){
            this.setModal=action;
            this.$bvModal.show('modalCompletaInvia');
            },
    
    
    async send() {
        if(!this.incomingData) {
            console.error("invio.send: Invalid scheda!?");
            return;
        }
        const scheda = this.incomingData.schedaAssessment;
        const codiceProgetto = scheda.codiceProgetto;
        const idPeriodo = scheda.idPeriodo;
        console.log("cliccato su send per ", codiceProgetto, idPeriodo);
        let saveResult = await this.save();
        if(!saveResult) {
            console.log('salvataggio fallito');
            return false;
        }
        this.disableButtons = true;
        // la key di cronoprog e la versione del suo tabellone vengono passati sempre
        // ma il backend li setta solo nelle giuste fasi del processo
        const cronoKey = saveResult.cronoprog.key;
        const schemaVersioneCronoprog = this.schemaVersioneCronoprog;


        await this.$store.dispatch('assessment/completeSchedaAssessment', {
            codiceProgetto: codiceProgetto,
            idPeriodo: idPeriodo,
            approvazione: true,
            cronoprogKey: cronoKey,
            schemaVersioneCronoprog: schemaVersioneCronoprog,
        }).then(
            () => {
                this.$store.dispatch('assessment/clearSchedaAssessment', {
                    codiceProgetto: codiceProgetto,
                    idPeriodo: idPeriodo,
                });
                notify.success(notify.strings.success, notify.strings.sendAssessmentOk);
                this.$router.push({ name: "secure"});
            },
            error => {
                console.log('error SEND', error.response);
                notify.error(notify.strings.error, notify.strings.sendAssessmentError + notify.strings.requestStatus(error.response.status));
            }
        );

        this.disableButtons = false;
    },

    async approve() {
        if(!this.incomingData) {
            console.error("invio.approve: Invalid scheda!?");
            return;
        }
        const scheda = this.incomingData.schedaAssessment;
        const codiceProgetto = scheda.codiceProgetto;
        const idPeriodo = scheda.idPeriodo;
        console.log("cliccato su approve per " + codiceProgetto + ' ' + idPeriodo);

        let saveResult = await this.save();
        if(!saveResult) {
            console.log('salvataggio fallito');
            return false;
        }
        const cronoKey = saveResult.cronoprog.key;
        
        this.disableButtons = true;
        await this.$store.dispatch('assessment/completeSchedaAssessment', {
              codiceProgetto: codiceProgetto,
              idPeriodo: idPeriodo,
              cronoprogKey: cronoKey,
              approvazione: true
        }).then(
            () => {
                this.$store.dispatch('assessment/clearSchedaAssessment', {
                    codiceProgetto: codiceProgetto,
                    idPeriodo: idPeriodo,
                });
                notify.success(notify.strings.success, notify.strings.approveAssessmentOk);
                this.$router.push({ name: "secure"});
            },
            error => {
                console.log('error APPROVE', error.response);
                notify.error(notify.strings.error, notify.strings.approveAssessmentError + notify.strings.requestStatus(error.response.status));
            }
        );
        this.disableButtons = false;
    },

    async reject() {
        if(!this.incomingData) {
            console.error("invio.reject: Invalid scheda!?");
            return;
        }
        const scheda = this.incomingData.schedaAssessment;
        const codiceProgetto = scheda.codiceProgetto;
        const idPeriodo = scheda.idPeriodo;
        console.log("cliccato su reject per " + codiceProgetto + ' ' + idPeriodo);
        
        let saveResult = await this.save();
        if(!saveResult) {
            console.log('salvataggio fallito');
            return false;
        }
        this.disableButtons = true;
        await this.$store.dispatch('assessment/completeSchedaAssessment', {

              codiceProgetto: codiceProgetto,
              idPeriodo: idPeriodo,
              approvazione: false
        }).then(
            () => {
                this.$store.dispatch('assessment/clearSchedaAssessment', {
                    codiceProgetto: codiceProgetto,
                    idPeriodo: idPeriodo,
                });
                notify.success(notify.strings.success, notify.strings.rejectAssessmentOk);
                this.$router.push({ name: "secure"});
            },
            error => {
                console.log('error REJECT', error.response);
                notify.error(notify.strings.error, notify.strings.rejectAssessmentError + notify.strings.requestStatus(error.response.status));
            }
        );
        this.disableButtons = false;
    },

    reset() {
        this.note = ""
    },
    resetReservedNote() {
        this.noteRiservate = ""
    },
    getVisibility() {
      const schedaCompleta = this.incomingData;
        if(!schedaCompleta) {
            console.log("invio.getVisibility: Invalid scheda!?");
            return;
        }
        const scheda = schedaCompleta.schedaAssessment;
        let task = schedaCompleta.taskList;
        this.isHiddenSave = !utils.caniuse("save", task, scheda)
        this.isHiddenSend = !utils.caniuse("send", task, scheda)
        this.isHiddenApprove = !utils.caniuse("approve", task, scheda)
        this.isHiddenReject = !utils.caniuse("reject", task, scheda)
        this.isHiddenNote = this.isHiddenSave && this.isHiddenSend && this.isHiddenApprove && this.isHiddenReject;

        // WARNING: se le note riservate non vengono caricate (vecchi processi), allora non viene visualizzato il collapse
        this.isVisibleInternalNote = utils.caniuse("internalNote", task, scheda) && this.incomingData.schedaNoteRiservate;
    },

    elaboraMandatori() {
      //ripulisco l'array
        this.tabledata.rows = [];
        if(this.incomingData && this.incomingData.schedaAssessment && this.incomingData.schedaAssessment.dataEntryConfiguration){
            let errori = mapUtils.controllaMandatori(this.incomingData.schedaAssessment);
            // A questi obbligatori vanno aggiunti quelli di Cronoprog
            let mandatoryCronoProgMissed = this.getMandatoryCronoProgMissed()
            if(mandatoryCronoProgMissed.length > 0)
              errori = errori.concat(mandatoryCronoProgMissed)
            this.tabledata.rows = errori;
        } else {
            console.error("SchedaAssessment o configurazione non presente!?");
        }
        
    }
  }
};
</script>
