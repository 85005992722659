<template>
    <div>
        <b-overlay :show="!loadComplete" rounded="sm">
            <div class="table-title">
                Scheda Irregolarità {{this.$route.params.idControllo}}
            </div>
            <div>
                <b-table-lite
                thead-class="head"
                :items="this.tabledata.rows"
                :fields="this.tabledata.header"
                v-if="loadComplete" />
                <b-card no-body>
                    <b-tabs card nav-class="tabs" content-class="content">
                        <ColorLegend/>
                        <b-tab
                            v-if="loadComplete && isViewAllowed('Anagrafica')"
                            no-body
                            :title="conf_export_pdf['anagrafica'].title_page_pdf"
                            title-item-class="tab1"
                            title-link-class="titleLink"
                            v-on:click="setTabSelection(conf_export_pdf['anagrafica'].name)">
                            <GeneralViewerCard
                                :idTablePdf="conf_export_pdf['anagrafica'].id_table_pdf"
                                :name="conf_export_pdf['anagrafica'].name"
                                :tabName="tabSelection"
                                :pathDataEntryConfiguration="'content'"
                                :store="'irregolarita'"
                                :fnGetterTab="'getTabellone'"
                                :headerTable="headerAnagrafica"
                                :rowIds="rowIds_anagrafica"
                                :fnGetter="'getScheda'"
                                :idEntity="{
                                    id: this.$route.params.idControllo
                                }"
                                :actions="actionsMap[mappingTabs[1]]"
                                :fnSetter="'updateScheda'"
                            />
                        </b-tab>
                        <b-tab
                        v-if="loadComplete && isViewAllowed('Documentazione')"
                        no-body
                        :title="conf_export_pdf['documentazione'].title_page_pdf"
                        title-item-class="tab1"
                        title-link-class="titleLink"
                        v-on:click="setTabSelection(conf_export_pdf['documentazione'].name)">
                        <Documentazione
                            :ownerProcessStatus= this.retrieveTaskDefinitionKey
                            :actions="actionsMap[mappingTabs[2]]"
                            :entity = this.store
                            :getters = this.getters
                            :setters = this.setters
                            :payload = this.payload
                            :riferimento = this.riferimento
                            tabName="Documentazione"
                        />                        
                        </b-tab>
                        <b-tab
                            v-if="loadComplete && isViewAllowed('EsitoFinale') && getCurrentScheda().stato === 'Procedimento Irregolarità avviato'"
                            no-body
                            :title="conf_export_pdf['EsitoFinale_commenti'].title_page_pdf"
                            title-item-class="tab1"
                            title-link-class="titleLink"
                            v-on:click="setTabSelection(conf_export_pdf['EsitoFinale_commenti'].name)">
                            <EsitoFinale :forceUpdateScheda="updateScheda" :actions="actionsMap[mappingTabs[3]]" />
                        </b-tab>
                        <b-tab
                            v-if="loadComplete && isViewAllowed('Invio')"
                            no-body
                            :title="conf_export_pdf['Invio_mandatory'].title_page_pdf"
                            title-item-class="tab1"
                            title-link-class="titleLink"
                            v-on:click="setTabSelection('Invio')">
                        <Invio
                            :tabName="tabSelection"
                            :config="tabellone"
                            :idsToCheck="rowIds_anagrafica"
                            :actions="actionsMap[mappingTabs[4]]"
                            @buildPdf="generateReport"
                        />
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </b-overlay>
    </div>
</template>

<script>

import Documentazione from "@/components/Documentazione.vue"
import Invio from "./invio.vue"
import EsitoFinale from "./esitoFinale.vue"
import { mapGetters } from "vuex";
import GeneralViewerCard from '@/components/GeneralViewerCard.vue'
import notify from "@/helpers/notifications.js"
import utils from "@/helpers/utils.js"
import helper_pdf from "@/helpers/exportPdf.js"
import tools from '@/helpers/tools.js'
import ColorLegend from "@/components/legend.vue"


export default {
    name: "schedaIrregolarita",
    components: {
        Documentazione,
        Invio,
        EsitoFinale,
        GeneralViewerCard,
        ColorLegend
    },
    data() {
        return {
            //informazioni di riferimento per i file allegati, i campi verranno valorizzati durante la mounted
            riferimento : {
                idOggettoRif: "",
                tipoOggettoRif: "Scheda Segnalazione Irregolarità", //uno dei valori definiti in TipoOggettoRiferimento.java in ISF-files
                idBando: "",
                tipoBando: "Sconosciuto", //uno dei valori definiti in TipoBando.java in ISF-files
                idProgetto: "",
                codiceProgetto: ""
            },
            updateScheda: false, // Ogni volta che cambio i commenti devo dire ad EsitoFinale di aggiornare la scheda nello Store
            schedaIrregolarita: {},
            tabellone: {},
            tabledata: {
                header: [
                    {
                        key: "codiceProgetto"
                    },
                    {
                        key: "titolo"
                    },
                    {
                        key: "stato"
                    },
                    {
                        key: "dataConclusione",
                        formatter: this.dateFormatter
                    }
                
                ],
                rows: [
                    {
                        "codiceProgetto": "Non disponibile",
                        "titolo": "Non disponibile",
                        "stato": "Non disponibile",
                        "dataConclusione": null
                    }
                ],
            },
            rowIds_anagrafica: [
                "['anagrafica']['oggettoControllo']",
                "['anagrafica']['riferimento']",
                "['anagrafica']['tipologiaControllo']",
                "['anagrafica']['dataControllo']",
                "['anagrafica']['oraControllo']",
                "['anagrafica']['luogoControllo']",
                "['anagrafica']['organismo']",
                "['anagrafica']['irregolarita']",
                "['anagrafica']['disposizioni']",
                "['anagrafica']['sospettoFrode']",
                "['anagrafica']['importoControllato']",
                "['anagrafica']['percentualeIrregolarita']",
                "['anagrafica']['importoIrregolareTotale']",
                "['anagrafica']['importoIrregolareUE']",
                "['anagrafica']['osservazioni']",
                "['anagrafica']['totaleSomme']",
                "['anagrafica']['totaleSommeUE']",
                "['anagrafica']['dataVerbale']",
                "['anagrafica']['protVerbale']"
            ],
            headerAnagrafica: [
                { key: "campo" },
                {
                    key: "descrizione",
                    tdAttr: this.tooltip,
                    formatter: ""
                },
                { key: "azione" }
            ],
            actionsMap: {},
            mappingTabs: {
                1: 'Anagrafica',
                2: 'Documentazione',
                3: 'EsitoFinale', 
                4: 'Invio'   
            },
            conf_export_pdf: {
                "anagrafica": {
                    id_table_pdf: 'anagrafica_irr',
                    name: "Anagrafica",
                    title_page_pdf: 'Anagrafica',
                    nameCollapse: '',
                },
                "documentazione": {
                    id_table_pdf: 'fileTable',
                    name: "Documentazione",
                    title_page_pdf: 'Documentazione',
                    nameCollapse: '',
                },
                "Invio_mandatory": {
                    id_table_pdf: 'mandatory_irregolarita',
                    name: "Invio",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Campi obbligatori non valorizzati',
                },
                "Invio_note": {
                    id_table_pdf: 'note_irregolarita',
                    name: "",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Note',
                },
                "Invio_note_riservate": {
                    id_table_pdf: 'note_riservate_controlli',
                    name:"",
                    title_page_pdf: 'Invio',
                    nameCollapse: 'Elenco Note Interne',
                },
                // L'ESITO FINALE DEL CONTROLLO SARA' INSERITO IN FONDO NELL'ULTIMA PAGINA DISPONIBILE
                "Invio_esito_finale": {
                    id_field: "['content']['esitoFinale']",
                    name: "Invio",
                    title_page_pdf: 'Dettagli Invio',
                    conf_field: { addPage: true, mapping: 'SiNo' },
                    descRadio: 'Segnalazione accettata: '
                },
                "EsitoFinale_commenti": {
                    id_field: "['content']['commenti']",
                    name: "EsitoFinale",
                    title_page_pdf: 'Esito Finale',
                    conf_field: { addPage: true },
                    descRadio: "Commenti conclusivi sull'irregolarita: "
                },
            },
            loadComplete: false,
            tabSelection: "Anagrafica",
            fakeStatus: "Irregolarita_irregolarita-conclusa_Nessuno-Nessuno",
            store: "irregolarita",
            getters: {
                scheda: "getScheda",
                tabellone: "getTabellone"
            },
            setters: {
                scheda: "updateScheda",
                allegatoDaCancellare: "setAllegatoDaCancellare",
                clearAllegatiDaCancellare: "clearAllegatiDaCancellare"
            },
            payload: {
                scheda: {          
                    codiceProgetto: this.$route.params.codiceProgetto, 
                    id: this.$route.params.idControllo
                },
            }
        }
    },
    computed: {
        retrieveTaskDefinitionKey() {
        if (this.schedaIrregolarita 
                && this.schedaIrregolarita.content 
                && this.schedaIrregolarita.content.taskInfo 
                && this.schedaIrregolarita.content.taskInfo.taskDefinitionKey)
            return this.schedaIrregolarita.content.taskInfo.taskDefinitionKey
        else 
            return this.fakeStatus
        },
        ...mapGetters({
            getScheda: "irregolarita/getScheda",
            getTabellone: "configuration/getTabellone"
        })
    },
    mounted() {
        this.loadSchedaAndConfigs(this.$route.params.codiceProgetto, this.$route.params.idControllo);
        this.riferimento.idOggettoRif = this.$route.params.idControllo;
        this.riferimento.codiceProgetto = this.$route.params.codiceProgetto;
    },
    methods: {
        generateReport(optPdf) {
            this.generationPdf = true

            // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)
            let source = {
                scheda: this.getCurrentScheda()
            }
            this.conf_export_pdf['Invio_esito_finale'].source = source

            // Gestisco la visibilità dei commenti conclusivi sull'Irregolarità nell'exportPdf
            // solo quando il tab relativo sarà visibile, ovvero quando la segnalazione è stata accettata
            if(source.scheda.stato === 'Procedimento Irregolarità avviato')
                this.conf_export_pdf['EsitoFinale_commenti'].source = source
            else delete this.conf_export_pdf['EsitoFinale_commenti']
            // GESTIONE ELEMENTI TA-RB (TextArea - RadioButton)

            helper_pdf.savingFilePdf(optPdf, this.conf_export_pdf)
            this.generationPdf = false
        },
        getCurrentScheda() {
            let scheda = this.getScheda({ id: this.$route.params.idControllo })
            if(!scheda){
                console.error("Errore getCurrentScheda: ", scheda)
                return null
            }
            let clonedScheda = tools.genericFunctions.cloneObject(scheda)
            return clonedScheda
        },
        loadSchedaAndConfigs(codice, id){
            this.$store.dispatch("irregolarita/setSchedaIrregolarita",
            {
                codiceProgetto: codice,
                idScheda: id
            }).then((scheda)=> {
                this.schedaIrregolarita = scheda;
                if (scheda.content.infoProgetto.idProgetto){
                    this.riferimento.idProgetto = scheda.content.infoProgetto.idProgetto;
                }
                this.loadTabellone();
            }).catch(error => {
                console.error('Errore store dispatch: ', error.message)
                notify.error(notify.strings.error, notify.strings.serverError)
            })
        },
        loadTabellone(){
            this.$store.dispatch("configuration/setTabellone", 'irregolarita')
            .then((res) => {
                this.tabellone = res
                this.$store.dispatch("irregolarita/setTabellone", {
                    tabellone: res
                })
                this.populateTabledata();
                this.loadActionMap();
                this.loadComplete = true;
            }).catch(error => {
                console.error('Errore store dispatch tabellone: ', error.message)
                notify.error(notify.strings.error, notify.strings.serverError)
            })
        },
        loadActionMap(){
            let scheda = this.schedaIrregolarita;            
            // Prendo la lista degli IDs dei tabs previsti per questa scheda dal mappingTabs
            let allIndices = Object.keys(this.mappingTabs)
            for (const index of allIndices) {
                let tabName = this.mappingTabs[index]
                this.actionsMap[tabName] = this.$getActionsIrregolarita(scheda, tabName)
            }
        },        
        isViewAllowed(tabName) {
            return this.actionsMap[tabName].length > 0
        },
        populateTabledata(){
            this.tabledata.rows[0].codiceProgetto = this.schedaIrregolarita.codiceProgetto;
            if (this.schedaIrregolarita.content.infoProgetto){
                
                if (this.schedaIrregolarita.content.infoProgetto.titolo){
                    this.tabledata.rows[0].titolo = this.schedaIrregolarita.content.infoProgetto.titolo;
                }

                if (this.schedaIrregolarita.content.infoProgetto.stato){  
                    this.tabledata.rows[0].stato = this.schedaIrregolarita.content.infoProgetto.stato;
                }

                if (this.schedaIrregolarita.content.infoProgetto.dataConclusione){  
                    this.tabledata.rows[0].dataConclusione = this.schedaIrregolarita.content.infoProgetto.dataConclusione;
                }
            }            
        },
        setTabSelection(name) {
            // Forzo l'aggionamento della scheda, che dovrà fare Esito Finale, ogni qual volta lascio proprio questo tab
            if(this.tabSelection === 'EsitoFinale' && name !== 'EsitoFinale') this.updateScheda = !this.updateScheda
            this.tabSelection = name
        },
        dateFormatter(value) {
            if (!value) 
                return "Non disponibile";
            return utils.formatDate(value);
        },

    }

}

</script>
